import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalCase'
})
export class PascalCasePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) { return '' };

    const words = value.trim().split(' ');

    // Capitalize the first letter of each word
    const pascalCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase());

    // Join the words to form the final Pascal case string
    const pascalCaseString = pascalCaseWords.join(' ');

    return pascalCaseString;
  }

}
