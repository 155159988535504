import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  THIRD_PARTY_SCREENS_KEY,
  THIRD_PARTY_SCREENS_CATEGORY,
  THIRD_PARTY_SCREENS_EVENTS,
  THIRD_PARTY_SCREENS_EVENTS_KEY,
  THIRD_PARTY_SCREENS_SCREENS
} from '../constants/third-party-multicountry-analytic.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof THIRD_PARTY_SCREENS_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class ThirdPartyMulticountryAnalyticsService extends AnalyticsService {
  constructor() {
    super(THIRD_PARTY_SCREENS_SCREENS, THIRD_PARTY_SCREENS_EVENTS);
  }

  /**
   * To track titleDetails page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof THIRD_PARTY_SCREENS_KEY, altObj: AltObj = {}) {
    this.trackPage(screen, altObj);
  }

  /**
   * To trackMultipleCountryEvent
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMultipleCountryEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.TIER_THREE_PAGE, action, altObj);
  }

}
