<a *ngIf="this.profileInfoService.currentProfile?.profileId"
    class="kz-user-profile kz-user-{{this.profileInfoService.currentProfile.profileType.toLowerCase()}}"
    id="loc_profileImg" mat-button axis360MatMenuFocus [matMenuTriggerFor]="menu" (menuOpened)="menuStateChange(true)"
    (menuClosed)="menuStateChange(false)" attr.aria-label="{{this.profileInfoService.currentProfile.profileName}} {{(this.profileInfoService.currentProfile.profileType.toLowerCase() === 'adult' && this.profileInfoService.currentProfile.isPrimary.toLowerCase() === 'true') ? 'General '+ this.profileInfoService.currentProfile.profileType +' Primary' :
    (this.profileInfoService.currentProfile.profileType.toLowerCase() === 'adult') ? 'General '+ this.profileInfoService.currentProfile.profileType : this.profileInfoService.currentProfile.profileType}} profile" href="javascript:;" (click)="profilePicClick()" role="button">
    <img [src]="profileInfoService.currentProfile.profileImage"
        *ngIf="profileInfoService.currentProfile.profileImage && !profileImageError" class="user-img" [alt]="getAvatarName(profileInfoService.currentProfile.profileImage, profileInfoService.currentProfile.profileName)"
        (error)="profileImageError = true;" aria-hidden="true" />
    <mat-icon svgIcon="kz-profile-user" *ngIf="!profileInfoService.currentProfile.profileImage"
        aria-hidden="true"></mat-icon>
</a>
<mat-menu #menu="matMenu" class="kz-action-menu-item kz-profile-action-menu">
    <div class="profile-user-list">
        <ng-container *ngIf="currentLibrary.kidsZoneEnabled">
            <ng-container *ngFor="let profile of profileInfoService.profiles; let i = index">
                <button mat-menu-item class="kz-profile-menu-item"
                    *ngIf="profile.profileId !== profileInfoService.currentProfile.profileId"
                    attr.aria-label="{{profile.profileName}} {{(profile.profileType.toLowerCase() === 'adult' && profile.isPrimary === 'True') ? 'General '+ profile.profileType +' Primary': (profile.profileType.toLowerCase() === 'adult') ? 'General ' + profile.profileType : profile.profileType}}  Profile {{profile.pinStatus === 'True'? 'Pin Enabled': ''}} "
                    (click)="selectedProfile(profile)">
                    <span class="profile-image kz-user-{{profile.profileType.toLowerCase()}}">
                        <img [src]="profile.profileImage" *ngIf="profile.profileImage && !hasError" class="user-img"
                        [alt]="getAvatarName(profile.profileImage, profile.profileName)" (error)="hasError = true;" class="user-img" aria-hidden="true" />
                        <mat-icon svgIcon="kz-profile-user" *ngIf="!profile.profileImage" aria-hidden="true"></mat-icon>
                    </span> <span class="profile-name-lbl single-ellipsis" axis360EllipsisTitle>
                        {{profile.profileName}}</span>
                    <span><mat-icon class="kz-lock-prof" aria-hidden="true" svgIcon="kz-lock-profile"
                            *ngIf="profile.pinStatus === 'True'"></mat-icon></span>
                </button>
            </ng-container>
        </ng-container>
        <a [routerLink]="['/view/profiles/edit', profileInfoService.currentProfile.profileId]" [queryParams]="queryVal"
            href="javascript:;" [disabled]="this.router.url.includes('edit')"
            (click)="profileInfoService.profilePinSuccess = true;" mat-menu-item class="kz-manage-profile-btn">
            <mat-icon svgIcon="kz-settings" hidden></mat-icon> Profile
            Settings </a>
        <a *ngIf="currentLibrary.kidsZoneEnabled" [routerLink]="['/view/profiles']" [queryParams]="{isMenu: 'yes'}"
            mat-menu-item class="kz-manage-profile-btn fill-none" href="javascript:;">
            <mat-icon svgIcon="kz-manage-edit" hidden></mat-icon> Manage Profiles</a>
        <a href="javascript:;" mat-menu-item class="kz-manage-profile-btn" (click)="signOut()">
            <mat-icon svgIcon="kz-logout" hidden></mat-icon> Sign out</a>
    </div>
</mat-menu>