import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibCompononent } from '@shared/data/library-base.model';
import { THIRD_PARTY_SUBTYPE, THIRD_PARTY_TYPES, THIRD_PARTY_URLS } from 'app/third-party/shared/constants/third-party.constants';
import { UserService } from '@shared/services/user.service';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { Router } from '@angular/router';

@Component({
  selector: 'axis360-third-party-carousel-type',
  templateUrl: './third-party-carousel-type.component.html',
  styleUrls: ['./third-party-carousel-type.component.scss']
})
export class ThirdPartyCarouselTypeComponent implements OnInit {
  @Input() seeAllLink = THIRD_PARTY_URLS.TIER2;
  @Input() pageTitle = '';
  @Input() vendorCode = 'PRESSRDR';
  @Input() componentData: LibCompononent;
  @Input() isShowCount?: boolean;
  @Input() parentRoute: string;
  @Input() inputElmIndex?: number;
  @Output() count = new EventEmitter<{ type: string; count: number }>();
  @Input() isCarouselLoad: boolean;
  @Output() checkEventDataLoading = new EventEmitter<boolean>();
  THIRD_PARTY_TYPES = THIRD_PARTY_TYPES;
  THIRD_PARTY_SUBTYPE = THIRD_PARTY_SUBTYPE;
  isThirdPartyCarousel = false;
  constructor(
    public userService: UserService,
    public configService: ConfigService,
    public profileInfo: ProfileInfoService,
    public router: Router) { }
  /**
   * Counts event
   */
  countEvent(event) {
    this.count.emit(event);
  }

  /**
   * Get Event for checkDataLoadingEvent
   * @param [event] check Data Loading
   */
  checkDataLoadingEvent(event) {
    this.checkEventDataLoading.emit(event);
  }

  ngOnInit(): void {
    if (this.parentRoute === 'tier3') {
      const parentRoute = new RegExp('view/' + "(.*?)" + '/details').exec(this.router.url);
      this.parentRoute = parentRoute ? parentRoute[1] : ''
    }
    const isPressReaderEnabled = this.configService.getProfileFeatures(this.profileInfo.currentProfile.profileType.toLowerCase())?.[
      this.vendorCode
    ];
    if ((this.userService.userInfo?.isLoggedIn && isPressReaderEnabled) || !this.userService.userInfo?.isLoggedIn) {
      this.isThirdPartyCarousel = true;
    }
  }
}
