import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ListContent, BookDetail } from '@shared/data/config.model';
import { LIST_CONTENT_MOCK } from '@shared/data/mock/list.mock';
import { KzSearchService } from './shared/services/kz-search.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchRefinersComponent } from '@shared/components/search-refiners/search-refiners.component';
import { SpinnerService } from '@shared/services/spinner.service';
import {
  FORMAT_TYPE,
  GRADE_MAP,
  PRESSRDR,
  PRESS_READER_CATEGORY,
  SITES_DEFAULT_IMG,
  SITES_FILTER,
  SITES_NO_RESULTS,
  SOURCE_TYPE,
  VBOOKS,
  VIDEOS
} from '@search/shared/constants/search-results.constants';
import { SubjectInfo, TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { arrangeInOrder, cdkRemoveVisualHidden, cdkVisualHidden, deepClone, focusElement, positionFooter, strToBool } from '@shared/helper/app.util';
import { ALL_REFINERS, DEFAULT_SEARCH_FAILS_MSG, DEFAULT_SNAKE_BAR_MSG, HOME, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { getResultsCount } from '@search/shared/helper/search.util';
import {
  AppliedFilter,
  GetSitesRequest,
  GetTitlesRequest,
  Sites,
  WPEDOC,
  Facet,
  StudyTitles,
  StudySites,
  Filter,
  LearningActivity,
  IRefiner,
  ITotalCountType,
  ILearningActivity
} from './shared/data/search.model';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { IThirdPartySearch, LayoutComponent, ThirdPartyLayout } from '@shared/data/third-party-model';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { THIRD_PARTY_TIER, THIRD_PARTY_TYPES } from 'app/third-party/shared/constants/third-party.constants';
import { UserService } from '@shared/services/user.service';
import { getRefinerCount } from 'app/third-party/shared/helper/third-party-util';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CommonService } from '@shared/services/common.service';
import { CATEGORY_TYPE, SITES_REFINER_ORDER_LIST } from './shared/constant/search.constant';
import { handleProgramDetails } from '@readingprograms/programs/shared/helper/programs.util';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { AUDIENCE, KID, PRESSREADERDRUPALDOWN, VBOOKSDRUPALDOWN, VIDEOSDRUPALDOWN } from '@shared/constants/search.constant';
import { PROGRAM_TYPE } from '@readingprograms/shared/constants/programs.constants';
import { KzProgramsService } from '../kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
@Component({
  selector: 'axis360-kz-search-results',
  templateUrl: './kz-search-results.component.html',
  styleUrls: ['./kz-search-results.component.scss']
})
export class KzSearchResultsComponent implements OnInit, OnDestroy {
  searchText: string = '';
  isTitletoRecommend = false;
  programsDetails: string = '';
  breadcrumbs: { text: string; url?: string; isHref?: boolean }[];
  refiners: { title: string; subOptions: any[]; key: string; selectedItem: string[] }[] = [];
  isLoading: boolean = false;
  noResult = false;
  showLearningActivity = false;
  titleContent: BookDetail[];
  collectionData: ListContent = LIST_CONTENT_MOCK;
  sitesInfo: TitleCardInfo[] = [];
  carouselTitle = ['eBOOKS (2k+)', 'eAUDIO (999)', 'VIDEO (999)', 'vBOOKS (999)'];
  carousel = true;
  filterOpened = false;
  activityResourcesCard = false;
  webResourcesCard = false;
  @Input() titles: any;
  page: number;
  refiner = [];
  studyTitlesResult: ListContent[] = [];
  selectedOption: any;
  EMPTY = '';
  totalSitesCount: number = 0;
  availableLimiters: Filter[] = [];
  activityRefiner: IRefiner[] = [];
  initialFailed = false;
  learningActivityResult: ILearningActivity;
  totalCount: ITotalCountType = {
    All: 0,
    ABT: 0,
    EBT: 0,
    learningActivity: 0,
    webPathExpress: 0
  } as any;
  selectedOptions = {
    sortBy: ['Linked Title'],
    activityTypes: [ALL_REFINERS],
    audiences: [],
    publishers: [],
    relatedTitle: [],
    defaulter: false
  };
  selectedWebResoure = {};
  refinerMap = {
    sortBy: 'Sort By',
    activityTypes: 'Resource Type',
    audiences: 'Audience Level',
    publishers: 'Provider',
    relatedTitle: 'Related Title'
  };
  activityCategory = '';
  thirdPartyCount = [];
  studyTitles: StudyTitles;
  studySites: StudySites;
  learningActivity: LearningActivity;
  libraryComponent: any;
  layoutComponents: IThirdPartySearch[] = [];
  layoutComponentsList: IThirdPartySearch;
  initialLayout = { layout: { components: [] } };
  layoutData: Partial<ThirdPartyLayout> = this.initialLayout;
  refinersComponent: LayoutComponent;
  layoutComponent: LayoutComponent;
  isPressrdrLoad = false;
  categoryTypeMap: any[];
  isAppliedFilter: boolean;
  sitesTitlesInfo: TitleCardInfo[];
  countList = 0;
  appliedFilterCount = 0;
  studySitesPageCount: number = 0;
  kidsZoneEnabled: boolean = false;
  axis360Enabled: boolean = false;
  thirdPartyBaseGridClass: { divClass: string; cardsClass: string };
  filtersCount: string;
  vendorCode: string;
  activitySortRefiner: any[];
  arrangeInOrder = arrangeInOrder;
  currentCategory: string;
  programDetails: ProgramDetails;
  programId: any;
  showRefinerLayout: boolean = false;
  isProgram: boolean = true;
  enablePurchaseRequest: boolean;
  epopupValue: boolean;
  skipLink = '';
  constructor(
    public matDialog: MatDialog,
    public searchService: KzSearchService,
    public activeRouter: ActivatedRoute,
    public router: Router,
    public spinnerService: SpinnerService,
    public liveAnnouncer: LiveAnnouncer,
    public snackBarService: SnackBarService,
    public searchStorageService: SearchStorageService,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public thirdPartyService: ThirdPartyService,
    public userService: UserService,
    public breadcrumbService: BreadcrumbService,
    public commonService: CommonService,
    public programsService: ProgramsService,
    public titleService: Title,
    public kzProgramsService: KzProgramsService,
    public location: Location,
  ) { }

  ngOnInit() {
    this.epopupValue = this.configService.currentLibrary.partnerSiteEnabled;
    this.activeRouter.queryParamMap.subscribe(() => {
      this.handleQueryParamChange();
    });
    this.showRefinerLayout = window.innerWidth < 767;
    this.skipLink = `/ng${this.location.path(false)}#searchResultContainer`;
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }

  ngAfterViewInit(): void {
    focusElement('loc_headerId');
  }


  handleQueryParamChange = async () => {
    this.currentCategory = this.activeRouter.snapshot.params.format;
    this.searchService.totalCountList = this.totalCount;
    this.searchService.titleTotalCountList.next(this.totalCount);
    this.searchService.totalCount = 0;
    this.searchService.apiCount = 0;
    this.searchService.apiCompletedCount = 0;
    this.thirdPartyService.searchViewRefiner = true;
    this.countList = 0;
    this.thirdPartyCount = [];
    this.studyTitlesResult = [];
    this.layoutComponents = [];
    this.learningActivityResult = new ILearningActivity();
    this.enablePurchaseRequest = (this.userService.isLoggedIn()) ? this.configService.getProfileFeatures(
      this.profileInfoService.currentProfile.profileType.toLowerCase()
    ).purchaseRequestsEnabled : this.configService.getProfileFeatures(PROFILE_TYPES.ADULT).purchaseRequestsEnabled;
    this.readQueryParms();
    this.getSearchTitles();
    this.titleService.setTitle('Search Results');
    sessionStorage.setItem('defaultValues', undefined);
    sessionStorage.setItem('webresouse', undefined);
    this.thirdPartyService.sortByOptionIsSelected = {};
    const webpathExpressEnabled = this.profileInfoService.profileInfo.profileId
      ? this.profileInfoService.profileInfo.webpathExpressEnabled
      : this.configService.currentLibrary.enableWebPathExpress;
    if (webpathExpressEnabled && this.programsDetails !== 'programs' && !this.isTitletoRecommend) {
      this.getSitesData();
    }
    this.kzProgramsService.kzProgramType = this.programsDetails !== 'programs' ? '' :
      this.kzProgramsService.kzProgramType;
    ((this.programsDetails !== 'programs' && this.userService.isLoggedIn()) ? this.configService.getProfileFeatures(
      this.profileInfoService.currentProfile.profileType.toLowerCase()
    ).learningActivityEnabled : this.configService.getProfileFeatures(PROFILE_TYPES.ADULT).learningActivityEnabled)
      && this.kzProgramsService.kzProgramType !== PROGRAM_TYPE.MileStone && this.programsDetails !== 'programs' && !this.isTitletoRecommend && this.getLearningActivity();
    if (this.activeRouter.snapshot.queryParams['vendor_code'] !== '') {
      let vendorCodeByQueryParams = this.activeRouter.snapshot.queryParams['vendor_code'];
      const thirdPartyLibraryList = this.configService.vendorDetails || [];
      const vendorDetails = thirdPartyLibraryList.find(vendor => vendor.vendor_code === vendorCodeByQueryParams)
      const isPressReaderEnabled = this.configService.getProfileFeatures(
        this.profileInfoService.currentProfile.profileType.toLowerCase()
      )?.[vendorCodeByQueryParams];

      /** we call Get ThirdParty Search Home */
      const list = (((!this.userService.userInfo?.isLoggedIn || isPressReaderEnabled) && !this.isTitletoRecommend) &&
        (await this.getThirdPartySearchHome(vendorCodeByQueryParams))) || { vendorCode: vendorCodeByQueryParams, components: [] };
      this.layoutComponents.push(list);
      this.updateApiCallCount(list, { vendorCode: vendorDetails?.vendor_code, vendorGroup: vendorDetails?.vendor_groups.length !== 0 });

      return;
    }
    if (!this.isTitletoRecommend && (this.userService.userInfo?.isLoggedIn || !this.userService.userInfo?.isLoggedIn) && this.programsDetails !== 'programs') {
      this.searchService.showThiredParty = true;
      this.getThirdPartyLibraryComponents();
    }
    setTimeout(() => {
      window.scrollTo(0, this.searchService.scrollPostion)
    }, 0);
    this.searchService.selectedCategory = this.currentCategory ?? 'All';
    this.activityCategory = this.activeRouter.snapshot.queryParams['searchby'];
    this.searchService.updatedSearchText.next(this.searchText);
    this.breadcrumbService.set('@searchtext', 'Search Results');
    this.getBreadCrumbs();
  };

  openFilter() {
    if (this.filterOpened) {
      return;
    }
    this.filterOpened = !this.filterOpened;
    const searchRefinersDialog = this.matDialog.open(SearchRefinersComponent, {
      panelClass: 'filter-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'filter-title'
    });
    searchRefinersDialog.afterClosed().subscribe(() => (this.filterOpened = false));
  }
  /**
   * Read the search requested fields fom Query Parms
   */
  readQueryParms() {
    this.searchText = this.activeRouter.snapshot.queryParams.searchText;
    const collectionType = (this.activeRouter.snapshot.queryParams.collections === 'Titles to Recommend');
    this.isTitletoRecommend = !!collectionType;
    this.searchService.updateObjByQueryParams(this.activeRouter.snapshot.queryParams);
    this.studyTitles = JSON.parse(JSON.stringify(this.searchService.studyTitles));
    this.studySites = JSON.parse(JSON.stringify(this.searchService.studySites));
    this.programsDetails = this.activeRouter.snapshot.queryParams['pageName'];
    this.programId = this.activeRouter.snapshot.queryParams['programId'];
    const totalCountTemp = {
      All: 0,
      ABT: 0,
      EBT: 0,
      learningActivity: 0,
      webPathExpress: 0
    } as any;
    this.totalCount = deepClone(totalCountTemp);
    this.searchService.totalCountList = deepClone(totalCountTemp);
    this.searchService.titleTotalCountList.next(deepClone(totalCountTemp));
    this.getBreadCrumbs();
    this.learningActivity = JSON.parse(JSON.stringify(this.searchService.learningActivity));
    this.sitesInfo = [];
    this.sitesTitlesInfo = [];
  }

  getBreadCrumbs() {
    if (this.programsDetails !== 'programs') {
      this.isProgram = false;
      const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
      this.breadcrumbs = [
        { text: homePage, url: HOME[homePage], isHref: true },
        { text: 'Search Results', url: '', isHref: false }
      ];
    }
    else {
      const b = sessionStorage.getItem('breadcrumb')
      if (b) {
        this.breadcrumbs = JSON.parse(sessionStorage.getItem('breadcrumb'))
      }
      else {
        this.breadcrumbService.breadcrumbs$.subscribe((response) => {
          if (response.filter(res => res.label === 'programs').length > 0) {
            this.commonService.breadcrumbs = [];
            const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
            const url = homePage === 'Library' ? '/view/library' : '/view/myshelf'
            response.forEach((res) => {
              const breadcrumbData = {
                text: res.label,
                url: res.routeLink,
                queryParams: res.queryParams
              }
              this.commonService.breadcrumbs.push(breadcrumbData)

            })
            this.commonService.breadcrumbs[0] = { text: homePage, url, queryParams: undefined }
            const unique = this.commonService.breadcrumbs.filter((set => f => !set.has(f.text) && set.add(f.text))(new Set()));
            this.breadcrumbs = unique;
            sessionStorage.setItem("breadcrumb", JSON.stringify(this.commonService.breadcrumbs))
          }
        });
      }
    }
  }
  /**
   * Get Search Titles based on the Search term and Filter Options
   */
  async getSearchTitles(refiner = false, formatFilter = false) {
    this.spinnerService.showLoader();
    let formatArray = [];
    if (refiner) {
      formatArray = [this.studyTitles.format];
    } else {
      formatArray = this.studyTitles.format === 'All' || this.studyTitles.format === '' ? ['EBT', 'ABT'] : [this.studyTitles.format];
    }
    var fromProgramMilestone = false;
    if (!(this.programDetails?.name) && this.programsDetails === 'programs') {
      fromProgramMilestone = true;
      this.searchService.apiCount += 1;
      let programRes: any = await this.programsService.getProgramProgress(this.programId).toPromise();
      this.searchService.apiCompletedCount += 1;
      this.programDetails = handleProgramDetails(programRes);
      this.programDetails.books = (this.programDetails.participants?.length > 0 && this.programDetails?.participants[0].milestoneBookList) ? this.programDetails?.participants[0].milestoneBookList : []
    }
    formatArray.forEach(async (format: string) => {
      this.studyTitles.collections = (this.studyTitles.collections === "Titles to Recommend") ? this.studyTitles.collections : '';
      this.studyTitles.format = format || 'All';
      const req: GetTitlesRequest = this.studyTitles;
      const checkTypeInfo = typeof this.studyTitles.isFromProgramMilestone;
      const titleFromProgramMillestone = checkTypeInfo !== 'string' ? this.studyTitles.isFromProgramMilestone : strToBool(this.studyTitles.isFromProgramMilestone);
      const isFromProgramMilestone = this.studyTitles.isFromProgramMilestone === '' ? false : titleFromProgramMillestone;
      req.isFromProgramMilestone = fromProgramMilestone ? fromProgramMilestone : isFromProgramMilestone;
      this.epopupValue && (req.availability = "Available");
      try {
        this.searchService.apiCount += 1;
        let response = await this.searchService.getTitleSearchResults(req);
        if (response.Filter.Format === 'All') {
          this.totalCount.ABT = 0;
          this.totalCount.EBT = 0;
          this.totalCount.All = response.TotalItems;
        } else {
          this.totalCount.All = 0;
          this.totalCount[response.Filter.Format] = response.TotalItems;
          this.searchService.totalCountList[response.Filter.Format] = response.TotalItems;
          this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
          this.searchService.totalCount = this.totalTilte(this.totalCount);
        }

        this.spinnerService.showLoader(false);
        if (this.programsDetails === 'programs') {
          for (let iterator of response.Items) {
            const isProgramBook = this.programDetails.books.filter((programbook) => programbook.bookId === iterator.itemId || programbook.bookId === iterator.ItemId).length > 0
            iterator.titleStateInfo = {
              actions: [{
                actionButtonId: isProgramBook ? 'btnRemove' : 'btnAddProgram',
                action: 0,
                buyNowUrl: null,
                enabled: true,
                formatType: iterator.formatType || iterator.FormatType,
                isbn: iterator.ISBN || iterator.isbn,
                itemId: iterator.itemId || iterator.ItemId,
              }],
              displayStatus: iterator.DisplayStatus
            }
          }
        }
        response.Items.map(item => {
          item.Author = item.Author ? this.parseString(item.Author) : item.Author;
        });
        if (response.Items || response.Items.length !== 0) {
          if (this.studyTitlesResult.length) {
            this.studyTitlesResult.forEach((val) => {
              if (val.Filter.Format === response.Filter.Format) {
                val.Items.push(...response.Items);
              } else {
                this.studyTitlesResult.push(response);
              }
            });
          } else {
            this.studyTitlesResult.push(response);
          }
          this.getActionViewButtonForLibraryList(response);
        }
        this.studyTitlesResult = this.studyTitlesResult.filter((x) => x.SearchTerm === this.searchText);
        if (formatFilter) {
          this.studyTitlesResult = this.studyTitlesResult.filter((x) => x.Filter.Format === this.studyTitles.format);
        }

        if (this.searchService.isPillsEmptyTitleFoucs) {
          const id = this.studyTitlesResult[0].Items[0].ItemId;
          setTimeout(() => {
            const titleFoucs = document.getElementById(`title_${id}`);
            titleFoucs.focus();
          }, 1000);
          this.searchService.isPillsEmptyTitleFoucs = false;
        }

        /* this.searchService.tierTwoRefinerSearch.subscribe((res: string) => {
           if (response && this.studyTitlesResult.length > 0 && res === 'search') {
             setTimeout(() => {
               const searchbtn: HTMLElement = document.getElementById('searchCarouselTitle');
               searchbtn && searchbtn.focus();
               searchbtn?.setAttribute('tabindex', '0');
               searchbtn?.setAttribute('aria-hidden', 'false');
            }, 2000);
           } else {
             setTimeout(() => {
               const menubtn: any  = document.getElementsByClassName('menuitem active category-text');
               menubtn[0] && menubtn[0].focus();
               menubtn[0] && menubtn[0]?.setAttribute('tabindex', '0');
               menubtn[0] && menubtn[0]?.setAttribute('aria-hidden', 'false');
            }, 2000);
           }

         });*/
        this.searchService.apiCompletedCount += 1;
      } catch (e) {
        this.searchService.apiCompletedCount += 1;
        this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
        this.spinnerService.showLoader(false);
        if (refiner) {
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SEARCH_FAILS_MSG);
        }
      }
    });
  }

  parseString(input: string): string {
    // Split by both '#' and '&' and trim the results
    return input?.split(/[#&]/).map(part => part.trim()).toString() || '';
  }

  /**
   * Retruns the Search Titles CTA
   */
  getActionViewButtonForLibraryList(titlesResponse: ListContent) {
    titlesResponse.Items?.map((element) => {
      this.searchService.getMyLibraryListActionButton(element).subscribe((actionButtonResponse) => {
        titlesResponse.Items?.map((elm) => {
          const searchRegExp = '#';
          const replaceWith = ';';
          elm.author = elm.author?.replace(searchRegExp, replaceWith);
          elm.Title = elm.Title?.replace(/<[^<>]*?>/g, '');
          if (elm.ISBN === element.ISBN) {
            if (this.programsDetails === 'programs') {
              actionButtonResponse.actions = [];
              actionButtonResponse.actions.push({
                actionButtonId: 'btnAddProgram',
                action: 0,
                buyNowUrl: null,
                enabled: true,
                formatType: elm.formatType || elm.FormatType,
                isbn: elm.ISBN || elm.isbn,
                itemId: elm.itemId || elm.ItemId
              });
            }
            elm.DisplayStatus = actionButtonResponse.displayStatus;
            elm.HoldPosition = actionButtonResponse.holdPosition;
            elm.titleStateInfo = actionButtonResponse;
          }
        });
      });
    });
  }
  /**
   * Get  Learning Activity based on the Search term and Filter Options
   */
  async getLearningActivity(isLoad = false, action?) {
    this.spinnerService.showLoader();
    this.searchService.apiCount += 1;

    const { searchby } = this.activeRouter.snapshot.queryParams;
    const { profileType } = this.profileInfoService.profileInfo;
    if (searchby !== 'all') {
      this.searchService.apiCompletedCount += 1;
      this.searchService.totalCountList.learningActivity = 0;
      this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
      this.learningActivityResult = null;
      this.spinnerService.showLoader(false);
      return;
    }
    const req: LearningActivity = this.learningActivity;
    req.audiences = profileType === KID ? profileType : req.audiences;
    req.audience = profileType === KID ? profileType : req.audience;
    req.profileType = profileType;
    try {
      this.searchService.apiCompletedCount += 1;
      let response = await this.searchService.getLearningActivity(req);
      if (response.statusCode === 200) {
        response.learningActivity.filter.activityTypes.splice(response.learningActivity.filter.activityTypes.findIndex(x => x === "All"), 1);
        response.learningActivity.filter.audiences.splice(response.learningActivity.filter.audiences.findIndex(x => x === "All"), 1);
        response.learningActivity.filter.sortBy.findIndex((x => x === "Relevance"), 1);
        this.programsDetails === 'programs' && response.learningActivity.filter.sortBy.splice(response.learningActivity.filter.sortBy.findIndex(x => x === "Publisher"), 1);
        this.totalCount.learningActivity = response.learningActivity.totalCount;
        this.searchService.totalCountList.learningActivity = response.learningActivity.totalCount;
        this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
        this.searchService.totalCount = this.totalTilte(this.totalCount);
        this.searchService.setDisableCheckBox.next(true);
        if (this.learningActivityResult?.data.length) {
          this.spinnerService.showLoader(false);
          // Extracted assignment
          this.learningActivityResult.data = [...this.learningActivityResult.data, ...response.learningActivity.data];
          return this.learningActivityResult.data;
        }
        this.learningActivityResult = response.learningActivity;
        if (this.searchService.isPillsEmptyTitleFoucs) {
          const id = this.learningActivityResult.data[0].id;
          setTimeout(() => {
            const learningActivityTitleFoucs = document.getElementById(`title_${id}`);
            learningActivityTitleFoucs.focus();
          }, 1000);
          this.searchService.isPillsEmptyTitleFoucs = false;
        }
      }
      this.spinnerService.showLoader(false);
      this.getLearningActivityFilters(this.learningActivityResult, false, action, isLoad);
    } catch (e) {
      this.searchService.apiCompletedCount += 1;
      this.learningActivityResult = null;
      this.searchService.totalCountList.learningActivity = 0;
      this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
      this.showLearningActivity && this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SEARCH_FAILS_MSG);
      this.spinnerService.showLoader(false);
    }
    setTimeout(() => {
      document.getElementById('carouselTitle')?.focus();
      const userAgentMatch = /(android|iPhone|iPod|iPad)/i.exec(navigator.userAgent);
      if (userAgentMatch) {
        document.getElementById('carouselTitle')?.setAttribute('tabindex', '0');
      }
    }, 0)
  }
  /**
   * Returns  Learning Activity Refiners
   */

  getLearningActivityFilters(learningActivityResult: ILearningActivity, isClear?: boolean, action?, isLoad = false) {
    let filterArray = [];
    if (isLoad) {
      Object.keys(learningActivityResult.filter).forEach((key, index) => {
        const tempRefiner = { title: '', subOptions: [], key: '', selectedItem: [] };
        tempRefiner.title = this.refinerMap[key];
        tempRefiner.key = key;
        tempRefiner.subOptions = learningActivityResult.filter[key];
        if (action === 'ClearAll' || this.activityCategory === 'All') {
          if (key === 'activityTypes' || key === 'audiences' || key === 'publishers' || key === 'relatedTitle') {
            tempRefiner.selectedItem = [learningActivityResult.defaultFilterOptions[index]];
            this.selectedOptions[key] = [];
          } else {
            if (tempRefiner.title && tempRefiner.title !== '') {
              tempRefiner.selectedItem = [learningActivityResult.defaultFilterOptions[index]];
              this.selectedOptions[key] = [learningActivityResult.defaultFilterOptions[index]];
            }
          }
        } else {
          if (key === 'activityTypes') {
            this.selectedOptions[key] = [this.activityCategory];
            tempRefiner.selectedItem = [this.activityCategory];
          } else if (key === 'audiences') {
            const item = learningActivityResult.defaultFilterOptions[index];
            this.selectedOptions[key] = item.includes('All') ? [] : [item];
            tempRefiner.selectedItem = item.includes('All') ? [] : [item];
          } else if (key === 'publishers') {
            const item = learningActivityResult.defaultFilterOptions[index];
            this.selectedOptions[key] = item.includes('All') ? [] : [item];
            tempRefiner.selectedItem = item.includes('All') ? [] : [item];
          } else if (key === 'relatedTitle') {
            const item = learningActivityResult.defaultFilterOptions[index];
            this.selectedOptions[key] = item.includes('All') ? [] : [item];
            tempRefiner.selectedItem = item.includes('All') ? [] : [item];
          } else {
            if (tempRefiner.title && tempRefiner.title !== '') {
              tempRefiner.selectedItem = [learningActivityResult.defaultFilterOptions[index]];
              this.selectedOptions[key] = [learningActivityResult.defaultFilterOptions[index]];
            }
          }
        }
        (tempRefiner.title && tempRefiner.title !== '') && this.refiners.push(tempRefiner);
        this.selectedOptions.defaulter = true;
      });
    }
    Object.entries(learningActivityResult.filter).forEach(([key, value], index) => {
      let filter = [];
      value.forEach((val: string) => {
        filter.push({
          myParent: key,
          text: val,
          value: val,
          isSelected:
            learningActivityResult.defaultFilterOptions[index]?.toLowerCase() === 'all'
              ? true
              : val === learningActivityResult.defaultFilterOptions[index]
        });
      });
      filterArray.push({ refiner: key, filters: filter, selectedFilter: learningActivityResult.defaultFilterOptions[index] });
    });
    if (this.activityRefiner.length == 0 || isClear) {
      this.activityRefiner = filterArray;
      if (this.profileInfoService.profileInfo.profileType === KID) {
        this.activityRefiner = this.activityRefiner?.filter((elm) => elm.refiner !== AUDIENCE);
      };
      // Remove the below code for Separation of Sort from Refiners in Search result Page
      // this.activitySortRefiner = this.activityRefiner.filter(item => item.refiner === "sortBy");
      // this.activityRefiner = this.activityRefiner.filter(item => item.refiner !== "sortBy");
      this.appliedFilterCount = this.activityRefiner
        .map((x) => x.filters.reduce((n, e) => (e.isSelected === true ? n + 1 : n), 0))
        .reduce((num, item) => num + item, 0);
    }
  }
  /**
   *
   * @param [categories]
   * @returns layoutComponents Based on Vendor code in Third Party APIM
   */
  async getThirdPartyLibraryComponents(categories: string = '', isLoading = false) {
    this.searchService.apiCount += 1;
    try {
      if (this.isTitletoRecommend) {
        this.searchService.isTitletoRecommendThirdPartyRemoved.next(true);
        this.searchService.apiCompletedCount += 1;
        this.searchService.showThiredPartyRefiner = true;
        return;
      }
      const thirdPartyLibraryList = this.configService.vendorDetails || [];
      if (thirdPartyLibraryList.length === 0) {
        this.searchService.apiCompletedCount += 1;
        return;
      }
      this.countList = thirdPartyLibraryList.length;
      this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
      this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
      const isKZGuest = !this.userService.userInfo?.isLoggedIn;
      const checkEnable = (!this.axis360Enabled && this.kidsZoneEnabled);
      const isProfileType = checkEnable ? PROFILE_TYPES.TEEN : PROFILE_TYPES.ADULT
      const currentProfileFeatures =
        this.configService.getProfileFeatures(
          isKZGuest
            ? isProfileType
            : this.profileInfoService.currentProfile.profileType.toLowerCase()
        ) || {};
      this.categoryTypeMap = (thirdPartyLibraryList || [])
        .filter((x) => x.include_in_search && (currentProfileFeatures[x.vendor_code]))
        .sort((a, b) => a.vendor_order - b.vendor_order)
        .map((x) => {
          if (x.vendor_code !== PRESSRDR) {
            return {
              text: x.display_name,
              value: x.display_name,
              vendorCode: x.vendor_code,
              vendor_order: x.vendor_order,
              vendorGroup: false,
              enabled: true,
              url: '/view/search/' + x.display_name
            };
          }
        });
      thirdPartyLibraryList.forEach((item) => {
        if (item.vendor_code === PRESSRDR && currentProfileFeatures[item.vendor_code]) {
          item.vendor_groups.length > 0 ? item.vendor_groups.forEach((subItem) => {
            this.categoryTypeMap.push({
              text: subItem.name,
              value: subItem.name,
              enabled: true,
              vendorGroup: true,
              url: '/view/search/' + subItem.name,
              vendorCode: item.vendor_code,
              vendor_order: item.vendor_order,
            });
          }) :
            this.categoryTypeMap.push({
              text: item.display_name,
              value: item.display_name,
              vendorCode: item.vendor_code,
              vendor_order: item.vendor_order,
              vendorGroup: false,
              enabled: true,
              url: '/view/search/' + item.display_name
            });
        }
      });
      this.categoryTypeMap = this.categoryTypeMap.sort((a, b) => a.vendor_order - b.vendor_order).filter(item => item !== undefined);
      if (categories) {
        const selectedCategory = this.categoryTypeMap.find((catType) => catType.value === categories);
        this.searchService.apiCount += 1;
        if (!selectedCategory.vendorGroup && selectedCategory.vendorCode === PRESSRDR) {
          const list = await this.getThirdPartySearchHome(selectedCategory.vendorCode, isLoading);
          if (list.components.length === 0) {
            this.searchService.thirdPartyCount.next(0);
            this.layoutComponents = [];
            return;
          }
          this.searchService.apiCompletedCount += 1;
          this.layoutComponents.push(list);
          this.updateApiCallCount(list, selectedCategory);
          return;
        }
        this.vendorCode = selectedCategory.vendorCode;
        await this.getLayout(selectedCategory.vendorCode, isLoading);
        this.searchService.apiCompletedCount += 1;
        return;
      }
      this.searchService.setThiredPartyCategories(this.categoryTypeMap);
      for (const catType of this.categoryTypeMap) {
        this.searchService.apiCount += 1;
        const list = await this.getThirdPartySearchHome(catType.vendorCode);
        this.searchService.apiCompletedCount += 1;
        this.layoutComponents.push(list);
        this.updateApiCallCount(list, catType);
      }
      this.searchService.apiCompletedCount += 1;
      const vendorOrderList = thirdPartyLibraryList.sort((a, b) => a.vendor_order - b.vendor_order).map(({ vendor_code }) => vendor_code);
      this.layoutComponents = this.arrangeInOrder(this.layoutComponents, vendorOrderList).filter(elem => elem !== undefined);
      const filterLayoutComponents = this.layoutComponents.filter((comp) => comp.components.length > 0);
      this.layoutComponents = filterLayoutComponents;
    } catch (e) {
      this.searchService.apiCompletedCount += 1;
      this.searchService.setThiredPartyCategories([]);
    }
  }
  /**
   * Updates api call count
   */
  updateApiCallCount(list, vendorDetails) {
    if (
      (this.userService.userInfo.isLoggedIn &&
        this.configService.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase())?.[vendorDetails?.vendorCode]) ||
      !this.userService.userInfo.isLoggedIn
    ) {
      this.searchService.apiCount += (list?.components?.length) || 0;
      if (vendorDetails?.vendorCode === PRESSRDR && vendorDetails?.vendorGroup) {
        this.searchService.apiCompletedCount += (list?.components && list.components.length - 1) || 0;
      }
    }
  }

  /**
   * getThirdPartySearchHome
   * @param [vendor_code]
   * @returns LayoutData in Third Party APIM
   */
  async getThirdPartySearchHome(vendor_code: string, isLoading = false) {
    this.isLoading = true;
    isLoading && this.spinnerService.showLoader();
    this.searchService.apiCount += 1;
    this.searchService.drupalServiceDownErrorMsg = '';
    try {
      const { searchby } = this.activeRouter.snapshot.queryParams;
      if (vendor_code === PRESSRDR && searchby !== 'all') {
        this.searchService.apiCompletedCount += 1;
        this.spinnerService.showLoader(false);
        this.searchService.showThiredPartyRefiner = true;
        this.isLoading = false;
        return { vendorCode: vendor_code, components: [] };
      }
      const [layoutData] = await this.thirdPartyService.getTierLayout(THIRD_PARTY_TIER.SEARCH_HOME, vendor_code, {
        q: encodeURIComponent(this.searchText),
        query: encodeURIComponent(this.searchText)
      });
      if (!layoutData?.layout) {
        this.searchService.apiCompletedCount += 1;
        this.spinnerService.showLoader(false);
        this.searchService.showThiredPartyRefiner = true;
        this.isLoading = false;
        return { vendorCode: vendor_code, components: [] };
      }
      this.searchService.apiCompletedCount += 1;
      const { vendor, layout } = layoutData;
      const { components } = layout;
      const { code } = vendor;
      this.layoutComponentsList = { vendorCode: code, components: components };
      this.spinnerService.showLoader(false);
      this.searchService.showThiredPartyRefiner = true;
      this.isLoading = false;
      return this.layoutComponentsList;
    } catch (error) {
      this.searchService.apiCompletedCount += 1;
      this.layoutComponents = [];
      this.isPressrdrLoad = false;
      this.spinnerService.showLoader(false);
      this.isLoading = false;
      if (vendor_code === PRESSRDR) {
        this.searchService.drupalServiceDownErrorMsg = PRESSREADERDRUPALDOWN;
      } else if (vendor_code === VIDEOS) {
        this.searchService.drupalServiceDownErrorMsg = VIDEOSDRUPALDOWN;
      } else if (vendor_code === VBOOKS) {
        this.searchService.drupalServiceDownErrorMsg = VBOOKSDRUPALDOWN;
      }
      return { vendorCode: vendor_code, components: [] };
    }
  }

  groupLayout(layoutData: ThirdPartyLayout) {
    const { components } = layoutData.layout;
    this.layoutComponent = components.find((comp) => comp.type === THIRD_PARTY_TYPES.ITEMS_GRID);
    this.refinersComponent = components.find((comp) => comp.type === THIRD_PARTY_TYPES.REFINERS);
    this.filtersCount = this.refinersComponent && getRefinerCount(this.refinersComponent.defaultValues || {});
  }

  async getLayout(vendorCode: string, isLoading = false) {
    isLoading && this.spinnerService.showLoader();
    this.searchService.drupalServiceDownErrorMsg = '';
    sessionStorage.setItem('defaultValues', undefined);
    this.isPressrdrLoad = true;
    this.layoutComponents = [];
    try {
      let thirdPartyBaseURL = {
        url: THIRD_PARTY_TIER.SEARCH_LIST,
        class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-common' }
      };
      if (vendorCode === PRESSRDR) {
        this.isPressrdrLoad = false;
        let pressReaderCategory = {
          [PRESS_READER_CATEGORY.ARTICLES]: THIRD_PARTY_TIER.SEARCH_LIST_ARTICLES,
          [PRESS_READER_CATEGORY.NEWSPAPER]: THIRD_PARTY_TIER.SEARCH_LIST_NEWSPAPER,
          [PRESS_READER_CATEGORY.MAGAZINE]: THIRD_PARTY_TIER.SEARCH_LIST_MAGAZINES,
        }
        thirdPartyBaseURL.url = pressReaderCategory[this.activeRouter.snapshot.queryParams.pageTitle];
        if (this.activeRouter.snapshot.queryParams.pageTitle === PRESS_READER_CATEGORY.ARTICLES) {
          thirdPartyBaseURL.class = { divClass: 'third-party-articles-container', cardsClass: 'third-party-articles' }
        }
      }
      const { searchText } = this.activeRouter.snapshot.queryParams;
      this.thirdPartyBaseGridClass = thirdPartyBaseURL.class;
      const [layoutData] = await this.thirdPartyService.getTierLayout(thirdPartyBaseURL.url, vendorCode, {
        q: searchText,
        query: searchText
      });
      if (!layoutData?.layout) {
        this.layoutComponents = [];
        this.isPressrdrLoad = false;
        this.layoutData = this.initialLayout;
        this.searchService.apiCompletedCount += 1;
        this.spinnerService.showLoader(false);
        this.searchService.showThiredPartyRefiner = true;
        this.isLoading = false;
        return { vendorCode: vendorCode, components: [] };
      }
      this.searchService.showThiredPartyRefiner = true;
      this.layoutData = layoutData;
      this.groupLayout(layoutData);
      this.isLoading = false;
      this.spinnerService.showLoader(false);
    } catch (e) {
      this.layoutData = this.initialLayout;
      this.isLoading = false;
      this.isPressrdrLoad = false;
      this.searchService.apiCompletedCount += 1;
      this.spinnerService.showLoader(false);
      if (vendorCode === PRESSRDR) {
        this.searchService.drupalServiceDownErrorMsg = PRESSREADERDRUPALDOWN;
      } else if (vendorCode === VIDEOS) {
        this.searchService.drupalServiceDownErrorMsg = VIDEOSDRUPALDOWN;
      } else if (vendorCode === VBOOKS) {
        this.searchService.drupalServiceDownErrorMsg = VBOOKSDRUPALDOWN;
      }
    }
  }

  /**
   * Get  WebPath Express based on the Search term and Filter Options and Refiners
   */

  getSitesData() {
    this.spinnerService.showLoader();
    this.searchService.apiCount += 1;
    const availableLimiters = sessionStorage.getItem('webresouse') !== 'undefined' ? JSON.parse(sessionStorage.getItem('webresouse')) : 'undefined';
    if (availableLimiters !== '') {
      for (let item of availableLimiters) {
        switch (item.title) {
          case 'Subject':
            this.studySites.subjectFilter = item.selectedFacets;
            break;
          case 'Format':
            this.studySites.formatFilter = item.selectedFacets;
            break;
          case 'Source':
            this.studySites.sourceFilter = item.selectedFacets;
            break;
          case 'Language':
            this.studySites.language = item.selectedFacets;
            break;
          case 'Grade Level':
            this.studySites.gradeMap = item.selectedFacets;
            break;
          case 'Domain':
            this.studySites.domainFilter = item.selectedFacets;
            break;
        }
      }
    }
    const req: GetSitesRequest = this.studySites;
    this.searchService.totalCountList.webPathExpress = 0;
    this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
    const { searchby } = this.activeRouter.snapshot.queryParams;
    if (searchby !== 'all') {
      this.searchService.apiCompletedCount += 1;
      this.spinnerService.showLoader(false);
      positionFooter(true);
      this.sitesTitlesInfo = [];
      this.initialFailed = true;
      this.totalSitesCount = 0;
      this.searchService.totalCountList.webPathExpress = 0;
      this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
      return;
    }

    this.searchService.getSitesResults(req).subscribe(
      (response) => {
        const { WPEDOC: wpeDoc } = response;
        if (!wpeDoc) {
          this.isLoading = false;
          this.spinnerService.showLoader(false);
          this.sitesTitlesInfo = [];
          this.totalSitesCount = 0;
          this.searchService.totalCountList.webPathExpress = 0;
          this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
          if (this.activeRouter.snapshot.params && this.activeRouter.snapshot.params.format === CATEGORY_TYPE.WEB_RESOURCES) {
            this.liveAnnouncer.announce(SITES_NO_RESULTS.split('<searchTerm>').join(this.searchText), 'assertive');
          }
          positionFooter(true);
          this.searchService.apiCompletedCount += 1;
          return;
        }
        const {
          WS_DETAIL_ENTRY: sitesInfo = [],
          PAGERECORDCOUNT: pageCount = [{ Count: 0 }],
          SEARCHRECORDCOUNT: searchCount = [{ Count: 0 }]
        } = wpeDoc;
        if (sitesInfo && sitesInfo.length > 0) {
          this.sitesInfo = this.sitesInfo.concat(sitesInfo?.filter((item) => item).map((siteInfo) => this.getSiteCard(siteInfo)));
        }
        this.totalCount.webPathExpress = +searchCount[0].Count;
        this.searchService.totalCountList.webPathExpress = +searchCount[0].Count;
        this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
        this.studySitesPageCount = pageCount[0].Count;
        this.searchService.totalCount = this.totalTilte(this.totalCount);
        positionFooter(true);
        this.updateFilters(wpeDoc);
        this.totalSitesCount = searchCount[0].Count;
        this.spinnerService.showLoader(false);
        this.sitesTitlesInfo = this.sitesInfo.slice(0, 10);
        if (this.searchService.isPillsEmptyTitleFoucs) {
          const title = this.sitesInfo[0].mainTitle;
          setTimeout(() => {
            const sitesTitleFoucs = document.getElementById(`title_${title}`);
            sitesTitleFoucs.focus();
          }, 1000);
          this.searchService.isPillsEmptyTitleFoucs = false;
        }
        this.searchService.apiCompletedCount += 1;
      },
      () => {
        this.searchService.apiCompletedCount += 1;
        this.spinnerService.showLoader(false);
        positionFooter(true);
        if (this.page === 0) {
          this.initialFailed = true;
          this.totalSitesCount = 0;
          this.liveAnnouncer.announce(DEFAULT_SNAKE_BAR_MSG, 'assertive');
          return;
        }
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SEARCH_FAILS_MSG);
      }
    );
  }
  /**
   * Get  WebPath Express based on the Search term and Filter Options and Refiners reused from StudySites
   */
  public getSiteCard(sites: Sites): TitleCardInfo {
    const {
      DESCRIPTION: description = '',
      DISPLAYURL: displayURL = '',
      TITLE: mainTitle = '',
      IMAGEDATA: image = { ID: '', URL: undefined, imageAlt: 'No Image' },
      HEADING: subjects = [],
      LEXILE_MEASURE: lexile = '',
      LANGUAGE: languages = [],
      GRADE_PREK_2 = '0',
      GRADE_3_5 = '0',
      GRADE_6_8 = '0',
      GRADE_9_12 = '0',
      REDIRECT_URL: redirectUrl = '',
      FORMAT_TYPE: formatType = '',
      SOURCE_TYPE: sourceType = ''
    } = sites;
    const { URL: imageSrc = SITES_DEFAULT_IMG } = image;
    const grades = [
      (GRADE_PREK_2 === '0' ? '' : GRADE_MAP.GRADE_PREK_2 + ',') +
      (GRADE_3_5 === '0' ? '' : GRADE_MAP.GRADE_3_5 + ',') +
      (GRADE_6_8 === '0' ? '' : GRADE_MAP.GRADE_6_8 + ',') +
      (GRADE_9_12 === '0' ? '' : GRADE_MAP.GRADE_9_12)
    ];
    const interestLevel = [grades[0].endsWith(',') ? grades[0].slice(0, -1) : grades[0]];
    const subjectInfo: SubjectInfo = {
      subjects,
      lexile,
      languages,
      interestLevel
    };
    const formatTypeIcon = FORMAT_TYPE[formatType];
    const sourceTypeIcon = SOURCE_TYPE[sourceType];
    return {
      mainTitle,
      displayURL,
      description,
      subjectInfo,
      imageSrc,
      imageAlt: '',
      redirectUrl,
      formatType,
      formatTypeIcon,
      sourceType,
      sourceTypeIcon
    };
  }
  updateFilters(wpeDoc: WPEDOC) {
    const {
      GRADEOPTION: gradeOption = [],
      DOMAINOPTION: domainOption = [],
      LANGUAGEOPTION: languageOption = [],
      FORMATTYPEOPTION: formatOptions = [],
      SUBJECTOPTION: subjectOption = [],
      SOURCEOPTION: sourceOption = []
    } = wpeDoc;
    const gradeFacets: Facet[] = gradeOption.map((option) => ({
      text: option.GRADEMAP,
      value: option.GRADEMAP,
      count: option.Count,
      selected: option.GRADEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.GRADE)
    }));
    const domainFacets: Facet[] = domainOption.map((option) => ({
      text: option.DOMAINMAP,
      value: option.DOMAINMAP,
      count: option.Count,
      selected: option.DOMAINMAP === this.getSelectedFilterFromStorage(SITES_FILTER.DOMAIN)
    }));
    const languageFacets: Facet[] = languageOption.map((option) => ({
      text: option.LANGUAGEMAP,
      value: option.LANGUAGEMAP,
      count: option.Count,
      selected: option.LANGUAGEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.LANGUAGE)
    }));
    const formatFacets: Facet[] = formatOptions.map((option) => ({
      text: option.FORMATTYPEMAP,
      value: option.FORMATTYPEMAP,
      count: option.Count,
      selected: option.FORMATTYPEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.FORMAT_TYPE)
    }));
    const subjectFacets: Facet[] = subjectOption.map((option) => ({
      text: option.SUBJECTMAP,
      value: option.SUBJECTMAP,
      count: option.Count,
      selected: option.SUBJECTMAP === this.getSelectedFilterFromStorage(SITES_FILTER.SUBJECT)
    }));
    const sourceFacets: Facet[] = sourceOption.map((option) => ({
      text: option.SOURCEMAP,
      value: option.SOURCEMAP,
      count: option.Count,
      selected: option.SOURCEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.SOURCE)
    }));
    this.availableLimiters = [
      {
        title: SITES_FILTER.SUBJECT,
        facets: subjectFacets,
        selectedFacets: this.getSelectedFilterTextFromStorage(SITES_FILTER.SUBJECT)
      },
      {
        title: SITES_FILTER.FORMAT_TYPE,
        facets: formatFacets,
        selectedFacets: this.getSelectedFilterTextFromStorage(SITES_FILTER.FORMAT_TYPE)
      },
      {
        title: SITES_FILTER.SOURCE,
        facets: sourceFacets,
        selectedFacets: this.getSelectedFilterTextFromStorage(SITES_FILTER.SOURCE)
      },
      {
        title: SITES_FILTER.GRADE,
        facets: gradeFacets,
        selectedFacets: this.getSelectedFilterTextFromStorage(SITES_FILTER.GRADE)
      },
      {
        title: SITES_FILTER.LANGUAGE,
        facets: languageFacets,
        selectedFacets: this.getSelectedFilterTextFromStorage(SITES_FILTER.LANGUAGE)
      },
      {
        title: SITES_FILTER.DOMAIN,
        facets: domainFacets,
        selectedFacets: this.getSelectedFilterTextFromStorage(SITES_FILTER.DOMAIN)
      }
    ].filter((limiter) => limiter.facets.length > 0);
    const availableLimiters = sessionStorage.getItem('webresouse') !== 'undefined' ? JSON.parse(sessionStorage.getItem('webresouse')) : 'undefined';
    this.availableLimiters = availableLimiters !== 'undefined' ? availableLimiters : this.availableLimiters;
    this.availableLimiters = this.arrangeInOrder(this.availableLimiters, SITES_REFINER_ORDER_LIST, true, 'title').filter(elem => elem !== undefined);
    this.storeRefiners();
    this.selectedWebResoure = this.availableLimiters.reduce((result, item) => {
      const key = item.title.toLowerCase();
      const selectedFacet = item.selectedFacets;
      selectedFacet !== '' && (result[key] = [selectedFacet]);
      return result;
    }, {});
    this.spinnerService.showLoader(false);
    this.appliedFilterCount = this.availableLimiters
      .map((x) => x.facets.reduce((n, e) => (e.selected === true ? n + 1 : n), 0))
      .reduce((num, item) => num + item, 0);
  }
  announceResult() {
    if (this.page === 0) {
      this.totalSitesCount === 0
        ? this.liveAnnouncer.announce(SITES_NO_RESULTS.split('<searchTerm>').join(this.searchText), 'assertive')
        : this.liveAnnouncer.announce(getResultsCount(this.sitesInfo.length, this.totalSitesCount, this.searchText), 'assertive');
    }
  }
  /**
   * appliedFilters on Webpath express stores the refiners in storage and
   * Refetch the studySites based on the refiners
   * reused as in studySites.
   */
  applyFilter = (applyFilter: { appliedFilter: AppliedFilter; filters: Filter[] }) => {
    this.page = 0;
    const limiterMap = {
      [SITES_FILTER.DOMAIN]: () => (this.studySites.domainFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.GRADE]: () => (this.studySites.gradeMap = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.LANGUAGE]: () => (this.studySites.language = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.SUBJECT]: () => (this.studySites.subjectFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.SOURCE]: () => (this.studySites.sourceFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.FORMAT_TYPE]: () => (this.studySites.formatFilter = applyFilter.appliedFilter.facets[0].value)
    };
    limiterMap[applyFilter.appliedFilter.limiter]();
    this.searchService.studySites.page = 1;
    this.studySites.page = 1;
    this.isAppliedFilter = true;
    this.availableLimiters = applyFilter.filters;
    this.appliedFilterCount = this.availableLimiters
      .map((x) => x.facets.reduce((n, e) => (e.selected === true ? n + 1 : n), 0))
      .reduce((num, item) => num + item, 0);
    this.storeRefiners();
  };
  /**
   * stores the filter changes of Webpath Express in the localStorage.
   */
  storeRefiners() {
    this.searchStorageService.setSitesRefiners({ filters: this.availableLimiters });
  }
  /**
   * Refetch the store filters of webpath Express and assign the selected filter to the
   * webpathExpress refiners.
   */
  getSelectedFilterFromStorage = (filterTitle: string): string => {
    const refiners = this.searchStorageService.getSitesRefiners();
    if (!refiners) {
      return '';
    }
    return (refiners.filters || []).find((limiter) => limiter.title === filterTitle)?.facets.find((facet) => facet.selected)?.value || '';
  };
  /**
   * Refetch the store filters of webpath Express and assign the selected filter to the
   * webpathExpress refiners.
   */
  getSelectedFilterTextFromStorage = (filterTitle: string): string => {
    const storedRefiner = this.searchStorageService.getSitesRefiners();
    if (!storedRefiner) {
      return '';
    }
    return (storedRefiner.filters || []).find((limiter) => limiter.title === filterTitle)?.selectedFacets || '';
  };

  /**
   * Totals tilte
   * @param obj
   * @returns
   */
  totalTilte(obj) {
    let sum = 0;
    for (const element in obj) {
      if (obj.hasOwnProperty(element)) {
        sum += parseFloat(obj[element]);
      }
    }
    if (this.thirdPartyCount?.length) {
      sum += this.thirdPartyCount.reduce((c, p) => {
        return c + p.realcount;
      }, 0);
    }
    return sum;
  }
  /**
   * Emitted count
   * @param event
   */
  emittedCount(event) {
    this.countList--;
    if (this.countList === 0) {
    }
    this.thirdPartyCount.push({ type: event.type, count: event.count || 0, realcount: event.realcount || 0 });
    this.searchService.totalCount = this.totalTilte(this.totalCount);
    const totalCountListTemp = deepClone(this.searchService.totalCountList);
    this.searchService.totalCountList = {};
    this.searchService.totalCountList = { ...totalCountListTemp, [event.type]: event.count || 0 };
    this.searchService.titleTotalCountList.next(this.searchService.totalCountList);
    this.searchService.apiCompletedCount += 1;
  }
  onResize() {
    this.showRefinerLayout = window.innerWidth < 767;
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('breadcrumb');
    sessionStorage.removeItem('applied-refiners');
    sessionStorage.setItem('webresouse', undefined);
    this.searchService.totalCount = 0;
    this.searchService.scrollPostion = window.scrollY;
    cdkRemoveVisualHidden();
  }
  skipRefiners() {
    const searchContainer = document.getElementById('searchResultContainer');
    searchContainer?.focus();
  }
}
