<div class="alwaysavailable_budget_message titledetails-message" id="ppcMessage_home" aria-labelledby="Your library has reached its daily check out limit for the Always Available collection.Please check again later">
    <span class="ico infoIcon-color">
        <svg id="info-icon-solid-blue" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="path2" d="M0,0H24V24H0Z" fill="none" />
            <g id="layer1" transform="translate(0.172 -282.535)">
                <ellipse id="path815" cx="10.943" cy="9.684" rx="10.943" ry="9.684" transform="translate(1.088 284.291)" fill="#4d6ed9" stroke="#000" stroke-miterlimit="25.4" stroke-width="0" />
                <text id="text819" transform="translate(8.706 300.963)" fill="#fff" font-size="18" font-family="Georgia-Bold, Georgia" font-weight="700"><tspan x="0" y="0">i</tspan></text>
            </g>
        </svg>
    </span>
    The library has reached its daily checkout limit. Please add items to your Wishlist and return later to check out.
</div> 