import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  MY_LIBRARY_CATEGORY,
  MY_LIBRARY_EVENTS,
  MY_LIBRARY_EVENTS_KEY,
  MY_LIBRARY_SCREENS,
  MY_LIBRARY_SCREENS_KEY
} from '../constants/my-library.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof MY_LIBRARY_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class MyLibraryAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of notifications analytics service.
   */
  constructor() {
    super(MY_LIBRARY_SCREENS, MY_LIBRARY_EVENTS);
  }
  /**
   * To track programs page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof MY_LIBRARY_SCREENS_KEY, altObj: AltObj = {}) {
    this.trackPage(screen, altObj);
  }
  /**
   * To track programs event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyLibraryEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_LIBRARY_CATEGORY.BOOK_OF_MONTH, action, altObj);
  }
  /**
   * To track myshelf page - Goals and insights events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackCarouselEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_LIBRARY_CATEGORY.LEARNING_ACTIVITIES, action, altObj);
  }
  /**
   * To track programs event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyLibraryAnnouncementEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_LIBRARY_CATEGORY.ANNOUNCEMENTS, action, altObj);
  }
  /**
   * To track programs event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyLibraryLearningViewInterest(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_LIBRARY_CATEGORY.LEARNING_ACTIVITIES, action, altObj);
  }
  /**
   * To track featured Reading programs event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyLibraryFeaturedReadingProgram(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_LIBRARY_CATEGORY.LEARNING_ACTIVITIES, action, altObj);
  }
  /**
   * To track featured filter changes event 
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyLibraryFilterEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_LIBRARY_CATEGORY.FILTERS, action, altObj);
  }
}
