<div class="kz-breadcrumb-sec">
    <axis360-breadcrumb [breadcrumbs]="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'">
    </axis360-breadcrumb>
</div>
<axis360-spinner-loader [isLoading]="true" *ngIf="isInitialLoad"></axis360-spinner-loader>
<p class="kz-visually-hidden"> Search result will update automatically when user selects any refiner</p>
<button class="skip-refiners skip-refiners-format" id="loc_btnSkiptoMain" (click)="skipRefiner()"(keydown.enter)="skipRefiner()">
    Skip the Refiners
</button>
<div class="kz-learning-activity-container" *ngIf="!isInitialLoad">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isResourceHubPage" aria-live="polite" id="isResourceHubPage">{{pageAnnounce}}</p>
    <div class="learn-content mb-3">
        <div class="refiner-sec">
            <div class="col-3 la-refines sticky-refiner">
                <axis360-search-refiners [isLearningActivity]="true" [showAccordion]="false" [learningActivityRefiners]="refiners"
                    (selectedRefiners)="updateSelectedOption($event)" (clearFilterEmitter)="resetLearningActivityRefiners($event)">
                </axis360-search-refiners>
            </div>
        </div>
        <div class="col-9 la-learning-cards">
            <div class="learning-title">
                <div id="loc_resource_hub">
                    <h1 class="heading-1">
                        <span role="alert"  class="kz-visually-hidden" id="searchResultsResouceHub">Resource Hub ({{totalCount || 0}} results)</span>
                        <span class="result-text mt-1 d-block"  aria-hidden="true">Resource Hub</span>
                        <span class="result-count mt-1 d-block"  aria-hidden="true">({{totalCount || 0}} results)</span>
                    </h1>

                </div>
                <div class="search-view-container" (window:resize)="closeFilter()">
                    <button class="filter btn-nobg-bluecolor" (click)="openFilter()">
                        <mat-icon svgIcon="kz-filter-icon"
                            attr.aria-label="filter menu count of {{appliedFilterCount>9?'9+':appliedFilterCount}}"
                            aria-hidden="false"></mat-icon>
                        <span aria-hidden="true"
                            class="count d-flex">{{appliedFilterCount>9?'9+':appliedFilterCount}}</span>
                    </button>
                </div>
            </div>
            <axis360-navigation-tabs class="nav-library" [centerTab]="centerTab" [multiTabsList]="selectedOptions"
                [isLearningActivity]="true" (removeSelection)="removeSelection($event)" (isEmptyPill)="isEmptyPill($event)">
            </axis360-navigation-tabs>
            <div class="no-stuffs" *ngIf="!isLoading && !learningActivities.length"><span> We could not find results for your search. Please try another search.</span>
            </div>
            <div class="kz-learning-cards mt-1" id="resourceHubContainer" *ngIf="learningActivities.length" tabindex="-1">
                <axis360-kz-activity-resources-card (selectedRefiners)="updateSelectedOption($event,true)"
                    *ngFor="let item of learningActivities" [activity]="item">
                </axis360-kz-activity-resources-card>
            </div>
            <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
        </div>
    </div>
</div>