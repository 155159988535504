<section class="pressreader-list">
    <axis360-breadcrumb class="pressreader-list-breadcrumb" [breadcrumbs]="breadcrumbs"
        [ngClass]="{'hide-refiner': (publicationType === PUBLICATION_TYPES.LATEST_ISSUES || publicationType === PUBLICATION_TYPES.SUPPLEMENT)}" *ngIf="breadcrumbs"></axis360-breadcrumb>
    <axis360-title-and-refiners [showSort]="true" [hideText]="true" [titleText]="publicationTitle"
        [hideRefiners]="publicationType === PUBLICATION_TYPES.LATEST_ISSUES || publicationType === PUBLICATION_TYPES.SUPPLEMENT" 
        [availableLimiters]="availableLimiters" [sortOptions]="sortOptions" (applySortEvnt)="sortOptionSelected($event)"
        (applyFilterEvnt)="applyFilter($event)" (clearFilterEvnt)="clearFilters($event)" (openRefinerDialogEvent)="openRefinerDialog($event)"></axis360-title-and-refiners>  
    <ng-container *ngIf="!isLoading && publications.length === 0">
        
        <div class="no-results-found">
            <p class="no-results-title">No Results Found</p>
            <p>for "{{publicationTitle}}"</p>
        </div>
    </ng-container>
    <ng-container *ngIf="publications.length > 0">
        <div class="press-reader-card-block">
            <ng-container *ngFor="let publication of publications">
                <axis360-press-reader-card class="card-item" [type]="publicationType" [title]="publicationTitle" [publicationCard]="publication"></axis360-press-reader-card>
            </ng-container>
        </div> 
    </ng-container>
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
</section>