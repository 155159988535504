import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[axis360MatSelectAriaExpanded]'
})
export class MatSelectAriaExpandedDirective implements AfterViewInit {
  constructor(public select: MatSelect, public elem: ElementRef, private overlayContainer: OverlayContainer) {}
  /**
   * To add and remove aria-expanded after view init
   */
  ngAfterViewInit() {
    const selectElem = this.elem.nativeElement;
    const matLabel = selectElem.getAttribute('aria-labelledby');
    const matSelectOverlay = this.overlayContainer.getContainerElement().classList;
    selectElem.querySelector('.mat-select-trigger').setAttribute('aria-hidden', 'false');
    selectElem.setAttribute('aria-expanded', 'false');
    selectElem.removeAttribute('aria-multiselectable');
    const labelElm = selectElem.querySelector('.mat-form-field-label');
    labelElm?.removeAttribute('for');
    this.select.openedChange.subscribe((isOpen: boolean) => {
      if (isOpen) {
        const matSelectPanel = document.querySelector('.mat-select-panel');
        const matSelectPanelId = matSelectPanel.id;
        const matOptions = this.select.options.toArray();
        matSelectOverlay.add('kz-mat-select-overlay');
        selectElem.setAttribute('aria-controls', matSelectPanelId);
        selectElem.setAttribute('aria-expanded', 'true');
        matSelectPanel.setAttribute('role', 'listbox');
        matSelectPanel.setAttribute('aria-labelledby', matLabel);
        matSelectPanel.setAttribute('cdkTrapFocusAutoCapture', 'true');
        matSelectPanel.setAttribute('tabindex', '-1');
        const currentIndex = matOptions.findIndex(option => option.active);
        matOptions[0]._getHostElement().setAttribute('cdkFocusRegionStart', 'true');
        matOptions[currentIndex]._getHostElement().setAttribute('cdkFocusInitial', 'true');
        matOptions[matOptions.length - 1]._getHostElement().setAttribute('cdkFocusRegionEnd', 'true');
        matOptions[currentIndex].focus();
        matSelectPanel.addEventListener('keydown', (event: KeyboardEvent) => {
          const activeIndex = matOptions.findIndex(option => option.active);
          if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            matOptions[activeIndex].focus();
          } else if (event.key === 'Escape') {
            selectElem.focus();
          }
        });
        document.querySelectorAll('.mat-option').forEach(n => {
          if (!n.classList.value.includes('mat-selected')) {
            n.setAttribute('aria-selected', 'false')
          }
        });
        matOptions.forEach((option: MatOption, i: number) => {
          const value = option.viewValue.replace(/eAudio/g, "e Audio")
          .replace(/eBook/g, "e Book")
          .replace(/vBook/g, "v Book");
          matOptions[i]._getHostElement().setAttribute('aria-label', value);
        })
        return;
      }
      selectElem.setAttribute('aria-expanded', 'false');
      matSelectOverlay.remove('kz-mat-select-overlay');
    });
  }
}
