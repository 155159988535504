import { ListContent } from '../config.model';
import { MyLibraryList } from '../list-base.model';

export const LIST_CONTENT_MOCK: ListContent = {
  listName: '',
  TotalItems: 13,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: [
    {
      ISBN: '9780545675765',
      ItemId: '0028794309',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Amulet 1',
      Title: 'Amulet 1',
      Author: 'Kibuishi, Kazu ; Kibuishi, Kazu (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761362258',
      ItemId: '0009241606',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'And Then There Were Gnomes',
      Title: 'And Then There Were Gnomes',
      Author: 'Venable, Colleen AF ; Yue, Stephanie (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 3,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781682558225',
      ItemId: '0026624013',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Archie',
      Title: 'Archie',
      Author: 'Waid, Mark ; Augustyn, Brian ; Krause, Peter (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 2,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781602704152',
      ItemId: '0009130899',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Around the World in 80 Days',
      Title: 'Around the World in 80 Days',
      Author: 'Verne, Jules ; Espinosa, Rod (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 4,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761359159',
      ItemId: '0009203068',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Attack of the Mutant Meteors',
      Title: 'Attack of the Mutant Meteors',
      Author: 'Jolley, Dan ; Witt, David (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781250245458',
      ItemId: '0024689976',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Bake Like a Pro!',
      Title: 'Bake Like a Pro!',
      Author: 'Koch, Falynn ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781476796642',
      ItemId: '0022964106',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Belonging',
      Title: 'Belonging',
      Author: 'Krug, Nora ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: false,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 1,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781250753960',
      ItemId: '0028200812',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Best Friends',
      Title: 'Best Friends',
      Author: 'Hale, Shannon ; Pham, Leuyen (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781449469818',
      ItemId: '0015910967',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Big Nate Say Good-bye to Dork City',
      Title: 'Big Nate Say Good-bye to Dork City',
      Author: 'Peirce, Lincoln ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781449460884',
      ItemId: '0014846753',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Big Nate the Crowd Goes Wild!',
      Title: 'Big Nate the Crowd Goes Wild!',
      Author: 'Peirce, Lincoln ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781551525136',
      ItemId: '0013657534',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Blue Is the Warmest Color',
      Title: 'Blue Is the Warmest Color',
      Author: 'Maroh, Julie ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 16,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Bo Obama',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    }
  ]
};

export const LIST_ACTION_CTA_V1 = [
  {
    itemId: '0028794309',
    isbn: '9780545675765',
    displayStatus: '',
    holdPosition: ''
  },
  {
    itemId: '0012276795',
    isbn: '9780545675890',
    displayStatus: '',
    holdPosition: ''
  }
];

export const LIST_ACTION_CTA_V2_ONE = [
  {
    state: 0,
    displayStatus: "Past Checkout",
    estimatedWaitTime: null,
    holdPosition: null,
    numOfPatronsOnHold: null,
    consortiaType: "MEMBER",
    ppcBudgetReached: false,
    actions: [
      {
        actionButtonId: "btnCheckoutNow",
        ISBN: "9780545675765",
        itemId: "0028794309",
        buyNowUrl: null,
        reservationId: null,
        formatType: "EBT",
        action: 0,
        enabled: true
      },
      {
        actionButtonId: "btnRemoveWishlist",
        itemId: "0028794309",
        isbn: null,
        buyNowUrl: null,
        reservationId: null,
        formatType: "EBT",
        action: 14,
        enabled: true
      }
    ],
    checkoutDueDate: null,
    checkoutDueDateinHrs: 0,
    reserveDuration: null
  }
];

export const LIST_ACTION_CTA_V2_TWO = [
  {
    state: 0,
    displayStatus: "Past Checkout",
    estimatedWaitTime: null,
    holdPosition: null,
    numOfPatronsOnHold: null,
    consortiaType: "MEMBER",
    ppcBudgetReached: false,
    actions: [
      {
        actionButtonId: "btnCheckoutNow",
        ISBN: "9780545675765",
        itemId: "0028794309",
        buyNowUrl: null,
        reservationId: null,
        formatType: "EBT",
        action: 0,
        enabled: true
      },
      {
        actionButtonId: "btnRemoveWishlist",
        itemId: "0028794309",
        isbn: null,
        buyNowUrl: null,
        reservationId: null,
        formatType: "EBT",
        action: 14,
        enabled: true
      }
    ],
    checkoutDueDate: null,
    checkoutDueDateinHrs: 0,
    reserveDuration: null
  }
];
export const LIST_CONTENT_MOCK_WITH_MORE_ITEM: ListContent = {
  listName: '',
  TotalItems: 13,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: [
    {
      ISBN: '9780545675765',
      ItemId: '0028794309',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Amulet 1',
      Author: 'Kibuishi, Kazu ; Kibuishi, Kazu (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780545675765',
      ItemId: '0012276795',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Amulet 1',
      Author: 'Kibuishi, Kazu ; Kibuishi, Kazu (ILT)',
      Narrator: null,
      FormatType: 'VID',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761362258',
      ItemId: '0012276794',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'And Then There Were Gnomes',
      Author: 'Venable, Colleen AF ; Yue, Stephanie (ILT)',
      Narrator: null,
      FormatType: 'VBK',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 3,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761362258',
      ItemId: '0009241606',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'And Then There Were Gnomes',
      Author: 'Venable, Colleen AF ; Yue, Stephanie (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 3,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781682558225',
      ItemId: '0026624013',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Archie',
      Author: 'Waid, Mark ; Augustyn, Brian ; Krause, Peter (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 2,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781602704152',
      ItemId: '0009130899',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Around the World in 80 Days',
      Author: 'Verne, Jules ; Espinosa, Rod (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 4,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761359159',
      ItemId: '0009203068',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Attack of the Mutant Meteors',
      Author: 'Jolley, Dan ; Witt, David (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781250245458',
      ItemId: '0024689976',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bake Like a Pro!',
      Author: 'Koch, Falynn ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781476796642',
      ItemId: '0022964106',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Belonging',
      Author: 'Krug, Nora ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: false,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 1,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781250753960',
      ItemId: '0028200812',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Best Friends',
      Author: 'Hale, Shannon ; Pham, Leuyen (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781449469818',
      ItemId: '0015910967',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Big Nate Say Good-bye to Dork City',
      Author: 'Peirce, Lincoln ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781449460884',
      ItemId: '0014846753',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Big Nate the Crowd Goes Wild!',
      Author: 'Peirce, Lincoln ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781551525136',
      ItemId: '0013657534',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Blue Is the Warmest Color',
      Author: 'Maroh, Julie ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 16,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    }
  ]
};


export const FEATURED_LIST_MOCK_002 = [
  {
    ListID: 'checkout',
    ListName: 'Currently Reading',
    Description: null,
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: 'Collections',
    ListName: 'Collections',
    Description: null,
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: 'Recommended_for_you',
    ListName: 'Because you checked out "Fantastic Flying Books of Mr. Morris Lessmore"',
    Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: 'Based_your_Interests',
    ListName: 'Based on your Interests',
    Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24353',
    ListName: 'Curated - Titles List Check In Kidszone library',
    Description: ' KidsZOne Titles list Powered by Axis360 Updated description ',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24108',
    ListName: 'Teens',
    Description: 'Collection of books for Teens',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24742',
    ListName: 'Sep21 list',
    Description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24757',
    ListName: 'Demo curated',
    Description: 'Demo curated list.......... Demo....... Demo.....',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24740',
    ListName: 'NewYork Teen',
    Description: 'Kidszone new york teen',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24602',
    ListName: 'ebook',
    Description: 'The New York Times Best Seller lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.\r\n',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24100',
    ListName: 'Fiction',
    Description: '',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24094',
    ListName: 'Non-Fiction',
    Description: '',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24098',
    ListName: 'Children',
    Description: '',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  },
  {
    ListID: '24760',
    ListName: 'QA2 New list',
    Description: 'Description for qa2 new list',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: ""
  }
];

export const myLibraryListMock: MyLibraryList[] = [
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '72984',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: '12345',
    ListName: 'Comic & Graphic Novels',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  }
];

export const RECOMMENDATION_LIST: MyLibraryList[] = [
  {
    ListID: 'Recommended_for_you',
    ListName: 'Recommended for you',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  },
  {
    ListID: 'Based_your_Interests',
    ListName: 'Based your Interests',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  }
];

export const CHECKOUT_LIST: MyLibraryList[] = [
  {
    ListID: 'checkout',
    ListName: 'Currently Reading',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  }
];

export const ASSIGNMENT_LIST: MyLibraryList[] = [
  {
    ListID: 'Assignment',
    ListName: 'Assignment',
    Position: 0,
    Limit: 0,
    PageNumber: 0,
    SortOrder: 0,
    SortField: null,
    Term: null,
    SearchBy: null,
    Subject: null,
    IncludeRecommendable: false,
    IncludeAlwaysAvailable: false,
    listDataLoad: true,
    Available: null,
    Format: null,
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    ProfileType: ""
  }
];

export const actionButtonMock = {
  state: 6,
  displayStatus: 'COMING SOON',
  estimatedWaitTime: null,
  holdPosition: null,
  numOfPatronsOnHold: null,
  consortiaType: 'MEMBER',
  ppcBudgetReached: false,
  actions: [
    {
      actionButtonId: "btnCheckoutNow",
      itemId: "0099488317",
      isbn: null,
      buyNowUrl: null,
      reservationId: null,
      formatType: "VBK",
      action: 0,
      enabled: true
    },
    {
      actionButtonId: "btnAccessinApp",
      itemId: "0099488317",
      isbn: null,
      buyNowUrl: null,
      reservationId: null,
      formatType: "VBK",
      action: 0,
      enabled: true
    },
    {
      actionButtonId: 'btnAddHold',
      itemId: '0012275588',
      isbn: null,
      buyNowUrl: null,
      reservationId: null,
      formatType: 'EBT',
      action: 8,
      enabled: true
    },
    {
      actionButtonId: 'btnAddWishlist',
      itemId: '0012275588',
      isbn: null,
      buyNowUrl: null,
      reservationId: null,
      formatType: 'EBT',
      action: 13,
      enabled: true
    },
    {
      actionButtonId: 'btnBuyNow',
      itemId: '0012275588',
      isbn: null,
      buyNowUrl:
        'http://www.barnesandnoble.com/s/9780767999427-?store=allproducts&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;keyword=9780767999427',
      reservationId: null,
      formatType: 'EBT',
      action: 15,
      enabled: true
    }
  ],
  checkoutDueDate: null,
  checkoutDueDateinHrs: 0
};
export const ReadingProgressMock = {
  bookProgress: [
    {
      bookId: '0012276795',
      progressPercentage: 2.5
    },
    {
      bookId: '0012276794',
      progressPercentage: 2.5
    },
    {
      bookId: '0012276793',
      progressPercentage: 2.5
    }
  ]
};

export const listContentMock = {
  listName: 'test',
  TotalItems: 1,
  PageNum: 0,
  PageSize: 0,
  SortField: null,
  SortOrder: 0,
  Filter: null,
  Items: [
    {
      ISBN: '9780307461889',
      ItemId: '0012276795',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5,
      titleStateInfo: {
        displayStatus: 'Due In 20 Hour(s)'
      }
    },
    {
      ISBN: '9780307461889',
      ItemId: '0012276710',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5
    },
    {
      ISBN: '9780307461889',
      ItemId: '0012276794',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'VID',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5
    },
    {
      ISBN: '9780307461889',
      ItemId: '0012276793',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'VBK',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5
    }
  ]
};

export const listContentMockNonEbookAudio = {
  listName: 'test',
  TotalItems: 1,
  PageNum: 0,
  PageSize: 0,
  SortField: null,
  SortOrder: 0,
  Filter: null,
  Items: [
    {
      ISBN: '9780307461889',
      ItemId: '0012276794',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'VID',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5
    },
    {
      ISBN: '9780307461889',
      ItemId: '0012276793',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'VBK',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5
    }
  ]
};

export const listContentMockNonThirdParty = {
  listName: 'test',
  TotalItems: 1,
  PageNum: 0,
  PageSize: 0,
  SortField: null,
  SortOrder: 0,
  Filter: null,
  Items: [
    {
      ISBN: '9780307461889',
      ItemId: '0012276795',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5,
      titleStateInfo: {
        displayStatus: 'Due In 20 Hour(s)'
      }
    },
    {
      ISBN: '9780307461889',
      ItemId: '0012276710',
      LibraryId: null,
      ListId: null,
      Title: ' Payback Time',
      Author: 'Town, Phil',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      listName: null,
      percentageComplete: 5
    }
  ]
};
export const BookDetailsMock = {
  bookIdList: ['0012428769', '0012449072', '0012461787']
};

export const FEATURED_LIST_COMPONENT_WITH_ENTITYID_MOCK = {
  statusCode: 200,
  component: {
    id: null,
    type: 'CAROUSEL',
    sequence: 4,
    title: null,
    description: null,
    entityId: '24602',
    entityType: 'FEATURED_LIST',
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: {
      url: null,
      headers: null,
      queryParams: null
    },
    jsonTempl: null,
    components: []
  }
};

export const FEATURED_LIST_COMPONENT_WITHOUT_ENTITYID_MOCK = {
  statusCode: 200,
  component: {
    id: null,
    type: 'CAROUSEL',
    sequence: 4,
    title: null,
    description: null,
    entityId: '',
    entityType: 'FEATURED_LIST',
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: {
      url: null,
      headers: null,
      queryParams: null
    },
    jsonTempl: null,
    components: []
  }
};

export const FEATURED_LIST_MOCK = {
  TotalItems: 3,
  PageNum: 1,
  PageSize: 10,
  SortField: 'AddedDate',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'ABT',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL',
    AxisAttribute: null,
    ProfileType: 0
  },
  Items: [
    {
      ISBN: '9780449808115',
      ItemId: '0012407440',
      LibraryId: 'E8862F37-CB93-EC11-A507-0003FFFF6A11',
      ListId: '24100',
      Title: 'Art Forger',
      Author: 'Shapiro, B. A. ',
      Narrator: null,
      FormatType: 'ABT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Fiction',
      SortField: 'AddedDate',
      CollectionType: 'Default',
      Edition: null,
      Length: null,
      AxisAttribute: [''],
      RunTime: '12H 53M',
      FileSize: 0
    },
    {
      ISBN: '9781518918674',
      ItemId: '0012477577',
      LibraryId: 'E8862F37-CB93-EC11-A507-0003FFFF6A11',
      ListId: '24100',
      Title: 'Book of Tobit the Amateur Mendicant Society',
      Author: 'Meiser, Edith ; Doyle, Arthur Conan, Sir ',
      Narrator: null,
      FormatType: 'ABT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Fiction',
      SortField: 'AddedDate',
      CollectionType: 'Default',
      Edition: null,
      Length: null,
      AxisAttribute: [''],
      RunTime: ' 52 MIN',
      FileSize: 0
    },
    {
      ISBN: '9781662107016',
      ItemId: '0012477644',
      LibraryId: 'E8862F37-CB93-EC11-A507-0003FFFF6A11',
      ListId: '24100',
      Title: 'June Bug for Kids',
      Author: 'Hill, Jason ',
      Narrator: null,
      FormatType: 'ABT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Fiction',
      SortField: 'AddedDate',
      CollectionType: 'Default',
      Edition: null,
      Length: null,
      AxisAttribute: [''],
      RunTime: ' 17 MIN',
      FileSize: 0
    }
  ]
};

export const LIST_CONTENT_MOCK_FOR_MYSHELF: ListContent = {
  listName: '',
  TotalItems: 13,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: [
    {
      ISBN: '9780761359159',
      ItemId: '0009203068',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Attack of the Mutant Meteors',
      Author: 'Jolley, Dan ; Witt, David (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781250245458',
      ItemId: '0024689976',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bake Like a Pro!',
      Author: 'Koch, Falynn ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781476796642',
      ItemId: '0022964106',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Belonging',
      Author: 'Krug, Nora ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: false,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 1,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781250753960',
      ItemId: '0028200812',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Best Friends',
      Author: 'Hale, Shannon ; Pham, Leuyen (ILT)',
      Narrator: null,
      FormatType: 'VID',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781449469818',
      ItemId: '0015910967',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Big Nate Say Good-bye to Dork City',
      Author: 'Peirce, Lincoln ',
      Narrator: null,
      FormatType: 'VBK',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781449460884',
      ItemId: '0014846753',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Big Nate the Crowd Goes Wild!',
      Author: 'Peirce, Lincoln ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781551525136',
      ItemId: '0013657534',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Blue Is the Warmest Color',
      Author: 'Maroh, Julie ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 16,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781123948226',
      ItemId: '0018310263',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bo Obama',
      Author: 'Salamoff, Paul J. ; Tucker, Keith (CON)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    }
  ]
};

export const LIST_CONTENT_MOCK_CHECKER: ListContent = {
  listName: '',
  TotalItems: 13,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: [
    {
      ISBN: '9780761359100',
      ItemId: '00092080033',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Attack of the Mutant Meteors',
      Author: 'Jolley, Dan ; Witt, David (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761359130',
      ItemId: '0024689800',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      Title: 'Bake Like a Pro!',
      Author: 'Koch, Falynn ',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: false,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 0,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    }
  ]
};
export const EMPTY_LIST_CONTENT_MOCK = {
  listName: '',
  TotalItems: 0,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: []
}

export const FILTER_ALL_DATA = {
  selectedSubject: "",
  selectedCategory: "",
  menuType: "subject",
  staffPickId: "",
  categoryId: "",
  refiners: [
    {
      text: "Sort By",
      value: "sortby",
      items: [
        {
          myParent: "sortby",
          text: "Return date",
          value: "Returndate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbReturnDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Popularity",
          value: "Score",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPopularity",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Publication Date",
          value: "PublishDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPublicationDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Added Date",
          value: "AddedDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAddedDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Title",
          value: "Title",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitle",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Author",
          value: "Author",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAuthor",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Relevancy",
          value: "Relevancy",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbRelevancy",
          isSelected: true
        }
      ],
      selected: "Relevancy",
      selectedText: "Relevancy",
      defaultSelected: false
    },
    {
      text: "Availability",
      value: "availability",
      items: [
        {
          myParent: "availability",
          text: "All Titles",
          value: "All",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllTitles",
          isSelected: true
        },
        {
          myParent: "availability",
          text: "Available Now",
          value: "Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAvailableNow",
          isSelected: false
        }
      ],
      selected: "All",
      selectedText: "All Titles",
      defaultSelected: false
    },
    {
      text: "Age Level",
      value: "agelevel",
      items: [
        {
          myParent: "agelevel",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAgeLevelAll",
          isSelected: true
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 7-9, Age 12-14",
          value: "Teen - Grade 7-9, Age 12-14",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade7-9,Age12-14",
          isSelected: false
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 10-12, Age 15-18",
          value: "Teen - Grade 10-12, Age 15-18",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade10-12,Age15-18",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Release Date",
      value: "releasedate",
      items: [
        {
          myParent: "releasedate",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllMonths",
          isSelected: true
        },
        {
          myParent: "releasedate",
          text: "Last 6 months",
          value: "6",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast6Months",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Last year",
          value: "12",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLastYear",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Over a year",
          value: "1",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbOver1Year",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Recently Added",
      value: "recentlyadded",
      items: [
        {
          myParent: "recentlyadded",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllDays",
          isSelected: true
        },
        {
          myParent: "recentlyadded",
          text: "Last 30 days",
          value: "30",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast30Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 60 days",
          value: "60",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast60Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 90 days",
          value: "90",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast90Days",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Language",
      value: "language",
      items: [
        {
          myParent: "language",
          text: "All",
          value: "",
          controlType: "radio",
          defaultSelected: true,
          id: "rdbLanguageAll",
          isSelected: true
        },
        {
          myParent: "language",
          text: "English",
          value: "ENGLISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbENGLISH",
          isSelected: false
        },
        {
          myParent: "language",
          text: "Spanish",
          value: "SPANISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbSPANISH",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    }
  ],
  categoryList: [
    {
      text: "ACTION & ADVENTURE",
      value: "Action & Adventure",
      url: "/Search?term=YAF000000,YAN000000/Action %26 Adventure&searchBy=subject",
      code: "YAF001000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "ADVENTURE & ADVENTURERS",
      value: "Adventure & Adventurers",
      url: "/Search?term=YAF000000,YAN000000/Adventure %26 Adventurers&searchBy=subject",
      code: "YAN002000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LGBTQ+",
      value: "LGBTQ+",
      url: "/Search?term=YAF000000,YAN000000/LGBTQ+&searchBy=subject",
      code: "YAF031000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LIFESTYLES / FARM & RANCH LIFE",
      value: "Lifestyles / Farm & Ranch Life",
      url: "/Search?term=YAF000000,YAN000000/Lifestyles / Farm %26 Ranch Life&searchBy=subject",
      code: "YAF034000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LITERARY CRITICISM & COLLECTIONS",
      value: "Literary Criticism & Collections",
      url: "/Search?term=YAF000000,YAN000000/Literary Criticism %26 Collections&searchBy=subject",
      code: "YAN033000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "VISIONARY & METAPHYSICAL",
      value: "Visionary & Metaphysical",
      url: "/Search?term=YAF000000,YAN000000/Visionary %26 Metaphysical&searchBy=subject",
      code: "YAF066000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT FICTION&searchBy=subject",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT NONFICTION&searchBy=subject",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  subjectList: [
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Browse?subject=YOUNG ADULT FICTION",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Browse?subject=YOUNG ADULT NONFICTION",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  featuredList: [],
  isLoadingCategory: false,
  isLoadingSubject: false,
  isLoadingRefiner: false,
  selected: "",
  searchCategory: "",
  isEnableAgeLevel: true,
  selectedRefine: true
};

export const FILTER_ALL_DATA_MOCK = {
  selectedSubject: "",
  selectedCategory: "",
  menuType: "subject",
  staffPickId: "",
  categoryId: "",
  refiners: [
    {
      text: "Sort By",
      value: "sortby",
      items: [
        {
          myParent: "sortby",
          text: "Return date",
          value: "Returndate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbReturnDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Popularity",
          value: "Score",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPopularity",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Publication Date",
          value: "PublishDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPublicationDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Added Date",
          value: "AddedDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAddedDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Title",
          value: "Title",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitle",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Author",
          value: "Author",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAuthor",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Relevancy",
          value: "Relevancy",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbRelevancy",
          isSelected: true
        }
      ],
      selected: "Relevancy",
      selectedText: "Relevancy",
      defaultSelected: false
    },
    {
      text: "Availability",
      value: "availability",
      items: [
        {
          myParent: "availability",
          text: "All Titles",
          value: "All",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllTitles",
          isSelected: true
        },
        {
          myParent: "availability",
          text: "Available Now",
          value: "Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAvailableNow",
          isSelected: false
        }
      ],
      selected: "All",
      selectedText: "All Titles",
      defaultSelected: false
    },
    {
      text: "Age Level",
      value: "agelevel",
      items: [
        {
          myParent: "agelevel",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAgeLevelAll",
          isSelected: true
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 7-9, Age 12-14",
          value: "Teen - Grade 7-9, Age 12-14",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade7-9,Age12-14",
          isSelected: false
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 10-12, Age 15-18",
          value: "Teen - Grade 10-12, Age 15-18",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade10-12,Age15-18",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Release Date",
      value: "releasedate",
      items: [
        {
          myParent: "releasedate",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllMonths",
          isSelected: true
        },
        {
          myParent: "releasedate",
          text: "Last 6 months",
          value: "6",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast6Months",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Last year",
          value: "12",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLastYear",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Over a year",
          value: "1",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbOver1Year",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Recently Added",
      value: "recentlyadded",
      items: [
        {
          myParent: "recentlyadded",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllDays",
          isSelected: true
        },
        {
          myParent: "recentlyadded",
          text: "Last 30 days",
          value: "30",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast30Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 60 days",
          value: "60",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast60Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 90 days",
          value: "90",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast90Days",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Language",
      value: "language",
      items: [
        {
          myParent: "language",
          text: "All",
          value: "",
          controlType: "radio",
          defaultSelected: true,
          id: "rdbLanguageAll",
          isSelected: true
        },
        {
          myParent: "language",
          text: "English",
          value: "ENGLISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbENGLISH",
          isSelected: false
        },
        {
          myParent: "language",
          text: "Spanish",
          value: "SPANISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbSPANISH",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Collections",
      value: "collections",
      items: [
        {
          myParent: "collections",
          text: "General & Always Available",
          value: "General & Always Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbGeneralAlwaysAvailable",
          isSelected: false
        },
        {
          myParent: "collections",
          text: "General",
          value: "General",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbGeneral",
          isSelected: false
        },
        {
          myParent: "collections",
          text: "Always Available",
          value: "Always Available",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAlwaysAvailable",
          isSelected: true
        },
        {
          myParent: "collections",
          text: "Purchase Requests",
          value: "Titles to Recommend",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitlesToRecommend",
          isSelected: false
        }
      ],
      selected: "Always Available",
      selectedText: "Always Available",
      defaultSelected: false
    }
  ],
  categoryList: [
    {
      text: "ACTION & ADVENTURE",
      value: "Action & Adventure",
      url: "/Search?term=YAF000000,YAN000000/Action %26 Adventure&searchBy=subject",
      code: "YAF001000",
      isMyStuffMenu: false,
      isChecked: true
    },
    {
      text: "ADVENTURE & ADVENTURERS",
      value: "Adventure & Adventurers",
      url: "/Search?term=YAF000000,YAN000000/Adventure %26 Adventurers&searchBy=subject",
      code: "YAN002000",
      isMyStuffMenu: false,
      isChecked: true
    },
    {
      text: "LGBTQ+",
      value: "LGBTQ+",
      url: "/Search?term=YAF000000,YAN000000/LGBTQ+&searchBy=subject",
      code: "YAF031000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LIFESTYLES / FARM & RANCH LIFE",
      value: "Lifestyles / Farm & Ranch Life",
      url: "/Search?term=YAF000000,YAN000000/Lifestyles / Farm %26 Ranch Life&searchBy=subject",
      code: "YAF034000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LITERARY CRITICISM & COLLECTIONS",
      value: "Literary Criticism & Collections",
      url: "/Search?term=YAF000000,YAN000000/Literary Criticism %26 Collections&searchBy=subject",
      code: "YAN033000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "VISIONARY & METAPHYSICAL",
      value: "Visionary & Metaphysical",
      url: "/Search?term=YAF000000,YAN000000/Visionary %26 Metaphysical&searchBy=subject",
      code: "YAF066000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT FICTION&searchBy=subject",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: true
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT NONFICTION&searchBy=subject",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  subjectList: [
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Browse?subject=YOUNG ADULT FICTION",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Browse?subject=YOUNG ADULT NONFICTION",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: true
    }
  ],
  featuredList: [],
  isLoadingCategory: false,
  isLoadingSubject: false,
  isLoadingRefiner: false,
  selected: "",
  searchCategory: "",
  isEnableAgeLevel: true,
  selectedRefine: true
};

export const FILTER_ALL_DATA_MOCK_03 = {
  selectedSubject: "",
  selectedCategory: "",
  menuType: "subject",
  staffPickId: "",
  categoryId: "",
  refiners: [
    {
      text: "Sort By",
      value: "sortby",
      items: [
        {
          myParent: "sortby",
          text: "Return date",
          value: "Returndate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbReturnDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Popularity",
          value: "Score",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPopularity",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Publication Date",
          value: "PublishDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPublicationDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Added Date",
          value: "AddedDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAddedDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Title",
          value: "Title",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitle",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Author",
          value: "Author",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAuthor",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Relevancy",
          value: "Relevancy",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbRelevancy",
          isSelected: true
        }
      ],
      selected: "Relevancy",
      selectedText: "Relevancy",
      defaultSelected: false
    },
    {
      text: "Availability",
      value: "availability",
      items: [
        {
          myParent: "availability",
          text: "All Titles",
          value: "All",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllTitles",
          isSelected: true
        },
        {
          myParent: "availability",
          text: "Available Now",
          value: "Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAvailableNow",
          isSelected: false
        }
      ],
      selected: "All",
      selectedText: "All Titles",
      defaultSelected: false
    },
    {
      text: "Age Level",
      value: "agelevel",
      items: [
        {
          myParent: "agelevel",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAgeLevelAll",
          isSelected: true
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 7-9, Age 12-14",
          value: "Teen - Grade 7-9, Age 12-14",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade7-9,Age12-14",
          isSelected: false
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 10-12, Age 15-18",
          value: "Teen - Grade 10-12, Age 15-18",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade10-12,Age15-18",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Release Date",
      value: "releasedate",
      items: [
        {
          myParent: "releasedate",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllMonths",
          isSelected: true
        },
        {
          myParent: "releasedate",
          text: "Last 6 months",
          value: "6",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast6Months",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Last year",
          value: "12",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLastYear",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Over a year",
          value: "1",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbOver1Year",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Recently Added",
      value: "recentlyadded",
      items: [
        {
          myParent: "recentlyadded",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllDays",
          isSelected: true
        },
        {
          myParent: "recentlyadded",
          text: "Last 30 days",
          value: "30",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast30Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 60 days",
          value: "60",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast60Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 90 days",
          value: "90",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast90Days",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Language",
      value: "language",
      items: [
        {
          myParent: "language",
          text: "All",
          value: "",
          controlType: "radio",
          defaultSelected: true,
          id: "rdbLanguageAll",
          isSelected: true
        },
        {
          myParent: "language",
          text: "English",
          value: "ENGLISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbENGLISH",
          isSelected: false
        },
        {
          myParent: "language",
          text: "Spanish",
          value: "SPANISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbSPANISH",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Collections",
      value: "collections",
      items: [
        {
          myParent: "collections",
          text: "General & Always Available",
          value: "General & Always Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbGeneralAlwaysAvailable",
          isSelected: false
        },
        {
          myParent: "collections",
          text: "General",
          value: "General",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbGeneral",
          isSelected: false
        },
        {
          myParent: "collections",
          text: "Always Available",
          value: "Always Available",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAlwaysAvailable",
          isSelected: true
        },
        {
          myParent: "collections",
          text: "Purchase Requests",
          value: "Titles to Recommend",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitlesToRecommend",
          isSelected: false
        }
      ],
      selected: "Always Available",
      selectedText: "Always Available",
      defaultSelected: false
    }
  ],
  categoryList: [
    {
      text: "ACTION & ADVENTURE",
      value: "Action & Adventure",
      url: "/Search?term=YAF000000,YAN000000/Action %26 Adventure&searchBy=subject",
      code: "YAF001000",
      isMyStuffMenu: false,
      isChecked: true
    },
    {
      text: "ADVENTURE & ADVENTURERS",
      value: "Adventure & Adventurers",
      url: "/Search?term=YAF000000,YAN000000/Adventure %26 Adventurers&searchBy=subject",
      code: "YAN002000",
      isMyStuffMenu: false,
      isChecked: true
    },
    {
      text: "LGBTQ+",
      value: "LGBTQ+",
      url: "/Search?term=YAF000000,YAN000000/LGBTQ+&searchBy=subject",
      code: "YAF031000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LIFESTYLES / FARM & RANCH LIFE",
      value: "Lifestyles / Farm & Ranch Life",
      url: "/Search?term=YAF000000,YAN000000/Lifestyles / Farm %26 Ranch Life&searchBy=subject",
      code: "YAF034000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LITERARY CRITICISM & COLLECTIONS",
      value: "Literary Criticism & Collections",
      url: "/Search?term=YAF000000,YAN000000/Literary Criticism %26 Collections&searchBy=subject",
      code: "YAN033000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "VISIONARY & METAPHYSICAL",
      value: "Visionary & Metaphysical",
      url: "/Search?term=YAF000000,YAN000000/Visionary %26 Metaphysical&searchBy=subject",
      code: "YAF066000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT FICTION&searchBy=subject",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: true
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT NONFICTION&searchBy=subject",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  subjectList: [
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Browse?subject=YOUNG ADULT FICTION",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Browse?subject=YOUNG ADULT NONFICTION",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  featuredList: [],
  isLoadingCategory: false,
  isLoadingSubject: false,
  isLoadingRefiner: false,
  selected: "",
  searchCategory: "",
  isEnableAgeLevel: true,
  selectedRefine: true
};

export const FILTER_ALL_DATA_MOCK_02 = {
  selectedSubject: "",
  selectedCategory: "",
  menuType: "subject",
  staffPickId: "",
  categoryId: "",
  refiners: [
    {
      text: "Sort By",
      value: "sortby",
      items: [
        {
          myParent: "sortby",
          text: "Return date",
          value: "Returndate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbReturnDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Popularity",
          value: "Score",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPopularity",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Publication Date",
          value: "PublishDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPublicationDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Added Date",
          value: "AddedDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAddedDate",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Title",
          value: "Title",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitle",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Author",
          value: "Author",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAuthor",
          isSelected: false
        },
        {
          myParent: "sortby",
          text: "Relevancy",
          value: "Relevancy",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbRelevancy",
          isSelected: true
        }
      ],
      selected: "Relevancy",
      selectedText: "Relevancy",
      defaultSelected: false
    },
    {
      text: "Availability",
      value: "availability",
      items: [
        {
          myParent: "availability",
          text: "All Titles",
          value: "All",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllTitles",
          isSelected: true
        },
        {
          myParent: "availability",
          text: "Available Now",
          value: "Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAvailableNow",
          isSelected: false
        }
      ],
      selected: "All",
      selectedText: "All Titles",
      defaultSelected: false
    },
    {
      text: "Age Level",
      value: "agelevel",
      items: [
        {
          myParent: "agelevel",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAgeLevelAll",
          isSelected: true
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 7-9, Age 12-14",
          value: "Teen - Grade 7-9, Age 12-14",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade7-9,Age12-14",
          isSelected: false
        },
        {
          myParent: "agelevel",
          text: "Teen - Grade 10-12, Age 15-18",
          value: "Teen - Grade 10-12, Age 15-18",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTeen-Grade10-12,Age15-18",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Release Date",
      value: "releasedate",
      items: [
        {
          myParent: "releasedate",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllMonths",
          isSelected: true
        },
        {
          myParent: "releasedate",
          text: "Last 6 months",
          value: "6",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast6Months",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Last year",
          value: "12",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLastYear",
          isSelected: false
        },
        {
          myParent: "releasedate",
          text: "Over a year",
          value: "1",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbOver1Year",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Recently Added",
      value: "recentlyadded",
      items: [
        {
          myParent: "recentlyadded",
          text: "All",
          value: "",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllDays",
          isSelected: true
        },
        {
          myParent: "recentlyadded",
          text: "Last 30 days",
          value: "30",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast30Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 60 days",
          value: "60",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast60Days",
          isSelected: false
        },
        {
          myParent: "recentlyadded",
          text: "Last 90 days",
          value: "90",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast90Days",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Language",
      value: "language",
      items: [
        {
          myParent: "language",
          text: "All",
          value: "",
          controlType: "radio",
          defaultSelected: true,
          id: "rdbLanguageAll",
          isSelected: true
        },
        {
          myParent: "language",
          text: "English",
          value: "ENGLISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbENGLISH",
          isSelected: false
        },
        {
          myParent: "language",
          text: "Spanish",
          value: "SPANISH",
          controlType: "radio",
          defaultSelected: false,
          id: "rdbSPANISH",
          isSelected: false
        }
      ],
      selected: "",
      selectedText: "All",
      defaultSelected: false
    },
    {
      text: "Collections",
      value: "collections",
      items: [
        {
          myParent: "collections",
          text: "General & Always Available",
          value: "General & Always Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbGeneralAlwaysAvailable",
          isSelected: false
        },
        {
          myParent: "collections",
          text: "General",
          value: "General",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbGeneral",
          isSelected: false
        },
        {
          myParent: "collections",
          text: "Always Available",
          value: "Always Available",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAlwaysAvailable",
          isSelected: true
        },
        {
          myParent: "collections",
          text: "Purchase Requests",
          value: "Titles to Recommend",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitlesToRecommend",
          isSelected: false
        }
      ],
      selected: "Always Available",
      selectedText: "Always Available",
      defaultSelected: false
    }
  ],
  categoryList: [
    {
      text: "ACTION & ADVENTURE",
      value: "Action & Adventure",
      url: "/Search?term=YAF000000,YAN000000/Action %26 Adventure&searchBy=subject",
      code: "YAF001000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "ADVENTURE & ADVENTURERS",
      value: "Adventure & Adventurers",
      url: "/Search?term=YAF000000,YAN000000/Adventure %26 Adventurers&searchBy=subject",
      code: "YAN002000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LGBTQ+",
      value: "LGBTQ+",
      url: "/Search?term=YAF000000,YAN000000/LGBTQ+&searchBy=subject",
      code: "YAF031000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LIFESTYLES / FARM & RANCH LIFE",
      value: "Lifestyles / Farm & Ranch Life",
      url: "/Search?term=YAF000000,YAN000000/Lifestyles / Farm %26 Ranch Life&searchBy=subject",
      code: "YAF034000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "LITERARY CRITICISM & COLLECTIONS",
      value: "Literary Criticism & Collections",
      url: "/Search?term=YAF000000,YAN000000/Literary Criticism %26 Collections&searchBy=subject",
      code: "YAN033000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "VISIONARY & METAPHYSICAL",
      value: "Visionary & Metaphysical",
      url: "/Search?term=YAF000000,YAN000000/Visionary %26 Metaphysical&searchBy=subject",
      code: "YAF066000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT FICTION&searchBy=subject",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Search?term=YAF000000,YAN000000/YOUNG ADULT NONFICTION&searchBy=subject",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  subjectList: [
    {
      text: "YOUNG ADULT FICTION",
      value: "YOUNG ADULT FICTION",
      url: "/Browse?subject=YOUNG ADULT FICTION",
      code: "YAF000000",
      isMyStuffMenu: false,
      isChecked: false
    },
    {
      text: "YOUNG ADULT NONFICTION",
      value: "YOUNG ADULT NONFICTION",
      url: "/Browse?subject=YOUNG ADULT NONFICTION",
      code: "YAN000000",
      isMyStuffMenu: false,
      isChecked: false
    }
  ],
  featuredList: [],
  isLoadingCategory: false,
  isLoadingSubject: false,
  isLoadingRefiner: false,
  selected: "",
  searchCategory: "",
  isEnableAgeLevel: true,
  selectedRefine: true
};
