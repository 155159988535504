export const PROFILE_TYPES = {
  ADULT: 'adult',
  TEEN: 'teen',
  KID: 'kid'
};
export const PROFILE_TYPES_UI_MAP = {
  [PROFILE_TYPES.ADULT]: 'General',
  [PROFILE_TYPES.TEEN]: 'Teen',
  [PROFILE_TYPES.KID]: 'Kid'
};
export const REDIRECT_URL = {
  LIBRARY: 'view/library',
  PROFILES_LIST: 'view/profiles'
};
export const PROFILE_TYPE_ID = {
  [PROFILE_TYPES.ADULT]: 1,
  [PROFILE_TYPES.TEEN]: 2,
  [PROFILE_TYPES.KID]: 3
};
export const PROFILE_ACTION = {
  CREATE: 'create',
  ACCESS: 'access',
  EDIT: 'edit',
  DELETE: 'delete',
  DISABLE: 'disable',
  ENABLE: 'enable',
  DELETEACCOUNT: 'deleteaccount'
};
export const IMG_FILE_MAX_SIZE = 5242880;
export const MAX_PROFILE_SIZE = 5;
export const PROFILES_MSGS = {
  PIN_NO_MATCH: 'The PINs you entered do not match. Please retry.',
  ERROR: 'Something went wrong. Please try again.',
  PIN_CREATION_SUCCESS: 'Your Pin is set successfully.',
  PROFILE_CREATION_SUCESS: 'Yay! Your Profile has been created.',
  PROFILE_DELETED_SUCCESS: 'Your Profile has been deleted.',
  PROFILE_UPDATED_SUCCESS: 'Your Profile has been updated.',
  PIN_LOCKED: 'Hmm, it looks like the PIN is locked. Please reset the Pin to continue.',
  PIN_INVALID: 'Hmm, it looks like the PIN you entered is incorrect.',
  RESET_PIN_SUCCESS: 'Your PIN was successfully changed!',
  ACCOUNT_DELETED_SUCCESS: 'Your account has been deleted successfully',
  OTP_SUCCESS: 'OTP code has been sent successfully',
  OTP_VERIFIED: 'OTP code has been verified successfully',
  OTP_NOT_MATCH: 'OTP code is not match',
  EMAIL_SUCCESS: 'Email sent successfully',
  RESET_SUCCESS: 'PIN Reset successfully'
};
export const PROFILE_RESPONSE_MSGS = {
  PIN_SETUP_SUCCESS: 'ParentalPin is set successfully',
  INVALID_PIN: 'Invalid pin',
  PIN_VALIDATION_SUCCESS: 'Pin validated successfully'
};
export const SECURITY_MSGS = {
  WRONG_ANSWER: 'Entered security answer doesnot match',
  SECURITY_FAILED: 'Something went wrong. Please reload the page',
  SECURITY_ANS_SUCCESS: 'Successful validation of security question'
};
export const WHITE_SPACE_REGEX = /^\s*\S(?:.*\S)?\s*$/;
export const KZ_INTEREST_SURVEY_TYPE_PROFILE = {
  1: 'teen',
  2: 'teen',
  3: 'kid'
};
export const PROFILE_NAVIGATION = { SAVE: 'save', DELETE: 'delete' };

export const VENDER_CODE_LIST = {
  video: 'VIDEOS',
  vbooks: 'VBOOKS'
};
export const PROFILE_LIST = {
  url: 'view/profiles'
}
export const PROFILE_ENABLED = {
  ADULT_ENABLED: 'isAdultEnabled',
  TEEN_ENABLED: 'isTeenEnabled',
  KID_ENABLED: 'isKidEnabled'
};
export const PROFILE_FEATURES = {
  PAYPERCIRC: 'PayPerCirc'
}
