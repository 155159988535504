export const LIB_COMP_ENTITY_TYPES = {
  FEATURED_READING_PROGRAM: 'FEATURED_READING_PROGRAM',
  LIBRARY_BANNERS: 'LIBRARY_BANNERS',
  FEATURED_LIST: 'FEATURED_LIST',
  ALWAYS_AVAILABLE: 'ALWAYS_AVAILABLE',
  NEWSPAPERS_MAGAZINES: 'NEWSPAPERS_MAGAZINES',
  CURATED_LIST: 'CURATED_LIST',
  THIRD_PARTY: 'THIRD_PARTY',
  WIDGETS_BANNERS: 'WIDGETS_BANNERS',
  ANNOUNCEMENTS: 'ANNOUNCEMENTS',
  FEATURED_TITLE: 'FEATURED_TITLE',
  LEARNING_ACTIVITIES: 'LEARNING_ACTIVITIES',
  ADDTIONAL_MODULE: 'ADDTIONAL_MODULE'
};
export const LIB_COMPONENT_TYPES = {
  CARD: 'CARD',
  CAROUSEL: 'CAROUSEL'
};

export const BACKGROUND_IMAGE_URL = [
  "https://DevAxisCDN.azureedge.net/reimaginedigital/KidsZone/orang-375.svg",
  "https://DevAxisCDN.azureedge.net/reimaginedigital/KidsZone/orange-768.svg",
  "https://DevAxisCDN.azureedge.net/reimaginedigital/KidsZone/orange-1024.svg",
  "https://DevAxisCDN.azureedge.net/reimaginedigital/KidsZone/orange-1440.svg"
];