<div class="interest-pop-survey">
    <div class="close-container">
        <button id="closeButton" class="closeDialogButton" aria-label="close" (click)="closeInterestsSurveyDialog(false)"
            (keydown.enter)="closeInterestsSurveyDialog(false)">
            <mat-icon role="none" aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <div class="interst-title">
        <h1 class="intrest-setpref" id="interest-survey-popup-title">Set Your Reading Preferences</h1>
        <p class="intrest-notes">Preferences you select here will be used to recommend titles you may want
            to read.</p>
        <p class="intrest-notes">You can also manage your reading preferences in your account.</p>
    </div>
    <div class="interst-survey">
        <div class="intrst-main-survy">
            <axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>
            <ng-container>
                <ng-container *ngFor="let interest of interests; let i = index">
                    <div role="button" attr.aria-label="{{interest.interestName}} {{interest.selected ? 'selected':'unselected'}}" class="interst-cardbtn" tabindex="0"
                        (keyup.enter)="selectInterest(i)" *ngIf="interest.imageUrl"
                        [ngClass]="{'intst-active': interest.selected}"
                        (click)="selectInterest(i)">
                        <img class="intst-img" alt="interest-img" aria-hidden="true"
                            [src]="interest.imageUrl[interest.imageUrl.length - 1]" />
                        <div class="intst-txt-sec" aria-hidden="true">
                            <p aria-hidden="true" class="intest-name">{{interest.interestName}}</p>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="interst-filter-sec" *ngIf="audience.length > 0">
        <div class="interst-filter-main">
            <div class="interst-filter-col">
                <h2 class="filter-title" id="audience-heading">PREFERRED AGE LEVEL (SELECT ONE OR MORE):</h2>
                <div class="interst-filter-check">
                    <div class="filter-list" role="group" aria-labelledby="audience-heading">
                        <mat-checkbox class="filter-checkbox-list" (change)="checkAudience($event.checked, i)" *ngFor="let aud of audience; let i = index;"
                            [attr.aria-checked]="aud.checked" [checked]="aud.checked">{{aud.audienceLevelName}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="interst-save-btn" *ngIf="!isLoading && interests.length > 0">
        <button class="save-btn" (click)="saveInterests(true, closeInterestsSurveyDialog)">Save Preferences</button>
        <button class="no-thanks-btn" aria-label="NO, THANKS" aria-describedby="skip_setting_pref"
            (click)="saveInterests(false, closeInterestsSurveyDialog)"><span class="visually-hidden" id="skip_setting_pref">Skip setting interest preference</span>NO, THANKS <mat-icon role="none" aria-hidden="true">
                close</mat-icon></button>
    </div>
</div>