<div class="book-of-month-col">
    <div class="kz-carousel book-of-month-carousel" (window:resize)="onResize()">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="cellsToScroll" [margin]="cellMargin"
            [cellWidth]="cellWidth" (click)="trackCarousel($event);$event.stopPropagation();" height="400"
            (keyup.enter)="trackCarousel($event);$event.stopPropagation();">
            <div class="carousel-cell book-of-month-cell" *ngFor="let book of componentData.components" role="listitem">
                <h2 class="book-of-month-heading" id="kz-bookofmonth"
                    [ngClass]="{'book-of-month-head-space': componentData.title?.length > 17}">
                    {{book.title}}</h2>
                <div class="book-of-month-wrapper">
                    <a class="book-of-month-img"
                    (click)="redirectToDetailsPage(book.bookDetails)"
                            (keyup.enter)="redirectToDetailsPage(book.bookDetails)"
                    *ngIf="book.bookDetails"
                        [ngClass]="{'book-of-month-img-space': componentData.title?.length > 17}" href="javascript:;" [attr.aria-label]="titleAriaLabel(book)">
                        <img class="img-responsive" [src]="book.bookDetails?.imageUrl"
                            alt="{{book.bookDetails.title}} Author name: {{book.bookDetails.author}} {{book.bookDetails?.runTime || (book.bookDetails?.formatType === 'EBT' || book.bookDetails?.iconInfo?.icon === 'ebook') ? 'eBook' : '' }}"
                            aria-hidden="true">
                        <p class="hidden" *ngIf="book.bookDetails.author">{{book.bookDetails.author}}
                        </p>
                        <div class="book-poster-bg" aria-hidden="true">
                            <div class="book-poster" *ngIf="book.bookDetails.iconInfo">
                                <mat-icon class="book-poster-icon"
                                    [svgIcon]="getImage(book.bookDetails.iconInfo.icon)"></mat-icon>
                            </div>
                            <div class="book-poster" *ngIf="book.bookDetails.formatType"
                                [ngClass]="{'disabled': !(book.bookDetails.isAvailable && book.bookDetails.isRTV)}">
                                <mat-icon class="book-poster-icon"
                                    [svgIcon]="getIcon(book.bookDetails.formatType).icon"></mat-icon>
                            </div>
                            <div class="duration">
                                <div *ngIf="(book.bookDetails?.formatType === 'ABT')">
                                    <div class="duration-sec" *ngIf="(book.bookDetails?.runTime); else noDuration">
                                        <span class="duration" aria-hidden="true">{{book.bookDetails?.runTime}}</span>
                                    </div>
                                    <ng-template #noDuration>
                                        <div class="duration-sec" *ngIf="!(book.bookDetails?.runTime )">
                                            eAudio
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="duration-sec"
                                    *ngIf="(book.bookDetails?.formatType === 'EBT' || book.bookDetails?.iconInfo?.icon === 'ebook')">
                                    eBook
                                </div>
                                <div class="duration-sec" *ngIf="(book.bookDetails?.formatType === 'VID')">
                                    Videos
                                </div>
                                <div class="duration-sec" *ngIf="(book.bookDetails?.formatType === 'VBK')">
                                    Videobook
                                </div>
                            </div>
                        </div>
                        <div aria-hidden="true" class="book-ribbon" *ngIf="isShowWaitList(book.bookDetails)">
                            {{waitList}}
                        </div>
                        <ng-container *ngIf="book?.bookDetails?.titleStateInfo">
                            <axis360-title-actions [isDueDate]="true"
                                [durationSecClass]="'carousel-card-ribbon'"
                                [dueDurationClass]="'kz-time-remain-lbl'" [kzCardBtnClass]="'kz-card-btn kz-card-btn-list'"
                                [kzActionMenuItemClass]="'kz-my-stuff-list-option program-list-option'"
                                [expandSecClass]="'more-menu more-menu-list'" [bookDetail]="book.bookDetails" [showAction]="true"
                                [isProgressCalculate]="false" >
                            </axis360-title-actions>
                        </ng-container>
                    </a>
                    <p axis360EllipsisTitle class="book-of-month-para" (click)="redirectToDetailsPage(book.bookDetails)"
                        (keyup.enter)="redirectToDetailsPage(book.bookDetails)" aria-roledescription="description">
                        {{book.bookDetails?.description}}</p>
                    <div></div>
                </div>
            </div>
        </carousel>
    </div>
</div>