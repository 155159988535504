import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { PublicationCard } from '@pressreader/shared/data/press-reader-model';
import { BreakPointService } from '@shared/services/break-point.service';

@Component({
  selector: 'axis360-press-reader-card-carousels',
  templateUrl: './press-reader-card-carousels.component.html'
})
export class PressReaderCardCarouselsComponent implements OnInit, AfterContentChecked {
  @Input() publicationList: PublicationCard[];
  @Input() usedIn: string;
  @Input() type = '';
  @Input() title = '';
  @Input() fromHomeCarousel = false;
  numVisible: number;
  numScroll: number;
  responsiveOptions: any;
  hideCarouselOption = false;

  /**
   * Creates an instance of press reader card carousel component.
   * @param breakPointService params for breakPointService
   */
  constructor(private breakPointService: BreakPointService) {}

  /**
   * Intialize the carousel options
   */
  ngOnInit(): void {
    this.initializeCarouselOption();
    this.onResize(0, null);
  }

  /**
   * handle window resize event
   */
  ngAfterContentChecked() {
    this.onResize(0, null);
  }

  /**
   * handle window resize event
   * @param type type of carousel
   * @param event event object
   */
  onResize(type: number, event?: any) {
    let numVisible = 0;
    if (this.usedIn === 'view') {
      this.breakPointService.onResize(type, event);
      numVisible = this.breakPointService.breakPoint.numVisible;
    } else if (this.usedIn === 'featured') {
      this.breakPointService.onResizeFeaturedTitle(type, event);
      numVisible = this.breakPointService.featureCover.numVisible;
    } else {
      numVisible = 4;
    }
    this.hideCarouselOption = this.publicationList.length <= numVisible;
  }

  /**
   * Intialize the numscroll, numvisible and responsiveOptions objects
   */
  public initializeCarouselOption() {
    switch (this.usedIn) {
      case 'view':
        this.numScroll = this.breakPointService.breakPoint.numScroll;
        this.numVisible = this.breakPointService.breakPoint.numVisible;
        this.responsiveOptions = this.breakPointService.responsiveOptions;
        break;
      case 'featured':
        this.numScroll = this.breakPointService.featureCover.numScroll;
        this.numVisible = this.breakPointService.featureCover.numVisible;
        this.responsiveOptions = this.breakPointService.responsiveFeatureOptions;
        break;
      default:
        this.numScroll = 4;
        this.numVisible = 4;
        this.responsiveOptions = [];
        break;
    }
  }
}
