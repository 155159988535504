import { OverlayContainer } from '@angular/cdk/overlay';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Input, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InterestSurveyComponent } from '@home/interest-survey/interest-survey.component';
import { HOME_EVENTS_KEY } from '@home/shared/constants/home.analytics.constants';
import { INTERESTS_AUDIENCE_LEVEL, KZ_INTEREST_SURVEY_CLASS_PROFILE } from '@home/shared/constants/home.constants';
import {
  BACK_ROUNDIMAGE_KIDINTERESTSURVEY, BACK_ROUNDIMAGE_TEENINTERESTSURVEY, BACK_ROUNDIMAGE_INTERESTSURVEY,
  BACK_ROUNDIMAGE_TEENINTERESTSURVEY_BOTTOM, BACK_ROUNDIMAGE_KIDINTERESTSURVEY_BOTTOM, BACK_ROUNDIMAGE_INTERESTSURVEY_BOTTOM, KID
} from '@shared/constants/search.constant';
import { Audience, UserInterest, InterestAPIResponse } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { SNAKE_BAR_MODE, INTEREST_SURVEY_SUCCESS, DEFAULT_SUCCESS_MESSAGE } from '@shared/constants/app.constants';
import { focusElement, positionFooter, getBackgroundImageResize, cdkVisualHidden, cdkRemoveVisualHidden } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { noop } from 'rxjs';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@shared/services/user.service';
import { KzInterestSurveyPopupComponent } from './kz-interest-survey-popup/kz-interest-survey-popup.component';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-kz-interest-survey',
  templateUrl: './kz-interest-survey.component.html',
  styleUrls: ['./kz-interest-survey.component.scss']
})
export class KzInterestSurveyComponent extends InterestSurveyComponent implements OnInit, AfterViewInit, OnDestroy {
  profileType: string;
  @Input() isPopUp: string;
  @Input() popUpData: UserInterest;
  @Input() closePopup: () => void;
  breadcrumbs: (
    | { text: string; url: string; queryParams?: any; isHref: boolean }
    | { text: string; url: string; queryParams?: any }
  )[] = [];
  KZ_INTEREST_SURVEY_CLASS_PROFILE = KZ_INTEREST_SURVEY_CLASS_PROFILE;
  queryParams = '';
  INTERESTS_AUDIENCE_LEVEL = INTERESTS_AUDIENCE_LEVEL;
  isFrom: string;
  backgroundImageUrl: string;
  backgroundImageUrlBottom: string;
  kidsZoneEnabled: boolean;
  axis360Enabled: boolean;
  pageAnnounce = '';
  constructor(
    public homeService: HomeService,
    public snackBarService: SnackBarService,
    public homeAnalyticsService: HomeAnalyticsService,
    public spinnerService: SpinnerService,
    public liveAnnouncer: LiveAnnouncer,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public overlayContainer: OverlayContainer,
    public myShelfListService: MyShelfListService,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    public profilesService: ProfilesService,
    private dialogRef: MatDialogRef<KzInterestSurveyPopupComponent>,
    public titleListService: TitleListService,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public dialogData: InterestAPIResponse
  ) {
    super(homeService, snackBarService, homeAnalyticsService, spinnerService, liveAnnouncer, profileInfoService,
      myShelfListService, router, route, profilesService, titleListService, commonService);
  }
  /**
   * on init
   */
  ngOnInit(): void {
    this.selectedProfileType = this.profileInfoService?.profileInfo?.profileType === 'Adult' ? 'General' : this.profileInfoService?.profileInfo?.profileType;
    document.title = `Interest Survey - ${this.selectedProfileType}`;
    this.pageAnnounce = `Interest Survey - ${this.selectedProfileType} page`;
    this.liveAnnouncer.announce(`Interest Survey - ${this.selectedProfileType} page`, 'assertive');
    if (this.popUpData) {
      this.profileType = this.popUpData.profileType.toLowerCase();
      super.setInitialInterest(this.popUpData);
    } else {
      this.profileType = this.route.snapshot.params.type.toLowerCase();
      this.isFrom = this.route.snapshot.queryParams.isFrom;
      if (this.route.snapshot?.queryParams?.id) {
        this.queryParams = `?currentProfileID=${this.route.snapshot.queryParams.id}&currentprofileType=${this.profileType}&currentProfileIsPrimary=${this.route.snapshot.queryParams.isPrimary}`;
        this.profileInfoService.profilePinSuccess = true;
      }
      const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
      const url = homePage === 'Library' ? '/view/library' : '/view/myshelf'
      if (this.isFrom) {
        this.breadcrumbs = [
          { text: homePage, url },
          { text: 'Based On Your Interests', url: '/view/title', queryParams: { list: 'Based_your_Interests' } },
          { text: 'My Interests', url: './' }
        ]
      } else {
        this.breadcrumbs = [
          { text: homePage, url },
          { text: 'My Interests', url: './' }
        ]
      }
      super.ngOnInit();
    }
    this.onResize();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
  /**
   * Handles audience level
   */
  handleAudienceLevel(audience: Audience = []) {

    if (this.configService.currentLibrary.kidsZoneEnabled) {
      const selectedAudience = [...new Set([...audience.map((audienceObj) => audienceObj.audienceLevel)])];
      const profileAudMap = {
        adult:
          !this.configService.currentLibrary.axis360Enabled && this.configService.currentLibrary.kidsZoneEnabled
            ? [INTERESTS_AUDIENCE_LEVEL.TEEN, INTERESTS_AUDIENCE_LEVEL.KID]
            : [INTERESTS_AUDIENCE_LEVEL.ADULT, INTERESTS_AUDIENCE_LEVEL.TEEN, INTERESTS_AUDIENCE_LEVEL.KID],
        teen: [INTERESTS_AUDIENCE_LEVEL.TEEN, INTERESTS_AUDIENCE_LEVEL.KID],
        kid: [INTERESTS_AUDIENCE_LEVEL.KID]
      };

      this.audience = profileAudMap[this.profileType].map((audienceLevel) => ({
        audienceLevel: audienceLevel.value,
        audienceLevelName: audienceLevel.name,
        checked: selectedAudience.includes(audienceLevel.value) || this.profileType === INTERESTS_AUDIENCE_LEVEL.KID.value
      }));
    } else if (this.configService.currentLibrary.axis360Enabled) {
      UpdateAudienceLevel();
      const selectedAudience = audience.map((audienceObj) => audienceObj.audienceLevel);
      this.audience = [INTERESTS_AUDIENCE_LEVEL.ADULT, INTERESTS_AUDIENCE_LEVEL.TEEN, INTERESTS_AUDIENCE_LEVEL.KID].map(
        (audienceLevel) => ({
          audienceLevel: audienceLevel.value,
          audienceLevelName: audienceLevel.name,
          checked: selectedAudience.includes(audienceLevel.value)
        })
      );
    }

    function UpdateAudienceLevel() {
      audience.forEach(aud => {
        switch (aud.audienceLevel) {
          case INTERESTS_AUDIENCE_LEVEL.YOUNG_ADULT.value:
            aud.audienceLevel = INTERESTS_AUDIENCE_LEVEL.TEEN.value;
            break;
          case INTERESTS_AUDIENCE_LEVEL.JUVENILE.value:
            aud.audienceLevel = INTERESTS_AUDIENCE_LEVEL.KID.value;
            break;
          default:
            break;
        }
      });
    }
  }
  /**
   * Gets interests
   */
  getInterests() {
    this.homeService.getKzInterests(this.queryParams).subscribe(
      (response) => {
        if (response.statusCode === 200 && response.message === DEFAULT_SUCCESS_MESSAGE) {
          this.interests = response.userInterests.interests;
          this.handleAudienceLevel(response.userInterests.audience);
          this.isLoading = false;
          positionFooter(true);
        } else {
          this.snackBarService.showSnackBar();
          this.isLoading = false;
          positionFooter(true);
        }
      },
      () => {
        this.snackBarService.showSnackBar();
        this.isLoading = false;
        positionFooter(true);
      }
    );
  }
  /**
   * Saves interests
   */
  saveInterests(save = true, callback: (save?: boolean) => void = noop, closePopup = false) {
    let eventKey = 'INTEREST_NO_THANKS';
    let interests = this.initialInterests;
    if (closePopup) {
      eventKey = 'INTEREST_CLOSE';
    }
    if (this.initialAudience && this.initialAudience.length > 0) {
      this.handleAudienceLevel(this.initialAudience);
    }
    if (save) {
      eventKey = 'KZ_INTEREST_SAVE';
      interests = this.interests;
    }
    this.homeAnalyticsService.trackInteresetSurveyEvent(eventKey as keyof typeof HOME_EVENTS_KEY);
    const selectedInterests = interests.filter((res) => res.selected);
    this.spinnerService
      .withObservable(
        this.homeService.kzSaveInterests(
          {
            interests: selectedInterests,
            audience: (this.profileInfoService.profileInfo.profileType === KID && selectedInterests.length === 0 && !save) ? [] :
              this.audience.filter((aud) => aud.checked).map(({ audienceLevel }) => ({ audienceLevel }))
          },
          this.queryParams
        ),
        false,
        false
      )
      .subscribe(
        (interestRes: any) => {
          this.liveAnnouncer.announce('Finished Loading.', 'polite');
          if (interestRes.responseCode === 200) {
            if (save) {
              this.overlayContainer.getContainerElement().classList.add('kz-mat-interst-overlay');
              this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, INTEREST_SURVEY_SUCCESS, { duration: 2000 });
            }
            setTimeout(() => {
              callback(save);
              this.overlayContainer.getContainerElement().classList.remove('kz-mat-interst-overlay');
            }, 200);
            return;
          }
          this.snackBarService.showSnackBar();
        },
        () => {
          this.liveAnnouncer.announce('Finished Loading.', 'polite');
          this.snackBarService.showSnackBar();
        }
      );

  }
  onResize() {
    const profileAudMap = {
      adult: BACK_ROUNDIMAGE_INTERESTSURVEY,
      teen: BACK_ROUNDIMAGE_KIDINTERESTSURVEY,
      kid: BACK_ROUNDIMAGE_TEENINTERESTSURVEY
    };
    const profileAudMapBottom = {
      adult: BACK_ROUNDIMAGE_INTERESTSURVEY_BOTTOM,
      teen: BACK_ROUNDIMAGE_KIDINTERESTSURVEY_BOTTOM,
      kid: BACK_ROUNDIMAGE_TEENINTERESTSURVEY_BOTTOM
    };
    const getImage = profileAudMap[this.profileType];
    const getImageBottom = profileAudMapBottom[this.profileType];
    this.backgroundImageUrl = getBackgroundImageResize(getImage);
    this.backgroundImageUrlBottom = getBackgroundImageResize(getImageBottom);
  }
  closeKzInterestsSurveyDialog = () => {
    this.dialogRef.close();
  };
  ngAfterViewInit() {
    if (!this.popUpData) {
      focusElement('loc_headerId');
    }
  }
}
