import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forceRedirect } from '@shared/helper/app.util';
import { AccountService } from '@shared/services/account.service';
import { CommonService } from '@shared/services/common.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { ILearningActivityData } from '@titledetails/shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from '@titledetails/shared/services/title-details.analytics.service';
import { AccountComponent } from 'app/core/account/account.component';
@Component({
  selector: 'axis360-kz-learning-activity-new',
  templateUrl: './kz-learning-activity-new.component.html',
  styleUrls: ['./kz-learning-activity-new.component.scss']
})
export class KzLearningActivityNewComponent implements OnInit {
  @Input() learningActivity: ILearningActivityData;
  @Input() parentRoute: string;
  isDownloading: false;
  isPlaying: false;
  public loginOpened = false;
  forceRedirect = forceRedirect;

  constructor(
    public router: Router,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public userService: UserService,
    public spinnerService: SpinnerService,
    public accountService: AccountService,
    public matDialog: MatDialog,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.learningActivity.disabled = (this.learningActivity?.linkedTitleID === null || this.learningActivity?.linkedTitleID === '');
  }

  downloadLearningActivity(fileUrl: string) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent('DOWNLOAD');
    if (!this.userService.userInfo.isLoggedIn) {
      this.openLoginModel(fileUrl);
      return;
    }
    window.open(fileUrl, '_blank');
  }

  openLoginModel(url: string) {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        if (this.loginOpened) {
          return;
        }
        this.loginOpened = !this.loginOpened;
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true
            }
          })
          .afterClosed()
          .subscribe((res: boolean) => {
            this.loginOpened = false;
            if (res) {
              this.downloadLearningActivity(url);
            }
          });
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }

  redirectLinkedTitle(itemId: string, isbn: string) {
    if (!this.learningActivity.disabled) {
      this.forceRedirect(`/ng/view/${this.parentRoute}/title/${itemId}/${isbn}`);
    }
  }
}
