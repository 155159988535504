import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InSightGoalModal } from '@home/shared/data/home.model';

@Component({
  selector: 'axis360-insights-cards-carousel',
  templateUrl: './insights-cards-carousel.component.html',
  styleUrls: ['./insights-cards-carousel.component.scss']
})
export class InsightsCardsCarouselComponent {
  @Input() insight: InSightGoalModal;
  @Output() openSetGoalEvent = new EventEmitter<InSightGoalModal>();
}
