import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[axis360MatCheckboxAda]'
})
export class MatCheckboxAdaDirective implements AfterViewInit {
  @Input() notificationId: string;
  constructor(public elem: ElementRef, private renderer: Renderer2) {}
  /**
   * To set ADA tags after view init
   */
  ngAfterViewInit() {
    const elem = this.elem.nativeElement.querySelector('.mat-checkbox-label');
    const input = this.elem.nativeElement.querySelector('.mat-checkbox-input');
    const disp = this.elem.nativeElement.querySelector('.kz-visually-hidden');
    const label = elem.textContent;
    elem.setAttribute('aria-hidden', 'true');
    input.setAttribute('aria-label', label.replace('PINs', 'pins'));
    this.notificationId && input.setAttribute('aria-labelledby', this.notificationId);
    disp && this.renderer.setStyle(disp, 'display', 'none');
  }
}
