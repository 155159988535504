import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgOtpInputComponent } from 'ng-otp-input';

@Component({
  selector: 'axis360-ng-otp-input',
  templateUrl: './ng-otp-input.component.html',
  styleUrls: ['./ng-otp-input.component.scss']
})
export class NgPinInputComponent implements AfterViewInit, OnChanges {
  @ViewChild(NgOtpInputComponent, { static: false }) otpInput: NgOtpInputComponent;
  @Output() pinNumber = new EventEmitter<string>();
  @Input() inputValid;
  @Input() isConfirm = false;
  @Input() isSetProfile;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  pinNumberValue: string;
  component: Element;
  setInputValue;
  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      const ngotpInput = document?.querySelector('#ngInput');
      if (changes.isConfirm?.currentValue || !changes.inputValid?.currentValue || changes.isSetProfile) {
        this.otpInput?.setValue('');
      }

      ngotpInput?.querySelectorAll('input').forEach((element) => {
        element.classList.remove('has-value');
        element.classList.remove('error-pin');
        element.classList.add(this.inputValid ? 'empty' : 'error-pin');
      });

      (!changes.inputValid?.currentValue || changes.isConfirm?.currentValue) &&
        ngotpInput.querySelectorAll('input') &&
        ngotpInput.querySelectorAll('input')[0].focus();
    }
  }

  ngAfterViewInit(): void {
    this.setInputpin();

    document.getElementById('ngInput').addEventListener('click', (e: any) => this.autoSelectInput(e), true);
    document.getElementById('ngInput').addEventListener('keydown', (e: any) => this.changeInputValue(e), true);
    document.getElementById('ngInput').addEventListener('paste', () => this.changeInputValueByCopyPaste(), true);

  }
  onOtpChange(pinNumber: string) {
    this.pinNumberValue = pinNumber;
    this.pinNumber.emit(this.pinNumberValue);
    const setpin = document.getElementById('loc_profile_edit_pin');
    if (setpin) {
      setpin?.removeAttribute('aria-labelledby');
      setpin?.removeAttribute('aria-describedby');
    }
    document.querySelectorAll('input').forEach((element) => {
      element.classList.remove('error-pin');
    });
  }
  setInputpin() {
    const infoMesg = ' Please enter a number between 0 to 9';
    const ngotpInput = document?.querySelector('#ngInput');
    ngotpInput.querySelectorAll('input').forEach((n, i) => {
      n.setAttribute('aria-label', 'pin number ' + (i + 1) + infoMesg);
      /**
       * n.setAttribute('aria-describedby', 'loc_srInputHint');
       */
      n.setAttribute('inputmode', 'numeric');
      n.setAttribute('pattern', '[0-9]*');
      n.setAttribute('autocomplete', '');
      if (i === 0) {
        n.setAttribute('cdkFocusInitial', '');
        n.setAttribute('aria-describedby', 'loc_txtProfileManagementPin');
        n.setAttribute('aria-errormessage', 'loc_txtProfileManagementPin');
      }
    });
  }

  changeInputValueByCopyPaste() {
    const ngotpInput = document?.querySelector("#ngInput");

    setTimeout(() => {
      ngotpInput.querySelectorAll('input').forEach((n) => {
        if (n.value) {
          n.classList.add('has-value');
          /*********copied dot dont delete this !important********/
          n.value = '•';
        }
      });
    }, 500);
  }
  changeInputValue(event) {
    const input = document?.querySelector('#' + event.target.id) as HTMLInputElement;
    if (event.key !== 'Enter') {
      setTimeout(() => {
        if (input.value) {
          setTimeout(() => {
            /*********copied dot dont delete this !important********/
            if (this.inputValid) {
              input.classList.add('has-value');
              input.value = '•';
            }
          }, 500);
        } else {
          input.classList.remove('has-value');
        }
      }, 100);
    }
  }
  changeValueToDot(input) {
    if (this.inputValid) {
      input.classList.add('has-value');
      /*********copied dot dont delete this !important********/
      input.value = '•';
    }
  }
  /**
   * Autos select input
   * @param e element
   */
  autoSelectInput(e) {
    const input = document?.querySelector('#' + e.target.id) as HTMLInputElement;
    input.value = "";
    input.classList.remove('has-value');
    input.focus();
  }
}
