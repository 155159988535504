<div class="kz-add-own-title kz-main">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">Add A Title Page</p>
    <div class="kz-breadcrumb-sec">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'"
            class="kz-breadcrumb">
        </axis360-breadcrumb>
    </div>
    <div class="add-title mt-3 mb-3">
        <h1 class="heading-1" id="loc_txtAddTitleHeading">Add a Title</h1>
    </div>
    <div class="kz-add-form title-program-edit">
        <form [formGroup]="addProgramForm">
            <mat-form-field class="kz-name-form kz-add-title-form" appearance="fill">
                <mat-label>Title Name</mat-label>
                <input matInput formControlName="titleName" maxlength="26" required>
                <mat-error *ngIf="addProgramForm.controls['titleName'].errors?.required || addProgramForm.controls['titleName'].errors?.whitespace">Title Name is
                    required.</mat-error>
                <mat-error *ngIf="addProgramForm.controls['titleName'].errors?.maxlength">
                    Title Name can not exceed 25 characters.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="kz-name-form kz-add-title-form" appearance="fill">
                <mat-label>Author Name</mat-label>
                <input matInput maxlength="26" formControlName="authorName">
                <mat-error *ngIf="addProgramForm.controls['authorName'].errors?.maxlength">
                    Author Name can not exceed 25 characters
                </mat-error>
            </mat-form-field>
            <mat-form-field class="kz-name-form kz-add-title-form kz-type-form" appearance="fill">
                <mat-label id="loc_titleType">Title Type</mat-label>
                <mat-select disableOptionCentering axis360MatSelectAriaExpanded aria-labelledby="loc_titleType "
                    role="combobox" id="loc_titleTypeSelect" aria-haspopup="listbox"
                    panelClass="kz-security-que-panel kz-profile-type-panel kz-program-add-title-select"
                    (openedChange)="openProgamMatSelect($event)" required #profileType
                    [attr.aria-expanded]="profileType.panelOpen" formControlName="profileType">
                    <mat-option *ngFor="let op of options" [id]="op.id" [value]="op.value"
                        attr.aria-label="{{op.viewValue}}">{{op.viewValue}}</mat-option>
                </mat-select>
                <mat-icon matSuffix svgIcon="kz-filter-down-arrow" aria-hidden="true" class="arrow-down"></mat-icon>
                <mat-error *ngIf="addProgramForm.controls['profileType'].errors?.required">Title type is
                    required</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="add-title-btn done_Submit d-flex">
        <button (click)="addaTitle();" id="loc_btnAddTitleOwn" class="saveBtn addBtn active"
            [disabled]="addProgramForm.invalid">Add</button>
    </div>
</div>