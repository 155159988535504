import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';
import { strToBool } from '@shared/helper/app.util';

@Directive({
  selector: '[axis360PradioAda]'
})
export class PradioAdaDirective implements AfterViewInit {
  @Input() isRevers: string;
  @Input() isWebSite: string;
  @Input() isWebTitle: string;
  radioElm: HTMLElement;
  constructor(private el: ElementRef) {
    this.radioElm = el.nativeElement;
  }
  ngAfterViewInit() {
    const label = this.radioElm.querySelector('.ui-radiobutton-label');
    const inputElm = this.radioElm.querySelector('input');
    const radioIcon = this.radioElm.querySelector('.ui-radiobutton-box');
    radioIcon.setAttribute('aria-hidden', 'true');
 
    if (strToBool(this.isRevers)) {
      this.el.nativeElement.prepend(label);
      if (label?.textContent === 'Titles (A-Z)' || label?.textContent === 'Title (A-Z)')
      {
        inputElm?.setAttribute('aria-label', 'Titles A to Z');
      }
      else if(label?.textContent === 'Titles (Z-A)' || label?.textContent === 'Title (Z-A)')
      {
        inputElm?.setAttribute('aria-label', 'Titles Z to A');
      }
      else if(label?.textContent.includes('-') && !label?.textContent.includes('--'))
      {
        if(strToBool(this.isWebSite)) {
          inputElm?.setAttribute('aria-label', label?.textContent.replace(/-/g, " to "));
          return;
        }
        inputElm?.setAttribute('aria-label', label?.textContent.replace(/Grade (\d+)-(\d+)/, 'Grade $1 to $2').replace(/Age (\d+)-(\d+)/, 'Age $1 to $2'));
      }
      else if (this.isWebTitle === 'Domain')
      {
        inputElm?.setAttribute('aria-label', this.formatString(label?.textContent));
      }
      else {
        inputElm?.setAttribute('aria-label', label?.textContent);
        
      }
    }
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
      inputElm.setAttribute('tabindex', '-1');
    }
  }
  formatString(input) {
    return input.replace(/\.(\w)(\w)$/, 'dot $1 $2');
}
}
