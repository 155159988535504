export const PROGRAMS_PAGE_SIZE = 20;
export const PROGRAMS_LIST_IMAGES_COUNT = 4;
export const KZ_PROGRAMS_LIST_IMAGES_COUNT = 3;
export const PROGRAM_TYPE = {
  OrderOfBooks: 'OrderOfBooks',
  XOfYOfBooks: 'XOfYOfBooks',
  MileStone: 'Milestone'
};
export const PROGRAM_TYPE_DISPLAY = {
  [PROGRAM_TYPE.OrderOfBooks]: 'Program Items in Order',
  [PROGRAM_TYPE.XOfYOfBooks]: 'X of Y Program Items',
  [PROGRAM_TYPE.MileStone]: 'Milestone'
};
export const PROGRAMS_STATUS = {
  ACCEPT: 'Accept',
  COMPLETE: 'Complete',
  COMPLETED: 'Completed',
  COMPLETE_CLOSED: 'Completed',
  NOT_COMPLETE_CLOSED: 'Not Completed',
  ACTIVE: 'Active',
  UNPUBLISHED: 'Unpublish'
};
export const PROGRAMS_STATUS_TEXT = {
  STARTED: 'Started',
  NOT_STARTED: 'Not started',
  CLOSED: 'Closed',
  JOINED: 'Joined',
  Endstoday: 'Ends today'
};
export const PROGRAMS_TABS = {
  MY_PROGRAMS: 'MY PROGRAMS',
  OPEN_PROGRAMS: 'ALL PROGRAMS'
};
export const PROGRAMS_STATUS_MAP: { [key: string]: string } = {
  [PROGRAMS_TABS.MY_PROGRAMS]: `${PROGRAMS_STATUS.ACTIVE},${PROGRAMS_STATUS.COMPLETE}`,
  [PROGRAMS_TABS.OPEN_PROGRAMS]: PROGRAMS_STATUS.ACTIVE
};
export const PROGRAMS_TAB_TITLE = {
  [PROGRAMS_TABS.MY_PROGRAMS]: 'My Programs',
  [PROGRAMS_TABS.OPEN_PROGRAMS]: 'All Programs'
};
export const PROGRAMS_TAB_ROUTE_NAME = {
  [PROGRAMS_TABS.MY_PROGRAMS]: PROGRAMS_TABS.MY_PROGRAMS,
  OPEN: PROGRAMS_TABS.OPEN_PROGRAMS
};
export const PROGRAMS_TAB_ROUTE_MAP = {
  [PROGRAMS_TABS.MY_PROGRAMS]: `/programs`,
  [PROGRAMS_TABS.OPEN_PROGRAMS]: `/programs/open`
};
export const CARD_ROLE_MAP = {
  [PROGRAMS_TABS.MY_PROGRAMS]: `link`,
  [PROGRAMS_TABS.OPEN_PROGRAMS]: `button`
};
export const PROGRAMS_TABS_LIST = [PROGRAMS_TABS.OPEN_PROGRAMS, PROGRAMS_TABS.MY_PROGRAMS];
export const OPEN_JOIN_PROGRAM = {
  content: 'Would you like to show your reading/listening progress?',
  submitBtnText: 'Yes',
  cancelBtnText: 'No',
}
