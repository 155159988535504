import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HOME_EVENTS_KEY } from '@home/shared/constants/home.analytics.constants';
import { INSIGHT_GOAL, INSIGHT_GOAL_PREDEFINED } from '@home/shared/constants/home.constants';
import { InSightGoalModal, Insights, SetGoalReq } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { CurrentLibrary } from '@shared/data/config.model';
import { focusElement, positionFooter } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SetGoalPopupComponent } from './set-goal-popup/set-goal-popup.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'axis360-goals-and-insights',
  templateUrl: './goals-and-insights.component.html',
  styleUrls: ['./goals-and-insights.component.scss']
})
export class GoalsAndInsightsComponent implements OnInit {
  isLoading = true;
  insights: InSightGoalModal[] = [];
  responsiveOptions = [
    {
      breakpoint: '3840px',
      breakpointWidth: 3840,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '700px',
      breakpointWidth: 700,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '600px',
      breakpointWidth: 600,
      numVisible: 2.5,
      numScroll: 2.5
    },
    {
      breakpoint: '500px',
      breakpointWidth: 500,
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '450px',
      breakpointWidth: 450,
      numVisible: 1.8,
      numScroll: 1.8
    },

    {
      breakpoint: '375px',
      breakpointWidth: 375,
      numVisible: 1.5,
      numScroll: 1.5
    },
    {
      breakpoint: '0px',
      breakpointWidth: 0,
      numVisible: 2,
      numScroll: 2
    }
  ];
  hideNavButtons: boolean;
  numScroll: number;
  numVisible: number;
  currentLibrary: Partial<CurrentLibrary>;
  setGoalToast: string;
  @Output() progressiveGoalsEmit = new EventEmitter();
  insightFocusElementId: string;
  focusElement = focusElement;
  constructor(
    public homeService: HomeService,
    public matDialog: MatDialog,
    public snackBarService: SnackBarService,
    public homeAnalyticsService: HomeAnalyticsService,
    public configService: ConfigService,
    public liveAnnouncer: LiveAnnouncer
  ) { }
  /**
   * Tget insights data on init
   */
  ngOnInit(): void {
    this.currentLibrary = this.configService.currentLibrary;
    this.getInsights();
    this.onResize();
  }
  /**
   * To calculate the percentage of goals and insights
   * @param goal Goal number
   * @param done Completed number
   */
  getPercentage = (goal: number, done: number): number => {
    const percentage = Math.floor((done / goal) * 100);
    return percentage > 100 ? 100 : percentage;
  };
  /**
   * Gets insights info
   * @param insightKey insight key
   * @param goal goal value
   * @param done done value
   * @returns insights info
   */
  getInsightsInfo(insightKey: string, goal: number, done: number, changeHeader: string, avg = 0) {
    const insightData = INSIGHT_GOAL_PREDEFINED[insightKey];
    const percentage = this.getPercentage(goal, done);
    let titleInfo = {
      insightTitle: insightData.title,
      insightSubTitle: insightData.subtitle,
      insightMetrics: `${done}`,
      ariaLabel: `${insightData.title}, ${done} ${insightData.subtitle}`
    };
    insightData.goalHeading = changeHeader;
    if (goal) {
      const setPercentage = percentage > 0? `${percentage} Percentage `: '' ;
      titleInfo = {
        insightTitle: insightData.goalRelatedTitle,
        insightSubTitle: insightData.goalRelatedSubTitle,
        insightMetrics: `${done}/${goal}`,
        ariaLabel: `${insightData.goalRelatedTitle} ${setPercentage} ${done}/${goal} ${insightData.goalRelatedSubTitle}`
      };
    }
    return {
      ...insightData,
      ...titleInfo,
      goal,
      done,
      avg,
      percentage
    };
  }
  /**
   * To handle insights for UI display
   * @param insights insights data from API
   * @returns formatted insights data for UI
   */
  handleInsights(insights: Insights) {
    const {
      minsRead = { goal: 0, totalRead: 0, averageRead: 0 },
      minsListened = { goal: 0, totalListened: 0, averageListened: 0 },
      currentStreak = { goal: 0, totalCurrentStreak: 0 },
      bookRead = { monthlyGoal: 0, monthlyBookRead: 0, yearlyGoal: 0, yearlyBookRead: 0 }
    } = insights;
    if (this.currentLibrary.isPPCOnlyLibrary) {
      return {
        [INSIGHT_GOAL.AVG_LISTEN]: this.getInsightsInfo(
          INSIGHT_GOAL.AVG_LISTEN,
          minsListened.goal,
          minsListened.totalListened,
          minsListened.goal ? 'Edit Listening Goal' : 'Set Listening Goal',
          minsListened.averageListened,
        ),
        [INSIGHT_GOAL.CURRENT_STREAK]: this.getInsightsInfo(
          INSIGHT_GOAL.CURRENT_STREAK,
          currentStreak.goal,
          currentStreak.totalCurrentStreak,
          currentStreak.goal ? 'Edit Streak Goal' : 'Set Streak Goal',
          0
        )
      };
    } else {
      return {
        [INSIGHT_GOAL.AVG_READ]: this.getInsightsInfo(
          INSIGHT_GOAL.AVG_READ,
          minsRead.goal,
          minsRead.totalRead,
          minsRead.goal ? 'Edit Reading Goal' : 'Set Reading Goal',
          minsRead.averageRead
        ),
        [INSIGHT_GOAL.AVG_LISTEN]: this.getInsightsInfo(
          INSIGHT_GOAL.AVG_LISTEN,
          minsListened.goal,
          minsListened.totalListened,
          minsListened.goal ? 'Edit Listening Goal' : 'Set Listening Goal',
          minsListened.averageListened
        ),
        [INSIGHT_GOAL.CURRENT_STREAK]: this.getInsightsInfo(
          INSIGHT_GOAL.CURRENT_STREAK,
          currentStreak.goal,
          currentStreak.totalCurrentStreak,
          currentStreak.goal ? 'Edit Streak Goal' : 'Set Streak Goal',
          0
        ),
        [INSIGHT_GOAL.MONTHLY]: this.getInsightsInfo(
          INSIGHT_GOAL.MONTHLY,
          bookRead.monthlyGoal,
          bookRead.monthlyBookRead,
          bookRead.monthlyGoal ? 'Edit Monthly Book Goal' : 'Set Monthly Book Goal',
          0
        ),
        [INSIGHT_GOAL.YEARLY]: this.getInsightsInfo(
          INSIGHT_GOAL.YEARLY,
          bookRead.yearlyGoal,
          bookRead.yearlyBookRead,
          bookRead.yearlyGoal ? 'Edit Yearly Book Goal' : 'Set Yearly Book Goal',
          0
        )
      };
    }
  }
  /**
   * To get insights from API
   */
  getInsights() {
    this.homeService.getInsights().subscribe(
      (response) => {
        if (!response.insights) {
          this.isLoading = false;
          this.progressiveEmitGoals();
          return;
        }
        const insights = this.handleInsights(response.insights);
        this.insights = Object.keys(insights).map((insightKey) => insights[insightKey]);
        this.isLoading = false;
        setTimeout(() => {
          if (this.setGoalToast === '') {
            const insLocLable = document.getElementById(`ins_${this.insightFocusElementId}`);
            const computedStyle = window.getComputedStyle(insLocLable);
            const transform = computedStyle.transform;
            const values = transform.split('(')[1].split(')')[0].split(',');
            const translateX = values[4];
            const updateStyle = document.querySelector('.carousel-cells');
            const oldStyle = updateStyle.getAttribute('style');
            updateStyle.setAttribute('style', `${oldStyle}transform: translateX(0px)`);
            updateStyle.setAttribute('style', `${oldStyle}transition: transform 200ms ease-out 0s; transform: translateX(${-Math.abs(Number(translateX))}px)`);
            document.querySelector('.carousel-arrow-prev').classList.remove('carousel-arrow-disabled');

            this.insightFocusElementId && this.focusElement(this.insightFocusElementId);
          }
        }, 2000);
        positionFooter(true);
        this.progressiveEmitGoals();
      },
      () => {
        this.isLoading = false;
        this.progressiveEmitGoals();
      }
    );
  }
  /**
   * To show toast message
   * @param type type of message
   * @param msg message
   */
  showToast = (type?: string, msg?: string) => {
    this.snackBarService.showSnackBar(type, msg);
  };
  /**
   * To cal set goal api
   * @param setGoalReq set goal request for API
   */
  setGoal(setGoalReq: SetGoalReq) {
    if (!setGoalReq) {
      return;
    }
    this.isLoading = true;
    this.homeService.setGoal(setGoalReq).subscribe(
      (response) => {
        if (response.responseCode === 200) {
          this.showToast(SNAKE_BAR_MODE.INFO, response.message);
          this.setGoalToast = response.message;
          setTimeout(() => {
            if (this.setGoalToast) {
              if (!navigator.userAgent.match(/(iPhone|iPad|iPod|Mac)/i)) {
                this.liveAnnouncer.announce('Success!' + ' ' + this.setGoalToast, 'assertive');
                this.setGoalToast = '';
              } else {
                this.setGoalToast = '';
              }
            }
          }, 1210);
          this.getInsights();
          return;
        }
        this.isLoading = false;
        this.showToast();
      },
      () => {
        this.isLoading = false;
        this.showToast();
      }
    );
  }
  /**
   * To track goal or insights of goals and insights
   * @param type goal type
   * @param goal goal number
   */
  trackGoalOrInsights = (type: string, goal: number) => {
    const goalEventMap: { [key: string]: keyof typeof HOME_EVENTS_KEY } = {
      [INSIGHT_GOAL.AVG_READ]: 'GOAL_DAILY_READ',
      [INSIGHT_GOAL.AVG_LISTEN]: 'GOAL_DAILY_LISTENED',
      [INSIGHT_GOAL.CURRENT_STREAK]: 'GOAL_CURRENT_STREAK',
      [INSIGHT_GOAL.MONTHLY]: 'GOAL_MONTHLY_READ',
      [INSIGHT_GOAL.YEARLY]: 'GOAL_YEARLY_READ',
      [INSIGHT_GOAL.MONTHLY_LISTENED]: 'GOAL_MONTHLY_LISTENED',
      [INSIGHT_GOAL.YEARLY_LISTENED]: 'GOAL_YEARLY_LISTENED'
    };
    const insightsEventMap: { [key: string]: keyof typeof HOME_EVENTS_KEY } = {
      [INSIGHT_GOAL.AVG_READ]: 'INSIGHTS_DAILY_READ',
      [INSIGHT_GOAL.AVG_LISTEN]: 'INSIGHTS_DAILY_LISTENED',
      [INSIGHT_GOAL.CURRENT_STREAK]: 'INSIGHTS_CURRENT_STREAK',
      [INSIGHT_GOAL.MONTHLY]: 'INSIGHTS_MONTHLY_READ',
      [INSIGHT_GOAL.YEARLY]: 'INSIGHTS_YEARLY_READ',
      [INSIGHT_GOAL.MONTHLY_LISTENED]: 'INSIGHTS_MONTHLY_LISTENED',
      [INSIGHT_GOAL.YEARLY_LISTENED]: 'INSIGHTS_YEARLY_LISTENED'
    };
    const eventMap = goal ? goalEventMap : insightsEventMap;
    this.homeAnalyticsService.trackHomeEvent(eventMap[type]);
  };
  /**
   * To open the set goal popup
   * @param insightDetails details for popup
   */
  openSetGoal(insightDetails: InSightGoalModal) {
    const { done, type, goal, avg } = insightDetails;
    this.trackGoalOrInsights(type, goal);
    insightDetails.content = insightDetails.content
      .replace('<done>', `${done}`)
      .replace('<avg>', `${avg}`)
      .replace('<add-s>', done > 0 ? 's' : '');
    this.openSetGoalPopup(insightDetails);
  }

  openSetGoalPopup(insightDetails: InSightGoalModal) {
    const goalModal = this.matDialog.open(SetGoalPopupComponent, {
      data: insightDetails,
      panelClass: 'set-goal-modal',
      ariaLabelledBy: 'set-reading-goal'
    });
    goalModal.afterClosed().subscribe((res) => {
      this.insightFocusElementId = insightDetails.id;
      setTimeout(() => this.setGoal(res), 2000);
    });
  }

  onResize() {
    const innerWidth = window.innerWidth;
    const curBreakpoint = this.responsiveOptions.find((option) => innerWidth >= option.breakpointWidth);
    this.numScroll = curBreakpoint.numScroll;
    this.numVisible = curBreakpoint.numVisible;
    this.hideNavButtons = curBreakpoint.numVisible >= this.insights.length;
  }
  progressiveEmitGoals() {
    this.progressiveGoalsEmit.emit();
  }
}
