import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ThirdPartyService } from '../../services/third-party.service';
import { SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { SOCIAL_MEDIA_LIST, FACEBOOK_SHARE_LINK, TWITTER_SHARE_LINK, SHARE_BASE_THIRD_PARTY_URL } from '@titledetails/shared/constants/title-details.constants';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';
import { EmailShareDialogComponent } from '@shared/components/email-share-dialog/email-share-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TitleDetailsAnalyticsService } from '@titledetails/shared/services/title-details.analytics.service';
import { TITLE_DETAILS_EVENTS_KEY } from '@titledetails/shared/constants/title-details.analytics.constants';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { MatMenuTrigger } from '@angular/material/menu';
@Component({
  selector: 'axis360-third-party-secondary-actions',
  templateUrl: './third-party-secondary-actions.component.html',
  styleUrls: ['./third-party-secondary-actions.component.scss']
})
export class ThirdPartySecondaryActionsComponent implements OnInit {
  @Input() componentApiData: any;
  navUrl: string;
  itemTitleName: string;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  constructor(
    public thirdPartyService: ThirdPartyService,
    public toastService: SnackBarService,
    public activatedRoute: ActivatedRoute,
    public matDialog: MatDialog,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public overlayContainer: OverlayContainer,
    public liveAnnouncer: LiveAnnouncer,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public titleDetailsService: TitleDetailsService,
    public clipboard: Clipboard) { }

  ngOnInit(): void {
    const { title: bookTitle = '' } = this.activatedRoute.snapshot.queryParams;
    this.itemTitleName = bookTitle;
  }

  isMobile() {
    return window.innerWidth < 610;
  }

  menuState(state) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      !this.isMobile && document.getElementsByTagName('html')[0].setAttribute('class', 'cdk-global-scrollblock');
      return;
    }
    setTimeout(() => {
      document.getElementById('loc_btnShare').setAttribute('aria-expanded', 'false');
    }, 1000);
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    !this.isMobile && document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
  }

  menuCloseEvent(event?) {
    if (navigator.userAgent.match(/(Mac)/i)) {
      event.stopPropagation();
      event.preventDefault();
      this.menuTrigger?.openMenu();
      setTimeout(() => {
        this.menuTrigger?.closeMenu();
      }, 2500);
    }
  }

  /**
   * Switch type base on social media options by clicking Share icon
   * @param [type] Pass by facebook,twitter,email,copyLink
   */
  shareTitleSocialMedia(type: string) {
    const searchParams = new URLSearchParams();
    const { titleID, ISBN, vendorCode, series, collectionId } = this.componentApiData;
    const shareUrl = SHARE_BASE_THIRD_PARTY_URL.replace('<originUrl>', `${window.origin}`)
      .replace('<itemId>', `${titleID}`)
      .replace('<ISBN>', `${ISBN}`)
      .replace('<vendorCode>', `${vendorCode}`)
      .replace('<collectionId>', `${collectionId}`)
      .replace('<title>', `${this.itemTitleName}`)
      .replace('<series>', `${series}`)
    const { FACEBOOK, TWITTER, EMAIL, COPY_LINK, INSTAGRAM } = SOCIAL_MEDIA_LIST;
    switch (type) {
      case FACEBOOK:
        searchParams.set('u', shareUrl);
        this.navUrl = FACEBOOK_SHARE_LINK.replace('<params>', searchParams.toString());
        window.open(this.navUrl, '_blank');
        break;
      case TWITTER:
        searchParams.set('url', shareUrl);
        this.navUrl = TWITTER_SHARE_LINK.replace('<params>', searchParams.toString());
        window.open(this.navUrl, '_blank');
        break;
      case EMAIL:
        this.openShareEmailTemplate(shareUrl);
        break;
      case INSTAGRAM:
        this.clipboard.copy(shareUrl);
        this.shareOnInstagram(shareUrl);
        break;
      case COPY_LINK:
        this.clipboard.copy(shareUrl);
        this.titleDetailsService.shareSnackBarToastMsg = true;
        this.toastService.showSnackBar(SNAKE_BAR_MODE.INFO, 'Link has been copied to clipboard');
        break;
      default:
        break;
    }
  }

  removeAnnounce() {
    this.liveAnnouncer.announce('', 'assertive');
  }

  /**
  *  Track by Title details Actions in href values
  * @param action  Selected action to pass
  * @param value   set the value in pass the action
  */
  trackByTitleDetailsActions(action: keyof typeof TITLE_DETAILS_EVENTS_KEY) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action);
  }


  openShareEmailTemplate(urlLink) {
    this.matDialog
      .open(EmailShareDialogComponent, {
        panelClass: 'email-modal-container',
        autoFocus: true,
        ariaLabelledBy: 'email-title',
        data: {
          titleName: this.itemTitleName,
          titleImage: this.configService.appSettings?.titleImageSrcFormat?.replace('{0}', this.componentApiData.ISBN),
          author: this.componentApiData.authors?.author?.join('; '),
          publisher: this.componentApiData.publisher,
          narrator: this.componentApiData.narrators?.narrator?.join('; '),
          series: this.componentApiData.series,
          format: this.componentApiData.format || '',
          shareUrl: urlLink,
          ccMailId: this.profileService?.currentProfile?.profileEmail || '',
          brandLogoImage: `${window.origin}/angular-app/assets/images/boundless_logo-primary.png`
        }
      })
      .afterClosed()
      .subscribe((response: any) => {
        if (response) {
          this.toastService.showSnackBar(response.mode, response.message);
          this.liveAnnouncer.announce(
            document.getElementById('toast-heading')?.innerHTML + ' ' + document.getElementById('toast-description')?.innerHTML,
            'assertive'
          );
        }
        setTimeout(() => {
          const shareButton = document.getElementById('loc_btnShare');
          shareButton.focus();
        }, 1000);
      });
  }

  shareOnInstagram(link) {
    const productLink = link;
    const instagramLink = "https://www.instagram.com/direct/inbox/";

    // You can customize the message as needed
    const message = encodeURIComponent("Check out this product on Amazon: " + productLink);

    // Combine the Instagram link with the product message
    const finalLink = instagramLink + "?message=" + message;

    // Open a new window or redirect the user to the Instagram link
    window.open(finalLink, "_blank");
  }


  cardAction(actions) {
    const { titleID, ISBN, bookTitle, format, isAvailable, collectionId } = this.componentApiData
    const action = {
      data: {
        type: actions.type,
        actiontype: actions.actionType,
        pageName: "tier3",
        ItemId: titleID,
        ISBN,
        title: bookTitle,
        vendorCode: format === 'VBK' ? "VBOOKS" : 'VIDEOS',
        formatType: format,
        isAvailable,
        collectionId
      }
    };
    this.thirdPartyService.cardAction(action)
  }
}
