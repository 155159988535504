<div>
    <div style="display: flex;justify-content: end;">
        <button class="btn-nobg-bluecolor btn-close" mat-icon-button cdkFocusInitial aria-label="Close" (click)="cancelClick()"
            (keydown.enter)="cancelClick()">
            <mat-icon aria-hidden="true">close</mat-icon>
        </button>
    </div>

    <div class="mail-template">
        <form [formGroup]="emailForm" (ngSubmit)="sendEmail()">
            <fieldset class="fieldset">
                <legend>
                    <h2 class="kz-visually-hidden">Share title via email</h2>
                </legend>
            <label for="to" class="template-mailid">To: </label>
            <input class="to-cc-subject" type="email" id="to" name="to" formControlName="toMailId"
                [ngClass]="{ 'is-invalid': submitted && f.toMailId.errors }">
            <div *ngIf="submitted && f.toMailId.errors" class="invalid-feedback">
                <p class="error-msg" role="alert" *ngIf="f.toMailId.errors.required"> Please enter the email</p>
                <p class="error-msg" role="alert" *ngIf="f.toMailId.errors.email">Whoops! The email you entered is not
                    in valid format. Please try again.</p>
            </div>
            <label for="cc" class="template-mailid">Cc :</label>
            <input class="to-cc-subject" type="email" id="cc" name="cc" formControlName="ccMailId"
                [ngClass]="{ 'is-invalid': submitted && f.ccMailId.errors }">
            <div *ngIf="submitted && f.ccMailId.errors" class="invalid-feedback">
                <p class="error-msg" role="alert" *ngIf="f.ccMailId.errors.email">Whoops! The email you entered is not
                    in valid format. Please try again.</p>
            </div>
            <label for="subject" class="template-mailid">Subject :</label>
            <input type="text" class="to-cc-subject" id="subject" name="subject" formControlName="mailSubject">
            <div class="template-send-mail">
                <button class="template-send-mail-btn" mat-icon-button>
                    <mat-icon svgIcon="kz-send-mail-icon" aria-hidden="true"></mat-icon>
                    Send Mail</button>
            </div>
            </fieldset>
        </form>
    </div>
    <table aria-label="Email Templates"
        style="max-width: 600px; border: 1px solid #98BFFF; padding: 30px 0 40px; margin: 40px auto auto; text-align: center; background: #EFF3FB; font-family: 'poppinsregular',sans-serif;">
        <tr style="display: none;">
            <th></th>
        </tr>
        <tr>
            <td style="background-color: #06275C; line-height: 24px; color: #ffffff; height: 64px;">
                <h2 style=" font-size: 20px; font-family: 'poppinssemibold',sans-serif;">Take a look at this title I
                    found in my Boundless library!</h2>
            </td>
        </tr>
        <tr>
            <td style="height: 50px;">&nbsp;</td>
        </tr>
        <tr>
            <td style="height: 300px; display: block; padding: 0 30px;"><img width="210" height="300"
                    style="border-radius: 20px;" [alt]="data.titleName + ' ' + data.format +' book cover Image'"
                    [src]="data.titleImage"></td>
        </tr>
        <tr>
            <td style="padding: 0 30px;">
                <h2
                    style="font-size: 28px; line-height: 34px; margin: 30px 0 0; font-family: 'poppinssemibold',sans-serif;">
                    {{data.titleName}}</h2>
                <p
                    style="font-size: 14px; line-height: 20px; color: #454545; margin: 10px 0 50px; font-family: 'poppinsregular',sans-serif;">
                    Writer:
                    {{data.author}}
                    Illustrator: {{data.publisher}}
                    Narrator: {{data.narrator}}
                    Publisher: {{data.publisher}}
                </p>
            </td>
        </tr>
        <tr>
            <td style="text-align: center; padding: 0 30px;">
                <a
                    style="padding: 10px 16px; font-size: 16px; background: #00508B; border-radius: 4px; color: #ffffff; border: none; text-decoration:none; font-family: 'poppinssemibold',sans-serif;pointer-events: none;">
                    Take a Look
                </a>
                <br>
                <img alt="Boundless by baker & taylor" style="margin-top: 60px; " width="150" height="40"
                    [src]="data.brandLogoImage">
            </td>
        </tr>
    </table>

</div>