<div class="main-ng-container">
    <axis360-spinner-loader isLoading="true" *ngIf="isLoading"></axis360-spinner-loader>
</div>
<div *ngIf="!isLoading && (showFeaturedProgram || isTrackingEnabled)" class="home-program-section">

    <div class="home-error-mag" *ngIf="isPPCOnlyLibrary && configService.ppcBugetReached">
        <axis360-budgetreached></axis360-budgetreached>
    </div>
    <axis360-featured-program [isTrackingEnabled]="isTrackingEnabled" *ngIf="showFeaturedProgram">
    </axis360-featured-program>
    <div class="insigt-badge" *ngIf="isTrackingEnabled && userService.isTrackingEnabled()">
        <axis360-goals-and-insights class="goals-main"></axis360-goals-and-insights>
        <axis360-badges class="badge-box"></axis360-badges>
    </div>
</div>