import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PROGRAMS_TABS } from '@readingprograms/shared/constants/programs.constants';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';

@Component({
  selector: 'axis360-programs-tabs',
  templateUrl: './programs-tabs.component.html',
  styleUrls: ['./programs-tabs.component.scss']
})
export class ProgramsTabsComponent implements AfterViewInit {
  PROGRAMS_TABS = PROGRAMS_TABS;
  @Output() selectedTabChangeEvent = new EventEmitter<string>();

  constructor(public programService: ProgramsService) {}

  ngAfterViewInit() {
    const tabActive = document.querySelector('#programTabs .mat-tab-label-active') || { id: '' };
    document.getElementById(tabActive.id)?.focus();
  }
  /**
   * To be called on selecting tab to change the active tab
   */
  selectedTabChange = (matTabEvent: MatTabChangeEvent) => {
    this.programService.selectedProgramsTab = matTabEvent.index;
    this.selectedTabChangeEvent.emit(matTabEvent.tab.textLabel);
  };
}
