<div class="book-title-details">
  <div class="book-of-month-img">
    <img class="img-responsive" [src]="titleActionContent?.imageurl" alt="{{titleActionContent?.title}} book cover">
  </div>
  <div class="title-rating-center" *ngIf="titleDetailsRating;else nodata">
    <div class="title-rating-review d-flex">
      <ng-container>
        <axis360-title-details-more [removeBooksInfo]="true" [titleDetailsRating]="titleDetailsRating"
          (titleRatingEvent)="handleRating($event)" (firstTitleReview)="scrollTitleReview($event)"
          (scrollTitleReview)="scrollTitleReview($event)">
        </axis360-title-details-more>
      </ng-container>
    </div>
  </div>
  <ng-template #nodata>
    <axis360-shimmer-cards [compName]="compName"></axis360-shimmer-cards>
  </ng-template>
  <div class="kz-progress-bar-wrapper"
    *ngIf="titleActionContent?.enableTracking && readingProgress > 0 && showInsightsAndBadges">
    <div class="kz-progress-bar-container">
      <div class="kz-progress-bar" [ngStyle]="{'width': (readingProgress >= 0)? (readingProgress + '%'):'0%'}">
      </div>
    </div>
    <div class="kz-progressbar-percentage-sec" attr.aria-label="Reading progress bar {{readingProgress}} percent"
      [ngStyle]="{'width': (readingProgress >= 0)? (readingProgress + '%'):'0%'}">
      <span class="kz-progressbar-percentage" role="text"><span class="kz-visually-hidden">Reading progress bar</span>
        {{readingProgress}}%</span>
    </div>
  </div>
</div>