
export const PRESS_READER_LATEST_ISSUE_LIMIT = 90;
export const THIRD_PARTY_TYPES = {
  BANNER_CAROUSEL: 'Card_Carousel',
  CATEGORIES_CAROUSEL: 'Category_Carousel',
  ITEMS_CAROUSEL: 'Item_Carousel',
  STACK_CAROUSEL: 'Stack_Carousel',
  ITEM_LIST_DETAILS: 'Item_List_Details',
  ITEM_DETAILS_TITLE: 'Item_Details_Title',
  ITEM_DETAILS_INLINE_CTA: 'Item_Details_Inline_CTA',
  ITEM_DETAILS_INFO: 'Item_Details_Info',
  ITEM_DETAILS_ICONLIST: 'Item_Details_Iconlist',
  ITEM_DETAILS_DESCRIPTION: 'Item_Details_Description',
  ITEM_DETAILS_DUEDATE: 'Item_Details_Duedate',
  ITEM_DETAILS_MAIN_CTA: 'Item_Details_Main_CTA',
  ITEM_DETAILS_RATINGS: 'Item_Details_Ratings',
  ITEM_DETAILS_REVIEW: 'Item_Details_Review',
  ITEM_DETAILS_PROGRESSBAR: 'Item_Details_Progressbar',
  ITEM_DETAILS_TAB_LINK_TITLES: 'Item_Details_Tab_Link_Titles',
  REFINERS: 'Refiners',
  ITEMS_GRID: 'Items_Grid',
  ITEM_DETAILS: 'Item_Details',
  SELECT_ISSUE_DATE: 'Select_Issue_Date',
  ARTICLE_CAROUSEL: 'Article_Carousel',
  WIDGETS_CAROUSEL: 'Widgets_Carousel'
};
export const THIRD_PARTY_GROUPS = {
  REFINERS: 'Refiners',
  VIDEOS: 'VIDEOS',
  VBOOKS: 'VBOOKS',
  PRESSRDR: 'PRESSRDR'
};
export const THIRD_PARTY_TIER = {
  TIER_ONE: 'tier1',
  TIER_TWO: 'tier2',
  TIER_THREE: 'tier3',
  SEARCH_LIST_PUBLICATIONS: 'searchListPublications',
  SEARCH_LIST: 'searchList',
  SEARCH_LIST_ARTICLES: 'searchListArticles',
  SEARCH_LIST_NEWSPAPER: 'searchListNewspaper',
  SEARCH_LIST_MAGAZINES: 'searchListMagazines',
  LATEST_ISSUES: 'latestIssues',
  RECOMMEND_TILE: 'recommendTitle',
  FEATURED_LIST: 'featuredList',
  SEARCH_HOME: 'searchHome',
  MYSTUFF_HISTORY: 'myStuffHistory',
  MYSTUFF: 'myStuff',
  MYSHEIF: 'myShelf'
};
export const NEWS_FILTER = {
  COUNTRY: 'countries',
  LANGUAGE: 'languages',
  CATEGORIES: 'categories'
};

export const FOCUS_ELEMENT = {
  checkout: { btn: 'moreOption', btnId: 'loc_playBtn' },
  renewtitle: { btn: 'moreOption', btnId: 'loc_more_option' },
  returntitle: { btn: 'moreOption', btnId: 'loc_Checkout' }
}

export const THIRD_PARTY_REDIRECT_URL = {
  PRESSRDR: `/PressReader?redirectionUrl=<redirectionUrl>`,
  VIDEO_PLAY: `/Action/PlayNowClick?itemId=<item>&ISBN=<isbn>&contentCollectionId=<contentCollectionId>`
};
export const THIRD_PARTY_ALERTS = {
  REDIRECT_CONF: {
    heading: 'PressReader',
    content:
      'Redirecting you to PressReader, where you can read newspapers and magazines. PressReader will open in a new tab\r\nDo you want to proceed ?',
    submitBtnText: 'Proceed',
    cancelBtnText: 'Cancel'
  }
};

export const THIRD_PARTY_VIDEOS_ALERTS = {
  REDIRECT_CONF: {
    heading: 'PressReader',
    content:
      'Redirecting you to PressReader, where you can read Video/VBooks. PressReader will open in a new tab\r\nDo you want to proceed ?',
    submitBtnText: 'Play',
    cancelBtnText: 'Cancel'
  }
};

export const TAB_GROUPS = [
  { name: 'Details', value: 'details' },
  { name: 'More Like This', value: 'moreLikeThis' },
  { name: 'Related Items', value: 'relatedItems' }
];

export const THIRD_PARTY_URLS = {
  TIER1: 'view/content',
  TIER2: 'view/content/list',
  TIER3: 'view/content/details'
};
export const THIRD_PARTY_SUBTYPE = {
  SUPPLEMENTS: 'Supplements',
  LATEST_ISSUES: 'Latest_Issues',
  ARTICLES: 'Articles',
  ITEMS: 'items',
  PUBLICATIONS: 'Publications',
  PUBLICATIONS_SEARCH_LIST: 'Publications_Search_List',
  VIDEOS: 'Videos',
  VBOOKS: 'vBooks',
  VIDEOS_FEATURED_LIST: 'Videos_Featured_List',
  PRESSRDR_FEATURED_LIST: 'Pressreader_Featured_List',
  PRESSREADER_FEATURED_REFINER: 'Pressreader_Featured_Refiner',
  VIDEOS_REFINERS: 'Video_Refiners',
  RECOMMEND_VIDEOS: 'Recommend_Videos',
  NEWSPAPERS: 'Newspapers',
  MAGAZINES: 'Magazines'
};
export const THIRD_PARTY_SUBTYPE_KEY = {
  [THIRD_PARTY_SUBTYPE.SUPPLEMENTS]: 'supplements',
  [THIRD_PARTY_SUBTYPE.LATEST_ISSUES]: '',
  [THIRD_PARTY_SUBTYPE.ITEMS]: 'items',
  [THIRD_PARTY_SUBTYPE.ARTICLES]: 'items',
  [THIRD_PARTY_SUBTYPE.PUBLICATIONS]: 'items',
  [THIRD_PARTY_SUBTYPE.VIDEOS]: "searchTitleResponse",
  [THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST]: "searchTitleResponse",
  [THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS]: 'recommendedTitles',
  [THIRD_PARTY_SUBTYPE.NEWSPAPERS]: 'items',
  [THIRD_PARTY_SUBTYPE.MAGAZINES]: 'items',
  [THIRD_PARTY_SUBTYPE.PRESSRDR_FEATURED_LIST]: 'items',
};

export const THIRD_PARTY_VENDOR_CODE = {
  PRESSRDR: 'PRESSRDR',
  VIDEOS: 'VIDEOS',
  VBOOKS: 'VBOOKS',
  WIDGETS: 'WIDGETS'
};

export const THIRD_PARTY_AGE = {
  AGE_RANGE: "age_range",
  ORIGINAL_AGE_RANGE: "originalAgeRange",
  AGE_TITLE: "name",
  AGE_NUMBER: "age",
  GEN_ADULT: "Gen Adult",
  KID: "Kid",
  TEEN: "Teen",
  EIGHTEEN_PLUS: "18+",
}

export const THIRD_PARTY_VENDOR_CODE_ENABLE = [ 'ALLVIDEOS', 'VBOOKS', 'VIDEOS' ] 

export const GET_FILTER_VALUE = {
  inVal: 'countries',
  inVal1: 'languages',
  inVal2: 'categories'
}

export const THIRD_PARTY_ACTIONS_CTA = {
  ['renewtitle']: 'Renew',
  ['returntitle']: 'Return',
  ['removefromcheckouthistory']: 'Remove'
};

export const THIRD_PARTY_DAYS = {
  ['Su']: 'Sunday',
  ['Mo']: 'Monday',
  ['Tu']: 'Tuesday',
  ['We']: 'Wednesday',
  ['Th']: 'Thursday',
  ['Fr']: 'Friday',
  ['Sa']: 'Saturday'
};

export const FORMAT_CODE_BY_VENDOR_LIST = {
  VID: 'vid',
  VBK: 'vbk'
}

export const FORMAT_TYPE_BY_VENDOR_CODE = {
  [FORMAT_CODE_BY_VENDOR_LIST.VID]: 'Video',
  [FORMAT_CODE_BY_VENDOR_LIST.VBK]: 'Videobook'
};

export const THIRD_PARTY_CATEGORY = {
  [THIRD_PARTY_VENDOR_CODE.PRESSRDR]: 'Newspapers & Magazines',
  [THIRD_PARTY_VENDOR_CODE.VIDEOS]: 'Videos',
  [THIRD_PARTY_VENDOR_CODE.VBOOKS]: 'vBooks'
};

export const PROFILE_TYPES = {
  ADULT: 'adult',
  TEEN: 'teen',
  KID: 'kid'
};
export const PROFILE_TYPES_UI_AUDIENCE_DESC = {
  [PROFILE_TYPES.ADULT]: '',
  [PROFILE_TYPES.TEEN]: 'teen,children',
  [PROFILE_TYPES.KID]: 'children'
};
export const PRESSRDR = 'PRESSRDR';

export const PUBLICATIONS_SORT_REFINER = {
  refiner: "sort By",
  filters: [
    {
      title: "Relevance",
      value: "searchrank",
      isSelected: false
    },
    {
      title: "Popularity",
      value: "popularity", // it should be 'rank' - for selection purpose tagged as popularity
      isSelected: false
    },
    {
      title: "Issue Date",
      value: "latestissuedate desc",
      isSelected: false
    },
    {
      title: "Title (A - Z)",
      value: "name asc",
      isSelected: false
    },
    {
      title: "Title (Z - A)",
      value: "name desc",
      isSelected: false
    },
    // {
    //   title: "Rank",
    //   value: "rank",
    //   isSelected: false
    // },

  ],
  selectedFilter: "Relevance"
};

export const ARTICLE_SORT_REFINER = {
  refiner: "sort By",
  filters: [
    {
      title: "Relevance",
      value: "Relevance",
      isSelected: false
    },
    {
      title: "Publication Date",
      value: "Date",
      isSelected: false
    }
  ],
  selectedFilter: "Relevance"
};

export const TIER2_SORT_REFINER_NM = {
  refiner: "sort By",
  filters: [
    {
      title: "Popularity",
      value: "rank desc",
      isSelected: false
    },
    {
      title: "Publication Date",
      value: "latestissuedate desc",
      isSelected: false
    },
    {
      title: "Relevance",
      value: "searchrank",
      isSelected: false
    }
  ],
  selectedFilter: "rank desc"
};

export const TIER2_SORT_REFINER_NM_02 = {
  refiner: "sort By",
  filters: [
    {
      title: "Popularity",
      value: "rank desc",
      isSelected: false
    },
    {
      title: "Publication Date",
      value: "latestissuedate desc",
      isSelected: false
    },
    {
      title: "Relevance",
      value: "searchrank",
      isSelected: false
    }
  ],
  selectedFilter: "rank desc"
};
export const TIER2_SORT_BY_REFINER_NM = {
  POPULARITY: 'popularity',
  RANK: 'rank'
}

export const FORMAT_TYPE_MAP = {
  ['ebook']: 'eBook',
  ['audio']: 'eAudio',
  ['book']: 'Print Book',
  ['kz-vbooks']: 'Videobook',
  ['kz-videos']: 'Videos'
};
export const THIRD_PARTY_VIDEO_REFINERS = {
  sortList: 'sortList',
  refinerList: 'refinerList',
  publicationDateList: 'publicationDateList',
  addedDateList: 'addedDateList'
};
export const THIRD_PARTY_REFINER_PUBLICATIONS_PILLS = {
  7: 'Last 7 Days',
  30: 'Last 30 Days',
  3: 'Last 3 Months',
  6: 'Last 6 Months',
  12: 'Last 12 Months'
}
export const THIRD_PARTY_REFINER_ADDEDDATE_PILLS = {
  7: 'Last 7 Days',
  30: 'Last 30 Days',
  90: 'Last 3 Months',
  180: 'Last 6 Months',
  360: 'Last 12 Months'
}
export const THIRD_PARTY_REFINER_AVAILABLE_PILLS = {
  forcheck: 'Available Now',
  all: 'All'
}
export const ADDEDDATE = 'dateFilter';
export const PUBLICATIONS_DATE = 'pubDateFilter';
export const AVAILLIBRARY = 'availlibrary';
