<div class="kz-learning-activity">
    <div class="kz-learning-head">
        <h2 class="learning-heading single-ellipsis"axis360EllipsisTitle >{{componentData.title}}</h2>
        <div class="primary-para description">
            <span class="description-text">{{componentData.description}}</span>
        </div>
    </div>
    <div class="kz-carousel learnings-carousel">
        <carousel [dots]="false" class="goals-learning-carousel" [margin]="0" [cellWidth]="113"
            [height]="190" (click)="trackCarousel($event)" (keydown)="trackCarousel($event)">
            <div class="carousel-cell" *ngFor="let category of learningActivityCategories; let i = index" role="listitem">
                <axis360-learning-activities-carousel [index]="i+1" [category]="category">
                </axis360-learning-activities-carousel>
            </div>
        </carousel>
    </div>
</div>