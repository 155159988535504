export const TITLE_DETAILS_CATEGORY = {
  TITLE_DETAILS: 'Title Details',
  RATING: 'Ratings',
  REVIEW: 'Review'
};

export const TITLE_DETAILS_SCREENS_KEY = {
  LANDING: 'LANDING'
};

export const TITLE_DETAILS_SCREENS: { [key: string]: string } = {
  [TITLE_DETAILS_SCREENS_KEY.LANDING]: 'Title Details'
};

export const TITLE_DETAILS_EVENTS_KEY = {
  ACTIONS_BACK: 'ACTION_BACK',
  ACTIONS_WISHLIST: 'ACTIONS_WISHLIST',
  TITLE_PRIMARY_ACTIONS: 'TITLE_PRIMARY_ACTIONS',
  TITLE_SECONDARY_ACTIONS: 'TITLE_SECONDARY_ACTIONS',
  ACTIONS_SHARE: 'ACTIONS_SHARE',
  ACTIONS_RATINGS: 'ACTIONS_RATINGS',
  ACTIONS_SEE_MORE: 'ACTIONS_SEE_MORE',
  ACTIONS_SEE_LESS: 'ACTIONS_SEE_LESS',
  ACTIONS_MORE_LIKE_THIS_TABS: 'ACTIONS_MORE_LIKE_THIS_TABS',
  ACTIONS_RELATED_ITEMS_TABS: 'ACTIONS_RELATED_ITEMS_TABS',
  ACTIONS_REVIEW_TABS: 'ACTIONS_REVIEW_TABS',
  ACTIONS_DETAILS_TABS: 'ACTIONS_DETAILS_TABS',
  ACTIONS_AUTHOR: 'ACTIONS_AUTHOR',
  ACTIONS_NARRATOR: 'ACTIONS_NARRATOR',
  ACTIONS_SERIES: 'ACTIONS_SERIES',
  ACTIONS_ATTRIBUTE: 'ACTIONS_ATTRIBUTE',
  ACTIONS_PUBLISHER: 'ACTIONS_PUBLISHER',
  ACTIONS_LEXILE: 'ACTIONS_LEXILE',
  ACTIONS_AWARDS: 'ACTIONS_AWARDS',
  ACTIONS_SUBJECT: 'ACTIONS_SUBJECT',
  WRITE_A_REVIEW: 'WRITE_A_REVIEW',
  CHECKOUT: 'CHECKOUT',
  PLACE_HOLD: 'PLACE_HOLD',
  SUSPEND_HOLD: 'SUSPEND_HOLD',
  ACTIVATE_SUSPEND: 'ACTIVATE_SUSPEND',
  DOWNLOAD: 'DOWNLOAD',
  CANCEL_DOWNLOAD: 'CANCEL_DOWNLOAD',
  READ_ONLINE: 'READ_ONLINE',
  REMOVE: 'REMOVE',
  READ_BOOKS: 'READ_BOOKS',
  CONTINUE_READ: 'CONTINUE_READ',
  RATING: 'RATING',
  CLOSE_RATING: 'CLOSE_RATING',
  ENTER_REVIEW: 'ENTER_REVIEW',
  SUBMIT_REVIEW: 'SUBMIT_REVIEW',
  EDIT_REVIEW: 'EDIT_REVIEW'
};
export const TITLE_DETAILS_EVENTS: { [key: string]: string } = {
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_ATTRIBUTE]: 'View Attribute CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_AUTHOR]: 'View Author - <actionName>',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_AWARDS]: 'View Awards CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_BACK]: 'Back CTA ',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_DETAILS_TABS]: '	View Details CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_LEXILE]: 'View Lexile CTA ',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_MORE_LIKE_THIS_TABS]: 'See More Like This CTA ',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_NARRATOR]: 'View Narrator - <actionName>',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_PUBLISHER]: 'View Publisher CTA ',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_RATINGS]: 'Ratings CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_RELATED_ITEMS_TABS]: 'View Related Items CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_REVIEW_TABS]: 'View Reviews CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_SERIES]: 'View Series - <actionName> ',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_SHARE]: 'Share CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_SUBJECT]: 'Subject - <actionName> ',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_SEE_MORE]: 'View See More CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_SEE_LESS]: 'View See Less CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIONS_WISHLIST]: 'Wishlist CTA',
  [TITLE_DETAILS_EVENTS_KEY.ACTIVATE_SUSPEND]: '	Activate Suspend CTA',
  [TITLE_DETAILS_EVENTS_KEY.CANCEL_DOWNLOAD]: 'Cancel Download CTA',
  [TITLE_DETAILS_EVENTS_KEY.CHECKOUT]: 'Checkout CTA',
  [TITLE_DETAILS_EVENTS_KEY.CLOSE_RATING]: 'Close CTA',
  [TITLE_DETAILS_EVENTS_KEY.CONTINUE_READ]: 'Continue CTA ',
  [TITLE_DETAILS_EVENTS_KEY.DOWNLOAD]: 'Download CTA ',
  [TITLE_DETAILS_EVENTS_KEY.EDIT_REVIEW]: 'Edit Review CTA',
  [TITLE_DETAILS_EVENTS_KEY.ENTER_REVIEW]: 'Enter review',
  [TITLE_DETAILS_EVENTS_KEY.PLACE_HOLD]: 'Place Hold CTA ',
  [TITLE_DETAILS_EVENTS_KEY.RATING]: 'Rating CTA ',
  [TITLE_DETAILS_EVENTS_KEY.READ_BOOKS]: 'Read CTA ',
  [TITLE_DETAILS_EVENTS_KEY.READ_ONLINE]: 'Read Online CTA',
  [TITLE_DETAILS_EVENTS_KEY.REMOVE]: 'Remove CTA ',
  [TITLE_DETAILS_EVENTS_KEY.SUBMIT_REVIEW]: 'Submit CTA',
  [TITLE_DETAILS_EVENTS_KEY.SUSPEND_HOLD]: 'Suspend Hold CTA ',
  [TITLE_DETAILS_EVENTS_KEY.TITLE_PRIMARY_ACTIONS]: 'Title Primary action CTA',
  [TITLE_DETAILS_EVENTS_KEY.TITLE_SECONDARY_ACTIONS]: 'Title secondary actions CTA',
  [TITLE_DETAILS_EVENTS_KEY.WRITE_A_REVIEW]: 'Write a review CTA'
};
export const AFTER_ACTION_CTA_FOCUS_ELEMENT_ID = {
  MOBILE: 'afterCtaFocusMobile',
  DESKTOP: 'afterCtaFocusPc'
}
