import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { Subscription } from 'rxjs';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { SearchCategory } from '@shared/data/shared.model';
import { CHECK_VENDOR_CODE, CATEGORY_TYPE as catType } from 'app/core/kz-search-results/shared/constant/search.constant';
import { CATEGORY_TYPE } from '@shared/constants/search.constant';
import { ConfigService } from '@shared/services/config.service';
import { deepClone, randomNumderGenerator } from '@shared/helper/app.util';
import { TITLE_LIST } from 'app/core/title-list/shared/constants/title-list-constants';
@Component({
  selector: 'axis360-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss']
})
export class NavigationTabsComponent implements OnInit, OnDestroy {
  @Input() tabsList = [];
  @Input() set multiTabsList(data) {
    this.tabsList = this.formatMultiOptions(data);
    const tier2Heading = document.getElementById('tier2-heading');
    tier2Heading?.removeAttribute('tabindex');
    const resourceHub = document.getElementById('loc_resource_hub');
    if (this.activeRouter.snapshot && this.activeRouter.snapshot.routeConfig.path === 'learning-activity') {
      resourceHub?.removeAttribute('tabindex');
      this.isResourceHub = true;
    }
    if (this.searchService.pillRemovedStatus) {
      if (this.tabsList[0]) {
        setTimeout(() => {
          const pillLists = document.getElementById('loc_remove_pill' + this.tabsList[0].value);
          pillLists && pillLists.focus()
          if (this.isResourceHub) {
            const resourceHubLists = document.getElementById('resource_hub_remove_pill' + this.tabsList[0].value);
            resourceHubLists && resourceHubLists.focus()
          }
        }, 0);
      } else{
        this.isEmptyPill.emit(true);
      }
      this.searchService.pillRemovedStatus = false;
    }
  }
  @Input() searchPills: any;
  @Input() centerTab = false;
  @Input() isPrograms = false;
  @Input() isTitleList = false;
  @Input() isMyStuff = false;
  @Input() isSearchListView = false;
  @Input() isLearningActivity = false;
  @Input() isWebResources = false;
  @Input() isTierTwo = false;
  @Input() activeMyStuff: string;
  @Input() navClass: string;
  @Input() programsDetails: string;
  @Output() removeSelection = new EventEmitter();
  @Output() selectedMystuffTitle = new EventEmitter();
  @Output() isEmptyPill = new EventEmitter<boolean>();
  navigationProgramSubscription: Subscription;
  pillsOrderListSubscription: Subscription;
  subjectAndfeaturedDataPillsSubscription: Subscription;
  titleTotalCountListSubscription: Subscription;
  compName = SHIMMER_COMPONENTS.NAV_TABS;
  TITLE_LIST = TITLE_LIST;
  selectedTab: number;
  isDefaultLearningActivity = false;
  showAccordion: boolean;
  selectedText: string;
  featuredListData: string;
  categoryType = CATEGORY_TYPE;
  thirdPartyLibraryList = [];
  orderList: string[];
  holdLimit: number;
  epopupValue: boolean;
  pillRemovedStatus: boolean;
  isResourceHub: boolean;
  randomNumderGenerator = randomNumderGenerator;
  constructor(
    public router: Router,
    public sharedAnalyticsService: SharedAnalyticsService,
    private titleListService: TitleListService,
    public programsService: ProgramsService,
    public getconditionservice: KzSearchService,
    public searchService: KzSearchService,
    public configService: ConfigService,
    public activeRouter: ActivatedRoute
  ) {
    this.epopupValue = this.titleListService.configService.currentLibrary.partnerSiteEnabled;
  }
  /**
   * Component init method
   */
  ngOnInit(): void {
    this.holdLimit =
      this.configService.currentLibrary && this.configService.currentLibrary.limits && this.configService.currentLibrary.limits.holdLimit;
    if (this.holdLimit === -1) {
      this.tabsList.forEach((value, index) => {
        value.name === 'holds' && this.tabsList.splice(index, 1);
      });
    }
    this.navigationProgramSubscription = this.programsService.navigationProgram.subscribe((res) => {
      if (res) {
        this.isPrograms = true;
        this.selectedTab = this.router.url.includes('open') ? 1 : 0;
      }
    });
    this.pillsOrderListSubscription = this.searchService.setPillsOrderList.subscribe((list) => {
      if (list) {
        this.orderList = deepClone(list);
      }
    });
    this.subjectAndfeaturedDataPillsSubscription = this.titleListService.subjectAndfeaturedDataPillsValue$.subscribe((value) => {
      if (value) {
        this.isTitleList = true;
        this.featuredListData = value;
      }
    });

    this.titleTotalCountListSubscription = this.searchService.titleTotalCountList.subscribe((count) => {
      if (this.isSearchListView && count) {
        this.setSearchPillsValue(count);
      }
    });
    this.thirdPartyLibraryList = this.configService.vendorDetails || [];
    this.sentReset();
    this.configService.focusMystuffTab && this.selectedMystuffTitle.emit('loc_label' + this.activeMyStuff);
  }

  setSearchPillsValue(searchPills) {
    const expected = Object.entries(searchPills)
      .filter(([key, value]) => key !== 'All' && value !== 0)
      .map(([key, value]) => {
        let text;
        let url;
        let vendorCode;
        switch (key) {
          case 'EBT':
            text = 'eBooks';
            url = '/view/search/eBook';
            vendorCode = '';
            break;
          case 'ABT':
            text = 'eAudios';
            url = '/view/search/eAudio';
            vendorCode = '';
            break;
          case 'learningActivity':
            text = 'Resource Hub';
            url = '/view/search/active_Resources';
            vendorCode = '';
            break;
          case 'webPathExpress':
            text = 'Web Resources';
            url = '/view/search/web_Resources';
            vendorCode = '';
            break;
          default:
            text = key;
            url = CHECK_VENDOR_CODE[key]
              ? `/view/search/${this.thirdPartyLibraryList.find((vendor) => vendor.vendor_code.includes(CHECK_VENDOR_CODE[key]))?.display_name
              }`
              : '/view/search/' + key;
            vendorCode = CHECK_VENDOR_CODE[key]
              ? CHECK_VENDOR_CODE[key]
              : this.thirdPartyLibraryList.find((vendor) => vendor.display_name.includes(key))?.vendor_code;
            break;
        }
        return {
          text,
          value: value.toString(),
          display_name: CHECK_VENDOR_CODE[key]
            ? `${this.thirdPartyLibraryList.find((vendor) => vendor?.vendor_code.includes(CHECK_VENDOR_CODE[key]))?.display_name}`
            : key,
          name: key,
          url,
          vendor_code: vendorCode
        };
      });
    expected.sort((a, b) => this.orderList.indexOf(a.text) - this.orderList.indexOf(b.text));
    this.tabsList = expected;
  }
  isFromMyShelf(isMyStuff: boolean) {
    if (window.location.href.includes('mystuff')) {
      this.configService.focusMystuffTab = isMyStuff;
    } else {
      this.configService.focusMystuffTab = false;
    }
  }

  formatMultiOptions(multiTabsList) {
    const tempList = [];
    Object.keys(multiTabsList).forEach((key) => {
      if (key === 'sortBy' || key === "sort" || key === 'sortby' || key === 'searhSort' || key === 'orderBy') {
        /** The Block needs  to me empty for specific reaseon. */
      } else if (key === 'defaulter') {
        this.isDefaultLearningActivity = multiTabsList[key];
      } else if (typeof multiTabsList[key][0] === 'string' || multiTabsList[key][0] instanceof String) {
        !multiTabsList[key].some((x) => x === 'All') && multiTabsList[key].forEach((value) => tempList.push({ key, value }));
      }
    });
    return tempList;
  }

  /**
   * clear Filters in tablist
   * @param item selected item
   */
  clearFilters(item) {
    const clearItem = this.tabsList.findIndex((val) => val.selected === item.selected);
    this.titleListService.tabCount = this.tabsList.length;
    const itemselected = this.tabsList[clearItem];
    if (itemselected.tabvalue === 'Clear All') {
      this.tabsList = [];
    } else {
      this.tabsList.splice(clearItem, 1);
    }
    if (this.tabsList && this.tabsList.length === 0) {
      this.titleListService.isFirstItemListSelection = true;
    }
    if (this.tabsList.length > 0) {
      const id = `loc_label${this.tabsList[0]?.tabvalue === 'Audiobooks' ? 'eAudio' : this.tabsList[0].tabvalue.split(" ").join("")}_clear`
      this.titleListService.elementId = id;
    }
    this.titleListService.clearFilter(itemselected);
  }

  removeRefiner(selection, value) {
    this.searchService.pillRemovedStatus = true;
    this.removeSelection.emit({ key: selection, value });
  }
  /**
   * Destroty method
   */
  ngOnDestroy(): void {
    this.programsService.navigationProgram.next(false);
    this.pillsOrderListSubscription && this.pillsOrderListSubscription.unsubscribe();
    this.navigationProgramSubscription && this.navigationProgramSubscription.unsubscribe();
    this.subjectAndfeaturedDataPillsSubscription && this.subjectAndfeaturedDataPillsSubscription.unsubscribe();
    this.titleTotalCountListSubscription && this.titleTotalCountListSubscription.unsubscribe();
  }
  /**
   * navigate url when change the program tab
   * @param event tab change event
   */
  onTabChanged(event: MatTabChangeEvent): void {
    document.getElementsByClassName('mat-tab-label')[0]?.removeAttribute("aria-label");
    if (event.index === 1) {
      document.getElementsByClassName('mat-tab-label-active')[0]?.setAttribute("aria-label", "Open Programs");
      this.router.navigate(['view/programs/open']);
      this.sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB_PROGRAM', { tabName: 'Open Programs' });
      return;
    }
    document.getElementsByClassName('mat-tab-label-active')[0]?.setAttribute("aria-label", "My Programs");
    this.router.navigate(['view/programs']);
    this.sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB_PROGRAM', { tabName: 'MyProgram' });
  }
  sentReset() {
    this.getconditionservice.getResetdata(this.isDefaultLearningActivity);
  }

  /**
   * Select the pills value showActive
   * @param [category] pass selected category
   */
  showActive(category: SearchCategory) {
    this.searchService.selectedCategory = category.value;
    this.showAccordion = this.selectedText !== this.categoryType[0].text;
    /** We remove the below code for ebook and eaudio subrate the value */
    /**
     * if (category.value === 'titles' || category.value === 'All') {
     * this.searchService.studyTitles.format = 'All';
     * }
     */
    /** Newly add the below code for change the category value based on format type */
    const formatType = category.name;
    this.searchService.studyTitles.format = formatType || 'All';
    this.searchService.format = formatType || 'All';
    this.categoryRefiner(category);
  }

  /**
   * Search Page Renavigating based on the Category Selection in Search Result page
   * @param [category] Pass selected category
   */
  categoryRefiner(category: SearchCategory) {
    let CategoryRefined = {
      [catType.ALL]: this.searchService.studyTitles,
      [catType.WEB_RESOURCES]: this.searchService.studySites,
      [catType.TITLES]: this.searchService.studyTitles,
      [catType.EAUDIO_TITLES]: this.searchService.studyTitles,
      [catType.EBOOKS_TITLES]: this.searchService.studyTitles,
      [catType.ACTIVE_RESOURCES]: this.searchService.learningActivity
    };
    if (!CategoryRefined[category.display_name]) {
      CategoryRefined = { ...CategoryRefined, ...{ [category.display_name]: this.searchService.studyTitles } };
    }
    this.routeSearch(CategoryRefined[category.display_name], category);
    sessionStorage.removeItem('applied-refiners');
  }

  /**
   * Search Page Renavigating based on the Category Selection in Search Result page.
   * @param [params] Pass the Params value in route Search category.
   * @param [category] Pass selected category
   */
  routeSearch(params, category: SearchCategory) {
    if (category.value !== 'All') {
      params.pageSize = 24;
    } else {
      params.pageSize = 10;
    }
    params.format = this.searchService.format;
    params.collections = '';
    params.pageTitle = category.text;
    params.ref = this.randomNumderGenerator();
    this.router.navigate([category.url], { queryParams: params });
  }
}
