export const API_INFO = {
  getNews: '/pressreader/pressreadercontent?sort=<sortOption>&limit=<limit>&offset=<offset>',
  getSites:
    '/Webpathexpress/WebPathExpressContent?searchtext=<searchtext>&language=<language>&format=<format>&sourcetype=<sourcetype>&subject=<subject>&grade=<grade>&domain=<domain>&pageindex=<pageindex>&pagesize=<pagesize>',
  getCountries: '/PressReader/GetCountries?code=<languageCode>',
  getLanguages: '/PressReader/GetLanguages?code=<countryCode>',
  getNewsFilters: '/PressReader/GetFilters',
  getUserAndSettings: '/ng/userandsettings',
  getLibrarySettings: '/ng/GetLibrarySettings',
  getAppSettings: '/appsetting/getsetting',
  getCurrentUser: '/Account/GetUser',
  getCurrentProfile: '/profiles/current',
  updateUserTracking:
    '/Account/UpdatePatronTracking?userName=<userName>&enableTracking=<enableTracking>&displayTrackingPrompt=<displayTrackingPrompt>',
  getNotifications: '/engage/messages?pageSize=<pageSize>&pageIndex=<pageIndex>',
  updateNotifications: '/engage/messages/update',
  getUnreadNotificationsCount: '/engage/messages/count?profileType=<profileType>',
  getProgramById: '/engage/programs/<programId>',
  getBooksById: '/engage/booksearch?itemIdList=<itemIdList>&fetchStateInfo=<fetchStateInfo>',
  // getBooksByIdV1: '/engage/booksearchv1?itemIdList=<itemIdList>&fetchStateInfo=<fetchStateInfo>',
  // getBooksByIdV1: '/engage/booksearchv1?fetchStateInfo=<fetchStateInfo>',
  getBooksByIdV1: '/engage/booksearchv1',
  getRelatedBooksById: '/engage/relatedbooksearch?itemIdList=<itemIdList>&collectionType=<collectionType>',
  getBooksByIsbn: '/engage/booksearch/isbn?isbnList=<isbnList>',
  getProgramProgress: '/engage/programs/<programId>/progress',
  leaveProgram: '/engage/programs/<programId>/unsubscribe',
  joinProgram: '/engage/programs/<programId>/subscribe',
  getPrograms: '/engage/programs/user?pageSize=<pageSize>&pageIndex=<pageIndex>&filterByStatus=<filterByStatus>&isMyshelf=<isMyshelf>',
  getOpenPrograms: '/engage/programs/open?pageSize=<pageSize>&pageIndex=<pageIndex>',
  getBookActions: '/engage/bookactions?fromCheckoutHistory=<fromCheckoutHistory>',
  bookActionClick: '/action/actionclickjson',
  markAsRead: '/engage/book/<bookId>/read?isbn=<isbn>&bookType=<bookType>&timeZone=<timeZone>&isManual=<isManual>&markAsRead=<markAsRead>&markAsNotRead=<markAsNotRead>',
  getBadges: '/engage/user/badges',
  getInterests: '/engage/user/interests',
  saveInterests: '/engage/user/updateinterests',
  getInsights: '/engage/user/insights',
  setGoal: '/engage/user/updategoals',
  pressReaderNewsPaper: '/pressreader/newspapers?offSet=<offSet>&limit=<limit>&inVal=<inVal>&inVal1=<inVal1>&orderBy=<orderBy>',
  pressReaderMagazines: '/pressreader/magazines?offSet=<offSet>&limit=<limit>&inVal=<inVal>&inVal1=<inVal1>&orderBy=<orderBy>',
  pressReaderCategories: '/pressreader/Categories?offSet=<offSet>&limit=<limit>',
  pressReaderCategoriesIcon: '/pressreader/Icons',
  pressReaderPublicationById: '/pressreader/PublicationsById?id=<id>',
  pressReaderPublications:
    '/pressreader/Publications?offSet=<offSet>&limit=<limit>&tp=<tp>&inVal=<inVal>&inVal1=<inVal1>&has=<has>&orderBy=<orderBy>&q=<searchTerm>',
  publicationsWithQuery: '/pressreader/PublicationsWithQuery?offSet=<offSet>&limit=<limit>&query=<searchTerm>&orderBy=<orderBy>&tp=<tp>',
  pressLatestIssues: '/pressreader/IssueByDateRange?cid=<cid>&limit=<limit>&fromDate=<fromDate>&toDate=<toDate>',
  pressIssueByDate: '/pressreader/IssueByDate?cid=<cid>&date=<date>',
  getMyLibraryList: '/Home/GetMyLibraryList',
  getListContent: '/Home/GetListContent?listId=<listId>&pagenumber=<pagenumber>&limit=<limit>&format=<format>&availability=<availability>',
  getMyStuffContent:
    '/MyStuff/GetListContent?listId=<listId>&pagenumber=<pagenumber>&limit=<limit>&format=<format>&availability=<availability>&sortField=<sortField>&sortOrder=<sortOrder>&pageToken=<pageToken>&searchTerm=<searchTerm>',
  getMyLibraryListActionButton:
    '/Home/GetMyLibraryListActionButton?ISBN=<ISBN>&ItemId=<ItemId>&IsAvailable=<IsAvailable>&IsRTV=<IsRTV>&FormatType=<FormatType>&isRecommendable=<isRecommendable>&TotalQuantity=<TotalQuantity>&OnOrderQuantity=<OnOrderQuantity>&CollectionType=<CollectionType>&Title=<Title>&PublicationDate=<PublicationDate>&fromCheckoutHistory=<fromCheckoutHistory>',
  getMyLibraryListActionButtonV2: '/Home/GetListActionButton',
  getMyLibraryListActionButtonFeaturedProgram: '/library/GetListActionButton',
  getMyCollectionList: '/Collection/MyCollectionList?collectionType=<collectionType>',
  getMyCollectionContent: '/Collection/GetListContent?listId=<listId>&pagenumber=<pagenumber>&limit=<limit>&format=<format>',
  getCollectionActionButton:
    '/Collection/GetCollectionActionButton?ISBN=<ISBN>&ItemId=<ItemId>&IsAvailable=<IsAvailable>&IsRTV=<IsRTV>&FormatType=<FormatType>&isRecommendable=<isRecommendable>&TotalQuantity=<TotalQuantity>&OnOrderQuantity=<OnOrderQuantity>&CollectionType=<CollectionType>&Title=<Title>',
  getBrowseBySubject: '/Home/GetSubMenuView?view=false',
  getFeaturedList: '/Home/GetSubMenuView?category=StaffPicks&view=false',
  getAnnouncements: 'Kidszone/Announcements',
  getBookId: 'Home/getAnnouncements',
  getMyStuffs: '/MyShelf/GetMyStuffs',
  updateEmail: '/account/updateEmail',
  getTitleDetails: '/Title/TitleDetails?itemId=<itemId>&ISBN=<isbn>&isRecommendable=<isRecommendable>&collectionType=Default&view=false',
  getTitleDetailsItem: '/Title/TitleDetails?itemId=<itemId>&view=false',
  getRecommendedTitles:
    'Title/RecommendedTitles?ISBN=<isbn>&authors=<authors>&genres=<genres>&subject=<subject>&itemId=<itemId>&isRecommendable=<isRecommendable>&formatType=<formatType>&view=false&profileTypeId=<profileTypeId>&collectionType=<collectionType>',
  getTitleReviews: '/Title/Reviews?itemId=<itemId>&view=false',
  getButtonViews: '/Title/GetButtonView?ISBN=<isbn>&itemId=<itemId>&isRecommendable=false&formatType=XPS&view=false',
  getAllReviews: '/Title/GetAllReviews?itemId=<itemId>&view=false',
  getPatronReviews: '/Title/CheckPatronReview?itemId=<itemId>&collectionId=<collectionId>',
  postReview: 'Title/ReviewSubmitted?itemId=<itemId>&Rating=<rating>&Content=<content>&UserName=<userName>&ReviewDate=<reviewDate>',
  getLibraryComponents: '/library/librarycomponents',
  getFeaturedListComponent: '/library/component?entityType=FEATURED_LIST',
  getSeriesByTitle: 'Search?term=<term>&searchby=<searchby>&view=false',
  getListContentTitle:
    'Search/GetListContent?sortby=<sortby>&sortorder=<sortorder>&term=<term>&searchby=<searchby>&availability=<availability>&format=<format>&releasedate=<releasedate>&addeddate=<addeddate>&language=<language>&agelevel=<agelevel>&collections=<collections>&pageSize=<pageSize>&page=<page>&ereadalong=<ereadalong>&itemid=<itemid>&view=false&profiletype=<profileTypeId>',
  getTitleList: 'Search/GetListContent?',
  postRatingSubmit: 'Title/AddTitleRating?itemId=<itemId>&ReviewRating=<bookRating>&ReviewContent=<reviewContent>&collectionId=<collectionId>',
  postReviewSubmitted: '/Title/SubmittReview?itemId=<itemId>&ReviewRating=<bookRating>&ReviewContent=<reviewContent>&collectionId=<collectionId>',
  getAllUserRatings: '/Title/GetAllUserRatings?itemId=<itemId>&collectionId=<collectionId>',
  getAllUserReviews: '/Title/GetAllUserReviews?itemId=<itemId>&recordsCount=<recordsCount>&offset=<offset>&collectionId=<collectionId>',
  getTitleAlternateFormat: '/Title/GetTitleAlternateFormats?isbn=<isbn>&itemId=<itemId>',
  getBookProgress: '/engage/User/BookProgress',
  getTitleBookProgressByItemId: 'Title/Progress/<itemId>',
  getMultipleTitleBookProgress: 'Title/Progress',
  getFeatureListRefiners: '/<controller>/Category?catid=<catid>&view=false',
  getFeatureList: '/<controller>/GetListContent?',
  getLoginData:
    '/Account/LogOn?isDialog=true&returnPage=<returnPage>&buttonValue=<buttonValue>&returnItemID=<returnItemID>&bookItemID=<bookItemID>',
  logoff: 'Account/LogOff',
  postLoginData: '/Account/LogOn',
  getQuestionForUsername: 'Account/GetQuestionForUsername',
  sendPassword: '/Account/SendPassword',
  postRegisterData: 'Account/Register?isDialog=true',
  getRegisterData: 'Account/Register?isDialog=true',
  verifyRegisterPassword: 'Account/VerifyRegistrationPassword?isDialog=true',
  getLearningActivityByISBN: 'LearningActivity/Title',
  getLearningActivity: '/LearningActivity',
  getLearningActivitySearch: 'LearningActivity/Search',
  getLearningActivityCategories: '/LearningActivity/Category?ProfileType=<profileType>&LibraryID=<libraryId>',
  getSearchRefiners: 'Search/Index?view=false',
  getSearchResults:
    'Search/GetListContent?view=false&page=<page>&pageSize=<pageSize>&term=<term>&searchby=<searchby>&addeddate=<addeddate>&format=<format>&agelevel=<agelevel>&availability=<availability>&collections=<collections>&releasedate=<releasedate>&language=<language>&axisattribute=<axisattribute>&sortby=<sortby>&profiletype=<profiletype>&isFromProgramMilestone=<isFromProgramMilestone>&bisacsdesc=<bisacsdesc>',
  getWebPathExpress:
    'Webpathexpress/WebPathExpressContent?searchtext=<searchtext>&language=<language>&format=<format>&sourcetype=<sourcetype>&subject=<subject>&grade=<grade>&domain=<domain>&pageindex=<pageindex>&pagesize=<pagesize>',
  getThirdPartyData: '/thirdparty/data',
  getThirdPartyVendors: '/thirdparty/vendors',
  getTierLayout: '/thirdparty/layout/<tier>?vendor_code=<vendorCode>',
  getThirdPartyLibrary: '/thirdparty/layout/library?vendor_code=<vendorCode>',
  getTermsConditions: '/Terms?type=<type>&view=false',
  getThirdPartyLibraryComponents: 'library/components?entityType=<entityType>',
  setAppBannerMessageFlag: '/Home/SetAppBannerMessageFlag',
  getlocation: '/pressreader/getlocation?libraryId=<libraryId>',
  getFeatureProgram: '/library/GetFeatureBannerDetails',
  addTitleListToProgram: '/engage/AddTitleListToProgram?programId=<programId>',
  addBookToProgram: '/engage/AddTitleListToProgram?programId=<programId>',
  UpdateTitleListToProgram: '/engage/UpdateTitleListToProgram?programId=<programId>',
  getSubMenuViewBrowse: 'Home/GetSubMenuViewV1',
  getRefinerBrowse: 'search/GetRefinerV1',
  getTitleListV1: 'Search/GetListContentV1',
  sendmail: '/thirdparty/sendmail',
  getPastCheckouts: '/Home/GetPastCheckouts'
};
