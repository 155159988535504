import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LIST_CONTENT_MOCK } from '@shared/data/mock/list.mock';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { PAGE_NUMBER, PAGE_SIZE } from '@titledetails/shared/constants/title-details.constants';
import { IBookDetail, IBookDetailById, ILearningActivityData } from '@titledetails/shared/data/title-details.model';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { MyStuffService } from 'app/my-stuff/shared/services/my-stuff.service';
import { noop } from 'rxjs';

@Component({
  selector: 'axis360-title-related-items',
  templateUrl: './title-related-items.component.html',
  styleUrls: ['./title-related-items.component.scss']
})
export class TitleRelatedItemsComponent implements OnChanges, OnInit {
  @Input() isLoad: boolean;
  @Input() ISBN: string;
  @Output() relatedTabsHide = new EventEmitter();
  @Input() learningActivityData: ILearningActivityData[];
  @Input() alternateFormatData: IBookDetail[];
  recommendedBooksRev: IBookDetail[];
  bookDetails: IBookDetail;
  learningActivityBooks: ILearningActivityData[];
  pageSize = PAGE_SIZE;
  pageNumber = PAGE_NUMBER;
  LIST_CONTENT_MOCK = LIST_CONTENT_MOCK.Items;
  parentRoute;
  bookIdArray = [];
  constructor(public titleDetailsService: TitleDetailsService, public profileInfoService: ProfileInfoService,
    public myStuffService: MyStuffService, public router: Router) { }

  ngOnInit() {
    const regExString = /(?:view\/)((.[\s\S]*))(?:\/title)/ig; //set ig flag for global search and case insensitive
    const regExString1 = regExString.exec(this.router.url)
    this.parentRoute = regExString1 ? regExString1[1] : '';
    const pageUrl = (this.router.url.includes('title/list') ? 'list' : '');
    const routeURL = (this.router.url.includes('subject') ? 'subject' : pageUrl);
    this.parentRoute = this.parentRoute ? this.parentRoute : routeURL;

  }

  /**
   *
   * To init Changes TitleRelatedItemsComponent
   * @param [changes]
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.isLoad = true;
    if (changes.isLoad.currentValue) {
      this.recommendedBooksRev = [];
      this.learningActivityBooks = this.learningActivityData;
      if (this.alternateFormatData.length > 0) {
        this.getAlternateFormatTitles();
      }
      else {
        this.isLoad = false;
      }
    } else {
      this.learningActivityBooks = this.learningActivityData;
      this.isLoad = false;
    }
  }
  /**
   * To get alternative format Titles
   */
  async getAlternateFormatTitles() {
    try {
      this.bookIdArray = this.alternateFormatData.map((book: any) => book.itemId);
      await this.getAlternateFormatDetails();
    } catch (e) {
      this.isLoad = false;
    }
  }
  /**
   * To get details of recommended books
   */
  async getAlternateFormatDetails() {
    if (this.bookIdArray.length === 0) {
      this.isLoad = false;
      return;
    }
    //retreive the recommended books based on Id for current title
    const bookDetailsResponse = await this.getBookDetail(this.bookIdArray);
    const recommendedBooks = [];
    if (bookDetailsResponse.length === 0) {
      this.isLoad = false;
      return;
    } else {
      bookDetailsResponse.forEach((books: IBookDetailById) => {
        this.bookDetails = {
          Title: books.title,
          ISBN: books.isbn,
          Author: books.author,
          ImageUrl: books.imageUrl,
          ItemId: books.itemId,
          FormatType: books.formatType,
          TotalQuantity: books.totalQuantity,
          OnOrderQuantity: books.onOrderQuantity,
          CollectionType: books.collectionType,
          IsAvailable: books.isAvailable,
          IsRTV: books.isRTV,
          titleUrl: `${window.origin}/ng/view/title/${books.itemId}/${books.isbn}`
        };
        recommendedBooks.push(this.bookDetails);
      });
    }
    for (const details of recommendedBooks) {
      this.getActionViewButtonForLibraryList(details);
    }
    this.recommendedBooksRev = recommendedBooks;
    this.isLoad = false;
  }

  /**
   * To get BookDetails from API
   * @param [_bookIdArray] pass the ItemId Array
   */
  async getBookDetail(_bookIdArray: string[]): Promise<any> {
    try {
      return (await this.titleDetailsService.getBooksByIdV1(_bookIdArray)).items;
    } catch (e) { }
  }

  /**
 * Generate action button for the my stuff card
 * @param listConstent list content
 */
  getActionViewButtonForLibraryList(listConstent) {
    this.myStuffService.getMyLibraryListActionButton(listConstent).subscribe((actionButtonResponse) => {
      this.alternateFormatData?.map((elm) => {
        if (elm.isbn === listConstent.ISBN) {
          listConstent.titleStateInfo = actionButtonResponse;
        }
      });
    }, noop);
  }

  /**
   * getLearningActivityByISBN in title related Items
   * @returns ILearningActivityData[]
   */
  async getLearningActivityByISBN() {
    const { profileType } = this.profileInfoService.currentProfile || {};
    const learningActivityData = {
      ISBN: this.ISBN,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      profileType: profileType
    }
    const learningActivityBooks = await this.titleDetailsService.getLearningActivityByISBN(learningActivityData);
    if (learningActivityBooks.statusCode !== 200) {
      this.learningActivityBooks = [];
      this.relatedTabsHide.emit('relatedItem');
      this.isLoad = false;
      return;
    }
    this.learningActivityBooks = learningActivityBooks.learningActivityIsbn?.data;
    learningActivityBooks.learningActivityIsbn?.data.length === 0 && this.relatedTabsHide.emit('relatedItem');
    this.isLoad = false;
  }

}
