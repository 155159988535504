<div class="login-container">
    <div class="login-close">
        <a class="close-icon" role="button" attr.aria-label="Close {{accountService.currentDialog}} dialog" (click)="closeDialog();accountAnalyticsService.trackAccountEvent('CLOSE_BUTTON')"
            (keydown.enter)="closeDialog()" href="javascript:;">
            <mat-icon aria-hidden="true" svgIcon="kz-close"></mat-icon>
        </a>
    </div>
    <div class="profile-image" aria-hidden="true" tabindex="-1">
        <img src="/angular-app/assets/images/kz-images/kz-user-profile.svg" alt="user profile icon">
    </div>
    <axis360-email-update *ngIf="emailUpdate"></axis360-email-update>
    <axis360-login *ngIf="openLoginForm"></axis360-login>
</div>