import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TermsConditionsService } from './shared/services/terms-conditions.service';
import { Title } from '@angular/platform-browser';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  @ViewChild('termsRef') public termsConditionRef: ElementRef;
  termsConditions: any;
  type: string;
  isLoading = true;
  pageAnnounce = '';
  constructor(public termsConditionService: TermsConditionsService,  private activatedRoute: ActivatedRoute, private titleService : Title, public commonService: CommonService) { }

  ngOnInit(): void {
    this.type = this.activatedRoute.snapshot.queryParams.type;
    if (this.type === 'privacy') {
      this.titleService.setTitle('Privacy Policy');
      this.pageAnnounce = 'Privacy Policy Page';
    } else {
      this.titleService.setTitle('Terms & Conditions');
      this.pageAnnounce = 'Terms and Conditions Page';
    }
    this.getTermsConditions();
    setTimeout(() => {
      this.commonService.isAnnouncedPage = false;
      this.commonService.toStopAnnoucedPage();
    }, 2000);
  }

  async getTermsConditions(){
    this.termsConditions = await this.termsConditionService.getTermsConditions(this.type);
    this.isLoading = false;
    this.termsConditionRef.nativeElement.innerHTML = this.termsConditions;
  }

}
