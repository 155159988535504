<div class="no-program-mag" *ngIf="programsLen === 0">
  <div class="no-challange-main">
    <div class="no-chlg-box no-programs">
      <p class="nochlgheadg" tabindex="0">No Reading Programs Yet!</p>
      <img aria-hidden="true" alt="No reading programs yet" src="/angular-app/assets/images/reading-program.svg" />
    </div>
  </div>
</div>

<div *ngFor="let programsObj of programs | keyvalue; let lastIndex = last">
  <ng-container *ngIf="programsObj.value?.length">
    <h1 class="card-list-category" aria-level="1" attr.aria-label="{{programsObj.key}} Programs">{{programsObj.key}}
      Programs
    </h1>
    <div class="card-list">
      <axis360-program-card class="card-item" [cardRole]="CARD_ROLE_MAP[tabName]" (programCardClickEvent)="onProgramCardClick($event)" [program]="program"
        *ngFor="let program of programsObj.value"></axis360-program-card>
      <div class="divider-line" [ngClass]="{'last-element': lastIndex}"></div>
    </div>
  </ng-container>
</div>
<axis360-spinner-loader></axis360-spinner-loader>