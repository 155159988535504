<div class="title-list-container" (window:resize)="closeFilter()">
    <h1 class="heading-1" aria-hidden="false" id="loc_title_list_heading" aria-relevant="text">
        <p class="kz-visually-hidden" *ngIf="filterData?.isLoadingRefiner">
            <span class="kz-visually-hidden" role="alert">
                Loading</span>
        </p>
        <p role="alert" aria-live="assertive" id="loc_title_list_count"class="kz-visually-hidden" *ngIf="!filterData?.isLoadingRefiner && istotalItemsResult">{{getHeadingAriaLabel()}}</p>
        <span class="result-text" aria-hidden="true"
            *ngIf="filterData?.menuType === TITLE_LIST.LIST || filterData?.menuType === TITLE_LIST.ALWAYSAVAILABLE || filterData?.menuType === TITLE_LIST.BASED_ON_INTEREST"
            [innerHTML]="(filterData?.selectedCategory)?
        filterData?.selectedCategory?.toUpperCase() === TITLE_LIST.LGBTQ?
        filterData?.selectedCategory?.toUpperCase():
        filterData?.selectedCategory?.toLowerCase():filterData?.selectedSubject?  filterData?.selectedSubject : BASED_ON_INTEREST"></span>
        <span class="result-text" aria-hidden="true"
            *ngIf="filterData?.menuType === TITLE_LIST.SUBJECT && !filterData?.isLoadingRefiner ">Results</span>
        <span class="result-count"
            *ngIf="(filterData?.menuType === TITLE_LIST.SUBJECT ) && istotalItemsResult && !filterData?.isLoadingRefiner"
            aria-hidden="true">({{totalItems}})
        </span>
        <span class="result-count"
            *ngIf="(filterData?.menuType === TITLE_LIST.LIST || filterData?.menuType === TITLE_LIST.ALWAYSAVAILABLE || filterData?.menuType === TITLE_LIST.BASED_ON_INTEREST)  && istotalItemsResult && !filterData?.isLoadingRefiner"
            aria-hidden="true">({{totalItems}})
        </span>
    </h1>
    <div class="icon-container">
        <a href="javascript:;"class="kz-edit-interest" aria-describedby="edittooltip"
            *ngIf="filterData?.selectedSubject === BASED_ON_INTEREST || filterData?.categoryId === CAROUSEL_CONST.Based_your_Interests" (click)="editInterest()"
            (keyup.enter)="editInterest()"><mat-icon class="edit-icon" svgIcon="kz-edit-icon"
                aria-hidden="true"></mat-icon><span class="kz-visually-hidden">Edit Interests</span></a>
        <div role="tooltip" id="edittooltip" aria-hidden="true">Edit Interests</div>

        <button cdkTrapFocusAutoCapture="true" #closeButton *ngIf="!isLargeScreen" id="refinerFilterIcon" class="btn-nobg-bluecolor filter-icon" aria-describedby="refinertooltip"
            (click)="openFilter()" (keydown.enter)="openFilter()">
            <mat-icon svgIcon="kz-filter-icon" aria-hidden="false"></mat-icon></button>
        <div role="tooltip" id="refinertooltip" class="refinertooltips">Refiner</div>

    </div>
</div>
<ng-container *ngIf="!filterData?.isLoadingRefiner">
    <axis360-navigation-tabs [tabsList]="tabsList" [isTitleList]="isTitleList"
        (removeSelection)="removeTitleListSelection($event)">
    </axis360-navigation-tabs>
</ng-container>
<p class="no-result-text primary-para mt-2"
    *ngIf=" !(filterData?.isLoadingRefiner || isLoading) && listContent?.length===0" [innerHTML]='titleNotMatchMessage'>
</p>
<div class="title-list-sec" *ngIf="!filterData?.isLoadingRefiner">
    <axis360-title-card [parentRoute]="parentRoute" [isProgramDetail]="true" class="title-card"
        *ngFor="let titleContent of listContent" [titleContent]="titleContent" (cardClickEvent)="titleCardClickEvent()">
    </axis360-title-card>
</div>
<axis360-spinner-loader isLoading="true" *ngIf="filterData?.isLoadingRefiner || isLoading"></axis360-spinner-loader>
