import { NotificationIconComponent } from './../../notifications/notification-icon/notification-icon.component';
import { Component } from '@angular/core';
import { NOTIFICATIONS_ELEM_ID } from '@notifications/shared/constants/notifications.constants';
import { KzNotificationsComponent } from '../kz-notifications.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { HeaderAnalyticsService } from 'app/header/shared/services/header.analytics.service';

@Component({
  selector: 'axis360-kz-notification-icon',
  templateUrl: './kz-notification-icon.component.html',
  styleUrls: ['./kz-notification-icon.component.scss']
})
export class KzNotificationIconComponent extends NotificationIconComponent {
  constructor(
    public matDialog: MatDialog,
    public notificationsService: NotificationsService,
    public headerAnalyticsService: HeaderAnalyticsService
  ) {
    super(matDialog, notificationsService);
  }
  /**
   * To open the kz-notifications on bell icon click
   */
  notificationClick() {
    this.headerAnalyticsService.trackHeaderEvent('NOTIFICATION_BELL');
    if (this.notificationOpened) {
      return;
    }
    this.notificationOpened = !this.notificationOpened;
    const notificationDialog = this.matDialog.open(KzNotificationsComponent, {
      ariaLabel: 'Notification',
      id: NOTIFICATIONS_ELEM_ID,
      panelClass: 'notification-modal-container',
      autoFocus: true
    });
    const removeRoleById = ['searchResultsViewCount', 'loc_title_list_count', 'searchResultsResouceHub', 'searchCarouselTitleCount', 'resultsCountReourceHub', 'resultsCountWPE', 'zeroResult', 'noResultText', 'no-result-text'];
    notificationDialog.afterClosed().subscribe(() => {
      this.notificationOpened = false;
      removeRoleById.map(x => {
        const getRemoveRoleId = document.getElementById(x);
        getRemoveRoleId && getRemoveRoleId?.removeAttribute('role');
      });
    });
  }
  removeTooltipAnnouce() {
    const profileTooltip = document.getElementById('loc_notifications_icon');
    profileTooltip?.removeAttribute('aria-describedby');
    document.querySelectorAll('.kz-card-tooltip').forEach((element) => {
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    });
  }
}
