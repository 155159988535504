<div class="badge-card kz-goals-badge-common" *ngIf="badge" [attr.tabindex]="isMobileDevice() || isTabletDevice() ? '0' : ''" [attr.role]="isMobileDevice() || isTabletDevice() ? 'button' : ''">
    <div class="badge-award-img" aria-hidden="true">
        <img *ngIf="badge.isEarned" class="badge-img" [src]="badge.badgeURLs[0]" alt="{{badge.badgeName}} badge">
        <img *ngIf="!badge.isEarned" class="badge-disable-img" src="/angular-app/assets/images/first-read-disable.svg"
            alt="{{badge.badgeName}} disable badge">
    </div>
    <div class="badge-text double-ellipsis" axis360EllipsisTitle>
        <p class="badge-text-container" [attr.aria-hidden]="isMobileDevice()">
           <span  tabindex="-1">{{badge.badgeName}}</span> <span class="kz-visually-hidden">{{badge.isEarned ? 'badge earned' : 'badge not earned'}}</span>
        </p>
    </div>
</div>