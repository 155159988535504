import { Component, Input, OnInit } from '@angular/core';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { setIvyCarouselProps } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-shimmer-cards',
  templateUrl: './shimmer-cards.component.html',
  styleUrls: ['./shimmer-cards.component.scss']
})
export class ShimmerCardsComponent implements OnInit {
  @Input() compName = '';
  @Input() showCarouselTitle = true;
  @Input() showCarouselSubTitle = true;
  SHIMMER_COMPONENTS = SHIMMER_COMPONENTS;
  shimmerCardList = [1, 2, 3, 4, 5, 6, 7]
  cellWidth = 164;
  cellMargin = 0;
  cellHeight = 252;
  cellsToScroll = 5;
  cellsToScrollNums = [5, 4, 3, 2];
  onResize = setIvyCarouselProps.bind(this, 160, 0, 248, 164, 0, 252, this.cellsToScrollNums);
  ngOnInit(): void {
    this.onResize = setIvyCarouselProps.bind(this, 160, 0, 248, 164, 0, 252, this.cellsToScrollNums);
  }
}
