export const MYSHELF_SCREENS_KEY = {
  MY_SHELF: 'MY_SHELF'
};

export const MYSHELF_SCREENS: { [key: string]: string } = {
  [MYSHELF_SCREENS_KEY.MY_SHELF]: 'Myshelf Page'
};

export const MYSHELF_CATEGORY = {
  CURRENTLY_CHECKOUT: 'Currently Checked Out',
  MY_PROGRAMS: 'Actions-Your programs',
  GOALS_INSIGHTS: 'Actions-Goals and insights',
  BADGES: 'Actions - Badges Carousel',
  CAROUSEL_NAV: 'Carousel Navigation'
};
export const MYSHELF_EVENTS_KEY = {
  CONTINUE_READING: 'CONTINUE_READING',
  CLICK_MY_PROGRAM: 'CLICK_MY_PROGRAM',
  CLICK_CAROUSEL_RIGHT: 'CLICK_CAROUSEL_RIGHT',
  CLICK_CAROUSEL_LEFT: 'CLICK_CAROUSEL_LEFT',
  EXPAND_GOALS_ACC: 'EXPAND_GOALS_ACC',
  COLLAPSE_GOALS_ACC: 'COLLAPSE_GOALS_ACC',
  CLICK_SEE_ALL_CHECKOUT: 'CLICK_SEE_ALL_CHECKOUT',
  ENTERS_YOUR_TARGET_GOAL: 'ENTERS_YOUR_TARGET_GOAL'
};
export const MYSHELF_EVENTS: { [key: string]: string } = {
  [MYSHELF_EVENTS_KEY.CONTINUE_READING]: 'Click continue reading CTA',
  [MYSHELF_EVENTS_KEY.CLICK_MY_PROGRAM]: 'Click any programs in the available list',
  [MYSHELF_EVENTS_KEY.CLICK_CAROUSEL_RIGHT]: 'Click carousel navigation - right',
  [MYSHELF_EVENTS_KEY.CLICK_CAROUSEL_LEFT]: 'Click carousel navigation - left',
  [MYSHELF_EVENTS_KEY.EXPAND_GOALS_ACC]: 'Click Goals carousel expand',
  [MYSHELF_EVENTS_KEY.COLLAPSE_GOALS_ACC]: 'Click Goals carousel collapse',
  [MYSHELF_EVENTS_KEY.CLICK_SEE_ALL_CHECKOUT]: 'Click see all - Currently checked out',
  [MYSHELF_EVENTS_KEY.ENTERS_YOUR_TARGET_GOAL]: 'Enter your target goal'
};
