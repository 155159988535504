<div class="insight-col">
    <div class="insight-widget" tabindex="0" role="button" [attr.aria-label]="insight.ariaLabel" id="{{insight.id}}"
        (click)="openSetGoalEvent.emit(insight)" (keyup.enter)="openSetGoalEvent.emit(insight)">
        <div class="widget-set" aria-hidden="true">
            <div class="insight-item-main {{insight.styleClass}}" [ngClass]="{'watch-goal': insight.goal}">
                <mat-progress-spinner *ngIf="insight.percentage" mode="determinate" [value]="insight.percentage"
                    [strokeWidth]="10" class="insight-progress">
                </mat-progress-spinner>
                <span class="insight-icon">
                </span>
            </div>
            <div class="insight-info-main">
                <span class="insight-value {{insight.styleClass}}" [ngClass]="{'watch-goal': insight.goal}">
                    {{insight.insightMetrics}}
                </span>
                <span class="info-message">
                    {{insight.insightSubTitle}}
                </span>
            </div>
        </div>
        <span class="widget-name" aria-hidden="true">{{insight.insightTitle}}</span>
        <img class="pencil-icon" src="/angular-app/assets/images/pencil.png" alt="" title="" aria-hidden="true">
    </div>
</div>