import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  HOME_CATEGORY,
  HOME_EVENTS,
  HOME_EVENTS_KEY,
  HOME_SCREENS,
  HOME_SCREENS_KEY,
  INTEREST_SURVEY_CATEGORY
} from '../constants/home.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof HOME_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class HomeAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of notifications analytics service.
   */
  constructor() {
    super(HOME_SCREENS, HOME_EVENTS);
  }
  /**
   * To track home page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof HOME_SCREENS_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track home events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackHomeEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(HOME_CATEGORY, action, altObj);
  }
  /**
   * To track interest survey page events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackInteresetSurveyEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(INTEREST_SURVEY_CATEGORY, action, altObj);
  }
}
