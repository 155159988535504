<div class="kz-press-reader">
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading; else content"></axis360-spinner-loader>
    <ng-template #content>
        <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{titleCardAnnounce}}</p>
        <h1 aria-hidden="true" class="sr-only" hidden>Title detail: "{{pageTitle}}"</h1>
        <div class="bread-crum-main" *ngIf="currentProfileFeatures[vendorCode]">
            <axis360-breadcrumb [breadcrumbs]="commonService.breadcrumbs"
                *ngIf="commonService.breadcrumbs && browserHistory"
                [breadcrumbsClass]="'kz-breadcrumb third-party-tier-three'"></axis360-breadcrumb>
        </div>
        <div class="tier-three-wrap" *ngIf="vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR;else nonVendorPressrdr">
            <ng-container *ngFor="let comp of layoutComponents">
                <axis360-third-party-card-details *ngIf="comp.type === THIRD_PARTY_TYPES.ITEM_DETAILS"
                (carouselEvent)="carouselEvent($event)"
                    [componentData]="comp" [isVenderEnabled]="currentProfileFeatures[vendorCode]">
                </axis360-third-party-card-details>
                <ng-container *ngIf="showRecommendation && currentProfileFeatures[vendorCode]">
                    <axis360-third-party-carousel-type [vendorCode]="vendorCode" [parentRoute]="parentRoute"
                        *ngIf="comp.type !== THIRD_PARTY_TYPES.ITEM_DETAILS" [componentData]="comp">
                    </axis360-third-party-carousel-type>
                </ng-container>

            </ng-container>
        </div>

        <ng-template #nonVendorPressrdr>
            <ng-container *ngIf="layoutComponents.length !== 0" >
                <div class="tier-three-wrap">
                    <axis360-third-party-card-details (firstTitleReview)="scrollTitleReview($event)"
                        (scrollTitleReview)="scrollTitleReview($event)" (carouselListEvent)="carouselListEvent($event)"
                        *ngIf="layoutComponentsItemDetails.type === THIRD_PARTY_TYPES.ITEM_DETAILS"
                        [componentData]="layoutComponentsItemDetails"
                        [isVenderEnabled]="currentProfileFeatures[vendorCode]">
                    </axis360-third-party-card-details>
                    <ng-container *ngIf="itemDetailsTabLinkTitles;else noTabLinkTitles">
                        <mat-tab-group class="custom-tab-group third-party-tier3-tab-group"
                            (selectedTabChange)="selectedTabChange($event)" #tabGroup>
                            <mat-tab class="custom-tab-labels" label="Details"
                                *ngIf="titleContent && layoutComponentsItemList">
                            </mat-tab>
                            <ng-container
                                *ngIf="titleContent && showRecommendationVideos && currentProfileFeatures[vendorCode]">
                                <mat-tab class="custom-tab-labels" label="More Like This">
                                </mat-tab>
                            </ng-container>
                            <mat-tab class="custom-tab-labels" label="Related Items"
                                *ngIf="learningActivityBooks?.length > 0 || alternateFormatTitle?.length > 0">
                            </mat-tab>

                        </mat-tab-group>


                        <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'Details'">
                            <axis360-third-party-base (acOnExecuteAction)="acOnExecuteAction($event)"
                                [componentData]="titleContent" [componentTemplate]="layoutComponentsItemList.jsonTempl"
                                [componentClass]="'third-party-card-details third-party-videos-detail third-party-details'"
                                *ngIf="titleContent"></axis360-third-party-base>
                        </div>
                        <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'More Like This'">
                            <ng-container *ngFor="let comp of layoutComponentsCarousel">
                                <ng-container *ngIf="showRecommendationVideos && currentProfileFeatures[vendorCode]">
                                    <axis360-third-party-carousel-type [vendorCode]="vendorCode"
                                        [isCarouselLoad]="isCarouselLoad" [parentRoute]="parentRoute"
                                        *ngIf="comp.type !== THIRD_PARTY_TYPES.ITEM_DETAILS" [componentData]="comp"
                                        (checkEventDataLoading)="checkEventDataLoading($event)">
                                    </axis360-third-party-carousel-type>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'Related Items'">
                            <axis360-spinner-loader isLoading="true" *ngIf="realatedItemLoader"></axis360-spinner-loader>
                            <axis360-title-related-items [ISBN]="isbn" [isLoad]="true"
                                [learningActivityData]="learningActivityBooks" [alternateFormatData]="alternateFormatTitle">
                            </axis360-title-related-items>
                        </div>
                    </ng-container>
                    <ng-template #noTabLinkTitles>
                        <div class="custom-tab-body">
                            <axis360-third-party-base [componentData]="titleContent"
                                [componentTemplate]="layoutComponentsItemList.jsonTempl"
                                [componentClass]="'third-party-card-details third-party-videos-detail third-party-details'"
                                *ngIf="titleContent"></axis360-third-party-base>
                        </div>
                        <div class="custom-tab-body">
                            <ng-container *ngFor="let comp of layoutComponents">
                                <ng-container *ngIf="showRecommendationVideos && currentProfileFeatures[vendorCode]">
                                    <axis360-third-party-carousel-type [vendorCode]="vendorCode"
                                        [isCarouselLoad]="isCarouselLoad" [parentRoute]="parentRoute"
                                        *ngIf="comp.type !== THIRD_PARTY_TYPES.ITEM_DETAILS" [componentData]="comp"
                                        (checkEventDataLoading)="checkEventDataLoading($event)">
                                    </axis360-third-party-carousel-type>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'Related Items'">
                            <axis360-spinner-loader isLoading="true" *ngIf="realatedItemLoader"></axis360-spinner-loader>
                            <axis360-title-related-items [ISBN]="isbn" [isLoad]="true"
                                [learningActivityData]="learningActivityBooks" [alternateFormatData]="alternateFormatTitle">
                            </axis360-title-related-items>
                        </div>
                    </ng-template>
                </div>
            <ng-container *ngIf="(titleContent && defaultSelectedTabs === 'Details' && itemDetailsReviewComponent)">
                <div class="review-sec" *ngIf="patronReviewsEnabled">
                    <p class="kz-visually-hidden" *ngIf="isLoadReviewMsgAnnouncer" role="alert">{{reviewSubmittedMsg}}</p>
                    <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
                    <div class="review-ratings">
                        <div>
                            <h3 aria-label="Reviews" class="reviews">Reviews</h3>
                            <p class="reviews-desc"> {{totalReviewsCount ? totalReviewsCount : 0}}
                                reader
                                reviews</p>
                        </div>
                        <div>
                            <button aria-label="Write a Review" id="review-btn"
                                class="btn-primary-blue btn-full-width btn-review" (click)="writeReview()">
                                Write a Review
                            </button>
                        </div>
                    </div>
                </div>
                <div class="reviews-content" *ngIf="patronReviewsEnabled && reviewList">
                    <axis360-title-review [reviewList]="reviewList"></axis360-title-review>
                    <axis360-spinner-loader></axis360-spinner-loader>
                </div>
            </ng-container>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="layoutComponents.length === 0" >
            <div class="third-party-three-no-title-sec">
                <p class="third-party-three-no-title-heading">No Vendor template Yet</p>
            </div>
        </ng-container>
    </ng-template>

</div>