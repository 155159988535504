<axis360-overlay-spinner></axis360-overlay-spinner>
<div [ngClass]="{'carousel-block': !userService.isLoginStatus}">
  <div class="pressreader-homepage">
    <div class="homepage-filterArea" *ngIf="!isPPCOnlyLibrary">
      <mat-form-field class="dropdown-input-field">
        <mat-label class="dropdown-input-label" aria-label="Availability">Availability</mat-label>
        <mat-select [disabled]="isCorouselLoaded" panelClass="title-filter-select"
          (openedChange)="filterChangeEvent($event)" class="dropdown-input-select" [(value)]="selectedAvailability"
          (selectionChange)="filterChangeForCarousel('Availability',$event.value)" disableOptionCentering>
          <mat-option *ngFor="let availability of homeAvailability" [value]="availability.value"
            [disabled]="configService.currentLibrary.partnerSiteEnabled === true && availability.value ==='All'">
            {{availability.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="dropdown-input-field">
        <mat-label class="dropdown-input-label" aria-label="Format">Format</mat-label>
        <mat-select [disabled]="isCorouselLoaded" panelClass="title-filter-select"
          (openedChange)="filterChangeEvent($event)" aria-expanded="false" class="dropdown-input-select"
          [(value)]="selectedFormat" (selectionChange)="filterChangeForCarousel('Format',$event.value)"
          disableOptionCentering>
          <mat-option *ngFor="let format of homeFormatType" [value]="format.value">
            {{format.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="(isPPCOnlyLibrary && configService.ppcBugetReached && !(showFeaturedProgram || isTrackingEnabled))">
      <div class="ppcBugetReached-error-mag">
        <axis360-budgetreached></axis360-budgetreached>
      </div>
    </div>
    <ng-container *ngFor="let myList of myLibraryListDataCheckout">
      <ng-container *ngIf="myList.listName === carouselConst.Currently_Reading && myList.Items.length > 0"
        class="homepage-carousel">
        <div class="carosel-head">
          <h2 class="carousel-title">{{myList.listName}}</h2>
          <button *ngIf="myList.Items.length === 10 || myList.Items.length > 10" role="link" class="achev-seeall-btn"
            aria-label="See all currently reading" (click)="redirectToListPage(myList.listName,'Checkout')"> SEE
            ALL </button>
        </div>
        <div class="currently-reading">
          <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="myList.Items"
            class="press-reader-carousel-block" type="Default" [title]="myList.listName"
            (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let myList of myLibraryListData">
      <div *ngIf="myList.listName === carouselConst.Assignment && myList.Items.length > 0"
        id="homeCarousel_{{myList.listId}}">
        <div class="carosel-head">
          <h2 class="carousel-title">{{myList.listName}}</h2>
          <button *ngIf="myList.Items.length === 10 || myList.Items.length > 10" role="link" class="achev-seeall-btn"
            aria-label="See all assignment" (click)="redirectToListPage(myList.listName,'Assignment')"> SEE
            ALL </button>
        </div>
        <div class="Assignment">
          <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="myList.Items"
            class="press-reader-carousel-block" type="Default" [title]="myList.listName"
            (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
        </div>
      </div>
    </ng-container>
    <axis360-newspapers-and-magazines class="press-reader-carousel-block" *ngIf="isPressReaderEnabled">
    </axis360-newspapers-and-magazines>
  </div>
  <ng-container *ngFor="let list of collectionData">
    <div class="homepage-carousel" id="homeCarousel_{{list.listId}}" *ngIf="isCollectionEnabled && !isPPCOnlyLibrary">
      <div class="carosel-head">
        <h2 class="carousel-title">Always Available</h2>
        <button *ngIf="list.Items.length === 10 || list.Items.length > 10" role="link" class="achev-seeall-btn"
          aria-label="See all always available"
          (click)="redirectToListPage('Always Available','Always Available','','ppc')"> SEE
          ALL</button>
      </div>
      <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="list.Items"
        class="press-reader-carousel-block" type="PPC" [title]="list.listName"
        (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
    </div>
  </ng-container>
  <ng-container *ngFor="let list of collectionData">
    <div class="homepage-carousel" id="homeCarousel_{{list.listId}}" *ngIf="isPPCOnlyLibrary">
      <div class="carosel-head">
        <h2 class="carousel-title">{{list.listName}}</h2>
        <button *ngIf="list.Items.length === 10 || list.Items.length > 10" role="link" class="achev-seeall-btn"
          attr.aria-label="See all {{list.listName}}"
          (click)="redirectToListPage('PPCOnly','PPCOnly',list.listId,'ppc')"> SEE
          ALL</button>
      </div>
      <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="list.Items"
        class="press-reader-carousel-block" type="PPC" [title]="list.listName"
        (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
    </div>
  </ng-container>
  <ng-container *ngFor="let myList of homeService.myLibraryListDataRecommendation">
    <div class="homepage-carousel" id="homeCarousel_{{myList.listId}}"
      *ngIf="myList.listId === carouselConst.Recommended_for_you && isRecommendationEnabled">
      <div class="carosel-head">
        <h2 class="carousel-title" [innerHTML]="myList.listName"></h2>
        <button *ngIf="myList.Items.length === 10 || myList.Items.length > 10" role="link" class="achev-seeall-btn"
          attr.aria-label="See all {{myList.listName}}"
          (click)="redirectToListPage(myList.listName,'Category',myList.listId)"> SEE
          ALL </button>
      </div>
      <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="myList.Items"
        class="press-reader-carousel-block" type="Default" [title]="myList.listName"
        (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
    </div>
    <div class="homepage-carousel" id="homeCarousel_{{myList.listId}}"
      *ngIf="myList.listId === carouselConst.Based_your_Interests">
      <div class="carosel-head">
        <h2 class="carousel-title">{{myList.listName}}</h2>
        <button *ngIf="myList.Items.length === 10 || myList.Items.length > 10" role="link" class="achev-seeall-btn"
          attr.aria-label="See all {{myList.listName}}"
          (click)="redirectToListPage(myList.listName,'Category',myList.listId)"> SEE
          ALL </button>
      </div>
      <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="myList.Items"
        class="press-reader-carousel-block" type="Default" [title]="myList.listName"
        (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
    </div>
  </ng-container>
  <ng-container *ngFor="let myList of myLibraryListData">
    <div class="homepage-carousel" id="homeCarousel_{{myList.listId}}"
      *ngIf="myList.listName !== carouselConst.Currently_Reading && myList.listName !== carouselConst.Assignment && myList.listId !== carouselConst.Recommended_for_you && myList.listId !== carouselConst.Based_your_Interests">
      <div class="carosel-head">
        <h2 class="carousel-title">{{myList.listName}}</h2>
        <button *ngIf="myList.Items.length === 10 || myList.Items.length > 10" role="link" class="achev-seeall-btn"
          attr.aria-label="See all {{myList.listName}}"
          (click)="redirectToListPage(myList.listName,'Category',myList.listId)"> SEE
          ALL </button>
      </div>
      <axis360-home-my-library-list-carousels usedIn="view" [myListContent]="myList.Items"
        class="press-reader-carousel-block" type="Default" [title]="myList.listName"
        (titleCheckedOutEvt)="titleCheckedOutEvent()"></axis360-home-my-library-list-carousels>
    </div>
  </ng-container>
</div>