<div class="filter-dialog" *ngIf="!isSort" title="Filter" cdkTrapFocus>
    <div class="panelaccordion">
        <mat-accordion [multi]=true>
            <ng-container *ngFor="let limiter of availableLimiters">

                <ng-container *ngIf="limiter.type === listSingleSelection">
                    <mat-expansion-panel class="filter-section accord-filter" (opened)="panelOpenStateListPub = true"
                        (closed)="panelOpenStateListPub = false" #panelList_pub hideToggle [expanded]="isExpanded(limiter)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="sort-and-filter-view">
                                    <div class="filter-label-display">{{ limiter.title }}
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-icon>{{panelList_pub.expanded? 'expand_more' : 'chevron_right'}}</mat-icon>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <ng-container *ngIf="limiter.facets">
                                <ul class="display-filtervalue" role="list">
                                    <ng-container *ngFor="let facet of limiter.facets">
                                        <li role="listitem">
                                            <button mat-button
                                                [attr.role]="limiter.title !== 'Country' ? 'radio':'checkbox'"
                                                (click)="applyLimiter(limiter.title, facet, limiter.title !== 'Country')"
                                                [attr.aria-checked]="facet.selected">
                                                <span [ngClass]="{'active': facet.selected}">{{ facet.text }}<span
                                                        *ngIf="showCount"><sup> ({{facet.count}})</sup></span></span>
                                                <span class="icontick" *ngIf="facet.selected">
                                                    <mat-icon>done</mat-icon>
                                                </span>
                                            </button>
                                        </li>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </ng-template>
                        <div>                            
                            <span class="refiner-clear"
                            *ngIf="panelList_pub.expanded && showClearFilter(limiter.facets)"
                            (click)="clearFilter(limiter.title)" (keyup.enter)="clearFilter(limiter.title)">
                            <span id="refinerClear" class="refinerClear" role="button"
                                [attr.tabindex]="panelList_pub.expanded ? 0 : -1">clear</span>
                            </span>
                        </div>                    
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="limiter.type !== tabType && limiter.type !== listSingleSelection">
                    <mat-expansion-panel class="filter-section accord-filter" (opened)="panelOpenStateList = true"
                        (closed)="panelOpenStateList = false" #panelList hideToggle [expanded]="isExpanded(limiter)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="sort-and-filter-view">
                                    <div class="filter-label-display">{{ limiter.title }}
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-icon>{{panelList.expanded? 'expand_more' : 'chevron_right'}}</mat-icon>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <ng-container *ngIf="limiter.facets">
                                <ul class="display-filtervalue" role="list">
                                    <ng-container *ngFor="let facet of limiter.facets">
                                        <li role="listitem">
                                            <button mat-button
                                                [attr.role]="limiter.title !== 'Country' ? 'radio':'checkbox'"
                                                (click)="applyLimiter(limiter.title, facet, limiter.title !== 'Country')"
                                                [attr.aria-checked]="facet.selected">
                                                <span [ngClass]="{'active': facet.selected}">{{ facet.text }}<span
                                                        *ngIf="showCount"><sup> ({{facet.count}})</sup></span></span>
                                                <span class="icontick" *ngIf="facet.selected">
                                                    <mat-icon>done</mat-icon>
                                                </span>
                                            </button>
                                        </li>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </ng-template>
                        <div>                            
                            <span class="refiner-clear"
                            *ngIf="panelList.expanded && showClearFilter(limiter.facets)"
                            (click)="clearFilter(limiter.title)" (keyup.enter)="clearFilter(limiter.title)">
                            <span id="refinerClear" class="refinerClear" role="button"
                                [attr.tabindex]="panelList.expanded ? 0 : -1">clear</span>
                            </span>
                        </div>                    
                    </mat-expansion-panel>
                </ng-container>

                <ng-container *ngIf="limiter.type === tabType && limiter.type !== listSingleSelection">
                    <mat-expansion-panel class="filter-section filtertype-accord" (opened)="panelOpenStateTab = true"
                        (closed)="panelOpenStateTab = false" #panelTab hideToggle [expanded]="isExpanded(limiter)">
                        <mat-expansion-panel-header cdkFocusInitial>
                            <mat-panel-title>
                                <div class="sort-and-filter-view">
                                    <div class="filter-label-display">{{limiter.title}}</div>
                                </div>
                            </mat-panel-title>
                            <mat-icon>{{panelTab.expanded? 'expand_more' : 'chevron_right'}}</mat-icon>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <ng-container>
                                <ul class="display-sortvalue" role="list">
                                    <ng-container *ngFor="let facet of limiter.facets">
                                        <li role="listitem">
                                            <button class="rectangle" role="checkbox"
                                                [ngClass]="{'active': facet.selected}"
                                                (click)="applyLimiter(limiter.title, facet, false)"
                                                [attr.aria-checked]="facet.selected">
                                                <span>{{facet.text}}</span></button>
                                        </li>
                                    </ng-container>
                                </ul>
                            </ng-container>
                        </ng-template>
                        <div>                            
                            <span class="refiner-clear"
                            *ngIf="panelTab.expanded && showClearFilter(limiter.facets)">
                            <span id="refinerClear" class="refinerClear" (click)="clearFilter(limiter.title)"
                                (keyup.enter)="clearFilter(limiter.title)" role="button"
                                [attr.tabindex]="panelTab.expanded ? 0 : -1">clear</span>
                            </span>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
            </ng-container>
        </mat-accordion>
    </div>
</div>

<div class="sort-dialog" *ngIf="isSort" title="Sort" cdkTrapFocus>
    <ul class="displaysort-value" id="sortSection" role="list">
        <li *ngFor="let sortOption of sortOptions" role="listitem">
            <button role="radio" (click)="applySort(sortOption)" [attr.aria-checked]="sortOption.selected" cdkFocusInitial>
                <span [ngClass]="{'active': sortOption.selected}">{{sortOption.displayable}}</span>
                <span class="icontick" *ngIf="sortOption.selected">
                    <mat-icon>done</mat-icon>
                </span>
            </button>
        </li>
    </ul>
</div>