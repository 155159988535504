import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog.component';
import { CommonService } from '@shared/services/common.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { Observable } from 'rxjs';
import { PROFILES_ALERTS } from '../constants/profiles-alerts.constants';
import { PROFILES_API_INFO } from '../constants/profiles.api.constants';
import { PROFILE_TYPE_ID } from '../constants/profiles.constants';
import {
  ProfilesResponse,
  ProfileAvatarResponse,
  APIResponse,
  ProfileTypeResponse,
  ProfileType,
  CreateProfileRequest,
  ParentalPinResponce,
  AddProfileFormInfo,
  GetProfileByIdResponse,
  EditProfileFormInfo,
  UpdateProfileRequest,
  ProfileDetail,
  SecurityAnswerResponce,
  DeleteAccountResponse,
  ParentalPinProfile,
  OTPResponse
} from '../data/profiles.model';
import { ProfilesAnalyticsService } from './profiles.analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService extends CommonService {
  private avatarUrl;
  avatarData: ProfileAvatarResponse[];
  profileTypes: ProfileType[] = [];
  profileTypesMap: { [profileType: string]: number } = PROFILE_TYPE_ID;
  addProfileFormInfo: AddProfileFormInfo = {};
  editProfileFormInfo: EditProfileFormInfo = {};
  isProfileNavaigation: string;
  /**
   * Setter for avatar url on selecting avatars in /avatars
   */
  set avatar(url: string) {
    this.avatarUrl = url;
  }
  /**
   * Getter of selected of avatar url
   */
  get avatar() {
    return this.avatarUrl;
  }
  /**
   * Setter for add profile form on selecting avatars in /avatars
   */
  set addProfileForm(addProfileFormInfo: AddProfileFormInfo) {
    this.addProfileFormInfo = addProfileFormInfo;
  }
  /**
   * Getter of add profile form
   */
  get addProfileForm(): AddProfileFormInfo {
    return this.addProfileFormInfo;
  }
  /**
   * Setter for edit profile form on selecting avatars in /avatars
   */
  set editProfileForm(editProfileFormInfo: EditProfileFormInfo) {
    this.editProfileFormInfo = editProfileFormInfo;
  }
  /**
   * Getter of edit profile form
   */
  get editProfileForm(): EditProfileFormInfo {
    return this.editProfileFormInfo;
  }
  /**
   * Creates an instance of programs service.
   * @param http http client
   */
  constructor(
    private http: HttpClient,
    public matDialog: MatDialog,
    protected spinnerService: SpinnerService,
    private profilesAnalyticsService: ProfilesAnalyticsService,
    public profileInfoService: ProfileInfoService
  ) {
    super(matDialog);
  }
  /**
   * To call the api with overlay spinner
   * @param serviceObservable service observable to call api
   * @returns observable with service
   */
  private withOverlaySpinner<T>(serviceObservable: Observable<T>) {
    return this.spinnerService.withObservable(serviceObservable, false, false);
  }

  /**
   *  Get Profile list
   * @returns  profile list
   */
  getProfiles(): Observable<ProfilesResponse> {
    return this.http.get<ProfilesResponse>(PROFILES_API_INFO.getProfiles);
  }
  /**
   * Get Avatars list
   * @returns Avatars list
   */
  getAvatars(): Observable<ProfileAvatarResponse[]> {
    return this.http.get<ProfileAvatarResponse[]>(PROFILES_API_INFO.getAvatars);
  }

  updatePinStatus(profileId: string): Observable<any> {
    return this.http.get<any>(PROFILES_API_INFO.pinStatus.replace('<profileId>', profileId));
  }
  /**
   * create new profile without profile ID
   * @param profile object
   * @returns profile with profile ID
   */
  createProfile(profile: CreateProfileRequest): Observable<ProfilesResponse> {
    return this.withOverlaySpinner(this.http.post<ProfilesResponse>(PROFILES_API_INFO.createProfile, profile));
  }

  createProfileNew(profile: UpdateProfileRequest): Observable<ProfilesResponse> {
    return this.withOverlaySpinner(this.http.post<ProfilesResponse>(PROFILES_API_INFO.createProfile, profile));
  }
  /**
   * updates profile with profileId
   * @param profileId string
   * @param profileData object
   * @returns Profile Successfully updated
   */
  updateProfile(profileId: string, profileData: UpdateProfileRequest): Observable<APIResponse> {
    return this.withOverlaySpinner(
      this.http.put<APIResponse>(PROFILES_API_INFO.updateProfile.replace('<profileId>', profileId), profileData)
    );
  }
  /**
   * updates profile with profileId
   * @param profileId string
   * @param profileData object
   * @returns Profile Successfully updated
   */
  updateProfileDetails(profileId: string, profileData: UpdateProfileRequest): Observable<APIResponse> {
    return this.withOverlaySpinner(
      this.http.put<APIResponse>(PROFILES_API_INFO.updateProfileDetails.replace('<profileId>', profileId), profileData)
    );
  }
  /**
   * updates profile with profileId
   * @param profileId string
   * @param profileData object
   * @returns Profile Successfully updated
   */
  updateProfileFeatures(profileId: string, profileData: UpdateProfileRequest): Observable<APIResponse> {
    return this.withOverlaySpinner(
      this.http.put<APIResponse>(PROFILES_API_INFO.updateProfileFeatures.replace('<profileId>', profileId), profileData)
    );
  }
  /**
   * Deactivated Profile
   * @param profileId string
   * @returns Profile Successfully Deactivated
   */
  deactivateProfile(profileId: string, isPrimary: string): Observable<APIResponse> {
    return this.withOverlaySpinner(
      this.http.put<APIResponse>(
        PROFILES_API_INFO.deactivateProfile.replace('<profileId>', profileId).replace('<isPrimary>', isPrimary),
        ''
      )
    );
  }
  /**
   * Get Parental pin
   * @param parentalPin string
   * @returns profile details with libraryName
   */
  validateParentalPin(parentalPin: string, profileId: string): Observable<ParentalPinResponce> {
    return this.withOverlaySpinner(
      this.http.post<ParentalPinResponce>(PROFILES_API_INFO.validateParentalPin.replace('<profileId>', profileId), { parentalPin })
    );
  }

  generateOTP(profileId: string): Observable<OTPResponse> {
    return this.withOverlaySpinner(this.http.get<OTPResponse>(PROFILES_API_INFO.generateOTP.replace('<profileId>', profileId)));
  }

  validateOTP(profileId: string, otp: string): Observable<OTPResponse> {
    return this.withOverlaySpinner(
      this.http.get<OTPResponse>(PROFILES_API_INFO.validateOTP.replace('<profileId>', profileId).replace('<otp>', otp))
    );
  }

  /**
   * Get profile types
   * @returns list of profile types
   */
  getProfileTypes(): void {
    this.http
      .get<ProfileTypeResponse>(PROFILES_API_INFO.getProfileTypes)
      .toPromise()
      .then((response) => {
        this.profileTypes = response.profiles;
        this.profileTypesMap = response.profiles.reduce((acc, cur) => {
          acc[cur.profileType.toLowerCase()] = cur.profileTypeId;
          return acc;
        }, {});
      })
      .catch(() => {
        this.profileTypes = [];
      });
  }
  /**
   * Set Parental Pin
   * @param parentalPin string
   * @returns set new Parental Pin
   */
  setParentalPin(parentalPin: string, profileId: string): Observable<ParentalPinResponce> {
    return this.withOverlaySpinner(
      this.http.post<ParentalPinResponce>(PROFILES_API_INFO.setParentalPin.replace('<profileId>', profileId), { parentalPin })
    );
  }
  /**
   * To return profileTypeId based on type
   * @param type profile type
   */
  getProfileTypeId = (type: string): number => this.profileTypesMap[type.toLowerCase()];

  /**
   * To show the failure alert modal
   * @param  alertDataKey error alert dialog data key
   * @param  data error alert dialog data
   */
  showFailureAlertDialog(alertDataKey: keyof typeof PROFILES_ALERTS, data?: Partial<AlertDialog>) {
    this.profilesAnalyticsService.trackScreen('ERROR');
    this.showAlertDialog({
      ...PROFILES_ALERTS[alertDataKey],
      ...data,
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('OKAY');
      }
    });
  }
  /**
   * To reload the page on reload button press from alert
   */
  reloadPage = () => {
    window.location.reload();
  };
  /**
   * To show the error alert modal
   */
  showErrorAlertDialog() {
    this.profilesAnalyticsService.trackScreen('ERROR');
    this.showErrorAlert();
  }
  /**
   * Get profile info by profile id
   * @param profileId profile id
   * @returns profile info
   */
  getProfileById(profileId: string): Observable<GetProfileByIdResponse> {
    return this.http.get<GetProfileByIdResponse>(PROFILES_API_INFO.getProfileById.replace('<profileId>', profileId));
  }

  /**
   * Switch profile
   * @param profile profile info
   * @returns profile info
   */
  selectProfile(profile: ProfileDetail): Observable<ParentalPinProfile> {
    return this.withOverlaySpinner(this.http.post<ParentalPinProfile>(PROFILES_API_INFO.selectProfile, profile));
  }
  /**
   * get Security Question
   * @returns return SecurityQuestion
   */
  getSecurityQuestion(): Observable<string> {
    return this.http.get<string>(PROFILES_API_INFO.getSecurityQuestion);
  }
  /**
   * varified Security Answer
   * @param Answer input answer
   * @returns return result
   */
  securityAnswer(Answer: string): Observable<SecurityAnswerResponce> {
    return this.withOverlaySpinner(this.http.post<SecurityAnswerResponce>(PROFILES_API_INFO.securityAnswer, { Answer }));
  }
  /**
   * To get child profile types
   * @returns child profile types
   */
  getChildProfilesTypes = (): ProfileType[] => this.profileTypes.filter((profile) => Number(profile.profileTypeId) > 1);
  /**
   * To force stop the overlay spinner if any other page loader is affected
   */
  forceStopOverlaySpinner = () => (this.spinnerService.isOverlayLoading = false);
  /**
   * Delete Account
   * @returns Account Successfully Deleted
   */
  deleteAccount(): Observable<DeleteAccountResponse> {
    return this.withOverlaySpinner(this.http.get<DeleteAccountResponse>(PROFILES_API_INFO.deleteAccount));
  }
  getProfileComponent = (): Observable<string> => {
    return this.http.get(PROFILES_API_INFO.getProfileComponent, { responseType: 'text' });
  };
  /**
   * Get profile info by profile id
   * @param profileId profile id
   * @returns profile info
   */
  getPatronInfo(): Observable<GetProfileByIdResponse> {
    return this.http.get<GetProfileByIdResponse>(PROFILES_API_INFO.getPatronInfo);
  }
}
