import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_INFO } from '@shared/constants/api.constant';
import {
  ForgotPasswordModel,
  ILogOn,
  LogOnModel,
  LogOnResponseModel,
  QuestionUserNameResponse,
  RegisterResponseModel
} from 'app/core/account/shared/data/account.model';
import { getFormData } from '@shared/helper/app.util';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  loginInfo: ILogOn;
  currentDialog: string;
  fromActionHandler = false;
  isActionCompleted = new BehaviorSubject<boolean>(false);
  /**
   * Creates an instance of search service.
   * @param http http client
   */
  constructor(private http: HttpClient) {
    // Exposed this window method to update logged in status from .net login
  }
  /**
   * To post login data
   * @param loginInfo login info
   * @returns login response
   */

  accountLogin(data: LogOnModel) {
    return this.http.post<LogOnResponseModel>(API_INFO.postLoginData, getFormData(data));
  }

  /**
   * To get security question for username
   * @param username user info
   * @returns security question for username
   */
  getQuestionForUsername(data: ForgotPasswordModel) {
    return this.http.post<QuestionUserNameResponse>(API_INFO.getQuestionForUsername, getFormData(data));
  }

  /**
   * To get loginData
   * @returns loginInfo
   */

  async getLoginData(): Promise<ILogOn> {
    this.loginInfo = await this.http
      .get<ILogOn>(
        API_INFO.getLoginData
          .replace('<returnPage>', window.location.href)
          .replace('<returnItemID>', '')
          .replace('<buttonValue>', '')
          .replace('<bookItemID>', '')
      )
      .toPromise();
    return this.loginInfo;
  }

  /**
   * To post security question with answer for the username
   * @param username user info
   * @returns response of security question values
   */
  sendPassword(data: ForgotPasswordModel) {
    return this.http.post<string>(API_INFO.sendPassword, getFormData(data));
  }
  /**
   * To get registerData
   * @returns registerInfo
   */
  getRegisterData() {
    return this.http.get(API_INFO.getRegisterData);
  }

  /**
   * To post register data
   * @param registerInfo register info
   * @returns  register response if it logged in or not
   */

  postRegisterData(data) {
    return this.http.post<RegisterResponseModel>(API_INFO.postRegisterData, getFormData(data));
  }
  verifyRegisterPassword(data) {
    return this.http.post<string>(API_INFO.verifyRegisterPassword, getFormData(data));
  }
}
