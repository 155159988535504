import { Component, Input, OnInit } from '@angular/core';
import { resizeReviewContent } from '@shared/helper/app.util';
import { TITLE_DETAILS_EVENTS_KEY } from '@titledetails/shared/constants/title-details.analytics.constants';
import { IOtherUserReview } from '@titledetails/shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from '@titledetails/shared/services/title-details.analytics.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';

@Component({
  selector: 'axis360-title-review',
  templateUrl: './title-review.component.html',
  styleUrls: ['./title-review.component.scss']
})
export class TitleReviewComponent implements OnInit {
  @Input() reviewList: IOtherUserReview[];
  reviewContent: IOtherUserReview[];
  resizeReviewContent: any = resizeReviewContent;
  constructor(public titleDetailsAnalyticsService: TitleDetailsAnalyticsService, public titleDetailsService: TitleDetailsService) { }

  ngOnInit(): void {
    this.titleDetailsService.getReviewSubject().subscribe(review => {
      if (review) {
        this.reviewList = review;
        this.onResize();
        return;
      }
    });
    this.onResize();
  }
  onResize() {
    this.reviewContent = this.reviewList.map((review) => {
      review.more = false;
      review.reSizeReviewContent = resizeReviewContent(review.reviewContent);
      return review;
    });
  }

  setFocusToText = (focusText: string) => {
    setTimeout(() => {
      document.getElementById(focusText)?.focus();
    }, 200);
  }

  /**
   *  Track by Title details Actions in href values
   * @param action  Selected action to pass
   * @param value   set the value in pass the action
   */
  trackByTitleDetailsActions(action: keyof typeof TITLE_DETAILS_EVENTS_KEY) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action);
  }
}