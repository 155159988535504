<div class="shimmer-card">
    <div class="default-carousel " *ngIf="compName === SHIMMER_COMPONENTS.TITLE_CARDS">
        <div class="default-heading" *ngIf="showCarouselTitle">
            <div class="shimmer-BG default-main-text"></div>
            <div class="shimmer-BG default-sub-text" *ngIf="showCarouselSubTitle"></div>
        </div>
        <div class="default-title kz-carousel">
            <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="cellsToScroll" [margin]="cellMargin"
            [cellWidth]="cellWidth" [height]="cellHeight">
            <div class="carousel-cell" *ngFor="let shimmerCard of shimmerCardList">
                <div class="default-card d-block">
                    <div class="shimmer-BG default-card-img"></div>
                </div>
            </div>
        </carousel>
        </div>
    </div>
    <div class="action-buttons" *ngIf="compName === SHIMMER_COMPONENTS.ACTION_BUTTON">
        <div class="shimmer-BG default-btn"></div>
    </div>

    <div class="goals-carousel" *ngIf="compName === SHIMMER_COMPONENTS.GOALS">
        <div class="goals-card">
            <div class="goals-card-img">
                <div class="shimmer-BG goals-circle"></div>
                <div class="goals-text-value">
                    <div class="shimmer-BG goals-sub-text"></div>
                    <div class="shimmer-BG goals-main-text"></div>
                </div>
            </div>
            <div class="goals-content">
                <div class="shimmer-BG goals-sub-text"></div>
            </div>
        </div>
        <div class="goals-card">
            <div class="goals-card-img">
                <div class="shimmer-BG goals-circle"></div>
                <div class="goals-text-value">
                    <div class="shimmer-BG goals-sub-text"></div>
                    <div class="shimmer-BG goals-main-text"></div>
                </div>
            </div>
            <div class="goals-content">
                <div class="shimmer-BG goals-sub-text"></div>
            </div>
        </div>
        <div class="goals-card xs-none">
            <div class="goals-card-img">
                <div class="shimmer-BG goals-circle"></div>
                <div class="goals-text-value">
                    <div class="shimmer-BG goals-sub-text"></div>
                    <div class="shimmer-BG goals-main-text"></div>
                </div>
            </div>
            <div class="goals-content">
                <div class="shimmer-BG goals-sub-text"></div>
            </div>
        </div>
        <div class="goals-card xs-none">
            <div class="goals-card-img">
                <div class="shimmer-BG goals-circle"></div>
                <div class="goals-text-value">
                    <div class="shimmer-BG goals-sub-text"></div>
                    <div class="shimmer-BG goals-main-text"></div>
                </div>
            </div>
            <div class="goals-content">
                <div class="shimmer-BG goals-sub-text"></div>
            </div>
        </div>
        <div class="goals-card xs-none">
            <div class="goals-card-img">
                <div class="shimmer-BG goals-circle"></div>
                <div class="goals-text-value">
                    <div class="shimmer-BG goals-sub-text"></div>
                    <div class="shimmer-BG goals-main-text"></div>
                </div>
            </div>
            <div class="goals-content">
                <div class="shimmer-BG goals-sub-text"></div>
            </div>
        </div>
        <div class="goals-card xs-none">
            <div class="goals-card-img">
                <div class="shimmer-BG goals-circle"></div>
                <div class="goals-text-value">
                    <div class="shimmer-BG goals-sub-text"></div>
                    <div class="shimmer-BG goals-main-text"></div>
                </div>
            </div>
            <div class="goals-content">
                <div class="shimmer-BG goals-sub-text"></div>
            </div>
        </div>
    </div>

    <div class="nav-carousel" *ngIf="compName === SHIMMER_COMPONENTS.NAV_TABS">
        <div class="nav-card">
            <div class="shimmer-BG nav-text"></div>
            <div class="shimmer-BG nav-count"></div>
        </div>
        <div class="nav-card">
            <div class="shimmer-BG nav-text"></div>
            <div class="shimmer-BG nav-count"></div>
        </div>
        <div class="nav-card xs-none">
            <div class="shimmer-BG nav-text"></div>
            <div class="shimmer-BG nav-count"></div>
        </div>
        <div class="nav-card xs-none">
            <div class="shimmer-BG nav-text"></div>
            <div class="shimmer-BG nav-count"></div>
        </div>
        <div class="nav-card xs-none">
            <div class="shimmer-BG nav-text"></div>
            <div class="shimmer-BG nav-count"></div>
        </div>
    </div>

    <div class="badges-carousel" *ngIf="compName === SHIMMER_COMPONENTS.BADGE_CARD">
        <div class="badge-card">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card xs-none">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card xs-none">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card xs-none">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card xs-none">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card xs-none">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
        <div class="badge-card xs-none">
            <div class="shimmer-BG badge-circle"></div>
            <div class="shimmer-BG badge-text"></div>
        </div>
    </div>

    <div class="announcement-card" *ngIf="compName === SHIMMER_COMPONENTS.ANNOUNCEMENT_CARD">
        <div class="announce-card">
            <div class="shimmer-BG announce-title"></div>
            <div class="shimmer-BG announe-image"></div>
            <div class="announce-title-desc">
                <div class="shimmer-BG announce-sub-title"></div>
                <div class="shimmer-BG announce-description w-90"></div>
                <div class="shimmer-BG announce-description w-80"></div>
                <div class="shimmer-BG announce-description w-70"></div>
                <div class="shimmer-BG announce-description w-50"></div>
            </div>
        </div>
    </div>

    <div class="announcement-card" *ngIf="compName === SHIMMER_COMPONENTS.WIDGET_CARD">
        <div class="announce-card widget-card">
            <div class="shimmer-BG announce-title"></div>
            <div class="shimmer-BG announe-image"></div>
            <div class="announce-title-desc">
                <div class="shimmer-BG announce-sub-title"></div>
                <div class="shimmer-BG announce-description w-90"></div>
                <div class="shimmer-BG announce-description w-80"></div>
                <div class="shimmer-BG announce-description w-70"></div>
                <div class="shimmer-BG announce-description w-50"></div>
            </div>
        </div>
    </div>

    <div class="article-card-sec" *ngIf="compName === SHIMMER_COMPONENTS.ARTICLE_CARDS">
        <div class="article-card">
            <div class="article-card-left">
                <div class="shimmer-BG article-img"></div>
            </div>
            <div class="article-card-right">
                <div class="shimmer-BG article-title"></div>
                <div class="shimmer-BG article-date w-50"></div>
                <div class="shimmer-BG article-name"></div>
                <div class="article-card-desc">
                    <div class="shimmer-BG article-desc w-90"></div>
                    <div class="shimmer-BG article-desc w-80"></div>
                    <div class="shimmer-BG article-desc w-70"></div>
                </div>
                <div class="shimmer-BG article-author"></div>
                <div class="shimmer-BG article-country w-50"></div>
                <div class="shimmer-BG article-lang w-50"></div>
            </div>
        </div>
        <div class="article-card">
            <div class="article-card-left">
                <div class="shimmer-BG article-img"></div>
            </div>
            <div class="article-card-right">
                <div class="shimmer-BG article-title"></div>
                <div class="shimmer-BG article-date w-50"></div>
                <div class="shimmer-BG article-name"></div>
                <div class="article-card-desc">
                    <div class="shimmer-BG article-desc w-90"></div>
                    <div class="shimmer-BG article-desc w-80"></div>
                    <div class="shimmer-BG article-desc w-70"></div>
                </div>
                <div class="shimmer-BG article-author"></div>
                <div class="shimmer-BG article-country w-50"></div>
                <div class="shimmer-BG article-lang w-50"></div>
            </div>
        </div>
        <div class="article-card">
            <div class="article-card-left">
                <div class="shimmer-BG article-img"></div>
            </div>
            <div class="article-card-right">
                <div class="shimmer-BG article-title"></div>
                <div class="shimmer-BG article-date w-50"></div>
                <div class="shimmer-BG article-name"></div>
                <div class="article-card-desc">
                    <div class="shimmer-BG article-desc w-90"></div>
                    <div class="shimmer-BG article-desc w-80"></div>
                    <div class="shimmer-BG article-desc w-70"></div>
                </div>
                <div class="shimmer-BG article-author"></div>
                <div class="shimmer-BG article-country w-50"></div>
                <div class="shimmer-BG article-lang w-50"></div>
            </div>
        </div>
    </div>

    <div class="third-party-card-details" *ngIf="compName === SHIMMER_COMPONENTS.THIRD_PARTY_DETAILS">
        <div class="title shimmer-BG" *ngIf="showCarouselTitle"></div>
        <div class="card shimmer-BG"></div>
        <div class="button shimmer-BG"></div>
        <hr class="horizontal-rule">
        <div class="table">
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
            <div class="tr">
                <div class="td shimmer-BG">
                </div>
                <div class="td shimmer-BG"></div>
            </div>
        </div>
        <hr class="horizontal-rule">
    </div>

    <div class="title-detail-card" *ngIf="compName === SHIMMER_COMPONENTS.TITLE_DETAILS_RATINGS">
        <div class="titles-card">
            <div class="details-title-desc">
                <div class="shimmer-BG details-sub-title"></div>
                <div class="shimmer-BG details-description w-90"></div>
            </div>
        </div>
    </div>

    <div class="title-detail-info-card" *ngIf="compName === SHIMMER_COMPONENTS.TITLE_DETAILS_MORE_INFO">
        <div class="titles-more-card widget-card">
            <div class="title-pills-card">
                <div class="shimmer-BG title-pills"></div>
                <div class="shimmer-BG title-pills"></div>
                <div class="shimmer-BG title-pills"></div>
                <div class="shimmer-BG title-pills"></div>
            </div>

            <div class="details-title-desc">
                <div class="shimmer-BG details-sub-title w-70"></div>
            </div>
        </div>
    </div>


    <div class="title-copies-card" *ngIf="compName === SHIMMER_COMPONENTS.TITLE_DETAILS_COPIES_INFO">
        <div class="titles-more-card">
            <div class="shimmer-BG details-sub-title w-90"></div>
        </div>
    </div>

    <div class="title-detail-button-card" *ngIf="compName === SHIMMER_COMPONENTS.TITLE_DETAILS_BUTTONS">
        <div class="titles-detailbtn-card">
            <div class="title-pills-card">
                <div class="shimmer-BG title-btns"></div>
            </div>
        </div>
    </div>
    <div class="third-party-card-details" *ngIf="compName === SHIMMER_COMPONENTS.THIRD_PARTY_ITEM_DETAILS">
        <div class="card-item shimmer-BG"></div>
    </div>
</div>