import { Inject, Injectable, InjectionToken } from '@angular/core';

interface EventConfig {
  event_category?: string;
  event_label?: string;
  value?: string;
  non_interaction?: boolean;
  page_location?: string;
  page_title?: string;
}
interface DataObj {
  [key: string]: string;
}
export const screensDIToken = new InjectionToken('screens');
export const eventsDIToken = new InjectionToken('events');
@Injectable()
export class AnalyticsService {
  screens: DataObj;
  events: DataObj;
  /**
   * Creates an instance of analytics service.
   * Params are used for sub module wise tracking like Search, RP
   * @param screens screen values key:value pair
   * @param events event values key:value pair
   */
  constructor(@Inject(screensDIToken) screens, @Inject(eventsDIToken) events: DataObj) {
    this.screens = screens;
    this.events = events;
  }
  /**
   * To call gtag to track the events
   * @param action event action
   * @param config event config
   */
  private track(action: string, config: EventConfig) {
    (window as any).gtag && (window as any).gtag('event', action, config);
  }
  /**
   * To send the event to track
   * @param action event action
   * @param category event category
   * @param label event label
   * @param nonInteraction interaction event or not
   */
  private sendEvent = (action: string, category: string, label?: string, nonInteraction = false) => {
    this.track(action, {
      event_category: category,
      event_label: label,
      non_interaction: nonInteraction,
      page_location: window.location.href
    });
  };
  /**
   * To Send page view event to track
   * @param page_title page title
   */
  private sendPageView = (pageTitle: string) => {
    this.track('page_view', { page_location: window.location.href, page_title: pageTitle });
  };
  /**
   * To Track the page event for different modules.
   * This should be called from sub modules wise like Search, RP
   * @param screenKey keyof typeof screens values
   * @param [altObj] to alter the tracking text
   */
  public trackPage(screenKey: string, altObj: DataObj = {}): void {
    let screenName = this.screens[screenKey];
    Object.keys(altObj).forEach((k) => {
      screenName = screenName.replace(`<${k}>`, altObj[k]);
    });
    this.sendPageView(screenName);
  }
  /**
   * To tracks event for different modules.
   * This should be called from sub modules wise like Search, RP
   * @param category event category
   * @param action event action
   * @param [altObj] to alter the tracking text
   */
  public trackEvent(category: string, action: string, altObj: DataObj = {}) {
    let eventAction = this.events[action];
    Object.keys(altObj).forEach((k) => {
      eventAction = eventAction.replace(`<${k}>`, altObj[k]);
    });
    this.sendEvent(eventAction, category);
  }
}
