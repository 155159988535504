<div class="kz-featured-reading-program-container" (window:resize)="onResize()" *ngIf="!(!isLoading && !program)"
    [ngStyle]="backgroundImageUrl ? {'background': 'url(' + backgroundImageUrl + ') center top / cover no-repeat fixed'} : ''">
    <mat-accordion class="featured-accordion" id="featured-accordion" multi>
        <mat-expansion-panel class="featured-reading-panel" [expanded]="expandState" (opened)="expandProgram()"
            (closed)="collapseProgram()" (click)="expand();">
            <mat-expansion-panel-header class="featured-reading-header" tabindex="0" (click)="$event.stopPropagation()"
                (keyup.enter)="$event.stopPropagation()" #exppanel>
                <mat-panel-title>
                    <h2 class="kz_title" aria-label="featured reading program" axis360EllipsisTitle id="loc_TxtFeaturedReadingProgram">
                        Featured Reading Program</h2>
                    <mat-icon class="mat-expansion-indicator featured-reading-indicator"
                        (click)="exppanel._toggle(); $event.stopPropagation()"
                        svgIcon="kz-accordian-down-arrow"></mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="featured-reading-program">
                <div class="kz_program_title_sec d-flex">
                    <h3 class="kz_program_title" axis360EllipsisTitle *ngIf="program">{{program.programName}}</h3>
                </div>
                <div role="none" [ngClass]="{'not-carousel': isNotCarousel}" (click)="$event.stopPropagation();" (keydown.enter)="$event.stopPropagation();">
                    <axis360-titles-carousel
                        [titlesList]="isLoading ? undefined :(program.books.length > 10 ? program.books.slice(0,10): program.books)"
                        [showCarouselTitle]="false" [parentRoute]="parentRoute" [isFeaturedProgram]="isFeaturedProgram" [addCard]="false" [programId]="isLoading ? undefined: program.programId "></axis360-titles-carousel>
                </div>
                <div class="action-btn-group" *ngIf="program">
                    <a (click)="viewProgramDetail()" (keyup.enter)="viewProgramDetail()" role="button"
                        class="primary-btn" routerLink="/view/programs/{{program.programId}}">View Program
                        Details</a>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>