import { Component, OnInit } from '@angular/core';
import { cdkVisualHidden } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-kz-search-list-view-main',
  templateUrl: './kz-search-list-view-main.component.html',
  styleUrls: ['./kz-search-list-view-main.component.scss']
})
export class KzSearchListViewMainComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    cdkVisualHidden();
  }
}
