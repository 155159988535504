import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[axis360CarouselAda]'
})
export class CarouselAdaDirective implements AfterViewInit {
  constructor(public elem: ElementRef) {}
  /**
   * To set ADA tags after view init
   */
  ngAfterViewInit() {
    this.updateAdaTags();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateAdaTags();
  }

  updateAdaTags() {
    this.setRibBtnsAriaLabel('.ui-carousel-next.ui-button', 'Next');
    this.setRibBtnsAriaLabel('.ui-carousel-prev.ui-button', 'Previous');
    this.addRibbonBtnAria();
  }
  /**
   * Sets ribbon buttons aria label
   * @param clsName class name
   * @param lbl label
   */
  setRibBtnsAriaLabel(clsName: string, lbl: string) {
    const ribbonBtns = this.elem.nativeElement.querySelectorAll(clsName);
    if (ribbonBtns.length === 0) {
      return;
    }
    const tabIndex = this.calulateTabIndex();
    for (const key in ribbonBtns) {
      if (ribbonBtns[key] && typeof ribbonBtns[key] === 'object') {
        ribbonBtns[key].setAttribute('aria-label', lbl);
        ribbonBtns[key].setAttribute('tabindex', tabIndex);
        ribbonBtns[key].setAttribute('aria-hidden', tabIndex === -1);
      }
    }
  }

  /**
   * Calulate tab index for differnt scenario
   * @returns returns -1 or 0
   */
  calulateTabIndex() {
    if (this.elem.nativeElement.id !== 'badges-carousel' || this.elem.nativeElement.classList.contains('hide-dots')) {
      return -1;
    }
    return 0;
  }

  /**
   * Adds ribbon button aria
   */
  addRibbonBtnAria() {
    const pageBtn = this.elem.nativeElement.querySelectorAll('ul.ui-carousel-dots-container .ui-carousel-dot-item');
    if (pageBtn.length === 0) {
      return;
    }
    for (const key in pageBtn) {
      if (pageBtn[key] && typeof pageBtn[key] === 'object') {
        const dotAriaLabel = pageBtn[key].classList.contains('ui-state-highlight') ? 'visited Pagination' : 'Pagination';
        const pgBtn = pageBtn[key].querySelector('button');
        if (pgBtn) {
          pgBtn.setAttribute('aria-label', dotAriaLabel);
        }
      }
    }
  }
}
