import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FILTER_TYPES,
  MAT_TABS_NAME,
  NEWS_DEFAULT_IMG,
  NEWS_SORT_OPTIONS,
  NEWS_FILTER,
  SEARCH_RESULT_PAGE_SIZE,
  ACTION_HANDLER_TYPE,
  NEWS_NO_RESULTS,
  NEWS_TITLE,
  PRESS_READER_REDIRECT_URL,
  MAT_TABS_ID,
  TAB_GROUP_ID,
  MAT_TABS,
  PRESSREADER_TITLE,
  MAT_PUB_TABS,
  MAT_PUB_TABS_NAME,
  MAT_PUB_TABS_ID
} from '@search/shared/constants/search-results.constants';
import { AppliedFilter, Facet, Filter, PublicationsSort, Sort } from '@search/shared/data/filter-model';
import { GetNewsAPIRequest, GetNewsRequest, News } from '@search/shared/data/news-model';
import { TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { SearchService } from '@search/shared/services/search.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { Subscription } from 'rxjs';
import { cdkRemoveVisualHidden, cdkVisualHidden, positionFooter, setSelectedTabId } from '@shared/helper/app.util';
import { SEARCH_EVENTS_KEY } from '@search/shared/constants/search.analytics.constants';
import { checkLoginAndHandleAction, getResultsCount } from '@search/shared/helper/search.util';
import { DEFAULT_SEARCH_FAILS_MSG, DEFAULT_SNAKE_BAR_MSG, SEARCH_QUERY_PARAM, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UserService } from '@shared/services/user.service';
import { ConfigService } from '@shared/services/config.service';
import { PRESS_READER_PUB_FILTER_OPTIONS, PRESS_READER_SORT_OPTIONS } from '@pressreader/shared/constants/press-reader.constansts';
import { PublicationCard } from '@pressreader/shared/data/press-reader-model';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import { getPublicationCard } from '@pressreader/shared/helper/press-reader.util';
import { MatTabChangeEvent } from '@angular/material/tabs';
type Action = keyof typeof SEARCH_EVENTS_KEY;
@Component({
  selector: 'axis360-news-results',
  templateUrl: './news-results.component.html'
})
export class NewsResultsComponent implements OnInit, OnDestroy, AfterViewInit {
  newsInfo: TitleCardInfo[] = [];
  countries: Facet[] = [];
  languages: Facet[] = [];
  pubType: Facet[] = [
    { text: PRESS_READER_PUB_FILTER_OPTIONS.ALL.text, selected: true, value: PRESS_READER_PUB_FILTER_OPTIONS.ALL.value },
    { text: PRESS_READER_PUB_FILTER_OPTIONS.NEWS_PAPERS.text, selected: false, value: PRESS_READER_PUB_FILTER_OPTIONS.NEWS_PAPERS.value },
    { text: PRESS_READER_PUB_FILTER_OPTIONS.MAGAZINES.text, selected: false, value: PRESS_READER_PUB_FILTER_OPTIONS.MAGAZINES.value }
  ];
  availableLimiters: Filter[] = [];
  sortOptions: Sort[] = [
    { displayable: NEWS_SORT_OPTIONS.RELEVANCE, selected: true },
    { displayable: NEWS_SORT_OPTIONS.DATE, selected: false }
  ];
  selectedSortOption = NEWS_SORT_OPTIONS.RELEVANCE;
  searchTerm: string;
  totalCount = 0;
  offset = 0;
  view: string;
  scrollSubscription: Subscription;
  initialFailed = false;
  NEWS_TITLE = NEWS_TITLE;

  PRESSREADER_TITLE = PRESSREADER_TITLE;
  publications: PublicationCard[] = [];
  sortOptionsPub: PublicationsSort[] = [
    { displayable: PRESS_READER_SORT_OPTIONS.SEARCH_RANK.displayable, selected: true, value: PRESS_READER_SORT_OPTIONS.SEARCH_RANK.value },
    { displayable: PRESS_READER_SORT_OPTIONS.NAME_A_Z.displayable, selected: false, value: PRESS_READER_SORT_OPTIONS.NAME_A_Z.value },
    { displayable: PRESS_READER_SORT_OPTIONS.NAME_Z_A.displayable, selected: false, value: PRESS_READER_SORT_OPTIONS.NAME_Z_A.value },
    { displayable: PRESS_READER_SORT_OPTIONS.POPULARITY.displayable, selected: false, value: PRESS_READER_SORT_OPTIONS.POPULARITY.value },
    { displayable: PRESS_READER_SORT_OPTIONS.ISSUE_DATE.displayable, selected: false, value: PRESS_READER_SORT_OPTIONS.ISSUE_DATE.value }
  ];
  selectedSortOptionPub = PRESS_READER_SORT_OPTIONS.SEARCH_RANK.value;
  publicationType = PRESS_READER_PUB_FILTER_OPTIONS.ALL.value;
  totalCountPub = 0;
  MAT_PUB_TABS_NAME = MAT_PUB_TABS_NAME;
  MAT_PUB_TABS_ID = MAT_PUB_TABS_ID;

  /**
   * Creates an instance of news results component.
   * @param activatedRoute Activated route info
   * @param searchService Search service
   * @param spinnerService Spinner service
   * @param snackBarService Snackbar service
   * @param infiniteScroll InfiniteScroll service
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    public searchService: SearchService,
    public spinnerService: SpinnerService,
    private snackBarService: SnackBarService,
    private infiniteScroll: InfiniteScrollService,
    private searchAnalyticsService: SearchAnalyticsService,
    private searchStorageService: SearchStorageService,
    private liveAnnouncer: LiveAnnouncer,
    private userService: UserService,
    private configService: ConfigService,
    private pressReaderService: PressReaderService
  ) {
    this.loadMore = this.loadMore.bind(this);
  }
  /**
   * To get news data from API by searchTerm
   */
  ngOnInit(): void {
    this.liveAnnouncer.announce(NEWS_TITLE, 'assertive');
    this.searchService.selectedSearchTab = MAT_TABS.indexOf(MAT_TABS_NAME.NEWS);
    this.searchService.selectedNewsStandTab = MAT_PUB_TABS.indexOf(MAT_PUB_TABS_NAME.PUBLICATIONS);
    this.searchTerm = this.activatedRoute.snapshot.queryParams[SEARCH_QUERY_PARAM] || '';
    this.getRefiners();
    this.view = MAT_TABS_NAME.NEWS;
    this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
    this.searchAnalyticsService.trackScreen('NEWS');
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }
  ngAfterViewInit() {
    setSelectedTabId(TAB_GROUP_ID, MAT_TABS_ID[MAT_TABS_NAME.NEWS]);
  }
  /**
   * Gets filtered contries ids or languages ids
   * @param type type to idendify it is country or language
   * @returns filtered ids
   */
  getFilteredContriesOrLanguages(type: string) {
    const typeMap = { [NEWS_FILTER.LANGUAGE]: this.languages, [NEWS_FILTER.COUNTRY]: this.countries };
    const availableValues = typeMap[type].filter((facet) => facet.selected).map((facet) => facet.value);
    return availableValues.length > 0 ? availableValues : null;
  }
  /**
   * To announces result count
   */
  announceResult() {
    if (this.offset === 0) {
      this.totalCount === 0
        ? this.liveAnnouncer.announce(NEWS_NO_RESULTS.split('<searchTerm>').join(this.searchTerm), 'assertive')
        : this.liveAnnouncer.announce(getResultsCount(this.newsInfo.length, this.totalCount, this.searchTerm), 'assertive');
    }
  }
  /**
   * To get news API request
   * @param [languages] languages filter
   * @param [countries] countries filter
   * @returns news API request
   */
  getNewsAPIRequest(): GetNewsAPIRequest {
    return {
      query: this.searchTerm,
      countries: this.getFilteredContriesOrLanguages(NEWS_FILTER.COUNTRY),
      languages: this.getFilteredContriesOrLanguages(NEWS_FILTER.LANGUAGE)
    };
  }
  /**
   * To get news data on init and load more click
   */
  getNewsData() {
    const req: GetNewsRequest = {
      requestBody: this.getNewsAPIRequest(),
      offset: this.offset,
      limit: SEARCH_RESULT_PAGE_SIZE,
      sortOption: this.selectedSortOption
    };
    this.spinnerService.showLoader();
    if (this.offset === 0) {
      this.newsInfo = [];
    }
    this.searchService.getNewsResults(req).subscribe(
      (response) => {
        const { items = [], meta = { totalCount: 0 } } = response;
        this.newsInfo = this.newsInfo.concat(items.filter((item) => item).map((newsInfo) => this.getNewsCard(newsInfo)));
        this.totalCount = meta.totalCount;
        this.spinnerService.showLoader(false);
        this.announceResult();
        positionFooter(true);
      },
      () => {
        this.spinnerService.showLoader(false);
        if (this.offset === 0) {
          this.initialFailed = true;
          this.totalCount = 0;
          this.liveAnnouncer.announce(DEFAULT_SNAKE_BAR_MSG, 'assertive');
          return;
        }
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SEARCH_FAILS_MSG);
        this.offset -= SEARCH_RESULT_PAGE_SIZE;
        positionFooter(true);
      }
    );
  }
  /**
   * To get country for news card
   * @param countries list of country codes
   * @returns country name
   */
  getCountryForNewsCard(countries: string[]): string {
    let countryName = (this.countries.find((con) => con.value === countries[0]) || {}).text;
    countries.forEach((country) => {
      const countryObj = this.countries.find((con) => con.selected && con.value === country);
      if (countryObj) {
        countryName = countryObj.text;
      }
    });
    return countryName;
  }
  /**
   * To convert news data to title card info for title info component rendering
   * @param news News object
   * @returns title card info based on news data
   */
  public getNewsCard(news: News): TitleCardInfo {
    const { article = {}, publication = {}, issue = {} } = news;
    const { author, title: mainTitle, media, url: redirectUrl } = article;
    const { countries = [], title: subTitle, language } = publication;
    const [{ url: imageSrc = NEWS_DEFAULT_IMG, title: imageAlt = '' } = {}] = media || [];
    const { date: releaseDate } = issue;
    const [description = ''] = news.content || [];
    const country = this.getCountryForNewsCard(countries);
    const { text: langText, value: languageCode } = this.languages.filter((lan) => lan.value === language)[0] || {};
    const countrylist = countries.map((obj) => this.countries.find((obj1) => obj1.value === obj)?.text);
    return {
      subTitle,
      mainTitle,
      author: this.getAuthor(author),
      description,
      country: country || '',
      multiCountry: countrylist?.filter(Boolean)?.toString().replace(/,/g, ' | '),
      imageSrc,
      imageAlt,
      language: langText || '',
      languageCode: languageCode || '',
      releaseDate,
      redirectUrl
    };
  }

  /**
   * To remove 'By' string if it is available
   * @param author author name
   * @returns updated author name
   */
  getAuthor(author: string): string {
    return author && author.substring(0, 2).toLowerCase() === 'by' ? author.substring(3, author.length) : author;
  }
  /**
   * Get response from news api and apply filter
   * @param filter filter name to where apply filter
   * @param availableLimiters list of available values
   * @param code code for filter api response
   * @param clearFilter boolean value for denote it is clear filter or not
   */
  getAndApplyFilters = (
    filter: string,
    availableLimiters: Filter[],
    code = '',
    clearFilter = false,
    consumeGetNews = true,
    setSelected = false
  ) => {
    if (this.searchService.selectedNewsStandTab === 1) {
      const languageSvcFunc = { dataKey: 'languages', svcfunc: this.searchService.getLanguages, limiterKey: NEWS_FILTER.LANGUAGE };
      const countriesSvcFunc = { dataKey: 'countries', svcfunc: this.searchService.getCountries, limiterKey: NEWS_FILTER.COUNTRY };
      const svcFuncMap = {
        [NEWS_FILTER.LANGUAGE]: clearFilter ? languageSvcFunc : countriesSvcFunc,
        [NEWS_FILTER.COUNTRY]: clearFilter ? countriesSvcFunc : languageSvcFunc
      };
      const oppositeFilter = filter === NEWS_FILTER.COUNTRY ? NEWS_FILTER.LANGUAGE : NEWS_FILTER.COUNTRY;
      const svcInfo = svcFuncMap[filter];
      const { dataKey, limiterKey } = svcInfo;
      this.spinnerService.showLoader();
      svcInfo.svcfunc(code).subscribe(
        (res) => {
          if (!clearFilter) {
            res[dataKey].forEach(
              (facet) =>
              (facet.selected =
                availableLimiters.find((limiter) => limiter.title === limiterKey).facets?.find((obj) => obj.text === facet.text)
                  ?.selected || false)
            );
          }
          if (setSelected) {
            res[dataKey].forEach((facet) => (facet.selected = this[dataKey].find((obj) => obj.text === facet.text)?.selected || false));
          }
          this[dataKey] = res[dataKey];

          if (!consumeGetNews) {
            const selectedId = res[dataKey].map((facet) => facet.id);
            const selectedIdStr = selectedId.length > 0 ? selectedId.join() : '';
            this.getAndApplyFilters(oppositeFilter, availableLimiters, selectedIdStr, true, true, true);
          }
          if (consumeGetNews) {
            this.setAvailableLimiters();
            this.spinnerService.showLoader(false);
            this.getNewsData();
          }
        },
        () => {
          this.spinnerService.showLoader(false);
          this.snackBarService.showSnackBar();
        }
      );
    } else {
      this.pubType = availableLimiters[0].facets;
      this.availableLimiters = [
        {
          title: NEWS_FILTER.PUBLICATION_TYPE,
          facets: this.pubType,
          type: FILTER_TYPES.LIST_SINGLE_SELECTION
        }
      ];
      this.getPublications();
    }
  };
  /**
   * Clear filter values
   * @param clearLimiter input value as limiter name to clear and list of available values
   */
  clearFilters = (clearLimiter: { limiter: string; filters: Filter[] }) => {
    this.searchAnalyticsService.trackNewsEvent('CLEAR_FILTER');
    if (this.searchService.selectedNewsStandTab === 1) {
      this.liveAnnouncer.announce(clearLimiter.limiter + ' cleared', 'assertive');
      const limiter = clearLimiter.limiter === NEWS_FILTER.COUNTRY ? NEWS_FILTER.LANGUAGE : NEWS_FILTER.COUNTRY;
      const selectedId = clearLimiter.filters
        .find((filter) => filter.title === limiter)
        .facets.filter((facet) => facet.selected)
        .map((facet) => facet.id);
      const selectedIdStr = selectedId.length > 0 ? selectedId.join() : '';
      this.getAndApplyFilters(clearLimiter.limiter, clearLimiter.filters, selectedIdStr, true, false);
      return;
    }
    this.pubType.forEach((filter) => (filter.selected = false));
    this.availableLimiters = [
      {
        title: NEWS_FILTER.PUBLICATION_TYPE,
        facets: this.pubType,
        type: FILTER_TYPES.LIST_SINGLE_SELECTION
      }
    ];
    this.getPublications();
  };
  /**
   * To update the results based on the sort option selected
   * @param sortOption  input param as sortOption
   */
  sortOptionSelected = (sortOption: Sort | PublicationsSort) => {
    this.searchAnalyticsService.trackNewsEvent('SELECT_SORT');
    this.offset = 0;
    if (this.searchService.selectedNewsStandTab === 1) {
      this.selectedSortOption = sortOption.displayable;
      this.sortOptions.forEach((sort) => (sort.selected = false));
      this.sortOptions.find((sort) => sort.displayable === sortOption.displayable).selected = true;
      this.storeRefiners();
      this.getNewsData();
      return;
    }
    this.selectedSortOptionPub = this.sortOptionsPub.find((sort) => sort.displayable === sortOption.displayable).value;
    this.sortOptionsPub.forEach((sort) => (sort.selected = false));
    this.sortOptionsPub.find((sort) => sort.displayable === sortOption.displayable).selected = true;
    this.getPublications();
  };
  /**
   * To loads more news on load more click
   */
  loadMore() {
    if (
      this.searchService.selectedNewsStandTab === 1 &&
      !this.spinnerService.isLoading &&
      this.totalCount > 0 &&
      this.newsInfo.length < this.totalCount
    ) {
      this.offset += SEARCH_RESULT_PAGE_SIZE;
      this.getNewsData();
      return;
    }
    else if (this.searchService.selectedNewsStandTab === 0 &&
      !this.spinnerService.isLoading &&
      this.totalCountPub > 0 &&
      this.publications.length < this.totalCountPub) {
      this.offset += SEARCH_RESULT_PAGE_SIZE;
      this.getPublications();
    }
  }
  /**
   * Updates default selected filter
   */
  updateDefaultSelectedFilter() {
    const defaultCountry = this.configService.getPressReaderDefaultCounty();
    const defaultLanguage = this.configService.getPressReaderDefaultLang();
    this.countries.forEach((country) => (country.selected = country.value === defaultCountry));
    this.languages.forEach((language) => (language.selected = language.value === defaultLanguage));
  }
  /**
   * get refiners data
   * @param isPublicationsTab input param as isPublications
   * @returns break if condition
   */
  getFilters(isPublicationsTab: boolean = false) {
    if (isPublicationsTab) {
      this.spinnerService.showLoader();
      this.searchService.getCountries('').subscribe(
        (contryFilterRes) => {
          this.countries = contryFilterRes.countries;
          this.setAvailableLimiters();
          this.sortOptionsPub.forEach((sort) => (sort.selected = false));
          this.sortOptionsPub.find((sort) => sort.value === this.selectedSortOptionPub).selected = true;
          this.pubType = this.availableLimiters[0].facets;
          this.getPublications();
        },
        () => {
          this.spinnerService.showLoader(false);
          this.snackBarService.showSnackBar();
        }
      );
      return;
    }
    this.spinnerService.showLoader();
    this.searchService.getCountries('').subscribe(
      (contryFilterRes) => {
        const defaultCountry = this.configService.getPressReaderDefaultCounty();
        this.countries = contryFilterRes.countries;
        const { id: defaultCountryCode } = this.countries.find((country) => country.value === defaultCountry) || {};
        this.searchService.getLanguages(defaultCountryCode).subscribe((langFilterRes) => {
          this.languages = langFilterRes.languages;
          this.updateDefaultSelectedFilter();
          this.setAvailableLimiters();
          this.getNewsData();
        });
      },
      () => {
        this.spinnerService.showLoader(false);
        this.snackBarService.showSnackBar();
      }
    );
  }
  /**
   * Set available limiters for filtering the results
   */
  setAvailableLimiters() {
    if (this.searchService.selectedNewsStandTab === 1) {
      this.availableLimiters = [
        {
          title: NEWS_FILTER.LANGUAGE,
          facets: this.languages,
          type: FILTER_TYPES.TAB
        },
        {
          title: NEWS_FILTER.COUNTRY,
          facets: this.countries,
          type: FILTER_TYPES.LIST
        }
      ];
      this.storeRefiners();
      return;
    }
    this.sortOptionsPub = [
      { displayable: PRESS_READER_SORT_OPTIONS.SEARCH_RANK.displayable,
        selected: true, value: PRESS_READER_SORT_OPTIONS.SEARCH_RANK.value },
      { displayable: PRESS_READER_SORT_OPTIONS.NAME_A_Z.displayable,
        selected: false, value: PRESS_READER_SORT_OPTIONS.NAME_A_Z.value },
      { displayable: PRESS_READER_SORT_OPTIONS.NAME_Z_A.displayable,
        selected: false, value: PRESS_READER_SORT_OPTIONS.NAME_Z_A.value },
      { displayable: PRESS_READER_SORT_OPTIONS.POPULARITY.displayable,
        selected: false, value: PRESS_READER_SORT_OPTIONS.POPULARITY.value },
      { displayable: PRESS_READER_SORT_OPTIONS.ISSUE_DATE.displayable,
        selected: false, value: PRESS_READER_SORT_OPTIONS.ISSUE_DATE.value }
    ];
    this.selectedSortOptionPub = PRESS_READER_SORT_OPTIONS.SEARCH_RANK.value;
    this.pubType = [
      { text: PRESS_READER_PUB_FILTER_OPTIONS.ALL.text, selected: true, value: PRESS_READER_PUB_FILTER_OPTIONS.ALL.value },
      { text: PRESS_READER_PUB_FILTER_OPTIONS.NEWS_PAPERS.text, selected: false, value: PRESS_READER_PUB_FILTER_OPTIONS.NEWS_PAPERS.value },
      { text: PRESS_READER_PUB_FILTER_OPTIONS.MAGAZINES.text, selected: false, value: PRESS_READER_PUB_FILTER_OPTIONS.MAGAZINES.value }
    ];
    this.availableLimiters = [
      {
        title: NEWS_FILTER.PUBLICATION_TYPE,
        facets: this.pubType,
        type: FILTER_TYPES.LIST_SINGLE_SELECTION
      }
    ];
  }

  /**
   * Applys filter
   * @param appliedFilter input param as applied filters
   */
  applyFilter = (applyFilter: { appliedFilter: AppliedFilter; filters: Filter[] }) => {
    this.searchAnalyticsService.trackNewsEvent('SELECT_FILTER');
    this.offset = 0;
    this.getAndApplyFilters(
      applyFilter.appliedFilter.limiter,
      applyFilter.filters,
      applyFilter.appliedFilter.facets.map((facet) => `${facet.id}`).join()
    );
  };
  /**
   * Opens filter dialog
   * @param [refinerType] SORT or FILTER
   */
  openRefinerDialogEvent(refinerType: string) {
    this.searchAnalyticsService.trackNewsEvent(refinerType as Action);
  }
  /**
   * destroy the scrollSubscription
   */
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
    cdkRemoveVisualHidden();
  }
  /**
   * Gets refiners from session storage
   */
  getRefiners() {
    if (this.searchService.selectedNewsStandTab === 1) {
      const refiners = this.searchStorageService.getNewsRefiners();
      if (!refiners) {
        this.getFilters();
        return;
      }
      this.availableLimiters = refiners.filters;
      this.countries = this.availableLimiters.find((limiter) => limiter.title === NEWS_FILTER.COUNTRY)?.facets;
      this.languages = this.availableLimiters.find((limiter) => limiter.title === NEWS_FILTER.LANGUAGE)?.facets;
      this.selectedSortOption = refiners.sortBy;
      this.sortOptions.forEach((sort) => (sort.selected = false));
      this.sortOptions.find((sort) => sort.displayable === this.selectedSortOption).selected = true;
      this.getNewsData();
      return;
    }
    this.getFilters(true);
  }
  /**
   * Sets refiners to session storage
   */
  storeRefiners() {
    this.searchStorageService.setNewsRefiners({ filters: this.availableLimiters, sortBy: this.selectedSortOption });
  }
  /**
   * To handle event on title card click
   * @param input event and url value of the emitter event
   */
  titleCardClick(input) {
    this.searchAnalyticsService.trackNewsEvent('ARTICLE_LINK');
    const url = PRESS_READER_REDIRECT_URL.replace('<redirectionUrl>', input.url);
    if (this.userService.isLoggedIn()) {
      window.open(url, '_blank');
      return;
    }
    const event = input.event;
    event.data = ACTION_HANDLER_TYPE[MAT_TABS_NAME.NEWS];
    event.url = url;
    checkLoginAndHandleAction(event);
  }

  /**
   * To get publications with searchTerm
   */
  getPublications() {
    this.publicationType = this.pubType.find((element) => element.selected === true)?.value || '';
    const orderBy = this.sortOptionsPub.find((element) => element.value === this.selectedSortOptionPub).value;
    this.spinnerService.showLoader();
    if (this.offset === 0) {
      this.liveAnnouncer.announce('Results loaded', 'assertive');
      this.publications = [];
    }
    this.pressReaderService
      .getPublicationsWithQuery(this.offset, SEARCH_RESULT_PAGE_SIZE, this.searchTerm, orderBy, this.publicationType)
      .subscribe(
        (publications) => {
          const { items, meta = {} } = publications;
          const { totalCount } = meta;
          this.totalCountPub = totalCount || 0;
          const publicationCards = (items || []).map((publication) => getPublicationCard(publication, this.countries));
          this.publications = this.publications.concat(publicationCards);
          this.spinnerService.showLoader(false);
          positionFooter(true);
        },
        () => {
          this.spinnerService.showLoader(false);
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
          positionFooter(true);
        }
      );
  }
  /**
   * To be called on selecting tab to change the active tab
   */
  selectedPubTabChange = (matTabEvent: MatTabChangeEvent) => {
    this.searchService.selectedNewsStandTab = matTabEvent.index;
    this.offset = 0;
    if (matTabEvent.index === 0) {
      this.setAvailableLimiters();
      this.getPublications();
      return;
    }
    this.getRefiners();
  };
}
