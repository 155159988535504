<div class="alert-dialog">
    <div mat-dialog-content class="alert-heading">
        <p id="alert-heading" tabindex="-1">{{modaldata.content}}</p>
    </div>
    <div mat-dialog-actions class="alert-btns">
        <button mat-button (click)="submitClick()" (keyup.enter)="submitClick()" cdkFocusInitial
            class="submit-btn" *ngIf="modaldata.submitBtnText">{{modaldata.submitBtnText}}</button>
        <button mat-button (click)="cancelClick()" (keyup.enter)="cancelClick()" cdkFocusInitial
            class="cancel-btn"  *ngIf="modaldata.cancelBtnText">{{modaldata.cancelBtnText}}</button>
    </div>
</div>