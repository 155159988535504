import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgramCard } from '@readingprograms/shared/data/programs.model';
import { PROGRAMS_CLASS_BY_STATUS, PROGRAMS_STATUS_TEXT } from '../../constants/kz-programs.constants';
import { getOverAllStatus } from '../../helper/kz-programs.util';
import { KzProgramsService } from '../../service/kz-programs.service';

@Component({
  selector: 'axis360-kz-program-card',
  templateUrl: './kz-program-card.component.html',
  styleUrls: ['./kz-program-card.component.scss']
})
export class KzProgramCardComponent implements OnInit {
  PROGRAMS_STATUS_TEXT = { ...PROGRAMS_STATUS_TEXT };
  PROGRAMS_CLASS_BY_STATUS = PROGRAMS_CLASS_BY_STATUS;
  statusMessage: string;
  @Input() cardRole: string;
  @Input() program: ProgramCard;
  @Input() tabName: string;
  @Output() deleteClickEvent = new EventEmitter<ProgramCard>();
  @Output() programCardClickEvent = new EventEmitter<ProgramCard>();
  constructor(public kzProgramsService: KzProgramsService) {}
  /**
   * Component init method
   */
  ngOnInit(): void {
    this.statusMessage = getOverAllStatus(this.program);
  }
  /**
   * Delete event popup emitted
   * @param programId
   */
  deleteProgramPopup(program: ProgramCard) {
    this.deleteClickEvent.emit(program);
  }
}
