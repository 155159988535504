import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { forceRedirect } from './helper/app.util';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
  forceRedirect = forceRedirect;
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.message.includes('/ng/view/library')) {
             this.forceRedirect('/ng/view/library');
             return;
            }
          }
          return throwError(err);
        }));
  }
}
