import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SNAKE_BAR_MODE, SNAKE_BAR_MODE_MESSAGE } from '@shared/constants/app.constants';
import { SnakeBarConfig } from '@shared/data/snake-bar-model';
import { cdkRemoveVisualHidden, cdkVisualHidden } from '@shared/helper/app.util';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
@Component({
  selector: 'axis360-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit, OnDestroy {
  data: { mode: string; message: string; snakeBarData: SnakeBarConfig };
  snackBarMode = SNAKE_BAR_MODE;
  snackMessage: string;
  isToastMessage: boolean;
  isToastButtonFocus: number;
  isToastAriaLiveStatus: string;
  cdkRemoveVisualHidden = cdkRemoveVisualHidden;
  cdkVisualHidden = cdkVisualHidden;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private liveAnnouncer: LiveAnnouncer,
    public titleDetailsService: TitleDetailsService,
    @Inject(MAT_SNACK_BAR_DATA) data: { mode: string; message: string; snakeBarData: SnakeBarConfig }
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    const locSnackBar = document.getElementById('loc_snackBar');
    locSnackBar?.setAttribute('aria-hidden', 'true');
    this.snackMessage = this.removeUnwantedSymbols(this.data.message || '');
    const snackMessageVO = this.titleDetailsService.titleDetailsStautsCheck
      ? this.titleDetailsService.titleDetailsStautsContent : this.snackMessage;
    if (navigator.userAgent.match(/(Android|iPhone|iPad|iPod)/i)) {
      this.liveAnnouncer.announce(`${SNAKE_BAR_MODE_MESSAGE[this.data.mode]} ${snackMessageVO}`, 'assertive');
      this.changeDetectorRef.detectChanges();
      this.cdkRemoveVisualHidden();
      return;
    }
    if (navigator.userAgent.match(/(Mac)/i)) {
      //locSnackBar?.removeAttribute('aria-hidden');
      this.liveAnnouncer.announce(`${SNAKE_BAR_MODE_MESSAGE[this.data.mode]} ${snackMessageVO}`, 'assertive');
      this.changeDetectorRef.detectChanges();
      this.cdkRemoveVisualHidden();
      return;
    }
    if (this.titleDetailsService.shareSnackBarToastMsg) {
      this.liveAnnouncer.announce(`${SNAKE_BAR_MODE_MESSAGE[this.data.mode]} ${snackMessageVO}`, 'assertive');
    }
    this.changeDetectorRef.detectChanges();
    this.cdkRemoveVisualHidden();
  }

  public dismiss(): void {
    this.snackBarRef.dismissWithAction();
  }

  public removeUnwantedSymbols(val: string) {
    val = val.trim();
    const matchAll = val.match(/(\.\s+[A-Za-z0-9])/g);
    if (matchAll && matchAll.length > 0) {
      matchAll.forEach((x) => {
        val = val.replace(x, x.toUpperCase());
      });
    }
    const firstWord = val.split(' ');
    const containsEbooksOrEAudio = firstWord.some(word => word === 'eBook' || word === 'eAudio');
    if (!containsEbooksOrEAudio) {
      val = val.charAt(0).toUpperCase() + val.substring(1);
    }
    return val;
  }
  ngOnDestroy(): void {
  //if(!navigator.userAgent.match(/(Mac)/i)) {
    setTimeout(() => {
      cdkVisualHidden();
    }, 4000);
  //}
}
}
