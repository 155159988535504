export const VIEW_BOOKS_SIZE = 10;
export const PROGRAMS_STATUS_TEXT = {
  STARTED: 'Started',
  NOT_COMPLETED: 'Not Completed',
  CLOSED: 'Closed',
  ENDS_TODAY: 'Ends today',
  JOINED: 'Joined',
  COMPLETED: 'Completed',
  DB_COMPLETE: 'Complete'
};

export const PROGRAMS_CLASS_BY_STATUS = {
  [PROGRAMS_STATUS_TEXT.STARTED]: 'started',
  [PROGRAMS_STATUS_TEXT.NOT_COMPLETED]: 'not-completed',
  [PROGRAMS_STATUS_TEXT.ENDS_TODAY]: 'ends-today',
  [PROGRAMS_STATUS_TEXT.JOINED]: 'joined',
  [PROGRAMS_STATUS_TEXT.COMPLETED]: 'completed',
  [PROGRAMS_STATUS_TEXT.DB_COMPLETE]: 'complete',
}

export const BOOLIAN_FLAGS = {
  TRUE: 'True',
  FALSE: 'False'
}

