<div class="currently-section">
    <div class="currently-header">
        <h2 class="title heading-2" id="loc_labelCurrentlyCheckoutOut"
            [ngClass]="{'title-width': listContent.TotalItems >= LIST_CARD_COUNT}">Currently checked out</h2>
        <a *ngIf="listContent.TotalItems >= LIST_CARD_COUNT" role="link" aria-label="See all currently checked out"
            tabindex="0" class="see-all see-all-text" (click)="redirectToCheckoutList()">See All
            <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></a>
    </div>
    <ng-container>

        <p class="no-checkouts" *ngIf="!isLoading && !listContent?.Items?.length && refreshResumeCTA">You currently have no titles checked out.</p>

        <div class="carousel-section" *ngIf="!isLoading && listContent?.Items?.length && refreshResumeCTA">
            
            <axis360-titles-carousel [myShelfLoad]="true" [titlesList]="listContent.Items" [isCurrentlyCheckout]="true" [addCard]="true" (titleActionEvt)="titleActionEvent()"
                (seeAllClickEvent)="redirectToCheckoutList()" [showCarouselTitle]="false" [parentRoute]="parentRoute"
                [layoutComponents]="layoutComponents"></axis360-titles-carousel>
        </div>
        <div class="carousel-section" *ngIf="(isLoading || !refreshResumeCTA)">
            <axis360-titles-carousel [titlesList]="undefined" [showCarouselTitle]="false"></axis360-titles-carousel>
        </div>
    </ng-container>
</div>