import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FORMAT_ICON_MAP, HOLD_POSITION_INFO } from '@shared/constants/app.constants';
import { formatFileSizeBytes, replaceHyphenToEndashTitleDetails } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { TITLE_DETAILS_EVENTS_KEY } from '@titledetails/shared/constants/title-details.analytics.constants';
import { HOLD_POSITION_TOOLTIP } from '@titledetails/shared/data/mock/title-details.mock';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { Subscription } from 'rxjs';
import { AGE_RANGE, FORMAT_TYPE_MAP, LGBTQ } from '../shared/constants/title-details.constants';
import { ISubjectList, ITitleDetails, ITitleDetailsActions } from '../shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from '../shared/services/title-details.analytics.service';

@Component({
  selector: 'axis360-title-details-info',
  templateUrl: './title-details-info.component.html',
  styleUrls: ['./title-details-info.component.scss']
})
export class TitleDetailsInfoComponent implements OnInit, OnDestroy {
  titleDetailsData: ITitleDetails;
  @Input() titleDetailsActions: ITitleDetailsActions;
  formatType: string;
  NARRATOR_SUFFIX = ' (NAR)';
  isLoading = true;
  holdPosition: number;
  isRTV: boolean;
  fileSize: string;
  numOfPatronsOnHold: number;
  holdsAllowed: boolean;
  isInHoldQueue: boolean;
  estimatedWaitTime: number;
  consortiaType: string;
  collectionsType: string;
  AGE_RANGE = AGE_RANGE;
  HOLD_POSITION_TOOLTIP = HOLD_POSITION_TOOLTIP;
  upDateButtonViewSubscriptions: Subscription;
  encodeURIComponent = encodeURIComponent;
  replaceHyphenToEndashTitleDetails = replaceHyphenToEndashTitleDetails;
  constructor(public titleDetailsAnalyticsService: TitleDetailsAnalyticsService, public titleDetailsService: TitleDetailsService, public commonService: CommonService, public searchService: KzSearchService) { }
  /**
   * To init TitleDetailsInfoComponent
   */
  ngOnInit(): void {
    try {
      this.upDateButtonViewSubscriptions = this.titleDetailsService.getUpdateButtonView().subscribe((updateCTA) => {
        if (!updateCTA) {
          return;
        }
        const { titleDetails } = this.titleDetailsActions;
        const { availability, holdsAllowed, consortiaType } = updateCTA;
        const { isRTV, holdPosition, numOfPatronsOnHold, isInHoldQueue, estimatedWaitTime, availableCopies } = availability || {};
        const { formatType } = titleDetails || {};
        this.holdPosition = holdPosition;
        this.numOfPatronsOnHold = numOfPatronsOnHold;
        this.holdsAllowed = holdsAllowed;
        this.isInHoldQueue = isInHoldQueue;
        this.collectionsType = availableCopies === 9999 ? 'General and Always Available' : 'General';
        this.formatType = formatType ? this.getFormatType(formatType) : '';
        this.estimatedWaitTime = estimatedWaitTime;
        this.isRTV = isRTV;
        this.consortiaType = consortiaType;
      });
      const { titleButtonDetails, titleDetails } = this.titleDetailsActions;
      const { availability, holdsAllowed, consortiaType } = titleButtonDetails || {};
      const { isRTV, holdPosition, numOfPatronsOnHold, isInHoldQueue, estimatedWaitTime, availableCopies } = availability || {};
      const { subjectList: subjectLists, fileSize, formatType } = titleDetails || {};
      this.titleDetailsData = titleDetails;
      this.titleDetailsData.subjectList = this.getSubjectListObject(subjectLists);
      this.fileSize = formatFileSizeBytes(fileSize);
      this.holdPosition = holdPosition;
      this.numOfPatronsOnHold = numOfPatronsOnHold;
      this.holdsAllowed = holdsAllowed;
      this.isInHoldQueue = isInHoldQueue;
      this.collectionsType = availableCopies === 9999 ? 'General and Always Available' : 'General';
      this.formatType = formatType ? this.getFormatType(formatType) : '';
      this.estimatedWaitTime = estimatedWaitTime;
      this.isRTV = isRTV;
      this.consortiaType = consortiaType;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }

  /**
   * getSubjectListObject action button
   * @param [subject] pass the Object of Subject list to split the key and value 
   * @returns 
   */
  getSubjectListObject(subject: ISubjectList) {
    if (!subject) {
      return;
    }
    const keyArray = Object.keys(subject);
    const subjectList = [];
    for (const key of keyArray) {
      subjectList.push(subject[key]);
    }
    const convertToLowerCase = subjectList.map((elm) => {
      elm.subject = (elm.subject.toUpperCase() === LGBTQ) ? elm.subject.toUpperCase() : elm.subject.toLocaleLowerCase();
      return elm;
    });
    return convertToLowerCase;
  }
  getAriaLabel(str : string){
    return str.replace(/MB/g, "Mega Byte");
  }
  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getFormatType(formatType: string) {
    return FORMAT_TYPE_MAP[FORMAT_ICON_MAP[formatType].icon];
  }

  checkSubjectToClearInSessionRefiner() {
    sessionStorage.setItem('titleListFilters', JSON.stringify({}));
  };

  /**
   * To be called on email info button click
   */
  holdPositionAlertPopup(event: Event) {
    event.stopPropagation();
    this.commonService.showAlertDialog({ ...HOLD_POSITION_INFO });
  }

  /**
   *  Track by Title details Actions in href values
   * @param action  Selected action to pass
   * @param value   set the value in pass the action
   */
  trackByTitleDetailsActions(action: keyof typeof TITLE_DETAILS_EVENTS_KEY, value: string) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action, { actionName: value });
    ((action === 'ACTIONS_AUTHOR') || (action === 'ACTIONS_NARRATOR') || (action === 'ACTIONS_SERIES')) && this.searchService.updatedSearchText.next(value);
  }

  ngOnDestroy(): void {
    this.upDateButtonViewSubscriptions && this.upDateButtonViewSubscriptions.unsubscribe();
  }
}
