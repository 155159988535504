import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { setCalenderActiveElements } from '@shared/helper/app.util';
import { THIRD_PARTY_DAYS } from 'app/third-party/shared/constants/third-party.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'axis360-press-reader-calender-header',
  templateUrl: './press-reader-calender-header.component.html',
  styleUrls: ['./press-reader-calender-header.component.scss']
})
export class PressReaderCalenderHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();
  @Output() issueClickEvent = new EventEmitter<Date>();

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    cdr: ChangeDetectorRef,
    private liveAnnouncer: LiveAnnouncer
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    setTimeout(() => {
      document.querySelectorAll('.mat-calendar-body-cell').forEach((n) => {
        const getAriaLabel = n?.getAttribute('aria-label');
        if (getAriaLabel) {
          const extractedDate = this.extractDate(getAriaLabel);
          const dayConverteIntoDate = this.getDay(extractedDate);
          n?.children[0]?.setAttribute('aria-label', `${dayConverteIntoDate} ${getAriaLabel}`);
          n?.removeAttribute('aria-label');
          n?.children[0]?.removeAttribute('aria-hidden');
        }
      });
    }, 50);
    return this.dateAdapter.format(this.calendar.activeDate, { year: 'numeric', month: 'long' });
  }

  getDay = (date: Date | string): string => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[new Date(date).getDay()] || '';
  };

  extractDate(input: string): Date | string {
    const datePattern = /(\b\w+\s\d{1,2},\s\d{4}\b)/;
    const match = input.match(datePattern);
    if (match) {
      return new Date(match[0]);
    }
    return null;
  }

  announceMonthChange() {
    this.liveAnnouncer.announce(this.periodLabel, 'assertive');
  }
  previousClicked() {
    document.getElementById('calendarNext')?.removeAttribute('disabled');
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    if (
      this.dateAdapter.format(this.calendar.activeDate, { year: 'numeric', month: 'long' }) ===
      this.dateAdapter.format(this.calendar.minDate, { year: 'numeric', month: 'long' })
    ) {
      document.getElementById('calendarBack')?.setAttribute('disabled', 'true');
    } else {
      document.getElementById('calendarBack')?.removeAttribute('disabled');
    }
    this.announceMonthChange();
    setCalenderActiveElements((issueDate) => {
      this.issueClickEvent.emit(issueDate);
    });
    setTimeout(() => {
      document.querySelectorAll('.third-party-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header th[scope="col"]').forEach((n) => {
        const days = THIRD_PARTY_DAYS;
        const weekday = days[n.getAttribute('aria-label')];
        if (weekday) {
          n.innerHTML = `<span class="kz-visually-hidden">${weekday}</span> <span aria-hidden="true">${n.getAttribute('aria-label')}</span>`;
          n.removeAttribute('aria-label');
        }
      });

      document.querySelectorAll('.third-party-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-body .mat-calendar-body-cell .mat-calendar-body-cell-content').forEach((n) => {
        const fullDate = n.getAttribute('aria-label');
        const date = n.innerHTML
        if (date) {
          n.innerHTML = `<span class="visually-hidden" aria-label>${fullDate}</span><span aria-hidden="true"> ${date}</span>`;
        }
      });
    }, 2000);
  }

  nextClicked() {
    document.getElementById('calendarBack')?.removeAttribute('disabled');
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    if (
      this.dateAdapter.format(this.calendar.activeDate, { year: 'numeric', month: 'long' }) ===
      this.dateAdapter.format(this.calendar.maxDate, { year: 'numeric', month: 'long' })
    ) {
      document.getElementById('calendarNext')?.setAttribute('disabled', 'true');
    } else {
      document.getElementById('calendarNext')?.removeAttribute('disabled');
    }
    this.announceMonthChange();
    setCalenderActiveElements((issueDate) => {
      this.issueClickEvent.emit(issueDate);
    });
    setTimeout(() => {
      document.querySelectorAll('.third-party-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header th[scope="col"]').forEach((n) => {
        const days = THIRD_PARTY_DAYS;
        const weekday = days[n.getAttribute('aria-label')];
        if (weekday) {
          n.innerHTML = `<span class="kz-visually-hidden">${weekday}</span> <span aria-hidden="true">${n.getAttribute('aria-label')}</span>`;
          n.removeAttribute('aria-label');
        }
      });

      document.querySelectorAll('.third-party-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-body .mat-calendar-body-cell .mat-calendar-body-cell-content').forEach((n) => {
        const fullDate = n.getAttribute('aria-label');
        const date = n.innerHTML
        if (date) {
          n.innerHTML = `<span class="visually-hidden" aria-label>${fullDate}</span><span aria-hidden="true"> ${date}</span>`;
        }
      });

    }, 2000);
  }
}
