<div class="kz-carousel kz-third-party-carousel {{componentTitleClass}}"
    *ngIf="componentData && (isLoading || itemsData?.length>0)"
    [ngClass]="{'card-carousel-featured-list':componentData.type == 'Card_Carousel','item-carousel-default': componentData.type == 'Item_Carousel'}">
    <div class="third-party-heading-sec"
        *ngIf="componentData.type === THIRD_PARTY_TYPES.ITEMS_CAROUSEL || componentData.type === THIRD_PARTY_TYPES.STACK_CAROUSEL || componentData.type === THIRD_PARTY_TYPES.ARTICLE_CAROUSEL">
        <div class="title-with-arrow">
            <h2 class="heading-2" *ngIf=" !isLoading && componentData.title && itemsData">
                <span class="kz-visually-hidden" aria-live="assertive" aria-relevant="text">{{componentData.title ===
                    'Vbook Carousel ' ? 'v Book Carousel' : componentData.title }} {{(isShowCount && (itemsData?.count
                    || titleCount))?'Count '+((itemsData.count || titleCount))+'':''}}</span>
                <span aria-hidden="true" tabindex="-1">{{componentData.title === 'vBooks' ? 'vBooks' :
                    componentData.title
                    }}
                    </span>
                    <span class="result-count" aria-hidden="true">{{(isShowCount && (itemsData?.count ||
                        titleCount))?'('+((itemsData.count || titleCount))+')':''}}</span>
            </h2>
            <a href="javascript:;" (click)="seeAllClick.apply(this)" class="link"
                [ngClass]="{'see-all-text': componentData.description}"
                *ngIf="(!isLoading && !hideSeeAll && !componentData.hideSeeAllLink && itemsData?.length > 9)"
                attr.aria-label="See All {{componentData.title === 'vBooks' ? 'vBooks' : componentData.title }}">See All
                <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon>
            </a>
        </div>

        <p class="description" *ngIf="!isLoading && componentData.description">
            <span [ngClass]="{'description-text': componentData.description}">{{componentData.description}}</span>
        </p>
    </div>
    <ng-container
        *ngIf="!isLoading && itemsData?.length > 0 && componentData.type !== THIRD_PARTY_TYPES.ARTICLE_CAROUSEL">
        <div (window:resize)="onResize()" id="loc_third_party_{{vendorCode}}_{{carouselType}}"
            class="kz-third-party-carousel"
            [ngClass]="thirdPartyVideoClass !== '' ? 'kz-third-party-no-PRESSRDR' : 'kz-third-party-PRESSRDR'">
            <carousel axis360IvyCarousel [dots]="false" (click)="trackCarousel($event);$event.stopPropagation();"
                [margin]="cellMargin" [cellWidth]="cellWidth" [height]="cellHeight"
                (keydown.enter)="trackCarousel($event);$event.stopPropagation();" [cellsToScroll]="cellsToScroll">
                <div class="carousel-cell thirdparty-featured-list-cell" *ngIf="thirdPartyFeatured">
                    <mat-card class="kz-card-des">
                        <div class="text-sec carousel-description"
                            [ngClass]="thirdPartyVideoClass !== '' ? 'eight-ellipsis' : ''">
                            {{componentData.description}}
                        </div>
                    </mat-card>
                </div>
                <div class="carousel-cell" *ngFor="let item of itemsData; let i = index" role="listitem">
                    <axis360-third-party-cards [componentData]="item" [vendorCode]="vendorCode"
                        [parentRoute]="parentRoute" [componentTemplate]="componentData.jsonTempl"
                        [thirdPartyClass]="'third-party-card third-party-card-common newspapers-magazines-img-container'+' '+ item.thirdPartyVideoClass"
                        iscarousel="true">
                    </axis360-third-party-cards>
                </div>
            </carousel>
        </div>
    </ng-container>
    <ng-container
        *ngIf="!isLoading && itemsData?.length > 0 && componentData.type === THIRD_PARTY_TYPES.ARTICLE_CAROUSEL">
        <div id="loc_third_party_article_carousel">
            <carousel axis360IvyCarousel [dots]="false" [margin]="0" [cellWidth]="354"
                (click)="trackCarousel($event);$event.stopPropagation();" [height]="260" [cellsToScroll]="2"
                (keydown.enter)="trackCarousel($event);$event.stopPropagation();">
                <div class="carousel-cell" role="listitem" *ngFor="let item of itemsData">
                    <axis360-third-party-cards [componentData]="item" [vendorCode]="vendorCode"
                        [parentRoute]="parentRoute" [componentTemplate]="componentData.jsonTempl"
                        [thirdPartyClass]="'third-party-articles'" iscarousel="true">
                    </axis360-third-party-cards>
                </div>
            </carousel>
        </div>
    </ng-container>
    <p class="title-shimmer" *ngIf="isLoading"></p>
    <axis360-shimmer-cards
        [compName]="componentData.type === THIRD_PARTY_TYPES.ARTICLE_CAROUSEL ? thirdCompName : compName"
        *ngIf="isLoading" [showCarouselTitle]="false">
    </axis360-shimmer-cards>
</div>