import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { MYSTUFF_SORT_ADDED_DATE } from '@shared/constants/app.constants';
import { ListContent } from '@shared/data/config.model';
import { ListBaseService } from '@shared/services/list-base.service';
import { MYSTUFF_SORT, KZ_HOME_FORMAT_TYPE, HOME_AVAILABILITY } from '@shared/constants/app.constants';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MyStuffService extends ListBaseService {
  isGrid = false;
  public sortOption = MYSTUFF_SORT[0].value;
  public filterOption = KZ_HOME_FORMAT_TYPE[0].value;
  public selectAvailability = HOME_AVAILABILITY[1].value;
  public myStuffCounts = {};
  public activeMyStuff = '';
  public searchKeyword = '';
  public activeVideoVbooksTitle = new BehaviorSubject<boolean>(false);
  public formatTypeList = new BehaviorSubject<any>([]);
  public showSearch = false;
  /**
   * Getter of is grid view boolean
   */
  get isGridView(): boolean {
    return this.isGrid;
  }
  /**
   * Setter for is grid view boolean
   */
  set isGridView(isGridView: boolean) {
    this.isGrid = isGridView;
  }
  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) {
    super(http, profileInfoService, configService);
  }
  /**
   * Gets ListContent from API
   * @param listId input param as listId
   * @param selectedAvailability input param as Availability Filter value
   * @param selectedFormat input param as Format Filter value
   * @returns returns ListContent
   */
  getMyStuffContent(
    listId,
    selectedAvailability,
    selectedFormat,
    sortOption,
    pagenumber = 1,
    limit = 12,
    pageToken = '',
    searchTerm = ''
  ): Promise<ListContent> {
    return this.http
      .get<ListContent>(
        API_INFO.getMyStuffContent
          .replace('<listId>', listId)
          .replace('<pagenumber>', pagenumber.toString())
          .replace('<limit>', limit.toString())
          .replace('<format>', selectedFormat)
          .replace('<availability>', selectedAvailability)
          .replace('<sortField>', sortOption)
          .replace('<sortOrder>', sortOption === MYSTUFF_SORT_ADDED_DATE ? '-1' : '1')
          .replace('<pageToken>', encodeURIComponent(pageToken))
          .replace('<searchTerm>', searchTerm)
      )
      .toPromise();
  }
}
