<div class="profile-image  {{ (profileType || '').toLowerCase() }}"
    [ngClass]="{ 'profile-default-border': !imgSrc || hideImg, 'profile-edit-image': isImageEdit }">
    <img *ngIf="imgSrc" class="profile-image" [src]="imgSrc" [alt]="imgAlt"
        (error)="hideImg = isImageEdit ? false : true" [hidden]="hideImg" />
    <mat-icon svgIcon="kz-profile-user" *ngIf="!imgSrc " aria-hidden="true"></mat-icon>
</div>
<div class="profile-type-indicator profile-general profile-{{profileType.toLowerCase()}}"
    *ngIf="(isManageEdit && !isImageEdit)">
    <p class="profile-type-name " [ngClass]="{'profile-type-center' : profileType.toLowerCase() !== 'adult'}">
        {{profileType.toLowerCase() === 'adult' ? 'General' : profileType}}
    </p>
    <mat-icon class="" aria-hidden="true" *ngIf="profileType.toLowerCase() === 'adult' && isPrimary">star</mat-icon>
</div>
<div role="button" tabindex="{{ isImageEdit ? 0 : -1 }}" class="action-icon cursor-pointer"
    (mouseover)="removeTooltipAnnouce()" (keyup.Tab)="removeTooltipAnnouce()"
    [matTooltip]="isEdit ? 'Edit profile picture' : ''" matTooltipClass="kz-card-tooltip"
    id="{{ isEdit ? 'edit-icon-focus' : '' }}" [ngClass]="{ 'kz-edit-profile-icon': isImageEdit }" *ngIf="isEdit"
    (click)="editImg()" (keydown.enter)="editImg();$event.stopPropagation();" [attr.aria-label]="editAriaLabel">
    <img class="kz-edit-icon" alt="" src="/angular-app/assets/images/Edit-kz.svg" />
</div>