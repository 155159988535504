<div class="kz-press-reader">
    <div class="bread-crum-main">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'"></axis360-breadcrumb>
    </div>
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading; else content"></axis360-spinner-loader>
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{titleCardAnnounce}}</p>
    <ng-template #content>
        <ng-container *ngFor="let component of layoutComponents; let i =index;">
            <axis360-third-party-carousel-type [id]="'thirdPartyCarousel_' + i" class="tierone-third-party-container theme-odd-even" [componentData]="component" [vendorCode]="vendorCode" [inputElmIndex]="i" [parentRoute]="parentRoute"> </axis360-third-party-carousel-type>
        </ng-container>
    </ng-template>
</div>