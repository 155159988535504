export interface Book {
  bookId?: string;
  availability?: string;
  percentageComplete?: number;
  totalCompletePercentage?: string;
  info?: BookDetail;
  title?: string;
  type?: number;
  sequence?: number;
  isParticipantCanRead?: boolean;
  isHiddenTitle?: boolean;
  suggestedBookId?: string;
  numberOfBooks?: number;
  addBtn?: boolean;
  isDelete?: boolean;
  isManual?: boolean;
  bookType?: string;
  author?: string;
  bookName?: string;
}
export interface User {
  userGuid: string;
  userFirstName?: string;
  userLastName?: string;
  userImageURL?: string;
}
export interface BookDetail {
  ISBN: string;
  isbn: string;
  ItemId: string;
  itemId: string;
  LibraryId?: string;
  ListId?: string;
  LongTitle?: string;
  Title?: string;
  title?: string;
  Author?: string;
  author?: string;
  authors?: string[];
  Narrator?: string[];
  FormatType?: string;
  formatType?: string;
  IsAvailable?: boolean;
  isAvailable?: boolean;
  IsRTV?: boolean;
  isRTV?: boolean;
  IsRecommendable?: boolean;
  isRecommendable?: boolean;
  TotalQuantity?: number;
  OnOrderQuantity?: number;
  RTVFormats?: string;
  ActiveHolds?: number;
  ListName?: string;
  SortField?: string;
  CollectionType?: string;
  Edition?: string;
  Length?: string;
  imageUrl?: string;
  titleStateInfo?: TitleStateInfo;
  titleStatusInfo?: TitleStateInfo;
  libraryId?: string;
  listId?: string;
  narrator?: string[];
  totalQuantity?: number;
  onOrderQuantity?: number;
  rtvFormats?: string;
  activeHolds?: number;
  listName?: string;
  sortField?: string;
  collectionType?: string;
  edition?: string;
  length?: number;
  runTime?: string;
  RunTime?: string;
  Attribute?: string;
  attribute?: string;
  AxisAttribute?: string[];
  axisAttribute?: string[];
  numberOfBooks?: number;
  addBtn?: boolean;
  isManual?: boolean;
  percentageComplete?: number;
  ischecked?: boolean;
  DisplayStatus?: string;
  HoldPosition?: string;
  src?: string;
  series?: string;
  Series?: string;
  publisher?: string;
  format?:string;
}
export interface TitleStateInfo {
  state?: number;
  displayStatus?: string;
  estimatedWaitTime?: string;
  holdPosition?: string;
  numOfPatronsOnHold?: string;
  consortiaType?: string;
  ppcBudgetReached?: boolean;
  actions?: Actions[];
  checkoutDueDate?: string;
  checkoutDueDateinHrs?: number;
  reserveDuration?: string;
  isManual?: boolean;
}
export interface Actions {
  actionButtonId?: string;
  itemId?: string;
  isbn?: string;
  buyNowUrl?: string;
  reservationId?: string;
  formatType?: string;
  action?: number;
  enabled?: boolean;
  isSuggestedbook?: boolean;
  displayStatus?: string;
}
export interface BookDetailAPIRes {
  Items: BookDetail[];
  items?: BookDetail[];
}
export interface BookSearchRequest {
  fetchStateInfo: boolean;
  itemIdList: string;
}
export interface ActionClickResponse {
  status?: boolean;
  title?: string;
  primaryStatusMessage?: string;
  secondaryMessage?: string;
  bottomMessage?: string;
  actionButtonId?: string;
  itemId?: string;
  iSBN?: string;
}
export interface BookDetails {
  bookIdList?: string[];
}
export interface ReadingProgressRes {
  bookProgress: [BookProgress];
  responseCode?: number;
}
export interface BookProgress {
  bookId?: string;
  progressPercentage?: number;
}

export interface Participants {
  status: string;
  userGuid: string;
  mandatory: boolean;
  profileId: string;
  joinedDate: string | Date;
  milestoneBookList?: MilestoneBookList[];
}
export interface MilestoneBookList {
  bookId?: string;
  bookName?: string;
  author?: string;
  bookType?: string;
  isManual?: boolean;
  isbn?: any;
  percentageComplete?: any;
  sequence?: any;
  addBtn?: boolean;
}

export class MilestoneInfo {
  ISBN: string;
  isbn: string;
  ItemId: string;
  itemId: string;
  LibraryId?: string;
  ListId?: string;
  LongTitle?: string;
  Title?: string;
  title?: string;
  Author?: string;
  author?: string;
  Narrator?: string[];
  FormatType?: string;
  formatType?: string;
  IsAvailable?: boolean;
  isAvailable?: boolean;
  IsRTV?: boolean;
  isRTV?: boolean;
  IsRecommendable?: boolean;
  isRecommendable?: boolean;
  TotalQuantity?: number;
  OnOrderQuantity?: number;
  RTVFormats?: string;
  ActiveHolds?: number;
  ListName?: string;
  SortField?: string;
  CollectionType?: string;
  Edition?: string;
  Length?: string;
  imageUrl?: string;
  titleStateInfo?: TitleStateInfo;
  libraryId?: string;
  listId?: string;
  narrator?: string[];
  totalQuantity?: number;
  onOrderQuantity?: number;
  rtvFormats?: string;
  activeHolds?: number;
  listName?: string;
  isManual?: boolean;
  sortField?: string;
  collectionType?: string;
  edition?: string;
  length?: number;
  runTime?: string;
  RunTime?: string;
  Attribute?: string;
  attribute?: string;
  AxisAttribute?: string[];
  axisAttribute?: string[];
  numberOfBooks?: number;
  addBtn?: boolean;
  percentageComplete?: number
  constructor(book) {
    this.itemId = book.bookId;
    this.isManual = book.isManual;
    this.title = book.bookName;
    this.author = book.author;
    this.titleStateInfo = {};
    this.formatType = book.bookType;
    this.titleStateInfo.actions = [];
    this.percentageComplete = book.percentageComplete;
    const actionButton = this.percentageComplete === 100 ? 'btnMarkAsNotDone' : "btnMarkAsDone"
    this.titleStateInfo.actions = [
      {
        actionButtonId: actionButton,
        action: 0,
        buyNowUrl: null,
        enabled: true,
        isbn: book.ISBN || book.isbn,
        itemId: book.itemId || book.ItemId
      }
    ];
  }
}
