import { ProfileInfoService } from './../../shared/services/profile-info.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadInputComponent, UploadEventData } from '@profile/shared/components/file-upload-input/file-upload-input.component';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, getBackgroundImageResize, positionFooter } from '@shared/helper/app.util';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { Location } from '@angular/common';
import { CommonService } from '@shared/services/common.service';
import { BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT, BACK_ROUNDIMAGE_TEEN_PROFILE_EDIT, BACK_ROUNDIMAGE_KID_PROFILE_EDIT } from '@shared/constants/search.constant';
import { ProfileAvatarResponse } from '@profile/shared/data/profiles.model';
@Component({
  selector: 'axis360-avatars',
  templateUrl: './avatars.component.html',
  styleUrls: ['./avatars.component.scss']
})
export class AvatarsComponent extends FileUploadInputComponent implements OnInit, OnDestroy {
  avatarUrls?: ProfileAvatarResponse[];
  @Input() type = '';
  @Output() avatarChanged = new EventEmitter();
  isLoading = true;
  selectedProfileType = 'General';
  isAvatarAnnouce = false;
  backgroundImageUrl: string;
  pageAnnounce = ''
  /**
   * @param profilesService profiles service
   * @param router angular router
   */

  constructor(
    private profilesService: ProfilesService,
    public router: Router,
    private liveAnnouncer: LiveAnnouncer,
    private snackBarService: SnackBarService,
    public location: Location,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    public profileInfoService: ProfileInfoService
  ) {
    super();
  }
  /**
   * Get avatars urls from API reponse
   */
  ngOnInit(): void {
    document.getElementById('loc_headerId').focus();
    this.selectedProfileType = this.profileInfoService.selectedEditProfileType;
    document.title = `Choose profile picture - ${this.selectedProfileType}`;
    if (!this.profilesService.avatarData) {
      this.getAvatars();
    }
    else{
      this.avatarUrls = this.profilesService.avatarData;
      this.isLoading = false;
    }
    positionFooter(true);
    this.onResize();
    this.startTimerByAnnounce().then(result => {
      if (result) {
        this.pageAnnounce = `Choose profile picture - ${this.selectedProfileType}`
        this.isAvatarAnnouce = true;
      }
    });
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
      this.isAvatarAnnouce = false;
    }, 4000);
  }
  startTimerByAnnounce = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
          resolve('Data loaded successfully');
      }, 2000);
    });
  }
  onResize() {
    const profileAudMap = {
      adult: BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT,
      teen: BACK_ROUNDIMAGE_TEEN_PROFILE_EDIT,
      kid: BACK_ROUNDIMAGE_KID_PROFILE_EDIT
    };
    const getImage = profileAudMap[this.type.toLowerCase()];
    this.backgroundImageUrl = getBackgroundImageResize(getImage);
  }
  ngOnDestroy() {
    cdkRemoveVisualHidden();
  }
  /**
   * Get avatars urls by calling API on init
   */
  getAvatars(): void {
    this.profilesService.getAvatars().subscribe(
      (reponse) => {
        this.avatarUrls = reponse;
        this.isLoading = false;
        this.profilesService.avatarData = reponse;
        positionFooter(true);
      },
      () => {
        this.isLoading = false;
        this.avatarUrls = [];
        this.profilesService.showErrorAlertDialog();
        positionFooter(true);
      }
    );
  }
  /**
   * To set avatar data and navigave back to create adult/teen/kid screen
   * @param  data avatar data
   */
  setAvatarAndNavigate(data: string) {
    this.profilesService.avatar = data;
    this.avatarChanged.emit(data);
    this.liveAnnouncer.announce('Avatar image updated successfully');
    setTimeout(() => {
      this.liveAnnouncer.announce(`Profile Settings - ${this.type} Page`);
    }, 1000);
  }
  /**
   * To be called on uploading the photo
   * @param  uploadEventData contains error and data
   */
  uploadPhotoEvent({ error, data }: UploadEventData) {
    if (error) {
      this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, error);
      this.liveAnnouncer.announce(`Failure! ${error}`);
      return;
    }
    this.setAvatarAndNavigate(data);
  }
}
