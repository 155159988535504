import { Injectable } from '@angular/core';
import { BreakPoint } from '@shared/data/break-point';
import { BreakPointsOption } from '@shared/data/carousal-break-point-option';

@Injectable({
  providedIn: 'root'
})
export class BreakPointService {
  breakPoint: BreakPointsOption = { breakpoint: BreakPoint.pixel1440, numVisible: 6, numScroll: 6, height: 286, width: 192 };
  featureCover: BreakPointsOption = { breakpoint: BreakPoint.pixel1440, numVisible: 3, numScroll: 3, height: 286, width: 192 };
  badgesBreak: BreakPointsOption = { breakpoint: BreakPoint.pixel1440, numVisible: 3, numScroll: 3, height: 195, width: 192 };
  categoryBreakPoint: BreakPointsOption = { breakpoint: BreakPoint.pixel1440, numVisible: 9, numScroll: 9, height: 286, width: 192 };
  responsiveOptions = [
    {
      breakpoint: BreakPoint.pixel3840,
      numVisible: 15,
      numScroll: 15,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2560,
      numVisible: 10,
      numScroll: 10,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2200,
      numVisible: 9,
      numScroll: 9,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1920,
      numVisible: 7,
      numScroll: 7,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1600,
      numVisible: 6,
      numScroll: 6,
      height: 336,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1440,
      numVisible: 6,
      numScroll: 6,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1280,
      numVisible: 5,
      numScroll: 5,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1024,
      numVisible: 5,
      numScroll: 5,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1023,
      numVisible: 5,
      numScroll: 5,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel992,
      numVisible: 4,
      numScroll: 4,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel768,
      numVisible: 4,
      numScroll: 4,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel700,
      numVisible: 3,
      numScroll: 3,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel560,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel475,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
  ];
  responsiveBadgesOptions = [
    {
      breakpoint: BreakPoint.pixel3840,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2560,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2200,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1920,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1600,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1440,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1280,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1024,
      numVisible: 3,
      numScroll: 3,
      height: 144,
      width: 142,
    },
    {
      breakpoint: BreakPoint.pixel1023,
      numVisible: 3,
      numScroll: 3,
      height: 144,
      width: 142,
    },
    {
      breakpoint: BreakPoint.pixel768,
      numVisible: 3,
      numScroll: 3,
      height: 195,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel560,
      numVisible: 2.8,
      numScroll: 2.8,
      height: 144,
      width: 142,
    },
    {
      breakpoint: BreakPoint.pixel475,
      numVisible: 2.8,
      numScroll: 2.8,
      height: 144,
      width: 142,
    },
  ];
  responsiveFeatureOptions = [
    {
      breakpoint: BreakPoint.pixel3840,
      numVisible: 3,
      numScroll: 3,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2560,
      numVisible: 3,
      numScroll: 3,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2200,
      numVisible: 3,
      numScroll: 3,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1920,
      numVisible: 3,
      numScroll: 3,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1600,
      numVisible: 3,
      numScroll: 3,
      height: 336,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1440,
      numVisible: 3,
      numScroll: 3,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1280,
      numVisible: 3,
      numScroll: 3,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1024,
      numVisible: 3,
      numScroll: 3,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1023,
      numVisible: 3,
      numScroll: 3,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel992,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel768,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel560,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel475,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
  ];

  responsiveCategoryOptions = [
    {
      breakpoint: BreakPoint.pixel3840,
      numVisible: 14,
      numScroll: 14,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2560,
      numVisible: 13,
      numScroll: 13,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel2200,
      numVisible: 12,
      numScroll: 12,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1920,
      numVisible: 11,
      numScroll: 11,
      height: 286,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1600,
      numVisible: 10,
      numScroll: 10,
      height: 336,
      width: 192,
    },
    {
      breakpoint: BreakPoint.pixel1440,
      numVisible: 9,
      numScroll: 9,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1280,
      numVisible: 9,
      numScroll: 9,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1024,
      numVisible: 7,
      numScroll: 7,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel1023,
      numVisible: 7,
      numScroll: 7,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel992,
      numVisible: 5,
      numScroll: 5,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel768,
      numVisible: 5,
      numScroll: 5,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel560,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
    {
      breakpoint: BreakPoint.pixel475,
      numVisible: 2,
      numScroll: 2,
      height: 292,
      width: 162,
    },
  ];
  constructor() { }

  onResize(type: number, event?: any) {
    const innerWidth = type === 1 ? event.target.innerWidth : window.innerWidth;
    let breakPoint = BreakPoint.pixel1440;
    if (innerWidth > 2560) {
      breakPoint = BreakPoint.pixel3840;
    } else if (innerWidth > 2200) {
      breakPoint = BreakPoint.pixel2560;
    } else if (innerWidth > 1920) {
      breakPoint = BreakPoint.pixel2200;
    } else if (innerWidth > 1600) {
      breakPoint = BreakPoint.pixel1920;
    } else if (innerWidth > 1440) {
      breakPoint = BreakPoint.pixel1600;
    } else if (innerWidth > 1280) {
      breakPoint = BreakPoint.pixel1440;
    } else if (innerWidth > 1024) {
      breakPoint = BreakPoint.pixel1280;
    } else if (innerWidth > 1023) {
      breakPoint = BreakPoint.pixel1024;
    } else if (innerWidth > 700) {
      breakPoint = BreakPoint.pixel1023;
    } else if (innerWidth > 560) {
      breakPoint = BreakPoint.pixel700;
    } else if (innerWidth > 475) {
      breakPoint = BreakPoint.pixel560;
    } else {
      breakPoint = BreakPoint.pixel475;
    }
    const breakpointFilter = this.responsiveOptions.filter((point) => point.breakpoint === breakPoint);
    if (breakpointFilter && breakpointFilter.length > 0) {
      this.breakPoint = breakpointFilter[0];
    }
  }

  onResizeCategories(type: number, event?: any) {
    const innerWidth = type === 1 ? event.target.innerWidth : window.innerWidth;
    let breakPoint = BreakPoint.pixel1440;
    if (innerWidth > 2560) {
      breakPoint = BreakPoint.pixel3840;
    } else if (innerWidth > 2200) {
      breakPoint = BreakPoint.pixel2560;
    } else if (innerWidth > 1920) {
      breakPoint = BreakPoint.pixel2200;
    } else if (innerWidth > 1600) {
      breakPoint = BreakPoint.pixel1920;
    } else if (innerWidth > 1440) {
      breakPoint = BreakPoint.pixel1600;
    } else if (innerWidth > 1280) {
      breakPoint = BreakPoint.pixel1440;
    } else if (innerWidth > 1024) {
      breakPoint = BreakPoint.pixel1280;
    } else if (innerWidth > 1023) {
      breakPoint = BreakPoint.pixel1024;
    } else if (innerWidth > 700) {
      breakPoint = BreakPoint.pixel1023;
    } else if (innerWidth > 560) {
      breakPoint = BreakPoint.pixel700;
    } else if (innerWidth > 475) {
      breakPoint = BreakPoint.pixel560;
    } else {
      breakPoint = BreakPoint.pixel475;
    }
    const breakpointFilter = this.responsiveCategoryOptions.filter((point) => point.breakpoint === breakPoint);
    if (breakpointFilter && breakpointFilter.length > 0) {
      this.categoryBreakPoint = breakpointFilter[0];
    }
  }

  onResizeFeaturedTitle(type: number, event?: any) {
    const innerWidth = type === 1 ? event.target.innerWidth : window.innerWidth;
    let breakPoint = BreakPoint.pixel1440;
    if (innerWidth > 1400) {
      breakPoint = BreakPoint.pixel1440;
    } else if (innerWidth > 1200) {
      breakPoint = BreakPoint.pixel1280;
    } else if (innerWidth > 1023) {
      breakPoint = BreakPoint.pixel1024;
    } else if (innerWidth > 767) {
      breakPoint = BreakPoint.pixel768;
    } else {
      breakPoint = BreakPoint.pixel700;
    }
    const breakpointFilter = this.responsiveFeatureOptions.filter((point) => point.breakpoint === breakPoint);
    if (breakpointFilter && breakpointFilter.length > 0) {
      const breakPointAcceptTitle = breakpointFilter[0];
      this.featureCover = { ...breakPointAcceptTitle, height: 286, width: 192 };
    }
  }

  onResizeBadges(type: number, event?: any) {
    const innerWidth = type === 1 ? event.target.innerWidth : window.innerWidth;
    let breakPoint = BreakPoint.pixel1440;
    if (innerWidth > 1400) {
      breakPoint = BreakPoint.pixel1440;
    } else if (innerWidth > 1200) {
      breakPoint = BreakPoint.pixel1280;
    } else if (innerWidth > 1023) {
      breakPoint = BreakPoint.pixel1024;
    } else if (innerWidth > 767) {
      breakPoint = BreakPoint.pixel768;
    } else {
      breakPoint = BreakPoint.pixel700;
    }
    const breakpointFilter = this.responsiveBadgesOptions.filter((point) => point.breakpoint === breakPoint);
    if (breakpointFilter && breakpointFilter.length > 0) {
      const breakPointAcceptTitle = breakpointFilter[0];
      this.badgesBreak = { ...breakPointAcceptTitle, height: 195, width: 192 };
    }
  }

}
