import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInterest } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { InterestSurveyComponent } from '../interest-survey.component';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { CommonService } from '@shared/services/common.service';
@Component({
  selector: 'axis360-interest-survey-popup',
  templateUrl: './interest-survey-popup.component.html',
  styleUrls: ['./interest-survey-popup.component.scss']
})
export class InterestSurveyPopupComponent extends InterestSurveyComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<InterestSurveyPopupComponent>,
    public homeService: HomeService,
    public snackBarService: SnackBarService,
    public homeAnalyticsService: HomeAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public dialogData: UserInterest,
    public spinnerService: SpinnerService,
    public liveAnnouncer: LiveAnnouncer,
    public myShelfListService: MyShelfListService,
    public router: Router,
    public profilesService: ProfilesService,
    public profileInfoService: ProfileInfoService,
    public route: ActivatedRoute,
    public titleListService: TitleListService,
    public commonService: CommonService
  ) {
    super(homeService, snackBarService, homeAnalyticsService, spinnerService, liveAnnouncer, profileInfoService, myShelfListService, router, route, profilesService, titleListService, commonService);
  }
  /**
   * To set modal data as interests on init
   */
  ngOnInit() {
    super.setInitialInterest(this.dialogData);
    this.homeAnalyticsService.trackScreen('INTEREST_SURVEY');
  }
  /**
   * To close interests survey dialog of interest survey popup component
   * @param reload input param as reload
   */
  closeInterestsSurveyDialog = (reload = true) => {
    this.dialogRef.close({ saveInterest: false });
    if (reload) {
      this.dialogRef.close({ saveInterest: true });
    }
  };
}
