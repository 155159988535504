<div *ngIf="isCollectionEnabled && !isPPCOnlyLibrary">
  <ng-container *ngFor="let list of collectionData">  
    <axis360-titles-carousel class="carousel-block kz-custom-container-secondary" [listData]="list.listData" [titlesList]="list.Items" [addCard]="true" [parentRoute]="parentRoute"></axis360-titles-carousel>
  </ng-container>
</div>
<div *ngIf="isPPCOnlyLibrary">
  <ng-container *ngFor="let list of collectionData">    
    <axis360-titles-carousel class="carousel-block kz-custom-container-secondary" [listData]="list.listData" [titlesList]="list.Items" [addCard]="true" [parentRoute]="parentRoute" *ngIf="list.Items.length > 0" ></axis360-titles-carousel>
  </ng-container>
</div>
