import { Subscription } from 'rxjs';
import { Component, Input, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LayoutComponent } from '@shared/data/third-party-model';
import {
  ARTICLE_SORT_REFINER,
  FORMAT_TYPE_BY_VENDOR_CODE,
  NEWS_FILTER,
  PRESSRDR,
  PROFILE_TYPES_UI_AUDIENCE_DESC,
  PUBLICATIONS_SORT_REFINER,
  THIRD_PARTY_SUBTYPE,
  THIRD_PARTY_TYPES,
  TIER2_SORT_BY_REFINER_NM,
  TIER2_SORT_REFINER_NM,
  THIRD_PARTY_VIDEO_REFINERS,
  THIRD_PARTY_VENDOR_CODE
} from '../shared/constants/third-party.constants';
import { IAction, ISortByRefiner } from '../shared/data/third-party-model';
import { ThirdPartyService } from '../shared/services/third-party.service';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { ActivatedRoute } from '@angular/router';
import { ThirdPartyAnalyticService } from '../shared/services/third-party-analytic.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, deepClone, refinerScrollFound } from '@shared/helper/app.util';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'axis360-third-party-refiners',
  templateUrl: './third-party-refiners.component.html',
  styleUrls: ['./third-party-refiners.component.scss']
})
export class ThirdPartyRefinersComponent implements OnInit, OnDestroy {
  @Input() component: LayoutComponent;
  @Input() showCategories: boolean;
  @Input() vendorCodeInput = '';
  @Input() isMobileResponsive = false;
  @ViewChild('refiners', { read: TemplateRef }) private refinersRef: TemplateRef<any>;
  THIRD_PARTY_TYPES = THIRD_PARTY_TYPES;
  componentData;
  initialRefiners: {};
  tempRefiners: {};
  isLoading = true;
  dialogRef: MatDialogRef<TemplateRef<any>>;
  refinerSubscription: Subscription;
  updateRefinerSubscription: Subscription;
  vendorCode: string;
  isHiddenRefiner = true;
  sortByRefiner: ISortByRefiner[];
  state = -1;
  typeSortOption: string;
  THIRD_PARTY_SUBTYPE = THIRD_PARTY_SUBTYPE;
  refinerScrollFound = refinerScrollFound;
  contentCollectionId = 'contentCollectionId';
  featuredClass = 'featuredClass';
  touchTime: number;
  constructor(
    private thirdPartyService: ThirdPartyService,
    public activeRoute: ActivatedRoute,
    private matDialog: MatDialog,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public searchService: KzSearchService,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public liveAnnouncer: LiveAnnouncer,
  ) { }
  ngOnInit() {
    const { vendor_code = '', subType = '' } = this.activeRoute.snapshot.queryParams;
    this.vendorCode = this.vendorCodeInput === '' ? vendor_code : this.vendorCodeInput;
    const defaultValues = {
      inVal: 'inVal',
      inVal1: 'inVal1'
    };
    if (this.vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
      const SortOption = {
        ['Articles']: ARTICLE_SORT_REFINER,
        ['Publications']: PUBLICATIONS_SORT_REFINER
      };
      this.typeSortOption = subType !== 'Articles' ? 'orderBy' : 'sort';
      const resultSortOption = SortOption[subType] ? SortOption[subType] : TIER2_SORT_REFINER_NM;
      this.sortByRefiner = [resultSortOption];
      this.sortByRefiner[0].filters = this.sortByRefiner[0].filters.filter((ref) => {
        if (ref.value === this.component.defaultValues[this.typeSortOption]) {
          ref.isSelected = true;
          this.sortByRefiner[0].selectedFilter = ref.value;
        }
        return ref;
      });
      this.thirdPartyService.sortByOptionIsSelected = { [this.typeSortOption]: this.sortByRefiner[0].selectedFilter };
      this.component.defaultValues = {
        ...this.component.defaultValues,
        inVal: this.component.defaultValues[defaultValues.inVal] !== '' ? this.configService.currentLibrary.countryCode.toString() : '',
        inVal1: this.component.defaultValues[defaultValues.inVal1] !== '' ? this.configService.currentLibrary.languageCode.toString() : ''
      };
    } else {
      this.component.defaultValues = {
        ...this.component.defaultValues
      };
    }
    this.tempRefiners = deepClone(this.component.defaultValues);
    this.thirdPartyService.tempRefiners = this.tempRefiners;
    this.updateRefinerSubscription = this.thirdPartyService.updateRef$.subscribe((ref) => {
      const isRefiner =
        sessionStorage.getItem('refinerUpdated') !== 'undefined' ? JSON.parse(sessionStorage.getItem('refinerUpdated')) : false;
      if (ref) {
        const refDeepClone = deepClone(ref);
        this.isLoading = true;
        this.updateCompData(undefined, refDeepClone, isRefiner);
        const restUpdate = this.restUpdate(ref);
        this.thirdPartyService.tierTwoRefinerChange.next(restUpdate);
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    });
    const defaultSelected =
      sessionStorage.getItem('defaultValues') !== 'undefined' ? JSON.parse(sessionStorage.getItem('defaultValues')) : null;
    if (defaultSelected) {
      this.initialRefiners = { ...defaultSelected };
    } else {
      this.initialRefiners = { ...this.component.defaultValues };
    }
    this.refinerSubscription = this.thirdPartyService.openRefiner.subscribe((isOpen) => {
      if (isOpen) {
        this.isMobileResponsive = !!isOpen;
        isOpen ? this.openRefinersInModal() : (this.dialogRef?.close && this.dialogRef.close());
      }
    });
    this.getData();
    const vendorList = (this.configService.vendorDetails || []).find((list) => list.vendor_code === this.vendorCode);
    this.searchService.selectedCategory = vendorList?.display_name || '';
    this.thirdPartyAnalyticService.trackScreen('REFINERS');
    if (vendorList?.vendor_groups?.length > 0 && vendorList.vendor_code === PRESSRDR) {
      this.searchService.selectedCategory = this.activeRoute.snapshot.queryParams.pageTitle;
    }
  }
  openRefinersInModal() {
    this.isMobileResponsive = false;
    this.dialogRef = this.matDialog.open(this.refinersRef, {
      panelClass: 'filter-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'filter-title'
    });
  }
  async getData() {
    try {
      if (this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS_REFINERS) {
        const { q = '', searchTerm = '', searchType = '', title = '', code = '', series = '' } = this.activeRoute.snapshot.queryParams;
        const { configService, profileInfoService, component } = this;
        const { dataApi } = component;
        const { currentProfile } = profileInfoService;
        const { currentLibrary, vendorDetails } = configService;
        const vendorList = vendorDetails.find((list) => list.vendor_code === this.vendorCode);
        const vendorSubscription = configService.getSubscriptionData(this.vendorCode.toLocaleLowerCase());
        // Update collection ID based on library settings
        const updateCollectionId = Object.keys(dataApi.library_settings || {}).reduce((acc, key) => {
          acc[key] = vendorSubscription[dataApi.library_settings[key]];
          return acc;
        }, {});
        series && (dataApi.search = { ...dataApi.search, term: series });
        q && (dataApi.search = { ...dataApi.search, term: q });
        searchType &&
          code &&
          (dataApi.search = {
            ...dataApi.search,
            term: title
          });
        searchTerm && (dataApi.search = { ...dataApi.search, term: searchTerm });
        const isZoneEnable = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
          ? 'teen'
          : 'adult';
        const isAudiencesOptions = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
          ? 'teen,children'
          : '';
        const isProfileTypeByAudience = currentProfile.profileType.toLowerCase() === 'adult'
          ? isZoneEnable
          : currentProfile.profileType.toLowerCase();
        this.component.dataApi = {
          ...dataApi,
          search: {
            ...dataApi.search,
            format: FORMAT_TYPE_BY_VENDOR_CODE[vendorList.format_code],
            libraryid: updateCollectionId[this.contentCollectionId],
            audience: currentProfile.profileType
              ? PROFILE_TYPES_UI_AUDIENCE_DESC[isProfileTypeByAudience]
              : isAudiencesOptions
          }
        };
      }
      const data = (await this.thirdPartyService.getDataFromDataApi(this.component.dataApi)) as any[];
      if (this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS_REFINERS) {
        const refiner = THIRD_PARTY_VIDEO_REFINERS;
        const sortListData = data[refiner.sortList];
        let refinerListData = data[refiner.refinerList];
        const publicationDateListData = data[refiner.publicationDateList];
        const addedDateListData = data[refiner.addedDateList];
        this.typeSortOption = 'searhSort';
        const sortByOptionIsSelected = this.thirdPartyService.sortByOptionIsSelected[this.typeSortOption];
        const selectedSortOption = sortByOptionIsSelected || this.component.defaultValues[this.typeSortOption];
        this.sortByRefiner = [
          {
            refiner: 'sort By',
            filters: sortListData.map((item) => {
              return {
                title: item.displayName,
                value: item.value,
                isSelected: item.value === selectedSortOption // Set isSelected to true for "Relevance"
              };
            }),
            selectedFilter: selectedSortOption
          }
        ];
        if (!this.thirdPartyService.searchViewRefiner) {
          refinerListData = refinerListData.filter(x => x.listname !== 'Subject').filter(y => y.listname !== 'Availablity');
        }
        this.thirdPartyService.sortByOptionIsSelected = { [this.typeSortOption]: this.sortByRefiner[0].selectedFilter };
        this.updateCompData({
          refinerList: refinerListData,
          publicationDateList: publicationDateListData,
          addedDateList: addedDateListData
        });
        this.isLoading = false;
        return;
      }
      const componentData = data.reduce((acc, cur) => {
        acc[cur.name.toLowerCase()] = cur.categories.map((cat) => {
          cat.id = `${cat.id}`;
          return cat;
        });
        return acc;
      }, {});
      this.updateCompData(componentData);
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }
  updateCompData(componentData = this.componentData, defaultValues = this.initialRefiners, refinerUpdate = false) {
    this.component.defaultValues = { ...defaultValues };
    if (this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS_REFINERS) {
      const refinerList = 'refinerList';
      componentData[refinerList].length === 0 && (this.isHiddenRefiner = false);
      /** componentData[refinerList] = componentData[refinerList].slice().sort((a, b) => a.listname.localeCompare(b.listname)); */
      componentData[refinerList].map((ref) => {
        ref.defaultValues = this.component.defaultValues;
        return ref;
      });
      this.componentData = {
        ...componentData,
        defaultValues: this.component.defaultValues
      };
      sessionStorage.setItem('defaultValues', JSON.stringify(this.component.defaultValues));
      this.thirdPartyService.getRefiner.next(this.component.defaultValues);
      return;
    }
    this.componentData = { ...componentData, defaultValues: this.component.defaultValues };
    sessionStorage.setItem('defaultValues', JSON.stringify(this.component.defaultValues));
    sessionStorage.setItem('refinerUpdated', JSON.stringify(refinerUpdate));
    this.thirdPartyService.getRefiner.next(deepClone(this.componentData));
    const countries = this.componentData[NEWS_FILTER.COUNTRY];
    const languages = this.componentData[NEWS_FILTER.LANGUAGE];
    const categories = this.componentData[NEWS_FILTER.CATEGORIES];
    this.thirdPartyService.filterObject = { countries, languages, categories };
  }
  reset() {
    this.isLoading = true;
    this.sortByRefiner[0].filters = this.sortByRefiner[0].filters.filter((ref) => {
      if (ref.value === this.tempRefiners[this.typeSortOption]) {
        ref.isSelected = true;
        this.sortByRefiner[0].selectedFilter = ref.value;
      }
      ref.isSelected = false;
      return ref;
    });
    this.thirdPartyService.sortByOptionIsSelected = { [this.typeSortOption]: this.sortByRefiner[0].selectedFilter };
    this.updateCompData(undefined, { ...this.tempRefiners, ...this.thirdPartyService.sortByOptionIsSelected });
    const { tp, has, q } = this.activeRoute.snapshot.queryParams;
    this.initialRefiners = { ...this.tempRefiners, tp, has, q };
    this.thirdPartyService.tierTwoRefinerChange.next(this.initialRefiners);
    this.thirdPartyService.tierTwoRefinerReset.next(true);
    cdkRemoveVisualHidden();
    setTimeout(() => {
      this.liveAnnouncer.announce('Filters have been cleared');
      this.isLoading = false;
      cdkVisualHidden();
    }, 2000);
  }
  refinersChange(action: IAction) {
    if (!action.data) {
      return;
    }
    this.dialogRef?.close && this.dialogRef.close();
    const { type, ...rest } = action.data;
    if (type === 'reset') {
      this.updateCompData(undefined, rest, false);
      this.thirdPartyAnalyticService.trackRefinersEvent('ACTIONS_REST');
      setTimeout(() => {
        cdkRemoveVisualHidden();
      }, 1500);
      this.reset();
      return;
    }
    this.thirdPartyAnalyticService.trackRefinersEvent('ACTIONS_SEARCH');
    this.updateCompData(undefined, { ...rest, ...this.thirdPartyService.sortByOptionIsSelected }, true);
    let restUpdate = this.restUpdate(rest);
    this.thirdPartyAnalyticService.trackRefinersEvent('ACTIONS_SELECTED', { refinername: JSON.stringify(rest) });
    restUpdate = { ...restUpdate, ...this.thirdPartyService.sortByOptionIsSelected };
    this.thirdPartyService.tierTwoRefinerChange.next(restUpdate);
  }

  restUpdate(rest) {
    const { tp = '', has = '', q = '' } = this.activeRoute.snapshot.queryParams;
    /* tslint:disable */
    if (rest['tp']) {
      const restUpdate = { ...rest, has, q };
      return restUpdate;
    }
    const restUpdate = { ...rest, tp, has, q };
    return restUpdate;
  }

  /**
   *
   * @param stateNumb accordion index number
   */
  setState(stateNumb: number) {
    this.state = stateNumb;
    this.refinerscroll();
  }
  refinerscroll() {
    refinerScrollFound();
  }

  activityRefinerChange(event, sortOption, index: number, refiner?) {
    const userAgent = navigator.userAgent;
    sortOption.isSelected = true;
    refiner.filters[index].isSelected = true;
    refiner.filters.forEach((val) => {
      val.isSelected = (val.title === sortOption.title);
    });
    this.thirdPartyService.sortByOptionIsSelected = {
      [this.typeSortOption]:
        refiner.selectedFilter === TIER2_SORT_BY_REFINER_NM.POPULARITY ? TIER2_SORT_BY_REFINER_NM.RANK : refiner.selectedFilter
    };
    if (/Android/i.test(userAgent)) {
      const element = event.srcElement?.classList;
      (element?.contains('ui-radiobutton-label') && element?.contains('ui-label-active')) ? element?.remove('ui-label-active') : element?.add('ui-label-active');
    }
  }

  ngOnDestroy() {
    this.thirdPartyService.openRefiner.next(false);
    this.thirdPartyService.updateRef$.next(null);
    this.refinerSubscription && this.refinerSubscription.unsubscribe();
    this.updateRefinerSubscription && this.updateRefinerSubscription.unsubscribe();
  }
}
