export const TITLE_LIST = {
    SUBJECT: 'subject',
    LIST: 'list',
    ALWAYSAVAILABLE: 'alwaysavailable',
    CATEGORY: 'category',
    FEATURE_LIST: 'featureList',
    ALWAYS_AVAILABLE_TEXT: 'Always Available',
    CLEAR_ALL: 'Clear All',
    LGBTQ: 'LGBTQ+',
    BASED_ON_INTEREST: 'Based_your_Interests'
};
export const BASED_ON_INTEREST = "Based on your Interests";
export const BASED_ON_INTERESTS = "Based On Your Interests";
export const defaultSubjectBasedOnProfileType = {
    'Teen':'YAN000000#YAF000000',
    'Kid':'JNF000000#JUV000000'
}
export const REFINER_LISTS = {
    SORT_BY: 'Sort By',
    AGE_LEVEL: 'Age Level',
    FORMAT: 'Format',
    PUBLICATION_DATE: 'Publication Date',
    POPULARITY: 'Popularity',
    ALL: 'All'
};
export const BASED_ON_INTEREST_ERROR = `The library currently has no content matching your Interest Survey selections. Please expand your selections.`;

export const BROWSE_ORDER_REFINER_LIST = [
    'Sort By',
    'Availability',
    'Format',
    'Age Level',
    'Language',
    'Release Date',
    'Recently Added',
    'Attributes'
];

export const SORT_REFINER = [
    {
        text: "Sort By",
        value: "sortby",
        items: [
            {
                myParent: "sortby",
                text: "Return date",
                value: "Returndate",
                controlType: "normal",
                defaultSelected: false,
                id: "rdbReturnDate",
                isSelected: false
            },
            {
                myParent: "sortby",
                text: "Popularity",
                value: "Score",
                controlType: "normal",
                defaultSelected: false,
                id: "rdbPopularity",
                isSelected: false
            },
            {
                myParent: "sortby",
                text: "Publication Date",
                value: "PublishDate",
                controlType: "normal",
                defaultSelected: false,
                id: "rdbPublicationDate",
                isSelected: false
            },
            {
                myParent: "sortby",
                text: "Added Date",
                value: "AddedDate",
                controlType: "normal",
                defaultSelected: false,
                id: "rdbAddedDate",
                isSelected: false
            },
            {
                myParent: "sortby",
                text: "Title",
                value: "Title",
                controlType: "normal",
                defaultSelected: false,
                id: "rdbTitle",
                isSelected: false
            },
            {
                myParent: "sortby",
                text: "Author",
                value: "Author",
                controlType: "normal",
                defaultSelected: false,
                id: "rdbAuthor",
                isSelected: false
            }
        ],
        selected: "",
        selectedText: "",
        defaultSelected: false
    }
];
