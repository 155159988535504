import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SHIMMER_COMPONENTS, LIST_CARD_COUNT, CAROUSEL_CONST } from '@shared/constants/app.constants';
import { ListData } from '@shared/data/list-base.model';
import { IThirdPartyMystuff } from '@shared/data/third-party-model';
import { focusElement, forceRedirect, getBookIcon, getValueFromCaseinsensitiveAttr, setIvyCarouselProps } from '@shared/helper/app.util';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { Router } from '@angular/router';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-titles-carousel',
  templateUrl: './titles-carousel.component.html',
  styleUrls: ['./titles-carousel.component.scss']
})
export class TitlesCarouselComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() listData: ListData;
  @Input() titlesList;
  @Input() showCarouselTitle = true;
  @Input() showCarouselSubTitle = true;
  @Input() cellsToScrollNums = [5, 4, 3, 2];
  @Input() layoutComponents: IThirdPartyMystuff;
  @Output() titleActionEvt = new EventEmitter();
  @Output() seeAllClickEvent = new EventEmitter();
  @Output() clickFeatureprogram = new EventEmitter();
  @Input() parentRoute: string;
  @Input() isFeaturedProgram: boolean;
  @Input() programId = '';
  @Input() isCurrentlyCheckout = false;
  @Input() featuredList;
  @Input() totalItemCount: number;
  @Input() addCard = false;
  @Input() isParticipant: string;
  @Input() myShelfLoad = false;
  @Input() isProgramCTAShow = false;
  cellWidth = 164;
  cellMargin = 0;
  cellHeight = 252;
  cellsToScroll = 5;
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  LIST_CARD_COUNT = LIST_CARD_COUNT;
  @Input() searchResult: boolean;
  titleDetailLink: string;
  playerURL: string;
  initTitleAriaLabel: string;
  booktitle = CAROUSEL_CONST.booktitle;
  format = CAROUSEL_CONST.format;
  forceRedirect = forceRedirect;
  setAriaLabel = '';
  onResize = setIvyCarouselProps.bind(this, 160, 0, 248, 164, 0, 252, this.cellsToScrollNums);
  constructor(
    public router: Router,
    private sharedAnalyticsService: SharedAnalyticsService,
    private commonService: CommonService,
    public titleDetailsService: TitleDetailsService,
    public cdref: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.onResize = setIvyCarouselProps.bind(this, 160, 0, 248, 164, 0, 252, this.cellsToScrollNums);
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.titlesList?.currentValue) {
      this.initTitleContent();
    }
  }

  getAriaLabel(value: string) {
    this.setAriaLabel = value;
  }
  /**
   * redirect to concern list page
   */
  redirectToListPage(seeAllLink) {
    setTimeout(() => {
      const matchResult = /(Android|iPhone|iPod|iPad)/i.exec(navigator.userAgent);
      matchResult && focusElement('loc_btnHamburgerMenu')
    }, 2000);
    this.sharedAnalyticsService.trackCarouselEvent('CLICK_SEE_ALL', { carouselName: this.listData.listName });
    if (seeAllLink) {
      this.router.navigateByUrl(seeAllLink);
    }
  }

  /**
   * emit the event title action
   */
  titleActionEvent() {
    this.titleActionEvt.emit();
  }

  /**
   * redirect to see all page.
   */
  seeAllClick() {
    this.seeAllClickEvent.emit();
  }

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.sharedAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.sharedAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    this.clickFeatureprogram.emit();
  }

  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType) {
    return getBookIcon(`KZ_${formatType}`);
  }

  initTitleContent() {
    this.titlesList?.forEach(element => {
      if (element) {
        const pageCount = element.bookLength || element.BookLength;
        const pageCountText = (pageCount !== '0') ? `${pageCount} Pages` : '';
        if (element.formatType) {
          this.initTitleAriaLabel = `${this.format} ${this.getIcon(element?.formatType)?.alt}, ${this.booktitle} ${element?.title?.replace(/<[^<>]*?>/g, '')
            }${element?.author ? ', Author' : ''} ${element?.author}, ${pageCountText}`;
        } else {
          this.initTitleAriaLabel = `${this.format} ${element?.iconInfo?.alt}, ${this.booktitle} ${element?.title?.replace(/<[^<>]*?>/g, '')
            } ${element?.author ? ', Author' : ''} ${element?.author}, ${pageCountText}`;
        }
        /*********based on parentRoute the title-details router-url will be change********/
        if (this.isCurrentlyCheckout) {
          this.playerURL = element.formatType === 'EBT' ? 'ReadNowClick' : 'ListenNowClick';
          element.titleDetailLink = decodeURIComponent(`/Action/${this.playerURL}?itemId=${element.itemId}&ISBN=${element.isbn}`);
          return;
        }

        if (this.parentRoute && (this.parentRoute.includes('subject') || this.parentRoute.includes('list') && !this.parentRoute.includes('wishlist')
          || this.parentRoute.includes('detail') || this.parentRoute.includes('alwaysavailable'))) {
          element.titleDetailLink = `/view/title/${this.parentRoute}/${element.itemId}/${element.ISBN || element.isbn
            }`;
          return;
        }
        if (this.isFeaturedProgram && this.programId) {
          element.titleDetailLink = `/view/programs/${this.programId}`;
          return;
        }
        element.titleDetailLink = `/view/${this.parentRoute}/title/${element.itemId || element.ItemId}/${element.ISBN || element.isbn
          }`;
      }
    });
  }
  cardClick(titleContent) {
    this.commonService.breadcrumbs = [];
    this.commonService.titleBreadCrumb = false;
    sessionStorage.removeItem('breadcrumb');
    this.titleDetailsService.itemsTitleDetails = titleContent;
    this.sharedAnalyticsService.trackCarouselEvent('CLICK_TITLE_CARD', {
      titleName: getValueFromCaseinsensitiveAttr(titleContent.Title, titleContent.title, '')
    });
  }
  ngAfterViewInit() {
    this.cdref.detectChanges();
  }
}
