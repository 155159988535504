import { DataApi } from '../data/third-party-model';

export class UrlConstrutor {
  private url = '';
  private pathParams = {};
  private queryParams = {};
  constructor({ url, pathParams, search }: DataApi) {
    this.url = url;
    this.pathParams = pathParams || {};
    this.queryParams = search || {};
  }
  private addPathParams() {
    Object.keys(this.pathParams).forEach((k) => {
      this.url = this.url.replace(`{${k}}`, this.pathParams[k]);
    });
  }
  private addQueryParams() {
    const defaultValues = {
      inVal: 'inVal',
      inVal1: 'inVal1',
      inVal2: 'inVal2'
    }
    Object.keys(this.queryParams).forEach((key, i) => {
      let qKey = key;
      if (qKey.includes('inVal')) {
        qKey = 'in[]';
      }
      if (qKey === 'country') {
        this.queryParams[key] = this.queryParams[defaultValues.inVal];
      } else if (qKey === 'language') {
        this.queryParams[key] = this.queryParams[defaultValues.inVal1];
      }
      this.url += `${i === 0 ? '?' : '&'}${qKey}=${this.queryParams[key]}`;
    });
  }
  formatUrl(): string {
    this.addPathParams();
    this.addQueryParams();
    return this.url;
  }
}
