import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_TABS, MAT_TABS_ID, MAT_TABS_NAME, PATH_TAB_NAME_MAP, TAB_ROUTE_MAP } from '@search/shared/constants/search-results.constants';
import { SEARCH_EVENTS_KEY } from '@search/shared/constants/search.analytics.constants';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { SEARCH_QUERY_PARAM } from '@shared/constants/app.constants';
import { cdkRemoveVisualHidden, cdkVisualHidden, positionFooter } from '@shared/helper/app.util';
import { updateSearchUrl } from '@search/shared/helper/search.util';
import { SearchService } from './shared/services/search.service';

@Component({
  selector: 'axis360-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  searchTerm: string;
  selectedTabId: string;
  /**
   * Creates an instance of search results component.
   * @param activatedRoute Activated route
   * @param router Angular router
   */
  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private searchAnalyticsService: SearchAnalyticsService,
    private searchService: SearchService
  ) {
    /* This will be called on searching terms in header for sites/news tabs */
    (window as any).updateSearchUrl = updateSearchUrl;
  }
  /**
   * Redirect to search page
   */
  redirectToSearch = () => {
    window.location.href = `/Search?${SEARCH_QUERY_PARAM}=${encodeURIComponent(this.searchTerm)}`;
  };
  /**
   * To redirect to search if no routes matched
   * If route is matched, mark matched tab as selected
   */
  ngOnInit(): void {
    this.searchTerm = this.activatedRoute.snapshot.queryParams[SEARCH_QUERY_PARAM];
    if (this.activatedRoute.children.length === 0) {
      this.redirectToSearch();
      return;
    }
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
    const { path } = this.activatedRoute.snapshot.firstChild.routeConfig;
    const tabName = PATH_TAB_NAME_MAP[path.toUpperCase()];
    this.selectedTabId = MAT_TABS_ID[tabName];
    this.searchService.selectedSearchTab = MAT_TABS.indexOf(tabName);
  }
  /**
   * To navigate to the route based on the selectedTab on the selected tab change action
   * @param selectedTab Selected tab name
   */
  onSelectedTabChange = (selectedTab: string) => {
    const eventMap: { [key: string]: keyof typeof SEARCH_EVENTS_KEY } = {
      [MAT_TABS_NAME.BOOKS]: 'BOOKS',
      [MAT_TABS_NAME.SITES]: 'SITES',
      [MAT_TABS_NAME.NEWS]: 'NEWS'
    };
    this.searchAnalyticsService.trackSearchEvent(eventMap[selectedTab]);
    if (selectedTab === MAT_TABS_NAME.BOOKS) {
      this.redirectToSearch();
      return;
    }
    this.selectedTabId = MAT_TABS_ID[selectedTab];
    positionFooter(true);
    this.router.navigate([TAB_ROUTE_MAP[selectedTab]], { queryParams: { [SEARCH_QUERY_PARAM]: this.searchTerm } });
  };
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
}
