import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ACTIVITY_REFINER } from '@shared/constants/search.constant';
import { ILearningActivity } from '@shared/data/learning-activity.model';
import { forceRedirect } from '@shared/helper/app.util';
import { AccountService } from '@shared/services/account.service';
import { CommonService } from '@shared/services/common.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { AccountComponent } from 'app/core/account/account.component';
import { KzLearningActivitiesAnalyticsService } from 'app/kz-learning-activities/kz-learning-activities-analytics.service';

@Component({
  selector: 'axis360-kz-activity-resources-card',
  templateUrl: './kz-activity-resources-card.component.html',
  styleUrls: ['./kz-activity-resources-card.component.scss']
})
export class KzActivityResourcesCardComponent implements OnInit {
  @Input() activity: ILearningActivity;
  @Input() parentRoute = 'learning-activity';
  isDownloading = false;
  isPlaying = false;
  isDownloadAnnouncer = false;
  tabindexValue: string;
  @Output() selectedRefiners = new EventEmitter();
  public loginOpened = false;

  constructor(
    public router: Router,
    public userService: UserService,
    public learningActivityAnalyticsService: KzLearningActivitiesAnalyticsService,
    public spinnerService: SpinnerService,
    public accountService: AccountService,
    public matDialog: MatDialog,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    if ((!this.activity.linkedTitleID && !this.activity.thirdpartyVendorCode) || this.activity.thirdpartyVendorCode?.includes('Videos') || this.activity.thirdpartyVendorCode?.includes('VBooks')) {
      this.activity.disabled = true;
      this.tabindexValue = "-1"
    } else {
      this.activity.disabled = false;
      this.tabindexValue = "0"
    }
  }
  showLinkedTitle() {
    if (!this.activity.disabled) {
      this.learningActivityAnalyticsService.trackLearningActivityEvent('LINKED_TITLE_CTA');
      this.router.navigateByUrl(`/view/${this.parentRoute}/title/${this.activity.linkedTitleID}/${this.activity.linkedTitleISBN}`);
    }
  }
  
  downloadAnnouncer(){
    this.isDownloadAnnouncer = true;
    setTimeout(() => {
      this.isDownloadAnnouncer = false;
    }, 2000);
  }

  download(url: string) {
    this.learningActivityAnalyticsService.trackLearningActivityEvent('DOWNLOAD_CTA');
    if (!this.userService.userInfo.isLoggedIn) {
      this.openLoginModel(url);
      return;
    }
    this.downloadAnnouncer();
    setTimeout(() => {
      window.open(url, '_blank');    
    }, 2000);
  }
  openLoginModel(url: string) {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        if (this.loginOpened) {
          return;
        }
        this.loginOpened = !this.loginOpened;
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true
            }
          })
          .afterClosed()
          .subscribe((res: boolean) => {
            this.loginOpened = false;
            if (res) {
              this.download(url);
            }
          });
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }
  selectedResurceType(resourceType: string) {
    if (!this.activity.disabled) {
    this.selectedRefiners.emit({ key: ACTIVITY_REFINER[1], value: resourceType, checked: true });
    }
  }
}
