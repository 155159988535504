import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAT_TABS_NAME, NEWS_NO_RESULTS, SITES_NO_RESULTS } from '@search/shared/constants/search-results.constants';
import { TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { DEFAULT_SEARCH_FAILS_MSG } from '@shared/constants/app.constants';
import { SpinnerService } from '@shared/services/spinner.service';

@Component({
  selector: 'axis360-search-results-cards',
  templateUrl: './search-results-cards.component.html',
  styleUrls: ['./search-results-cards.component.scss']
})
export class SearchResultsCardsComponent {
  @Input() titleCardsInfo: TitleCardInfo[];
  @Input() view: string;
  @Input() searchTerm: string;
  @Input() initialFailed = false;
  @Output() titleCardClickEvent = new EventEmitter<{ event: Event; url: string }>();
  DEFAULT_SEARCH_FAILS_MSG = DEFAULT_SEARCH_FAILS_MSG;
  constructor(public spinnerService: SpinnerService) {}
  /**
   * Gets no results text
   * @param view Input params as view
   * @returns Returns no results text
   */
  getNoResults(view: string) {
    const noResultMap = {
      [MAT_TABS_NAME.SITES]: SITES_NO_RESULTS.split('<searchTerm>').join(this.searchTerm),
      [MAT_TABS_NAME.NEWS]: NEWS_NO_RESULTS.split('<searchTerm>').join(this.searchTerm)
    };
    return noResultMap[view];
  }

  /**
   * To handle event on title card click
   * @param input event and url value of the emitter event
   */
  titleCardClick(input) {
    this.titleCardClickEvent.emit(input);
  }
}
