import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MAT_TABS_NAME, REFINER_TYPE } from '@search/shared/constants/search-results.constants';
import { AppliedFilter, Facet, Filter, PublicationsSort, Sort } from '@search/shared/data/filter-model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'axis360-title-and-refiners',
  templateUrl: './title-and-refiners.component.html',
  styleUrls: ['./title-and-refiners.component.scss']
})
export class TitleAndRefinersComponent {
  @Input() titleText: string;
  @Input() searchTerm: string;
  @Input() resultsCount = 0;
  @Input() showSort = false;
  @Input() showFilter = true;
  @Input() availableLimiters: Facet[];
  @Input() sortOptions: Sort[] | PublicationsSort[];
  @Input() hideText = false;
  @Input() hideRefiners = false;
  @Input() initialFailed = false;
  @Input() showCount = false;
  @Input() hideNewsStands = false;
  @Output() applySortEvnt = new EventEmitter<Sort | PublicationsSort>();
  @Output() applyFilterEvnt = new EventEmitter<{ appliedFilter: AppliedFilter; filters: Filter[] }>();
  @Output() clearFilterEvnt = new EventEmitter<{ limiter: string; filters: Filter[] }>();
  @Output() openRefinerDialogEvent = new EventEmitter<string>();
  isDialogOpened = false;
  MAT_TABS_NAME = MAT_TABS_NAME;
  REFINER_TYPE = REFINER_TYPE;
  isFilterOpen = false;
  isSortOpen = false;
  constructor(private liveAnnouncer: LiveAnnouncer, private overlayContainer: OverlayContainer) {}
  /**
   * Emit the sort event
   * @param sortOption input parama as apply sort options
   */
  applySortEvent(sortOption: Sort | PublicationsSort) {
    this.applySortEvnt.emit(sortOption);
  }
  /**
   * Emit the filter event
   * @param applyFilter input parama as apply filter options
   */
  applyFilterEvent(applyFilter: { appliedFilter: AppliedFilter; filters: Filter[] }) {
    this.applyFilterEvnt.emit(applyFilter);
  }
  /**
   * Emit the clear event
   * @param clearLimiter input parama as clear filter options
   */
  clearFilterEvent(clearLimiter: { limiter: string; filters: Filter[] }) {
    this.clearFilterEvnt.emit(clearLimiter);
  }
  /**
   * Opens sort or filter dialog
   * @param refinerType input param as refnier type
   */
  openRefinersPopup(refinerType: string) {
    this.openRefinerDialogEvent.emit(refinerType);
    if (refinerType === REFINER_TYPE.FILTER) {
      this.liveAnnouncer.announce('Filter expanded', 'assertive').then(() => {
        this.isFilterOpen = !this.isFilterOpen;
      });
      return;
    }
    this.liveAnnouncer.announce('Sort expanded', 'assertive').then(() => {
      this.isSortOpen = !this.isSortOpen;
    });
  }
  /**
   * Close refiner on escape key pressed (keycode : 27 for Escape Key)
   * @param event input param as event
   * @param refinerType input param as refnier type
   * @returns to check refiner type to block
   */
  closeRefinersPopup(event, refinerType: string) {
    if (event.keyCode !== 27) {
      return;
    }
    if (refinerType === REFINER_TYPE.FILTER) {
      this.isFilterOpen = false;
      return;
    }
    this.isSortOpen = false;
  }

  overlayDetached() {
    this.overlayContainer.getContainerElement().classList.remove('refiner-overlay-container');
  }
}
