<div class="kz-reading-programs-sec">
    <div class="kz-no-program-sec" *ngIf="programsLen === 0">
        <div *ngIf="tabName === PROGRAMS_TABS.OPEN_PROGRAMS">
            <h1 class="kz-no-pro-heading">Your library has no active programs at this time</h1>
            <p class="kz-no-pro-desc">Please check back later</p>
        </div>
        <div *ngIf="tabName === PROGRAMS_TABS.MY_PROGRAMS">
            <h2 class="kz-no-pro-heading">Find Programs to Join in Open Programs</h2>
            <p class="kz-no-pro-desc">When you join, your programs display here</p>
        </div>
        <img aria-hidden="true" alt="No reading programs yet" src="/angular-app/assets/images/reading-program.svg" />
    </div>
    <p id="loc_btnOpenProgram" class="kz-info" *ngIf="programsLen > 0 && tabName === PROGRAMS_TABS.OPEN_PROGRAMS">Here are the Open Programs that you can join. When you join a Program, it will be moved to your My Programs tab.</p>
    <div class="kz-cards-sec" *ngFor="let programsObj of programs | keyvalue; let lastIndex = last">
        <h1 class="hidden">My Programs</h1>
        <h2 class="kz-cards-heading"
            attr.aria-label="{{programsObj.key==='active' ? 'My '+programsObj.key : programsObj.key}} Programs"
            id="loc_{{programsObj.key}}Programs">{{programsObj.key==='active' ? 'My '+programsObj.key :
            programsObj.key}} Programs</h2>
        <div class="kz-card-list" role="list">
            <div class="kz-card-list-item" *ngFor="let program of programsObj.value" role="listitem">
                <axis360-kz-program-card class="kz-card-item" [cardRole]="CARD_ROLE_MAP[tabName]"
                    (programCardClickEvent)="onProgramCardClick($event)" [program]="program"
                    (deleteClickEvent)="onDeleteClickEvent(program)" [tabName]="programsObj.key">
                </axis360-kz-program-card>
            </div>
        </div>
    </div>
</div>
<axis360-spinner-loader></axis360-spinner-loader>