<div class="kz-reading-programs-details">
    <p class="kz-visually-hidden" role="alert" aria-live="polite" *ngIf="commonService.readingProgramList" id="readingProgramListPage">Program Reading list page</p>
    <div class="kz-breadcrumb-sec">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" (clickBreadcrumb)="onBreadCrumbClicked($event)"
            *ngIf="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'" class="kz-breadcrumb">
        </axis360-breadcrumb>
    </div>
    <div class="kz-reading-programs-list" *ngIf="filteredBooks.length > 0">
        <div class="kz-carousel-heading-sec">
            <h1 class="kz-carousel-heading" id="loc_txtProgramViewAllTitle"><span class="kz-visually-hidden">Titles {{programDetails.type === 'Milestone' ?
                filteredBooks?.length-1 : filteredBooks?.length}}</span><span aria-hidden="true">Titles </span><span aria-hidden="true" class="title-count">({{programDetails.type
                    === 'Milestone' ?
                    filteredBooks?.length-1 : filteredBooks?.length}})</span></h1>
            <ol class="kz-program-filter-sec-menu" *ngIf="programDetails.type === 'Milestone' ">
                <li>
                    <button class="custom-menu-trigger btn-nobg-bluecolor" id="loc_Sort" mat-icon-button
                        matTooltip="Sort" matTooltipClass="kz-card-tooltip"
                        (mouseover)="removeTooltipAnnouce()" (keyup.Tab)="removeTooltipAnnouce()"
                        axis360MatMenuFocus [matMenuTriggerFor]="menuOne" (menuOpened)="menuState(true)" (menuClosed)="menuState(false)"
                            aria-label="Sort">
                        <mat-icon svgIcon="kz-strawberry" aria-hidden="true"></mat-icon>
                    </button>
                    <mat-menu #menuOne="matMenu" class="kz-action-menu-item dropdown-filter-menu sort">
                        <ng-container *ngFor="let sortOption of sortOptions">
                            <button id="loc_sort" mat-menu-item class="anchor-link" id="loc_Sort_{{sortOption.type}}" [ngClass]="{'active': sortOption.code === isSortActive}"
                                (click)="sortChange(sortOption)"
                                [attr.aria-label]="(sortOption.code === isSortActive) ? ('Selected ' + sortOption.type) : (sortOption.type)"
                                >{{sortOption.type}}</button>
                        </ng-container>
                    </mat-menu>
                </li>
                <li>
                    <button class="custom-menu-trigger btn-nobg-bluecolor" id="loc_Filter" mat-icon-button
                        matTooltip="Filter" matTooltipClass="kz-card-tooltip"
                        (mouseover)="removeTooltipAnnouce()" (keyup.Tab)="removeTooltipAnnouce()"
                        [ngClass]="{'active': filterOption.code != 'ALL'}" axis360MatMenuFocus [matMenuTriggerFor]="menuTwo" (menuOpened)="menuState(true)" (menuClosed)="menuState(false)"
                        [attr.aria-label]="filterOption.code != 'ALL' ? 'Menu button submenu filter with one item selected' : 'filter'">
                        <mat-icon svgIcon="kz-filtter-icon" aria-hidden="true"></mat-icon>
                    </button>
                    <mat-menu #menuTwo="matMenu" class="kz-action-menu-item dropdown-filter-menu filter">
                        <ng-container *ngFor="let filter of filters">
                            <button id="loc_btnSelected" mat-menu-item class="anchor-link" id="loc_Filter_{{filter.type}}"
                                [ngClass]="{'active': filter.code === filterOption.code}" (click)="filterChange(filter)"
                                [attr.aria-label]="(filter.code === filterOption.code) ? ('Selected ' + filter.type) : (filter.type)">{{filter.type}}</button>
                        </ng-container>
                    </mat-menu>
                </li>
                <li>
                    <button [attr.aria-label]="editTitleLable" aria-live="assertive" class="custom-menu-trigger btn-nobg-bluecolor"
                        matTooltip="Edit Program" matTooltipClass="kz-card-tooltip"
                        (mouseover)="removeTooltipAnnouce()" (keyup.Tab)="removeTooltipAnnouce()"
                        id="loc_editPenicon" (click)="editTitle()" (window:keypress.enter)="editTitle()" [disabled]="programDetails.status=== 'Completed' || programDetails.dbStatus=== 'Complete' || filteredBooks.length === 1">
                        <mat-icon svgIcon="kz-edit-pencil" aria-hidden="true"></mat-icon>
                        <ng-container *ngIf="removeEditProgramNotification">
                            <span class="kz-visually-hidden" *ngIf="!isDeleteFlag">Edit program button disable, and checkboxes disappear</span>
                            <span class="kz-visually-hidden" *ngIf="isDeleteFlag">Edit program button enable, and checkboxes appear</span>
                        </ng-container>
                    </button>
                </li>
            </ol>
        </div>
        <div class="card-container" *ngIf="filteredBooks.length > 1">
            <div class="card-item" *ngFor="let bookDetail of filteredBooks; index as i">
                <axis360-kz-programs-title [bookDetail]="bookDetail" [programDetails]="programDetails" [seeAll]="true"
                    [isProgramDetail]="true" [isBooksInOrder]="programDetails.isOrder" [isDeleteFlag]="isDeleteFlag"
                    (titleMarkAsReadEvent)="markAsReadClick()">
                </axis360-kz-programs-title>
            </div>
        </div>
        <ng-container *ngIf="filteredBooks.length === 1">
            <div class="no-stuffs no-programs">No Results found</div>
        </ng-container>
        <div class="program-deleteBtn d-flex done_Submit"
            *ngIf="showSaveCancelBtn && programDetails.type === 'Milestone' ">
            <button class="btn-secondary-nobg cancelBtn mr-2" (click)="cancel()" id="loc_Cancel"
                (keyup.enter)="cancel()">Cancel</button>
            <button class="btn-secondary-fill saveBtn" id="loc_Remove" [disabled]="!EnableProgramList.length"
                (click)="removeTitleDialog();$event.stopPropagation();" (window:keypress.enter)="removeTitleDialog();$event.stopPropagation();"
                [ngClass]="{active:EnableProgramList.length}">Remove</button>
        </div>
    </div>
</div>