import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SHIMMER_COMPONENTS, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { BookDetail } from '@shared/data/engage-base.model';
import { getBookIcon, strToBool } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { TITLE_DETAILS_EVENTS_KEY } from '@titledetails/shared/constants/title-details.analytics.constants';
import { CHECKOUT_LIMIT, HOLD_POSITION_TOOLTIP } from '@titledetails/shared/data/mock/title-details.mock';
import { Subscription } from 'rxjs';
import { WITH_OUT_INTERNET_MSG_RATING } from '../shared/constants/title-details.constants';
import { IShareActionButton, ITitleActionsContent, ITitleDetailsActions, ITitleDetailsRating } from '../shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from '../shared/services/title-details.analytics.service';
import { TitleDetailsService } from '../shared/services/title-details.service';

@Component({
  selector: 'axis360-title-details-actions',
  templateUrl: './title-details-actions.component.html',
  styleUrls: ['./title-details-actions.component.scss']
})
export class TitleDetailsActionsComponent implements OnInit, OnChanges {
  @Input() titleDetailsActions: ITitleDetailsActions;
  @Input() titleDetailsRating: ITitleDetailsRating;
  userName: string;
  narratorSuffix = ' (NAR)';
  titleContent: BookDetail;
  titleActionContent: ITitleActionsContent;
  shareActionsButton: IShareActionButton;
  isPPCOnlyLibrary: boolean;
  readingProgress: number = 0;
  showInsightsAndBadges: boolean = false;
  updateCTATitleButton: any;
  shareCTA: boolean = false;
  HOLD_POSITION_TOOLTIP = HOLD_POSITION_TOOLTIP;
  upDateButtonViewSubscriptions: Subscription;
  CHECKOUT_LIMIT = CHECKOUT_LIMIT;
  isCheckOutOrNot: boolean;
  compName = SHIMMER_COMPONENTS.TITLE_DETAILS_RATINGS;
  @Output() progressBarEvent = new EventEmitter<boolean>();
  @Output() moveToReviewCTAEvent = new EventEmitter();
  @Output() firstTitleReviewEvent = new EventEmitter()
  constructor(
    private titleDetailsService: TitleDetailsService,
    private userService: UserService,
    public snackBarService: SnackBarService,
    public profileInfoService: ProfileInfoService,
    public enagageService: EngageBaseService,
    public config: ConfigService,
    public spinnerService: SpinnerService,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService
  ) { }
  /**
   * To init TitleDetailsActionsComponent
   */
  ngOnInit(): void {
    this.getTitleActionsDetails();
    this.titleDetailsService.getTitleCheckOutOrNot().subscribe((action) => {
     this.isCheckOutOrNot = !action;
    });
  }
  /**
   * To Change the getTitleActionDetails
   * @param [changes]
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.titleDetailsActions?.currentValue !== changes.titleDetailsActions?.previousValue || changes.titleDetailsRating?.currentValue !== changes.titleDetailsRating?.previousValue) {
      this.getTitleActionsDetails();
    }
  }
  /**
   * Creates a new title button.
   *
   */
  getTitleActionsDetails() {
    this.userName = this.userService.getUserName();
    const titleItemDetails = this.titleDetailsService.itemsTitleDetails;
    if (!this.titleDetailsActions && titleItemDetails) {
      this.titleActionContent = {
        showProgressBar: '',
        userName: this.userName,
        imageurl: titleItemDetails.ImageUrl ? titleItemDetails.ImageUrl : this.config.appSettings?.titleImageSrcFormat?.replace('{0}', titleItemDetails.ISBN || titleItemDetails.isbn),
        itemId: titleItemDetails.ItemId || titleItemDetails.itemId,
        enableTracking: strToBool(this.profileInfoService.currentProfile.enableTracking),
        title: titleItemDetails.Title || titleItemDetails.title
      };
      return;
    }
    if (this.titleDetailsActions) {
      const { titleDetails } = this.titleDetailsActions;
      const { id, itemId, title } = titleDetails || {};
      this.titleActionContent = {
        userName: this.userName,
        imageurl: this.config.appSettings?.titleImageSrcFormat?.replace('{0}', id),
        itemId,
        enableTracking: strToBool(this.profileInfoService.currentProfile.enableTracking),
        title
      };
      this.titleDetailsService.setRatingsSubject(this.titleDetailsRating);
      if (this.userService.isLoggedIn()) {
        this.getReadingProgressBasedOnItemId([itemId]);
      }
    }
  }

  async getReadingProgressBasedOnItemId(bookIds: string[]) {
    const bookDetail = { bookIdList: bookIds };
    const booksDetailsRes = await this.enagageService.getBookProgress(bookDetail);
    if (booksDetailsRes.responseCode === 200) {
      this.readingProgress = Math.round(booksDetailsRes.bookProgress[0].progressPercentage);
      const profileFeatures = this.config.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase()) || {};
      this.showInsightsAndBadges = profileFeatures.insightsBadgesEnabled;
      this.isProgressBarShown();
    }
  }
  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType: string) {
    return getBookIcon(`KZ_${formatType}`);
  }

  /**
   *  Track by Title details Actions in href values
   * @param action  Selected action to pass
   * @param value   set the value in pass the action
   */
  trackByTitleDetailsActions(action: keyof typeof TITLE_DETAILS_EVENTS_KEY, value: string) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action, { actionName: value });
  }

  /**
   * Save the Title rating in submitReview API
   */
  async handleRating(rating: string) {
    const { titleDetails } = this.titleDetailsActions;
    if (rating.length === 0) {
      return;
    }
    await this.spinnerService
      .withPromise(this.titleDetailsService.submitRating(titleDetails.itemId, '', new Date().toISOString(), this.userName, rating))
      .then(
        (res) => {
          if (!res.ratingReviewResponse.ratingSubmitted) {
            this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, res.message);
            return;
          }
          const { profileType } = this.profileInfoService.currentProfile || {};
          const { totalRatingCount, otherUserReview, ratingSubmitted, averageRating } = res.ratingReviewResponse;
          this.titleDetailsAnalyticsService.trackRatingEvent('RATING');
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, `Thanks for adding your rating!.`);
          this.titleDetailsService.setUserRatingTitle({
            avgStarRating: Number(averageRating.toFixed(1)),
            reviewLength: totalRatingCount,
            userAvgStartRating: Number(rating),
            reviews: otherUserReview,
            otherUserReviewCount: otherUserReview.length || 0,
            submitted: ratingSubmitted,
            profileType: profileType
          });
        },
        () => {
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, WITH_OUT_INTERNET_MSG_RATING);
        }
      );
  }
  isProgressBarShown() {
    this.progressBarEvent.emit(this.titleActionContent.enableTracking && this.readingProgress > 0 && this.showInsightsAndBadges)
  }

  scrollTitleReview(count) {
    if (count === 0) {
      this.firstTitleReviewEvent.emit(count);
      return;
    }
    this.moveToReviewCTAEvent.emit(count);
  }

}
