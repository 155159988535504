export const PROFILES_API_INFO = {
  getProfiles: '/profiles/list',
  getAvatars: '/profiles/avatars',
  createProfile: '/profiles/create',
  updateProfile: '/profiles/<profileId>',
  updateProfileFeatures: '/profiles/<profileId>/features',
  updateProfileDetails: '/profiles/<profileId>/details',
  deactivateProfile: '/profiles/<profileId>/deactivate?isPrimary=<isPrimary>',
  validateParentalPin: '/profiles/pin/validate?profileId=<profileId>',
  generateOTP: '/profiles/GenerateOTP/<profileId>',
  validateOTP: '/profiles/ValidateOTP/<profileId>/<otp>',
  getProfileTypes: '/profiles/types',
  setParentalPin: '/profiles/pin/create?profileId=<profileId>',
  getProfileById: '/profiles/<profileId>',
  getPatronInfo: '/profiles/patrondetails',
  pinStatus: '/profiles/pinstatus/<profileId>',
  selectProfile: '/profiles/select',
  getSecurityQuestion: '/account/securityquestion',
  securityAnswer: '/account/securityanswer',
  deleteAccount: '/profiles/patronaccountcancel',
  logOff: '/Account/LogOff',
  getProfileComponent: '/Home/GetProfileComponent'
};
export const PROFILE = 'profile';
