import { Component, OnInit } from '@angular/core';
import { forceRedirect } from '@shared/helper/app.util';
import { AccountService } from '@shared/services/account.service';
import { CommonService } from '@shared/services/common.service';
import { MainService } from '@shared/services/main.service';
import { SpinnerService } from '@shared/services/spinner.service';

@Component({
  selector: 'axis360-patron-must-login',
  templateUrl: './patron-must-login.component.html',
  styleUrls: ['./patron-must-login.component.scss']
})
export class PatronMustLoginComponent implements OnInit {
  isLoading = true;
  isUserAndSettingCalled = false;

  constructor(
    public spinnerService: SpinnerService,
    public accountService: AccountService,
    public commonService: CommonService,
    public mainService: MainService,
  ) { }

  ngOnInit(): void {
    this.patronLogin();
  }

  async patronLogin() {
    this.spinnerService.showLoader();
    await this.mainService.getUserAndSettings();
    this.spinnerService.showLoader(false);
    this.isLoading = false;
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        this.isUserAndSettingCalled = true;
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }

}
