import { Component, Input } from '@angular/core';
import { ProgramCardImg } from '@readingprograms/shared/data/programs.model';

@Component({
  selector: 'axis360-programs-card-images',
  templateUrl: './programs-card-images.component.html',
  styleUrls: ['./programs-card-images.component.scss']
})
export class ProgramsCardImagesComponent {
  @Input() cardImgs: ProgramCardImg[];
}
