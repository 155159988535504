<div class="reviewscontent-wrapper" *ngFor="let review of reviewContent;let i = index;" role="listitem">
    <div class="reviewbox" (window:resize)="onResize()">
        <ng-container *ngIf="!review.more">
            <div class="review-desc">
                <p class="kz-visually-hidden">Review {{i + 1}}, {{review.reSizeReviewContent}} </p>
                <span aria-hidden="true" [innerHTML]="review.reSizeReviewContent"></span>
                <a class="a-review-desc" href="javascript:void(0);" id="review-more"
                    *ngIf="review?.reviewContent?.length > 192"
                    (click)="review.more = !review.more;trackByTitleDetailsActions('ACTIONS_SEE_MORE');setFocusToText('review-less')"
                    (keydown.enter)="review.more = !review.more;trackByTitleDetailsActions('ACTIONS_SEE_MORE');setFocusToText('review-less')">See
                    more</a>
            </div>
        </ng-container>
        <ng-container *ngIf="review.more">
            <div class="review-desc"> 
                <p class="kz-visually-hidden">Review {{i + 1}}, {{review.reviewContent}} </p>
                <span aria-hidden="true" [innerHTML]="review.reviewContent"></span>
                <a class="a-review-desc" href="javascript:void(0);" id="review-less"
                    (click)="review.more = !review.more;trackByTitleDetailsActions('ACTIONS_SEE_LESS');setFocusToText('review-more')"
                    (keydown.enter)="review.more = !review.more;trackByTitleDetailsActions('ACTIONS_SEE_LESS');setFocusToText('review-more')">See
                    Less</a>
            </div>
        </ng-container>
        <p class="reviewer-name review-desc">
        <span class="kz-visually-hidden" > Review given by  {{review.userName}} </span>
           <span aria-hidden="true" > - {{review.userName}}</span>
        </p>
    </div>
</div>