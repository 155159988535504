import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfilesService } from './shared/services/profiles.service';

@Component({
  selector: 'axis360-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  constructor(private profilesService: ProfilesService) { }

  /**
   * Component init method
   */
  ngOnInit(): void {
    this.profilesService.getProfileTypes();
    document.getElementsByClassName('container-fluid body-content')[0]?.classList.add('profile');
  }
  /**
   * remove added class profile, when we leave from this component
   */
  ngOnDestroy(): void {
    document.getElementsByClassName('container-fluid body-content')[0]?.classList.remove('profile');
  }
}
