import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService {
  mediaQueryListener: MediaQueryList;
  /**
   *
   * @param mediaQuery - browser min width
   * @returns it will return media query list
   */
  getMediaQueryListener(mediaQuery = '(min-width: 769px)'): MediaQueryList {
    return this.mediaQueryListener = window.matchMedia(mediaQuery);
  }
}
