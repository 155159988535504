<div class="kz-main kz-profile-creation" (window:resize)="onResize()"
    [ngStyle]="backgroundImageUrl ? {'background-image': 'url(' + backgroundImageUrl + ')'} : ''">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{pageAnnounce}}</p>
    <button class="btn-nobg-bluecolor d-flex back-btn" id="loc_btnBackCTA" (click)="back()"> <mat-icon
            aria-hidden="true" class="back-arrow" svgIcon="kz-back-arrow" aria-hidden="true"></mat-icon>back</button>
    <div class="kz-title">
        <h1 class="kz-heading" id="loc_txtAddaProfile">Add a Profile</h1>
        <p class="kz-sub-text" id="loc_txtDescription">What Age group would you like to add?</p>
    </div>
    <div class="kz-profile-choose">
        <div class="kz-select-profile ">
            <a class="kz-add-prof adult" aria-label="Add a General from age 18+" [routerLink]="['./adult']"
                id="loc_btnAddanAdult" *ngIf="configService?.currentLibrary?.axis360Enabled"
                (click)="setTitle('Adult')">
                <span aria-hidden="true">Add a General</span>
                <span class="adult" aria-hidden="true">18+</span>
            </a>
            <a class="kz-add-prof teen" aria-label="Add a teen from age 12 to 18" [routerLink]="['./teen']"
                id="loc_btnAddaTeen" (click)="setTitle('Teen')">
                <span aria-hidden="true">Add a Teen</span>
                <span class="teen" aria-hidden="true">12-18</span>
            </a>
            <a class="kz-add-prof kid" aria-label="Add a kid from age 0 to 11" [routerLink]="['./kid']"
                id="loc_btnAddaKid" (click)="setTitle('Kid')">
                <span aria-hidden="true">Add a Kid</span>
                <span class="kid-age" aria-hidden="true">0-11</span>
            </a>
        </div>
    </div>
</div>