import { PROGRAM_TYPE_DISPLAY, PROGRAM_TYPE, PROGRAMS_STATUS } from '@readingprograms/shared/constants/programs.constants';
import { Program, ProgramCard, ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { getDate } from '@shared/helper/app.util';

export const getTypeInfo = (programType, numberOfBooks, books = [], participants = []): string => {
  let programTypeText = PROGRAM_TYPE_DISPLAY[programType];
  if (programType === PROGRAM_TYPE.XOfYOfBooks) {
    programTypeText = programTypeText.replace('X', `${numberOfBooks}`).replace('Y', `${books.length}`);
  }
  if (programType === PROGRAM_TYPE.MileStone && participants.length > 0) {
    let completedBook = getCompletedBooks(programType, participants)
    programTypeText = completedBook + ' of ' + numberOfBooks + ' Items'
  }
  if (programType === PROGRAM_TYPE.MileStone && participants.length === 0) {
    programTypeText = '0' + ' of ' + numberOfBooks + ' Items'
  }
  return programTypeText;
};

export const getStatus = (program: Program | ProgramCard): string => {
  return program.status;
};
export const getCompletedBooks = (programType, participants): number => {
  let completedBook = 0
  if (programType === PROGRAM_TYPE.MileStone && participants.length > 0) {
    let mileStoneBook = participants[0].milestoneBookList ? participants[0].milestoneBookList : [];
    completedBook = mileStoneBook.filter((book) => book.percentageComplete === 100).length
  }
  return completedBook;
}
export const handleProgramDetails = (program: Program | ProgramCard): ProgramDetails => {
  const { programId: id, programName: name, description, startDate, readByDate, status, numberOfBooks, books, programType, isParticipant, dbStatus, participants,
    suggestedBooks, goal, totalParticipants } = program;
  return {
    id,
    name,
    description,
    books,
    startDate: getDate(startDate),
    endDate: getDate(readByDate),
    status,
    numberOfBooks,
    type: programType,
    isOrder: programType === PROGRAM_TYPE.OrderOfBooks,
    typeText: getTypeInfo(program.programType, program.numberOfBooks, program.books, program.participants),
    statusText: getStatus(program),
    isClosedProgram: status === PROGRAMS_STATUS.COMPLETE,
    isParticipant,
    dbStatus,
    totalParticipants,
    participants,
    suggestedBooks,
    goal,
    joinDate: isParticipant && participants && getDate(participants[0].joinedDate)
  };
};
