import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  TITLES_TO_RECOMMEND,
  attributes,
  availability,
  collections,
  type,
  FORMAT_TYPE,
  KEYWORD,
  TYPE,
  GENERAL,
  DisableCollectionType,
  ALL,
  ISBN,
  AUTHOR,
  formatType,
  collectionCheck,
  DEFAULT_PATH_PARMS,
  PRESS_READER,
  ProgramCollection,
  COLLECTION_CATEGORY,
} from './advanced-search-constants';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { KzSearchService } from '../../core/kz-search-results/shared/services/kz-search.service';
import { carouselpageSize, page, EMPTY, SEARCH_ERROR_TEXT, pageSize } from '@shared/constants/search.constant';
import { Router } from '@angular/router';
import { HeaderAnalyticsService } from '../shared/services/header.analytics.service';
import { ConfigService } from '@shared/services/config.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, deepClone, focusElement } from '@shared/helper/app.util';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ThirdPartyBaseService } from '@shared/services/third-party-base.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { CurrentLibrary } from '@shared/data/config.model';
import { SearchCategory } from '@shared/data/shared.model';
import { MyLibraryService } from 'app/core/my-library/shared/services/my-library.service';
import { ThirdPartyAnalyticService } from 'app/third-party/shared/services/third-party-analytic.service';
import { UserService } from '@shared/services/user.service';
import { PRESSRDR, PROFILE_TYPES } from 'app/third-party/shared/constants/third-party.constants';
import { of } from 'rxjs';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { Title } from '@angular/platform-browser';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'axis360-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, AfterViewChecked, OnDestroy {
  typeValue = this.searchService.selectedOptions.typeValue;
  collectionValue = this.searchService.selectedOptions.collectionValue;
  type = type;
  collections = collections;
  availability = deepClone(availability);
  format = deepClone(formatType);
  attributes = attributes;
  EBook = collectionCheck.EBook;
  EAudio = collectionCheck.EAudio;
  disableCollection = DisableCollectionType;
  KEYWORD = KEYWORD;
  TYPE = TYPE;
  ALL = ALL;
  AUTHOR = AUTHOR;
  ISBN = ISBN;
  TITLES_TO_RECOMMEND = TITLES_TO_RECOMMEND;
  GENERAL = GENERAL;
  FORMAT_TYPE = FORMAT_TYPE;
  PRESS_READER = PRESS_READER;
  advancedSearchForm: FormGroup;
  searchError: string = SEARCH_ERROR_TEXT;
  currentLibrary: Partial<CurrentLibrary>;
  vendorCode = this.searchService.selectedOptions.vendorCode;
  alwaysAvailableKzEnabled: boolean;
  selectedpathParms = this.searchService.selectedOptions.vendorUrl;
  enabledNewsPaper: any;
  isLoading = true;
  isAttributeCheck = false;
  isAttributeDisabled: boolean;
  typeInput = 'text';
  searchText: string;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  isHiddenFormat = false;
  isKZGuest: any;
  pageTitle = '';
  showResources = true;
  thirdPartyCollection = [];
  currentProfile: string;
  epopupValue: boolean;
  isPPC = false;
  isMobile = false;
  isRestCta = false;
  constructor(
    private searchDialog: MatDialogRef<AdvancedSearchComponent>,
    private formBuilder: FormBuilder,
    public searchService: KzSearchService,
    public router: Router,
    public configService: ConfigService,
    public headerAnalyticsService: HeaderAnalyticsService,
    public profileInfoService: ProfileInfoService,
    public thirdPartyBaseService: ThirdPartyBaseService,
    public spinnerService: SpinnerService,
    public myLibraryService: MyLibraryService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public userService: UserService,
    private titleListService: TitleListService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    public titleService: Title,
    public liveAnnouncer: LiveAnnouncer
  ) {
    this.epopupValue = this.titleListService.configService.currentLibrary.partnerSiteEnabled;
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  ngOnInit(): void {
    this.collections = this.data.pageName !== 'programs' ? collections : ProgramCollection;
    document.querySelectorAll('.mat-dialog-container').forEach((n) => {
      n.removeAttribute('tabindex');
    });
    this.searchText = this.data.searchText ? this.data.searchText : this.searchService.searchText;
    this.availability =
      this.searchService.selectedOptions.availability.length > 0 ? this.searchService.selectedOptions.availability : this.availability;
    this.format = this.searchService.selectedOptions.format.length > 0 ? this.searchService.selectedOptions.format : this.format;
    this.attributes =
      this.searchService.selectedOptions.attributes.length > 0 ? this.searchService.selectedOptions.attributes : this.attributes;
    this.spinnerService.showLoader();
    this.initUpdateEmailForm();
    if (this.data.pageName === 'programs') {
      this.clearForm();
    }
    this.searchService.selectedOptions.axisattribute !== '' && this.advancedSearchForm.get('axisattribute').setValue('RAL');
    this.collections.forEach((x) => {
      x.enabled = this.collectionCheck(x.value);
    });
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    this.isKZGuest = !this.userService.userInfo?.isLoggedIn;
    this.isPPC = this.configService.currentLibrary.isPPCOnlyLibrary;
    if (this.isPPC) {
      this.collections = this.collections.filter((x) => x.text !== 'eBooks');
    }
    this.typeChange(this.typeValue);
    this.getLibraryComponent();
    if (this.searchText.length > 1) {
      this.advancedSearchForm.markAsDirty();
    }
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
  /**
   * Determines whether resize on
   */

  getLibraryComponent() {
    if (this.data.pageName !== 'programs') {
      this.getThirdPartyCollection();
    }
    this.spinnerService.showLoader(false);
    this.isLoading = false;
  }
  async getThirdPartyCollection() {
    const response = this.configService.vendorDetails || [];
    const isZoneType = !this.axis360Enabled && this.kidsZoneEnabled ? PROFILE_TYPES.TEEN : PROFILE_TYPES.ADULT;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(
        this.isKZGuest ? isZoneType : this.profileInfoService.currentProfile.profileType.toLowerCase()
      ) || {};
    const enabledThirdParty = this.userService.userInfo?.isLoggedIn
      ? response.filter((element) => element.vendor_code && element.include_in_search && currentProfileFeatures[element.vendor_code])
      : response.filter(
        (element) =>
          element.vendor_code && element.include_in_search && (this.isKZGuest ? currentProfileFeatures[element.vendor_code] : true)
      );
    enabledThirdParty.forEach((f) => {
      if (f.vendor_code === PRESS_READER && currentProfileFeatures[f.vendor_code]) {
        f.vendor_groups.length > 0
          ? f.vendor_groups.forEach((g) => {
            this.thirdPartyCollection.push({
              text: g.name,
              value: g.name,
              enabled: g.include_search,
              url: '/view/search/' + g.name,
              vendor_code: f.vendor_code,
              vendorGroup: true,
              isThirdPartyVendor: true
            });
          })
          : this.thirdPartyCollection.push({
            text: f.display_name,
            value: f.vendor_name,
            enabled: f.include_in_search,
            url: '/view/search/' + f.display_name,
            vendor_code: f.vendor_code,
            vendorGroup: false,
            isThirdPartyVendor: true
          });
      } else {
        this.thirdPartyCollection.push({
          text: f.display_name,
          value: f.vendor_name,
          enabled: f.include_in_search,
          url: '/view/search/' + f.display_name,
          vendor_code: f.vendor_code,
          vendorGroup: false,
          isThirdPartyVendor: true
        });
      }
      if (f.format_code !== 'vbk' && f.format_code !== 'vid') {
        this.disableCollection = { ...this.disableCollection, [f.vendor_name]: f.vendor_name };
      }
    });
    this.collections.splice(3, 0, ...this.thirdPartyCollection);
    this.spinnerService.showLoader(false);
    this.isLoading = false;
    this.alwaysAvailableKzEnabled = this.configService.appSettings.alwaysAvailableKz;
  }
  collectionCheck(value: string) {
    const currentProfileFeatures =
      this.configService.getProfileFeatures(
        this.isKZGuest ? PROFILE_TYPES.TEEN : this.profileInfoService.currentProfile.profileType.toLowerCase() || PROFILE_TYPES.ADULT
      ) || {};
    const collection = {
      [collectionCheck.General]: true,
      [collectionCheck.EBook]: !this.configService.currentLibrary.isPPCOnlyLibrary,
      [collectionCheck.EAudio]: true,
      [collectionCheck.ResourceHub]:
        !this.configService.currentLibrary.isPPCOnlyLibrary && this.userService.isLoggedIn()
          ? this.configService.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase()).learningActivityEnabled
          : this.configService.getProfileFeatures(PROFILE_TYPES.ADULT).learningActivityEnabled,
      [collectionCheck.Available]:
        this.alwaysAvailableKzEnabled && this.configService.currentLibrary.enablePayPerCircFlag
          ? this.configService.currentLibrary.isSubscribedToPPCCollection || !this.configService.currentLibrary.isPPCOnlyLibrary
          : (!this.configService.currentLibrary.enablePayPerCircFlag && this.configService.currentLibrary.isPPCOnlyLibrary) ||
          this.configService.currentLibrary.isPPCOnlyLibrary,
      [collectionCheck.PurchaseRequest]: this.configService.currentLibrary.recommendations.allowPatronRecommendation,
      [collectionCheck.WebResources]: this.profileInfoService.profileInfo.profileId
        ? this.profileInfoService.profileInfo.webpathExpressEnabled
        : currentProfileFeatures.webpathExpressEnabled
    };
    return collection[value];
  }
  initUpdateEmailForm() {
    this.advancedSearchForm = this.formBuilder.group({
      term: [this.searchText, Validators.required, this.noWhitespaceValidator],
      searchType: ['all', Validators.required],
      collections: ['General', Validators.required],
      availability: [''],
      formatType: [''],
      axisattribute: ['']
    });
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return of(isValid ? null : { whitespace: true });
  }
  closeDialog() {
    this.searchService.addingAlertCount();
    this.headerAnalyticsService.trackHeaderEvent('CLOSE');
    this.initUpdateEmailForm();
    this.format = deepClone(formatType);
    this.availability = deepClone(availability);
    this.searchDialog.close();
  }
  toCheckMobile(): boolean {
    const matchResult = /(Android|iPhone|iPod|iPad|Mac)/i.exec(navigator.userAgent);
    return matchResult !== null;
  }
  search() {
    this.searchService.addingAlertCount();
    sessionStorage.setItem('defaultValues', undefined);
    if (!this.advancedSearchForm.valid) {
      this.advancedSearchForm.markAsDirty();
      const element = document.getElementById('advance-search-form');
      element.classList.add('mat-form-field-invalid');
      setTimeout(() => {
        const savebtn = document.getElementById('loc_btnSearch');
        savebtn && savebtn?.focus();
      }, 1000);
      this.isRestCta = true;
      focusElement('searchText');
      return;
    }
    if (this.toCheckMobile() && this.router.url.includes('view/search')) {
      document.getElementById('loc_btnAdvancesrc').focus();
    } else if (this.toCheckMobile()) {
      document.getElementById('loc_btnHamburgerMenu').focus();
    } else {
      focusElement('loc_headerId');
    }
    this.searchText.length === 0 && (this.isRestCta = true);
    let agelevel = '';
    this.currentProfile = this.profileInfoService.currentProfile.profileType;
    if (this.configService.currentLibrary.axis360Enabled && (this.currentProfile === 'Adult' || this.currentProfile === '')) {
      agelevel = 'All';
    }

    this.availability.forEach((x) => {
      if (x.checked) {
        this.advancedSearchForm.get('availability').setValue(x.value);
        return;
      }
      x.checked = false;
    });

    const data = {
      page,
      pageSize: carouselpageSize,
      searchText: this.advancedSearchForm.controls.term.value.trim(),
      searchby: this.advancedSearchForm.controls.searchType.value,
      recentlyadded: EMPTY,
      format: this.format.find((value) => value.name === this.advancedSearchForm.controls.collections.value)?.value || 'All',
      agelevel,
      availability: this.epopupValue ? 'Available' : this.advancedSearchForm.controls.availability.value,
      collections: this.advancedSearchForm.controls.collections.value,
      releasedate: EMPTY,
      language: EMPTY,
      axisattribute: this.advancedSearchForm.controls.axisattribute.value,
      sortby: 'Relevancy',
      subject: EMPTY,
      vendor_code: this.vendorCode,
      attributes: this.attributes[0].checked,
      pageName: this.data.pageName,
      q: this.advancedSearchForm.controls.term.value.trim(),
      pageTitle: this.advancedSearchForm.controls.collections.value,
      programId: this.data.programId
    };
    this.searchService.format = data.format;
    this.searchService.updatedSearchText.next(data.searchText);
    let updateURLBasedOnCollections = this.collections.find((item) => item.value === data.collections);
    if (!updateURLBasedOnCollections && this.data.pageName === 'programs') {
      data.collections = GENERAL;
      updateURLBasedOnCollections = this.collections.find((item) => item.value === data.collections);
    }
    this.searchService.collections = data.collections === GENERAL ? '' : data.collections;
    this.selectedpathParms = updateURLBasedOnCollections?.url || DEFAULT_PATH_PARMS;
    this.searchService.selectedOptions = {
      typeValue: data.searchby,
      collectionValue: data.collections,
      searchText: data.searchText,
      availability: this.availability,
      format: this.format,
      attributes: this.attributes,
      axisattribute: data.axisattribute,
      vendorCode: this.vendorCode,
      vendorUrl: this.selectedpathParms
    };
    if (COLLECTION_CATEGORY[data.collections]) {
      data.pageSize = pageSize;
    }
    if (data.collections === 'eBook' && this.attributes[0].checked) {
      this.selectedpathParms = '/view/search/eBook';
    }
    this.selectedpathParms =
      this.data.pageName === 'programs' ? this.selectedpathParms.replace('<programId>', this.data.programId) : this.selectedpathParms;
    this.router.navigate([this.selectedpathParms], { queryParams: data });
    this.headerAnalyticsService.trackHeaderEvent('SEARCH');
    this.closeDialog();
  }
  formatChange(value: string, checked: boolean) {
    if (checked) {
      if (value === 'All') {
        return this.changeFormat(true, value);
      }
      const index = this.format.findIndex((x) => x.value === value);
      this.format[index].checked = true;
      this.advancedSearchForm.get('formatType').setValue(value);
      const checkedData = this.format.filter((x) => x.checked);
      if (checkedData.length > 1) {
        this.changeFormat(true, 'All');
      }
    }
    if (!checked) {
      if (value === 'All') {
        return this.changeFormat(false, '');
      }
      const index = this.format.findIndex((x) => x.value === value);
      this.format[0].checked = false;
      this.format[index].checked = false;
      const selectFormat = this.format.findIndex((x) => x.checked);
      if (selectFormat < 1) {
        this.advancedSearchForm.get('formatType').setValue('');
        return;
      }
      this.advancedSearchForm.get('formatType').setValue(this.format[selectFormat].value);
    }
  }
  clearSearch() {
    this.advancedSearchForm.get('term').setValue('');
    if (!this.data.searchEnabled) {
      this.searchService.searchText = '';
    }
  }
  clearForm() {
    this.headerAnalyticsService.trackHeaderEvent('CLEAR');
    this.formatChange('All', false);
    this.collectionValue = 'General';
    this.typeValue = ALL;
    this.typeInput = 'text';
    this.avalibilityChange('', false);
    this.attributes[0].checked = false;
    this.searchText = '';
    this.showResources = true;
    this.advancedSearchForm.markAsPristine();
    this.advancedSearchForm.markAsUntouched();
    this.advancedSearchForm.updateValueAndValidity();
    this.initUpdateEmailForm();
    this.searchService.selectedOptions = {
      typeValue: 'all',
      collectionValue: 'General',
      searchText: '',
      availability: [],
      format: [],
      attributes: [],
      axisattribute: '',
      vendorCode: '',
      vendorUrl: DEFAULT_PATH_PARMS
    };
    this.isRestCta = false;
    cdkRemoveVisualHidden();
    this.liveAnnouncer.announce('Advance search options have been reset', 'assertive');
    const element = document.getElementById('advance-search-form');
    const checkClass = element.classList.contains('mat-form-field-invalid');
    checkClass && element.classList.remove('mat-form-field-invalid');
    setTimeout(() => {
      cdkVisualHidden();
      element.classList.add('mat-form-field-invalid');
    }, 1000);
    const savebtn = document.getElementById('loc_btnSearch');
    savebtn.focus();
  }
  avalibilityChange(value: string, checked: boolean) {
    this.advancedSearchForm.get('availability').setValue('');
    this.availability.forEach((x) => {
      if (x.value === value && checked) {
        this.advancedSearchForm.get('availability').setValue(value);
        const advanceValue = (x.checked = true);
        return advanceValue;
      }
      x.checked = false;
    });
  }
  collectionChange(vendorCode: SearchCategory) {
    this.attributes[0].checked = false;
    this.vendorCode = vendorCode.vendor_code;
    this.pageTitle = vendorCode.text;
    this.selectedpathParms = vendorCode?.url;
    this.format = deepClone(formatType);
    this.advancedSearchForm.controls.formatType.setValue(this.format.find((fmt) => fmt.name === vendorCode.text)?.value);
    this.availability = deepClone(availability);
    this.advancedSearchForm.get('axisattribute').setValue('');
    this.attributeDisable();
    this.thirdPartyAnalyticService.trackCollectionActionsEvent('ACTIONS_COLLECTIONS', { collectionsname: vendorCode.text });
  }
  attributeChange(checked: boolean) {
    this.isAttributeCheck = checked;
    if (checked) {
      return this.advancedSearchForm.get('axisattribute').setValue('RAL');
    }
    this.advancedSearchForm.get('axisattribute').setValue('');
  }
  attributeDisable() {
    this.isAttributeDisabled =
      this.advancedSearchForm.get('collections').value === this.disableCollection[this.advancedSearchForm.get('collections').value] ||
      this.advancedSearchForm.get('collections').value === this.disableCollection.STUDY_SITES ||
      this.advancedSearchForm.get('collections').value === 'Videos' ||
      this.advancedSearchForm.get('collections').value === 'VBooks' ||
      this.vendorCode === PRESSRDR ||
      this.advancedSearchForm.get('collections').value === 'active_Resources' ||
      this.advancedSearchForm.get('collections').value === 'eAudio';
    if (this.isAttributeDisabled) {
      this.isAttributeCheck = false;
      this.attributeChange(this.isAttributeCheck);
    }
    return this.isAttributeDisabled;
  }
  changeFormat(checked: boolean, value: string) {
    this.format.forEach((x) => {
      x.checked = checked;
      this.advancedSearchForm.get('formatType').setValue(value);
    });
  }
  typeChange(typeData: string) {
    if (typeData === 'all') {
      this.showResources = true;
    } else {
      this.showResources = false;
    }
    this.advancedSearchForm.get('term').clearValidators();
    if (typeData !== 'isbn') {
      this.advancedSearchForm.get('term').setValidators([Validators.required]);
      this.typeInput = 'text';
    } else {
      this.typeInput = 'number';
      this.advancedSearchForm.get('term').setValidators([Validators.required, Validators.maxLength(13)]);
    }
    this.advancedSearchForm.get('term').updateValueAndValidity();
  }
  /**
   * Inputs validation
   */
  inputValidation(event: KeyboardEvent) {
    this.advancedSearchForm.get('searchType').value === 'isbn' &&
      (!/\d/.test(event.key) || this.advancedSearchForm.get('term').value.length === 13) &&
      event.preventDefault();
  }
  /**
   * Determines whether paste on
   */
  onPaste() {
    setTimeout(() => {
      this.advancedSearchForm.get('searchType').value === 'isbn' &&
        !/^\d+$/.test(this.advancedSearchForm.get('term').value) &&
        this.advancedSearchForm.get('term').setValue('');
    }, 0);
  }
}
