import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { AppSettings, CurrentLibrary } from '@shared/data/config.model';
import { ProfileInfo } from '@shared/data/profile-info.model';
import { IThirdPartyVendor } from '@shared/data/third-party-model';
import { User } from '@shared/data/user.model';
import { strToBool, updateTheme } from '@shared/helper/app.util';
import { ConfigService } from './config.service';
import { ProfileInfoService } from './profile-info.service';
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { ProfilesService } from '@profile/shared/services/profiles.service';

interface UserAndSettings {
  appSettings: AppSettings;
  librarySettings: CurrentLibrary;
  user: User;
  profile: ProfileInfo;
  vendors: IThirdPartyVendor[];
}
@Injectable({
  providedIn: 'root'
})
export class MainService {
  currentProfileType: any;
  constructor(
    public http: HttpClient,
    private userService: UserService,
    private configService: ConfigService,
    private profileInfoService: ProfileInfoService,
    private sharedService: SharedService,
    private profilesService: ProfilesService
  ) {}
  /**
   * Gets user, app and library settings values
   */
  async getUserAndSettings() {
    try {
      const userAndSettings: UserAndSettings = await this.http.get<UserAndSettings>(API_INFO.getUserAndSettings).toPromise();
      this.userService.currentUser = userAndSettings.user;
      this.configService.appSettingsInfo = userAndSettings.appSettings;
      this.configService.librarySettings = userAndSettings.librarySettings;
      const sortedVendors = userAndSettings.vendors.slice(); // Create a shallow copy to avoid modifying the original array
      sortedVendors.sort((a: IThirdPartyVendor, b: IThirdPartyVendor) => (+a.vendor_order || 0) - (+b.vendor_order || 0));
      this.configService.vendorDetails = sortedVendors;
      this.profileInfoService.currentProfile = userAndSettings.profile?.profileId ? userAndSettings.profile : { profileType: '' };
      this.sharedService.refinerSubject.next('offcanvas');
      this.configService.setLibrarySettings(userAndSettings.librarySettings);

      if (this.userService.isLoggedIn()) {
        this.profileInfoService.getMyStuffCounts(userAndSettings.profile.myStuffs);
      }
      
      if (!this.userService.isLoggedIn()) {
        if(userAndSettings.librarySettings.authSettings.loginRequired && window.location.pathname !== "/ng/login"){
          window.location.href="/ng/login";
          return;
        }
      }
    } catch (e) {
      this.userService.currentUser = {};
      this.configService.appSettings = {};
      this.configService.librarySettings = {};
      this.configService.vendorDetails = [];
      this.profileInfoService.currentProfile = { profileType: '' };
    }
  }
  async getAvatar() {
    try {
      this.profilesService.getAvatars().subscribe((reponse) => {
        this.profilesService.avatarData = reponse;
      });
    } catch (e) {
      this.profilesService.avatarData = [];
    }
  }

  /**
   * To update theme on login from header
   * @param isHighContrastEnabled flag for high contrast
   */
  updateTheme = () => {
    const kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    const axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    if (!this.userService.userInfo?.isLoggedIn) {
      this.currentProfileType = axis360Enabled ? 'adult' : 'teen';
    } else {
      this.currentProfileType =
        !axis360Enabled && this.profileInfoService.currentProfile.profileType === 'Adult'
          ? 'teen'
          : this.profileInfoService.currentProfile.profileType;
    }
    if (kidsZoneEnabled) {
      updateTheme(strToBool(this.profileInfoService.currentProfile.highContrast), this.currentProfileType);
      return;
    }
    updateTheme(
      this.configService.currentLibrary.isMigrated
        ? strToBool(this.profileInfoService.currentProfile.highContrast)
        : this.userService.isHighContrastEnabled(),
      this.profileInfoService.currentProfile.profileType
    );
  };
}
