import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Component, ElementRef, Inject, Input } from '@angular/core';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { TitleActionButtonsComponent } from '@shared/components/title-action-buttons/title-action-buttons.component';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { DOCUMENT, Location } from '@angular/common';
import { CommonService } from '@shared/services/common.service';
import { HOLD_POSITION_INFO, SHIMMER_COMPONENTS, MYSTUFF_CAROUSEL_BANNER_TEXT, LIBRARY_CAROUSEL_FOOTER_TEXT } from '@shared/constants/app.constants';
import { ConfigService } from '@shared/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SharedService } from '@shared/services/shared.service';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { AccountService } from '@shared/services/account.service';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { Router } from '@angular/router';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';

@Component({
  selector: 'axis360-title-actions',
  templateUrl: './title-actions.component.html',
  styleUrls: ['./title-actions.component.scss']
})
export class TitleActionsComponent extends TitleActionButtonsComponent implements AfterViewInit {
  @Input() durationSecClass: string;
  @Input() dueDurationClass: string;
  @Input() kzCardBtnClass: string;
  @Input() kzActionMenuItemClass: string;
  @Input() expandSecClass: string;
  @Input() activeMyStuff: string;
  @Input() bookProgress = 0;
  @Input() isParticipant: boolean;
  @Input() programType: string;
  @Input() seeAll: boolean;
  @Input() searchResult: boolean;
  @Input() isProgramCTAShow = false;
  @Input() isAriaLabel: string;
  actionLoadingComponent = SHIMMER_COMPONENTS.ACTION_BUTTON;
  available = MYSTUFF_CAROUSEL_BANNER_TEXT.available;
  pastCheckout = MYSTUFF_CAROUSEL_BANNER_TEXT.pastCheckout;
  onHold = MYSTUFF_CAROUSEL_BANNER_TEXT.onHold;
  readyForCheckout = MYSTUFF_CAROUSEL_BANNER_TEXT.readyForCheckout;
  unavailable = MYSTUFF_CAROUSEL_BANNER_TEXT.unavailable;
  waitlist = MYSTUFF_CAROUSEL_BANNER_TEXT.waitlist;
  requested = LIBRARY_CAROUSEL_FOOTER_TEXT.requested;
  holdSuspended = LIBRARY_CAROUSEL_FOOTER_TEXT.holdSuspended;
  comingSoon = LIBRARY_CAROUSEL_FOOTER_TEXT.comingSoon;
  constructor(
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    public toastService: SnackBarService,
    public userService: UserService,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public liveAnnouncer: LiveAnnouncer,
    public overlayContainer: OverlayContainer,
    public matDialog: MatDialog,
    public commonService: CommonService,
    @Inject(DOCUMENT) public document: Document,
    public elRef: ElementRef,
    public sharedService: SharedService,
    public sharedAnalyticsService: SharedAnalyticsService,
    public accountService: AccountService,
    public location: Location,
    public kzProgramsService: KzProgramsService,
    public notificationsService: NotificationsService,
    public programAnalyticsService: ProgramsAnalyticsService,
    public titleDetailsService: TitleDetailsService,
    public router: Router
  ) {
    super(
      engageService,
      spinnerService,
      toastService,
      userService,
      configService,
      profileService,
      liveAnnouncer,
      overlayContainer,
      document,
      matDialog,
      elRef,
      sharedService,
      sharedAnalyticsService,
      accountService,
      commonService,
      location,
      kzProgramsService,
      notificationsService,
      programAnalyticsService,
      router,
      titleDetailsService
    );
  }

  /**
   * To be called on email info button click
   */
  holdPositionAlertPopup(event: Event) {
    event.stopPropagation();
    this.commonService.showAlertDialog({ ...HOLD_POSITION_INFO });
  }
  getTitleFormat(format: string) {
    return format.replace('Read Along', 'eRead-Along');
  }
  removeTooltipAnnouce(id) {
    document.querySelectorAll('.kz-card-tooltip').forEach((element) => {
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    });
    const profileTooltip = document.getElementById(`moreoption_${id}`);
    profileTooltip?.removeAttribute('aria-describedby');
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const disableElems = document.querySelectorAll('.disableActionSec .primary-action');
      disableElems.forEach((elem: HTMLElement) =>
        elem.addEventListener('click', (event) => {
          event.preventDefault();
        })
      );
    });
  }
}
