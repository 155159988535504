import { Component, Input } from '@angular/core';
import { INIT_LOAD_IMGS_COUNT } from '@search/shared/constants/search-results.constants';
import { TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { CommonService } from '@shared/services/common.service';
import { UserService } from '@shared/services/user.service';
@Component({
  selector: 'axis360-kz-web-resources-card',
  templateUrl: './kz-web-resources-card.component.html',
  styleUrls: ['./kz-web-resources-card.component.scss']
})
export class KzWebResourcesCardComponent {
  @Input() webResourceCardInfo: TitleCardInfo;
  @Input() index: number;
  @Input() webResourcesCarouselClass: string;
  INIT_LOAD_IMGS_COUNT = INIT_LOAD_IMGS_COUNT;

  constructor(
    private searchAnalyticsService: SearchAnalyticsService,
    private userService: UserService,
    public commonService: CommonService
  ) {}

  /**
   * Redirecting to selected Study Sites
   */
  titleCardClick(url: string) {
    this.searchAnalyticsService.trackSitesEvent('WEB_LINK');
    if (this.userService.isLoggedIn()) {
      window.open(url, '_blank');
      return;
    }
    this.commonService.doLogin();
  }
}
