<div class="activity-resources-container">
    <div class="activity-resources-content">
        <div class="card-image">
            <img [src]="activity.imageUrl" alt="">
            <div class="activity-poster d-flex">
                <mat-icon class="activity-poster-icon" svgIcon="kz-activity-poster-icon"></mat-icon>
            </div>
        </div>
        <div class="card-content">
            <div class="card-text">
                <h3 class="title double-ellipsis" axis360EllipsisTitle id="loc_programName{{activity.id}}">
                    {{activity.learningResourceName}}
                </h3>
                <a href="javascript:;" axis360EllipsisTitle
                    (click)="selectedResurceType(activity.activityType);$event.stopPropagation();"
                     class="primary-para single-ellipsis"
                    tabindex="{{tabindexValue}}" [attr.aria-disabled]="activity.disabled ? true : false">
                    {{activity.activityType}}
                </a>
                <a href="javascript:;" axis360EllipsisTitle (click)="showLinkedTitle();$event.stopPropagation();"
                     class="link single-ellipsis"
                    [ngClass]="{'disabled': activity.disabled}" [attr.aria-disabled]="activity.disabled ? true : false"
                    tabindex="{{tabindexValue}}">{{activity.linkedTitle}}
                </a>
            </div>
            <p class="kz-visually-hidden" *ngIf="isDownloadAnnouncer" role="alert">Check the file you downloaded on your local machine.</p>
            <button mat-raised-button class="btn-primary-blue mt-1" id="title_{{activity.id}}" *ngIf="!isDownloading"
                (click)="download(activity.fileUrl);$event.stopPropagation();" (keydown.enter)="download(activity.fileUrl);$event.stopPropagation();"
                attr.aria-label="Download {{activity.learningResourceName}}">Download</button>
            <div class="mt-1 downloading-btn" *ngIf="isDownloading">
                <div class="kz-download-bar-wrapper">
                    <div class="kz-download-bar-container d-flex" tabindex="0">
                        <div class="kz-download-bar" style="width: 50%;">
                        </div>
                    </div>
                    <div class="kz-downloadbar-percentage-sec" style="width: 50%;">
                        <span class="kz-downloadbar-percentage" aria-hidden="true">50%</span>
                    </div>
                </div>
                .
                <button class="kz-play d-flex btn-nobg-bluecolor" [attr.aria-label]="isPlaying ? 'play' : 'pause'">
                    <mat-icon svgIcon="kz-play-blue" *ngIf="isPlaying" aria-hidden="true"></mat-icon>
                    <mat-icon svgIcon="kz-pause" *ngIf="!isPlaying" aria-hidden="true"></mat-icon>
                </button>
                <button class="kz-play d-flex btn-nobg-bluecolor" aria-label="Cancel download">
                    <mat-icon svgIcon="kz-close" aria-hidden="true"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>