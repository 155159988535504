import { Component, Input, OnInit } from '@angular/core';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';

@Component({
  selector: 'axis360-newspaper-magazine-carousel',
  templateUrl: './newspaper-magazine-carousel.component.html',
  styleUrls: ['./newspaper-magazine-carousel.component.scss']
})
export class NewspaperMagazineCarouselComponent implements OnInit {
  @Input() publicationList;
  @Input() usedIn: string;
  @Input() type = '';
  cellWidth = 182;
  cellMargin = 0;
  cellHeight = 338;
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  showCarouselTitle = true;
  seeAllLink = `/ng/pressreader`;
  constructor(private sharedAnalyticsService: SharedAnalyticsService) {}

  ngOnInit(): void {}

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.sharedAnalyticsService.trackNewspaperCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.sharedAnalyticsService.trackNewspaperCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
    }
  }
}
