import { Component, OnDestroy, OnInit } from '@angular/core';
import { FILTER_TYPES, NEWS_FILTER, PRESSREADER_TITLE, REFINER_TYPE } from '@search/shared/constants/search-results.constants';
import { PublicationCard } from '@pressreader/shared/data/press-reader-model';
import { ActivatedRoute } from '@angular/router';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import {
  PRESS_READER_LIST_PAGE_SIZE,
  PRESS_READER_SORT_OPTIONS,
  PUBLICATION_TITLE_MAP,
  PUBLICATION_TYPES
} from '@pressreader/shared/constants/press-reader.constansts';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { DEFAULT_SNAKE_BAR_MSG, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { getPublicationCard, getPublicationCardFromIssue } from '@pressreader/shared/helper/press-reader.util';
import { noop, Subscription } from 'rxjs';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { SearchService } from '@search/shared/services/search.service';
import { AppliedFilter, Facet, Filter, Sort } from '@search/shared/data/filter-model';
import { ConfigService } from '@shared/services/config.service';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, getDate, positionFooter } from '@shared/helper/app.util';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SearchStorageService } from '@search/shared/services/search-storage-service';

@Component({
  selector: 'axis360-press-reader-list',
  templateUrl: './press-reader-list.component.html',
  styleUrls: ['./press-reader-list.component.scss']
})
export class PressReaderListComponent implements OnInit, OnDestroy {
  PRESSREADER_TITLE = PRESSREADER_TITLE;
  publications: PublicationCard[] = [];
  publicationType = '';
  categoryId = '';
  offset = 0;
  limit = PRESS_READER_LIST_PAGE_SIZE;
  scrollSubscription: Subscription;
  publicationTitle = '';
  totalPublicationCount = 0;
  countries: Facet[] = [];
  languages: Facet[] = [];
  availableLimiters: Filter[] = [];
  sortOptions: Sort[] = [
    { id: PRESS_READER_SORT_OPTIONS.SEARCH_RANK.value, displayable: PRESS_READER_SORT_OPTIONS.SEARCH_RANK.displayable, selected: false },
    {
      id: PRESS_READER_SORT_OPTIONS.LATEST_ISSUE_DATE.value,
      displayable: PRESS_READER_SORT_OPTIONS.LATEST_ISSUE_DATE.displayable,
      selected: false
    },
    {
      id: PRESS_READER_SORT_OPTIONS.POPULARITY.value,
      displayable: PRESS_READER_SORT_OPTIONS.POPULARITY.displayable,
      selected: true
    }
  ];
  selectedSortOption = PRESS_READER_SORT_OPTIONS.POPULARITY.displayable;
  isLoading = true;
  PUBLICATION_TYPES = PUBLICATION_TYPES;
  breadcrumbs: ({ text: string; url: string } | { text: string; url: string; queryParams: { type: string; cid: string } })[];

  /**
   * Creates an instance of press reader list component.
   * @param activatedRoute activate route info
   * @param pressReaderService press reader service
   * @param spinnerService spinner service
   * @param snackBarService toast service
   * @param infiniteScroll infinite scroll service
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private pressReaderService: PressReaderService,
    private snackBarService: SnackBarService,
    private infiniteScroll: InfiniteScrollService,
    private searchService: SearchService,
    private configService: ConfigService,
    private pressReaderAnalyticsService: PressReaderAnalyticsService,
    private liveAnnouncer: LiveAnnouncer,
    private searchStorageService: SearchStorageService
  ) {}

  /**
   * To get the query params and initial data
   */
  ngOnInit(): void {
    this.publicationType = this.activatedRoute.snapshot.queryParams.type;
    this.categoryId = this.activatedRoute.snapshot.queryParams.cid;
    if (this.activatedRoute.snapshot.queryParams.refresh) {
      this.searchStorageService.removePRRefiners();
    }
    this.getRefiners();
    this.getTitle();
    this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
    this.getBreadcrums();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }

  getBreadcrums() {
    this.breadcrumbs = [
      { text: 'NEWSPAPERS & MAGAZINES', url: '/pressreader' },
      {
        text: this.publicationTitle?.toUpperCase(),
        url: '/pressreader/list',
        queryParams: { type: this.publicationType, cid: this.categoryId }
      }
    ];
  }

  /**
   * To unsubscribe the scroll event
   */
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
    cdkRemoveVisualHidden();
  }

  /**
   * To get value for API in query param
   * @returns in query param
   */
  getInValForPublicationsAPI() {
    const inArray = [];
    if (this.countries) {
      const val = this.searchService.locationInfo?.countryCode?.toLowerCase() || '';
      const selectedCountries = this.countries.filter((country) => val ? country.value === val : country.selected);
      inArray.push(
        ...selectedCountries.map((element) => {
          return element.id;
        })
      );
    }
    return inArray.toString();
  }

  /**
   * To get value for API inVal1 query param
   * @returns inVal1 query param
   */
  getInVal1ForPublicationsAPI() {
    const inArray = [];
    if (this.languages) {
      const val = this.searchService.locationInfo?.languageCode?.toLowerCase() || '';
      const selectedLAnguage = this.languages.filter((language) => val ? language.value === val : language.selected);
      inArray.push(
        ...selectedLAnguage.map((element) => {
          return element.id;
        })
      );
    }
    return inArray.toString();
  }

  /**
   * To get value for API has query param
   * @returns has query param
   */
  getHasValForPublicationsAPI() {
    const inArray = [];
    if (this.categoryId) {
      inArray.push(this.categoryId);
    }
    return inArray.toString();
  }

  /**
   * To get publications info
   */
  async getPublications() {
    if (this.publicationType === PUBLICATION_TYPES.LATEST_ISSUES || this.publicationType === PUBLICATION_TYPES.SUPPLEMENT) {
      this.getPublicationDetails(this.categoryId);
      return;
    }
    if (!this.searchService.locationInfo?.countryCode) {
      this.searchService.locationInfo = await this.searchService.getlocation(this.configService.currentLibrary.id);
    }
    const inVal = this.getInValForPublicationsAPI();
    const inVal1 = this.getInVal1ForPublicationsAPI();
    const hasVal = this.getHasValForPublicationsAPI();
    const orderBy = this.sortOptions.find((element) => element.displayable === this.selectedSortOption).id;
    this.isLoading = true;
    if (this.offset === 0) {
      this.liveAnnouncer.announce('Results loaded', 'assertive');
      this.publications = [];
    }
    this.pressReaderService.getPublications(this.offset, this.limit, this.publicationType, inVal, inVal1, hasVal, orderBy).subscribe(
      (publications) => {
        const { items, meta = {} } = publications;
        const { totalCount } = meta;
        this.totalPublicationCount = totalCount || 0;
        const publicationCards = (items || []).map((publication) => getPublicationCard(publication, this.countries));
        this.publications.push(...publicationCards);
        this.isLoading = false;
        positionFooter(true);
      },
      () => {
        this.isLoading = false;
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
        positionFooter(true);
      }
    );
  }

  /**
   * To get publication details
   * @param publicationId publication id
   */
  getPublicationDetails(publicationId: string) {
    this.isLoading = true;
    this.pressReaderService.getPublicationById(publicationId).subscribe(
      async (res) => {
        if (this.publicationType === PUBLICATION_TYPES.SUPPLEMENT) {
          this.publications = (res.supplements || []).map((publication) => getPublicationCard(publication, this.countries));
          this.isLoading = false;
          positionFooter(true);
          return;
        }
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 90);
        this.pressReaderService.getLatestIssues(res.cid, getDate(fromDate), getDate(toDate), 90).subscribe(
          (latestIssues) => {
            latestIssues.forEach((issue) => {
              this.publications.push(getPublicationCardFromIssue(issue, res, this.countries));
            });
            this.isLoading = false;
            positionFooter(true);
          },
          () => {
            this.isLoading = false;
            this.publications = [];
            positionFooter(true);
          }
        );
      },
      () => {
        this.isLoading = false;
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
        positionFooter(true);
      }
    );
  }

  /**
   * To load next set of data when scroll reaches the page end
   */
  loadMore = () => {
    if (!this.canLoadMore()) {
      return;
    }
    this.offset = this.offset + this.limit;
    this.getPublications();
  };

  /**
   * To indentify load more can be called or not
   */
  canLoadMore = (): boolean =>
    !this.isLoading &&
    !(this.publicationType === PUBLICATION_TYPES.LATEST_ISSUES || this.publicationType === PUBLICATION_TYPES.SUPPLEMENT) &&
    this.totalPublicationCount >= this.limit;

  /**
   * To get the page title
   * @returns page title
   */
  getTitle() {
    if (this.publicationType) {
      this.publicationTitle = PUBLICATION_TITLE_MAP[this.publicationType];
      this.liveAnnouncer.announce(this.publicationTitle, 'assertive');
      this.pressReaderAnalyticsService.trackScreen('LIST', { carouselName: this.publicationTitle });
      return;
    }
    this.pressReaderService.getCategories().subscribe((res) => {
      const [firstIndex = {}] = res || [];
      const { categories } = firstIndex;
      this.publicationTitle = categories?.find((category) => category.id.toString() === this.categoryId)?.displayName || '';
      this.getBreadcrums();
      this.pressReaderAnalyticsService.trackScreen('LIST', { carouselName: this.publicationTitle });
    }, noop);
  }

  /**
   * Updates default selected filter
   */
  updateDefaultSelectedFilter() {
    const defaultCountry = this.configService.getPressReaderDefaultCounty();
    const defaultLanguage = this.configService.getPressReaderDefaultLang();
    this.countries.forEach((country) => (country.selected = country.value === defaultCountry));
    this.languages.forEach((language) => (language.selected = language.value === defaultLanguage));
  }

  /**
   * To get initial values for the filters
   */
  getFilters() {
    this.isLoading = true;
    if (this.publicationType === PUBLICATION_TYPES.LATEST_ISSUES || this.publicationType === PUBLICATION_TYPES.SUPPLEMENT) {
      this.getPublicationDetails(this.categoryId);
      return;
    }
    this.searchService.getCountries('').subscribe(
      (contryFilterRes) => {
        const defaultCountry = this.configService.getPressReaderDefaultCounty();
        this.countries = contryFilterRes.countries;
        const { id: defaultCountryCode } = this.countries.find((country) => country.value === defaultCountry) || {};
        this.searchService.getLanguages(defaultCountryCode).subscribe((langFilterRes) => {
          this.languages = langFilterRes.languages;
          this.updateDefaultSelectedFilter();
          this.setAvailableLimiters();
          this.getPublications();
        });
      },
      () => {
        this.isLoading = false;
        this.snackBarService.showSnackBar();
        positionFooter(true);
      }
    );
  }
  /**
   * Set available limiters for filtering the results
   */
  setAvailableLimiters() {
    this.setLDefaultCountriesFirst();
    this.setLDefaultLanguagesFirst();
    this.availableLimiters = [
      {
        title: NEWS_FILTER.LANGUAGE,
        facets: this.languages,
        type: FILTER_TYPES.TAB
      },
      {
        title: NEWS_FILTER.COUNTRY,
        facets: this.countries,
        type: FILTER_TYPES.LIST
      }
    ];
    this.storeRefiners();
  }

  /**
   * Set available limiters for filtering the results
   */
  setLDefaultLanguagesFirst() {
    const english = this.languages?.find((language) => language.value === 'en');
    const spanish = this.languages?.find((language) => language.value === 'es');
    const filteredLangusgaes = [];
    if (english) {
      filteredLangusgaes.push(english);
    }
    if (spanish) {
      filteredLangusgaes.push(spanish);
    }
    filteredLangusgaes.push(...this.languages.filter((language) => language.value !== 'en' && language.value !== 'es'));
    this.languages = filteredLangusgaes;
  }

  /**
   * Set available limiters for filtering the results
   */
  setLDefaultCountriesFirst() {
    const unitedStates = this.countries.find((country) => country.value === 'us');
    const filteredCountries = [];
    if (unitedStates) {
      filteredCountries.push(unitedStates);
    }
    filteredCountries.push(...this.countries.filter((country) => country.value !== 'us'));
    this.countries = filteredCountries;
  }

  /**
   * To update the results based on the sort option selected
   * @param sortOption  input param as sortOption
   */
  sortOptionSelected = (sortOption: Sort) => {
    this.pressReaderAnalyticsService.trackPressReaderEvent('SORT');
    this.offset = 0;
    this.selectedSortOption = sortOption.displayable;
    this.sortOptions.forEach((sort) => (sort.selected = false));
    this.sortOptions.find((sort) => sort.displayable === sortOption.displayable).selected = true;
    this.storeRefiners();
    this.getPublications();
  };

  /**
   * Applys filter
   * @param appliedFilter input param as applied filters
   */
  applyFilter = (applyFilter: { appliedFilter: AppliedFilter; filters: Filter[] }) => {
    this.pressReaderAnalyticsService.trackPressReaderEvent('SELECT_FILTER');
    this.offset = 0;
    this.getAndApplyFilters(
      applyFilter.appliedFilter.limiter,
      applyFilter.filters,
      applyFilter.appliedFilter.facets.map((facet) => `${facet.id}`).join()
    );
  };

  /**
   * Get response from news api and apply filter
   * @param filter filter name to where apply filter
   * @param availableLimiters list of available values
   * @param code code for filter api response
   * @param clearFilter boolean value for denote it is clear filter or not
   */
  getAndApplyFilters = (
    filter: string,
    availableLimiters: Filter[],
    code = '',
    clearFilter = false,
    consumeGetNews = true,
    setSelected = false
  ) => {
    const languageSvcFunc = { dataKey: 'languages', svcfunc: this.searchService.getLanguages, limiterKey: NEWS_FILTER.LANGUAGE };
    const countriesSvcFunc = { dataKey: 'countries', svcfunc: this.searchService.getCountries, limiterKey: NEWS_FILTER.COUNTRY };
    const svcFuncMap = {
      [NEWS_FILTER.LANGUAGE]: clearFilter ? languageSvcFunc : countriesSvcFunc,
      [NEWS_FILTER.COUNTRY]: clearFilter ? countriesSvcFunc : languageSvcFunc
    };
    const oppositeFilter = filter === NEWS_FILTER.COUNTRY ? NEWS_FILTER.LANGUAGE : NEWS_FILTER.COUNTRY;
    const svcInfo = svcFuncMap[filter];
    const { dataKey, limiterKey } = svcInfo;
    this.isLoading = true;
    svcInfo.svcfunc(code).subscribe(
      (res) => {
        if (!clearFilter) {
          res[dataKey].forEach(
            (facet) =>
              (facet.selected =
                availableLimiters.find((limiter) => limiter.title === limiterKey).facets?.find((obj) => obj.text === facet.text)
                  ?.selected || false)
          );
        }
        if (setSelected) {
          res[dataKey].forEach((facet) => (facet.selected = this[dataKey].find((obj) => obj.text === facet.text)?.selected || false));
        }
        this[dataKey] = res[dataKey];
        if (consumeGetNews) {
          this.setAvailableLimiters();
          this.isLoading = false;
          this.getPublications();
          return;
        }
        const selectedId = res[dataKey].map((facet) => facet.id);
        const selectedIdStr = selectedId.length > 0 ? selectedId.join() : '';
        this.getAndApplyFilters(oppositeFilter, availableLimiters, selectedIdStr, true, true, true);
      },
      () => {
        this.isLoading = false;
        this.snackBarService.showSnackBar();
      }
    );
  };
  /**
   * Clear filter values
   * @param clearLimiter input value as limiter name to clear and list of available values
   */
  clearFilters = (clearLimiter: { limiter: string; filters: Filter[] }) => {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CLEAR');
    const limiter = clearLimiter.limiter === NEWS_FILTER.COUNTRY ? NEWS_FILTER.LANGUAGE : NEWS_FILTER.COUNTRY;
    const selectedId = clearLimiter.filters
      .find((filter) => filter.title === limiter)
      .facets.filter((facet) => facet.selected)
      .map((facet) => facet.id);
    const selectedIdStr = selectedId.length > 0 ? selectedId.join() : '';
    this.getAndApplyFilters(clearLimiter.limiter, clearLimiter.filters, selectedIdStr, true, false);
  };
  /**
   * Gets refiners from session storage
   */
  getRefiners() {
    const refiners = this.searchStorageService.getPRCatelogRefiners();
    if (!refiners) {
      this.getFilters();
      return;
    }
    this.setLDefaultCountriesFirst();
    this.setLDefaultLanguagesFirst();
    this.availableLimiters = refiners.filters;
    this.countries = this.availableLimiters.find((limiter) => limiter.title === NEWS_FILTER.COUNTRY)?.facets;
    this.languages = this.availableLimiters.find((limiter) => limiter.title === NEWS_FILTER.LANGUAGE)?.facets;
    this.selectedSortOption = refiners.sortBy;
    this.sortOptions.forEach((sort) => (sort.selected = false));
    this.sortOptions.find((sort) => sort.displayable === this.selectedSortOption).selected = true;
    this.getPublications();
  }
  /**
   * Sets refiners to session storage
   */
  storeRefiners() {
    this.searchStorageService.setPRCatelogRefiners({ filters: this.availableLimiters, sortBy: this.selectedSortOption });
  }

  /**
   * event handling when open refiner dialog
   * @param refinerType input parameter as refiner type
   */
  openRefinerDialog(refinerType: string) {
    if (refinerType === REFINER_TYPE.FILTER) {
      this.pressReaderAnalyticsService.trackScreen('FILTER');
      this.pressReaderAnalyticsService.trackPressReaderEvent('FILTER');
    } else {
      this.pressReaderAnalyticsService.trackPressReaderEvent('SORT');
    }
  }
}
