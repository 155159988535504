import { Component, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MAT_TABS_ID, MAT_TABS_NAME } from '@search/shared/constants/search-results.constants';
import { SearchService } from '@search/shared/services/search.service';
import { ConfigService } from '@shared/services/config.service';
@Component({
  selector: 'axis360-search-tabs',
  templateUrl: './search-tabs.component.html'
})
export class SearchtabsComponent implements AfterViewInit, OnInit {
  @Output() selectedTabChangeEvent = new EventEmitter<string>();
  MAT_TABS_NAME = MAT_TABS_NAME;
  MAT_TABS_ID = MAT_TABS_ID;
  sitesTabEnabled = false;
  newsTabEnabled = false;
  /**
   * Creates an instance of search tabs component.
   * @param configService Config Service
   */
  constructor(public configService: ConfigService, public searchService: SearchService) {}
  /**
   * On init of search tabs component
   */
  ngOnInit() {
    this.sitesTabEnabled = this.configService.isWebPathEnabled();
    this.newsTabEnabled = this.configService.isPressReaderEnabled();
  }
  /**
   * To be called on selecting tab to change the active tab
   */
  selectedTabChange = (matTabEvent: MatTabChangeEvent) => {
    this.searchService.selectedSearchTab = matTabEvent.index;
    this.selectedTabChangeEvent.emit(matTabEvent.tab.textLabel);
  };
  /**
   * On after view init of search tabs component
   * Focus on active tab after view init
   */
  ngAfterViewInit() {
    const tabActive = document.querySelector('#searchTabs .mat-tab-label-active') || { id: '' };
    document.getElementById(tabActive.id)?.focus();
  }
}
