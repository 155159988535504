import { Component, Input } from '@angular/core';
import { BadgesComponent } from '@home/badges/badges.component';
import { Badge } from '@home/shared/data/home.model';
import { HomeService } from '@home/shared/services/home.service';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { MyShelfAnalyticsService } from '../../shared/services/my-shelf.analytics.service';

@Component({
  selector: 'axis360-badges-card-carousel',
  templateUrl: './badges-card-carousel.component.html',
  styleUrls: ['./badges-card-carousel.component.scss']
})
export class BadgesCardCarouselComponent extends BadgesComponent {
  cellWidth = 160;
  cellMargin = 0;
  cellHeight = 200;
  cellsToScroll = 5;
  @Input() cellsToScrollNums = [5, 4, 3, 2];
  onResize = setIvyCarouselProps.bind(this, 102, 0, 130, 160, 0, 200, this.cellsToScrollNums);

  compName = SHIMMER_COMPONENTS.BADGE_CARD;
  constructor(protected homeService: HomeService, public myShelfAnalyticsService: MyShelfAnalyticsService) {
    super(homeService);
  }
  /**
   * Call resize function on init with super call
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.onResize();
    this.onResize = setIvyCarouselProps.bind(this, 102, 0, 130, 160, 0, 200, this.cellsToScrollNums);

  }
  handleBadges(badges: Badge[] = []) {
    return badges;
  }
  /**
   * To update the carousel cell width and margin
   */
  isMobileDevice():boolean {
    return window.innerWidth < 560;
  }
  isTabletDevice():boolean {
    return window.innerWidth < 1024;
  }
  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.myShelfAnalyticsService.trackBadgesCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.myShelfAnalyticsService.trackBadgesCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling?.classList.remove('carousel-arrow-disabled');
    }
  }
}
