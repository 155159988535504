<div class="login-container-sec kz-main" *ngIf="!pwdRecovery && !pwdVerification && !showRegisterPrompt">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <fieldset class="fieldset">
            <legend class="legend">
                <h2 class="heading-1" id="dialogHeading">Log Into Your Library</h2>
            </legend>
            <mat-error *ngIf="loginResponse && loginResponse.hasErrors">{{loginResponse.errorMessage}}</mat-error>
            <div class="kz-login-form">
                <mat-form-field appearance="fill">
                    <mat-label>{{libraryLoginInfo.userDisplayName}}</mat-label>
                    <input matInput placeholder="" [formControl]="loginForm.controls.logOnModel_UserName"
                        ngDefaultControl cdkFocusInitial axis360TrapFocus id="LogOnModel_UserName"
                        (change)="libraryLoginInfo.userDisplayName === 'Library Card Id' ?accountAnalyticsService.trackAccountEvent('TYPE_LC_ID') : accountAnalyticsService.trackAccountEvent('TYPE_USERNAME')  ">
                    <mat-error> Please enter a {{libraryLoginInfo.userDisplayName}} to login.
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="!currentLibrary.authSettings.noPatronPinNeeded">
                    <mat-form-field appearance="fill">
                        <mat-label>{{libraryLoginInfo.authenticationType}}</mat-label>
                        <input matInput placeholder="" [formControl]="loginForm.controls.logOnModel_Password"
                            [type]="passwordHide ? 'password' : 'text'" id="LogOnModel_Password"
                            (change)="libraryLoginInfo.authenticationType === 'PIN' ? accountAnalyticsService.trackAccountEvent('TYPE_PIN') : accountAnalyticsService.trackAccountEvent('TYPE_PASSWORD')">
                        <button type="button" mat-icon-button matSuffix (click)="passwordHide = !passwordHide" [matTooltip]="passwordHide ? 'Show password': 'Hide password'"
                        matTooltipClass="kz-card-tooltip"
                            [attr.aria-label]="passwordHide ? 'Show password': 'Hide password'" class="hide-btn"
                            ngDefaultControl>
                            <mat-icon *ngIf="passwordHide" svgIcon="kz-password-hide-icon"></mat-icon>
                            <mat-icon *ngIf="!passwordHide" svgIcon="kz-password-show-icon"></mat-icon>
                        </button>
                        <mat-error>{{libraryLoginInfo.authenticationType}} is required.
                        </mat-error>
                    </mat-form-field>
                    <div class="forgot-sec" *ngIf="loginData && loginData.passwordRecoverable">
                        <a class="secondary-btn forgot-btn" href="javascript:void(0)" role="link"
                            (click)="pwdRecovery = true;accountAnalyticsService.trackAccountEvent('FORGOT_PIN')"
                            id="forgotPinLink">Forgot
                            {{libraryLoginInfo.authenticationType}}?</a>
                    </div>
                </ng-container>
            </div>
            <div class="kz-checkbox-sec kz-login-checkbox">
                <mat-checkbox axis360MatCheckboxAda [formControl]="loginForm.controls.rememberMe" ngDefaultControl
                    id="RememberMe" (click)="accountAnalyticsService.trackAccountEvent('REMEMBER_CARD_ID')">
                    Remember me<span class="recommend-text">Recommended
                        only for private
                        devices</span></mat-checkbox>
            </div>
            <div class="done_Submit d-flex">
                <button mat-raised-button class="btn-primary-blue text-capitalize saveBtn"
                    [ngClass]="{active: loginForm && !loginForm.invalid}" id="loc_btnLogin"
                    [disabled]="loginForm.invalid || !loginForm"
                    (click)="accountAnalyticsService.trackAccountEvent('SIGIN_CTA')">log in</button>
            </div>
        </fieldset>
    </form>
    <div *ngIf="currentLibrary.authSettings.ilsVendorID == 'PASSWORD'" class="admin-sec">
        <a class="secondary-btn" role="link"
            (click)="pwdVerification = true;accountAnalyticsService.trackAccountEvent('NEW_USER')" (keyup.enter)="pwdVerification = true;accountAnalyticsService.trackAccountEvent('NEW_USER')" role="link"
            *ngIf="loginData && loginData.showRegisterPrompt" tabindex="0" id="lnkShowRegPwd">
            <span>{{loginData.showRegisterPrompt}}</span></a>
    </div>
</div>
<axis360-registeration [noModal]="noModal" *ngIf="showRegisterPrompt" [registerResponse]="registerResponse"></axis360-registeration>
<axis360-password-recovery [noModal]="noModal" *ngIf="pwdRecovery"></axis360-password-recovery>
<axis360-password-verification [noModal]="noModal" *ngIf="pwdVerification" [loginData]="loginData"></axis360-password-verification>