import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'axis360-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeComponent {
  safeUrl: SafeResourceUrl;
  @Input() set url(value) {
    if (this.iframeSrc !== value) {
      this.iframeSrc = value;
      this.changeRef.detectChanges();
    }
  }
  @Input() classes = '';
  @Output() hasError = new EventEmitter<boolean>();
  iframeSrc = '';

  constructor(public sanitizer: DomSanitizer, private changeRef: ChangeDetectorRef) {}

  /**
   * Emit error event on error in iframe
   */
  emitError() {
    this.hasError.emit();
  }
}
