import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CATEGORY_TYPE, PROGRAM_CATEGORY_TYPE } from '@shared/constants/search.constant';
import { SearchCategory } from '@shared/data/shared.model';
import { deepClone, randomNumderGenerator, refinerScrollFound } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { FORMAT_TYPE, PRESSRDR, CATEGORY_TYPE as catType } from 'app/core/kz-search-results/shared/constant/search.constant';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { PROFILE_TYPES } from 'app/third-party/shared/constants/third-party.constants';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'axis360-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['../../components/search-refiners/search-refiners.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @Output() filterPopupClose = new EventEmitter();
  categoryType = CATEGORY_TYPE;
  categoryTypeMap: any = [];
  selectedText: string;
  showAccordion: boolean;
  thiredPartyCategoriesSubscription: Subscription;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  selectedpathParms: string;
  pageName: string;
  programId: string;
  refinerScrollFound = refinerScrollFound;
  randomNumderGenerator = randomNumderGenerator;
  programsDetails: any;
  parentRoute: string;
  constructor(
    public searchService: KzSearchService,
    public configService: ConfigService,
    private router: Router,
    public profileInfoService: ProfileInfoService,
    public thirdPartyService: ThirdPartyService,
    public userService: UserService,
    public activeRouter: ActivatedRoute,
    public liveAnnouncer: LiveAnnouncer
  ) { }

  ngOnInit(): void {
    this.pageName = this.activeRouter.snapshot.queryParams?.pageName;
    this.programId = this.activeRouter.snapshot.queryParams?.programId;
    const regExString = /(?:view\/)((.[\s\S]*))(?:\/search)/ig; // set ig flag for global search and case insensitive
    const regExString1 = regExString.exec(this.router.url)
    this.parentRoute = regExString1 ? regExString1[1] : '';
    if (this.pageName !== 'programs') {
      this.categoryType.forEach((category) => {
        if (category.text === 'Web Resources') {
          category.enabled = this.profileInfoService.profileInfo.profileId
            ? this.profileInfoService.profileInfo.webpathExpressEnabled
            : this.configService.currentLibrary.enableWebPathExpress;
        }
        if (category.text === 'Resource Hub') {
          category.enabled = this.userService.isLoggedIn() ? this.configService.getProfileFeatures(
            this.profileInfoService.currentProfile.profileType.toLowerCase()
          ).learningActivityEnabled : this.configService.getProfileFeatures(PROFILE_TYPES.ADULT).learningActivityEnabled;
        }
      });
      this.searchService.focusToFormatText.subscribe(res => {
        if (res) {
          setTimeout(() => {
            (document.querySelector('#' + res.foucsValue.replace(/\s+/g, '')))?.querySelector('a')?.focus();
          }, 4000);
        }
      });
      this.thiredPartyCategoriesSubscription = this.searchService.getThiredPartyCategories().subscribe((categoryTypeRes) => {
        const OLD_CATEGORY_TYPE_LIST = deepClone(CATEGORY_TYPE);
        if (categoryTypeRes.length === 0 && (this.userService.userInfo?.isLoggedIn || !this.userService.userInfo?.isLoggedIn)) {
          this.getThirdPartyVendor();
          return;
        }
        OLD_CATEGORY_TYPE_LIST.splice(3, 0, ...categoryTypeRes);
        this.categoryType = [...OLD_CATEGORY_TYPE_LIST.filter((x) => x.enabled === true)];
        const pillsOrderList = this.categoryType.filter(ref => ref.text !== 'All').map(list => list.text);
        const pillsOrderListIndex = pillsOrderList.indexOf("Newspapers & Magazines");
        pillsOrderListIndex >= 0 && pillsOrderList.splice(pillsOrderListIndex, 1, "Publications", "Articles");
        this.searchService.setPillsOrderList.next(pillsOrderList);
      });
    }
    else {
      this.categoryType = PROGRAM_CATEGORY_TYPE;
      const pillsOrderList = this.categoryType.filter(ref => ref.text !== 'All').map(list => list.text);
      this.searchService.setPillsOrderList.next(pillsOrderList);
    }
    if (this.configService.currentLibrary.isPPCOnlyLibrary) {
      this.categoryType = this.categoryType.filter(x => x.text !== 'eBooks');
    }
  }

  /**
   * getThirdPartyVendor
   * @returns object for categoryTypeMap
   */
  async getThirdPartyVendor() {
    const OLD_CATEGORY_TYPE_LIST = deepClone(CATEGORY_TYPE);
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    const isKZGuest = !this.userService.userInfo?.isLoggedIn;
    const thirdPartyLibraryList = this.configService.vendorDetails || [];
    const isGetZoneValue = (!this.axis360Enabled && this.kidsZoneEnabled) ? PROFILE_TYPES.TEEN : PROFILE_TYPES.ADULT;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(isKZGuest ?
        isGetZoneValue :
        this.profileInfoService.currentProfile.profileType.toLowerCase()) || {};
    if (thirdPartyLibraryList.length === 0) {
      this.categoryType = [...CATEGORY_TYPE];
      return;
    }
    this.categoryTypeMap = (thirdPartyLibraryList || [])
      .filter((x) => x.include_in_search && currentProfileFeatures[x.vendor_code])
      .sort((a, b) => a.vendor_order - b.vendor_order)
      .map((x) => {
        if (x.vendor_code !== PRESSRDR) {
          return {
            text: x.display_name,
            value: x.display_name,
            vendorCode: x.vendor_code,
            vendorGroup: false,
            vendor_order: x.vendor_order,
            enabled: true,
            url: '/view/search/' + x.display_name
          };
        }
      });
    thirdPartyLibraryList.forEach((item) => {
      if (item.vendor_code === PRESSRDR && currentProfileFeatures[item.vendor_code]) {
        item.vendor_groups.length > 0 ? item.vendor_groups.forEach((subItem) => {
          this.categoryTypeMap.push({
            text: subItem.name,
            value: subItem.name,
            enabled: true,
            vendorGroup: true,
            vendor_order: subItem.vendor_order,
            url: '/view/search/' + subItem.name,
            vendorCode: item.vendor_code
          });
        }) :
          this.categoryTypeMap.push({
            text: item.display_name,
            value: item.display_name,
            vendorCode: item.vendor_code,
            vendorGroup: false,
            vendor_order: item.vendor_order,
            enabled: true,
            url: '/view/search/' + item.display_name
          });
      }
    });
    this.categoryTypeMap = this.categoryTypeMap.toSorted((a, b) => a.vendor_order - b.vendor_order)
      .filter((item) => item !== undefined);
    OLD_CATEGORY_TYPE_LIST.splice(3, 0, ...this.categoryTypeMap);
    this.categoryType = [...OLD_CATEGORY_TYPE_LIST.filter((x) => x.enabled === true)];
    const pillsOrderList = this.categoryType.filter(ref => ref.text !== 'All').map(list => list.text);
    const pillsOrderListIndex = pillsOrderList.indexOf("Newspapers & Magazines");
    pillsOrderListIndex >= 0 && pillsOrderList.splice(pillsOrderListIndex, 1, "Publications", "Articles");
    this.searchService.setPillsOrderList.next(pillsOrderList);
  }

  /**
   * Shows active
   */
  showActive(category: SearchCategory) {
    this.searchService.addingAlertCount();
    this.pageName = this.activeRouter.snapshot.queryParams?.pageName;
    this.programId = this.activeRouter.snapshot.queryParams?.programId;
    this.searchService.selectedCategory = category.value;
    this.showAccordion = this.selectedText !== this.categoryType[0].text;
    this.searchService.studyTitles.pageTitle = category.text;


    /** We remove the below code for ebook and eaudio subrate the value */
    /**
     * if (category.value === 'titles' || category.value === 'All') {
     * this.searchService.studyTitles.format = 'All';
     * }
     */
    /** Newly add the below code for change the category value based on format type */
    const isCategoryCond = category.value === 'eAudio' ? FORMAT_TYPE.eAUDIO : 'All';
    const formatType = category.value === 'eBook' ? FORMAT_TYPE.eBOOK : isCategoryCond;
    this.searchService.studyTitles.format = formatType || 'All';
    this.searchService.studyTitles.isFromProgramMilestone = (this.pageName === 'programs');
    this.searchService.format = formatType || 'All';
    this.liveAnnouncer.announce(`Selected ${category.text} `, 'assertive');
    this.searchService.focusToFormatText?.next({ foucsValue: `loc_${category.value === 'Newspapers & Magazines' ? 'NewspapersMagazines' : category.value}` });
    this.categoryRefiner(category);
    this.filterPopupClose.emit();
  }
  /**
   * Search Page Renavigating based on the Category Selection in Search Result page
   */
  categoryRefiner(category: SearchCategory) {
    let CategoryRefined = {
      [catType.ALL]: this.searchService.studyTitles,
      [catType.WEB_RESOURCES]: this.searchService.studySites,
      [catType.TITLES]: this.searchService.studyTitles,
      [catType.EAUDIO_TITLES]: this.searchService.studyTitles,
      [catType.EBOOKS_TITLES]: this.searchService.studyTitles,
      [catType.ACTIVE_RESOURCES]: this.searchService.learningActivity
    };
    if (!CategoryRefined[category.value]) {
      CategoryRefined = { ...CategoryRefined, ...{ [category.value]: this.searchService.studyTitles } };
    }
    this.routeSearch(CategoryRefined[category.value], category);
    sessionStorage.removeItem('applied-refiners');
  }
  /**
   * Search Page Renavigating based on the Category Selection in Search Result page
   */
  routeSearch(params, category: SearchCategory) {
    if (category.value !== 'All') {
      params.pageSize = 24;
    } else {
      params.pageSize = 10;
    }
    params.format = this.searchService.format;
    params.collection = ''
    params.ref = this.randomNumderGenerator();
    this.selectedpathParms = this.pageName === 'programs'
      ? category.url.replace('<parentRoute>', this.parentRoute)
      : category.url
    this.router.navigate([this.selectedpathParms], { queryParams: params });
  }
  setState() {
    this.refinerScrollFound();
  }
  ngOnDestroy(): void {
    if (this.thiredPartyCategoriesSubscription) {
      this.thiredPartyCategoriesSubscription.unsubscribe();
    }
  }
  removeSpaces(text: string): string {
    if (text === 'Newspapers & Magazines') {
      text = 'NewspapersMagazines';
    }
    return text.replace(/\s+/g, '');
  }
}
