<div class="prog-list-main" *ngIf="bookDetail.isHiddenTitle">
    <div class="reader-list">
        <div class="sequence" *ngIf="isBooksInOrder" aria-hidden="true">{{bookDetail.sequence}}</div>
        <mat-card class="reader-poster hidden-title">
            <mat-icon aria-hidden="false" role="button" aria-label="Title info"
                matTooltip="This title is no longer available.">info_outline</mat-icon>
            <div class="hidden-title-img"></div>
        </mat-card>
    </div>
</div>
<div class="prog-list-main" *ngIf="!bookDetail.isHiddenTitle">
    <div class="reader-list">
        <div class="sequence" *ngIf="isBooksInOrder" aria-hidden="true">{{bookDetail.sequence}}</div>
        <mat-card class="reader-poster">
            <a [href]="bookRedirectionUrl" class="poster-main-sec">
                <img class="poster-main-img" attr.id="title-img-{{bookDetail.info.itemId}}"
                    [hidden]="bookDetail.hideImage" (error)="bookDetail.hideImage=true" [src]="bookDetail.info.imageUrl"
                    [alt]="bookDetail.info.title" [attr.aria-label]="titleAriaLabel" />
                <div class="book-poster"
                    [ngClass]="{'in':(bookDetail.info.isAvailable && bookDetail.info.isRTV),'out':!(bookDetail.info.isAvailable && bookDetail.info.isRTV)}">
                    <mat-icon [svgIcon]="getIcon(bookDetail.info.formatType).icon"></mat-icon>
                </div>
            </a>
            <div class="poster-author-detail" aria-hidden="true">
                <p class="title-name">{{bookDetail.info.title}}</p>
                <p class="author-name">{{bookDetail.info.author}}</p>
            </div>
            <ng-container>
                <axis360-title-action-buttons [bookDetail]="bookDetail.info"
                    [bookProgress]="bookDetail.percentageComplete"
                    [bookSequence]="isBooksInOrder ? bookDetail.sequence : 0"
                    [showAction]="bookDetail.isParticipantCanRead && showActionCTA"
                    [showPercentage]="bookDetail.isParticipantCanRead && showPercentage"
                    (titleMarkAsReadEvent)="markAsReadClick()"></axis360-title-action-buttons>
            </ng-container>
        </mat-card>
        <ng-container
            *ngIf="bookDetail.isParticipantCanRead && showPercentage && configService.currentLibrary.profileFeatures[profileInfoService.currentProfile.profileType.toLocaleLowerCase()].insightsBadgesEnabled">
            <div class="progress-bar-wrapper" *ngIf="bookDetail.percentageComplete">
                <div class="progress-bar-container" tabindex="-1" role="text"
                    attr.aria-label="{{bookDetail.info.title}} completed {{bookDetail.percentageComplete}}%">
                    <div class="progress-bar"
                        [ngStyle]="{'width': (bookDetail.percentageComplete >= 0)?bookDetail.percentageComplete + '%':'0%'}">
                    </div>
                </div>
                <div class="progressbar-percentage-sec" tabindex="-1" role="presentation"
                    [ngStyle]="{'width': (bookDetail.percentageComplete >= 20)?bookDetail.percentageComplete + '%':'20%'}">
                    <span class="progressbar-percentage" aria-hidden="true"> {{bookDetail.percentageComplete}} %</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>