import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Directive({
  selector: '[axis360MsDisableButtonAfterClick]'
})
export class DisableAfterClickDirective implements OnInit, OnDestroy {
    @Input() throttleMillis = 1000;
    @Output() singleClick = new EventEmitter();
    private subscription: Subscription;

    constructor(public elementRef: ElementRef) {}
    ngOnInit(): void {
      this.subscription = fromEvent(this.elementRef.nativeElement, 'click')
        .pipe(throttleTime(this.throttleMillis))
        .subscribe((v) => {
          this.singleClick.emit(v);
        });
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
}
