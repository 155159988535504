import { Component, Input, OnInit } from '@angular/core';
import { TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { setIvyCarouselProps } from '@shared/helper/app.util';
@Component({
  selector: 'axis360-kz-web-resources-carousel',
  templateUrl: './kz-web-resources-carousel.component.html',
  styleUrls: ['./kz-web-resources-carousel.component.scss']
})
export class KzWebResourcesCarouselComponent implements OnInit {
  @Input() titleCardsInfo: TitleCardInfo[] = [];
  @Input() cellsToScrollNums = [2, 2, 1, 1];
  cellWidth = 354;
  cellMargin = 0;
  cellHeight = 236;
  cellsToScroll = 2;

  constructor(private sharedAnalyticsService: SharedAnalyticsService) {}
  onResize = setIvyCarouselProps.bind(this, 354, 0, 236, 354, 0, 236, this.cellsToScrollNums);

  /**
   * on init
   */
  ngOnInit(): void {
    this.onResize();
  }
  /**
   * Tracks carousel
   * @parame
   * @returns
   */
  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.sharedAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.sharedAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
    }
  }
}
