import { Component, OnDestroy, OnInit } from '@angular/core';
import { cdkRemoveVisualHidden, cdkVisualHidden, getBackgroundImageResize, positionFooter } from '@shared/helper/app.util';
import { Location } from '@angular/common';
import { CommonService } from '@shared/services/common.service';
import { Router } from '@angular/router';
import { ConfigService } from '@shared/services/config.service';
import { BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT } from '@shared/constants/search.constant';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { REDIRECT_URL } from '@profile/shared/constants/profiles.constants';
@Component({
  selector: 'axis360-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit, OnDestroy {
  backgroundImageUrl: string;
  pageAnnounce = '';
  cdkVisualHidden = cdkVisualHidden;
  constructor(
    public location: Location,
    public commonService: CommonService,
    public router: Router,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
  ) { }
  ngOnInit(): void {
    document.title = this.commonService?.currentUrl?.includes(`profiles/create`) ? `Create Profile` : document.title;
    this.pageAnnounce = 'Create Profile Page';
    this.commonService.hideHeaderFooter.next(true);
    positionFooter(true);
    this.onResize();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      this.cdkVisualHidden();
    }, 2000);
  }
  onResize() {
    this.backgroundImageUrl = getBackgroundImageResize(BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT);
  }
  ngOnDestroy() {
    this.commonService.hideHeaderFooter.next(false);
    cdkRemoveVisualHidden();
  }
  back() {
    document.title = `Manage profile`;
    this.router.navigate([REDIRECT_URL.PROFILES_LIST], { queryParams: { isLogin: 'yes' } });
  }
  setTitle(title: string)
  {
    this.profileInfoService.selectedEditProfileType = title;
  }
}
