export interface User {
  patronType?: string;
  userName?: string;
  isLoggedIn?: boolean;
  isHighContrastEnabled?: boolean;
  enableTracking?: boolean;
  displayTrackingPrompt?: boolean;
  email?: string;
  notifications?: UserNotification[];
  patronId?: string;
}

export interface UserNotification {
  type?: NotificationType;
  url?: string;
  titleName?: string;
  message?: string;
  groupName?: string;
}

export enum NotificationType {
  HoldReserved = '0',
  Assignment = '1',
  ExpiringSoon = '2',
  RecommendAvailable = '3'
}
