<axis360-spinner-loader [isLoading]="!loadThirdPartyAPIM" *ngIf="!loadThirdPartyAPIM"></axis360-spinner-loader>
<div class="mystuff-lists"
    *ngIf="loadThirdPartyAPIM && myStuffContent && myStuffContent.Items && myStuffContent.Items.length > 0">
    <ng-container *ngFor="let titleContent of myStuffContent.Items">
        <ng-container *ngIf="titleContent.FormatType !== 'VID' && titleContent.FormatType !== 'VBK'">
            <mat-card class="list-card" [ngClass]="{'checkouts': activeMyStuff === 'checkouts'}">
                <a href="javascript:;" tabindex="0" class="card-link" [routerLink]="titleLink"
                    (click)="navigateToListPage(titleContent)" (keyup.enter)="navigateToListPage(titleContent)"
                    id="mystuff-{{titleContent.ItemId}}" [attr.aria-label]="titleAriaLabel(titleContent)"
                    aria-hidden="false">
                    <div class="remaining-hours">
                        <ng-container *ngIf="titleContent?.titleStateInfo">
                            <axis360-title-actions [isDueDate]="true" [durationSecClass]="'carousel-card-ribbon'"
                                [dueDurationClass]="'kz-time-remain-lbl'"
                                [kzCardBtnClass]="'kz-card-btn kz-card-btn-list'"
                                [kzActionMenuItemClass]="'kz-my-stuff-list-option'"
                                [expandSecClass]="'more-menu more-menu-list'" [bookDetail]="titleContent"
                                [isProgressCalculate]="false" [isMyStuff]="true" [activeMyStuff]="activeMyStuff">
                            </axis360-title-actions>
                        </ng-container>
                    </div>
                    <div aria-hidden="true" class="book-ribbon" *ngIf="isShowWaitList(titleContent)">
                        <span class="sr-only">Status &nbsp;</span>{{waitList}}
                    </div>
                    <div class="kz-stuff-book-image" [attr.aria-hidden] ="!isMobileTab()">
                        <img class="mystuff-card-image" [src]="titleContent.url || getTitleImageUrl(titleContent.ISBN)"
                            alt="{{titleContent.Title}} By {{titleContent.Author}}">
                    </div>
                    <div class="book-poster-bg" tabindex="-1" aria-hidden="true">
                        <div class="book-poster" *ngIf="titleContent.FormatType">
                            <mat-icon class="book-poster-icon"
                                [svgIcon]="getIcon(titleContent.FormatType).icon"></mat-icon>
                        </div>
                        <div class="duration" tabindex="-1" aria-hidden="true">
                            <div class="duration-sec"
                                *ngIf="((titleContent?.FormatType === 'ABT' || titleContent?.iconInfo?.icon === 'audio')  && (titleContent.runTime || titleContent?.RunTime))"
                                aria-hidden="true">
                                {{titleContent.runTime || titleContent.RunTime}}
                            </div>
                            <div class="sr-only"
                                *ngIf="((titleContent?.FormatType === 'ABT' || titleContent?.iconInfo?.icon === 'audio')  && titleContent?.RunTime)">
                                {{eAudio}}
                                {{titleContent.RunTime.replace('h','hours').replace('m','minutes').replace('Mins','Minutes')}}
                            </div>
                            <div class="duration-sec"
                                *ngIf="((titleContent?.FormatType === 'ABT' || titleContent?.iconInfo?.icon === 'audio')  && (!titleContent.runTime && !titleContent?.RunTime))">
                                {{eAudio}}
                            </div>
                            <div class="duration-sec"
                                *ngIf="titleContent?.FormatType === 'EBT' && titleContent?.Attribute ==='Read Along'  && (titleContent?.BookLength ==='0' || !titleContent?.BookLength)">
                                <span class="duration-text" [innerHtml]="getTitleFormat(titleContent.Attribute)"></span>
                            </div>
                            <div class="duration-sec"
                                *ngIf="(titleContent?.FormatType === 'EBT' || titleContent?.iconInfo?.icon === 'ebook') && (titleContent?.BookLength ==='0' || !titleContent?.BookLength) && titleContent?.Attribute !=='Read Along'">
                                {{eBook}}
                            </div>
                            <div class="duration-sec"
                                *ngIf="(titleContent?.FormatType === 'EBT' || titleContent?.iconInfo?.icon === 'ebook') && (titleContent?.BookLength && titleContent?.BookLength !='0')">
                                {{titleContent.BookLength}} Pages
                            </div>
                        </div>
                    </div>
                </a>
                <mat-card-actions class="kz-list-group">
                    <ng-container *ngIf="titleContent?.titleStateInfo">
                        <axis360-title-actions [bookDetail]="titleContent" [isProgressCalculate]="false"
                            [durationSecClass]="'remaining-hours remaining-hours-list'"
                            [isAriaLabel]="titleAriaLabelActionsCTA(titleContent)"
                            [dueDurationClass]="'kz-time-remain-lbl'" [kzCardBtnClass]="'kz-card-btn kz-card-btn-list'"
                            [kzActionMenuItemClass]="'kz-my-stuff-list-option'"
                            [expandSecClass]="'more-menu more-menu-list'"
                            (click)="$event.stopPropagation();$event.preventDefault()"
                            (keyup.enter)="$event.stopPropagation();$event.preventDefault()" [isMyStuff]="true"
                            [isHistory]="activeMyStuff === 'history'" (titleActionEvent)="titleActionEvent($event)"
                            [activeMyStuff]="activeMyStuff">
                        </axis360-title-actions>
                    </ng-container>
                </mat-card-actions>

            </mat-card>
        </ng-container>
        <ng-container
            *ngIf="(titleContent.FormatType === 'VID' || titleContent.FormatType === 'VBK') && layoutComponentsList && titleContent?.titleStateInfo">
            <axis360-third-party-cards [componentData]="titleContent" [parentRoute]="parentRoute"
                [vendorCode]="(titleContent.VendorId ? titleContent.VendorId : (titleContent.FormatType === 'VID'?'VIDEOS':'VBOOKS'))"
                [componentTemplate]="layoutComponentsList.jsonTempl"
                [thirdPartyClass]=" titleContent.displayStatusCss + ' third-party-card-common third-party-card-checkout-videos third-party-card-mystuff-videos'"
                [myStuffThirdParty]="'my-stuff'" [activeMyStuff]="myStuffService.activeMyStuff">
            </axis360-third-party-cards>
        </ng-container>
    </ng-container>
</div>