import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PressReaderCardComponent } from '@pressreader/shared/components/press-reader-card/press-reader-card.component';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';

@Component({
  selector: 'axis360-newspaper-magazine-card',
  templateUrl: './newspaper-magazine-card.component.html',
  styleUrls: ['./newspaper-magazine-card.component.scss']
})
export class NewspaperMagazineCardComponent extends PressReaderCardComponent {

  constructor(
    public pressReaderService: PressReaderService,
    public router: Router,
    public pressReaderAnalyticsService: PressReaderAnalyticsService
  ) { super(pressReaderService, router, pressReaderAnalyticsService); }

}
