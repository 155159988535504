<mat-accordion class="kz-checkbox-sec refiners-accordion" [multi]="true">
    <mat-expansion-panel class="refiners-expansion-panel category" [expanded]="true" (closed)="setState()"
        (opened)="setState()">
        <mat-expansion-panel-header class="refiners-panel-header">
            <mat-panel-title class="heading-3">
                <div class="content-assign content-read">
                    <div class="content-name-head">
                        <h2 class="refiners-title single-ellipsis">
                            Format
                        </h2>
                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordion-content category-items">
            <ul class="main-menu">
                <li [id]="'loc_'+ removeSpaces(category.value)" *ngFor="let category of categoryType; index as i">
                    <a *ngIf="category.enabled" class="menuitem" role="button"
                        attr.aria-label="{{searchService.selectedCategory === category.value ? 'selected ' + category.text : category.text }}"
                        [ngClass]="{'active': searchService.selectedCategory === category.value, 'category-text': category.text === 'eBooks' || category.text === 'eAudios' ||  category.text === 'vBooks'}"
                        href="javascript:void(0)" (click)="showActive(category)">
                        {{category.text}}
                    </a>
                </li>
            </ul>
        </div>
    </mat-expansion-panel>
    <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
</mat-accordion>