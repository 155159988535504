import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
export interface AlertDialog {
  panelClass?: string;
  ariaDescribedBy?: string;
  heading?: string;
  content: string;
  submitBtnText?: string;
  searchBtnText?: string;
  searchBtnFunc?: () => void;
  searchOwnBtnFunc?: () => void;
  searchOwnBtnText?: string;
  type?: string;
  submitBtnFunc?: () => void;
  cancelBtnText?: string;
  cancelBtnFunc?: () => void;
  closeIcon?: boolean;
  buttonText?: string;
  isHold?: boolean;
  link?: string;
}
@Component({
  selector: 'axis360-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
  dialogHeadingLabel: string;
  isDialogHeading: boolean;
  dialogHeadingId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public modaldata: AlertDialog,
    public alertDialog: MatDialogRef<AlertDialogComponent>,
    private kzProgramsService?: KzProgramsService,
    public liveAnnouncer?: LiveAnnouncer
  ) {
    alertDialog.backdropClick().subscribe(() => {
      this.cancelClick();
    })
  }

  isDialogHeadingLabel() {
    this.kzProgramsService.thirdPartyDialog.subscribe(label => {
      if (label) {
        this.dialogHeadingLabel = label;
        this.dialogHeadingId = label + '_text';
        return true;
      }
    });
  }

  submitClick() {
    setTimeout(() => {
      this.alertDialog.close();
      this.modaldata?.submitBtnFunc();
    }, 0);
  }
  searchBtnClick() {
    this.alertDialog.close();
    this.modaldata.searchBtnFunc();
  }
  searchOwnBtnClick() {
    this.alertDialog.close();
    this.modaldata.searchOwnBtnFunc();
  }
  cancelClick() {
    if (this.modaldata.cancelBtnFunc) {
      this.modaldata.cancelBtnFunc();
    }
    this.alertDialog.close();
  }
}
