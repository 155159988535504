<div class="page-title">
    <div>
        <h1 class="main-title" *ngIf="!hideNewsStands">{{titleText}}</h1>
        <p class="sub-title" *ngIf="!hideText">{{resultsCount}} results for ‘{{searchTerm}}’</p>
    </div>
    <div class="sort-and-filter" *ngIf="!hideRefiners">
        <ng-container *ngIf="showSort">
            <button id="sortBtn" (click)="openRefinersPopup(REFINER_TYPE.SORT)" type="button" aria-label="Sort"
                cdkOverlayOrigin #triggerSort="cdkOverlayOrigin" [attr.aria-expanded]="isSortOpen"><span
                    class="refiner-label">Sort</span>
                <span class="refinerIcon">
                    <mat-icon svgIcon="sorticon"></mat-icon>
                </span>
            </button>
            <ng-template [cdkConnectedOverlayHasBackdrop]="true" cdkConnectedOverlayPanelClass="refiner-panel"
                cdkConnectedOverlayBackdropClass="refiner-back-drop" (backdropClick)="isSortOpen = false"
                cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerSort" [cdkConnectedOverlayOpen]="isSortOpen"
                (detach)="overlayDetached()" (overlayKeydown)=closeRefinersPopup($event,REFINER_TYPE.SORT)>
                <axis360-refiners [availableLimiters]="availableLimiters" [isSort]="true" [sortOptions]="sortOptions"
                    (applySortEvent)="applySortEvent($event)" cdkTrapFocus cdkTrapFocusAutoCapture="true">
                </axis360-refiners>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="showFilter">
            <button aria-label="Filter" id="filterBtn" (click)="openRefinersPopup(REFINER_TYPE.FILTER)" type="button"
                cdkOverlayOrigin #trigger="cdkOverlayOrigin" [attr.aria-expanded]="isFilterOpen">
                <span class="refiner-label">Filter</span>
                <span class="refinerIcon refinerIcon-color">
                    <mat-icon svgIcon="filtericon"></mat-icon>
                </span>
            </button>
            <ng-template [cdkConnectedOverlayHasBackdrop]="true" cdkConnectedOverlayBackdropClass="refiner-back-drop"
                (backdropClick)="isFilterOpen = false" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayOpen]="isFilterOpen" (detach)="overlayDetached()"
                (overlayKeydown)=closeRefinersPopup($event,REFINER_TYPE.FILTER)>
                <axis360-refiners [availableLimiters]="availableLimiters" [isSort]="false" [sortOptions]="sortOptions"
                    (applyFilterEvent)="applyFilterEvent($event)" (clearFilterEvent)="clearFilterEvent($event)"
                    cdkTrapFocus cdkTrapFocusAutoCapture="true" [showCount]="showCount"></axis360-refiners>
            </ng-template>
        </ng-container>
    </div>
</div>