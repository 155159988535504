<div class="kz-learning-activity third-party-widgets-carousel">
    <div class="kz-learning-head">
        <h2 class="learning-heading">{{vendorDetails.display_name}}</h2>
        <p class="primary-para five-ellipsis" axis360EllipsisTitle>{{vendorDetails.description}}</p>
    </div>
    <div class="kz-carousel kz-category-carousel">
        <carousel axis360IvyCarousel
            *ngIf="vendorDetails.dataResponse && vendorDetails.dataResponse[0]?.categories?.length" [dots]="false"
            (click)="trackCarousel($event);$event.stopPropagation();"
            (keydown.enter)="trackCarousel($event);$event.stopPropagation();" class="goals-learning-carousel"
            [margin]="0" [cellWidth]="112" [height]="160">
            <div class="carousel-cell" *ngFor="let category of vendorDetails.dataResponse[0].categories; let i = index"
                role="listitem">
                <axis360-third-party-categories (acOnExecuteAction)="acOnExecuteAction($event)"
                    [componentData]="category" [categoryClass]="'third-party-video-widgets-category'"
                    [componentTemplate]="vendorDetails.jsonTempl">
                </axis360-third-party-categories>
            </div>
        </carousel>
    </div>
</div>