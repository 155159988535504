export const RECORDS_COUNT = 10;
export const HOLD_POSITION_TOOLTIP = "You may move forward or backward in the queue as other patrons suspend and reactivate their hold. You will receive an e-mail when it is available for checkout.";
export const CHECKOUT_LIMIT = "The library has reached its daily checkout limit.  Please add items to your Wishlist and return later to check out.";
export const REVIEW_CONTENT_MOCK = "Magical doesn’t begin to describe this place! Most of the books were amazing. Allyison was good. Magical doesn’t begin to describe this place! Most of the books were amazing. Allyison was good. Magical doesn’t begin to describe this place! Most of the books were amazing. Allyison was good. Magical doesn’t begin to describe this place! Most of the books were amazing. Allyison was good."
export const TITLE_DETAILS_MOCK = {
  bookModel : {
    id: '9781610580595',
    title: ' Hearty Vegan Meals for Monster Appetites',
    author: ['Steen, Celine ', ' Newman, Joni Marie'],
    narrator: [''],
    publicationDate: '2011-07-01T00:00:00Z',
    series: '',
    synopsis:
      "Reluctant bank robber Jack Burn is on the run after a heist in the United States that left three million dollars missing and one cop dead. Hiding out in Cape Town, South Africa, he is desperate to build a new life for his pregnant wife and young son. But on a tranquil evening in their new suburban neighborhood, they are the victims of a random gangland break-in. Benny Mongrel, an ex-con night watchman, knows who went into Burn's house, and what the American did to them. Burn's actions soon trap them both in a cat-and-mouse game with Rudi 'Gatsby' Barnard, a corrupt Afrikaner cop. Once Gatsby smells those missing American millions, the men are drawn into a web of murder and vengeance that builds to an unforgettable conclusion",
    publisher: 'Fair Winds Pr',
    languageDesc: 'ENGLISH',
    audience: 'General Adult',
    subject: 'COOKING/Vegetarian',
    subjectlist: {
      "subject-1": {
        "subject": "FICTION",
        "term": "FICTION"
      },
      "subject-2": {
        "subject": "Fantasy",
        "term": "FICTION/Fantasy"
      },
      "subject-3": {
        "subject": "Epic",
        "term": "FICTION/Fantasy/Epic"
      },
      "subject-4": {
        "subject": "JUVENILE FICTION",
        "term": "JUVENILE FICTION"
      },
      "subject-5": {
        "subject": "Fantasy & Magic",
        "term": "JUVENILE FICTION/Fantasy & Magic"
      },
      "subject-6": {
        "subject": "Science Fiction",
        "term": "JUVENILE FICTION/Science Fiction"
      },
      "subject-7": {
        "subject": "General",
        "term": "JUVENILE FICTION/Science Fiction/General"
      }
    },
    attribute: '',
    axisAttribute: [''],
    axisAttributeList: [{ attribute: "" }],
    btKeys: ['0012324807'],
    genre: 'nonfiction',
    formatType: 'XPS',
    itemId: '0012324807',
    isRecommendable: false,
    collectionType: 'Default',
    edition: '',
    length: '8 hours 06 minutes',
    showAudioFormatFields: false,
    returnedActionFromSSO: 'NA'
  },
  titleAvailabilityInfo: {
    availability: {
      availableCopies: 9999,
      availableCopiesSpecified: true,
      availableFormats: 'FND',
      belongsToLibrary: true,
      belongsToLibrarySpecified: true,
      collectionType: 'REG',
      estimatedWaitTime: 0,
      estimatedWaitTimeSpecified: true,
      holdPosition: 0,
      holdPositionSpecified: true,
      isInHoldQueue: false,
      isInHoldQueueSpecified: true,
      isRTV: true,
      isRTVSpecified: true,
      numOfPatronsOnHold: 0,
      numOfPatronsOnHoldSpecified: true,
      totalQuantity: 9999,
      totalQuantitySpecified: true
    },
    availableFormatString: 'Axis 360 eAudio',
    actions: [
      {
        actionButtonId: 'btnCheckoutNow',
        itemId: '0012377424',
        isbn: '9781615735273',
        buyNowUrl: null,
        reservationId: null,
        formatType: 'ABT',
        action: 0,
        enabled: true
      },
      {
        actionButtonId: 'btnAddWishlist',
        itemId: '0012377424',
        isbn: null,
        buyNowUrl: null,
        reservationId: null,
        formatType: 'ABT',
        action: 13,
        enabled: true
      }
    ],
    response: null,
    holdsAllowed: true,
    consortiaType: 'MEMBER',
    ppcBudgetReached: false,
    titleCheckoutInfo: null
  }
};
export const TITLE_DETAILS_MOCK02 = {
  id: '9780345463753',
  title: ' Bad Love',
  author: ['Kellerman, Jonathan'],
  narrator: [''],
  publicationDate: '2003-04-01T00:00:00Z',
  series: 'Alex Delaware',
  synopsis: '',
  publisher: 'Ballantine Books',
  languageDesc: 'ENGLISH',
  audience: 'General Adult',
  subject: 'FICTION/Mystery & Detective/General; FICTION/Thrillers/Suspense; FICTION/Thrillers/General',
  subjectlist: {
    "subject-1": {
      "subject": "FICTION",
      "term": "FICTION"
    },
    "subject-2": {
      "subject": "Fantasy",
      "term": "FICTION/Fantasy"
    },
    "subject-3": {
      "subject": "Epic",
      "term": "FICTION/Fantasy/Epic"
    },
    "subject-4": {
      "subject": "JUVENILE FICTION",
      "term": "JUVENILE FICTION"
    },
    "subject-5": {
      "subject": "Fantasy & Magic",
      "term": "JUVENILE FICTION/Fantasy & Magic"
    },
    "subject-6": {
      "subject": "Science Fiction",
      "term": "JUVENILE FICTION/Science Fiction"
    },
    "subject-7": {
      "subject": "General",
      "term": "JUVENILE FICTION/Science Fiction/General"
    }
  },
  attribute: 'Text to Speech Enabled',
  axisAttribute: [''],
  axisAttributeList: [{ attribute: "" }],
  btKeys: ['0012441918', '0012282223'],
  genre: 'fiction',
  formatType: 'XPS',
  itemId: '0012282223',
  isRecommendable: false,
  collectionType: 'Default',
  edition: '',
  length: '8 hours 06 minutes',
  showAudioFormatFields: false,
  returnedActionFromSSO: 'NA'
};
export const TITLE_REVIEWS_MOCK = {
  avgStarRating: 4.0,
  reviews: [
    {
      content: 'this review is a review. (bpl)',
      reviewDate: '2017-02-13T20:00:42.31Z',
      userName: 'bee pee ell',
      rating: 4
    }
  ]
};

export const TITLE_REVIEW_MOCK = {
  content: 'this review is a review. (bpl)',
  reviewDate: '2017-02-13T20:00:42.31Z',
  userName: 'bee pee ell',
  rating: 4
};

export const PATRON_REVIEW_DETAILS_MOCK = {
  msg: 'Your review has been submitted to your library for approval.',
  patronHasAlreadyReviewed: false,
  selfReview: {
    content: null,
    reviewDate: '0001-01-01T00:00:00',
    userName: null,
    rating: 0
  }
};

export const REVIEW_BUTTON_DETAILS_MOCK = {
  availability: {
    availableCopies: 1,
    availableCopiesSpecified: true,
    availableFormats: 'FND',
    belongsToLibrary: true,
    belongsToLibrarySpecified: true,
    collectionType: 'REG',
    estimatedWaitTime: 0,
    estimatedWaitTimeSpecified: true,
    holdPosition: 0,
    holdPositionSpecified: true,
    isInHoldQueue: false,
    isInHoldQueueSpecified: true,
    isRTV: true,
    isRTVSpecified: true,
    numOfPatronsOnHold: 0,
    numOfPatronsOnHoldSpecified: true,
    totalQuantity: 1,
    totalQuantitySpecified: true
  },
  availableFormatString: 'Axis 360 eAudio',
  actions: [
    {
      actionButtonId: 'btnCheckoutNow',
      itemId: '0012377424',
      isbn: '9781615735273',
      buyNowUrl: null,
      reservationId: null,
      formatType: 'ABT',
      action: 0,
      enabled: true
    },
    {
      actionButtonId: 'btnAddWishlist',
      itemId: '0012377424',
      isbn: null,
      buyNowUrl: null,
      reservationId: null,
      formatType: 'ABT',
      action: 13,
      enabled: true
    }
  ],
  response: null,
  holdsAllowed: true,
  consortiaType: 'MEMBER',
  ppcBudgetReached: false,
  titleCheckoutInfo: null
};

export const REVIEW_BUTTON_DETAILS_MOCK_01 = {
  availability: {
    availableCopies: 9999,
    availableCopiesSpecified: true,
    availableFormats: 'FND',
    belongsToLibrary: true,
    belongsToLibrarySpecified: true,
    collectionType: 'REG',
    estimatedWaitTime: 0,
    estimatedWaitTimeSpecified: true,
    holdPosition: 0,
    holdPositionSpecified: true,
    isInHoldQueue: false,
    isInHoldQueueSpecified: true,
    isRTV: true,
    isRTVSpecified: true,
    numOfPatronsOnHold: 0,
    numOfPatronsOnHoldSpecified: true,
    totalQuantity: 1,
    totalQuantitySpecified: true
  },
  availableFormatString: 'Axis 360 eAudio',
  actions: [
    {
      actionButtonId: 'btnCheckoutNow',
      itemId: '0012377424',
      isbn: '9781615735273',
      buyNowUrl: null,
      reservationId: null,
      formatType: 'ABT',
      action: 0,
      enabled: true
    },
    {
      actionButtonId: 'btnAddWishlist',
      itemId: '0012377424',
      isbn: null,
      buyNowUrl: null,
      reservationId: null,
      formatType: 'ABT',
      action: 13,
      enabled: true
    }
  ],
  response: null,
  holdsAllowed: true,
  consortiaType: 'MEMBER',
  ppcBudgetReached: false,
  titleCheckoutInfo: null
};

export const REVIEW_BUTTON_DETAILS_MOCK_02 = {
  availability: undefined,
  availableFormatString: 'Axis 360 eAudio',
  actions: undefined,
  response: null,
  holdsAllowed: true,
  consortiaType: 'MEMBER',
  ppcBudgetReached: false,
  titleCheckoutInfo: null
};

export const BOOKS_DETAILS_MOCK_2 = {
  items: []
};

export const BOOKS_DETAILS_MOCK = {
  items: [
    {
      ISBN: '9781498592253',
      isbn: '9781498592253',
      ItemId: '0012471500',
      itemId: '0012471500',
      LibraryId: '57C86A8B-E3B9-E011-9A12-6431504A306A',
      ListId: '12121',
      title: 'Test TItle',
      author: 'Kalpagam, U. ',
      Narrator: [''],
      FormatType: 'EBT',
      formatType: 'EBT',
      IsAvailable: true,
      isAvailable: true,
      IsRTV: false,
      isRTV: false,
      IsRecommendable: true,
      isRecommendable: true,
      TotalQuantity: 10,
      OnOrderQuantity: 10,
      RTVFormats: 'XPS,EPB,AXS',
      ActiveHolds: 1,
      ListName: null,
      SortField: '',
      CollectionType: null,
      Edition: null,
      Length: null,
      imageUrl: 'https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781498592253/Medium/Logo',
      titleStateInfo: null,
      libraryId: null,
      listId: null,
      narrator: null,
      totalQuantity: null,
      onOrderQuantity: null,
      rtvFormats: null,
      activeHolds: null,
      listName: null,
      sortField: null,
      collectionType: null,
      edition: null,
      length: null
    },
    {
      ISBN: '9783298592253',
      isbn: '9781198592253',
      ItemId: '0022471500',
      itemId: '0022471500',
      LibraryId: '53C86A8B-E3B9-E011-9A12-6431504A306A',
      ListId: '12131',
      title: 'Test T3Itle',
      author: 'Kalpagam, U. ',
      Narrator: [''],
      FormatType: 'EBT',
      formatType: 'EBT',
      IsAvailable: true,
      isAvailable: true,
      IsRTV: false,
      isRTV: false,
      IsRecommendable: true,
      isRecommendable: true,
      TotalQuantity: 10,
      OnOrderQuantity: 10,
      RTVFormats: 'XPS,EPB,AXS',
      ActiveHolds: 1,
      ListName: null,
      SortField: '',
      CollectionType: null,
      Edition: null,
      Length: null,
      imageUrl: 'https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781498592253/Medium/Logo',
      titleStateInfo: null,
      libraryId: null,
      listId: null,
      narrator: null,
      totalQuantity: null,
      onOrderQuantity: null,
      rtvFormats: null,
      activeHolds: null,
      listName: null,
      sortField: null,
      collectionType: null,
      edition: null,
      length: null
    }
  ],
  Items: [
    {
      ISBN: '9781498592253',
      isbn: '9781498592253',
      ItemId: '0012471500',
      itemId: '0012471500',
      LibraryId: '57C86A8B-E3B9-E011-9A12-6431504A306A',
      ListId: '12121',
      title: 'Test TItle',
      author: 'Kalpagam, U. ',
      Narrator: [''],
      FormatType: 'EBT',
      formatType: 'EBT',
      IsAvailable: true,
      isAvailable: true,
      IsRTV: false,
      isRTV: false,
      IsRecommendable: true,
      isRecommendable: true,
      TotalQuantity: 10,
      OnOrderQuantity: 10,
      RTVFormats: 'XPS,EPB,AXS',
      ActiveHolds: 1,
      ListName: null,
      SortField: '',
      CollectionType: null,
      Edition: null,
      Length: null,
      imageUrl: 'https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781498592253/Medium/Logo',
      titleStateInfo: null,
      libraryId: null,
      listId: null,
      narrator: null,
      totalQuantity: null,
      onOrderQuantity: null,
      rtvFormats: null,
      activeHolds: null,
      listName: null,
      sortField: null,
      collectionType: null,
      edition: null,
      length: null
    },
    {
      ISBN: '9783298592253',
      isbn: '9781198592253',
      ItemId: '0022471500',
      itemId: '0022471500',
      LibraryId: '53C86A8B-E3B9-E011-9A12-6431504A306A',
      ListId: '12131',
      title: 'Test T3Itle',
      author: 'Kalpagam, U. ',
      Narrator: [''],
      FormatType: 'EBT',
      formatType: 'EBT',
      IsAvailable: true,
      isAvailable: true,
      IsRTV: false,
      isRTV: false,
      IsRecommendable: true,
      isRecommendable: true,
      TotalQuantity: 10,
      OnOrderQuantity: 10,
      RTVFormats: 'XPS,EPB,AXS',
      ActiveHolds: 1,
      ListName: null,
      SortField: '',
      CollectionType: null,
      Edition: null,
      Length: null,
      imageUrl: 'https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781498592253/Medium/Logo',
      titleStateInfo: null,
      libraryId: null,
      listId: null,
      narrator: null,
      totalQuantity: null,
      onOrderQuantity: null,
      rtvFormats: null,
      activeHolds: null,
      listName: null,
      sortField: null,
      collectionType: null,
      edition: null,
      length: null
    }
  ]
};

export const TITLE_DETAILS_II_MOCK = {
  title: 'titlename1',
  languageDesc: 'ENG',
  publicationDate: '01-01-2001',
  ageRange: { name: '', age: '', age_range: '' },
  length: '8 hours 06 minutes',
  runTime: '8 hours 06 minutes',
  formatType: { icon: '', alt: '' },
  formatTypeName: 'E Book',
  reviewsCount: 4,
  avgStarRating: 4,
  userAvgStarRating: 0,
  axisAttribute: [""],
  axisAttributeList: [{ attribute: '' }],
  axisAttributeIndex: 1,
  patronReviewsEnabled: true,
  subjectName: 'pets'
};

export const TITLE_DETAILS_II_MOCK_01 = {
  title: 'titlename1',
  languageDesc: 'ENG',
  publicationDate: '01-01-2001',
  ageRange: { name: '', age: '', age_range: '' },
  length: '8 hours 06 minutes',
  formatType: { icon: '', alt: '' },
  formatTypeName: 'E Book',
  reviewsCount: 4,
  avgStarRating: 4,
  userAvgStarRating: null,
  axisAttribute: [""],
  axisAttributeList: [{ attribute: '' }],
  axisAttributeIndex: 1,
  patronReviewsEnabled: true,
  subjectName: 'pets'
};

export const TITLE_DETAILS_II_MOCK_02 = {
  title: 'titlename1',
  languageDesc: 'ENG',
  publicationDate: '01-01-2001',
  ageRange: { name: '', age: '', age_range: '' },
  length: '8 hours 06 minutes',
  formatType: { icon: '', alt: '' },
  formatTypeName: 'E Book',
  reviewsCount: 4,
  avgStarRating: 4,
  userAvgStarRating: 3,
  axisAttribute: [""],
  axisAttributeList: [{ attribute: '' }],
  axisAttributeIndex: 1,
  patronReviewsEnabled: true,
  subjectName: 'pets'
};

export const GET_BOOKS_DETAILS_MOCK = {
  ItemId: '0012282223',
  isbn: '9780345463753',
  author: ['author1', 'author2'],
  narrator: ['narrator 1', 'narrator 2'],
  title: 'titlename1'
};

export const TITLE_DETAILS_MORE = {
  languageDesc: 'ENGLISH',
  publicationDate: '2012-01-01T00:00:00Z',
  ageRange: {
    name: 'Adult',
    age: '18+'
  },
  length: '8 hours 06 minutes',
  formatType: {
    icon: 'kz-audio',
    alt: 'Axis 360 eAudio'
  },
  formatTypeName: 'audiobook',
  reviewsCount: 1,
  avgStarRating: 5
};

export const TITLE_DETAILS_ID_MOCK = {
  ItemId: '0012377424',
  isbn: '9781615735273',
  author: ['Smith, Roger'],
  narrator: ['Lee, John'],
  title: ' Mixed Blood'
};

export const RECOMMENDED_TITLE_DETAILS = [
  {
    isbn: '9780307744678',
    title: 'The Civil War',
    className: 'col-xs-1 col-sm-1',
    itemId: '0012279282'
  },
  {
    isbn: '9780307744692',
    title: 'The Civil War',
    className: 'col-xs-1 col-sm-1',
    itemId: '0012279284'
  }
];

export const TITLE_DETAILS_ACTIONS = {
  titleButtonDetails: {
    availability: {
      availableCopies: 9999,
      availableCopiesSpecified: true,
      availableFormats: 'FND',
      belongsToLibrary: true,
      belongsToLibrarySpecified: true,
      collectionType: 'REG',
      estimatedWaitTime: 0,
      estimatedWaitTimeSpecified: true,
      holdPosition: 0,
      holdPositionSpecified: true,
      isInHoldQueue: false,
      isInHoldQueueSpecified: true,
      isRTV: true,
      isRTVSpecified: true,
      numOfPatronsOnHold: 0,
      numOfPatronsOnHoldSpecified: true,
      totalQuantity: 9999,
      totalQuantitySpecified: true
    },
    availableFormatString: 'Axis 360 eAudio',
    actions: [
      {
        actionButtonId: 'btnCheckoutNow',
        itemId: '0012377424',
        isbn: '9781615735273',
        buyNowUrl: null,
        reservationId: null,
        formatType: 'ABT',
        action: 0,
        enabled: true
      },
      {
        actionButtonId: 'btnAddWishlist',
        itemId: '0012377424',
        isbn: null,
        buyNowUrl: null,
        reservationId: null,
        formatType: 'ABT',
        action: 13,
        enabled: true
      }
    ],
    response: null,
    holdsAllowed: true,
    consortiaType: 'MEMBER',
    ppcBudgetReached: false,
    titleCheckoutInfo: null
  },
  titleDetails: {
    id: '9781615735273',
    title: ' Mixed Blood',
    author: [
      'Smith, Roger'
    ],
    narrator: [
      'Lee, John'
    ],
    publicationDate: '2012-01-01T00:00:00Z',
    series: '',
    synopsis: "Reluctant bank robber Jack Burn is on the run after a heist in the United States that left three million dollars missing and one cop dead. Hiding out in Cape Town, South Africa, he is desperate to build a new life for his pregnant wife and young son. But on a tranquil evening in their new suburban neighborhood, they are the victims of a random gangland break-in.  Benny Mongrel, an ex-con night watchman, knows who went into Burn's house, and what the American did to them.  Burn's actions soon trap them both in a cat-and-mouse game with Rudi \"Gatsby\" Barnard, a corrupt Afrikaner cop. Once Gatsby smells those missing American millions, the men are drawn into a web of murder and vengeance that builds to an unforgettable conclusion.",
    publisher: 'Highbridge Co',
    languageDesc: 'ENGLISH',
    audience: 'General Adult',
    subject: 'FICTION/Mystery & Detective/General',
    subjectlist: {
      "subject-1": {
        "subject": "FICTION",
        "term": "FICTION"
      },
      "subject-2": {
        "subject": "Fantasy",
        "term": "FICTION/Fantasy"
      },
      "subject-3": {
        "subject": "Epic",
        "term": "FICTION/Fantasy/Epic"
      },
      "subject-4": {
        "subject": "JUVENILE FICTION",
        "term": "JUVENILE FICTION"
      },
      "subject-5": {
        "subject": "Fantasy & Magic",
        "term": "JUVENILE FICTION/Fantasy & Magic"
      },
      "subject-6": {
        "subject": "Science Fiction",
        "term": "JUVENILE FICTION/Science Fiction"
      },
      "subject-7": {
        "subject": "General",
        "term": "JUVENILE FICTION/Science Fiction/General"
      }
    },
    attribute: '',
    axisAttribute: [
      ''
    ],
    axisAttributeList: [{ attribute: "" }],
    btKeys: [
      '0012377424'
    ],
    genre: 'fiction',
    formatType: 'EBT',
    itemId: '0012377424',
    isRecommendable: false,
    collectionType: 'Default',
    edition: 'Unabridged',
    fileSize: 0,
    length: '8 hours 06 minutes',
    showAudioFormatFields: true,
    returnedActionFromSSO: 'NA'
  }
}

export const RECOMMENDED_TITLE_DETAILS_MOCK = [
  {
    isbn: "9780345535672",
    title: " Star Wars the Old Republic",
    className: "col-xs-1 col-sm-1",
    itemId: "0012384075"
  },
  {
    isbn: "9780385361835",
    title: " Path of Destruction",
    className: "col-xs-1 col-sm-1",
    itemId: "0012407546"
  },
  {
    isbn: "9780440609513",
    title: " Hard Time",
    className: "col-xs-1 col-sm-1",
    itemId: "0012274891"
  },
  {
    isbn: "9781094314099",
    title: " Face of Madness",
    className: "col-xs-1 col-sm-1",
    itemId: "0012475185"
  },
  {
    isbn: "9781094313900",
    title: " Un Reino De Hierro",
    className: "hidden-xs col-sm-1",
    itemId: "0012475168"
  },
  {
    isbn: "9781094314815",
    title: " Een Gift Van Wapens",
    className: "hidden-xs hidden-sm",
    itemId: "0012475200"
  },
  {
    isbn: "9781094314686",
    title: " Weihnachten Für Immer",
    className: "hidden-xs hidden-sm",
    itemId: "0012475186"
  },
  {
    isbn: "9781094314822",
    title: " Герои в поисках приключений",
    className: "hidden-xs hidden-sm",
    itemId: "0012475201"
  },
  {
    isbn: "9781094314839",
    title: " Un Ciel Ensorcelé",
    className: "hidden-xs hidden-sm",
    itemId: "0012475202"
  }
];

export const SUBJECT_LIST_MOCK = {
  "subject-1": {
    "subject": "FICTION",
    "term": "FICTION"
  },
  "subject-2": {
    "subject": "Fantasy",
    "term": "FICTION/Fantasy"
  },
  "subject-3": {
    "subject": "Epic",
    "term": "FICTION/Fantasy/Epic"
  },
  "subject-4": {
    "subject": "JUVENILE FICTION",
    "term": "JUVENILE FICTION"
  },
  "subject-5": {
    "subject": "Fantasy & Magic",
    "term": "JUVENILE FICTION/Fantasy & Magic"
  },
  "subject-6": {
    "subject": "Science Fiction",
    "term": "JUVENILE FICTION/Science Fiction"
  },
  "subject-7": {
    "subject": "General",
    "term": "JUVENILE FICTION/Science Fiction/General"
  }
};

export const SUBJECT_LIST_MOCK_CONVERT_STRING = {
  "subject-1": {
    "subject": "fiction",
    "term": "FICTION"
  },
  "subject-2": {
    "subject": "fantasy",
    "term": "FICTION/Fantasy"
  },
  "subject-3": {
    "subject": "epic",
    "term": "FICTION/Fantasy/Epic"
  },
  "subject-4": {
    "subject": "juvenile fiction",
    "term": "JUVENILE FICTION"
  },
  "subject-5": {
    "subject": "fantasy & magic",
    "term": "JUVENILE FICTION/Fantasy & Magic"
  },
  "subject-6": {
    "subject": "science fiction",
    "term": "JUVENILE FICTION/Science Fiction"
  },
  "subject-7": {
    "subject": "general",
    "term": "JUVENILE FICTION/Science Fiction/General"
  }
};

export const SUBJECT_LIST_MOCK2 = [
  {
    "subject": "FICTION",
    "term": "FICTION"
  }, {
    "subject": "Fantasy",
    "term": "FICTION/Fantasy"
  }, {
    "subject": "Epic",
    "term": "FICTION/Fantasy/Epic"
  }, {
    "subject": "JUVENILE FICTION",
    "term": "JUVENILE FICTION"
  }, {
    "subject": "Fantasy & Magic",
    "term": "JUVENILE FICTION/Fantasy & Magic"
  }, {
    "subject": "Science Fiction",
    "term": "JUVENILE FICTION/Science Fiction"
  }, {
    "subject": "General",
    "term": "JUVENILE FICTION/Science Fiction/General"
  }
];

export const SUBJECT_LIST_MOCK3 = [
  {
    subject: "YOUNG ADULT NONFICTION",
    term: "YOUNG ADULT NONFICTION"
  },
  {
    subject: "Health & Daily Living",
    term: "YOUNG ADULT NONFICTION/Health & Daily Living"
  },
  {
    subject: "Diseases, Illnesses & Injuries",
    term: "YOUNG ADULT NONFICTION/Health & Daily Living/Diseases, Illnesses & Injuries"
  },
  {
    subject: "LGBTQ+",
    term: "YOUNG ADULT NONFICTION/LGBTQ+"
  }
];

export const SUBJECT_LIST_CONVERT_TO_LOWERCASE02 = [
  {
    subject: "young adult nonfiction",
    term: "YOUNG ADULT NONFICTION"
  },
  {
    subject: "health & daily living",
    term: "YOUNG ADULT NONFICTION/Health & Daily Living"
  },
  {
    subject: "diseases, illnesses & injuries",
    term: "YOUNG ADULT NONFICTION/Health & Daily Living/Diseases, Illnesses & Injuries"
  },
  {
    subject: "LGBTQ+",
    term: "YOUNG ADULT NONFICTION/LGBTQ+"
  }
];

export const SUBJECT_LIST_CONVERT_TO_LOWERCASE = [
  {
    subject: "fiction",
    term: "FICTION"
  },
  {
    subject: "fantasy",
    term: "FICTION/Fantasy"
  },
  {
    subject: "epic",
    term: "FICTION/Fantasy/Epic"
  },
  {
    subject: "juvenile fiction",
    term: "JUVENILE FICTION"
  },
  {
    subject: "fantasy & magic",
    term: "JUVENILE FICTION/Fantasy & Magic"
  },
  {
    subject: "science fiction",
    term: "JUVENILE FICTION/Science Fiction"
  },
  {
    subject: "general",
    term: "JUVENILE FICTION/Science Fiction/General"
  }
];

export const SERIES_LIST_MOCK = {
  searchBy: "series",
  term: "Vintage Civil War Library",
  collection: "",
  recommendationAllowed: true,
  refiner: [],
  refinerJson: null,
  categoryName: null,
  categoryId: null,
  staffPickId: null,
  isLibrarySubscribedToPPCCollection: true,
  isPPCOnlyLibrary: false,
  defaultSearchCollection: "General",
  showTabs: false,
  showNewsTab: true,
  showSitesTab: true,
  isRecommendationCategory: false,
  sortBy: 'Relevancy',
  availability: 'All'
}

export const SERIES_LIST = {
  searchBy: "series",
  term: "Vintage Civil War Library",
  collection: "",
  recommendationAllowed: true,
  refiner: [
    {
      text: "Sort By",
      value: "sortby",
      items: [
        {
          myParent: "sortby",
          text: "Return date",
          value: "Returndate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbReturnDate"
        },
        {
          myParent: "sortby",
          text: "Popularity",
          value: "Score",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPopularity"
        },
        {
          myParent: "sortby",
          text: "Publication Date",
          value: "PublishDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbPublicationDate"
        },
        {
          myParent: "sortby",
          text: "Added Date",
          value: "AddedDate",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAddedDate"
        },
        {
          myParent: "sortby",
          text: "Title",
          value: "Title",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitle"
        },
        {
          myParent: "sortby",
          text: "Author",
          value: "Author",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAuthor"
        },
        {
          myParent: "sortby",
          text: "Relevancy",
          value: "Relevancy",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbRelevancy"
        }
      ]
    },
    {
      text: "Collections",
      value: "collections",
      items: [
        {
          myParent: "collections",
          text: "General",
          value: "General",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbGeneral"
        },
        {
          myParent: "collections",
          text: "Always Available",
          value: "Always Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAlwaysAvailable"
        },
        {
          myParent: "collections",
          text: "Titles to Recommend",
          value: "Titles to Recommend",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbTitlesToRecommend"
        }
      ]
    },
    {
      text: "Availability",
      value: "availability",
      items: [
        {
          myParent: "availability",
          text: "All Titles",
          value: "All",
          controlType: "normal",
          defaultSelected: true,
          id: "rdbAllTitles"
        },
        {
          myParent: "availability",
          text: "Available Now",
          value: "Available",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbAvailableNow"
        }
      ]
    },
    {
      text: "Release Date",
      value: "releasedate",
      items: [
        {
          myParent: "releasedate",
          text: "Last 6 months",
          value: "6",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast6Months"
        },
        {
          myParent: "releasedate",
          text: "Last year",
          value: "12",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLastYear"
        },
        {
          myParent: "releasedate",
          text: "Over a year",
          value: "1",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbOver1Year"
        }
      ]
    },
    {
      text: "Recently Added",
      value: "recentlyadded",
      items: [
        {
          myParent: "recentlyadded",
          text: "Last 30 days",
          value: "30",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast30Days"
        },
        {
          myParent: "recentlyadded",
          text: "Last 60 days",
          value: "60",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast60Days"
        },
        {
          myParent: "recentlyadded",
          text: "Last 90 days",
          value: "90",
          controlType: "normal",
          defaultSelected: false,
          id: "rdbLast90Days"
        }
      ]
    }
  ],
  refinerJson: null,
  categoryName: null,
  categoryId: null,
  staffPickId: null,
  isLibrarySubscribedToPPCCollection: true,
  isPPCOnlyLibrary: false,
  defaultSearchCollection: "General",
  showTabs: false,
  showNewsTab: true,
  showSitesTab: true,
  isRecommendationCategory: false,
  sortBy: 'Relevancy',
  availability: 'All'
};

export const LIST_CONTENT_TITLE = {
  SearchTerm: "Vintage Civil War Library",
  SearchBy: "series",
  TotalItems: 3,
  PageNum: 1,
  PageSize: 24,
  SortField: "Relevancy",
  SortOrder: -1,
  Filter: {
    Available: "All",
    Format: "",
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: 0
  },
  Items: [
    {
      ISBN: "9780307744685",
      itemId: "0012279283",
      LibraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11",
      ListId: null,
      Title: "The Civil War",
      Author: "Foote, Shelby ",
      Narrator: null,
      FormatType: "EBT",
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 5,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: null,
      SortField: null,
      CollectionType: "Default",
      Edition: null,
      Length: null,
      AxisAttribute: [
        ""
      ],
      RunTime: null,
      FileSize: 0
    },
    {
      ISBN: "9780307744692",
      itemId: "0012279284",
      LibraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11",
      ListId: null,
      Title: "The Civil War",
      Author: "Foote, Shelby ",
      Narrator: null,
      FormatType: "EBT",
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 5,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: null,
      SortField: null,
      CollectionType: "Default",
      Edition: null,
      Length: null,
      AxisAttribute: [
        ""
      ],
      RunTime: null,
      FileSize: 0
    },
    {
      ISBN: "9780307744678",
      itemId: "0012279282",
      LibraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11",
      ListId: null,
      Title: "The Civil War",
      Author: "Foote, Shelby ",
      Narrator: null,
      FormatType: "EBT",
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 5,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: null,
      SortField: null,
      CollectionType: "Default",
      Edition: null,
      Length: null,
      AxisAttribute: [
        ""
      ],
      RunTime: null,
      FileSize: 0
    }
  ]
}

export const LIST_CONTENT_TITLE_MOCK = {
  SearchTerm: "Vintage Civil War Library",
  SearchBy: "series",
  TotalItems: 3,
  PageNum: 1,
  PageSize: 24,
  SortField: "Relevancy",
  SortOrder: -1,
  Filter: {
    Available: "All",
    Format: "",
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: null,
    AxisAttribute: null,
    ProfileType: 0
  },
  Items: []
}

export const ADD_RATING_RESPONSE = {
  averageRating: 3,
  totalRatingCount: 1,
  userReview: {
    relatedUserReviewID: null,
    title: null,
    id: "4cb5b5f1-130c-ed11-b47a-0050f26b09c4",
    isReviewApproved: "0",
    itemID: "0012377784",
    libraryID: "57C86A8B-E3B9-E011-9A12-6431504A306A",
    patronID: "e214f73d-fef5-4838-a192-896af146de1d",
    patronProfileId: "516B5CF2-2A42-4A44-B48D-32C38EA9E48E",
    rating: 3,
    ratingSpecified: true,
    reviewContent: "",
    reviewDate: {
      dateTime: "2022-07-25T12:18:52.75Z",
      offsetMinutes: 0
    },
    userName: "BTPhoton333"
  },
  otherUserReview: [
    {
      id: "29f9e7e0-20e5-e411-8349-6431504a306a",
      isReviewApproved: null,
      itemID: null,
      libraryID: null,
      patronID: "9ae4317f-366f-4ff7-9b57-ab7f05e65ae9",
      patronProfileId: null,
      rating: 5,
      ratingSpecified: true,
      reviewContent: "testing this review situation ",
      reviewDate: {
        dateTime: "2015-04-17T16:42:51.253Z",
        offsetMinutes: 0
      },
      userName: "Amber",
      more: false
    }
  ],
  submitted: true,
  ratingSubmitted: true,
  msg: ""
};
export const SUBMIT_REVIEW_RESPONSE = {
  message: '',
  patronRatingSubmitted: false,
  ratingReviewResponse:
  {
    averageRating: 3,
    totalRatingCount: 1,
    userReview: {
      relatedUserReviewID: null,
      title: null,
      id: "4cb5b5f1-130c-ed11-b47a-0050f26b09c4",
      isReviewApproved: "0",
      itemID: "0012377784",
      libraryID: "57C86A8B-E3B9-E011-9A12-6431504A306A",
      patronID: "e214f73d-fef5-4838-a192-896af146de1d",
      patronProfileId: "516B5CF2-2A42-4A44-B48D-32C38EA9E48E",
      rating: 3,
      ratingSpecified: true,
      reviewContent: "",
      reviewDate: {
        dateTime: "2022-07-25T12:18:52.75Z",
        offsetMinutes: 0
      },
      userName: "BTPhoton333"
    },
    otherUserReview: [
      {
        id: "29f9e7e0-20e5-e411-8349-6431504a306a",
        isReviewApproved: null,
        itemID: null,
        libraryID: null,
        patronID: "9ae4317f-366f-4ff7-9b57-ab7f05e65ae9",
        patronProfileId: null,
        rating: 5,
        ratingSpecified: true,
        reviewContent: "testing this review situation ",
        reviewDate: {
          dateTime: "2015-04-17T16:42:51.253Z",
          offsetMinutes: 0
        },
        userName: "Amber",
        more: false
      }
    ],
    submitted: true,
    ratingSubmitted: true,
    msg: ""
  }
}

export const ADD_RATING_RESPONSE_2 = {
  averageRating: 3,
  totalRatingCount: 1,
  userReview: {
    relatedUserReviewID: null,
    title: null,
    id: "4cb5b5f1-130c-ed11-b47a-0050f26b09c4",
    isReviewApproved: "1",
    itemID: "0012377784",
    libraryID: "57C86A8B-E3B9-E011-9A12-6431504A306A",
    patronID: "e214f73d-fef5-4838-a192-896af146de1d",
    patronProfileId: "516B5CF2-2A42-4A44-B48D-32C38EA9E48E",
    rating: 3,
    ratingSpecified: true,
    reviewContent: "",
    reviewDate: {
      dateTime: "2022-07-25T12:18:52.75Z",
      offsetMinutes: 0
    },
    userName: "BTPhoton333"
  },
  otherUserReview: [
    {
      id: "29f9e7e0-20e5-e411-8349-6431504a306a",
      isReviewApproved: null,
      itemID: null,
      libraryID: null,
      patronID: "9ae4317f-366f-4ff7-9b57-ab7f05e65ae9",
      patronProfileId: null,
      rating: 5,
      ratingSpecified: true,
      reviewContent: "testing this review situation ",
      reviewDate: {
        dateTime: "2015-04-17T16:42:51.253Z",
        offsetMinutes: 0
      },
      userName: "Amber",
      more: false
    }
  ],
  submitted: false,
  ratingSubmitted: false,
  msg: ""
};


export const ADD_RATING_RESPONSE_3 = {
  averageRating: 3,
  totalRatingCount: 1,
  userReview: null,
  otherUserReview: [
    {
      id: "29f9e7e0-20e5-e411-8349-6431504a306a",
      isReviewApproved: null,
      itemID: null,
      libraryID: null,
      patronID: "9ae4317f-366f-4ff7-9b57-ab7f05e65ae9",
      patronProfileId: null,
      rating: 5,
      ratingSpecified: true,
      reviewContent: "testing this review situation ",
      reviewDate: {
        dateTime: "2015-04-17T16:42:51.253Z",
        offsetMinutes: 0
      },
      userName: "Amber",
      more: false
    }
  ],
  submitted: false,
  ratingSubmitted: false,
  msg: ""
};

export const SUBMIT_REVIEW_RESPONSE_2 = {
  message: '',
  patronRatingSubmitted: false,
  ratingReviewResponse: ADD_RATING_RESPONSE_2
}
export const OTHER_USER_REVIEW = [
  {
    id: "29f9e7e0-20e5-e411-8349-6431504a306a",
    isReviewApproved: null,
    itemID: null,
    libraryID: null,
    patronID: "9ae4317f-366f-4ff7-9b57-ab7f05e65ae9",
    patronProfileId: null,
    rating: 5,
    ratingSpecified: true,
    reviewContent: "testing this review situation ",
    reviewDate: {
      dateTime: "2015-04-17T16:42:51.253Z",
      offsetMinutes: 0
    },
    userName: "Amber",
    more: false
  }
];

export const SUBMITTED_REVIEW_MOCK = {
  avgStarRating: 2,
  userAvgStartRating: 2,
  reviews: [],
  reviewLength: 0,
  profileType: 'Kid',
  submitted: true
};

export const SUBMITTED_REVIEW_MOCK_2 = {
  avgStarRating: 2,
  reviews: [],
  reviewLength: 0,
  profileType: 'Kid',
  submitted: false
};

export const READING_PROCESS = {
  bookProgress: [
    {
      bookId: "0012377424",
      progressPercentage: 0,
    }
  ],
  responseCode: 200
};

export const READING_PROCESS_ERROR = {
  bookProgress: [
    {
      bookId: "0012377424",
      progressPercentage: 0,
    }
  ],
  responseCode: 500
};

export const BOOKS_PROCESS = {
  bookId: "0012377424",
  progressPercentage: 0
};

export const CURRENT_PROFILE = {
  profileId: "516b5cf2-2a42-4a44-b48d-32c38ea9e48e",
  profileName: "BTPhoton333",
  profileTypeId: "1",
  profileType: "Adult",
  profileEmail: "Test12@photon.com",
  profileImage: "",
  isPrimary: "True",
  defaultLandingPageID: "2",
  displayCheckoutHistory: "False",
  enableTracking: "False",
  pinStatus: "True"
}
export const PATRON_DETAILS = {
  profile: null,
  libraryName: "Magic Independent Library",
  myStuff: {
      currentCheckoutsCount: 0,
      currenteBookCheckoutCount: 0,
      currenteAudiobookCheckoutCount: 0,
      currentHoldCount: 0,
      currentRecommendCount: 0,
      currentHistory: 0,
    currentWeeklyRecommend: 0,
    currentWishListCoun: 0,
    currentAssignmentsCoun: 0,
    checkoutLimi: 40,
    holdLimi: 20,
    recommendLimi: 10,
    eBookCheckoutLimi: 20,
    audiobookCheckoutLimi: 20
  },
  email: "",
  isAutoCheckout: false,
  checkoutHistory: false,
  enableTracking: false,
  securityQuestions: [
      {
          disabled: false,
          group: null,
          selected: false,
          text: "What is your favourite animal?",
          value: "6"
      },
      {
          disabled: false,
          group: null,
          selected: false,
          text: "What is your favourite color?",
          value: "7"
      },
      {
          disabled: false,
          group: null,
          selected: false,
          text: "What is your favourite dessert?",
          value: "8"
      },
      {
          disabled: false,
          group: null,
          selected: false,
          text: "What is your favourite holiday?",
          value: "9"
      },
      {
          disabled: false,
          group: null,
          selected: false,
          text: "What is your maternal grandmother's maiden name?",
          value: "4"
      }
  ],
  securityQuestion: 7,
  securityAnswer: "Blue",
  activeHold: 0,
  activeCheckout: 0,
  activeeBookCheckout: 0,
  activeeAudiobookCheckout: 0,
  remainingHold: 20,
  numberOfRecommendations: 0,
  pinProfileStatus: null
}

export const LEARNING_ACTIVITY_MOCK = {
  statusCode: 200,
  message: "Successfull",
  learningActivityIsbn: {
    totalCount: 3,
    pageNumber: 1,
    pageSize: 20,
    pageTotal: 1,
    status: "Success",
    statusCode: 200,
    data: [
      {
        id: "67",
        publisher: "Scholastic",
        activityType: "Activity",
        audience: "Kid",
        linkedTitleISBN: "9780545362689",
        linkedTitleID: "0012454416",
        linkedTitle: "I SURVIVED #1: I SURVIVED THE SINKING OF THE TITANIC, 1912",
        learningResourceName: "I Survived!: #1 I Survived the Sinking of the Titanic, 1912! Readers Theater Script",
        imageUrl: "https://i.prcdn.co/img?file=9zzw2022052600000000001001&page=1&width=360&retina=2",
        fileUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
        disabled: false
      },
      {
        id: "69",
        publisher: "Scholastic",
        activityType: "Activity",
        audience: "Kid",
        linkedTitleISBN: "9780545362689",
        linkedTitleID: "0012454416",
        linkedTitle: "I SURVIVED #1: I SURVIVED THE SINKING OF THE TITANIC, 1912",
        learningResourceName: "I Survived!: #1 I Survived the Sinking of the Titanic, 1912! Activity",
        imageUrl: "https://i.prcdn.co/img?file=9zzw2022052600000000001001&page=1&width=360&retina=2",
        fileUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
      },
      {
        id: "68",
        publisher: "Scholastic",
        activityType: "Educator Guide",
        audience: "Kid",
        linkedTitleISBN: "9780545362689",
        linkedTitleID: "0012454416",
        linkedTitle: "I SURVIVED #1: I SURVIVED THE SINKING OF THE TITANIC, 1912",
        learningResourceName: "I Survived!: #1 I Survived the Sinking of the Titanic, 1912! Teachers Guide",
        imageUrl: "https://i.prcdn.co/img?file=9zzw2022052600000000001001&page=1&width=360&retina=2",
        fileUrl: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
      }
    ],
    errors: null
  }
}

export const ALTERNATIVE_FORMAT_TITLE_MOCK = {
  statusCode: 200,
  message: "Successfull",
  alternateResultModel: {
    totalCount: 3,
    pageNumber: 1,
    pageSize: 20,
    pageTotal: 1,
    status: "Success",
    statusCode: 200,
    data: [
      {
        isbn: '9780307744678',
        FormatCode: "XPS",
        FormatType: "EBT",
        itemId: '0012279282'
      },
      {
        isbn: '9780307744692',
        FormatCode: "ABT",
        FormatType: "FND",
        itemId: '0012279284'
      }
    ],
    errors: null
  }
}

export const ALTERNATIVE_FORMAT_TITLE_ERROR_MOCK = {
  statusCode: 400,
  message: "Successfull",
  alternateResultModel: {
    totalCount: 3,
    pageNumber: 1,
    pageSize: 20,
    pageTotal: 1,
    status: "Success",
    statusCode: 200,
    data: [],
    errors: null
  }
}
export const LEARNING_ACTIVITY_ERROR_MOCK = {
  errorCode: "404",
  message: "Learning Activity not available",
  learningActivity: null
}

export const LEARNING_ACTIVITY_DATA_MOCK = {
  contentType: null,
  serializerSettings: null,
  statusCode: null,
  value: LEARNING_ACTIVITY_MOCK,
}


export const LEARNING_ACTIVITY_MOCK2 = {
  statusCode: 200,
  message: "Successfull",
  learningActivityIsbn: undefined
}

export const LEARNING_ACTIVITY_MOCK03 = {
  statusCode: 200,
  message: "Successfull",
  learningActivityIsbn: {
    totalCount: 3,
    pageNumber: 1,
    pageSize: 20,
    pageTotal: 1,
    status: "Success",
    statusCode: 200,
    data: [],
    errors: null
  }
}

export const LEARNING_ACTIVITY_MOCK_ERROR = {
  statusCode: 400,
  message: "Successfull",
  learningActivityIsbn: {
    totalCount: 3,
    pageNumber: 1,
    pageSize: 20,
    pageTotal: 1,
    status: "Success",
    statusCode: 200,
    data: [],
    errors: null
  }
}
export const LEARNING_ACTIVITY_DATA_MOCK2 = {
  contentType: null,
  serializerSettings: null,
  statusCode: null,
  value: LEARNING_ACTIVITY_MOCK2,
}

export const REVIEW_DIALOG_MOCK = {
  heading: "What do you think of this title?",
  content: "",
  submitBtnText: "Submit",
  closeIcon: true,
  panelClass: "kz-review-dialog",
  patronReviewDetails: {
    msg: "Your review has been submitted to your library for approval.",
    patronHasAlreadyReviewed: false,
    selfReview: {
      content: null,
      reviewDate: "0001-01-01T00:00:00",
      userName: null,
      rating: 0
    }
  }
};

export const TITLE_DETAILS_III_MOCK = {
  id: "9781785302732",
  title: "A  Sinner's Prayer",
  author: [
    "Wright, M. P."
  ],
  narrator: [
    ""
  ],
  publicationDate: "2019-07-18T00:00:00Z",
  series: "J. T. Ellington",
  synopsis: "",
  publisher: "Black & White Pub Ltd",
  languageDesc: "ENGLISH",
  audience: "General Adult",
  subject: "FICTION/Mystery & Detective/General; FICTION/Thrillers/Crime; FICTION/Crime",
  subjectList: {
    "subject-1": {
      subject: "FICTION",
      term: "FICTION"
    },
    "subject-2": {
      subject: "Mystery & Detective",
      term: "FICTION/Mystery & Detective"
    },
    "subject-3": {
      subject: "General",
      term: "FICTION/Mystery & Detective/General"
    },
    "subject-4": {
      subject: "Thrillers",
      term: "FICTION/Thrillers"
    },
    "subject-5": {
      subject: "Crime",
      term: "FICTION/Thrillers/Crime"
    },
    "subject-6": {
      subject: "Crime",
      term: "FICTION/Crime"
    }
  },
  attribute: "",
  axisAttribute: [
    "FXL",
    "eRead-Along"
  ],
  axisAttributeList: [
    {
      attribute: "FXL"
    },
    {
      attribute: "eRead-Along"
    }
  ],
  btKeys: [
    "0012471483",
    "0012471484"
  ],
  genre: "fiction",
  formatType: "XPS",
  itemId: "0012471484",
  isRecommendable: false,
  collectionType: "Default",
  edition: "",
  length: "NA",
  fileSize: 844619,
  showAudioFormatFields: false,
  returnedActionFromSSO: "NA",
  subTitle: "",
  shortTitle: "Sinners Prayer",
  createdDate: "2019-07-12T08:38:00Z",
  updatedDate: "2019-07-17T16:30:00Z",
  jacketImageInd: true,
  purchaseOption: "SUPO",
  language: "eng",
  readyToVend: "true",
  audienceCode: "",
  gradeLevel: "adult",
  bisacCode: [
    "FIC050000",
    " FIC031010",
    " FIC022000"
  ],
  format: "XPS",
  runTime: null,
  bookLength: "0",
  acoustikItemID: ""
};
export const TITLE_ACTIONS_POPUP_RESPONSE_MOCK = {
  checkoutTransactionID: null,
  expectedActionEndDate: null,
  checkoutDuration: 0,
  status: false,
  title: null,
  primaryStatusMessage: "Another profile under this account already has this item checked out. Please wait for them to return the title.",
  secondaryMessage: "Already added by another profile.",
  bottomMessage: null,
  actionButtonId: null,
  itemId: null,
  isbn: null
};

export const TITLE_ACTION_CONTENTS = {
  formatType: "EBT",
  showProgressBar: "btnCheckoutNow",
  userName: "",
  imageurl: "https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9780794424985/Medium/Logo",
  itemId: "0010086108",
  author: "Schade, Susan ",
  narrator: "",
  availableCopies: 2,
  isRTV: true,
  totalQuantity: 2,
  holdsAllowed: true,
  isInHoldQueue: false,
  holdPosition: 0,
  holdPositionFormat: "0th",
  consortiaType: "MEMBER",
  collectionType: "REG",
  ppcBudgetReached: false,
  enableTracking: false,
  titleCheckoutInfo: null,
  title: " Dinosaur Ed, Level 2"
};
export const TITLE_DETAILS_RATING = {
  relatedUserReviewID: null,
  title: 'listst',
  id: "4cb5b5f1-130c-ed11-b47a-0050f26b09c4",
  isReviewApproved: "0",
  itemID: "0012377784",
  libraryID: "57C86A8B-E3B9-E011-9A12-6431504A306A",
  patronID: "e214f73d-fef5-4838-a192-896af146de1d",
  patronProfileId: "516B5CF2-2A42-4A44-B48D-32C38EA9E48E",
  rating: 3,
  ratingSpecified: true,
  reviewContent: "",
  reviewDate: {
    dateTime: "2022-07-25T12:18:52.75Z",
    offsetMinutes: 0
  },
  userName: "BTPhoton333"
};

export const  LAYOUT_COMPONENTS_CAROUSEL = [
  {
    region: 1,
    title: "Titles in This Series",
    description: null,
    type: "Item_Carousel",
    subType: "Videos",
    vendor_details: null,
    hideSeeAllLink: false,
    seeAllLinkPage: null,
    handleInApp: false,
    defaultValues: null,
    dataApi: {
      url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartySearch/v1",
      method: "GET",
      headers: {
        api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
        authorization: "",
        "ocp-apim-subscription-key": "",
        patronid: "",
        profileid: "",
        thirdparty: "true"
      },
      body: null,
      pathParams: null,
      library_settings: {
        contentCollectionId: "collection"
      },
      search: {
        searhSort: "",
        sortOrder: "",
        pageNumber: "0",
        pageSize: "10",
        formatType: "Videobook",
        audienceDesc: "",
        collectionType: "thirdparty",
        contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
        searchType: "series",
        searchTerm: "",
        pubDateFilter: ""
      },
      app: {
        searhSort: "false",
        sortOrder: "false",
        pageNumber: "false",
        pageSize: "false",
        formatType: "false",
        audienceDesc: "false",
        collectionType: "false",
        contentCollectionId: "false",
        searchType: "false",
        searchTerm: "true",
        pubDateFilter: "false"
      }
    },
    jsonTempl: {
      type: "AdaptiveCard",
      id: "primary_shadow_container",
      altText: "${if(bookTitle != '', bookTitle, title)} Videobook ${if(isMobile, if(adaAltDuration && adaAltDuration != 'NA' && adaAltDuration != '', 'Watching time '+adaAltDuration, if(runTime && runtime != 'NA' && runtime != '', 'Watching time '+runtime, '')), if(runTime && runtime != 'NA' && runtime != '', 'Watching time '+runtime, ''))} button  list ${if(currentIndex && currentIndex != '', currentIndex, '')} of ${if(totalCount && totalCount != '', totalCount, '')}",
      body: [
        {
          type: "Container",
          id: "adp_display_status_container",
          $when: "${displayStatus && displayStatus != '' }",
          items: [
            {
              type: "TextBlock",
              text: "${if(displayStatus,displayStatus,'NA')}",
              wrap: true,
              height: "stretch",
              id: "adp_display_status_text",
              horizontalAlignment: "Center",
              spacing: "Medium",
              color: "Accent"
            }
          ],
          style: "attention",
          bleed: true,
          rtl: false,
          horizontalAlignment: "Left",
          verticalContentAlignment: "Top"
        },
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "Container",
                  selectAction: {
                    type: "Action.Submit",
                    data: {
                      type: "title",
                      ItemId: "${titleID}",
                      ISBN: "${iSBN}",
                      formatType: "${formatType}",
                      isAvailable: "${isAvailable}",
                      collectionId: "${collectionId}",
                      title: "${bookTitle}",
                      series: "${series}",
                      vendorCode: "VBOOKS"
                    },
                    id: "adp_card_image_card_${formatType}_${titleID}"
                  },
                  backgroundImage: {
                    url: "${imageURL}"
                  },
                  height: "stretch",
                  minHeight: "192px",
                  id: "adp_card_image_container_${formatType}_${titleID}"
                }
              ]
            }
          ],
          minHeight: "50px"
        },
        {
          type: "ColumnSet",
          $when: "${!isMobile}",
          id: "vbook-poster-bg",
          style: "good",
          columns: [
            {
              type: "Column",
              id: "vbook_text",
              title: "Videobook",
              items: [
                {
                  type: "Image",
                  $when: "${!isMobile}",
                  style: "Person",
                  altText: "Format Videobook, Book title ${bookTitle}, Author ${authors.author[0]}",
                  url: "${if(isAvailable == 'True','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/logo/EBook_Format_Icon.png','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/thirdparty/VBook2x_grey.png')}",
                  size: "Small",
                  horizontalAlignment: "Right",
                  width: "24px",
                  height: "24px"
                },
                {
                  type: "Media",
                  $when: "${isMobile}",
                  style: "Person",
                  url: "${if(isAvailable == 'True','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/logo/EBook_Format_Icon.png','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/thirdparty/VBook2x_grey.png')}",
                  size: "Small",
                  horizontalAlignment: "Right",
                  id: "adp_card_image_icon_${formatType}_${titleID}"
                }
              ],
              width: "stretch"
            },
            {
              type: "Column",
              id: "adp_card_title_text_footer_container",
              title: "Videobook",
              items: [
                {
                  type: "TextBlock",
                  wrap: true,
                  text: "${if(runTime == '', 'Videobook', runTime)}",
                  color: "Dark",
                  weight: "Bolder",
                  id: "adp_card_title_text_bottom",
                  title: "Videobook"
                }
              ],
              width: "stretch"
            }
          ]
        },
        {
          type: "Container",
          id: "vbk_book_wrapper",
          $when: "${isMobile}",
          items: [
            {
              type: "Media",
              $when: "${isMobile}",
              style: "Person",
              url: "${if(isAvailable == 'True','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/logo/EBook_Format_Icon.png','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/thirdparty/VBook2x_grey.png')}",
              size: "Small",
              horizontalAlignment: "Right",
              id: "adp_vbk_card_image_icon_${formatType}_${titleID}"
            },
            {
              type: "TextBlock",
              wrap: true,
              text: "${if(runTime == '', 'Videobook', runTime)}",
              color: "Dark",
              id: "adp_vbk_card_title_text_bottom",
              title: "vBook"
            }
          ],
          style: "accent"
        }
      ],
      $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
      version: "1.5",
      verticalContentAlignment: "Top"
    }
  },
  {
    region: 1,
    title: "Other Titles Like This",
    description: null,
    type: "Item_Carousel",
    subType: "Recommend_Videos",
    vendor_details: null,
    hideSeeAllLink: false,
    seeAllLinkPage: null,
    handleInApp: false,
    defaultValues: null,
    dataApi: {
      url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdpartyRecommendation",
      method: "POST",
      headers: {
        api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
        "content-type": "application/json",
        "ocp-apim-subscription-key": "",
        thirdparty: "true"
      },
      body: {
        PatronGUID: "b50cb181-f133-44c8-a7c2-b1d2081b993e",
        LibraryId: "3430B643-9371-ED11-819C-0022481E03C3",
        ByPassCache: false,
        Books: [
          {
            ItemId: "0099488428"
          }
        ],
        Filters: {
          Format: "videobook"
        }
      },
      pathParams: null,
      library_settings: {
        contentCollectionId: "collection"
      },
      search: {
        pageNumber: "0",
        pageSize: "10"
      },
      app: {
        pageNumber: "false",
        pageSize: "false"
      }
    },
    jsonTempl: {
      type: "AdaptiveCard",
      id: "primary_shadow_container",
      altText: "${if(bookTitle != '', bookTitle, title)} Videobook ${if(runtime && runtime != 'NA' && runtime != '', 'Watching time '+runtime, '')} button  list ${if(currentIndex && currentIndex != '', currentIndex, '')} of ${if(totalCount && totalCount != '', totalCount, '')}",
      body: [
        {
          type: "ColumnSet",
          columns: [
            {
              type: "Column",
              width: "stretch",
              items: [
                {
                  type: "Container",
                  selectAction: {
                    type: "Action.Submit",
                    data: {
                      type: "title",
                      ItemId: "${itemId}",
                      ISBN: "${iSBN}",
                      formatType: "${format}",
                      isAvailable: "${isAvailable}",
                      collectionId: "${collectionId}",
                      title: "${title}",
                      series: "${series}",
                      vendorCode: "VBOOKS"
                    },
                    id: "adp_card_image_${itemId}"
                  },
                  backgroundImage: {
                    url: "${imageURL}"
                  },
                  height: "stretch",
                  minHeight: "192px"
                }
              ]
            }
          ],
          minHeight: "50px"
        },
        {
          type: "ColumnSet",
          $when: "${!isMobile}",
          id: "video-poster-bg",
          style: "good",
          columns: [
            {
              type: "Column",
              id: "video_text",
              title: "Videobook",
              items: [
                {
                  type: "Image",
                  $when: "${!isMobile}",
                  style: "Person",
                  altText: "${title} Videobook",
                  url: "${if(isAvailable == 'true','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/logo/EBook_Format_Icon.png','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/thirdparty/VBook2x_grey.png')}",
                  size: "Small",
                  horizontalAlignment: "Right",
                  width: "24px",
                  height: "24px"
                },
                {
                  type: "Media",
                  $when: "${isMobile}",
                  style: "Person",
                  altText: "${title} Vbook",
                  url: "${if(isAvailable == 'true','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/logo/EBook_Format_Icon.png','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/thirdparty/VBook2x_grey.png')}",
                  size: "Small",
                  horizontalAlignment: "Right",
                  width: "24px",
                  height: "24px"
                }
              ],
              width: "stretch"
            },
            {
              type: "Column",
              id: "adp_card_title_text_footer_container",
              title: "Videobook",
              items: [
                {
                  type: "TextBlock",
                  $when: "${runTime && runTime != ''}",
                  wrap: true,
                  text: "${runTime}",
                  color: "Dark",
                  weight: "Bolder",
                  id: "adp_card_title_text_bottom",
                  title: "Videobook"
                },
                {
                  type: "TextBlock",
                  $when: "${!runTime || runTime == ''}",
                  wrap: true,
                  text: "Videobook",
                  color: "Dark",
                  weight: "Bolder",
                  id: "adp_card_title_text_bottom",
                  title: "Videobook"
                }
              ],
              width: "stretch"
            }
          ]
        },
        {
          type: "Container",
          id: "vbk_book_wrapper",
          $when: "${isMobile}",
          items: [
            {
              type: "Media",
              $when: "${isMobile}",
              style: "Person",
              url: "${if(isAvailable == 'true','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/logo/EBook_Format_Icon.png','https://boundlesscdn.baker-taylor.com/reimaginedigital/kidszone/thirdparty/VBook2x_grey.png')}",
              size: "Small",
              horizontalAlignment: "Right",
              id: "adp_vbk_card_image_icon_${formatType}_${titleID}"
            },
            {
              type: "TextBlock",
              $when: "${runTime && runTime != ''}",
              wrap: true,
              text: "${runTime}",
              color: "Dark",
              id: "adp_vbk_card_title_text_bottom",
              title: "vBooks"
            },
            {
              type: "TextBlock",
              $when: "${!runTime || runTime == ''}",
              wrap: true,
              text: "Videobook",
              color: "Dark",
              id: "adp_vbk_card_title_text_bottom",
              title: "Videos"
            }
          ],
          style: "accent"
        }
      ],
      $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
      version: "1.5",
      verticalContentAlignment: "Top"
    }
  }
];