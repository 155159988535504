<div class="kz-card-images" role="list">
        <ng-container *ngFor="let img of cardImgs">
            <div role="listitem" class="kz-cover-poster"
                [ngClass]=" {'kz-hidden-image': !img.src , 'card-stack' : img.src === 'default'}">
                <div>
                    <div class="hidden-title" *ngIf="!img.src">
                        <mat-icon matTooltip="This title is no longer available." svgIcon="kz-no-info-icon"
                            matTooltipClass="kz-card-tooltip">
                        </mat-icon>
                        <div class="hidden-title-img"></div>
                    </div>
                    <div class="user-added-title" *ngIf="img.src === 'default'">
                        <div class="kz-user-cover-title">
                            <div class="kz-user-add-title mb-2">
                                <p class="heading-2 milestone-head three-ellipsis"
                                    axis360EllipsisTitle>{{img.alt}}</p>
                            </div>
                            <div class="kz-user-author-name">
                                <p class="secondary-para milestone-author single-ellipsis"
                                    axis360EllipsisTitle>{{img.author}}</p>
                            </div>
                        </div>
                    </div>
                    <img *ngIf="img.src !== 'default'" [src]="img.src" [alt]="img.alt" />
                </div>
            </div>
        </ng-container>
    <ng-container *ngIf="programType === 'Milestone' && cardImgs.length === 0">
        <div class="kz-milestone-cover">
            <mat-card class="card-user-add">
                <div class="kz-user-add-title">
                    <div class="kz-add-title">
                      <a class="heading-2 milestone-head three-ellipsis" axis360EllipsisTitle>No Items Yet</a>
                    </div>
                </div>
            </mat-card>
        </div>
    </ng-container>
</div>
