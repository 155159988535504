import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PROGRAMS_SCREENS__KEY_BY_TAB } from '@readingprograms/shared/constants/programs.anaytics.constants';
import {
  CARD_ROLE_MAP,
  KZ_PROGRAMS_LIST_IMAGES_COUNT,
  PROGRAMS_LIST_IMAGES_COUNT,
  PROGRAMS_PAGE_SIZE,
  PROGRAMS_STATUS,
  PROGRAMS_STATUS_MAP,
  PROGRAMS_TABS,
  PROGRAMS_TABS_LIST,
  PROGRAMS_TAB_ROUTE_NAME,
  PROGRAMS_TAB_TITLE
} from '@readingprograms/shared/constants/programs.constants';
import { Book, BookDetail } from '@readingprograms/shared/data/engage.model';
import { Program, ProgramCard, ProgramCardImg } from '@readingprograms/shared/data/programs.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { CurrentLibrary } from '@shared/data/config.model';
import { getDate, positionFooter } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { Subscription } from 'rxjs';
import { JoinProgramPopupComponent } from '../shared/components/join-program-popup/join-program-popup.component';

@Component({
  selector: 'axis360-programs-cards',
  templateUrl: './programs-cards.component.html',
  styleUrls: ['./programs-cards.component.scss']
})
export class ProgramsCardsComponent implements OnInit, OnDestroy {
  title = '';
  tabName = '';
  pageIndex = 1;
  programs: { [key: string]: ProgramCard[] } = {};
  programsLen: number;
  booksDetailsObj: { [itemId: string]: BookDetail };
  scrollSubscription: Subscription;
  CARD_ROLE_MAP = CARD_ROLE_MAP;
  currentLibrary: Partial<CurrentLibrary>;
  constructor(
    public activatedRoute: ActivatedRoute,
    public programsService: ProgramsService,
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    public infiniteScroll: InfiniteScrollService,
    public router: Router,
    public snackbarService: SnackBarService,
    public matDialog: MatDialog,
    public programsAnalyticsService: ProgramsAnalyticsService,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService
  ) { }
  ngOnInit(): void {
    this.currentLibrary = this.configService.currentLibrary;
    const tabKey = this.activatedRoute.snapshot.routeConfig.path.includes('open') ? 'OPEN' : PROGRAMS_TABS.MY_PROGRAMS;
    this.programsService.selectedProgramsTab = PROGRAMS_TABS_LIST.indexOf(PROGRAMS_TAB_ROUTE_NAME[tabKey]);
    this.tabName = PROGRAMS_TAB_ROUTE_NAME[tabKey];
    this.title = PROGRAMS_TAB_TITLE[this.tabName];
    this.getJoinProgramById();
    this.getPrograms();
    this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
    this.programsAnalyticsService.trackScreen(PROGRAMS_SCREENS__KEY_BY_TAB[this.tabName]);
  }
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
  }
  updateProgramsLen(newLen = 0) {
    this.programsLen = (this.programsLen || 0) + newLen;
    setTimeout(() => {
      this.setTabIndex()
    }, 0)
  }
  reduceProgramBooksLen = (books: Book[] = []): Book[] => books.slice(0, PROGRAMS_LIST_IMAGES_COUNT).filter((book) => book);

  async getBooksDetails(programs: Program[]): Promise<{ [itemId: string]: BookDetail }> {
    const bookIdsSet: Set<string> = new Set();
    programs
      .filter((program) => {
        const books = program?.programType === 'Milestone'
          ? (program.participants?.length > 0 && program.participants[0]?.milestoneBookList) || []
          : program?.books || [];
        return books.length > 0;
      })
      .forEach((program) => {
        const books = program?.programType === 'Milestone'
          ? (program.participants?.length > 0 && program.participants[0]?.milestoneBookList) || []
          : program?.books || [];
        books.forEach((book) => bookIdsSet.add(book.bookId));
      })
    try {
      const booksDetails = await this.engageService.getBooksByIdV1([...bookIdsSet]);
      return booksDetails.items.reduce((acc, cur) => {
        acc[cur.itemId] = cur;
        return acc;
      }, {});
    } catch (e) {
      return {};
    }
  }
  getCardImgsObj = (program: Program, bookDetails: { [itemId: string]: BookDetail }) => {
    let books: Book[] = [];
    if (program?.programType === 'Milestone') {
      books = (program.participants?.length > 0 && program.participants[0]?.milestoneBookList) || [];
    } else {
      books = program?.books || [];
    }
    let cardImages: ProgramCardImg[] = this.reduceProgramBooksLen(books).reverse().map((book) => {
      const externalBook = book.isManual ? {
        isManual: true,
        title: book?.bookName,
        imageUrl: "default",
        author: book.author
      } : {}
      const { imageUrl: src, title: alt, author } = bookDetails[book.bookId] || externalBook;
      return { src, alt, author };
    });
    cardImages = cardImages.filter((img) => img.src).splice(0, KZ_PROGRAMS_LIST_IMAGES_COUNT);
    return cardImages
  };
  getProgramCard(program: Program): ProgramCard {
    const {
      programName,
      programId,
      description,
      startDate,
      readByDate,
      totalParticipants,
      books,
      status,
      programType,
      numberOfBooks,
      totalCompletePercentage,
      isParticipant,
      participants,
      dbStatus,
      goal
    } = program;
    return {
      status,
      programName,
      programId,
      description,
      startDate,
      readByDate,
      readByDateText: getDate(readByDate),
      totalParticipants,
      books,
      programType,
      numberOfBooks,
      totalCompletePercentage: Math.round(totalCompletePercentage),
      isParticipant,
      dbStatus,
      participants,
      goal
    };
  }
  async getProgramsCard(programs: Program[]): Promise<ProgramCard[]> {
    const bookDetails = await this.getBooksDetails(programs);
    return programs.map((program) => ({ ...this.getProgramCard(program), cardImgs: this.getCardImgsObj(program, bookDetails) }));
  }
  filterPrograms = (programs: { [key: string]: ProgramCard[] }) => {
    Object.keys(programs).forEach((key) => {
      programs[key].length === 0 && delete programs[key];
    });
    this.programs = programs;
  };
  reduceMyPrograms = (programs: ProgramCard[]) => {
    const activePrograms = [];
    const closedPrograms = [];
    programs.forEach((program) =>
      program.dbStatus === PROGRAMS_STATUS.COMPLETE ? closedPrograms.push(program) : activePrograms.push(program)
    );
    if (this.pageIndex === 1) {
      this.filterPrograms({ active: activePrograms, closed: closedPrograms });
      return;
    }
    this.filterPrograms({
      active: (this.programs.active || []).concat(activePrograms),
      closed: (this.programs.closed || []).concat(closedPrograms)
    });
  };
  reduceOpenPrograms = (programs: ProgramCard[]) => {
    const OngoingUpcoming = "Ongoing & Upcoming ";
    if (this.pageIndex === 1) {
      this.programs = { "Ongoing & Upcoming ": programs };
      return;
    }
    this.programs = { "Ongoing & Upcoming ": this.programs[OngoingUpcoming].concat(programs) };
  };
  reducePrograms = (programs: ProgramCard[]): void => {
    const reduceProgramsMap = {
      [PROGRAMS_TABS.MY_PROGRAMS]: this.reduceMyPrograms,
      [PROGRAMS_TABS.OPEN_PROGRAMS]: this.reduceOpenPrograms
    };
    reduceProgramsMap[this.tabName](programs);
  };
  getPrograms() {
    const getProgramsSvcMap = {
      [PROGRAMS_TABS.MY_PROGRAMS]: this.programsService.getPrograms,
      [PROGRAMS_TABS.OPEN_PROGRAMS]: this.programsService.getOpenPrograms
    };
    this.spinnerService.showLoader();
    getProgramsSvcMap[this.tabName](this.pageIndex, PROGRAMS_STATUS_MAP[this.tabName]).subscribe(
      async (response) => {
        const { programs: programsList, errors } = response;
        if (errors || !programsList?.length) {
          this.updateProgramsLen();
          this.spinnerService.showLoader(false);
          errors && this.snackbarService.showSnackBar();
          positionFooter(true);
          return;
        }
        const programs = await this.getProgramsCard(programsList);
        this.reducePrograms(programs);
        this.updateProgramsLen(programsList.length);
        this.spinnerService.showLoader(false);
        positionFooter(true);
      },
      async () => {
        this.programsLen = this.programsLen || 0;
        this.spinnerService.showLoader(false);
        this.snackbarService.showSnackBar();
        positionFooter(true);
      }
    );
  }

  setTabIndex() {
    if (this.tabName == 'ALL PROGRAMS') {
      let parentElementId = document.getElementById('loc_btnOpenProgram')
      parentElementId?.setAttribute('tabindex', '0');
      let childElementId = document?.getElementById('loc_' + Object.keys(this.programs)[0] + 'Programs')
      childElementId?.removeAttribute('tabindex');
    }
    else {
      let parentElementId = document.getElementById('loc_btnOpenProgram')
      parentElementId?.removeAttribute('tabindex');
      let childElementId = document?.getElementById('loc_' + Object.keys(this.programs)[0] + 'Programs')
      childElementId?.setAttribute('tabindex', '0');
    }
  }
  getJoinProgramById() {
    const { programId: joinProgramId } = this.activatedRoute.snapshot.params;
    if (!joinProgramId || this.tabName !== PROGRAMS_TABS.OPEN_PROGRAMS) {
      return;
    }
    this.programsService.getProgramById(joinProgramId).subscribe(
      (res) => {
        res.programId ? this.onProgramCardClick(this.getProgramCard(res)) : this.snackbarService.showSnackBar();
      },
      () => {
        this.snackbarService.showSnackBar();
      }
    );
  }
  canLoadMore = (): boolean => !this.spinnerService.isLoading && this.programsLen >= this.pageIndex * PROGRAMS_PAGE_SIZE;
  loadMore = () => {
    if (!this.canLoadMore()) {
      return;
    }
    this.pageIndex++;
    this.getPrograms();
  };
  onProgramCardClick(programCard: ProgramCard) {
    this.programsAnalyticsService.trackNotificationsEvent('TAP_ON_PROGRAM');
    if (this.tabName === PROGRAMS_TABS.OPEN_PROGRAMS) {
      const dialogRef = this.matDialog.open(JoinProgramPopupComponent, {
        data: programCard,
        panelClass: 'common-modal-container',
        ariaLabelledBy: 'Join-program',
        autoFocus: true
      });
      dialogRef.backdropClick().subscribe(() => {
        dialogRef.close();
      });
      return;
    }
    this.router.navigateByUrl(`/program/${programCard.programId}`);
  }
}
