<div class="kz-reading-programs-main">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">Program page</p>
    <div class="kz-breadcrumb-sec">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'"
            class="kz-breadcrumb">
        </axis360-breadcrumb>
    </div>
    <div class="kz-program-tabs-sec">
        <axis360-navigation-tabs [tabsList]="tabsList" [isPrograms]="true"></axis360-navigation-tabs>
    </div>
    <router-outlet></router-outlet>
</div>