export const SIDE_NAV_URL = {
  MY_LIBRARY: '/view/library',
  MY_SHELF: '/view/myshelf',
  FEATURE_LIST: '/Terms?type=privacy#myStaffPicks',
  PROGRAMS: '/view/programs',
  PROFILES: '/view/profiles',
  CHECKOUT: '/view/mystuff/checkouts',
  BROWSEBYSUBJECT: '/view/title',
  HOLDS: '/view/mystuff/holds',
  HELP: 'https://baker-taylor.my.site.com/boundless',
  SUPPORT: 'https://bakerandtaylor.tfaforms.net/42',
  SIGNOUT: '/Account/LogOff',
  GOBACKSIGNOUT: '/Account/LogOff?isGoBack=true'
};
export const SIGNOUT_ALERT = {
  heading: 'Sign out',
  content: 'Are you sure you want to sign out?',
  submitBtnText: 'Yes',
  cancelBtnText: 'No',
  type: 'CONFIRMATION',
  ariaDescribedBy: 'alert-heading-desc'
};
