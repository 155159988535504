import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@shared/services/user.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountComponent } from '../../account/account.component';
import { SpinnerService } from '@shared/services/spinner.service';
import { DEFAULT_EMAIL_UPDATE_FAILS_MSG, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
@Component({
  selector: 'axis360-email-update',
  templateUrl: './email-update.component.html',
  styleUrls: ['./email-update.component.scss']
})
export class EmailUpdateComponent implements OnInit {
  emailUpdateForm: FormGroup;
  isMatchEmailId = false;
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public matDialog: MatDialogRef<AccountComponent>,
    public spinnerService: SpinnerService,
    public toastService: SnackBarService,
    public liveAnnouncer: LiveAnnouncer
  ) { }

  ngOnInit() {
    this.initUpdateEmailForm();
  }
  initUpdateEmailForm() {
    this.emailUpdateForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]],
      confirmEmailId: ['', [Validators.required, Validators.email]]
    })
  }

  onConfirmEmail(){
    if (this.emailUpdateForm.controls.emailId.value === this.emailUpdateForm.controls.confirmEmailId.value) {
      this.isMatchEmailId = false;
    }
  }
  updateEmail() {
    if (this.emailUpdateForm.controls.emailId.value !== this.emailUpdateForm.controls.confirmEmailId.value) {
      this.isMatchEmailId = true;
      return;
    }
    this.isMatchEmailId = false;
    this.spinnerService.withObservable(
      this.userService.updateEmail(this.emailUpdateForm.controls.emailId.value, this.emailUpdateForm.controls.confirmEmailId.value))
      .subscribe((response: string) => {
        if (response === "SUCCESS") {
          this.userService.userInfo.email = this.emailUpdateForm.controls.emailId.value;
          this.matDialog.close(true);
        } else {
          this.liveAnnouncer.announce('Finished Loading.', 'polite');
          this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_EMAIL_UPDATE_FAILS_MSG);
        }
      }, () => {
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_EMAIL_UPDATE_FAILS_MSG);
      })

  }
}
