import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyLibraryList } from '@shared/data/list-base.model';
import { ISubMenuItem } from '@shared/services/sidenav.service';
import {
  Refiner,
  Filter,
  AppliedFilter,
  Facet,
  LearningActivityFilter,
  RefinerItem,
  ISearchRefiners
} from '../../../core/kz-search-results/shared/data/search.model';
import { KzSearchService } from '../../../core/kz-search-results/shared/services/kz-search.service';
import { CATEGORY_TYPE, CATEGORY, AUDIENCE, KID, REFINER_TITLE } from '@shared/constants/search.constant';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { KzLearningActivitiesAnalyticsService } from 'app/kz-learning-activities/kz-learning-activities-analytics.service';
import { LearningActivitiesService } from '@shared/services/learning-activities.service';
import { cdkRemoveVisualHidden, refinerScrollFound, replaceHyphenToEndash } from '@shared/helper/app.util';
import { SELECTED_CATEGORY } from 'app/core/kz-search-results/shared/constant/search.constant';
import { LiveAnnouncer } from '@angular/cdk/a11y';
@Component({
  selector: 'axis360-search-refiners',
  templateUrl: './search-refiners.component.html',
  styleUrls: ['./search-refiners.component.scss']
})
export class SearchRefinersComponent implements OnInit, AfterViewInit {
  browseBySubject: ISubMenuItem[] = [];
  featuredCategory: ISubMenuItem[] = [];
  featuredList: MyLibraryList[];
  favoriteSeason: string;
  seasons: string[] = ['eBook', 'eAudio', 'Video', 'vBooks'];
  categoryType = CATEGORY_TYPE;
  category = CATEGORY;
  state = -1;
  disableReset: boolean;
  isDisabled = false;
  showRefiner = true;
  customExpandedHeight = '48px';
  refinerScrollFound = refinerScrollFound;
  @Output() selectedRefiners = new EventEmitter();
  @Input() showAccordion = false;
  @Input() showAccordionThirdParty = false;
  selectedText = 'All';
  @Input() isLearningActivity: boolean;
  @Input() radioClass = '';
  @Output() searchRefinerEvent = new EventEmitter();
  @Output() actvityRefinerEvent = new EventEmitter();
  @Input() refiners: Refiner[];
  @Input() learningActivityRefiners: Refiner[];
  @Input() availableLimiters: Filter[] = [];
  @Input() activityRefiner: LearningActivityFilter[] = [];
  @Output() categoryEmitter = new EventEmitter();
  @Output() clearFilterEmitter = new EventEmitter();
  @Output() applyFilterEvent = new EventEmitter<{ appliedFilter: AppliedFilter; filters: Filter[] }>();
  @Output() searchFilterEvent = new EventEmitter();
  eventMap = {
    sortBy: 'VIEW_SORT_BY',
    activityTypes: 'VIEW_ACTIVITY_TYPE',
    audiences: 'VIEW_AUDIENCE_LEVEL'
  };
  replaceHyphenToEndash = replaceHyphenToEndash;
  isLoadAnnouncer = false;
  selectedItem ='';
  selectedKey ='';
  constructor(
    public filterDialog: MatDialogRef<SearchRefinersComponent>,
    public searchService: KzSearchService,
    public searchAnalyticsService: SearchAnalyticsService,
    public profileInfoService: ProfileInfoService,
    public learningActivityAnalyticsService: KzLearningActivitiesAnalyticsService,
    public learningActivitiesService: LearningActivitiesService,
    public liveAnnouncer: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public modaldata: ISearchRefiners
  ) { }
  ngOnInit(): void {
    if (typeof this.modaldata !== 'undefined' && this.modaldata !== null && Object.keys(this.modaldata).length !== 0) {
      this.isLearningActivity = this.modaldata.isLearningActivity ? this.modaldata.isLearningActivity : false;
      this.refiners = this.modaldata.refiners;
      this.learningActivityRefiners = this.modaldata.learningActivityRefiners;
      this.availableLimiters = this.modaldata.availableLimiters;
      this.activityRefiner = this.modaldata.activityRefiner;
      this.showAccordionThirdParty = this.modaldata.refinersHidden;
      this.showAccordion = this.modaldata.showAccordion;
      this.radioClass = this.modaldata.radioClass;
    }
    this.searchService.setDisableCheckBox.subscribe(checkBox => {
      if (checkBox) {
        const checkedFilter = `input[value="${this.selectedItem}"][name="${this.selectedKey}"]`;
        const checkboxElement = document.querySelector(checkedFilter) as HTMLInputElement;
        if (checkboxElement) {
          const selectedId = checkboxElement.id;
          document.getElementById(selectedId)?.focus();
        }
        setTimeout(() => {
          document.querySelectorAll('.kz-radio-button.check-button').forEach(n => {
            n.removeAttribute('disabled');
          });
        }, 1000);
        this.isDisabled = false;
      }
    });
    if (this.profileInfoService.profileInfo.profileType === KID) {
      this.refiners = this.refiners?.filter((elm) => elm.key !== AUDIENCE);
      this.learningActivityRefiners = this.learningActivityRefiners?.filter((elm) => elm.key !== AUDIENCE);
    }
    this.selectedText = this.searchService.selectedCategory;
    this.disableReset = true;
    this.categoryType.forEach((category) => {
      if (category.text === 'Web Resources') {
        category.enabled = this.profileInfoService.profileInfo.webpathExpressEnabled;
      }
    });
    this.searchService.disableReset.subscribe((enabledReset) => { 
      this.disableReset = enabledReset;
      this.showRefinerBlock();
    });
    this.learningActivityRefiners?.forEach((refiner) => {
      if ((refiner.key !== 'sortBy') && refiner.selectedItem.includes('All')) {
        this.setSelectedRefiner(refiner.selectedItem.includes('All'), refiner.key, 'All');
      }
    });
    this.refiners?.forEach((refiner) => {
      if ((refiner.key === 'activityTypes' || refiner.key === 'audiences') && refiner.selectedItem.includes('All')) {
        this.setSelectedRefiner(refiner.selectedItem.includes('All'), refiner.key, 'All');
      }
    });
  }
  /**
   * Closes dialog
   */
  closeDialog() {
    this.filterDialog?.close && this.filterDialog.close();
  }
  /**
   * Referesh the search Titles Content and apply filter
   */
  studyTitlesRefinerChange(sortOption: RefinerItem, index: number, disable = false, isSortBy = false) {
    this.searchService.addingAlertCount();
    if (!isSortBy) { this.disableReset = !disable; }
    this.searchAnalyticsService.trackSearchEvent('REFINER');
    this.refiners[index].selectedText = sortOption.text;
    this.refiners[index].items.forEach((val) => {
      val.isSelected = val.text === sortOption.text;
    });
    if (!this.showAccordion) {
      this.searchRefinerEvent.emit(sortOption);
    }
  }
  /**
   * Referesh the search Titles Content and apply filter reused from studySites
   */
  applyLimiter(limiterName: string, facet: Facet, singleSelectType = true, disable = false) {
    const filterIndex = this.availableLimiters.findIndex((obj) => obj.title === limiterName);
    const facetIndex = this.availableLimiters[filterIndex].facets.findIndex((obj) => obj.text === facet.text);
    this.availableLimiters[filterIndex].selectedFacets = facet.text;
    this.disableReset = !disable;
    if (singleSelectType) {
      if (this.availableLimiters[filterIndex].facets.find((obj) => obj.selected)) {
        this.availableLimiters[filterIndex].facets.find((obj) => obj.selected).selected = false;
      }
    }
    this.availableLimiters[filterIndex].facets[facetIndex].selected = !this.availableLimiters[filterIndex].facets[facetIndex].selected;
    const applyFilter = {
      filters: this.availableLimiters,
      appliedFilter: {
        limiter: limiterName,
        facets: this.availableLimiters[filterIndex].facets.filter((facetElement) => facetElement.selected),
        selectedFacts: facet.text
      }
    };
    this.searchAnalyticsService.trackSearchEvent('REFINER');
    if (!this.showAccordion) {
      this.applyFilterEvent.emit(applyFilter);
    }
  }
  /**
   * Activitys refiner change
   */
  activityRefinerChange(sortOption, redioBtn, refiner?) {
    this.searchService.addingAlertCount();
    this.searchAnalyticsService.trackSearchEvent('REFINER');
    this.isDisabled = true;
    sortOption.isSelected = !sortOption.isSelected;
    if (this.showAccordion) {
      if (redioBtn) {
        refiner.filters.forEach((ele) => (ele.isSelected = false));
        sortOption.isSelected = true;
      }
      return;
    }
    let str = '';
    const val = { myParent: '', value: '' };
    if (redioBtn) {
      val.myParent = sortOption.myParent;
      val.value = sortOption.value;
    } else {
      val.myParent = sortOption.myParent;
      const filters = this.activityRefiner[this.activityRefiner.findIndex((x) => x.refiner === sortOption.myParent)].filters;
      for (const item of filters) {
        if (item.isSelected && item.value !== "All") {
          str = str ? str.concat(`,${item.value}`) : item.value;
        }
      }
      const selectedRefinerLength = str.split(',').length;
      if (selectedRefinerLength === refiner.filters.length || (sortOption.value === 'All' && sortOption.isSelected)) {
        str = 'All';
      } else {
        str = str.replace(/\bAll\b,/g, "");
      }
      val.value = str;
    }
    this.actvityRefinerEvent.emit(val);
    /* by rashmi changes for reset button: start*/
    let excludeFilters = [];
    if (val.value == "") {
      excludeFilters = this.activityRefiner[this.activityRefiner.findIndex((x) => x.refiner === sortOption.myParent)].filters;
    }
    if (refiner?.refiner !== "sortBy") {
      this.disableReset = true;
      for (const ac of this.activityRefiner) {
        if (ac.refiner !== "sortBy") {
          for (const item of ac.filters.filter(x => excludeFilters.indexOf(x) == -1)) {
            if (!item.isSelected && item.value !== "All") {
              this.disableReset = false;
              break;
            }

          }
        }

      }
    }
  }
  /* by rashmi changes for reset button: end*/
  /**
   * Removes the Selected Filters.
   */
  clearFilter() {
    this.state = -1;
    this.searchAnalyticsService.trackSearchEvent('CLEAR_ALL');
    if (this.isLearningActivity) {
      this.filterDialog?.close
        ? this.filterDialog.close({ clearFilter: { key: 'ClearAll', value: '' } })
        : this.clearFilterEmitter.emit({ key: 'ClearAll', value: '' });
      this.liveAnnouncer.announce('Filters have been cleared');
      cdkRemoveVisualHidden();
      return;
    }
    this.liveAnnouncer.announce('Filters have been cleared');
    cdkRemoveVisualHidden();
    this.clearFilterEmitter.emit({ key: 'ClearAll', value: '' });
    this.filterDialog?.close && this.filterDialog.close({ clearFilter: { key: 'ClearAll', value: '' } });
  }

  /**
   * Sets selected refiner
   */
  setSelectedRefiner(isChecked, key, selectedItem, disable = false, select = false) {
    this.selectedItem = selectedItem;
    this.selectedKey = key;
    this.isLoadAnnouncer = true;
    this.searchService.addingAlertCount();
    this.searchAnalyticsService.trackSearchEvent('REFINER');
    setTimeout(() => {
      document.querySelectorAll('.kz-radio-button.check-button').forEach(n =>{
        n.setAttribute('disabled', 'true');
      });
      this.isDisabled = true;
    }, 500);
    (this.modaldata !== null && Object.keys(this.modaldata).length !== 0) && (setTimeout(() => {
      this.searchService.setDisableCheckBox.next(true);      
    }, 1000))
    const selectdItems = (this.refiners || this.learningActivityRefiners)?.find((refinerItem) => refinerItem.key === key);
    selectdItems.selectedItem = Array.isArray(selectdItems?.selectedItem) ? selectdItems.selectedItem : [selectdItems.selectedItem];
    const index = selectdItems.selectedItem.findIndex((val) => val === selectedItem);
    (key !== 'sortBy') && (this.disableReset = !disable);
    if (this.showAccordion) {
      if (key === 'sortBy') {
        this.refiners.find((refinerItem) => refinerItem.key === key).selectedItem = [selectedItem];
      } else {
        if (isChecked) {
          if (selectedItem.toLowerCase() === 'all') {
            selectdItems.selectedItem = [...selectdItems.subOptions];
            return;
          }
          if (index === -1) {
            selectdItems.selectedItem.push(selectedItem);
          }
          const allIndex = selectdItems.selectedItem.findIndex((val) => val.toLowerCase() === 'all');
          if (allIndex === -1 && selectdItems.subOptions.length - selectdItems.selectedItem.length === 1) {
            selectdItems.selectedItem.push('All');
          }
        } else {
          if (selectedItem.toLowerCase() === 'all') {
            selectdItems.selectedItem = [];
            return;
          }
          if (index !== -1) {
            selectdItems.selectedItem.splice(index, 1);
          }
          const allIndex = selectdItems.selectedItem.findIndex((val) => val.toLowerCase() === 'all');
          if (allIndex !== -1) {
            selectdItems.selectedItem.splice(allIndex, 1);
          }
        }
      }
      return;
    }
    if (key === 'sortBy') {
      (this.refiners || this.learningActivityRefiners).find((refinerItem) => refinerItem.key === key).selectedItem = [selectedItem];
      this.selectedRefiners.emit({ key, value: selectedItem, checked: isChecked, selectRef: select });
      this.searchService.setDisableReset(false);
      return;
    }
    if (isChecked) {
      if (selectedItem.toLowerCase() === 'all') {
        selectdItems.selectedItem = [...selectdItems.subOptions];
        this.selectedRefiners.emit({ key, value: selectedItem, checked: isChecked, selectRef: select });
        return;
      }
      if (index === -1) {
        selectdItems.selectedItem.push(selectedItem);
      }
      const allIndex = selectdItems.selectedItem.findIndex((val) => val.toLowerCase() === 'all');
      if (allIndex === -1 && selectdItems.subOptions.length - selectdItems.selectedItem.length === 1) {
        selectdItems.selectedItem.push('All');
      }
    } else {
      if (selectedItem.toLowerCase() === 'all') {
        this.selectedRefiners.emit({ key, value: selectedItem, checked: isChecked, selectRef: select });
        return;
      }
      if (index !== -1) {
        selectdItems.selectedItem.splice(index, 1);
      }
      const allIndex = selectdItems.selectedItem.findIndex((val) => val.toLowerCase() === 'all');
      if (allIndex !== -1) {
        selectdItems.selectedItem.splice(allIndex, 1);
      }
    }
    setTimeout(() => {
      this.isLoadAnnouncer = false;
    }, 2000);
    this.selectedRefiners.emit({ key, value: selectedItem, checked: isChecked, selectRef: select });
  }
  /**
   * Tracks learning analytics
   */
  trackLearningAnalytics(refiner: Refiner) {
    this.learningActivityAnalyticsService.trackLearningActivityEvent(this.eventMap[refiner.key]);
  }
  /**
   * Searchs filters
   */
  searchFilters() {
    this.searchService.addingAlertCount();
    if (this.showAccordion) {
      if (this.isLearningActivity) {
        this.filterDialog.close(this.refiners);
        return;
      }
      if ((this.searchService.showEbookRefiner || this.searchService.showEaudioRefiner)) {
        this.filterDialog.close({ studyTitlesRefinerChange: this.refiners });
        return;
      }
      if (this.searchService.showSitesRefiner) {
        this.filterDialog.close({ applyLimiter: this.availableLimiters });
        return;
      }
      this.filterDialog.close({ activityRefinerChange: this.activityRefiner });
    }
    window.scrollTo(0, 0);
    this.searchFilterEvent.emit(this.searchService.selectedCategory);
  }
  /**
   *
   * @param stateNumb accordion index number
   */
  setState(stateNumb: number) {
    this.state = stateNumb;
    this.refinerscroll();
  }
  refinerscroll() {
    refinerScrollFound();
  }

  getSubTitle(refiner: Refiner) {
    return refiner.selectedItem.toString().includes(`All`) ? `All ${refiner.title}` : refiner.selectedItem.toString();
  }
  getSubTitleSearch(refiner: Refiner) {
    return refiner.selectedFilter.toString().includes(`All`) ? `All ${REFINER_TITLE[refiner.refiner]}` : refiner.selectedFilter.toString();
  }
  getResourcesTitle(refiner: Refiner) {
    return REFINER_TITLE[refiner.refiner];
  }
  ngAfterViewInit(): void {
    this.refinerscroll();
  }
  showRefinerBlock() {
    this.showRefiner =
      (this.searchService.totalCountList[SELECTED_CATEGORY[this.searchService.selectedCategory]] > 0);
    return this.disableReset ? this.showRefiner : true;
  }
}
