import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AvatarsComponent } from './avatars/avatars.component';
import { ParentalPinComponent } from './parental-pin/parental-pin.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { ProfileComponent } from './profile.component';
import { ProfilesListComponent } from './profiles-list/profiles-list.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { CanDeactivateGuard } from './shared/guard/deactive.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPInterceptor } from '@shared/http.interceptor';
const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: '',
        component: ProfilesListComponent
      },
      {
        path: 'create',
        component: CreateProfileComponent
      },
      {
        path: 'create/:type',
        component: ProfileEditComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'create/:type/avatars',
        component: AvatarsComponent
      },
      {
        path: 'pin/:action',
        component: ParentalPinComponent
      },
      {
        path: 'edit/:profileId',
        component: ProfileEditComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'edit/:profileId/avatars',
        component: AvatarsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true
    },
  ]
})
export class ProfileRoutingModule { }
