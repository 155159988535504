<p-carousel axis360CarouselAda id="badges-carousel" class="parentCarousel" [ngClass]="{'hide-dots': hideNavButtons}"
    (window:resize)="onResize()" [numScroll]="numScroll" [numVisible]="numVisible" [value]="badges" [circular]="false"
    [responsiveOptions]="responsiveOptions">
    <ng-template let-item pTemplate="item">
        <div class="badges-item" *ngFor="let badge of item">
            <div class="badges-item-main">
                <div class="badges-img">
                    <img aria-hidden="true" [src]="badge.badgeURLs[0]" [alt]="badge.badgeName" />
                </div>
            </div>
            <div class="name-titles">
                <p class="badge-title-head">{{badge.badgeName}}</p>
            </div>
        </div>
    </ng-template>
</p-carousel>