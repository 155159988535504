import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FILTER_TYPES } from '@search/shared/constants/search-results.constants';
import { AppliedFilter, Facet, Filter, PublicationsSort, Sort } from '@search/shared/data/filter-model';

@Component({
  selector: 'axis360-refiners',
  templateUrl: './refiners.component.html',
  styleUrls: ['./refiners.component.scss']
})
export class RefinersComponent {
  @Input() availableLimiters: Filter[] = [];
  @Input() isSort = false;
  @Input() sortOptions: Sort[] | PublicationsSort[] = [];
  @Input() showCount = false;
  @Output() applyFilterEvent = new EventEmitter<{ appliedFilter: AppliedFilter; filters: Filter[] }>();
  @Output() clearFilterEvent = new EventEmitter<{ limiter: string; filters: Filter[] }>();
  @Output() applySortEvent = new EventEmitter<Sort | PublicationsSort>();
  tabType = FILTER_TYPES.TAB;
  listSingleSelection = FILTER_TYPES.LIST_SINGLE_SELECTION;
  panelOpenStateList = false;
  panelOpenStateTab = false;
  panelOpenStateListPub = false;

  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('refiner-overlay-container');
  }
  /**
   * To expand filter or not based on selection
   * @param limiter filter
   * @returns true if any filter is selected for the limiter
   */
  isExpanded(limiter: Filter) {
    return limiter.facets.some((facet) => facet.selected);
  }

  /**
   * Apply single selected filters
   * @param limiter input param as name of the filters
   * @param facet input param as sub list of filters
   * @param type input param as filter type
   */
  applyLimiter(limiterName: string, facet: Facet, singleSelectType = true) {
    const filterIndex = this.availableLimiters.findIndex((obj) => obj.title === limiterName);
    const facetIndex = this.availableLimiters[filterIndex].facets.findIndex((obj) => obj.text === facet.text);
    if (singleSelectType) {
      if (this.availableLimiters[filterIndex].facets.find((obj) => obj.selected)) {
        this.availableLimiters[filterIndex].facets.find((obj) => obj.selected).selected = false;
      }
    }
    this.availableLimiters[filterIndex].facets[facetIndex].selected = !this.availableLimiters[filterIndex].facets[facetIndex].selected;
    this.applyFilterEvent.emit({
      appliedFilter: {
        limiter: limiterName,
        facets: this.availableLimiters[filterIndex].facets.filter((facetElement) => facetElement.selected)
      },
      filters: this.availableLimiters
    });
  }
  /**
   * Apply sort option
   * @param sort input param as selected sort option
   */
  applySort(sort: Sort | PublicationsSort) {
    this.applySortEvent.emit(sort);
  }
  /**
   * To clear concern filter
   * @param limiter To clear based on Filter Heading
   */
  clearFilter(limiter: string) {
    this.clearFilterEvent.emit({ limiter, filters: this.availableLimiters });
  }
  /**
   * To enable clear button based on filter is selected or not
   * @param limiter input param as limiter
   * @returns true or false
   */
  showClearFilter(limiter: Facet[]) {
    return limiter.some((limit) => limit.selected);
  }
}
