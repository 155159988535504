export const PROFILES_CATEGORY = {
  PROFILES: 'PROFILES'
};
export enum PROFILES_SCREENS_KEY {
  CRAETE_PROFILE = 'CRAETE_PROFILE',
  EDIT_PROFILE = 'EDIT_PROFILE',
  DELETE_PROFILE = 'DELETE_PROFILE',
  SET_PIN = 'SET_PIN',
  ERROR = 'ERROR',
  MANAGE_PROFILE = 'MANAGE_PROFILE',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  POPUP = 'POPUP',
  PROFILE = 'PROFILE',
  ABOUT_PROFILE = 'ABOUT_PROFILE',
  INFO_POPUP = 'INFO_POPUP'
}
export const PROFILES_SCREENS: { [key: string]: string } = {
  [PROFILES_SCREENS_KEY.CRAETE_PROFILE]: 'Add Profile',
  [PROFILES_SCREENS_KEY.EDIT_PROFILE]: 'Edit profile page',
  [PROFILES_SCREENS_KEY.DELETE_PROFILE]: 'Delete profile confirmation page',
  [PROFILES_SCREENS_KEY.SET_PIN]: 'Set profile management pin page',
  [PROFILES_SCREENS_KEY.ERROR]: 'Error page',
  [PROFILES_SCREENS_KEY.MANAGE_PROFILE]: 'Manage Profile page',
  [PROFILES_SCREENS_KEY.DELETE_ACCOUNT]: 'Delete account confirmation page',
  [PROFILES_SCREENS_KEY.POPUP]: 'Popup page',
  [PROFILES_SCREENS_KEY.PROFILE]: 'Profile page',
  [PROFILES_SCREENS_KEY.ABOUT_PROFILE]: 'About Profiles',
  [PROFILES_SCREENS_KEY.INFO_POPUP]: 'I info popup'
};
export enum PROFILES_EVENTS_KEY {
  TYPE_DN = 'TYPE_DN',
  TYPE_EMAIL = 'TYPE_EMAIL',
  SELECT_PROFILE_IMAGE = 'SELECT_PROFILE_IMAGE',
  DONE = 'DONE',
  SUBMIT = 'SUBMIT',
  EDIT_DN = 'EDIT_DN',
  EDIT_CHECKOUT_HISTORY = 'EDIT_CHECKOUT_HISTORY',
  SAVE = 'SAVE',
  BACK = 'BACK',
  DC_YES = 'DC_YES',
  DC_NO = 'DC_NO',
  OKAY = 'OKAY',
  OK_CTA = 'OK_CTA',
  REMOVE_PROFILE = 'REMOVE_PROFILE',
  CANCEL_MY_ACCOUNT = 'CANCEL_MY_ACCOUNT',
  ENTER_PIN = 'ENTER_PIN',
  FORGOT_PIN = 'FORGOT_PIN',
  RESET_PIN = 'RESET_PIN',
  DONE_PIN = 'DONE_PIN',
  DISMISS_PIN = 'DISMISS_PIN',
  BACK_PIN = 'BACK_PIN',
  SUBMIT_PIN = 'SUBMIT_PIN',
  EDIT_PROFILE = 'EDIT_PROFILE',
  ADD_PROFILE = 'ADD_PROFILE',
  VIEW_PROFILE = 'VIEW_PROFILE',
  VIEW_MY_INTERESTS = 'VIEW_MY_INTERESTS',
  VIEW_SECURITY_QUESTION = 'VIEW_SECURITY_QUESTION',
  SECURITY_ANSWER = 'SECURITY_ANSWER',
  PROFILE_TYPE = 'PROFILE_TYPE',
  CHECKBOX_SELECTED = 'CHECKBOX_SELECTED',
  CHECKBOX_UNSELECTED = 'CHECKBOX_UNSELECTED',
  CHECKOUT_SELECTED = 'CHECKOUT_SELECTED',
  CHECKOUT_UNSELECTED = 'CHECKOUT_UNSELECTED',
  PROFILE_PICTURE_EDIT = 'PROFILE_PICTURE_EDIT',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  CANCEL_DISABLE_CHECKOUT_HISTORY = 'CANCEL_DISABLE_CHECKOUT_HISTORY',
  DISABLE_CHECKOUT_HISTORY = 'DISABLE_CHECKOUT_HISTORY',
  ENABLE_CHECKOUT_HISTORY = 'ENABLE_CHECKOUT_HISTORY',
  CANCEL_DISABLE_Insights_Badges = 'CANCEL_DISABLE_Insights_Badges',
  DISABLE_Insights_Badges = 'DISABLE_Insights_Badges',
  ENABLE_Insights_Badges = 'ENABLE_Insights_Badges',
  CLOSE = 'CLOSE',
  GOT_IT = 'GOT_IT'
}
export const PROFILES_EVENTS: { [key: string]: string } = {
  [PROFILES_EVENTS_KEY.TYPE_DN]: 'Type display name',
  [PROFILES_EVENTS_KEY.TYPE_EMAIL]: 'Type Email',
  [PROFILES_EVENTS_KEY.SELECT_PROFILE_IMAGE]: 'Select profile image',
  [PROFILES_EVENTS_KEY.DONE]: 'Done',
  [PROFILES_EVENTS_KEY.SUBMIT]: 'Submit',
  [PROFILES_EVENTS_KEY.EDIT_DN]: 'Edit display name',
  [PROFILES_EVENTS_KEY.EDIT_CHECKOUT_HISTORY]: 'Edit checkout history checkbox',
  [PROFILES_EVENTS_KEY.SAVE]: 'Save CTA',
  [PROFILES_EVENTS_KEY.BACK]: 'Back Button CTA',
  [PROFILES_EVENTS_KEY.CANCEL_MY_ACCOUNT]: 'Cancel my Account CTA',
  [PROFILES_EVENTS_KEY.REMOVE_PROFILE]: 'Remove profile',
  [PROFILES_EVENTS_KEY.DC_YES]: 'Yes - Delete confimration',
  [PROFILES_EVENTS_KEY.DC_NO]: 'No - Delete confimration',
  [PROFILES_EVENTS_KEY.OKAY]: 'Ok',
  [PROFILES_EVENTS_KEY.OK_CTA]: 'Ok CTA',
  [PROFILES_EVENTS_KEY.ENTER_PIN]: 'Enter pin',
  [PROFILES_EVENTS_KEY.FORGOT_PIN]: 'Forgot pin CTA',
  [PROFILES_EVENTS_KEY.RESET_PIN]: 'Reset pin',
  [PROFILES_EVENTS_KEY.EDIT_PROFILE]: 'Edit profile CTA',
  [PROFILES_EVENTS_KEY.ADD_PROFILE]: 'Add profile CTA',
  [PROFILES_EVENTS_KEY.VIEW_PROFILE]: 'Views profiles',
  [PROFILES_EVENTS_KEY.VIEW_MY_INTERESTS]: 'View My interest CTA',
  [PROFILES_EVENTS_KEY.VIEW_SECURITY_QUESTION]: 'View security question',
  [PROFILES_EVENTS_KEY.SECURITY_ANSWER]: 'Security answer field entry',
  [PROFILES_EVENTS_KEY.PROFILE_TYPE]: 'Profile type Dropdown',
  [PROFILES_EVENTS_KEY.CHECKBOX_SELECTED]: 'Checkbox selected',
  [PROFILES_EVENTS_KEY.CHECKBOX_UNSELECTED]: 'Checkbox Unselected',
  [PROFILES_EVENTS_KEY.CHECKOUT_SELECTED]: 'Checkout selected',
  [PROFILES_EVENTS_KEY.CHECKOUT_UNSELECTED]: 'Checkout Unselected',
  [PROFILES_EVENTS_KEY.PROFILE_PICTURE_EDIT]: 'Profile picture Edit CTA',
  [PROFILES_EVENTS_KEY.DELETE_ACCOUNT]: 'Delete profile CTA',
  [PROFILES_EVENTS_KEY.DISMISS_PIN]: 'Dismiss CTA',
  [PROFILES_EVENTS_KEY.BACK_PIN]: 'Back CTA',
  [PROFILES_EVENTS_KEY.SUBMIT_PIN]: 'Submit CTA',
  [PROFILES_EVENTS_KEY.CLOSE]: 'Close CTA',
  [PROFILES_EVENTS_KEY.CANCEL_DISABLE_CHECKOUT_HISTORY]: 'Cancel Disable Checkout History',
  [PROFILES_EVENTS_KEY.DISABLE_CHECKOUT_HISTORY]: 'Disable Checkout History',
  [PROFILES_EVENTS_KEY.ENABLE_CHECKOUT_HISTORY]: 'Enable Checkout History',
  [PROFILES_EVENTS_KEY.CANCEL_DISABLE_Insights_Badges]: 'Cancel Disable Insights & Badges',
  [PROFILES_EVENTS_KEY.DISABLE_Insights_Badges]: 'Disable Insights & Badges',
  [PROFILES_EVENTS_KEY.ENABLE_Insights_Badges]: 'Enable Insights & Badges',
  [PROFILES_EVENTS_KEY.DONE_PIN]: 'Done CTA',
  [PROFILES_EVENTS_KEY.GOT_IT]: 'Got it !'

};
