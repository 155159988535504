import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { PROFILES_MSGS, PROFILE_ACTION, PROFILE_RESPONSE_MSGS, SECURITY_MSGS } from '@profile/shared/constants/profiles.constants';
import { focusElement, positionFooter } from '@shared/helper/app.util';
import { ProfilesAnalyticsService } from '@profile/shared/services/profiles.analytics.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { PROFILES_ALERTS } from '@profile/shared/constants/profiles-alerts.constants';
import { ConfigService } from '@shared/services/config.service';
import { CommonService } from '@shared/services/common.service';
@Component({
  selector: 'axis360-parental-pin',
  templateUrl: './parental-pin.component.html',
  styleUrls: ['./parental-pin.component.scss']
})
export class ParentalPinComponent implements OnInit, AfterViewInit {
  profileName: string;
  isConfirm = false;
  isSetProfile = false;
  action: string;
  pinNumber: string;
  setPinNumber: string;
  confirmedPin: string;
  isResetPin = false;
  isdone = false;
  isAnnouncerSendMailMsg = false;
  isAnnouncerPINSetMsg = false;
  showPinInput = true;
  isSecurityQues = false;
  questions: string;
  baseHref = '';
  showOTP: boolean;
  inValidAttempts = 0;
  otp: string;
  isValidPin = true;
  forgotPin = false;
  isMatch = true;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  };
  inputValid = true;
  pinInputText: HTMLElement;
  patronTypeAuthentication: boolean;
  pageAnnounce = '';
  constructor(
    private profilesService: ProfilesService,
    public profilesAnalyticsService: ProfilesAnalyticsService,
    private profileInfoService: ProfileInfoService,
    public router: Router,
    private snackBarService: SnackBarService,
    public pinDialog: MatDialogRef<ParentalPinComponent>,
    public configService: ConfigService,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public profilePinData
  ) { }
  /**
   * Component init method
   */
  ngOnInit(): void {
    this.pageAnnounce = 'Profile Management Pin Page';
    this.action = this.profilePinData.action;
    this.baseHref = '';
    this.profileInfoService.isFromForgotPin = false
    this.isSetProfile = this.action === PROFILE_ACTION.CREATE;
    this.profileName = this.profileInfoService.currentProfile.profileName;
    positionFooter(true);
    this.isSetProfile && this.profilesAnalyticsService.trackScreen('SET_PIN');
    this.profilesAnalyticsService.trackScreen('POPUP');
    this.patronTypeAuthentication = this.configService.currentLibrary.authSettings.patronTypeAuthentication;
    const disable = document?.getElementById('ngInput');
    disable && disable.querySelectorAll('input').forEach((n, i) => {
      if (i === 0) {
        n.setAttribute('cdkFocusInitial', '');
      }
    });
    setTimeout(() => {
      this.commonService.isAnnouncedPage = false;
    }, 2000);
  }
  ngAfterViewInit(): void {
    this.pinInputText = document.querySelector('#loc_txtProfileManagementPin');
  }
  /**
   * TO navigate to profiles pages
   * @param url url string
   */
  navigateToProfiles(url: string) {
    this.router.navigate([`${this.baseHref}${url}`], { replaceUrl: true });
  }
  /**
   * check security verification call back
   */
  securityResult() {
    this.isSecurityQues = false;
    this.isSetProfile = true;
    this.action = PROFILE_ACTION.CREATE;
    this.isdone = false;
    this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, SECURITY_MSGS.SECURITY_ANS_SUCCESS);
  }

  closeDialog() {
    focusElement('loc_profileImg');
    if (this.profilePinData.type !== PROFILE_ACTION.ACCESS) {
      this.pinDialog.close(false);
      this.profilesAnalyticsService.trackProfilesEvent('CLOSE');
      return
    }
    this.router.navigateByUrl('view/profiles')
  }
  /** check validation Pin
   * if success goto Create Page fails mean show Error message
   */
  submitForAccess = () => {
    this.profilesService.validateParentalPin(this.pinNumber, this.profilePinData.profileId).subscribe(
      (response) => {
        if (response.message === PROFILE_RESPONSE_MSGS.PIN_VALIDATION_SUCCESS) {
          this.inValidAttempts = 0;
          this.pinDialog.close({ validPin: true });
        } else {
          this.inputValidation(false);
          this.isValidPin = false
        }
      },
      (httpError) => {
        this.inputValidation(false);
        positionFooter(true);
        this.inValidAttempts = httpError.error.inValidAttempts;
        this.isValidPin = false
      }
    );
    this.profilesAnalyticsService.trackProfilesEvent('SUBMIT');
  };
  /**
   * inputs validation class added/remov
   */
  inputValidation(isError) {

    this.isdone = false;
    this.inputValid = isError;
  }
  /**
   * Set ParentalPin for new registration
   */
 
   setPin = () => {
    if (!this.isConfirm) {
      this.pinInputText.click();
      setTimeout(() => {
        this.setPinNumber = this.pinNumber;
        this.isConfirm = true;
        this.isSetProfile = false;
        const firstInput = document.querySelector('.kz-pin-password input') as HTMLElement;
        firstInput && firstInput.focus();
      }, 1000)
      return;
    }
    this.confirmedPin = this.pinNumber;
    if (this.setPinNumber !== this.confirmedPin) {
      this.isMatch = false;
      this.isSetProfile = true;
      this.inputValidation(false);
      return;
    }
    this.profilesAnalyticsService.trackProfilesEvent('SUBMIT_PIN');
    this.profilesService.setParentalPin(this.pinNumber, this.profilePinData.profileId).subscribe(
      (res) => {
        this.isConfirm = false;
        this.isMatch = true;
        if (res.message === PROFILE_RESPONSE_MSGS.PIN_SETUP_SUCCESS) {
          this.pinDialog.close({ validPin: true });
        }
      },
      () => {
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, PROFILES_MSGS.ERROR);
      }
    );
  };
  // parental pin ada fix
  setInputpin() {
    const infoMesg = ' Please enter a number between 0 to 9';
    document.querySelectorAll('.kz-pin-password input').forEach((n, i) => {
      n.setAttribute('aria-label', 'pin number ' + (i + 1) + infoMesg);
      n.setAttribute('aria-labelledby', 'loc_txtProfileManagementPin');
      n.setAttribute('aria-errormessage', 'loc_txtProfileManagementPin');
      n.setAttribute('id', 'loc_txtPinNumber' + (i + 1));
      n.setAttribute('inputmode', 'numeric');
      n.setAttribute('pattern', '[0-9]*');
    });
  }
  /**
   * submit pin
   * @returns API responce
   */
  submitPin() {
    if (!this.isdone) {
      return;
    }
    const actionMap = {
      [PROFILE_ACTION.ACCESS]: this.submitForAccess,
      [PROFILE_ACTION.CREATE]: this.setPin
    };
    actionMap[this.action]();
    this.profilesAnalyticsService.trackProfilesEvent('DONE_PIN');
  }
  resetPin = () => {
    setTimeout(() => {
      const submitBtn = document?.getElementById('loc_sendEmail');
      submitBtn?.focus();
    }, 1000);
    this.isResetPin = true;
    this.showPinInput = false;
    this.forgotPin = true;
    this.isdone = true;
    this.inValidAttempts = 0;
    this.profilesAnalyticsService.trackProfilesEvent('FORGOT_PIN');
  };
  sendOTP() {
    this.profilesService.generateOTP(this.profilePinData.profileId).subscribe(
      (response) => {
        if (response.message === PROFILES_MSGS.OTP_SUCCESS) {
          this.isResetPin = false;
          this.showPinInput = true;
          this.showOTP = true;
          setTimeout(() => {
            this.isAnnouncerSendMailMsg = true;
            this.removeIsAnnouncerMail();
          }, 2000);
          this.isdone = false;
          this.isValidPin = true;
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.EMAIL_SUCCESS);
        }
      },
      (httpError) => {
        this.pinDialog.close();
        const alert = PROFILES_ALERTS.OTP_FAILURE;
        alert.content = httpError.error.message;
        this.profilesService.showAlertDialog({
          ...alert,
          submitBtnFunc: () => {
            this.profilesAnalyticsService.trackProfilesEvent('OK_CTA');
          }
        });
      }
    );
  }

  removeIsAnnouncerMail() {
    setTimeout(() => {
      this.isAnnouncerSendMailMsg = false;
    }, 2000);
  }

  validateOTP() {
    this.profilesAnalyticsService.trackProfilesEvent('SUBMIT_PIN');
    this.otp = this.pinNumber;
    this.profilesService.validateOTP(this.profilePinData.profileId, this.otp).subscribe(
      (response) => {
        if (response.message === PROFILES_MSGS.OTP_VERIFIED) {
          if (this.profilePinData.type === PROFILE_ACTION.DISABLE) {
            this.pinDialog.close(true);
          }
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.RESET_SUCCESS);
          this.inputValidation(true);
          this.showOTP = false;
          this.isResetPin = false;
          this.showPinInput = true;
          this.isSetProfile = true;
          this.action = PROFILE_ACTION.CREATE;
          this.isdone = false;
          this.profileInfoService.isFromForgotPin = true;
        }
      },
      (httpError) => {
        this.inputValidation(false);
        this.isValidPin = !(httpError.error.message === 'OTP code is not match' || httpError.error.message === 'OTP code is not found');
        this.isdone = false;
      }
    );
  }
  /**
   * this called every time when user changed the pin
   * @param pin input value
   */
  pinNumberChange(pin: string) {
    this.inValidAttempts = 0;
    this.isdone = pin.length >= 4;

    if (pin.length > 0) {
      this.inputValid = true;
      this.isMatch = true;
      this.isValidPin = true;
    };

    if (this.isdone) {
      this.isMatch = true;
      this.isValidPin = true;
      this.profilesAnalyticsService.trackProfilesEvent('DONE_PIN');
    }
    this.pinNumber = pin;
  }

}
