import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from '@shared/services/account.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from '@shared/services/config.service';
import { CurrentLibrary } from '@shared/data/config.model';
import { forceRedirect, setElemAttribute } from '@shared/helper/app.util';
import { SpinnerService } from '@shared/services/spinner.service';
import { IRegisterRequest, LogOnResponseModel, RegisterModel, RegisterResponseModel, SecurityQuestion } from '../shared/data/account.model';
import { AGE_RANGE, REGISTER, SKIP_PATRONPIN } from '../shared/constants/account.constants';
import { AGE_LEVEL } from '../shared/data/mock/login.mock';
import { UserService } from '@shared/services/user.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { AccountComponent } from '../account.component';
import { CommonService } from '@shared/services/common.service';
import { Router } from '@angular/router';
import { SharedService } from '@shared/services/shared.service';
import { AccountAnalyticsService } from '../shared/services/account.analytics.service';
import { MatSelect, MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'axis360-registeration',
  templateUrl: './registeration.component.html',
  styleUrls: ['./registeration.component.scss']
})
export class RegisterationComponent {
  @Input() registerResponse: RegisterModel;
  forceRedirect = forceRedirect;
  @Input() noModal = false;
  backToLogin: boolean = false;
  passwordHide: boolean = true;
  defaultAge: string = AGE_RANGE['Adult 18+'];
  registerFormGroup: FormGroup;
  registerData: RegisterResponseModel;
  isLoggedIn: boolean;
  securityQuestions: Array<SecurityQuestion>;
  registerRequest: IRegisterRequest;
  noPatronPinNeeded: boolean;
  currentLibrary: Partial<CurrentLibrary>;
  setElemAttribute = setElemAttribute;
  userDisplayName: string;
  authenticationType: string;
  defaultQuestion: string;
  selectedSecurityQuestion: string[];
  postRegisterResponse: LogOnResponseModel;
  profileTypeId: string;
  redirectUrl: string;
  ageLevel = AGE_LEVEL;
  libraryLoginInfo: { userDisplayName: string; authenticationType: string };
  EMAIL_PATTERN =
    /^([a-zA-Z0-9_.\-]+)@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[\d]{1,3})(\]?)$/;
  component: {
    authenticationMethod: string;
    errorMessage: any;
    isDialog: boolean;
    noPatronPinNeeded: boolean;
    passwordRecoverable: boolean;
    profileTypeId: any;
    registerModel_DisplayName: any;
    registerModel_Email: any;
    registerModel_Password: string;
    registerModel_SecurityAnswer: any;
    registerModel_SecurityQuestion: number;
    registerModel_UserName: string;
    returnUrl: string;
  };
  backToPwdVerification: boolean;
  isShowSecurityQuestion: boolean;
  selectedItemMessage: string = '';
  isShowSelectedItemMessage = false;
  @ViewChild('mySelect') mySelect: MatSelect;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.altKey && event.code === 'Space') {
      this.mySelect.open();
    }
  }

  constructor(
    public spinnerService: SpinnerService,
    public accountService: AccountService,
    public matDialog: MatDialogRef<AccountComponent>,
    private configService: ConfigService,
    public userService: UserService,
    public profileService: ProfileInfoService,
    public commonService: CommonService,
    public sharedService: SharedService,
    public router: Router,
    public accountAnalyticsService: AccountAnalyticsService
  ) { }

  ngOnInit() {
    this.isShowSecurityQuestion = true;
    this.currentLibrary = this.configService.currentLibrary;
    this.libraryLoginInfo = this.configService.getLibraryLoginInfo();
    this.registerFormValues();
    this.getRegisterData();
    this.accountService.currentDialog = REGISTER;
    this.accountAnalyticsService.trackScreen('REGISTER');
    if (this.currentLibrary && this.currentLibrary.authSettings && this.currentLibrary.authSettings.ilsVendorID && this.currentLibrary.authSettings.ilsVendorID == 'PREFIX' && this.currentLibrary.authSettings.noPatronPinNeeded) {
      this.isShowSecurityQuestion = false;
    }
  }

  registerFormValues() {
    this.registerFormGroup = new FormGroup({
      libraryId: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      passWord: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
      displayName: new FormControl('', [Validators.minLength(3), Validators.maxLength(25)]),
      emailAddress: new FormControl('', [Validators.pattern(this.EMAIL_PATTERN)]),
      securityAnswer: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      securityQuestion: new FormControl(this.defaultQuestion, [Validators.required])
    });
    if (!this.isShowSecurityQuestion) {
      this.registerFormGroup.controls.securityAnswer.setValidators([]);
      this.registerFormGroup.controls.securityQuestion.setValidators([]);
      this.registerFormGroup.controls.securityQuestion.setValue('');
    }
    this.getRegisterResponse();
  }
  public getRegisterResponse() {
    if (!this.registerResponse) {
      return;
    }
    this.registerFormGroup.controls.libraryId.setValue(this.registerResponse.registerModel_UserName);
    this.registerFormGroup.controls.passWord.setValue(this.registerResponse.registerModel_Password);
    this.noPatronPinNeeded = this.registerResponse.NoPatronPinNeeded;
    if (!this.currentLibrary.authSettings.noPatronPinNeeded) {
      return;
    }
    this.registerFormGroup.patchValue({
      passWord: SKIP_PATRONPIN
    });
  }
  public checkError = (controlName: string, errorName: string, id?) => {
    const elementId = document?.getElementById(id);
    if (elementId) {
      const ariaDescribedby = elementId.getAttribute('aria-describedby')
      elementId.setAttribute('aria-errormessage', ariaDescribedby)
    }
    return this.registerFormGroup.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    const { libraryId, emailAddress, passWord, securityQuestion, securityAnswer, displayName } = this.registerFormGroup.controls;
    if (this.registerFormGroup.valid) {
      this.registerRequest = {
        registerModel_UserName: libraryId.value?.trim(),
        registerModel_Email: emailAddress.value,
        registerModel_Password: passWord.value,
        registerModel_SecurityQuestion: securityQuestion.value || 0,
        registerModel_SecurityAnswer: securityAnswer.value,
        profileTypeId: this.defaultAge,
        registerModel_DisplayName: displayName.value
      };
      this.postRegisterResponse = {};
      this.postRegisterData(this.registerRequest);
    }
  }

  postRegisterData(value) {
    this.spinnerService.withObservable(this.accountService.postRegisterData(value)).subscribe(
      async (response) => {
        this.spinnerService.isOverlayLoading = false;
        window.scrollTo(0, 0);
        this.postRegisterResponse = response;
        if (this.postRegisterResponse.isAuthenticated) {
          !this.noModal && this.matDialog.close(true);
          this.commonService.reloadMainComponent.next(true);
          this.sharedService.isNewUser = true;
          if (this.currentLibrary.axis360Enabled && !this.currentLibrary.kidsZoneEnabled) {
            this.router.navigateByUrl('/view/library');
            return;
          }
          this.router.navigateByUrl('/view/profiles?isLogin=true');
        }
      },
      () => {
        !this.noModal && this.matDialog.close();
      }
    );
  }
  openMatSelect(event: boolean) {
    this.setElemAttribute('.kz-mat-select-panel', 'role', 'listbox');
    if (event) {
      document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'true');
      return;
    }
    document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'false');
  }

  onSelectChange(event: MatSelectChange) {
    if (navigator.userAgent.match(/(Mac)/i)) {
      this.selectedItemMessage = `Security Question list ${event.source.triggerValue}`;
      this.isShowSelectedItemMessage = true;
      const securityQuestionSetAttribute = document.getElementById('RegisterModel_SecurityQuestion_button');
      securityQuestionSetAttribute?.removeAttribute('aria-labelledby');
      securityQuestionSetAttribute?.setAttribute('aria-describedby', 'loc_securityQuestion');
      this.accountAnalyticsService.trackAccountEvent('SELECT_SECURITY_QUESTION')
      return;
    }
    this.accountAnalyticsService.trackAccountEvent('SELECT_SECURITY_QUESTION');
  }

  getRegisterData() {
    this.accountService.getRegisterData().subscribe(
      (response) => {
        this.registerData = response;
        this.securityQuestions = this.registerData.registerModel_SecurityQuestionList;
        this.defaultQuestion = this.securityQuestions[0].value;
        this.registerFormValues();
        this.isLoggedIn = false;
        setTimeout(() => {
          document.querySelectorAll('#RegisterModel_SecurityQuestion_button .mat-select-value-text')?.forEach((n) => {
            n.children[0]?.setAttribute('aria-live', 'polite');
          });
        }, 200);
      },
      () => {
        this.isLoggedIn = false;
      }
    );
  }
  goBack() {
    if (this.currentLibrary.authSettings.ilsVendorID == 'PASSWORD') {
      this.backToPwdVerification = true;
      return;
    }
    this.backToLogin = true;
  }
}
