import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { HomeService } from '@home/shared/services/home.service';
import { Carousel } from 'primeng/carousel';
import { fromEvent, noop } from 'rxjs';
import { HomeAvailability, HomeFormatType } from '@shared/data/config.model';
import { CAROUSEL_CONST, COLLECTION_TYPE, HOME_AVAILABILITY, HOME_FORMAT_TYPE, HOME_REFINER } from '@home/shared/constants/home.constants';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { UserService } from '@shared/services/user.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { ConfigService } from '@shared/services/config.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { forceRedirect, positionFooter } from '@shared/helper/app.util';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HOME_EVENTS_KEY } from '@home/shared/constants/home.analytics.constants';
import { MyLibraryList } from '@home/shared/data/home.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { THIRD_PARTY_VENDOR_CODE } from 'app/third-party/shared/constants/third-party.constants';
import { MainService } from '@shared/services/main.service';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';

type Action = keyof typeof HOME_EVENTS_KEY;
@Component({
  selector: 'axis360-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss']
})
export class HomeCarouselComponent implements OnInit {
  isLoading = true;
  isLoginStatus = true;
  myLibraryList: MyLibraryList[];
  myLibraryListContent = {};
  myLibraryListData = [];
  myLibraryListDataCheckout = [];
  myCollectionList: MyLibraryList[];
  myCollectionContent = {};
  collectionData = [];
  fromHomeCarousel = true;
  homeAvailability: HomeAvailability[] = HOME_AVAILABILITY;
  homeFormatType: HomeFormatType[] = HOME_FORMAT_TYPE;
  selectedAvailability = HOME_AVAILABILITY[0].value;
  selectedFormat = HOME_FORMAT_TYPE[0].value;
  isPressReaderEnabled = false;
  isRecommendationEnabled = false;
  isCollectionEnabled = false;
  isPPCOnlyLibrary = false;
  showFeaturedProgram = false;
  isTrackingEnabled = false;
  isCorouselLoaded = true;
  carouselConst = CAROUSEL_CONST;
  fromEvent = fromEvent;
  constructor(
    public homeService: HomeService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public searchStorageService: SearchStorageService,
    public userService: UserService,
    public spinnerService: SpinnerService,
    public configService: ConfigService,
    public homeAnalyticsService: HomeAnalyticsService,
    public overlayContainer: OverlayContainer,
    public engageService: EngageService,
    public profileInfoService: ProfileInfoService,
    public mainService: MainService
  ) {
    Carousel.prototype.onTouchMove = noop;
    iconRegistry.addSvgIcon('ebook', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/ebook.svg'));
    iconRegistry.addSvgIcon('book', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/book.svg'));
    iconRegistry.addSvgIcon('audio', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/audiobook.svg'));
    iconRegistry.addSvgIcon(
      'home_ebook',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/home-book.svg')
    );
    iconRegistry.addSvgIcon('home_book', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/home-book.svg'));
    iconRegistry.addSvgIcon(
      'home_audio',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/home-audiobook.svg')
    );
    iconRegistry.addSvgIcon(
      'DOWN_ARROW',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-chevron-down-arrow.svg')
    );
  }
  /**
   * To get home carousel on init
   */
  ngOnInit(): void {
    this.getLibrarySettings();
    if (this.userService.menuEmitter) {
      this.fromEvent(window, 'scroll').subscribe(() => {
        this.userService.menuEmitter.next(false);
      });
    }
  }
  /**
   * To get Library Settings from API
   */
  async getLibrarySettings() {
    this.spinnerService.showLoader();
    await Promise.all([this.configService.getLibrarySetting(), this.userService.getCurrentUser(), this.mainService.getUserAndSettings()]);
    this.spinnerService.showLoader(false);
    this.isCollectionEnabled = this.configService.getCollectionEnabled();
    this.isPPCOnlyLibrary = this.configService.getIsPPCOnlyLibrary(this.profileInfoService.currentProfile.profileType.toLowerCase());
    this.showFeaturedProgram = this.configService.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase()).programsEnabled;
    this.isTrackingEnabled = this.userService.isTrackingEnabled();
    const isGuest = this.configService.currentLibrary.axis360Enabled && !this.userService.userInfo?.isLoggedIn;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(
        isGuest ? PROFILE_TYPES.ADULT : this.profileInfoService.currentProfile.profileType.toLowerCase()
      ) || {};
    this.isPressReaderEnabled = currentProfileFeatures[THIRD_PARTY_VENDOR_CODE.PRESSRDR];
    this.isRecommendationEnabled =
      (this.configService.currentLibrary.profileFeatures &&
        this.configService.currentLibrary.profileFeatures['adult'].recommendationEnabled) ||
      false;
    if (this.userService.isLoggedIn()) {
      this.isLoginStatus = false;
    }
    if (this.isPPCOnlyLibrary) {
      this.selectedAvailability = HOME_AVAILABILITY[0].value;
      this.selectedFormat = HOME_FORMAT_TYPE[2].value;
      this.getPartnerSiteEnabled();
      this.searchStorageService.setHomeFiters(this.selectedAvailability, this.selectedFormat);
      this.alwaysAvailable(this.isPPCOnlyLibrary);
    } else {
      this.selectedAvailability = sessionStorage.getItem(HOME_REFINER.AVAILABILITY) || this.selectedAvailability;
      this.getPartnerSiteEnabled();
      this.selectedFormat = sessionStorage.getItem(HOME_REFINER.FORMAT) || this.selectedFormat;
      if (this.isCollectionEnabled && !this.isPPCOnlyLibrary) {
        this.alwaysAvailable();
      }
      this.myLibrary();
    }
  }
  getPartnerSiteEnabled() {
    const partnerSiteEnabled = this.configService.currentLibrary.partnerSiteEnabled;
    if (partnerSiteEnabled) {
      this.selectedAvailability = HOME_AVAILABILITY[1].value;
    }
  }
  /**
   * To get Library List from response
   */
  async myLibrary() {
    this.myLibraryList = await this.homeService.getMyLibraryList();
    this.homeService.myLibraryList = this.myLibraryList;
    this.myLibraryContent(this.myLibraryList, this.selectedAvailability, this.selectedFormat, true);
    this.titleforRecommendation(this.myLibraryList, this.selectedAvailability, this.selectedFormat);
    this.myLibraryContent(this.myLibraryList, this.selectedAvailability, this.selectedFormat);
  }
  /**
   * To get Library content based on List Id from response
   * @param myLibList input param as Library List
   * @param selectedAvailability input param as Selected Availability Filter value
   * @param selectedFormat input param as Selected Format Filter value
   * @param isCheckedout input param as Checkedout flag
   * @returns to returns content based on list id from response
   */
  async myLibraryContent(myLibList = [], selectedAvailability, selectedFormat, isCheckedout = false) {
    if (isCheckedout) {
      const myLibraryListDataTempCheckout = [];
      const getListContent = await this.homeService.getListContent(CAROUSEL_CONST.checkout, this.selectedAvailability, this.selectedFormat);
      this.myLibraryListContent = { ...getListContent, listName: CAROUSEL_CONST.Currently_Reading, listId: CAROUSEL_CONST.checkout };
      const myList = await this.listDestructure(this.myLibraryListContent, CAROUSEL_CONST.checkout);
      if (myList) {
        myLibraryListDataTempCheckout.push(myList);
        for (const listConstent of myList.Items) {
          this.getActionViewButtonForLibraryList(listConstent, myList.listName, listConstent?.ISBN, true);
        }
      }
      this.myLibraryListDataCheckout = myLibraryListDataTempCheckout;
      positionFooter(true);
      return;
    }
    const myLibraryListDataTemp = [];
    let i = 0;
    const myLibraryList = myLibList.filter(
      (el) =>
        el?.ListID !== CAROUSEL_CONST.checkout &&
        el?.ListID !== CAROUSEL_CONST.Recommended_for_you &&
        el?.ListID !== CAROUSEL_CONST.Based_your_Interests
    );
    for (const list of myLibraryList) {
      const getListContent = await this.homeService.getListContent(list?.ListID, selectedAvailability, selectedFormat);
      this.myLibraryListContent = { ...getListContent, listName: list?.ListName, listId: list?.ListID };
      const myList = await this.listDestructure(this.myLibraryListContent, list?.ListID);
      if (myList) {
        myLibraryListDataTemp.push(myList);
        for (const listConstent of myList.Items) {
          this.getActionViewButtonForLibraryList(listConstent, myList.listName, listConstent?.ISBN);
        }
      }
      this.myLibraryListData = myLibraryListDataTemp;
      positionFooter(true);
      if (i === myLibraryList?.length - 1) {
        this.isCorouselLoaded = false;
      }
      i++;
    }
  }
  /**
   * To get action buttons for the respective titles
   * @param listContent input param as listContent
   * @param myLibraryListName input param as listName
   * @param currentISBN input param as ISBN
   * @param isCheckedout input param as Checkedout flag
   * @param isRecommendations input param as Recommendations flag
   * @returns To return action buttons for the respective titles
   */
  getActionViewButtonForLibraryList(listContent, myLibraryListName, currentISBN, isCheckedout = false, isRecommendations = false) {
    if (isCheckedout) {
      this.homeService.getMyLibraryListActionButton(listContent).subscribe(
        (actionButtonResponse) => {
          this.configService.ppcBugetReached = actionButtonResponse?.ppcBudgetReached;
          this.myLibraryListDataCheckout.forEach((carouselList) => {
            if (carouselList.listName === myLibraryListName) {
              carouselList.Items?.map((elm) => {
                if (elm.ISBN === currentISBN) {
                  elm.titleStateInfo = actionButtonResponse;
                }
              });
            }
          });
          positionFooter(true);
        },
        (errMag) => {
          console.log('err', errMag);
        }
      );
      return;
    } else if (isRecommendations) {
      this.homeService.getMyLibraryListActionButton(listContent).subscribe(
        (actionButtonResponse) => {
          this.configService.ppcBugetReached = actionButtonResponse?.ppcBudgetReached;
          this.homeService.myLibraryListDataRecommendation.forEach((carouselList) => {
            if (carouselList.listName === myLibraryListName) {
              carouselList.Items?.map((elm) => {
                if (elm.ISBN === currentISBN) {
                  elm.titleStateInfo = actionButtonResponse;
                }
              });
            }
          });
          positionFooter(true);
        },
        (errMag) => {
          console.log('err', errMag);
        }
      );
      return;
    }
    this.homeService.getMyLibraryListActionButton(listContent).subscribe(
      (actionButtonResponse) => {
        this.configService.ppcBugetReached = actionButtonResponse?.ppcBudgetReached;
        this.myLibraryListData.forEach((carouselList) => {
          if (carouselList.listName === myLibraryListName) {
            carouselList.Items?.map((elm) => {
              if (elm.ISBN === currentISBN) {
                elm.titleStateInfo = actionButtonResponse;
              }
            });
          }
        });
        positionFooter(true);
      },
      (errMag) => {
        console.log('err', errMag);
      }
    );
  }
  /**
   * To get Collection List from response
   * @param isPPCOnlyLibrary input param as PPCOnlyLibrary flag
   */
  alwaysAvailable(isPPCOnlyLibrary = false) {
    this.homeService.getMyCollectionList(COLLECTION_TYPE.PPC).subscribe((res) => {
      this.myCollectionList = res;
      this.alwaysAvailableContent(this.myCollectionList, this.selectedFormat, isPPCOnlyLibrary);
    });
  }
  /**
   * To get Library collection content based on list Id from response
   * @param myCollectionList input param as collection list
   * @param selectedFormat input param as selected format filter value
   * @param isPPCOnlyLibrary input param as PPCOnlyLibrary flag
   * @returns To return Library collection content based on list Id from response
   */
  alwaysAvailableContent(myCollectionList, selectedFormat, isPPCOnlyLibrary = false) {
    const myCollectionListTemp = [];
    const format =
      selectedFormat === HOME_FORMAT_TYPE[0].value || selectedFormat === HOME_FORMAT_TYPE[2].value
        ? HOME_FORMAT_TYPE[0].value
        : HOME_FORMAT_TYPE[1].value;
    if (!isPPCOnlyLibrary) {
      myCollectionList = myCollectionList.filter((item) => item.ListName === CAROUSEL_CONST.featured);
      this.homeService.getMyCollectionContent(myCollectionList[0]?.ListID, format).subscribe((res1) => {
        this.myCollectionContent = { ...res1, listName: myCollectionList[0]?.ListName, listId: myCollectionList[0]?.ListID };
        const alwaysAvail = this.listDestructure(this.myCollectionContent);
        if (alwaysAvail) {
          myCollectionListTemp.push(alwaysAvail);
          for (const listConstent of alwaysAvail?.Items) {
            this.getActionViewButtonForAlwaysAvailable(listConstent, alwaysAvail.listName, listConstent.ISBN);
          }
        }
        positionFooter(true);
      });
      this.collectionData = myCollectionListTemp;
      return;
    }
    this.alwaysAvailableContentPPCOnly(myCollectionList, format);
  }
  /**
   * To get Library collection PPC content based on list Id from response
   * @param myCollectionList input param as collection list
   * @param format input param as selected format filter value
   */
  alwaysAvailableContentPPCOnly(myCollectionList, format) {
    const myCollectionListTemp = [];
    for (const list of myCollectionList) {
      this.homeService.getMyCollectionContent(list?.ListID, format).subscribe((res1) => {
        this.myCollectionContent = { ...res1, listName: list?.ListName, listId: list?.ListID };
        const alwaysAvail = this.listDestructure(this.myCollectionContent);
        if (alwaysAvail) {
          myCollectionListTemp.push(alwaysAvail);
          for (const listConstent of alwaysAvail?.Items) {
            this.getActionViewButtonForAlwaysAvailable(listConstent, alwaysAvail.listName, listConstent.ISBN);
          }
        }
        this.collectionData = myCollectionListTemp;
        positionFooter(true);
      });
    }
  }
  /**
   * To get action buttons for the respective always available titles
   * @param listContent input param as collection list content
   * @param collectionListName input param as collection list name
   * @param currentISBN input param as collection ISBN
   */
  getActionViewButtonForAlwaysAvailable(listContent, collectionListName, currentISBN) {
    this.homeService.getMyCollectionActionButton(listContent).subscribe(
      (actionButtonResponse) => {
        this.configService.ppcBugetReached = actionButtonResponse?.ppcBudgetReached;
        this.collectionData.forEach((collectionList) => {
          if (collectionList.listName === collectionListName) {
            collectionList.Items?.map((element) => {
              if (element.ISBN === currentISBN) {
                element.titleStateInfo = actionButtonResponse;
              }
            });
          }
        });
        positionFooter(true);
      },
      (errMsg) => {
        console.log('err', errMsg);
      }
    );
  }
  /**
   * To get destructured list from response
   * @param listContent input param as list content
   * @param listId input param as listId
   * @returns To return destructured list from response
   */
  listDestructure(listContent, listId = '') {
    if (listContent?.Items?.length > 0 && listContent?.Items.some((a) => a.ListId !== CAROUSEL_CONST.foundation_skills)) {
      if (listId === CAROUSEL_CONST.myassignment && this.selectedFormat !== HOME_FORMAT_TYPE[0].value) {
        listContent.Items = listContent?.Items.filter((item) => item.FormatType === this.selectedFormat);
      }
      listContent.TotalItems = listContent?.Items?.length > 10 ? 10 : listContent?.TotalItems;
      listContent.Items = listContent?.Items.slice(0, 10);
      listContent.Items.map((elm) => {
        elm.formatType = elm.FormatType;
        elm.isbn = elm.ISBN;
        elm.isAvailable = elm.IsAvailable;
        elm.isRTV = elm.IsRTV;
        elm.itemId = elm.ItemId;
        elm.title = elm.Title;
        elm.isRecommendable = elm.IsRecommendable;
      });
      return listContent;
    }
  }
  /**
   * redirect to concern list page
   */
  redirectToListPage(carouselName = '', carouselType = '', cid: string = '', type: string = '') {
    this.homeAnalyticsService.trackHomeEvent('CAROUSEL_SEE_ALL', { carouselName });
    const eventURL = {
      Checkout: `/MyStuff?type=checkout`,
      Assignment: `/MyStuff?type=assignment`,
      'Always Available': `/Collection?type=${type}`,
      Category: `/Home/Category/?catid=${cid}`,
      PPCOnly: `/Collection/Category?catid=${cid}`
    };
    if (eventURL[carouselType]) {
      forceRedirect(eventURL[carouselType]);
    }
  }
  /**
   * when we change filter, this function triggered
   * @param filter input param as filter name
   * @param filterValue input param as filter value
   */
  filterChangeForCarousel(filter: string, filterValue: string) {
    this.isCorouselLoaded = true;
    this.myLibraryListData = [];
    this.myLibraryListDataCheckout = [];
    this.collectionData = [];
    if (filter === CAROUSEL_CONST.Availability) {
      this.selectedAvailability = filterValue;
      this.homeAnalyticsService.trackHomeEvent(HOME_EVENTS_KEY.AVAILABILITY_FILTER as Action);
    } else {
      this.selectedFormat = filterValue;
      this.homeAnalyticsService.trackHomeEvent(HOME_EVENTS_KEY.FORMAT_FILTER as Action);
    }
    this.getPartnerSiteEnabled();
    this.searchStorageService.setHomeFiters(this.selectedAvailability, this.selectedFormat);
    this.myLibraryContent(this.myLibraryList, this.selectedAvailability, this.selectedFormat, true);
    this.titleforRecommendation(this.myLibraryList, this.selectedAvailability, this.selectedFormat);
    this.myLibraryContent(this.myLibraryList, this.selectedAvailability, this.selectedFormat);
    if (this.isCollectionEnabled && !this.isPPCOnlyLibrary) {
      const format =
        this.selectedFormat === HOME_FORMAT_TYPE[0].value || this.selectedFormat === HOME_FORMAT_TYPE[2].value
          ? HOME_FORMAT_TYPE[0].value
          : HOME_FORMAT_TYPE[1].value;
      this.alwaysAvailableContent(this.myCollectionList, format);
    }
  }
  /**
   * To retrigger checkout corousel after checkout title
   * @returns returns nothing
   */
  async titleCheckedOutEvent() {
    const myLibraryListDataTempCheckout = [];
    const getListContentCheckout = await this.homeService.getListContent(
      CAROUSEL_CONST.checkout,
      this.selectedAvailability,
      this.selectedFormat
    );
    if (!getListContentCheckout) {
      this.myLibraryListDataCheckout = [];
      return;
    }
    this.myLibraryListContent = { ...getListContentCheckout, listName: CAROUSEL_CONST.Currently_Reading, listId: CAROUSEL_CONST.checkout };
    const myListCheckout = await this.listDestructure(this.myLibraryListContent, CAROUSEL_CONST.checkout);
    if (myListCheckout) {
      myLibraryListDataTempCheckout.push(myListCheckout);
      for (const listContent of myListCheckout.Items) {
        this.getActionViewButtonForLibraryList(listContent, myListCheckout.listName, listContent?.ISBN, true);
      }
      this.myLibraryListDataCheckout = myLibraryListDataTempCheckout;
      setTimeout(() => {
        const parentElementId = document.getElementById(this.engageService.homeCarouselListId);
        if (parentElementId) {
          parentElementId.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  }
  /**
   * To get recommendation titles
   * @param myLibList input param as Library List
   * @param selectedAvailability input param as selected availability filter value
   * @param selectedFormat input param as selected format filter value
   * @param isFromHome input param as fromHome component flag
   */
  async titleforRecommendation(myLibList = [], selectedAvailability, selectedFormat, isFromHome = false) {
    if (isFromHome) {
      myLibList = this.homeService.myLibraryList;
      selectedAvailability = this.selectedAvailability;
      selectedFormat = this.selectedFormat;
    }
    this.homeService.myLibraryListDataRecommendation = [];
    const myLibraryListDataTempRecommendation = [];
    const myLibraryListDataRecommend = myLibList.filter(
      (el) => el?.ListID === CAROUSEL_CONST.Recommended_for_you || el?.ListID === CAROUSEL_CONST.Based_your_Interests
    );
    for (const list of myLibraryListDataRecommend) {
      const getListContent = await this.homeService.getListContent(list?.ListID, selectedAvailability, selectedFormat);
      this.myLibraryListContent = { ...getListContent, listName: list?.ListName, listId: list?.ListID };
      const myList = await this.listDestructure(this.myLibraryListContent, list?.ListID);
      if (myList) {
        myLibraryListDataTempRecommendation.push(myList);
        for (const listConstent of myList.Items) {
          this.getActionViewButtonForLibraryList(listConstent, myList.listName, listConstent?.ISBN, false, true);
        }
      }
      this.homeService.myLibraryListDataRecommendation = myLibraryListDataTempRecommendation;
    }
  }
  /**
   * when we change filter, this function triggered
   * @param event input param as event
   * @returns returns nothing
   */
  filterChangeEvent(event: boolean) {
    if (event) {
      this.overlayContainer.getContainerElement().classList.add('refiner-overlay-container');
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('refiner-overlay-container');
  }
}