import { ConfigService } from './../../shared/services/config.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BookDetail } from '@readingprograms/shared/data/engage.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { AlertDialogComponent } from '@shared/components/alert-dialog/alert-dialog.component';
import { CurrentLibrary } from '@shared/data/config.model';
import { NotificationType, UserNotification } from '@shared/data/user.model';
import { getTimeAMPM, getDate, isToday, forceRedirect } from '@shared/helper/app.util';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { noop } from 'rxjs';
import { ProgramsService } from '../reading-programs/shared/services/programs.service';
import { NOTIFICATIONS_EVENT_KEY } from './shared/constants/notifications.analytics.constants';
import {
  MENU_OPTS,
  MENU_OPTS_VAL,
  MSG_BUTTON_TEXT,
  MSG_DATA_CATEGORY,
  MSG_STATUS,
  NOTIFICATIONS_ELEM_ID,
  NOTIFICATIONS_PAGE_SIZE,
  NOTIFICATIONS_SCROLL_BUFFER
} from './shared/constants/notifications.constants';
import { MessagesResponse, Message } from './shared/data/notifications.model';
import { NotificationsAnalyticsService } from './shared/services/notifications.analytics.service';
import { NotificationsService } from './shared/services/notifications.service';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { handleProgramDetails } from '@readingprograms/programs/shared/helper/programs.util';
import { DatePipe } from '@angular/common';
interface MenuOptInfo {
  value: string;
  viewValue: string;
  ariaDescription: string;
}
@Component({
  selector: 'axis360-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  messages: Message[] = [];
  messageIdList: string[] = [];
  menuOptions: MenuOptInfo[] = [
    { value: MENU_OPTS.ALL, viewValue: MENU_OPTS_VAL[MENU_OPTS.ALL], ariaDescription: 'Select all notifications' },
    { value: MENU_OPTS.NONE, viewValue: MENU_OPTS_VAL[MENU_OPTS.NONE], ariaDescription: 'Unselect all notifications' },
    { value: MENU_OPTS.READ, viewValue: MENU_OPTS_VAL[MENU_OPTS.READ], ariaDescription: 'Select all read notifications' },
    { value: MENU_OPTS.UNREAD, viewValue: MENU_OPTS_VAL[MENU_OPTS.UNREAD], ariaDescription: 'Select all unread notifications' }
  ];
  pageIndex = 1;
  isAllChecked = false;
  msgData?: {
    name: string;
    description: string;
    status?: string;
    readByDate?: string;
    startDate?: string;
    detailsLink?: string;
    text?: string;
    category?: string;
    link?: string;
    title?: string;
    data?: any;
    bookDetail?: BookDetail;
    programDetail?: ProgramDetails;
    messageIcon?: any;
  };
  isLoading: boolean;
  expandedMsgs = new Set();
  isScrollListenerAdded: boolean;
  MSG_STATUS = MSG_STATUS;
  isLoadingRP: boolean;
  overlayLoading = false;
  userNotification: UserNotification[];
  selectedMsgStatus: string = '';
  currentLibrary: Partial<CurrentLibrary>;
  constructor(
    public notifitcationsDialog: MatDialogRef<NotificationsComponent>,
    public matDialog: MatDialog,
    public notificationsService: NotificationsService,
    public notificationsAnalyticsService: NotificationsAnalyticsService,
    public programService: ProgramsService,
    public liveAnnouncer: LiveAnnouncer,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public engageService: EngageService,
    public overlayContainer: OverlayContainer,
    public router: Router,
    public userService: UserService,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public datePipe: DatePipe,
  ) {
    iconRegistry.addSvgIcon('DELETE_IMAGE', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-trash.svg'));
    iconRegistry.addSvgIcon(
      'UNREAD_IMAGE',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-invisible.svg')
    );
  }
  /**
   * To get first set messages on init
   * And call messages count on dialog close
   */
  ngOnInit() {
    this.overlayContainer.getContainerElement().classList.remove('refiner-overlay-container');
    this.notificationsAnalyticsService.trackScreen('NOTIFICATIONS');
    this.currentLibrary = this.configService.currentLibrary;
    this.loadMessages();
    this.notifitcationsDialog.afterClosed().subscribe(() => this.notificationsService.updateMessagesCount());
    this.notifitcationsDialog.backdropClick().subscribe(() => this.notifitcationsDialog.close());
    this.userNotification = this.userService.userInfo.notifications;
    this.loaduserNotification();
  }
  loaduserNotification() {
    (this.userNotification || []).forEach((notification) => {
      notification.message = '';
      const setNotificationMessage = {
        [NotificationType.HoldReserved]: () => (notification.message = notification.titleName + ' is ready for checkout'),
        [NotificationType.Assignment]: () =>
          (notification.message = notification.titleName + ' has been assigned to your group ' + notification.groupName)
      };
      setNotificationMessage[notification.type]();
    });
  }
  /**
   * To enable or disable close popup on menus state change
   * Handles ESC key press for both menu and dialog
   * @param state menu opened or closed flag
   */
  menuStateChange(state: boolean) {
    if (state) {
      this.notifitcationsDialog.disableClose = true;
      document.getElementById('matSidenav')?.setAttribute('aria-hidden', 'true');
      document.getElementById('matSidenav')?.setAttribute('tabindex', '-1');
      document.querySelector('.notification-modal-container')?.setAttribute('tabindex', '-1');
      setTimeout(() => {
        const menuItems = document.querySelectorAll('.mat-menu-item');
        if (menuItems.length > 0) {
          const firstMenuItem = menuItems[0] as HTMLElement;
          firstMenuItem.focus();
          menuItems[0]?.setAttribute('cdkFocusRegionStart', 'true');
          menuItems[0]?.setAttribute('cdkFocusInitial', 'true');
          menuItems[menuItems.length - 1]?.setAttribute('cdkFocusRegionEnd', 'true');
        }
      }, 100);
      return;
    }
    // This timeout is needed for enabling close after menu is opened and tapping ESC
    setTimeout(() => {
      this.notifitcationsDialog.disableClose = false;
      document.getElementById('matSidenav')?.removeAttribute('aria-hidden');
      document.getElementById('matSidenav')?.removeAttribute('tabindex');
      document.querySelector('.notification-modal-container')?.removeAttribute('tabindex');
    }, 300);
    setTimeout(() => {
      document.getElementById('loc_notifications_menu').setAttribute('aria-expanded', 'false');
      const locNotificationsMenu = document.getElementById('loc_notifications_menu');
      locNotificationsMenu.focus();
    }, 500);
  }
  /**
   * To get notifications div elem of notifications component
   */
  getNotificationsDivElem = (): HTMLElement | null => document.getElementById(NOTIFICATIONS_ELEM_ID);
  /**
   * Remove scroll listener on destroy
   */
  ngOnDestroy(): void {
    this.getNotificationsDivElem()?.removeEventListener('scroll', noop);
  }
  /**
   * To determines whether pagination is required or not
   * @returns true if pagination required
   */
  isPaginationReq(): boolean {
    return this.messages.length >= this.pageIndex * NOTIFICATIONS_PAGE_SIZE;
  }
  /**
   * To load more messgaes on scroll of notifications dialog
   */
  handleScroll = (): void => {
    const elem = this.getNotificationsDivElem();
    if (!elem || !this.isPaginationReq()) {
      return;
    }
    const scrollTop = elem.scrollTop;
    const scrollHeight = elem.scrollHeight;
    const offsetHeight = elem.offsetHeight;
    const contentHeight = scrollHeight - offsetHeight - NOTIFICATIONS_SCROLL_BUFFER;
    if (!this.isLoading && contentHeight <= scrollTop) {
      this.pageIndex++;
      this.loadMessages();
    }
  };
  /**
   * To be called on scorll
   */
  onScroll(): void {
    if (this.isScrollListenerAdded || this.pageIndex < 1 || !this.getNotificationsDivElem()) {
      return;
    }
    this.isScrollListenerAdded = true;
    this.getNotificationsDivElem()?.addEventListener('scroll', this.handleScroll);
  }
  /**
   * To gets date time string for UI based on isToday check
   * @param createdDate message created date
   * @returns date time string
   */
  getDateTimeStr(createdDate: Date): string {
    const createdDateObj = new Date(createdDate);
    return isToday(createdDate) ? getTimeAMPM(createdDateObj) : this.getDateMonth(createdDate);
  }
  /**
   * To gets books details from API
   * @param bookIdsSet list of book ids
   * @returns books details
   */

  getDateMonth(date?: string | Date) {
    return date ? this.datePipe.transform(date, 'MMM dd, yyyy') : '';
  }

  async getBooksDetails(bookIdsSet: Set<string>): Promise<{ [itemId: string]: BookDetail }> {
    try {
      const bookIds = [...bookIdsSet];
      if (bookIds.length === 0) {
        return {};
      }
      const booksDetails = await this.engageService.getBooksByIdV1(bookIds);
      return booksDetails.items.reduce((acc, cur) => {
        acc[cur.itemId] = cur;
        return acc;
      }, {});
    } catch (e) {
      return {};
    }
  }
  /**
   * To replace book id in message description
   * @param messages list of messages
   * @param bookDetails book details
   * @returns  messages with replaced description
   */
  updateBookId(messages: Message[], bookDetails: { [itemId: string]: BookDetail }) {
    messages.forEach((item: Message) => {
      if (item.lookupBookId) {
        item.description = item.description.replace('<<bookId>>', (bookDetails[item.lookupBookId]?.title) || 'Title');
        item.bookDetail = bookDetails[item.lookupBookId];
      }
    });
    return messages;
  }
  /**
   * To load the messages on init and scroll
   */
  loadMessages(): void {
    this.isLoading = true;
    this.notificationsService.getMessages(this.pageIndex).subscribe(
      async (result: MessagesResponse) => {
        const bookIdsSet: Set<string> = new Set();
        const { messages = [] } = result;
        messages.forEach((item: Message) => {
          item.dateTimeStr = this.getDateTimeStr(item.createdDate);
          item.data = item.data || [{}];
          item.description = item.description || '';
          if (item.description.indexOf('<<bookId>>') > -1) {
            const bookId = (item.data.find((obj) => obj.category === 'lookup' && obj.text === 'bookId'))?.uri;
            if (bookId) {
              bookIdsSet.add(bookId);
              item.lookupBookId = bookId;
            }
          }
        });
        const bookDetails = await this.getBooksDetails(bookIdsSet);
        const updatedMessages = this.updateBookId(messages, bookDetails);
        this.messages = this.pageIndex > 1 ? this.messages.concat(updatedMessages) : updatedMessages;
        this.isAllChecked = this.isAllMsgsChecked();
        this.onScroll();
        this.isLoading = false;
        this.overlayLoading = false;
        this.expandedMsgs.clear();
      },
      () => {
        this.expandedMsgs.clear();
        this.isLoading = false;
        this.overlayLoading = false;
        if (this.pageIndex > 1) {
          this.pageIndex--;
        }
      }
    );
  }
  /**
   * To select/unselect all check box
   * @param isChecked checked or not flag
   */
  selectAllCheckBox(isChecked: boolean): void {
    this.isAllChecked = isChecked;
    this.messageIdList = isChecked ? this.messages.map(({ messageId }) => messageId) : [];
    this.notificationsAnalyticsService.trackNotificationsEvent(isChecked ? 'SELECT_ALL' : 'SELECT_NONE');
  }
  /**
   * To select message by status
   * @param msgStatus message status
   */
  selectMsgsByStatus(msgStatus: string): void {
    this.messageIdList = this.messages.filter(({ status }) => status === msgStatus).map(({ messageId }) => messageId);
    this.isAllChecked = this.isAllMsgsChecked();
  }
  /**
   * To do msg center action
   * @param menuVal menu value
   */
  doMsgCenterAction(menuVal: string): void {
    this.selectedMsgStatus = menuVal !== 'delete' ? menuVal : '';
    const selectMsgsByStatusFunc = () => this.selectMsgsByStatus(menuVal);
    const menuActMap: { [key: string]: () => void } = {
      [MENU_OPTS.ALL]: () => this.selectAllCheckBox(true),
      [MENU_OPTS.NONE]: () => this.selectAllCheckBox(false),
      [MENU_OPTS.READ]: selectMsgsByStatusFunc,
      [MENU_OPTS.UNREAD]: selectMsgsByStatusFunc,
      [MENU_OPTS.DELETE]: () => this.updateMsgs(menuVal),
      [MENU_OPTS.CANCEL]: noop
    };
    menuActMap[menuVal]();
    const eventsKeyMap: { [key: string]: keyof typeof NOTIFICATIONS_EVENT_KEY } = {
      [MENU_OPTS.ALL]: 'SELECT_ALL',
      [MENU_OPTS.NONE]: 'SELECT_NONE',
      [MENU_OPTS.READ]: 'SELECT_READ',
      [MENU_OPTS.UNREAD]: 'SELECT_UNREAD',
      [MENU_OPTS.DELETE]: 'DELETE',
      [MENU_OPTS.CANCEL]: 'CANCEL_MENU'
    };
    this.notificationsAnalyticsService.trackNotificationsEvent(eventsKeyMap[menuVal]);
  }
  /**
   * To do actions on messages
   * @param status status to update
   */
  doActionsOnMsgs(status: string) {
    const eventsKeyMap: { [key: string]: keyof typeof NOTIFICATIONS_EVENT_KEY } = {
      [MSG_STATUS.READ]: 'MARK_AS_READ',
      [MSG_STATUS.UNREAD]: 'MARK_AS_UNREAD'
    };
    this.updateMsgs(status);
    this.notificationsAnalyticsService.trackNotificationsEvent(eventsKeyMap[status]);
  }
  /**
   * Determines whether message is checked or not
   * @param messageId message id
   * @returns true if message checked
   */
  isMsgChecked(messageId: string): boolean {
    return this.messageIdList.indexOf(messageId) > -1;
  }
  /**
   * Determines whether all messages is checked
   * @returns true if all messages checked
   */
  isAllMsgsChecked(): boolean {
    const selectedMsgsLength = this.messageIdList.length;
    if (!selectedMsgsLength) {
      return false;
    }
    return selectedMsgsLength === this.messages.length;
  }
  /**
   * To check/uncheck message
   * @param isChecked flag for checked or not
   * @param messageId message id
   * @returns message
   */
  checkedMessage(isChecked: boolean, messageId: string): void {
    if (isChecked) {
      this.messageIdList.push(messageId);
      this.isAllChecked = this.isAllMsgsChecked();
      this.notificationsAnalyticsService.trackNotificationsEvent('SELECT_MSG');
      return;
    }
    this.isAllChecked = false;
    this.messageIdList = this.messageIdList.filter((item) => item !== messageId);
    this.notificationsAnalyticsService.trackNotificationsEvent('UNSELECT_MSG');
  }
  /**
   * To update messages
   * @param status message status
   */
  updateMsgs(status: string): void {
    if (this.messageIdList.length === 0) {
      return;
    }
    const markActMap: { [key: string]: string } = {
      [MENU_OPTS.READ]: 'Notification marked as read',
      [MENU_OPTS.UNREAD]: 'Notification marked as unread'
    };
    this.liveAnnouncer.announce(markActMap[status], 'assertive');
    this.focusOnClose();
    const updateMessagesrequest = { message: this.messageIdList.map((messageId) => ({ messageId, status })) };
    this.overlayLoading = true;
    this.notificationsService.updateMessages(updateMessagesrequest).subscribe((result) => {
      this.overlayLoading = false;
      this.notificationsService.updateMessagesCount();
      this.messageIdList = [];
      this.isAllChecked = false;
      if (result) {
        this.pageIndex = 1;
        this.loadMessages();
      }
    });
  }
  /**
   * To update single message
   * @param messageId message id
   * @param status message status
   * @param [reloadMsgs] flag to reload or not
   */
  updateMessage(messageId: string, status: string, reloadMsgs = true): void {
    const eventsKeyMap: { [key: string]: keyof typeof NOTIFICATIONS_EVENT_KEY } = {
      [MSG_STATUS.READ]: 'MARK_AS_READ',
      [MSG_STATUS.UNREAD]: 'MARK_AS_UNREAD',
      [MSG_STATUS.DELETE]: 'DELETE'
    };
    if (status === MSG_STATUS.DELETE || status === MSG_STATUS.UNREAD) {
      this.overlayLoading = true;
    }
    this.liveAnnouncer.announce(status === MSG_STATUS.UNREAD ? 'Notification marked as unread' : '', 'assertive');
    this.notificationsService.updateMessages({ message: [{ messageId, status }] }).subscribe(
      (result) => {
        this.notificationsService.updateMessagesCount();
        if (!result) {
          this.overlayLoading = false;
          return;
        }
        if (reloadMsgs) {
          this.pageIndex = 1;
          this.loadMessages();
          this.focusOnClose();
          return;
        }
        const msgIndex = this.messages.findIndex(({ messageId: msgId }) => msgId === messageId);
        this.messages[msgIndex].status = status;
        this.overlayLoading = false;
      },
      () => {
        this.overlayLoading = false;
      }
    );
    this.notificationsAnalyticsService.trackNotificationsEvent(eventsKeyMap[status]);
  }
  /**
   * Gets details url
   * @param message message data
   * @returns details url
   */
  getDetailsURL({ data }: Message): string {
    const [{ category, uri }] = data;
    const catMap: { [key: string]: string } = {
      [MSG_DATA_CATEGORY.READINGPROGRAM]: '/ng/program/'
    };
    const catStr = catMap[category];
    return catStr + uri;
  }
  /**
   * Gets reading program
   * @param uri program id
   * @param msgInfo message info
   * @param msgData message data
   */
  getReadingProgram(uri: string, msgInfo: { name: string; description: string; link: string; text: string; bookDetail: BookDetail }, msgData: Message): void {
    this.isLoadingRP = true;
    this.programService.getProgramById(uri).subscribe(
      async (result) => {
        if (!result || result.errors) {
          this.msgData = msgInfo;
          this.isLoadingRP = false;
          return;
        }

        this.msgData = {
          name: result.programName,
          description: result.description,
          status: result.status,
          readByDate: getDate(result.readByDate),
          startDate: getDate(result.startDate),
          link: msgInfo.link,
          text: msgInfo.text,
          bookDetail: msgInfo.bookDetail,
          title: msgData.title,
          programDetail: handleProgramDetails(result)

        };
        this.msgData.detailsLink = this.getDetailsURL(msgData);
        this.msgData.text = this.msgData.text ? this.msgData.text : MSG_BUTTON_TEXT.GO_TO_PROGRAM;
        this.isLoadingRP = false;
      },

      () => {
        this.msgData = msgInfo;
        this.isLoadingRP = false;
      }
    );
  }
  /**
   * To be called on opening the message details
   * @param msgData message data
   */
  openDetails(msgData: Message): void {
    this.notificationsAnalyticsService.trackNotificationsEvent('EXPAND_MSG');
    const { messageId, status, data, title, description, bookDetail } = msgData;
    this.expandedMsgs.add(messageId);
    this.isAllChecked = false;
    this.messageIdList = [];
    if (status === MSG_STATUS.UNREAD) {
      this.updateMessage(messageId, MSG_STATUS.READ, false);
    }
    this.msgData = undefined;
    const [{ category = '', uri = '', text = '', link = '' } = {}] = data;
    const msgInfo = { name: title, description, link, text, uri, bookDetail };
    if (category === MSG_DATA_CATEGORY.USER_BADGE || category === MSG_DATA_CATEGORY.USER_GOAL) {
      this.getInsightsBadges(category, text, msgInfo);
      return;
    }
    if (category === MSG_DATA_CATEGORY.USER_TITLE) {
      this.getUserTitle(category, text, msgInfo);
      return;
    }
    if (!uri || !category) {
      this.msgData = msgInfo;
      if (link && link.includes('programs/open')) {
        this.msgData.detailsLink = `/view/programs/open`;
        this.msgData.text = text;
      }
      return;
    }
    if (link && link.includes('mystuff/checkout')) {
      this.msgData = msgInfo;
      this.msgData.detailsLink = `/view/mystuff/checkouts`;
      this.msgData.text = text;
      return;
    }
    if (data[1] && data[1].category === 'Milestone') {
      msgInfo.bookDetail = {
        title: data[1].text,
        formatType: data[1]?.type,
        author: data[1].link,
        ISBN: '',
        isbn: '',
        itemId: '11',
        ItemId: '11',
        isManual: true
      }
    }
    this.getReadingProgram(uri, msgInfo, msgData);
  }
  /**
   *
   * @param category identify category type
   * @param text text sting value
   * @param msgInfo msgInfo displaying name and description
   */
  getUserTitle(category: string, text: string, msgInfo: { name: string; description: string; uri: string }) {
    this.msgData = msgInfo;
    this.msgData.category = category;
    this.msgData.text = text;
    this.msgData.detailsLink = '/view/title/' + msgInfo.uri;
  }
  getInsightsBadges(category: string, text: string, msgInfo: { name: string; description: string }) {
    this.msgData = msgInfo;
    this.msgData.category = category;
    this.msgData.text = text;
    this.msgData.detailsLink = '/view/myshelf';
  }
  openDialog() {
    this.alertDialog({
      panelClass: 'mat-alert-dialog',
      data: {
        content: `'Insights and Badges' has been disabled, please enabled in My Profile`,
        cancelBtnText: 'Cancel',
        cancelBtnFunc: this.cancelDelete.bind(this)
      },
      ariaLabelledBy: 'alert-heading'
    });
  }
  /**
   * To be called on closing message details
   * @param message message data
   */
  closeDetails({ messageId }: Message) {
    this.expandedMsgs.delete(messageId);
    this.notificationsAnalyticsService.trackNotificationsEvent('COLLAPSE_MSG');
  }
  /**
   * Focus on close
   */
  focusOnClose() {
    setTimeout(() => {
      document.getElementById('msg-close-btn').focus();
    }, 200);
  }
  redirectToProgram(url: string) {
    forceRedirect(url);
  }
  /**
   * To be called on Views details button click
   */
  viewDetails(): void {
    this.notifitcationsDialog.close();
    this.notificationsAnalyticsService.trackNotificationsEvent('GO_TO_PROGRAM');
    if (
      (this.msgData.category === MSG_DATA_CATEGORY.USER_GOAL || this.msgData.category === MSG_DATA_CATEGORY.USER_BADGE) &&
      this.profileInfoService.currentProfile.enableTracking == 'False'
    ) {
      this.openDialog();
      return;
    }
    this.redirectToProgram(this.msgData.detailsLink);
  }
  alertDialog(data: {}) {
    this.matDialog.open(AlertDialogComponent, data);
  }
  /**
   * Opens alert dialog
   * @param [noOfMsgs] number of messages selected
   * @param [messageId] message id
   */
  openAlertDialog(noOfMsgs = 0, messageId = '') { 
    document.querySelectorAll('.cdk-overlay-container').forEach(dialogHtm =>{
      dialogHtm.children[0].setAttribute('tabindex', '-1');
      dialogHtm.children[0].setAttribute('aria-hidden', 'true');
      dialogHtm.children[1].setAttribute('tabindex', '-1');
      dialogHtm.children[1].setAttribute('aria-hidden', 'true');
    });
    this.alertDialog({
      panelClass: 'mat-alert-dialog',
      data: {
        content: `Are you sure you want to delete ${noOfMsgs && noOfMsgs !== 1 ? noOfMsgs + ' messages' : 'this message'}?`,
        submitBtnText: 'Delete',
        submitBtnFunc: () => {
          noOfMsgs ? this.doMsgCenterAction('delete') : this.updateMessage(messageId, 'delete');
          this.liveAnnouncer.announce('Notification deleted', 'assertive');
          this.focusOnClose();
        },
        cancelBtnText: 'Cancel',
        cancelBtnFunc: this.cancelDelete.bind(this)
      },
      ariaLabelledBy: 'alert-heading',
      ariaDescribedBy: 'alert-heading-desc'
    });
  }
  /**
   * Opens delete popup
   * @param [noOfMsgs] number of messages selected
   * @param [messageId] message id
   */
  openDeletePopup(noOfMsgs = 0, messageId = '') {
    this.openAlertDialog(noOfMsgs, messageId);
    this.notificationsAnalyticsService.trackScreen('DELETE');
  }
  /**
   * Cancels delete
   */
  cancelDelete() {
    this.notificationsAnalyticsService.trackNotificationsEvent('CANCEL');
    const deleteButton: HTMLElement = document.getElementById('msg-delete-all');
    document.querySelectorAll('.cdk-overlay-container').forEach(dialogHtm =>{
      dialogHtm.children[0].removeAttribute('tabindex');
      dialogHtm.children[0].removeAttribute('aria-hidden');
      dialogHtm.children[1].removeAttribute('tabindex');
      dialogHtm.children[1].removeAttribute('aria-hidden');
    });
    setTimeout(() => {
      deleteButton?.focus();
      deleteButton.setAttribute('cdkTrapFocusAutoCapture', 'true');
    }, 200)

  }
  /**
   * Opens more menu
   */
  openMoreMenu() {
    this.notificationsAnalyticsService.trackScreen('MORE_MENU');
    document.getElementById('moreoptionmenu').removeAttribute('aria-expanded');
  }
  /**
   * Closes dialog
   */
  closeDialog(): void {
    this.notificationsAnalyticsService.trackNotificationsEvent('CLOSE');
    this.notifitcationsDialog.close();
  }
  /**
   * Opens menu
   * @param event click event
   */
  openMenu(event: Event): void {
    (event.target as HTMLElement).removeAttribute('aria-expanded');
  }
  /**
   * DELETE dialog - test for sonar scan
   */
  deleteDialog(): void {
    this.notificationsAnalyticsService.trackNotificationsEvent('DELETE');
    this.notifitcationsDialog.close();
  }
}
