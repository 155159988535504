import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IActivityCategory } from '@shared/data/learning-activity.model';
import { MyLibraryAnalyticsService } from '../../shared/services/my-library.analytics.service';

@Component({
  selector: 'axis360-learning-activities-carousel',
  templateUrl: './learning-activities-carousel.component.html',
  styleUrls: ['./learning-activities-carousel.component.scss']
})
export class LearningActivitiesCarouselComponent implements OnInit {
  @Input() index = 1;
  @Input() category: IActivityCategory;
  constructor(public myLibraryAnalyticsService: MyLibraryAnalyticsService, private router: Router) {}

  ngOnInit(): void { }

  trackByViewInterest(category: string) {
    this.router.navigate(['/view', 'learning-activity'], { queryParams: { category } });
    this.myLibraryAnalyticsService.trackMyLibraryLearningViewInterest('INTEREST_IMAGE');
  }
}
