<div tabindex="-1" id="loc_headerId"><p class="kz-visually-hidden" tabindex="-1"></p></div>
<a [href]="skipLink" class="skip-navigation" id="loc_btnSkiptoMain" *ngIf="!hideHeaderFooter && advancedSearchToolbar">Skip to main content</a>

<header class="kz-header crayons-focus-reset print-hidden"  role="banner" (window:resize)="onResize()">
    <mat-toolbar role="navigation">
        <mat-toolbar-row>
            <div class="kz-home-container">
                <div class="header">
                    <div class="kz-header-view" *ngIf="!hideHeaderFooter">
                        <div class="kz-nav-logo" *ngIf="sidebarNav">
                            <a href="javascript:void(0)" matTooltip="Menu" matTooltipClass="kz-card-tooltip" class="hamburger-menu" mat-icon-button
                                (click)="openSidebar()" id="loc_btnHamburgerMenu" aria-controls="main-menu" aria-label="Menu" role="button" [attr.aria-expanded]="sidenavService.isHamburgerExpanded">
                                <mat-icon svgIcon="kz-hamburger" aria-hidden="true"></mat-icon>
                            </a>
                        </div>
                        <div class="logo-div" *ngIf="configService.currentLibrary.branding?.isLogoImg">
                            <a class="brand-logo" [href]="configService.currentLibrary.branding?.logoUrl" (click)="trackByHeaderLogo()"
                                [attr.aria-label]="configService.currentLibrary.name+' logo'">
                                <img [src]="configService.currentLibrary.branding?.headerLogoPath" class="kz-logo"
                                    [alt]="configService.currentLibrary.name+' logo'" aria-hidden="true" />
                            </a>
                        </div>
                        <div class="logo-text-div" *ngIf="!configService.currentLibrary.branding?.isLogoImg">
                            <a class="brand-logo-text double-ellipsis" [href]="configService.currentLibrary.branding?.logoUrl" (click)="trackByHeaderLogo()"
                                [attr.aria-label]="configService.currentLibrary.name+' logo'">
                                {{configService.currentLibrary.branding?.logoTextWeb}}
                            </a>
                        </div>
                        <ng-container *ngIf="!isLoading && isVisibleMobile">
                                <div class="kz-notify kz-visible-xs" *ngIf="userService.isLoggedIn() && userService.isInitialLoaded">
                                    <axis360-kz-notification-icon></axis360-kz-notification-icon>
                                    <axis360-profile-list-dropdown (profilePicClickEvent)="profilePicClick()"></axis360-profile-list-dropdown>
                                </div>
                                <div class="kz-notify kz-visible-xs" *ngIf="!userService.isLoggedIn()">
                                    <button class="kz-login" aria-label="Sign in" id="loginBtn" (click)="loginClick()">Sign
                                        in</button>
                                </div>
                        </ng-container>

                        <div class="kz-wrap" *ngIf="advancedSearchToolbar">
                            <div class="kz-search-main">
                                <label for="middle-initial" class="kz-visually-hidden">Search for content</label>
                                <input type="text" id="middle-initial" class="kz-searchTerm" placeholder="Search for content"
                                    [(ngModel)]="searchText"
                                    (keydown.enter)="search(); headerAnalyticsService.trackHeaderEvent('SEARCH_FOR_CONTENT')" autocomplete="off">
                                    <button *ngIf="searchText?.length" class="close-icon search-close-icon" aria-label="Clear Search"
                                        matTooltip="Clear Search" matTooltipClass="kz-card-tooltip" (click)="clearSearch()" (keydown.enter)="clearSearch()">
                                        <mat-icon svgIcon="kz-close" aria-hidden="true"></mat-icon>
                                    </button>
                                <button type="submit" class="kz-searchButton" [disabled]="!searchText "
                                    matTooltip="Submit Search" matTooltipClass="kz-card-tooltip"
                                    (click)="search(); headerAnalyticsService.trackHeaderEvent('SEARCH_FOR_CONTENT')"
                                    aria-label="Search">
                                    <mat-icon svgIcon="kz-search-icon" aria-hidden="true"></mat-icon>
                                </button>
                            </div>
                            <button (click)="advancedSearch()" id="loc_btnAdvancesrc" (keydown.enter)="advancedSearch()"
                                class="advsearch-button"><span class="advsearch">Advanced
                                    Search</span>
                            </button>
                        </div>
                        <ng-container *ngIf="!isLoading && !isVisibleMobile && signInButton">
                                <div class="kz-notify kz-hidden-xs" *ngIf="userService.isLoggedIn() && userService.isInitialLoaded">
                                    <axis360-kz-notification-icon></axis360-kz-notification-icon>
                                    <axis360-profile-list-dropdown (profilePicClickEvent)="profilePicClick()"></axis360-profile-list-dropdown>
                                </div>
                                <div class="kz-notify kz-hidden-xs" *ngIf="!userService.isLoggedIn()">
                                    <button class="kz-login" aria-label="Sign in" id="loginBtn" (click)="loginClick()">Sign
                                        in</button>
                                </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</header>