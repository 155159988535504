import { Component, OnInit } from '@angular/core';
import { Facet } from '@search/shared/data/filter-model';
import { SearchService } from '@search/shared/services/search.service';
import { PRESS_READER_SORT_OPTIONS, PUBLICATION_TYPES } from '@shared/constants/app.constants';
import { PublicationCard, Publications } from '@shared/data/press-reader-base.model';
import { getPublicationCard } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { PressReaderBaseService } from '@shared/services/press-reader-base.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'axis360-newspaper-magazine',
  templateUrl: './newspaper-magazine.component.html',
  styleUrls: ['./newspaper-magazine.component.scss']
})
export class NewspaperMagazineComponent implements OnInit {
  publicationId: string;
  publicationInfo: PublicationCard;
  newspapersAndMagazinesCard: PublicationCard[] = [];
  newsPapersCard: PublicationCard[] = [];
  country = '';
  language = '';
  isNewspaperAndMagazinesLoading = true;
  PUBLICATION_TYPES = PUBLICATION_TYPES;
  defaultCountryCode: number;
  defaultLanguageCode: number;
  countries: Facet[] = [];
  
  constructor(
    private configService: ConfigService,
    private pressReaderService: PressReaderBaseService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.getCountriesLanguages();
  }

  /**
   * To get the featured publications info
   */
  getNewaspaperAndMagazinesPublications() {
    const featuredPublications: Observable<Publications[]> = forkJoin([
      this.pressReaderService.getMagazines(
        0,
        5,
        PRESS_READER_SORT_OPTIONS.RANK.value,
        this.defaultCountryCode?.toString(),
        this.defaultLanguageCode?.toString()
      ),
      this.pressReaderService.getNewsPaper(
        0,
        5,
        PRESS_READER_SORT_OPTIONS.RANK.value,
        this.defaultCountryCode?.toString(),
        this.defaultLanguageCode?.toString()
      )
    ]);
    featuredPublications.subscribe(
      (publicationsRes) => {
        let newspaperAndMagazinesPublicationsCards: PublicationCard[];
        publicationsRes.forEach((publications) => {
          newspaperAndMagazinesPublicationsCards = (publications.items || []).map((publication) =>
            getPublicationCard(publication, this.countries)
          );
          this.newspapersAndMagazinesCard.push(...newspaperAndMagazinesPublicationsCards);
        });
      },
      (e) => {
        console.log(e);
      }
    );
  }
  /**
   * To get initial values for the filters
   */
  getCountriesLanguages() {
    this.searchService.getCountries('').subscribe(
      (contryFilterRes) => {
        this.countries = contryFilterRes.countries;
        const defaultCountry = this.configService.getPressReaderDefaultCounty();
        const { id: defaultCountryCode } = contryFilterRes.countries.find((country) => country.value === defaultCountry) || {};
        this.defaultCountryCode = defaultCountryCode;
        this.searchService.getLanguages(defaultCountryCode).subscribe((langFilterRes) => {
          const defaultLanguage = this.configService.getPressReaderDefaultLang();
          const { id: defaultLanguageCode } = langFilterRes.languages.find((lan) => lan.value === defaultLanguage) || {};
          this.defaultLanguageCode = defaultLanguageCode;
          this.getNewaspaperAndMagazinesPublications();
        });
      },
      () => {
        console.log('get country error');
      }
    );
  }
}