export const THIRD_PARTY_SCREENS_CATEGORY = {
  TITLE_DETAILS: 'Title Details',
  TITLE_CAROUSEL: 'Title Carousel',
  CATEGORTY_CAROUSEL: 'Category carousel',
  FEATURE_BANNER: 'Feature banner',
  CATEGORTY_LIST_CAROISEL: 'Category listing carousels',
  WIDGETS: 'WIDGETS',
  REFINERS: 'Refine',
  TITLE_ACTIONS: 'Title Actions',
  SEARCH: 'Search'
};

export const THIRD_PARTY_SCREENS_KEY = {
  LANDING_TIRE1: 'LANDING_TIRE1',
  LANDING_TIRE2: 'LANDING_TIRE2',
  LANDING_TIRE3: 'LANDING_TIRE3',
  REFINERS: 'REFINERS',
  CURRENTLY_CHECKOUT: 'CURRENTLY_CHECKOUT'
};

export const THIRD_PARTY_SCREENS_SCREENS: { [key: string]: string } = {
  [THIRD_PARTY_SCREENS_KEY.LANDING_TIRE1]: 'Tier1 Page',
  [THIRD_PARTY_SCREENS_KEY.LANDING_TIRE2]: 'Tier2 Page',
  [THIRD_PARTY_SCREENS_KEY.LANDING_TIRE3]: 'Tier3 Page',
  [THIRD_PARTY_SCREENS_KEY.REFINERS]: 'Refine',
  [THIRD_PARTY_SCREENS_KEY.CURRENTLY_CHECKOUT]: 'Currently checked out'
};

export const THIRD_PARTY_SCREENS_EVENTS_KEY = {
  ACTIONS_SEE_ALL: 'ACTIONS_SEE_ALL',
  ACTIONS_TITLE_COVER_CTA: 'ACTIONS_TITLE_COVER_CTA',
  ACTIONS_PRIMARY_CTA: 'ACTIONS_PRIMARY_CTA',
  ACTIONS_SECONDRY_CTA: 'ACTIONS_SECONDRY_CTA',
  CLICK_CAROUSEL_RIGHT: 'CLICK_CAROUSEL_RIGHT',
  CLICK_CAROUSEL_LEFT: 'CLICK_CAROUSEL_LEFT',
  ACTIONS_CATEGORTY: 'ACTIONS_CATEGORTY',
  ACTIONS_REST: 'ACTIONS_REST',
  ACTIONS_SEARCH: 'ACTIONS_SEARCH',
  ACTIONS_SELECTED: 'ACTIONS_SELECTED',
  ACTIONS_COLLECTIONS: 'ACTIONS_COLLECTIONS'
};
export const THIRD_PARTY_SCREENS_EVENTS: { [key: string]: string } = {
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_SEE_ALL]: '<vendorName> See ALL CTA',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_TITLE_COVER_CTA]: 'Title cover CTA - <title>',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_PRIMARY_CTA]: 'Primary CTA - <primaryCtaName>',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_SECONDRY_CTA]: 'Secondary CTA - <secondaryCtaName>',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.CLICK_CAROUSEL_RIGHT]: 'Right Arrow',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.CLICK_CAROUSEL_LEFT]: 'Left Arrow',

  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_CATEGORTY]: 'category CTA',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_SEARCH]: 'Search',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_REST]: 'Reset',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_SELECTED]: 'selected <refinername>',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_COLLECTIONS]: 'selected <collectionsname>'
};
