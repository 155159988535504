import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[axis360TrapFocus]'
})
export class TrapFocusDirective implements AfterViewInit {
  constructor(private elem: ElementRef) {}
  /**
   * To set ADA tags after view init
   */
  ngAfterViewInit() {
    this.elem.nativeElement.focus();
  }
}
