import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PRESS_READER_EVENTS_KEY } from '@pressreader/shared/constants/press-reader.analytics.constants';
import { MyDateAdapter } from '@pressreader/shared/data/date.model';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { getFullDay, getFullMonth, getFullYear, getPressReaderDate, setCalenderActiveElements } from '@shared/helper/app.util';
import { PressReaderCalenderHeaderComponent } from '../press-reader-calender-header/press-reader-calender-header.component';
export interface CalenderData {
  latestIssues: { issueDate: Date; day: string }[];
  currentIssue: Date;
  cid: string;
  invalidDates: Date[];
  maxDate: Date;
  minDate: Date;
  type: string;
  title: string;
}

@Component({
  selector: 'axis360-press-reader-calendar',
  templateUrl: './press-reader-calendar.component.html',
  styleUrls: ['./press-reader-calendar.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }]
})
export class PressReaderCalendarComponent implements OnInit {
  selected: Date | null;
  isDetailView = true;
  currentIssueDate = new Date();
  issueDates = new Set<string>();
  headerComponent = PressReaderCalenderHeaderComponent;

  /**
   * Creates an instance of press reader calender component.
   * @param dialogRef calender dialog reference
   * @param calenderData calender dialog data
   * @param router activate router
   * @param pressReaderAnalyticsService PR analytics service
   */
  constructor(
    public dialogRef: MatDialogRef<PressReaderCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public calenderData: CalenderData,
    public router: Router,
    public pressReaderAnalyticsService: PressReaderAnalyticsService
  ) { }

  /**
   * To set the current issue date
   */
  ngOnInit(): void {
    this.pressReaderAnalyticsService.trackScreen('SELECT_ISSUE_DATE');
    this.currentIssueDate = new Date(this.calenderData.currentIssue);
    this.selected = this.calenderData.currentIssue;
    this.issueDates = new Set((this.calenderData.latestIssues || []).map(({ issueDate }) => getPressReaderDate(issueDate)));
  }

  dateClass = (cellDate): MatCalendarCellCssClasses => {
    return this.isLatestIssueDate(cellDate) ? 'active-date-class' : '';
  };

  /**
   * To change the list view and calender view
   * @param isDetail input params as boolean value
   */
  onClickDetail(isDetail: boolean) {
    const typeMap = isDetail ? 'SELECT_LIST_TYPE' : 'SELECT_CALENDAR_TYPE';
    this.pressReaderAnalyticsService.trackPressReaderEvent(typeMap as keyof typeof PRESS_READER_EVENTS_KEY);
    this.isDetailView = isDetail;
    if (!isDetail) {
      setCalenderActiveElements(this.onIssueClick);
    }
  }

  /**
   * Close the slect issue dialog
   */
  closeDialog = () => {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CLOSE');
    this.dialogRef.close();
  };

  /**
   * To idendify the given date is current
   * @param issueDate issue date
   * @returns true or false
   */
  isIssueDateCurrent(issueDate: any) {
    if (
      issueDate.day === new Date(this.calenderData.currentIssue).getDate() &&
      issueDate.month === new Date(this.calenderData.currentIssue).getMonth() &&
      issueDate.year === new Date(this.calenderData.currentIssue).getFullYear()
    ) {
      return true;
    }
    return false;
  }

  /**
   * To idendify the given date is current
   * @param issueDate issue date
   * @returns true or false
   */
  isIssueCurrent(issueDate: Date) {
    return getPressReaderDate(new Date(issueDate)) === getPressReaderDate(new Date(this.calenderData.currentIssue));
  }

  /**
   * To get date format for press reader API
   * @param date issue date
   * @returns date format for pr API
   */
  getPressReaderDate = (date?: string | Date) => {
    return getFullYear(date) + '-' + getFullMonth(date) + '-' + getFullDay(date);
  };

  /**
   * To handle the event when issue is click
   * @param issueDate issue date
   */
  onIssueClick = (issueDate: Date) => {
    this.pressReaderAnalyticsService.trackPressReaderEvent('SELECT_DATE');
    this.dialogRef.close();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/pressreader/details/' + this.calenderData.cid], {
        queryParams: { issueDate: this.getPressReaderDate(issueDate), type: this.calenderData.type, title: this.calenderData.title }
      })
    );
  };

  isLatestIssueDate = (date: Date) => {
    return this.issueDates.has(getPressReaderDate(date));
  };
}
