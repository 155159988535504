import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { TitleStateInfo } from '@shared/data/engage-base.model';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  IBookDetail,
  IButtonDetails,
  ILearningActivityRequest,
  ILearningActivityResponse,
  IPatronReviewDialogInputDetails,
  IRecommendTitle,
  ISeriesTitleDetails,
  ISubmitRating,
  ISubmitRatings,
  ISubmittedReviewResult,
  ITitleDetailsWithActions,
  ITitleReviews,
  ListContentTitleOptions
} from '../data/title-details.model';

@Injectable({
  providedIn: 'root'
})
export class TitleDetailsService extends EngageBaseService {
  public updateRatingStarSubject = new BehaviorSubject<ISubmittedReviewResult>({ submitted: false });
  public updateButtonViewsSubject = new Subject<TitleStateInfo>();
  isLoadRecommendationListSubject = new Subject<any>();
  isLoadRatingsSubject = new Subject<any>();
  isLoadReviewSubject = new Subject<any>();
  itemsTitleDetails: any;
  titleDetailsActions: any;
  titleCheckOutOrNot = new Subject<boolean>();
  titleCheckOutOrNotData$ = this.titleCheckOutOrNot.asObservable();
  recommendedTitleBooks: IBookDetail[];
  recommendedSeriesTitleBooks: IBookDetail[];
  titleDetailsRating = new BehaviorSubject<any>(null);
  thirdPartyToast = new BehaviorSubject<any>(null);
  statusUserAgentCheck;
  titleDetailsStautsCheck;
  titleDetailsStautsContent;
  shareSnackBarToastMsg = false;
  /**
   * set the recommendedTitleDetails
   * @param [recommendation]
   */
  public setRecommendationList(recommendedTitleDetails: any) {
    this.isLoadRecommendationListSubject.next(recommendedTitleDetails);
  }

  /**
   *
   * @returns pass the recommendedTitleDetails list value
   */
  public getRecommendationList(): Observable<any> {
    return this.isLoadRecommendationListSubject.asObservable();
  }

  /**
   * set the ratingsSubject
   * @param [recommendation]
   */
  public setRatingsSubject(ratingsSubject: any) {
    this.isLoadRatingsSubject.next(ratingsSubject);
  }

  /**
   *
   * @returns pass the ratingsSubject list value
   */
  public getRatingsSubject(): Observable<any> {
    return this.isLoadRatingsSubject.asObservable();
  }

  /**
   * set the reviewSubject
   * @param [ReviewSubject]
   */
  public setReviewSubject(reviewSubject: any) {
    this.isLoadReviewSubject.next(reviewSubject);
  }

  /**
   *
   * @returns pass the reviewSubject list value
   */
  public getReviewSubject(): Observable<any> {
    return this.isLoadReviewSubject.asObservable();
  }

  /**
   * set the Rating lsit
   * @param [user]
   */
  public setUserRatingTitle(user: any) {
    this.updateRatingStarSubject.next(user);
  }

  /**
   *
   * @returns list update Button
   */
  public getUserRatingTitle(): Observable<any> {
    return this.updateRatingStarSubject.asObservable();
  }

  /**
   *
   * @returns setTitleCheckOutOrNot
   */
  public setTitleCheckOutOrNot(details: any) {
    return this.titleCheckOutOrNot.next(details);
  }
  /**
     *
     * @returns setTitleCheckOutOrNot
     */
  public getTitleCheckOutOrNot(): Observable<any> {
    return this.titleCheckOutOrNot.asObservable();
  }
  /**
   * set the Button lsit
   * @param [user]
   */
  public setUpdateButtonView(updateBtnCTA: any) {
    this.updateButtonViewsSubject.next(updateBtnCTA);
  }

  /**
   *
   * @returns list update Button
   */
  public getUpdateButtonView(): Observable<any> {
    return this.updateButtonViewsSubject.asObservable();
  }


  /**
   * API Call for getting the Title Details
   * @param [itemId]
   * @param [isbn]
   * @param [isRecommendable]
   * @returns ITitleDetails
   */
  getTitleDetails = (itemId: string, isbn: string, isRecommendable: boolean): Promise<ITitleDetailsWithActions> =>

    this.http
      .get<ITitleDetailsWithActions>(
        API_INFO.getTitleDetails
          .replace('<itemId>', `${itemId}`)
          .replace('<isbn>', `${isbn}`)
          .replace('<isRecommendable>', `${isRecommendable}`)
      )
      .toPromise();

  /**
* API Call for getting the Title Details
* @param [itemId]
* @returns ITitleDetails
*/
  getTitleDetailItem = (itemId: string): Promise<ITitleDetailsWithActions> =>

    this.http
      .get<ITitleDetailsWithActions>(
        API_INFO.getTitleDetailsItem
          .replace('<itemId>', `${itemId}`)
      )
      .toPromise();
  /**
   * API Call for getting the recommended Title Details
   * @param [itemId]
   * @param [isbn]
   * @param [subject]
   * @param [genres]
   * @param [authors]
   * @param [isRecommendable]
   * @param [formatType]
   * @returns IRecommendTitle
   */
  getRecommendedTitleDetails = (
    itemId: string,
    isbn: string,
    subject: string,
    genres: string,
    authors: string,
    isRecommendable: boolean,
    formatType: string,
    collectionType: string
  ): Promise<IRecommendTitle[]> =>
    this.http
      .get<IRecommendTitle[]>(
        API_INFO.getRecommendedTitles
          .replace('<itemId>', `${itemId}`)
          .replace('<isbn>', `${isbn}`)
          .replace('<subject>', `${encodeURIComponent(subject)}`)
          .replace('<genres>', `${genres}`)
          .replace('<authors>', `${authors}`)
          .replace('<isRecommendable>', `${isRecommendable}`)
          .replace('<formatType>', `${formatType}`)
          .replace('<profileTypeId>', `${this.getProfile()}`)
          .replace('<collectionType>', `${collectionType}`)
      )
      .toPromise();

  /**
   * API Call for getting the Title Reviews
   * @param [itemId]
   * @returns
   */
  getTitleReviews = (itemId: string): Promise<ITitleReviews> =>
    this.http.get<ITitleReviews>(API_INFO.getTitleReviews.replace('<itemId>', `${itemId}`)).toPromise();

  /**
   * API Call for getting the All Title Reviews
   * @param [itemId]
   * @returns
   */
  getAllReviews = (itemId: string,): Promise<ITitleReviews> =>
    this.http.get<ITitleReviews>(API_INFO.getAllReviews.replace('<itemId>', `${itemId}`)).toPromise();

  /**
   * API Call for getting the Button views
   * @param [itemId]
   * @param [isbn]
   * @returns
   */
  getButtonViews = (itemId: string, isbn: string): Promise<IButtonDetails> =>
    this.http.get<IButtonDetails>(API_INFO.getButtonViews.replace('<itemId>', `${itemId}`).replace('<isbn>', `${isbn}`)).toPromise();

  /**
   * API Call for getting the Patron views
   * @param [itemId]
   * @returns
   */
  getPatronReviews = (itemId: string, collectionId = ''): Promise<IPatronReviewDialogInputDetails> =>
    this.http.get<IPatronReviewDialogInputDetails>(API_INFO.getPatronReviews.replace('<itemId>', `${itemId}`).replace('<collectionId>', `${collectionId}`)).toPromise();

  /**
   * API Call for posting the review
   * @param [itemId]
   * @param [content]
   * @param [reviewDate]
   * @param [userName]
   * @param [rating]
   * @returns
   */
  submitRating = (itemId: string, content: string, reviewDate: string, userName: string, rating: string, collectionId = ''): Promise<ISubmitRatings> =>
    this.http
      .post<ISubmitRatings>(
        API_INFO.postRatingSubmit
          .replace('<itemId>', `${itemId}`)
          .replace('<content>', `${content}`)
          .replace('<reviewDate>', `${reviewDate}`)
          .replace('<userName>', `${userName}`)
          .replace('<rating>', `${rating}`)
          .replace('<bookRating>', `${rating}`)
          .replace('<reviewContent>', `${encodeURIComponent(content.toString())}`)
          .replace('<collectionId>', `${collectionId}`),
        {}
      )
      .toPromise();


  /**
* API Call for posting the review
* @param [itemId]
* @param [content]
* @param [reviewDate]
* @param [userName]
* @param [rating]
* @returns
*/
  submitReview = (itemId: string, content: string, reviewDate: string, userName: string, rating: string, collectionId = ''): Promise<ISubmitRating> =>
    this.http
      .post<ISubmitRating>(
        API_INFO.postReviewSubmitted
          .replace('<itemId>', `${itemId}`)
          .replace('<content>', `${content}`)
          .replace('<reviewDate>', `${reviewDate}`)
          .replace('<userName>', `${userName}`)
          .replace('<rating>', `${rating}`)
          .replace('<bookRating>', `${rating}`)
          .replace('<reviewContent>', `${encodeURIComponent(content.toString())}`)
          .replace('<collectionId>', `${collectionId}`),
        {}
      )
      .toPromise();

  /**
 * API Call for getting the Title Rating
 * @param [itemId]
 * @returns
 */
  getAllUserRatings = (itemId: string, collectionId = ''): Promise<ISubmitRating> =>
    this.http.get<ISubmitRating>(API_INFO.getAllUserRatings.replace('<itemId>', `${itemId}`).replace('<collectionId>', `${collectionId}`)).toPromise();


  /**
   * API Call for get the seriesByTitle
   * @param [seriestitle]
   * @param [searchby]
   * @returns
   */
  getSeriesByTitle = (term: string, searchby: string): Promise<ISeriesTitleDetails> =>
    this.http.get<ISeriesTitleDetails>(API_INFO.getSeriesByTitle
      .replace('<term>', `${term}`)
      .replace('<searchby>', `${searchby}`)
    )
      .toPromise();

  getListContentTitle = (options: ListContentTitleOptions): Promise<any> =>
    this.http.get<any>(API_INFO.getListContentTitle
      .replace('<sortby>', `${options.sortby}`)
      .replace('<sortorder>', `${options.sortorder}`)
      .replace('<term>', `${options.term}`)
      .replace('<searchby>', `${options.searchby}`)
      .replace('<availability>', `${options.availability}`)
      .replace('<format>', `${options.format}`)
      .replace('<releasedate>', `${options.releasedate}`)
      .replace('<addeddate>', `${options.addeddate}`)
      .replace('<language>', `${options.language}`)
      .replace('<agelevel>', `${options.agelevel}`)
      .replace('<collections>', `${options.collections}`)
      .replace('<pageSize>', `${options.pageSize}`)
      .replace('<page>', `${options.page}`)
      .replace('<ereadalong>', `${options.ereadalong}`)
      .replace('<itemid>', `${options.itemId}`)
      .replace('<profileTypeId>', `${this.getProfile()}`)

    )
      .toPromise();


  /**
   *
   * @param [itemId]
   * @param [recordsCount]
   * @param [offset]
   * @returns
   */
  getAllUserReviews = (itemId: string, recordsCount: number, offset: number, collectionId = ''): Observable<any> =>
    this.http.get<any>(API_INFO.getAllUserReviews
      .replace('<itemId>', `${itemId}`)
      .replace('<recordsCount>', `${recordsCount}`)
      .replace('<offset>', `${offset}`)
      .replace('<collectionId>', `${collectionId}`),
    );

  /**
   * get the Learning Activity base on ISBN
   * @param [patronName]
   * @param [learningActivityData]
   * @returns ILearningActivityResponse
   */
  getLearningActivityByISBN = (learningActivityData: ILearningActivityRequest): Promise<ILearningActivityResponse> =>
    this.http.post<ILearningActivityResponse>(API_INFO.getLearningActivityByISBN, learningActivityData)
      .toPromise();

  /**
   * get the Alternate Format Title base on ISBN
   * @param isbn [isbn]
   * @returns Alternate Format Title
   */
  getTitleAlternateFormat = (isbn: string, itemId: string): Promise<any> =>
    this.http.get<any>(API_INFO.getTitleAlternateFormat
      .replace('<isbn>', `${isbn}`)
      .replace('<itemId>', `${itemId}`)
    ).toPromise();

  getProfile() {
    const profileId = this.configService.currentLibrary.axis360Enabled
      ? ''
      : '2';
    return this.profileInfoService.currentProfile.profileTypeId
      ? this.getProfileId() : profileId;
  }

  getProfileId() {
    const profileTypeId = this.profileInfoService.currentProfile.profileTypeId === '1'
      ? '2'
      : this.profileInfoService.currentProfile.profileTypeId;
    return this.configService.currentLibrary.axis360Enabled
      ? this.profileInfoService.currentProfile.profileTypeId
      : profileTypeId;
  }

  /**
   * Send Mail to SMPT server
   * @param [emailBody]
   * @returns send mail response success or Not
   */
  sendMail = (emailBody: any): Promise<any> => this.http.post<any>(API_INFO.sendmail, emailBody).toPromise();
}
