import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { ProgramsAPIRes } from '@shared/data/programs-base.model';
import { ConfigService } from '@shared/services/config.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { GET_MY_PROGRAMS_FILTERBY_STATUS, MY_PROGRAMS_SIZE } from '../constants/my-shelf.constants';

@Injectable({
  providedIn: 'root'
})
export class MyShelfService extends EngageBaseService {
  /**
   * Creates an instance of programs service.
   * @param http http client
   */
  constructor(public http: HttpClient,public profileInfoService: ProfileInfoService, public configService: ConfigService) {
    super(http, profileInfoService, configService);
  }

  getMyPrograms = (): Promise<ProgramsAPIRes> =>
    this.http
      .get<ProgramsAPIRes>(
        API_INFO.getPrograms
          .replace('<pageIndex>', `1`)
          .replace('<pageSize>', `${MY_PROGRAMS_SIZE}`)
          .replace('<filterByStatus>', `${GET_MY_PROGRAMS_FILTERBY_STATUS}`)
          .replace('<isMyshelf>', `true`)
      )
      .toPromise();
}
