import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  LEARNING_ACTIVITIES_EVENTS,
  LEARNING_ACTIVITIES_EVENTS_KEY,
  LEARNING_ACTIVITIES_SCREENS
} from './kz-learning-activities.analytics.constants';

interface AltObj {
  [key: string]: string;
}

type Action = keyof typeof LEARNING_ACTIVITIES_EVENTS_KEY;

@Injectable({
  providedIn: 'root'
})
export class KzLearningActivitiesAnalyticsService extends AnalyticsService {
  constructor() {
    super(LEARNING_ACTIVITIES_SCREENS, LEARNING_ACTIVITIES_EVENTS);
  }

  /**
   * To track myshelf page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof LEARNING_ACTIVITIES_SCREENS) {
    this.trackPage(screen);
  }
  /**
   * To track Learning activities page - currently checkout events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackLearningActivityEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(LEARNING_ACTIVITIES_SCREENS.LEARNING_ACTIVITIES, action, altObj);
  }
}
