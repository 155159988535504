import { Component, Input } from '@angular/core';
import { Badge } from '@home/shared/data/home.model';

@Component({
  selector: 'axis360-badges-card',
  templateUrl: './badges-card.component.html',
  styleUrls: ['./badges-card.component.scss']
})
export class BadgesCardComponent {
  @Input() badge: Badge;
  ariaLabel: string | boolean;
  isMobileDevice() {
    const isSmallWidth = window.innerWidth < 560;
    const isAndroid = isSmallWidth && /Android/i.exec(navigator.userAgent);
    const isAndroidCondition = (isAndroid ? 'true' : 'false');
    const result = isSmallWidth ? isAndroidCondition : '';
    return result;
  }
  isTabletDevice() {
    const isSmallWidth = window.innerWidth < 1024;
    const isAndroid = isSmallWidth && /Android/i.exec(navigator.userAgent);
    const isAndroidCondition = (isAndroid ? 'true' : 'false');
    const result = isSmallWidth ? isAndroidCondition : '';
    return result;
  }
}
