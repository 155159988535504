import { Component, Input, OnInit } from '@angular/core';
import { LayoutComponent } from '../../../../shared/data/third-party-model';
import { ThirdPartyService } from '../../services/third-party.service';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { ActivatedRoute, Router } from '@angular/router';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { THIRD_PARTY_SUBTYPE } from '../../constants/third-party.constants';
import { ThirdPartyAnalyticService } from '../../services/third-party-analytic.service';
import { ConfigService } from '@shared/services/config.service';

@Component({
  selector: 'axis360-third-party-categories-carousel',
  templateUrl: './third-party-categories-carousel.component.html',
  styleUrls: ['./third-party-categories-carousel.component.scss']
})
export class ThirdPartyCategoriesCarouselComponent implements OnInit {
  @Input() componentData: LayoutComponent;
  catData;
  cellWidth = 120;
  cellMargin = 0;
  cellHeight = 150;
  compName = SHIMMER_COMPONENTS.BADGE_CARD;
  isLoading = true;
  cellsToScroll = 5;
  cellsToScrollNums = [5, 4, 3, 2];
  THIRD_PARTY_SUBTYPE = THIRD_PARTY_SUBTYPE;
  /**
   * To update the carousel cell width and margin
   */
  onResize = setIvyCarouselProps.bind(this, 120, 0, 150, 120, 0, 150, this.cellsToScrollNums);
  constructor(
    private thirdPartyService: ThirdPartyService,
    private router: Router,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public configService: ConfigService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.formatDataApi();
    this.getCatData();
    this.onResize();
  }
  formatDataApi() {
    if (this.componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS) {
      /* tslint:disable */
      const { vendor_code = '' } = this.activatedRoute.snapshot.queryParams;
      const vendorSubscription = this.configService.getSubscriptionData((vendor_code || '').toLocaleLowerCase());
      const search = {};
      Object.keys(this.componentData.dataApi.library_settings).forEach((key) => {
        search[key] = vendorSubscription[this.componentData.dataApi.library_settings[key]];
        return search;
      });
      this.componentData.dataApi.search = {
        ...this.componentData.dataApi.search,
        ...search
      };
    }
  }
  async getCatData() {
    try {
      this.catData = await this.thirdPartyService.getThirdPartyCategories(this.componentData.dataApi);
      if (this.componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS) {
        this.catData = [this.catData];
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  }
  redirectToTierTwo(qParams: {}) {
    const { categoryType = '' } = this.componentData.dataApi.search || ({} as any);
    this.router.navigate(['list'], {
      queryParams: {
        ...qParams,
        searchType: categoryType,
        vendor_code: this.activatedRoute.snapshot.queryParams.vendor_code,
        q: '',
        tp: ''
      },
      relativeTo: this.activatedRoute
    });
  }
  acOnExecuteAction(action: { data: { id?: string; title?: string; code?: string } }) {
    this.thirdPartyAnalyticService.trackCategortyCarouselEvent('ACTIONS_CATEGORTY');
    const { id: has, title, code } = action.data;
    this.redirectToTierTwo({ has, title, code });
  }

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.thirdPartyAnalyticService.trackCategortyCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.thirdPartyAnalyticService.trackCategortyCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
  }
}
