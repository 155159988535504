import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { DEFAULT_SUCCESS_MESSAGE } from '@shared/constants/app.constants';
import { addStylesToContainerDiv, getDaysDiff } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { MainService } from '@shared/services/main.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { Carousel } from 'primeng/carousel';
import { noop } from 'rxjs';
import { HomeCarouselComponent } from './home-carousel/home-carousel.component';
import { InterestSurveyPopupComponent } from './interest-survey/interest-survey-popup/interest-survey-popup.component';
import { UserInterest } from './shared/data/home.model';
import { HomeAnalyticsService } from './shared/services/home.analytics.service';
import { HomeService } from './shared/services/home.service';

@Component({
  selector: 'axis360-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends HomeCarouselComponent implements OnInit, AfterViewInit {
  showFeaturedProgram = false;
  isTrackingEnabled = false;
  isLoading = true;
  isPPCOnlyLibrary = false;
  constructor(
    public matDialog: MatDialog,
    public homeService: HomeService,
    public configService: ConfigService,
    public userService: UserService,
    public mainService: MainService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public profileInfo: ProfileInfoService,
    public searchStorageService: SearchStorageService,
    public spinnerService: SpinnerService,
    public homeAnalyticsService: HomeAnalyticsService,
    public overlayContainer: OverlayContainer,
    public engageService: EngageService,
    public profileInfoService: ProfileInfoService
  ) {
    super(
      homeService,
      iconRegistry,
      sanitizer,
      searchStorageService,
      userService,
      spinnerService,
      configService,
      homeAnalyticsService,
      overlayContainer,
      engageService,
      profileInfoService,
      mainService
    );
    Carousel.prototype.onTouchMove = noop;
    iconRegistry.addSvgIcon('ebook', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/ebook.svg'));
    iconRegistry.addSvgIcon('book', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/book.svg'));
    iconRegistry.addSvgIcon('audio', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/audiobook.svg'));
  }
  /**
   * To init home components
   */
  ngOnInit(): void {
    this.initHome();
  }
  /**
   * To add styles to library container after view init
   */
  ngAfterViewInit() {
    addStylesToContainerDiv();
  }
  /**
   * To fetch data from API for configs and interest survey and decide to show feature program, badges, insights
   */
  async initHome() {
    await this.mainService.getUserAndSettings();
    this.isLoading = false;
    this.mainService.updateTheme();
    this.showFeaturedProgram = this.configService.getProfileFeatures(this.profileInfo.currentProfile.profileType.toLowerCase()).programsEnabled;
    this.isTrackingEnabled = this.configService.getProfileFeatures(
      this.profileInfo.currentProfile.profileType.toLowerCase()
    ).insightsBadgesEnabled;
    this.userService.isLoginStatus = this.userService.isLoggedIn();
    this.configService.isRecommendationsEnabled(this.profileInfo.currentProfile.profileType.toLowerCase()) && this.profileInfo.currentProfile.recommendationEnabled && this.getInterestSurvey();
    this.isPPCOnlyLibrary = this.configService?.currentLibrary?.isPPCOnlyLibrary;
  }
  /**
   * To decide interest survey popup display
   * @param interestRes API response
   * @returns true if can show the popup
   */
  showInterestSurvey(interestRes: UserInterest): boolean {
    const { interests = [], modifiedDate, audience = [] } = interestRes;
    const selectedAlready = interests.some((interest) => interest.selected) || audience.length > 0;
    return !selectedAlready && (!modifiedDate || getDaysDiff(new Date(modifiedDate), new Date()) > 30);
  }
  /**
   * To get interest survey data from API and display popup
   */
  getInterestSurvey() {
    this.homeService.getInterests().subscribe((interestRes) => {
      if (interestRes.statusCode === 200 && interestRes.message === DEFAULT_SUCCESS_MESSAGE) {
        this.showInterestSurvey(interestRes.userInterests) && this.openSurvey(interestRes.userInterests);
      }
    }, noop);
  }
  /**
   * To opens survey popup
   * @param interestRes API response
   */
  openSurvey(interestRes: UserInterest) {
    const interestModal = this.matDialog.open(InterestSurveyPopupComponent, {
      panelClass: 'interest-dialog-panel',
      autoFocus: true,
      data: interestRes,
      ariaLabelledBy: 'interest-survey-popup-title'
    });
    interestModal.afterClosed().subscribe((res) => {
      if (res.saveInterest) {
        this.titleforRecommendation(this.myLibraryList, this.selectedAvailability, this.selectedFormat, true);
      }
    });
  }
}
