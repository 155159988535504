export const pageSize = 24;
export const page = 1;
export const EMPTY = '';
export const carouselpageSize = 10;
export const AUDIENCE = 'audiences';
export const KID = 'Kid';
export const CATEGORY_TYPE = [
  { text: 'All', value: 'All', enabled: true, url: '/view/search'},
  { text: 'eBooks', value: 'eBook', enabled: true, url: '/view/search/eBook' },
  { text: 'eAudios', value: 'eAudio', enabled: true, url: '/view/search/eAudio' },
  { text: 'Resource Hub', value: 'active_Resources', enabled: true, url: '/view/search/active_Resources' },
  { text: 'Web Resources', value: 'web_Resources', enabled: true, url: '/view/search/web_Resources' }
];
export const PROGRAM_CATEGORY_TYPE = [
  { text: 'All', value: 'All', enabled: true,  url: '/view/<parentRoute>/search'},
  { text: 'eBooks', value: 'eBook', enabled: true, url: '/view/<parentRoute>/search/eBook' },
  { text: 'eAudios', value: 'eAudio', enabled: true, url: '/view/<parentRoute>/search/eAudio' },
];
export const CATEGORY = {
  ALL: 'All',
  TITLES: 'titles',
  ACTIVE_RESOURCES: 'active_Resources',
  WEB_RESOURCES: 'web_Resources'
};
export const REFINER_TITLE = {
  sortBy: 'Sort By',
  activityTypes: 'Resource Type',
  audiences: 'Audience Level',
  publishers: 'Provider',
  relatedTitle: 'Related Title'
};

export const SITE_REFINER = ['domainFilter', 'language', 'formatFilter', 'subjectFilter', 'sourceFilter', 'gradeMap'];
export const TITLE_REFINER = [
  'recentlyadded',
  'agelevel',
  'availability',
  'collections',
  'releasedate',
  'language',
  'axisattribute',
  'sortby',
  'subject'
];
export const ACTIVITY_REFINER = ['sortBy', 'activityTypes', 'audiences'];
export const SEARCH_ERROR_TEXT = 'Search term is required';
export const ACTIVITY_TYPES = 'activityTypes';
export const AUDIENCES = 'audiences';
export const BACK_ROUNDIMAGE_FEATUREDLIST = ['/angular-app/assets/images/kz-images/adult-quaternary-bg-1440.svg',
'/angular-app/assets/images/kz-images/adult-quaternary-bg-1024.svg',
'/angular-app/assets/images/kz-images/adult-quaternary-bg-768.svg',
'/angular-app/assets/images/kz-images/adult-quaternary-bg-375.svg'];
export const BACK_ROUNDIMAGEKID_FEATUREDLIST = ['/angular-app/assets/images/kz-images/kid-tertiary-bg-1440.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-1024.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-768.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-375.svg'];
export const BACK_ROUNDIMAGETEEN_FEATUREDLIST = ['/angular-app/assets/images/kz-images/teen-tertiary-bg-1440.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-1024.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-768.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-375.svg'];
export const BACK_GROUNDIMAGE_TIER_ONE = ['/angular-app/assets/images/kz-images/adult-primary-bg-375.svg',
  '/angular-app/assets/images/kz-images/adult-primary-bg-768.svg',
  '/angular-app/assets/images/kz-images/adult-primary-bg-1024.svg',
  '/angular-app/assets/images/kz-images/adult-primary-bg-1440.svg'
];
export const BACK_GROUNDIMAGE_KID_TIER_ONE = ['/angular-app/assets/images/kz-images/kid-primary-bg-375.svg',
  '/angular-app/assets/images/kz-images/kid-primary-bg-768.svg',
  '/angular-app/assets/images/kz-images/kid-primary-bg-1024.svg',
  '/angular-app/assets/images/kz-images/kid-primary-bg-1440.svg'
];
export const BACK_GROUNDIMAGE_TEEN_TIER_ONE = ['/angular-app/assets/images/kz-images/teen-primary-bg-375.svg',
  '/angular-app/assets/images/kz-images/teen-primary-bg-768.svg',
  '/angular-app/assets/images/kz-images/teen-primary-bg-1024.svg',
  '/angular-app/assets/images/kz-images/teen-primary-bg-1440.svg'
];
export const BACK_ROUNDIMAGE_INTERESTSURVEY = ['/angular-app/assets/images/kz-images/adult-tertiary-bg-1440-top.svg',
'/angular-app/assets/images/kz-images/adult-tertiary-bg-1024-top.svg',
'/angular-app/assets/images/kz-images/adult-tertiary-bg-768-top.svg',
'/angular-app/assets/images/kz-images/adult-tertiary-bg-375-top.svg'];
export const BACK_ROUNDIMAGE_KIDINTERESTSURVEY = ['/angular-app/assets/images/kz-images/teen-tertiary-bg-1440-top.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-1024-top.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-768-top.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-375-top.svg'];
export const BACK_ROUNDIMAGE_TEENINTERESTSURVEY =  ['/angular-app/assets/images/kz-images/kid-tertiary-bg-1440-top.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-1024-top.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-768-top.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-375-top.svg'];

export const BACK_ROUNDIMAGE_INTERESTSURVEY_BOTTOM = ['/angular-app/assets/images/kz-images/adult-tertiary-bg-1440-bottom.svg',
'/angular-app/assets/images/kz-images/adult-tertiary-bg-1024-bottom.svg',
'/angular-app/assets/images/kz-images/adult-tertiary-bg-768-bottom.svg',
'/angular-app/assets/images/kz-images/adult-tertiary-bg-375-bottom.svg'];
export const BACK_ROUNDIMAGE_KIDINTERESTSURVEY_BOTTOM = ['/angular-app/assets/images/kz-images/teen-tertiary-bg-1440-bottom.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-1024-bottom.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-768-bottom.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-375-bottom.svg'];
export const BACK_ROUNDIMAGE_TEENINTERESTSURVEY_BOTTOM =  ['/angular-app/assets/images/kz-images/kid-tertiary-bg-1440-bottom.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-1024-bottom.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-768-bottom.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-375-bottom.svg'];
export const BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT = ['/angular-app/assets/images/kz-images/adult-quaternary-bg-1440.svg',
'/angular-app/assets/images/kz-images/adult-quaternary-bg-1024.svg',
'/angular-app/assets/images/kz-images/adult-quaternary-bg-768.svg',
'/angular-app/assets/images/kz-images/adult-quaternary-bg-375.svg'];
export const BACK_ROUNDIMAGE_TEEN_PROFILE_EDIT = ['/angular-app/assets/images/kz-images/teen-tertiary-bg-1440.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-1024.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-768.svg',
'/angular-app/assets/images/kz-images/teen-tertiary-bg-375.svg'];
export const BACK_ROUNDIMAGE_KID_PROFILE_EDIT =  ['/angular-app/assets/images/kz-images/kid-tertiary-bg-1440.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-1024.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-768.svg',
'/angular-app/assets/images/kz-images/kid-tertiary-bg-375.svg'];
export const PRESSREADERDRUPALDOWN = 'The Publications and Articles service is not currently available. Please try at a later time.';
export const VIDEOSDRUPALDOWN = 'The Checker Library TV service is not currently available. Please try at a later time.';
export const VBOOKSDRUPALDOWN = 'The Videobooks service is not currently available. Please try at a later time.';
