<div class="kz-joined-program">
    <button class="kz-close" id="loc_closeicons" aria-label="Close welcome program" (click)="closeDialog()" axis360TrapFocus>
        <mat-icon aria-hidden="true">close</mat-icon>
    </button>
    <div class="kz-joined-pro-sec">
        <h1>Welcome to {{programDetails.name}}</h1>
        <mat-icon svgIcon="JOINED_PROGRAM" class="kz-join-pro-img"></mat-icon>
        <div class="kz-joined-action-sec">
            <button type="button" (click)="onJoinSelected(programDetails.id)" id="loc_labelLetsGetStarted">Let's Get Started!</button>
        </div>
    </div>
</div>