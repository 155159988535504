import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TOTAL_TEXT_COUNT } from '@shared/constants/app.constants';
import { SharedService } from '@shared/services/shared.service';
import { IPatronReviewDialogInputDetails } from '@titledetails/shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from 'app/core/title-details/shared/services/title-details.analytics.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
export interface AlertDialogInput {
  heading?: string;
  submitBtnText?: string;
  type?: string;
  submitBtnFunc?: () => void;
  cancelBtnText?: string;
  cancelBtnFunc?: () => void;
  closeIcon?: boolean;
  patronReviewDetails?: IPatronReviewDialogInputDetails;
  panelClass?: string;
  ariaDescribedby?: string;
  ariaLabelledBy?: string;
}

@Component({
  selector: 'axis360-alert-dialog-input',
  templateUrl: './alert-dialog-input.component.html',
  styleUrls: ['./alert-dialog-input.component.scss']
})
export class AlertDialogInputComponent implements OnInit {
  value?: string;
  remainingText = TOTAL_TEXT_COUNT;
  valueLength = 0;
  disableBtn = false;
  showTextArea = false;
  reviewDetails: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public modaldata: AlertDialogInput,
    public sharedService: SharedService,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public alertDialog: MatDialogRef<AlertDialogComponent>
  ) { }

  ngOnInit(): void {
    document.querySelectorAll('.mat-dialog-container').forEach((n) => {
      n.removeAttribute('tabindex');
    });
    if (!this.showTextArea) {
      setTimeout(() => {
        const alertDialogContent = document.getElementById('alert-dialog-content');
        alertDialogContent.setAttribute('aria-describedby', 'loc_alert_content alert-dialog-count');
      }, 200);
    }
    if (this.modaldata?.patronReviewDetails?.selfReview.content !== null) {
      this.showTextArea = true;
      this.value = this.modaldata?.patronReviewDetails?.selfReview.content;
      this.remainingText = TOTAL_TEXT_COUNT - this.modaldata?.patronReviewDetails?.selfReview.content.length;
    }
  }

  submitClick() {
    if (this.value.trim().length < 3) {
      return;
    } else {
      this.reviewDetails = { value: this.value.trim() };
      this.sharedService.setInputAlertDialogValue(this.reviewDetails);
      this.alertDialog.close();
      this.modaldata.submitBtnFunc();
    }
  }

  cancelClick() {
    this.alertDialog.close();
  }

  valueChange(value: string) {
    this.value = value;
    const alertDialogCount = document.getElementById('alert-dialog-count');
    alertDialogCount.setAttribute('aria-live', 'polite');
    this.titleDetailsAnalyticsService.trackReviewEvent('ENTER_REVIEW');
    this.valueLength = value.trim().length;
    this.remainingText = TOTAL_TEXT_COUNT - this.valueLength;
  }
}
