import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  SEARCH_CATEGORY,
  SEARCH_EVENTS,
  SEARCH_EVENTS_KEY,
  SEARCH_SCREENS,
  SEARCH_SCREENS_KEY
} from '@search/shared/constants/search.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof SEARCH_EVENTS_KEY;
@Injectable()
export class SearchAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of search analytics service.
   */
  constructor() {
    super(SEARCH_SCREENS, SEARCH_EVENTS);
  }
  /**
   * To track search page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof SEARCH_SCREENS_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track sites event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackSitesEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(SEARCH_CATEGORY.SITES, action, altObj);
  }
  /**
   * To track news event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackNewsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(SEARCH_CATEGORY.NEWS, action, altObj);
  }
  /**
   * To track search common event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackSearchEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(SEARCH_CATEGORY.SEARCH, action, altObj);
  }
}
