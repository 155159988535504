export const LIST_REVIEW_COUNT = 10;
export const PAGE_NUMBER = 1;
export const PAGE_SIZE = 20;
export const WITH_OUT_INTERNET_MSG_RATING = "This title does not exist for this library  for rating status bar error as well"
export const WITH_OUT_INTERNET_MSG_REVIEW = "Something went wrong! Please submit your review again."
export const LGBTQ = 'LGBTQ+';
export const REVIEW_ALERT = {
  heading: 'What do you think of this title?',
  content: '',
  submitBtnText: 'Submit',
  closeIcon: true,
  panelClass: 'kz-review-dialog',
  ariaDescribedby: 'alert-dialog-count',
  ariaLabelledBy:'alert-heading'
};

export const SHARE_ACTIONS_BUTTON = {
  action: 0,
  actionButtonId: 'btnShareNow',
  buyNowUrl: null,
  enabled: true,
  reservationId: null
};

export const MAT_TEXT_LABEL = {
  'More Like This': 'More Like This',
  'Related Items': 'Related Items',
  'Details': 'Details'
};

export const FORMAT_TYPE_MAP = {
  ['ebook']: 'eBook',
  ['audio']: 'eAudio',
  ['book']: 'Print Book'
};

export const TITLE_DETAILS_TABS_KEY = {
  'Related Items': 'ACTIONS_RELATED_ITEMS_TABS',
  Details: 'ACTIONS_DETAILS_TABS',
  'More Like This': 'ACTIONS_MORE_LIKE_THIS_TABS',
  Review: 'ACTIONS_REVIEW_TABS'
};

export const AUDIENCE_FORMAT_TYPES = {
  GENERAL_ADULT: 'General Adult',
  TEEN_AGE_12_14: 'Teen - Grade 7-9, Age 12-14',
  TEEN_AGE_15_18: 'Teen - Grade 10-12, Age 15-18',
  KIDS_AGE_5_6: "Children's - Kindergarten, Age 5-6",
  KIDS_AGE_9_11: "Children's - Grade 4-6, Age 9-11",
  KIDS_AGE_8_9: "Children's - Grade 3-4, Age 8-9",
  KIDS_AGE_7_8: "Children's - Grade 2-3, Age 7-8",
  KIDS_AGE_6_7: "Children's - Grade 1-2, Age 6-7",
  KIDS_AGE_2_4: "Children's - Toddlers, Age 2-4",
  KIDS_AGE_0_1: "Children's - Babies, Age 0-1",
  KIDS_AGE_0_2: "Children's - Babies, Age 0-2",
  SCHOLARLY_UG: "Scholarly/Undergraduate",
  SCHOLARLY_G: "Scholarly/Graduate",
  SCHOLARLY_ASSOCIATE: "Scholarly/Associate",
  PROFESSIONAL: "Professional"
};

export const AGE_RANGE = {
  [AUDIENCE_FORMAT_TYPES.GENERAL_ADULT]: { name: 'Gen Adult', age_range: '18+', age: '18+' },
  [AUDIENCE_FORMAT_TYPES.TEEN_AGE_12_14]: { name: 'Teen', age_range: '12-14', age: '12+' },
  [AUDIENCE_FORMAT_TYPES.TEEN_AGE_15_18]: { name: 'Teen', age_range: '15-18', age: '15+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_9_11]: { name: 'Kid', age_range: '9-11', age: '9+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_5_6]: { name: 'Kid', age_range: '5-6', age: '5+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_8_9]: { name: 'Kid', age_range: '8-9', age: '8+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_7_8]: { name: 'Kid', age_range: '7-8', age: '7+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_6_7]: { name: 'Kid', age_range: '6-7', age: '6+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_2_4]: { name: 'Kid', age_range: '2-4', age: '2+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_0_1]: { name: 'Kid', age_range: '0-1', age: '0+' },
  [AUDIENCE_FORMAT_TYPES.KIDS_AGE_0_2]: { name: 'Kid', age_range: '0-2', age: '0+' },
  [AUDIENCE_FORMAT_TYPES.SCHOLARLY_UG]: { name: 'Gen Adult', age_range: '18+', age: '18+' },
  [AUDIENCE_FORMAT_TYPES.SCHOLARLY_G]: { name: 'Gen Adult', age_range: '18+', age: '18+' },
  [AUDIENCE_FORMAT_TYPES.SCHOLARLY_ASSOCIATE]: { name: 'Gen Adult', age_range: '18+', age: '18+' },
  [AUDIENCE_FORMAT_TYPES.PROFESSIONAL]: { name: 'Gen Adult', age_range: '18+', age: '18+' },
};

export const FACEBOOK_SHARE_LINK = "https://www.facebook.com/sharer/sharer.php?<params>";
export const TWITTER_SHARE_LINK = "https://twitter.com/share?<params>";
export const EMAIL_SHARE_LINK = "mailto:?subject=<subject>&body=<body>";
export const SHARE_BASE_URL = "<originUrl>/sharable/title?itemId=<itemId>&isbn=<isbn>";
export const SHARE_BASE_THIRD_PARTY_URL = "<originUrl>/sharable?itemId=<itemId>&ISBN=<ISBN>&vendor_code=<vendorCode>&collectionId=<collectionId>&title=<title>&series=<series>";
export const SOCIAL_MEDIA_LIST = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  EMAIL: 'email',
  COPY_LINK: 'copyLink',
  INSTAGRAM: 'instagram'
};
export const HIDE_TABS_DETAILS = {
  RELATED_ITEM: 'relatedItem',
  MORE_LIKE_ITEM: 'moreLikeItem'
};
export const ADDPOPUP_ALERT = {
  heading: 'Add an Item',
  closeIcon: false,
  content: '',
  searchBtnText: 'Search Your Library',
  searchOwnBtnText: 'Add Your Own Item',
  type: 'CONFIRMATION',
  panelClass: 'kz-review-dialog-addcart',
};

export const ADD_CONFIRM_ALERT = {
  heading: 'Title Added',
  closeIcon: false,
  content: 'You have added a new title to your program',
  searchBtnText: 'OK',
  type: 'CONFIRMATION',
  panelClass: 'kz-review-dialog-addcart',
};

export const TITLE_REVIEWS_TEST = {
  title: "",
  languageDesc: "",
  publicationDate: "",
  ageRange: {
    name: "",
    age_range: "",
    age: ""
  },
  length: "",
  formatType: {
    icon: "",
    alt: ""
  },
  formatTypeName: "",
  reviewsCount: 0,
  userAvgStarRating: 0,
  avgStarRating: 0,
  axisAttribute: [""],
  axisAttributeList: [{ attribute: "" }],
  axisAttributeIndex: -1,
  patronReviewsEnabled: true,
  subjectName: "",
  bookLength: "0",
  BookLength: "0",
  attribute: "",
  runTime: ""
};
export const COMING_SOON = 'COMING SOON';
export const UNLIMITED_COPIES = 'Unlimited Copies Available';
export const RATINGS = 'Ratings';
export const RATING = 'Rating';
export const REVIEWS = 'Reviews';
export const REVIEW = 'Review';
