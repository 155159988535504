<ng-container *ngIf="!partnerSiteEnabled">
    <div mat-dialog-content class="alert-dialog" (window:resize)="onResize()">
        <div class="app-banner-wrapper">
            <a class="close-icon" tabindex="0" (click)="appBannerCloseBtn()" aria-label="Close" role="button"
                aria-hidden="true" (keydown.enter)="appBannerCloseBtn()" href="javascript:;">
                <mat-icon aria-hidden="true" svgIcon="kz-close"></mat-icon>
            </a>
            <div class="alert-bounless-logo d-flex">
                <img src="/angular-app/assets/images/boundless_B.svg" alt="" aria-hidden="true">
            </div>
            <div class="alert-heading">
                <h1 id="alert-heading" class="alert-title" tabindex="-1">Boundless</h1>
                <p class="alert-subtitle">Open in the Boundless app</p>
            </div>
        </div>
        <div mat-dialog-actions class="alert-btns">
            <button mat-button cdkFocusInitial class="submit-btn" (click)="openApp()">Open</button>
            <button mat-button cdkFocusInitial *ngIf="false" class="submit-btn" (click)="installApp()">Install</button>
        </div>
    </div>
</ng-container>