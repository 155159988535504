<div class="kz-custom-container-secondary" *ngIf="isCheckedOutCarousal && checkoutRecommendationContent.Items?.length != 0"
    [ngClass]="{'recommendation-no-data': checkoutRecommendationContent.Items?.length === 0, 'mb-3': checkoutRecommendationContent.Items?.length > 0 && interestRecommendationContent.Items?.length === 0, 'featuredlist-empty': isFeaturedListHide && checkoutRecommendationContent.Items?.length > 0 && interestRecommendationContent.Items?.length === 0}">
    <axis360-titles-carousel [listData]="checkoutListData" [addCard]="true" [titlesList]="checkoutRecommendationContent.Items" [parentRoute] = "parentRoute">
    </axis360-titles-carousel>
</div>
<div class="kz-custom-container-secondary" *ngIf="isInterestRecommendation"
    [ngClass]="{'recommendation-no-data': interestRecommendationContent.Items?.length === 0, 'kz-interest': checkoutRecommendationContent.Items?.length > 0 && interestRecommendationContent.Items?.length > 0, 'featuredlist-empty': isFeaturedListHide}">
    <axis360-titles-carousel [parentRoute]="parentRoute" [addCard]="true" [listData]="interestListData" [titlesList]="interestRecommendationContent.Items" [parentRoute] = "parentRoute">
    </axis360-titles-carousel>
</div>
