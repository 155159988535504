export const PRESS_READER_CATEGORY = 'News & Magazines';
export const PRESS_READER_SCREENS_KEY = {
  LANDING: 'LANDING',
  LIST: 'LIST',
  DETAILS: 'DETAILS',
  FILTER: 'FILTER',
  SELECT_ISSUE_DATE: 'SELECT_ISSUE_DATE'
};

export const PRESS_READER_SCREENS: { [key: string]: string } = {
  [PRESS_READER_SCREENS_KEY.LANDING]: 'News & Magazines',
  [PRESS_READER_SCREENS_KEY.LIST]: '<carouselName>',
  [PRESS_READER_SCREENS_KEY.DETAILS]: 'Publication Detail screen',
  [PRESS_READER_SCREENS_KEY.FILTER]: 'Filter',
  [PRESS_READER_SCREENS_KEY.SELECT_ISSUE_DATE]: 'Select issue date'
};

export const PRESS_READER_EVENTS_KEY = {
  CAROUSEL_SEE_ALL: 'CAROUSEL_SEE_ALL',
  CATEGORY: 'CATEGORY',
  READ_NOW: 'READ_NOW',
  SELECT_ISSUE: 'SELECT_ISSUE',
  SORT: 'SORT',
  FILTER: 'FILTER',
  SELECT_FILTER: 'SELECT_FILTER',
  CLEAR: 'CLEAR',
  CARD_DETAIL: 'CARD_DETAIL',
  SELECT_CALENDAR_TYPE: 'SELECT_CALENDAR_TYPE',
  SELECT_LIST_TYPE: 'SELECT_LIST_TYPE',
  SELECT_DATE: 'SELECT_DATE',
  CLOSE: 'CLOSE',
  MULTI_COUNTRY_PLUS: 'PLUS',
  MULTI_COUNTRY_MINUS: 'MINUS'
};
export const PRESS_READER_EVENTS: { [key: string]: string } = {
  [PRESS_READER_EVENTS_KEY.CAROUSEL_SEE_ALL]: 'See all <carouselName>',
  [PRESS_READER_EVENTS_KEY.CATEGORY]: '<categoryName>',
  [PRESS_READER_EVENTS_KEY.READ_NOW]: 'Read now',
  [PRESS_READER_EVENTS_KEY.SELECT_ISSUE]: 'Select issue',
  [PRESS_READER_EVENTS_KEY.SORT]: 'Sort',
  [PRESS_READER_EVENTS_KEY.FILTER]: 'filter',
  [PRESS_READER_EVENTS_KEY.SELECT_FILTER]: 'Select Filter',
  [PRESS_READER_EVENTS_KEY.CLEAR]: 'clear',
  [PRESS_READER_EVENTS_KEY.CARD_DETAIL]: 'Card detail link',
  [PRESS_READER_EVENTS_KEY.SELECT_CALENDAR_TYPE]: 'Select calendar type',
  [PRESS_READER_EVENTS_KEY.SELECT_LIST_TYPE]: 'Select list type',
  [PRESS_READER_EVENTS_KEY.SELECT_DATE]: 'Select date',
  [PRESS_READER_EVENTS_KEY.CLOSE]: 'close',
  [PRESS_READER_EVENTS_KEY.MULTI_COUNTRY_PLUS]: 'plus',
  [PRESS_READER_EVENTS_KEY.MULTI_COUNTRY_MINUS]: 'minus'
};
