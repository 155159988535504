import { Injectable } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public isLoading = false;
  public isOverlayLoading = false;
  public isModal = false;
  private startTimeout?: number;
  private stopTimeout?: number;
  private count = 0;
  istitleDetailsRating = false;
  isthirdPartyToast = false;
  constructor(private liveAnnouncer: LiveAnnouncer, public titleDetailsService: TitleDetailsService) { }
  public withObservable<T>(completion: Observable<T>, isModal = false, announceFinish = true): Observable<T> {
    this.isModal = isModal;
    this.startSpinner();
    return completion.pipe(finalize(() => this.stopSpinner(announceFinish)));
  }

  public withPromise<T>(completion: Promise<T>, announceFinish = true): Promise<T> {
    this.startSpinner();
    return completion
      .then((val) => {
        this.stopSpinner(announceFinish);
        return val;
      })
      .catch((err) => {
        this.stopSpinner(announceFinish);
        throw err;
      });
  }

  private startSpinner() {
    this.count++;
    if (this.stopTimeout && this.isOverlayLoading) {
      clearTimeout(this.stopTimeout);
      this.stopTimeout = undefined;
    } else if (!this.startTimeout) {
      setTimeout(() => {
        this.startTimeout = undefined;
        this.liveAnnouncer.announce('Loading', 'assertive');
        this.isOverlayLoading = true;
      }, 250);
    }
  }

  private stopSpinner(announceFinish: boolean) {
    if (this.count > 0) {
      this.count--;
    }

    if (this.count === 0) {
      if (this.startTimeout && !this.isOverlayLoading) {
        clearTimeout(this.startTimeout);
        this.startTimeout = undefined;
      } else if (!this.stopTimeout) {
        setTimeout(() => {
          this.stopTimeout = undefined;
          announceFinish && this.liveAnnouncer.announce('Finished loading.', 'assertive');
          this.titleDetailsService.titleDetailsRating?.subscribe((response: string) => {
            if (response && response === 'rating') {
              this.istitleDetailsRating = true;
            }
          });
          if (this.istitleDetailsRating) {
            this.istitleDetailsRating = false;
            this.titleDetailsService.titleDetailsRating.next('');
            setTimeout(() => {
              let announceText = document?.getElementById('star-rating-update')?.innerHTML
                ? document?.getElementById('star-rating-update')?.innerHTML : '';
              if (navigator.userAgent.match(/(Android|android|Samsung|Mac)/i)) {
                announceText = '';
              }
              announceText += document?.getElementById('toast-heading')?.innerHTML ? ` ${document?.getElementById('toast-heading')?.innerHTML}` : '';
              announceText += document?.getElementById('toast-description')?.innerHTML ? ` ${document?.getElementById('toast-description')?.innerHTML}` : '';
              this.liveAnnouncer.announce(announceText, 'assertive');
              setTimeout(() => {
                const review = document?.getElementById('star-rating-update');
                review && review.setAttribute('aria-hidden', 'false');
              }, 2000);
            }, 500);
          } else {
            setTimeout(() => {
              this.titleDetailsService.thirdPartyToast?.subscribe((response: string) => {
                if (response) {
                  this.isthirdPartyToast = true;
                }
              });
              if (!this.isthirdPartyToast) {
                let announceText = document?.getElementById('toast-heading')?.innerHTML
                  ? document?.getElementById('toast-heading')?.innerHTML : '';
                announceText += document?.getElementById('toast-description')?.innerHTML ? ` ${document?.getElementById('toast-description')?.innerHTML}` : '';
                if (announceText) {
                  this.liveAnnouncer.announce(announceText, 'assertive');
                }
              } else {
                this.liveAnnouncer.announce(' ', 'assertive');
                this.titleDetailsService.thirdPartyToast.next('');
              }
            }, 1000);
          }
          this.isOverlayLoading = false;
          this.isModal = false;
        }, 500);
      }
    }
  }
  /**
   * To show or hide loader
   * @param [isLoading] flag to show or hide the spinner
   */
  public showLoader(isLoading = true) {
    this.isLoading = isLoading;
  }
}
