<ol class="side-bar-menu" [ngClass]="{'search-open':myStuffService.showSearch}"
    *ngIf="sharedService.myStuffCounts[activeMyStuff] > 0">
    <li>
        <button (click)="showSerach()" *ngIf="isShowSearch" class="custom-menu-trigger" id="loc_btnSearchicons"
            matTooltip="Search" matTooltipClass="kz-card-tooltip" mat-icon-button aria-label="Search">
            <mat-icon svgIcon="kz-search-icon" aria-hidden="true"></mat-icon>
        </button>
        <div class="kz-search-main" *ngIf="myStuffService.showSearch ">
            <mat-form-field class="kz-mystuff-search" floatLabel="auto" appearance="fill">
                <input matInput class="kz-mystuff-input" [(ngModel)]="myStuffService.searchKeyword"  [attr.aria-labelledby]="sharedService.noResults ? 'search-input searchCheckouts' : 'searchCheckouts'" placeholder="Search within {{search[activeMyStuff]}}" #searchCheckouts
                    (keydown.enter)="searchChange('loc_btnSearchicons')">
                    <span class="kz-visually-hidden" id="searchCheckouts">Search within {{search[activeMyStuff]}}</span>
                <mat-icon *ngIf="myStuffService.searchKeyword.trim().length" tabindex="0" matTooltip="Clear Search"
                    matTooltipClass="kz-card-tooltip" role="button" aria-hidden="false" matSuffix class="cursor-pointer"
                    svgIcon="kz-close" (click)="clearSearch('loc_btnSearchicons')"
                    (keydown.enter)="clearSearch('loc_btnSearchicons')"></mat-icon>
            </mat-form-field>
            <button type="submit" aria-label="Search" [attr.aria-labelledby]="sharedService.noResults ? 'search-input' : null" class="kz-search-button"
                [disabled]="!myStuffService.searchKeyword.trim().length" matTooltip="Submit Search"
                matTooltipClass="kz-card-tooltip" (click)="searchChange('loc_btnSearchicons')">
                <mat-icon svgIcon="kz-search-icon" class="kz-search-icon" aria-hidden="true">
                </mat-icon>
            </button>
        </div>
    </li>
    <li class="filter-menus">
        <button class="custom-menu-trigger" [ngClass]="{'active': myStuffService.filterOption != 'All'}"
            matTooltip="Filter" matTooltipClass="kz-card-tooltip" aria-haspopup="true"
            (mouseover)="removeTooltipAnnouce()" (keyup.Tab)="removeTooltipAnnouce()"
            id="loc_btnFilter" mat-icon-button axis360MatMenuFocus [matMenuTriggerFor]="menuOne" (menuOpened)="menuState(true)"
            (menuClosed)="menuState(false)" [attr.aria-label]="myStuffService.filterOption != 'All' ? 'filter with one item selected' : 'filter'">
            <mat-icon svgIcon="kz-filtter-icon" aria-hidden="true"></mat-icon>
        </button>
        <mat-menu #menuOne="matMenu" class="kz-action-menu-item dropdown-sub-menu">
            <button *ngFor="let formatType of homeFormatType" id="loc_btnSelected filter_items {{formatType.text}}"
                (click)="filterChange('formatType', formatType.value, formatType.text,'loc_btnFilter',$event)" mat-menu-item
                class="anchor-link" [ngClass]="{'active': (myStuffService.filterOption === formatType.value)}"
                [attr.aria-selected]="formatType.value === myStuffService.filterOption" (focus)="announceSelection($event)">{{formatType.text}}</button>
        </mat-menu>
    </li>
    <li>
        <button class="custom-menu-trigger" id="loc_btnSort" mat-icon-button axis360MatMenuFocus [matMenuTriggerFor]="menuTwo"
            matTooltip="Sort" matTooltipClass="kz-card-tooltip" aria-haspopup="true" aria-label="Sort"
            (mouseover)="removeTooltipAnnouce()" (keyup.Tab)="removeTooltipAnnouce()"
            (menuOpened)="menuState(true)" (menuClosed)="menuState(false)">
            <mat-icon svgIcon="kz-strawberry" aria-hidden="true"></mat-icon>
        </button>
        <mat-menu #menuTwo="matMenu" class="kz-action-menu-item dropdown-sub-menu">
            <button mat-menu-item *ngFor="let sort of sortOptions" (click)="sortChange(sort.value, sort.text, 'loc_btnSort',$event)"
                class="anchor-link" id="loc_{{sort.text}}"
                [ngClass]="{'active': sort.value === myStuffService.sortOption}" (focus)="announceSelection($event)"
                [attr.aria-selected]="sort.value === myStuffService.sortOption">{{sort.text}}</button>
        </mat-menu>
    </li>
    <li *ngIf="!isGridView">
        <button class="custom-menu-trigger" matTooltip="List view" matTooltipClass="kz-card-tooltip"
        id="loc_List" mat-icon-button aria-label="List view"
            *ngIf="myStuffService.isGridView" (click)="switchGridListView('loc-Grid')"
            (keydown.enter)="switchGridListView('loc-Grid')">
            <mat-icon svgIcon="kz-list-view" aria-hidden="true"></mat-icon>
        </button>
        <button class="custom-menu-trigger" matTooltip="Grid view" matTooltipClass="kz-card-tooltip"
            id="loc-Grid" mat-icon-button aria-label="Grid view"
            *ngIf="!myStuffService.isGridView" (click)="switchGridListView('loc_List')"
            (keydown.enter)="switchGridListView('loc_List')">
            <mat-icon svgIcon="kz-chocolate-menu" aria-hidden="true"></mat-icon>
        </button>
    </li>
</ol>