import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ProfileEditComponent } from '@profile/profile-edit/profile-edit.component';
import { AlertDialogNewComponent } from '@shared/components/alert-dialog-new/alert-dialog-new.component';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog.component';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { Observable } from 'rxjs';
import { PROFILES_ALERTS } from '../constants/profiles-alerts.constants';
import { ProfilesService } from '../services/profiles.service';
import { Location } from '@angular/common';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';

type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
  enableSave: boolean;
  isPinAutoClose: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ProfileEditComponent> {
  constructor(
    protected matDialog: MatDialog,
    public profilesService: ProfilesService,
    private profileInfoService: ProfileInfoService,
    public route: Router,
    public location: Location,
    public searchService: KzSearchService
  ) { }
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    this.searchService.searchText = "";
    const can = component.canDeactivate();

    if (!can) {
      console.log(currentRoute, currentState);
      this.showAlertDialog({
        ...PROFILES_ALERTS.WITHOUT_SAVE_ALERT,
        submitBtnFunc: () => {
          component.enableSave = false;
          nextState.url === '/' ? this.location.back() : this.route.navigateByUrl(nextState.url)
          return true;
        },
        cancelBtnFunc: () => {
          const selectedProfileType = this.profileInfoService?.selectedEditProfileType === 'Adult' ? 'General' : this.profileInfoService?.selectedEditProfileType;
          document.title = `Profile Settings - ${selectedProfileType}`;
          return false;
        }
      });
    } else {
      if (component.isPinAutoClose) {
        return false;
      }
      return true;
    }
  }

  showAlertDialog(data: AlertDialog) {
    const dialog = this.matDialog.open(AlertDialogNewComponent, {
      panelClass: ['profile-create-alert-dialog', data.panelClass],
      ariaLabelledBy: 'alert-heading',
      ariaDescribedBy: data.ariaDescribedBy ? data.ariaDescribedBy : 'alert-dialog-content',
      id: 'alert-dialog-title',
      role: 'dialog',
      data
    });
    dialog.afterClosed().subscribe(() => {
      this.profileInfoService.changesSaved = false;
    });
  }
}
