
export const TITLE_LIST_CATEGORY = 'TITLE LIST';

export const TITLE_LIST_SCREENS_KEY = {
  TITLE_LIST: 'TITLE_LIST',
  REFINER_SCREEN: 'REFINER_SCREEN',
  FEATURED_LIST: 'FEATURED_LIST'
}

export const TITLE_LIST_SCREENS: { [key: string]: string } = {
  [TITLE_LIST_SCREENS_KEY.TITLE_LIST]: 'Title List',
  [TITLE_LIST_SCREENS_KEY.REFINER_SCREEN]: 'Refiners screen',
  [TITLE_LIST_SCREENS_KEY.FEATURED_LIST]: 'Curated list screen',

};

export const TITLE_LIST_EVENTS_KEY = {
  CLEAR_ALL: 'CLEAR_ALL',
  CLEAR_ITEM: 'CLEAR_ITEM',
  SUBJECT_CHANGE: 'SUBJECT_CHANGE',
  CATEGORY_CHANGE: 'CATEGORY_CHANGE',
  VIEW_CHANGE: 'VIEW_CHANGE',
  VIEW_REFINER: 'VIEW_REFINER',
  EXPAND_VIEW:'EXPAND_VIEW',
  COLLAPSE_VIEW:'COLLAPSE_VIEW',

};
export const TITLE_LIST_EVENTS: { [key: string]: string } = {
  [TITLE_LIST_EVENTS_KEY.CLEAR_ALL]: 'Clear all refiners',
  [TITLE_LIST_EVENTS_KEY.CLEAR_ITEM]: 'Selected cleared ',
  [TITLE_LIST_EVENTS_KEY.SUBJECT_CHANGE]: 'Subject level 1 Changed to <item>',
  [TITLE_LIST_EVENTS_KEY.CATEGORY_CHANGE]: 'Subject level 2 Changed to <item>',
  [TITLE_LIST_EVENTS_KEY.VIEW_CHANGE]: 'Refiner <type> selected <value>',
  [TITLE_LIST_EVENTS_KEY.VIEW_REFINER]: 'View <type>',
  [TITLE_LIST_EVENTS_KEY.EXPAND_VIEW]: 'Chevron CTA to expand <view>',
  [TITLE_LIST_EVENTS_KEY.COLLAPSE_VIEW]: 'Chevron CTA to collapse <view>'
};