import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FORMAT_TYPES_EMAIL, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'axis360-email-share-dialog',
  templateUrl: './email-share-dialog.component.html',
  styleUrls: ['./email-share-dialog.component.scss']
})
export class EmailShareDialogComponent implements OnInit {
  toMailId = '';
  ccMailId = '';
  mailSubject = '';
  mailBody = '';
  titleBannerTemplate = '';
  emailForm: FormGroup;
  submitted = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public alertDialog: MatDialogRef<EmailShareDialogComponent>,
    public titleDetailsServices: TitleDetailsService,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initUpdateEmailForm();
    this.data.format = FORMAT_TYPES_EMAIL[this.data.format] || '';
  }
  get f() { return this.emailForm.controls; }

  initUpdateEmailForm() {
    this.emailForm = this.formBuilder.group({
      toMailId: ['', [Validators.required, this.commaSepEmail]],
      ccMailId: [this.data.ccMailId, [this.commaSepCCEmail]],
      mailSubject: [this.data.titleName, Validators.required],
    });
  }

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value?.split(",").map(e => e.trim());
    if (!emails?.includes("")) {
      const forbidden = emails?.some(email =>
        Validators.email(new FormControl(email))
      );
      return forbidden ? { email: true } : null;
    }
    return { required: true, email: false }
  };

  commaSepCCEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const ccEmails = control.value?.split(",").map(e => e.trim());
    if (!ccEmails?.includes("")) {
      const forbidden = ccEmails?.some(ccEmail =>
        Validators.email(new FormControl(ccEmail))
      );
      return forbidden ? { email: true } : null;
    }
    return null;
  };

  sendEmail() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }
    const sendEmailObj = {
      ...this.data,
      ToMailList: this.emailForm.value.toMailId,
      Subject: this.emailForm.value.mailSubject,
      CcMailList: this.emailForm.value.ccMailId,
      body: this.mailBody
    };

    this.titleDetailsServices.sendMail(sendEmailObj).then(res => {
      if (res.errorCode === 400) {
        this.alertDialog.close({ mode: SNAKE_BAR_MODE.ERROR, message: res.message });
        return;
      }
      this.alertDialog.close({ mode: SNAKE_BAR_MODE.INFO, message: 'Mail send Successfully' });
    }, () => {
      this.alertDialog.close({ mode: SNAKE_BAR_MODE.ERROR, message: 'Something went wrong' });
    });
  }

  cancelClick() {
    this.alertDialog.close();
  }

}
