export const TOTAL_TEXT_COUNT = 1000;
export const ANDROID_OPEN_URL = 'market://details?id=com.bt.mdd';
export const IOS_OPEN_URL = 'https://itunes.apple.com/us/app/axis-360/id903001147?mt=8';
export const IOS_INSTALL_URL = 'itms-apps://itunes.apple.com/us/app/axis-360/id903001147?mt=8';
export const TITLE_ROUTE_PATH = 'title/:itemId/:isbn';
export const APP_ELEM_TAGS = {
  APP_ROOT: 'axis360-ng-root',
  NOTIFICATION_ICON: 'axis360-notification-icon',
  HOME: 'axis360-home',
  HOMECAROUSEL: 'axis360-home-carousel'
};
export const SNAKE_BAR_MODE = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  THANK_YOU: 'THANK_YOU',
  ERROR_UH_OH: 'ERROR_UH_OH'
};
export const SNAKE_BAR_MODE_MESSAGE = {
  INFO: 'Success!',
  ERROR: 'Failure!',
  THANK_YOU: 'Thank you!',
  ERROR_UH_OH: 'Uh oh!'
};
export const DEFAULT_SNAKE_BAR_DURATION = 4000;
export const DEFAULT_SNAKE_BAR_MSG = 'Something went wrong, please try again.';
export const DEFAULT_LOGIN_ERROR_BAR_MSG = 'The credentials you entered for login are not valid. Please try again.';
export const DEFAULT_SNAKE_BAR_MSG_CHK = 'The action could not be completed. Please try again.';
export const DEFAULT_SEARCH_FAILS_MSG = 'Something went wrong. Try searching again.';
export const DEFAULT_EMAIL_UPDATE_FAILS_MSG = 'Your e-mail could not be updated.  Please try again.';
export const DEFAULT_ACCESS_DENIED_MSG = "Sorry, access is not allowed. The Audience Level for this title does not match your Profile."
export const DEFAULT_SNAKE_BAR_CONFIG = {
  buttonAction: '',
  duration: DEFAULT_SNAKE_BAR_DURATION,
  panelClass: 'toastMsgSec',
  politeness: 'assertive'
};
export const DEFAULT_SUCCESS_MESSAGE = 'Success';
export const PATRON_FAILURE_MESSAGE = 'Patron information is not provided.';
export const SEARCH_QUERY_PARAM = 'term';
export const APP_THEME_CLASS = {
  STANDARD: 'standard-ng-theme',
  HIGH_CONTRAST: 'high-contrast-ng-theme'
};
export const ACTION_BUTTON_DATA = {
  CHECKOUT: 'checkout',
  ADD_WISHLIST: 'addtowishlist',
  REMOVE_WISHLIST: 'removefromwishlist',
  ADD_HOLD: 'addtohold',
  CANCEL_HOLD: 'cancelhold',
  SUSPEND_HOLD: 'suspendhold',
  UNSUSPEND_HOLD: 'unsuspendhold',
  RENEW: 'renewtitle',
  RETURN: 'returntitle',
  CANCEL_REQUEST: 'cancelrecommendation',
  RECOMMEND: 'recommend',
  ADD_PROGRAM: 'addprogram',
  CANCEL_RECOMMEND: 'cancelrecommendation',
  LIT_LAUNCH: 'LTILaunch',
  BUY_NOW: 'btnBuynow',
  MARK_AS_READ: 'btnMarkAsRead',
  MARK_AS_DONE: 'btnMarkAsDone',
  MARK_AS_NOT_DONE: 'btnMarkAsNotDone',
  REMOVE_CHECKOUT_HISTORY: 'removefromcheckouthistory',
  READ_ONLINE: 'readonline',
  LISTEN_NOW: 'listennow',
  DOWNLOAD: 'download',
  ADD_CTA: 'addprogram',
  REMOVE: 'btnRemove',
  ADD_TO_PROGRAM: 'btnAddProgram'
};

export const ACTION_BUTTON_TEXT = {
  [ACTION_BUTTON_DATA.RENEW]: 'renew',
  [ACTION_BUTTON_DATA.RETURN]: 'return',
  [ACTION_BUTTON_DATA.REMOVE_CHECKOUT_HISTORY]: 'remove'
};

export const POPUP_ACTION_BUTTON = {
  [ACTION_BUTTON_DATA.CHECKOUT]: 'Checkout not available',
  [ACTION_BUTTON_DATA.RECOMMEND]: 'Purchase Requests is pending',
  [ACTION_BUTTON_DATA.ADD_HOLD]: 'Hold cannot be completed'
};

export const KZ_FORMAT_TYPES = {
  VIDEOS: 'VID',
  VBOOKS: 'VBK'
};

export const FORMAT_TYPES_EMAIL = {
  ABT: 'eAudio',
  EBT: 'eBook',
  VID: 'Videos',
  VBK: 'Videobook'
}

export const KZ_FORMAT_VENDOR_NAME = {
  VIDEOS: 'Video',
  VBOOKS: 'vBook'
};

export const FORMAT_TYPES = {
  FORMATTYPE_EBOOK: 'EBT',
  FORMAT_EBOOK: 'EBOOK',
  FORMAT_BLIO: 'BLIO',
  FORMAT_XPS: 'XPS',
  FORMAT_F1: 'F1',
  FORMAT_ADOBE_EPUB: 'ADOBE EPUB',
  FORMAT_EPUB: 'EPUB',
  FORMAT_EPB: 'EPB',
  FORMAT_F2: 'F2',
  FORMAT_PDF: 'PDF',
  FORMAT_ADOBE_PDF: 'ADOBE PDF',
  FORMAT_F3: 'F3',
  FORMATTYPE_AUDIO: 'ABT',
  FORMAT_AUDIO: 'AUDIO',
  FORMAT_AUDIOBOOK: 'AUDIOBOOK',
  FORMAT_AUDIO_BOOK: 'AUDIO BOOK',
  FORMAT_ACOUSTIK: 'ACOUSTIK',
  FORMAT_FND: 'FND',
  FORMAT_F4: 'F4',
  FORMAT_AXISNOW: 'AXS',
  FORMAT_F5: 'F5',
  FORMATTYPE_DIGITALONLINE: 'PNF',
  FORMAT_DIGITALONLINE: 'PennFoster',
  FORMAT_DIGITALONLINE_1: 'Penn Foster',
  FORMAT_DIGITALONLINE_2: 'PENNFOSTER',
  FORMAT_DIGITALONLINE_3: 'PENN FOSTER',
  FORMAT_DOL: 'PNF',
  FORMAT_FAST_DOL: 'DOL',
  FORMAT_F6: 'F6',
  FORMATTYPE_HOME_EBOOK: 'HOME_EBT',
  FORMATTYPE_HOME_AUDIO: 'HOME_ABT',
  FORMATTYPE_KZ_EBOOK: 'KZ_EBT',
  FORMATTYPE_KZ_AUDIO: 'KZ_ABT',
  FORMATTYPE_KZ_AUDIOBOOK: 'KZ_AUDIOBOOK',
  FORMATTYPE_KZ_EBOOK1: 'KZ_EBOOK',
  FORMATTYPE_KZ_VIDEOS: 'KZ_VID',
  FORMATTYPE_KZ_VBOOKS: 'KZ_VBK',
};

export const FORMAT_ICON_MAP = {
  [FORMAT_TYPES.FORMATTYPE_EBOOK]: { icon: 'ebook', alt: 'eBook' },
  [FORMAT_TYPES.FORMATTYPE_AUDIO]: { icon: 'audio', alt: 'eAudio' },
  [FORMAT_TYPES.FORMATTYPE_DIGITALONLINE]: { icon: 'kz-ebook', alt: 'Print Book' },
  [FORMAT_TYPES.FORMATTYPE_HOME_EBOOK]: { icon: 'home_ebook', alt: 'eBook' },
  [FORMAT_TYPES.FORMATTYPE_HOME_AUDIO]: { icon: 'home_audio', alt: 'eAudio' },
  [FORMAT_TYPES.FORMATTYPE_KZ_EBOOK]: { icon: 'kz-ebook', alt: 'eBook' },
  [FORMAT_TYPES.FORMATTYPE_KZ_AUDIO]: { icon: 'kz-audio', alt: 'eAudio' },
  [FORMAT_TYPES.FORMATTYPE_KZ_VIDEOS]: { icon: 'kz-videos', alt: 'videos' },
  [FORMAT_TYPES.FORMATTYPE_KZ_VBOOKS]: { icon: 'kz-vbooks', alt: 'Videobooks' }
};

export const HOME_AVAILABILITY = [
  {id:'loc_availabilityAll', text: 'All', value: 'All' },
  { id:'loc_availabilityNow', text: 'Available Now', value: 'Available' }
];
export const HOME_AVAILABILITY_EPOPUP_LIB = [{id:'loc_availabilityNowEpopup', text: 'Available Now', value: 'Available' }];
export const HOME_FORMAT_TYPE = [
  { id:'loc_for_all',text: 'All', value: 'All' },
  { id:'loc_for_ebook',text: 'eBook', value: 'EBT' },
  { id:'loc_for_audio', text: 'Audio', value: 'ABT' }
];
export const KZ_HOME_FORMAT_TYPE = [
  { id:'loc_hfor_all', text: 'All', value: 'All', enabled: true },
  { id:'loc_hfor_ebook', text: 'eBook', value: 'EBT', enabled: true },
  { id:'loc_hfor_audio', text: 'eAudio', value: 'ABT', enabled: true },
  { id:'loc_hfor_video', text: 'Video', value: 'VID', enabled: false },
  { id:'loc_hfor_videoBooks', text: 'Videobooks', value: 'VBK', enabled: false }
];

export const KZ_MYSTUFF_FORMAT_TYPE = [
  { id:'loc_mystuff_all', text: 'All', value: 'All', enabled: true },
  { id:'loc_mystuff__ebook', text: 'eBook', value: 'EBT', enabled: true },
  { id:'loc_mystuff__audio', text: 'eAudio', value: 'ABT', enabled: true },
  { id:'loc_mystuff__video', text: 'Video', value: 'VID', enabled: true },
  { id:'loc_mystuff__videoBooks', text: 'Videobooks', value: 'VBK', enabled: true }
];
export const KZ_MYSTUFF_NON_THIRD_PARY_FORMAT_TYPE = [
  { id:'loc_mystuffthird_all', text: 'All', value: 'All', enabled: true },
  { id:'loc_mystuffthird_ebook', text: 'eBook', value: 'EBT', enabled: true },
  { id:'loc_mystuffthird_audio', text: 'eAudio', value: 'ABT', enabled: true }
];
export const MYSTUFF_SORT = [
  { text: 'Recently Checked Out', value: 'Latest' },
  { text: 'Due Date', value: 'Returndate' },
  { text: 'A-Z', value: 'Title' }
];
export const MYSTUFF_SORT_CHECKOUT = [
  { text: 'Recently Opened', value: 'LastOpened' },
  { text: 'Recently Checked Out', value: 'Latest' },
  { text: 'Due Date', value: 'DueDate' },
  { text: 'A-Z', value: 'Title' }
];
export const MYSTUFF_SORT_WHISHLIST = [
  { text: 'Recently Added', value: 'Latest' },
  { text: 'Ratings', value: 'score' },
  { text: 'A-Z', value: 'Title' }
];
export const MYSTUFF_SORT_HOLDS = [
  { text: 'Recently Added', value: 'Latest' },
  { text: 'Hold Position (Low to High)', value: 'holdposition' },
  { text: 'A-Z', value: 'Title' }
];
export const MYSTUFF_SORT_PURCHASE_REQUEST = [
  { text: 'Recently Requested', value: 'Latest' },
  { text: 'Ratings', value: 'score' },
  { text: 'A-Z', value: 'Title' }
];
export const MYSTUFF_SORT_HISTORY = [
  { text: 'Recently Checked Out', value: 'Latest' },
  { text: 'Ratings', value: 'score' },
  { text: 'A-Z', value: 'Title' }
];

export const MYSTUFF_SORT_REFINER = 'myStuffSort';
export const CURATED_LISTS = {
  CHECKOUT: { text: 'checkout', title: 'Checkout' },
  CHECKOUT_RECOMMENDATION: { text: 'Recommended_for_you', title: 'Based on Your Checkouts' },
  INTEREST_RECOMMENDATION: { text: 'Based_your_Interests', title: 'Based on your Interests' }
};
export const HOME_REFINER = {
  AVAILABILITY: 'myLibraryAvailability',
  FORMAT: 'myLibraryFormat'
};
export const LIST_CARD_COUNT = 10;
export const LIST_CARD_COUNT_HISTORY = 24;
export const MAX_CARD_COUNT = 9999;
export const SHIMMER_COMPONENTS = {
  TITLE_CARDS: 'TITLE_CARDS',
  NAV_TABS: 'NAV_TABS',
  GOALS: 'GOALS',
  BADGE_CARD: 'BADGE_CARD',
  ANNOUNCEMENT_CARD: 'ANNOUNCEMENT_CARD',
  ACTION_BUTTON: 'ACTION_BUTTON',
  ARTICLE_CARDS: 'ARTICLE_CARDS',
  WIDGET_CARD: 'WIDGET_CARD',
  THIRD_PARTY_DETAILS: 'THIRD_PARTY_DETAILS',
  THIRD_PARTY_ITEM_DETAILS: 'THIRD_PARTY_ITEM_DETAILS',
  TITLE_DETAILS_RATINGS: 'TITLE_DETAILS_RATINGS',
  TITLE_DETAILS_MORE_INFO: 'TITLE_DETAILS_MORE_INFO',
  TITLE_DETAILS_COPIES_INFO: 'TITLE_DETAILS_COPIES_INFO',
  TITLE_DETAILS_BUTTONS: 'TITLE_DETAILS_BUTTONS'
};
export const COLLECTION_TYPE = {
  Default: 'Default',
  PPC: 'PPC',
  Recommendation: 'Recommendation',
  REG: 'REG'
};
export const CAROUSEL_CONST = {
  myassignment: 'myassignment',
  foundation_skills: 'Foundation Skills',
  featured: 'Featured',
  checkout: 'checkout',
  Currently_Reading: 'Currently Reading',
  Availability: 'Availability',
  Assignment: 'Assignment',
  Recommended_for_you: 'Recommended_for_you',
  Based_your_Interests: 'Based_your_Interests',
  booktitle: 'Book title',
  format: 'Format',
  duration: 'Duration ',
  bookstatus: 'Book Status '
};
export const PRESS_READER_PAGE_SIZE = 12;
export const PRESS_READER_SORT_OPTIONS = {
  SEARCH_RANK: { displayable: 'Relevance', value: 'searchrank desc' },
  NAME_A_Z: { displayable: 'Publication Name A to Z', value: 'name asc' },
  NAME_Z_A: { displayable: 'Publication Name Z to A', value: 'name desc' },
  RANK: { displayable: 'Rank', value: 'rank desc' },
  POPULARITY: { displayable: 'Popularity', value: 'rank desc' },
  ISSUE_DATE: { displayable: 'Issue Date', value: 'latestissuedate desc' },
  LATEST_ISSUE_DATE: { displayable: 'Date', value: 'latestissuedate desc' }
};
export const PUBLICATION_IMAGE_URL = 'https://i.prcdn.co/img?file=<file>&page=1&width=<width>&retina=2';
export const PUBLICATION_REDIRECTION_URL = 'https://www.pressreader.com/<cid>/<issueDate>';
export const PUBLICATION_TYPES = {
  MAGAZINES: 'Magazine',
  NEWS_PAPERS: 'Newspaper',
  LATEST_ISSUES: 'LatestIssues',
  SUPPLEMENT: 'Supplement'
};
export const MYSTUFF_LIST_MAP = {
  checkouts: 'checkout',
  holds: 'hold',
  wishlist: 'wishlist',
  recommendations: 'recommend',
  history: 'history'
};
export const MYSTUFF_ACTIVE_TAB = {
  checkouts: 'Checkouts',
  holds: 'Holds',
  wishlist: 'Wishlist',
  recommendations: 'Purchase Requests',
  history: 'Checkout History'
};
export const NON_FEATURE_LISTIDS = ['checkout', 'Assignment', 'Recommended_for_you', 'Based_your_Interests', 'myassignment', 'Collections'];
export const HOLD_POSITION_INFO = {
  panelClass: 'mystuff-dialog-alert',
  heading: 'Holds Info',
  content: 'Your Hold position may change based on the activity of other users. You will be notified when a copy is availble for you.',
  type: 'INFO',
  closeIcon: true
};
export const MYSTUFF_SORT_ADDED_DATE = 'AddedDate';
export const IMAGE_UPLOAD_MAX_WIDTH = 250;
export const IMAGE_UPLOAD_MAX_HEIGHT = 250;
export const ERROR_ALERT_DATA = {
  heading: 'Error',
  content: 'Something went wrong! Please refresh the page.',
  submitBtnText: 'Refresh',
  type: 'ERROR'
};
export const NETWORK_ALERT = {
  heading: 'Error',
  content: 'Please check your internet connection and try again',
  submitBtnText: 'Ok',
  type: 'ERROR'
};
export const MYSTUFF_FILTER_HOLDS = 'holds';
export const MYSTUFF_FILTER_RECOMMENDATIONS = 'recommendations';
export const MYSTUFF_FORMAT_TYPE_ALL = 'All';
export const MYSTUFF_SORT_ARRAY = {
  checkouts: MYSTUFF_SORT_CHECKOUT,
  holds: MYSTUFF_SORT_HOLDS,
  wishlist: MYSTUFF_SORT_WHISHLIST,
  recommendations: MYSTUFF_SORT_PURCHASE_REQUEST,
  history: MYSTUFF_SORT_HISTORY
};
export const PROGRESS_BAR = {
  checkouts: 'Checkouts',
  wishlist: 'Wishlist',
  history: 'Checkout History'
};

export const ACTION_BUTTON = {
  [ACTION_BUTTON_DATA.CHECKOUT]: 'Checkout',
  [ACTION_BUTTON_DATA.ADD_WISHLIST]: 'Add to Wishlist',
  [ACTION_BUTTON_DATA.REMOVE_WISHLIST]: 'Remove from Wishlist',
  [ACTION_BUTTON_DATA.ADD_HOLD]: 'Place on Hold',
  [ACTION_BUTTON_DATA.CANCEL_HOLD]: 'Remove Hold ',
  [ACTION_BUTTON_DATA.SUSPEND_HOLD]: 'Suspend Hold',
  [ACTION_BUTTON_DATA.UNSUSPEND_HOLD]: 'Un suspend Hold',
  [ACTION_BUTTON_DATA.RENEW]: 'Renew ',
  [ACTION_BUTTON_DATA.RETURN]: 'Return ',
  [ACTION_BUTTON_DATA.RECOMMEND]: 'Recommend',
  [ACTION_BUTTON_DATA.CANCEL_RECOMMEND]: 'Cancel Recommendation',
  [ACTION_BUTTON_DATA.CANCEL_REQUEST]: 'Cancel Request',
  [ACTION_BUTTON_DATA.LIT_LAUNCH]: 'LTILaunch',
  [ACTION_BUTTON_DATA.BUY_NOW]: 'Buy Now',
  [ACTION_BUTTON_DATA.MARK_AS_READ]: 'MarkAsRead',
  [ACTION_BUTTON_DATA.READ_ONLINE]: 'Read Now',
  [ACTION_BUTTON_DATA.REMOVE_CHECKOUT_HISTORY]: 'Remove',
  [ACTION_BUTTON_DATA.DOWNLOAD]: 'Download',
  [ACTION_BUTTON_DATA.LISTEN_NOW]: 'Listen Now',
  [ACTION_BUTTON_DATA.ADD_CTA]: 'Add CTA',
  [ACTION_BUTTON_DATA.MARK_AS_DONE]: 'MarkAsDone',
  [ACTION_BUTTON_DATA.MARK_AS_NOT_DONE]: 'Mark As Not Done'
};
export const MYSTUFF_ACTIVE_TAB_SEARCH = {
  checkouts: 'Checkouts',
  holds: 'Holds',
  wishlist: 'Wishlists',
  recommendations: 'Purchase Requests',
  history: 'History'
};
export const INTEREST_SURVEY_SUCCESS = 'Your interests have been saved!';
export const MYSTUFF_NORESULT_MESSAGE = {
  checkouts: 'You currently have no <format> checked out.',
  holds: 'You currently have no <format> placed on hold.',
  wishlist: 'You currently have no <format> added to wishlist.',
  recommendations: "You currently have no <format> 'Requested'.",
  history: 'You currently have no history available for the <format>.'
};
export const FILTER_FORMAT = {
  All: '',
  EBT: 'ebooks',
  ABT: 'eAudio books',
  VID: 'Videos',
  VBK: 'Videobooks'
};
export const THIRD_PARTY_VENDOR_CODE = {
  ['VID']: 'VIDEOS',
  ['VBK']: 'VBOOKS'
};

export const SEARCH_NORESULT_MESSAGE = 'No Results Found';
export const READY_FOR_CHECKOUT = 'READY FOR CHECKOUT';
export const EMAIL_HOLD_NOTIFICATION = {
  heading: 'Hold Confirmed',
  content: 'If you would like to receive email notifications when Holds become available, please update your Profile Settings.',
  submitBtnText: 'Ok',
  type: 'CONFIRMATION'
};
export const ALL_REFINERS = 'All';
export const AUDIENCE_KID = 'Kid';

export const LEARNING_ACTIVITY_FILTER = {
  activityTypes: 'All Activity Type',
  audiences: 'All Audiences'
};
export const INTEREST = 'interest';
export const PURCHASE_REQUESTS = {
  recommendations: 'recommendations',
  pageName: 'Purchase Requests page'
};
export const HOME = {
  ['Library']: '/ng/view/library',
  ['My Shelf']: '/ng/view/myshelf'
};
export const LIBRARY_FILTERS = {
  availability: 'Availability',
  format: 'Format'
};
export const MYSTUFF_CAROUSEL_BANNER_TEXT = {
  available: 'Available',
  pastCheckout: 'Past Checkout',
  onHold: 'ON HOLD',
  readyForCheckout: 'READY FOR CHECKOUT',
  unavailable: 'UNAVAILABLE',
  waitlist: 'Waitlist'
};
export const MYSTUFF_CAROUSEL_FOOTER_TEXT = {
  EBOOK: 'eBook',
  EAUDIO: 'eAudio',
  WAITLIST: 'Waitlist'
};
export const LIBRARY_CAROUSEL_FOOTER_TEXT = {
  eBook: 'eBook',
  eAudio: 'eAudio',
  printBook: 'Print Book',
  Waitlist: 'Waitlist',
  pastCheckout: 'Past Checkout',
  onHold: 'ON HOLD',
  unAvailable: 'UNAVAILABLE',
  readyForCheckout: 'READY FOR CHECKOUT',
  holdSuspended: 'HOLD SUSPENDED',
  comingSoon: 'COMING SOON',
  available: 'Available',
  requested: 'REQUESTED',
  expieingSoon: 'EXPIRING SOON'
}
export const LIBRARY_URL = {
  AXIS360: 'axis360',
  BOUNDLESS: 'boundless'
};
export const PROGRESSIVE_CALLED_COUNT = {
  PROGRESSIVE_INITIAL_CALLED: 2,
  PROGRESSIVE_CALLED: 2
}

export const USER_AND_SETTINGS = {
  appSettings: {
    webPathExpressEnabled: "true",
    pressReaderEnabled: "true",
    pressReaderDefaultCountry: "us",
    pressReaderDefaultLanguage: "en",
    titleImageSrcFormat: "https://cccloudcdn.baker-taylor.com/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/{0}/Medium/Logo",
    alwaysAvailableKz: false,
    boundlessAndroidBundleId: "com.bnt.boundless.mobile.android",
    boundlessIOSBundleId: "com.bnt.boundless.mobile.ios"
  },
  librarySettings: {
    id: "6FBC1A14-D1D6-E811-8607-0003FFE6AD91",
    libraryKey: 80,
    name: "Magic Independent Library",
    acoustikFilter: true,
    marketType: "Elementary School",
    adobeType: "library",
    timezone: "India Standard Time",
    hideNonRTVTitle: false,
    urlSettings: {
      anonymousUrl: "https://magici.boundlessqa5.baker-taylor.com",
      urlPrefix: "magici",
      bookStore: ""
    },
    limits: {
      checkoutLimit: 0,
      maxeBookCheckoutLimit: 0,
      maxeAudiobookCheckoutLimit: 0,
      holdLimit: 0,
      defaultLendingPeriod: 0,
      renewalProperty: {
        renewalNotificationPeriod: "1",
        renewalPeriod: "1",
        renewalTypeProperty: 1,
        renewalTypePropertySpecified: true
      },
      holdType: 2
    },
    branding: {
      headerLogoPath: null,
      logoUrl: "https://magici.boundlessqa5.baker-taylor.com/ng/view/myshelf",
      siteTheme: "",
      isMessagingEnabled: true,
      messagingInfo: {
        body: "<p><strong>new data</strong></p>",
        endDate: "2024-01-31T18:29:59",
        endDateSpecified: true,
        header: "test header",
        id: "437",
        isValid: true,
        isValidSpecified: true,
        libraryID: "6FBC1A14-D1D6-E811-8607-0003FFE6AD91",
        optOutOption: true,
        optOutOptionSpecified: true,
        startDate: "2024-01-01T18:29:59",
        startDateSpecified: true,
        type: null,
        updatedBy: null
      },
      leftBranding: ""
    },
    authSettings: {
      ilsVendorID: "PREFIX",
      noPatronPinNeeded: false,
      caseInsensitiveBarcode: true,
      isTrimPatronBarcode: false,
      loginRequired: false,
      prefix: "QA5;BT",
      registrationPassword: "",
      registrationPasswordPrompt: "",
      showRegistrationPasswordPrompt: "",
      showLoginPrompt: "",
      ncipServerURL: null,
      ezProxyLabel: "",
      isNewEZProxy: false
    },
    consortia: {
      isConsortiaLibrary: true,
      consortiaType: "MEMBER",
      consortiaParentId: null,
      parentListItems: [
        {
          parentId: "D4B4F8F5-2EE0-E711-80F7-005056B87613",
          parentName: "*eRead Illinois",
          priority: 1,
          prioritySpecified: true
        },
        {
          parentId: "C6A30E83-7B4D-EA11-A94C-0003FFE68A97",
          parentName: "AK_ParentTestLibrary1",
          priority: 2,
          prioritySpecified: true
        }
      ],
      showSwitchLibrary: false,
      switchLibraryUrl: null
    },
    recommendations: {
      allowPatronRecommendation: true,
      patronRecommendationCollection: "4d189dcb-f267-486a-b6c2-ba1ea0d9f2ca",
      maxRecommendationAllowed: 30
    },
    reviews: {
      industrialReviewsEnabled: false,
      patronReviewsEnabled: true,
      publicReviewsEnabled: false,
      makeMyReviewsPublicEnabled: false,
      viewPublicReviewsFromConsortiaEnabled: false,
      makeReviewsPublicToConsortiaEnabled: false
    },
    assignment: {
      isReservationEnabled: true
    },
    listenNow: true,
    readNow: true,
    thirdPartyExtID: {
      pennFosterClientID: "334393"
    },
    pennFosterSetting: false,
    enablePatronSupport: true,
    supportPageURL: "https://bakerandtaylor.tfaforms.net/42",
    supportPageLabel: "Patron Support",
    collectionEnabled: true,
    buyNow: {
      displayNotification: false,
      notificationHeader: "",
      notificationMessage: ""
    },
    subCategorySort: "Author",
    staffPickLabel: "Staff Picks",
    isAutoCheckout: true,
    isSubscribedToPPCCollection: false,
    ppcLibraryId: "",
    pressReader: {
      isPressReaderEnabled: true,
      siteID: "30066",
      accessURL: "https://www.pressreader.com/giftedaccess/activate",
      token: ""
    },
    ppcBugetReached: false,
    dataCollectionID: "6FBC1A14-D1D6-E811-8607-0003FFE6AD91",
    isPPCOnlyLibrary: false,
    dataCollectionType: "Default",
    enableWebPathExpress: true,
    programEnabled: true,
    recommendationsEnabled: true,
    partnerSiteEnabled: false,
    enablePayPerCircFlag: false,
    kidsZoneEnabled: true,
    axis360Enabled: true,
    profileFeatures: {
      adult: {
        programsEnabled: true,
        recommendationEnabled: true,
        insightsBadgesEnabled: true,
        webpathExpressEnabled: true,
        learningActivityEnabled: true,
        vendors: [
          {
            vendorName: "Press Reader",
            vendorCode: "PRESSRDR",
            vendorEnabled: true
          },
          {
            vendorName: "Widgets",
            vendorCode: "WIDGETS",
            vendorEnabled: true
          },
          {
            vendorName: "VBooks",
            vendorCode: "VBOOKS",
            vendorEnabled: true
          },
          {
            vendorName: "Videos",
            vendorCode: "VIDEOS",
            vendorEnabled: true
          }
        ]
      },
      teen: {
        programsEnabled: true,
        recommendationEnabled: true,
        insightsBadgesEnabled: true,
        webpathExpressEnabled: true,
        learningActivityEnabled: true,
        vendors: [
          {
            vendorName: "Press Reader",
            vendorCode: "PRESSRDR",
            vendorEnabled: false
          },
          {
            vendorName: "Widgets",
            vendorCode: "WIDGETS",
            vendorEnabled: true
          },
          {
            vendorName: "VBooks",
            vendorCode: "VBOOKS",
            vendorEnabled: true
          },
          {
            vendorName: "Videos",
            vendorCode: "VIDEOS",
            vendorEnabled: true
          }
        ]
      },
      kid: {
        programsEnabled: true,
        recommendationEnabled: true,
        insightsBadgesEnabled: true,
        webpathExpressEnabled: true,
        learningActivityEnabled: true,
        vendors: [
          {
            vendorName: "Press Reader",
            vendorCode: "PRESSRDR",
            vendorEnabled: false
          },
          {
            vendorName: "Widgets",
            vendorCode: "WIDGETS",
            vendorEnabled: true
          },
          {
            vendorName: "VBooks",
            vendorCode: "VBOOKS",
            vendorEnabled: true
          },
          {
            vendorName: "Videos",
            vendorCode: "VIDEOS",
            vendorEnabled: true
          }
        ]
      }
    },
    subscriptionData: [
      {
        collection: "3430b643-9371-ed11-819c-0022481e03c3",
        name: "VBooks"
      },
      {
        collection: "3430b643-9371-ed11-819c-0022481e03c3",
        name: "Videos"
      }
    ],
    adultProfileFeatures: "ANNOC,BOM,PRGMS,RCMS,INSBG,WBEXP,LARH,PRESSRDR,WIDGETS,VBOOKS,VIDEOS,ANNOC,BOM",
    teenProfileFeatures: "ANNOC,BOM,PRGMS,RCMS,INSBG,WBEXP,LARH,WIDGETS,VBOOKS,VIDEOS,ANNOC,BOM",
    kidProfileFeatures: "ANNOC,BOM,PRGMS,RCMS,INSBG,WBEXP,LARH,WIDGETS,VBOOKS,VIDEOS,ANNOC,BOM",
    isAppBannerMessage: true,
    countryCode: 569,
    languageCode: 808,
    countryTxtCode: "au",
    languageTxtCode: "en",
    isActiveLibrary: true,
    isMigrated: true
  },
  user: {
    userName: "",
    isLoggedIn: false,
    isHighContrastEnabled: false,
    enableTracking: false,
    displayTrackingPrompt: false,
    email: "",
    notifications: null,
    patronId: null
  },
  profile: null,
  vendors: [
    {
      category_type: null,
      collection_id: null,
      description: "7,000 news and entertainment publications from around the world.",
      display_name: "Newspapers & Magazines",
      format_code: null,
      include_in_search: true,
      include_in_widgets: false,
      url_alias: "/pressreader",
      vendor_code: "PRESSRDR",
      vendor_name: "Press Reader",
      vendor_order: 1,
      vendorEnabled: false,
      vendor_groups: []
    },
    {
      category_type: null,
      collection_id: null,
      description: "",
      display_name: "WIDGETS",
      format_code: null,
      include_in_search: false,
      include_in_widgets: false,
      url_alias: "/widgets",
      vendor_code: "WIDGETS",
      vendor_name: "Widgets",
      vendor_order: 4,
      vendorEnabled: false,
      vendor_groups: null
    },
    {
      category_type: "bisac",
      collection_id: null,
      description: "Picture books brought to life with animation, narration, music and read-along text.",
      display_name: "Videobooks",
      format_code: "vbk",
      include_in_search: true,
      include_in_widgets: true,
      url_alias: "/vbooks",
      vendor_code: "VBOOKS",
      vendor_name: "VBooks",
      vendor_order: 3,
      vendorEnabled: false,
      vendor_groups: []
    },
    {
      category_type: "series",
      collection_id: null,
      description: "Join in on fun video adventures with Checkers, Snoozer and friends!&nbsp;",
      display_name: "Checkers Library TV",
      format_code: "vid",
      include_in_search: true,
      include_in_widgets: true,
      url_alias: "/videos",
      vendor_code: "VIDEOS",
      vendor_name: "Videos",
      vendor_order: 2,
      vendorEnabled: false,
      vendor_groups: []
    }
  ]
}
