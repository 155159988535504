import { HOME_AVAILABILITY, HOME_FORMAT_TYPE, HOME_REFINER, COLLECTION_TYPE, CAROUSEL_CONST } from '@shared/constants/app.constants';

export { HOME_AVAILABILITY, HOME_FORMAT_TYPE, HOME_REFINER, COLLECTION_TYPE, CAROUSEL_CONST };

export const INSIGHT_GOAL = {
  AVG_READ: 'AVG_READ',
  AVG_LISTEN: 'AVG_LISTEN',
  CURRENT_STREAK: 'CURRENT_STREAK',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  MONTHLY_LISTENED: 'MONTHLY_LISTENED',
  YEARLY_LISTENED: 'YEARLY_LISTENED'
};
export const INSIGHT_GOAL_PREDEFINED = {
  [INSIGHT_GOAL.AVG_READ]: {
    type: INSIGHT_GOAL.AVG_READ,
    goal: 0,
    done: 0,
    max: 999,
    min: 1,
    styleClass: 'avg-read',
    title: 'Average Read',
    subtitle: 'MINUTES A DAY',
    goalHeading: 'Set Reading Goal',
    goalInputTitle: 'Enter Goal for Minutes of Reading Per Day',
    goalRelatedTitle: 'Minutes Read',
    goalRelatedSubTitle: 'OF YOUR DAILY GOAL',
    goalTitle: 'MINS OF READING PER DAY',
    content: 'You are currently averaging <avg> minute<add-s> of reading time per day over the past 7 days.',
    goalName: 'minsread',
    id: 'loc_labelAverageRead',
    modalId: 'loc_labelSetReadingGoal',
    modalDesID: 'loc_labelSetReadingGoalDes'
  },
  [INSIGHT_GOAL.AVG_LISTEN]: {
    type: INSIGHT_GOAL.AVG_LISTEN,
    goal: 0,
    done: 0,
    max: 999,
    min: 1,
    styleClass: 'avg-listener',
    title: 'Average Listened',
    subtitle: 'MINUTES A DAY',
    goalRelatedTitle: 'Minutes Listened',
    goalHeading: 'Set Listening Goal',
    goalInputTitle: 'Enter Goal for Minutes of Listening Per Day',
    goalRelatedSubTitle: 'OF YOUR DAILY GOAL',
    goalTitle: 'MINS OF LISTENING PER DAY',
    content: 'You are currently averaging <avg> minute<add-s> of listening time per day over the past 7 days.',
    goalName: 'minslisten',
    id: 'loc_labelAverageListened',
    modalId: 'loc_labelSetListeningGoal',
    modalDesID: 'loc_labelSetListeningGoalDes'
  },
  [INSIGHT_GOAL.CURRENT_STREAK]: {
    type: INSIGHT_GOAL.CURRENT_STREAK,
    goal: 0,
    done: 0,
    max: 365,
    min: 1,
    styleClass: 'current-streak',
    title: 'Current Streak',
    goalRelatedTitle: 'Current Streak',
    subtitle: 'DAYS IN A ROW',
    goalHeading: 'Set Streak Goal',
    goalInputTitle: 'Enter New Streak Goal',
    goalRelatedSubTitle: 'OF YOUR STREAK GOAL',
    goalTitle: 'CURRENT STREAK',
    content: 'Your current daily reading streak is <done> day<add-s> in a row.',
    goalName: 'streak',
    id: 'loc_labelCurrentStreak',
    modalId: 'loc_labelSetStreakingGoal'
  },
  [INSIGHT_GOAL.MONTHLY]: {
    type: INSIGHT_GOAL.MONTHLY,
    goal: 0,
    done: 0,
    max: 999,
    min: 1,
    styleClass: 'monthly-insight',
    title: 'Books Read',
    goalRelatedTitle: 'Books Read',
    subtitle: 'READ THIS MONTH',
    goalHeading: 'Set Monthly Book Goal',
    goalInputTitle: 'Enter Monthly Goal',
    goalRelatedSubTitle: 'OF YOUR MONTHLY GOAL',
    goalTitle: 'MINS OF READING MONTHLY',
    content: 'You have read <done> book<add-s> this month.',
    goalName: 'booksreadinmonth',
    id: 'loc_labelBooksReadMonth',
    modalId: 'loc_labelSetMonthlyBookGoal'
  },
  [INSIGHT_GOAL.YEARLY]: {
    type: INSIGHT_GOAL.YEARLY,
    goal: 0,
    done: 0,
    max: 9999,
    min: 1,
    styleClass: 'yearly-insight',
    title: 'Books Read',
    goalRelatedTitle: 'Books Read',
    subtitle: 'READ THIS YEAR',
    goalTitle: 'MINS OF READING YEARLY',
    goalHeading: 'Set Yearly Book Goal',
    goalInputTitle: 'Enter Yearly Goal',
    goalRelatedSubTitle: 'OF YOUR YEARLY GOAL',
    content: 'You have read <done> book<add-s> this year.',
    goalName: 'booksreadinyear',
    id: 'loc_labelBooksReadYear',
    modalId: 'loc_labelSetYearlyGoal'
  },
  [INSIGHT_GOAL.MONTHLY_LISTENED]: {
    type: INSIGHT_GOAL.MONTHLY_LISTENED,
    goal: 0,
    done: 0,
    max: 999,
    min: 1,
    styleClass: 'listened-month',
    title: 'Books Listened',
    goalRelatedTitle: 'Books Listened',
    subtitle: 'LISTENED THIS MONTH',
    goalHeading: 'Set Monthly Book Goal',
    goalInputTitle: 'Enter Monthly Goal',
    goalRelatedSubTitle: 'OF YOUR MONTHLY GOAL',
    goalTitle: 'MINS OF LISTENING MONTHLY',
    content: 'You have listened <done> book<add-s> this month.',
    goalName: 'bookslistenedinmonth',
    id: 'loc_labelBooksListenedMonth',
    modalId: 'loc_labelSetMonthlyListened'
  },
  [INSIGHT_GOAL.YEARLY_LISTENED]: {
    type: INSIGHT_GOAL.YEARLY_LISTENED,
    goal: 0,
    done: 0,
    max: 9999,
    min: 1,
    styleClass: 'listened-yearly',
    title: 'Books Listened',
    goalRelatedTitle: 'Books Listened',
    subtitle: 'LISTENED THIS YEAR',
    goalTitle: 'MINS OF LISTENING YEARLY',
    goalHeading: 'Set Yearly Book Goal',
    goalInputTitle: 'Enter Yearly Goal',
    goalRelatedSubTitle: 'OF YOUR YEARLY GOAL',
    content: 'You have listened <done> book<add-s> this year.',
    goalName: 'bookslistenedinyear',
    id: 'loc_labelBooksListenedYear',
    modalId: 'loc_labelSetYearlyListenedGoal'
  }
};
export const INTERESTS_AUDIENCE_LEVEL = {
  JUVENILE: { name: 'Juvenile', value: 'juvenile' },
  YOUNG_ADULT: { name: 'Young Adult', value: 'youngadult' },
  ADULT: { name: 'Adult', value: 'adult' },
  TEEN: { name: 'Teen', value: 'teen' },
  KID: { name: 'Kid', value: 'kid' }
};

export const KZ_INTEREST_SURVEY_CLASS_PROFILE = {
  adult: 'adult',
  teen: 'teen',
  kid: 'kid'
};
