<div class="kz-drawer-refiners-container thirdparty-tiertwoleftwrap" *ngIf="isLoading; else refiners">
  <div class="refine-filter-head">
    <h2 class="refiners-title single-ellipsis text-uppercase">{{component.title}}</h2>
  </div>
  <axis360-spinner-loader [isLoading]="true"></axis360-spinner-loader>
</div>
<ng-template #refiners>
  <div class="kz-drawer-refiners-container thirdparty-tiertwoleftwrap" *ngIf="!isMobileResponsive">
    <div class="refiners-head visible-sm">
      <button class="close-icon" role="button" aria-label="Close refiners" (click)="dialogRef.close()">
        <mat-icon svgIcon="kz-close" aria-hidden="true">close</mat-icon>
      </button>
    </div>
    <axis360-categories *ngIf="showCategories"></axis360-categories>
    <ng-container *ngIf="sortByRefiner?.length > 0 && sortByRefiner[0]?.filters?.length > 0">
      <div class="kz-drawer-refiners-container  kz-search-refiners">
        <ng-container *ngFor="let refiner of sortByRefiner; let j = index">
          <mat-accordion class="kz-checkbox-sec refiners-accordion">
            <mat-expansion-panel class="refiners-expansion-panel" [expanded]="true" (opened)="setState(j)"
              (closed)="refinerscroll()">
              <mat-expansion-panel-header class="refiners-panel-header">
                <mat-panel-title class="heading-3">
                  <div class="content-assign content-read">
                    <div class="content-name-head">
                      <h2 class="refiners-title single-ellipsis text-uppercase">
                        {{refiner.refiner}}
                      </h2>
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-content">
                <div class="radio-group">
                  <ng-container *ngFor="let limiter of refiner.filters; let i = index">
                    <p-radioButton name="learning-refiner" class="kz-radio-button radio-fluid" [label]="limiter.title"
                      [value]="limiter.value" [(ngModel)]="refiner.selectedFilter"
                      id="loc_refiners_{{limiter.value}}"
                      (onClick)="activityRefinerChange($event, limiter, i, refiner)" [inputId]="limiter.title"
                      axis360PradioAda isRevers="true"></p-radioButton>
                  </ng-container>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
          </mat-accordion>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="isHiddenRefiner">
      <div class="refine-filter-head">
        <h2 class="refiners-title single-ellipsis text-uppercase">{{component.title}}</h2>
      </div>
      <mat-divider aria-hidden="true" class="kz-divider" *ngIf="isHiddenRefiner"></mat-divider>
      <axis360-third-party-refiner-ac (acOnExecuteAction)="refinersChange($event)" [componentData]="componentData"
        [componentClass]="component.subType === THIRD_PARTY_SUBTYPE.PRESSREADER_FEATURED_REFINER ? '': '' "
        [componentTemplate]="component.jsonTempl" *ngIf="!isLoading && componentData">
      </axis360-third-party-refiner-ac>
    </div>
  </div>
</ng-template>