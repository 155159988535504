import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPInterceptor } from '@shared/http.interceptor';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule],
  exports: [FooterComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true
    },
  ]
})
export class FooterModule { }
