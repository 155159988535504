import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import {
  CURATED_LISTS,
  HOME_AVAILABILITY,
  KZ_HOME_FORMAT_TYPE,
  LIST_CARD_COUNT,
  SHIMMER_COMPONENTS
} from '@shared/constants/app.constants';
import { BookDetail, ListContent } from '@shared/data/config.model';
import { forceRedirect } from '@shared/helper/app.util';
import { MyShelfListService } from '../shared/services/my-shelf-list.service';
import { MyShelfAnalyticsService } from '../shared/services/my-shelf.analytics.service';
import { Subscription, noop } from 'rxjs';
import { SharedService } from '@shared/services/shared.service';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { ThirdPartyAnalyticService } from 'app/third-party/shared/services/third-party-analytic.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { THIRD_PARTY_TIER } from 'app/third-party/shared/constants/third-party.constants';
@Component({
  selector: 'axis360-currently-checked-out',
  templateUrl: './currently-checked-out.component.html',
  styleUrls: ['./currently-checked-out.component.scss']
})
export class CurrentlyCheckedOutComponent implements OnInit, OnDestroy {
  listContent: ListContent = { listName: '' };
  @Input() parentRoute: string;
  @Input() myShelfLoad: boolean;
  @Output() progressiveCCEmit = new EventEmitter();
  forceRedirect = forceRedirect;
  checkTitleActionCTASubscription: Subscription;
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  LIST_CARD_COUNT = LIST_CARD_COUNT;
  isLoading = true;
  isThiredParty = false;
  refreshResumeCTA = false;
  layoutComponents = {};
  isNonThirdPartyDone = false;
  constructor(
    public myShelfListService: MyShelfListService,
    private myShelfAnalyticsService: MyShelfAnalyticsService,
    public sharedService: SharedService,
    public enagageService: EngageBaseService,
    private thirdPartyService: ThirdPartyService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService
  ) { }

  ngOnInit(): void {
    this.thirdPartyAnalyticService.trackScreen('CURRENTLY_CHECKOUT');
    this.listContent = { listName: '', Items: [] };
    this.getCurrentlyCheckedOutItems();
    this.checkTitleActionCTASubscription = this.sharedService.getIsCheckTitleActionCTAList().subscribe((res) => {
      if (res === 'checkout' || res === 'returntitle') {
        this.isLoading = true;
        this.refreshResumeCTA = false;
        this.listContent = { listName: '', Items: undefined };
        this.getCurrentlyCheckedOutItems();
      }
    });
  }

  /**
   * Get currently checkout titles
   */
  async getCurrentlyCheckedOutItems() {
    try {
      const getListContent = (await this.myShelfListService.getListContent(
        CURATED_LISTS.CHECKOUT.text,
        HOME_AVAILABILITY[0].value,
        KZ_HOME_FORMAT_TYPE[0].value,
        1,
        LIST_CARD_COUNT
      )) || { listName: '', Items: [] };
      getListContent.Items = getListContent.Items.slice(0, LIST_CARD_COUNT);

      this.listContent = getListContent?.Items.some((elm) => elm.FormatType == 'VID' || elm.FormatType == 'VBK')
        ? await this.getThiredPartyLayout(getListContent)
        : getListContent;
      /** this.getBookDetails(getListContent); */
      this.isLoading = false;
      this.getActionViewButtonForLibraryList(getListContent);
      this.getMultipleVideoProgressBasedOnItemId(getListContent);
      /** we remove the Action View Button in currently-checked-out page */
      /**
       * for (const listConstent in getListContent.Items) {
       * if (getListContent.Items.hasOwnProperty(listConstent)) {
       *       this.getActionViewButtonForLibraryList(getListContent.Items[listConstent], 'listContent');
       *    }
       *  }
       * this.getMultipleVideoProgressBasedOnItemId(getListContent);
       */
      this.addSeeAllCard();
    } catch (e) {
      this.isLoading = false;
      this.listContent = { listName: '', Items: [] };
      this.isNonThirdPartyDone = true;
      this.progressiveEmit();
    }
  }

  async getThiredPartyLayout(getListContent): Promise<ListContent> {
    try {
      const [layoutData] = await this.thirdPartyService.getTierLayout(THIRD_PARTY_TIER.MYSHEIF, 'VIDEOS');
      if (!layoutData?.layout) {
        this.layoutComponents = null;
        getListContent.Items = getListContent.Items.filter((x) => x.FormatType === 'EBT' || x.FormatType === 'ABT');
        return getListContent;
      }
      this.layoutComponents = layoutData.layout.components[0].jsonTempl;
      return getListContent;
    } catch (error) {
      this.layoutComponents = null;
      getListContent.Items = getListContent.Items.filter((x) => x.FormatType === 'EBT' || x.FormatType === 'ABT');
      return getListContent;
    }
  }

  /**
   * Reading BookProgress
   */
  async getBookDetails(books: ListContent) {
    const bookIds = (books.Items || [])
      .filter((elem) => elem.FormatType !== 'VID' && elem.FormatType !== 'VBK')
      .map(({ ItemId }) => ItemId);
    const bookDetail = { bookIdList: bookIds };
    if (!bookIds.length) {
      const listConstentItems = this.listContent.Items;
      this.listContent.Items = listConstentItems;
      this.isNonThirdPartyDone = true;
      this.progressiveEmit();
      return;
    }
    try {
      const booksDetailsRes = await this.enagageService.getBookProgress(bookDetail);
      this.listContent.Items.forEach((book) => {
        booksDetailsRes.bookProgress.forEach((title) => {
          if (title.bookId === book.ItemId && Math.round(title.progressPercentage) > 0) {
            book.percentageComplete = Math.round(title.progressPercentage);
          }
        });
      });
      this.isNonThirdPartyDone = true;
      this.progressiveEmit();
    } catch (e) {
      const listConstentItems = this.listContent.Items;
      this.listContent.Items = listConstentItems;
      this.isNonThirdPartyDone = true;
      this.progressiveEmit();
      return;
    }
  }

  /**
   * Get MultipleVideoProgressBasedOn ItemId
   * @param [books] to pass the booklist
   * @returns List of Item return with Resume or play CTA
   */
  async getMultipleVideoProgressBasedOnItemId(books: ListContent) {
    const bookIds = (books.Items || []).filter((elem) => elem.FormatType === 'VID' || elem.FormatType === 'VBK').map(({ ISBN }) => ISBN);
    if (!bookIds.length) {
      this.refreshResumeCTA = true;
      const listConstentItems = this.listContent.Items;
      this.listContent.Items = listConstentItems;
      this.progressiveEmit();
      return;
    }
    const bookDetail = { bookIdList: bookIds };
    try {
      const booksDetailsRes = await this.thirdPartyService.getMultipleTitleBookProgress(bookDetail);
      if (booksDetailsRes && booksDetailsRes.length > 0) {
        let itemProgress: any;
        booksDetailsRes.forEach((progressItems) => {
          itemProgress = (this.listContent.Items || [])?.map((itemList) => {
            itemList.displayStatusCss =
              itemList.titleStateInfo && itemList.titleStateInfo.displayStatus !== '' ? 'carousel-card-ribbon-info' : '';
            if (itemList.ISBN === progressItems.itemId && progressItems.status.type === 0 && Math.round(progressItems.timeoffset) > 0) {
              itemList.resumeCTA = 'Resume';
              return itemList;
            }
            return itemList;
          });
        });
        this.listContent.Items = JSON.parse(JSON.stringify(itemProgress));
        this.refreshResumeCTA = true;
        this.progressiveEmit();
      } else {
        this.refreshResumeCTA = true;
        this.listContent.Items =  JSON.parse(JSON.stringify(this.listContent.Items));
        this.progressiveEmit();
        return;
      }
    } catch (error) {
      this.listContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
      this.refreshResumeCTA = true;
      this.progressiveEmit();
      return;
    }
  }

  /**
   * Add see all card if card length is more then 15
   */
  addSeeAllCard() {
    const seeAllCardContent: BookDetail = { itemId: 'seeAll', TotalQuantity: this.listContent.TotalItems };
    this.listContent.TotalItems >= LIST_CARD_COUNT && this.listContent.Items.push(seeAllCardContent);
  }

  /**
   * Redirect to the checkout page
   */
  redirectToCheckoutList() {
    this.myShelfAnalyticsService.trackCurrentlyCheckoutEvent('CLICK_SEE_ALL_CHECKOUT');
    this.forceRedirect('/ng/view/mystuff/checkouts');
  }

  /**
   * get title state info for card
   * @param inputdata
   */
  /** getActionViewButtonForLibraryList = getActionButtons.apply(this); */
  getActionViewButtonForLibraryList(inputdata) {
    try {
      this.myShelfListService.getMyLibraryListActionButtonV2(inputdata).subscribe((actionButtonResponse: any) => {
        this.listContent.Items?.map((elm) => {
          const listData = inputdata.Items?.filter(item => item.ISBN === elm.ISBN);
          if (listData.length > 0) {
            elm.DisplayStatus = actionButtonResponse.find(item => item.isbn === elm.ISBN)?.displayStatus || '';
            elm.HoldPosition = actionButtonResponse.find(item => item.isbn === elm.ISBN)?.holdPosition || '';
            elm.titleStateInfo = { ...actionButtonResponse.find(item => item.isbn === elm.ISBN) };
            if (elm.FormatType === 'VID' || elm.FormatType === 'VBK') {
              const bookStatus = 'Book Status ' + elm.DisplayStatus + ', ';
              const bookDuration = elm.RunTime !== '' ? 'Duration ' + this.replaceTimeStrings(elm.RunTime) + ',' : '';
              const bookAltTitle = `Format ${elm.FormatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${elm.Title}, Author ${elm.Author}`;
              elm.altLabel = `${(elm.DisplayStatus && elm.DisplayStatus !== '') ? bookStatus : ''} ${bookAltTitle}, ${bookDuration} Opens in a new tab`
              elm.displayStatusCss = (elm.titleStateInfo && elm.titleStateInfo.displayStatus !== '') ? 'carousel-card-ribbon-info' : '';
            }
          }
        });
        this.refreshResumeCTA = true;
        this.isNonThirdPartyDone = true;
        this.progressiveEmit();
      });
    } catch (error) {
      const listConstentItems = this.listContent.Items;
      this.listContent.Items = listConstentItems;
      this.refreshResumeCTA = true;
      this.progressiveEmit();
      return;
    }
  };
  replaceTimeStrings = (text: string): string => {
    if(text?.includes('Mins')) {
      return text?.replace(/m(in)?s?/gi, 'minutes');
    }
    return text?.replace(/h(r?s)?/gi, ' hours').replace(/m(in)?s?/gi, ' minutes');
  };
  /**
   * emit the event title action
   */
  titleActionEvent() {
    this.getCurrentlyCheckedOutItems();
    this.sharedService.updatMystuffTabCount(true);
  }

  ngOnDestroy(): void {
    this.checkTitleActionCTASubscription && this.checkTitleActionCTASubscription.unsubscribe();
  }
  progressiveEmit() {
    if (this.refreshResumeCTA && this.isNonThirdPartyDone) {
      this.progressiveCCEmit.emit();
    } else {
      noop();
    }
  }
}
