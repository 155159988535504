import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PROGRAMS_EVENTS_KEY_BY_TAB } from '@readingprograms/shared/constants/programs.anaytics.constants';
import {
  PROGRAMS_TABS,
  PROGRAMS_TABS_LIST,
  PROGRAMS_TAB_ROUTE_MAP,
  PROGRAMS_TAB_ROUTE_NAME
} from '@readingprograms/shared/constants/programs.constants';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { positionFooter } from '@shared/helper/app.util';
@Component({
  selector: 'axis360-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private programsAnalyticsService: ProgramsAnalyticsService,
    private programsService: ProgramsService
  ) {}
  ngOnInit() {
    const tabKey = this.activatedRoute.snapshot.firstChild.routeConfig.path.includes('open') ? 'OPEN' : PROGRAMS_TABS.MY_PROGRAMS;
    this.programsService.selectedProgramsTab = PROGRAMS_TABS_LIST.indexOf(PROGRAMS_TAB_ROUTE_NAME[tabKey]);
  }
  /**
   * To navigate to the route based on the selectedTab on the selected tab change action
   * @param selectedTab Selected tab name
   */
  onSelectedTabChange = (selectedTab: string) => {
    positionFooter(true);
    this.router.navigate([PROGRAMS_TAB_ROUTE_MAP[selectedTab]]);
    this.programsAnalyticsService.trackNotificationsEvent(PROGRAMS_EVENTS_KEY_BY_TAB[selectedTab]);
  };
}
