<mat-sidenav-container class="example-container" autosize (backdropClick)="closeSidenav()" id="matSidenav">
    <mat-sidenav class="kz-mat-drawer-inner-container" #sidenav mode="over" disableClose fixedInViewport="true"
        fixedTopGap="0" fixedBottomGap="0">
        <axis360-offcanvas></axis360-offcanvas>
    </mat-sidenav>
    <mat-sidenav-content #sidenavContent id="matSidenavContent">
        <axis360-header [isLoading]="isLoading"></axis360-header>
        <main id="skip-nav" tabindex="-1" class="ng-view-main-container main-container-content sticky-header">
            <ng-container *ngIf="!isLoading">
                <router-outlet></router-outlet>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <axis360-spinner-loader></axis360-spinner-loader>
            </ng-container>
        </main>
        <axis360-footer></axis360-footer>

    </mat-sidenav-content>
</mat-sidenav-container>
<axis360-overlay-spinner></axis360-overlay-spinner>