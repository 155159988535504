<ng-container>
    <div class="kz-card-number" *ngIf="isBooksInOrder">{{bookDetail.sequence}}</div>
    <axis360-title-card [seeAll] = "seeAll" [titleContent]="bookDetail.isHiddenTitle ? undefined: bookDetail.info" [isDeleteFlag]="isDeleteFlag" [bookProgress]="bookDetail.percentageComplete" [isProgramDetail]="isProgramDetail" [isReadingProgress] = "true" [programDetails]="programDetails"
        [showAction]="bookDetail.isParticipantCanRead && showActionCTA" [isProgramCTAShow]="true" [parentRoute] = "parentRoute" (titleMarkAsReadEventData)="titleMarkAsReadEventData($event)"></axis360-title-card>
    <div class="kz-progress-bar-wrapper"
        *ngIf="bookDetail.isParticipantCanRead && showPercentageTracking && bookDetail.percentageComplete && !bookDetail.isHiddenTitle">
        <div class="kz-progress-bar-container" role="progressbar" aria-hidden="true">
            <div class="kz-progress-bar"
                [ngStyle]="{'width': (bookDetail.percentageComplete >= 0)?(bookDetail.percentageComplete + '%'):'0%'}">
            </div>
        </div>
        <div class="kz-progressbar-percentage-sec"
            [ngStyle]="{'width': (bookDetail.percentageComplete > 0)?(bookDetail.percentageComplete + '%'):'10%'}">
            <span class="kz-progressbar-percentage" role="text" attr.aria-label="Reading Progress {{bookDetail.percentageComplete}} %"><span class="kz-visually-hidden">Reading Progress</span>{{bookDetail.percentageComplete}}%</span>
        </div>
    </div>
</ng-container>