export const PROFILES_ALERTS = {
  CREATION_FAILURE: {
    heading: 'Uh-oh!',
    content: 'Uh-oh! We could not create your Profile. Please check all required fields and try again.',
    submitBtnText: 'OK',
    type: 'ERROR',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  DISABLE_UNIVERSALPIN: {
    heading: 'Other Profiles are using PINs',
    content: 'Disabling other Profile PINs will delete existing PINs and remove the PIN option for other Profiles. Do you want to proceed?',
    submitBtnText: 'Yes',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc',
  },
  OTP_FAILURE: {
    heading: 'Uh-oh!',
    content: '',
    submitBtnText: 'OK',
    type: 'ERROR'
  },
  PROFILE_DELETE: {
    heading: 'Delete Profile',
    content: 'Upon confirmation, all the associated checkouts and holds will be returned and profile will be deleted',
    submitBtnText: 'OK',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc',
  },
  ACCOUNT_DELETE: {
    heading: 'Cancel my Boundless Account',
    content:
      'Upon confirming here, your account will be removed and related data, profiles will be removed, log you out from all the devices using Kidszone. You will not be able to log back in',
    submitBtnText: 'OK',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc'
  },

  WITHOUT_SAVE_ALERT: {
    heading: '',
    content: 'Are you sure you want to leave this page without saving?',
    submitBtnText: 'Leave Page',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc'
  },

  UPDATION_FAILURE: {
    heading: 'Oops...',
    content: 'Sorry, your updates were not saved. Please try again',
    submitBtnText: 'OK',
    type: 'ERROR',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  DELETE_FAILURE: {
    heading: 'Error',
    content:
      'Your profile could not be deleted. Please check all required fields and try again. Please contact support for further assistance or if problem does not resolve',
    submitBtnText: 'OK',
    type: 'ERROR',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  DELETE_ACCOUNT_FAILURE: {
    heading: 'Error',
    content: 'Uh-oh! Your account could not be deleted. Please contact support for further assistance or if problem does not resolve',
    submitBtnText: 'OK',
    type: 'ERROR',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  EMAIL_INFO: {
    heading: 'Why do you need my email address?',
    content:
      'You will receive an email to this address when your hold is ready for check out. If you are under 13 years old, enter the email address of your parent or guardian',
    closeIcon: false,
    submitBtnText: 'OK',
  },
  PROFILE_TYPES: {
    content: 'Please note that Profiles are independent, with separate Preferences, Interests, Content and My Stuff display',
    submitBtnText: 'Yes',
    cancelBtnText: 'Cancel',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  PROFILE_CONFORMATION: {
    heading: 'Yay! Your Profile has been created.',
    content: 'Would you like to create another Profile?',
    submitBtnText: 'Yes',
    cancelBtnText: 'No',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  ENABLE_PIN_ADULT: {
    heading: '',
    content: 'Please add an email to enable your Profile PIN',
    submitBtnText: 'OK'
  },
  ENABLE_PIN_KID: {
    heading: '',
    content: 'The Primary Profile must enter an email address in their profile to enable a Profile PIN',
    submitBtnText: 'OK'
  },
  ENABLE_PIN_KID_TEMPA: {
    heading: '',
    content: 'An email address is required to enable a Profile PIN',
    submitBtnText: 'OK'
  },
  DISABLE_PIN: {
    heading: 'Disable Profile Pin',
    content: 'Are you sure you want to disable your Profile PIN?',
    submitBtnText: 'Yes',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION'
  },
  SETPIN_INFO: {
    content: 'When a Profile PIN is enabled, you will be required to enter your PIN to access and manage your profile',
    submitBtnText: 'OK'
  },
  PROFILE_HINT: {
    heading: 'Why can’t I add more profiles?',
    content: 'We are sorry, but you can only add a maximum of 5 profiles',
    submitBtnText: 'Close',
    type: 'CONFIRMATION',
    ariaDescribedby: 'loc_textalertcontent',
    ariaLabelledby: 'alert-heading'
  },
  SETAUTOMATICCHECKOUT_INFO: {
    content: 'Automatic Checkouts are controlled by the Primary Profile. All profiles will inherit this setting',
    submitBtnText: 'OK'
  },
  ENABLE_EMAIL_NOTIFICATION_INFO: {
    content: 'Notify me via email whenever a Hold becomes available',
    submitBtnText: 'OK'
  },
  ENABLE_EMAIL_NOTIFICATION_ALERT_ADULT_TEEN: {
    content: 'Please add your email to enable email notifications',
    submitBtnText: 'OK'
  },
  ENABLE_EMAIL_NOTIFICATION_ALERT_KID: {
    content: 'Your admin must enter an email address in their Profile for you to enable email notifications ',
    submitBtnText: 'OK'
  },
  ENABLE_UNIVERSAL_PIN_INFO: {
    content: 'Enabling this setting allows each profile to add their own Profile PIN',
    submitBtnText: 'OK'
  },
  CHECKOUT_HISTORY: {
    heading: 'Disable Checkout History',
    content: 'Are you sure you want to Disable checkout history? This will delete your history and cannot be restored',
    submitBtnText: 'Disable',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc'
  },
  DISABLE_INSIGHTSANDBADGES: {
    heading: '',
    content: 'Disabling Goals & Insights also deactivates Reading, Listening and Program progress tracking.',
    submitBtnText: 'Disable',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'loc_textalertcontent'
  },
  ABOUT_PROFILES: {
    heading: 'About Profiles',
    content:
      `Profiles allow you to create personalized, age-range experiences in Boundless. To learn more about using profiles, go to <a class="helpmore" href='https://baker-taylor.my.site.com/boundless/s/article/Profiles-Boundless'
      target="_blank" role="link" >Profiles Help.</a>`,
    submitBtnText: 'Got It',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc'
  }
};
