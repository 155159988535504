import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PROFILE_ENABLED, PROFILE_FEATURES } from '@profile/shared/constants/profiles.constants';
import { API_INFO } from '@shared/constants/api.constant';
import { AppSettings, CurrentLibrary, Features, VendorSubscriptionData } from '@shared/data/config.model';
import { IThirdPartyVendor } from '@shared/data/third-party-model';
import { AUTH_PASSWORD, EMAIL, LIBRARY_CARD_ID, PASSWORD, PIN, USER_NAME } from 'app/core/account/shared/constants/account.constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  currentLibrary: Partial<CurrentLibrary> = {};
  appSettings: AppSettings = {};
  vendorDetails: IThirdPartyVendor[];
  ppcBugetReached = false;
  userDisplayName: string;
  authenticationType: string;
  public showBanner$ = new BehaviorSubject<boolean>(false);
  isShowBanner$ = this.showBanner$.asObservable();
  scrollPostion = 0;
  focusMystuffTab = false;
  public librarySettings$ = new Subject<CurrentLibrary>();
  isPPCLibrary: boolean;
  /**
   * Creates an instance of search service
   */
  constructor(private http: HttpClient) { }

  /**
   * Sets librarySettings
   * @param librarySettings We pass the lib setting data
   */
  public setLibrarySettings(librarySettings: CurrentLibrary) {
    this.librarySettings$.next(librarySettings);
  }

  /**
   * gets librarySettings
   * @returns librarySettings$
   */
  public getLibrarySettings() {
    return this.librarySettings$.asObservable();
  }

  /**
   * Sets setBanner
   * @param show_banner pass the setBanner
   */
  public setBanner(showBanner: boolean) {
    this.showBanner$.next(showBanner);
  }
  /**
   * getBanner
   * @returns showBanner$
   */
  public getBanner(): Observable<any> {
    return this.showBanner$.asObservable();
  }

  /**
   * Setter of current library
   * @param  currentLibrary current lib data
   */
  set librarySettings(currentLibrary: Partial<CurrentLibrary>) {
    this.currentLibrary = currentLibrary;
  }

  /**
   * Setter of app settings
   * @param  appSettings app settings
   */
  set appSettingsInfo(appSettings: AppSettings) {
    this.appSettings = appSettings;
  }

  /**
   * Gets initails library settings values
   * @returns returns library settings
   */
  async getLibrarySetting() {
    try {
      this.currentLibrary = await this.http.get<CurrentLibrary>(API_INFO.getLibrarySettings).toPromise();
    } catch (e) {
      this.currentLibrary = {};
    }
  }

  /**
   * Gets app settings values
   * @returns returns app settings
   */
  async getAppSettings() {
    try {
      this.appSettings = (await this.http.get<{ appSettings: AppSettings }>(API_INFO.getAppSettings).toPromise()).appSettings || {};
    } catch (e) {
      this.appSettings = {};
    }
  }

  /**
   * Determines whether web path enabled is
   * @returns true if webpath is enabled
   */
  isWebPathEnabled = (): boolean => this.appSettings.webPathExpressEnabled === 'true' && this.currentLibrary.enableWebPathExpress;
  /**
   * Determines whether checkout out Carousal enabled is
   * @returns true if checkedOutCarousal is enabled
   */
  isCheckedOutCarousal = (): boolean => this.appSettings.checkedOutCarousal === 'true';
  /**
   * Determines whether press reader enabled is
   * @returns true if pressreader is enabled
   */
  isPressReaderEnabled = (): boolean =>
    this.appSettings.pressReaderEnabled === 'true' && this.currentLibrary.pressReader?.isPressReaderEnabled;
  /**
   * Determines whether program enabled is
   * @returns true if program is enabled
   */

  isProgramEnabled = (type?: string): boolean => {
    const programEnabled = (this.currentLibrary.profileFeatures || {})[type].programsEnabled || false;
    return programEnabled;
  };
  /**
   * Determines whether recommendations enabled is
   * @returns true if recommendations is enabled
   */
  isRecommendationsEnabled = (type?: string): boolean => {
    const recommendationsEnabled = (this.currentLibrary.profileFeatures || {})[type].recommendationEnabled || false;
    return recommendationsEnabled;
  }
  /**
   * Get press reader default lang of config service
   */
  getPressReaderDefaultLang = (): string => this.appSettings.pressReaderDefaultLanguage;
  /**
   * Get press reader default county of config service
   */
  getPressReaderDefaultCounty = (): string => this.appSettings.pressReaderDefaultCountry;
  /**
   * Determines whether PPCOnlyLibrary enabled
   * @returns true if IsPPCOnlyLibrary is enabled
   */
  getIsPPCOnlyLibrary = (profileType?: string): boolean => {
    const offerProfileType = {
      adult: PROFILE_ENABLED.ADULT_ENABLED,
      teen: PROFILE_ENABLED.TEEN_ENABLED,
      kid: PROFILE_ENABLED.KID_ENABLED
    };
    const profileOfferItemsPPC =
      this.currentLibrary.profileOfferItems?.find((ref) => ref.offerName === PROFILE_FEATURES.PAYPERCIRC)?.[
      offerProfileType[profileType]
      ] || false;
    const isPPCOnlyLibrary = this.currentLibrary.isPPCOnlyLibrary && profileOfferItemsPPC ? true : false
    return isPPCOnlyLibrary ? profileOfferItemsPPC : isPPCOnlyLibrary;
  };
  /**
   * Determines whether Collection enabled
   * @returns true if Collection is enabled
   */
  getCollectionEnabled = (): boolean => this.currentLibrary.collectionEnabled;
  /**
   * To return the settings of profile features for given type
   * @param type profile type - 'adult' | 'teen' | 'kid'
   * @returns settings of profile features for given type
   */
  getProfileFeatures = (type: string): Partial<Features> => {
    if (!this.currentLibrary || type === '') {
      return {};
    }
    const offerProfileType = {
      adult: PROFILE_ENABLED.ADULT_ENABLED,
      teen: PROFILE_ENABLED.TEEN_ENABLED,
      kid: PROFILE_ENABLED.KID_ENABLED
    };
    const getVendorDetails = this.currentLibrary.profileFeatures[type].vendors || [];
    const getProfileOfferItems = this.currentLibrary?.profileOfferItems || [];
    getProfileOfferItems.forEach(item => {
      if (item.isThirdParty) {
        var profileOfferItemsList = getVendorDetails.find(ven => ven?.vendorCode === item.vendorCode);
        if (profileOfferItemsList) {
          // Check if item[offerProfileType[type]] is defined
          if (item[offerProfileType[type]] !== undefined) {
            profileOfferItemsList.vendorEnabled = item[offerProfileType[type]];
          }
        }
      };
    });
    getVendorDetails.forEach((vendor) => {
      this.currentLibrary.profileFeatures[type] = {
        ...this.currentLibrary.profileFeatures[type],
        ...{ [vendor.vendorCode]: vendor.vendorEnabled }
      };
    });
    return this.currentLibrary.profileFeatures[type] || {};
  };
  /**
   * To return the settings of profile features for given type
   * @param type profile type - 'adult' | 'teen' | 'kid'
   * @returns settings of profile features for given type NUll
   */
  getProfileTypeFeatures = (type: string): Partial<Features> => (this.currentLibrary.profileFeatures || {})[type];

  /**
   * To return the settings of SubscriptionData for given vendorcode
   * @param vendorCode Vendorcode VIDEOS or VBOOKS
   * @returns settings of SubscriptionData for given type NUll
   */
  getSubscriptionData = (vendorCode?: string): Partial<VendorSubscriptionData> => {
    const subscriptionData = this.currentLibrary?.profileOfferItems
      ?.filter(item => item.isThirdParty)
      .map(item => {
        const vendor = this.vendorDetails.find(v => v.vendor_code === item.vendorCode);
        return {
          collection: item.properties.map(property => property.value).join(',') || '',
          name: vendor ? vendor.vendor_name.replace(/\s+/g, '') : item.vendorCode
        };
      }).find((item) => item.name.toLocaleLowerCase() === vendorCode.toLocaleLowerCase()) || {};
    return subscriptionData || {};
  }
  // (this.currentLibrary.subscriptionData || []).find((item) => item.name.toLocaleLowerCase() === vendorCode) || {};
  /**
   * To return the user display name and authentication type based on the ilsVendorID
   * @returns user display ame and authentication type of library
   */
  getLibraryLoginInfo() {
    if (this.currentLibrary.partnerSiteEnabled) {
      this.userDisplayName = EMAIL;
      this.authenticationType = PASSWORD;
    } else {
      if (this.currentLibrary.authSettings.ilsVendorID !== AUTH_PASSWORD) {
        this.userDisplayName = LIBRARY_CARD_ID;
        this.authenticationType = PIN;
      } else {
        this.userDisplayName = USER_NAME;
        this.authenticationType = PASSWORD;
      }
    }

    return { userDisplayName: this.userDisplayName, authenticationType: this.authenticationType };
  }

  /**
   * Set App Banner MessageFlag
   * @param [close] pass the boolean value
   * @returns boolean
   */
  setAppBannerMessageFlag(close: boolean) {
    return this.http.post<boolean>(API_INFO.setAppBannerMessageFlag, { value: close });
  }
}
