<div class="press-reader-calendar third-party-calendar" cdkTrapFocus>
    <div class="header">
        <div class="header-cal">
            <button id="calenderCloseBtn" cdkFocusInitial aria-label="Select Issue Date close" (click)="closeDialog()">
                <mat-icon aria-hidden="true" class="active calclose-icon">close</mat-icon>
            </button>
            <h2 class="header-label" id="select-issue-heading">Select Issue Date</h2>
            <button aria-label="Calendar Grid view" (click)="onClickDetail(false)"
                (keydown.enter)="onClickDetail(false)" [attr.aria-selected]="!isDetailView" [attr.aria-pressed]="!isDetailView" id="calendor-icon">
                <mat-icon aria-hidden="true" [ngClass]="{'active': !isDetailView}" class="calendar-icon"
                    svgIcon="kz-calender-icon"></mat-icon>
            </button>
            <button aria-label="Calendar List view" class="calender-list" (click)="onClickDetail(true)"
                (keydown.enter)="onClickDetail(true)" [attr.aria-selected]="isDetailView" [attr.aria-pressed]="isDetailView">
                <mat-icon aria-hidden="true" [ngClass]="{'active': isDetailView}" class="list-icon"
                    svgIcon="kz-calenderlist-icon"></mat-icon>
            </button>
        </div>
    </div>
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading" [loaderClass]="'third-party-inline-loader'">
    </axis360-spinner-loader>
    <ng-container *ngIf="!isLoading">
        <div *ngIf="!isDetailView" class="calendar-block">
            <mat-card class="inline-calendar-card">
                <mat-calendar [selected]="selected" [dateClass]="dateClass" [minDate]="minDate"
                    [maxDate]="maxDate" [dateFilter]="isLatestIssueDate" [headerComponent]="thirdPartyHeaderComponent"
                    (issueClickEvent)="onCalendarIssueClick($event)" (selectedChange)="onCalendarIssueClick($event)"
                    [startAt]="selected">
                </mat-calendar>
            </mat-card>
        </div>
        <div *ngIf="isDetailView" class="date-list">
            <ul role="list">
                <ng-container *ngFor="let issue of latestIssues">
                    <ng-container>
                        <li tabindex="0" role="option"
                            attr.aria-selected="{{isIssueCurrent(issue.issueDate) ? true : false}}"
                            attr.aria-label="{{issue.issueDate | date:'d MMM yyyy'}} {{issue.day}}"
                            [ngClass]="{'active': isIssueCurrent(issue.issueDate)}" (click)="onListIssueClick(issue)"
                            (keydown.enter)="onListIssueClick(issue)">
                            {{issue.issueDate | date:'d MMM yyyy'}} <span>
                                {{issue.day}}</span></li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</div>