import { Component, Input,  OnInit } from '@angular/core';
import { IAnnouncement, IAnnouncementResponse } from '../shared/data/announcement.model';
import { MyLibraryAnalyticsService } from '../shared/services/my-library.analytics.service';

@Component({
  selector: 'axis360-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {
  @Input() announcementData: IAnnouncementResponse;
  @Input() showIframe: boolean = true;
  date = Date.now();
  announcement: IAnnouncement;
  constructor(private myLibraryAnalyticsService: MyLibraryAnalyticsService) {}
  /**
   * To be format and bind data on init
   */
  ngOnInit(): void {
    this.announcement = this.announcementData.announcements[0];
    this.getIframeEvent();
  }
  trackIframe = (tagName: string) => {
    tagName.toLowerCase() === 'iframe' && this.myLibraryAnalyticsService.trackMyLibraryAnnouncementEvent('VIEW_VIDEO');
  };
  getIframeEvent() {
    window.addEventListener('blur', () => {
      this.trackIframe(document.activeElement.tagName);
    });
  }
}
