import { Component, OnInit } from '@angular/core';
import { PRESS_READER_SORT_OPTIONS, PUBLICATION_TYPES } from '@pressreader/shared/constants/press-reader.constansts';
import { PublicationCard, Publications } from '@pressreader/shared/data/press-reader-model';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import { getPublicationCard } from '@pressreader/shared/helper/press-reader.util';
import { forkJoin, Observable } from 'rxjs';
import { Facet } from '@search/shared/data/filter-model';
import { SearchService } from '@search/shared/services/search.service';
import { ConfigService } from '@shared/services/config.service';
import { forceRedirect } from '@shared/helper/app.util';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';

@Component({
  selector: 'axis360-newspapers-and-magazines',
  templateUrl: './newspapers-and-magazines.component.html',
  styleUrls: ['./newspapers-and-magazines.component.scss', './newspapers-and-magazines.component.themes.scss']
})
export class NewspapersAndMagazinesComponent implements OnInit {
  publicationId: string;
  publicationInfo: PublicationCard;
  newspapersAndMagazinesCard: PublicationCard[] = [];
  newsPapersCard: PublicationCard[] = [];
  country = '';
  language = '';
  isNewspaperAndMagazinesLoading = true;
  PUBLICATION_TYPES = PUBLICATION_TYPES;
  defaultCountryCode: number;
  defaultLanguageCode: number;
  countries: Facet[] = [];
  constructor(
    private pressReaderService: PressReaderService,
    private searchService: SearchService,
    private configService: ConfigService,
    private pressReaderAnalyticsService: PressReaderAnalyticsService
  ) {}

  ngOnInit(): void {
    this.getLibrarySettings();
  }

  async getLibrarySettings() {
    await Promise.all([this.configService.getAppSettings()]);
    this.searchService.locationInfo = await this.searchService.getlocation(this.configService.currentLibrary.id);
    this.getCountriesLanguages();
  }

  /**
   * To get the featured publications info
   */
  getNewaspaperAndMagazinesPublications() {
    const featuredPublications: Observable<Publications[]> = forkJoin([
      this.pressReaderService.getMagazines(
        0,
        5,
        PRESS_READER_SORT_OPTIONS.RANK.value,
        this.defaultCountryCode?.toString(),
        this.defaultLanguageCode?.toString()
      ),
      this.pressReaderService.getNewsPaper(
        0,
        5,
        PRESS_READER_SORT_OPTIONS.RANK.value,
        this.defaultCountryCode?.toString(),
        this.defaultLanguageCode?.toString()
      )
    ]);
    featuredPublications.subscribe(
      (publicationsRes) => {
        let newspaperAndMagazinesPublicationsCards: PublicationCard[];
        publicationsRes.forEach((publications) => {
          newspaperAndMagazinesPublicationsCards = (publications.items || []).map((publication) =>
            getPublicationCard(publication, this.countries)
          );
          this.newspapersAndMagazinesCard.push(...newspaperAndMagazinesPublicationsCards);
        });
      },
      (e) => {
        console.log(e);
      }
    );
  }
  /**
   * To get initial values for the filters
   */
  getCountriesLanguages() {
    this.searchService.getCountries('').subscribe(
      (contryFilterRes) => {
        this.countries = contryFilterRes.countries;
        const defaultCountry = this.searchService.locationInfo?.countryCode?.toLocaleLowerCase() || this.configService.getPressReaderDefaultCounty();
        const defaultLanguage = this.searchService.locationInfo?.languageCode?.toLocaleLowerCase() || this.configService.getPressReaderDefaultLang();
        const { id: defaultCountryCode } = contryFilterRes.countries?.find((country) => country.value === defaultCountry) || {};
        this.defaultCountryCode = defaultCountryCode;
        this.searchService.getLanguages(defaultCountryCode).subscribe((langFilterRes) => {
          const { id: defaultLanguageCode } = langFilterRes.languages.find((lan) => lan.value === defaultLanguage) || {};
          this.defaultLanguageCode = defaultLanguageCode;
          this.getNewaspaperAndMagazinesPublications();
        });
      },
      () => {
        console.log('get country error');
      }
    );
  }
  /**
   * redirect to the press reader list page
   */
  redirectToListPage() {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CAROUSEL_SEE_ALL', { carouselName: 'Newspapers & Magazines' });
    forceRedirect(`/ng/pressreader`);
  }
}
