import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  NOTIFICATIONS_CATEGORY,
  NOTIFICATIONS_EVENTS,
  NOTIFICATIONS_EVENT_KEY,
  NOTIFICATIONS_SCREENS,
  NOTIFICATIONS_SCREEN_KEY
} from '../constants/notifications.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof NOTIFICATIONS_EVENT_KEY;
@Injectable()
export class NotificationsAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of notifications analytics service.
   */
  constructor() {
    super(NOTIFICATIONS_SCREENS, NOTIFICATIONS_EVENTS);
  }
  /**
   * To track notifications page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof NOTIFICATIONS_SCREEN_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track notifications event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackNotificationsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(NOTIFICATIONS_CATEGORY, action, altObj);
  }
}
