import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'axis360-selected-refiners',
  templateUrl: './selected-refiners.component.html',
  styleUrls: ['./selected-refiners.component.scss']
})
export class SelectedRefinersComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
