export const MY_LIBRARY_CATEGORY = {
  BOOK_OF_MONTH: 'Book Of Month',
  CAROUSEL_NAV: 'Carousel Navigation',
  LEARNING_ACTIVITIES: 'Learning Activities',
  ANNOUNCEMENTS: 'Announcements',
  FILTERS: 'Filters'
};

export const MY_LIBRARY_SCREENS_KEY = {
  LANDING: 'LANDING'
};

export const MY_LIBRARY_SCREENS: { [key: string]: string } = {
  [MY_LIBRARY_SCREENS_KEY.LANDING]: 'My Library'
};

export const MY_LIBRARY_EVENTS_KEY = {
  BOOK_OF_MONTH: 'BOOK_OF_MONTH',
  CLICK_CAROUSEL_RIGHT: 'CLICK_CAROUSEL_RIGHT',
  CLICK_CAROUSEL_LEFT: 'CLICK_CAROUSEL_LEFT',
  VIEW_VIDEO: 'VIEW_VIDEO',
  VIEW_INTEREST_CATEGORIES: 'VIEW_INTEREST_CATEGORIES',
  INTEREST_IMAGE: 'INTEREST_IMAGE',
  OPEN_PROGRAMS: 'OPEN_PROGRAMS',
  VIEW_PROGRAM_DETAILS: 'VIEW_PROGRAM_DETAILS',
  VIEW_PROGRAM_EXPANDED: 'VIEW_PROGRAM_EXPANDED',
  VIEW_PROGRAM_COLLAPSE: 'VIEW_PROGRAM_COLLAPSE',
  FILTERS: 'FILTERS'
};
export const MY_LIBRARY_EVENTS: { [key: string]: string } = {
  [MY_LIBRARY_EVENTS_KEY.BOOK_OF_MONTH]: 'View Book details CTA',
  [MY_LIBRARY_EVENTS_KEY.CLICK_CAROUSEL_RIGHT]: 'Click carousel navigation - right',
  [MY_LIBRARY_EVENTS_KEY.CLICK_CAROUSEL_LEFT]: 'Click carousel navigation - left',
  [MY_LIBRARY_EVENTS_KEY.INTEREST_IMAGE]: 'Interest image CTA ',
  [MY_LIBRARY_EVENTS_KEY.VIEW_INTEREST_CATEGORIES]: 'View Categories',
  [MY_LIBRARY_EVENTS_KEY.VIEW_VIDEO]: 'View Video CTA',
  [MY_LIBRARY_EVENTS_KEY.OPEN_PROGRAMS]: 'View Open programs CTA',
  [MY_LIBRARY_EVENTS_KEY.VIEW_PROGRAM_DETAILS]: 'View Program details CTA ',
  [MY_LIBRARY_EVENTS_KEY.VIEW_PROGRAM_EXPANDED]: 'View Expand ',
  [MY_LIBRARY_EVENTS_KEY.VIEW_PROGRAM_COLLAPSE]: 'View Collapse',   
  [MY_LIBRARY_EVENTS_KEY.FILTERS]: 'View Filter <filterName>'
};
