import { Component, AfterViewInit, OnInit, Inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MyDateAdapter } from '@pressreader/shared/data/date.model';
import { LayoutComponent } from '@shared/data/third-party-model';
import { deepClone, formatIssueDate, getPressReaderDate } from '@shared/helper/app.util';
import { formatDataAPILatestIssues, latestIssuesDates } from '../../helper/third-party-util';
import { ThirdPartyService } from '../../services/third-party.service';
import { ThirdPartyCalenderHeaderComponent } from '../third-party-calender-header/third-party-calender-header.component';
import { THIRD_PARTY_DAYS } from '../../constants/third-party.constants';
@Component({
  selector: 'axis360-third-party-calender',
  templateUrl: './third-party-calender.component.html',
  styleUrls: ['./third-party-calender.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MyDateAdapter }]
})
export class ThirdPartyCalenderComponent implements OnInit, AfterViewInit {
  thirdPartyHeaderComponent = ThirdPartyCalenderHeaderComponent;
  isDetailView = false;
  latestIssues: { key: string; issueDate: Date; apiIssueDate: Date; day: string }[];
  isLoading = true;
  minDate: any;
  maxDate: any;
  selected: Date;
  issueDates = new Set<string>();
  selectIssueComponent: LayoutComponent;
  latestIssuesArr: string[];
  constructor(
    public dialogRef: MatDialogRef<ThirdPartyCalenderComponent>,
    private thirdPartyService: ThirdPartyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public calenderData
  ) {}
  ngOnInit(): void {
    document.getElementById('calendarBack')?.removeAttribute('disabled');
    document.getElementById('calendarNext')?.removeAttribute('disabled');
    const { currentIssue, cid, currentTitleIssue } = this.calenderData;
    this.selectIssueComponent = deepClone(this.thirdPartyService.selectIssueComponent);
    const { fromDate, toDate }: { fromDate?: Date; toDate?: Date } = latestIssuesDates(
      this.selectIssueComponent.dataApi,
      currentTitleIssue && currentTitleIssue !== '' ? currentTitleIssue : currentIssue
    );
    this.maxDate = new Date(toDate).toISOString();
    this.minDate = new Date(fromDate).toISOString();
    this.selectIssueComponent.dataApi.pathParams = { id: cid };
    this.selectIssueComponent.dataApi.search = {
      ...this.selectIssueComponent.dataApi.search,
      ...formatDataAPILatestIssues({ fromDate, toDate })
    };
    this.selected = currentIssue;
    this.getData();
  }

  /**
   * To idendify the given date is current
   * @param issueDate issue date
   * @returns true or false
   */
  isIssueCurrent(issueDate: Date) {
    return getPressReaderDate(new Date(issueDate)) === getPressReaderDate(new Date(this.calenderData.currentIssue));
  }
  getDay = (date: Date | string): string => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[new Date(date).getDay()];
  };

  isLatestIssueDate = (date: Date) => {
    return this.issueDates.has(getPressReaderDate(date));
  };

  dateClass = (cellDate): MatCalendarCellCssClasses => {
    return this.isLatestIssueDate(cellDate) ? 'active-date-class' : '';
  };

  /**
   * Close the slect issue dialog
   */
  closeDialog = () => {
    this.dialogRef.close();
  };

  redirectToTier3(issue: { key: string; issueDate: Date }) {
    const qParams = this.activatedRoute.snapshot.queryParams;
    const { currentTitleIssue } = this.calenderData;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/view/content/details/' + this.calenderData.cid], {
        queryParams: {
          ...qParams,
          vendor_code: this.calenderData.vendor_code,
          ...issue,
          titleIssue_date: currentTitleIssue && currentTitleIssue !== '' ? currentTitleIssue.toISOString() : '',
          type: this.calenderData.type,
          title: this.calenderData.title
        }
      })
    );
  }
  onListIssueClick(issue: { key: string; issueDate: Date; apiIssueDate: Date }) {
    this.dialogRef.close();
    setTimeout(() => {
      this.redirectToTier3({ key: issue.key, issueDate: issue.apiIssueDate });
    }, 0);
  }
  /**
   * To handle the event when issue is click
   * @param issueDate issue date
   */
  onCalendarIssueClick(issueDate: Date) {
    this.dialogRef.close();
    setTimeout(() => {
      const index = this.latestIssuesArr.indexOf(getPressReaderDate(issueDate));
      const issue = this.latestIssues[index];
      this.redirectToTier3({ key: issue.key, issueDate: issue.apiIssueDate });
    }, 0);
  }
  async getData() {
    try {
      const latestIssues = (await this.thirdPartyService.getDataFromDataApi(this.selectIssueComponent.dataApi)) as [];
      if (latestIssues.length > 0) {
        setTimeout(() => {
          document
            .querySelectorAll('.third-party-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-table-header th[scope="col"]')
            .forEach((n) => {
              const days = THIRD_PARTY_DAYS;
              const weekday = days[n.getAttribute('aria-label')];
              if (weekday) {
                n.innerHTML = `<span class="kz-visually-hidden">${weekday}</span> <span aria-hidden="true">${n.getAttribute(
                  'aria-label'
                )}</span>`;
                n?.removeAttribute('aria-label');
              }
            });
          document
            .querySelectorAll(
              '.third-party-calendar .mat-calendar-content .mat-calendar-table .mat-calendar-body .mat-calendar-body-cell .mat-calendar-body-cell-content'
            )
            .forEach((n) => {
              const fullDate = n.getAttribute('aria-label');
              const date = n.innerHTML;
              if (date) {
                n.innerHTML = `<span class="visually-hidden" aria-label>${fullDate}</span><span aria-hidden="true"> ${date}</span>`;
              }
            });
          this.toCheckCalendarNextBack();
        }, 2000);
      }
      this.latestIssues = latestIssues.map((issue) => {
        const { issueDate, key } = issue;
        const issueDateStr = formatIssueDate(issueDate);
        const day = this.getDay(issueDateStr);
        return {
          apiIssueDate: issueDate,
          issueDate: new Date(issueDateStr),
          day,
          key
        };
      });
      this.latestIssuesArr = this.latestIssues.map(({ issueDate }) => getPressReaderDate(issueDate));
      this.issueDates = new Set(this.latestIssuesArr);
      this.isLoading = false;
      this.toCheckCalendarNextBack();
    } catch (e) {
      this.isLoading = false;
    }
  }
  onClickDetail(isDetail: boolean) {
    this.isDetailView = isDetail;
    this.toCheckCalendarNextBack(true);
  }
  toCheckCalendarNextBack(isToggle = false) {
    setTimeout(() => {
      const IssueSelectedDate = new Date(this.selected).toLocaleString('default', { month: 'long' });
      const IssueSelectedYear = new Date(this.selected).getFullYear();
      const IssueSelected = `${IssueSelectedDate} ${IssueSelectedYear}`;
      const IssueMinDate = `${new Date(this.minDate).toLocaleString('default', { month: 'long' })} ${new Date(this.minDate).getFullYear()}`;
      const IssueMaxDate = `${new Date(this.maxDate).toLocaleString('default', { month: 'long' })} ${new Date(this.maxDate).getFullYear()}`;
      if (IssueSelected === IssueMinDate) {
        document.getElementById('calendarBack')?.setAttribute('disabled', 'true');
      }
      if (IssueSelected === IssueMaxDate) {
        document.getElementById('calendarNext')?.setAttribute('disabled', 'true');
      }
    }, isToggle ? 1000 : 0);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      document.getElementById('calenderCloseBtn')?.focus();
    });
  }
}
