export const NOTIFICATIONS_PAGE_SIZE = 20;
export const NOTIFICATIONS_ELEM_ID = 'notifications';
export const NOTIFICATIONS_SCROLL_BUFFER = 200;
export const SENDER_ID_IN_DESC = '<<senderId>>';
export const MC_SELECTED_TAB = 'ENGAGE_MESSAGE_BOOKS';
export const SAD_FACE_EMOJI = '&#128542';
export const MSG_SAD_FACE_EMOJI = 'Bummer, you Broke Your Streak.';

export const MSG_DATA_CATEGORY = {
  REPORTABUSEREADINGCHALLENGE: 'ReportAbuseReadingChallenge',
  READINGCHALLENGE: 'ReadingChallenge',
  READINGPROGRAM: 'ReadingProgram',
  REPORTABUSEREADINGPROGRAM: 'ReportAbuseReadingProgram',
  USER_GOAL: 'UserGoal',
  USER_BADGE: 'UserBadge',
  USER_TITLE: 'UserTitle'
};
export const MENU_OPTS = {
  ALL: 'all',
  NONE: 'none',
  READ: 'read',
  UNREAD: 'unread',
  DELETE: 'delete',
  CANCEL: 'cancel'
};
export const MENU_OPTS_VAL = {
  [MENU_OPTS.ALL]: 'All',
  [MENU_OPTS.NONE]: 'None',
  [MENU_OPTS.READ]: 'Read',
  [MENU_OPTS.UNREAD]: 'Unread',
  [MENU_OPTS.DELETE]: 'Delete'
};
export const MSG_STATUS = {
  READ: 'read',
  UNREAD: 'unread',
  DELETE: 'delete'
};
export const MSG_BUTTON_TEXT = {
  GO_TO_PROGRAM: 'Go to Program',
  GO_TO_THE_PROGRAM: 'Go to the Program',
  VIEW_BADGE: 'Go to Badge',
  VIEW_GOALS: 'Go to My Goals'
};

export const MSG_BUTTON = {
  ['complete']: 'See Other Programs ?',
  ['closed']: 'Go to My Checkouts'
};
