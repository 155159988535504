import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MileStoneProgramBook } from '@shared/data/programs-base.model';
import { Location } from '@angular/common';
import { KzProgramsService } from '../../service/kz-programs.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { HOME, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { displayErrorMessage, focusElement, setElemAttribute } from '@shared/helper/app.util';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@shared/services/common.service';
import { ADD_CONFIRM_ALERT } from '@titledetails/shared/constants/title-details.constants';
import { Title } from '@angular/platform-browser';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'axis360-kz-program-add-own-title',
  templateUrl: './kz-program-add-own-title.component.html',
  styleUrls: ['./kz-program-add-own-title.component.scss']
})
export class KzProgramAddOwnTitleComponent implements OnInit, AfterViewInit {
  MileStoneProgramBook = <MileStoneProgramBook>{};
  addProgramForm: FormGroup;
  sequenceVal: number;
  programId: string;
  breadcrumbs = [];
  setElemAttribute = setElemAttribute;
  displayErrorMessage = displayErrorMessage;
  selectedOption: string;
  options: { id: string; value: string; viewValue: string }[] = [
    { id: 'loc_ebook', value: 'ebook', viewValue: 'eBook' },
    { id: 'loc_audiobook', value: 'audiobook', viewValue: 'eAudio/Audiobook' },
    { id: 'loc_printbook', value: 'printbook', viewValue: 'Print Book' }
  ];
  @ViewChild('profileType') profileType: MatSelect;
  platform: boolean;
  constructor(
    private KzProgramsService: KzProgramsService,
    private formBuilder: FormBuilder,
    public location: Location,
    public toastMessage: SnackBarService,
    public spinnerService: SpinnerService,
    public programAnalyticsService: ProgramsAnalyticsService,
    public activateRoute: ActivatedRoute,
    public commonService: CommonService,
    public titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Add A Title');
    const selectTag = document.getElementById('loc_titleTypeSelect');
    this.platform = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2
    if (this.platform !== null) {
      selectTag.setAttribute('role', 'combobox');
    } else {
      selectTag.setAttribute('role', 'combobox');
    }
    this.programAnalyticsService.trackScreen('ADD_TITLE_SCREEN');
    this.programId = this.activateRoute.snapshot.queryParams.programIds;
    this.sequenceVal = parseInt(this.activateRoute.snapshot.queryParams.sequenceId) + 1;
    const { titleName, authorName, titletype } = this.KzProgramsService.editProgramFormInfo;
    this.addProgramForm = this.formBuilder.group({
      titleName: [titleName, [Validators.required, this.noWhitespaceValidator(), Validators.maxLength(25)]],
      authorName: [authorName, [Validators.maxLength(25)]],
      profileType: [titletype, [Validators.required]]
    });
    this.getBreadCrumbs();
  }
  noWhitespaceValidator(): ValidatorFn  {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const isWhitespace = value.trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.commonService.isAnnouncedPage = false;
      focusElement('loc_headerId');
      if (!this.platform) {
        const cdkElement = document.getElementById('loc_titleTypeSelect').children[0];
        cdkElement.setAttribute('aria-hidden', 'true');
      }
    }, 2000);
  }

  getBreadCrumbs() {
    const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
    this.breadcrumbs = [
      { text: homePage, url: HOME[homePage], isHref: true },
      {
        text: 'My Programs',
        url: '/view/programs'
      },
      { text: this.activateRoute.snapshot.queryParams.programName, url: `/view/programs/${this.programId}` },
      { text: 'Add a Title ', url: '' }
    ];
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }
  addaTitle() {
    this.programAnalyticsService.trackNotificationsEvent('ADD_CTA');
    if (!this.addProgramForm.valid) {
      return;
    }
    const { titleName, authorName, profileType } = this.addProgramForm.controls;
    Object.assign(this.MileStoneProgramBook, {
      MilestoneBooks: {
        externalBooks: [
          {
            bookName: titleName.value,
            author: authorName.value,
            BookType: profileType.value,
            isManual: true,
            sequence: this.sequenceVal
          }
        ]
      }
    });
    this.spinnerService.withObservable(this.KzProgramsService.addProgram(this.programId, this.MileStoneProgramBook)).subscribe((result) => {
      if (result.errors) {
        this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, this.displayErrorMessage(result));
      } else {
        this.openPopup();
      }
    });
  }
  openProgamMatSelect(event: boolean) {
    this.setElemAttribute('.kz-program-add-title-select', 'role', 'listbox');
    const cdkElement = document.getElementById('loc_titleTypeSelect').children[0];
    if (event) {
      document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'true');
      !this.platform && cdkElement.setAttribute('aria-hidden', 'true');
      return;
    }
    !this.platform && cdkElement.setAttribute('aria-hidden', 'false');
    document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'false');
    document.querySelectorAll('#profileType .mat-select-value-text').forEach((n) => {
      n.children[0].setAttribute('aria-live', 'polite');
    });
    //document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'true');
  }
  openPopup() {
    this.commonService.showAlertDialog({
      ...ADD_CONFIRM_ALERT,
      searchBtnFunc: this.backToProgramDetial
    });
  }
  backToProgramDetial = () => {
    this.KzProgramsService.titleId = 'ADD_NEW_TITLE';
    this.location.back();
    this.programAnalyticsService.trackNotificationsEvent('ADD_TITLE');
  };
  onTitleTypeChange() {
    this.addProgramForm.controls.profileType.setValidators([Validators.required]);
    this.addProgramForm.controls.profileType.setValue('');
    this.addProgramForm.controls.profileType.markAsUntouched();
    this.profileType.focus();
  }
}
