import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { ListContent } from '@shared/data/config.model';
import { ConfigService } from '@shared/services/config.service';
import { ListBaseService } from '@shared/services/list-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { DEFAULT_PATH_PARMS } from 'app/header/advanced-search/advanced-search-constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  SearchModel,
  GetTitlesRequest,
  GetSitesRequest,
  SitesInfo,
  ILearningActivityResponse,
  LearningActivity,
  StudySites,
  StudyTitles
} from '../data/search.model';

@Injectable({
  providedIn: 'root'
})
export class KzSearchService extends ListBaseService {
  thiredPartyCategories = new BehaviorSubject<any>([]);
  thiredPartyNoData = new BehaviorSubject(false);
  removeElmId = new Subject();
  updatedSearchText = new Subject();
  searchText = "";
  titleTotalCountList = new Subject<any>();
  setDisableCheckBox = new Subject<any>();
  showTitleRefiner: boolean = false;
  showEbookRefiner: boolean = false;
  showEaudioRefiner: boolean = false;
  showSitesRefiner: boolean = false;
  showLeaningActivityRefiner: boolean = false;
  showThiredPartyRefiner: boolean = false;
  showThiredParty: boolean = false;
  pillRemovedStatus: boolean = false;
  selectedCategory: string = 'All';
  studyTitles: GetTitlesRequest;
  studySites: GetSitesRequest;
  learningActivity: LearningActivity;
  format: string;
  collections: string;
  totalCount: number;
  totalCountThirdparty = 0;
  focusToFormatText = new Subject<any>();
  isPillsEmptyTitleFoucs = false;
  totalCountList: any = {
    All: 0,
    ABT: 0,
    EBT: 0,
    learningActivity: 0,
    webPathExpress: 0
  } as any;
  apiCount: number;
  apiCompletedCount: number;
  getcondiation = new Subject<boolean>();
  disableReset = new Subject<boolean>();
  setPillsOrderList = new BehaviorSubject<string[]>([]);
  scrollPostion = 0;
  selectedOptions = {
    typeValue: 'all',
    collectionValue: 'General',
    searchText: '',
    availability: [],
    format: [],
    attributes: [],
    axisattribute: '',
    vendorCode: '',
    vendorUrl: DEFAULT_PATH_PARMS
  };
  thirdPartyCount = new Subject<number>();
  isTitletoRecommendThirdPartyRemoved = new BehaviorSubject<boolean>(false);
  tierTwoRefinerSearch = new BehaviorSubject<any>(null);
  drupalServiceDownErrorMsg = '';
  /**
   * Creates an instance of programs service.
   * @param http http client
   */
  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) {
    super(http, profileInfoService, configService);
  }

  /**
   * set the ThiredPartyCategories
   * @param [categories]
   */
  public setThiredPartyCategories(categories: any) {
    this.thiredPartyCategories.next(categories);
  }

  /**
   *
   * @returns getThiredPartyCategories
   */
  public getThiredPartyCategories(): Observable<any> {
    return this.thiredPartyCategories.asObservable();
  }

  /**
   * Get search refiners
   */
  getSearchRefiners = (collections: string, term: string, format: string): Observable<SearchModel> => {
    const profileType = this.profileInfoService.currentProfile.profileTypeId;
    const isAxis360Enabled = this.configService.currentLibrary.axis360Enabled;
    const iskidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    let url = API_INFO.getSearchRefiners;

    if (profileType) {
      url += `&profiletype=${this.getProfileId()}&collections=${collections}&term=${term}&format=${format}`;
    } else if (isAxis360Enabled && iskidsZoneEnabled) {
      url += `&term=${term}&format=${format}`;
    } else {
      url += `&profiletype=2&collections=${collections}&term=${term}&format=${format}`;
    }

    return this.http.get<SearchModel>(url);
  };

  getProfile() {
    const profileType = this.configService.currentLibrary.axis360Enabled
      ? '' : '2';
    return this.profileInfoService.currentProfile.profileTypeId
      ? this.getProfileId() : profileType;
  }

  /**
   * Gets getTitleSearchResults from API
   * @param GetTitlesRequest input param as search Request
   * @returns returns ListContent
   */
  getTitleSearchResults(req: GetTitlesRequest): Promise<ListContent> {
    return this.http
      .get<ListContent>(
        API_INFO.getSearchResults
          .replace('<page>', `${req.page}`)
          .replace('<pageSize>', `${req.pageSize}`)
          .replace('<term>', `${encodeURIComponent(req.searchText)}`)
          .replace('<searchby>', `${req.searchby}`)
          .replace('<addeddate>', `${req.recentlyadded}`)
          .replace('<format>', `${req.format}`)
          .replace('<agelevel>', `${encodeURIComponent(req.agelevel)}`)
          .replace('<availability>', `${req.availability}`)
          .replace('<collections>', `${req.collections}`)
          .replace('<releasedate>', `${req.releasedate}`)
          .replace('<language>', `${req.language}`)
          .replace('<axisattribute>', `${req.axisattribute}`)
          .replace('<sortby>', `${req.sortby}`)
          .replace('<profiletype>', `${this.getProfile()}`)
          .replace('<isFromProgramMilestone>', `${req.isFromProgramMilestone}`)
          .replace('<bisacsdesc>', `${req.subject}`)
      )
      .toPromise();
  }
  /**
   * Gets sites results
   * @param req
   * @returns sites results
   */
  getSitesResults(req: GetSitesRequest): Observable<SitesInfo> {
    const apiUrl = API_INFO.getSites
      .replace('<searchtext>', `${encodeURIComponent(req.searchText)}`)
      .replace('<pageindex>', `${req.page}`)
      .replace('<pagesize>', `${req.pageSize}`)
      .replace('<grade>', `${req.gradeMap}`)
      .replace('<domain>', `${req.domainFilter}`)
      .replace('<language>', `${req.language}`)
      .replace('<format>', `${req.formatFilter}`)
      .replace('<sourcetype>', `${req.sourceFilter}`)
      .replace('<subject>', `${req.subjectFilter}`);
    return this.http.get<SitesInfo>(apiUrl);
  }
  /**
   * Gets learning Activity results
   * @param patronName input param as patron user name
   * @param LearningActivity input param as learning Activity refiners and search keywords
   * @returns sites results
   */
  getLearningActivity(learningActivityData: LearningActivity): Promise<ILearningActivityResponse> {
    learningActivityData.search = learningActivityData.searchText;
    learningActivityData.audience = learningActivityData.audiences;
    learningActivityData.activityType = learningActivityData.activityTypes;
    learningActivityData.publisher = learningActivityData.publishers;
    return this.http.post<ILearningActivityResponse>(API_INFO.getLearningActivitySearch, learningActivityData).toPromise();
  }
  updateObjByQueryParams(queryParams: {}) {
    const studyTitles = new StudyTitles();
    Object.entries(studyTitles).forEach(([key]) => (studyTitles[key] = queryParams[key] || ''));
    const studySites = new StudySites();
    Object.entries(studySites).forEach(([key]) => (studySites[key] = queryParams[key] || ''));
    const learningActivity = new LearningActivity();
    Object.entries(learningActivity).forEach(([key, value]) => (learningActivity[key] = queryParams[key] || value));
    this.studyTitles = JSON.parse(JSON.stringify(studyTitles));
    this.studySites = JSON.parse(JSON.stringify(studySites));
    this.learningActivity = JSON.parse(JSON.stringify(learningActivity));
  }
  getResetdata(data: boolean) {
    this.getcondiation.next(data);
  }

  setDisableReset(data: boolean) {
    this.disableReset.next(data);
  }
  addingAlertCount(){
    const alertElement = document.getElementById('loc_title_list_count');
    const searchResultsViewCount = document.getElementById('searchResultsViewCount');
    const searchResultsView = document.getElementById('searchResultsView');
    const searchResultsList = document.getElementById('searchResultsList');
    const searchResultsThirdParty = document.getElementById('searchResultsThirdParty');
    const searchCarouselTitleCount = document.getElementById('searchCarouselTitleCount');
    const resultsCountReourceHub = document.getElementById('resultsCountReourceHub');
    const resultsCountWPE = document.getElementById('resultsCountWPE');
    const searchResultsResouceHub = document.getElementById('searchResultsResouceHub');
    alertElement?.setAttribute('role', 'alert');
    searchResultsViewCount?.setAttribute('role', 'alert');
    searchResultsView?.setAttribute('role','alert');
    searchResultsList?.setAttribute('role','alert');
    searchResultsThirdParty?.setAttribute('role','alert');
    searchCarouselTitleCount?.setAttribute('role', 'alert');
    resultsCountReourceHub?.setAttribute('role', 'alert');
    resultsCountWPE?.setAttribute('role', 'alert');
    searchResultsResouceHub?.setAttribute('role', 'alert');
  }
}
