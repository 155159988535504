import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { LibCompononent } from '@shared/data/library-base.model';
import { Subject } from 'rxjs';

export interface ISubMenuItem {
  isMyStuffMenu?: boolean;
  text?: string;
  url?: string;
  value?: string;
  code?: string;
  isChecked?: boolean;
  parentCode?: string;
}

export interface ISubMenu {
  items: ISubMenuItem[];
  libraryComponents: Component
  message: string,
  statusCode: number
}
export interface Component {
  components: LibCompononent[];
}

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  isHamburgerExpanded: boolean = false;
  sidenavAction = new Subject<string>();
  constructor(private http: HttpClient) { }

  getSideNav(type: string) {
    const urlMap = {
      browseBySubject: API_INFO.getBrowseBySubject,
      featuredList: API_INFO.getLibraryComponents
    };
    return this.http.get(urlMap[type]);
  }
}
