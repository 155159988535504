import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[axis360AnnounceElem]'
})
export class AnnounceElemDirective implements AfterViewInit {
  constructor(private elem: ElementRef, private liveAnnouncer: LiveAnnouncer) {}
  /**
   * To announce the element innertext using live announcer
   */
  ngAfterViewInit(): void {
    this.liveAnnouncer.announce(this.elem.nativeElement.innerText);
  }
}
