<div class="web-resources-container {{webResourcesCarouselClass}}" id="title_{{webResourceCardInfo.mainTitle}}" role="link" tabindex="0"
    (click)="titleCardClick(webResourceCardInfo.redirectUrl)"
    (keydown.enter)="titleCardClick(webResourceCardInfo.redirectUrl)">
    <div class="web-resources-content">
        <div class="web-resources-left">
            <div class="card-image">
                <img #cardImg [ngClass]="{'lazy-image': index > INIT_LOAD_IMGS_COUNT}"
                    [attr.data-src]="webResourceCardInfo.imageSrc"
                    [src]="webResourceCardInfo.imageSrc" alt="" />
            </div>
            <div class="icon-group">
                <div class="book-poster">
                    <mat-icon class="book-poster-icon" svgIcon="kz-play-button"></mat-icon>
                </div>
                <div class="book-poster">
                    <mat-icon class="book-poster-icon" svgIcon="kz-ebook"></mat-icon>
                </div>
            </div>
        </div>
        <div class="web-resources-right">
            <h3 class="title single-ellipsis" axis360EllipsisTitle>
                {{webResourceCardInfo.mainTitle}}
            </h3>
            <p class="secondary-para url single-ellipsis" axis360EllipsisTitle>
                {{webResourceCardInfo.displayURL}}
            </p>
            <p class="secondary-para web-content-para mt-2" axis360EllipsisTitle
                [ngClass]="webResourcesCarouselClass ? 'carousel-card' : 'card-list'" aria-roledescription="Description:">
                <span >{{webResourcesCarouselClass
                    ?webResourceCardInfo?.description?.slice(0,90):webResourceCardInfo?.description?.slice(0,250)}}</span>
            </p>
            <div class="web-content-sec">
                <div class="last-row">
                    <div class="web-content">
                        <label attr.aria-label="Subjects: {{webResourceCardInfo.subjectInfo.subjects[0]}}"
                            class="flex heading">Subjects:
                            <span class="secondary-para single-ellipsis" axis360EllipsisTitle>
                                {{webResourceCardInfo.subjectInfo.subjects[0]}}</span>
                        </label>
                    </div>
                    <div class="web-content">
                        <p attr.aria-label="Languages: {{webResourceCardInfo.subjectInfo.languages}}"
                            class="flex secondary-para heading">Languages:
                            <span class="secondary-para single-ellipsis" axis360EllipsisTitle>
                                {{webResourceCardInfo.subjectInfo.languages}}</span>
                        </p>
                    </div>
                    <div class="web-content">
                        <p attr.aria-label="Interest Level: {{webResourceCardInfo.subjectInfo.interestLevel}}"
                            class="flex secondary-para heading">Interest Level:
                            <span class="secondary-para single-ellipsis" axis360EllipsisTitle>
                                {{webResourceCardInfo.subjectInfo.interestLevel}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>