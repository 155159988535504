<div class="kz-goals-col" *ngIf="insight">
    <div class="kz-goals-widget kz-goals-badge-common" tabindex="0" role="button" [attr.aria-label]="insight.ariaLabel" id="{{insight.id}}">
        <div class="kz-goals-set">
            <div class="kz-goals-item-main  kz-{{insight.styleClass}} " [ngClass]="{'kz-watch-goal': insight.goal}">
                <mat-progress-spinner *ngIf="insight.percentage" mode="determinate" [value]="insight.percentage" 
                [strokeWidth]="10" aria-label="progress bar" class="kz-goals-progress">
                </mat-progress-spinner>
                <span class="kz-goals-icon">
                </span>
            </div>
            <div class="btn-nobg-bluecolor edit-icon-btn" tabindex="-1" title="edit"><span class="sr-only">Edit</span> <mat-icon svgIcon="kz-editgoal-pencil" aria-hidden="true"></mat-icon> </div>
            <div class="kz-goals-info-main">
                <span class="kz-goals-value  kz-{{insight.styleClass}}">
                    {{insight.insightMetrics}}
                </span>
                <span class="kz-info-message">
                    {{insight.insightSubTitle}}
                </span>
            </div>
        </div>
        <span class="kz-goals-name" id="{{insight.insightTitle}}">{{insight.insightTitle}} </span>
    </div>
</div>