import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { VideoDialogComponent } from '@shared/components/video-dialog/video-dialog.component';
import { API_INFO } from '@shared/constants/api.constant';
import { ACTION_BUTTON_TEXT, DEFAULT_SNAKE_BAR_MSG_CHK, POPUP_ACTION_BUTTON, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { ActionClickResponse } from '@shared/data/engage-base.model';
import { appendQueryParams, cdkRemoveVisualHidden, focusElement, forceRedirect, randomNumderGenerator } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { SharedService } from '@shared/services/shared.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { ThirdPartyBaseService } from '@shared/services/third-party-base.service';
import { UserService } from '@shared/services/user.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataApi, IBookProgress, IPastCheckout, IPastCheckoutsDetails, LayoutComponent, ThirdPartyLayouts } from '../../../shared/data/third-party-model';
import {
  THIRD_PARTY_ACTIONS_CTA,
  THIRD_PARTY_AGE,
  THIRD_PARTY_ALERTS,
  THIRD_PARTY_REDIRECT_URL,
  THIRD_PARTY_URLS,
  THIRD_PARTY_VIDEOS_ALERTS
} from '../constants/third-party.constants';
import { IAction, IActionData, IActionHandler } from '../data/third-party-model';
import { ThirdPartyAnalyticService } from './third-party-analytic.service';
import { AccountService } from '@shared/services/account.service';
import { AccountComponent } from 'app/core/account/account.component';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { THIRD_PARTY_VENDOR_CODE_TITLE } from 'app/core/kz-search-results/shared/constant/search.constant';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService extends ThirdPartyBaseService {
  selectIssueComponent: LayoutComponent;
  itemDetailsTitleComponent: LayoutComponent;
  itemDetailsInlineCTAComponent: LayoutComponent;
  itemDetailsInfoComponent: LayoutComponent;
  itemDetailsIconlistComponent: LayoutComponent;
  itemDetailsDescriptionComponent: LayoutComponent;
  itemDetailsDuedateComponent: LayoutComponent;
  itemDetailsMainCTAComponent: LayoutComponent;
  itemDetailsRatingsComponent: LayoutComponent;
  itemDetailsReviewComponent: LayoutComponent;
  itemDetailsProgressbarComponent: LayoutComponent;
  itemDetailsTabLinkTitles: LayoutComponent;
  layoutCollectionId: any;
  searchViewRefiner = false;
  breadBookTitle = new BehaviorSubject<any>(null);
  public setContentCollectionId$ = new Subject<any>();
  public seriesList$ = new BehaviorSubject<any>({ series: '' });
  contentCollectionId$ = this.setContentCollectionId$.asObservable();
  public reloadDataAPI = new BehaviorSubject<any>(null);
  tierTwoRefinerChange = new BehaviorSubject<{} | undefined>(undefined);
  tierTwoRefinerReset = new BehaviorSubject<boolean>(false);
  tierTwoRefinerSearch = new BehaviorSubject<any>(null);
  setLatestIssueData = new BehaviorSubject<any>(null);
  sortByOptionIsSelected = {};
  filterObject = {};
  getRefiner = new Subject<any>();
  updateRef$ = new Subject();
  tempRefiners = {};
  openRefiner = new BehaviorSubject<boolean>(false);
  contentCollectionId: any;
  focusElement = focusElement;
  forceRedirect = forceRedirect;
  randomNumderGenerator = randomNumderGenerator;
  cdkRemoveVisualHidden = cdkRemoveVisualHidden;
  public loginOpened = false;
  isPillsEmptyTitleFoucs = false;
  constructor(
    public http: HttpClient,
    public matDialog: MatDialog,
    protected userService: UserService,
    protected commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    private toastService: SnackBarService,
    private sharedService: SharedService,
    public liveAnnouncer: LiveAnnouncer,
    public notificationsService: NotificationsService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public accountService: AccountService,
    public profileInfoService: ProfileInfoService,
    public titleDetailsService: TitleDetailsService,
    public searchService: KzSearchService
  ) {
    super(http, userService, commonService);
  }
  /**
   * setContentCollectionId
   * @param [contentCollectionId] pass the collectionID
   */
  setContentCollectionId(contentCollectionId: any) {
    this.setContentCollectionId$.next(contentCollectionId);
    this.contentCollectionId$ = contentCollectionId;
    this.contentCollectionId = contentCollectionId;
  }

  /**
   * set reloadDataAPI
   * @param [reloadDataAPIItem] pass the Reload Data
   */
  public setReloadDataAPI(reloadDataAPIItem: any) {
    this.reloadDataAPI.next(reloadDataAPIItem);
  }
  /**
   *
   * @returns reloadDataAPI
   */
  public getReloadDataAPI(): Observable<any> {
    return this.reloadDataAPI.asObservable();
  }

  getTierLayout(tier: string, vendorCode: string, otherParams = {}): Promise<ThirdPartyLayouts> {
    return this.http
      .get<ThirdPartyLayouts>(
        appendQueryParams(API_INFO.getTierLayout.replace('<tier>', tier).replace('<vendorCode>', vendorCode), otherParams)
      )
      .toPromise();
  }

  getTitleBookProgressByItemId(bookId: string): Promise<IBookProgress> {
    return this.http.get<IBookProgress>(API_INFO.getTitleBookProgressByItemId.replace('<itemId>', bookId)).toPromise();
  }
  getMultipleTitleBookProgress(bookId: any): Promise<IBookProgress[]> {
    return this.http.post<IBookProgress[]>(API_INFO.getMultipleTitleBookProgress, bookId).toPromise();
  }

  getThirdPartyCategories(dataApi: DataApi) {
    return this.getDataFromDataApi(dataApi);
  }

  getPastCheckouts(bookDetail: IPastCheckout): Promise<IPastCheckoutsDetails[]> {
    return this.http.post<IPastCheckoutsDetails[]>(API_INFO.getPastCheckouts, bookDetail).toPromise();
  }

  getThirdPartyCategoriesWidgets = (dataApi: DataApi): Observable<any> => this.getDataFromDataCategoryApi(dataApi);

  openVideoDialog(url: string) {
    this.matDialog.open(VideoDialogComponent, {
      panelClass: 'video-modal-container',
      data: { url },
      ariaLabelledBy: 'video-title'
    });
  }
  openUrlInNewTab = (url: string) => {
    window.open(url, '_blank');
  };
  readNowCb = (url: string) => () => {
    this.commonService.showAlertDialog({
      ...THIRD_PARTY_ALERTS.REDIRECT_CONF,
      submitBtnFunc: () => this.openUrlInNewTab(url)
    });
  };
  playCb = (url: string) => () => {
    this.commonService.showAlertDialog({
      ...THIRD_PARTY_VIDEOS_ALERTS.REDIRECT_CONF,
      submitBtnFunc: () => this.openUrlInNewTab(url)
    });
  };
  readNow = (action: IAction) => {
    let { url } = action.data;
    if (!url) {
      return;
    }
    url = THIRD_PARTY_REDIRECT_URL.PRESSRDR.replace('<redirectionUrl>', url);
    if (this.userService.isLoggedIn()) {
      this.openUrlInNewTab(url);
      return;
    }
    this.commonService.doLogin(this.readNowCb(url));
  };
  playNow = (action: IAction) => {
    this.thirdPartyAnalyticService.trackTitleActionsEvent('ACTIONS_PRIMARY_CTA', { primaryCtaName: action.data.type });
    const { ItemId, ISBN, collectionId } = action.data;
    let url;
    if (!ItemId || !ISBN || !collectionId) {
      return;
    }
    url = THIRD_PARTY_REDIRECT_URL.VIDEO_PLAY.replace('<item>', ItemId)
      .replace('<isbn>', ISBN)
      .replace('<contentCollectionId>', collectionId);
    if (this.userService.isLoggedIn()) {
      this.openUrlInNewTab(url);
      return;
    }
    this.commonService.doLogin(this.playCb(url));
  };
  actionCTA = (action: IAction) => {
    const actionDetails = this.getDataForAction(action.data);
    if (!this.userService.isLoggedIn()) {
      this.openLoginModel(actionDetails);

      return;
    }
    setTimeout(() => {
      const ctaId = document.getElementById('loc_cta_id');
      ctaId?.setAttribute('aria-hidden', 'true');
    }, 1000);
    action.data.actiontype === 'checkout'
      ? this.thirdPartyAnalyticService.trackTitleActionsEvent('ACTIONS_PRIMARY_CTA', { primaryCtaName: action.data.actiontype })
      : this.thirdPartyAnalyticService.trackTitleActionsEvent('ACTIONS_SECONDRY_CTA', {
        secondaryCtaName: THIRD_PARTY_ACTIONS_CTA[action.data.actiontype]
      });

    if (action.data.actiontype === 'checkout' || action.data.actiontype === 'addtowishlist' || action.data.actiontype === 'removefromwishlist') {
      this.titleDetailsService.thirdPartyToast.next('thirdPartyToast');
      this.actionHandler(actionDetails);
      return;
    }
    this.confirmPopupByTitleActionHanlder(action.data.actiontype, actionDetails, action.data.title)
  };

  confirmPopupByTitleActionHanlder(actionType?: string, actionDetails?: IActionHandler, title?: string) {
    this.commonService.showAlertDialog({
      content: `Are you sure you want to ${ACTION_BUTTON_TEXT[actionType]} ${title} ?`,
      submitBtnText: 'Ok',
      cancelBtnText: 'Cancel',
      submitBtnFunc: () => this.actionHandler(actionDetails),
      cancelBtnFunc: () => { setTimeout(() => {
        const ctaId = document.getElementById('loc_cta_id');
        ctaId?.removeAttribute('aria-hidden');
        document.getElementById('loc_more_option')?.focus();
      }, 1000); }
    });
  }
  getDataForAction = (action: IActionData) => {
    switch (action.actiontype) {
      case 'checkout':
      case 'renewtitle':
      case 'returntitle':
      case 'removefromcheckouthistory':
      case 'addtowishlist':
      case 'removefromwishlist':
        return {
          actiontype: action.actiontype,
          itemId: action.ItemId,
          reservationId: '',
          formatType: action.formatType,
          title: action.title,
          isbn: action.ISBN,
          collectionType: 'THIRDPARTY',
          contentCollectionId: action.collectionId,
          vendorId: action.vendorCode,
          pageName: action.pageName || ''
        };
    }
  };
  /**
   * we are pass the Actions CTA for Title in Videos or Vbooks
   * @param [actionHandler] pass the Action Handler Object
   */
  actionHandler(actionHandler: IActionHandler) {
    this.spinnerService.withObservable(this.engageService.bookActionClick(actionHandler), undefined, false).subscribe(
      async (response: ActionClickResponse) => {
        this.getMyStuffData();
        this.spinnerService.isOverlayLoading = false;
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        if (response.secondaryMessage === 'Already added by another profile.') {
          this.openAddWishListCTAModel(response.primaryStatusMessage, actionHandler);
          return;
        }
        const reloadDataAPI = response.status ?
          {
            actiontype: actionHandler?.actiontype,
            ItemId: actionHandler?.itemId,
            formatType: actionHandler?.formatType,
            pageName: actionHandler.pageName
          } : false;
        this.setReloadDataAPI(reloadDataAPI);
        this.sharedService.refinerSubject.next('checkCount');
        if (actionHandler?.actiontype === 'checkout' || actionHandler?.actiontype === 'returntitle') {
          this.notificationsService.updateMessagesCount();
        }
        const isShowSnackBarError = response.primaryStatusMessage.includes('To borrow this title, return a checked out item.') ?
          SNAKE_BAR_MODE.ERROR_UH_OH : SNAKE_BAR_MODE.ERROR;
        this.toastService.showSnackBar(
          response.status ? SNAKE_BAR_MODE.INFO : isShowSnackBarError,
          this.getActionHandlerMessage(response)
        );
        await this.liveAnnouncer.announce(
          document.getElementById('toast-heading')?.innerHTML + ' ' + document.getElementById('toast-description')?.innerHTML,
          'assertive'
        );
        if (actionHandler?.actiontype === 'returntitle') {
          this.titleDetailsService.thirdPartyToast.next('thirdPartyToast');
          setTimeout(() => {
            this.focusElement('Checkoutbtn');
          }, 2000);
        }
        if (actionHandler?.actiontype === 'renewtitle') {
          this.titleDetailsService.thirdPartyToast.next('thirdPartyToast');
          setTimeout(() => {
            this.focusElement('Playbtn');
          }, 2000);
        }
      },
      () => {
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        this.setReloadDataAPI(false);
        this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG_CHK);
      }
    );
  }

  openAddWishListCTAModel(primaryStatusMessage?: string, data?: any) {
    this.commonService.showAlertDialog({
      heading: POPUP_ACTION_BUTTON[data.actiontype],
      panelClass: 'my-checkout-hold-dialog',
      content: primaryStatusMessage,
      closeIcon: true,
      buttonText: 'OK',
      type: 'INFO',
      submitBtnFunc: () => {
        return;
      }
    });
  }

  getActionHandlerMessage(response: ActionClickResponse) {
    const isStatus = response.primaryStatusMessage?.includes('To borrow this title, return a checked out item.')
      ? 'Failure Uh Oh'
      : 'Failure';
    const responseStatus = response.status
      ? 'Success'
      : isStatus;
    if (responseStatus === 'Failure' || responseStatus === 'Failure Uh Oh') {
      this.liveAnnouncer.announce(responseStatus + ' ' + response.primaryStatusMessage, 'assertive')
    };
    if (response.primaryStatusMessage) {
      return response.primaryStatusMessage;
    }
    if (response.secondaryMessage) {
      return response.secondaryMessage;
    }
    if (response.bottomMessage) {
      return response.bottomMessage;
    }
  }
  /**
   * To redirect To Search result Page in Tier2 Page
   * @param [action] to pass the select data in Author or Series
   * @returns Navigate to search page based on SearchType
   */
  redirectToTier2 = (action: IAction) => {
    const { collectionId = '' } = this.activatedRoute.snapshot.queryParams;
    const { title, vendorCode, subType, searchType, searchTerm } = action.data as any || {};
    this.searchService.updatedSearchText.next(searchTerm);
    this.router.navigate(['/view/search'], {
      queryParams: {
        page: 1,
        pageSize: 10,
        searchText: searchTerm,
        searchby: searchType,
        title,
        vendor_code: vendorCode,
        subType,
        searchType,
        searchTerm,
        q: searchTerm,
        tp: '',
        has: '',
        collectionId,
        addeddate: '',
        format: 'All',
        agelevel: '',
        availability: '',
        collections: 'General and Always Available',
        language: '',
        ereadalong: false,
        sortby: ''
      }
    });
  }

  redirectToBrowseTier2 = (action: IAction) => {
    const { searchTerm } = action.data as any || {};
    this.router.navigate(['/view/title'], {
      queryParams: {
        subject: encodeURIComponent(searchTerm),
        isTitle: true
      }
    });
  }

  /**
   *
   * @param [action] To get action value in Tier3 page
   * @param [vendorcode] we are get the VendorCode BasedOn ThirdParty
   * @returns routing URL for Tier3
   */
  redirectToTier3 = (action: IAction, vendorcode?: string, parentRoute?: string) => {
    this.cdkRemoveVisualHidden();
    this.thirdPartyAnalyticService.trackTitleCarouselEvent('ACTIONS_TITLE_COVER_CTA', { title: action.data.title });
    /* tslint:disable */
    const {
      ItemId,
      ISBN,
      title,
      vendorCode,
      series,
      collectionId
    }: {
      ItemId: string; ISBN: string; title: string; vendorCode: string; series: string; collectionId: string
      author: string; topics: string
    } = (action.data as any) || {};
    const { has, latestIssue = { key: '', issueDate: '' } }: { has: string; latestIssue?: { key: string; issueDate: string } } =
      action.data as any;
    const { key = '', issueDate = '' } = latestIssue || {};
    const TIER3_URL = parentRoute ? `view/${parentRoute}/details` : THIRD_PARTY_URLS.TIER3
    this.commonService.titleBreadCrumb = false;
    if (ItemId) {
      const { cardTitle: bookTitle = '' } = this.activatedRoute.snapshot.queryParams;
      const titleAnnounce = bookTitle || THIRD_PARTY_VENDOR_CODE_TITLE[vendorCode];
      this.router.navigate([`${decodeURIComponent(TIER3_URL)}/${ItemId}`], {
        queryParams: { ISBN, vendor_code: vendorCode, title, series, collectionId, ref: this.randomNumderGenerator(), titleAnnounce }
      });
    } else {
      const { vendor_code: code } = this.activatedRoute.snapshot.queryParams;
      this.router.navigate([`${decodeURIComponent(TIER3_URL)}/${has}`], {
        queryParams: { vendor_code: vendorcode || code, key, issueDate, title, ref: this.randomNumderGenerator() }
      });
    }
  };
  cardAction = (action: IAction, vendorCode?: string, params?: string) => {
    const actionMap = {
      search: this.redirectToTier2,
      download: this.readNow,
      title: this.redirectToTier3,
      article: this.readNow,
      play: this.playNow,
      actions: this.actionCTA,
      subject: this.redirectToBrowseTier2
    };
    actionMap[action.data.type](action, vendorCode, params);
  };

  openLoginModel(action?: IActionHandler) {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          this.forceRedirect(response.redirectUrl);
          return;
        }
        if (this.loginOpened) {
          return;
        }
        this.loginOpened = !this.loginOpened;
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true
            }
          })
          .afterClosed()
          .subscribe((res: boolean) => {
            this.loginOpened = false;
            if (res) {
              this.actionHandler(action)
            }
          });
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }
  /**
 * Get MyShelfData list by calling API on init
 */
  getMyStuffData() {
    if (!this.userService.isLoggedIn()) {
      return;
    }
    this.sharedService.getMyShelfNavigation().subscribe((responce) => {
      this.profileInfoService.getMyStuffCounts(responce);
    });
  }

  getAgeRange = (data: any,): any => {
    const rangeData: any = {};
    const ages = data?.ageRange;
    rangeData[THIRD_PARTY_AGE.ORIGINAL_AGE_RANGE] = data.audience;
    if (ages?.length > 1) {
      rangeData[THIRD_PARTY_AGE.AGE_RANGE] = ages ?? "";
      const age = ages.includes("to") ? ages.split(" to ") : ages?.split("-");
      const startAge = parseInt(age[0]);
      const endAge = parseInt(age[1]);
      if (endAge <= 11) {
        rangeData[THIRD_PARTY_AGE.AGE_TITLE] = THIRD_PARTY_AGE.KID;
        rangeData[THIRD_PARTY_AGE.AGE_NUMBER] = `${startAge}+`;
      } else if (endAge <= 18) {
        rangeData[THIRD_PARTY_AGE.AGE_TITLE] = THIRD_PARTY_AGE.TEEN;
        rangeData[THIRD_PARTY_AGE.AGE_NUMBER] = `${startAge}+`;
      } else if (endAge > 18 && endAge <= 100) {
        rangeData[THIRD_PARTY_AGE.AGE_TITLE] = THIRD_PARTY_AGE.GEN_ADULT;
        rangeData[THIRD_PARTY_AGE.AGE_NUMBER] = THIRD_PARTY_AGE.EIGHTEEN_PLUS;
        rangeData[THIRD_PARTY_AGE.AGE_RANGE] = THIRD_PARTY_AGE.EIGHTEEN_PLUS;
      }
    }
    if (["General Adult", "Professional"].includes(data?.audience)) {
      rangeData[THIRD_PARTY_AGE.AGE_TITLE] = THIRD_PARTY_AGE.GEN_ADULT;
      rangeData[THIRD_PARTY_AGE.AGE_NUMBER] = THIRD_PARTY_AGE.EIGHTEEN_PLUS;
      rangeData[THIRD_PARTY_AGE.AGE_RANGE] = THIRD_PARTY_AGE.EIGHTEEN_PLUS;
    }
    return rangeData || null;
  };
 


}
