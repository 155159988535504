<div class="kz-learnings-col" tabindex="0" attr.aria-label="{{category.category}}"
    (keydown.enter)="trackByViewInterest(category.category)" (click)="trackByViewInterest(category.category)" role="link">
    <div class="learning-card kz-goals-learning-common" aria-hidden="true">
        <div class="learning-award-img">
            <img class="learning-img" [src]="category.thumbnailImage" alt="">
        </div>
    </div>
    <div class="learning-title" aria-hidden="true">
        <p class="learning-text" title=""> {{category.category}}</p>
    </div>
</div>