declare const window: any;
/**
 * To check login and handle the click event of title card
 * @param event click event value
 */
export const checkLoginAndHandleAction = (event: any) => {
  window.ActionHandler && window.ActionHandler(event);
};
/**
 * To get results count
 * @param cardInfo card info
 * @param totalCount total count
 * @param searchTerm search term
 * @returns results text
 */
export const getResultsCount = (cardInfo: number, totalCount: number, searchTerm: string): string =>
  cardInfo + ' of ' + totalCount + ' Results : ' + searchTerm;
/**
 * To update the search url with term
 * @param term search term
 */
export const updateSearchUrl = (term = ''): void => {
  window.location.href = window.location.pathname + `?term=${encodeURIComponent(term)}`;
  return;
};
