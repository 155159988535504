import { Component, OnDestroy, OnInit } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Title } from '@angular/platform-browser';
import { IProfileInfo, ProfileInfoService } from '@shared/services/profile-info.service';
import { CurrentLibrary } from '@shared/data/config.model';
import { ConfigService } from '@shared/services/config.service';
import { MyLibraryList } from '@shared/data/list-base.model';
import { MyShelfListService } from './../shared/services/my-shelf-list.service';
import { DEFAULT_SUCCESS_MESSAGE, NON_FEATURE_LISTIDS } from '@shared/constants/app.constants';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement, getDaysDiff, strToBool } from '@shared/helper/app.util';
import { MyStuffsDetail } from '@shared/data/shared.model';
import { UserService } from '@shared/services/user.service';
import { noop } from 'rxjs';
import { UserInterest } from '@home/shared/data/home.model';
import { HomeService } from '@home/shared/services/home.service';
import { MatDialog } from '@angular/material/dialog';
import { KzInterestSurveyPopupComponent } from 'app/kz-interest-survey/kz-interest-survey-popup/kz-interest-survey-popup.component';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-my-shelf',
  templateUrl: './my-shelf.component.html',
  styleUrls: ['./my-shelf.component.scss']
})
export class MyShelfComponent implements OnInit, OnDestroy {
  currentProfile: IProfileInfo = {};
  currentLibrary: Partial<CurrentLibrary>;
  goalsInsightsTrackingEnabled = false;
  becauseYouCheckedOut = {
    title: 'Based on Your Checkouts',
    book: 'Book Title',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  };
  basedOnYourInterests = { title: 'BASED ON YOUR INTERESTS', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.' };
  mystuffsdetail: MyStuffsDetail[];
  featuredList: MyLibraryList;
  parentRoute: string;
  isBrowserBack:boolean = false;
  isRecommendationsLoaded = false;
  isCurrentlyCheckoutLoaded = false;
  isPillsLoaded = false;
  isGoalsBadgesLoaded = false;
  isCheckedOutCarousal = false;
  constructor(
    private liveAnnouncer: LiveAnnouncer,
    public profileInfoService: ProfileInfoService,
    private configService: ConfigService,
    private myShelfListService: MyShelfListService,
    public userService: UserService,
    private homeService: HomeService,
    private titleService: Title,
    private matDialog: MatDialog,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.isCheckedOutCarousal = this.configService.isCheckedOutCarousal();
    this.titleService.setTitle('My Shelf');
    this.parentRoute = 'myshelf';
    this.currentProfile = this.profileInfoService.currentProfile;
    this.currentLibrary = this.configService.currentLibrary;
    this.goalsInsightsTrackingEnabled = this.currentProfile.insightsBadgesEnabled && strToBool(this.currentProfile.enableTracking);
    this.isRecommendationsLoaded = !this.currentProfile.recommendationEnabled || !this.userService.isLoggedIn();
    this.isGoalsBadgesLoaded = !this.goalsInsightsTrackingEnabled;
    this.currentProfile.defaultLandingPageID === '1' &&
      this.configService.isRecommendationsEnabled(this.profileInfoService.currentProfile.profileType.toLowerCase()) &&
      this.currentProfile.recommendationEnabled && !this.profileInfoService.isEditProfile &&
      this.getInterestSurvey();
    this.profileInfoService.isEditProfile = false;
    this.getFeaturedList();
    this.BrowserBack();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
  }
  async getFeaturedList() {
    try {
      const lists = await this.myShelfListService.getMyLibraryList();
      const curatedLists = lists.filter((list) => !NON_FEATURE_LISTIDS.includes(list.ListID));
      this.featuredList = curatedLists.splice(0, 1)[0];
      setTimeout(() => {
        window.scrollTo(0, this.configService.scrollPostion);
      }, 0);
    } catch (e) { }
  }
  /**
   * To get interest survey data from API and display popup
   */
  getInterestSurvey() {
    const queryParams = `?currentProfileID=${this.currentProfile.profileId}&currentprofileType=${
      this.currentProfile.profileType === 'Adult' ? 'teen' : this.currentProfile.profileType.toLowerCase()
    }&currentProfileIsPrimary=${this.currentProfile.isPrimary}`;
    this.homeService.getKzInterests(queryParams).subscribe((interestRes) => {
      if (interestRes.statusCode === 200 && interestRes.message === DEFAULT_SUCCESS_MESSAGE) {
        this.showInterestSurvey(interestRes.userInterests) && this.openSurvey(interestRes.userInterests);
      }
    }, noop);
  }
  /**
   * To decide interest survey popup display
   * @param interestRes API response
   * @returns true if can show the popup
   */
  showInterestSurvey(interestRes: UserInterest): boolean {
    const { interests, modifiedDate, audience = [] } = interestRes;
    const selectedAlready = interests.some((interest) => interest.selected) || audience.length > 0;
    return !selectedAlready && (!modifiedDate || getDaysDiff(new Date(modifiedDate), new Date()) > 30);
  }
  /**
   * To opens survey popup
   * @param interestRes API response
   */
  openSurvey(interestRes: UserInterest) {
    const interestSurveyPopup = this.matDialog.open(KzInterestSurveyPopupComponent, {
      panelClass: 'kz-interest-popup-modal',
      autoFocus: true,
      data: {
        ...interestRes,
        profileType: !this.configService.currentLibrary.axis360Enabled && this.currentProfile.profileType === 'Adult' ? 'teen' : this.currentProfile.profileType.toLowerCase() },
      ariaLabelledBy: 'loc_txtSetyourReadingInterest'
    });
    interestSurveyPopup.afterClosed().subscribe(() => {
      this.titleService.setTitle('My Shelf');
      this.liveAnnouncer.announce('My Shelf Page', 'assertive');
    });
  }
  BrowserBack() {
    window.addEventListener(
      'popstate',
      (event) => {
        if (event.type === 'popstate') {
          this.isBrowserBack = true;
        }
      },
      false
    );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      focusElement('loc_headerId');
    }, 100);
  }
  ngOnDestroy(): void {
    this.configService.scrollPostion = window.scrollY;
    if(!this.isBrowserBack){
      sessionStorage.setItem('home','My Shelf');
    }
    cdkRemoveVisualHidden();
  }
  progressivePillsEmit()
  {
    this.isPillsLoaded = true;
  }
  progressiveGIBEmit()
  {
    this.isGoalsBadgesLoaded = true;
  }
  progressiveCCEmit()
  {
    this.isCurrentlyCheckoutLoaded = true;
  }
  progressiveRECEmit()
  {
    this.isRecommendationsLoaded = true;
  }
}
