import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { API_INFO } from '@shared/constants/api.constant';
import { MileStoneProgramBook, EditProgramFormInfo, ProgramsAPIRes, DeleteMilestoneModel } from '@shared/data/programs-base.model';
@Injectable({
  providedIn: 'root'
})
export class KzProgramsService extends ProgramsService {
  navigationProgram = new BehaviorSubject<boolean>(false);
  backgroundImageSubject = new BehaviorSubject<string[]>([]);
  editProgramFormInfo: EditProgramFormInfo = {};
  removeEditProgramNotification = new Subject<boolean>();
  programId = new BehaviorSubject<string>("");
  programType = new BehaviorSubject<string>("");
  focusElementHeaderId = new BehaviorSubject<boolean>(false);
  tempSequenceId: number = 0;
  sequenceId = new BehaviorSubject<number>(0);
  numberOfBooks = new BehaviorSubject<number>(0);
  updateProgramAfterAddCTA = new Subject<boolean>();
  titleId: string;
  isDelete = new Subject<string>();
  kzProgramType: string = "";
  thirdPartyDialog = new BehaviorSubject<string>("");
  addProgram(programId: string, programmodal: MileStoneProgramBook): Observable<ProgramsAPIRes> {
    return this.http.post<ProgramsAPIRes>(API_INFO.addTitleListToProgram.replace('<programId>', programId), programmodal);
  }

  addBooks(programId: string, programmodal: MileStoneProgramBook): Observable<ProgramsAPIRes> {
    return this.http.post<ProgramsAPIRes>(API_INFO.addBookToProgram.replace('<programId>', programId), programmodal);
  }
  /**
 * Setter for edit profile form on selecting avatars in /avatars
 */
  set editprogramForm(editprogramFormInfo: EditProgramFormInfo) {
    this.editProgramFormInfo = editprogramFormInfo;
  }
  /**
   * Getter of edit profile form
   */
  get editprogramForm(): EditProgramFormInfo {
    return this.editProgramFormInfo;
  }
  public setIsDelete(BookDetail: any) {
    this.isDelete.next(BookDetail);
  }

  public getIsDelete(): Observable<any> {
    return this.isDelete.asObservable();
  }

  deleteMilestoneBook(programId: string, programmodal: DeleteMilestoneModel): Observable<ProgramsAPIRes> {
    return this.http.put<ProgramsAPIRes>(API_INFO.UpdateTitleListToProgram.replace('<programId>', programId), programmodal);
  }
}
