<div class="kz-drawer-notifications-container kz-main">
    <div class="loading " *ngIf="overlayLoading">
        <div id="floatingBarsG" class="kz-overlay-spinner">
            <div class="blockG" id="rotateG_01"></div>
            <div class="blockG" id="rotateG_02"></div>
            <div class="blockG" id="rotateG_03"></div>
            <div class="blockG" id="rotateG_04"></div>
            <div class="blockG" id="rotateG_05"></div>
            <div class="blockG" id="rotateG_06"></div>
            <div class="blockG" id="rotateG_07"></div>
            <div class="blockG" id="rotateG_08"></div>
        </div>
    </div>
    <div class="notifications-head">
        <h2 class="notifications-title" id="kz-notification-title">Notifications</h2>
    </div>
    <button id="msg-close-btn" class="close-icon btn-nobg-bluecolor" aria-label="close" (click)="closeDialog()"
        (keydown.enter)="closeDialog()" cdkFocusInitial>
        <mat-icon svgIcon="kz-close" aria-hidden="true">close</mat-icon>
    </button>
    <div class="notifications-menu-head">
        <div class="checkbox-filter-menu kz-checkbox-sec">
            <mat-checkbox axis360MatCheckboxAda class="btn filter-checkbox-list" id="msgReadAll"
                (change)="selectAllCheckBox($event.checked)" [(ngModel)]="isAllChecked"
                (keydown.enter)="selectAllCheckBox(!isAllChecked)"></mat-checkbox>
            <button class="btn msg-all-btn btn-nobg-bluecolor d-flex" mat-icon-button aria-haspopup="menu"
                id="loc_notifications_menu" aria-label="Select menu" mat-icon-button axis360MatMenuFocus
                [matMenuTriggerFor]="selectMenuAction" (click)="openMenu($event)" (menuOpened)="menuStateChange(true)"
                (menuClosed)="menuStateChange(false)">
                <span class="selected-msg-status" *ngIf="selectedMsgStatus">{{selectedMsgStatus}}</span>
                <mat-icon svgIcon="kz-filter-down-arrow" aria-hidden="true">filter-down-arrow</mat-icon>
            </button>
            <mat-menu #selectMenuAction="matMenu" class="checkbox-filter-menu-item" yPosition="below" xPosition="after">
                <button mat-menu-item (click)="doMsgCenterAction(option.value)" *ngFor="let option of menuOptions"
                    [attr.aria-label]="option.viewValue" [attr.aria-describedby]="option.viewValue">
                    <span>{{option.viewValue}}</span>
                    <span class="visually-hidden" [id]="option.viewValue">{{option.ariaDescription}}</span>
                </button>
            </mat-menu>
        </div>
        <div class="sub-menu-item" *ngIf="messageIdList.length > 0">
            <button class="btn btn-nobg-bluecolor" mat-icon-button id="msg-delete-all" aria-label="Delete"
                matTooltip="Delete" matTooltipClass="kz-card-tooltip" aria-describedby="info"
                (click)="openDeletePopup(messageIdList.length)" (keydown.enter)="openDeletePopup(messageIdList.length)">
                <mat-icon svgIcon="kz-trash" aria-hidden="true">trash</mat-icon>
                <p class="visually-hidden" id="info">Selected notifications will be deleted</p>
            </button>
            <button class="btn btn-nobg-bluecolor" mat-icon-button id="moreoptionmenu" aria-haspopup="true"
                matTooltip="More menu" matTooltipClass="kz-card-tooltip" aria-label="More menu" axis360MatMenuFocus
                [matMenuTriggerFor]="menuAction" (click)="openMoreMenu()" (menuOpened)="menuStateChange(true)"
                (menuClosed)="menuStateChange(false)">
                <mat-icon svgIcon="kz-meatballs-menu" aria-hidden="true">meatballs-menu</mat-icon>
            </button>
            <mat-menu class="checkbox-filter-menu-item" #menuAction="matMenu" yPosition="below" xPosition="after">
                <button mat-menu-item (click)="doActionsOnMsgs(MSG_STATUS.READ)" aria-label="Mark as Read"
                    aria-describedby="mark-as-read">
                    <span>Mark as Read</span><span class="visually-hidden" id="mark-as-read">Selected messages
                        will mark as read</span>
                </button>
                <button mat-menu-item (click)="doActionsOnMsgs(MSG_STATUS.UNREAD)" aria-label="Mark as Unread"
                    aria-describedby="mark-as-unread">
                    <span>Mark as Unread</span><span class="visually-hidden" id="mark-as-unread">Selected
                        messages will mark as unread</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div *ngIf="userNotification?.length > 0">
        <ng-container *ngFor="let notification of userNotification; let i = index">
            <a [href]="notification.url" class="notification-message primary-para double-ellipsis"
                axis360EllipsisTitle>{{notification.message}}</a>
        </ng-container>
    </div>

    <mat-accordion class="kz-checkbox-sec" *ngIf="messages.length > 0">
        <ng-container *ngFor="let message of messages; let i = index">
            <mat-checkbox axis360MatCheckboxAda [notificationId]="'kz-notifications-title-' + i"
                class="btn filter-checkbox-list" tabindex="0" #checkbox
                [attr.aria-checked]="isMsgChecked(message.messageId)" [checked]="isMsgChecked(message.messageId)"
                (change)="checkedMessage($event.checked, message.messageId)"
                (keydown.enter)="checkedMessage(isMsgChecked(message.messageId), message.messageId)"></mat-checkbox>
            <mat-expansion-panel class="notification-expansion-panel" (opened)="openDetails(message)"
                (closed)="closeDetails(message)">
                <mat-expansion-panel-header class="notification-panel-header" axis360RemoveTabIndex
                    [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                    <mat-panel-title class="heading-3" tabindex="0">
                        <div class="content-assign" [ngClass]="{'content-read': message.status == MSG_STATUS.READ}">
                            <div class="content-name-head">
                                <h2 class="notification-title" [attr.id]="'kz-notifications-title-' + i">
                                    {{message.title}}</h2>
                                <span class="time">{{message.dateTimeStr}}</span>
                            </div>
                            <p class="notification-description double-ellipsis" axis360EllipsisTitle
                                [innerHTML]="(message.title === showEmojiMsg)?message.description+sadFaceEmoji:(message?.data[0]?.messageIcon)?message.description+(message?.data[0]?.messageIcon):message.description">
                            </p>
                        </div>

                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="accordion-content">
                    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoadingRP"></axis360-spinner-loader>
                    <ng-container *ngIf="msgData">
                        <ng-container *ngIf="msgData.name && msgData.startDate">
                            <h3 class="heading-3 sub-heading">Program Name</h3>
                            <p class="teritary-para" [title]="msgData.name">
                                {{msgData.name}}</p>
                        </ng-container>
                        <ng-container *ngIf="msgData.description && msgData.startDate">
                            <h3 class="heading-3 sub-heading">Description</h3>
                            <p class="teritary-para notifcation-msg-description"
                                [innerHTML]="(msgData.name === showEmojiMsg)?msgData.description+sadFaceEmoji:msgData.description">
                            </p>
                        </ng-container>
                        <ng-container *ngIf="msgData.startDate">
                            <h3 class="heading-3 sub-heading">Program Starts</h3>
                            <p class="teritary-para">{{msgData.startDate}}</p>
                        </ng-container>
                        <ng-container *ngIf="msgData.readByDate">
                            <h3 class="heading-3 sub-heading">Read By</h3>
                            <p class="teritary-para">{{msgData.readByDate}}</p>
                        </ng-container>
                        <ng-container *ngIf="msgData?.title?.includes('New Title Added')">
                            <axis360-title-card [titleContent]="msgData.bookDetail" parentRoute="library"
                                [programDetails]="msgData.programDetail"
                                [notificationClass]="'kz-notification-card-img'"
                                (notificationEvent)="closeDialog()"></axis360-title-card>
                        </ng-container>
                        <div class="btn-group" *ngIf="msgData.detailsLink">
                            <button aria-label="Checkout" class="btn-primary btn-primary-blue notification-btn"
                                id="go-to-program" name="Go_To_Program" [attr.aria-label]=msgData.text
                                (click)="viewDetails()" (keydown.enter)="viewDetails()" *ngIf="msgData.text">
                                {{msgData.text}}
                            </button>
                        </div>
                    </ng-container>
                    <div class="menu-item-link">
                        <button class="menuitem btn-nobg-bluecolor"
                            (click)="updateMessage(message.messageId, MSG_STATUS.UNREAD)" id="mark_asread"
                            aria-label="mark as unread"
                            (keydown.enter)="updateMessage(message.messageId, MSG_STATUS.UNREAD)">
                            <mat-icon svgIcon="kz-email-icon" aria-hidden="true">email-icon</mat-icon>
                            <span class="menuitem-text">Mark as Unread</span>
                        </button>
                    </div>
                    <div class="menu-item-link">
                        <button class="menuitem btn-nobg-bluecolor" (click)="openDeletePopup(0, message.messageId)"
                            aria-haspopup="false" (keydown.enter)="openDeletePopup(0, message.messageId)"
                            aria-label="Delete">
                            <mat-icon svgIcon="kz-trash" aria-hidden="true">trash</mat-icon>
                            <span class="menuitem-text">Delete</span>
                        </button>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
    <axis360-spinner-loader [isLoading]="true" *ngIf="pageIndex > 1 && isLoading">
    </axis360-spinner-loader>
    <div class="no-message" *ngIf="messages.length === 0">
        <p *ngIf="!isLoading && userNotification?.length === 0">No notifications available</p>
        <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
    </div>
</div>