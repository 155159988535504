<p-carousel axis360CarouselAda class="parentCarousel  hidden-card featured-carousel"
    [ngClass]="{'hide-dots': hideNavButtons}" (window:resize)="onResize()" [value]="bookDetails" [circular]="false"
    [numScroll]="numScroll" [numVisible]="numVisible" [responsiveOptions]="responsiveOptions">
    <ng-template let-book pTemplate="book">
        <mat-card class="reader-poster feature-card" *ngIf="book.titleUrl">
            <a class="poster-main-sec" [href]="book.titleUrl" tabindex="0"
                attr.aria-label="book is  {{book.title}} author is {{book.author}}">
                <img class="poster-main-img" tabindex="-1" [src]="book.url" [alt]="book.title" aria-hidden="true" />
                <div class="book-poster" [ngClass]="{'in':book.isAvailable,'out':!book.isAvailable}">
                    <mat-icon [svgIcon]="book.iconInfo.icon" [attr.aria-label]="book.iconInfo.alt"></mat-icon>
                </div>
            </a>
            <div class="poster-author-detail" aria-hidden="true">
                <p class="title-name" axis360EllipsisTitle>{{book.title}}</p>
                <p class="author-name" axis360EllipsisTitle>{{book.author}}</p>
            </div>
        </mat-card>
        <mat-card class="reader-poster feature-card hidden-title" *ngIf="!book.titleUrl">
            <mat-icon aria-hidden="false" tabindex="0" role="button" aria-label="Title info" #tooltip="matTooltip" matTooltip="This title is no longer available." (window:scroll)="onWindowScroll()">info_outline</mat-icon>
            <div class="hidden-title-img"></div>
        </mat-card>
    </ng-template>
</p-carousel>