import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_INFO } from '@shared/constants/api.constant';
import { User } from '@shared/data/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo: User = {};
  isLoginStatus = false;
  isInitialLoaded = true;
  public menuEmitter = new EventEmitter<boolean>();
  /**
   * Creates an instance of search service.
   * @param http http client
   */
  constructor(private http: HttpClient) {
    // Exposed this window method to update logged in status from .net login
    (window as any).updateLoggedIn = (isLoggedIn: boolean) => (this.userInfo.isLoggedIn = isLoggedIn);
  }

  /**
   * Setter of user info
   * @param userInfo user info
   */
  set currentUser(userInfo: User) {
    this.userInfo = userInfo || {};
  }

  /**
   * To get current user details
   * @returns user info
   */
  async getCurrentUser(): Promise<User> {
    try {
      this.userInfo = await this.http.get<User>(API_INFO.getCurrentUser).toPromise();
      return this.userInfo;
    } catch (e) {
      return this.userInfo;
    }
  }

  /**
   * To update the current user details
   * @returns user info
   */
  updateUserTracking(): Observable<string> {
    return this.http.post<string>(
      API_INFO.updateUserTracking
        .replace('<userName>', this.userInfo.userName)
        .replace('<enableTracking>', this.userInfo.enableTracking.toString())
        .replace('<displayTrackingPrompt>', this.userInfo.displayTrackingPrompt.toString()),
      {}
    );
  }
  /**
   * To determines whether user is logged in or not
   * @returns true if logged in
   */
  isLoggedIn = (): boolean => this.userInfo.isLoggedIn;
  /**
   * To return current PatronId
   * @returns patronId
   */
  getPatronId = (): string => this.userInfo.patronId || '';
  /**
   * To determines whether high contrast enabled or not
   * @returns true if it is enabled
   */
  isHighContrastEnabled = (): boolean => this.userInfo.isHighContrastEnabled;
  /**
   * To return current user name
   * @returns user name
   */
  getUserName = (): string => this.userInfo.userName;
  /**
   * To return current user email
   * @returns user email
   */
  getUserEmail = (): string => this.userInfo.email;

  /**
   * To determines whether tracking is enabled or not
   * @returns true if it is enabled
   */
  isTrackingEnabled = (): boolean => this.userInfo.enableTracking;

  /**
   * To determines whether tracking prompt can be displayable or not
   * @returns true if it is displayable
   */
  isDisplayTrackingPrompt = (): boolean => this.userInfo.displayTrackingPrompt;

  /**
   * To update the current user Email Id
   * @returns Success or Fail message
   */
  updateEmail(email: string, confirmEmail: string): Observable<string> {
    const formData = new FormData();
    formData.append('UpdateEmailModel_Email', email);
    formData.append('UpdateEmailModel_ConfirmEmail', confirmEmail);
    return this.http.post<string>(API_INFO.updateEmail, formData);
  }
}
