import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MyStuffsDetail } from '@shared/data/shared.model';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SharedService } from '@shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'axis360-mystuff-nav-tabs',
  templateUrl: './mystuff-nav-tabs.component.html',
  styleUrls: ['./mystuff-nav-tabs.component.scss']
})
export class MystuffNavTabsComponent implements OnInit, OnDestroy {
  @Input() isMyStuff = false;
  @Input() activeMyStuff: string;
  @Input() navClass: string;
  @Output() progressivePillsEmit = new EventEmitter();
  @Output() selectedMystuffTitle = new EventEmitter();
  mystuffsdetail: MyStuffsDetail[];
  refinerSubscription: Subscription;

  constructor(private sharedService: SharedService, private profileService: ProfileInfoService, private router: Router) { }

  ngOnInit(): void {
    this.mystuffsdetail = this.profileService.myStuffDetails?.pillsDetails || [];
    this.getMyShelfData();
    this.refinerSubscription = this.sharedService.refinerSubject.subscribe((res) => {
      if (res === 'checkCount' && !this.profileService?.myStuffDetails?.updated) {
        this.mystuffsdetail = this.profileService.myStuffDetails?.pillsDetails || [];
        const myStuffCounts = {};
        this.mystuffsdetail.forEach((x) => (myStuffCounts[x.name.toLowerCase()] = Number(x.value)))
        this.sharedService.myStuffCounts = myStuffCounts;
      }
    });
    this.sharedService.updateMystuffTabCount.subscribe((isUpdate) => {
      if (isUpdate) {
        this.sharedService.getMyShelfNavigation().subscribe(
          (responce) => {
            this.profileService.getMyStuffCounts(responce);
            this.mystuffsdetail = this.profileService.myStuffDetails?.pillsDetails;
            const myStuffCounts = {};
            responce.forEach((x) => (myStuffCounts[x.name.toLowerCase()] = Number(x.value)));
            this.sharedService.myStuffCounts = myStuffCounts;
            if (
              this.profileService.currentProfile.displayCheckoutHistory === 'False' ||
              this.profileService.currentProfile.displayCheckoutHistory === '' ||
              this.profileService.currentProfile.displayCheckoutHistory === 'N'
            ) {
              this.mystuffsdetail = this.mystuffsdetail.filter((_) => _.text !== 'History'); return;
            }
          },
          () => {
            this.mystuffsdetail = [];
          }
        );
      }
    });
  }

  /**
   * Get MyShelfData list by calling API on init
   */
  getMyShelfData() {
    this.sharedService.getMyShelfNavigation().subscribe(
      (responce) => {
        this.profileService.getMyStuffCounts(responce);
        this.mystuffsdetail = this.profileService.myStuffDetails?.pillsDetails;
        (!this.isMyStuff || this.router.url.includes('myshelf')) && this.mystuffsdetail.forEach((detail) => detail.url = detail.url.replace('mystuff', 'myshelf/mystuff'))
        const myStuffCounts = {};
        responce.forEach((x) => (myStuffCounts[x.name.toLowerCase()] = Number(x.value)));
        this.sharedService.myStuffCounts = myStuffCounts;
        this.progressiveEmitPills();
        if (
          this.profileService.currentProfile.displayCheckoutHistory === 'False' ||
          this.profileService.currentProfile.displayCheckoutHistory === '' ||
          this.profileService.currentProfile.displayCheckoutHistory === 'N'
        ) {
          this.mystuffsdetail = this.mystuffsdetail.filter((_) => _.text !== 'History'); return;
        }
        this.progressiveEmitPills();
      },
      () => {
        this.mystuffsdetail = [];
        this.progressiveEmitPills();
      }
    );
  }
  ngOnDestroy() {
    this.refinerSubscription && this.refinerSubscription.unsubscribe();
  }
  progressiveEmitPills() {
    this.progressivePillsEmit.emit();
  }
}
