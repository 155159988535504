import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PROFILE_TYPES, THIRD_PARTY_TIER, THIRD_PARTY_TYPES, THIRD_PARTY_VENDOR_CODE } from '../shared/constants/third-party.constants';
import { LayoutComponent } from '../../shared/data/third-party-model';
import { ThirdPartyService } from '../shared/services/third-party.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@shared/services/common.service';
import { ThirdPartyAnalyticService } from '../shared/services/third-party-analytic.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '@shared/services/config.service';
import { UserService } from '@shared/services/user.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { forceRedirect, focusElement, cdkVisualHidden, cdkRemoveVisualHidden, setTimeoutPromise } from '@shared/helper/app.util';
import { BreadcrumbService } from 'xng-breadcrumb';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
@Component({
  selector: 'axis360-third-party-tier-one',
  templateUrl: './third-party-tier-one.component.html',
  styleUrls: ['./third-party-tier-one.component.scss']
})
export class ThirdPartyTierOneComponent implements OnInit, AfterViewInit, OnDestroy {
  layoutComponents: LayoutComponent[] = [];
  breadcrumbs = [];
  THIRD_PARTY_TYPES = THIRD_PARTY_TYPES;
  isLoading = true;
  vendorCode = '';
  kidsZoneEnabled = false;
  axis360Enabled = false;
  isKZGuest: any;
  forceRedirect = forceRedirect;
  focusElement = focusElement;
  setTimeoutPromise = setTimeoutPromise;
  parentRoute = '';
  titleCardAnnounce = '';
  constructor(
    private thirdPartyService: ThirdPartyService,
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public configService: ConfigService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public profileInfoService: ProfileInfoService,
    public userService: UserService,
    private titleService: Title,
    public breadcrumbService: BreadcrumbService,
    public searchService: KzSearchService,
    public router: Router
  ) { }

  ngOnInit() {
    const parentRoute = new RegExp('view/' + "(.*)" + '/content').exec(this.router.url);
    this.parentRoute = parentRoute ? parentRoute[1] + '/content' : '';
    const { vendor_code: vendorCode, cardTitle: bookTitle } = this.activatedRoute.snapshot.queryParams;
    sessionStorage.setItem('defaultValues', undefined);
    sessionStorage.setItem('thirdparty', 'tire1');
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    this.isKZGuest = !this.userService.userInfo?.isLoggedIn;
    const isZoneEnable = !this.axis360Enabled && this.kidsZoneEnabled
      ? PROFILE_TYPES.TEEN
      : PROFILE_TYPES.ADULT;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(
        this.isKZGuest
          ? isZoneEnable
          : this.profileInfoService.currentProfile.profileType.toLowerCase()
      ) || {};
    if (!currentProfileFeatures[vendorCode]) {
      this.forceRedirect('/ng/view/library');
      return;
    }
    this.thirdPartyAnalyticService.trackScreen('LANDING_TIRE1');
    if (vendorCode !== THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
      const formatType = vendorCode === THIRD_PARTY_VENDOR_CODE.VBOOKS ? 'Videobooks' : 'Videos';
      const titleAnnounce = bookTitle ? bookTitle + ' - ' + 'Featured Titles and Categories' : `${formatType}${' - '}Featured Titles and Categories`;
      this.titleService.setTitle(titleAnnounce);
      this.titleCardAnnounce = `${titleAnnounce} Page`;
    } else {
      this.titleService.setTitle('Newspapers and Magazines - Featured Titles and Categories');
      this.titleCardAnnounce = 'Newspapers and Magazines - Featured Titles and Categories Page';
    }
    window.scrollTo(0, 0);
    this.getLayout();
    this.searchService.removeElmId.subscribe((id) => {
      if (id) {
        const elm = document?.getElementById('thirdPartyCarousel_' + id);
        elm && elm.remove();
      }
    });
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
  }
  async getLayout() {
    try {
      const { vendor_code: vendorCode } = this.activatedRoute.snapshot.queryParams;
      this.vendorCode = vendorCode;
      const [layoutData] = await this.thirdPartyService.getTierLayout(THIRD_PARTY_TIER.TIER_ONE, vendorCode);
      this.layoutComponents = layoutData.layout.components;
      this.breadcrumbs = [{ text: 'Library', url: '/view/library' }, { text: layoutData.vendor.display_name }];
      this.isLoading = false;
      this.breadcrumbService.set('@content', layoutData.vendor.display_name);

    } catch (e) {
      this.layoutComponents = [];
      this.isLoading = false;
      this.commonService.showErrorAlert();
    }
  }

  ngAfterViewInit(): void {
    const matchResult = /(Android|iPhone|iPod|iPad)/i.exec(navigator.userAgent)|| (navigator.userAgent.includes('Mac') && "ontouchend" in document);
    matchResult ? this.focusElement('loc_btnHamburgerMenu') : this.focusElement('loc_headerId');
    window.scrollTo(0, this.commonService.scrollPostion);
  }

  ngOnDestroy() {
    this.searchService.removeElmId.unsubscribe();
    cdkRemoveVisualHidden();
  }
}
