import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PressReaderCalendarComponent } from '@pressreader/shared/components/press-reader-calendar/press-reader-calendar.component';
import {
  PRESS_READER_LATEST_ISSUE_LIMIT,
  PRESS_READER_PAGE_SIZE,
  PUBLICATION_TYPES
} from '@pressreader/shared/constants/press-reader.constansts';
import { Category, LatestIssue, Publication, PublicationCard } from '@pressreader/shared/data/press-reader-model';
import { formatIssueDate, getPublicationCard, getPublicationCardFromIssue } from '@pressreader/shared/helper/press-reader.util';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import { Facet } from '@search/shared/data/filter-model';
import { SearchService } from '@search/shared/services/search.service';
import { DEFAULT_SNAKE_BAR_MSG, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { cdkRemoveVisualHidden, cdkVisualHidden, getCountyAtrr, getDate, getPressReaderAPIDate, getPressReaderDate, positionFooter, redirectUrl } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { SnackBarService } from '@shared/services/snack-bar.service';

@Component({
  selector: 'axis360-press-reader-details',
  templateUrl: './press-reader-details.component.html',
  styleUrls: ['./press-reader-details.component.scss']
})
export class PressReaderDetailsComponent implements OnInit {
  publicationId: string;
  publicationInfo: PublicationCard;
  category: Category;
  getDate = getDate;
  categoryPublications: PublicationCard[] = [];
  supplementPublications: PublicationCard[] = [];
  latestIssuePublications: PublicationCard[] = [];
  latestIssues: LatestIssue[] = [];
  country = '';
  language = '';
  isLoading = true;
  PUBLICATION_TYPES = PUBLICATION_TYPES;
  issueDate: string;
  invalidDates: Date[] = [];
  minDate: Date;
  maxDate: Date;
  latestCalenderIssues: { issueDate: Date; day: string }[];
  PRESS_READER_PAGE_SIZE = PRESS_READER_PAGE_SIZE;
  breadcrumbs: (
    | { text: string; url: string }
    | { text: string; url: string; queryParams: { type: string; cid: string; refresh: string } }
    | { text: string; url: string; queryParams: { issueDate: string; type: string; title: string } }
  )[];
  publicationType = '';
  publicationTitle = '';
  breadcrumbCategoryId: number;
  showCountryMoreLess = false;
  countryBrief = false;
  countries: Facet[] = [];
  ariaLabelCountry = 'Expand to see more countries';
  hidePara = false;
  @ViewChild('countryDesc') public countryDesc: ElementRef;
  /**
   * Creates an instance of press reader detail component.
   * @param activatedRoute activate route info
   * @param pressReaderService press reader servide
   * @param snackBarService toast service
   * @param spinnerService spinner service
   * @param router router info
   * @param searchService search service
   */
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private pressReaderService: PressReaderService,
    private snackBarService: SnackBarService,
    private router: Router,
    private searchService: SearchService,
    private pressReaderAnalyticsService: PressReaderAnalyticsService,
    private liveAnnouncer: LiveAnnouncer,
    private configService: ConfigService
  ) {}

  /**
   * To get publication id and publication details
   */
  ngOnInit(): void {
    this.liveAnnouncer.announce('Publication Detail screen', 'assertive');
    this.pressReaderAnalyticsService.trackScreen('DETAILS');
    this.publicationId = this.activatedRoute.snapshot.params.publicationId;
    this.issueDate = this.activatedRoute.snapshot.queryParams.issueDate;
    this.publicationType = this.activatedRoute.snapshot.queryParams.type;
    this.publicationTitle = this.activatedRoute.snapshot.queryParams.title;
    this.maxDate = new Date();
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);
    this.minDate = minDate;
    this.getPublicationDetails(this.publicationId);
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
  /**
   * To get breadcrumb info
   * @returns breadcrumb info
   */
  getBreadcrumb() {
    const breadcrumbCategoryId = this.breadcrumbCategoryId
      ? this.breadcrumbCategoryId.toString()
      : ''
    this.breadcrumbs = [
      { text: 'NEWSPAPERS & MAGAZINES', url: '/pressreader' },
      {
        text: `${this.publicationTitle.toUpperCase()}`,
        url: `/pressreader/list`,
        queryParams: {
          type: this.publicationType,
          cid:
            this.publicationType === PUBLICATION_TYPES.LATEST_ISSUES || this.publicationType === PUBLICATION_TYPES.SUPPLEMENT
              ? this.publicationId
              : breadcrumbCategoryId,
          refresh:
            this.publicationType === PUBLICATION_TYPES.LATEST_ISSUES || this.publicationType === PUBLICATION_TYPES.SUPPLEMENT ? '' : 'true'
        }
      },
      {
        text: this.publicationInfo.name,
        url: '/pressreader/details' + this.publicationId,
        queryParams: { issueDate: this.issueDate, type: this.publicationType, title: this.publicationType }
      }
    ];
  }

  /**
   * To get publication details
   * @param publicationId publication id
   */
  getPublicationDetails(publicationId: string) {
    this.isLoading = true;
    this.pressReaderService.getPublicationById(publicationId).subscribe(
      async (res) => {
        if (this.issueDate) {
          const issue: LatestIssue = await this.getIssueByDate(res, this.issueDate);
          if (issue.issueDate) {
            res.latestIssue = issue;
          }
        }
        this.publicationInfo = getPublicationCard(res);
        const currentIssueDate = new Date(this.publicationInfo.issueDate);
        if (currentIssueDate < this.minDate) {
          this.minDate = currentIssueDate;
          this.maxDate = currentIssueDate;
        }
        if (currentIssueDate > this.maxDate) {
          this.maxDate = currentIssueDate;
        }
        this.supplementPublications = (res.supplements || []).map((publication) => getPublicationCard(publication));
        await this.getLanguageAndCountries(this.publicationInfo.language, this.publicationInfo.country);
        await this.getLatestIssues(res);
        const publicationCategories = res.categories;
        this.pressReaderService.getCategories().subscribe(
          async (categoryRes) => {
            const [firstIndex = {}] = categoryRes || [];
            const { categories } = firstIndex;
            publicationCategories.forEach((pubCategory) => {
              const matchCategory = categories.find((category) => category.id === pubCategory);
              if (matchCategory) {
                this.category = matchCategory;
              }
            });
            this.getTitle();
            const breadcrumbCategory = categories.find((category) => category.displayName === this.publicationTitle);
            if (breadcrumbCategory) {
              this.breadcrumbCategoryId = breadcrumbCategory.id;
            }
            this.getBreadcrumb();
            await this.getCategoryPublications();
            this.isLoading = false;
            setTimeout(() => {
              this.showCountryMoreLessButton();
            });
            positionFooter(true);
          },
          () => {
            this.isLoading = false;
            positionFooter(true);
          }
        );
      },
      () => {
        this.isLoading = false;
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
        redirectUrl(this.router, '/pressreader');
      }
    );
  }

  /**
   * To get publications for the related categories
   * @returns publications details
   */
  async getCategoryPublications() {
    return new Promise((resolve) => {
      this.searchService.getCountries('').subscribe((contryFilterRes) => {
        const defaultCountry = this.configService.getPressReaderDefaultCounty();
        const { id: defaultCountryCode } = contryFilterRes.countries.find((country) => country.value === defaultCountry) || {};
        this.searchService.getLanguages(defaultCountryCode).subscribe((langFilterRes) => {
          const defaultLanguage = this.configService.getPressReaderDefaultLang();
          const { id: defaultLanguageCode } = langFilterRes.languages.find((lan) => lan.value === defaultLanguage) || {};
          this.pressReaderService
            .getPublications(
              0,
              PRESS_READER_PAGE_SIZE,
              '',
              defaultCountryCode.toString(),
              defaultLanguageCode.toString(),
              this.category.id.toString()
            )
            .subscribe(
              (publications) => {
                const { items } = publications;
                items.forEach((publication) => {
                  this.categoryPublications.push(getPublicationCard(publication, contryFilterRes.countries));
                });
                resolve(publications);
              },
              () => resolve([])
            );
        });
      });
    });
  }

  /**
   * To get publications for the related categories
   * @returns publications details
   */
  async getLatestIssues(publication: Publication) {
    return new Promise((resolve) => {
      this.pressReaderService
        .getLatestIssues(
          publication.cid,
          getPressReaderAPIDate(this.minDate),
          getPressReaderAPIDate(this.maxDate),
          PRESS_READER_LATEST_ISSUE_LIMIT
        )
        .subscribe(
          (latestIssues) => {
            this.latestIssues = latestIssues;
            latestIssues.slice(0, 12).forEach((issue) => {
              this.latestIssuePublications.push(getPublicationCardFromIssue(issue, publication, this.countries));
            });
            this.getInvalidDates();
            this.latestCalenderIssues = (this.latestIssues || []).map(this.getIssueModel);
            resolve(latestIssues);
          },
          () => resolve([])
        );
    });
  }

  /**
   * To get issue by date
   * @returns publications details
   */
  async getIssueByDate(publication: Publication, issueDate: string) {
    return new Promise((resolve) => {
      this.pressReaderService.getIssueByDate(publication.cid, issueDate).subscribe(
        (issue) => {
          resolve(issue);
        },
        () => resolve({})
      );
    });
  }

  /**
   * To invoke read now method from service
   * @param event cliek event
   * @param redirectionUrl press reader article url
   */
  readNow(event: PointerEvent, redirectionUrl: string) {
    this.pressReaderAnalyticsService.trackPressReaderEvent('READ_NOW');
    this.pressReaderService.readNow(event, redirectionUrl);
  }

  /**
   * To get languages and countries lookup
   * @param language language iso code
   * @param country country iso code
   */
  getLanguageAndCountries(language: string, country: string[]) {
    return new Promise((resolve) => {
      this.searchService.getNewsFilters().subscribe(
        (filterRes) => {
          this.countries = filterRes.countries;
          const multiCountries = country.map((obj) => filterRes.countries.find((countryObj) => countryObj.value === obj)?.text);
          this.country = multiCountries?.filter(Boolean)?.toString().replace(/,/g, ' | ') || '';
          this.language = filterRes.languages.find((langObj) => langObj.value === language)?.text;
          resolve(filterRes);
        },
        () => resolve([])
      );
    });
  }

  /**
   * redirect to the press reader listing page
   * @param type publication type
   * @param cid category id
   */
  redirectToListPage(type: string, cid: string) {
    const refreshParam = !type ? '&refresh=true' : '';
    redirectUrl(this.router, `/pressreader/list?type=${type}&cid=${cid}${refreshParam}`);
  }
  /**
   * open calendar dialog box
   */
  openCalendarDialog() {
    this.pressReaderAnalyticsService.trackPressReaderEvent('SELECT_ISSUE');
    this.dialog.open(PressReaderCalendarComponent, {
      panelClass: 'select-issue-calendar',
      backdropClass: 'select-issue-calendar-backDrop',
      data: {
        latestIssues: this.latestCalenderIssues,
        currentIssue: this.publicationInfo.issueDate,
        cid: this.publicationId,
        invalidDates: this.invalidDates,
        maxDate: this.maxDate,
        minDate: this.minDate,
        type: this.publicationType,
        title: this.publicationTitle
      },
      autoFocus: true,
      disableClose: false,
      ariaLabelledBy: 'select-issue-heading'
    });
  }

  /**
   * get past 90 days as array
   * @returns function returns past 90 days as array
   */
  getPast90Dates() {
    const dateArray = new Array();
    const stopDate = new Date(this.maxDate);
    const startDate = new Date(this.minDate);
    const currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  /**
   * get invalid issue dates in the last 90 days
   */
  getInvalidDates() {
    const dates = this.getPast90Dates();
    dates.forEach((date) => {
      if (!this.latestIssues.find((issue) => getPressReaderDate(new Date(issue.issueDate)) === getPressReaderDate(new Date(date)))) {
        this.invalidDates.push(date);
      }
    });
  }

  /**
   * To get calender issue model
   * @param issue latest issues object
   * @returns issue model
   */
  getIssueModel = (issue: LatestIssue): { issueDate: Date; day: string } => {
    const { issueDate } = issue;
    const issueDateStr = formatIssueDate(issueDate);
    const day = this.getDay(issueDateStr);
    return {
      issueDate: new Date(issueDateStr),
      day
    };
  };

  /**
   * To get full day name of the day
   * @param date input param as date
   * @returns full day name of the day
   */
  getDay = (date: Date | string): string => {
    let day;
    switch (new Date(date).getDay()) {
      case 0:
        day = 'Sunday';
        break;
      case 1:
        day = 'Monday';
        break;
      case 2:
        day = 'Tuesday';
        break;
      case 3:
        day = 'Wednesday';
        break;
      case 4:
        day = 'Thursday';
        break;
      case 5:
        day = 'Friday';
        break;
      case 6:
        day = 'Saturday';
        break;
      default:
        day = '';
        break;
    }
    return day;
  };

  /**
   * To get the page title
   * @returns page title
   */
  getTitle() {
    if (!this.publicationTitle) {
      this.publicationTitle = this.publicationInfo.type + 's';
      this.publicationType = this.publicationInfo.type;
    }
  }
  /**
   * To show country more less
   */
  showCountryMoreLessButton() {
    if (this.countryDesc?.nativeElement) {
      const descElement = this.countryDesc.nativeElement;
      const pHeight = descElement.offsetHeight;
      if (pHeight > 20) {
        this.showCountryMoreLess = true;
        this.countryBrief = false;
        this.countryDesc.nativeElement.children[0].style.display = '-webkit-box';
        return;
      }
      this.showCountryMoreLess = false;
      this.countryBrief = true;
      this.countryDesc.nativeElement.children[0].style.display = 'flex';
    }
  }
  /**
   * To switch country more less
   * @returns returns nothing
   */
  countryMoreLess() {
    this.countryBrief = !this.countryBrief;
    if (this.countryBrief) {
      this.countryDesc.nativeElement.children[0].style.display = 'flex';
      this.ariaLabelCountry = 'Contract to see less countries';
      this.pressReaderAnalyticsService.trackPressReaderEvent('MULTI_COUNTRY_PLUS');
      return;
    }
    this.countryDesc.nativeElement.children[0].style.display = '-webkit-box';
    this.ariaLabelCountry = 'Expand to see more countries';
    this.pressReaderAnalyticsService.trackPressReaderEvent('MULTI_COUNTRY_MINUS');
  }
  /**
   * to returns multiple country without pipe symbol
   * @param multiCountry input parameter as multiCountry
   * @returns to returns multiple country without pipe symbol
   */
  getCountyAtrr = (multiCountry: string) => {
    return getCountyAtrr(multiCountry);
  };
}
