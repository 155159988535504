<div class="carousel-container">
        <div class="carousel-wrapper" *ngIf="showShimmerSeriesCards">
                <h2 class="primary-heading-2">Titles in This Series</h2>
                <axis360-titles-carousel [addCard]="true" [parentRoute]="parentRoute" [showCarouselTitle]="showCarouselTitle" [showCarouselSubTitle]="showCarouselSubTitle"
                        [titlesList]="recommendedSeriesBooks">
                </axis360-titles-carousel>
        </div>
        <div class="carousel-wrapper" *ngIf="showShimmerRecommendedCards">
                <h2 class="primary-heading-2">Other Titles Like This</h2>
                <axis360-titles-carousel [addCard]="true" [parentRoute]="parentRoute" [showCarouselTitle]="showCarouselTitle" [showCarouselSubTitle]="showCarouselSubTitle"
                        [titlesList]="recommendedBooks">
                </axis360-titles-carousel>
        </div>
        <div class="carousel-wrapper"
                *ngIf="recommendedBooks?.length === 0 && recommendedSeriesBooks?.length === 0 && !isLoad">
                <p class="primary-heading-2">No Recommended books available</p>
        </div>
</div>