import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { BookDetail } from '@shared/data/engage-base.model';
import { KzProgramsService } from '../../service/kz-programs.service';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';

@Component({
  selector: 'axis360-kz-program-card-carousel',
  templateUrl: './kz-program-card-carousel.component.html',
  styleUrls: ['./kz-program-card-carousel.component.scss']
})
export class KzProgramCardCarouselComponent {
  constructor(public programSer: KzProgramsService,
    public programAnalyticsService: ProgramsAnalyticsService) { }
  @Input() programDetails: ProgramDetails;
  @Input() suggestedBooks: BookDetail[];
  @Input() parentRoute;
  @Input() cellsToScrollNums = [5, 4, 3, 2];
  @Output() markAsReadClickEvent = new EventEmitter();
  cellWidth = 164;
  cellMargin = 0;
  cellHeight = 310;
  cellsToScroll = 5;
  onResize = setIvyCarouselProps.bind(this, 160, 0, 292, 164, 0, 310, this.cellsToScrollNums);
  markAsReadClick(event) {
    this.markAsReadClickEvent.emit(event);
  }
  ngOnInit(): void {
    this.onResize = setIvyCarouselProps.bind(this, 160, 0, 285, 164, 0, 310, this.cellsToScrollNums);
    this.onResize();
  }
  trackCarousel(e: Event) {
      const target = e.target as any;
      if (Array.isArray(target.className) && target.className?.indexOf('carousel-arrow-next') > -1) {
        this.programAnalyticsService.trackNotificationsEvent('CLICK_CAROUSEL_RIGHT');
        target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
        return;
      }
      if (Array.isArray(target.className) && target.className?.indexOf('carousel-arrow-prev') > -1) {
        this.programAnalyticsService.trackNotificationsEvent('CLICK_CAROUSEL_LEFT');
        target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
      }
  }
}
