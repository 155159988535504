<mat-card class="search-info-card" (click)="titleCardClick($event, titleInfo.redirectUrl)" (keyup.enter)="titleCardClick($event, titleInfo.redirectUrl)" tabindex="0" [attr.lang]="titleInfo.languageCode">
    <div class="search-info-card-left">
        
        <img #cardImg [ngClass]="{'lazy-image': index > INIT_LOAD_IMGS_COUNT}" [className]="view === MAT_TABS_NAME.SITES ? 'sitesImg' : 'newsImg'" [attr.data-src]="titleInfo.imageSrc" [src]="index > INIT_LOAD_IMGS_COUNT ? '':titleInfo.imageSrc" alt=""
        />
        <mat-card-footer class="search-card-footer" *ngIf="titleInfo.subjectInfo">
            <div class="title-card-action-icon tab-show mob-show desktop-show" *ngIf="titleInfo.formatTypeIcon">
                <mat-icon [svgIcon]="titleInfo.formatTypeIcon"></mat-icon>
            </div>
            <div class="title-card-action-icon tab-show mob-show desktop-show" *ngIf="titleInfo.sourceTypeIcon">
                <mat-icon [svgIcon]="titleInfo.sourceTypeIcon"></mat-icon>
            </div>
        </mat-card-footer>
        <mat-card-subtitle class="search-subtitle mob-show tab-show" *ngIf="!titleInfo.subjectInfo">
            <p>
                <ng-container *ngIf="titleInfo.language"><span>in</span>&nbsp;<strong>{{titleInfo.language}}</strong></ng-container>
                <ng-container *ngIf="titleInfo.country"><br><span>from</span>&nbsp;<strong>{{titleInfo.country}}</strong></ng-container>
            </p>
        </mat-card-subtitle>
    </div>
    <div class="search-info-card-right">
        <mat-card-header>
            <mat-card-subtitle class="search-subtitle" *ngIf="titleInfo.subTitle">
                <p><strong class="center-dot" [attr.lang]="titleInfo.languageCode">{{titleInfo.subTitle}} </strong> <span class="search-date">{{getDate(titleInfo.releaseDate) | date:'d MMM yyyy'}}</span></p>
            </mat-card-subtitle>
            <mat-card-title class="search-maintitle" [attr.role]="view === MAT_TABS_NAME.NEWS ? 'link' : ''" [attr.lang]="titleInfo.languageCode" *ngIf="titleInfo.mainTitle">{{titleInfo.mainTitle}}
            </mat-card-title>
            <mat-card-subtitle class="search-subtitle serach-card-url" role="link" *ngIf="titleInfo.displayURL">
                {{titleInfo.displayURL}}</mat-card-subtitle>

            <mat-card-subtitle class="search-subtitle  tab-show mob-show author-info" >
                <p *ngIf="titleInfo.author"><span>by</span>&nbsp;
                    <strong [attr.lang]="titleInfo.languageCode">{{titleInfo.author}}</strong></p>
                <p *ngIf="titleInfo.multiCountry" class="multiCountry-txt" attr.aria-label="Country: {{getCountyAtrr(titleInfo.multiCountry)}}">
                    <span aria-hidden="true">Country:</span>&nbsp;
                    <strong aria-hidden="true">{{titleInfo.multiCountry}}</strong>
                </p>    
            </mat-card-subtitle>

        </mat-card-header>
        <mat-card-content class="serach-detils">
            <span [attr.lang]="titleInfo.languageCode">
                {{titleInfo.description}}
            </span>
        </mat-card-content>
        <mat-card-footer class="search-card-footer">
            <mat-card-subtitle class="search-subtitle desktop-show serach-card-author">
                <p *ngIf="titleInfo.author"><span>by&nbsp;</span><strong [attr.lang]="titleInfo.languageCode">{{titleInfo.author}}</strong></p>
                <p *ngIf="titleInfo.multiCountry" attr.aria-label="{{getCountyAtrr(titleInfo.multiCountry)}}">
                    <span aria-hidden="true">Country:&nbsp;</span>
                    <strong aria-hidden="true">{{titleInfo.multiCountry}}</strong>
                </p>
            </mat-card-subtitle>
            <mat-card-subtitle class="search-subtitle desktop-show serach-card-location" [ngClass]="{'width-auto': !titleInfo.author}" *ngIf="titleInfo.language || titleInfo.country">
                <p>
                    <ng-container *ngIf="titleInfo.language"><span>in</span><strong>&nbsp;{{titleInfo.language}}</strong></ng-container>
                    <ng-container *ngIf="titleInfo.country"><span> from</span><strong>&nbsp;{{titleInfo.country}}</strong></ng-container>
                </p>
            </mat-card-subtitle>
        </mat-card-footer>
        <mat-card-footer class="search-card-footer icon-footer" *ngIf="titleInfo.subjectInfo">
            <mat-card-subtitle class="search-subtitle">
                <p *ngIf="titleInfo.subjectInfo.subjects"><strong>Subjects</strong> <span>: {{titleInfo.subjectInfo.subjects}}</span></p>
                <p *ngIf="titleInfo.subjectInfo.languages"><strong>Languages</strong> <span>: {{titleInfo.subjectInfo.languages}}</span></p>
            </mat-card-subtitle>
            <mat-card-subtitle class="search-subtitle">
                <p *ngIf="titleInfo.subjectInfo.interestLevel"><strong>Interest Level</strong> <span>: {{titleInfo.subjectInfo.interestLevel}}</span></p>
            </mat-card-subtitle>
        
            <div class="title-card-action-icon desktop-show" >
                <mat-icon [svgIcon]="titleInfo.formatTypeIcon" *ngIf="titleInfo.formatTypeIcon"></mat-icon>
                <mat-icon [svgIcon]="titleInfo.sourceTypeIcon" *ngIf="titleInfo.sourceTypeIcon"></mat-icon>
            </div>
        </mat-card-footer>
    </div>
</mat-card>