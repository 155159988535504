import { Injectable } from '@angular/core';
import { HOME_REFINER } from '@home/shared/constants/home.constants';
import { APPLIED_PR_REFINERS, APPLIED_REFINERS } from '../constants/search-results.constants';
@Injectable({
  providedIn: 'root'
})
export class SearchStorageService {
  /**
   * Get refiners from session storage
   * @returns Refiner object from session storage
   */
  getRefiners = () => JSON.parse(sessionStorage.getItem(APPLIED_REFINERS) || '{}');
  /**
   * Set refiners to session storage
   * @param refiners Refiner object
   */
  setRefiners = (refiners) => {
    sessionStorage.setItem(APPLIED_REFINERS, JSON.stringify({ ...this.getRefiners(), ...refiners }));
  };
  /**
   * Get refiners from session storage
   * @returns Refiner object from session storage
   */
  getPRRefiners = () => JSON.parse(sessionStorage.getItem(APPLIED_PR_REFINERS) || '{}');
  /**
   * Set refiners to session storage
   * @param refiners Refiner object
   */
  setPRRefiners = (refiners) => {
    sessionStorage.setItem(APPLIED_PR_REFINERS, JSON.stringify({ ...refiners }));
  };
  /**
   * Remove refiners to session storage
   */
  removePRRefiners = () => {
    sessionStorage.removeItem(APPLIED_PR_REFINERS);
  };
  /**
   * Set news refiners to session storage
   * @param news News Refiner object
   */
  setNewsRefiners = (news) => this.setRefiners({ news });
  /**
   * Get news refiners from session storage
   * @returns News refiner object from session storage
   */
  getNewsRefiners = () => this.getRefiners().news;
  /**
   * Set sites refiners from session storage
   * @param sites Sites Refiner object
   */
  setSitesRefiners = (sites) => this.setRefiners({ sites });
  /**
   * Get sites refiners from session storage
   * @returns Sites refiner object from session storage
   */
  getSitesRefiners = () => this.getRefiners().sites;
  /**
   * Set news refiners to session storage
   * @param news News Refiner object
   */
  setPRCatelogRefiners = (pressReaderRefiner) => this.setPRRefiners({ pressReaderRefiner });
  /**
   * Get news refiners from session storage
   * @returns News refiner object from session storage
   */
  getPRCatelogRefiners = () => this.getPRRefiners().pressReaderRefiner;
  setHomeFiters = (selectedAvailability, selectedFormat) => {
    sessionStorage.setItem(HOME_REFINER.AVAILABILITY, selectedAvailability);
    sessionStorage.setItem(HOME_REFINER.FORMAT, selectedFormat);
  };

  getHomeFilter = () => sessionStorage.getItem(HOME_REFINER.FORMAT);
  /**
   * Set news sub tab refiners to session storage
   * @param news_pub News Refiner object
   */
  setNewsPubRefiners = (news_pub) => this.setPubRefiners({ news_pub });
  /**
   * Set Pub refiners to session storage
   * @param refiners Refiner object
   */
  setPubRefiners = (refiners) => {
    sessionStorage.setItem(APPLIED_PR_REFINERS, JSON.stringify({ ...this.getPubRefiners(), ...refiners }));
  };

  /**
   * Get refiners from session storage
   * @returns Refiner object from session storage
   */
  getPubRefiners = () => JSON.parse(sessionStorage.getItem(APPLIED_PR_REFINERS) || '{}');

  /**
   * Get Pub refiners from session storage
   * @returns Refiner object from session storage
   */
  getNewsPubRefiners = () => this.getPubRefiners().news_pub;

  /**
   * Remove refiners to session storage
   */
   removeSearchRefiners = () => {
    sessionStorage.removeItem(APPLIED_REFINERS);
  };

}
