<div class="section" *ngIf="programs.length">
    <div class="title-sec">
        <h2 class="title" id="loc_labelMyPrograms">
            My Programs
        </h2>
        <a role="link" href="javascript:;" id="loc_linkViewAllPrograms" tabindex="0" aria-label="See all my Programs"
            class="see-all see-all-text" routerLink="/view/programs" *ngIf="programs.length >= MY_PROGRAMS_SIZE">See All
            <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></a>
    </div>
    <div class="kz-carousel myPrograms-carousel" (window:resize)="onResize()">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="cellsToScroll" [margin]="cellMargin"
            [cellWidth]="cellWidth" [height]="cellHeight" (click)="trackCarousel($event)">
            <div class="carousel-cell" *ngFor="let program of programs" role="listitem">
                <mat-card class="myProgram" role="link" tabindex="0" (click)="onClickMyProgram(program.programId)" (keyup.enter)="onClickMyProgram(program.programId)">
                    <div class="kz-card-image"
                        [style.background-image]="programBackgroundImage(booksImageUrl[program?.books[0]?.bookId])">
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="progress-bar-container">
                            <div class="progress-bar d-flex"
                                [ngStyle]="{'width': (program.totalCompletePercentage > 0)?program.totalCompletePercentage + '%':'0%'}" attr.aria-label="{{program.programName}} progress bar {{(program.totalCompletePercentage >= 0)?program.totalCompletePercentage + '%':'0%'}}" role="text">
                                <p *ngIf="(program.totalCompletePercentage > 0)" aria-hidden="true">{{(program.totalCompletePercentage >= 0)?program.totalCompletePercentage + '%':'0%'}}</p>
                                <span class="kz-visually-hidden">{{program.programName}} progress bar {{(program.totalCompletePercentage >= 0)?program.totalCompletePercentage + '%':'0%'}}</span>
                            </div>
                        </div>
                    </div>
                    <mat-card-content class="kid">
                        <div class="kz-head-group">
                            <div class="prgram-type">PROGRAM</div>
                            <div class="date" *ngIf="program.readByDate">COMPLETE BY {{program.readByDate | date :'mediumDate'}}</div>
                        </div>
                        <div class="prgram-title single-ellipsis" axis360EllipsisTitle aria-hidden="true">{{program.programName}}</div>
                    </mat-card-content>
                </mat-card>
            </div>
        </carousel>
    </div>
</div>