import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgramCard } from '@readingprograms/shared/data/programs.model';

@Component({
  selector: 'axis360-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss']
})
export class ProgramCardComponent {
  @Input() cardRole: string;
  @Input() program: ProgramCard;
  @Output() programCardClickEvent = new EventEmitter<ProgramCard>();
}
