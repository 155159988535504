import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { ACCOUNT_CATEGORY, ACCOUNT_EVENTS, ACCOUNT_EVENTS_KEY, ACCOUNT_SCREENS, ACCOUNT_SCREENS_KEY } from '../constants/account.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof ACCOUNT_EVENTS_KEY;
@Injectable()
export class AccountAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of myshelf analytics service.
   */
  constructor() {
    super(ACCOUNT_SCREENS, ACCOUNT_EVENTS);
  }
  trackScreen(screen: keyof typeof ACCOUNT_SCREENS_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track account  events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackAccountEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(ACCOUNT_CATEGORY.ACTIONS, action, altObj);
  }

  
 
}
