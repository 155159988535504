<div class="thirdparty-tiertwowrap kz-custom-container-secondary" (window:resize)="onResize()">
  <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
  <div *ngIf="!isLoading">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{titleCardAnnounce}}</p>
    <div class="bread-crum-main">
      <axis360-breadcrumb [breadcrumbs]="commonService.breadcrumbs"
        [breadcrumbsClass]="'kz-breadcrumb thirdparty-tiertwo-breadcrumb'"></axis360-breadcrumb>
    </div>
    <button *ngIf="refinersComponent" class="skip-refiners skip-refiners-thirdparty" id="loc_btnSkiptoMain" (click)="skipRefiner()"(keydown.enter)="skipRefiner()">Skip the Refiners </button>
    <div class="thirdpartytiertwo-container" [ngClass]="{'no-refiners': !refinersComponent}">
      <div class="refiner-sec" *ngIf="refinersComponent">
        <div class="col-lg-4 col-xs-12 sticky-refiner">
          <axis360-third-party-refiners [showCategories]="showCategories" [component]="refinersComponent"
            *ngIf="refinersComponent" [isMobileResponsive]="isMobile">
          </axis360-third-party-refiners>
        </div>
      </div>
      <div class="col-lg-8 col-xs-12" [ngClass]="{'no-refiners': !refinersComponent}" id="thirdparty_result" tabindex="-1">
        <axis360-third-party-cards-grid [title]="pageTitle" [vendorCode]="vendorCode" [parentRoute]="parentRoute"
          [thirdPartyClass]="thirdPartyBaseGridClass" [filtersCount]="filtersCount"
          [componentClass]="layoutComponent.subType === THIRD_PARTY_SUBTYPE.PRESSRDR_FEATURED_LIST ? 'featuredClass': '' "
          (openFilterEvent)="thirdPartyService.openRefiner.next(true)" [component]="layoutComponent"
          *ngIf="layoutComponent">
        </axis360-third-party-cards-grid>
      </div>
    </div>
  </div>
</div>