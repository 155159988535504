import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MyStuffsDetail } from '@shared/data/shared.model';
import { HOME, MYSTUFF_ACTIVE_TAB } from '@shared/constants/app.constants';
import { MyStuffService } from './shared/services/my-stuff.service';
import { UserService } from '@shared/services/user.service';
import { CommonService } from '@shared/services/common.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement } from '@shared/helper/app.util';
import { Router } from '@angular/router';
@Component({
  selector: 'axis360-my-stuff',
  templateUrl: './my-stuff.component.html',
  styleUrls: ['./my-stuff.component.scss']
})
export class MyStuffComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
  mystuffsdetail: MyStuffsDetail[];
  MYSTUFF_ACTIVE_TAB = MYSTUFF_ACTIVE_TAB;
  homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'My Library';
  breadcrumbs = [
    { text: HOME[this.homePage], url: '/', isHref: true },
    { text: 'My Stuff', url: './' }
  ];
  hasTitleChildren: any;

  constructor(
    private liveAnnouncer: LiveAnnouncer,
    public myStuffService: MyStuffService,
    private userService: UserService,
    private titleService: Title,
    private commonService: CommonService,
    private cdref: ChangeDetectorRef,
    public router: Router
  ) { }

  ngOnInit(): void {
    document.title = 'MyStuff';
    if (!this.userService.isLoggedIn()) {
      this.commonService.redirectURLfromMail = this.router.url;
      this.commonService.doLogin();
    }
    this.titleService.setTitle('My Stuff');
    this.liveAnnouncer.announce('My Stuff Page', 'assertive');
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngAfterViewInit() {
    focusElement('loc_headerId');
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
  /**
   * Referesh the my stuff content and apply filter
   */
}
