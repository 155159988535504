<ng-container *ngIf="componentData && isThirdPartyCarousel">
    <axis360-third-party-cards-carousel [cellsToScrollNums]="[4,4,2,1]" [seeAllLink]="seeAllLink" [pageTitle]="pageTitle" [vendorCode]="vendorCode" (count)="countEvent($event)" [parentRoute] = "parentRoute"
        [isShowCount]="isShowCount"
        [isCarouselLoad]="isCarouselLoad"
        (checkEventDataLoading)="checkDataLoadingEvent($event)"
        *ngIf="componentData.type === THIRD_PARTY_TYPES.ITEMS_CAROUSEL || componentData.type === THIRD_PARTY_TYPES.STACK_CAROUSEL || componentData.type === THIRD_PARTY_TYPES.ARTICLE_CAROUSEL"
        [componentData]="componentData" [componentTitleClass]="componentData.type === THIRD_PARTY_TYPES.ARTICLE_CAROUSEL ? 'kz-third-party-articles-carousel' : componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS ? 'kz-video-carousel' : ' '"
        [inputElmIndex]="inputElmIndex">
    </axis360-third-party-cards-carousel>
    <axis360-third-party-featured [seeAllLink]="seeAllLink" [vendorCode]="vendorCode" [componentData]="componentData"
        *ngIf="componentData.type === THIRD_PARTY_TYPES.BANNER_CAROUSEL" [parentRoute] = "parentRoute">
    </axis360-third-party-featured>
    <axis360-third-party-categories-carousel [componentData]="componentData"
        *ngIf="componentData.type === THIRD_PARTY_TYPES.CATEGORIES_CAROUSEL">
    </axis360-third-party-categories-carousel>
    <axis360-third-party-widgets class="thired-party-widget" [componentData]="componentData" [seeAllLink]="seeAllLink" (count)="countEvent($event)" [parentRoute] = "parentRoute"
    *ngIf="componentData.type === THIRD_PARTY_TYPES.WIDGETS_CAROUSEL">
    </axis360-third-party-widgets>
</ng-container>