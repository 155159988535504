<div class="mystuff-grids" *ngIf="myStuffContent && myStuffContent.Items && myStuffContent.Items.length > 0">
    <ng-container *ngFor="let titleContent of myStuffContent.Items">
        <mat-card class="cards-main" (click)="navigateToListPage(titleContent)"
            (keyup.enter)="navigateToListPage(titleContent)" [ngClass]="{'checkouts': activeMyStuff === 'checkouts'}"
            tabindex="0">
            <div class="remaining-hours">
                <ng-container
                    *ngIf="(titleContent?.titleStateInfo && (!(activeMyStuff === 'checkouts') || (titleContent?.titleStateInfo?.displayStatus)))">
                    <axis360-title-actions [isDueDate]="true" [durationSecClass]="'remaining-hours'"
                        [dueDurationClass]="'kz-time-remain-lbl'" [kzCardBtnClass]="'kz-card-btn kz-card-btn-grid'"
                        [kzActionMenuItemClass]="'kz-my-stuff-grid-option'" [expandSecClass]="'more-menu'"
                        [bookDetail]="titleContent" [isProgressCalculate]="false" [isMyStuff]="true"
                        [isHistory]="activeMyStuff === 'history'">
                    </axis360-title-actions>
                </ng-container>
            </div>
            <div class="card-body">
                <h3 class="card-title single-ellipsis" axis360EllipsisTitle>
                    {{titleContent.Title || titleContent.title}}</h3>
                <div class="card-column mt-1">
                    <div class="col-1">
                        <div class="kz-stuff-book-image" aria-hidden="true">
                            <img class="mystuff-card-image"
                                [src]="titleContent.url || getTitleImageUrl(titleContent.ISBN)" alt="">
                            <div class="duration-sec" *ngIf="titleContent.Attribute">
                                <span class="duration-text">{{titleContent.Attribute}}</span>
                            </div>
                        </div>
                        <div class="book-poster" *ngIf="titleContent.FormatType" role=""
                            attr.aria-label="{{getIcon(titleContent.FormatType).alt}}" [ngClass]="{'disabled': !(titleContent.IsAvailable && titleContent.IsRTV) && 
                            !(titleContent?.titleStateInfo?.displayStatus === READY_FOR_CHECKOUT)}">
                            <mat-icon class="book-poster-icon" [svgIcon]="getIcon(titleContent.FormatType).icon">
                            </mat-icon>
                        </div>
                        <div class="kz-progress-bar-wrapper"
                            *ngIf="titleContent.percentageComplete > 0 && showProgress">
                            <div class="kz-progress-bar-container" tabindex="-1" role="text"
                                attr.aria-label="{{titleContent.Title}} completed {{titleContent.percentageComplete}}%">
                                <div class="kz-progress-bar"
                                    [ngStyle]="{'width': (titleContent.percentageComplete >= 0)?titleContent.percentageComplete + '%':'0%'}">
                                </div>
                            </div>
                            <div class="kz-progressbar-percentage-sec" role="progressbar" tabindex="-1" aria-hidden="true"
                                [ngStyle]="{'width': (titleContent.percentageComplete >= 20)?titleContent.percentageComplete + '%':'20%'}">
                                <span class="kz-progressbar-percentage" aria-hidden="true">
                                    {{titleContent.percentageComplete}}%</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="kz-card-right">
                            <div class="kz-card-text">
                                <p class="author-name single-ellipsis"
                                    title="{{titleContent.Author.slice(0,titleContent.Author.indexOf(';')) || titleContent.author.slice(0,titleContent.uuthor.indexOf(';'))}}">
                                    {{titleContent.Author.slice(0,titleContent.Author.indexOf(';')) ||
                                    titleContent.author.slice(0,titleContent.author.indexOf(';'))}}</p>
                                <p *ngIf="titleContent?.Narrator && titleContent?.Narrator?.length > 0 && titleContent?.Narrator[0]"
                                    class="narrator-name single-ellipsis" title="{{titleContent?.Narrator[0]}}">
                                    {{titleContent?.Narrator[0]}}</p>
                                <p *ngIf="activeMyStuff === 'history'" class="last-checkout"
                                    title="Last checkout {{formatLastCheckoutDate(titleContent.LastCheckoutDate)}}">
                                    Last checkout: {{formatLastCheckoutDate(titleContent.LastCheckoutDate)}}
                                </p>
                                <p *ngIf="activeMyStuff === 'checkouts' && titleContent?.titleStateInfo?.checkoutDueDate"
                                    class="last-checkout"
                                    attr.aria-label="Due date {{formatLastCheckoutDate(titleContent.titleStateInfo.checkoutDueDate)}}"
                                    title="Due date {{formatLastCheckoutDate(titleContent.titleStateInfo.checkoutDueDate)}}">
                                    Due date: {{formatLastCheckoutDate(titleContent.titleStateInfo.checkoutDueDate)}}
                                </p>
                            </div>
                            <div class="kz-btn-group">
                                <ng-container *ngIf="titleContent?.titleStateInfo">
                                    <axis360-title-actions [durationSecClass]="'remaining-hours'"
                                        [dueDurationClass]="'kz-time-remain-lbl'"
                                        [kzCardBtnClass]="'kz-card-btn kz-card-btn-grid'"
                                        [kzActionMenuItemClass]="'kz-my-stuff-grid-option'"
                                        [expandSecClass]="'more-menu kz-grid-more'" [isDueDate]="false"
                                        [isRecommendation]="activeMyStuff === 'recommendations'"
                                        [isHold]="activeMyStuff === 'holds'" [bookDetail]="titleContent"
                                        [isProgressCalculate]="false" (click)="$event.stopPropagation();"
                                        (keyup.enter)="$event.stopPropagation();" [isMyStuff]="true"
                                        [isHistory]="activeMyStuff === 'history'"
                                        (titleActionEvent)="titleActionEvent($event)">
                                    </axis360-title-actions>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </ng-container>
</div>