import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@shared/services/config.service';
import { ListBaseService } from '@shared/services/list-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyShelfListService extends ListBaseService {
  public isFeaturedListHide = new BehaviorSubject<boolean>(false);
/*   public reloadMyShelfComponent = new BehaviorSubject<boolean>(false);  */

  public setreloadMyShelfComponent$ = new Subject<boolean>();
  reloadMyShelfComponent = this.setreloadMyShelfComponent$.asObservable();

  /**
   * Creates an instance of programs service.
   * @param http http client
   */
  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) {
    super(http, profileInfoService, configService);
  }
  /**
   * Sets reload my shelf component
   * @param filter 
   */
  setReloadMyShelfComponent(filter: boolean) {
    this.setreloadMyShelfComponent$.next(filter);
  }
}
