<div
    [ngClass]="bookDetails && announcementData ? 'custom-row my-library-container' : 'custom-row my-library-container custom-row-adjust-content ' ">
    <ng-container *ngFor="let component of libBannerData.components">
        <axis360-shimmer-cards [compName]="compName"
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.ANNOUNCEMENTS && isAnnouncementLoading && !announcementData">
        </axis360-shimmer-cards>
        <div class="custom-col-4 custom-col-sm-6"
            [ngClass]="libBannerData.components.length === 1 || libBannerData.components.length === 2 && !bookDetails && !learningActivitiesData ? 'announcements single' : libBannerData.components.length === 2 && (bookDetails || learningActivitiesData) || libBannerData.components.length === 3 && (!bookDetails || !learningActivitiesData) ? 'double' : ''"
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.ANNOUNCEMENTS && !isAnnouncementLoading && announcementData && announcementData.announcements && announcementData.announcements.length && announcementData.announcements[0]">
            <axis360-announcements [announcementData]="component.announcementData"
                [showIframe]="showIframe"></axis360-announcements>
        </div>
        <axis360-shimmer-cards [compName]="compName"
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.FEATURED_TITLE && bookDetailsLoading && !bookDetails">
        </axis360-shimmer-cards>
        <div class="custom-col-4 custom-col-sm-6"
            [ngClass]="libBannerData.components.length === 1 || libBannerData.components.length === 2 && !announcementData && !learningActivitiesData ? '' : libBannerData.components.length === 2 && (announcementData || learningActivitiesData) || libBannerData.components.length === 3 && (!announcementData || !learningActivitiesData) ? 'double' : ''"
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.FEATURED_TITLE  && component?.components?.length > 0 && bookDetails && showBookOfMonth && !partnerSiteEnabled">
            <axis360-book-of-month [componentData]="component"></axis360-book-of-month>
        </div>
        <axis360-shimmer-cards [compName]="compName"
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.LEARNING_ACTIVITIES && isLearningActivitiesLoading && !learningActivitiesData && showLearningActivity">
        </axis360-shimmer-cards>
        <div class="custom-col-4 custom-col-sm-12"
            [ngClass]="libBannerData.components.length === 1 || libBannerData.components.length === 2 && !bookDetails && !announcementData ? 'learning-activities single': libBannerData.components.length === 2 && (bookDetails || announcementData) || libBannerData.components.length === 3 && (!bookDetails || !announcementData)? 'double' : ''"
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.LEARNING_ACTIVITIES && !isLearningActivitiesLoading && showLearningActivity && !partnerSiteEnabled">
            <axis360-learning-activities [learningActivityCategories]="component.learningActivitiesData"
                *ngIf="!isLearningActivitiesLoading" [componentData]="component"></axis360-learning-activities>
        </div>
    </ng-container>
</div>
