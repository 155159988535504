import { Injectable, NgZone, Inject } from '@angular/core';
import { documentToken, windowToken } from '@shared/constants/di-tokens';
import { Subject, Subscription } from 'rxjs';

const triggerOffsetRatio = 0.5;

@Injectable()
export class InfiniteScrollService {
  private scroll = new Subject();
  private shouldNotify = true;
  private prevScrollPosition = 0;
  private prevScrollHeight: number;
  isDataRefreshed = false;
  /**
   * Creates an instance of infinite Scroll service.
   * @param zone zone of the page
   * @param window window of the page
   * @param document document of the page
   */
  constructor(private zone: NgZone, @Inject(windowToken) private window: Window, @Inject(documentToken) private document: Document) {
    this.prevScrollHeight = this.document.body.scrollHeight;

    // Run scroll listener outside Angular to avoid running change detection too frequently.
    this.zone.runOutsideAngular(() => this.window.addEventListener('scroll', this.onScroll.bind(this)));
  }
  /**
   * subscribe the event
   * @param handler input param as handler
   * @returns returns scroll subscription
   */
  subscribe(handler: () => void): Subscription {
    return this.scroll.subscribe(handler);
  }
  /**
   * onScroll - called when we scroll
   */
  onScroll() {
    const scrollHeightChanged =
      this.document.body.scrollHeight > this.prevScrollHeight || this.document.body.scrollHeight < this.prevScrollHeight;
    if (scrollHeightChanged) {
      this.shouldNotify = true;
    }

    const isScrollingDown = this.window.pageYOffset > this.prevScrollPosition;
    if (isScrollingDown) {
      this.runNotify(this.shouldNotify);
    }

    this.prevScrollPosition = this.window.pageYOffset;
    this.prevScrollHeight = this.document.body.scrollHeight;
  }
  /**
   * scrolling - called when we scroll
   * @param notify input param as true/false
   */
  public runNotify(notify: boolean) {
    const bottomOffset = this.document.body.scrollHeight - (this.window.pageYOffset + this.window.innerHeight);
    const offsetViewportRatio = bottomOffset / this.window.innerHeight;
    if ((notify || this.isDataRefreshed) && offsetViewportRatio < triggerOffsetRatio) {
      this.zone.run(() => this.notifySubscribers());
      this.shouldNotify = false;
      this.isDataRefreshed = false;
    }
  }

  /**
   * notifySubscribers - called when scroll infinitely
   */
  private notifySubscribers() {
    this.scroll.next();
  }
}
