import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ANDROID_OPEN_URL, IOS_INSTALL_URL, IOS_OPEN_URL } from '@shared/constants/app.constants';
import { ConfigService } from '@shared/services/config.service';
declare const window: any;
@Component({
  selector: 'axis360-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit {
  partnerSiteEnabled: boolean;
  type: string;
  constructor(
    public configService: ConfigService,
    public alertDialog: MatDialogRef<AppBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public modaldata: any) { }

  ngOnInit(): void {
    this.partnerSiteEnabled = this.configService.currentLibrary.partnerSiteEnabled;
    this.type = this.modaldata.type;
  }


  onResize() {
    if (window.innerWidth < 769) {
      return;
    }
    this.alertDialog.close();
  }

  /**
   * Open the APP URL Based on Os Type
   */
  openApp() {
    if (this.type === 'Android') {
      window.open(ANDROID_OPEN_URL);
    }
    else if (this.type === 'iOS') {
      window.open(IOS_OPEN_URL);
    }
  }

  /**
   * InstallApp to get Url Based on Os Type
   */
  installApp() {
    if (this.type === 'Android') {
      window.open(ANDROID_OPEN_URL);
    }
    else if (this.type === 'iOS') {
      window.open(IOS_INSTALL_URL);
    }
  }

  /**
   * Close the Popup view and update the App Banner
   * MessageFlag
   */
  appBannerCloseBtn() {
    const close = false;
    this.alertDialog.close();
    this.configService.setAppBannerMessageFlag(close).subscribe((appCloseBanner: any) => {
      return !appCloseBanner;
    });
  }

  /**
   * App Banner to click don't remind me set the cookie value
   */
  appBannerOptOut() {
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 30);
    const cookieName = "AppBannerMessageFlag";
    const cookieValue = "false";
    document.cookie = cookieName + "=" + cookieValue + ";expires=" + expiredDate.toUTCString() + ";path=/";
    this.alertDialog.close();
  }


}
