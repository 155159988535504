export const type = [
  { text: 'Keyword', value: 'all', code: 'all' },
  { text: 'Title', value: 'title', code: 'all' },
  { text: 'Author', value: 'author', code: '' },
  { text: 'ISBN', value: 'isbn', code: '' }
];
export const collections = [
  {
    text: 'All',
    value: 'General',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/search/',
    isThirdPartyVendor: false
  },
  {
    text: 'eBooks',
    value: 'eBook',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/search/eBook',
    isThirdPartyVendor: false
  },
  {
    text: 'eAudios',
    value: 'eAudio',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/search/eAudio',
    isThirdPartyVendor: false
  },
  {
    text: 'Resource Hub',
    value: 'active_Resources',
    enabled: true,
    vendorGroup: false,
    url: '/view/search/active_Resources',
    isThirdPartyVendor: false
  },
  {
    text: 'Always Available',
    value: 'Always Available',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/search/',
    isThirdPartyVendor: false
  },
  /*{
    text: 'Requests For Library Purchase',
    value: 'Titles to Recommend',
    enabled: true,
    vendor_code: '',
    url: '/view/search/titles'
  },*/
  {
    text: 'Web Resources',
    value: 'sites',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/search/web_Resources',
    isThirdPartyVendor: false
  },

];
export const ProgramCollection = [
  {
    text: 'All',
    value: 'General',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/programs/<programId>/search/',
    isThirdPartyVendor: false
  },
  {
    text: 'eBooks',
    value: 'eBook',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/programs/<programId>/search/eBook',
    isThirdPartyVendor: false
  },
  {
    text: 'eAudios',
    value: 'eAudio',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/programs/<programId>/search/eAudio',
    isThirdPartyVendor: false
  },
  {
    text: 'Always Available',
    value: 'Always Available',
    enabled: true,
    vendor_code: '',
    vendorGroup: false,
    url: '/view/programs/<programId>/search/',
    isThirdPartyVendor: false
  }
];
export const availability = [{ text: 'Available Now', value: 'Available', checked: false }];
export const attributes = [{ text: 'eRead-Along', value: 'eRead-Along', checked: false }];
export const DisableCollectionType = {
  STUDY_SITES: 'sites',
  RESOURCES_HUB: 'active_Resources'
};
export const DisableCollection = {
  [DisableCollectionType.STUDY_SITES]: 'Study Sites'
};
export const TYPE = {
  Title: 'Title',
  Author: 'Author',
  ISBN: 'ISBN'
};
export const FORMAT_TYPE = {
  ABT: 'ABT',
  All: 'All'
};
export const KEYWORD = 'Keyword';
export const GENERAL = 'General';
export const ALL = 'all';
export const AUTHOR = 'author';
export const ISBN = 'isbn';
export const TITLE = 'title';
export const TITLES_TO_RECOMMEND = 'Titles to Recommend';
export const PRESS_READER = 'PRESSRDR'
export const collectionCheck = {
  General: 'General',
  Available: 'Always Available',
  EBook: 'eBook',
  EAudio: 'eAudio',
  ResourceHub: 'active_Resources',
  PurchaseRequest: 'Titles to Recommend',
  WebResources: 'sites'
}
export const formatType = [
  { name: 'All', value: 'All', checked: false },
  { name: 'eBook', value: 'EBT', checked: false },
  { name: 'eAudio', value: 'ABT', checked: false },
];
export const DEFAULT_PATH_PARMS = '/view/search/';
export const COLLECTION_CATEGORY = {
  eBook: "eBook",
  eAudio: "eAudio",
  active_Resources: "active_Resources",
  sites: "sites",
}
