import { Component, EventEmitter, OnInit, Output, Input, AfterViewChecked, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  HOME_AVAILABILITY,
  MYSTUFF_SORT_CHECKOUT,
  MYSTUFF_SORT_ARRAY,
  MYSTUFF_ACTIVE_TAB_SEARCH,
  MYSTUFF_LIST_MAP,
  MYSTUFF_SORT,
  KZ_MYSTUFF_FORMAT_TYPE,
  KZ_MYSTUFF_NON_THIRD_PARY_FORMAT_TYPE
} from '@shared/constants/app.constants';
import { HomeFormatType } from '@shared/data/config.model';
import { MyStuffService } from '../../services/my-stuff.service';
import { MyStuffAnalyticsService } from '../../services/my-stuff.analytics.service';
import { SharedService } from '@shared/services/shared.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ConfigService } from '@shared/services/config.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ProfileInfoService } from '@shared/services/profile-info.service';
@Component({
  selector: 'axis360-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit, AfterViewChecked {
  @Output() switchGridListViewEvent = new EventEmitter<boolean>();
  @Output() filterChanged = new EventEmitter();
  @Output() sortChanged = new EventEmitter();
  @Input() activeMyStuff: string;
  @Output() searchChanged = new EventEmitter();
  @ViewChild('searchCheckouts') searchCheckouts;
  homeFormatType: HomeFormatType[] = KZ_MYSTUFF_FORMAT_TYPE;
  selectedAvailability = HOME_AVAILABILITY[0].value;
  selectedFormat = KZ_MYSTUFF_FORMAT_TYPE[0].value;
  sortOptions = MYSTUFF_SORT_CHECKOUT;
  search = MYSTUFF_ACTIVE_TAB_SEARCH;
  selectedSortOption: string;
  showFilterMenu = true;
  history = MYSTUFF_LIST_MAP.history;
  isGridView = false;
  isShowSearch = true;

  constructor(
    public myStuffService: MyStuffService,
    public sharedService: SharedService,
    private myStuffAnalyticsServcie: MyStuffAnalyticsService,
    public overlayContainer: OverlayContainer,
    public configService: ConfigService,
    public cdref: ChangeDetectorRef,
    public liveAnnouncer: LiveAnnouncer,
    public profileInfo: ProfileInfoService
  ) {}

  ngOnInit(): void {
    this.myStuffService.filterOption = KZ_MYSTUFF_FORMAT_TYPE[0].value;
    this.myStuffService.selectAvailability = HOME_AVAILABILITY[0].value;
    this.selectedFormat = this.myStuffService.sortOption;
    this.myStuffService.sortOption = this.selectedFormat === MYSTUFF_SORT_CHECKOUT[0].value ? MYSTUFF_SORT_CHECKOUT[0].value
    : MYSTUFF_SORT[0].value;
  }

  ngAfterViewChecked() {
    this.sortOptions = MYSTUFF_SORT_ARRAY[this.activeMyStuff];
    if (!this.configService?.currentLibrary?.reviews?.patronReviewsEnabled) {
      this.sortOptions = this.sortOptions?.filter((_) => _.text !== 'Ratings');
    }
    this.isGridView = true;
    if (!this.configService.currentLibrary.axis360Enabled
      && this.configService.currentLibrary.kidsZoneEnabled
    ) {
      const isMyStuffPage = this.activeMyStuff === 'checkouts' || this.activeMyStuff === 'wishlist' || this.activeMyStuff === 'history';
      this.homeFormatType = isMyStuffPage ? KZ_MYSTUFF_FORMAT_TYPE : KZ_MYSTUFF_NON_THIRD_PARY_FORMAT_TYPE;
      return this.homeFormatType;
    }
    this.homeFormatType = (this.activeMyStuff === 'checkouts' || this.activeMyStuff === 'wishlist' || this.activeMyStuff === 'history')
      ? KZ_MYSTUFF_FORMAT_TYPE : KZ_MYSTUFF_NON_THIRD_PARY_FORMAT_TYPE;
  }
  /**
   * switch between grid and list view
   */
  switchGridListView(focusId: string) {
    setTimeout(() => {
      document.getElementById(focusId)?.focus();
    });
    this.myStuffService.isGridView = !this.myStuffService.isGridView;

    if (this.myStuffService.isGridView) {
      this.myStuffAnalyticsServcie.trackMyStuffEvent('ACTION_VIEW_GRID');
      this.liveAnnouncer.announce('List view changed.', 'polite');
    } else {
      this.myStuffAnalyticsServcie.trackMyStuffEvent('ACTION_VIEW_LIST');
      this.liveAnnouncer.announce('Grid view changed.', 'polite');
    }
  }
  showSerach() {
    this.isShowSearch = false;
    this.myStuffAnalyticsServcie.trackMyStuffEvent('ACTION_SEARCH_ICON');
    this.myStuffService.showSearch = !this.myStuffService.showSearch;
    if (!this.myStuffService.showSearch) {
      this.clearSearch();
    }
    this.cdref.detectChanges();
    this.searchCheckouts?.nativeElement.focus();
  }

  /**
   * Filter change
   */
  filterChange(filter: string, filterValue: string, filterText: string, id: string, event: any) {
    if (filter === 'Availability') {
      this.selectedAvailability = filterValue;
    } else {
      this.selectedFormat = filterValue;
    }
    this.myStuffService.filterOption = this.selectedFormat;
    this.myStuffService.selectAvailability = this.selectedAvailability;
    this.filterChanged.emit(id);
    this.myStuffAnalyticsServcie.trackMyStuffEvent('ACTION_FILTER', { filterName: filterText });
    const menuItems = document.querySelectorAll('[aria-selected="true"]');
    menuItems.forEach(item => item.setAttribute('aria-selected', 'false'));
    (event.target as HTMLElement).setAttribute('aria-selected', 'true');
  }
  announceSelection(event: Event) {
    const item = event.target as HTMLElement;
    if (item.getAttribute('aria-selected') === 'true') {
      item.setAttribute('aria-label', item.innerText + 'Selected');
    }
    else if(item.getAttribute('aria-selected') === 'false') {
      item.setAttribute('aria-label', item.innerText);
    }
  }
  /**
   * Sort change
   */
  sortChange(sortValue: string, sortText: string, id: string, event: any) {
    this.selectedSortOption = sortValue;
    this.myStuffService.sortOption = sortValue;
    this.sortChanged.emit(id);
    this.myStuffAnalyticsServcie.trackMyStuffEvent('ACTION_SORT', { sortName: sortText });
    const menuItems = document.querySelectorAll('[aria-selected="true"]');
    menuItems.forEach(item => item.setAttribute('aria-selected', 'false'));
    (event.target as HTMLElement).setAttribute('aria-selected', 'true');
  }
  searchChange(id: string) {
    this.myStuffAnalyticsServcie.trackMyStuffEvent('ACTION_SEARCH', { keywords: this.myStuffService.searchKeyword });
    this.searchChanged.emit(id);
  }
  clearSearch(id: string = '') {
    this.myStuffService.searchKeyword = '';
    this.searchChanged.emit(id);
    setTimeout(() => {
      this.searchCheckouts?.nativeElement.focus();
    }, 1000);
  }

  /**
   * @param state menu open State
   */
  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      document.getElementsByTagName('html')[0].setAttribute('class', 'cdk-global-scrollblock');
      (document.querySelector('.mat-menu-item') as HTMLElement)?.focus();
      return;
    }
    setTimeout(() => {
      document.getElementById('loc_btnFilter').setAttribute('aria-expanded', 'false');
      document.getElementById('loc_btnSort').setAttribute('aria-expanded', 'false');
    }, 1000);
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
  }
  removeTooltipAnnouce() {
    document.querySelectorAll('.kz-card-tooltip').forEach((element) => {
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    });

    const formatTooltip = document.getElementById('loc_btnFilter');
    formatTooltip?.removeAttribute('aria-describedby');
    const sortTooltip = document.getElementById('loc_btnSort');
    sortTooltip?.removeAttribute('aria-describedby');
  }
}
