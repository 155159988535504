import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACTION_HANDLER_TYPE, MAT_TABS_NAME, PRESS_READER_REDIRECT_URL } from '@search/shared/constants/search-results.constants';
import { checkLoginAndHandleAction } from '@search/shared/helper/search.util';
import { API_INFO } from '@shared/constants/api.constant';
import { PressReaderBaseService } from '@shared/services/press-reader-base.service';
import { UserService } from '@shared/services/user.service';
import { Observable } from 'rxjs';
import { PRESS_READER_PAGE_SIZE, PRESS_READER_SORT_OPTIONS } from '../constants/press-reader.constansts';
import { Categories, CategoryIcons, LatestIssue, Publication, Publications } from '../data/press-reader-model';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PressReaderService extends PressReaderBaseService {

  constructor(public http: HttpClient, private userService: UserService) {
    super(http);
  }

  createClientIPHeader(headers: HttpHeaders) {
    headers.append('x-client-ip', this.localIp);
  }

  /**
   * To get categories from press reader
   * @returns categories info
   */
  getCategories(offset = 0, limit = PRESS_READER_PAGE_SIZE): Observable<Categories[]> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<Categories[]>(
      API_INFO.pressReaderCategories.replace('<offSet>', offset.toString()).replace('<limit>', limit.toString())
    );
  }

  /**
   * To get publication detail from press reader
   * @returns publication detail
   */
  getPublicationById(id: string): Observable<Publication> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<Publication>(API_INFO.pressReaderPublicationById.replace('<id>', id));
  }
  /**
   * To get publications from press reader
   * @param offset input param as offset
   * @param limit input param as limit
   * @param type input param as type
   * @param inVal input param as inVal
   * @param inVal1 input param as inVal1
   * @param has input param as has
   * @param orderBy input param as orderBy
   * @param searchTerm input param as searchTerm
   * @returns return publications from press reader
   */
  getPublications(
    offset = 0,
    limit = PRESS_READER_PAGE_SIZE,
    type = '',
    inVal = '',
    inVal1 = '',
    has = '',
    orderBy = PRESS_READER_SORT_OPTIONS.LATEST_ISSUE_DATE.value,
    searchTerm = ''
  ): Observable<Publications> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<Publications>(
      API_INFO.pressReaderPublications
        .replace('<offSet>', offset.toString())
        .replace('<limit>', limit.toString())
        .replace('<tp>', type)
        .replace('<inVal>', inVal)
        .replace('<inVal1>', inVal1)
        .replace('<has>', has)
        .replace('<orderBy>', orderBy)
        .replace('<searchTerm>', searchTerm)
    );
  }
  /**
   * To get publications from press reader
   * @returns publications details
   */
  getPublicationsWithQuery(
    offset = 0,
    limit = PRESS_READER_PAGE_SIZE,
    searchTerm = '',
    orderBy = PRESS_READER_SORT_OPTIONS.LATEST_ISSUE_DATE.value,
    type = ''
  ): Observable<Publications> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<Publications>(
      API_INFO.publicationsWithQuery
        .replace('<offSet>', offset.toString())
        .replace('<limit>', limit.toString())
        .replace('<searchTerm>', searchTerm)
        .replace('<orderBy>', orderBy)
        .replace('<tp>', type)
    );
  }

  /**
   * To get categories icons from press reader
   * @returns publications details
   */
  getCategoriesIcon(): Observable<CategoryIcons> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<CategoryIcons>(API_INFO.pressReaderCategoriesIcon);
  }

  /**
   * To get latest issues from press reader
   * @returns publications details
   */
  getLatestIssues(cid: string, fromDate: string, toDate: string, limit = PRESS_READER_PAGE_SIZE): Observable<LatestIssue[]> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<LatestIssue[]>(
      API_INFO.pressLatestIssues
        .replace('<cid>', cid)
        .replace('<limit>', limit.toString())
        .replace('<fromDate>', fromDate)
        .replace('<toDate>', toDate)
    );
  }

  /**
   * To get issue detail by date from press reader
   * @returns publications details
   */
  getIssueByDate(cid: string, date: string): Observable<LatestIssue> {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<LatestIssue>(API_INFO.pressIssueByDate.replace('<cid>', cid).replace('<date>', date));
  }

  /**
   * Open login popup or open press reader article page
   * @param event pointer event
   * @param redirectionUrl redirection url for press reader article
   */
  readNow = (event: any, redirectionUrl: string) => {
    const url = PRESS_READER_REDIRECT_URL.replace('<redirectionUrl>', redirectionUrl);
    if (this.userService.isLoggedIn()) {
      window.open(url, '_blank');
      return;
    }
    event.data = ACTION_HANDLER_TYPE[MAT_TABS_NAME.NEWS];
    event.url = url;
    checkLoginAndHandleAction(event);
  };
}
