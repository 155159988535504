<div class="body" id="loc_snackBar">
  <div class="message success-msg" *ngIf="data.mode === snackBarMode.INFO">
    <div id="toast-message" class="kz-toast-msg">
      <span class="kz-visually-hidden" aria-live="assertive" role="alert" [attr.aria-label]="'Success! ' + snackMessage" >Success! {{snackMessage}}</span>
      <p class="toast-title heading-1" aria-hidden="true" id="toast-heading">Success! </p>
      <span [innerHTML]="snackMessage" aria-hidden="true" id="toast-description"></span>
      <button id="toast-close-button" aria-label="close" tabindex="-1" aria-hidden="true" type="button" mat-raised-button class="action"
        (click)="dismiss()">
        <mat-icon svgIcon="kz-close">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="message success-msg" *ngIf="data.mode === snackBarMode.THANK_YOU">
    <div id="toast-message" class="kz-toast-msg">
      <span class="kz-visually-hidden" aria-live="assertive" role="alert" >Thank you! {{snackMessage}}</span>
      <p aria-live="assertive" aria-hidden="true" class="toast-title heading-1" id="toast-heading">Thank you!</p>
      <span aria-live="assertive" aria-hidden="true" id="toast-description">{{snackMessage}}</span>
      <button type="button" id="toast-close-button" mat-raised-button class="action" (click)="dismiss()"
        aria-label="close" tabindex="-1">
        <mat-icon svgIcon="kz-close"></mat-icon>
      </button>
    </div>
  </div>
  <div class="message error-msg" *ngIf="data.mode === snackBarMode.ERROR">
    <div id="toast-message" class="kz-toast-msg">
      <span class="kz-visually-hidden" aria-live="assertive" role="alert" >Failure! {{snackMessage}}</span>
      <p aria-live="assertive" aria-hidden="true" class="toast-title heading-1" id="toast-heading">Failure!</p>
      <span aria-live="polite" aria-hidden="true" [innerHTML]="snackMessage" id="toast-description"></span>
      <button type="button" id="toast-close-button" mat-raised-button class="action" (click)="dismiss()"
        aria-label="close" tabindex="-1">
        <mat-icon svgIcon="kz-close"></mat-icon>
      </button>
    </div>
  </div>
  <div class="message error-msg" *ngIf="data.mode === snackBarMode.ERROR_UH_OH">
    <div id="toast-message" class="kz-toast-msg">
      <span class="kz-visually-hidden" aria-live="assertive" role="alert" >Uh oh! {{snackMessage}}</span>
      <p aria-live="assertive" aria-hidden="true" class="toast-title heading-1" id="toast-heading">Uh oh!</p>
      <span aria-live="assertive" aria-hidden="true" id="toast-description">{{snackMessage}}</span>
      <button type="button" id="toast-close-button" mat-raised-button class="action" (click)="dismiss()"
        aria-label="close" tabindex="-1">
        <mat-icon svgIcon="kz-close"></mat-icon>
      </button>
    </div>
  </div>
</div>