import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LearningActivitiesService {
  constructor(public http: HttpClient, public userService: UserService) { }
  async getLearningActivities(refiner) {
    return this.http.post(API_INFO.getLearningActivity, refiner).toPromise();
  }
}
