import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { FeaturedProgramBookDetails } from '@home/shared/data/home.model';

@Component({
  selector: 'axis360-featured-program-carousel',
  templateUrl: './featured-program-carousel.component.html',
  styleUrls: ['./featured-program-carousel.component.scss']
})
export class FeaturedProgramCarouselComponent implements OnInit {
  @ViewChild('tooltip') tooltip: MatTooltip;
  @Input() bookDetails: FeaturedProgramBookDetails[] = [];
  responsiveOptions = [
    {
      breakpoint: '3840px',
      breakpointWidth: 3840,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1440px',
      breakpointWidth: 1440,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1024px',
      breakpointWidth: 1024,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      breakpointWidth: 768,
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '375px',
      breakpointWidth: 375,
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '0px',
      breakpointWidth: 0,
      numVisible: 2,
      numScroll: 2
    }
  ];
  hideNavButtons: boolean;
  numScroll: number;
  numVisible: number;
  /**
   * To refresh the carousel view on init
   */
  ngOnInit() {
    this.onResize();
  }
  /**
   * To be called on window resize to refresh the carousel view
   */
  onResize() {
    const innerWidth = window.innerWidth;
    const curBreakpoint = this.responsiveOptions.find((option) => innerWidth >= option.breakpointWidth);
    this.numScroll = curBreakpoint.numScroll;
    this.numVisible = curBreakpoint.numVisible;
    this.hideNavButtons = curBreakpoint.numVisible >= this.bookDetails.length;
  }
  /**
   * trigger event when window scroll
   */
  onWindowScroll() {
    this.tooltip.hide();
  }
}
