import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HOME } from '@shared/constants/app.constants';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement } from '@shared/helper/app.util';
import { KzProgramsService } from './shared/service/kz-programs.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-kz-programs',
  templateUrl: './kz-programs.component.html',
  styleUrls: ['./kz-programs.component.scss']
})
export class KzProgramsComponent {
  focusElement = focusElement;
  constructor(
    public commonService: CommonService,
    public titleService: Title,
    public kzProgramsService: KzProgramsService) { }
  tabsList = [
    { url: '/view/programs', text: 'My Programs' },
    { url: '/view/programs/open', text: 'Open Programs' }
  ];

  breadcrumbs: ({ text: string; url: string; isHref: boolean } | { text: string; url: string; isHref?: boolean })[];

  ngOnInit(): void {
    this.titleService.setTitle('Program');
    const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
    this.breadcrumbs = [
      { text: homePage, url: HOME[homePage], isHref: true },
      { text: 'Programs', url: '/view/programs' }
    ];
    this.kzProgramsService.focusElementHeaderId.subscribe(focus =>{
      if (focus) {
        return;
      }
      this.focusElement('loc_headerId');
    })
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2500);
  }

  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
}
