<div class="kz-badge-head" *ngIf="isLoading || badges.length">
    <h2 class="badge-heading heading-2">Badges</h2>
</div>
<axis360-shimmer-cards [compName]="compName" *ngIf="isLoading"></axis360-shimmer-cards>
<div class="kz-carousel badges-carousel" id="loc_badges_carosel" *ngIf="badges.length" (window:resize)="onResize()">
    <carousel axis360IvyCarousel [dots]="false" class="goals-badge-carousel" [margin]="cellMargin" [cellWidth]="cellWidth"
        (click)="trackCarousel($event)" [height]="cellHeight">
        <div class="carousel-cell" *ngFor="let badge of badges" role="listitem" [attr.aria-label]="isMobileDevice() ? badge.badgeName : '' " [attr.tabindex]="isMobileDevice() ? '0' : ''">
            <axis360-badges-card [badge]="badge"></axis360-badges-card>
        </div>
    </carousel>
</div>