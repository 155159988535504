import { Component, Input, OnInit } from '@angular/core';
import { PublicationCard } from '@pressreader/shared/data/press-reader-model';
import { PRESSREADER_TITLE } from '@search/shared/constants/search-results.constants';

@Component({
  selector: 'axis360-press-reader-publications',
  templateUrl: './press-reader-publications.component.html',
  styleUrls: ['./press-reader-publications.component.scss']
})
export class PressReaderPublicationsComponent implements OnInit {
  PRESSREADER_TITLE = PRESSREADER_TITLE;
  publicationTitle = '';
  publicationType = '';
  @Input() publications: PublicationCard[] = [];

  constructor() {}

  ngOnInit(): void {}
}
