import { Component, Input } from '@angular/core';
import { ThirdPartyBaseComponent } from '../third-party-base/third-party-base.component';

@Component({
  selector: 'axis360-third-party-categories',
  templateUrl: './third-party-categories.component.html',
  styleUrls: ['./third-party-categories.component.scss']
})
export class ThirdPartyCategoriesComponent extends ThirdPartyBaseComponent {
  @Input() categoryClass: string;
}
