import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CURATED_LISTS, HOME } from '@shared/constants/app.constants';
import { CommonService } from '@shared/services/common.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BASED_ON_INTEREST, BASED_ON_INTERESTS, TITLE_LIST } from './../shared/constants/title-list-constants';
import { TitleListAnalyticsService } from './../shared/services/title-list-analytic.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement, strToBool } from '@shared/helper/app.util';
import { TitleListService } from '../shared/services/title-list.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
/**
 *
 * Component
 * on init
 */
@Component({
  selector: 'axis360-title-list',
  templateUrl: './title-list.component.html',
  styleUrls: ['./title-list.component.scss']
})
export class TitleListComponent implements OnInit, AfterViewInit {
  selectedSubject: { param: string; type: string };
  menuType: string;
  subject = '';
  breadcrumbs: { text: string; url: string; handleClick?: boolean; isHref?: boolean }[];
  parentRoute: string;
  selectedCategoryId: any;
  showErrorMsg = false;
  TITLE_LIST = TITLE_LIST;
  pageName: string;
  isMobile = false;
  skipLink = '';
  titleListAnnoucePage = '';
  focusElement = focusElement;
  constructor(
    private activatedRoute: ActivatedRoute,
    public titleListAnalyticsService: TitleListAnalyticsService,
    public router: Router,
    public commonService: CommonService,
    public breadcrumbService: BreadcrumbService,
    public titleListService: TitleListService,
    public titleService: Title,
    public location: Location
  ) { }
  /**
   * on init
   */
  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
    this.titleListService.checkEmptySubjectItemsData$.subscribe(items => {
      if (items) {
        this.showErrorMsg = true;
      }
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.focusElement('loc_headerId');
      try {
        const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library'
        this.skipLink = `/ng${this.location.path(false)}#title_list_result`;
        if (params.subject === '') {
          this.selectedSubject = { param: '', type: TITLE_LIST.SUBJECT };
          this.parentRoute = TITLE_LIST.SUBJECT;
          this.titleListService.loadBrowseLists.next(true);
          this.breadcrumbs = [
            { text: homePage, url: HOME[homePage], isHref: true },
            {
              text: 'Browse',
              url: ``,
              isHref: true
            }
          ];
          this.breadcrumbService.set('@subject', 'Browse');
          this.titleService.setTitle('Browse');
          this.titleListAnnoucePage = 'Browse Title List page';
          document.getElementById('isBBSPage')?.removeAttribute('tabIndex');
          setTimeout(() => {
            this.commonService.toStopAnnoucedPage(false, false, false, false, true);
            cdkVisualHidden();
          }, 4000);
          return;
        } else if (params.subject && params.subject !== '') {
          this.breadcrumbs = [];
          const isAlwaysavailable = params.alwaysavailable ? false : !params.list;
          const loadBrowseLists = params.subject !== '' ? isAlwaysavailable : false;
          this.titleListService.loadBrowseLists.next(loadBrowseLists);
        } else {
          const isFilterList = params.list ? "Featured List" : params.listName;
          this.pageName = params.subject ? "Browse" : isFilterList;
          this.breadcrumbs = [
            { text: homePage, url: HOME[homePage], isHref: true },
            {
              text: this.pageName,
              url: ``,
              isHref: true
            }
          ];
          const isLoadBrowseLists = params.alwaysavailable ? false : !params.list;
          const loadBrowseLists = params.subject !== '' ? isLoadBrowseLists : false;
          this.titleListService.loadBrowseLists.next(loadBrowseLists);
        }
        const isBasedYourInterests = params.list === TITLE_LIST.BASED_ON_INTEREST ?
          { param: params.list, type: TITLE_LIST.BASED_ON_INTEREST } :
          { param: params.list, type: TITLE_LIST.LIST }
        const isSelectedSubject = params.list
          ? isBasedYourInterests
          : { param: params.alwaysavailable, type: TITLE_LIST.ALWAYSAVAILABLE }
        this.selectedSubject = params.subject
          ? { param: decodeURIComponent(params.subject), type: TITLE_LIST.SUBJECT }
          : isSelectedSubject;

        const isCheckList = params.list ? "Featured Lists " : params.listName;
        const isPageName = (params.list === TITLE_LIST.BASED_ON_INTEREST) ? BASED_ON_INTEREST
          : isCheckList;
        this.pageName = params.subject ? "Browse"
          : isPageName;
        this.titleService.setTitle(`${this.pageName} Title List page`);
        this.titleListAnnoucePage = `${this.pageName} Title List page`;
        document.getElementById('isBBSPage')?.removeAttribute('tabIndex');
        setTimeout(() => {
          this.commonService.toStopAnnoucedPage(false, false, false, false, true);
          cdkVisualHidden();
        }, 4000);
        const subjectSplit = this.selectedSubject.param.split('/');
        this.titleListService.selectedSubjectTitle = this.selectedSubject.param;
        this.titleListService.selectedSubject = subjectSplit[0];
        this.titleListService.subCategory = subjectSplit[1];
        this.titleListService.subCategoryLevel3 = subjectSplit[2];
        this.titleListService.isTitleDetail = strToBool(params?.isTitle) || false;
      } catch {
        this.commonService.showErrorAlert();
      }
    });
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
  ngAfterViewInit() {
    this.focusElement('loc_headerId');
  }
  setCategory(value) {
    const isCheckSelectedSubject = value
      ? { param: value, type: TITLE_LIST.LIST }
      : { param: value, type: TITLE_LIST.ALWAYSAVAILABLE }
    this.selectedSubject = value
      ? { param: decodeURIComponent(value), type: TITLE_LIST.SUBJECT }
      : isCheckSelectedSubject;
  }
  selectedId(id) {
    this.selectedCategoryId = id;
  }
  /**
   * Changes subject
   * @param value
   */
  changeSubject(value) {
    if (this.selectedSubject) {
      this.titleListAnalyticsService.trackScreen('TITLE_LIST');
      const breadcrumb = this.selectedSubject.param.split('/');
      const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
      value = this.selectedSubject.param == CURATED_LISTS.INTEREST_RECOMMENDATION.text ? CURATED_LISTS.INTEREST_RECOMMENDATION.title : value;
      if (this.selectedSubject.type === TITLE_LIST.LIST || this.selectedSubject.type === TITLE_LIST.ALWAYSAVAILABLE) {
        const isBreadCrumbs = (value === BASED_ON_INTEREST) ? BASED_ON_INTERESTS : decodeURIComponent(value);
        this.breadcrumbs = [
          { text: homePage, url: HOME[homePage], isHref: true },
          {

            text: this.selectedSubject.type === TITLE_LIST.LIST && value !== BASED_ON_INTEREST ? 'Featured List'
              : isBreadCrumbs,
            url: `/ng/view/title?${this.selectedSubject.type}=${breadcrumb[0].toLowerCase()}`,
            isHref: true
          }
        ];
        this.breadcrumbService.set('@subject', decodeURIComponent(value.toLowerCase()));
      } else if (this.selectedSubject.param !== '') {
        this.breadcrumbs = [
          { text: homePage, url: HOME[homePage], isHref: true },
          {
            text: decodeURIComponent(value.toLowerCase()),
            url: `/ng/view/title?${this.selectedSubject.type}=${breadcrumb[0].toLowerCase()}&isTitle=${this.titleListService.isTitleDetail}`,
            isHref: true
          }
        ];
      } else {
        this.breadcrumbs = [
          { text: homePage, url: HOME[homePage], isHref: true },
          {
            text: 'Browse',
            url: `/ng/view/title?subject=''`,
            isHref: true
          }
        ];
        this.breadcrumbService.set('@subject', 'Browse');
      }
      this.parentRoute = this.selectedSubject.type;
      /* if type = subject , value is empty  the subject should be "Browse"
         if type = list , value is not Based on intrest , the subject should be "Feature List"
         */
      const isCheckSelectedSubjectType = this.selectedSubject.type === TITLE_LIST.SUBJECT && value === '' ? 'Browse' : decodeURIComponent(value.toLowerCase())
      const isCheckSubject = value === BASED_ON_INTEREST ? BASED_ON_INTERESTS : isCheckSelectedSubjectType;
      let subject = this.selectedSubject.type === TITLE_LIST.LIST && value !== BASED_ON_INTEREST ? 'Featured List' : isCheckSubject;
      if (breadcrumb.length > 1 && !this.titleListService.refreshBreadcrumb) {
        this.breadcrumbs.push({
          text: decodeURIComponent(
            breadcrumb[1].toUpperCase() === TITLE_LIST.LGBTQ ? breadcrumb[1].toUpperCase() : breadcrumb[1].toLowerCase()
          ),
          url: `/ng/view/title?${this.selectedSubject.type}=${breadcrumb}&isTitle=${this.titleListService.isTitleDetail}`,
          isHref: true
        });
        const isSubject = decodeURIComponent(breadcrumb[1].toUpperCase() === TITLE_LIST.LGBTQ ? breadcrumb[1].toUpperCase() : breadcrumb[1].toLowerCase());
        subject = this.selectedSubject.type === TITLE_LIST.SUBJECT ? isSubject : 'Featured List'
      }
      this.breadcrumbService.set('@subject', subject);

    }
  }
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
