import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { HOME_REFINER } from '@shared/constants/app.constants';
import { MyStuffsDetail } from '@shared/data/shared.model';
import { forceRedirect, setFocusToText } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SharedService } from '@shared/services/shared.service';
import { ISubMenu, ISubMenuItem, SidenavService } from '@shared/services/sidenav.service';
import { UserService } from '@shared/services/user.service';
import { Subscription } from 'rxjs';
import { SIDE_NAV_URL, SIGNOUT_ALERT } from '../shared/constants/header.constants';
import { HeaderAnalyticsService } from '../shared/services/header.analytics.service';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
@Component({
  selector: 'axis360-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.scss']
})
export class OffcanvasComponent implements OnInit, AfterViewInit, OnDestroy {
  showSubMenu = false;
  selectedItem = '';
  isLoading = false;
  browseBySubject: ISubMenuItem[] = [];
  SIDE_NAV_URL = SIDE_NAV_URL;
  featuredList: ISubMenuItem[] = [];
  lblBrowseBySubject = 'Browse';
  lblFeaturedList = 'Featured Lists';
  openSubMenuItem: string;
  openSubMenuItemId: string;
  collection: ISubMenuItem[] = [];
  showFeaturedList = true;
  forceRedirect = forceRedirect;
  mystuffsdetail: MyStuffsDetail[];
  checkoutCount: string;
  holdCount: string;
  isBrowseListExpand = false;
  isFeatureListExpand = false;
  featureListIndex = 0;
  tabIndex = 0;
  refinerSubscription: Subscription;
  sideNavSubscription: Subscription;
  sideMenuListSubscription: Subscription;
  patronTechnicalSupportEnabled: boolean;
  constructor(
    public sidenavService: SidenavService,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public userService: UserService,
    private commonService: CommonService,
    private searchService: KzSearchService,
    public sharedService: SharedService,
    private headerAnalyticsService: HeaderAnalyticsService,
    private liveAnnouncer: LiveAnnouncer,
    private myShelfListService: MyShelfListService
  ) {}
  @ViewChildren('submenu') subMenuList;
  @ViewChild('loc_linkBrowserBySubject') linkBrowserBySubject;
  @ViewChild('loc_linkFeaturedLists') linkFeaturedLists;

  ngAfterViewInit(): void {
    document.addEventListener('keydown', (e: any) => this.listenSubmenu(e), false);
    this.sideMenuListSubscription = this.subMenuList.changes.subscribe(() => {
      if (this.subMenuList.length > 1) {
        this.subMenuList.first.nativeElement.focus();
        this.focus();
      }
    });
  }

  /**
   * on init
   */
  ngOnInit(): void {
    this.sideNavSubscription = this.sidenavService.sidenavAction.subscribe((action) => {
      this.showSubMenu = false;
      if (action === 'closeSubMenu') {
        this.selectedItem = '';
        this.isFeatureListExpand = false;
        this.isBrowseListExpand = false;
      }
    });
    this.profileInfoService.getProfileInfo.subscribe((profileInfo) => {
      if (profileInfo.profileType !== '') {
        const { patronTechnicalSupportEnabled } = this.configService.getProfileFeatures(profileInfo.profileType.toLocaleLowerCase());
        this.patronTechnicalSupportEnabled = patronTechnicalSupportEnabled || false;
        return;
      }
      // this.patronTechnicalSupportEnabled = true;
    });

  }

  onCloseFocus() {
    setTimeout(() => {
      const closeButton = document.getElementById('menuClosebtn');
      closeButton?.removeAttribute('aria-expanded');
    },2000);
  }

  /**
   * Trigger action on the MenuBarEvent Track
   * @param [action] specifies the type of action_event
   */
  trackByMenuBarEvent(action: any) {
    this.selectedItem = this.isMobile() ? '' : this.selectedItem;
    this.headerAnalyticsService.trackMenuEvent(action);

    if (action) {
      const announceMessage = this.getAnnounceMessage(action);
      setTimeout(() => {
        this.liveAnnouncer.announce(announceMessage, 'assertive');
      }, 300);
    }
  }

  getAnnounceMessage(action) {
    let message = '';
    switch (action) {
      case 'VIEW_MY_SHELF':
        message = 'My Shelf Page';
        break;
      case 'VIEW_LIBRARY':
        // message = 'My Library page';
        break;
      case 'VIEW_PROGRAMS':
        message = 'Program page';
        break;
      case 'VIEW_CHECKOUTS':
        message = 'Checkout page';
        break;
      case 'VIEW_HOLDS':
        message = 'Hold page';
        break;
      case 'VIEW_BROWSE':
        // message = 'Browse page';
        break;
    }
    return message;
  }

  /**
   * Trigger action on the sidenav
   * @param [action] specifies the type of action
   */
  sidebarAction(action = '') {
    this.sidenavService.sidenavAction.next(action);
    this.sidenavService.sidenavAction.next('closeSubMenu');
    this.searchService.updatedSearchText.next('');
    this.commonService.isAnnouncedPage = false;
    const closeButton = document.getElementById('menuClosebtn');
    closeButton?.setAttribute('aria-expanded', 'true');
    this.sidenavService.isHamburgerExpanded = false;
    sessionStorage.setItem('titleListFilters', JSON.stringify({}));
    if (action === 'close') {
      const alertElement = document.getElementById('loc_title_list_count');
      const searchResultsViewCount = document.getElementById('searchResultsViewCount');
      const searchResultsView = document.getElementById('searchResultsView');
      const searchResultsList = document.getElementById('searchResultsList');
      const searchResultsThirdParty = document.getElementById('searchResultsThirdParty');
      const searchCarouselTitleCount = document.getElementById('searchCarouselTitleCount');
      const resultsCountReourceHub = document.getElementById('resultsCountReourceHub');
      const resultsCountWPE = document.getElementById('resultsCountWPE');
      const tier2HeadingCount = document.getElementById('tier2-heading-count');
      const searchResultsResouceHub = document.getElementById('searchResultsResouceHub');
      const searchZeroResults = document.getElementById('zeroResult');
      const searchNoResults = document.getElementById('noResultText');
      const searchNoResultsTxt = document.getElementById('no-result-text');
      
      alertElement?.removeAttribute('role');
      searchResultsViewCount?.removeAttribute('role');
      searchResultsView?.removeAttribute('role');
      searchResultsList?.removeAttribute('role');
      searchResultsThirdParty?.removeAttribute('role');
      searchCarouselTitleCount?.removeAttribute('role');
      resultsCountReourceHub?.removeAttribute('role');
      resultsCountWPE?.removeAttribute('role');
      tier2HeadingCount?.removeAttribute('role');
      searchResultsResouceHub?.removeAttribute('role');
      searchZeroResults?.removeAttribute('role');
      searchNoResults?.removeAttribute('role');
      searchNoResultsTxt?.removeAttribute('role');
    }
  }

  /**
   * Used to return if the viewport is mobile size
   */
  isMobile() {
    return window.innerWidth < 560;
  }

  cdkAddHiddenHeaderId() {
    const elementToHide = document.querySelector('.cdk-live-announcer-element.cdk-visually-hidden');
    if (elementToHide) {
      const locHeaderId = document.getElementById('loc_headerId');
      locHeaderId && locHeaderId.setAttribute('hidden', '');
    }
  }

  /**
   * Get data from api and show on the sub menu
   * @param [item] used to set active class on level 1 menu
   */
  getSubMenuandShow(item: string) {
    this.selectedItem = item;
    this.showSubMenu = false;
    this.collection = [];
    setTimeout(() => {
      this.showSubMenu = true;
    }, 1000);
    if (item === this.lblBrowseBySubject) {
      this.getBrowseBySubject();
      this.headerAnalyticsService.trackMenuEvent('VIEW_BROWSE_BY_SUBJECT');
      this.openSubMenuItem = 'Browse By Subject';
      this.openSubMenuItemId = 'browse-by-subject';
      this.isBrowseListExpand = true;
      this.isFeatureListExpand = false;
      this.featureListIndex = -1;
      this.tabIndex = -1;
    } else if (item === this.lblFeaturedList) {
      this.getFeaturedList();
      this.headerAnalyticsService.trackMenuEvent('VIEW_FEATURED_LISTS');
      this.openSubMenuItem = 'Featured Lists';
      this.openSubMenuItemId = 'featured-lists';
      this.isBrowseListExpand = false;
      this.isFeatureListExpand = true;
      this.featureListIndex = 0;
      sessionStorage.setItem('titleListFilters', JSON.stringify({}));
      this.tabIndex = -1;
    }
    this.focus();
  }

  trackBySubMenuItems(subMenuItems: string) {
    this.headerAnalyticsService.trackMenuEvent('VIEW_SUB_LISTS', { subMenuItemsNames: this.openSubMenuItem + '-' + subMenuItems });
  }

  getBrowseBySubject() {
    if (this.browseBySubject.length) {
      this.collection = this.browseBySubject;
      return;
    }
    this.isLoading = true;
    this.sidenavService.getSideNav('browseBySubject').subscribe((subMenuItems: ISubMenu) => {
      this.isLoading = false;
      this.browseBySubject = subMenuItems.items;
      this.collection = this.browseBySubject;
    });
  }

  listenSubmenu(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeSideNav(event);
    }
    const lastElm = document.querySelector('#main-menu li:last-child a');
    if (lastElm === document.activeElement) {
      setFocusToText('menuClosebtn');
    }

    if (document.getElementById('loc_linkFeaturedLists')) {
      document.getElementById('loc_linkFeaturedLists').addEventListener('keyup', this.tabIndexChange.bind(this, 'featurelist'), false);
    }
    if (!event.shiftKey && event.key === 'Tab') {
      if (this.featuredList.length) {
        const elem = document.getElementById(
          'loc_txt' + this.featuredList[this.featuredList.length - 1].text.toLowerCase().replace(/ /g, '_')
        );
        if (elem === document.activeElement) {
          this.focusNextMainMenu('loc_linkFeaturedLists', event);
        }
      }
    }
    if (event.shiftKey && event.key === 'Tab') {
      if (this.featuredList.length) {
        const elem = document.getElementById('loc_txt' + this.featuredList[0].text.toLowerCase().replace(/ /g, '_'));
        if (elem === document.activeElement) {
          this.showSubMenu = false;
          setTimeout(() => {
            this.isFeatureListExpand = false;
          }, 1000);
        }
      }
    }
  }
  closeSideNav(event: KeyboardEvent) {
    if (!this.showSubMenu) {
      this.sidenavService.sidenavAction.next('close');
    } else if (this.isFeatureListExpand) {
      this.focusNextMainMenu('loc_linkFeaturedLists', event);
    }
  }

  focusNextMainMenu(id: string, e: KeyboardEvent) {
    this.featureListIndex = 0;
    this.tabIndex = 0;
    this.showSubMenu = false;
    if (id === 'loc_linkFeaturedLists') {
      this.linkFeaturedLists.nativeElement.focus();
      setTimeout(() => {
        this.isFeatureListExpand = false;
      },1000)
      e.preventDefault();
    }
  }
  tabIndexChange(val: string, e: KeyboardEvent) {
    const key = e.key;
    if (key === 'Enter') {
      if (val === 'featurelist') {
        this.featureListIndex = 0;
        this.tabIndex = -1;
      } else {
        this.featureListIndex = -1;
        this.tabIndex = -1;
      }
    } else if (key === 'Tab') {
      this.featureListIndex = 0;
      this.tabIndex = 0;
    }
  }
  getFeaturedList() {
    if (this.featuredList.length) {
      this.collection = this.featuredList;
      return;
    }
    this.isLoading = true;
    this.sidenavService.getSideNav('featuredList').subscribe(async (subMenuItems: ISubMenu) => {
      if (subMenuItems.message !== 'Success') {
        return '';
      }
      const libraryList = await this.myShelfListService.getMyLibraryList();
      this.isLoading = false;
      if(libraryList) {
        const featuredlist = subMenuItems.libraryComponents.components.filter((list) => list.entityType === 'FEATURED_LIST' && libraryList?.find(liblist => liblist?.ListID === list?.entityId));
        const curatedlist = subMenuItems.libraryComponents.components.filter((list) => list.entityType === 'CURATED_LIST');
        this.featuredList = [...featuredlist, ...curatedlist].map((item) => {
          return { text: item.title, value: item.entityId };
        });
        this.collection = this.featuredList;
        return;
      }
      const featuredlist = subMenuItems.libraryComponents.components.filter((list) => list.entityType === 'FEATURED_LIST');
      const curatedlist = subMenuItems.libraryComponents.components.filter((list) => list.entityType === 'CURATED_LIST');
      this.featuredList = [...featuredlist, ...curatedlist].map((item) => {
        return { text: item.title, value: item.entityId };
      });
      this.collection = this.featuredList;
    });
  }

  /**
   * To redirect to home after signout
   */
  handleSignOut = () => {
    sessionStorage.removeItem(HOME_REFINER.AVAILABILITY);
    sessionStorage.removeItem(HOME_REFINER.FORMAT);
    this.forceRedirect(SIDE_NAV_URL.SIGNOUT);
  };
  /**
   * To show the signout popup
   */
  signOut() {
    this.headerAnalyticsService.trackMenuEvent('VIEW_SIGNOUT');
    this.commonService.showAlertDialog({
      ...SIGNOUT_ALERT,
      submitBtnFunc: this.handleSignOut
    });
  }
  /**
   * Focus offcanvas component
   */
  focus() {
    if (this.collection.length > 0 && this.collection[0]?.text) {
      document.getElementById('loc_txt' + this.collection[0].text?.replace(/ /g, '_').toLowerCase())?.focus();
    }
  }
  /**
   * on destroy
   */
  ngOnDestroy() {
    this.refinerSubscription && this.refinerSubscription.unsubscribe();
    this.sideMenuListSubscription && this.sideMenuListSubscription.unsubscribe();
    this.sideNavSubscription && this.sideNavSubscription.unsubscribe();
  }
}
