<footer class="container-fluid kz-footer" role="contentinfo">
    <nav class="footer-nav">
        <ul class="menu-navigation" *ngIf="!hideHeaderFooter">
            <li class="menu-item">
                <a class="menu-link" href="javascript:void(0)" (click)="navigateToHome()" id="home">Home</a>
            </li>
            <li class="menu-item">
                <a class="menu-link" href="/ng/view/terms?type=privacy">Privacy Policy</a>
            </li>
            <li class="menu-item">
                <a class="menu-link" href="/ng/view/terms?type=terms">Terms & Conditions</a>
            </li>
        </ul>
    </nav>
    <div class="footer-info-txt kz-custom-container-secondary" *ngIf="!hideHeaderFooter" [innerHTML]="configService.currentLibrary.branding?.leftBranding"></div>
    <div class="footer-brand-logo" role="img" aria-label="boundless by baker & taylor">
        <img class="img-responsive" src="/angular-app/assets/images/boundless_logo-primary.png" alt="boundless by baker & taylor" aria-hidden="true"/>
    </div>
    <div class="footer-copy" role="text" aria-label="Copyright 2023 Baker & Taylor, LLC. All Rights Reserved."><span aria-hidden="true">Copyright &copy; 2023 Baker & Taylor, LLC. All Rights Reserved.</span></div>

</footer>