<axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>

<div class="interest-survey" *ngIf="!isLoading && interests.length>0">
    <div class="bread-crum-main">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs"></axis360-breadcrumb>
    </div>
    <div class="interst-title">
        <h1 class="intrest-setpref">Set Your Reading Preferences</h1>
        <p class="intrest-notes">Preferences you select here will be used to recommend titles you may want to
            read.</p>
        <p class="intrest-notes"> You can change these at any time in your Account.</p>
    </div>
    <div class="intrst-main-survy">
        <ng-container>
            <ng-container *ngFor="let interest of interests; let i = index">
                <div role="button" class="interst-cardbtn" tabindex="0" *ngIf="interest.imageUrl"
                    [ngClass]="{'intst-active': interest.selected}"
                    attr.aria-label="{{interest.interestName}} {{interest.selected ? 'selected':'unselected'}}"
                    (click)="selectInterest(i)" (keyup.enter)="selectInterest(i)">
                    <img class="intst-img" alt="" [src]="interest.imageUrl[interest.imageUrl.length - 1]" />
                    <div class="intst-txt-sec">
                        <p aria-hidden="true" class="intst-nme">{{interest.interestName}}</p>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="interst-filter-sec" *ngIf="audience.length > 0">
        <div class="interst-filter-main">
            <div class="interst-filter-col">
                <h2 class="filter-title" id="audience-heading">PREFERRED AGE LEVEL (SELECT ONE OR MORE):</h2>
                <div class="interst-filter-check">
                    <div class="filter-list" role="group" aria-labelledby="audience-heading">
                        <mat-checkbox (change)="checkAudience($event.checked, i)" class="filter-checkbox-list"
                            *ngFor="let aud of audience; let i = index;" [attr.aria-checked]="aud.checked"
                            [checked]="aud.checked">{{aud.audienceLevelName}}</mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="interst-save-btn">
        <button class="save-btn" (keyup.enter)="saveInterests(true, redirectToPrevPage)"
            (click)="saveInterests(true, redirectToPrevPage)">Save Preferences</button>
        <button class="save-btn back-btn" aria-label="Cancel" (click)="redirectToPrevPage()"
            (keyup.enter)="redirectToPrevPage()">
            Cancel
        </button>
    </div>
</div>
