import { Component, Input, OnInit } from '@angular/core';
import { ProgramsTitleComponent } from '@readingprograms/programs/shared/components/programs-title/programs-title.component';
import { strToBool } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { KzProgramsService } from '../../service/kz-programs.service';
import { BookDetail } from '@shared/data/engage-base.model';

@Component({
  selector: 'axis360-kz-programs-title',
  templateUrl: './kz-programs-title.component.html',
  styleUrls: ['./kz-programs-title.component.scss']
})
export class KzProgramsTitleComponent extends ProgramsTitleComponent implements OnInit {
  @Input() programName: string;
  @Input() isDeleteFlag: boolean;
  @Input() isParticipant: boolean;
  showPercentageTracking = false;
  showInsightsAndBadges = false;
  parentRoute: string;
  @Input() programType: string;
  @Input() programDetails: any;
  @Input() isProgramDetail: boolean;
  @Input() suggestedBooks: BookDetail[]
  @Input() seeAll: boolean;
  programId: any;
  constructor(
    public breadcrumbService: BreadcrumbService,
    public profileInfoService: ProfileInfoService,
    public userService: UserService,
    public configService: ConfigService,
    private KzProgramsService: KzProgramsService
  ) {
    super(userService, configService, profileInfoService);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.isBooksInOrder = this.programDetails?.isOrder;
    this.programStatus = this.programDetails?.statusText;
    this.programStart = this.programDetails?.programStart;
    this.isParticipant = this.programDetails?.isParticipant;
    this.programId = this.programDetails?.id
    this.parentRoute = 'programs/' + this.programDetails?.id;
    this.breadcrumbService.set('@programName', this.programDetails?.name);
    this.KzProgramsService.programType.next(this.programDetails?.type);
    const profileFeatures = this.configService.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase()) || {};
    this.showInsightsAndBadges = profileFeatures.insightsBadgesEnabled;
    this.showPercentageTracking =
      strToBool(this.profileInfoService.currentProfile.enableTracking) && this.isParticipant && this.showInsightsAndBadges;
  }
  titleMarkAsReadEventData(event) {
    if (event === 'btnMarkAsDone') {
      this.bookDetail.info.percentageComplete = 0;
      this.bookDetail.percentageComplete = 0;
      this.showPercentage = false;
    }
    if (event === 'btnMarkAsNotDone') {
      this.bookDetail.info.percentageComplete = 100;
      this.bookDetail.percentageComplete = 100;
      this.showPercentage = true;
    }
    this.titleMarkAsReadEvent.emit(event);
  }
}
