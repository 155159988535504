<div class="kz-title-carousel" *ngIf="titleCardsInfo && titleCardsInfo.length > 0">
    <div class="kz-carousel">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="cellsToScroll" [margin]="cellMargin"
            [cellWidth]="cellWidth" (click)="trackCarousel($event)" [height]="cellHeight">
            <div class="carousel-cell" role="listitem" *ngFor="let titleCards of titleCardsInfo; let i = index">
                <axis360-kz-web-resources-card [webResourceCardInfo]="titleCards" [index]="i"
                    [webResourcesCarouselClass]="'carousel-card'">
                </axis360-kz-web-resources-card>
            </div>
        </carousel>
    </div>
</div>