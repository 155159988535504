<mat-card class="kz-newspaper-card" *ngIf="publicationCard" (click)="cardClick(type, title)"
    (keyup.enter)="cardClick(type, title)" tabindex="0" role="link">
    <div class="kz-card-image">
        <a href="javascript:;" tabindex="-1">
            <img class="card-image" mat-card-image [src]="publicationCard.imageUrl" alt="">
        </a>
    </div>
    <mat-card-content>
        <p class="kz-card-date">{{getDate(publicationCard.issueDate) | date:'d MMM yyyy'}}</p>
        <p class="kz-card-country single-ellipsis" title="{{publicationCard.multiCountry}}">
            {{publicationCard.multiCountry}}</p>
        <h3 class="kz-card-title single-ellipsis" title="{{publicationCard.displayName}}">
            {{publicationCard.displayName}}</h3>
        <button class="primaryBtn" (click)="$event.stopPropagation(); readNow($event, publicationCard.redirectionUrl);"
            (keyup.enter)="$event.stopPropagation(); readNow($event, publicationCard.redirectionUrl);">Read Now</button>
    </mat-card-content>
</mat-card>