<div mat-dialog-title class="message-center" cdkTrapFocus>
    <div *ngIf="overlayLoading" class="loading mc-loading"></div>
    <div class="msg-header">
        <button id="msg-close-btn" class="msg-close" role="button" type="button" aria-label="close"
            (click)="closeDialog()" (keydown.enter)="closeDialog()" cdkFocusInitial>
            <mat-icon class="material-icons">close</mat-icon>
        </button>
        <h1 class="msg-title" id="notification-title">Notifications</h1>
        <div class="msg-sec-row">
            <div class="msg-check-all">
                <mat-checkbox class="msg-read-all" id="msgReadAll" aria-label="Select all notifications"
                    (change)="selectAllCheckBox($event.checked)" role="none" [(ngModel)]="isAllChecked"
                    (keydown.enter)="selectAllCheckBox(!isAllChecked)"></mat-checkbox>
                <button aria-haspopup="false" title="Select menu" aria-label="Select menu" class="msg-all-btn"
                    mat-icon-button [matMenuTriggerFor]="selectMenuAction" aria-expanded="false"
                    (click)="openMenu($event)" (menuOpened)="menuStateChange(true)"
                    (menuClosed)="menuStateChange(false)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu #selectMenuAction="matMenu" class="msg-select-pane" xPosition="before">
                    <button mat-menu-item class="msgread" (click)="doMsgCenterAction(option.value)"
                        *ngFor="let option of menuOptions" [attr.aria-label]="option.viewValue"
                        [attr.aria-describedby]="option.viewValue">
                        <span>{{option.viewValue}}</span>
                        <span class="visually-hidden" [id]="option.viewValue">{{option.ariaDescription}}</span>
                    </button>
                </mat-menu>
            </div>

            <div class="msg-delete">
                <span [hidden]="messageIdList.length === 0">
                    <mat-icon role="button" svgIcon="DELETE_IMAGE" id="msg-delete-all" tabindex="0" aria-label="Delete"
                        aria-describedby="info" (click)="openDeletePopup(messageIdList.length)"
                        (keydown.enter)="openDeletePopup(messageIdList.length)" aria-hidden="false"></mat-icon>
                    <p id="info">Selected notifications will be deleted</p>
                </span>
                <div class="msg-more-opt">
                    <button class="more_opt" id="moreoptionmenu" aria-haspopup="false" title="More menu"
                        aria-label="More menu" mat-icon-button [matMenuTriggerFor]="menuAction" aria-expanded="false"
                        (click)="openMoreMenu()" *ngIf="messageIdList.length > 0" (menuOpened)="menuStateChange(true)"
                        (menuClosed)="menuStateChange(false)">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menuAction="matMenu" class="msg-mark-read">
                        <button mat-menu-item class="msgread" (click)="doActionsOnMsgs(MSG_STATUS.READ)"
                            aria-label="Mark as Read" aria-describedby="mark-as-read">
                            <span>Mark as Read</span><span class="visually-hidden" id="mark-as-read">Selected messages
                                will mark as read</span>
                        </button>
                        <button mat-menu-item class="msgread" (click)="doActionsOnMsgs(MSG_STATUS.UNREAD)"
                            aria-label="Mark as Unread" aria-describedby="mark-as-unread">
                            <span>Mark as Unread</span><span class="visually-hidden" id="mark-as-unread">Selected
                                messages will mark as unread</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="msg-body">
        <div class="msg-notify-box">
            <mat-accordion class="message-center-accordion" *ngIf="messages.length > 0">
                <ng-container *ngFor="let message of messages; let i = index">
                    <mat-checkbox tabindex="0" #checkbox aria-label="{{message.status}} notification"
                        class="msg-read-all" [attr.aria-checked]="isMsgChecked(message.messageId)"
                        [checked]="isMsgChecked(message.messageId)"
                        (change)="checkedMessage($event.checked, message.messageId)"
                        (keydown.enter)="checkedMessage(isMsgChecked(message.messageId), message.messageId)"
                        *ngIf="!expandedMsgs.has(message.messageId)">
                    </mat-checkbox>
                    <mat-expansion-panel (opened)="openDetails(message)" (closed)="closeDetails(message)">
                        <mat-expansion-panel-header axis360RemoveTabIndex>
                            <mat-panel-title tabindex="0">
                                <div class="content-assign"
                                    [ngClass]="{'content-read': message.status == MSG_STATUS.READ}">
                                    <div class="content-name-head">
                                        <h2>
                                            {{message.title}}</h2>
                                        <span>{{message.dateTimeStr}}</span>
                                    </div>
                                    <p>{{message.description}}</p>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <hr tabindex="-1" aria-hidden="true">
                        <div class="accordion-content">
                            <axis360-spinner-loader [isLoading]="true" *ngIf="isLoadingRP"></axis360-spinner-loader>
                            <div class="accordion-msg" *ngIf="msgData">
                                <h3>{{msgData.name}}</h3>
                                <p>{{msgData.description}}</p>
                                <h4 class="mc-date-read" *ngIf="msgData.startDate">Program Starts</h4>
                                <p>{{msgData.startDate}}</p>
                                <h4 class="mc-date-read" *ngIf="msgData.readByDate">Read By</h4>
                                <p class="read-by-date">{{msgData.readByDate}}</p>
                            </div>
                            <div class="accordion-title-msg control-img" *ngIf="msgData && msgData.detailsLink && msgData.text">
                                <button class="view-program" id="go-to-program" name="Go_To_Program" role="link" 
                                    aria-label="Go To Program" aria-pressed="false" (click)="viewDetails()"
                                    (keydown.enter)="viewDetails()">{{msgData.text}}
                                </button>
                            </div>
                            <div class="mark-unread">
                                <div class="add-date">
                                    <div class="add-titles">
                                        <div class="invite-other">
                                            <a href="javascript:void(0);"
                                                (click)="updateMessage(message.messageId, MSG_STATUS.UNREAD)"
                                                id="mark_asread" role="button" aria-label="mark as unread"
                                                (keydown.enter)="updateMessage(message.messageId, MSG_STATUS.UNREAD)">MARK
                                                AS
                                                UNREAD <span>
                                                    <mat-icon svgIcon="UNREAD_IMAGE"></mat-icon>
                                                </span></a>
                                        </div>
                                        <div class="invite-other">
                                            <a id="delete-msg-single" href="javascript:void(0);"
                                                (click)="openDeletePopup(0, message.messageId)" aria-haspopup="false"
                                                (keydown.enter)="openDeletePopup(0, message.messageId)"
                                                aria-label="Delete" role="button" tabindex="0">DELETE
                                                <span>
                                                    <mat-icon svgIcon="DELETE_IMAGE"></mat-icon>
                                                </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
                <axis360-spinner-loader [isLoading]="true" *ngIf="this.pageIndex > 1 && isLoading">
                </axis360-spinner-loader>
            </mat-accordion>
            <div class="no-message" *ngIf="messages.length === 0">
                <p *ngIf="!isLoading">No notifications available</p>
                <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
            </div>
        </div>
    </div>
</div>