import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyStuffRoutingModule } from './my-stuff-routing.module';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { SharedModule } from '@shared/shared.module';
import { MyStuffGridCardComponent } from './my-stuff-titles/my-stuff-grid-card/my-stuff-grid-card.component';
import { MyStuffListCardComponent } from './my-stuff-titles/my-stuff-list-card/my-stuff-list-card.component';
import { MyStuffComponent } from './my-stuff.component';
import { MatCardModule } from '@angular/material/card';
import { MyStuffTitlesMainComponent } from './my-stuff-titles/my-stuff-titles-main.component';
import { SidebarMenuComponent } from 'app/my-stuff/shared/components/sidebar-menu/sidebar-menu.component';
import { MyStuffTitleComponent } from './my-stuff-titles/my-stuff-title/my-stuff-title.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    MyStuffTitleComponent,
    MyStuffGridCardComponent,
    MyStuffListCardComponent,
    MyStuffComponent,
    MyStuffTitlesMainComponent,
    SidebarMenuComponent
  ],
  imports: [
    CommonModule,
    MyStuffRoutingModule,
    MatCardModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CodeInputModule,
    MatTooltipModule,
    SharedModule
  ],
  providers: []
})
export class MyStuffModule {}
