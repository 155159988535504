import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SearchRefinersComponent } from '@shared/components/search-refiners/search-refiners.component';
import { ALL_REFINERS, AUDIENCE_KID, HOME } from '@shared/constants/app.constants';
import { windowToken } from '@shared/constants/di-tokens';
import { ILearningActivity, ILearningActivityResponse } from '@shared/data/learning-activity.model';
import { cdkRemoveVisualHidden, cdkVisualHidden, deepClone, focusElement } from '@shared/helper/app.util';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { LearningActivitiesService } from '@shared/services/learning-activities.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { Subscription } from 'rxjs';
import { KzLearningActivitiesAnalyticsService } from './kz-learning-activities-analytics.service';
import { AUDIENCE, KID } from '@shared/constants/search.constant';
import { Location } from '@angular/common';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-kz-learning-activities',
  templateUrl: './kz-learning-activities.component.html',
  styleUrls: ['./kz-learning-activities.component.scss']
})
export class KzLearningActivitiesComponent implements OnInit, OnDestroy, AfterViewInit {
  filterOpened = false;
  refiners: { title: string; subOptions: any[]; key: string; selectedItem: string[] }[] = [];
  learningActivities: ILearningActivity[] = [];
  isLoading = true;
  defaultFilterOptions = [];
  centerTab = true;
  skipLink = '';
  selectedOptions = {
    sortBy: ['Relevance'],
    activityTypes: [ALL_REFINERS],
    audiences: [],
    publishers: [ALL_REFINERS],
    relatedTitle: [ALL_REFINERS],
    defaulter: false
  };
  isInitialLoad = true;
  scrollSubscription: Subscription;
  totalCount = 0;
  pageNumber = 1;
  isLoadingMore = false;
  activityCategory = '';
  defaultRefiner = {
    sortBy: 'Relevance',
    activityType: 'Activity',
    audience: ALL_REFINERS,
    pageNumber: 1,
    pageSize: 10,
    ProfileType: this.profileService.currentProfile.profileType,
    publishers: 'All',
    relatedTitle: 'All'
  };

  refinerMap = {
    sortBy: 'Sort By',
    activityTypes: 'Resource Type',
    audiences: 'Audience Level',
    publishers: 'Providers',
    relatedTitle: 'Related Title'
  };

  requestMap = {
    sortBy: 'sort By',
    activityTypes: 'category',
    audiences: 'audience'
  };

  eventMap = {
    sortBy: 'VIEW_SORT_BY',
    activityTypes: 'VIEW_ACTIVITY_TYPE',
    audiences: 'VIEW_AUDIENCE_LEVEL'
  };
  appliedFilterCount = 0;
  breadcrumbs: ({ text: string; url: string; isHref: boolean } | { text: string; url: string; isHref?: boolean })[];
  isClearAll: boolean = false;
  pageAnnounce = '';
  constructor(
    public liveAnnouncer: LiveAnnouncer,
    public matDialog: MatDialog,
    private learningActivitiesService: LearningActivitiesService,
    private infiniteScroll: InfiniteScrollService,
    public learningActivityAnalyticsService: KzLearningActivitiesAnalyticsService,
    private route: ActivatedRoute,
    private profileService: ProfileInfoService,
    public kzSearchService: KzSearchService,
    public location: Location,
    public commonService: CommonService,
    @Inject(windowToken) private window: Window
  ) { }
  /**
   * oninit
   */
  ngOnInit(): void {
    const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
    this.breadcrumbs = [
      { text: homePage, url: HOME[homePage], isHref: true },
      { text: 'Resource Hub', url: '/view/learning-activity' }
    ];
    document.title = 'Resource Hub';
    this.pageAnnounce = 'Resource Hub Page';
    this.commonService.isAnnouncedPage = true;
    this.activityCategory = this.route.snapshot.queryParams.category;
    const refiner = this.activityCategory ? { ...this.defaultRefiner, activityType: this.activityCategory } : this.defaultRefiner;
    this.kzSearchService.selectedCategory = 'All';
    this.getLearningActivities(refiner);
    this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
    this.skipLink = `/ng${this.location.path(false)}#resourceHubContainer`;
  }
  ngAfterViewInit() {
    focusElement('loc_headerId');
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage(false, false, false, true);
    }, 4000);
  }

  loadMore = () => {
    if (this.canLoadMore()) {
      this.isLoadingMore = true;
      this.pageNumber += 1;
      const refiner = this.getRefinerObject();
      this.learningActivityAnalyticsService.trackLearningActivityEvent('REFINE_CTA');
      this.getLearningActivities({ ...refiner, pageNumber: this.pageNumber });
    }
  };

  canLoadMore = (): boolean => !this.isLoadingMore && this.totalCount > this.learningActivities.length;
  getSum() {
    return this.selectedOptions.activityTypes.length + this.selectedOptions.audiences.length + 1;
  }
  openFilter() {
    if (this.filterOpened) {
      return;
    }
    this.filterOpened = !this.filterOpened;
    const searchRefinersDialog = this.matDialog.open(SearchRefinersComponent, {
      panelClass: 'filter-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'filter-title',
      data: {
        refiners: deepClone(this.refiners),
        learningActivityRefiners: deepClone(this.refiners),
        isLearningActivity: true,
        showAccordion: true
      }
    });
    searchRefinersDialog.afterClosed().subscribe((result) => {
      this.filterOpened = false;
      if (result?.length) {
        this.refiners = deepClone(result);
        const refineObj = { sortBy: [], audiences: [], activityTypes: [], publishers: [], relatedTitle: [] };
        result.forEach((ele) => {
          refineObj[ele.key] = ele.selectedItem;
        });
        const isRefAudiences = refineObj.audiences.indexOf(ALL_REFINERS) !== -1
          ? ALL_REFINERS
          : result[2].selectedItem.toString();
        const refiner = {
          ...this.defaultRefiner,
          sortBy: refineObj.sortBy.toString(),
          activityType: refineObj.activityTypes.length ? refineObj.activityTypes.toString() : ALL_REFINERS,
          audience: refineObj.audiences.length
            ? isRefAudiences : ALL_REFINERS
        };
        this.selectedOptions.sortBy = [refiner.sortBy];
        this.selectedOptions.activityTypes = refiner.activityType ? refiner.activityType.split(',') : [];
        this.selectedOptions.audiences = refiner.audience && refiner.audience !== ALL_REFINERS ? refiner.audience.split(',') : [];
        this.selectedOptions.publishers = refiner.publishers && refiner.publishers !== ALL_REFINERS ? refiner.publishers.split(',') : [];
        this.selectedOptions.relatedTitle = refiner.relatedTitle && refiner.relatedTitle !== ALL_REFINERS ? refiner.relatedTitle.split(',') : [];
        this.selectedOptions.defaulter = false;
        this.selectedOptions = { ...this.selectedOptions };
        this.appliedFilterCount = this.getSum();
        this.learningActivities = [];
        this.getLearningActivities(refiner);
        return;
      }
      if (result) {
        result.clearFilter ? this.resetLearningActivityRefiners(result.clearFilter) : this.updateSelectedOption(result);
      }
    });
  }
  /**
   * Close dialog on window resize
   */
  closeFilter(): void {
    if (window.innerWidth > 768 && this.filterOpened) {
      this.matDialog.closeAll();
      this.filterOpened = false;
    }
  }

  getLearningActivities(refiner) {
    this.isLoading = true;
    this.infiniteScroll.isDataRefreshed = true;
    this.learningActivitiesService.getLearningActivities(refiner).then((res: ILearningActivityResponse) => {
      const response = res.learningActivity;
      this.isLoading = false;
      this.learningActivities.push(...response.data);
      this.totalCount = response.totalCount;
      this.isLoadingMore = false;
      setTimeout(() => {
        this.kzSearchService.setDisableCheckBox.next(true);
      }, 2000);
      if (this.isClearAll && this.learningActivities) {
        setTimeout(() => {
          document.getElementById(`title_${this.learningActivities[0].id}`)?.focus();
          this.isClearAll = false;
        }, 2000)
      }
      if (this.isInitialLoad) {
        Object.keys(response.filter).forEach((key, index) => {
          const tempRefiner = { title: '', subOptions: [], key: '', selectedItem: [] };
          tempRefiner.title = this.refinerMap[key];
          tempRefiner.key = key;
          tempRefiner.subOptions = response.filter[key];
          response.defaultFilterOptions[0] = "Relevance";
          if (this.activityCategory === 'All') {
            if (key !== 'sortBy') {
              tempRefiner.selectedItem = [response.defaultFilterOptions[index]];
              this.selectedOptions[key] = [];
            } else {
              if (tempRefiner.title && tempRefiner.title !== '') {
                tempRefiner.selectedItem = [response.defaultFilterOptions[index]];
                this.selectedOptions[key] = [response.defaultFilterOptions[index]];
              }
            }
          } else {
            if (key === 'activityTypes') {
              this.selectedOptions[key] = [this.activityCategory];
              tempRefiner.selectedItem = [this.activityCategory];
            } else if (key === 'audiences') {
              const item = response.defaultFilterOptions[index];
              this.selectedOptions[key] = item.includes('All') ? [item] : [item];
              tempRefiner.selectedItem = item.includes('All') ? [item] : [item];
            } else {
              if (tempRefiner.title && tempRefiner.title !== '') {
                tempRefiner.selectedItem = [response.defaultFilterOptions[index]];
                this.selectedOptions[key] = [response.defaultFilterOptions[index]];
              }
            }
          }
          this.appliedFilterCount = this.getSum();
          (tempRefiner.title && tempRefiner.title !== '') && this.refiners.push(tempRefiner);
          this.selectedOptions.defaulter = true;
        });
        this.isInitialLoad = false;
        focusElement('loc_headerId');

      }
    });
  }

  getRefinerObject() {
    const isAudiencesOptions = this.selectedOptions.audiences.indexOf(ALL_REFINERS) !== -1
      ? ALL_REFINERS
      : this.selectedOptions.audiences.toString();
    const isProfileType = this.profileService.profileInfo.profileType === AUDIENCE_KID ? AUDIENCE_KID : ALL_REFINERS;
    const tempRefiner = {
      ...this.defaultRefiner,
      sortBy: Array.isArray(this.selectedOptions.sortBy) ? this.selectedOptions.sortBy.toLocaleString() : this.selectedOptions.sortBy,
      activityType: this.selectedOptions.activityTypes.length ? this.selectedOptions.activityTypes.toString() : ALL_REFINERS,
      audience: this.selectedOptions.audiences.length
        ? isAudiencesOptions
        : isProfileType,
      publishers: this.selectedOptions.publishers.length ? this.selectedOptions.publishers.toString() : ALL_REFINERS,
      relatedTitle: this.selectedOptions.relatedTitle.length ? this.selectedOptions.relatedTitle.toString() : ALL_REFINERS
    };
    return tempRefiner;
  }
  /**
   * Updates selected option
   */
  updateSelectedOption(updatedOption: { key: any; value: string; checked: boolean, selectRef: boolean }, fromCard = false) {
    if (!updatedOption.selectRef) {
      this.kzSearchService.setDisableCheckBox.next(true);
      return;
    };
    let selectedOptionsValue: string[];
    if (fromCard) {
      selectedOptionsValue = [updatedOption.value];
      this.refiners.find(x => x.key === 'activityTypes').selectedItem = [updatedOption.value];
    } else {
      selectedOptionsValue = [...this.refiners.find((currRefiner) => currRefiner.key === updatedOption.key).selectedItem];
      if (updatedOption.value.toLocaleLowerCase() === 'all' && updatedOption.selectRef) {
        const refinerSelectedItem = this.refiners.find((currRefiner) => currRefiner.key === updatedOption.key).selectedItem.filter(type => type !== updatedOption.value);
        selectedOptionsValue = refinerSelectedItem;
      }
    }

    this.selectedOptions[updatedOption.key] = selectedOptionsValue;

    const selectdItems = this.refiners.find((currRefiner) => currRefiner.key === updatedOption.key).selectedItem;
    if (selectdItems.length === 0) {
      const refiner = deepClone(this.refiners);
      refiner.map(currRefiner => {
        if (currRefiner.key === updatedOption.key) {
          currRefiner.selectedItem = [...currRefiner.subOptions];
        };
        return currRefiner;
      });
      this.selectedOptions[updatedOption.key] = [...refiner.find((currRefiner) => currRefiner.key === updatedOption.key).selectedItem]
      this.refiners = refiner;
    }
    const keys = { activityTypes: 'activityTypes', audiences: 'audiences', publishers: 'publishers', relatedTitle: 'relatedTitle' }
    const selectedCategory =
      (this.selectedOptions[keys.activityTypes].length > 0
        && this.selectedOptions[keys.activityTypes].find(item => item.includes('All'))
        && this.selectedOptions[keys.audiences].length > 0
        && this.selectedOptions[keys.audiences].find(item => item.includes('All')) === 'All'
        && this.selectedOptions[keys.relatedTitle].length > 0
        && this.selectedOptions[keys.relatedTitle].find(item => item.includes('All')) === 'All'
        && this.selectedOptions[keys.publishers].length > 0
        && this.selectedOptions[keys.publishers].find(item => item.includes('All'))) === 'All';
    if (selectedCategory) {
      this.kzSearchService.setDisableReset(true);
    }
    this.selectedOptions = { ...this.selectedOptions };
    this.selectedOptions.defaulter = false;
    this.getLearningActivitiesWithUpdatedRefiners();
  }

  resetLearningActivityRefiners(option) {
    if (option.key === 'ClearAll') {
      document.getElementById('loc_resource_hub')?.focus();
      this.learningActivityAnalyticsService.trackLearningActivityEvent('CLEAR_CTA');
      this.selectedOptions = {
        sortBy: this.selectedOptions.sortBy,
        activityTypes: ['All'],
        audiences: ['All'],
        publishers: ['All'],
        relatedTitle: ['All'],
        defaulter: false
      };
      this.isInitialLoad = false;
      let refiner = deepClone(this.refiners);
      refiner.map(x => {
        if (x.key === "activityTypes" || x.key === 'audiences' || x.key === "publishers") {
          x.selectedItem = [...x.subOptions];
        };
        return x;
      });
      if (this.profileService.profileInfo.profileType === KID) {
        refiner = refiner?.filter((elm) => elm.key !== AUDIENCE);
      }
      this.refiners = refiner;
      this.kzSearchService.setDisableReset(true);
      this.selectedOptions = { ...this.selectedOptions };
      this.appliedFilterCount = this.getSum();
      this.getLearningActivitiesWithUpdatedRefiners();
    }
  }

  /**
   * Removes selection
   */
  removeSelection(option) {
    if (option.key === 'ClearAll') {
      this.isClearAll = true;
      this.learningActivityAnalyticsService.trackLearningActivityEvent('CLEAR_CTA');
      this.selectedOptions = {
        sortBy: this.selectedOptions.sortBy,
        activityTypes: ['All'],
        audiences: ['All'],
        publishers: ['All'],
        relatedTitle: ['All'],
        defaulter: false
      };
      this.selectedOptions = { ...this.selectedOptions };
      this.isInitialLoad = false;
      let refiner = deepClone(this.refiners);
      refiner.map(x => {
        if (x.key === "activityTypes" || x.key === 'audiences' || x.key === "publishers") {
          x.selectedItem = [...x.subOptions];
        };
        return x;
      });
      if (this.profileService.profileInfo.profileType === KID) {
        refiner = refiner?.filter((elm) => elm.key !== AUDIENCE);
      }
      this.refiners = refiner;
      this.kzSearchService.setDisableReset(true);
    }
    else {
      if (option.key === 'sortBy') {
        this.selectedOptions.sortBy = [];
        this.refiners.find((item) => item.key === 'sortBy').selectedItem = [];
      } else {
        const allRemove = this.selectedOptions[option.key].indexOf(ALL_REFINERS);
        this.selectedOptions[option.key].splice(this.selectedOptions[option.key].indexOf(option.value), 1);
        if (allRemove >= 0) {
          if (option.value === ALL_REFINERS) {
            this.selectedOptions[option.key] = [];
          } else {
            this.selectedOptions[option.key].splice(allRemove, 1);
          }
        }
        const refinerSelectedItem = this.refiners.find((item) => item.key === option.key);
        const allRemoveKey = refinerSelectedItem.selectedItem.indexOf(ALL_REFINERS);
        refinerSelectedItem.selectedItem.splice(refinerSelectedItem.selectedItem.indexOf(option.value), 1);
        if (allRemoveKey >= 0) {
          let selectedItem: any[];
          if (option.value === ALL_REFINERS) {
            selectedItem = [];
          } else {
            selectedItem = refinerSelectedItem.selectedItem.slice();
            selectedItem.splice(allRemoveKey, 1);
          }
          refinerSelectedItem.selectedItem = selectedItem;
        }
        if (!this.selectedOptions[option.key].length) {
          const refiner = this.refiners.find((item) => item.key === option.key);

          if (refiner) {
            this.selectedOptions[option.key] = [...refiner.subOptions];
            refinerSelectedItem.selectedItem = [...refiner.subOptions];
            let refinerClone = deepClone(this.refiners);
            this.refiners = refinerClone;
          }
        }

        const keys = { activityTypes: 'activityTypes', audiences: 'audiences', publishers: 'publishers', relatedTitle: 'relatedTitle' }
        let selectedCategory;
        if (this.profileService.profileInfo.profileType === KID) {
          selectedCategory = (
            this.selectedOptions[keys.activityTypes].length > 0
            && this.selectedOptions[keys.activityTypes].find(item => item.includes('All'))
            && ((this.selectedOptions[keys.relatedTitle].length === 0 ? 'All' : false) || (this.selectedOptions[keys.relatedTitle].length > 0
              && this.selectedOptions[keys.relatedTitle].find(item => item.includes('All'))))
            && this.selectedOptions[keys.publishers].length > 0
            && this.selectedOptions[keys.publishers].find(item => item.includes('All'))
          ) === 'All';
        } else {
          selectedCategory =
            (this.selectedOptions[keys.activityTypes].length > 0
              && this.selectedOptions[keys.activityTypes].find(item => item.includes('All'))
              && this.selectedOptions[keys.audiences].length > 0
              && this.selectedOptions[keys.audiences].find(item => item.includes('All'))
              && ((this.selectedOptions[keys.relatedTitle].length === 0 ? 'All' : false)
              || (this.selectedOptions[keys.relatedTitle].length > 0
                && this.selectedOptions[keys.relatedTitle].find(item => item.includes('All'))))
              && this.selectedOptions[keys.publishers].length > 0
              && this.selectedOptions[keys.publishers].find(item => item.includes('All'))) === 'All';
        }
        if (selectedCategory) {
          this.kzSearchService.setDisableReset(true);
          this.selectedOptions = { ...this.selectedOptions };
          this.appliedFilterCount = this.getSum();
          this.getLearningActivitiesWithUpdatedRefiners();
          return;
        }
      }
      this.kzSearchService.setDisableReset(false);
    }
    this.selectedOptions = { ...this.selectedOptions };
    this.appliedFilterCount = this.getSum();
    this.getLearningActivitiesWithUpdatedRefiners();
  }

  /**
   * Gets learning activities with updated refiners
   */
  getLearningActivitiesWithUpdatedRefiners() {
    this.pageNumber = 1;
    this.learningActivities = [];
    const refiner = this.getRefinerObject();
    this.window.scrollTo(0, 0);
    this.getLearningActivities(refiner);
  }
  /**
   * To unsubscribe the scroll event
   */
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
    cdkRemoveVisualHidden();
  }
  skipRefiner() {
    document.getElementById('resourceHubContainer').focus();
  }

  isEmptyPill(value: boolean) {
    this.isClearAll = value;
  }

}
