export const SEARCH_CATEGORY = {
  SEARCH: 'SEARCH',
  BOOKS: 'BOOKS',
  SITES: 'SITES',
  NEWS: 'NEWS'
};
export enum SEARCH_SCREENS_KEY {
  BOOKS = 'BOOKS',
  SITES = 'SITES',
  NEWS = 'NEWS'
}
export const SEARCH_SCREENS: { [key: string]: string } = {
  [SEARCH_SCREENS_KEY.SITES]: 'Sites',
  [SEARCH_SCREENS_KEY.NEWS]: 'News'
};
export enum SEARCH_EVENTS_KEY {
  BOOKS = 'BOOKS',
  NEWS = 'NEWS',
  SITES = 'SITES',
  FILTER = 'FILTER',
  WEB_LINK = 'WEB_LINK',
  ARTICLE_LINK = 'ARTICLE_LINK',
  SORT = 'SORT',
  SELECT_FILTER = 'SELECT_FILTER',
  CLEAR_FILTER = 'CLEAR_FILTER',
  SELECT_SORT = 'SELECT_SORT',
  CLEAR_ALL = 'CLEAR_ALL',
  SEE_ALL = 'SEE_ALL',
  REFINER = 'REFINER'
}
export const SEARCH_EVENTS: { [key: string]: string } = {
  [SEARCH_EVENTS_KEY.BOOKS]: 'Books',
  [SEARCH_EVENTS_KEY.SITES]: 'Sites',
  [SEARCH_EVENTS_KEY.NEWS]: 'News',
  [SEARCH_EVENTS_KEY.FILTER]: 'Filter',
  [SEARCH_EVENTS_KEY.SORT]: 'Sort',
  [SEARCH_EVENTS_KEY.WEB_LINK]: 'Website Link',
  [SEARCH_EVENTS_KEY.ARTICLE_LINK]: 'Article Link',
  [SEARCH_EVENTS_KEY.SELECT_FILTER]: 'Select Filter',
  [SEARCH_EVENTS_KEY.CLEAR_FILTER]: 'Clear Filter',
  [SEARCH_EVENTS_KEY.SELECT_SORT]: 'Select Sort',
  [SEARCH_EVENTS_KEY.CLEAR_ALL]: 'Clear All CTA',
  [SEARCH_EVENTS_KEY.SEE_ALL]: 'See All CTA',
  [SEARCH_EVENTS_KEY.REFINER] : 'Refine CTA'
};
