<div class="press-reader-details">
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
    <ng-container *ngIf="!isLoading && publicationInfo">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"></axis360-breadcrumb>
        <div class="press-reader-cover">
            <div class="cover-img-sec">
                <img [src]="publicationInfo.imageUrl" aria-hidden="true"
                    [alt]="publicationInfo.name" title="cover-image">
                <span class="date" attr.aria-label="{{getDate(publicationInfo.issueDate) | date:'d MMM yyyy'}}">
                    <mat-icon svgIcon="PRESSREADER_CALENDER"></mat-icon>{{getDate(publicationInfo.issueDate) | date:'d MMM yyyy'}}</span>
            </div>
            <div class="cover-details-sec">
                <h1 id="publication-name">{{publicationInfo.displayName}}</h1>                
                <div class="cover-country">
                    <p class="cover-country-label" role="text">Country: </p>
                    <div class="cover-country-data" role="text" attr.aria-label="{{getCountyAtrr(country)}}" [ngClass]="{'country-desc-brief':countryBrief,'country-desc': !countryBrief}"  #countryDesc>
                        <p aria-hidden="true">{{country}}</p>
                    </div>
                    <ng-container *ngIf="showCountryMoreLess">
                        <button mat-button tabindex="0" [attr.aria-label]="ariaLabelCountry" class="country-expand" (click)="countryMoreLess()" (keydown.enter)="countryMoreLess()"><p *ngIf="hidePara">Hide paragraph always for lint issues</p></button>
                    </ng-container>
                </div>
                
                <p class="cover-lang" role="text" attr.aria-label="Language: {{language}}">Language: {{language}}</p>
                <span class="date" role="text" attr.aria-label="{{getDate(publicationInfo.issueDate) | date:'d MMM yyyy'}}">
                    <mat-icon svgIcon="PRESSREADER_CALENDER"></mat-icon>{{getDate(publicationInfo.issueDate) | date:'d MMM yyyy'}}</span>
                <a class="active-cta" href="javascript:;" (click)="readNow($event, publicationInfo.redirectionUrl)" aria-describedby="publication-name">Read Now</a>
                <button class="non-active-cta" mat-raised-button (click)="openCalendarDialog()">Select Issue</button>
           
            </div>
        </div>
    </ng-container>
    <div class="press-reader-carousel-block">
        <ng-container *ngIf="!isLoading && latestIssuePublications.length > 0">
            <div class="achev-heading">
                <h2 class="axis-achev-title">Latest Issues</h2>
                <button role="link" class="achev-seeall-btn" aria-label="See all Latest Issues" (click)="redirectToListPage(PUBLICATION_TYPES.LATEST_ISSUES, publicationId)" *ngIf="latestIssuePublications.length >= PRESS_READER_PAGE_SIZE"> SEE ALL <span
                    class="material-icons">&#xe5cc;</span></button>
            </div>
            <axis360-press-reader-card-carousels usedIn="view" [publicationList]="latestIssuePublications" class="press-reader-details-carousel" [type]="PUBLICATION_TYPES.LATEST_ISSUES"></axis360-press-reader-card-carousels>
        </ng-container>
    </div>
    <div class="press-reader-carousel-block">
        <ng-container *ngIf="!isLoading && supplementPublications.length > 0">
            <div class="achev-heading">
                <h2 class="axis-achev-title">Supplements</h2>
                <button role="link" class="achev-seeall-btn" aria-label="See all Supplements" (click)="redirectToListPage(PUBLICATION_TYPES.SUPPLEMENT, publicationId)" *ngIf="supplementPublications.length >= PRESS_READER_PAGE_SIZE"> SEE ALL <span
                    class="material-icons">&#xe5cc;</span></button>
            </div>
            <axis360-press-reader-card-carousels usedIn="view" [publicationList]="supplementPublications" class="press-reader-details-carousel" [type]="PUBLICATION_TYPES.SUPPLEMENT" title="Supplements"></axis360-press-reader-card-carousels>
        </ng-container>
    </div>
    <div class="press-reader-carousel-block">
        <ng-container *ngIf="!isLoading && categoryPublications.length > 0">
            <div class="achev-heading">
                <h2 class="axis-achev-title">{{category.displayName}}</h2>
                <button role="link" class="achev-seeall-btn" attr.aria-label="See all {{category.displayName}}" (click)="redirectToListPage('', category.id.toString())" *ngIf="categoryPublications.length >= PRESS_READER_PAGE_SIZE"> SEE ALL <span
                    class="material-icons">&#xe5cc;</span></button>
            </div>
            <axis360-press-reader-card-carousels usedIn="view" [publicationList]="categoryPublications" class="press-reader-details-carousel" [title]="category.name"></axis360-press-reader-card-carousels>
        </ng-container>
    </div>
</div>