import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogNewComponent } from '@shared/components/alert-dialog-new/alert-dialog-new.component';
import { AlertDialog } from '@shared/components/alert-dialog/alert-dialog.component';
import { ERROR_ALERT_DATA } from '@shared/constants/app.constants';
import { AlertDialogInput, AlertDialogInputComponent } from '@shared/components/alert-dialog-input/alert-dialog-input.component';
import { BehaviorSubject, noop, Subject } from 'rxjs';
import { forceRedirect } from '@shared/helper/app.util';
import { AccountComponent } from 'app/core/account/account.component';
import { SpinnerService } from './spinner.service';
import { AccountService } from './account.service';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  inputValue: string;
  reloadMainComponent = new BehaviorSubject<boolean>(false);
  loginOpened = false;
  previousUrl = '';
  listpreviousUrl = '';
  titleBreadCrumb = false;
  currentUrl;
  hideHeaderFooter = new Subject<boolean>();
  breadcrumbs: (
    | { text: string; url?: string; queryParams?: any; isHref: boolean }
    | { text: string; url?: string; queryParams?: any; isHref?: boolean }
  )[] = [];
  scrollPostion = 0;
  redirectURLfromMail: string;
  isAnnouncedPage: boolean = false;
  isProfileSettingPage = false;
  isProgramDetailPage = false;
  readingProgramList = false;
  isResourceHubPage = false;
  isBBSPage = false;
  isDetailPage = false;
  constructor(
    protected matDialog: MatDialog,
    protected spinnerService?: SpinnerService,
    private accountService?: AccountService,
    private kzProgramsService?: KzProgramsService
  ) {}
  /**
   * To show the alert modal
   * @param  data alert dialog data
   */
  showAlertDialog(data: AlertDialog) {
    let isDataContent: string;

    if (data.content.includes('renew')) {
      isDataContent = 'renew';
    } else {
      isDataContent = '';
    }
    this.kzProgramsService?.thirdPartyDialog.next(data.content.includes('return') ? 'return' : isDataContent);
    let isAriaDescribedBy = 'loc_textalertcontent';

    if (data.content.includes('delete the title?')) {
      isAriaDescribedBy = 'loc_textalertcontent alert-heading-desc loc_confirmbtnOK';
    } else if (data.content.includes('renew')) {
      isAriaDescribedBy = 'alert-heading-desc';
    } else if (data.content.includes('return')) {
      isAriaDescribedBy = 'alert-heading-desc';
    } else if (data.content.includes('remove')) {
      isAriaDescribedBy = 'alert-heading-desc';
    }
    this.matDialog.open(AlertDialogNewComponent, {
      panelClass: ['profile-create-alert-dialog', data.panelClass],
      ariaLabelledBy: data.heading ? 'alert-heading' : '',
      ariaDescribedBy: data.ariaDescribedBy ? data.ariaDescribedBy : isAriaDescribedBy,
      id: 'alert-dialog-title',
      role: 'dialog',
      data
    });
  }
   /**
   * To show the EmailNotificationAlert modal
   * @param  data EmailNotificationAlert dialog data
   */
   showEmailNotificationAlertDialog(data: AlertDialog) {
    let isDataContent: string;

    if (data.content.includes('renew')) {
      isDataContent = 'renew';
    } else {
      isDataContent = '';
    }
    this.kzProgramsService?.thirdPartyDialog.next(data.content.includes('return') ? 'return' : isDataContent);
    let isAriaDescribedBy = 'loc_textalertcontent';

    if (data.content.includes('delete the title?')) {
      isAriaDescribedBy = 'loc_textalertcontent alert-heading-desc loc_confirmbtnOK';
    } else if (data.content.includes('renew')) {
      isAriaDescribedBy = 'alert-heading-desc';
    } else if (data.content.includes('return')) {
      isAriaDescribedBy = 'alert-heading-desc';
    } else if (data.content.includes('remove')) {
      isAriaDescribedBy = 'alert-heading-desc';
    }
  const alertNewDialog = this.matDialog.open(AlertDialogNewComponent, {
      panelClass: ['profile-create-alert-dialog', data.panelClass],
      ariaLabelledBy: data.heading ? 'alert-heading' : '',
      ariaDescribedBy: data.ariaDescribedBy ? data.ariaDescribedBy : isAriaDescribedBy,
      id: 'alert-dialog-title',
      role: 'dialog',
      data
    });

    return alertNewDialog;
  }

  showAddDialog(data: AlertDialog, isSuggestedbook) {
    const alertInputDialog = this.matDialog.open(AlertDialogNewComponent, {
      panelClass: ['profile-create-alert-dialog', data.panelClass],
      ariaLabelledBy: 'alert-heading',
      ariaDescribedBy: data.ariaDescribedBy ? data.ariaDescribedBy : 'loc_textalertcontent',
      id: 'alert-dialog-title',
      role: 'dialog',
      data
    });
    alertInputDialog.afterClosed().subscribe(() => {
      isSuggestedbook && this.kzProgramsService.updateProgramAfterAddCTA.next(true);
    });
  }

  /**
   * To reload the page on reload button press from alert
   */
  reloadPage = () => {
    window.location.reload();
  };
  /**
   * To show the error alert modal
   */
  showErrorAlert() {
    this.showAlertDialog({
      ...ERROR_ALERT_DATA,
      submitBtnFunc: this.reloadPage
    });
  }
  showAlertInputDialog(data: AlertDialogInput) {
    const alertInputDialog = this.matDialog.open(AlertDialogInputComponent, {
      data,
      panelClass: data.panelClass,
      ariaDescribedBy: data.ariaDescribedby,
      ariaLabelledBy: data.ariaLabelledBy ? data.ariaLabelledBy : ''
    });
    alertInputDialog.afterClosed().subscribe(() => {
      const elm = document.querySelector('.cdk-live-announcer-element');
      if (elm) {
        elm.removeAttribute('hidden');
      }
    });
  }
  openAccountPopup(data: {}, afterCloseFunc = noop) {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        if (this.loginOpened) {
          return;
        }
        this.loginOpened = !this.loginOpened;
        const loginDialog = this.matDialog.open(AccountComponent, {
          panelClass: 'account-modal-container',
          autoFocus: true,
          ariaLabelledBy: 'dialogHeading',
          data
        });
        loginDialog.afterClosed().subscribe((res: boolean) => {
          this.loginOpened = false;
          res && afterCloseFunc();
        });
      })
      .catch(() => {
        this.showErrorAlert();
      });
  }
  doLogin(cb?: () => void) {
    this.openAccountPopup({ openLoginForm: true }, cb);
  }
  toStopAnnoucedPage(isProfileSettingPage = false, isProgramDetailPage = false, readingProgramList = false, isResourceHubPage = false, isBBSPage = false, isDetailPage = false) {
    this.isProgramDetailPage = isProgramDetailPage;
    this.readingProgramList = readingProgramList;
    this.isResourceHubPage = isResourceHubPage;
    this.isBBSPage = isBBSPage;
    this.isDetailPage = isDetailPage;
    this.isAnnouncedPage = false;
    setTimeout(() => {
      if (isProfileSettingPage) {
        document.getElementById('profileSettingPage').setAttribute('tabIndex', '-1');
        this.isProfileSettingPage = false;
      }
      if (isProgramDetailPage) {
        document.getElementById('isProgramDetailPage').setAttribute('tabIndex', '-1');
        this.isProgramDetailPage = false;
      }
      if (readingProgramList) {
        document.getElementById('readingProgramListPage').setAttribute('tabIndex', '-1');
        this.readingProgramList = false;
      }
      if (isResourceHubPage) {
        document.getElementById('isResourceHubPage')?.setAttribute('tabIndex', '-1');
        this.isResourceHubPage = false;
      }
      if (isBBSPage) {
        document.getElementById('isBBSPage').setAttribute('tabIndex', '-1');
        this.isBBSPage = false;
      }
      if (isDetailPage) {
        document.getElementById('isDetailPage')?.setAttribute('tabIndex', '-1');
        this.isDetailPage = false;
      }
    }, 6000);
  }
}
