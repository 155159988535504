import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SHIMMER_COMPONENTS, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { calenderDatas } from '../shared/data/third-party-mock';
import { ThirdPartyService } from '../shared/services/third-party.service';
import {
  FORMAT_TYPE_BY_VENDOR_CODE,
  PROFILE_TYPES_UI_AUDIENCE_DESC,
  THIRD_PARTY_GROUPS,
  THIRD_PARTY_SUBTYPE,
  THIRD_PARTY_SUBTYPE_KEY,
  THIRD_PARTY_TIER,
  THIRD_PARTY_TYPES,
  THIRD_PARTY_VENDOR_CODE
} from '../shared/constants/third-party.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@shared/services/common.service';
import { LayoutComponent, ThirdPartyLayout } from '@shared/data/third-party-model';
import { ThirdPartyAnalyticService } from '../shared/services/third-party-analytic.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { PROFILE_TYPES } from 'app/third-party/shared/constants/third-party.constants';
import { UserService } from '@shared/services/user.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement, forceRedirect, setTimeoutPromise } from '@shared/helper/app.util';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SpinnerService } from '@shared/services/spinner.service';
import { PAGE_NUMBER, PAGE_SIZE, REVIEW_ALERT, WITH_OUT_INTERNET_MSG_REVIEW } from '@titledetails/shared/constants/title-details.constants';
import { AccountComponent } from 'app/core/account/account.component';
import { AccountService } from '@shared/services/account.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { MatDialog } from '@angular/material/dialog';
import { IBookDetail, ILearningActivityData, IOtherUserReview } from '@titledetails/shared/data/title-details.model';
import { SharedService } from '@shared/services/shared.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { RECORDS_COUNT } from '@titledetails/shared/data/mock/title-details.mock';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { Subscription } from 'rxjs';
import { IAction } from '../shared/data/third-party-model';
import { ThirdPartyBaseService } from '@shared/services/third-party-base.service';
import { updateDataApiItemForVideo } from '../shared/helper/third-party-util';
@Component({
  selector: 'axis360-third-party-tier-three',
  templateUrl: './third-party-tier-three.component.html',
  styleUrls: ['./third-party-tier-three.component.scss']
})
export class ThirdPartyTierThreeComponent implements OnInit, OnDestroy, AfterViewInit {
  breadcrumbs = [];
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  isOpen = false;
  triggerOrigin: any;
  vendorCode: string;
  itemDataAPISeries: string;
  showRecommendation = false;
  showRecommendationVideos = false;
  defaultSelectedTabs = 'Details';
  reviewList: IOtherUserReview[];
  realatedItemLoader = false;
  relatedItemTab = false;
  learningActivityBooks: ILearningActivityData[] = [];
  alternateFormatTitle: IBookDetail[] = [];
  pageSize = PAGE_SIZE;
  pageNumber = PAGE_NUMBER;
  avgStarRating = 0;
  reviewsCount = 0;
  totalReviewsCount = 0;
  recordsCount = RECORDS_COUNT;
  offset = 0;
  matLabel: string;
  isCarouselLoad = false;
  titleContent;
  isbn: string;
  decodedString: string;
  isLoadReviewMsgAnnouncer = false;
  reviewSubmittedMsg = '';
  calenderData = {
    latestIssues: calenderDatas.latestIssues,
    currentIssue: calenderDatas.currentIssue,
    cid: calenderDatas.cid,
    invalidDates: calenderDatas.invalidDates,
    maxDate: calenderDatas.maxDate,
    minDate: calenderDatas.minDate,
    type: calenderDatas.type,
    title: calenderDatas.title
  };
  layoutComponents: LayoutComponent[] = [];
  layoutComponentsItemList: LayoutComponent;
  layoutComponentsItemDetails: LayoutComponent;
  layoutComponentsCarousel: LayoutComponent[] = [];
  isMoreLikeThisTabLoading = false;
  isLoading = true;
  THIRD_PARTY_TYPES = THIRD_PARTY_TYPES;
  THIRD_PARTY_GROUPS = THIRD_PARTY_GROUPS;
  layoutData: Partial<ThirdPartyLayout>;
  pageTitle: string;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  isKZGuest: any;
  currentProfileFeatures: {};
  parentRoute: string;
  @ViewChild('tabGroup') tabGroup;
  itemId: string;
  userName: string;
  focusElement = focusElement;
  setTimeoutPromise = setTimeoutPromise;
  cdkVisualHidden = cdkVisualHidden;
  patronReviewsEnabled: boolean;
  scrollSubscription: Subscription;
  collectionId: string;
  THIRD_PARTY_VENDOR_CODE = THIRD_PARTY_VENDOR_CODE;
  itemDetailsTabLinkTitles: LayoutComponent;
  itemDetailsReviewComponent: LayoutComponent;
  browserHistory: number;
  titleCardAnnounce = '';
  newsTitle = '';
  constructor(
    public commonService: CommonService,
    public thirdPartyService: ThirdPartyService,
    public activatedRoute: ActivatedRoute,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    private titleService: Title,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    public userService: UserService,
    public breadcrumbService: BreadcrumbService,
    public router: Router,
    private spinnerService: SpinnerService,
    public accountService: AccountService,
    private titleDetailsService: TitleDetailsService,
    public matDialog: MatDialog,
    private sharedService: SharedService,
    public snackBarService: SnackBarService,
    private infiniteScroll: InfiniteScrollService,
    public thirdPartyBaseService: ThirdPartyBaseService
  ) { }
  ngOnInit() {
    this.parentRoute = 'tier3';
    const { id } = this.activatedRoute.snapshot.params;
    const { collectionId = '', ISBN = '' } = this.activatedRoute.snapshot.queryParams;
    this.itemId = id;
    this.isbn = ISBN;
    this.collectionId = collectionId;
    this.activatedRoute.queryParams.subscribe(() => {
      this.getLayout();
      this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
      this.patronReviewsEnabled = this.configService.currentLibrary.reviews?.patronReviewsEnabled;
      this.userName = this.profileInfoService.profileInfo.profileName;
      this.browserHistory = window.history.length;
      this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
      this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
      this.isKZGuest = !this.userService.userInfo?.isLoggedIn;
      const zoneEnabled = ((!this.axis360Enabled && this.kidsZoneEnabled) ? PROFILE_TYPES.TEEN :
        PROFILE_TYPES.ADULT);
      this.currentProfileFeatures =
        this.configService.getProfileFeatures(
          this.isKZGuest ? zoneEnabled : this.profileInfoService.currentProfile.profileType.toLowerCase()
        ) || {};
      const { vendor_code: vendorCode, titleAnnounce, title } = this.activatedRoute.snapshot.queryParams;
      this.thirdPartyAnalyticService.trackScreen('LANDING_TIRE3');
  
      if (vendorCode !== THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
        const formatType = vendorCode === THIRD_PARTY_VENDOR_CODE.VBOOKS ? 'Videobooks' : 'Videos';
        this.titleCardAnnounce = titleAnnounce ? `${title} ${formatType} - Title Details Page` : `${title} ${formatType}${' - '}Title Details Page`;
        if (!navigator.userAgent.match(/(Android|iPhone|iPad|iPod)/i)) {
          this.titleService.setTitle(this.titleCardAnnounce);
        }
        if (navigator.userAgent.match(/(Android|iPhone|iPad|iPod)/i)) {
          this.titleService.setTitle('');
        }
      } else {
        this.newsTitle = `${title || 'Newspapers and Magazines'} - Publication Details`;
        this.titleCardAnnounce = `${this.newsTitle} Page`;
        this.titleService.setTitle(this.newsTitle);
      }
    });
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);

  }
  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
    this.commonService.breadcrumbs = [];
    sessionStorage.removeItem('breadcrumb');
    cdkRemoveVisualHidden();
  }
  getBreadcrumbs() {
    const b = sessionStorage.getItem('breadcrumb');
    if (b) {
      const breadcrumbs = JSON.parse(sessionStorage.getItem('breadcrumb'))
      this.commonService.breadcrumbs = breadcrumbs.filter(x => x.text !== "");
    }
    else {
      this.breadcrumbService.breadcrumbs$.subscribe((response) => {
        if (response.filter(res => res.info === 'titledetail').length > 0) {
          this.commonService.breadcrumbs = [];
          const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
          const url = homePage === 'Library' ? '/view/library' : '/view/myshelf';
          response.forEach((res) => {
            const subject = res.queryParams?.subject?.split('/');
            if (subject && subject.length > 0) {
              const breadcrumbData = {
                text: decodeURIComponent(subject[0]).split('/')[0],
                queryParams: res.queryParams ? res.queryParams : this.checkRoute(res.queryParams),
                url: res.routeLink
              }
              this.commonService.breadcrumbs.push(breadcrumbData)
            }
            const routeLink = res.label === 'Title' ? this.commonService.previousUrl : res.routeLink;
            const routeText = res.label === 'history' ? "Checkout History" : res.label;
            if (res.label !== 'content') {

              const breadcrumbData1 = {
                text: routeText,
                url: routeLink,
                queryParams: res.queryParams ? res.queryParams : this.checkRoute(res.queryParams),
              }
              this.commonService.breadcrumbs.push(breadcrumbData1);
            }
          })
          this.commonService.breadcrumbs[0] = { text: homePage, url, queryParams: undefined }
          const unique = this.commonService.breadcrumbs.filter((set => f => !set.has(f.text) && set.add(f.text))(new Set()));
          if (sessionStorage.getItem('thirdparty') === 'library' && unique.length > 2) {
            unique.splice(1, 1);
          }
          const breadcrumbUniqueList = unique.filter(x => x.text !== "");
          this.commonService.breadcrumbs = breadcrumbUniqueList;
          sessionStorage.setItem("breadcrumb", JSON.stringify(this.commonService.breadcrumbs));
          sessionStorage.setItem('breadcrumbDetail', JSON.stringify(this.commonService?.breadcrumbs[2]?.queryParams));
        }
      });
    }
  }
  checkRoute(queryParams: any) {
    const url = window.location.href;
    return (url.includes('search/title/') || url.includes('title/list/') || url.includes('title/alwaysavailable')
      || url.includes('content/list/') || url.includes('title/subject')
    )
      && sessionStorage.getItem('breadcrumbDetail') !== 'undefined'
      ? JSON.parse(sessionStorage.getItem('breadcrumbDetail')) : queryParams;
  }
  handlingComponents(comps = []) {
    this.layoutComponents = comps.filter((c) => c.type !== THIRD_PARTY_TYPES.SELECT_ISSUE_DATE);
    this.layoutComponentsCarousel = comps.filter((c) => c.type === THIRD_PARTY_TYPES.ITEMS_CAROUSEL);
    this.layoutComponentsItemList = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_LIST_DETAILS);
    this.layoutComponentsItemDetails = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS);
    this.thirdPartyService.itemDetailsTitleComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_TITLE);
    this.thirdPartyService.itemDetailsInlineCTAComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_INLINE_CTA);
    this.thirdPartyService.itemDetailsInfoComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_INFO);
    this.thirdPartyService.itemDetailsIconlistComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_ICONLIST);
    this.thirdPartyService.itemDetailsDescriptionComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_DESCRIPTION);
    this.thirdPartyService.itemDetailsDuedateComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_DUEDATE);
    this.thirdPartyService.itemDetailsMainCTAComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_MAIN_CTA);
    this.thirdPartyService.itemDetailsRatingsComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_RATINGS);
    this.thirdPartyService.itemDetailsReviewComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_REVIEW);
    this.thirdPartyService.itemDetailsProgressbarComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_PROGRESSBAR);
    this.thirdPartyService.itemDetailsTabLinkTitles = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_TAB_LINK_TITLES);
    this.itemDetailsTabLinkTitles = comps.find((c) => c.type === THIRD_PARTY_TYPES.ITEM_DETAILS_TAB_LINK_TITLES);
    this.itemDetailsReviewComponent = this.thirdPartyService.itemDetailsReviewComponent;
    this.thirdPartyService.selectIssueComponent = comps.find((c) => c.type === THIRD_PARTY_TYPES.SELECT_ISSUE_DATE);
  }

  checkEventDataLoading(event) {
    this.isCarouselLoad = event;
    this.showRecommendationVideos = event;
  }

  async getLayout() {
    try {
      this.isLoading = true;
      const { vendor_code: vendorCode, title: bookTitle = '', series = '' } = this.activatedRoute.snapshot.queryParams;
      this.vendorCode = vendorCode;
      this.itemDataAPISeries = series;
      const { id } = this.activatedRoute.snapshot.params;
      const pageTitle = THIRD_PARTY_TIER.TIER_THREE;
      const [layoutData] = await this.thirdPartyService.getTierLayout(pageTitle, vendorCode, { id });
      if (!layoutData?.layout) {
        this.layoutComponents = [];
        this.isLoading = false;
        return;
      }
      const titleName = this.checkBase64ConvertedOrNot(bookTitle) ? this.decodedString : bookTitle;
      if (vendorCode !== THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
        this.thirdPartyService.breadBookTitle.subscribe((item) => {
          if (item !== null) {
            this.breadcrumbService.set('details/:id', item.replace(/<[^<>]*?>/g, ''));
            this.getBreadcrumbs()
            this.pageTitle = item.replace(/<[^<>]*?>/g, '');
          }
        });

        this.breadcrumbService.set('details/:id', titleName.replace(/<[^<>]*?>/g, ''));
        this.getBreadcrumbs()
        this.pageTitle = titleName.replace(/<[^<>]*?>/g, '');

        if (!series) {
          /* tslint:disable */
          layoutData.layout.components = layoutData.layout.components.filter((item) => item.dataApi.search['searchType'] !== 'series');
        }
        this.handlingComponents(layoutData.layout.components);
        this.layoutData = layoutData;
        this.getAllUserReviews(this.recordsCount, this.offset);
        this.getTitleAlternateFormat();
        this.getLearningActivityByISBN();
        this.renderDataAPI();
        setTimeout(() => {
          window.scrollTo(0, this.commonService.scrollPostion);
          this.focusElement('loc_headerId');
        }, 0);
        this.isLoading = false;
        return;
      }
      this.handlingComponents(layoutData.layout.components);
      this.layoutData = layoutData;
      this.breadcrumbService.set('details/:id', titleName);
      this.getBreadcrumbs();
      setTimeout(() => {
        window.scrollTo(0, this.commonService.scrollPostion);
        this.focusElement('loc_headerId');
      }, 0);
      this.isLoading = false;
    } catch (e) {
      this.layoutComponents = [];
      this.isLoading = false;
      this.commonService.showErrorAlert();
    }
  }

  /**
   * renderDataAPI
   * @returns ItemObject data we pass carousel
   */
  renderDataAPI() {
    if (this.layoutComponentsCarousel.length > 0) {
      this.layoutComponentsCarousel?.forEach(comp => {
        this.formatDataApi(comp);
        this.getData(comp);
      });
      return;
    }
    this.showRecommendationVideos = false;
  }

  formatDataApi(componentData) {
    const { searchText: q = '', searchby, series = '', vendor_code = '', collectionId } = this.activatedRoute.snapshot.queryParams;
    const { id = '' } = this.activatedRoute.snapshot.params;
    const { userService, profileInfoService, configService } = this;
    const { subType, dataApi } = componentData;
    const { currentProfile } = profileInfoService;
    const { currentLibrary, vendorDetails } = configService;
    const vendorList = vendorDetails.find((list) => list.vendor_code === this.vendorCode);
    const vendorSubscription = configService.getSubscriptionData((this.vendorCode || vendor_code).toLocaleLowerCase());
    // Update collection ID based on library settings
    const updateCollectionId = Object.keys(dataApi.library_settings || {}).reduce((acc, key) => {
      acc[key] = vendorSubscription[dataApi.library_settings[key]];
      return acc;
    }, {});

    const { contentCollectionId } = updateCollectionId as { contentCollectionId: string };

    switch (subType) {
      case THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS:
        dataApi.body = {
          ...dataApi.body,
          LibraryId: collectionId,
          PatronGUID: userService.getPatronId(),
          Books: [{ ItemId: id }],
          Filters: { Format: FORMAT_TYPE_BY_VENDOR_CODE[vendorList.format_code] }
        };
        dataApi.headers = {
          ...dataApi.headers,
          patronid: userService.getPatronId(),
          profileid: currentProfile.profileId || ''
        };
        break;
      case THIRD_PARTY_SUBTYPE.VIDEOS:
        {
          const { search } = dataApi as any;
          const isProfileType = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
            ? 'teen'
            : 'adult';
          const audienceTypeBasedOnSubscription =
            PROFILE_TYPES_UI_AUDIENCE_DESC[
            currentProfile.profileType.toLowerCase() === 'adult'
              ? isProfileType
              : currentProfile.profileType.toLowerCase()
            ];
          const isZoneEnable = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
            ? 'teen,children'
            : '';
          const queryParams = {
            ...search,
            contentCollectionId,
            audiencedesc: currentProfile.profileType
              ? audienceTypeBasedOnSubscription
              : isZoneEnable
          };
          let seriesType;
          let seriesName = this.checkBase64ConvertedOrNot(series) ? this.decodedString : series;
          if (seriesName) {
            queryParams.series = seriesName;
            queryParams.searchTerm = '';
            queryParams.formatType = FORMAT_TYPE_BY_VENDOR_CODE[vendorList.format_code];
          } else if (q) {
            seriesType = {
              series: searchby === 'series' ? q : '',
              searchTerm: searchby !== 'series' ? q : ''
            };

            queryParams.searchTerm = q;
            queryParams.searchType = searchby === 'all' ? 'term' : searchby;
          }

          dataApi.search = { ...queryParams, ...seriesType };
          dataApi.headers = {
            ...dataApi.headers,
            patronid: userService.getPatronId(),
            profileid: currentProfile.profileId || ''
          };
          break;
        }
      default:
        break;
    }
  }

  formatData(data: any, subType) {
    const formatMap = {
      [THIRD_PARTY_SUBTYPE.VIDEOS]: updateDataApiItemForVideo,
    };
    const formatFunc = formatMap[subType];
    if (formatFunc) {
      return formatFunc(data);
    }
    return data;
  }

  async getData(componentData) {
    try {
      const itemsDataObj = await this.thirdPartyBaseService.getThirdPartyItems(componentData.dataApi);
      componentData.itemsDataObj = itemsDataObj;
      if (this.layoutComponentsCarousel.length >= 2) {
        const ListOne = this.layoutComponentsCarousel[0].itemsDataObj || [];
        const ListOneRooKey = THIRD_PARTY_SUBTYPE_KEY[this.layoutComponentsCarousel[0].subType || THIRD_PARTY_SUBTYPE.ITEMS];
        const ListTwo = this.layoutComponentsCarousel[1].itemsDataObj || [];
        const ListTwoRooKey = THIRD_PARTY_SUBTYPE_KEY[this.layoutComponentsCarousel[1].subType || THIRD_PARTY_SUBTYPE.ITEMS];
        const ListOneData = this.formatData(ListOneRooKey ? ListOne[ListOneRooKey] : ListOne, this.layoutComponentsCarousel[0].subType);
        const ListTwoData = this.formatData(ListTwoRooKey ? ListTwo[ListTwoRooKey] : ListTwo, this.layoutComponentsCarousel[1].subType);

        this.showRecommendationVideos = ListOneData.length > 0 || ListTwoData.length > 0;
      } else {
        const ListOne = this.layoutComponentsCarousel[0].itemsDataObj || [];
        const ListOneRooKey = THIRD_PARTY_SUBTYPE_KEY[this.layoutComponentsCarousel[0].subType || THIRD_PARTY_SUBTYPE.ITEMS];
        const ListOneData = this.formatData(ListOneRooKey ? ListOne[ListOneRooKey] : ListOne, this.layoutComponentsCarousel[0].subType);

        this.showRecommendationVideos = ListOneData.length > 0;
      }
    } catch (error) {
      if (error.status === 400) {
        componentData.itemsDataObj = [];
      }
      this.showRecommendationVideos = false;
    }

  }
  /**
    * To be called on selecting tab to change the active tab
    */
  selectedTabChange = (matTabEvent: MatTabChangeEvent) => {
    this.matLabel = matTabEvent.tab.textLabel;
    this.defaultSelectedTabs = matTabEvent.tab.textLabel;
  };

  carouselEvent(event): void {
    this.showRecommendation = event;
  }

  carouselListEvent(event): void {
    this.titleContent = event;
  }


  /**
   * get All User Reviews
   * @param [recordsCount]
   * @param [offSet]
   */
  async getAllUserReviews(recordsCount: number, offSet: number) {
    this.spinnerService.showLoader();
    this.titleDetailsService.getAllUserReviews(this.itemId, recordsCount, offSet, '').subscribe((reviewUserList) => {
      if (!reviewUserList) {
        return;
      }
      this.spinnerService.showLoader(false);
      if (offSet === 0) {
        const isReviewApproved = reviewUserList.userReview?.isReviewApproved;
        this.processReview(isReviewApproved, reviewUserList);
        this.reviewList = reviewUserList.otherUserReview;
        this.titleDetailsService.setReviewSubject(this.reviewList);
        this.reviewsCount = reviewUserList.otherUserReview?.length;
        this.totalReviewsCount = this.reviewList.length;
        return;
      }
      reviewUserList.otherUserReview.sort((a, b) => {
        return (new Date(b.reviewDate) as any) - (new Date(a.reviewDate) as any);
      });
      this.reviewList?.push(...reviewUserList.otherUserReview);
      this.totalReviewsCount = this.reviewList?.length || 0;
      this.reviewsCount = reviewUserList.otherUserReview?.length;
    });
  }

  /**
   * Process Review
   * @param [isReviewApproved] Pass to string value
   * @param [reviewUserList] Pass title user review List
   * @returns
   */
  processReview(isReviewApproved: string, reviewUserList: { otherUserReview: any[]; userReview: any; }) {
    reviewUserList.otherUserReview.sort((a, b) => {
      return (new Date(b.reviewDate) as any) - (new Date(a.reviewDate) as any);
    });
    return isReviewApproved === '1' ?
      reviewUserList.otherUserReview.unshift(reviewUserList.userReview) :
      reviewUserList.otherUserReview;
  }

  async getTitleAlternateFormat() {
    const { id } = this.activatedRoute.snapshot.params;
    const { ISBN = '' } = this.activatedRoute.snapshot.queryParams;
    const titleAlternateFormat = await this.titleDetailsService.getTitleAlternateFormat(ISBN, id);
    if (titleAlternateFormat.statusCode !== 200) {
      this.relatedItemTab = !this.relatedItemTab;
      return;
    }
    this.alternateFormatTitle = titleAlternateFormat.alternateResultModel?.data;
  };

  async getLearningActivityByISBN() {
    this.realatedItemLoader = true;
    const { ISBN = '' } = this.activatedRoute.snapshot.queryParams;
    const { profileType } = this.profileInfoService.currentProfile || {};
    const learningActivityData = {
      ISBN: ISBN,
      pageNumber: 1,
      pageSize: 10,
      profileType: profileType
    };
    this.titleDetailsService.getLearningActivityByISBN(learningActivityData).then((learningActivityBooks) => {
      if (learningActivityBooks.statusCode !== 200) {
        this.learningActivityBooks = [];
        this.relatedItemTab = !this.relatedItemTab;
        return;
      }
      this.learningActivityBooks = learningActivityBooks.learningActivityIsbn?.data;
      if (this.learningActivityBooks && this.learningActivityBooks.length === 0) {
        this.defaultSelectedTabs = 'Details';
      }
      this.realatedItemLoader = false;
    });
  }

  /**
   * To verify can load more or not.
   * @returns returns can load more or not
   */
  canLoadMore = (): boolean => !this.spinnerService.isLoading && this.reviewsCount < RECORDS_COUNT && this.reviewsCount >= 0;

  /**
   * Load more content
   * @returns returns can load more or not
   */
  loadMore = () => {
    if (this.canLoadMore()) {
      return;
    }
    this.offset = this.offset + RECORDS_COUNT;
    this.getAllUserReviews(this.recordsCount, this.offset);
  };


  /**
  * Check the Patron Review in title details
  * @returns PatronReviews data
  */
  async checkPatronReview() {
    const { id } = this.activatedRoute.snapshot.params;
    return await this.titleDetailsService.getPatronReviews(id);
  }

  /**
   * Open the review Popup to Write the Review on title
   */
  async writeReview() {
    if (!this.userService.userInfo.isLoggedIn) {
      this.openLoginModel();
      return;
    }
    await this.spinnerService.withPromise(this.checkPatronReview()).then((res) => {
      this.commonService.showAlertInputDialog({ ...REVIEW_ALERT, submitBtnFunc: this.handleReview, patronReviewDetails: res });
    });
  };

  /**
   * Submits a review in title
   */
  handleReview = () => {
    const reviewContent = this.sharedService.getInputAlertDialogValue();
    cdkRemoveVisualHidden();
    this.spinnerService
      .withPromise(this.titleDetailsService.submitReview(this.itemId, reviewContent.value, new Date().toISOString(), this.userName, '0', ''))
      .then(
        (res) => {
          if (!res.submitted) {
            this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, res.msg);
            return;
          }
          if (navigator.userAgent.match(/(Mac)/i)) {
            this.reviewSubmittedMsg = `${SNAKE_BAR_MODE.THANK_YOU}! ${res.msg}`;
            this.isLoadReviewMsgAnnouncer = true;
            setTimeout(() => {
              this.isLoadReviewMsgAnnouncer = false;
              cdkVisualHidden();
            }, 2000);
          }
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.THANK_YOU, res.msg);
        },
        () => {
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, WITH_OUT_INTERNET_MSG_REVIEW);
        }
      );
  };
  openLoginModel() {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true
            }
          })
          .afterClosed();
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }

  scrollTitleReview(count) {
    if (count === 0) {
      this.writeReview();
      setTimeout(() => {
        (document.querySelector('#write_review') as HTMLElement)?.focus();
      }, 1000);
      return;
    }
    if (this.defaultSelectedTabs === 'More Like This' || this.defaultSelectedTabs === 'Related Items') {
      this.defaultSelectedTabs = 'Details';
      setTimeout(() => {
        this.tabGroup.selectedIndex = 0;
        (document.querySelector('#review-btn') as HTMLElement)?.focus();
      }, 0);
      return;
    }
    (document.querySelector('#review-btn') as HTMLElement)?.focus();
  }

  acOnExecuteAction(action: IAction) {
    if (action?.data) {
      this.thirdPartyService.cardAction(action);
    }
  }

  /**
   * check Base64 Converted Or not
   * @param [value] to pass base64 string
   * @returns boolean value
   */
  checkBase64ConvertedOrNot(value) {
    try {
      const regex = /^[A-Za-z0-9\+\/\=]+$/;
      const isBase64 = regex.test(value);
      this.decodedString = isBase64 ? atob(value) : value;
      return true;
    } catch (error) {
      return false;
    }
  }
  ngAfterViewInit() {
    window.scrollTo(0, this.commonService.scrollPostion);
    focusElement('loc_headerId');
  }
}
