export const HEADER_SCREENS_KEY = {
  HEADER: 'HEADER',
  MENU: 'MENU'
};

export const HEADER_SCREENS: { [key: string]: string } = {
  [HEADER_SCREENS_KEY.HEADER]: 'Header'
};

export const HEADER_CATEGORY = {
  HEADER: 'Actions-Header',
  SEARCH: 'Actions-Search',
  MENU: 'Actions-Menu'
};
export const HEADER_EVENTS_KEY = {
  ACC_PIC: 'ACC_PIC',
  NOTIFICATION_BELL: 'NOTIFICATION_BELL',
  ADVANCED_SEARCH: 'ADVANCED_SEARCH',
  VIEW_MY_SHEIF: 'VIEW_MY_SHEIF',
  VIEW_LIBRARY: 'VIEW_LIBRARY',
  VIEW_HAMBURGER_MENU: 'VIEW_HAMBURGER_MENU',
  VIEW_LOGO: 'VIEW_LOGO',
  VIEW_SEARCH_FOR_CONTENT: 'VIEW_SEARCH_FOR_CONTENT',
  VIEW_SIGNOUT: 'VIEW_SIGNOUT',
  VIEW_PROFILES: 'VIEW_PROFILES',
  VIEW_HELP: 'VIEW_HELP',
  VIEW_PATRON_SUPPORT: 'VIEW_PATRON_SUPPORT',
  VIEW_CHECKOUTS: 'VIEW_CHECKOUTS',
  VIEW_HOLDS: 'VIEW_HOLDS',
  VIEW_PROGRAMS: 'VIEW_PROGRAMS',
  VIEW_BROWSE_BY_SUBJECT: 'VIEW_BROWSE_BY_SUBJECT',
  VIEW_FEATURED_LISTS: 'VIEW_FEATURED_LISTS',
  VIEW_SUB_LISTS: 'VIEW_SUB_LISTS',
  SEARCH_FOR_CONTENT: 'SEARCH_FOR_CONTENT',
  SEARCH: 'SEARCH',
  CLEAR: 'CLEAR',
  CLOSE: 'CLOSE'
};
export const HEADER_EVENTS: { [key: string]: string } = {
  [HEADER_EVENTS_KEY.ACC_PIC]: 'Avatar icon',
  [HEADER_EVENTS_KEY.NOTIFICATION_BELL]: 'Click on the notification bell icon',
  [HEADER_EVENTS_KEY.ADVANCED_SEARCH]: 'View Advanced search CTA',
  [HEADER_EVENTS_KEY.VIEW_MY_SHEIF]: 'View My SheIf',
  [HEADER_EVENTS_KEY.VIEW_LIBRARY]: 'View Library',
  [HEADER_EVENTS_KEY.VIEW_HAMBURGER_MENU]: 'View menu',
  [HEADER_EVENTS_KEY.VIEW_LOGO]: 'View Logo',
  [HEADER_EVENTS_KEY.VIEW_SEARCH_FOR_CONTENT]: 'View search for content',
  [HEADER_EVENTS_KEY.VIEW_SIGNOUT]: 'View Signout',
  [HEADER_EVENTS_KEY.VIEW_PROFILES]: 'View Profiles',
  [HEADER_EVENTS_KEY.VIEW_HELP]: 'View help',
  [HEADER_EVENTS_KEY.VIEW_PATRON_SUPPORT]: 'View Patron support',
  [HEADER_EVENTS_KEY.VIEW_CHECKOUTS]: 'View Checkouts',
  [HEADER_EVENTS_KEY.VIEW_HOLDS]: 'View Holds',
  [HEADER_EVENTS_KEY.VIEW_PROGRAMS]: 'View Programs',
  [HEADER_EVENTS_KEY.VIEW_BROWSE_BY_SUBJECT]: 'View Browse',
  [HEADER_EVENTS_KEY.VIEW_FEATURED_LISTS]: 'View featurelist',
  [HEADER_EVENTS_KEY.VIEW_SUB_LISTS]: '<subMenuItemsNames>',
  [HEADER_EVENTS_KEY.SEARCH_FOR_CONTENT]: 'Search for content',
  [HEADER_EVENTS_KEY.SEARCH]: 'Search CTA',
  [HEADER_EVENTS_KEY.CLEAR]: 'Clear CTA',
  [HEADER_EVENTS_KEY.CLOSE]: 'Close CTA'
};
