import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NON_FEATURE_LISTIDS } from '@shared/constants/app.constants';
import { MyLibraryList } from '@shared/data/list-base.model';
import { arrangeInOrder, deepClone, focusElement, replaceHyphenToEndash } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ISubMenu, ISubMenuItem, SidenavService } from '@shared/services/sidenav.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { Subscription } from 'rxjs';
import {
  TITLE_LIST,
  REFINER_LISTS,
  BROWSE_ORDER_REFINER_LIST,
  SORT_REFINER,
  defaultSubjectBasedOnProfileType
} from '../shared/constants/title-list-constants';
import { Filters, IBrowseItemList, IRefinerItemList, Refiner, SearchObj } from '../shared/data/model';
import { TitleListAnalyticsService } from '../shared/services/title-list-analytic.service';
import { TitleListService } from '../shared/services/title-list.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { PROFILE_ENABLED, PROFILE_FEATURES } from '@profile/shared/constants/profiles.constants';
/**
 * Component
 */
/**
 *
 * Creates an instance of title list refiners component.
 * on init
 * on changes
 * Gets search result
 * Gets refiners
 * Gets browse by subject
 * Gets featured list
 * Dialogs close
 * Updates filter list
 * Selected filter
 * Refiners change
 * Clears title list refiners component
 */
@Component({
  selector: 'axis360-title-list-refiners',
  templateUrl: './title-list-refiners.component.html',
  styleUrls: ['./title-list-refiners.component.scss']
})
export class TitleListRefinersComponent implements OnInit {
  @Input() selectedSubject: { param: string; type: string };
  @Output() defaultcategory = new EventEmitter<string>();
  @Output() selectedId = new EventEmitter<string>();
  @Input() isMobileResponsive = false;
  @ViewChild('closeButton') closeButton: any;
  TITLE_LIST = TITLE_LIST;
  isDisable: boolean = false;
  filterData: Filters = {
    selectedSubject: '',
    selectedCategory: '',
    menuType: '',
    staffPickId: '',
    categoryId: '',
    refiners: [] as Refiner[],
    sortRefinerList: [] as Refiner[],
    categoryList: [] as ISubMenuItem[],
    tempCategoryList: [] as ISubMenuItem[],
    subjectList: [] as ISubMenuItem[],
    featuredList: [] as MyLibraryList[],
    tempFeaturedList: [] as MyLibraryList[],
    isLoadingCategory: true,
    isLoadingSubject: true,
    isLoadingRefiner: true,
    selected: '',
    searchCategory: '',
    isEnableAgeLevel: false,
    isEnableAvailability: false,
    isEnableFormat: false,
    isEnableLanguage: false,
    isEnableSort: false,
    selectedRefine: true,
    selectedSortBy: '',
    subjectFilter: [],
    isShowSubject: true,
    showCategory: false
  };
  sortRefinerList: Refiner[];
  disableReset: boolean;
  isShowSubject = true;
  filterDataSubscription: Subscription;
  resetSubscription: Subscription;
  refinersUpdateDataSubscription: Subscription;
  updateFiltersDataSubscription: Subscription;
  loadBrowseListsSubscription: Subscription;
  isOnLoad = true;
  state = -1;
  selectedValueForSortBY = REFINER_LISTS.PUBLICATION_DATE;
  selectedValueForAgeLevel = REFINER_LISTS.ALL;
  sortBy = REFINER_LISTS.SORT_BY;
  ageLevel = REFINER_LISTS.AGE_LEVEL;
  format = REFINER_LISTS.FORMAT;
  isLoadSearchResult = false;
  isLoadAnnouncer = false;
  isReadOnly = false;
  isCheckBoxFocus: string;
  getListCategory: any;
  collections = [];
  showRefiner = false;
  showCategory = false;
  categoryText = {
    axisattribute: 'axisattribute',
    releasedate: 'releasedate',
    recentlyadded: 'recentlyadded'
  };
  arrangeInOrder = arrangeInOrder;
  epopupValue: boolean;
  replaceHyphenToEndash = replaceHyphenToEndash;
  profileOfferItemsPPC: boolean;
    storedValues: any;
  selectedSubjectValues: ISubMenuItem[] = [];
  storedSubjctValues: any;
  selectedSubjectCatValues: ISubMenuItem[] = [];
  storedselectedCatValues: any;
  checkedSubjectListJSON: any;
  /**
   * Creates an instance of title list refiners component.
   * @param filterDialog
   * @param spinnerService
   * @param sidenavService
   * @param myShelfListService
   * @param titleListService
   */
  constructor(
    private filterDialog: MatDialogRef<TitleListRefinersComponent>,
    public spinnerService: SpinnerService,
    public sidenavService: SidenavService,
    private myShelfListService: MyShelfListService,
    public titleListService: TitleListService,
    public titleListAnalyticsService: TitleListAnalyticsService,
    private commonService: CommonService,
    public activeRouter: ActivatedRoute,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    public liveAnnouncer: LiveAnnouncer,
  ) { }
  /**
   * on init
   */
  ngOnInit(): void {
    this.epopupValue = this.titleListService.configService.currentLibrary.partnerSiteEnabled;
    this.closeButton?.focus && this.closeButton?.focus();
    this.titleListService.isLoadAnnouncerbyPillsRemove.subscribe((load) => {
      if (load) {
        this.isLoadAnnouncer = true;
        setTimeout(() => {
          this.isLoadAnnouncer = false;
          this.titleListService.isLoadAnnouncerbyPillsRemove.next(false);
        }, 2500);
      }
    });
    this.titleListService.isRemoveisReadOnly.subscribe((disable) =>{
      this.isReadOnly = disable;
      setTimeout(() => {
        if (this.isCheckBoxFocus) {
          document.querySelectorAll('.mat-checkbox-inner-container').forEach((btn) =>{
            const getAttributeId = btn?.children[0].getAttribute('id');
            if (getAttributeId.includes(this.isCheckBoxFocus)) {
              btn?.setAttribute('tabindex', '0');
              const checkBoxFocus = document.getElementById(`${getAttributeId}`);
              checkBoxFocus?.removeAttribute('aria-disabled');
              checkBoxFocus?.removeAttribute('disabled');
              checkBoxFocus?.removeAttribute('ng-reflect-disabled');
              checkBoxFocus && checkBoxFocus.focus();
            }
            setTimeout(() => {
              btn?.removeAttribute('tabindex');
            },1000)
          });
        }
      }, 100);
    });
    document.querySelectorAll('.mat-dialog-container').forEach((n) => {
      n.removeAttribute('tabindex');
    });
    this.activeRouter.queryParams.subscribe((params) => {
      const offerProfileType = {
        adult: PROFILE_ENABLED.ADULT_ENABLED,
        teen: PROFILE_ENABLED.TEEN_ENABLED,
        kid: PROFILE_ENABLED.KID_ENABLED
      };
      if (this.profileInfoService?.currentProfile?.profileType !== '') {
        const isOfferProfileItemType = offerProfileType[this.profileInfoService?.currentProfile.profileType.toLowerCase()];
        this.profileOfferItemsPPC =
          this.configService.currentLibrary.profileOfferItems?.find((ref) => ref.offerName === PROFILE_FEATURES.PAYPERCIRC)?.[
          isOfferProfileItemType
          ] || false;
      } else {
        this.profileOfferItemsPPC =
          this.configService.currentLibrary.profileOfferItems?.find((ref) => ref.offerName === PROFILE_FEATURES.PAYPERCIRC)
            ?.isAdultEnabled || false;
      }
      if (params.subject === '' && this.filterData.selectedCategory !== '' && this.filterData.subjectList.length !== 0) {
        this.collections = [];
        this.filterData.showCategory = false;
        this.showRefiner = false;
        this.disableReset = true;
        this.getBrowseBySubject();
        //this.getRefiners();
        return;
      }
    });
    this.disableReset = true;
    this.titleListService.isSelectedSubjectDisable.subscribe((disable) => {
      if (disable) {
        setTimeout(() => {
          document.querySelectorAll('.kz-radio-button.check-button').forEach(n => {
            n.removeAttribute('disabled');
          });
        }, 1000);
        this.isDisable = false;
      }
    });

    this.updateFiltersDataSubscription = this.titleListService.updateFiltersData$.subscribe((value) => {
      if (value?.refiners) {
        this.filterData.refiners = value.refiners;
        this.filterData.refiners.forEach((ref) => {
          if (ref.value === 'collections') {
            ref.selected = ref.items
              .filter((itemRef) => itemRef.value !== '' && itemRef.isSelected)
              .map((item) => item.value)
              .join('#');
            this.collections = ref.items.filter((itemRef) => itemRef.value !== '' && itemRef.isSelected).map((item) => item.value);
          }
        });
        if (this.collections.length > 0) {
          this.getRefiners();
          return;
        }
        this.titleListService.setFilters(this.filterData);
      }
    });
    this.refinersUpdateDataSubscription = this.titleListService.refinersUpdateData$.subscribe((ref) => {
      if (ref) {
        const codeList = this.filterData?.subjectList
          ?.filter((code) => code.isChecked)
          .map((items) => items.code)
          .toString();
        const filterCheckedCategoryList = this.filterData?.categoryList
          .filter((ref) => ref.isChecked)
          .map((item) => item.code)
          .join('#');
        if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
          localStorage.removeItem('selectedSubjectCatValues');
          if (Array.isArray(this.filterData.subjectList)) {
            this.selectedSubjectCatValues = [];
            this.selectedSubjectCatValues.push(...this.filterData.categoryList);
          }
          localStorage.setItem('selectedSubjectCatValues', JSON.stringify(this.selectedSubjectCatValues));
        }
        else
        {
          if (this.epopupValue == false) {
            localStorage.removeItem('selectedSortByValueStorage');
            localStorage.removeItem('selectedValues');
            localStorage.removeItem('selectedSortByStorage');
            localStorage.removeItem('selectedSubjectValues');
            localStorage.removeItem('selectedSubjectCatValues');
            localStorage.removeItem('checkedSubjectList');
          }
        }
        if (filterCheckedCategoryList === '') {
          this.getSearchResult(codeList);
          return;
        }
        this.getRefiners(true);
        return;
      }
    });

    this.filterDataSubscription = this.titleListService.filterChange$.subscribe((filterListData) => {
      if (filterListData?.tabvalue) {
          const tabValue = filterListData?.tabvalue;
        if (filterListData.tabvalue === 'Clear All') {
          this.titleListService.isFirstItemListSelection = true;
          this.clear(true);
          this.titleListAnalyticsService.trackTitleListEvent('CLEAR_ALL');
          filterListData.tabvalue = '';
          this.refinerChange('Clear', 'ClearAll');
          return;
        } else {
          let select = this.filterData.refiners.find((val) => val.selected === filterListData.selected);
          if (select) {
            this.titleListAnalyticsService.trackTitleListEvent('CLEAR_ITEM');
            select.items.forEach((item) => {
              if (item.defaultSelected) {
                if (item.myParent === 'agelevel') {
                  this.selectedValueForAgeLevel = REFINER_LISTS.ALL;
                }
                select.selected = item.value;
                select.selectedText = item.text;
              }
            });
          }
        }
        filterListData.tabvalue = '';
        const isDisable = this.titleListService.tabCount > 1;
        this.refinerChange('Clear', tabValue , isDisable);
      }
    });
    this.resetSubscription = this.titleListService.resetChange$.subscribe((resetValue) => {
      this.disableReset = resetValue;
      setTimeout(() => {
        this.liveAnnouncer.announce('Filters have been cleared');
      }, 3000);
    })
    if (document.getElementsByClassName('filter-modal-container')[0]) {
      this.filterData = this.titleListService.filterData;
      this.disableReset = this.filterData.selectedRefine;
      return;
    }
    this.filterData.sortRefinerList = SORT_REFINER;
    this.loadBrowseListsSubscription = this.titleListService.loadBrowseLists.subscribe((loadBrowse) => {
      if (!loadBrowse) {
        this.collections = [];
        this.filterData.showCategory = false;
        this.showRefiner = false;
        this.disableReset = true;
        this.filterData.subjectList = [];
        this.filterData.featuredList = [];
        this.filterData.isEnableSort = false;
        return;
      }
      this.collections = [];
      this.filterData.menuType = TITLE_LIST.SUBJECT;
      this.filterData.showCategory = false;
      this.showRefiner = false;
      this.disableReset = true;
      this.filterData.subjectList = [];
      this.filterData.featuredList = [];
      this.filterData.isEnableSort = false;
      this.getBrowseBySubject();
    });
  }
  /**
   * on changes
   * @param changes
   * @returns on changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.titleListAnalyticsService.trackScreen('REFINER_SCREEN');
    this.filterData.isShowSubject = true;
    if (
      !changes.selectedSubject?.currentValue ||
      (changes.selectedSubject.currentValue &&
        changes.selectedSubject.previousValue &&
        changes.selectedSubject.currentValue === changes.selectedSubject.previousValue)
    ) {
      return;
    }
    let filters = changes.selectedSubject.currentValue.param?.split('/');
    this.filterData.searchCategory = filters?.slice(1, filters.length).join('/');
    this.filterData.subjectFilter = filters;
    this.filterData.menuType = changes.selectedSubject.currentValue.type;
    this.filterData.refiners = [];
    if (this.filterData.menuType === TITLE_LIST.SUBJECT) {
      this.filterData.selectedSubject = this.titleListService.isTitleDetail || filters[0] !== '' ? filters[0] : 'Browse';
      this.filterData.selectedCategory = filters.length > 1 && this.titleListService.isTitleDetail ? this.filterData.searchCategory : '';
      if (filters.length === 1) {
        this.filterData.isLoadingCategory = true;
        this.filterData.categoryList = [];
      }
    } else {
      this.titleListAnalyticsService.trackScreen('FEATURED_LIST');
      this.filterData.selectedSubject = '';
      this.filterData.categoryId = filters[0];
      this.filterData.isShowSubject = !isNaN(+this.filterData.categoryId);
      this.getFeaturedList();
    }
  }
  /**
   * Gets search result
   * @param value
   */
  getSearchResult(browseItemCode?: string) {
    if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
      this.selectedSubjectValues = [];
      this.storedSubjctValues = JSON.parse(localStorage.getItem('selectedSubjectValues'));
      if (this.storedSubjctValues && this.storedSubjctValues.length > 0) {
        this.filterData.subjectList = this.storedSubjctValues;
      }
    }
    else
    {
      if (this.epopupValue == false) {
        localStorage.removeItem('selectedSortByValueStorage');
        localStorage.removeItem('selectedValues');
        localStorage.removeItem('selectedSortByStorage');
        localStorage.removeItem('selectedSubjectValues');
        localStorage.removeItem('selectedSubjectCatValues');
        localStorage.removeItem('checkedSubjectList');
      }
    }
    setTimeout(() => {
      this.isLoadAnnouncer = false;
    }, 2500);
    const unCheckedSubjectList = this.filterData?.subjectList
      ?.filter((code) => !code.isChecked)
      ?.map((items) => items.code)
      .toString();
    const checkedSubjectList = this.filterData?.subjectList
      ?.filter((code) => code.isChecked)
      ?.map((items) => items.code)
      .toString();
    const browseItemList: IBrowseItemList = {
      category: null,
      bisacscode: browseItemCode ?? unCheckedSubjectList,
      view: false
    };
    if (checkedSubjectList !== '') {
      this.filterData.showCategory = true;
      this.filterData.isLoadingCategory = true;
      this.filterData.categoryList = [];
      this.disableReset = false;
      this.titleListService.initialCategoryGetListContent.next(this.filterData?.subjectList);
    } else {
      this.collections = [];
      this.filterData.showCategory = false;
      return;
    }
    this.spinnerService.showLoader(true);
    const sessionRefiners = JSON.parse(sessionStorage.getItem('titleListFilters') || '{}');
    if (Object.keys(sessionRefiners).length !== 0 && sessionRefiners.categoryList?.length > 0) {
      this.filterData.categoryList = sessionRefiners.categoryList;
      this.getRefiners();
      return;
    }
    this.titleListService.getSubMenuViewBrowse(browseItemList).subscribe(
      (response) => {
        if (response) {
          if (response?.items?.length !== 0) {
            this.filterData.categoryList = response.items;
            if (this.titleListService.isTitleDetail && this.titleListService.subCategory) {
              if (this.titleListService.subCategoryLevel3) {
                const selectedCategoryTitle = `${this.titleListService?.subCategory.toLocaleUpperCase()} / ${this.titleListService.subCategoryLevel3?.toLocaleUpperCase()}`;
                const selectedCategoryList = this.filterData?.categoryList.filter((subMenu) => subMenu.text === selectedCategoryTitle);
                if (selectedCategoryList.length > 0) {
                  selectedCategoryList.map((menu) => {
                    menu.isChecked = true;
                    return menu;
                  });
                } else {
                  this.filterData?.categoryList
                    .filter((subMenu) => subMenu.text === this.titleListService?.subCategory.toLocaleUpperCase())
                    .map((menu) => {
                      menu.isChecked = true;
                      return menu;
                    });
                }
              }
              if (!this.titleListService.subCategoryLevel3) {
                this.filterData?.categoryList
                  .filter((subMenu) => subMenu.text.includes(this.titleListService?.subCategory.toLocaleUpperCase()))
                  .map((menu) => {
                    menu.isChecked = true;
                    return menu;
                  });
              }
            } else {
              this.filterData.categoryList?.map((category) => {
                category.isChecked = false;
                return category;
              });
            }
            this.filterData.tempCategoryList = deepClone(this.filterData.categoryList);
            this.filterData.isLoadingCategory = false;
            this.titleListService.isTitleDetail = false;
            this.titleListService.subCategory = undefined;
            this.titleListService.subCategoryLevel3 = undefined;
            this.spinnerService.showLoader(false);
            this.getRefiners(true);
          } else {
            this.filterData.isLoadingCategory = false;
            this.filterData.showCategory = false;
            this.titleListService.isTitleDetail = false;
            this.titleListService.subCategory = undefined;
            this.titleListService.subCategoryLevel3 = undefined;
            this.filterData.categoryList = [];
            this.spinnerService.showLoader(false);
          }
        } else {
          this.filterData.isLoadingCategory = false;
          this.filterData.showCategory = false;
          this.titleListService.isTitleDetail = false;
          this.titleListService.subCategory = undefined;
          this.titleListService.subCategoryLevel3 = undefined;
          this.filterData.categoryList = [];
          this.spinnerService.showLoader(false);
        }
      },
      () => {
        this.filterData.isLoadingCategory = false;
        this.filterData.showCategory = false;
        this.titleListService.isTitleDetail = false;
        this.titleListService.subCategory = undefined;
        this.titleListService.subCategoryLevel3 = undefined;
        this.filterData.categoryList = [];
        this.spinnerService.showLoader(false);
      }
    );
  }

  /**
   * Get profile i
   * @returns
   */
  getProfileType() {
    const profileTypeId = this.profileInfoService.currentProfile.profileTypeId === '1'
      ? 2
      : this.profileInfoService.currentProfile.profileTypeId
    return this.configService.currentLibrary.axis360Enabled
      ? this.profileInfoService.currentProfile.profileTypeId
      : profileTypeId;
  }

  /**
   * Gets refiners
   * @param value
   */
  getRefiners(listLoad = false) {
    if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
      this.storedSubjctValues = [];
      this.storedselectedCatValues = [];
      this.storedSubjctValues = JSON.parse(localStorage.getItem('selectedSubjectValues'));
      if (this.storedSubjctValues && this.storedSubjctValues.length > 0) {
        this.filterData.subjectList = this.storedSubjctValues;
      }
      this.storedselectedCatValues = JSON.parse(localStorage.getItem('selectedSubjectCatValues'));
      if (this.storedselectedCatValues && this.storedselectedCatValues.length > 0) {
        this.filterData.categoryList = this.storedselectedCatValues;
      }
    }
    else
    {
      if (this.epopupValue == false) {
        localStorage.removeItem('selectedSortByValueStorage');
        localStorage.removeItem('selectedValues');
        localStorage.removeItem('selectedSortByStorage');
        localStorage.removeItem('selectedSubjectValues');
        localStorage.removeItem('selectedSubjectCatValues');
        localStorage.removeItem('checkedSubjectList');
      }
    }
    const checkedSubjectList = this.filterData?.subjectList
      ?.filter((code) => code.isChecked)
      ?.map((items) => items.code)
      .join('#');
    if (this.epopupValue && checkedSubjectList == '') {
      this.filterData.categoryList = [];
      this.filterData.tempCategoryList = [];
    }
    const filterCheckedCategoryList = this.filterData?.categoryList
      .filter((ref) => ref.isChecked)
      .map((item) => item.code)
      .join('#');
    this.filterData.tempCategoryList = this.filterData.tempCategoryList.map((ref) => {
      if (filterCheckedCategoryList.split('#').includes(ref.code)) {
        ref.isChecked = true;
      }
      return ref;
    });
    const profileTypeIdCond = this.configService.currentLibrary.axis360Enabled ? '' : '2';
    const profileTypeId = this.profileInfoService.currentProfile.profileTypeId ? this.getProfileType() : profileTypeIdCond;
    const checkProfileType = (Number(profileTypeId) === 1 || Number(profileTypeId) === 0) ? null : 'Kid';
    const profileType = Number(profileTypeId) === 2 ? 'Teen' : checkProfileType;
    const defaultBisacsCode = profileType ? defaultSubjectBasedOnProfileType[profileType] : null;
    const checkBisacsCode = defaultBisacsCode || '';
    const getSubjectBisacsCode = (checkedSubjectList && checkedSubjectList !== '' ? checkedSubjectList : checkBisacsCode);
    if (checkedSubjectList && checkedSubjectList !== '') {
      this.titleListService.removePillsAferCheckSubject.next(true);
    }
    let refinerItemList: IRefinerItemList;
    if (!this.titleListService.isTitleDetail) {
      refinerItemList = {
        term: '',
        bisacscode: filterCheckedCategoryList !== '' ? filterCheckedCategoryList : getSubjectBisacsCode,
        searchBy: 'subject',
        view: false,
        collections: this.collections.join('#')
      };
    } else {
      refinerItemList = {
        term: this.titleListService.selectedSubjectTitle,
        bisacscode: null,
        searchBy: 'subject',
        view: false,
        collections: this.collections.join('#')
      };
    }
    this.filterData.isLoadingRefiner = true;
    this.titleListService.getRefinerForBrowse(refinerItemList).subscribe((response: SearchObj) => {
      const sessionRefiners = JSON.parse(sessionStorage.getItem('titleListFilters') || '{}');
      const loginSubject = this.selectedSubject?.param.split('/');
      const checkRefinerSessionStorageOrNot = !loginSubject?.includes('')
        ? loginSubject &&
        loginSubject.length > 1 &&
        sessionRefiners.selectedSubject === loginSubject[0] &&
        sessionRefiners.selectedCategory === loginSubject[1]
        : true;
      if (Object.keys(sessionRefiners).length !== 0 && checkRefinerSessionStorageOrNot) {
        this.filterData.refiners = sessionRefiners.refiners;
        sessionRefiners.categoryList?.length === 0 && this.refinerChange(this.filterData.menuType, this.filterData.selectedSubject);
        this.titleListService.setFilters(this.filterData);
      } else if (response && response.refiner) {
        this.configService.isPPCLibrary = response.isPPCLibrary;
        this.collections.length !== 0 && (this.disableReset = false);
        this.filterData.refiners = response.refiner?.map((val) => {
          val.selected = undefined;
          val.selectedText = '';
          val.items.forEach((item) => {
            item.text = item.text === 'Titles to Recommend' ? 'Purchase Requests' : item.text;
            const checkIsCollectionTureOrFalse = this.collections.some(coll => coll === item.value);
            let isCollection = (item.myParent === "collections") ? checkIsCollectionTureOrFalse : false;
            item.isSelected = isCollection;
            if (item.defaultSelected) {
              val.selected = item.value;
              val.selectedText = item.text;
              item.isSelected = isCollection;
            }
            if (this.epopupValue == false && (item.value == 'EBT' || item.value == 'ABT')) {
              item.isSelected = true;
            }
            if (item.value == 'Available') {
              item.isSelected = true;
            }
            val.defaultSelected = false;
          });
        
          return val;
        });
      }

      this.filterData.isLoadingRefiner = false;
      this.filterData.isLoadingCategory = false;
      const selectedSortBy = this.filterData.selectedSortBy;
      // this.profileOfferItemsPPC && (this.filterData.refiners = this.filterData.refiners?.filter((item) => item.value != 'availability')) // Earlier written for subscribed to PPC by Photon team to fix #228396
      this.filterData.isEnableAgeLevel = this.filterData.refiners.some(c => c.value === 'agelevel');
      this.filterData.isEnableSort = this.filterData.refiners.some(c => c.value === 'sortby');
      this.filterData.selectedSortBy = this.filterData.refiners.find(c => c.value === 'sortby')?.selectedText || '';
      this.filterData.sortRefinerList = this.filterData.refiners.filter(c => c.value === 'sortby');
      if (this.configService.isPPCLibrary) {
        this.filterData.isEnableAvailability = false;
      } else {
        this.filterData.isEnableAvailability = this.filterData.refiners.some((c) => c.value === 'availability');
      }
      this.filterData.isEnableLanguage = this.filterData.refiners.some((c) => c.value === 'language');
      this.filterData.isEnableFormat = this.filterData.refiners.some((c) => c.value === 'format');
      if (selectedSortBy !== '') {
        this.filterData.refiners.forEach((ref) => {
          if (ref.value === 'sortby') {
            const filterDataIndex = ref.items.findIndex((item) => item.text === this.filterData.selectedSortBy);
            if (filterDataIndex >= 0) {
              ref.items[filterDataIndex].isSelected = true;
              ref.selectedText = ref.items[filterDataIndex].text;
              ref.selected = ref.items[filterDataIndex].value;
            }
          }
        });
        this.filterData.sortRefinerList = this.filterData.refiners.filter((c) => c.value === 'sortby');
      }
      const isDisableRest = filterCheckedCategoryList !== '' || checkedSubjectList !== '';
      !listLoad && this.refinerChange(this.filterData.menuType, this.filterData.selectedSubject, isDisableRest);
      this.filterData.refiners = this.arrangeInOrder(this.filterData.refiners, BROWSE_ORDER_REFINER_LIST, true).filter(
        (elem) => elem !== undefined
      );
      if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
        //this.storedValues = JSON.parse(localStorage.getItem('selectedValues'));
        this.storedValues = this.storedValues && this.storedValues.length > 0 ? this.storedValues[0] : [];
        if (this.storedValues && this.storedValues.length > 0) {
          this.filterData.refiners = this.storedValues;
        }
      }
      else
      {
        if (this.epopupValue == false) {
          localStorage.removeItem('selectedSortByValueStorage');
          localStorage.removeItem('selectedValues');
          localStorage.removeItem('selectedSortByStorage');
          localStorage.removeItem('selectedSubjectValues');
          localStorage.removeItem('selectedSubjectCatValues');
          localStorage.removeItem('checkedSubjectList');
        }
      }
      this.titleListService.initialSetSubjectList.next(this.filterData);
      this.titleListService.filterData = this.filterData;
      if (this.isOnLoad)
      {
        this.titleListService.tempFilterData = deepClone(this.filterData);
      }
      var availableCheck = this.filterData.refiners?.filter((x) => x.value == 'availability')[0]?.items.find((y) => y.value == 'Available').defaultSelected;
      if (this.epopupValue && availableCheck == true)
      {
        var item = this.filterData.refiners?.filter((x) => x.value == 'availability')[0]?.items.find((y) => y.value == 'Available' && y.isSelected == true);
        this.setSelectedRefiner(true, item, true);
      }
      else if (this.epopupValue == false)
      {
        const formatRefiner = this.filterData.refiners?.find((x) => x.value === 'format');
        if (formatRefiner)
        {
          var item = this.filterData.refiners?.filter((x) => x.value == 'format')[0].items.find((y) => y.value == ('EBT' || 'ABT'));
          if (item.value == ('EBT' || 'ABT') && item.isSelected == true) {
            var item1 = this.filterData.refiners?.filter((x) => x.value == 'availability')[0].items.find((y) => y.value == 'Available');
            this.setSelectedRefiner(true, item1, true);
          }
          else
          {
            var item = this.filterData.refiners.filter((x) => x.value == 'format')[0].items.find((y) => y.text == ('All'));
            this.setSelectedRefiner(true, item, true);
          }
        }
        else
        {
          var item1 = this.filterData.refiners?.filter((x) => x.value == 'availability')[0].items.find((y) => y.value == 'Available');
          if (item1.isSelected == true) {
            this.setSelectedRefiner(true, item1, true);
          } else {
            this.setSelectedRefiner(false, item1, true);
          }
        }
      } else
      {
        var item1 = this.filterData.refiners?.filter((x) => x.value == 'availability')[0].items.find((y) => y.value == 'Available');
        if (item1.isSelected == true) {
          this.setSelectedRefiner(true, item1, true);
        }
        else
        {
          this.setSelectedRefiner(false, item1, true);
        }
      }
      this.configService.isPPCLibrary && (this.filterData.refiners = this.filterData.refiners?.filter((item) => item?.value != 'availability'));
      this.isOnLoad = false;
    });
  }
  /**
   * Gets browse by subject
   */
  getBrowseBySubject() {
    this.filterData.isLoadingSubject = true;
    const sessionRefiners = JSON.parse(sessionStorage.getItem('titleListFilters') || '{}');
    if (Object.keys(sessionRefiners).length !== 0 && this.filterData.menuType === TITLE_LIST.SUBJECT) {
      let itemCodeList;
      this.filterData = sessionRefiners || {};
      this.filterData.tempCategoryList = deepClone(this.filterData.tempCategoryList || []);
      itemCodeList = (sessionRefiners.subjectList || [])
        .filter((sub) => sub.isChecked)
        .map((item) => item.code)
        .toString();
      if (this.titleListService.isTitleDetail || this.filterData.subjectFilter[0] !== '') {
        (this.filterData.subjectList || sessionRefiners.subjectList).map((subMenu) => {
          if (subMenu.text === this.titleListService.selectedSubject) {
            subMenu.isChecked = true;
            itemCodeList = subMenu.code.toString();
          }
        });

      if (itemCodeList === undefined) {
          this.titleListService.filterData = this.filterData;
          this.titleListService.initialGetListContent.next(true);
          this.getRefiners(true);
          this.filterData.isLoadingSubject = false;
          this.isLoadSearchResult = true;
          return;
        }
      }
      if (
        itemCodeList === '' &&
        this.filterData.menuType === TITLE_LIST.SUBJECT &&
        (!this.titleListService.isTitleDetail || this.filterData.subjectList.length > 0)
      ) {
        this.titleListService.filterData = this.filterData;
        this.titleListService.initialGetListContent.next(true);
        this.getRefiners(true);
      }
      if (this.filterData.menuType === TITLE_LIST.SUBJECT) {
        this.isLoadSearchResult = true;
        if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
          this.checkedSubjectListJSON = JSON.parse(localStorage.getItem('checkedSubjectList'));
          if (this.checkedSubjectListJSON && this.checkedSubjectListJSON.length > 0) {
            itemCodeList = this.checkedSubjectListJSON;
          } else {
            itemCodeList = itemCodeList;
          }
        }
        else
        {
          if (this.epopupValue == false) {
            localStorage.removeItem('selectedSortByValueStorage');
            localStorage.removeItem('selectedValues');
            localStorage.removeItem('selectedSortByStorage');
            localStorage.removeItem('selectedSubjectValues');
            localStorage.removeItem('selectedSubjectCatValues');
            localStorage.removeItem('checkedSubjectList');
          }
        }
        this.getSearchResult(itemCodeList);
      }
      this.filterData.isLoadingSubject = false;
      return;
    }

    if (
      this.filterData.menuType === TITLE_LIST.SUBJECT &&
      (!this.titleListService.isTitleDetail || this.filterData.subjectFilter[0] === '')
    ) {
      this.getRefiners(true);
      this.titleListService.initialGetListContent.next(true);
    }

    this.sidenavService.getSideNav('browseBySubject').subscribe((subMenuItems: ISubMenu) => {
      if (subMenuItems && subMenuItems?.items.length === 0) {
        this.titleListService.checkEmptySubjectItems(true);
        return;
      }
      this.filterData.subjectList = (subMenuItems?.items ? subMenuItems.items : []);
      this.filterData.subjectList.map((subMenu) => {
        subMenu.isChecked = false;
        return subMenu;
      });
      let itemCodeList;
      if (this.titleListService.isTitleDetail || this.filterData.subjectFilter[0] !== '') {
        this.filterData.subjectList.map((subMenu) => {
          if (subMenu.text.toLocaleLowerCase() === this.titleListService?.selectedSubject.toLocaleLowerCase()) {
            subMenu.isChecked = true;
            itemCodeList = subMenu.code.toString();
          }
        });
        if (itemCodeList === undefined) {
          this.getRefiners(true);
          this.titleListService.initialGetListContent.next(true);
          this.filterData.isLoadingSubject = false;
          this.isLoadSearchResult = true;
          return;
        }
      } else {
        itemCodeList = subMenuItems.items.map((item) => item.code).toString();
      }
      if (this.filterData.menuType === TITLE_LIST.SUBJECT) {
        this.isLoadSearchResult = true;
        if (this.filterData?.subjectFilter == "" && this.epopupValue == false) {
          this.storedSubjctValues = [];
          this.checkedSubjectListJSON = JSON.parse(localStorage.getItem('checkedSubjectList'));
          if (this.checkedSubjectListJSON && this.checkedSubjectListJSON.length > 0) {
            itemCodeList = this.checkedSubjectListJSON;
          } else {
            itemCodeList = itemCodeList;
          }
        }
        this.getSearchResult(itemCodeList);
      }
      this.filterData.isLoadingSubject = false;
    });
  }
  /**
   * Gets featured list
   */
  async getFeaturedList() {
    this.filterData.selectedCategory = '';
    this.filterData.selectedSubject = '';
    this.filterData.selectedSortBy = '';
    try {
      let selected = { ListName: '' };
      if (this.filterData.menuType === TITLE_LIST.LIST) {
        if (this.filterData.tempFeaturedList.length > 0) {
          this.filterData.featuredList = this.filterData.tempFeaturedList.filter(
            (list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID)
          );
          selected = this.filterData.featuredList.find((val) => val && val.ListID === this.filterData.categoryId);
          if (selected?.ListName) {
            this.filterData.selectedSubject = selected.ListName;
          } else {
            this.filterData.isShowSubject = false;
          }
          this.getFeaturedListRefiners();
          return;
        }
        const lists = await this.myShelfListService.getMyLibraryList();
        const currentprofile = this.profileInfoService?.currentProfile?.profileType;
        if ((currentprofile.toLowerCase() == "adult" || currentprofile == "")) {
          this.filterData.tempFeaturedList = lists.filter((list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID) && list.ProfileType?.indexOf("GA") > -1);
          this.filterData.featuredList = lists.filter((list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID) && list.ProfileType?.indexOf("GA") > -1);
        }
        if ((currentprofile.toLowerCase() == "teen")) {
          this.filterData.tempFeaturedList = lists.filter((list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID) && list.ProfileType?.indexOf("TN") > -1);
          this.filterData.featuredList = lists.filter((list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID) && list.ProfileType?.indexOf("TN") > -1);
        }
        if ((currentprofile.toLowerCase() == "kid")) {
          this.filterData.tempFeaturedList = lists.filter((list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID) && list.ProfileType?.indexOf("KD") > -1);
          this.filterData.featuredList = lists.filter((list) => list && !NON_FEATURE_LISTIDS.includes(list.ListID) && list.ProfileType?.indexOf("KD") > -1);
        }
        selected = lists.find((val) => val && val.ListID === this.filterData.categoryId);
      }
      if (selected?.ListName) {
        this.filterData.selectedSubject = selected.ListName;
      } else {
        this.filterData.isShowSubject = false;
      }
      this.getFeaturedListRefiners();
    } catch (e) {
      this.commonService.showErrorAlert();
    }
  }
  /**
   * Gets featured list refiners
   * @param id
   */
  async getFeaturedListRefiners() {
    this.filterData.isLoadingRefiner = true;
    try {
      this.filterData.refiners = [];
      const sessionRefiners = JSON.parse(sessionStorage.getItem('titleListFilters') || '{}');
      const res = await this.titleListService.getFeatureListRefiners(
        this.filterData.categoryId,
        this.filterData.menuType === 'alwaysavailable' ? 'Collection' : 'Home'
      );
      if (sessionRefiners?.refiners && this.selectedSubject.param === sessionRefiners.categoryId) {
        this.filterData.refiners = sessionRefiners.refiners;
        this.filterData.isEnableAgeLevel = this.filterData.refiners.some((c) => c.value === 'agelevel');
        this.filterData.isEnableSort = this.filterData.refiners.some((c) => c.value === 'sortby');
        this.filterData.sortRefinerList = this.filterData.refiners.filter((c) => c.value === 'sortby');
        this.filterData.refiners = this.arrangeInOrder(this.filterData.refiners, BROWSE_ORDER_REFINER_LIST, true).filter(
          (elem) => elem !== undefined
        );
        this.filterData.selectedSortBy = this.filterData.refiners.find((c) => c.value === 'sortby')?.selectedText || '';
      } else {
        this.filterData.staffPickId = res.staffPickId;
        const refiners = res.refiner;
        if (refiners?.length) {
          this.filterData.refiners = refiners.map((val) => {
            val.selected = undefined;
            val.selectedText = '';
            val.items.forEach((item) => {
              item.text = item.text === 'Titles to Recommend' ? 'Purchase Requests' : item.text;
              item.isSelected = false;
              if (item.defaultSelected) {
                val.selected = item.value;
                val.selectedText = item.text;
                val.defaultSelected = false;
              }
            });
            return val;
          });
          (this.epopupValue) && (this.filterData.refiners = this.filterData.refiners?.filter((item) => item.value !== 'availability'));
          this.filterData.isEnableAgeLevel = this.filterData.refiners.some(c => c.value === 'agelevel');
          this.filterData.isEnableSort = this.filterData.refiners.some(c => c.value === 'sortby');
          this.filterData.sortRefinerList = this.filterData.refiners.filter(c => c.value === 'sortby');
          this.filterData.refiners = this.arrangeInOrder(this.filterData.refiners, BROWSE_ORDER_REFINER_LIST, true).filter(elem => elem !== undefined);
          this.filterData.selectedSortBy = this.filterData.refiners.find(c => c.value === 'sortby')?.selectedText || '';
        }
      }
      const filterList = ['Availability', 'Format'];
      const params = this.activeRouter.snapshot.queryParams;
      if (params.availability && params.format) {
        const sessionFilters = {
          ['Availability']: params.availability,
          ['Format']: params.format
        };
        filterList.forEach((item) => {
          const formatData = this.filterData.refiners.find((x) => x.text === item);
          if (formatData) {
            const items = formatData.items.find((y) => y.value === sessionFilters[item]);
            if (items) {
              formatData.selected = items.value;
              formatData.selectedText = items.text;
            }
          }
        });
      }
      this.filterData.isLoadingCategory = false;
      this.filterData.isLoadingRefiner = false;
      if (this.filterData.menuType === 'alwaysavailable') {
        const updatedRef = this.filterData.refiners.filter((ref) => ref.text !== 'Format');
        this.filterData.refiners = updatedRef;
      }
      if (this.isOnLoad) {
        this.titleListService.tempFilterData = deepClone(this.filterData);
      }
      this.isOnLoad = false;
    } catch (e) {
      this.filterData.isLoadingCategory = false;
      this.filterData.isLoadingRefiner = false;
      this.commonService.showErrorAlert();
    }
    this.refinerChange(this.filterData.menuType, this.filterData.selectedSubject);
  }
  /**
   * Dialogs close
   */
  dialogClose() {
    typeof this.filterDialog.close !== 'undefined' && this.filterDialog.close();

  }
  /**
   * Updates filter list
   * @param item
   * @param type
   */
  updateFilterList(item, type, option?, disable = false, isChecked?, selectedId?) {
    const alertElement = document.getElementById('loc_title_list_count');
    if (alertElement) {
      alertElement.setAttribute('role', 'alert');
    }
    this.isLoadAnnouncer = true;
    if (type === TITLE_LIST.SUBJECT) {
      this.filterData.categoryList = [];
      localStorage.removeItem('selectedSubjectCatValues');
      this.filterData.selectedSubject = this.titleListService.isTitleDetail ? item : 'Browse';
      this.filterData.selectedCategory = '';      
      option.isChecked = isChecked;
      setTimeout(() => {
        document.querySelectorAll('.kz-radio-button.check-button').forEach(n => {
          n.setAttribute('disabled', 'true');
        });
        this.isDisable = true;
      }, 500);
      this.disableReset = !disable;
      if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
        localStorage.removeItem('selectedSubjectValues');
        if (Array.isArray(this.filterData.subjectList)) {
          this.selectedSubjectValues = [];
          this.selectedSubjectValues.push(...this.filterData.subjectList);
        }
        localStorage.setItem('selectedSubjectValues', JSON.stringify(this.selectedSubjectValues));
      }
      else {
        if (this.epopupValue == false) {
          localStorage.removeItem('selectedSortByValueStorage');
          localStorage.removeItem('selectedValues');
          localStorage.removeItem('selectedSortByStorage');
          localStorage.removeItem('selectedSubjectValues');
          localStorage.removeItem('selectedSubjectCatValues');
          localStorage.removeItem('checkedSubjectList');
        }
      }
      const checkedSubjectList = this.filterData?.subjectList
        ?.filter((code) => code.isChecked)
        .map((items) => items.code)
        .toString();
      if (checkedSubjectList === '') {
        this.titleListService.initialGetListContent.next(true);
        this.getRefiners(true);
      }
      this.titleListService.refreshBreadcrumb = true;
      if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
        localStorage.setItem('checkedSubjectList', JSON.stringify(checkedSubjectList));
      }
      else
      {
        if (this.epopupValue == false) {
          localStorage.removeItem('selectedSortByValueStorage');
          localStorage.removeItem('selectedValues');
          localStorage.removeItem('selectedSortByStorage');
          localStorage.removeItem('selectedSubjectValues');
          localStorage.removeItem('selectedSubjectCatValues');
          localStorage.removeItem('checkedSubjectList');
        }
      }
      this.getSearchResult(checkedSubjectList);
      this.titleListAnalyticsService.trackTitleListEvent('SUBJECT_CHANGE', { item });
    } else if (type === TITLE_LIST.CATEGORY) {
      this.filterData.selectedCategory = this.titleListService.isTitleDetail ? item : '';
      option.isChecked = isChecked;
      this.disableReset = !disable;
      this.titleListService.setRefinerUpdate(true);
      this.titleListService.refreshBreadcrumb = true;
      this.titleListAnalyticsService.trackTitleListEvent('CATEGORY_CHANGE', { item });
    } else if (type === TITLE_LIST.FEATURE_LIST) {
      this.filterData.selectedSubject = item.ListName;
      setTimeout(() => {
        const focusSelectedId = document.getElementById(`${selectedId}`);
        focusSelectedId && focusSelectedId.focus();
      }, 2000);
      this.filterData.categoryId = item.ListID;
      this.titleListService.loadFeaturedLists.next(true);
    }
    this.isOnLoad = true;
    this.disableReset = !disable;
    this.filterData.selectedRefine = !disable;
    this.dialogClose();
  }
  /**
   * Refiners change
   * @param type
   * @param value
   * @param selectedType
   */
  refinerChange(type, value, disable = false) {
    this.isLoadAnnouncer = true;
    if (value === 'ClearAll') {
      this.titleListService.isFirstItemListSelection = true;
    }
    const alertElement = document.getElementById('loc_title_list_count');
    if (alertElement) {
      alertElement.setAttribute('role', 'alert');
    }
    if (type == this.sortBy) {
      this.selectedValueForSortBY = value;
      this.filterData.selectedSortBy = value;
      this.filterData.selectedSortByValue = this.filterData?.sortRefinerList[0]?.items?.find((ref) => ref.text === value)?.value || 'Score';
    }
    if (type == this.ageLevel) {
      this.selectedValueForAgeLevel = value;
    }
    const selecdRefinerIndex = this.filterData.refiners.findIndex((ref) => ref.text.toLocaleLowerCase() === type.toLocaleLowerCase());
    selecdRefinerIndex >= 0 && (this.filterData.refiners[selecdRefinerIndex].items.find((item) => item.text === value).isSelected = true);
    this.titleListAnalyticsService.trackTitleListEvent('VIEW_CHANGE', { type, value });
    this.disableReset = !disable;
    this.filterData.selectedRefine = !disable;
    this.filterData.refiners.forEach((ref) => {
      if (ref.text === type) {
        const filterDataIndex = ref.items.findIndex((item) => item.text === value);
        if (filterDataIndex >= 0) {
          ref.items[filterDataIndex].isSelected = true;
        }
      }
    });
    this.titleListService.setFilters(this.filterData);
    this.titleListService.elementId && focusElement(this.titleListService.elementId);
    this.titleListService.elementId = ''
    setTimeout(() => {
      this.isLoadAnnouncer = false;
    }, 2500);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.closeButton?.focus && this.closeButton?.focus();
    }, 2000);
  }

  setSelectedRefiner(isChecked, selectedItem, disable = false, tabName?) {
    selectedItem.isSelected = isChecked;
    this.disableReset = !disable;
    var selectedValues = [];
    this.isReadOnly = true;
    this.isCheckBoxFocus = tabName;
    this.filterData.selectedRefine = !disable;
    this.isLoadAnnouncer = true;
    if (selectedItem.myParent === 'collections') {
      this.filterData.refiners.forEach((ref) => {
        if (ref.value === selectedItem.myParent) {
          ref.selected = ref.items
            .filter((itemRef) => itemRef.value !== '' && itemRef.isSelected)
            .map((item) => item.value)
            .join('#');
          this.collections = ref.items.filter((itemRef) => itemRef.value !== '' && itemRef.isSelected).map((item) => item.value);
          selectedValues = ref.items.filter((itemRef) => itemRef.value !== '' && itemRef.isSelected).map((item) => item.value);
        }
      });
      this.collections = selectedValues;
      this.getRefiners();
      if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
        localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
       
      }
      else
      {
        if (this.epopupValue == false) {
          localStorage.removeItem('selectedSortByValueStorage');
          localStorage.removeItem('selectedValues');
          localStorage.removeItem('selectedSortByStorage');
          localStorage.removeItem('selectedSubjectValues');
          localStorage.removeItem('selectedSubjectCatValues');
          localStorage.removeItem('checkedSubjectList');
        }
      }
      return;
    }
    if (selectedItem.myParent === 'releasedate' || selectedItem.myParent === 'recentlyadded') {
      this.filterData.refiners.forEach((item) => {
        if (item.value === selectedItem.myParent) {
          item.selected = item.items[item.items.map((x) => x.isSelected).lastIndexOf(true)]?.value;
        }
      });
      this.titleListService.setFilters(this.filterData);
      if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
        selectedValues.push(this.filterData.refiners);
        localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
      
      }
      else {
        if (this.epopupValue == false) {
          localStorage.removeItem('selectedSortByValueStorage');
          localStorage.removeItem('selectedValues');
          localStorage.removeItem('selectedSortByStorage');
          localStorage.removeItem('selectedSubjectValues');
          localStorage.removeItem('selectedSubjectCatValues');
          localStorage.removeItem('checkedSubjectList');
        }
      }
      return;
    }
    this.filterData.refiners.forEach((ref) => {
      if (ref.value === selectedItem.myParent) {
        ref.selected = ref.items
          .filter((itemRef) => itemRef.value !== '' && itemRef.isSelected)
          .map((item) => {
            return item.value;
          })
          .join('#');
      }
    });
    setTimeout(() => {
      this.isLoadAnnouncer = false;
    }, 2500);
    this.titleListService.setFilters(this.filterData);
    if (this.filterData?.selectedSubject == "Browse" && this.epopupValue == false) {
      selectedValues.push(this.filterData.refiners);
      localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
      
    }
    else {
      if (this.epopupValue == false) {
        localStorage.removeItem('selectedSortByValueStorage');
        localStorage.removeItem('selectedValues');
        localStorage.removeItem('selectedSortByStorage');
        localStorage.removeItem('selectedSubjectValues');
        localStorage.removeItem('selectedSubjectCatValues');
        localStorage.removeItem('checkedSubjectList');
      }
    }
  }

  /**
   * checkExpandAndCollapseAnalytics
   * @param [view]
   * @param [item]
   * @returns
   */
  checkExpandAndCollapseAnalytics(view: string, item: string) {
    if (item !== 'Open') {
      this.titleListAnalyticsService.trackTitleListEvent('COLLAPSE_VIEW', { view });
      return;
    }
    this.titleListAnalyticsService.trackTitleListEvent('EXPAND_VIEW', { view });
  }

  /**
   * get the CheckRefineAnalyticsView
   * @param [type] pass View type Check the Refine Analytics
   */
  checkRefineAnalyticsView(type: string) {
    this.titleListAnalyticsService.trackTitleListEvent('VIEW_REFINER', { type });
  }

 refinerClear() {
    if (this.epopupValue == false)
    {
      localStorage.removeItem('selectedSortByValueStorage');
      localStorage.removeItem('selectedValues');
      localStorage.removeItem('selectedSortByStorage');
      localStorage.removeItem('selectedSubjectValues');
      localStorage.removeItem('selectedSubjectCatValues');
      localStorage.removeItem('checkedSubjectList');
      window.location.reload();
    }
    else
    {
      window.location.reload();
    }
  }

  /**
   * Clears title list refiners component
   */
  clear(clearAll = false) {
    this.isLoadAnnouncer = true;
    if (this.filterData?.refiners?.length) {
      this.filterData.refiners = deepClone(this.titleListService.tempFilterData.refiners);
      if (!clearAll) {
        this.filterData.refiners.forEach((ref) => {
          if (ref.text === this.sortBy || ref.text === this.ageLevel) {
            ref.selectedText = ref.text === this.sortBy ? this.selectedValueForSortBY : this.selectedValueForAgeLevel;
            ref.items.forEach((item) => {
              item.isSelected = false;
              if (item.text == this.selectedValueForSortBY || item.text == this.selectedValueForAgeLevel) {
                item.defaultSelected = true;
                ref.selected = item.value;
              } else {
                item.defaultSelected = false;
              }
            });
          }
        });
      }
      this.selectedValueForSortBY = REFINER_LISTS.PUBLICATION_DATE;
      this.selectedValueForAgeLevel = REFINER_LISTS.ALL;
      this.filterData.selectedRefine = true;
      this.titleListService.setFilters(this.filterData);
      this.filterData.sortRefinerList = this.filterData.refiners.filter(c => c.value === 'sortby');
      this.filterData.selectedSortBy = this.filterData.refiners.find(c => c.value === 'sortby')?.selectedText || ''
      this.disableReset = true;
      this.titleListAnalyticsService.trackTitleListEvent('CLEAR_ALL');
      setTimeout(() => {
        this.isLoadAnnouncer = false;
      }, 2500);
      this.dialogClose();
    }
  }
  /**
   *
   * @param stateNumb accordion index number
   */
  setState(stateNumb: number) {
    this.state = stateNumb;
  }
  /**
   * To unsubscribe the scroll event
   */
  ngOnDestroy() {
    this.filterDataSubscription.unsubscribe();
    this.refinersUpdateDataSubscription && this.refinersUpdateDataSubscription.unsubscribe();
    this.updateFiltersDataSubscription && this.updateFiltersDataSubscription.unsubscribe();
    this.loadBrowseListsSubscription && this.loadBrowseListsSubscription.unsubscribe();
    this.resetSubscription && this.resetSubscription.unsubscribe();
  }
  titleAriaLabel(element: any) {
    element = element.replace(/eAudio/g, "e Audio")
      .replace(/eBook/g, "e Book")
      .replace(/vBook/g, "v Book");
    return element;
  }

  onFocus(event: FocusEvent) {
    const element = event.target as HTMLElement;
    element.classList.add('cdk-focused', 'cdk-keyboard-focused');
  }

  onBlur(event: FocusEvent) {
    const element = event.target as HTMLElement;
    element.classList.remove('cdk-focused', 'cdk-keyboard-focused');
  }
  skipRefiner() {
    document.getElementById('title_list_result').focus();
  }
}
