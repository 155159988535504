<div class="press-reader-calendar">
    <div class="header">
        <div class="header-cal" role="none">
            <button aria-label="close" (click)="closeDialog()" (keydown.enter)="closeDialog()">
                <mat-icon aria-hidden="true" class="active">close</mat-icon>
            </button>
            <h2 class="header-label" id="select-issue-heading">Select Issue Date</h2>
            <button aria-label="Calendar List view" (click)="onClickDetail(true)" (keydown.enter)="onClickDetail(true)"
                [attr.aria-pressed]="isDetailView">
                <mat-icon aria-hidden="true" [ngClass]="{'active': isDetailView}" class="list-icon"
                    svgIcon="PRESSREADER_LIST"></mat-icon>
            </button>
            <button aria-label="Calendar Grid view" (click)="onClickDetail(false)"
                (keydown.enter)="onClickDetail(false)" [attr.aria-pressed]="!isDetailView">
                <mat-icon aria-hidden="true" [ngClass]="{'active': !isDetailView}" class="calendar-icon"
                    svgIcon="PRESSREADER_CALENDER"></mat-icon>
            </button>
        </div>
    </div>
    <div *ngIf="!isDetailView" class="calendar-block">
        <mat-card class="inline-calendar-card">
            <mat-calendar [selected]="selected" [dateClass]="dateClass" [minDate]="calenderData.minDate"
                [maxDate]="calenderData.maxDate" [dateFilter]="isLatestIssueDate" [headerComponent]="headerComponent"
                (issueClickEvent)="onIssueClick($event)" (selectedChange)="onIssueClick($event)"
                [startAt]="calenderData.latestIssues && calenderData.latestIssues[0].issueDate"></mat-calendar>
        </mat-card>
    </div>
    <div *ngIf="isDetailView" class="date-list">
        <ul role="list">
            <ng-container *ngFor="let issue of calenderData.latestIssues">
                <ng-container>
                    <li tabindex="0" role="option" attr.aria-label="{{issue.issueDate | date:'d MMM
                    yyyy'}} {{issue.day}}" (click)="onIssueClick(issue.issueDate)"
                        (keydown.enter)="onIssueClick(issue.issueDate)"
                        [attr.aria-selected]="isIssueCurrent(issue.issueDate)"
                        [ngClass]="{'active': isIssueCurrent(issue.issueDate)}">
                        {{issue.issueDate | date:'d MMM
                        yyyy'}} <span> {{issue.day}}</span></li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>