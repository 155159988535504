import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  TITLE_DETAILS_CATEGORY,
  TITLE_DETAILS_EVENTS,
  TITLE_DETAILS_EVENTS_KEY,
  TITLE_DETAILS_SCREENS,
  TITLE_DETAILS_SCREENS_KEY
} from '../constants/title-details.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof TITLE_DETAILS_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class TitleDetailsAnalyticsService extends AnalyticsService {
  constructor() {
    super(TITLE_DETAILS_SCREENS, TITLE_DETAILS_EVENTS);
  }

  /**
   * To track titleDetails page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof TITLE_DETAILS_SCREENS_KEY, altObj: AltObj = {}) {
    this.trackPage(screen, altObj);
  }

  /**
   * To track titleDetails event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackReviewEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(TITLE_DETAILS_CATEGORY.REVIEW, action, altObj);
  }

  /**
   * To track titleDetails event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackRatingEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(TITLE_DETAILS_CATEGORY.RATING, action, altObj);
  }

  /**
   * To track titleDetails event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackTitleDetailsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(TITLE_DETAILS_CATEGORY.TITLE_DETAILS, action, altObj);
  }
}
