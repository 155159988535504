import { Component, OnInit } from '@angular/core';
import { Program } from '@shared/data/programs-base.model';
import { forceRedirect, setIvyCarouselProps } from '@shared/helper/app.util';
import { MyShelfService } from '../shared/services/my-shelf.service';
import { Router } from '@angular/router';
import { MyShelfAnalyticsService } from '../shared/services/my-shelf.analytics.service';
import { MY_PROGRAMS_SIZE } from '../shared/constants/my-shelf.constants';
@Component({
  selector: 'axis360-my-programs',
  templateUrl: './my-programs.component.html',
  styleUrls: ['./my-programs.component.scss']
})
export class MyProgramsComponent implements OnInit {
  programs: Program[] = [];
  booksImageUrl: { [itemId: string]: string } = {};
  BookImg = '/angular-app/assets/images/kz-images/9781843655008 1.png';
  cellWidth = 477;
  cellMargin = 0;
  cellHeight = 290;
  MY_PROGRAMS_SIZE = MY_PROGRAMS_SIZE;
  cellsToScroll = 2;
  forceRedirect = forceRedirect;
  constructor(private myShelfService: MyShelfService, public router: Router, private myShelfAnalyticsService: MyShelfAnalyticsService) { }
  /**
   * To get myprograms for myshelf carousel
   */
  ngOnInit(): void {
    this.getMyPrograms();
    this.onResize();
  }
  /**
   * To be called on window resize to set the carousel props
   */
  onResize = setIvyCarouselProps.bind(this, 305, 0, 190, 477, 0, 290, [2, 2, 1, 1]);
  /**
   * To redirect to the program details on click of card
   * @param programId program id
   */
  onClickMyProgram(programId: string) {
    this.myShelfAnalyticsService.trackMyProgramsEvent('CLICK_MY_PROGRAM');
    this.router.navigate([`/view/programs/${programId}`]);
  }

  /**
   * To get first book images for every programs
   * @param programs programs with books
   */
  async getBooksDetails(programs: Program[]) {
    const booksIdSet = new Set<string>(
      programs.reduce((acc, cur) => {
        acc.push(cur?.books[0]?.bookId);
        return acc;
      }, [])
    );
    try {
      const books = await this.myShelfService.getBooksByIdV1([...booksIdSet]);
      this.booksImageUrl = books.items.reduce((acc, cur) => {
        acc[cur.itemId] = cur.imageUrl;
        return acc;
      }, {});
    } catch (e) {
      this.booksImageUrl = {};
    }
  }
  /**
   * To get myprograms from API
   */
  async getMyPrograms() {
    try {
      const res = await this.myShelfService.getMyPrograms();
      res.programs.length > 0 && this.getBooksDetails(res.programs);
      this.programs = res.programs.map(prog => {
        if (prog.totalCompletePercentage) {
          prog.totalCompletePercentage = Math.round(prog.totalCompletePercentage)
        }
        return prog;
      });
      this.programs.sort((a, b) => Number(new Date(a.readByDate)) - Number(new Date(b.readByDate)));
    } catch (e) {
      this.programs = [];
    }
  }
  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.myShelfAnalyticsService.trackMyProgramsEvent('CLICK_CAROUSEL_RIGHT');
      target?.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.myShelfAnalyticsService.trackMyProgramsEvent('CLICK_CAROUSEL_LEFT');
      target?.nextSibling?.classList.remove('carousel-arrow-disabled');
    }
  }
  programBackgroundImage(url?: string) {
    const largeImage = url?.replace('/Medium/', '/Large/');
    return url ? 'url(' + largeImage + ')' : 'url(/angular-app/assets/images/kz-images/my-program-default-image.svg)';
  }
}
