<ng-container *ngIf="titleStateInfo">
    <div class="book-availability" [ngClass]="{'homePage':fromHomeCarousel}" *ngIf="titleStateInfo?.displayStatus">
        <span>{{titleStateInfo?.displayStatus}}</span>
    </div>
    <div class="book-action"
        [ngClass]="{'disableActionSec':!showAction, 'btn-width-full' :titleStateInfo?.ppcBudgetReached}">
        <button *ngIf="!titleStateInfo?.ppcBudgetReached" class="expand-sec" [matMenuTriggerFor]="menu"
            #matMenuItem="matMenuTrigger" aria-label="More options" [disabled]="!showAction"
            (menuOpened)="menuStateChange(true);$event.stopPropagation();" (menuClosed)="menuStateChange(false);$event.stopPropagation();">
            <mat-icon svgIcon="DOWN_ARROW"></mat-icon>
        </button>
        <ng-container *ngIf="titleStateInfo?.actions?.length > 0">
            <div class="book-favorite" *ngIf="showFavorite(titleStateInfo?.actions)">
                <mat-icon svgIcon="favorite"></mat-icon>
            </div>
            <ng-container [ngSwitch]="titleStateInfo?.actions[0].actionButtonId">
                <ng-container *ngSwitchCase="'btnMarkAsRead'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_READ, titleStateInfo?.actions[0])">
                        <span class="primary-action">Mark as Read</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnMarkAsDone'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_DONE, titleStateInfo?.actions[0])">
                        <span class="primary-action">Mark as Done</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnMarkAsNotDone'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_NOT_DONE, titleStateInfo?.actions[0])">
                        <span class="primary-action">Mark as Not Done</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnCompleted'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction">
                        <span class="primary-action">Completed</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnCheckoutNow'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CHECKOUT, titleStateInfo?.actions[0])">
                        <span class="primary-action">Checkout</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnReturn'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RETURN, titleStateInfo?.actions[0])">
                        <span class="primary-action">Return</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnRenew'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RENEW, titleStateInfo?.actions[0])">
                        <span class="primary-action">Renew</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnUnsuspendHold'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.UNSUSPEND_HOLD, titleStateInfo?.actions[0])">
                        <span class="primary-action">Unsuspend</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnAddHold'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_HOLD, titleStateInfo?.actions[0])">
                        <span class="primary-action">Place Hold</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnRemoveHold'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_HOLD, titleStateInfo?.actions[0])">
                        <span class="primary-action">Remove Hold</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnReadNow'">
                    <a href="/Action/ReadNowClick?itemId={{titleStateInfo?.actions[0].itemId}}&ISBN={{titleStateInfo?.actions[0].isbn}}"
                        target="_blank" class="action-btn btn btn-flat">
                        <span class="primary-action">Read Now</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'btnViewPack'">
                    <a href="/coursepack" class="action-btn btn btn-flat">
                        <span class="primary-action">View Pack</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'btnAccessIndvidualCourse'">
                    <a href="/coursepack" class="action-btn btn btn-flat">
                        <span class="primary-action">View Pack</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'btnListenNow'">
                    <a href="/Action/ListenNowClick?itemId={{titleStateInfo?.actions[0].itemId}}&ISBN={{titleStateInfo?.actions[0].isbn}}"
                        target="_blank" class="action-btn btn btn-flat">
                        <span class="primary-action">Listen Now</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'btnDownload'">
                    <a href="/Action/DownloadNowClick?itemId={{titleStateInfo?.actions[0].itemId}}" target="_blank"
                        class="action-btn btn btn-flat">
                        <span class="primary-action">Download</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'btnAccessinApp'">
                    <span class="action-btn access-app-action btn" tabindex="0">
                        <span class="upper-text">access</span>
                        <span class="lower-text">&nbsp;on app</span>
                    </span>
                </ng-container>
                <ng-container *ngSwitchCase="'btnRecommend'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RECOMMEND, titleStateInfo?.actions[0])">
                        <span class="primary-action">Recommend</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnCancelRecommend'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_RECOMMEND, titleStateInfo?.actions[0])">
                        <span class="action-box">
                            <span class="wishlist-action">
                                <span class="lower-text">cancel</span>
                                <span class="upper-text">recommend</span>
                            </span>
                        </span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnAddWishlist'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_WISHLIST, titleStateInfo?.actions[0])">
                        <span class="primary-action">Add to Wishlist</span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnRemoveWishlist'">
                    <div class="book-favorite">
                        <mat-icon svgIcon="favorite"></mat-icon>
                    </div>
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, titleStateInfo?.actions[0])">
                        <span class="action-box">
                            <span class="wishlist-action">
                                <span class="lower-text">Remove from </span>
                                <span class="upper-text">Wishlist</span>
                            </span>
                        </span>
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'btnBuynow'">
                    <a class="action-btn btn btn-secondary waves-effect action-links action-expand"
                        href="{{titleStateInfo?.actions[0].buyNowUrl}}" target="_blank" rel="noopener"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.BUY_NOW, titleStateInfo?.actions[0])">
                        <span class="primary-action">Buy Now</span>
                    </a>
                </ng-container>
                <ng-container *ngSwitchCase="'btnSuspendHold'">
                    <button class="action-btn btn btn-flat" [disabled]="!showAction"
                        (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.SUSPEND_HOLD, titleStateInfo?.actions[0])">
                        <span class="primary-action">Suspend</span>
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
        <mat-menu #menu="matMenu" class="action-menu-item">
            <ng-container *ngFor="let action of titleStateInfo?.actions;let i = index;">
                <ng-container *ngIf="i > 0">
                    <ng-container [ngSwitch]="action.actionButtonId">
                        <div *ngSwitchCase="'btnViewPack'">
                            <a href="/coursepack?itemId={{action.itemId}}&ISBN={{action.isbn}}" mat-menu-item
                                class="action-expand action-links">View Pack</a>
                        </div>
                        <div *ngSwitchCase="'btnRenew'">
                            <button type="button" mat-menu-item alt="renew" class="action-expand action-links "
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RENEW, action)">
                                Renew
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnReturn'">
                            <button type="button" mat-menu-item alt="return" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RETURN, action)">
                                Return
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnReadNow'">
                            <a href="/Action/ReadNowClick?itemId={{action.itemId}}&ISBN={{action.isbn}}" target="_blank"
                                mat-menu-item class="action-expand action-links">Read Now</a>
                        </div>
                        <div *ngSwitchCase="'btnListenNow'">
                            <a href="/Action/ListenNowClick?itemId={{action.itemId}}&ISBN={{action.isbn}}"
                                target="_blank" mat-menu-item class="action-expand action-links">Listen Now</a>
                        </div>
                        <div *ngSwitchCase="'btnDownload'">

                            <a href="/Action/DownloadNowClick?itemId={{action.itemId}}" target="_blank" mat-menu-item
                                class="action-expand action-links">Download</a>

                        </div>
                        <div *ngSwitchCase="'btnAccessinApp'">
                            <button type="button" mat-menu-item alt="access on app" class="action-expand action-links">
                                Access on App
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddHold'">
                            <button type="button" mat-menu-item alt="place hold" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_HOLD, action)">
                                Place Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemoveHold'">
                            <button type="button" mat-menu-item alt="remove hold" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_HOLD, action)">
                                Remove Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRecommend'">
                            <button type="button" mat-menu-item alt="recommend" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RECOMMEND, action)">
                                Recommend
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnCancelRecommend'">
                            <button type="button" mat-menu-item alt="Cancel recommendation"
                                class="action-expand action-links wrap-cancel-recommendation"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_RECOMMEND, action)">
                                Cancel Recommendation
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnUnsuspendHold'">
                            <button type="button" mat-menu-item alt="unsuspend" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.UNSUSPEND_HOLD, action)">
                                Unsuspend
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddWishlist'">
                            <button type="button" mat-menu-item alt="add to wishlist" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_WISHLIST, action)">
                                Add to Wishlist
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemoveWishlist'">
                            <button type="button" mat-menu-item alt="remover from wishlist"
                                class="action-expand action-links remove-to-whishlist"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, action)">
                                Remove from Wishlist
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnBuyNow'">
                            <a href="{{action.buyNowUrl}}" mat-menu-item target="_blank" rel="noopener"
                                class="action-expand action-links buynow"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.BUY_NOW, action)">Buy
                                Now</a>
                        </div>
                        <div *ngSwitchCase="'btnSuspendHold'">
                            <button type="button" mat-menu-item alt="suspend hold" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.SUSPEND_HOLD,action)">
                                Suspend Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'writeAReview'">
                            <button type="button" mat-menu-item alt="write a review" class="action-expand action-links">
                                Write A Review
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-menu>
    </div>
</ng-container>