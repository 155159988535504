export interface SearchModel {
  SearchBy: string;
  searchBy: string;
  Term: string;
  Collection: string;
  RecommendationAllowed: boolean;
  Refiner: Refiner[];
  refiner: Refiner[];
  RefinerJson: string;
  CategoryName: string;
  CategoryId: string;
  StaffPickId: string;
  IsLibrarySubscribedToPPCCollection: boolean;
  IsPPCOnlyLibrary: boolean;
  DefaultSearchCollection: string;
  showTabs: boolean;
  showNewsTab: boolean;
  showSitesTab: boolean;
  isRecommendationCategory: boolean;
}

export interface Refiner {
  Text: string;
  Value: string;
  text: string,
  value: string,
  items: RefinerItem[];
  selected?: string;
  selectedText?: string;
  key?: string;
  selectedItem?: string[];
  subOptions?: string[];
  title?: string;
  selectedFilter?: string;
  refiner?: string;
}

export class Filter {
  title: string;
  facets: Facet[];
  selectedFacets?: string;
  type?: string;
}

export class Facet {
  id?: number;
  text: string;
  value?: string;
  selected?: boolean;
  count?: number;
}
export interface RefinerItem {
  myParent: string;
  text: string;
  value: string;
  ControlType: string;
  defaultSelected: boolean;
  Id?: string;
  selected?: string;
  selectedText?: string;
  isSelected?: boolean;
}
export interface GetTitlesRequest {
  page: number;
  pageSize: number;
  pageTitle?: string;
  searchText: string;
  searchby: string;
  format: string;
  agelevel: string;
  availability: string;
  collections: string;
  releasedate: string;
  recentlyadded: string;
  language: string;
  axisattribute: string;
  sortby?: string;
  subject: string;
  vendor_code?: string;
  q?: string;
  ref?: any;
  isFromProgramMilestone?: boolean
}
export interface GetSitesRequest {
  searchText: string;
  searchby?: string;
  page: number;
  pageSize: number;
  gradeMap?: string;
  domainFilter?: string;
  language?: string;
  subjectFilter?: string;
  sourceFilter?: string;
  formatFilter?: string;
  vendor_code?: string;
  q?: string;
}
export interface Sites {
  DESCRIPTION: string;
  DISPLAYURL: string;
  FORMAT_TYPE: string;
  FORMAT_TYPE_LIST?: string[];
  gradeelm: string;
  gradehig: string;
  grademid: string;
  GRADE_PREK_2: string;
  GRADE_3_5: string;
  GRADE_6_8: string;
  GRADE_9_12: string;
  grade_conted: string;
  grade_prek_2: string;
  HEADING: string[];
  IMAGEDATA: Imagedatas;
  LANGUAGE: string[];
  LEXILE_MEASURE: string;
  rating_count: string;
  rating_total: string;
  REDIRECT_URL: string;
  SOURCE_TYPE: string;
  SOURCE_TYPE_LIST?: string[];
  TITLE: string;
  topleveldomain: string;
  url: string;
  urlid: string;
}
export interface Imagedatas {
  ID: string;
  URL: string;
  imageAlt: string;
}
export interface WPEDOC {
  HITSTOTAL?: number;
  WS_DETAIL_ENTRY?: Sites[];
  GRADEOPTION?: Grade[];
  DOMAINOPTION?: Domain[];
  LANGUAGEOPTION?: Language[];
  FORMATTYPEOPTION?: FormatType[];
  SUBJECTOPTION?: Subject[];
  SOURCEOPTION?: Source[];
  SEARCHRECORDCOUNT?: RecordCount;
  PAGERECORDCOUNT?: RecordCount;
  RECORDCOUNT?: RecordCount;
}
export interface Grade {
  GRADEMAP: string;
  Count: number;
}
export interface Domain {
  DOMAINMAP: string;
  Count: number;
}
export interface Language {
  LANGUAGEMAP: string;
  Count: number;
}
export interface FormatType {
  FORMATTYPEMAP: string;
  Count: number;
}
export interface Subject {
  SUBJECTMAP: string;
  Count: number;
}
export interface Source {
  SOURCEMAP: string;
  Count: number;
}
export interface Source {
  SOURCEMAP: string;
  Count: number;
}
export interface RecordCount {
  Count: number;
}

export interface SitesInfo {
  WPEDOC?: WPEDOC;
}

export interface AppliedFilter {
  limiter: string;
  facets: Facet[];
}
export interface AppliedFilter {
  limiter: string;
  facets: Facet[];
  selectedFacts: string;
}

export class StudyTitles {
  page: number = 1;
  pageSize: number = 10;
  searchText: string = '';
  searchby: string = '';
  format: string = '';
  agelevel: string = '';
  availability: string = '';
  collections: string = '';
  subject: string = '';
  releasedate: string = '';
  recentlyadded: string = '';
  language: string = '';
  axisattribute: string = '';
  sortby?: string = '';
  vendor_code?: string = '';
  q?: string = '';
  pageName?: string = '';
  pageTitle?: string = '';
  programId?: string = '';
  isFromProgramMilestone?: any = false;
}

export class StudySites {
  searchText: string = '';
  searchby?: string = '';
  page: number = undefined;
  pageSize: number = undefined;
  gradeMap?: string = '';
  domainFilter?: string = '';
  language?: string = '';
  subjectFilter?: string = '';
  sourceFilter?: string = '';
  formatFilter?: string = '';
  vendor_code?: string = '';
  q?: string = '';
}
export class LearningActivity {
  searchText: string = '';
  searchby?: string = '';
  search: string = '';
  page: number = 1;
  pageSize: number = 24;
  pageTotal: number = undefined;
  sortBy: string = 'Relevancy';
  activityTypes: string = 'All';
  activityType?: string = 'All';
  audience: string = 'All';
  audiences: string = 'All';
  profileType: string = '';
  vendor_code?: string = '';
  q?: string = '';
  relatedTitle: string = 'All';
  publishers: string = 'All';
  publisher?: string = 'All';
  sortOrder?: string = '1'
}
export class ILearningActivity {
  totalCount: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  pageTotal: number = 1;
  status: string = '';
  statusCode: number = 0;
  data: ILearningActivityData[] = [];
  errors: string = '';
  filter: LearningActivityFilter = {} as LearningActivityFilter;
  defaultFilterOptions: string[] = [];
}

export interface ILearningActivityData {
  id: string;
  publisher: string;
  activityType: string;
  audience: string;
  linkedTitleISBN: string;
  linkedTitleID: string;
  linkedTitle: string;
  learningResourceName: string;
  imageUrl: string;
  fileUrl: string;
  thirdpartyVendorCode?: string;
}

export interface ILearningActivityResponse {
  statusCode: number;
  message: string;
  learningActivity: ILearningActivity;
  filter: LearningActivityFilter;
}
export interface ILearningActivityRequest {
  ISBN: string;
  pageNumber: number;
  pageSize: number;
}

export interface LearningActivityFilter {
  sortBy: string[];
  activityTypes: string[];
  audiences: string[];
  selectedFacets: string;
  refiner?: string;
  filters?: [{ isSelected: string, value: string }];
}
export interface IRefiner {
  refiner: string;
  filter: IFilter[];
  selectedFacts: string;
  filters: IFilter[];
  selectedFilter?: string;
}
export interface IFilter {
  myParent: string;
  value: string;
  text?: string;
  isSelected?: boolean;
}
export interface ITotalCountType {
  All: number;
  ABT: number;
  EBT: number;
  learningActivity: number;
  webPathExpress: number;
}

export interface ISearchRefiners {
  refiners: Refiner[];
  learningActivityRefiners?: Refiner[];
  availableLimiters?: Filter[];
  activityRefiner?: LearningActivityFilter[];
  refinersHidden?: boolean;
  isLearningActivity?: boolean;
  showAccordion?: boolean;
  radioClass?: string;
}
