import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import { HEADER_EVENTS_KEY, HEADER_SCREENS, HEADER_EVENTS, HEADER_CATEGORY } from '../constants/header.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof HEADER_EVENTS_KEY;
@Injectable()
export class HeaderAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of myshelf analytics service.
   */
  constructor() {
    super(HEADER_SCREENS, HEADER_EVENTS);
  }
  /**
   * To track header events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackHeaderEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(HEADER_CATEGORY.HEADER, action, altObj);
  }

  /**
   * To track menu events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMenuEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(HEADER_CATEGORY.MENU, action, altObj);
  }
}
