<div class="featured-magazine" *ngIf="!errorHandler" (window:resize)="onResize()"
    [ngStyle]="backgroundImageUrl ? {'background-image': 'url(' + backgroundImageUrl + ')'} : ''"
    [ngClass]="{'kz-video-featured-magazine': componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST || componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS, 'kz-video-library-featured-magazine': componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS, 'kz-featured-newspaper-magazine': componentData.subType === THIRD_PARTY_SUBTYPE.PRESSRDR_FEATURED_LIST}">
    <div class="featured-card-carousel">
        <div class="featured-col-left">
            <div class="third-party-featured-banner">
                <h2 class="heading-2" 
                *ngIf="componentData.title && totalCount > 0" attr.aria-label="{{componentData.title}}"><span aria-hidden="true">{{componentData.title}}</span></h2>
                <ng-container *ngIf="totalCount >= 10">
                    <a attr.aria-label="See All {{componentData.title}}" href="javascript:;"
                        (keyup.enter)="seeAllClick.apply(this)" (click)="seeAllClick.apply(this)"
                        *ngIf="!componentData.hideSeeAllLink" class="featured-list-link">See All
                        <mat-icon svgIcon="kz-right-arrow" class="kz-arrow"></mat-icon>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="featured-col-right">
            <axis360-third-party-cards-carousel [componentData]="componentData"
                (errorHandler)="errorHandlerEvent($event)" (count)="checkTitleCount($event)" [vendorCode]="vendorCode"
                [cellsToScrollNums]="[4,3,2,1]"
                [componentTitleClass]="(componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST || componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS) ? 'kz-video-carousel' : 'kz-featured-carousel'"
                [hideSeeAll]="true" [thirdPartyFeatured]="true"
                [parentRoute]="parentRoute"></axis360-third-party-cards-carousel>
        </div>
    </div>
</div>