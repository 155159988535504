import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { OffcanvasComponent } from './offcanvas/offcanvas.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from '@shared/shared.module';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { KzNotificationIconComponent } from 'app/core/kz-notifications/kz-notification-icon/kz-notification-icon.component';
import { ProfileListDropDownComponent } from './profile-list-dropdown/profile-list-dropdown.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HeaderComponent,
    OffcanvasComponent,
    AdvancedSearchComponent,
    KzNotificationIconComponent,
    ProfileListDropDownComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTooltipModule,
    SharedModule
  ],
  exports: [HeaderComponent, OffcanvasComponent]
})
export class HeaderModule { }
