import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SEARCH_QUERY_PARAM } from '@shared/constants/app.constants';
import { ConfigService } from '@shared/services/config.service';
import { MainService } from '@shared/services/main.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {
  isLoading = true;
  /**
   * Creates an instance of app component.
   * @param configService Config service
   * @param router Angular router
   * @param activatedRoute Activated route
   */
  constructor(
    public configService: ConfigService,
    public userService: UserService,
    public mainService: MainService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public spinnerService: SpinnerService,
    public profileInfo: ProfileInfoService
  ) { }
  /**
   * To get Library Settings from API
   */
  ngOnInit(): void {
    this.getLibrarySettings();
  }
  /**
   * To get Library Settings from API
   */
  async getLibrarySettings() {
    this.spinnerService.showLoader();
    await this.mainService.getAvatar();
    await this.mainService.getUserAndSettings();
    this.routeCheck();
    this.mainService.updateTheme();
    this.spinnerService.showLoader(false);
  }
  /**
   *
   * @param currentLibrary to check route if tabs not enabled means to redirect to books tab(.Net)
   * @returns return
   */
  routeCheck() {
    if (
      (this.router.url.indexOf('/news') > -1 && !this.configService.isPressReaderEnabled()) ||
      (this.router.url.indexOf('/sites') > -1 && !this.configService.isWebPathEnabled())
    ) {
      this.redirectToUrl(
        `/Search?${SEARCH_QUERY_PARAM}=${encodeURIComponent(this.activatedRoute.firstChild.snapshot.queryParams[SEARCH_QUERY_PARAM])}`
      );
      return;
    }
    const hasNoAccessForProgram =
      this.router.url.indexOf('/program') > -1 && (!this.userService.isLoggedIn() || !this.configService.isProgramEnabled(this.profileInfo.currentProfile.profileType.toLowerCase()));
    const hasNoAccessForInterestSurvey =
      this.router.url.indexOf('/interestsurvey') > -1 && (!this.userService.isLoggedIn() || !this.configService.isRecommendationsEnabled(this.profileInfo.currentProfile.profileType.toLowerCase()));
    if (hasNoAccessForProgram || hasNoAccessForInterestSurvey) {
      this.redirectToUrl('');
      return;
    }
    this.isLoading = false;
  }
  /**
   * Redirect to url
   */
  redirectToUrl = (url: string) => (window.location.href = url);
}
