import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { getTimeZone } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { Observable } from 'rxjs';
import { ActionClickResponse, BookDetail, TitleStateInfo } from '../data/engage.model';
@Injectable({
  providedIn: 'root'
})
export class EngageService extends EngageBaseService {
  homeCarouselListId: string;
  /**
   * Creates an instance of programs service.
   * @param http http client
   */
  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) {
    super(http, profileInfoService, configService);
  }
  bookActionClick(data: any): Observable<ActionClickResponse> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return this.http.post<ActionClickResponse>(API_INFO.bookActionClick, formData);
  }

  getBookActions(book: BookDetail, isHistory = false): Observable<TitleStateInfo[]> {
    return this.http.post<TitleStateInfo[]>(API_INFO.getBookActions.replace('<fromCheckoutHistory>', isHistory.toString()), [book]);
  }

  markAsRead(bookId: string, isbn: string, bookType: string, isManual = false, markAsRead = 'Yes', markAsNotRead = 'No'): Observable<number> {
    return this.http.post<number>(
      API_INFO.markAsRead
        .replace('<bookId>', bookId)
        .replace('<isbn>', isbn)
        .replace('<bookType>', bookType)
        .replace('<timeZone>', getTimeZone())
        .replace('<isManual>', isManual.toString())
        .replace('<markAsRead>', markAsRead)
        .replace('<markAsNotRead>', markAsNotRead),
      {}
    );
  }
}
