import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { HOME_AVAILABILITY, KZ_HOME_FORMAT_TYPE, HOME_REFINER } from '@shared/constants/app.constants';
import { BookDetail, ListContent, TitleStatusInfo } from '@shared/data/config.model';
import { MyLibraryList } from '@shared/data/list-base.model';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { ProfileInfoService } from './profile-info.service';

@Injectable({
  providedIn: 'root'
})
export class ListBaseService {
  /**
   * Gets selected availability info
   */
  get selectedAvailability() {
    return sessionStorage.getItem(HOME_REFINER.AVAILABILITY) || HOME_AVAILABILITY[0].value;
  }

  /**
   * Sets selected availability info
   */
  set selectedAvailability(availability: string) {
    sessionStorage.setItem(HOME_REFINER.AVAILABILITY, availability);
  }

  /**
   * Gets selected availability info
   */
  get selectedFormat() {
    return sessionStorage.getItem(HOME_REFINER.FORMAT) || KZ_HOME_FORMAT_TYPE[0].value;
  }

  /**
   * Sets selected availability info
   */
  set selectedFormat(formatType: string) {
    sessionStorage.setItem(HOME_REFINER.FORMAT, formatType);
  }

  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) { }

  /**
   * Gets MyLibraryList from API
   * @returns returns MyLibraryList
   */
  getMyLibraryList(): Promise<MyLibraryList[]> {
    return this.http.get<MyLibraryList[]>(API_INFO.getMyLibraryList).toPromise();
  }

  /**
   * Gets ListContent from API
   * @param listId input param as listId
   * @param selectedAvailability input param as Availability Filter value
   * @param selectedFormat input param as Format Filter value
   * @returns returns ListContent
   */
  getListContent(listId, selectedAvailability, selectedFormat, pagenumber = 1, limit = 12): Promise<ListContent> {
    const isZoneEnabled = this.configService.currentLibrary.axis360Enabled
      ? API_INFO.getListContent
      : API_INFO.getListContent.concat(`&profiletype=2`);
    const url = (this.profileInfoService.currentProfile.profileTypeId
      ? API_INFO.getListContent.concat(`&profiletype=${this.getProfileId()}`)
      : isZoneEnabled);
    return this.http
      .get<ListContent>(
        url
          .replace('<listId>', listId)
          .replace('<pagenumber>', pagenumber.toString())
          .replace('<limit>', limit.toString())
          .replace('<format>', selectedFormat)
          .replace('<availability>', selectedAvailability)
      )
      .toPromise();
  }

  /**
   * Gets MyLibraryListActionButton from API
   * @param listItems input param as listItems
   * @returns returns MyLibraryListActionButton
   */
  getMyLibraryListActionButton(listItems: BookDetail, fromCheckoutHistory = false): Observable<any> {
    return this.http.get<any>(
      API_INFO.getMyLibraryListActionButton
        .replace('<ISBN>', listItems.ISBN)
        .replace('<ItemId>', listItems.ItemId)
        .replace('<IsAvailable>', listItems.IsAvailable === true ? 'true' : 'false')
        .replace('<IsRTV>', listItems.IsRTV === true ? 'true' : 'false')
        .replace('<FormatType>', listItems.FormatType)
        .replace('<isRecommendable>', listItems.IsRecommendable === true ? 'true' : 'false')
        .replace('<TotalQuantity>', listItems.TotalQuantity.toString() || '')
        .replace('<OnOrderQuantity>', listItems.OnOrderQuantity.toString() || '')
        .replace('<CollectionType>', listItems.CollectionType)
        .replace('<PublicationDate>', listItems.PublicationDate)
        .replace('<fromCheckoutHistory>', fromCheckoutHistory === true ? 'true' : 'false')
    );
  }

  /**
   * Gets getMyLibraryBannersListActionButton from API
   * @param listItems input param as listItems
   * @returns returns getMyLibraryBannersListActionButton
   */
  getMyLibraryBannersListActionButton(listItems: BookDetail, fromCheckoutHistory = false): Observable<any> {
    return this.http.get<any>(
      API_INFO.getMyLibraryListActionButton
        .replace('<ISBN>', listItems.isbn)
        .replace('<ItemId>', listItems.itemId)
        .replace('<IsAvailable>', listItems.isAvailable === true ? 'true' : 'false')
        .replace('<IsRTV>', listItems.isRTV === true ? 'true' : 'false')
        .replace('<FormatType>', listItems.formatType)
        .replace('<isRecommendable>', listItems.isRecommendable === true ? 'true' : 'false')
        .replace('<TotalQuantity>', listItems.totalQuantity.toString() || '')
        .replace('<OnOrderQuantity>', listItems.onOrderQuantity.toString() || '')
        .replace('<CollectionType>', listItems.collectionType)
        .replace('<fromCheckoutHistory>', fromCheckoutHistory === true ? 'true' : 'false')
    );
  }
  /**
   * Gets MyLibraryListActionButton from API
   * @param listItems input param as listItems
   * @returns returns MyLibraryListActionButton
   */
  getMyLibraryListActionButtonV2(listItmes: ListContent): Observable<TitleStatusInfo[]> {
    return this.http.post<TitleStatusInfo[]>(API_INFO.getMyLibraryListActionButtonV2, listItmes);
  }
  /**
   * Gets MyLibraryListActionButton from API
   * @param listItems input param as listItems
   * @returns returns MyLibraryListActionButton
   */
  getMyLibraryListActionButtonFeaturedProgram(listItmes: any): Observable<TitleStatusInfo[]> {
    return this.http.post<TitleStatusInfo[]>(API_INFO.getMyLibraryListActionButtonFeaturedProgram, listItmes);
  }
  getFeaturedProgramAction(listItems: BookDetail, fromCheckoutHistory = false): Observable<any> {
    return this.http.get<any>(
      API_INFO.getMyLibraryListActionButton
        .replace('<ISBN>', listItems.isbn)
        .replace('<ItemId>', listItems.itemId)
        .replace('<IsAvailable>', listItems.isAvailable === true ? 'true' : 'false')
        .replace('<IsRTV>', listItems.isRTV === true ? 'true' : 'false')
        .replace('<FormatType>', listItems.formatType)
        .replace('<isRecommendable>', listItems.isRecommendable === true ? 'true' : 'false')
        .replace('<TotalQuantity>', listItems?.totalQuantity?.toString())
        .replace('<OnOrderQuantity>', listItems?.onOrderQuantity?.toString())
        .replace('<CollectionType>', listItems.collectionType)
        .replace('<fromCheckoutHistory>', fromCheckoutHistory === true ? 'true' : 'false')
    );
  }

  /**
   * Gets MyCollectionActionButton from API
   * @param listItems input param as listItems
   * @returns returns MyCollectionActionButton
   */
  getMyCollectionActionButton(listItems: BookDetail): Observable<any> {
    return this.http.get<any>(
      API_INFO.getCollectionActionButton
        .replace('<ISBN>', listItems.ISBN)
        .replace('<ItemId>', listItems.ItemId)
        .replace('<IsAvailable>', listItems.IsAvailable === true ? 'true' : 'false')
        .replace('<IsRTV>', listItems.IsRTV === true ? 'true' : 'false')
        .replace('<FormatType>', listItems.FormatType)
        .replace('<isRecommendable>', listItems.IsRecommendable === true ? 'true' : 'false')
        .replace('<TotalQuantity>', listItems.TotalQuantity.toString())
        .replace('<OnOrderQuantity>', listItems.OnOrderQuantity.toString())
        .replace('<CollectionType>', listItems.CollectionType)
    );
  }

  /**
   * Gets MyCollectionList from API
   * @param type input param as type
   * @returns returns MyCollectionList
   */
  getMyCollectionList(type: string): Observable<MyLibraryList[]> {
    return this.http.get<MyLibraryList[]>(API_INFO.getMyCollectionList.replace('<collectionType>', type));
  }

  /**
   * Gets MyCollectionContent from API
   * @param listId input param as listId
   * @param selectedFormat input param as Format Filter value
   * @returns returns MyCollectionContent
   */
  getMyCollectionContent(listId, selectedFormat, pageNumber = '1', limit = '12'): Observable<ListContent> {
    const isZoneEnable = this.configService.currentLibrary.axis360Enabled
    ? API_INFO.getMyCollectionContent
    : API_INFO.getMyCollectionContent.concat(`&profiletype=2`);
    const url = (this.profileInfoService.currentProfile.profileTypeId
      ? API_INFO.getMyCollectionContent.concat(`&profiletype=${this.getProfileId()}`)
      : isZoneEnable);
    return this.http.get<ListContent>(
      url.replace('<listId>', listId).replace('<pagenumber>', pageNumber).replace('<limit>', limit).replace('<format>', selectedFormat)
    );
  }

  getProfileId() {
    const isCurrentProfileType = this.profileInfoService.currentProfile.profileTypeId === '1'
      ? '2'
      : this.profileInfoService.currentProfile.profileTypeId;
    return (this.configService.currentLibrary.axis360Enabled
      ? this.profileInfoService.currentProfile.profileTypeId
      : isCurrentProfileType);
  }
}
