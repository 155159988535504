import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
    TITLE_LIST_SCREENS,
    TITLE_LIST_EVENTS_KEY,
    TITLE_LIST_EVENTS,
    TITLE_LIST_SCREENS_KEY,
    TITLE_LIST_CATEGORY
} from '../constants/title-list-anlytic-constants';
interface AltObj {
    [key: string]: string;
}
type Action = keyof typeof TITLE_LIST_EVENTS_KEY;
@Injectable({
    providedIn: 'root'
})
export class TitleListAnalyticsService extends AnalyticsService {
    /**
     * Creates an instance of notifications analytics service.
     */
    constructor() {
        super(TITLE_LIST_SCREENS, TITLE_LIST_EVENTS);
    }
    /**
     * To track programs page view
     * @param screen screen key to track
     */
    trackScreen(screen: keyof typeof TITLE_LIST_SCREENS_KEY, altObj: AltObj = {}) {
        this.trackPage(screen, altObj);
    }
    /**
     * To track programs event
     * @param action event action name
     * @param [altObj] to alter the tracking text
     */
    trackTitleListEvent(action: Action, altObj: AltObj = {}) {
        this.trackEvent(TITLE_LIST_CATEGORY, action, altObj);
    }
}
