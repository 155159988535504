import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ACTION_BUTTON_DATA, FORMAT_ICON_MAP, SHIMMER_COMPONENTS, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { LayoutComponent } from '@shared/data/third-party-model';
import { formatIssueDate, getBookIcon, getLocalDate, strToBool } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { Subscription } from 'rxjs';
import { ThirdPartyCalenderComponent } from '../shared/components/third-party-calender/third-party-calender.component';
import {
  FOCUS_ELEMENT,
  FORMAT_TYPE_MAP,
  THIRD_PARTY_SUBTYPE,
  THIRD_PARTY_TYPES,
  THIRD_PARTY_VENDOR_CODE,
  THIRD_PARTY_VENDOR_CODE_ENABLE
} from '../shared/constants/third-party.constants';
import { IAction } from '../shared/data/third-party-model';
import { getVideoProgressBasedOnItemIdInTier3 } from '../shared/helper/third-party-util';
import { ThirdPartyService } from '../shared/services/third-party.service';
import { ThirdPartyMulticountryAnalyticsService } from '../shared/services/third-party-multicountry-analytic.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { AGE_RANGE, TITLE_REVIEWS_TEST, UNLIMITED_COPIES, WITH_OUT_INTERNET_MSG_RATING } from '@titledetails/shared/constants/title-details.constants';
import { ISubjectList, ITitleDetailsRating } from '@titledetails/shared/data/title-details.model';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';

@Component({
  selector: 'axis360-third-party-card-details',
  templateUrl: './third-party-card-details.component.html',
  styleUrls: ['./third-party-card-details.component.scss']
})
export class ThirdPartyCardDetailsComponent implements OnInit, OnDestroy {
  @Input() componentData: LayoutComponent;
  @Input() isVenderEnabled: boolean;
  @Output() carouselEvent = new EventEmitter();
  @Output() carouselListEvent = new EventEmitter();
  compName = SHIMMER_COMPONENTS.THIRD_PARTY_DETAILS;
  compNameInfo = SHIMMER_COMPONENTS.TITLE_DETAILS_MORE_INFO;
  compNameCopies = SHIMMER_COMPONENTS.TITLE_DETAILS_COPIES_INFO;
  compNameButtons = SHIMMER_COMPONENTS.TITLE_DETAILS_BUTTONS;
  compNameRating = SHIMMER_COMPONENTS.TITLE_DETAILS_RATINGS;
  compNameItemDetails = SHIMMER_COMPONENTS.THIRD_PARTY_ITEM_DETAILS;
  THIRD_PARTY_VENDOR_CODE = THIRD_PARTY_VENDOR_CODE;
  componentApiData;
  itemsData;
  triggerOrigin: any;
  isOpen = false;
  isLoading = true;
  reloadDataAPISubscriptions: Subscription;
  thirdPartyDetailsClass = '';
  errorHandler = false;
  vendorCode: string;
  showParagraph = false;
  itemId: string;
  userName: string;
  isProgressBarShow = false;
  enableThirdPartyVendors = [];
  getVideoProgressBasedOnItemIdInTier3 = getVideoProgressBasedOnItemIdInTier3;
  @Output() firstTitleReview = new EventEmitter();
  @Output() scrollTitleReview = new EventEmitter();
  titleDetailsRating: ITitleDetailsRating;
  patronReviewsEnabled: boolean;
  collectionId: string;
  itemDetailsTitleComponent: LayoutComponent;
  itemDetailsInlineCTAComponent: LayoutComponent;
  itemDetailsInfoComponent: LayoutComponent;
  itemDetailsIconlistComponent: LayoutComponent;
  itemDetailsDescriptionComponent: LayoutComponent;
  itemDetailsDuedateComponent: LayoutComponent;
  itemDetailsMainCTAComponent: LayoutComponent;
  itemDetailsRatingsComponent: LayoutComponent;
  itemDetailsProgressbarComponent: LayoutComponent;
  itemDetailsTabLinkTitles: LayoutComponent;
  readingProgress = 0;
  showInsightsAndBadges = false;
  enableTracking = false;
  UNLIMITED_COPIES = UNLIMITED_COPIES;
  isVisibleMobile = false;
  constructor(
    private overlayContainer: OverlayContainer,
    private thirdPartyService: ThirdPartyService,
    public userService: UserService,
    private profileInfoService: ProfileInfoService,
    public enagageService: EngageBaseService,
    private matDialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public configService: ConfigService,
    private titleDetailsService: TitleDetailsService,
    private spinnerService: SpinnerService,
    public snackBarService: SnackBarService,
    public searchService: KzSearchService,
    public thirdPartyMulticountryAnalyticsService: ThirdPartyMulticountryAnalyticsService
  ) {
    overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
  }

  ngOnInit(): void {
    this.getData();
    window.scrollTo(0, 0);
    this.patronReviewsEnabled = this.configService.currentLibrary.reviews?.patronReviewsEnabled;
    this.enableTracking = strToBool(this.profileInfoService.currentProfile.enableTracking);
    const { id = '' } = this.activatedRoute.snapshot.params;
    const { vendor_code, collectionId = '' } = this.activatedRoute.snapshot.queryParams;
    this.collectionId = collectionId;
    this.vendorCode = vendor_code;
    this.enableThirdPartyVendors = this.configService.vendorDetails.map(vendor => vendor.vendor_code) || THIRD_PARTY_VENDOR_CODE_ENABLE;
    const { itemDetailsTitleComponent,
      itemDetailsInlineCTAComponent,
      itemDetailsInfoComponent,
      itemDetailsIconlistComponent,
      itemDetailsDescriptionComponent,
      itemDetailsDuedateComponent,
      itemDetailsMainCTAComponent,
      itemDetailsRatingsComponent,
      itemDetailsProgressbarComponent,
      itemDetailsTabLinkTitles } = this.thirdPartyService;
    this.itemDetailsTitleComponent = itemDetailsTitleComponent;
    this.itemDetailsInlineCTAComponent = itemDetailsInlineCTAComponent;
    this.itemDetailsInfoComponent = itemDetailsInfoComponent;
    this.itemDetailsIconlistComponent = itemDetailsIconlistComponent;
    this.itemDetailsDescriptionComponent = itemDetailsDescriptionComponent;
    this.itemDetailsDuedateComponent = itemDetailsDuedateComponent;
    this.itemDetailsMainCTAComponent = itemDetailsMainCTAComponent;
    this.itemDetailsRatingsComponent = itemDetailsRatingsComponent;
    this.itemDetailsProgressbarComponent = itemDetailsProgressbarComponent;
    this.itemDetailsTabLinkTitles = itemDetailsTabLinkTitles;
    this.reloadDataAPISubscriptions = this.thirdPartyService.getReloadDataAPI().subscribe((item) => {
      if (item && item.pageName !== '' && this.componentData.type === THIRD_PARTY_TYPES.ITEM_DETAILS) {
        this.isLoading = true;
        this.componentApiData = undefined;
        this.itemsData = {};
        this.getData(item.actiontype);
      }
    });
    this.itemId = id;
    this.userName = this.profileInfoService.profileInfo.profileName;
    this.thirdPartyMulticountryAnalyticsService.trackScreen('LANDING_TIRE3');
    this.onResize();
  }
  updateLatestIssue(componentApiData) {
    const { key, issueDate } = this.activatedRoute.snapshot.queryParams;
    if (key && issueDate) {
      componentApiData.issueDate = componentApiData.latestIssue.issueDate;
      componentApiData.latestIssue = { key, issueDate };
    }
    this.thirdPartyService.setLatestIssueData.next({
      displayName: componentApiData.displayName,
      country: componentApiData.countrySlug,
      type: componentApiData.type
    })
    this.componentApiData = componentApiData;
  }
  toggle(trigger: any) {
    this.triggerOrigin = trigger;
    this.isOpen = !this.isOpen;
  }

  onResize() {
    if (window.innerWidth <= 560) {
      this.isVisibleMobile = true;
    } else {
      this.isVisibleMobile = false;
    }
  }

  overlayDetached() {
    this.isOpen = false;
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementById('issuedate')?.focus();
  }
  getDataFromApi() {
    const { id = '' } = this.activatedRoute.snapshot.params;
    const { vendor_code, collectionId = '' } = this.activatedRoute.snapshot.queryParams;
    this.componentData.dataApi.pathParams = { id };

    if (vendor_code !== THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
      const isLoggedIn = this.userService.isLoggedIn();
      // Non-loggedIn User access the Tier3 page
      if (isLoggedIn) {
        this.componentData.dataApi.search = {
          ...this.componentData.dataApi.search,
          itemID: id,
          contentCollectionId: collectionId,
          libraryId: this.configService.currentLibrary.id,
          patronID: this.userService.getPatronId(),
          profileid: this.profileInfoService.currentProfile.profileId || ''
        };
      } else {
        this.componentData.dataApi.search = {
          ...this.componentData.dataApi.search,
          itemID: id,
          contentCollectionId: collectionId,
          libraryId: this.configService.currentLibrary.id
        };
        /* tslint:disable */
        delete this.componentData.dataApi.search['patronID'];
        delete this.componentData.dataApi.search['profileid'];
      }
    }
    return this.thirdPartyService.getDataFromDataApi(this.componentData.dataApi);
  }

  synopsisMoreLess(showSynopsis, id) {
    this.showParagraph = showSynopsis;
    setTimeout(() => {
      const showSynopsisId = document.getElementById(`${id}`);
      showSynopsisId?.focus();
      showSynopsisId && showSynopsisId?.setAttribute('aria-live', 'assertive');
    }, 1000);
  }

  async getData(actiontype: string = "") {
    try {
      const componentApiData = await this.getDataFromApi();
      if (this.componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS) {
        const { collectionId } = this.activatedRoute.snapshot.queryParams;
        const itemDetails = 'itemDetailResponseV6';
        this.thirdPartyDetailsClass = 'third-party-videos-detail third-party-card-new-details';
        this.componentApiData = componentApiData[itemDetails].getTitleResult.titleInfo || {};
        this.thirdPartyService.breadBookTitle.next(this.componentApiData.bookTitle);
        this.itemsData = componentApiData[itemDetails].getTitleResult.titleInfo || {};
        this.itemsData.collectionId = collectionId;
        this.itemsData.vendorCode = (this.vendorCode || '');
        this.componentApiData = this.profileInfoService.currentProfile.profileId
          ? await this.getVideoProgressBasedOnItemIdInTier3.apply(this)
          : this.itemsData;
        this.componentApiData.isVendorEnable = this.isVenderEnabled.toString();
        this.componentApiData.ageRangeList = AGE_RANGE[this.componentApiData.audience + ', Age ' + this.componentApiData.ageRange];
        this.componentApiData.subjectList = this.getSubjects(this.componentApiData.subjects, 0);
        this.componentApiData.availableCopies = Number(this.componentApiData.availableCopies);
        this.componentApiData.totalCopies = Number(this.componentApiData.totalCopies);
        this.componentApiData.RTV = strToBool(this.componentApiData.RTV);
        const profileFeatures = this.configService.getProfileFeatures(
          this.profileInfoService.currentProfile.profileType.toLowerCase()) || {};
        this.showInsightsAndBadges = profileFeatures.insightsBadgesEnabled;
        this.getTitleReview(this.componentApiData.titleID);
        if (this.userService.isLoggedIn()) {
          this.getReadingProgressBasedOnItemId([this.componentApiData.titleID]);
        }
        this.secondaryOptionsCTA(actiontype);
        const titleLength = (this.componentApiData.bookTitle || '').length;
        const delay = titleLength * 250 || 2500;
        if (actiontype === ACTION_BUTTON_DATA.ADD_WISHLIST) {
          setTimeout(() => {
            document.getElementById('thirdPartyAfterCtaFocusRemove')?.focus();
          }, delay);
        }
        if (actiontype === ACTION_BUTTON_DATA.REMOVE_WISHLIST) {
          setTimeout(() => {
            document.getElementById('thirdPartyAfterCtaFocusAdd')?.focus();
          }, delay);
        }
        setTimeout(() => {
          const ctaId = document.getElementById('loc_cta_id');
          ctaId?.removeAttribute('aria-hidden');
        }, 1000);
        this.carouselListEvent.emit(this.componentApiData);
        this.carouselEvent.emit(true);
        this.isLoading = false;
        return;
      }
      this.thirdPartyDetailsClass = 'third-party-news-paper';
      this.updateLatestIssue(componentApiData);
      this.carouselEvent.emit(true);
      this.isLoading = false;
    } catch (e) {
      this.carouselEvent.emit(false);
      (e.status === 400 || e.status === 401 || e.status === 500 || e.statusCode === 500) && (this.errorHandler = true);
      this.isLoading = false;
    }
  }

  secondaryOptionsCTA(eventId) {
    const sec = this.titleDetailsService.statusUserAgentCheck ? 5000 : 2000;
    const btnFocusId = FOCUS_ELEMENT[eventId];
    setTimeout(() => {
      if (btnFocusId && btnFocusId.btn === 'moreOption') {
        document.getElementById(`${btnFocusId.btnId}`)?.focus();
      }
    }, sec);
  }

  selectIssue = (action: IAction) => {
    const { issueDate, has: cid, titleIssueDate } = action.data;
    const { vendor_code, title } = this.activatedRoute.snapshot.queryParams;
    const formattedIssueDate = formatIssueDate(issueDate);
    const currentIssue = getLocalDate(formattedIssueDate);
    const currentTitleIssue = getLocalDate(titleIssueDate);
    this.matDialog.open(ThirdPartyCalenderComponent, {
      panelClass: 'select-issue-calendar',
      backdropClass: 'select-issue-calendar-backDrop',
      data: {
        currentIssue,
        currentTitleIssue,
        cid,
        vendor_code,
        title
      },
      autoFocus: true,
      disableClose: false,
      ariaLabelledBy: 'select-issue-heading'
    });
  };
  acOnExecuteAction(action: IAction) {
    document.querySelectorAll('#countryarrow').forEach((n) => {
      n.setAttribute('title', '');
      n.setAttribute('aria-label', '');
      
    });
    if (action?.title && action.title === 'Show more') {
      const showmore: HTMLElement = document.querySelector('#countryuparrow') as HTMLElement;
      document.querySelector('#countrydownarrow')?.setAttribute('aria-hidden', 'true');
      showmore.setAttribute('aria-hidden', 'false');
      setTimeout(() => {
        const matchResult = /(iPhone|iPod|iPad)/i.exec(navigator.userAgent);
        if (matchResult) {
          showmore.setAttribute('tabindex', '0');
          showmore && showmore.focus();
        } else {
          const img: any = showmore.querySelector('.ac-image');
          img && img.focus();

        }
      }, 0);
      return this.thirdPartyMulticountryAnalyticsService.trackMultipleCountryEvent('ACTIONS_SEE_ALL');
    }
    if (action?.title && action.title === 'Show less') {
      const showless = document.querySelector('#countrydownarrow') as HTMLElement;
      document.querySelector('#countryuparrow')?.setAttribute('aria-hidden', 'true');
      showless.setAttribute('aria-hidden', 'false');
      setTimeout(() => {
        const matchResult = /(iPhone|iPod|iPad)/i.exec(navigator.userAgent);
        if (matchResult) {
          showless.setAttribute('tabindex', '0');
          showless && showless.focus();
        } else {
          const img: any = showless.querySelector('.ac-image');
          img && img.focus();
        }
      }, 0);
      return this.thirdPartyMulticountryAnalyticsService.trackMultipleCountryEvent('ACTIONS_SEE_LESS_COUNTRY');
    }
    if (action.data && action.data.type === 'select_issue') {
      this.selectIssue(action);
      return;
    }
    if (action?.data) {
      this.thirdPartyService.cardAction(action);
    }
  }
  /**
   * Destroy the All subscription Data
   */
  ngOnDestroy(): void {
    if (this.reloadDataAPISubscriptions) {
      this.reloadDataAPISubscriptions.unsubscribe();
      this.thirdPartyService.setReloadDataAPI(false);
    }
  }

  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType: string) {
    return getBookIcon(`KZ_${formatType}`);
  }

  /**
   * Returns the icon for the given format type.
   * @param formatType format type
   * @returns icon name
   */
  getFormatType(formatType: string) {
    return FORMAT_TYPE_MAP[FORMAT_ICON_MAP[`KZ_${formatType}`]?.icon];
  }

  /**
   * getSubjectListObject action button
   * @param [subject] pass the Object of Subject list to split the key and value
   * @returns getSubjectListObject
   */
  getSubjectListObject(subject: ISubjectList) {
    const keyArray = Object.keys(subject);
    const subjectList = [];
    /* tslint:disable */
    for (const key of keyArray) {
      subjectList.push(subject[key]);
    }
    return subjectList;
  }

  /**
   * Get Title Review BasedOn Itemid
   * @param [itemId] Pass Review ItemId
   * @returns List out reviewed titles.
   */
  async getTitleReview(itemId) {
    if (!itemId) {
      return;
    }
    const {
      BookLength,
      AxisAttribute,
      audience,
      ageRange,
      format,
      language,
      publicationDate,
      bookTitle,
      subjectList,
      runtime
    } = this.componentApiData;
    this.titleDetailsService.getAllUserRatings(itemId).then((bookReviewResponse) => {
      if (!bookReviewResponse) {
        return;
      }
      const { totalRatingCount, averageRating, userReview, otherUserReview } = bookReviewResponse;

      this.titleDetailsRating = {
        itemId,
        bookTitle,
        language: language ? language.slice(0, 3) : '',
        languageDesc: language,
        publicationDate,
        ageRange: this.thirdPartyService.getAgeRange({audience: audience, ageRange: ageRange}) || undefined,
        length: length.toString(),
        formatType: this.componentApiData ? this.getIcon(format) : { icon: '', alt: '' },
        formatTypeName: this.componentApiData ? this.getFormatType(format) : '',
        reviewsCount: totalRatingCount,
        userAvgStarRating: userReview?.rating,
        otherUserReviewCount: otherUserReview.length || 0,
        avgStarRating: averageRating ? Number(averageRating.toFixed(1)) : 0,
        axisAttribute: AxisAttribute,
        subjectName: subjectList ? subjectList[0]?.subject : '',
        patronReviewsEnabled: this.patronReviewsEnabled,
        BookLength,
        runTime: runtime.replace('hours', 'h').replace('minutes', 'm')
      };
    }, () => {
      this.titleDetailsRating = TITLE_REVIEWS_TEST;
    });
  }

  /**
   * Get Reading Progress.
   * @param [bookIds] Pass Item Id in Progress title.
   */
  async getReadingProgressBasedOnItemId(bookIds: string[]) {
    const bookDetail = { bookIdList: bookIds };
    const booksDetailsRes = await this.enagageService.getBookProgress(bookDetail);
    if (booksDetailsRes.responseCode === 200) {
      this.readingProgress = Math.round(booksDetailsRes.bookProgress[0].progressPercentage);
      const profileFeatures = this.configService.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase()) || {};
      this.showInsightsAndBadges = profileFeatures.insightsBadgesEnabled;
      this.isProgressBarShown();
    }
  }

  /**
   * Check the ProgressBarShow or Hide.
   */
  isProgressBarShown() {
    this.isProgressBarShow = (this.enableTracking && this.readingProgress > 0 && this.showInsightsAndBadges)
  }

  /**
   * FirstTimeReviewTitle funcation
   * @param [count] pass reviewCount
   * @returns emit the function
   */
  firstTimeReviewTitle(count) {
    if (count > 0) {
      this.scrollTitleReview.emit(count);
      return;
    }
    this.firstTitleReview.emit(count);
  }

  /**
   * Save the Title rating in submitReview API
   */
  async handleRating(rating: string) {
    if (rating.length === 0) {
      return;
    }
    await this.spinnerService
      .withPromise(
        this.titleDetailsService.submitRating(this.itemId, '', new Date().toISOString(), this.userName, rating, ''))
      .then(
        (res) => {
          if (!res.ratingReviewResponse.ratingSubmitted) {
            this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, res.message);
            return;
          }
          const { profileType } = this.profileInfoService.currentProfile || {};
          const { totalRatingCount, otherUserReview, ratingSubmitted, averageRating } = res.ratingReviewResponse;
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, `Thanks for adding your rating!.`);

          this.titleDetailsService.setUserRatingTitle({
            avgStarRating: Number(averageRating.toFixed(1)),
            reviewLength: totalRatingCount,
            userAvgStartRating: Number(rating),
            reviews: otherUserReview,
            otherUserReviewCount: otherUserReview?.length || 0,
            submitted: ratingSubmitted,
            profileType
          });
        },
        () => {
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, WITH_OUT_INTERNET_MSG_RATING);
        }
      );
  }

  /**
   * To get Subjects formatting
   * @param [data] to pass the data in subject object
   * @param [key] set key of array object
   * @returns get formatted subjct list
   */
  getSubjects = (data: any, key: number): any => {
    const stringSubjects = data?.subject?.[key].subjectName || "";
    const originalSubjects = stringSubjects?.split(";");
    const subjectCodes: Array<any> = [];
    const subjects: Array<string> = [];

    originalSubjects.forEach((sub: string): any => {
      const listOfSubjects = sub?.split("/");
      listOfSubjects.forEach((subject: string, index: number): any => {
        const isDuplicate = subjects.find((word) => word === subject.trim());
        if (subject && !isDuplicate) {
          if (index > 0) {
            const path: Array<string> = [];
            for (let i = 0; i <= index; i++) {
              path.push(listOfSubjects[i]);
            }
            const finalPath = path.join("/");
            subjectCodes.push(finalPath);
          } else {
            subjectCodes.push(subject.trim());
          }
          subjects.push(subject.trim());
        }
      });
    });
    const result = {};
    subjects.forEach((subject, index) => {
      const term = subjectCodes.slice(index, index + 1).join('/');
      result[`subject${index + 1}`] = { subject, term };
    });
    return this.getSubjectListObject(result);
  };

   actionThirdpartyDetailsSearch(action: string, value: string) {
    ((action === 'ACTIONS_AUTHOR') || (action === 'ACTIONS_NARRATOR')) && this.searchService.updatedSearchText.next(value);
  }

}
