<div class="kz-main kz-profile-pin" [ngClass]="{'kz-confirm-main': isConfirm}">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{pageAnnounce}}</p>
    <div class="kz-close-container" *ngIf="!patronTypeAuthentication">
        <button class="btn-nobg-bluecolor mb-0" mat-dialog-close aria-label="Close" (click)="closeDialog()">
            <mat-icon class="btn-close close-icon" svgIcon="close" aria-hidden="true"></mat-icon>
        </button>
    </div>
    <div class="kz-title" [ngClass]="{'kz-confirm-title': isConfirm}">
        <h1 class="kz-heading" id="loc_txtProfileManagementPin" *ngIf="profilePinData.type !== 'disable' && !isResetPin && !showOTP && !isSetProfile && !isConfirm">Enter Profile PIN</h1>
        <h1 class="kz-heading" id="loc_txtProfileManagementPin" *ngIf="showOTP">PIN Verification</h1>
        <h1 class="kz-heading" *ngIf="isResetPin">
            <span *ngIf="profilePinData.type !== 'disable'"  id="loc_txtProfileManagementPin">Reset Profile PIN</span>
            <span *ngIf="profilePinData.type === 'disable'" id="loc_txtProfileManagementPin" aria-live="polite">Forgot PIN</span>
        </h1>
        <p *ngIf="showOTP" class="kz-sub-text mb-1" id="loc_textalertcontent">Please enter the one-time PIN that was sent to your email.</p>
        <h1 *ngIf="isConfirm" class="kz-heading" axis360AnnounceElem id="loc_txtProfileManagementPin">Confirm Profile PIN</h1>
        <h1 class="kz-heading" id="loc_txtProfileManagementPin" *ngIf="isSetProfile && !isConfirm">Create a Profile PIN</h1>
        <h1 class="kz-heading" *ngIf="profilePinData.type === 'disable' && !isResetPin && !showOTP" id="loc_txtProfileManagementPin">Disable Profile PIN</h1>
        <p *ngIf="isSetProfile && !isConfirm" class="kz-sub-text mb-1" id="loc_textalertcontent">You will need this PIN to access and manage your profile</p>
        <p *ngIf="!isConfirm && !isSetProfile && !isResetPin && !showOTP && profilePinData.type === 'disable'" class="kz-sub-text mb-1"
            axis360AnnounceElem id="loc_textalertcontent">Please enter your Profile PIN</p>
            <p *ngIf="!isConfirm && !isSetProfile && !isResetPin && !showOTP && profilePinData.type !== 'disable'" class="kz-sub-text mb-1"
            axis360AnnounceElem id="loc_textalertcontent">Please enter {{profilePinData.name}}'s Profile PIN</p>
        <p *ngIf="false" class="kz-sub-text mb-1" id="loc_textalertcontent">Please enter the one-time PIN that was
            sent to your email </p>
        <p *ngIf="false" class="kz-sub-text mb-1" axis360AnnounceElem id="loc_textalertcontent">A one-time PIN will be
            sent to your email</p>
        <p class="kz-visually-hidden" aria-live="polite" *ngIf="isAnnouncerSendMailMsg">
                Success! Email sent successfully</p>
        <p class="kz-visually-hidden" role="alert" *ngIf="isAnnouncerPINSetMsg">
            Success! Your Profile PIN has been set.</p>
        <p *ngIf="isResetPin" class="kz-sub-text mb-1">
            <span *ngIf="profilePinData.type !== 'disable'" id="loc_textalertcontent">An email with instructions to reset your PIN will be sent to {{profilePinData.email}}</span>
            <span *ngIf="profilePinData.type === 'disable'" id="loc_textalertcontent" aria-live="polite">An email with instructions to disable your PIN will be sent to {{profilePinData.email}}</span>
        </p>
    </div>
    <div class="kz-pin"  [ngClass]="{'kz-on-confirm': isConfirm}">
        <div class="verification-code-inputs" *ngIf="showPinInput">
            <axis360-ng-otp-input  (pinNumber)="pinNumberChange($event)" [isSetProfile]="isSetProfile" [inputValid] ="inputValid" [isConfirm] = "isConfirm" (keydown.enter)="showOTP ? isdone && validateOTP() : submitPin();$event.stopPropagation();" title=""></axis360-ng-otp-input>
            <p *ngIf="!isMatch"  class="error-pin-text" role="alert" id="pin-notmatch">The PINs you entered do not match. Please retry.</p>
            <p *ngIf="!isValidPin || inValidAttempts > 0" class="error-pin-text" role="alert" id="pin-notvalid">Hmm, the Profile PIN you entered is not correct. Please try again.</p>
            <p *ngIf="showOTP" class="primary-para pin-resend-text">Didn’t receive a PIN? <button
                    class="btn-nobg-bluecolor resend-btn" (click)="sendOTP()">Resend
                    PIN</button></p>
            <button class="btn-nobg-bluecolor forget-pin" *ngIf="action != 'create' && !showOTP" (click)="resetPin()"
                (keydown.enter)="resetPin()">Forgot
                PIN</button>
        </div>
    </div>
    <div class="done_Submit">
        <button *ngIf="isConfirm" class=" btn-secondary-nobg backBtn"
            (click)="isSetProfile = true ;isConfirm = false;isMatch = true; inputValidation(true);profilesAnalyticsService.trackProfilesEvent('BACK_PIN');">Back</button>
        <button *ngIf="!isResetPin && !showOTP" class="doneBtn " id="loc_btnSubmit" [disabled]="!isdone"
            [ngClass]="{'kz-access-pin':!isSetProfile, 'active': isdone, 'kz-answer-btn' : isResetPin}"
            (click)="submitPin();$event.stopPropagation();" (keydown.enter)="$event.preventDefault();submitPin()">
            <span *ngIf="profilePinData.type !== 'disable'">Done</span>
            <span *ngIf="profilePinData.type === 'disable'">Disable</span>
        </button>
        <button *ngIf="showOTP" class="doneBtn " id="loc_btnSubmit" [disabled]="!isdone"
        [ngClass]="{ 'active': isdone}" (click)="isdone && validateOTP()"
            >
           Submit
        </button>
        <button mat-button *ngIf="isResetPin" class="doneBtn resetPin active" id="loc_sendEmail" aria-describedby="toast-error-heading toast-error-description"
         cdkFocusInitial (click)="sendOTP()">Send
            email</button>
    </div>
</div>
