import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import { BookDetail } from '@shared/data/config.model';
import { getBookIcon } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';

@Component({
  selector: 'axis360-home-my-library-list-card',
  templateUrl: './home-my-library-list-card.component.html',
  styleUrls: ['./home-my-library-list-card.component.scss']
})
export class HomeMyLibraryListCardComponent implements OnInit {
  @Input() myListContent: BookDetail;
  @Input() usedIn: string;
  @Input() type = '';
  @Input() title = '';
  @Output() titleCheckedOutEvt = new EventEmitter();
  showActionCTA = false;
  titleAriaLabel: string;
  fromHomeCarousel = true;
  /**
   * Creates an instance of press reader card component.
   * @param pressReaderService press reader service
   * @param router router info
   */
  constructor(
    public pressReaderService: PressReaderService,
    public pressReaderAnalyticsService: PressReaderAnalyticsService,
    public configService: ConfigService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon('favorite', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/favorite.svg'));
  }

  ngOnInit(): void {
    this.titleAriaLabel = `${this.myListContent.Title} by ${this.myListContent.Author}, ${this.getIcon(this.myListContent.FormatType).alt}`;
  }

  /**
   * To invoke read now from press reader service
   * @param event pointer event
   * @param redirectionUrl redirection url
   */
  readNow(event: PointerEvent, redirectionUrl: string) {
    this.pressReaderAnalyticsService.trackPressReaderEvent('READ_NOW');
    this.pressReaderService.readNow(event, redirectionUrl);
  }

  /**
   * redirect to press reader detail page
   */
  cardClick(type: string) {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CARD_DETAIL');
    window.location.href = `/Title/TitleDetails?itemId=${this.myListContent.ItemId}&ISBN=${this.myListContent.ISBN}&isRecommendable=${this.myListContent.IsRecommendable}&collectionType=${type}`;
  }
  /**
   * To get Book Icon
   * @param formatType input param as formatType
   * @returns returns getBookIcon
   */
  getIcon(formatType) {
    return getBookIcon(`HOME_${formatType}`);
  }
  /**
   * To get Title Image
   * @param isbn input param as isbn
   * @returns returns getTitleImage
   */
  getTitleImage(isbn: string) {
    return this.configService.appSettings.titleImageSrcFormat?.replace('{0}', isbn);
  }
  /**
   * to get titleCheckedOutEvent
   */
  titleCheckedOutEvent() {
    this.titleCheckedOutEvt.emit();
  }
}
