import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { redirectUrl } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-kz-join-program-popup',
  templateUrl: './kz-join-program-popup.component.html',
  styleUrls: ['./kz-join-program-popup.component.scss']
})
export class KzJoinProgramPopupComponent {
  constructor(
    public joinProgramDialog: MatDialogRef<KzJoinProgramPopupComponent>,
    public router: Router,
    public programsAnalyticsService: ProgramsAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public programDetails: ProgramDetails
  ) {}
  /**
   * After Joined program navigate to MyProgarm Page
   */
  onJoinSelected(selectedCard: string) {
    this.programsAnalyticsService.trackNotificationsEvent('JOIN_PROGAM');
    this.joinProgramDialog.close();
    redirectUrl(this.router, `/view/programs/${selectedCard}`, true);
  }
  /**
   * colse join program Dialog
   */
  closeDialog() {
    this.joinProgramDialog.close();
    this.router.navigateByUrl('/view/programs/open');
  }
}
