import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { BookDetailAPIRes, BookDetails, BookSearchRequest, ReadingProgressRes } from '@shared/data/engage-base.model';
import { ConfigService } from './config.service';
import { ProfileInfoService } from './profile-info.service';
@Injectable({
  providedIn: 'root'
})
export class EngageBaseService {
  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) {}
  /**
   * Gets books by id
   * @returns books by id
   */
  getBooksById(itemIdList: string[] | number[], fetchStateInfo = false): Promise<BookDetailAPIRes> {
    return this.http
      .get<BookDetailAPIRes>(
        API_INFO.getBooksById.replace('<itemIdList>', itemIdList.join(',')).replace('<fetchStateInfo>', fetchStateInfo.toString())
      )
      .toPromise();
  }
  /**
   * Gets books by id
   * @returns books by id
   */
  getBooksByIdV1(itemIdList: string[] | number[], fetchStateInfo = false): Promise<BookDetailAPIRes> {
    const bookSearchRequest: BookSearchRequest = { fetchStateInfo, itemIdList: itemIdList.join(',') };
    return this.http.post<BookDetailAPIRes>(API_INFO.getBooksByIdV1, bookSearchRequest).toPromise();
  }
  /**
   * Gets related books by id
   * @param itemIdList to be passed as param
   * @returns related books by id
   */
  getRelatedBooksById(itemIdList: string[] | number[], collectionType = ''): Promise<BookDetailAPIRes> {
    return this.http.get<BookDetailAPIRes>(API_INFO.getRelatedBooksById.replace('<itemIdList>', itemIdList.join(',')).replace('<collectionType>', collectionType)).toPromise();
  }
  /**
   * Gets books by isbn
   * @returns books by isbn
   */
  getBooksByIsbn(isbnList: string[]): Promise<BookDetailAPIRes> {
    return this.http.get<BookDetailAPIRes>(API_INFO.getBooksByIsbn.replace('<isbnList>', isbnList.join(','))).toPromise();
  }
  /**
   * Gets book progress
   * @returns book progress
   */
  getBookProgress(bookIdList: BookDetails): Promise<ReadingProgressRes> {
    return this.http.post<ReadingProgressRes>(API_INFO.getBookProgress, bookIdList).toPromise();
  }
}
