import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[axis360RemoveTabIndex]'
})
export class RemoveTabIndexDirective implements AfterViewInit {
  constructor(private elem: ElementRef) {}

  ngAfterViewInit() {
    this.elem.nativeElement.setAttribute('tabindex', -1);
  }
}
