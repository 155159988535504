<section class="achievement-section" *ngIf="isLoading || insights.length > 0">
    <div class="actieve-main">
        <div class="achive-card insight-sec">
            <div class="insight-main">
                <div class="achev-heading">
                    <h2 class="axis-achev-title">Goals & Insights</h2>
                </div>
                <div class="insight-pref insightpref-desk">
                    <div class="insight-container">
                        <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
                        <div class="insight-item" *ngIf="!isLoading && insights.length > 0">
                            <div role="button" class="insight-widget" tabindex="0" [attr.aria-label]="insight.ariaLabel"
                                *ngFor="let insight of insights" (click)="openSetGoal(insight)"
                                (keyup.enter)="openSetGoal(insight)">
                                <div class="widget-set" aria-hidden="true">
                                    <div class="insight-item-main {{insight.styleClass}}"
                                        [ngClass]="{'watch-goal': insight.goal}">
                                        <mat-progress-spinner *ngIf="insight.percentage" mode="determinate"
                                            [value]="insight.percentage" [strokeWidth]="10" class="insight-progress">
                                        </mat-progress-spinner>
                                        <span class="insight-icon">
                                        </span>
                                    </div>
                                    <div class="insight-info-main">
                                        <span class="insight-value {{insight.styleClass}}"
                                            [ngClass]="{'watch-goal': insight.goal}">
                                            {{insight.insightMetrics}}
                                        </span>
                                        <span class="info-message">
                                            {{insight.insightSubTitle}}
                                        </span>
                                    </div>
                                </div>
                                <span class="widget-name" aria-hidden="true">{{insight.insightTitle}}</span>
                                <img class="pencil-icon" src="/angular-app/assets/images/pencil.png" alt="" title="" aria-hidden="true">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="insight-pref insight-pref-mob">
                    <div class="insight-container">
                        <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
                        <div class="insight-item" *ngIf="!isLoading && insights.length > 0">
                            <p-carousel axis360CarouselAda class="parentCarousel hide-dots" [value]="insights"
                                [circular]="false" [numVisible]="numVisible" [numScroll]="numScroll" [circular]="false"
                                [responsiveOptions]="responsiveOptions">
                                <ng-template let-item pTemplate="item">
                                    <axis360-insights-cards-carousel [insight]="item"
                                        (openSetGoalEvent)="openSetGoal($event)"></axis360-insights-cards-carousel>
                                </ng-template>
                            </p-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>