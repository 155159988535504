<axis360-shimmer-cards *ngIf="!titleStateInfo" [compName]="actionLoadingComponent" [showCarouselTitle]="false">
</axis360-shimmer-cards>
<ng-container *ngIf="titleStateInfo">
    <div class="duration-sec {{durationSecClass}}" tabindex="-1" aria-hidden="true"
        attr.aria-label="{{titleStateInfo.displayStatus.replace('(s)', 's') | titlecase}}"
        *ngIf="isDueDate && titleStateInfo.displayStatus && ((titleStateInfo.displayStatus != onHold && titleStateInfo?.displayStatus !== comingSoon && titleStateInfo.displayStatus !== pastCheckout &&
        titleStateInfo.displayStatus != unavailable && titleStateInfo.displayStatus !== requested && titleStateInfo.displayStatus !== readyForCheckout && !titleStateInfo?.displayStatus?.startsWith('Due')) || (titleStateInfo?.displayStatus?.startsWith('Due In')) && activeMyStuff !== 'recommendations') ||  (isProgramCTAShow && titleStateInfo?.displayStatus?.startsWith('Due In'))">
        <span aria-hidden="false" class="due-duration {{dueDurationClass}}">{{ titleStateInfo.displayStatus |
            titlecase}}</span>
    </div>
    <div class="duration-sec {{durationSecClass}}"
        *ngIf="isDueDate && titleStateInfo.displayStatus && titleStateInfo.displayStatus === holdSuspended && (activeMyStuff == 'recommendations')">
        <span class="due-duration {{dueDurationClass}}">{{titleStateInfo.displayStatus | titlecase}}</span>
    </div>
    <div class="duration-sec {{durationSecClass}} dark-top-banner-bg" tabindex="-1" aria-hidden="true"
        *ngIf="isDueDate && titleStateInfo.displayStatus && (titleStateInfo?.displayStatus?.startsWith('Due') && !titleStateInfo?.displayStatus?.startsWith('Due In')) && activeMyStuff !== 'recommendations'">
        <span class="due-duration {{dueDurationClass}}">{{titleStateInfo.displayStatus | titlecase}}</span>
    </div>
    <div class="duration-sec {{durationSecClass}} dark-top-banner-bg"
        *ngIf="isDueDate && titleStateInfo.displayStatus && ((titleStateInfo.displayStatus == onHold && !bookDetail.IsAvailable) || (activeMyStuff == 'recommendations' && (titleStateInfo.displayStatus == 'RECOMMENDED' || titleStateInfo.displayStatus == 'REQUESTED') && titleStateInfo.holdPosition))"
        aria-hidden="true" tabindex="-1">
        <span class="due-duration {{dueDurationClass}}" aria-hidden="true" tabindex="-1"><span class="sr-only"
                aria-hidden="true" tabindex="-1">Status
                &nbsp;</span>{{titleStateInfo.holdPosition}}</span>
    </div>
    <div class="duration-sec {{durationSecClass}} carousel-card-ribbon-available" tabindex="-1" aria-hidden="true"
        *ngIf="isDueDate && titleStateInfo.displayStatus === 'REQUESTED' && (!bookDetail.IsAvailable && activeMyStuff !== 'recommendations')">
        <span class="due-duration {{dueDurationClass}}">{{titleStateInfo.displayStatus === 'REQUESTED'?
            'Purchase Request': titleStateInfo.displayStatus | titlecase}}</span>
    </div>
    <div class="duration-sec {{durationSecClass}} carousel-card-ribbon-available"
        attr.aria-label="{{titleStateInfo.displayStatus.replace('(s)', 's') | titlecase}}"
        *ngIf="isDueDate && (titleStateInfo.displayStatus === readyForCheckout)">
        <span class="due-duration {{dueDurationClass}}"><span class="sr-only">Status &nbsp;</span>{{readyForCheckout |
            titlecase}}</span>
    </div>
    <div class="duration-sec {{durationSecClass}} carousel-card-ribbon-available"
        *ngIf="isDueDate && (titleStateInfo.displayStatus == readyForCheckout) && (activeMyStuff === 'holds' || activeMyStuff === 'wishlist')">
        <span class="due-duration {{dueDurationClass}}"><span class="sr-only">Status &nbsp;</span>{{readyForCheckout |
            titlecase}}</span>
    </div>

    <div class="duration-sec {{durationSecClass}} carousel-card-ribbon-past-checkout" tabindex="-1" aria-hidden="true"
        *ngIf="isDueDate && titleStateInfo.displayStatus === pastCheckout && activeMyStuff != 'history'">
        <span class="due-duration {{dueDurationClass}}" tabindex="-1" aria-hidden="true">{{pastCheckout}}</span>
    </div>

    <div class="duration-sec" *ngIf="(isHold || isRecommendation) && titleStateInfo.holdPosition ">
        <p class="hold-position" [attr.aria-label]="titleStateInfo.holdPosition">{{titleStateInfo.holdPosition}}
            <span role="button" aria-label="More Info" (click)="holdPositionAlertPopup($event)"
                (keydown.enter)="holdPositionAlertPopup($event)" tabindex="0">
                <mat-icon svgIcon="kz-info-icon" aria-hidden="true">
                </mat-icon>
            </span>
        </p>
        <span></span>
    </div>
    <div class="duration-sec" *ngIf="isHold && titleStateInfo.reserveDuration">
        <p class="hold-position">
            {{titleStateInfo.reserveDuration}}
        </p>
    </div>
    <div class="action-buttons {{kzActionMenuItemClass}}" *ngIf="(!isDueDate || isProgramCTAShow)">
        <ng-container *ngIf="!isMyStuff">
            <div class="duration-sec"
                *ngIf="(bookDetail?.FormatType === 'ABT'  && bookDetail.runTime) || titleStateInfo.displayStatus ">
                <span class="duration-hours sr-only"
                    *ngIf="bookDetail?.FormatType === 'ABT' && bookDetail.runTime">{{bookDetail.runTime.replace('h',
                    'hours').replace('m','minutes')}}</span>
                <span class="duration" aria-hidden="true"
                    *ngIf="bookDetail?.FormatType === 'ABT' && bookDetail.runTime">{{bookDetail.runTime}}</span>
                <span class="due-duration" *ngIf="titleStateInfo.displayStatus">{{titleStateInfo.displayStatus}}</span>
            </div>
            <div class="duration-sec"
                *ngIf="bookDetail?.FormatType === 'EBT' && bookDetail?.Attribute ==='Read Along' && !titleStateInfo.displayStatus">
                <span class="duration-text" [innerHtml]="getTitleFormat(bookDetail.Attribute)"></span>
            </div>
        </ng-container>
        <div class="action-buttons-sec" [ngClass]="{'disableActionSec':!showAction}">
            <ng-container *ngIf="titleStateInfo.actions?.length > 0">
                <ng-container [ngSwitch]="titleStateInfo.actions[0].actionButtonId">
                    <ng-container *ngSwitchCase="'btnMarkAsRead'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Mark as Done ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_READ, titleStateInfo.actions[0])">
                            <span>Mark as Done</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnMarkAsDone'">
                        <button class="primary-action {{kzCardBtnClass}}"
                            attr.aria-describedby="progressbar-detail{{bookDetail.itemId}}"
                            [ngClass]="{'kz-btn-full': bookDetail.isManual && seeAll}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}" tabindex="0"
                            [attr.aria-label]="'Mark as Done ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_DONE, titleStateInfo.actions[0])">
                            <span>Mark as Done</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnMarkAsNotDone'">
                        <button class="primary-action {{kzCardBtnClass}}" tabindex="0"
                            attr.aria-describedby="progressbar-detail{{bookDetail.itemId}}"
                            [ngClass]="{'kz-btn-full': bookDetail.isManual && seeAll}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Mark Not Done ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_NOT_DONE, titleStateInfo.actions[0])">
                            <span>Mark Not Done</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnCompleted'">
                        <p class="primary-action {{kzCardBtnClass}}" 
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}">
                            <span>Completed</span>
                        </p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRemoveCheckoutHistory'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Remove ' + isAriaLabel"
                            (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.REMOVE_CHECKOUT_HISTORY, titleStateInfo.actions[0],bookDetail?.title)">
                            <span>Remove</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnCheckoutNow'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Checkout ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CHECKOUT, titleStateInfo.actions[0])">
                            <span>Checkout</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnReturn'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Return ' + isAriaLabel"
                            (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RETURN, titleStateInfo.actions[0],bookDetail?.title)">
                            <span>Return</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRenew'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Renew ' + isAriaLabel"
                            (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RENEW, titleStateInfo.actions[0],bookDetail?.title)">
                            <span>Renew</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnUnsuspendHold'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Activate Hold ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.UNSUSPEND_HOLD, titleStateInfo.actions[0])">
                            <span>Activate Hold</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAddHold'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Place Hold ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_HOLD, titleStateInfo.actions[0])">
                            <span>Place Hold</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRemoveHold'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Remove Hold ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_HOLD, titleStateInfo.actions[0])">
                            <span>Remove Hold</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnReadNow'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            id="{{titleStateInfo.actions[0].actionButtonId}}_{{bookDetail.itemId}}"
                            [attr.aria-label]="'Read ' + isAriaLabel"
                            (click)="titleActionAnalytics(ACTION_BUTTON_DATA.READ_ONLINE);openNewTabCTA({itemId:titleStateInfo.actions[0].itemId,isbn:titleStateInfo.actions[0].isbn},'read')">
                            <span>Read</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnViewPack'">
                        <a href="/coursepack" class="primary-action {{kzCardBtnClass}}"
                            [attr.aria-label]="'View Pack ' + isAriaLabel" role="button">
                            <span>View Pack</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAccessIndvidualCourse'">
                        <a href="/coursepack" class="primary-action {{kzCardBtnClass}}"
                            [attr.aria-label]="'View Pack ' + isAriaLabel" role="button">
                            <span>View Pack</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnListenNow'">
                        <button class="primary-action {{kzCardBtnClass}}" [attr.aria-label]="'Listen ' + isAriaLabel"
                            (click)="titleActionAnalytics(ACTION_BUTTON_DATA.LISTEN_NOW);openNewTabCTA({itemId:titleStateInfo?.actions[0].itemId,isbn:titleStateInfo?.actions[0].isbn},'listen')">
                            <span>Listen</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnDownload'">
                        <button class="primary-action {{kzCardBtnClass}}" [attr.aria-label]="'Download ' + isAriaLabel"
                            (click)="titleActionAnalytics(ACTION_BUTTON_DATA.DOWNLOAD);openNewTabCTA({itemId:titleStateInfo?.actions[0].itemId,isbn:titleStateInfo?.actions[0].isbn},'download')">
                            <span>Download</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAccessinApp'">
                        <a class="primary-action {{kzCardBtnClass}}" tabindex="0" role="button">
                            Access on App
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRecommend'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            [attr.aria-label]="'Request Purchase ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RECOMMEND, titleStateInfo?.actions[0])">
                            <span>Request Purchase</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAddProgram'">
                        <button class="primary-action kz-btn-full {{kzCardBtnClass}}"
                            [disabled]="!showAction || disableAdd" [attr.aria-label]="'Add ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_PROGRAM, titleStateInfo?.actions[0])">
                            <span>Add</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRemove'">
                        <button class="primary-action kz-btn-full {{kzCardBtnClass}}" [disabled]="!showAction" [attr.aria-label]="'Remove from Program ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE, titleStateInfo?.actions[0])">
                            <span>Remove from Program</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnCancelRecommend'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            [attr.aria-label]="'Cancel Request ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_RECOMMEND, titleStateInfo?.actions[0])">
                            <span>Cancel Request</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAddWishlist'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            [attr.aria-label]="'Add to Wishlist ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_WISHLIST, titleStateInfo?.actions[0])">
                            <span>Add to Wishlist</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRemoveWishlist'">
                        <div class="book-favorite">
                            <mat-icon svgIcon="favorite"></mat-icon>
                        </div>
                        <button class="primary-action {{kzCardBtnClass}} kz-remove-wishlist" [disabled]="!showAction"
                            [attr.aria-label]="'Remove from Wishlist ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, titleStateInfo?.actions[0])">
                            <span class="action-box">
                                <span class="wishlist-action">
                                    <span class="lower-text wishlist-text-transform">Remove from </span>
                                    <span class="upper-text">Wishlist</span>
                                </span>
                            </span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnBuynow'">
                        <button class="primary-action {{kzCardBtnClass}}"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.BUY_NOW, titleStateInfo?.actions[0]);openNewTabCTA({buyNowUrl: titleStateInfo?.actions[0].buyNowUrl},'buyNow')">
                            <span>Buy Now</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnSuspendHold'">
                        <button class="primary-action {{kzCardBtnClass}}" [disabled]="!showAction"
                            [attr.aria-label]="'Suspend Hold ' + isAriaLabel"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.SUSPEND_HOLD, titleStateInfo?.actions[0])">
                            <span>Suspend</span>
                        </button>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="titleStateInfo.actions?.length > 1">
                <button [disabled]="!showAction" class="expand-sec {{expandSecClass}}"
                    matTooltip="More Options" matTooltipClass="kz-card-tooltip"
                    [attr.aria-label]="'More option ' + isAriaLabel" [matMenuTriggerFor]="menu"
                    (mouseover)="removeTooltipAnnouce(bookDetail.itemId)" (keyup.Tab)="removeTooltipAnnouce(bookDetail.itemId)"
                    #matMenuItem="matMenuTrigger" (menuOpened)="menuState(true, titleStateInfo?.actions)" (menuClosed)="menuState(false, titleStateInfo?.actions)"
                    id="moreoption_{{bookDetail.itemId}}">
                    <mat-icon *ngIf="!matMenuItem.menuOpen && !isMyStuff" svgIcon="kz-down-arrow"></mat-icon>
                    <mat-icon *ngIf="matMenuItem.menuOpen && !isMyStuff" svgIcon="kz-up-arrow"></mat-icon>
                    <mat-icon *ngIf="isMyStuff" svgIcon="kz-three-dots-icon"></mat-icon>
                </button>
            </ng-container>
        </div>
    </div>
    <mat-menu #menu="matMenu" class="kz-action-menu-item {{kzActionMenuItemClass}}" yPosition="below" xPosition="before">
        <ng-container *ngFor="let action of titleStateInfo?.actions;let i = index;" role="none">
            <ng-container *ngIf="i > 0" role="none">
                <ng-container [ngSwitch]="action.actionButtonId" role="none">
                    <div class="action-buttons-sec" role="none">
                        <div *ngSwitchCase="'btnViewPack'">
                            <button class="action-expand action-links" id="loc_btnViewPack"
                                (click)="openNewTabCTA({itemId:action.itemId,isbn:action.isbn},'viewPack')">
                                <span>View Pack</span>
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemoveCheckoutHistory'">
                            <button type="button" mat-menu-item alt="return" id="loc_btnRemoveCheckoutHistory" [attr.aria-label]="'Remove ' + isAriaLabel"
                                class="action-expand action-links"
                                (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.REMOVE_CHECKOUT_HISTORY, action,bookDetail?.title, {btn:'moreOptionCTA', btnId: bookDetail.itemId, errorId: bookDetail.itemId})">
                                Remove
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnCheckoutNow'">
                            <button type="button" mat-menu-item alt="Checkout" id="loc_btnCheckoutNow"
                                [attr.aria-label]="'Checkout ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CHECKOUT, action)">
                                Checkout
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRenew'">
                            <button type="button" mat-menu-item alt="renew" id="loc_btnRenew" [attr.aria-label]="'Renew ' + isAriaLabel"
                                class="action-expand action-links "
                                (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RENEW, action,bookDetail?.title,  {btn:'moreOptionCTA', btnId: bookDetail.itemId, errorId: bookDetail.itemId})">
                                Renew
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnReturn'">
                            <button type="button" mat-menu-item alt="return" id="loc_btnReturn" [attr.aria-label]="'Return ' + isAriaLabel"
                                class="action-expand action-links"
                                (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RETURN, action,bookDetail?.title, {btn:'moreOptionCTA', btnId: bookDetail.itemId, errorId: bookDetail.itemId})">
                                Return
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnReadNow'">
                            <button type="button" mat-menu-item alt="read" id="loc_btnReadNow" [attr.aria-label]="'Read ' + isAriaLabel"
                                class="action-expand action-links"
                                (click)="titleActionAnalytics(ACTION_BUTTON_DATA.READ_ONLINE);openNewTabCTA({itemId:action.itemId,isbn:action.isbn},'read')">
                                <span>Read</span>
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnListenNow'">
                            <button type="button" mat-menu-item alt="listen" id="loc_btnListenNow" [attr.aria-label]="'Listen ' + isAriaLabel"
                                class="action-expand action-links"
                                (click)="titleActionAnalytics(ACTION_BUTTON_DATA.LISTEN_NOW);openNewTabCTA({itemId:action.itemId,isbn:action.isbn},'listen')">
                                <span>Listen</span>
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnDownload'" role="none">
                            <button mat-menu-item class="action-expand action-links"
                                [attr.aria-label]="'Download ' + isAriaLabel" id="loc_btnDownload"
                                (click)="titleActionAnalytics(ACTION_BUTTON_DATA.DOWNLOAD);openNewTabCTA({itemId:action.itemId,isbn:action.isbn},'download')">
                                <span>Download</span>
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAccessinApp'">
                            <button type="button" mat-menu-item id="loc_btnAccessinApp" alt="access on app" class="action-expand action-links">
                                Access on App
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddHold'">
                            <button type="button" mat-menu-item alt="place hold" id="loc_btnAddHold"
                                [attr.aria-label]="'Place Hold ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_HOLD, action)">
                                Place Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemoveHold'">
                            <button type="button" mat-menu-item alt="remove hold" id="loc_btnRemoveHold"
                                [attr.aria-label]="'Remove Hold ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_HOLD, action)">
                                Remove Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemove'">
                            <button type="button" mat-menu-item alt="remove hold"
                                class="action-expand action-links kz-break-height" id="loc_btnRemove"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE, action)"
                                id="removeprogram_{{bookDetail.itemId}}">
                                Remove from Program
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRecommend'">
                            <button type="button" mat-menu-item alt="recommend" id="loc_btnRecommend"
                                [attr.aria-label]="'Request Purchase ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RECOMMEND, action)">
                                Request Purchase
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddProgram'">
                            <button type="button" mat-menu-item alt="addprogram"
                                class="action-expand kz-btn-full action-links" id="loc_btnAddProgram"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_PROGRAM, action)">
                                Add
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnCancelRecommend'">
                            <button type="button" mat-menu-item alt="Cancel recommendation"
                                class="action-expand action-links wrap-cancel-recommendation"
                                [attr.aria-label]="'Cancel Request ' + isAriaLabel" id="loc_btnCancelRecommend"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_REQUEST, action)">
                                Cancel Request
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnUnsuspendHold'">
                            <button type="button" mat-menu-item alt="unsuspend" id="loc_btnUnsuspendHold"
                                [attr.aria-label]="'Activate Hold ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.UNSUSPEND_HOLD, action)">
                                Activate Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddWishlist'" role="none">
                            <ng-container *ngIf="showAddToWishList">
                                <button type="button" mat-menu-item alt="add to wishlist"
                                    [attr.aria-label]="'Add to Wishlist ' + isAriaLabel"
                                    class="action-expand action-links" id="loc_btnAddWishlist"
                                    (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_WISHLIST, action)">
                                    Add to Wishlist
                                </button>
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'btnRemoveWishlist'">
                            <button type="button" mat-menu-item alt="remover from wishlist"
                                class="action-expand action-links" id="loc_btnRemoveWishlist"
                                [ngClass]="{'kz-break-height' : activeMyStuff != 'wishlist' }"
                                [attr.aria-label]="'Remove from Wishlist ' + isAriaLabel"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, action)">
                                <span *ngIf="activeMyStuff == 'wishlist'">Remove</span>
                                <span *ngIf="activeMyStuff != 'wishlist'">Remove from Wishlist</span>
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnBuyNow'">
                            <button type="button" mat-menu-item class="action-expand action-links" alt="buy now" id="loc_btnBuyNow"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.BUY_NOW, action);openNewTabCTA({buyNowUrl: action.buyNowUrl},'buyNow')">
                                <span>Buy Now</span>
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnSuspendHold'">
                            <button type="button" mat-menu-item alt="suspend hold" id="loc_btnSuspendHold"
                                [attr.aria-label]="'Suspend Hold ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.SUSPEND_HOLD,action)">
                                Suspend Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnMarkAsDone'">
                            <button type="button" mat-menu-item alt="mark as done" id="loc_btnMarkAsDone"
                                [attr.aria-label]="' Mark as Done ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_DONE,action)">
                                Mark as Done
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnMarkAsNotDone'">
                            <button type="button" mat-menu-item alt="mark not done" id="loc_btnMarkAsNotDone"
                                [attr.aria-label]="' Mark Not Done ' + isAriaLabel" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_NOT_DONE,action)">
                                Mark Not Done
                            </button>
                        </div>
                        <div *ngSwitchCase="'writeAReview'">
                            <button type="button" mat-menu-item alt="write a review" id="loc_writeAReview"
                                [attr.aria-label]="'Write A Review ' + isAriaLabel" class="action-expand action-links">
                                Write A Review
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-menu>
</ng-container>