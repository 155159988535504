export const LEARNING_ACTIVITIES_SCREENS = {
  LEARNING_ACTIVITIES: 'Learning activities',
  REFINE_SCREEN: 'Refine screen'
};

export const LEARNING_ACTIVITIES_EVENTS_KEY = {
  REFINE_CTA: 'REFINE_CTA',
  DOWNLOAD_CTA: 'DOWNLOAD_CTA',
  LINKED_TITLE_CTA: 'LINKED_TITLE_CTA',
  CLEAR_CTA: 'CLEAR_CTA',
  VIEW_SORT_BY: 'VIEW_SORT_BY',
  VIEW_ACTIVITY_TYPE: 'VIEW_ACTIVITY_TYPE',
  VIEW_AUDIENCE_LEVEL: 'VIEW_AUDIENCE_LEVEL'
};
export const LEARNING_ACTIVITIES_EVENTS: { [key: string]: string } = {
  [LEARNING_ACTIVITIES_EVENTS_KEY.REFINE_CTA]: 'Refine CTA',
  [LEARNING_ACTIVITIES_EVENTS_KEY.DOWNLOAD_CTA]: 'Download CTA',
  [LEARNING_ACTIVITIES_EVENTS_KEY.LINKED_TITLE_CTA]: 'Linked title CTA',
  [LEARNING_ACTIVITIES_EVENTS_KEY.CLEAR_CTA]: 'Clear CTA',
  [LEARNING_ACTIVITIES_EVENTS_KEY.VIEW_SORT_BY]: 'View Sort by',
  [LEARNING_ACTIVITIES_EVENTS_KEY.VIEW_ACTIVITY_TYPE]: 'View Activity Type',
  [LEARNING_ACTIVITIES_EVENTS_KEY.VIEW_AUDIENCE_LEVEL]: 'View Audience Level'
};
