<div class="search" (window:resize)="onResize()">
    <div class="bread-crum-main">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb kz-search'">
        </axis360-breadcrumb>
    </div>
    <button class="skip-refiners skip-refiners-format" id="loc_btnSkiptoMain" (click)="skipRefiners()" (keydown.enter)="skipRefiners()">
        Skip the Refiners
    </button>
    <div class="search-container mb-3">
        <div class="refiner-sec">
            <div class="col-lg-4 col-xs-12 sticky-refiner" *ngIf="!showRefinerLayout">
                <axis360-search-refiners></axis360-search-refiners>
            </div>
        </div>
        <div class="search-result-info" id="searchResultContainer" tabindex="-1">
            <axis360-search-view [programs]="programsDetails" [studyTitlesInfo]="studyTitlesResult" [searchby]="searchText" [searchList]="searchService.totalCountList" [searchResult] = "true"
                [layoutComponents]="layoutComponents" [totalSitesCount]="totalSitesCount" [enablePurchaseRequest]="enablePurchaseRequest" [isProgramCTAShow]="isProgram"
                [sitesTitlesInfo]="sitesTitlesInfo" [learningActivityInfo]='learningActivityResult'
                [isTitletoRecommend]="isTitletoRecommend" (count)="emittedCount($event)"></axis360-search-view>
        </div>
    </div>
</div>