import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilesListComponent } from '@profile/profiles-list/profiles-list.component';
import { ProfilesAnalyticsService } from '@profile/shared/services/profiles.analytics.service';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { HOME_REFINER } from '@shared/constants/app.constants';
import { forceRedirect } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SharedService } from '@shared/services/shared.service';
import { SIDE_NAV_URL, SIGNOUT_ALERT } from '../shared/constants/header.constants';
import { HeaderAnalyticsService } from '../shared/services/header.analytics.service';
import { Location } from '@angular/common';
import { CurrentLibrary } from '@shared/data/config.model';
import { ConfigService } from '@shared/services/config.service';
import { AccountService } from '@shared/services/account.service';
@Component({
  selector: 'axis360-profile-list-dropdown',
  templateUrl: './profile-list-dropdown.component.html',
  styleUrls: ['./profile-list-dropdown.component.scss']
})
export class ProfileListDropDownComponent extends ProfilesListComponent implements OnInit {
  hasError = false;
  selectedProfileId: string;
  canAddProfile: boolean;
  isLoading: boolean;
  queryVal: any;
  forceRedirect = forceRedirect;
  currentLibrary: Partial<CurrentLibrary>;
  @Output() profilePicClickEvent = new EventEmitter();
  profileImageError = false;

  constructor(
    public profileInfoService: ProfileInfoService,
    public profilesService: ProfilesService,
    public liveAnnouncer: LiveAnnouncer,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public profilesAnalyticsService: ProfilesAnalyticsService,
    public searchStorageService: SearchStorageService,
    public matDialog: MatDialog,
    public commonService: CommonService,
    public headerAnalyticsService: HeaderAnalyticsService,
    public sharedService: SharedService,
    public location: Location,
    public configService: ConfigService,
    public accountService: AccountService
  ) {
    super(
      profilesService,
      router,
      activatedRoute,
      liveAnnouncer,
      profilesAnalyticsService,
      profileInfoService,
      searchStorageService,
      matDialog,
      commonService,
      sharedService,
      location,
      configService,
      accountService
    );
  }

  ngOnInit(): void {
    this.getProfilelist();
    this.currentProfile = this.profileInfoService.currentProfile;
    this.currentLibrary = this.configService.currentLibrary;
    this.commonService.hideHeaderFooter.next(false);
    this.selectedProfilesettings(this.currentProfile.profileId);
  }
  onCreateProfile() {
    this.profilesAnalyticsService.trackProfilesEvent('ADD_PROFILE');
    this.router.navigate(['view/profiles/create']);
  }
  menuStateChange(state: boolean) {
    if (state) {
      document.body.classList.add('hide-scroll-overlay');
      document.getElementById('matSidenav').setAttribute('aria-hidden', 'true');
      document.getElementById('matSidenav').setAttribute('tabindex', '-1');
      return;
    }
    document.body.classList.remove('hide-scroll-overlay');
    document.getElementById('matSidenav').removeAttribute('aria-hidden');
    document.getElementById('matSidenav').removeAttribute('tabindex');
  }
  /**
   * To show the signout popup
   */
  signOut() {
    this.headerAnalyticsService.trackMenuEvent('VIEW_SIGNOUT');
    this.commonService.showAlertDialog({
      ...SIGNOUT_ALERT,
      submitBtnFunc: this.handleSignOut
    });
  }
  /**
   * To redirect to home after signout
   */
  handleSignOut = () => {
    sessionStorage.removeItem(HOME_REFINER.AVAILABILITY);
    sessionStorage.removeItem(HOME_REFINER.FORMAT);
    this.forceRedirect(SIDE_NAV_URL.SIGNOUT);
  };
  profilePicClick() {
    this.profilePicClickEvent.emit();
  }
  selectedProfilesettings(profileId) {
    const navUrl = '/view/profiles/edit/' + profileId;
    if (navUrl === this.router.url || this.router.url.includes('edit')) {
      this.queryVal = '';
      return;
    }
    this.queryVal = { isMenu: 'yes' };
  }
  getAvatarName(profileUrl, profileName) {
    if (this.profilesService.avatarData && !profileUrl.includes('base64')) {
      return `avatar ${this.profilesService.avatarData?.find((pro) => pro.avatarUrl === profileUrl)?.avatarName}`;
    }
    return `avatar ${profileName}`;
  }
}
