import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'axis360-title-share',
  templateUrl: './title-share.component.html',
  styleUrls: ['./title-share.component.scss']
})
export class TitleShareComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<TitleShareComponent>) { }

  /**
   * Switch type base on social media options by clicking Share icon
   * @param [type] Pass by facebook,twitter,email,copyLink
   * Close the title share dialog.
   */  
  shareTitleSocialMedia(type: string) {
    this.dialogRef.close(type);
  }

}
