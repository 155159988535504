import * as ACData from 'adaptivecards-templating';
import * as AdaptiveCards from 'adaptivecards';

export class AdaptiveCard {
  adaptiveCard: any;
  constructor(onExecuteAction?: (action: AdaptiveCards.Action) => void) {
    const adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.onExecuteAction = onExecuteAction;
    this.adaptiveCard = adaptiveCard;
  }
  getRenderedContent = (data: {}, jsonTempl: {}) => {
    const template = new ACData.Template(jsonTempl);
    const cardPayload = template.expand({
      $root: data
    });
    this.adaptiveCard.parse(cardPayload);
    return this.adaptiveCard.render();
  };
}
