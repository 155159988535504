import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Carousel } from 'primeng/carousel';
import { noop } from 'rxjs';
import { SidenavService } from '@shared/services/sidenav.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CommonService } from '@shared/services/common.service';
import { NETWORK_ALERT } from '@shared/constants/app.constants';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { Overlay } from '@angular/cdk/overlay';
import { scrollFactory } from '@shared/helper/app.util';
@Component({
  selector: 'axis360-ng-root',
  templateUrl: './app.component.html',
  providers: [
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ],
})
export class AppComponent implements OnInit {
  title = 'angular-app';

  constructor(
    public breadcrumbService: BreadcrumbService,
    public commonService: CommonService,
    public router: Router,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public sidenavService: SidenavService
  ) {
    Carousel.prototype.onTouchMove = noop;
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.commonService.isAnnouncedPage = false;
    });
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.commonService.previousUrl = this.commonService.currentUrl;
      this.commonService.currentUrl = event.url;
      if (!event.url.includes('skip-nav'))
      {
        setTimeout(() => {
          this.commonService.isAnnouncedPage = true;
        }, 1500);
      }
    });
    window.addEventListener('offline', () => {
      this.commonService.showAlertDialog({
        ...NETWORK_ALERT,
        submitBtnFunc: () => this.commonService.reloadPage()
      });
    });
    window.addEventListener('online', () => {
      this.commonService.reloadPage();
    });
    iconRegistry.addSvgIcon('ebook', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/ebook.svg'));
    iconRegistry.addSvgIcon('book', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/book.svg'));
    iconRegistry.addSvgIcon('audio', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/materialType/audiobook.svg'));
    iconRegistry.addSvgIcon('filtericon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-filter.svg'));
    iconRegistry.addSvgIcon('sorticon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-sort-filter.svg'));
    iconRegistry.addSvgIcon('VIDEO', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/video.svg'));
    iconRegistry.addSvgIcon('ARTICLE', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/article.svg'));
    iconRegistry.addSvgIcon('IMAGE_COLLECTION', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/image.svg'));
    iconRegistry.addSvgIcon('BIOGRAPHY', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/biography.svg'));
    iconRegistry.addSvgIcon('ANIMATION', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/animation.svg'));
    iconRegistry.addSvgIcon(
      'EDUCATIONAL_GAME',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/educational_games.svg')
    );
    iconRegistry.addSvgIcon('INTERACTIVE', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/interactive.svg'));
    iconRegistry.addSvgIcon('EBOOK', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/ebook.svg'));
    iconRegistry.addSvgIcon('AUDIO', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/audio.svg'));
    iconRegistry.addSvgIcon('MUSIC', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/music.svg'));
    iconRegistry.addSvgIcon('DICTIONARY_THESAURUS', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/dictionary.svg'));
    iconRegistry.addSvgIcon('ENCYCLOPEDIA', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/encyclopedia.svg'));
    iconRegistry.addSvgIcon('MAGAZINE', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/magazine.svg'));
    iconRegistry.addSvgIcon('MAP_ATLAS', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/map.svg'));
    iconRegistry.addSvgIcon('NEWS_SOURCE', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/news_source.svg'));
    iconRegistry.addSvgIcon(
      'PRIMARY_SOURCE_MATERIAL',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/primary_source_material.svg')
    );
    iconRegistry.addSvgIcon('JOINED_PROGRAM', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/joined-program.svg'));
    iconRegistry.addSvgIcon(
      'DOWN_ARROW',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-chevron-down-arrow.svg')
    );
    iconRegistry.addSvgIcon(
      'PRESSREADER_CALENDER',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-insights-days.svg')
    );
    iconRegistry.addSvgIcon('PRESSREADER_LIST', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-list.svg'));
    iconRegistry.addSvgIcon(
      'HIDDEN_TITLE',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-core-unavailable.svg')
    );
    iconRegistry.addSvgIcon('kz-audio', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/audio-icon.svg'));
    iconRegistry.addSvgIcon('kz-ebook', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/e-book.svg'));
    iconRegistry.addSvgIcon('kz-browse', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/browse.svg'));
    iconRegistry.addSvgIcon('kz-checkouts', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/checkout.svg'));
    iconRegistry.addSvgIcon('kz-featurelist', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/featurelist.svg'));
    iconRegistry.addSvgIcon('kz-help', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/help.svg'));
    iconRegistry.addSvgIcon('kz-holds', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/holds.svg'));
    iconRegistry.addSvgIcon('kz-library', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/library.svg'));
    iconRegistry.addSvgIcon('kz-myshelf', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/myshelf.svg'));
    iconRegistry.addSvgIcon('kz-profiles', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/profiles.svg'));
    iconRegistry.addSvgIcon('kz-programs', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/programs.svg'));
    iconRegistry.addSvgIcon('kz-signout', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/signout.svg'));
    iconRegistry.addSvgIcon('kz-support', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/support.svg'));
    iconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/close.svg'));
    iconRegistry.addSvgIcon('kz-left-arrow', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/left-arrow.svg'));
    iconRegistry.addSvgIcon('kz-hamburger', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/hamburger.svg'));
    iconRegistry.addSvgIcon('clock-goal', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/clock-goal.svg'));
    iconRegistry.addSvgIcon(
      'kz-down-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/down-arrow.svg')
    );
    iconRegistry.addSvgIcon('kz-right-arrow', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-arrow-right.svg'));
    iconRegistry.addSvgIcon('kz-calender-goal', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/calender-goal.svg'));
    iconRegistry.addSvgIcon('kz-up-arrow', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/up-arrow.svg'));
    iconRegistry.addSvgIcon(
      'kz-accordian-down-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/accordian-down-arrow.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-filter-down-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/filter-down-arrow.svg')
    );
    iconRegistry.addSvgIcon('kz-favorite', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/wishlist.svg'));
    iconRegistry.addSvgIcon('kz-favorite-fill', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/wishlist-fill.svg'));
    iconRegistry.addSvgIcon('kz-share', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/share.svg'));
    iconRegistry.addSvgIcon('kz-star', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/star.svg'));
    iconRegistry.addSvgIcon('kz-star-full', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/star-full.svg'));
    iconRegistry.addSvgIcon('kz-speaker-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/speaker-icon.svg'));
    iconRegistry.addSvgIcon(
      'kz-see-all-eye',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/see-all-eye-icon.svg')
    );
    iconRegistry.addSvgIcon('kz-favorite-fill', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/wishlist-fill.svg'));
    iconRegistry.addSvgIcon('kz-share', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/share.svg'));
    iconRegistry.addSvgIcon('kz-star', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/star.svg'));
    iconRegistry.addSvgIcon('kz-star-full', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/star-full.svg'));
    iconRegistry.addSvgIcon('kz-speaker-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/speaker-icon.svg'));
    iconRegistry.addSvgIcon(
      'kz-see-all-eye',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/see-all-eye-icon.svg')
    );
    iconRegistry.addSvgIcon('kz-more-down', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-more-down.svg'));
    iconRegistry.addSvgIcon('kz-info-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-info-icon.svg'));
    iconRegistry.addSvgIcon('kz-search-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/search-icon.svg'));
    iconRegistry.addSvgIcon('kz-strawberry', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/strawberry.svg'));
    iconRegistry.addSvgIcon('kz-filtter-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/filtter-icon.svg'));
    iconRegistry.addSvgIcon('kz-chocolate-menu', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/chocolate-menu.svg'));
    iconRegistry.addSvgIcon('kz-list-view', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/list-view.svg'));
    iconRegistry.addSvgIcon('kz-close', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-close.svg'));
    iconRegistry.addSvgIcon('kz-upload-photo', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-upload-photo.svg'));
    iconRegistry.addSvgIcon('kz-notification', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/notification.svg'));
    iconRegistry.addSvgIcon('kz-meatballs-menu', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/meatballs-menu.svg'));
    iconRegistry.addSvgIcon('kz-trash', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/trash.svg'));
    iconRegistry.addSvgIcon(
      'kz-filter-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/filter-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-close-black',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/close-icon-black.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-nav-close-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/nav-close-icon.svg')
    );
    iconRegistry.addSvgIcon('kz-email-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/email-icon.svg'));
    iconRegistry.addSvgIcon(
      'kz-timer',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/icons-insights-time-color.svg')
    );
    iconRegistry.addSvgIcon('kz-chat-arrow', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/chat-arrow.svg'));
    iconRegistry.addSvgIcon(
      'kz-back-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-back-arrow-with-circle.svg')
    );
    iconRegistry.addSvgIcon('kz-copy-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/copy-icon.svg'));
    iconRegistry.addSvgIcon(
      'kz-titlemail-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/titlemail-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-facebook-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/facebook-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-twitter-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/twitter-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-instagram-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/instagram-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-send-mail-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/send-mail-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-filter-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-filter-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-activity-poster-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-activity-poster.svg')
    );
    iconRegistry.addSvgIcon('kz-nav-x-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/x-icon.svg'));
    iconRegistry.addSvgIcon('kz-no-image', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/no-image.svg'));
    iconRegistry.addSvgIcon('kz-no-info-icon', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/no-info-icon.svg'));
    iconRegistry.addSvgIcon(
      'kz-calender-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/Calender-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-calenderlist-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/Calenderlist-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-play-button',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-play-button.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-play-blue',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-play-btn-blue.svg')
    );
    iconRegistry.addSvgIcon('kz-pause', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-pause.svg'));
    iconRegistry.addSvgIcon(
      'kz-calender-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-calender-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-exclamation-mark-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/exclamation-mark-round.svg')
    );
    iconRegistry.addSvgIcon('kz-back', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-back.svg'));
    iconRegistry.addSvgIcon('kz-manage-edit', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/manage-edit.svg'));
    iconRegistry.addSvgIcon('kz-error-pin', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/error-pin.svg'));
    iconRegistry.addSvgIcon('kz-addProfile-btn', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/addProfilePlus.svg'));
    iconRegistry.addSvgIcon('kz-right', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/right-arrow.svg'));
    iconRegistry.addSvgIcon('kz-addplus', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/add-plus.svg'));
    iconRegistry.addSvgIcon('kz-edit-pencil', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/edit-pencil-pg.svg'));
    iconRegistry.addSvgIcon('kz-delete-program', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/delete-prog.svg'));
    iconRegistry.addSvgIcon(
      'kz-grey-down-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-grey-down-arrow.svg')
    );
    iconRegistry.addSvgIcon('kz-user-cover', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-user-cover.svg'));
    iconRegistry.addSvgIcon(
      'kz-default-cover',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-default-cover.svg')
    );
    iconRegistry.addSvgIcon('kz-profile-user', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/profile-user.svg'));
    iconRegistry.addSvgIcon('kz-editgoal-pencil', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/edit_pen_grey.svg'));
    iconRegistry.addSvgIcon('kz-back-arrow', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/back-button-ui.svg'));
    iconRegistry.addSvgIcon('kz-logout', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-signout.svg'));
    iconRegistry.addSvgIcon('kz-settings', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-settings.svg'));
    iconRegistry.addSvgIcon('kz-lock-profile', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/lock-profile.svg'));
    iconRegistry.addSvgIcon(
      'kz-edit-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-edit-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-three-dots-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-three-dots-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-password-show-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/password-show.svg')
    );
    iconRegistry.addSvgIcon(
      'kz-password-hide-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/password-hide.svg')
    );
    iconRegistry.addSvgIcon('kz-videos', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-videos1.svg'));
    iconRegistry.addSvgIcon('kz-vbooks', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/kz-images/kz-vbook1.svg'));
  }
  ngOnInit(): void {
    if (document.getElementById('hdPartnerID')['value']) {
      localStorage.setItem('partnerID', document.getElementById('hdPartnerID')['value']);
    }

    if (document.getElementById('hdLocationID')['value']) {
      localStorage.setItem('locationID', document.getElementById('hdLocationID')['value']);
    }
  }
}
