import { Component, Input, OnInit } from '@angular/core';
import { ProgramCardImg } from '@readingprograms/shared/data/programs.model';

@Component({
  selector: 'axis360-kz-programs-card-images',
  templateUrl: './kz-programs-card-images.component.html',
  styleUrls: ['./kz-programs-card-images.component.scss']
})
export class KzProgramsCardImagesComponent implements OnInit {
  @Input() cardImgs: ProgramCardImg[];
  @Input() programType: string;
  @Input() isParticipant: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
