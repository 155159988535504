import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  MYSHELF_EVENTS_KEY,
  MYSHELF_SCREENS,
  MYSHELF_EVENTS,
  MYSHELF_SCREENS_KEY,
  MYSHELF_CATEGORY
} from '../constants/my-shelf.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof MYSHELF_EVENTS_KEY;
@Injectable()
export class MyShelfAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of myshelf analytics service.
   */
  constructor() {
    super(MYSHELF_SCREENS, MYSHELF_EVENTS);
  }
  /**
   * To track myshelf page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof MYSHELF_SCREENS_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track myshelf page - currently checkout events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackCurrentlyCheckoutEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MYSHELF_CATEGORY.CURRENTLY_CHECKOUT, action, altObj);
  }
  /**
   * To track myshelf page - my programs events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyProgramsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MYSHELF_CATEGORY.MY_PROGRAMS, action, altObj);
  }
  /**
   * To track myshelf page - Goals and insights events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackGoalsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MYSHELF_CATEGORY.GOALS_INSIGHTS, action, altObj);
  }
  /**
   * To track myshelf page - Goals and insights events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackBadgesCarouselEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MYSHELF_CATEGORY.BADGES, action, altObj);
  }
}
