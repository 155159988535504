import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { UserService } from '@shared/services/user.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TitleDetailsAnalyticsService } from '@titledetails/shared/services/title-details.analytics.service';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { DOCUMENT, Location } from '@angular/common';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { SharedService } from '@shared/services/shared.service';
import { AccountService } from '@shared/services/account.service';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { CommonService } from '@shared/services/common.service';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { Router } from '@angular/router';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ACTION_BUTTON_DATA } from '@shared/constants/app.constants';
import { TitleDetailsActionButtonComponent } from '../title-details-action-button/title-details-action-button.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'axis360-title-details-secondary-actions',
  templateUrl: './title-details-secondary-actions.component.html',
  styleUrls: ['./title-details-secondary-actions.component.scss']
})
export class TitleDetailsSecondaryActionsComponent extends TitleDetailsActionButtonComponent {

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  constructor(
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    public toastService: SnackBarService,
    public userService: UserService,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public liveAnnouncer: LiveAnnouncer,
    public overlayContainer: OverlayContainer,
    @Inject(DOCUMENT) public document: Document,
    public matDialog: MatDialog,
    public elRef: ElementRef,
    public clipboard: Clipboard,
    public sharedService: SharedService,
    public sharedAnalyticsService: SharedAnalyticsService,
    public accountService: AccountService,
    public commonService: CommonService,
    public notificationsService: NotificationsService,
    public dialog: MatDialog,
    public titleDetailsService: TitleDetailsService,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public kzProgramsService: KzProgramsService,
    public router: Router,
    public programAnalyticsService: ProgramsAnalyticsService,
    public location: Location

  ) {
    super(
      engageService,
      spinnerService,
      toastService,
      userService,
      configService,
      profileService,
      liveAnnouncer,
      overlayContainer,
      document,
      matDialog,
      elRef,
      clipboard,
      sharedService,
      sharedAnalyticsService,
      accountService,
      commonService,
      notificationsService,
      dialog,
      titleDetailsService,
      titleDetailsAnalyticsService,
      kzProgramsService, router, programAnalyticsService, location
    );
  }

  menuCloseEvent(event?) {
    if (navigator.userAgent.match(/(Mac)/i)) {
      event.stopPropagation();
      event.preventDefault();
      this.menuTrigger?.openMenu();
      setTimeout(() => {
        this.menuTrigger?.closeMenu();
      }, 2500);
    }
  }
  async getButtonViews() {
    const buttonViewData = await this.titleDetailsService.getButtonViews(this.bookDetail.itemId, this.bookDetail.isbn);
    if (buttonViewData) {
      this.titleButtonViews = buttonViewData;
      this.isLoading = false;
      if (this.actiontype === ACTION_BUTTON_DATA.ADD_WISHLIST || this.actiontype === ACTION_BUTTON_DATA.REMOVE_WISHLIST) {
        const wishListId = this.actiontype !== ACTION_BUTTON_DATA.ADD_WISHLIST ? 'loc_wishlist_add' : 'loc_wishlist_remove';
        setTimeout(() => {
          const ele = document?.getElementById(`${wishListId}`);
          ele?.focus();
        }, 2000);
      }
    } else {
      this.isLoading = false;
    }
  }

  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      !this.isMobile && document.getElementsByTagName('html')[0].setAttribute('class', 'cdk-global-scrollblock');
      return;
    }
    setTimeout(() => {
      document.getElementById('share_btn').setAttribute('aria-expanded', 'false');
    }, 1000);
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    !this.isMobile && document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
  }
}
