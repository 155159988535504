import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CurrentLibrary } from '@shared/data/config.model';
import { AccountService } from '@shared/services/account.service';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { AccountComponent } from '../account.component';
import { RECOVERY_PASS, SECURITYANSWER_ERROR_MSG, SECURITY_QUESTION, SUCCESS } from '../shared/constants/account.constants';
import { ILibraryLoginInfo } from '../shared/data/account.model';
import { AccountAnalyticsService } from '../shared/services/account.analytics.service';
import { Router } from '@angular/router';
@Component({
  selector: 'axis360-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  @Input() noModal = false;
  openLoginForm = false;
  isSecurityQuestion = false;
  currentLibrary: Partial<CurrentLibrary>;
  forgotPasswordForm: FormGroup;
  errorMessage: string;
  securityQuestionForm: FormGroup;
  securityAnswerError: string;
  successAlert: Boolean;
  libraryLoginInfo: ILibraryLoginInfo;
  currentDialog: string;
  constructor(
    private configService: ConfigService,
    public accountService: AccountService,
    public formBuilder: FormBuilder,
    public spinnerService: SpinnerService,
    public matDialog: MatDialogRef<AccountComponent>,
    private commonService: CommonService,
    public accountAnalyticsService : AccountAnalyticsService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50)]]
    });

    this.securityQuestionForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50)]],
      securityQuestion: ['', Validators.required],
      securityAnswer: ['', [Validators.required, Validators.maxLength(50)]]
    });
    this.currentLibrary = this.configService.currentLibrary;
    this.libraryLoginInfo = this.configService.getLibraryLoginInfo();
    this.currentDialog = RECOVERY_PASS + ' ' + this.libraryLoginInfo.authenticationType;
    this.accountService.currentDialog = this.currentDialog
    this.accountAnalyticsService.trackScreen('FORGOT_PIN')

  }
  onSubmit() {
    this.spinnerService.withObservable(this.accountService.getQuestionForUsername(this.forgotPasswordForm.value)).subscribe(
      (response) => {
        if (!response.username) {
          this.errorMessage = response.errorMessage;
          return;
        }
        this.errorMessage = '';
        this.isSecurityQuestion = true;
        this.accountService.currentDialog = SECURITY_QUESTION
        this.securityQuestionForm.setValue({
          securityQuestion: response.securityQuestion,
          username: this.forgotPasswordForm.controls.username.value,
          securityAnswer: ''
        });
      },
      () => {
        this.matDialog.close();
        this.commonService.showErrorAlert();
      }
    );
  }
  onSecurityFormSubmit() {
    this.spinnerService.withObservable(this.accountService.sendPassword(this.securityQuestionForm.value)).subscribe(
      (response) => {
        if (response === SUCCESS) {
          this.accountService.currentDialog = 'Password Recovery';
          this.successAlert = true;
         setTimeout(() => {
          const focusBtn = document.getElementById('loc_password_recovery_btn');
          focusBtn?.focus();
         }, 200);
          return;
        }
        this.securityAnswerError = SECURITYANSWER_ERROR_MSG[response];
      },
      () => {
        this.matDialog.close();
        this.commonService.showErrorAlert();
      }
    );
  }
  closeMatDialog() {
    if(this.noModal){
      window.location.reload();
    }else{
      this.matDialog.close();
    }
    
  }
  
}
