import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HOME_EVENTS_KEY } from '@home/shared/constants/home.analytics.constants';
import { INTERESTS_AUDIENCE_LEVEL } from '@home/shared/constants/home.constants';
import { Audience, Interest, UserInterest } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import {
  DEFAULT_SUCCESS_MESSAGE,
  SNAKE_BAR_MODE,
  INTEREST
} from '@shared/constants/app.constants';
import { deepClone, positionFooter } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { noop } from 'rxjs';

@Component({
  selector: 'axis360-interest-survey',
  templateUrl: './interest-survey.component.html',
  styleUrls: ['./interest-survey.component.scss']
})
export class InterestSurveyComponent implements OnInit {
  isLoading = true;
  initialInterests: Interest[] = [];
  interests: Interest[] = [];
  audience: Audience = [];
  initialAudience: Audience = [];
  breadcrumbs = [
    { text: 'Home', url: '/', isHref: true },
    { text: 'MY INTERESTS', url: './' }
  ];
  announcement = '';
  isMacOrTab = false;
  isDirty = false;
  isCancel = false;
  selectedProfileType = '';
  constructor(
    public homeService: HomeService,
    public snackBarService: SnackBarService,
    public homeAnalyticsService: HomeAnalyticsService,
    public spinnerService: SpinnerService,
    public liveAnnouncer: LiveAnnouncer,
    public profileInfoService: ProfileInfoService,
    public myShelfListService: MyShelfListService,
    public router: Router,
    public route: ActivatedRoute,
    public profilesService: ProfilesService,
    public titleListService: TitleListService,
    public commonService: CommonService
  ) {}
  /**
   * TO get interests on init
   */
  ngOnInit(): void {
    this.selectedProfileType = this.profileInfoService?.profileInfo?.profileType === 'Adult' ? 'General' : this.profileInfoService?.profileInfo?.profileType;
    this.getInterests();
    this.homeAnalyticsService.trackScreen('INTEREST_SURVEY_PAGE');
  }
  /**
   * To sts initial interest from pop up
   * @param interests popup data
   */
  setInitialInterest(modalData: UserInterest) {
    this.interests = deepClone(modalData.interests);
    this.handleAudienceLevel(modalData.audience);
    this.initialInterests = modalData.interests;
    this.initialAudience = modalData.audience || [];
    this.isLoading = false;
  }
  /**
   * To handles audience level
   * @param [audience] audience from API response
   */
  handleAudienceLevel(audience: Audience = []) {
    const selectedAudience = audience.map((audienceObj) => audienceObj.audienceLevel);
    this.audience = [INTERESTS_AUDIENCE_LEVEL.JUVENILE, INTERESTS_AUDIENCE_LEVEL.YOUNG_ADULT, INTERESTS_AUDIENCE_LEVEL.ADULT].map(
      (audienceLevel) => ({
        audienceLevel: audienceLevel.value,
        audienceLevelName: audienceLevel.name,
        checked: selectedAudience.includes(audienceLevel.value)
      })
    );
  }
  /**
   * To check audience level check box
   * @param checked state of the checkbox
   * @param index index of the audience level
   */
  checkAudience(checked: boolean, index: number) {
    this.audience[index].checked = checked;
    this.isDirty = this.interests.findIndex((x) => x.selected) === -1 ? false : true;
    this.homeAnalyticsService.trackInteresetSurveyEvent(checked ? 'AUDIENCE_SELECTED' : 'AUDIENCE_UNSELECTED', {
      audienceLevel: this.audience[index].audienceLevelName
    });
  }
  /**
   * TO get interests from API
   */
  getInterests() {
    this.homeService.getInterests().subscribe(
      (response) => {
        if (response.statusCode === 200 && response.message === DEFAULT_SUCCESS_MESSAGE) {
          this.interests = response.userInterests.interests;
          this.handleAudienceLevel(response.userInterests.audience);
          this.isLoading = false;
          positionFooter(true);
        } else {
          this.snackBarService.showSnackBar();
          this.isLoading = false;
          positionFooter(true);
        }
      },
      () => {
        this.snackBarService.showSnackBar();
        this.isLoading = false;
        positionFooter(true);
      }
    );
  }
  /**
   * To select interest
   * @param index index to select
   */
  selectInterest(index: number) {
    const { interestName, selected } = this.interests[index];
    this.interests[index].selected = !selected;
    this.homeAnalyticsService.trackInteresetSurveyEvent(selected ? 'INTEREST_UNSELECTED' : 'INTEREST_SELECTED', { interestName });
    this.isDirty = this.interests.findIndex((x) => x.selected) === -1 ? false : true;
    this.announcement = this.interests[index].selected ? 'pressed' : 'not pressed';
    this.isMacOrTab = navigator.userAgent.match(/(Mac)/i) ? true : false;
  }
  /**
   * Redirect to previous page in history from interest survey on save
   */
  redirectToPrevPage = () => {
    this.homeAnalyticsService.trackInteresetSurveyEvent('INTEREST_CANCEL');
    this.isCancel = true;
    if (this.route.snapshot.queryParams.isMenu === 'yes') {
      document.title = `Profile Settings - ${this.selectedProfileType}`;
      this.router.navigate(['/view/profiles/edit', this.profileInfoService.currentProfile.profileId], { queryParams: { isFrom: 'yes' } }
      );
      return;
    }
    if (this.route.snapshot.queryParams.isFrom != INTEREST) {
      document.title = `Profile Settings - ${this.selectedProfileType}`;
      return window.history.back();
    }
    this.getInterestRecommendation();
  };
  /**
   * To save or no, thanks interests
   * @param [save] indicating the save action
   * @param [callback] callback for popup component
   */
  saveInterests(save = true, callback: (save?: boolean) => void = noop, closePopup = false) {
    if (!this.isCancel) {
      let eventKey = 'INTEREST_NO_THANKS';
      let interests = this.initialInterests;
      if (closePopup) {
        eventKey = 'INTEREST_CLOSE';
      }
      if (this.initialAudience && this.initialAudience.length > 0) {
        this.handleAudienceLevel(this.initialAudience);
      }
      if (save) {
        eventKey = 'INTEREST_SAVE';
        interests = this.interests;
      }
      this.homeAnalyticsService.trackInteresetSurveyEvent(eventKey as keyof typeof HOME_EVENTS_KEY);
      const selectedInterests = interests.filter((res) => res.selected);
      this.spinnerService
        .withObservable(
          this.homeService.saveInterests({
            interests: selectedInterests,
            audience: this.audience.filter((aud) => aud.checked).map(({ audienceLevel }) => ({ audienceLevel }))
          }),
          false,
          false
        )
        .subscribe(
          (interestRes: any) => {
            this.liveAnnouncer.announce('Finished Loading.', 'polite');
            if (interestRes.responseCode === 200) {
              if (save) {
                const msg = 'Interests saved successfully';
                this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, msg);
              }
              setTimeout(() => {
                callback(save);
              }, 200);
              return;
            }
            this.snackBarService.showSnackBar();
          },
          () => {
            this.liveAnnouncer.announce('Finished Loading.', 'polite');
            this.snackBarService.showSnackBar();
          }
        );
    }
  }

  getInterestRecommendation() {
    this.titleListService.pageRetainInfo.pageNumber = 1;
    this.titleListService.pageRetainInfo.listContent = [];
    window.history.back();
  }
}
