import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CurrentLibrary } from '@shared/data/config.model';
import { AccountService } from '@shared/services/account.service';
import { ConfigService } from '@shared/services/config.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { AccountComponent } from '../account.component';
import { LOGIN, SKIP_PATRONPIN } from '../shared/constants/account.constants';
import { ILibraryLoginInfo, ILogOn, LogOnResponseModel } from '../shared/data/account.model';
import { forceRedirect } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { Router } from '@angular/router';
import { SharedService } from '@shared/services/shared.service';
import { AccountAnalyticsService } from '../shared/services/account.analytics.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { DEFAULT_LOGIN_ERROR_BAR_MSG, SNAKE_BAR_MODE } from '@shared/constants/app.constants';

@Component({
  selector: 'axis360-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  forceRedirect = forceRedirect;
  @Input() noModal = false;
  passwordHide = true;
  pwdRecovery = false;
  pwdVerification = false;
  loginForm: FormGroup;
  loginResponse: LogOnResponseModel;
  showRegisterPrompt = false;
  registerResponse: LogOnResponseModel;
  currentLibrary: Partial<CurrentLibrary>;
  isLoading: boolean = false;
  loginData: ILogOn;
  libraryLoginInfo: ILibraryLoginInfo;
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public accountService: AccountService,
    private configService: ConfigService,
    private loginDialog: MatDialogRef<AccountComponent>,
    public spinnerService: SpinnerService,
    private commonService: CommonService,
    public profileService: ProfileInfoService,
    private cdref: ChangeDetectorRef,
    public router: Router,
    public sharedService: SharedService,
    public toastService: SnackBarService,
    public accountAnalyticsService: AccountAnalyticsService
  ) {}
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      logOnModel_UserName: ['', Validators.required],
      logOnModel_Password: ['', Validators.required],
      authenticationMethod: ['', Validators.required],
      errorMessage: [],
      ignoreValidation: [],
      isDialog: ['', Validators.required],
      libraryID: ['', Validators.required],
      libraryURL: [],
      noPatronPinNeeded: ['', Validators.required],
      passwordAuthenticationPrompt: [],
      passwordRecoverable: [],
      registrationPassword: [],
      rememberMe: ['', Validators.required],
      returnUrl: [],
      showLoginPrompt: [],
      showRegisterPrompt: [],
      usageMode: []
    });
    this.currentLibrary = this.configService.currentLibrary;
    this.libraryLoginInfo = this.configService.getLibraryLoginInfo();
    this.accountService.currentDialog = LOGIN;
    this.accountAnalyticsService.trackScreen('LOGIN');
    this.getLoginData();
  }

  getLoginData() {
    this.loginData = this.accountService.loginInfo;
    this.loginForm.patchValue(this.loginData);
    if (this.currentLibrary.authSettings.noPatronPinNeeded) {
      this.loginForm.patchValue({
        logOnModel_Password: SKIP_PATRONPIN
      });
    }
  }

  onSubmit(): void {
    if (!this.loginForm.valid) {
      return;
    }
    this.spinnerService.withObservable(this.accountService.accountLogin(this.loginForm.value)).subscribe(
      async (res) => {
        this.spinnerService.isOverlayLoading = false;
        if (res.registerModel_UserName) {
          this.registerResponse = res;
          this.showRegisterPrompt = true;
          return;
        }
        this.loginResponse = res;
        localStorage.setItem('partnerID', this.loginResponse.partnerID.toString());
        localStorage.setItem('locationID', this.loginResponse.locationID.toString());
        if (this.loginResponse.isLogOnSuccess) {
          !this.noModal && this.loginDialog.close(true);
          this.userService.userInfo.isLoggedIn = true;
          this.commonService.reloadMainComponent.next(true);
          this.sharedService.isNewUser = true;
          if (this.currentLibrary.axis360Enabled && !this.currentLibrary.kidsZoneEnabled) {
            if (this.loginResponse.defaultLandingPageID === 1) {
              this.router.navigateByUrl('/view/myshelf');
              return;
            }
            else {
              this.router.navigateByUrl('/view/library');
              return;
            }
          } else {
            this.commonService.hideHeaderFooter.next(true);
            this.router.navigate(['/view/profiles'], { queryParams: { isLogin: true } });
          }
          return;
        }
        this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_LOGIN_ERROR_BAR_MSG);
        this.accountAnalyticsService.trackScreen('LOGIN_ERROR');
      },
      () => {
        !this.noModal && this.loginDialog.close();
        this.commonService.showErrorAlert();
      }
    );
  }
  showRegister() {
    this.showRegisterPrompt = true;
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
}
