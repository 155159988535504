import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsComponent } from '@notifications/notifications.component';
import { NOTIFICATIONS_ELEM_ID } from '@notifications/shared/constants/notifications.constants';
import { NotificationsService } from '@notifications/shared/services/notifications.service';

@Component({
  selector: 'axis360-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss']
})
export class NotificationIconComponent implements OnInit {
  totalUnreadCount = '';
  notificationOpened = false;
  constructor(public matDialog: MatDialog, public notificationsService: NotificationsService) {}
  /**
   * To update messages count on init
   */
  ngOnInit() {
    this.notificationsService.updateMessagesCount();
  }
  /**
   * TO be called on Notifications icon click to open Notification popup
   */
  notificationClick() {
    if (this.notificationOpened) {
      return;
    }
    this.notificationOpened = !this.notificationOpened;
    const notificationDialog = this.matDialog.open(NotificationsComponent, {
      id: NOTIFICATIONS_ELEM_ID,
      panelClass: 'common-modal-container',
      autoFocus: true,
      ariaLabelledBy: "notification-title"
    });
    notificationDialog.afterClosed().subscribe(() => (this.notificationOpened = false));
  }
}
