<ng-container *ngIf="!openLoginForm && !isSecurityQuestion &&!successAlert">
    <a class="back-icon" role="button" aria-label="Back To Login Dialog" href="javascript:void(0)"
        (click)="openLoginForm = true">
        <mat-icon aria-hidden="true" svgIcon="kz-back-arrow"> </mat-icon>
    </a>
    <div class="login-container-sec kz-main">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
            <fieldset class="fieldset" attr.aria-labelledby="{{libraryLoginInfo?.authenticationType}} dialog">
                <legend class="legend">
                    <h2 class="heading-1" id="dialogHeading">{{libraryLoginInfo?.authenticationType}} Recovery</h2>
                </legend>
                <p class="description m-bottom">
                    Enter your {{libraryLoginInfo?.userDisplayName}} below to reset the
                    {{libraryLoginInfo?.authenticationType}}
                    associated with your account.
                </p>
                <div class="kz-login-form">
                    <mat-form-field appearance="fill"
                        [ngClass]="{'mb-0': !forgotPasswordForm.controls['username'].errors?.required && errorMessage}">
                        <mat-label>{{libraryLoginInfo.userDisplayName}}</mat-label>
                        <input matInput placeholder="" maxlength="51"
                            [formControl]="forgotPasswordForm.controls.username" (keyup)="errorMessage = ''"
                            axis360TrapFocus id="Username">

                        <mat-error
                            *ngIf="!forgotPasswordForm.controls['username'].errors?.required && forgotPasswordForm.controls['username'].errors?.maxlength"
                            role="none">{{libraryLoginInfo.userDisplayName}}
                            can not exceed 50 characters.</mat-error>
                        <mat-error *ngIf="forgotPasswordForm.controls['username'].errors?.required"
                            role="none">{{libraryLoginInfo.userDisplayName}} is Required</mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="!forgotPasswordForm.controls['username'].errors?.required && errorMessage"
                        role="alert">
                        {{errorMessage}}</mat-error>
                </div>
                <div class="done_Submit d-flex">
                    <button mat-raised-button class="btn-primary-blue saveBtn"
                        [ngClass]="{active: forgotPasswordForm && !forgotPasswordForm.invalid}"
                        [disabled]="forgotPasswordForm.invalid || !forgotPasswordForm"
                        (click)="accountAnalyticsService.trackAccountEvent('SUBMIT')">Submit</button>
                </div>
                <p class="description m-top">
                    Forgot the email associated with your account? Contact your librarian for further assistance.
                </p>
            </fieldset>
        </form>
    </div>

</ng-container>
<axis360-login *ngIf="openLoginForm"></axis360-login>
<ng-container *ngIf="isSecurityQuestion && !successAlert">
    <div class="login-container-sec kz-main">
        <h2 class="heading-1" id="dialogHeading">
            Answer Security Question
        </h2>
        <a class="back-icon" role="button" href="javascript:void(0)"
            (click)="isSecurityQuestion = false;accountService.currentDialog = currentDialog"
            attr.aria-label="Back To {{libraryLoginInfo.authenticationType}} recovery Dialog">
            <mat-icon aria-hidden="true" svgIcon="kz-back-arrow"></mat-icon>
        </a>
        <div class="kz-login-form">
            <form [formGroup]="securityQuestionForm" (ngSubmit)="onSecurityFormSubmit()">
                <mat-form-field appearance="fill">
                    <mat-label>Security Question</mat-label>
                    <input matInput placeholder="" [formControl]="securityQuestionForm.controls.securityQuestion"
                        readonly>
                </mat-form-field>
                <mat-form-field appearance="fill"
                    [ngClass]="{'mb-0': !securityQuestionForm.controls['securityAnswer'].errors?.required &&securityAnswerError}">
                    <mat-label>Security Answer</mat-label>
                    <input matInput placeholder="" maxlength="51"
                        [formControl]="securityQuestionForm.controls.securityAnswer" (keyup)="securityAnswerError = ''"
                        axis360TrapFocus id="SecurityAnswer"
                        (change)="accountAnalyticsService.trackAccountEvent('ENTER_ANSWER')">
                    <mat-error
                        *ngIf="!securityQuestionForm.controls['securityAnswer'].errors?.required && securityQuestionForm.controls['securityAnswer'].errors?.maxlength"
                        role="none">Answer
                        can not exceed 50 characters.</mat-error>
                    <mat-error *ngIf="securityQuestionForm.controls['securityAnswer'].errors?.required"
                        role="none">Please answer
                        your security question to recover {{libraryLoginInfo?.authenticationType}}.</mat-error>
                </mat-form-field>
                <p class="kz-mat-error"
                    *ngIf="!securityQuestionForm.controls['securityAnswer'].errors?.required && !securityQuestionForm.controls['securityAnswer'].errors?.maxlength && securityAnswerError"
                    role="alert">
                    {{securityAnswerError}}</p>
                <div class="done_Submit d-flex">
                    <button mat-raised-button class="btn-primary-blue saveBtn"
                        [ngClass]="{active : securityQuestionForm && !securityQuestionForm.invalid}"
                        [disabled]="!securityQuestionForm || securityQuestionForm.invalid"
                        (click)="accountAnalyticsService.trackAccountEvent('SUBMIT')">Submit</button>
                </div>
            </form>
        </div>
    </div>
    
</ng-container>
<ng-container *ngIf="successAlert">
    <div class="login-container-sec kz-main kz-password-successfull">
        <p class="heading-1" id="loc_password_recovery">
            Your password has been sent to the email associated with this account.
        </p>
        <div class="kz-login-form">
            <button id="loc_password_recovery_btn" mat-raised-button aria-describedby="loc_password_recovery"
                tabindex="0" class="btn-primary-blue kz-pass-btn" (click)="closeMatDialog()" cdkFocusInitial>Ok</button>
        </div>
    </div>
</ng-container>