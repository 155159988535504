<axis360-spinner-loader isLoading="true" *ngIf="isLoading"></axis360-spinner-loader>
<section class="hero-banner-main" *ngIf="!isLoading && program">
    <div class="hero-container">
        <div class="hero-card">
            <div class="hero-titles">
                <h1 aria-label="Featured Reading Program" class="hero-feature">Featured Reading Program
                </h1>
                <h2 class="hero-heading">
                    {{program.programName}}</h2>
            </div>
            <div class="books-card" (click)="$event.stopPropagation();" (keydown.enter)="$event.stopPropagation();">
                <axis360-featured-program-carousel [bookDetails]="program.books"></axis360-featured-program-carousel>
            </div>
            <div class="hero-buttom-mian">
                <a aria-label="View Program Details" [href]="program.viewDetailsLink" class="view-programs-btn"
                    (click)="viewProgramDetails()" (keydown.enter)="viewProgramDetails()" id="loc_btnViewProgramDetails">View
                    Program Details</a>
                <a tabindex="0" aria-label="See All Programs" class="see-all-progs" href="/ng/programs/open"
                    (click)="seeAllPrograms()" id="loc_link_ViewAllOpenPrograms">
                    See All Programs</a>
            </div>
        </div>
    </div>
</section>