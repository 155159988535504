<div class="kz-custom-container-primary">
    <h1 class="primary-heading-1 kz-visually-hidden">Library Collection</h1>
</div>
<p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{myLibraryPage}}</p>
<div *ngIf="!isPPCLibraryEnabled">
    <div class="library-section" *ngIf="!(kidsZoneEnabled && !profileTypeChecker)">
        <div class="library-hidden"></div>
        <axis360-navigation-tabs class="nav-library" *ngIf="false" [centerTab]="centerTab" [tabsList]="tabsList">
        </axis360-navigation-tabs>
        <ng-container *ngIf="!(kidsZoneEnabled && !profileTypeChecker) && componentsTitleFiler.length > 0;else nodata">
            <axis360-title-filter *ngIf="!hasError" class="filter-main d-flex"
                [componentsTitleFiler]="componentsTitleFiler" [enableFilter]='enableFilter'
                (homeFilterChanged)="refreshCarousels()">
            </axis360-title-filter>
        </ng-container>
        <ng-template #nodata>
            <axis360-title-filter *ngIf="!hasError" class="filter-main d-flex" (homeFilterChanged)="refreshCarousels()"
                [enableFilter]='enableFilter'>
            </axis360-title-filter>
        </ng-template>
    </div>
</div>
<axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
<ng-container *ngIf="!(kidsZoneEnabled && !profileTypeChecker)">
    <ng-container *ngFor="let component of componentsArr; let i=index">
        <ng-container
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.FEATURED_READING_PROGRAM && showFeaturedProgram && component.isShow">
            <axis360-feature-program [compData]="component" [parentRoute]="parentRoute"
                id="list_id_{{component.entityId}}" [ngClass]="component.bgclass"
                (progressiveEmit)="progressiveEmit()"></axis360-feature-program>
        </ng-container>
        <ng-container
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.LIBRARY_BANNERS && component.components && component.components.length > 0">
            <axis360-lib-banners class="carousel-block" [libBannerData]="component" id="list_id_{{component.entityId}}"
                [ngClass]="component.bgclass" [showIframe]="showIframe" (progressiveEmit)="progressiveEmit()">
            </axis360-lib-banners>
        </ng-container>
        <ng-container
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.FEATURED_LIST && component.entityId && showComponent">
            <axis360-featured-list class="carousel-block" [featuredList]="component" [parentRoute]="parentRoute"
                [ngClass]="component.bgclass" id="list_id_{{component.entityId}}"
                (removeCuratedListEvent)="removeCuratedList($event)" (progressiveEmit)="progressiveEmit()">
            </axis360-featured-list>
        </ng-container>
        <ng-container
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.ALWAYS_AVAILABLE && showComponent && showAlwaysAvailable && component.isShow">
            <axis360-always-available [compData]="component" [parentRoute]="parentRoute" id="list_id_always_available"
                (removeCuratedListEvent)="removeCuratedList($event)" (progressiveEmit)="progressiveEmit()">
            </axis360-always-available>
        </ng-container>

        <ng-container
            *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.CURATED_LIST && component.entityId  && showComponent">
            <axis360-curated-lists class="carousel-block" [listData]="component" id="list_id_{{component.entityId}}"
                [parentRoute]="parentRoute" (removeCuratedListEvent)="removeCuratedList($event)"
                [ngClass]="component.bgclass" (progressiveEmit)="progressiveEmit()"></axis360-curated-lists>
        </ng-container>
        <ng-container *ngIf="component.entityType === LIB_COMP_ENTITY_TYPES.THIRD_PARTY || component.entityType === LIB_COMP_ENTITY_TYPES.WIDGETS_BANNERS &&
            component.entityId && showComponent">
            <div class="kz-third-party-library-carousel kz-custom-container-secondary carousel-block"
                id="list_id_{{component.entityId}}" [ngClass]="component.bgclass">
                <axis360-third-party-carousel [compData]="component"
                    (removeEmptyThirdPartyEvent)="removeEmptyThirdParty($event)" [parentRoute]="parentRoute"
                    (progressiveEmit)="progressiveEmit()"></axis360-third-party-carousel>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
<axis360-no-content
    *ngIf="hasError || (kidsZoneEnabled && !profileTypeChecker) || (!myLibraryService.titlesAvailable && noComponentsToShow && !myLibraryService.isThirdPartyAvailable && !myLibraryService.isCuratedListAvailable)">
</axis360-no-content>