import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'axis360-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss']
})
export class ThirdPartyComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }
}
