import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ParentalPinComponent } from './parental-pin/parental-pin.component';
import { AvatarsComponent } from './avatars/avatars.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { ProfilesListComponent } from './profiles-list/profiles-list.component';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { CreateProfileTypeComponent } from './create-profile-type/create-profile-type.component';
import { ProfileAvatarImageComponent } from './profile-avatar-image/profile-avatar-image.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadInputComponent } from './shared/components/file-upload-input/file-upload-input.component';
import { CodeInputModule } from 'angular-code-input';
import { ProfilesAnalyticsService } from './shared/services/profiles.analytics.service';
import { SharedModule } from '@shared/shared.module';
import { SecurityVerificationComponent } from './shared/components/security-verification/security-verification.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CanDeactivateGuard } from './shared/guard/deactive.guard';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgPinInputComponent } from './ng-otp-input/ng-otp-input.component';
import { A11yModule } from '@angular/cdk/a11y';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    ProfileComponent,
    ProfileEditComponent,
    ParentalPinComponent,
    AvatarsComponent,
    ProfilesListComponent,
    ProfilesListComponent,
    CreateProfileComponent,
    CreateProfileTypeComponent,
    ProfileAvatarImageComponent,
    FileUploadInputComponent,
    SecurityVerificationComponent,
    NgPinInputComponent
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    MatListModule,
    MatDialogModule,
    MatTooltipModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CodeInputModule,
    SharedModule,
    NgOtpInputModule,
    A11yModule
  ],
  providers: [ProfilesAnalyticsService, CanDeactivateGuard]
})
export class ProfileModule { }
