<div class="kz-my-stuff">
    <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'">
    </axis360-breadcrumb>
    <div class="kz-title">
        <h1 class="heading-2 mystuff-head" id="mystuff-title">My Stuff</h1>
    </div>
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{pageAnnounce}}</p>
    <axis360-mystuff-nav-tabs [isMyStuff]="true" [activeMyStuff]="myStuffService.activeMyStuff" (selectedMystuffTitle)="selectMystuffTitle($event)">
    </axis360-mystuff-nav-tabs>
    <div class="sub-menu-bar">
        <h2 class="heading-2" id="loc_txt{{MYSTUFF_ACTIVE_TAB[myStuffService.activeMyStuff]}}">{{MYSTUFF_ACTIVE_TAB[myStuffService.activeMyStuff]}}</h2>
        <div class="mystuff-sidebar-list">
            <axis360-sidebar-menu (filterChanged)="refinerChanged($event)" (sortChanged)="refinerChanged($event)" (searchChanged)="searchChange($event)" [activeMyStuff]="myStuffService.activeMyStuff"></axis360-sidebar-menu>
        </div>
    </div>
   <div class="kz-my-stuff-main">
    <ng-container *ngIf="myStuffContent && myStuffContent.Items && myStuffContent.Items.length > 0">
        <axis360-my-stuff-grid-card *ngIf="!myStuffService.isGridView" [myStuffContent]="myStuffContent"
            [activeMyStuff]="activeMyStuff" [parentRoute]="parentRoute" (titleActionEvt)="refreshAndGetMyStuffContent()"></axis360-my-stuff-grid-card>
        <axis360-my-stuff-list-card *ngIf="myStuffService.isGridView && refreshResumeCTA" [myStuffContent]="myStuffContent"
            [activeMyStuff]="activeMyStuff" [parentRoute]="parentRoute" (titleActionEvt)="refreshAndGetMyStuffContent()"></axis360-my-stuff-list-card>
    </ng-container>
    <ng-container
        *ngIf="!isLoading && !spinnerService.isLoading && (!myStuffContent || !myStuffContent.Items || myStuffContent.Items.length == 0) && (myStuffService.filterOption === All) && !isSearch">
        <div class="no-stuffs">You currently have no {{MYSTUFF_ACTIVE_TAB[activeMyStuff]}}.</div>
    </ng-container>
    <ng-container
        *ngIf="!spinnerService.isLoading && (!myStuffContent || !myStuffContent.Items || myStuffContent.Items.length == 0) && (myStuffService.filterOption === All) && isSearch" >
        <div class="no-stuffs" aria-live="polite" id="search-input">{{noResultMessage}}</div>
    </ng-container>
    <ng-container
        *ngIf="!spinnerService.isLoading && (!myStuffContent || !myStuffContent.Items || myStuffContent.Items.length == 0) && (myStuffService.filterOption !== All)">
        <div class="no-stuffs">{{noResultMessage}}</div>
    </ng-container>
   </div>
<axis360-spinner-loader></axis360-spinner-loader>
</div>
