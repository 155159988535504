import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { EngageBaseService } from '@shared/services/engage-base.service';

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService extends EngageBaseService {

  /**
   * API Call for getting the Terms Conditions
   *
   */
  getTermsConditions = (type: string): Promise<any> =>
    this.http.get<any>(API_INFO.getTermsConditions.replace('<type>',type)).toPromise();
}
