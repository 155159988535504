import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PublicationCard } from '@pressreader/shared/data/press-reader-model';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import { forceRedirect, getCountyAtrr, getDate, getFullDay, getFullMonth, getFullYear } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-press-reader-card',
  templateUrl: './press-reader-card.component.html',
  styleUrls: ['./press-reader-card.component.scss']
})
export class PressReaderCardComponent {
  @Input() publicationCard: PublicationCard;
  @Input() usedIn: string;
  @Input() type = '';
  @Input() title = '';
  @Input() fromHomeCarousel = false;
  getDate = getDate;
  forceRedirect = forceRedirect;
  /**
   * Creates an instance of press reader card component.
   * @param pressReaderService press reader service
   * @param router router info
   */
  constructor(
    public pressReaderService: PressReaderService,
    public router: Router,
    public pressReaderAnalyticsService: PressReaderAnalyticsService
  ) {}

  /**
   * To invoke read now from press reader service
   * @param event pointer event
   * @param redirectionUrl redirection url
   */
  readNow(event: PointerEvent, redirectionUrl: string) {
    this.pressReaderAnalyticsService.trackPressReaderEvent('READ_NOW');
    this.pressReaderService.readNow(event, redirectionUrl);
  }

  /**
   * redirect to press reader detail page
   */
  cardClick(type: string, title: string) {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CARD_DETAIL');
    if (this.fromHomeCarousel) {
      this.forceRedirect(
        `/ng/pressreader/details/${this.publicationCard.cid}?type=${type}&title=${title}&issueDate=${this.getPressReaderDate(
          this.publicationCard.issueDate
        )}`
      );
      return;
    }
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/pressreader/details/' + this.publicationCard.cid], {
        queryParams: { type, title, issueDate: this.getPressReaderDate(this.publicationCard.issueDate) }
      })
    );
  }

  /**
   * To get date format for press reader API
   * @param date issue date
   * @returns date format for pr API
   */
  getPressReaderDate = (date?: string | Date) => {
    return getFullYear(date) + '-' + getFullMonth(date) + '-' + getFullDay(date);
  };

  /**
   * To handle image error
   * @param event input parameter as event
   */
  onImgError(event: any) {
    event.target.src = '/angular-app/assets/images/PressReaderHiddenImage.png';
  }
  /**
   * to returns multiple country without pipe symbol
   * @param multiCountry input parameter as multiCountry
   * @returns to returns multiple country without pipe symbol
   */
  getCountyAtrr = (multiCountry: string) => {
    return getCountyAtrr(multiCountry);
  };
}
