export const SHARED_SCREENS_KEY = {
  COMMON: 'COMMON'
};

export const SHARED_SCREENS: { [key: string]: string } = {
  [SHARED_SCREENS_KEY.COMMON]: 'Common'
};

export const SHARED_CATEGORY = {
  NAV_TABS: 'Quick links',
  TITLE_CAROUSEL: 'Title Carousel',
  NEWSPAPER_CAROUSEL: 'Newspaper & Magazines Carousel',
  TITLE_ACTIONS: 'Title Actions'
};
export const SHARED_EVENTS_KEY = {
  CLICK_NAV_TAB: 'CLICK_NAV_TAB',
  CLICK_TITLE_CARD: 'CLICK_TITLE_CARD',
  CLICK_SEE_ALL: 'CLICK_SEE_ALL',
  CLICK_CAROUSEL_RIGHT: 'CLICK_CAROUSEL_RIGHT',
  CLICK_CAROUSEL_LEFT: 'CLICK_CAROUSEL_LEFT',
  CLICK_TITLE_ACTION: 'CLICK_TITLE_ACTION',
  CLICK_NAV_TAB_PROGRAM: 'CLICK_NAV_TAB_PROGRAM',
  ADD_TITLE_CTA: 'ADD_TITLE_CTA',
  TITLE_COVER_IMAGE: 'TITLE_COVER_IMAGE'
};
export const SHARED_EVENTS: { [key: string]: string } = {
  [SHARED_EVENTS_KEY.CLICK_NAV_TAB]: 'Click <tabName>',
  [SHARED_EVENTS_KEY.CLICK_SEE_ALL]: 'View see all CTA - <carouselName>',
  [SHARED_EVENTS_KEY.CLICK_TITLE_CARD]: 'View Book details CTA - <titleName>',
  [SHARED_EVENTS_KEY.CLICK_CAROUSEL_RIGHT]: 'Click carousel navigation - right',
  [SHARED_EVENTS_KEY.CLICK_CAROUSEL_LEFT]: 'Click carousel navigation - left',
  [SHARED_EVENTS_KEY.CLICK_TITLE_ACTION]: '<actionName>',
  [SHARED_EVENTS_KEY.CLICK_NAV_TAB_PROGRAM]: '<tabName>',
  [SHARED_EVENTS_KEY.ADD_TITLE_CTA]: 'Add a Title CTA',
  [SHARED_EVENTS_KEY.TITLE_COVER_IMAGE]: 'Title cover Image CTA'

};
