import { 
  PRESS_READER_PAGE_SIZE,
  PRESS_READER_SORT_OPTIONS,
  PUBLICATION_IMAGE_URL,
  PUBLICATION_REDIRECTION_URL,
  PUBLICATION_TYPES
} from "@shared/constants/app.constants";
export { 
  PRESS_READER_PAGE_SIZE,
  PRESS_READER_SORT_OPTIONS,
  PUBLICATION_IMAGE_URL,
  PUBLICATION_REDIRECTION_URL,
  PUBLICATION_TYPES
}

export const PRESS_READER_LIST_PAGE_SIZE = 24;
export const PRESS_READER_LATEST_ISSUE_LIMIT = 90;
export const PUBLICATION_TITLE_MAP = {
  [PUBLICATION_TYPES.MAGAZINES]: 'Magazines',
  [PUBLICATION_TYPES.NEWS_PAPERS]: 'Newspapers',
  [PUBLICATION_TYPES.LATEST_ISSUES]: 'Latest Issues',
  [PUBLICATION_TYPES.SUPPLEMENT]: 'Supplements'
};
export const CATEGORY_CAROUSEL = [
  'Business & Current Affairs',
  'Entertainment & TV',
  'Food & Drinks',
  'Health & Fitness',
  'Home & Garden',
  'News',
  'Travel & Culture'
];
export const PRESS_READER_PUB_FILTER_OPTIONS = {
  ALL: { text: 'All', value: '' },
  NEWS_PAPERS: { text: 'Newspapers', value: 'Newspaper' },
  MAGAZINES: { text: 'Magazines', value: 'Magazine' }
};
