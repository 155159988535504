export interface Theme {
  name: string;
  properties: any;
}
export const ADULT_THEME: Theme = {
  name: 'adult',
  properties: {
    '--primary': '#06275C',
    '--on-primary': '#4274C2',
    '--secondary': '#4274C2',
    '--on-secondary': '#98BFFF',
    '--tertiary': '#EFF3FB',
    '--on-tertiary': '#4274C2',
    '--quaternary': '#f4f4f4',
    '--on-quaternary': '#707070',
    '--quinary': '#06275C',
    '--on-quinary': '0 0.8rem 1.2rem -0.2rem rgba(16, 24, 40, 0.15), 0.8rem 0 1.2rem -0.2rem rgba(16, 24, 40, 0.1)',
    '--surface': '#f4f4f4',
    '--on-surface': '#E1ECFF',
    '--on-surface-info': '#ffffff',
    '--primary-info': '#3E8424',
    '--secondary-info': '#707070',
    '--primary-text': '#171717',
    '--secondary-text': '#00508B',
    '--tertiary-text': '#ffffff',
    '--info': '#d14900',
    '--quaternary-text': '#707070',
    '--quinary-text': '#757575',
    '--common-border': '#d9d9d6',
    '--on-info': '#000000',
    '--primary-border': '#B4BECE',
    '--success': '#3e8424',
    '--warning': '#d14900',
    '--complete': '#757575',
    '--not-complete': '#707070',
    '--primary-nav-tab': '#06275C',
    '--primary-box-shadow': '0 0 0.8rem 0 rgba(0, 80, 139, 0.5)',
    '--secondary-border': 'rgba(152, 191, 255, 1)',
    '--pills-border': '#4274C2',
    '--on-tertiary-border': 'transparent',
    '--primary-color': '#00508b',
    '--primary-color-contrast':'#0D5D98',
    '--secondary-title-text': '#ffffff',
    '--search-icon': '#707070',
    '--on-progress-bar': '#98BFFF',
    '--carousel-arrow': 'transparent',
    '--carousel-rightarrow-image': 'url("/angular-app/assets/images/kz-images/learning-right-arrow.svg")',
    '--carousel-leftarrow-image': 'url("/angular-app/assets/images/kz-images/learning-left-arrow.svg")',
    '--carousel-rightbookarrow-image': 'url("/angular-app/assets/images/kz-images/right-arrow.svg")',
    '--carousel-leftbookarrow-image': 'url("/angular-app/assets/images/kz-images/left-arrow.svg")',
    '--on-primary-text': '#757575',
    '--checkbox-checked': 'url(/angular-app/assets/images/kz-images/check-box-checked.svg)',
    '--on-secondary-text': '#707070',
    '--on-tertiary-text': '#00508b',
    '--profile-color': '#EFF3FB',
    '--common-border-line': '#d9d9d6',
    '--login-checkbox': '#737373'
  }
};

export const ADULT_HIGH_CONTRAST_THEME: Theme = {
  name: 'adult-high-contrast',
  properties: {
    '--primary': '#06275C',
    '--on-primary': '#06275C',
    '--secondary': '#EFF3FB',
    '--on-secondary': '#06275C',
    '--tertiary': '#EFF3FB',
    '--on-tertiary': '#06275C',
    '--quaternary': '#f4f4f4',
    '--on-quaternary': '#707070',
    '--quinary': '#06275C',
    '--on-quinary': '0 0.8rem 1.2rem -0.2rem rgba(16, 24, 40, 0.15), 0.8rem 0 1.2rem -0.2rem rgba(16, 24, 40, 0.1)',
    '--surface': '#f4f4f4',
    '--on-surface': 'transparent',
    '--on-surface-info': '#ffffff',
    '--primary-info': '#3E8424',
    '--secondary-info': '#707070',
    '--primary-text': '#171717',
    '--secondary-text': '#00508B',
    '--tertiary-text': '#ffffff',
    '--info': '#d14900',
    '--quaternary-text': '#000000',
    '--quinary-text': '#757575',
    '--common-border': '#d9d9d6',
    '--on-info': '#000000',
    '--primary-border': '#707070',
    '--success': '#3e8424',
    '--warning': '#d14900',
    '--complete': '#757575',
    '--not-complete': '#707070',
    '--primary-nav-tab': '#06275C',
    '--primary-box-shadow': '0 0 0.8rem 0 rgba(0, 80, 139, 0.5)',
    '--secondary-border': '#06275C',
    '--pills-border': '#4274C2',
    '--on-tertiary-border': '#06275C',
    '--primary-color': '#06275C',
    '--secondary-title-text': '#000000',
    '--search-icon': '#000000',
    '--on-progress-bar': '#98BFFF',
    '--carousel-arrow': '#ffffff',
    '--carousel-rightarrow-image': 'url("/angular-app/assets/images/kz-images/primary-right-arrow.svg")',
    '--carousel-leftarrow-image': 'url("/angular-app/assets/images/kz-images/primary-left-arrow.svg")',
    '--carousel-rightbookarrow-image': 'url("/angular-app/assets/images/kz-images/primary-right-arrow.svg")',
    '--carousel-leftbookarrow-image': 'url("/angular-app/assets/images/kz-images/primary-left-arrow.svg")',
    '--on-primary-text': '#000000',
    '--checkbox-checked': 'url(/angular-app/assets/images/kz-images/check-box-checked-dark.svg)',
    '--on-secondary-text': '#000000',
    '--on-tertiary-text': '#000000',
    '--profile-color': '#EFF3FB',
    '--common-border-line': '#06275C',
    '--login-checkbox': '#000000'
  }
};


export const TEEN_THEME: Theme = {
  name: 'teen',
  properties: {
    '--primary': '#06275C',
    '--on-primary': '#4274C2',
    '--secondary': '#427D8F',
    '--on-secondary': '#90D0D5',
    '--tertiary': '#DDF8F6',
    '--on-tertiary': '#318F9A',
    '--quaternary': '#E8FAFD',
    '--on-quaternary': '#318F9A',
    '--quinary': '#366775',
    '--on-quinary': '0 0.8rem 1.2rem -0.2rem rgba(144, 208, 213, 0.25), 0.8rem 0 1.2rem -0.2rem rgba(144, 208, 213, 0.25)',
    '--surface': '#f4f4f4',
    '--on-surface': '#DDF8F6',
    '--on-surface-info': '#ffffff',
    '--primary-info': '#3E8424',
    '--secondary-info': '#707070',
    '--primary-text': '#171717',
    '--secondary-text': '#00508B',
    '--tertiary-text': '#ffffff',
    '--info': '#d14900',
    '--quaternary-text': '#707070',
    '--quinary-text': '#757575',
    '--common-border': '#d9d9d6',
    '--on-info': '#000000',
    '--primary-border': '#B4BECE',
    '--success': '#3e8424',
    '--warning': '#d14900',
    '--complete': '#757575',
    '--not-complete': '#707070',
    '--primary-nav-tab': '#366775',
    '--primary-box-shadow': '0 0 0.8rem 0 rgba(54, 103, 117, 0.5)',
    '--secondary-border': 'rgba(144, 208, 213, 1)',
    '--pills-border': '#4B8FA3',
    '--on-tertiary-border': 'transparent',
    '--primary-color': '#00508b',
    '--secondary-title-text': '#ffffff',
    '--search-icon': '#707070',
    '--on-progress-bar': '#90D0D5',
    '--carousel-arrow': 'transparent',
    '--carousel-rightarrow-image': 'url("/angular-app/assets/images/kz-images/learning-right-arrow.svg")',
    '--carousel-leftarrow-image': 'url("/angular-app/assets/images/kz-images/learning-left-arrow.svg")',
    '--carousel-rightbookarrow-image': 'url("/angular-app/assets/images/kz-images/right-arrow.svg")',
    '--carousel-leftbookarrow-image': 'url("/angular-app/assets/images/kz-images/left-arrow.svg")',
    '--on-primary-text': '#757575',
    '--checkbox-checked': 'url(/angular-app/assets/images/kz-images/check-box-checked.svg)',
    '--on-secondary-text': '#707070',
    '--on-tertiary-text': '#00508b',
    '--profile-color': '#DDF8F6',
    '--common-border-line': '#d9d9d6',
    '--login-checkbox': '#737373'
  }
};

export const TEEN_HIGH_CONTRAST_THEME: Theme = {
  name: 'teen-high-contrast',
  properties: {
    '--primary': '#366775',
    '--on-primary': '#366775',
    '--secondary': '#DDF8F6',
    '--on-secondary': '#366775',
    '--tertiary': '#DDF8F6',
    '--on-tertiary': '#366775',
    '--quaternary': '#f4f4f4',
    '--on-quaternary': '#707070',
    '--quinary': '#366775',
    '--on-quinary': '0 0.8rem 1.2rem -0.2rem rgba(144, 208, 213, 0.25), 0.8rem 0 1.2rem -0.2rem rgba(144, 208, 213, 0.25)',
    '--surface': '#f4f4f4',
    '--on-surface': 'transparent',
    '--on-surface-info': '#ffffff',
    '--primary-info': '#3E8424',
    '--secondary-info': '#707070',
    '--primary-text': '#171717',
    '--secondary-text': '#00508B',
    '--tertiary-text': '#ffffff',
    '--info': '#d14900',
    '--quaternary-text': '#000000',
    '--quinary-text': '#757575',
    '--common-border': '#d9d9d6',
    '--on-info': '#000000',
    '--primary-border': '#707070',
    '--success': '#3e8424',
    '--warning': '#d14900',
    '--complete': '#757575',
    '--not-complete': '#707070',
    '--primary-nav-tab': '#366775',
    '--primary-box-shadow': '0 0 0.8rem 0 rgba(54, 103, 117, 0.5)',
    '--secondary-border': '#366775',
    '--pills-border': '#4B8FA3',
    '--on-tertiary-border': '#366775',
    '--primary-color': '#06275C',
    '--secondary-title-text': '#000000',
    '--search-icon': '#000000',
    '--on-progress-bar': '#90D0D5',
    '--carousel-arrow': '#ffffff',
    '--carousel-rightarrow-image': 'url("/angular-app/assets/images/kz-images/primary-right-arrow.svg")',
    '--carousel-leftarrow-image': 'url("/angular-app/assets/images/kz-images/primary-left-arrow.svg")',
    '--carousel-rightbookarrow-image': 'url("/angular-app/assets/images/kz-images/primary-right-arrow.svg")',
    '--carousel-leftbookarrow-image': 'url("/angular-app/assets/images/kz-images/primary-left-arrow.svg")',
    '--on-primary-text': '#000000',
    '--checkbox-checked': 'url(/angular-app/assets/images/kz-images/check-box-checked-dark.svg)',
    '--on-secondary-text': '#000000',
    '--on-tertiary-text': '#000000',
    '--profile-color': '#DDF8F6',
    '--common-border-line': '#366775',
    '--login-checkbox': '#000000'
  }
};


export const KID_THEME: Theme = {
  name: 'kid',
  properties: {
    '--primary': '#06275C',
    '--on-primary': '#4274C2',
    '--secondary': '#AF4BBE',
    '--on-secondary': '#E3A7ED',
    '--tertiary': '#FDEEFF',
    '--on-tertiary': '#AF4BBE',
    '--quaternary': '#FDEEFF',
    '--on-quaternary': '#AF4BBE',
    '--quinary': '#762682',
    '--on-quinary': '0 0.8rem 1.2rem -0.2rem rgba(176, 79, 191, 0.25), 0.8rem 0 1.2rem -0.2rem rgba(176, 79, 191, 0.25)',
    '--surface': '#f4f4f4',
    '--on-surface': '#F7E5FA',
    '--on-surface-info': '#ffffff',
    '--primary-info': '#3E8424',
    '--secondary-info': '#707070',
    '--primary-text': '#171717',
    '--secondary-text': '#00508B',
    '--tertiary-text': '#ffffff',
    '--info': '#d14900',
    '--quaternary-text': '#707070',
    '--quinary-text': '#757575',
    '--common-border': '#d9d9d6',
    '--on-info': '#000000',
    '--primary-border': '#B4BECE',
    '--success': '#3e8424',
    '--warning': '#d14900',
    '--complete': '#757575',
    '--not-complete': '#707070',
    '--primary-nav-tab': '#762682',
    '--primary-box-shadow': '0 0 0.8rem 0 rgba(118, 38, 130, 0.5)',
    '--secondary-border': 'rgba(227, 167, 237, 1)',
    '--pills-border': '#AF4BBE',
    '--on-tertiary-border': 'transparent',
    '--primary-color': '#00508b',
    '--secondary-title-text': '#ffffff',
    '--search-icon': '#707070',
    '--on-progress-bar': '#E3A7ED',
    '--carousel-arrow': 'transparent',
    '--carousel-rightarrow-image': 'url("/angular-app/assets/images/kz-images/learning-right-arrow.svg")',
    '--carousel-leftarrow-image': 'url("/angular-app/assets/images/kz-images/learning-left-arrow.svg")',
    '--carousel-rightbookarrow-image': 'url("/angular-app/assets/images/kz-images/right-arrow.svg")',
    '--carousel-leftbookarrow-image': 'url("/angular-app/assets/images/kz-images/left-arrow.svg")',
    '--on-primary-text': '#757575',
    '--checkbox-checked': 'url(/angular-app/assets/images/kz-images/check-box-checked.svg)',
    '--on-secondary-text': '#707070',
    '--on-tertiary-text': '#00508b',
    '--profile-color': '#FDEEFF',
    '--common-border-line': '#d9d9d6',
    '--login-checkbox': '#737373'
  }
};

export const KID_HIGH_CONTRAST_THEME: Theme = {
  name: 'kid-high-contrast',
  properties: {
    '--primary': '#762682',
    '--on-primary': '#762682',
    '--secondary': '#FDEEFF',
    '--on-secondary': '#762682',
    '--tertiary': '#FDEEFF',
    '--on-tertiary': '#762682',
    '--quaternary': '#f4f4f4',
    '--on-quaternary': '#707070',
    '--quinary': '#762682',
    '--on-quinary': '0 0.8rem 1.2rem -0.2rem rgba(176, 79, 191, 0.25), 0.8rem 0 1.2rem -0.2rem rgba(176, 79, 191, 0.25)',
    '--surface': '#f4f4f4',
    '--on-surface': 'transparent',
    '--on-surface-info': '#ffffff',
    '--primary-info': '#3E8424',
    '--secondary-info': '#707070',
    '--primary-text': '#171717',
    '--secondary-text': '#00508B',
    '--tertiary-text': '#ffffff',
    '--info': '#d14900',
    '--quaternary-text': '#000000',
    '--quinary-text': '#757575',
    '--common-border': '#d9d9d6',
    '--on-info': '#000000',
    '--primary-border': '#707070',
    '--success': '#3e8424',
    '--warning': '#d14900',
    '--complete': '#757575',
    '--not-complete': '#707070',
    '--primary-nav-tab': '#762682',
    '--primary-box-shadow': '0 0 0.8rem 0 rgba(118, 38, 130, 0.5)',
    '--secondary-border': '#762682',
    '--pills-border': '#AF4BBE',
    '--on-tertiary-border': '#762682',
    '--primary-color': '#06275C',
    '--secondary-title-text': '#000000',
    '--search-icon': '#000000',
    '--on-progress-bar': '#E3A7ED',
    '--carousel-arrow': '#ffffff',
    '--carousel-rightarrow-image': 'url("/angular-app/assets/images/kz-images/primary-right-arrow.svg")',
    '--carousel-leftarrow-image': 'url("/angular-app/assets/images/kz-images/primary-left-arrow.svg")',
    '--carousel-rightbookarrow-image': 'url("/angular-app/assets/images/kz-images/primary-right-arrow.svg")',
    '--carousel-leftbookarrow-image': 'url("/angular-app/assets/images/kz-images/primary-left-arrow.svg")',
    '--on-primary-text': '#000000',
    '--checkbox-checked': 'url(/angular-app/assets/images/kz-images/check-box-checked-dark.svg)',
    '--on-secondary-text': '#000000',
    '--on-tertiary-text': '#000000',
    '--profile-color': '#FDEEFF',
    '--common-border-line': '#762682',
    '--login-checkbox': '#000000'
  }
};
