export const MY_STUFF_CATEGORY = {
  MY_STUFF: 'My Stuff'
};

export const MY_STUFF_SCREENS_KEY = {
  MY_STUFF: 'MY_STUFF'
};

export const MY_STUFF_SCREENS: { [key: string]: string } = {
  [MY_STUFF_SCREENS_KEY.MY_STUFF]: 'My Stuff - <myStuff>'
};

export const MY_STUFF_EVENTS_KEY = {
  ACTION_SEARCH: 'ACTION_SEARCH',
  ACTION_FILTER: 'ACTION_FILTER',
  ACTION_SORT: 'ACTION_SORT',
  ACTION_VIEW_LIST: 'ACTION_VIEW_LIST',
  ACTION_VIEW_GRID: 'ACTION_VIEW_GRID',
  ACTION_READ: 'MORE_ACTION',
  ACTION_SEARCH_ICON: 'ACTION_SEARCH_ICON'
};
export const MY_STUFF_EVENTS: { [key: string]: string } = {
  [MY_STUFF_EVENTS_KEY.ACTION_SEARCH]: 'Search keywords - <keywords>',
  [MY_STUFF_EVENTS_KEY.ACTION_FILTER]: 'Filter - <filterName>',
  [MY_STUFF_EVENTS_KEY.ACTION_SORT]: 'Sort - <sortName>',
  [MY_STUFF_EVENTS_KEY.ACTION_VIEW_LIST]: 'View - List',
  [MY_STUFF_EVENTS_KEY.ACTION_VIEW_GRID]: 'View - Grid',
  [MY_STUFF_EVENTS_KEY.ACTION_READ]: 'click Action <action>',
  [MY_STUFF_EVENTS_KEY.ACTION_SEARCH_ICON]: 'Search'
};
