<h1 class="newsstands-title">{{NEWS_TITLE}}</h1>
<mat-tab-group  animationDuration="0ms" [disableRipple]="true" disablePagination="true" (selectedTabChange)="selectedPubTabChange($event)" [selectedIndex]="searchService.selectedNewsStandTab" id="searchNewsTabs">
    <mat-tab [label]="MAT_PUB_TABS_NAME.PUBLICATIONS" [id]="MAT_PUB_TABS_ID.PUBLICATIONS">
        <axis360-title-and-refiners [titleText]="NEWS_TITLE" [hideNewsStands]="true" [searchTerm]="searchTerm" [resultsCount]="totalCountPub"
            [showSort]=true [showFilter]=true (openRefinerDialogEvent)="openRefinerDialogEvent($event)"
            [availableLimiters]="availableLimiters" [sortOptions]="sortOptionsPub"
            (applySortEvnt)="sortOptionSelected($event)" (applyFilterEvnt)="applyFilter($event)"
            (clearFilterEvnt)="clearFilters($event)"
            [hideText]="initialFailed || (offset === 0 && spinnerService.isLoading)"
            [hideRefiners]="initialFailed || (availableLimiters.length === 0 && offset === 0 && spinnerService.isLoading)">
        </axis360-title-and-refiners>
        <axis360-press-reader-publications [publications]="publications"></axis360-press-reader-publications>
        <axis360-spinner-loader></axis360-spinner-loader>
    </mat-tab>
    <mat-tab [label]="MAT_PUB_TABS_NAME.ARTICLES" [id]="MAT_PUB_TABS_ID.ARTICLES">
        <ng-container>
            <axis360-title-and-refiners [titleText]="NEWS_TITLE" [hideNewsStands]="true" [searchTerm]="searchTerm" [resultsCount]="totalCount"
                [showSort]=true (openRefinerDialogEvent)="openRefinerDialogEvent($event)"
                [availableLimiters]="availableLimiters" [sortOptions]="sortOptions"
                (applySortEvnt)="sortOptionSelected($event)" (applyFilterEvnt)="applyFilter($event)"
                (clearFilterEvnt)="clearFilters($event)"
                [hideText]="initialFailed || (offset === 0 && spinnerService.isLoading)"
                [hideRefiners]="initialFailed || (availableLimiters.length === 0 && offset === 0 && spinnerService.isLoading)">
            </axis360-title-and-refiners>
            <axis360-search-results-cards [titleCardsInfo]="newsInfo" [view]="view" [searchTerm]="searchTerm"
                [initialFailed]="initialFailed" (titleCardClickEvent)="titleCardClick($event)">
            </axis360-search-results-cards>
            <axis360-spinner-loader></axis360-spinner-loader>
        </ng-container>
    </mat-tab>
</mat-tab-group>