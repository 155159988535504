<div class="search kz-custom-container-secondary" (window:resize)="onResize()">
    <div class="bread-crum-main">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb kz-search'">
        </axis360-breadcrumb>
    </div>
    <button class="skip-refiners skip-refiners-format" id="loc_btnSkiptoMain" (click)="skipRefiners()" (keydown.enter)="skipRefiners()">
        Skip the Refiners
    </button>
    <div class="search-container mb-3">
        <div class="refiner-sec" *ngIf="!isMobile">
            <div class="col-lg-4 col-xs-12 sticky-refiner" *ngIf="!refinersComponent">
                <axis360-search-refiners (searchRefinerEvent)="titleRefiner($event)" [refiners]="titleRefiners"
                    [availableLimiters]="availableLimiters" (applyFilterEvent)="applyFilter($event)"
                    [activityRefiner]="activityRefiner" [showAccordionThirdParty]="refinersHidden"
                    (actvityRefinerEvent)="learningActivityRefiner($event)" (searchFilterEvent)="search($event)"
                    (clearFilterEmitter)="clearAllRefiner()" [radioClass]="'kz-search-radio'">
                </axis360-search-refiners>
            </div>
            <div class="col-lg-4 col-xs-12 sticky-refiner" *ngIf="refinersComponent">
                <axis360-third-party-refiners [vendorCodeInput]="vendorCode" [showCategories]="true"
                    [component]="refinersComponent">
                </axis360-third-party-refiners>
            </div>
        </div>
        <div class="search-result-info" id="searchResultContainer" tabindex="-1">
            <div>
                <p class="kz-visually-hidden no-result-title heading-1"
                    *ngIf="(searchService.totalCount === 0 || !searchService.showThiredPartyRefiner)">
                    <span  id="searchResultsList" class="kz-visually-hidden" role="alert">
                        Search Results Page Loading</span>
                </p>
                <div
                    *ngIf="(searchService.showEbookRefiner || searchService.showEaudioRefiner) && studyTitlesResult && studyTitlesResult.length > 0">
                    <ng-container *ngFor="let title of studyTitlesResult">
                        <div class="carousel-title-group">
                            <div class="title-sort">
                                <div role="presentation">
                                    <h2 id="searchCarouselTitle" class="carousel-title heading-2">
                                        <span class="kz-visually-hidden" role="alert" aria-live="assertive" id="searchCarouselTitleCount">{{(title.Filter.Format ===
                                            audioFormat ? eAudio :
                                            eBook)}} count {{title.TotalItems |thousandSuff}}</span>
                                        <span aria-hidden="true" tabindex="-1"> {{(title.Filter.Format === audioFormat ?
                                            eAudio : eBook)}}</span>
                                        <span aria-hidden="true" tabindex="-1">({{title.TotalItems
                                            |thousandSuff}})</span>
                                    </h2>
                                </div>
                                <div class="search-view-container" (window:resize)="closeFilter()"
                                    *ngIf="!refinersComponent">
                                    <button class="filter btn-nobg-bluecolor" (click)="openFilter()">
                                        <mat-icon svgIcon="kz-filter-icon"
                                            attr.aria-label="filter menu count of {{appliedFilterCount>9?'9+':appliedFilterCount}}"
                                            aria-hidden="false"></mat-icon>
                                        <span class="count d-flex"
                                            aria-hidden="true">{{appliedFilterCount>9?'9+':appliedFilterCount}}</span>
                                    </button>
                                </div>
                                <div *ngIf="separationOfSortFromRefiners">
                                    <button class="custom-menu-trigger removeSortLayout" id="loc_btnSort"
                                        mat-icon-button axis360MatMenuFocus [matMenuTriggerFor]="menuTwo"
                                        (menuOpened)="menuState(true)" (menuClosed)="menuState(false)"
                                        aria-label="Sort">
                                        <mat-icon svgIcon="kz-strawberry" aria-hidden="true"></mat-icon>
                                    </button>
                                    <mat-menu #menuTwo="matMenu" class="kz-action-menu-item dropdown-sub-menu">
                                        <button mat-menu-item *ngFor="let sort of sortRefiner[0]?.items"
                                            (click)="sortChange(sort,'titleRefine')" class="anchor-link"
                                            id="loc_{{sort.text}}"
                                            [ngClass]="{'active': sort.text === sortRefiner[0].selectedText}">{{sort.text}}</button>
                                    </mat-menu>
                                </div>
                            </div>
                            <div>
                                <axis360-navigation-tabs class="nav-library" [centerTab]="centerTab"
                                    [multiTabsList]="selectedTitleRefiner" [isWebResources]="true"
                                    (removeSelection)="removeTitleSelection($event)">
                                </axis360-navigation-tabs>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let title of studyTitlesResult">
                        <div *ngIf="title.Items?.length > 0">
                            <div class="search-card-view mt-3" [ngClass]="{'title-card': titleCard}">
                                <ng-container *ngFor="let titleContent of title.Items">
                                    <axis360-title-card [titleContent]="titleContent" [isProgramDetail]="true"
                                        [isParticipant]="true" [searchResult]="true" [parentRoute]="parentRoute"
                                        [isProgramCTAShow]="isProgram"></axis360-title-card>
                                </ng-container>
                            </div>
                        </div>

                        <div class="no-result-text primary-para" *ngIf="!title.Items?.length && !isLoading">
                            <span id="no-result-text" aria-live="assertive" role="alert">
                                No results found, please check your spelling, or try a different search term.
                            </span>
                        </div>
                        <ng-container *ngIf="enablePurchaseRequest && programsDetails !=='programs'">
                            <p class="primary-para mt-3"
                                *ngIf="(!isLoading && !title.Items?.length) && !isTitletoRecommend">
                                Suggest a Purchase –
                                <a href="javascript:void(0);" class="primary-link kz-purchase-link"
                                    (click)="searchPurchaseRequest()">
                                    <span>Search</span></a> titles that you can request to your library for purchase.
                            </p>
                        </ng-container>
                    </ng-container>

                </div>
                <div *ngIf="searchService.showSitesRefiner">
                    <div>
                        <ng-container *ngIf="sitesInfo.length > 0">
                            <div class="carousel-title-group btn-group" role="presentation">
                                <h2 id="carouselTitle" class="carousel-title heading-2" aria-live="assertive">
                                    <span class="kz-visually-hidden" role="alert" id="resultsCountWPE">{{webPathExpressTiltle}} count
                                        {{totalSitesCount|thousandSuff}}</span>
                                    <span aria-hidden="true" tabindex="-1">{{webPathExpressTiltle}}</span> <span
                                        aria-hidden="true" tabindex="-1">({{totalSitesCount|thousandSuff}})</span>
                                </h2>
                                <div class="search-view-container" (window:resize)="closeFilter()"
                                    *ngIf="!refinersComponent">
                                    <button class="filter btn-nobg-bluecolor" (click)="openFilter()">
                                        <mat-icon svgIcon="kz-filter-icon"
                                            attr.aria-label="filter menu count of {{appliedFilterCount>9?'9+':appliedFilterCount}}"
                                            aria-hidden="false"></mat-icon>
                                        <span class="count d-flex"
                                            aria-hidden="true">{{appliedFilterCount>9?'9+':appliedFilterCount}}</span>
                                    </button>
                                </div>
                            </div>
                            <axis360-navigation-tabs class="nav-library" [centerTab]="centerTab"
                                [multiTabsList]="selectedWebResoure" [isWebResources]="true"
                                (removeSelection)="removeWebResourcesSelection($event)">
                            </axis360-navigation-tabs>
                        </ng-container>
                        <div class="search-card-view mt-2" *ngIf="totalSitesCount > 0"
                            [ngClass]="{'title-card resource-hub-card': titleCard}">
                            <ng-container *ngFor="let titleContent of sitesInfo">
                                <axis360-kz-web-resources-card [webResourceCardInfo]="titleContent">
                                </axis360-kz-web-resources-card>
                            </ng-container>

                        </div>
                    </div>
                    <div class="no-result-text primary-para" *ngIf="totalSitesCount < 1 && !spinnerService.isLoading">
                        <span id="no-result-text" aria-live="assertive" role="alert">
                            No results found, please check your spelling, or try a different search term.
                        </span>
                    </div>
                </div>
                <div *ngIf="searchService.showThiredPartyRefiner">
                    <ng-container *ngIf="layoutComponents && layoutComponents?.length > 0 && !isPressrdrLoad">
                        <h1 class="no-result-title heading-1" *ngIf="(searchService.totalCountThirdparty > 0);">
                            <span class="kz-visually-hidden"
                              role="alert" aria-live="assertive" id="searchResultsViewCount">
                                Results count {{searchService.totalCountThirdparty}}</span>
                            <span class="result-text" aria-hidden="true" tabindex="-1">
                                Results
                            </span><span class="result-count" aria-hidden="true" tabindex="-1">({{searchService.totalCountThirdparty
                                |thousandSuff}})</span>
                        </h1>
                        <div *ngIf="layoutComponents && layoutComponents?.length > 0">
                            <div class="web-resource-panel" *ngFor="let layout of layoutComponents; let i =index;">
                                <ng-container *ngFor="let component of layout.components; let i =index;">
                                    <axis360-third-party-carousel-type [componentData]="component"
                                        [vendorCode]="layout.vendorCode" [parentRoute]="parentRoute"
                                        [isShowCount]="true" (count)="emitCount($event)">
                                    </axis360-third-party-carousel-type>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="layoutComponent">
                        <div>
                            <div class="web-resource-panel">
                                <ng-container>
                                    <div class="col-lg-8 col-xs-12" [ngClass]="{'no-refiners': !refinersComponent}">
                                        <axis360-third-party-cards-grid [title]="" [isSearchPage]="true"
                                            [isSearchPageTier2]="false" [vendorCode]="vendorCode"
                                            [thirdPartyClass]="thirdPartyBaseGridClass" [filtersCount]="filtersCount"
                                            [parentRoute]="thirdPartyParentRount"
                                            (openFilterEvent)="thirdPartyService.openRefiner.next(true)"
                                            [component]="layoutComponent">
                                        </axis360-third-party-cards-grid>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div class="no-result-text primary-para"
                        *ngIf="!(thirdPartyCountvalue>0) && !isLoading && !spinnerService.isLoading && !isThirdPartyLoading">
                        <span id="no-result-text" aria-live="assertive" role="alert">
                            No results found, please check your spelling, or try a different search term.
                        </span>
                    </div>
                </div>
                <div class="no-result-text primary-para"
                    *ngIf="!isLoading && !spinnerService.isLoading && searchService.drupalServiceDownErrorMsg !== ''">
                    {{searchService.drupalServiceDownErrorMsg}}</div>
                <div *ngIf="searchService.showLeaningActivityRefiner">
                    <div>
                        <ng-container>
                            <div class="carousel-title-group">
                                <div class="title-sort">
                                    <h2 id="carouselTitle" class="carousel-title heading-2" aria-live="assertive">
                                    <span *ngIf="(learningActivityResult && learningActivityResult?.totalCount >= 0)" class="kz-visually-hidden" role="alert" id="resultsCountReourceHub">
                                        Resource Hub Count {{learningActivityResult?.totalCount | thousandSuff}}
                                    </span>
                                        <span aria-hidden="true">Resource Hub </span><span aria-hidden="true"
                                            class="resource-count"
                                            *ngIf="learningActivityResult && learningActivityResult?.totalCount >= 0">({{learningActivityResult?.totalCount
                                            | thousandSuff}})</span>
                                    </h2>
                                    <div class="search-view-container" (window:resize)="closeFilter()"
                                        *ngIf="!refinersComponent">
                                        <button class="filter btn-nobg-bluecolor" (click)="openFilter()">
                                            <mat-icon svgIcon="kz-filter-icon"
                                                attr.aria-label="filter menu count of {{appliedFilterCount>9?'9+':appliedFilterCount}}"
                                                aria-hidden="false"></mat-icon>
                                            <span class="count d-flex"
                                                aria-hidden="true">{{appliedFilterCount>9?'9+':appliedFilterCount}}</span>
                                        </button>
                                    </div>
                                    <div *ngIf="separationOfSortFromRefiners">
                                        <button class="custom-menu-trigger removeSortLayout" id="loc_btnSort"
                                            mat-icon-button axis360MatMenuFocus [matMenuTriggerFor]="menuTwo"
                                            (menuOpened)="menuState(true)" (menuClosed)="menuState(false)"
                                            aria-label="Sort">
                                            <mat-icon svgIcon="kz-strawberry" aria-hidden="true"></mat-icon>
                                        </button>
                                        <mat-menu #menuTwo="matMenu" class="kz-action-menu-item dropdown-sub-menu">
                                            <button mat-menu-item *ngFor="let sort of activitySortRefiner[0]?.filters"
                                                (click)="sortChange(sort,'learningActivity')" class="anchor-link"
                                                id="loc_{{sort.text}}"
                                                [ngClass]="{'active': sort.text === activitySortRefiner[0].selectedFilter}">{{sort.text}}</button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="learningActivityResult && learningActivityResult?.totalCount >= 0">
                                <axis360-navigation-tabs class="nav-library" [centerTab]="centerTab"
                                    [multiTabsList]="learningActivityPills" [isLearningActivity]="true"
                                    (removeSelection)="removeLearningActivitySelection($event)">
                                </axis360-navigation-tabs>
                            </ng-container>
                        </ng-container>
                        <div *ngIf="learningActivityResult && learningActivityResult?.totalCount > 0">
                            <div class="search-card-view mt-2" [ngClass]="{'title-card resource-hub-card': titleCard}">
                                <ng-container *ngFor="let titleCard of  learningActivityResult?.data">
                                    <axis360-kz-activity-resources-card [activity]="titleCard"
                                        [parentRoute]="parentRoute">
                                    </axis360-kz-activity-resources-card>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="no-result-text primary-para"
                        *ngIf="(!learningActivityResult && !spinnerService.isLoading) || learningActivityResult?.data?.length === 0">
                        <span id="no-result-text" aria-live="assertive" role="alert">
                            No results found, please check your spelling, or try a different search term.
                        </span>
                    </div>
                </div>
            </div>
            <axis360-spinner-loader> </axis360-spinner-loader>
        </div>
    </div>
</div>