import { Component, Input } from '@angular/core';
import { IActivityCategory } from '@shared/data/learning-activity.model';
import { LibCompononent } from '../shared/data/library.model';
import { MyLibraryAnalyticsService } from '../shared/services/my-library.analytics.service';
import { Router } from '@angular/router';
@Component({
  selector: 'axis360-learning-activities',
  templateUrl: './learning-activities.component.html',
  styleUrls: ['./learning-activities.component.scss']
})
export class LearningActivitiesComponent {
  @Input() componentData: LibCompononent;
  @Input() learningActivityCategories: IActivityCategory[];

  constructor(public myLibraryAnalyticsService: MyLibraryAnalyticsService, public router: Router) { }

  ngOnInit() {
    const cellsDivs = document.querySelectorAll<HTMLDivElement>('.carousel-cells');
    cellsDivs.forEach(cellsDiv => {
      const parentDiv = cellsDiv.closest('.kz-carousel.learnings-carousel');
      if (parentDiv) {
        cellsDiv.setAttribute('role', 'list');
      }
    });
  }
  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.myLibraryAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.myLibraryAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
    }
  }
  ngAfterViewInit() {

    const carousel = document.querySelector('.kz-carousel.learnings-carousel .carousel-arrows');
    if (carousel) {
      const anchor = document.createElement('a');
      anchor.className = 'link see-all-text'
      anchor.innerText = 'See All';
      anchor.href = 'javascript:;';
      anchor.setAttribute('aria-label', `See all ${this.componentData.title}`);
      anchor.addEventListener('click', () => {
        this.router.navigate(['/view', 'learning-activity'], { queryParams: { category: 'All' } });
      })
      carousel.insertBefore(anchor, carousel.children[1]);
    }
    const carouselList = document.querySelector('.carousel-cells');
    carouselList.setAttribute('role', 'list');
  }

}
