import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { focusElement, forceRedirect } from '@shared/helper/app.util';
import { AccountService } from '@shared/services/account.service';
import { CommonService } from '@shared/services/common.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { RATINGS, RATING, REVIEWS, REVIEW } from '@titledetails/shared/constants/title-details.constants';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { AccountComponent } from 'app/core/account/account.component';
import { ITitleDetailsRating } from 'app/core/title-details/shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from 'app/core/title-details/shared/services/title-details.analytics.service';
@Component({
  selector: 'axis360-title-details-more',
  templateUrl: './title-details-more.component.html',
  styleUrls: ['./title-details-more.component.scss']
})
export class TitleDetailsMoreComponent implements OnInit, OnDestroy {
  @Input() titleDetailsRating: ITitleDetailsRating;
  @Input() removeBooksInfo = false;
  @Input() removeRating = false;
  stars = [];
  fixRatingStart = 5;
  starStrokeCss = '';
  starFillCss = '';
  avgStarWidth: string;
  RATINGS: string = RATINGS;
  RATING: string = RATING;
  REVIEWS: string = REVIEWS;
  REVIEW: string = REVIEW;
  forceRedirect = forceRedirect;
  ratingStars: number | string = 0;
  fouceReviewCTA: string;
  isToastAriaLiveStatus: string;
  isUserLogin: boolean;
  @Output() titleRatingEvent = new EventEmitter();
  @Output() firstTitleReview = new EventEmitter();
  @Output() scrollTitleReview = new EventEmitter();
  ariaLabelFiveStartRating: string;
  constructor(
    public accountService: AccountService,
    public spinnerService: SpinnerService,
    public commonService: CommonService,
    public snackBarService: SnackBarService,
    public matDialog: MatDialog,
    public profileInfoService: ProfileInfoService,
    public userService: UserService,
    public overlayContainer: OverlayContainer,
    private titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public titleDetailsService: TitleDetailsService
  ) { }

  /**
   * To init Changes TitleDetailsMoreComponent
   */
  ngOnInit(): void {
    const { profileType } = this.profileInfoService.currentProfile || {};
    this.isUserLogin = this.userService.userInfo.isLoggedIn;
    this.titleDetailsService.getRatingsSubject().subscribe(list => {
      if (list) {
        this.titleDetailsRating = list;
        this.ratingStars = list.avgStarRating;
        this.getStarRating(list.avgStarRating);
      }
    });
    const {
      itemId,
      title,
      languageDesc,
      publicationDate,
      ageRange,
      avgStarRating,
      length,
      formatType,
      formatTypeName,
      axisAttribute,
      axisAttributeList,
      userAvgStarRating,
      axisAttributeIndex,
      patronReviewsEnabled,
      subjectName,
      bookLength,
      attribute,
      runTime
    } = this.titleDetailsRating || {};
    this.titleDetailsService.getUserRatingTitle().subscribe((res) => {
      if (res.submitted) {
        this.stars = [];
        this.starFillCss = res.profileType.toLocaleLowerCase();
        this.starStrokeCss = res.profileType.toLocaleLowerCase() + '-stroke';
        this.titleDetailsRating = {
          itemId,
          title,
          languageDesc,
          publicationDate,
          ageRange,
          length,
          formatType,
          formatTypeName,
          reviewsCount: res.reviewLength,
          avgStarRating: res.avgStarRating,
          userAvgStarRating,
          axisAttribute,
          axisAttributeList,
          axisAttributeIndex,
          patronReviewsEnabled,
          subjectName,
          bookLength,
          attribute,
          runTime,
          otherUserReviewCount: res.otherUserReviewCount,
        };
        this.ratingStars = res.userAvgStartRating;
        this.titleDetailsService.titleDetailsRating.next('rating');
        this.getStarRating(res.userAvgStartRating);
      } else {
        this.ratingStars = avgStarRating;
        if (userAvgStarRating && userAvgStarRating > 0 && userAvgStarRating <= 5) {
          this.starFillCss = profileType.toLocaleLowerCase();
          this.starStrokeCss = profileType.toLocaleLowerCase() + '-stroke';
        }
        this.getStarRating(avgStarRating);
      }
    });
    this.isToastAriaLiveStatus = "";
    if (/Mac|Android|iPhone|iPod|iPad/i.exec(navigator.userAgent)) {
      this.isToastAriaLiveStatus = "assertive";
    }
  }

  firstTimeReviewTitle(count) {
    const userAgentRegex = /(Mac|Android|iPhone|iPod|iPad)/i;
    const match = userAgentRegex.exec(navigator.userAgent);
    match && focusElement('alert-heading');
    if (count > 0) {
      this.scrollTitleReview.emit(count);
      return;
    }
    this.firstTitleReview.emit(count);
  }

  /**
   * Calculate the avgStarRating width
   * @param [avgStarRating] pass avgStarRating value
   */
  getStarRating(avgStarRating: number) {
    if (!avgStarRating) {
      this.avgStarWidth = '0%';
      return;
    }
    this.avgStarWidth = (parseFloat(avgStarRating.toFixed(1)) / 5) * 100 + '%';
  }

  /**
   *  Track by Title details Actions in href values
   * @param action  Selected action to pass
   * @param value   set the value in pass the action
   */
  trackBytrackRatingActions(action: any) {
    this.titleDetailsAnalyticsService.trackRatingEvent(action);
  }

  rateTitle(rating?: number) {
    this.titleDetailsService.titleDetailsRating.next('');
    const review = document.getElementById('star-rating-update');
    review && review.setAttribute('aria-hidden', 'true');
    if (rating === 0 && !this.userService.userInfo.isLoggedIn) {
      this.openLoginModel();
      return;
    }
    this.ratingStars = rating.toString();
    setTimeout(() => {
      const ratingStarId = document.getElementById('star-rating-update');
      ratingStarId && ratingStarId?.setAttribute('aria-live', 'assertive');
    }, 1000);
    setTimeout(() => {
      this.titleDetailsAnalyticsService.trackRatingEvent('CLOSE_RATING');
      setTimeout(() => {
        this.titleRatingEvent.emit(rating.toString());
      }, 2000)
    }, 1000);
  }

  openLoginModel() {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          this.forceRedirect(response.redirectUrl);
          return;
        }
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true
            }
          })
          .afterClosed()
          .subscribe((res: boolean) => {
            if (res) {
              this.titleDetailsAnalyticsService.trackRatingEvent('CLOSE_RATING');
            }
          });
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }
  menuState(state: boolean) {
    this.ariaLabelFiveStartRating = 'Five stars';
    const userAgent = navigator.userAgent;
    if (state) {
      setTimeout(() => {
        const ratingMenuButton: HTMLElement = document.getElementById('ratingMenuButton');
        ratingMenuButton && ratingMenuButton.removeAttribute('aria-expanded')
      }, 0)
      this.starRating('false');
      if ((/android|iPad|iPhone|iPod|Safari|CriOS|FxiOS/i.test(userAgent)) !== null) {
        this.ariaLabelFiveStartRating =
          'How many stars would you like to rate "' +
          (this.titleDetailsRating.title || this.titleDetailsRating.bookTitle) +
          '"? Five stars';
        setTimeout(() => {
          const fiveStart: HTMLElement = document.getElementById('fivestartrating');
          fiveStart && fiveStart.setAttribute('aria-label', 'Five stars');
        }, 2000)
      }
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    setTimeout(() => {
      document.getElementById('ratingMenuButton').setAttribute('aria-expanded', 'false');
    }, 1000);
  }

  @HostListener('document:keydown.escape') onKeyDown() {
   this.starRating('true')
  }

  ngOnDestroy(): void {
    this.titleDetailsService.setUserRatingTitle({});
  }
  starRating(option) {
    const fiveStars = document.getElementsByClassName('star-card');
    const optionsArr = Array.from(fiveStars)
    optionsArr.forEach(element => {
      element.setAttribute('aria-hidden', option);
    });
  }
}
