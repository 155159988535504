import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PROFILES_API_INFO } from '@profile/shared/constants/profiles.api.constants';
import { APIResponse, ProfileDetail } from '@profile/shared/data/profiles.model';
import { API_INFO } from '@shared/constants/api.constant';
import { Features } from '@shared/data/config.model';
import { MyStuffsModel, ProfileInfo } from '@shared/data/profile-info.model';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { UserService } from './user.service';
import { MyStuffsPillsDetails } from '@shared/data/shared.model';
export type IProfileInfo = Partial<ProfileInfo & Features>;
@Injectable({
  providedIn: 'root'
})
export class ProfileInfoService {
  changesSaved = false;
  profiles: ProfileDetail[] = [];
  canAddProfile = false;
  isEditProfile = false;
  profilePinSuccess = false;
  profileInfo: IProfileInfo = { profileType: '' };
  selectedEditProfileType = '';
  public myStuffCounts = {};
  public myStuffDetails: MyStuffsPillsDetails;
  getProfileInfo = new Subject<any>();
  isFromForgotPin = false;
  constructor(private http: HttpClient, private configService: ConfigService, public userService: UserService) { }

  /**
   * Getter - Current Profile info
   */
  get currentProfile() {
    return this.profileInfo;
  }
  /**
   * Setter - Current Profile info
   */
  set currentProfile(profileInfo: IProfileInfo) {
    this.profileInfo = { ...profileInfo, ...this.configService.getProfileFeatures(profileInfo.profileType?.toLowerCase()) };
    this.getProfileInfo.next(this.profileInfo);
  }
  /**
   * To get current profile
   * @returns current profile info
   */
  async getCurrentProfile(): Promise<Partial<ProfileInfo>> {
    if (!this.profileInfo.profileId) {
      return;
    }
    try {
      this.currentProfile = await this.http.get<ProfileInfo>(API_INFO.getCurrentProfile).toPromise();
      return this.currentProfile;
    } catch (e) {
      return this.currentProfile;
    }
  }
  /**
   * Updates profile
   */
  updateProfile(profileId: string, profileData: ProfileDetail): Observable<APIResponse> {
    const url = `${PROFILES_API_INFO.updateProfile}/updateprofile`;
    return this.http.put<APIResponse>(url.replace('<profileId>', profileId), profileData);
  }
  getMyStuffCounts(myStuffs: MyStuffsModel[]) {
    if (!this.userService.isLoggedIn()) {
      return;
    }
    const myStuffCounts = {};
    myStuffs.forEach((x) => (myStuffCounts[x.name?.toLowerCase()] = Number(x.value)));
    this.myStuffCounts = myStuffCounts;
    if (this.configService.currentLibrary.isPPCOnlyLibrary) {
      const myStuffList = myStuffs.filter((item) => item.text !== 'Holds');
      this.myStuffDetails = { pillsDetails: myStuffList, updated: false };
    } else {
      this.myStuffDetails = { pillsDetails: myStuffs, updated: false };
    }
    if (this.profileInfo.displayCheckoutHistory === 'False') {
      const myStuffList = this.myStuffDetails.pillsDetails.filter((item) => item.text !== 'History');
      this.myStuffDetails = { pillsDetails: myStuffList, updated: false };
      return;
    }
    if (!this.myStuffDetails) {
      this.myStuffDetails = { pillsDetails: myStuffs, updated: false };
    }
    this.myStuffDetails.pillsDetails.forEach((detail) => {
      detail.text.includes('History') && (detail.text = 'Checkout History');
      detail.value = detail.value !== '' ? detail.value : '0';
      return detail;
    });

  }
}
