export const ACCOUNT_SCREENS_KEY = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  FORGOT_PIN: 'FORGOT_PIN',
  LOGIN_ERROR: 'LOGIN_ERROR'
};

export const ACCOUNT_SCREENS: { [key: string]: string } = {
  [ACCOUNT_SCREENS_KEY.LOGIN]: 'Sign in Page',
  [ACCOUNT_SCREENS_KEY.REGISTER]: 'Register Page',
  [ACCOUNT_SCREENS_KEY.FORGOT_PIN]: ' Forgot pin page',
  [ACCOUNT_SCREENS_KEY.LOGIN_ERROR]: 'Error page'
};

export const ACCOUNT_CATEGORY = {
  ACTIONS: 'Actions'
};
export const ACCOUNT_EVENTS_KEY = {
  TYPE_LC_ID: 'TYPE_LC_ID',
  SELECT_SECURITY_QUESTION: 'SELECT_SECURITY_QUESTION',
  TYPE_ANSWER: 'TYPE_ANSWER',
  REGISTER: 'REGISTER',
  TYPE_PIN: 'TYPE_PIN',
  REMEMBER_CARD_ID: 'REMEMBER_CARD_ID',
  FORGOT_PIN: 'FORGOT_PIN',
  CLOSE_BUTTON: 'CLOSE_BUTTON',
  ENTER_ANSWER: 'ENTER_ANSWER',
  SUBMIT: 'SUBMIT',
  TYPE_PASSWORD: 'TYPE_PASSWORD',
  SIGIN_CTA: 'SIGIN_CTA',
  NEW_USER: 'NEW_USER',
  TYPE_USERNAME: 'TYPE_USERNAME'
};
export const ACCOUNT_EVENTS: { [key: string]: string } = {
  [ACCOUNT_EVENTS_KEY.TYPE_LC_ID]: 'Type Library card id',
  [ACCOUNT_EVENTS_KEY.TYPE_USERNAME]: 'Type User name',
  [ACCOUNT_EVENTS_KEY.SELECT_SECURITY_QUESTION]: 'Select security question',
  [ACCOUNT_EVENTS_KEY.TYPE_ANSWER]: 'Type answer',
  [ACCOUNT_EVENTS_KEY.REGISTER]: 'Clicks register',
  [ACCOUNT_EVENTS_KEY.TYPE_PIN]: 'Type Pin',
  [ACCOUNT_EVENTS_KEY.REMEMBER_CARD_ID]: 'Views Remember my card id',
  [ACCOUNT_EVENTS_KEY.FORGOT_PIN]: 'Views Forgot pin',
  [ACCOUNT_EVENTS_KEY.CLOSE_BUTTON]: 'Clicks close button',
  [ACCOUNT_EVENTS_KEY.SUBMIT]: 'Clicks Submit CTA',
  [ACCOUNT_EVENTS_KEY.ENTER_ANSWER]: 'Enter Answer',
  [ACCOUNT_EVENTS_KEY.SIGIN_CTA]: 'Selects Sign in CTA',
  [ACCOUNT_EVENTS_KEY.TYPE_PASSWORD]: 'Type Password',
  [ACCOUNT_EVENTS_KEY.NEW_USER]: 'Views New user? click here'
};
