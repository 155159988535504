import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { DEFAULT_SNAKE_BAR_CONFIG, DEFAULT_SNAKE_BAR_MSG, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { SnakeBarConfig } from '@shared/data/snake-bar-model';

@Injectable()
export class SnackBarService {
  constructor(public snackBar: MatSnackBar, private zone: NgZone) {}

  public showSnackBar(mode = SNAKE_BAR_MODE.ERROR, message = DEFAULT_SNAKE_BAR_MSG, data: SnakeBarConfig = DEFAULT_SNAKE_BAR_CONFIG) {
    const snakeBarData = { ...DEFAULT_SNAKE_BAR_CONFIG, ...data };
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = snakeBarData.buttonAction === '' ? snakeBarData.duration : 0;
    config.panelClass = snakeBarData.panelClass;
    config.politeness = snakeBarData.politeness as AriaLivePoliteness;
    config.data = { mode, message, snakeBarData };
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackBarComponent, config);
    });
  }

  public dismissSnackBar() {
    this.snackBar.dismiss();
  }
}
