<axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>
<div class="kz-reading-programs-details" [hidden]="isLoading" *ngIf="!seeAll">
    <p class="kz-visually-hidden" role="alert" aria-live="assertive" id="isProgramDetailPage">Program Details page</p>
    <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'">
    </axis360-breadcrumb>
    <div class="kz-program-title">
        <div class="program-title">
            <h1 class="kz-reading-programs-title" id="loc_programTitle{{programDetails.name}}" axis360EllipsisTitle>
                {{programDetails.name}}
            </h1>
        </div>
        <div class="btn-sec">
            <button class="btn-primary" id="loc_btnLeaveProgram{{programDetails.name}}"
                *ngIf="(programDetails.dbStatus==='Active') && (programDetails.isParticipant)"
                (click)="openKZLeaveDialog(programDetails.name);$event.stopPropagation();"
                (window:keypress.enter)="openKZLeaveDialog(programDetails.name);$event.stopPropagation();"
                >Leave Program</button>
            <button class="btn-primary" id="loc_btnJoinProgram{{programDetails.name}}" *ngIf="!programDetails.isParticipant"
                (click)="joinProgram(programDetails)">Join
                Program</button>
        </div>
    </div>
    <div (window:resize)="getResizedDescription()">
        <div class="kz-reading-programs-description three-ellipsis" id="loc_ProgramDescription" axis360EllipsisTitle
            *ngIf="!showDescription && programDetails.resizedDescription"
            [innerHtml]="replaceNewLine(programDetails.resizedDescription)">
        </div>
        <button class="kz-see-more" id="more"
            *ngIf="!showDescription && programDetails.description?.length > 393"
            (keydown.enter)="toggleshowDescription()" (click)="toggleshowDescription()">View
            More <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></button>
        <div class="kz-reading-programs-description pro-desc-new-line" id="loc_ProgramDescription{{programDetails.description}}"
            *ngIf="showDescription && programDetails.description"
            [innerHtml]="replaceNewLine(programDetails.description)">
        </div>
        <button class="kz-see-more" id="less" *ngIf="showDescription"
            (keydown.enter)="toggleshowDescription()" (click)="toggleshowDescription()">View
            Less <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></button>
    </div>
    <div class="kz-programs-date-sec">
        <div class="display-date" *ngIf="programDetails.type === 'Milestone'">
            <p class="display-prog-head">GOAL</p>
            <p id="loc_txt_goal">{{programDetails.goal}}</p>
        </div>
        <div class="display-date">
            <p class="display-prog-head">START DATE</p>
            <p id="loc_txt_startDate">{{programDetails.startDate | date :'MMM dd, yyyy'}}</p>
        </div>
        <div class="display-date" *ngIf="programDetails.endDate">
            <p class="display-prog-head">END DATE</p>
            <p id="loc_txt_endDate">{{programDetails.endDate | date :'MMM dd, yyyy'}}</p>
        </div>
        <div class="display-date" *ngIf="programDetails.type === 'Milestone' && programDetails?.isParticipant">
            <p class="display-prog-head">JOIN DATE</p>
            <p id="loc_txt_joinDate">{{programDetails.joinDate | date :'MMM dd, yyyy'}}</p>
        </div>
        <div class="display-date" *ngIf="programDetails.typeText">
            <p class="display-prog-head">{{programDetails.type === 'Milestone'?'COMPLETED':'READ'}}</p>
            <p id="loc_txt_{{programDetails.type === 'Milestone'?'COMPLETED':'READ'}}">{{(programDetails.type === 'XOfYOfBooks') ? programDetails.typeText.replace('Books','Titles') :
                programDetails.typeText}}</p>
        </div>
        <div class="display-date" *ngIf = "programDetails.type !== 'Milestone'">
            <p class="display-prog-head">{{programDetails.totalParticipants <= 1?'Participant':'Participants'}}</p>
                    <p id="loc_txt_totalParticipants">{{programDetails.totalParticipants}}</p>
        </div>
    </div>
    <div class="btn-sec btn-sec-mob mt-2">
        <button class="btn-primary" id="loc_btnLeaveProgram"
            *ngIf="(programDetails.dbStatus==='Active') && (programDetails.isParticipant)"
            (click)="openKZLeaveDialog(programDetails.name)"
            (keyup.enter)="openKZLeaveDialog(programDetails.name)">Leave Program</button>
        <button class="btn-primary" id="loc_btnJoinProgram" *ngIf="!programDetails.isParticipant"
            (click)="joinProgram(programDetails)">Join
            Program</button>
    </div>
    <div class="kz-reading-programs-list"
        *ngIf="programDetails.type !== 'Milestone' && programDetails.books && programDetails.books.length && programDetails.bookLoaded">
        <div class="kz-carousel-heading-sec">
            <h2 class="kz-carousel-heading" id="loc_txtProgramTitle"><span class="kz-visually-hidden">Program Items {{programDetails.books.length}}</span>
              <span aria-hidden="true">Program Items </span>
                <span aria-hidden="true" class="book-count">({{programDetails.books.length}})</span>
            </h2>
        </div>
        <ng-container>
            <div class="card-list" role="list">
                <div role="listitem" class="carousel-cell" *ngFor="let bookDetail of programDetails.books; index as i">
                    <axis360-kz-programs-title [bookDetail]="bookDetail" [programDetails]="programDetails"
                        [isProgramDetail]="true" [isParticipant]="programDetails.isParticipant"
                        (titleMarkAsReadEvent)="markAsReadClick($event)">
                    </axis360-kz-programs-title>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="kz-reading-programs-list" *ngIf="programDetails.type == 'Milestone'">
        <div class="kz-carousel-heading-sec">
            <h2 class="kz-carousel-heading" id="loc_txtMilestoneProgramTitle"><span class="kz-visually-hidden">Program Items {{programDetails.books?.length > 0 ?
                programDetails.books.length -1 : ''}}</span> <span aria-hidden="true">Program Items</span>
                <span aria-hidden="true">({{programDetails.books?.length > 0 ? programDetails.books.length -1 : ''}})</span></h2>
            <a class="see-all see-all-text" href="javascript:;" *ngIf="programDetails?.books?.length >= 2"
                (click)="seeAll = true;programsAnalyticsService.trackNotificationsEvent('SEE_ALL');seeAllEnabled()"
                id="loc_btnMilestoneSeeAll" aria-label="See All Program items">See All <mat-icon svgIcon="kz-right-arrow"
                    class="kz-right-arrow"></mat-icon></a>
        </div>
        <ng-container>
            <axis360-kz-program-card-carousel [programDetails]="programDetails"
                (markAsReadClickEvent)="markAsReadClick($event)" [parentRoute]="parentRoute">
            </axis360-kz-program-card-carousel>
        </ng-container>

        <div class="kz-title-carousel kz-program-card-carousel mt-2" *ngIf="filteredSuggestedBooks && filteredSuggestedBooks.length > 0">
            <div class="kz-carousel-heading-sec">
              <h2 class="kz-carousel-heading" id="loc_SuggestedTitles">Suggested Content</h2>
            </div>
            <div class="kz-carousel" *ngIf="programDetails.type === 'Milestone'">
                <div class="kz-card-wrapper">
                    <div class="suggested-wrapper">
                        <axis360-kz-program-card-carousel [programDetails]="programDetails"
                            [suggestedBooks]="filteredSuggestedBooks" [parentRoute]="parentRoute"
                            (markAsReadClickEvent)="markAsReadClick($event)">
                        </axis360-kz-program-card-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<axis360-kz-program-view-all *ngIf="seeAll" (titleMarkAsReadEvent)="markAsReadClick($event)" [programDetails]="programDetails" (updateSeeAll)="onUpdateSeeAll()">
</axis360-kz-program-view-all>
