<div class="action-wrapper">
    <ng-container *ngIf="titleButtonViews">
        <ng-container *ngFor="let action of titleButtonViews?.actions">
            <ng-container [ngSwitch]="action.actionButtonId">
              <ng-container *ngIf="showWishlist">
                <div *ngSwitchCase="'btnAddWishlist'">
                    <button mat-icon-button class="btn-nobg-bluecolor icon-button-with-txt wishlist-btn"
                        aria-describedby="wishlist-add" id="loc_wishlist_add"
                        (click)="$event.stopPropagation();$event.preventDefault();addToWishlist($event, ACTION_BUTTON_DATA.ADD_WISHLIST, action)"
                        (keydown.enter)="$event.stopPropagation();$event.preventDefault();addToWishlist($event, ACTION_BUTTON_DATA.ADD_WISHLIST, action)">
                        <mat-icon svgIcon="kz-favorite">
                        </mat-icon>
                        <small class="icon-text">Wishlist</small>
                    </button>

                    <span aria-hidden="true" *ngIf="action.actionButtonId === 'btnAddWishlist'"
                        class="kz-visually-hidden" id="wishlist-add">Add the title to wishlist</span>

                </div>
              </ng-container>
              <ng-container *ngIf="showWishlist">
                <div *ngSwitchCase="'btnRemoveWishlist'">
                    <button mat-icon-button class="btn-nobg-bluecolor icon-button-with-txt wishlist-btn"
                        aria-describedby="wishlist-remove" id="loc_wishlist_remove"
                        (click)="$event.stopPropagation();$event.preventDefault();addToWishlist($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, action)"
                        (keydown.enter)="$event.stopPropagation();$event.preventDefault();addToWishlist($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, action)">
                        <mat-icon svgIcon="kz-favorite-fill">
                        </mat-icon>
                        <small class="icon-text">Wishlist</small>
                    </button>

                    <span aria-hidden="true" *ngIf="action.actionButtonId === 'btnRemoveWishlist'"
                        class="kz-visually-hidden" id="wishlist-remove">Remove the title from wishlist</span>
                </div>
              </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="shareActionsButton">
        <div>
            <button mat-icon-button axis360MatMenuFocus [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" id="share_btn" mat-icon-button
                class="btn-nobg-bluecolor icon-button-with-txt share-btn"
                (click)="trackByTitleDetailsActions('ACTIONS_SHARE');removeAnnounce()" (menuOpened)="menuState(true)"
                (keydown.enter)="trackByTitleDetailsActions('ACTIONS_SHARE');removeAnnounce()"
                (menuClosed)="menuState(false)">
                <mat-icon svgIcon="kz-share"></mat-icon>
                <small class="icon-text">Share</small>
            </button>
            <mat-menu #menu="matMenu" class="shareDropmenu">
                <div class="action-buttons-sec">
                    <button mat-menu-item (click)="shareTitleSocialMedia('copyLink');menuCloseEvent($event)"
                        (keydown.enter)="shareTitleSocialMedia('copyLink');menuCloseEvent($event)">
                        <mat-icon svgIcon="kz-copy-icon"></mat-icon>
                        <span>Copy Link</span>
                    </button>
                    <button mat-menu-item (click)="shareTitleSocialMedia('email')"
                        (keydown.enter)="shareTitleSocialMedia('email')">
                        <mat-icon svgIcon="kz-titlemail-icon"></mat-icon>
                        <span>Email</span>
                    </button>
                    <button mat-menu-item (click)="shareTitleSocialMedia('facebook')"
                        (keydown.enter)="shareTitleSocialMedia('facebook')">
                        <mat-icon svgIcon="kz-facebook-icon"></mat-icon>
                        <span>Facebook</span>
                    </button>
                    <button mat-menu-item (click)="shareTitleSocialMedia('instagram')"
                        (keydown.enter)="shareTitleSocialMedia('instagram')">
                        <mat-icon svgIcon="kz-instagram-icon"></mat-icon>
                        <span>Instagram</span>
                    </button>
                </div>
            </mat-menu>
        </div>
    </ng-container>
</div>
