export const KZPROGRAM_ALERTS = {
  KZPROGRAM_LEAVE: {
    heading: 'Leave Program',
    content: 'Are you sure you want to leave the program, <programName>?',
    submitBtnText: 'OK',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc'
  },
  KZPROGRAM_MILESTONE_LEAVE: {
    heading: 'Leave Program',
    content: 'By leaving the program, you will lose any added titles and associated data. Are you sure you want to leave the program, <programName>?',
    submitBtnText: 'OK',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
    ariaDescribedBy: 'alert-heading-desc'
  },
  KZPROGRAM_DELETE: {
    heading: 'Remove',
    content: 'Are you sure you want to delete the program, "<programName>"?',
    submitBtnText: 'OK',
    cancelBtnText: 'Cancel',
    type: 'CONFIRMATION',
  },
  KZPROGRAM_JOIN: {
    content: 'Would you like to see your reading/listening progress?',
    submitBtnText: 'YES',
    cancelBtnText: 'NO'
  }
};
