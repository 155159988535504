import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CATEGORY_CAROUSEL,
  PRESS_READER_PAGE_SIZE,
  PRESS_READER_SORT_OPTIONS,
  PUBLICATION_TYPES
} from '@pressreader/shared/constants/press-reader.constansts';
import {
  Category,
  CategoryCard,
  CategoryCarousel,
  CategoryIcon,
  PublicationCard,
  Publications
} from '@pressreader/shared/data/press-reader-model';
import { getPublicationCard } from '@pressreader/shared/helper/press-reader.util';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { PressReaderService } from '@pressreader/shared/services/press-reader.service';
import { Facet } from '@search/shared/data/filter-model';
import { SearchService } from '@search/shared/services/search.service';
import { DEFAULT_SNAKE_BAR_MSG, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { positionFooter, redirectUrl } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'axis360-press-reader-landing',
  templateUrl: './press-reader-landing.component.html',
  styleUrls: ['./press-reader-landing.component.scss']
})
export class PressReaderLandingComponent implements OnInit {
  isFeaturedLoading = true;
  isCategoryLoading = true;
  isNewspaperLoading = true;
  isMagazineLoading = true;
  featuredPublications: PublicationCard[] = [];
  newsPapers: PublicationCard[] = [];
  magazines: PublicationCard[] = [];
  categories: CategoryCard[] = [];
  categoryCarousels: CategoryCarousel[] = [];
  offset = 0;
  PRESS_READER_PAGE_SIZE = PRESS_READER_PAGE_SIZE;
  PUBLICATION_TYPES = PUBLICATION_TYPES;
  CATEGORY_CAROUSEL = CATEGORY_CAROUSEL;
  defaultCountryCode: number;
  defaultLanguageCode: number;
  countries: Facet[] = [];
  /**
   * Creates an instance of press reader detail component.
   * @param pressReaderService press reader service
   * @param snackBarService toast service
   * @param spinnerService spinner service
   * @param router router info
   */
  constructor(
    private pressReaderService: PressReaderService,
    private snackBarService: SnackBarService,
    private router: Router,
    private pressReaderAnalyticsService: PressReaderAnalyticsService,
    private searchService: SearchService,
    private configService: ConfigService
  ) {}

  /**
   * To get the intial set of data
   */
  ngOnInit(): void {
    this.pressReaderAnalyticsService.trackScreen('LANDING');
    this.getFilters();
  }

  /**
   * To get the featured publications info
   */
  getFeaturedPublications() {
    this.isFeaturedLoading = true;
    const featuredPublications: Observable<Publications[]> = forkJoin([
      this.pressReaderService.getMagazines(
        0,
        5,
        PRESS_READER_SORT_OPTIONS.RANK.value,
        this.defaultCountryCode.toString(),
        this.defaultLanguageCode.toString()
      ),
      this.pressReaderService.getNewsPaper(
        0,
        5,
        PRESS_READER_SORT_OPTIONS.RANK.value,
        this.defaultCountryCode.toString(),
        this.defaultLanguageCode.toString()
      )
    ]);
    featuredPublications.subscribe(
      (publicationsRes) => {
        let featuredPublicationsCards: PublicationCard[];
        publicationsRes.forEach((publications) => {
          featuredPublicationsCards = (publications.items || []).map((publication) => getPublicationCard(publication, this.countries));
          this.featuredPublications.push(...featuredPublicationsCards);
        });
        this.isFeaturedLoading = false;
        positionFooter(true);
      },
      () => {
        this.isFeaturedLoading = false;
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
        positionFooter(true);
      }
    );
  }

  /**
   * To get publication details
   * @param type type of publication
   */
  getPublications(type: string) {
    const publicationSvcMap = {
      [PUBLICATION_TYPES.MAGAZINES]: this.pressReaderService.getMagazines,
      [PUBLICATION_TYPES.NEWS_PAPERS]: this.pressReaderService.getNewsPaper
    };
    this.isNewspaperLoading = true;
    this.isMagazineLoading = true;
    publicationSvcMap[type](
      0,
      PRESS_READER_PAGE_SIZE,
      PRESS_READER_SORT_OPTIONS.RANK.value,
      this.defaultCountryCode.toString(),
      this.defaultLanguageCode.toString()
    ).subscribe(
      (res) => {
        const { items } = res;
        const publications = (items || []).map((publication) => getPublicationCard(publication, this.countries));
        if (type === PUBLICATION_TYPES.MAGAZINES) {
          this.magazines = publications;
          this.isMagazineLoading = false;
          positionFooter(true);
          return;
        }
        this.newsPapers = publications;
        this.isNewspaperLoading = false;
        positionFooter(true);
      },
      () => {
        if (type === PUBLICATION_TYPES.MAGAZINES) {
          this.isMagazineLoading = false;
        } else {
          this.isNewspaperLoading = false;
        }
        positionFooter(true);
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
      }
    );
  }

  /**
   * To get information from category to show in the page
   * @param category category info
   * @returns required category object
   */
  getCategoryCard(category: Category, categoryIcons: CategoryIcon[]) {
    const { id, name, displayName, slug } = category;
    const iconUrl = categoryIcons.find((categoryIcon) => categoryIcon.categoryName === displayName)?.categoryIconUrl[0] || '';
    return {
      id,
      name,
      displayName,
      slug,
      iconUrl
    };
  }

  /**
   * To get category info
   */
  getCategories() {
    this.isCategoryLoading = true;
    const categories: Observable<any> = forkJoin([this.pressReaderService.getCategories(), this.pressReaderService.getCategoriesIcon()]);
    categories.subscribe(
      (res) => {
        const [categoriesArr] = res[0] || [];
        const { categories: firstCat = [] } = categoriesArr || {};
        const { icons = [] } = res[1];
        firstCat.forEach((category) => {
          this.categories.push(this.getCategoryCard(category, icons));
        });
        this.getCategoryCarousel();
        this.isCategoryLoading = false;
      },
      () => {
        this.isCategoryLoading = false;
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
      }
    );
  }

  /**
   * redirect to the press reader list page
   * @param type type of publication
   * @param cid category id
   */
  redirectToListPage(type: string, cid: string, categoryName = '') {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CAROUSEL_SEE_ALL', { carouselName: type || categoryName });
    redirectUrl(this.router, `/pressreader/list?type=${type}&cid=${cid}&refresh=true`);
  }

  /**
   * To get category carousel info
   */
  getCategoryCarousel() {
    CATEGORY_CAROUSEL.forEach((category) => {
      const catId = this.categories.find((cat) => cat.name === category)?.id;
      if (catId) {
        this.pressReaderService
          .getPublications(
            0,
            PRESS_READER_PAGE_SIZE,
            '',
            this.defaultCountryCode.toString(),
            this.defaultLanguageCode.toString(),
            catId.toString(),
            PRESS_READER_SORT_OPTIONS.RANK.value,
          )
          .subscribe((publications) => {
            const { items } = publications;
            const publicationCards = (items || []).map((publication) => getPublicationCard(publication, this.countries));
            if (publicationCards && publicationCards.length > 0) {
              this.categoryCarousels.push({ id: catId, name: category, items: publicationCards });
            }
          });
      }
    });
  }

  /**
   * Get carousel date based on category
   * @param category category name
   * @returns carousels data
   */
  getCarouselInfoByCategory = (category: string): CategoryCarousel | { items: []; id: string; name: '' } => {
    return this.categoryCarousels.find((categoryCarousel) => categoryCarousel.name === category) || { items: [], id: '', name: '' };
  };

  /**
   * To get initial values for the filters
   */
  async getFilters() {
    this.searchService.locationInfo = await this.searchService.getlocation(this.configService.currentLibrary.id);
    this.searchService.getCountries('').subscribe(
      (contryFilterRes) => {
        this.countries = contryFilterRes.countries;
        const defaultCountry = this.searchService.locationInfo?.countryCode?.toLocaleLowerCase() || this.configService.getPressReaderDefaultCounty();
        const defaultLanguage = this.searchService.locationInfo?.languageCode?.toLocaleLowerCase() || this.configService.getPressReaderDefaultLang();
        const { id: defaultCountryCode } = contryFilterRes.countries.find((country) => country.value === defaultCountry) || {};
        this.defaultCountryCode = defaultCountryCode;
        this.searchService.getLanguages(defaultCountryCode).subscribe((langFilterRes) => {
          const { id: defaultLanguageCode } = langFilterRes.languages.find((lan) => lan.value === defaultLanguage) || {};
          this.defaultLanguageCode = defaultLanguageCode;
          this.getFeaturedPublications();
          this.getPublications(PUBLICATION_TYPES.MAGAZINES);
          this.getPublications(PUBLICATION_TYPES.NEWS_PAPERS);
          this.getCategories();
        });
      },
      () => {
        this.snackBarService.showSnackBar();
        positionFooter(true);
      }
    );
  }

  /**
   * handle category see all click event
   * @param category input parameter as category
   */
  clickCategorySeeAll(category: string) {
    const { id, name } = this.getCarouselInfoByCategory(category);
    this.redirectToListPage('', id.toString(), name);
  }
}
