import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { ThirdPartyLayout } from '@shared/data/third-party-model';
import { ThirdPartyBaseService } from '@shared/services/third-party-base.service';
import { THIRD_PARTY_TYPES, THIRD_PARTY_URLS } from 'app/third-party/shared/constants/third-party.constants';
import { LIB_COMP_ENTITY_TYPES } from '../shared/constants/my-library.constants';
import { LibCompononent } from '../shared/data/library.model';
import { MyLibraryService } from '../shared/services/my-library.service';
import { ConfigService } from '@shared/services/config.service';
import { UserService } from '@shared/services/user.service';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';
import { ProfileInfoService } from '@shared/services/profile-info.service';

@Component({
  selector: 'axis360-third-party-carousel',
  templateUrl: './third-party-carousel.component.html',
  styleUrls: ['./third-party-carousel.component.scss']
})
export class ThirdPartyCarouselComponent implements OnInit {
  @Input() compData: LibCompononent;
  @Input() parentRoute: string;
  @Output() progressiveEmit = new EventEmitter();
  layoutData: Partial<ThirdPartyLayout> = { layout: { components: [] } };
  seeAllLink = THIRD_PARTY_URLS.TIER2;
  THIRD_PARTY_TYPES = THIRD_PARTY_TYPES;
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  isLoading = true;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  isKZGuest: any;
  @Output() removeEmptyThirdPartyEvent = new EventEmitter<{ type: string; count: number; entityId: string }>();
  constructor(
    private thirdPartyBaseService: ThirdPartyBaseService,
    public myLibraryService: MyLibraryService,
    public configService: ConfigService,
    public userService: UserService,
    public profileInfoService: ProfileInfoService
  ) { }

  ngOnInit(): void {
    if (
      this.compData.entityId === 'WIDGETS' &&
      this.compData.entityType === LIB_COMP_ENTITY_TYPES.WIDGETS_BANNERS &&
      this.compData.components.length === 0
    ) {
      this.removeEmptyThirdPartyEvent.emit({ type: this.compData.type, count: 0, entityId: this.compData.entityId });
      this.isLoading = false;
      this.progressiveEmitThirdParty();
      return;
    }
    this.getData();
  }
  async getData() {
    try {
      this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
      this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
      this.isKZGuest = !this.userService.userInfo?.isLoggedIn;
      const isZoneEnable = !this.axis360Enabled && this.kidsZoneEnabled;
      const isProfileType = isZoneEnable ? PROFILE_TYPES.TEEN : PROFILE_TYPES.ADULT;
      const currentProfileFeatures =
        this.configService.getProfileFeatures(
          this.isKZGuest
            ? isProfileType
            : this.profileInfoService.currentProfile.profileType.toLowerCase() || PROFILE_TYPES.ADULT
        ) || {};
      const [layoutData] = await this.thirdPartyBaseService.getThirdPartyLibrary(this.compData.entityId);
      this.layoutData = layoutData;
      const { layout } = layoutData || {};
      const [{ seeAllLinkPage }] = layout.components || [];
      this.seeAllLink = THIRD_PARTY_URLS[seeAllLinkPage];
      this.isLoading = false;
      if (this.compData.entityId === 'WIDGETS' && this.compData.entityType === LIB_COMP_ENTITY_TYPES.WIDGETS_BANNERS) {
        let updateVendorDetails = [];
        this.compData.components.forEach((comp) => {
          const getvendorDetailsIndex = this.layoutData.layout.components[0].vendor_details.findIndex(
            (layOutComp) => layOutComp.vendor_code === comp.entityId
          );
          if (getvendorDetailsIndex !== -1 && currentProfileFeatures[comp.entityId]) {
            updateVendorDetails.push(this.layoutData.layout.components[0].vendor_details[getvendorDetailsIndex]);
          }
        });
        this.layoutData.layout.components[0].vendor_details = updateVendorDetails;
      }
      if (this.layoutData?.layout?.components?.length) {
        this.myLibraryService.isThirdPartyAvailable = true;
      }
      this.progressiveEmitThirdParty();
    } catch (e) {
      this.layoutData = { layout: { components: [] } };
      this.isLoading = false;
      this.progressiveEmitThirdParty();
    }
  }
  emitCount(count: any) {
    this.removeEmptyThirdPartyEvent.emit(count);
  }
  progressiveEmitThirdParty() {
    this.compData.isShow = this.layoutData?.layout?.components?.length > 0;
    this.progressiveEmit.emit();
  }
}
