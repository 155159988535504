import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TitleDetailsComponent } from '@titledetails/title-details.component';
import { MyStuffTitleComponent } from './my-stuff-titles/my-stuff-title/my-stuff-title.component';
import { MyStuffTitlesMainComponent } from './my-stuff-titles/my-stuff-titles-main.component';
import { MyStuffComponent } from './my-stuff.component';
import { ThirdPartyTierThreeComponent } from 'app/third-party/third-party-tier-three/third-party-tier-three.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPInterceptor } from '@shared/http.interceptor';
import { ThirdPartyTierTwoComponent } from 'app/third-party/third-party-tier-two/third-party-tier-two.component';
import { ThirdPartyTierTwoMainComponent } from 'app/third-party/third-party-tier-two-main/third-party-tier-two-main.component';

const routes: Routes = [
  {
    path: '',
    component: MyStuffComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'checkouts'
      },
      {
        path: 'checkouts',
        component: MyStuffTitlesMainComponent,
        children: [
          { path: '', component: MyStuffTitleComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'title/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'list',
            component: ThirdPartyTierTwoMainComponent,
            data: { breadcrumb: { alias: 'contentlist' } },

            children: [
              {
                path: 'details/:id',
                component: ThirdPartyTierThreeComponent,
                data: { breadcrumb: { info: 'titledetail' } },
              },
              {
                path: '',
                component: ThirdPartyTierTwoComponent
              },
            ]
          }
        ]
      },
      {
        path: 'holds',
        component: MyStuffTitlesMainComponent,
        children: [
          { path: '', component: MyStuffTitleComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'title/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
        ]
      },
      {
        path: 'wishlist',
        component: MyStuffTitlesMainComponent,
        children: [
          { path: '', component: MyStuffTitleComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'title/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
        ]
      },
      {
        path: 'recommendations',
        component: MyStuffTitlesMainComponent,
        data: { breadcrumb: 'Purchase Requests' },
        children: [
          { path: '', component: MyStuffTitleComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
        ]
      },
      {
        path: 'history',
        component: MyStuffTitlesMainComponent,
        children: [
          { path: '', component: MyStuffTitleComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'title/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true
    },
  ]
})
export class MyStuffRoutingModule { }
