import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FeaturedProgramComponent } from '@home/featured-program/featured-program.component';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { CurrentLibrary } from '@shared/data/config.model';
import { getBackgroundImageResize, strToBool } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { Subscription } from 'rxjs';
import { MyLibraryAnalyticsService } from '../shared/services/my-library.analytics.service';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-feature-program',
  templateUrl: './feature-program.component.html',
  styleUrls: ['./feature-program.component.scss']
})
export class FeatureProgramComponent extends FeaturedProgramComponent implements OnDestroy {
  featureProgramBgImage: string[];
  backgroundImageUrl: string;
  backgroundImageUrlSubscrition: Subscription;
  getBackgroundImageResize: any = getBackgroundImageResize;
  currentLibrary: Partial<CurrentLibrary>;
  expandState: boolean;
  tempExpandState: boolean;
  isFeaturedProgram = true;
  @Input() parentRoute: string;
  @Input() compData: any;

  constructor(
    public myLibraryAnalyticsService: MyLibraryAnalyticsService,
    public homeService: HomeService,
    public programsService: ProgramsService,
    public profileInfoService: ProfileInfoService,
    public engageService: EngageService,
    public homeAnalyticsService: HomeAnalyticsService,
    public configService: ConfigService,
    public myShelfListService: MyShelfListService,
    public cdRef: ChangeDetectorRef,
    public commonService: CommonService
  ) {
    super(homeService, myShelfListService, programsService, engageService, homeAnalyticsService, cdRef, commonService);
  }
  ngOnInit(): void {
    this.expandState = sessionStorage.getItem('featureExpandState') !== 'false';

    super.ngOnInit(this.compData);
    this.currentLibrary = this.configService.currentLibrary;
    this.backgroundImageUrlSubscrition = this.programsService.getBackgroundImageSubject().subscribe((bgImage: string[]) => {
      if (bgImage && bgImage.length > 0) {
        const highContrast = strToBool(this.profileInfoService.profileInfo.highContrast);
        this.featureProgramBgImage = highContrast ? [] : bgImage;
        this.onResize();
      }
    });
  }

  onResize() {
    this.backgroundImageUrl = getBackgroundImageResize(this.featureProgramBgImage);
  }

  ngOnDestroy(): void {
    this.backgroundImageUrlSubscrition.unsubscribe();
  }
  viewProgramDetail() {
    this.myLibraryAnalyticsService.trackMyLibraryFeaturedReadingProgram('VIEW_PROGRAM_DETAILS');
  }
  openPrograms() {
    this.myLibraryAnalyticsService.trackMyLibraryFeaturedReadingProgram('OPEN_PROGRAMS');
  }
  expandProgram() {
    this.myLibraryAnalyticsService.trackMyLibraryFeaturedReadingProgram('VIEW_PROGRAM_EXPANDED');
    this.tempExpandState = true;
    this.expand();
  }
  collapseProgram() {
    this.myLibraryAnalyticsService.trackMyLibraryFeaturedReadingProgram('VIEW_PROGRAM_COLLAPSE');
    this.tempExpandState = false;
    this.expand();
  }
  /**
   * Expands goals insights and badges component
   */
  expand() {
    sessionStorage.setItem('featureExpandState', `${this.tempExpandState}`);
  }
}
