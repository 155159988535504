import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { IActivityCategory } from '@shared/data/learning-activity.model';
import { noop } from 'rxjs';
import { LIB_COMP_ENTITY_TYPES } from '../shared/constants/my-library.constants';
import { IAnnouncementResponse } from '../shared/data/announcement.model';
import { IBookDetail } from '../shared/data/book-detail.model';
import { LibCompononent } from '../shared/data/library.model';
import { MyLibraryService } from '../shared/services/my-library.service';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';
import { ListBaseService } from '@shared/services/list-base.service';

@Component({
  selector: 'axis360-lib-banners',
  templateUrl: './lib-banners.component.html',
  styleUrls: ['./lib-banners.component.scss']
})
export class LibBannersComponent implements OnInit {
  isAnnouncementLoading = false;
  isLearningActivitiesLoading = false;
  bookDetailsLoading = false;
  isBookOfMonthPresent: boolean;
  announcementData: IAnnouncementResponse;
  learningActivitiesData: IActivityCategory[];
  bookId: string;
  bookDetails: Partial<IBookDetail>;
  @Input() libBannerData: LibCompononent;
  @Input() showIframe: boolean = true;
  @Output() progressiveEmit = new EventEmitter();
  LIB_COMP_ENTITY_TYPES = LIB_COMP_ENTITY_TYPES;
  showLearningActivity = false;
  showBookOfMonth = false;
  compName = SHIMMER_COMPONENTS.ANNOUNCEMENT_CARD;
  partnerSiteEnabled: boolean = true;
  bookOfMonthList: any;
  isHaveData = false;
  imageSafeUrl: SafeUrl;
  constructor(private myLibraryService: MyLibraryService, private sanitizer: DomSanitizer, public listBaseService: ListBaseService,
    public configService: ConfigService, public profileInfoService: ProfileInfoService, public userService: UserService
  ) { }

  ngOnInit(): void {
    const componentListBOM = this.libBannerData.components.find(x => x.entityType === LIB_COMP_ENTITY_TYPES.FEATURED_TITLE);
    this.showBookOfMonth = componentListBOM?.components === null || (Array.isArray(componentListBOM?.components) && (componentListBOM?.components?.length === 0 || componentListBOM?.components?.length > 0)) || typeof componentListBOM?.components === 'undefined';
    this.showLearningActivity = (this.userService.isLoggedIn() ? this.configService.getProfileFeatures(
      this.profileInfoService.currentProfile.profileType.toLowerCase()
    ).learningActivityEnabled : this.configService.getProfileFeatures(PROFILE_TYPES.ADULT).learningActivityEnabled);
    this.partnerSiteEnabled = this.configService.currentLibrary.partnerSiteEnabled;
    this.getLibBanners();
  }
  getLibBanners() {
    const funcMap = {
      [LIB_COMP_ENTITY_TYPES.ANNOUNCEMENTS]: this.getAnnouncements,
      [LIB_COMP_ENTITY_TYPES.FEATURED_TITLE]: this.getBookDetail,
      [LIB_COMP_ENTITY_TYPES.LEARNING_ACTIVITIES]: this.showLearningActivity && this.getLearningActivities
    };
    this.libBannerData.components.forEach((libComponent) => (funcMap[libComponent.entityType] || noop)(libComponent));
  }

  sanitizeAndBypassUrl(bookDetailImageUrl: string): SafeResourceUrl {
    // Further validate or sanitize the URL if necessary
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, bookDetailImageUrl);
    return this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
  }

  getBookDetail = async (component: LibCompononent): Promise<void> => {
    try {
      this.bookDetailsLoading = true;
      // Ensure the components array exists and contains at least one item
      if (component.components === null || (Array.isArray(component.components) && component.components.length === 0) || typeof component.components === 'undefined') {
        component.components = [{ ...component }];
      }
      const entityIds = component.components.map((item) => item.entityId);
      const containsNull = entityIds.some(element => element === null);
      if (!entityIds.length || containsNull) {
        this.handleNoData();
        return;
      }
      // Fetch book details for all entityIds in one request
      const bookDetails = await this.myLibraryService.getBooksByIsbn(entityIds);
      if (!bookDetails?.items?.length) {
        this.handleNoData();
        return;
      }
      this.isHaveData = true;
      this.bookOfMonthList = bookDetails;
      const isLoggedIn = this.userService.isLoggedIn();
      if (isLoggedIn) {
        this.bookOfMonthList.items.forEach(bookDetails => {
          this.getButtonViewActions(bookDetails, component);
        });
      }
      // Process book details and update component properties
      bookDetails.items.forEach((bookDetail) => {
        const detail = component.components.find((x) => x.entityId === bookDetail.isbn);
        if (detail) {
          this.imageSafeUrl = this.sanitizeAndBypassUrl(bookDetail.imageUrl);
          this.bookDetails = {
            title: bookDetail.title,
            isbn: bookDetail.isbn,
            author: bookDetail.author,
            itemId: bookDetail.itemId,
            imageUrl: this.imageSafeUrl as string,
            formatType: bookDetail.formatType,
            subject: `${window.origin}/ng/view/title/${bookDetail.itemId}/${bookDetail.isbn}`,
            isAvailable: bookDetail.isAvailable,
            isRTV: bookDetail.isRTV,
            runTime: bookDetail.runTime || bookDetail.RunTime,
            DisplayStatus: bookDetail.titleStateInfo?.displayStatus || '',
            HoldPosition: bookDetail.titleStateInfo?.holdPosition,
            description: detail.description,
            libraryId: bookDetail.libraryId,
            series: bookDetail.series,
          };
          detail.bookDetails = this.bookDetails;
        }
      });
      this.handleNoData();
    } catch (e) {
      this.handleNoData();
    }
  };
  handleNoData(): void {
    this.bookDetailsLoading = false;
    this.checkAllLibBannersLoaded();
  }

  /**
   * Gets button view Action book
   * @param item
   */
  async getButtonViewActions(item, component) {
    try {
      this.listBaseService.getMyLibraryBannersListActionButton(item).subscribe((response) => {
        item.DisplayStatus = response.displayStatus;
        item.HoldPosition = response.holdPosition;
        item.titleStateInfo = response;
        item.titleStatusInfo = response;
        const detail = component.components.find((x) => x.bookDetails?.isbn === item.isbn);
        if (detail) {
          this.imageSafeUrl = this.sanitizeAndBypassUrl(item.imageUrl);
          this.bookDetails = {
            title: item.title,
            isbn: item.isbn,
            author: item.author,
            itemId: item.itemId,
            imageUrl: this.imageSafeUrl as string,
            formatType: item.formatType,
            subject: `${window.origin}/ng/view/title/${item.itemId}/${item.isbn}`,
            isAvailable: item.isAvailable,
            isRTV: item.isRTV,
            runTime: item.runTime || item.RunTime,
            DisplayStatus: response.displayStatus || '',
            HoldPosition: response.holdPosition,
            description: detail.description,
            libraryId: item.libraryId,
            series: item.series,
            titleStateInfo: response,
            titleStatusInfo: response,
          };
          detail.bookDetails = this.bookDetails;
        }
      });
    } catch (error) {
      this.bookDetails = {};
    }
  }

  /**
   * To get Announcements from API
   */
  getAnnouncements = async () => {
    try {
      this.isAnnouncementLoading = true;
      await this.myLibraryService.getAnnouncements().then((announcementData) => {
        if (announcementData && announcementData.announcements.length > 0) {
          this.isHaveData = true;
          this.announcementData = announcementData;
          this.libBannerData.components.map((items) => {
            if (items.entityType === LIB_COMP_ENTITY_TYPES.ANNOUNCEMENTS) {
              items.announcementData = announcementData;
            }
            return items;
          });
          this.isAnnouncementLoading = false;
          this.checkAllLibBannersLoaded();
          return;
        }
        this.isAnnouncementLoading = false;
        this.checkAllLibBannersLoaded();
      });
    } catch (e) {
      this.isAnnouncementLoading = false;
      this.checkAllLibBannersLoaded();
    }
  };

  getLearningActivities = async () => {
    try {
      this.isLearningActivitiesLoading = true;
      const learningActivitiesResponse = await this.myLibraryService.getLearningActivities();
      if (learningActivitiesResponse && learningActivitiesResponse.statusCode === 200) {
        this.isHaveData = true;
        this.learningActivitiesData = learningActivitiesResponse.learningActivityCategory.data;
        this.libBannerData.components.map((items) => {
          if (items.entityType === LIB_COMP_ENTITY_TYPES.LEARNING_ACTIVITIES) {
            items.learningActivitiesData = this.learningActivitiesData;
          }
          return items;
        });
        this.isLearningActivitiesLoading = false;
        this.checkAllLibBannersLoaded();
        return;
      }
      this.isLearningActivitiesLoading = false;
      this.checkAllLibBannersLoaded();

    } catch (e) {
      this.isLearningActivitiesLoading = false;
      this.checkAllLibBannersLoaded();
    }
  };
  checkAllLibBannersLoaded() {
    if (!this.isAnnouncementLoading && !this.bookDetailsLoading && !this.isLearningActivitiesLoading) {
      this.libBannerData.isShow = this.isHaveData;
      this.progressiveEmit.emit();
    }
  }
}
