import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { API_INFO } from '@shared/constants/api.constant';
import { CAROUSEL_CONST, INTEREST } from '@shared/constants/app.constants';
import { Subscription } from 'rxjs';
import { TitleListService } from '../shared/services/title-list.service';
import { TitleListRefinersComponent } from '../title-list-refiners/title-list-refiners.component';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { Filters, ITitleContent, Refiner } from '../shared/data/model';
import { ConfigService } from '@shared/services/config.service';
import {
  TITLE_LIST,
  BASED_ON_INTEREST,
  BASED_ON_INTEREST_ERROR,
  defaultSubjectBasedOnProfileType
} from '../shared/constants/title-list-constants';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ListBaseService } from '@shared/services/list-base.service';
import { CATEGORY_SORT_FIELD } from 'app/core/kz-search-results/shared/constant/search.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { strToBool } from '@shared/helper/app.util';
import { MediaQueryService } from '@shared/services/media-query.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThousandSuffixesPipe } from '@shared/pipes/thousand-suffixes.pipe';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UserService } from '@shared/services/user.service';
/**
 * Component
 */
@Component({
  selector: 'axis360-title-list-view',
  templateUrl: './title-list-view.component.html',
  styleUrls: ['./title-list-view.component.scss']
})
export class TitleListViewComponent implements OnInit, OnDestroy {
  @Output() filter = new EventEmitter<string>();
  @Input() parentRoute: string;
  @Input() selectedCategoryId;
  @Input() pageTitle: string;
  filterOpened = false;
  centerTab = false;
  isLoading = true;
  tabsList: Refiner[] = [];
  selectedTitleListRefiner = {};
  buttonViews = {};
  bookIdArray = [];
  listContent = [];
  filterData: Filters;
  isTitleList = true;
  scrollSubscription: Subscription;
  filterDataSubscription: Subscription;
  sortFilterDataSubscription: Subscription;
  loadFeaturedListsSubscription: Subscription;
  loadBrowseListsSubscription: Subscription;
  initialSubscription: Subscription;
  initialCategorySubscription: Subscription;
  pageIndex = 1;
  totalItems = 0;
  TITLE_LIST = TITLE_LIST;
  BASED_ON_INTEREST = BASED_ON_INTEREST;
  CAROUSEL_CONST = CAROUSEL_CONST;
  istotalItemsResult = false;
  titleNotMatchMessage = 'Please use the search options on the left panel.';
  isOnload = true;
  isLargeScreen: boolean;
  sortRefiner: Refiner[];
  selectedSubject = '';
  epopupValue: boolean;
  isRemovePillSelection = false;
  isBasedOnInterestPills = false;
  isFirstItemListSelection = false;
  constructor(
    public matDialog: MatDialog,
    private titleListService: TitleListService,
    private listBaseService: ListBaseService,
    public infiniteScroll: InfiniteScrollService,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    private router: Router,
    private mediaQueryService: MediaQueryService,
    public overlayContainer: OverlayContainer,
    public activeRouter: ActivatedRoute,
    public liveAnnouncer: LiveAnnouncer,
    public userService: UserService
  ) { }

  /**
   * on init
   */
  ngOnInit(): void {
    this.epopupValue = this.titleListService.configService.currentLibrary.partnerSiteEnabled;
    this.activeRouter.queryParams.subscribe((params) => {
      if (params.subject) {
        const fillter = decodeURIComponent(params.subject).split('/');
        this.selectedSubject = fillter[0] !== '' ? fillter[0] : 'Browse';
      }
    });
    this.loadFeaturedListsSubscription = this.titleListService.loadFeaturedLists.subscribe((load) => {
      if (load) {
        this.istotalItemsResult = false;
        this.isLoading = true;
        this.isOnload = false;
        this.listContent = [];
      }
    });
    this.loadBrowseListsSubscription = this.titleListService.loadBrowseLists.subscribe(() => {
      this.istotalItemsResult = false;
      this.isLoading = true;
      this.isOnload = false;
      this.listContent = [];
    });

    this.titleListService.initialSetSubjectList.subscribe((subject) => {
      if (subject) {
        this.filterData = subject;
        this.tabsList = [];
      }
    });

    this.titleListService.removePillsAferCheckSubject.subscribe((removePills) => {
      if (removePills) {
        this.tabsList = [];
      }
    });

    this.initialCategorySubscription = this.titleListService.initialCategoryGetListContent.subscribe((subject) => {
      if (subject) {
        this.filterData = this.filterData?.selectedSubject === "Browse" ?
          { menuType: 'subject', refiners: [], categoryList: [], subjectList: subject, selectedSortByValue: this.filterData?.selectedSortByValue ? this.filterData?.selectedSortByValue : "PublishDate" } as any :
          { menuType: 'subject', refiners: [], categoryList: [], subjectList: subject } as any
        //this.titleListService.filterData = this.filterData; //#237443
        this.tabsList = [];
        this.istotalItemsResult = false;
        this.filter.emit(this.selectedSubject);
        this.isLoading = true;
        this.listContent = [];
        this.isOnload = false;
        this.pageIndex = 1;
        if (this.epopupValue) {
          return;
        }
        this.getListContent();
      }
    });

    this.initialSubscription = this.titleListService.initialGetListContent.subscribe((initial) => {
      if (initial) {
        this.filterData = this.titleListService.filterData;
        this.filterData =
          this.filterData?.selectedSubject === 'Browse'
            ? ({
                menuType: 'subject',
                refiners: [],
                categoryList: [],
                selectedSortByValue: this.filterData?.selectedSortByValue ? this.filterData?.selectedSortByValue : 'Score'
              } as any)
            : ({ menuType: 'subject', refiners: [], categoryList: [] } as any);
           this.titleListService.filterData = this.filterData;
        this.filter.emit(this.selectedSubject);
        this.isOnload = false;
        this.isLoading = true;
        this.listContent = [];
        this.pageIndex = 1;
        if (this.epopupValue) {
          return;
        }
        //this.getListContent();  To fix waitlist issue 
      }
    });

    this.filterDataSubscription = this.titleListService.filterData$.subscribe((value) => {
      if (navigator.userAgent.includes('Mac') && value.categoryId == 'Based_your_Interests') {
        this.liveAnnouncer.announce(`${BASED_ON_INTEREST} Title List page`, 'assertive');
      }
      if (value && value.refiners) {
        this.istotalItemsResult = false;
        this.isLoading = true;
        this.listContent = [];
        this.pageIndex = 1;

        this.filterData = value;
        this.filterData.selectedSubject =
          this.filterData.menuType === TITLE_LIST.ALWAYSAVAILABLE ? this.pageTitle : this.filterData.selectedSubject;
        this.filter.emit(this.filterData.selectedSubject);
        const checkTitleNotMatchMessage = (this.filterData?.selectedCategory) ?
          this.filterData?.selectedCategory.toUpperCase() : this.filterData?.selectedSubject?.toUpperCase();
        this.titleNotMatchMessage = this.filterData.featuredList.length === 0 ? `Please use the search options on the left panel.` :
          (`There were no titles matching "${checkTitleNotMatchMessage}" within your library's
        selection`);
        if (this.filterData?.selectedSubject === BASED_ON_INTEREST) {
          this.titleNotMatchMessage = BASED_ON_INTEREST_ERROR;
        }
        this.titleListService.setSubjectAndfeaturedDataPills(this.filterData.menuType);
        if (this.filterData.menuType === TITLE_LIST.SUBJECT) {
          let tempList = [];
          value.refiners.forEach((ref) => {
            if (ref.value === 'collections') {
              ref.selected = ref.items
                .filter((itemRef) => itemRef.value !== '' && itemRef.isSelected)
                .map((item) => item.value)
                .join('#');
            }
          });
          this.selectedTitleListRefiner = value.refiners.reduce((result, item) => {
            const key = item.value;
            item.text !== 'Sort By' &&
              (result[key] = [...item.items.filter((x) => x.isSelected).map((list) => list.text)].filter((items) => items !== 'All'));
            return result;
          }, {});
          Object.keys(this.selectedTitleListRefiner).forEach((key) => {
            if (key === 'sortBy' || key === 'sort' || key === 'sortby' || key === 'searhSort' || key === 'orderBy') {
            } else if (
              typeof this.selectedTitleListRefiner[key][0] === 'string' ||
              this.selectedTitleListRefiner[key][0] instanceof String
            ) {
              !this.selectedTitleListRefiner[key].some((x) => x === 'All') &&
                this.selectedTitleListRefiner[key].forEach((value) => tempList.push({ key, value }));
            }
          });
          this.tabsList = tempList;
          if (this.tabsList && this.tabsList.length === 0) {
            this.isFirstItemListSelection = true;
          }
          if (this.tabsList[0] && this.isRemovePillSelection) {
            setTimeout(() => {
              const pillLists = document.getElementById('loc_search_pill' + this.tabsList[0].value);
              pillLists && pillLists.focus();
              this.isRemovePillSelection = false;
            }, 0);
          }
        } else {
          this.tabsList = value.refiners.filter((elem) => {
            if (elem.selected && elem.selected !== 'All' && elem.text !== 'Sort By') {
              const isSelected = elem.items.find(x => x.text === elem.selectedText)?.isSelected;
              if (isSelected) {
                elem.tabvalue = elem.selectedText;
              } else {
                elem.tabvalue = '';
              }
              return elem.selectedText;
            }
            })
            .filter((ref) => ref.tabvalue !== '');
          if (this.tabsList.length > 1 && !this.tabsList[0].defaultSelected) {
            this.tabsList.push({ tabvalue: TITLE_LIST.CLEAR_ALL, text: '', value: '', items: [] });
          }
          if (this.tabsList[0]) {
            this.isBasedOnInterestPills = true;
          }
        }
        if (this.isOnload && this.titleListService.pageRetainInfo.listContent?.length) {
          this.listContent = this.titleListService.pageRetainInfo.listContent;
          this.pageIndex = this.titleListService.pageRetainInfo.pageNumber;
          this.getListContent();
          setTimeout(() => {
            window.scrollTo(0, this.titleListService.pageRetainInfo.pageYOffset);
          }, 0);
        } else {
          this.getListContent();
          this.pageIndex = 1;
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 0);
        }
        this.isOnload = false;
      }
    });
    this.scrollSubscription = this.infiniteScroll.subscribe(() => {
      !this.isOnload && this.loadMore();
    });
    this.isLargeScreen = this.mediaQueryService.getMediaQueryListener().matches;
    this.mediaQueryService.getMediaQueryListener().addEventListener('change', this.onMediaQueryChange.bind(this));
    this.sortFilterDataSubscription = this.titleListService.sortRefinerData$.subscribe((value) => {
      this.sortRefiner = value;
    });
    if (navigator.userAgent.match(/(iPhone)/i)) {
      const refinerFilterIcon = document.getElementById('refinerFilterIcon');
      refinerFilterIcon && refinerFilterIcon.setAttribute('aria-hidden', 'true');
    }
  }
  /**
   * Opens filter
   * @returns
   */
  openFilter() {
    if (!this.filterOpened && !(this.titleListService.filterData && this.titleListService.filterData?.refiners.length === 0)) {
      this.filterOpened = !this.filterOpened;
      return;
    }
    document.getElementById('refiner-close') && document.getElementById('refiner-close')[1]?.focus();
    const titleListDialog = this.matDialog.open(TitleListRefinersComponent, {
      panelClass: 'filter-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'filter-title'
    });
    titleListDialog.afterClosed().subscribe(() => (this.filterOpened = true));
  }
  /**
   * Close dialog on window resize
   */
  closeFilter() {
    if (window.innerWidth > 768 && this.filterOpened) {
      this.matDialog.closeAll();
      this.filterOpened = false;
    }
  }
  /**
   * Get profile i
   * @returns
   */
  getProfileType() {
    const checkProfileTypeId = this.profileInfoService.currentProfile.profileTypeId === '1'
      ? 2
      : this.profileInfoService.currentProfile.profileTypeId
    return this.configService.currentLibrary.axis360Enabled
      ? this.profileInfoService.currentProfile.profileTypeId
      : checkProfileTypeId;
  }
  /**
   * Gets list content
   */
  async getListContent() {
    this.isLoading = true;
    this.istotalItemsResult = false;
    this.totalItems = this.pageIndex === 1 ? 0 : this.totalItems;
    const alertElement = document.getElementById('loc_title_list_count');
    if(alertElement) {
      alertElement.innerHTML = '';
    }
    let url = '';
    const checkedSubjectList = this.filterData?.subjectList
      ?.filter((code) => code.isChecked)
      .map((items) => items.code)
      .join('#');
    const filterCheckedCategoryList = this.filterData?.categoryList.filter(ref => ref.isChecked).map(item => item.code).join('#');
    const checkProfileTypeUrl = this.configService.currentLibrary.axis360Enabled
      ? ''
      : `&profiletype=2`;
    const profileType = this.profileInfoService.currentProfile.profileTypeId
      ? `&profiletype=${this.getProfileType()}`
      : checkProfileTypeUrl;
    const currentProfile = this.profileInfoService.currentProfile.profileType;
    const ageLevel =
      this.configService.currentLibrary.axis360Enabled && (currentProfile === 'Adult' || currentProfile === '') ? 'Adult' : '';
    let titleContentList: ITitleContent = {};
    if (this.filterData && this.filterData.menuType === TITLE_LIST.SUBJECT) {
      const checkProfileTypeId = this.configService.currentLibrary.axis360Enabled ? '' : '2';
      const profileTypeId = this.profileInfoService.currentProfile.profileTypeId ? this.getProfileType() : checkProfileTypeId;
      const defaultSortOption = { sortby: "PublishDate" };
      const checkProfileType = (Number(profileTypeId) === 1 || Number(profileTypeId) === 0) ? null : 'Kid';
      const profileType = Number(profileTypeId) === 2 ? 'Teen' : checkProfileType;
      const defaultBisacsCode = profileType ? defaultSubjectBasedOnProfileType[profileType] : null;
      const checkBisacsCode = defaultBisacsCode || '';
      const getSubjectBisacsCode = (checkedSubjectList && checkedSubjectList !== '') ? checkedSubjectList : checkBisacsCode;
      titleContentList = {
        term: this.titleListService.isTitleDetail ? this.titleListService.selectedSubjectTitle : null,
        bisacscode: this.titleListService.isTitleDetail
          ? null
          : filterCheckedCategoryList !== ''
          ? filterCheckedCategoryList
          : getSubjectBisacsCode,
        pageSize: 24,
        page: this.pageIndex,
        profiletype: Number(profileTypeId)
      };

      this.filterData.refiners.length === 0 && (titleContentList = { ...titleContentList, ...defaultSortOption });
    } else {
      url = `${API_INFO.getFeatureList.replace(
        '<controller>',
        `${this.filterData?.menuType === 'alwaysavailable' ? 'Collection' : 'Home'}`
      )}listId=${isNaN(+this.filterData.categoryId) ? this.filterData.categoryId : ''}&categoryId=${this.filterData.categoryId}_${
        this.configService.currentLibrary.id
      }&staffPickId=${this.filterData.staffPickId ? this.filterData.staffPickId : ''}&limit=24&pageNumber=${this.pageIndex}${profileType}`;
    }
     if (this.filterData && this.filterData.refiners && this.filterData.refiners.length) {
        this.filterData.refiners.forEach((element) => {
          if (this.filterData.menuType === 'list') {
            if (element.value === 'sortby') {
              url = url.concat(
                `&sortOrder=${CATEGORY_SORT_FIELD[element.selected] ? -1 : 1}&sortField=${element.selected ? encodeURIComponent(element.selected) : ''
                }`
              );
              if (this.filterData && this.filterData.featuredList && this.filterData.featuredList.length) {
                this.filterData.featuredList.forEach((element) => {
                  if (element.ListName === this.filterData.selectedSubject && element.AvailableTitlesFirst === true) {
                    url = url.replace(
                      `sortField=${this.filterData.refiners[0].selected ? encodeURIComponent(this.filterData.refiners[0].selected) : ''}`,
                      `sortField=AvailableFirst_${
                        this.filterData.refiners[0].selected ? encodeURIComponent(this.filterData.refiners[0].selected) : ''
                      }`
                    );
                  }
                });
              }
            }
            url = url.concat(
              `&${element.value === 'recentlyadded' ? 'addeddate' : element.value}=${element.selected ? encodeURIComponent(element.selected) : ''
              }`
            );
            return;
          } else if (this.filterData.menuType === TITLE_LIST.SUBJECT) {
            const refinerItemList = this.filterData.refiners.reduce((acc: any, item) => {
              const { value, selected } = item;
              acc[value === 'recentlyadded' ? 'addeddate' : value] = selected;
              return acc;
            }, {});

            titleContentList = { ...titleContentList, ...refinerItemList };
          } else if (this.filterData.menuType === 'alwaysavailable') {
            if (element.value === 'sortby') {
              url = url.concat(`&sortOrder=${CATEGORY_SORT_FIELD[element.selected] ? -1 : 1}&format=ABT`);
            }
            url = url.concat(
              `&${element.value === 'recentlyadded' ? 'addeddate' : element.value}=${element.selected ? encodeURIComponent(element.selected) : ''
              }`
            );
            return;
          } else {
            url = url.concat(
              `&sortOrder=${CATEGORY_SORT_FIELD[element.selected] ? -1 : 1}&${element.value === 'recentlyadded' ? 'addeddate' : element.value
              }=${element.selected ? encodeURIComponent(element.selected) : ''}`
            );
          }
        });
      }

    if (url.includes(`agelevel=`)) {
      url = url.replace(`agelevel=`, `agelevel=${ageLevel}`);
    }
    const isLoggedIn = this.userService.isLoggedIn();
    if (this.filterData.menuType === TITLE_LIST.SUBJECT) {
      const defaultSortOption = { sortby: this.filterData.selectedSortByValue ? this.filterData.selectedSortByValue : "PublishDate" };
      titleContentList = { ...titleContentList, ...defaultSortOption };
      this.titleListService.getListContentForBrowse(titleContentList).subscribe(browser => {
        this.titleListService.isSelectedSubjectDisable.next(true);
        this.titleListService.initialGetListContent.next(false);
        this.titleListService.initialCategoryGetListContent.next(null);
        this.titleListService.removePillsAferCheckSubject.next(false);
        this.totalItems = browser.TotalItems ? new ThousandSuffixesPipe().transform(browser.TotalItems || 0) : 0;
        alertElement ? alertElement.innerHTML = this.getHeadingAriaLabel(): '';
        this.istotalItemsResult = true;
        if (browser?.Items?.length) {
          let response = browser;
          this.bookIdArray = response.Items.map((book: any) => book.ItemId);
          if (this.pageIndex === 1) {
            this.listContent = [];
          }
          this.listContent.push(...response.Items);
          
          if(this.isFirstItemListSelection) {
            setTimeout(() => {
              const isfirstItemList = document.getElementById(`title_${this.listContent[0].ItemId}`);
              isfirstItemList && isfirstItemList.focus();
              this.isFirstItemListSelection = false;
              this.isRemovePillSelection = false;
            }, 0);
          }
          if (isLoggedIn) {
            this.getButtonViewRecommendedBookV2(browser);
          }
          sessionStorage.setItem('titleListFilters', JSON.stringify({}));
        }
        this.titleListService.pageRetainInfo = { listContent: [...this.listContent], pageYOffset: window.scrollY, pageNumber: this.pageIndex };
        this.titleListService.isRemoveisReadOnly.next(false);
        this.isLoading = false;
        this.titleListService.selectedSubject = undefined;
        this.titleListService.selectedSubjectTitle = undefined;
        this.titleListService.isTitleDetail = false;
        !this.titleListService.elementId && document.getElementById(this.selectedCategoryId)?.focus();
        this.titleListService.refreshBreadcrumb = false;
        document.getElementById(this.selectedCategoryId)?.focus();
      });
      return;
    }
    this.epopupValue && (url += `&availability=Available`);
    const res = await this.titleListService.getListContent(url);
    const getListContentUrlGen = (this.filterData?.menuType === 'alwaysavailable') ?
      url.replace('/Collection/GetListContent', '/Collection/GetListItemsCount')
      : url.replace('/Home/GetListContent', '/Home/GetListItemsCount');
    const res_total = (this.pageIndex === 1 && (url.includes('/Home/GetListContent') || url.includes('/Collection/GetListContent'))
      && this.filterData?.selectedSubject !== BASED_ON_INTEREST
      && this.filterData?.selectedSubject !== CAROUSEL_CONST.Recommended_for_you) ?
      (await this.titleListService.getListContent(getListContentUrlGen))
      : url;
    this.titleListService.isSelectedSubjectDisable.next(true);
    this.titleListService.initialGetListContent.next(false);
    this.titleListService.initialCategoryGetListContent.next(null);
    const addTotalCountItem = res.TotalItems ? new ThousandSuffixesPipe().transform(res.TotalItems || 0) : this.totalItems;
    const addTotalCountItemByPageIndex = (res_total.TotalItems && this.pageIndex === 1) ? new ThousandSuffixesPipe().transform(res_total.TotalItems || 0) : this.totalItems;
    this.totalItems = (this.filterData?.selectedSubject === BASED_ON_INTEREST || this.filterData?.selectedSubject === CAROUSEL_CONST.Recommended_for_you 
      || this.filterData?.categoryId === CAROUSEL_CONST.Recommended_for_you || this.filterData?.categoryId === CAROUSEL_CONST.Based_your_Interests)
      ? addTotalCountItem
      : addTotalCountItemByPageIndex;
    this.istotalItemsResult = true;
    alertElement ? alertElement.innerHTML = this.getHeadingAriaLabel(): '';
    if (res?.Items?.length) {
      let response = res;
      this.bookIdArray = response.Items.map((book: any) => book.ItemId);
      if (this.pageIndex === 1) {
        this.listContent = [];
      }
      this.listContent.push(...response.Items);
      sessionStorage.setItem('titleListFilters', JSON.stringify({}));
      if(this.titleListService.isFirstItemListSelection) {
        setTimeout(() => {
          const isfirstItemList = document.getElementById(`title_${this.listContent[0].ItemId}`);
          isfirstItemList && isfirstItemList.focus();
          this.isRemovePillSelection = false;
        }, 0);
        setTimeout(() => {
          this.titleListService.isFirstItemListSelection = false;
        }, 1000);
      }
    }
    if (isLoggedIn) {
      this.getButtonViewRecommendedBookV2(res);
    }

    if (this.isBasedOnInterestPills) {
      const headingWithCount = this.getHeadingAriaLabel();
      setTimeout(() => {
        headingWithCount && this.liveAnnouncer.announce(headingWithCount, 'polite');
      }, 2000);
    }
    this.titleListService.pageRetainInfo = { listContent: [...this.listContent], pageYOffset: window.scrollY, pageNumber: this.pageIndex };
    this.isLoading = false;
    this.titleListService.selectedSubject = undefined;
    this.titleListService.selectedSubjectTitle = undefined;
    this.titleListService.refreshBreadcrumb = false;
    if (this.filterData?.menuType !== TITLE_LIST.LIST) {
      document.getElementById(this.selectedCategoryId)?.focus();
    }
  }

  getButtonViewRecommendedBookV2(listItems) {
    try {
      this.listBaseService.getMyLibraryListActionButtonV2(listItems).subscribe(res => {
        this.listContent.map(item => {
          const listItem = res.find(resItem => resItem.itemId === item.ItemId);
          if (item.ItemId && listItem) {
            item.DisplayStatus = listItem.displayStatus || '';
            item.HoldPosition = listItem.holdPosition || '';
            item.titleStateInfo = listItem;
          }
        });
      });
    } catch (error) {
      const listConstentItems = this.listContent;
      this.listContent = listConstentItems;
      return;
    }
  }

  /**
   * Gets button view recommended book
   * @param item
   */
  async getButtonViewRecommendedBook(item) {
    try {
      this.listBaseService.getMyLibraryListActionButton(item).subscribe((response) => {
        item.DisplayStatus = response.displayStatus || '';
        item.HoldPosition = response.holdPosition || '';
        item.titleStateInfo = response;
      });
    } catch (error) {
      item = item;
    }
  }
  /**
   * Load more of title list view component
   */
  loadMore = () => {
    if (this.totalItems === this.listContent.length) {
      return;
    }
    this.pageIndex++;
    this.getListContent();
  };

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.titleListService.pageRetainInfo.pageYOffset = window.scrollY;
    this.scrollSubscription.unsubscribe();
    this.filterOpened = false;
    this.filterDataSubscription && this.filterDataSubscription.unsubscribe();
    this.initialSubscription && this.initialSubscription.unsubscribe();
    this.initialCategorySubscription && this.initialCategorySubscription.unsubscribe();
    this.loadFeaturedListsSubscription && this.loadFeaturedListsSubscription.unsubscribe();
    this.loadBrowseListsSubscription && this.loadBrowseListsSubscription.unsubscribe();
    this.mediaQueryService.getMediaQueryListener().removeEventListener('change', this.onMediaQueryChange.bind(this));
    this.sortFilterDataSubscription && this.sortFilterDataSubscription.unsubscribe();
  }
  titleCardClickEvent() {
    sessionStorage.setItem('titleListFilters', JSON.stringify({ ...this.filterData }));
  }

  editInterest() {
    const checkProfileType = this.profileInfoService?.currentProfile?.profileType === 'Adult' ? 'teen'
      : this.profileInfoService?.currentProfile?.profileType?.toLowerCase();
    const pathParams =
      !this.configService.currentLibrary.axis360Enabled && this.configService.currentLibrary.kidsZoneEnabled
        ? checkProfileType
        : this.profileInfoService?.currentProfile?.profileType?.toLowerCase();
    const isPrimary = strToBool(this.profileInfoService?.currentProfile?.isPrimary);
    this.router.navigate([`view/interestsurvey/${pathParams}`], {
      queryParams: { id: +this.profileInfoService?.currentProfile?.profileId, isPrimary: isPrimary, isFrom: INTEREST }
    });
  }

  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      document.getElementsByTagName('html')[0].setAttribute('class', 'hide-scroll');
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementsByTagName('html')[0].classList.remove('hide-scroll');
  }

  sortChange(sortValue: string, sortText: string, selectedType: string) {
    if (selectedType != 'defaultSelected') {
      this.filterData.refiners.map((val) => {
        if (val.text === 'Sort By') {
          val.selected = sortValue;
          val.selectedText = sortText;
          val.defaultSelected = false;
        }
      });
    }
    this.titleListService.setFilters(this.filterData);
  }

  /**
   * Remove TitleList Selection
   * @param [option] to pass refiner removeTitle options
   * @returns To get updated Filter data
   */
  removeTitleListSelection(option) {
    this.isRemovePillSelection = true;
    this.titleListService.isLoadAnnouncerbyPillsRemove.next(true);
    if (option.key === 'ClearAll') {
      this.isFirstItemListSelection = true;
      for (let filters of this.filterData.refiners) {
        if (filters.value !== 'sortby' && !(this.epopupValue && filters.value === 'availability')) {
          for (let item of filters.items) {
            item.isSelected = false;
          }
          filters.selectedText = '';
          filters.selected = undefined;
        }
      }

      this.titleListService.setFilters(this.filterData);
     // this.titleListService.setReset$.next(true);
      return;
    }
    if (option.key === 'releasedate' || option.key === 'recentlyadded') {
      this.filterData.refiners.forEach((item) => {
        if (item.value === 'releasedate' || item.value === 'recentlyadded') {
          const itemIndex = item.items.findIndex((ref) => ref.text === option.value);
          if (itemIndex >= 0) {
            item.items[itemIndex].isSelected = false;
          }
          item.selected = item.items[item.items.map((x) => x.isSelected).lastIndexOf(true)]?.value;
        }
      });
      this.titleListService.setFilters(this.filterData);
      return;
    }
    if (option.key === 'agelevel') {

      this.filterData.refiners.filter(ref => ref.value === option.key).map(item => {
        item.items.find(ref => ref.text === option.value).isSelected = false;
        item.selected = item.items.filter(y => y.value !== '' && y.isSelected).map(item => item.text)
          .join('#');
      });

      this.titleListService.setUpdateFilters(this.filterData);
      return;
    }
    this.filterData.refiners.filter(ref => ref.value === option.key).map(item => {
      item.items.find(ref => ref.text === option.value).isSelected = false;
      item.selected = item.items.filter(y => y.value !== '' && y.isSelected).map(item => item.value)
        .join('#');
    });
    this.titleListService.setUpdateFilters(this.filterData);
  }

  getHeadingAriaLabel() {
    if (this.filterData?.menuType === TITLE_LIST.SUBJECT) {
      return `Results Count ${this.totalItems}`;
    } else {
      const isSelectedCategory = this.filterData?.selectedCategory?.toUpperCase() === TITLE_LIST.LGBTQ ?
        this.filterData?.selectedCategory?.toUpperCase() :
        this.filterData?.selectedCategory?.toLowerCase();
      const titleText = (this.filterData?.selectedCategory) ?
        isSelectedCategory : this.filterData?.selectedSubject? this.filterData?.selectedSubject .toLowerCase() :BASED_ON_INTEREST
      return titleText ? `${titleText} Book Count ${this.totalItems}` : '';
    }
  }
  /**
   *
   * @param event - change event
   */
  onMediaQueryChange(event: MediaQueryListEvent): void {
    this.isLargeScreen = event.matches;
  }
}
