<ng-container>
    <div class="login-container-sec kz-main">
        <h2 class="heading-1">
            Update Your Email
        </h2>
        <p class="description m-bottom">
            Please enter a valid email address that can be used to notify you when the title is available for checkout.
        </p>
        <form [formGroup]="emailUpdateForm" id="emailUpdateForm">
            <div class="kz-login-form">
                <mat-form-field appearance="fill">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="" formControlName="emailId" id="emailId" cdkFocusInitial
                        autocomplete="off" axis360TrapFocus (keyup)="onConfirmEmail()">
                    <mat-error *ngIf="emailUpdateForm.controls.emailId.errors">Email is not valid.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill"
                    [ngClass]="{'mb-0': isMatchEmailId, 'mb-2': emailUpdateForm.controls.confirmEmailId.errors && isMatchEmailId }">
                    <mat-label>Confirm Email Address</mat-label>
                    <input matInput placeholder="" formControlName="confirmEmailId" id="confirmEmailId"
                        (keyup)="onConfirmEmail()">
                    <mat-error *ngIf="emailUpdateForm.controls.confirmEmailId.errors">Email is not valid.</mat-error>
                </mat-form-field>
                <mat-error *ngIf="isMatchEmailId">Email & confirm Email did not match.</mat-error>
            </div>
            <div class="done_Submit d-flex">
                <button mat-raised-button class="btn-primary-blue saveBtn" id="submit" [disabled]="emailUpdateForm.invalid"
                    (click)="updateEmail()">Ok</button>
            </div>
        </form>
    </div>
</ng-container>