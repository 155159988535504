<div class="kz-drawer-refiners-container  kz-search-refiners">
    <div class="refiners-head visible-sm">
        <button class="close-icon" role="button" aria-label="Close refiners" (click)="closeDialog()">
            <mat-icon svgIcon="kz-close" aria-hidden="true">close</mat-icon>
        </button>
    </div>
    <axis360-categories (filterPopupClose)="closeDialog()" *ngIf="!isLearningActivity"> </axis360-categories>
    <ng-container *ngIf="(searchService.showEaudioRefiner || searchService.showEbookRefiner)  && showRefinerBlock()">
        <ng-container *ngFor="let refiner of refiners; let j = index">
            <mat-accordion class="kz-checkbox-sec refiners-accordion" *ngIf="refiner.value === 'sortby'">
                <mat-expansion-panel class="refiners-expansion-panel" [expanded]="state === j" (opened)="setState(j)">
                    <mat-expansion-panel-header class="refiners-panel-header" [expandedHeight]="customExpandedHeight">
                        <mat-panel-title class="heading-3">
                            <div class="content-assign content-read">
                                <div class="content-name-head">
                                    <h2 class="refiners-title single-ellipsis text-uppercase">
                                        {{refiner.text}}
                                    </h2>
                                    <p class="sub-title-txt">
                                        {{refiner?.selectedText === 'Titles to Recommend' ? 'Purchase Requests' :
                                        refiner.selectedText}}</p>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-content">
                        <div class="radio-group kz-radio-btn-group">
                            <ng-container *ngFor="let item of refiner.items; let i = index">
                                <p-radioButton name="sortby" class="kz-radio-button radio-fluid {{radioClass}}"
                                    [label]="item?.text === 'Titles to Recommend' ? 'Purchase Requests' :item.text"
                                    id="loc_refiners_{{item.text}}"
                                    [ngClass]="{'category-text': item.text === 'eBooks' || 'eAudio' }"
                                    [value]="item.value" [(ngModel)]="refiner.selected"
                                    (onClick)="studyTitlesRefinerChange(item, j, true)" [inputId]="'sortby'+j+i"
                                    *ngIf="item?.text !== ''" name="type{{j}}" axis360PradioAda isRevers="true"
                                    ></p-radioButton>
                            </ng-container>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
            </mat-accordion>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="searchService.showLeaningActivityRefiner && showRefinerBlock()">
        <ng-container *ngFor="let refiner of activityRefiner; let j = index">
            <mat-accordion class="kz-checkbox-sec refiners-accordion" *ngIf="refiner.refiner === 'sortBy'">
                <mat-expansion-panel class="refiners-expansion-panel" [expanded]="state === j" (opened)="setState(j)"
                    (closed)="refinerscroll()">
                    <mat-expansion-panel-header class="refiners-panel-header" [expandedHeight]="customExpandedHeight">
                        <mat-panel-title class="heading-3">
                            <div class="content-assign content-read">
                                <div class="content-name-head">
                                    <h2 class="refiners-title-secondary single-ellipsis text-capitalize">
                                        {{refiner.refiner === 'activityTypes' ? 'Resource Type' : refiner.refiner}}
                                    </h2>
                                    <p class="sub-title-txt single-ellipsis" axis360EllipsisTitle>
                                        {{getSubTitleSearch(refiner)}}
                                    </p>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-content">
                        <div class="radio-group">
                            <ng-container *ngFor="let limiter of refiner.filters; let i = index">
                                <p-radioButton name="learning-refiner" class="kz-radio-button radio-fluid"
                                    [label]="limiter.text" id="loc_refiners_{{limiter.text}}"
                                    *ngIf="refiner.refiner === 'sortBy'" [value]="limiter.value"
                                    [(ngModel)]="refiner.selectedFilter"
                                    (onClick)="activityRefinerChange(limiter,true,refiner)"
                                    [inputId]="'learning-refiner'+j+i" name="type{{j}}" axis360PradioAda isRevers="true"
                                    ></p-radioButton>
                            </ng-container>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
            </mat-accordion>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isLearningActivity">
        <mat-accordion class="kz-checkbox-sec refiners-accordion"
            *ngFor="let refiner of learningActivityRefiners; let j = index">
            <mat-expansion-panel class="refiners-expansion-panel" [expanded]="state === j" (opened)="setState(j)"
                *ngIf="refiner.key === 'sortBy'">
                <mat-expansion-panel-header class="refiners-panel-header" (click)="trackLearningAnalytics(refiner)"
                    [expandedHeight]="customExpandedHeight">
                    <mat-panel-title class="heading-3 refiner-panel-title">
                        <div class="content-assign content-read">
                            <div class="content-name-head">
                                <h2 class="refiners-title single-ellipsis text-uppercase">
                                    {{refiner.title}}
                                </h2>
                                <p class="sub-title-txt single-ellipsis" axis360EllipsisTitle>
                                    {{getSubTitle(refiner)}}</p>
                            </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="accordion-content">
                    <div class="radio-group">
                        <ng-container *ngFor="let option of refiner.subOptions; let i = index">
                            <p-radioButton name="LearningActivity" class="kz-radio-button radio-fluid" [label]="option"
                                [value]="option" id="loc_refiners_{{refiner.key}}_{{i}}" [name]="refiner.key"
                                [(ngModel)]="refiner.selectedItem" *ngIf="refiner.key === 'sortBy'"
                                (onClick)="setSelectedRefiner(true,refiner.key,refiner.selectedItem, true, true)"
                                axis360PradioAda isRevers="true" [inputId]="'LearningActivity'+j+i">
                            </p-radioButton>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
        </mat-accordion>
    </ng-container>
    <div class="refine-filter-head" *ngIf="searchService.selectedCategory === category.ALL || showRefinerBlock()">
        <h2 class="refiners-title single-ellipsis text-uppercase">Refine</h2>
        <button *ngIf="(isLearningActivity) && !showAccordion" [class.disabled]="disableReset"
            class="anchor-link btn-nobg-bluecolor" (click)="clearFilter()" (keyup.enter)="clearFilter()" [disabled]="disableReset">Reset</button>
    </div>
    <p class="refiners-sub-title" *ngIf="!isLearningActivity && searchService.selectedCategory === category.ALL">
        Please choose a format to refine your search.</p>
    <p class="kz-visually-hidden" *ngIf="isLoadAnnouncer && !isLearningActivity" role="alert"> Loading</p>
    <div class="refine-section" [ngClass]="{'learning-refines' : !isLearningActivity}" *ngIf="!showAccordionThirdParty"
        id="loc_search_list_refiner">
        <ng-container *ngIf="isLearningActivity">
            <mat-accordion class="kz-checkbox-sec refiners-accordion">
                <ng-container *ngFor="let refiner of learningActivityRefiners; let j = index">
                    <mat-expansion-panel class="refiners-expansion-panel" [expanded]="state === j" (opened)="setState(j)"
                        *ngIf="refiner.key !== 'sortBy'">
                        <mat-expansion-panel-header class="refiners-panel-header" (click)="trackLearningAnalytics(refiner)"
                            [expandedHeight]="customExpandedHeight">
                            <mat-panel-title class="heading-3 refiner-panel-title">
                                <div class="content-assign content-read">
                                    <div class="content-name-head">
                                        <h2 class="refiners-title-secondary single-ellipsis text-capitalize">
                                            {{refiner.title}}
                                        </h2>
                                        <p class="sub-title-txt single-ellipsis" axis360EllipsisTitle>
                                            {{getSubTitle(refiner)}}</p>
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="accordion-content">
                            <div class="radio-group" [ngClass]="{'check-group': refiner.key !== 'sortBy'}">
                                <ng-container *ngFor="let option of refiner.subOptions; let i = index">
                                    <mat-checkbox axis360MatCheckboxAda ngDefaultControl class="kz-radio-button check-button"
                                        [name]="refiner.key" [value]="option"
                                        [checked]="refiner.selectedItem.indexOf(option) > -1"
                                        *ngIf="refiner.key !== 'sortBy' && option !== 'All'"
                                        (change)="setSelectedRefiner($event.checked, refiner.key,option, true, true)">
                                        {{option}}
                                    </mat-checkbox>
                                    <p-radioButton name="LearningActivity" class="kz-radio-button radio-fluid" [label]="option"
                                        [value]="option" id="loc_refiners_{{refiner.key}}_{{i}}" [name]="refiner.key"
                                        [(ngModel)]="refiner.selectedItem" *ngIf="refiner.key === 'sortBy'"
                                        (onClick)="setSelectedRefiner(true,refiner.key,refiner.selectedItem, true, true)"
                                        axis360PradioAda isRevers="true" [inputId]="'LearningActivity'+j+i">
                                    </p-radioButton>
                                </ng-container>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
                </ng-container>
            </mat-accordion>
        </ng-container>
        <ng-container *ngIf="(searchService.showEaudioRefiner || searchService.showEbookRefiner) && showRefinerBlock()">
            <mat-accordion class="kz-checkbox-sec refiners-accordion" *ngFor="let refiner of refiners; let j = index">
                <mat-expansion-panel class="refiners-expansion-panel" *ngIf="refiner.value !== 'sortby'"
                    [expanded]="state === j" (opened)="setState(j)" (closed)="refinerscroll()">
                    <mat-expansion-panel-header class="refiners-panel-header" [expandedHeight]="customExpandedHeight">
                        <mat-panel-title class="heading-3">
                            <div class="content-assign content-read">
                                <div class="content-name-head">
                                    <h2 class="refiners-title-secondary single-ellipsis text-capitalize">
                                        {{refiner.text}}
                                    </h2>
                                    <p class="sub-title-txt">
                                        {{refiner?.selectedText === 'Titles to Recommend' ? 'Purchase Requests' :
                                        refiner.selectedText}}</p>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="accordion-content">
                        <div class="radio-group kz-radio-btn-group">
                            <ng-container *ngFor="let season of refiner.items; let i = index">
                                <p-radioButton name="eaudio-ebook-refiner"
                                    class="kz-radio-button radio-fluid {{radioClass}}"
                                    [label]="season?.text === 'Titles to Recommend' ? 'Purchase Requests' : (refiner.text === 'Age Level' ? replaceHyphenToEndash(season.text) : season.text)"
                                    id="loc_refiners_{{season.text}}"
                                    [ngClass]="{'category-text': season.text === 'eBooks' || 'eAudio' }"
                                    [value]="season.value" [(ngModel)]="refiner.selected"
                                    (onClick)="studyTitlesRefinerChange(season, j, true)"
                                    [inputId]="'eaudio-ebook-refiner'+j+i" *ngIf="season?.text !== ''" name="type{{j}}"
                                    axis360PradioAda isRevers="true"></p-radioButton>
                            </ng-container>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
            </mat-accordion>
        </ng-container>
        <ng-container *ngIf="searchService.showSitesRefiner && showRefinerBlock()">
            <mat-accordion class="kz-checkbox-sec refiners-accordion"
                *ngFor="let siteRefiner of availableLimiters; let j = index">
                <mat-expansion-panel class="refiners-expansion-panel" [expanded]="state === j" (opened)="setState(j)"
                    (closed)="refinerscroll()">
                    <mat-expansion-panel-header class="refiners-panel-header" [expandedHeight]="customExpandedHeight">
                        <mat-panel-title class="heading-3">
                            <div class="content-assign content-read">
                                <div class="content-name-head">
                                    <h2 class="refiners-title-secondary single-ellipsis text-capitalize">
                                        {{siteRefiner.title}}
                                    </h2>
                                    <p class="sub-title-txt">
                                        {{siteRefiner.selectedFacets}}</p>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-content">
                        <div class="radio-group kz-radio-btn-group">
                            <ng-container *ngFor="let limiter of siteRefiner.facets; let i = index">
                                <p-radioButton name="sites-refiner" class="kz-radio-button radio-fluid"
                                    [ngClass]="{'domain': siteRefiner.title === 'Domain'}" [label]="limiter.text" id="loc_refiner_{{limiter.text}}"
                                    [value]="limiter.value" isWebSite="true" [isWebTitle]="siteRefiner.title" [(ngModel)]="siteRefiner.selectedFacets"
                                    (onClick)="applyLimiter(siteRefiner.title, limiter, siteRefiner.title !== 'Country', true)"
                                    [inputId]="'sites-refiner'+j+i" name="type{{j}}" axis360PradioAda isRevers="true"></p-radioButton>
                            </ng-container>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
            </mat-accordion>
        </ng-container>
        <ng-container *ngIf="searchService.showLeaningActivityRefiner  && showRefinerBlock()">
            <mat-accordion class="kz-checkbox-sec refiners-accordion" multi
                *ngFor="let refiner of activityRefiner; let j = index">
                <mat-expansion-panel class="refiners-expansion-panel" [expanded]="state === j" (opened)="setState(j)"
                    (closed)="refinerscroll()" *ngIf="refiner.refiner !== 'sortBy'">
                    <mat-expansion-panel-header class="refiners-panel-header" [expandedHeight]="customExpandedHeight">
                        <mat-panel-title class="heading-3">
                            <div class="content-assign content-read">
                                <div class="content-name-head">
                                    <h2 class="refiners-title-secondary single-ellipsis text-capitalize">
                                        {{getResourcesTitle(refiner)}}
                                    </h2>
                                    <p class="sub-title-txt single-ellipsis" axis360EllipsisTitle>
                                        {{getSubTitleSearch(refiner)}}
                                    </p>
                                </div>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="accordion-content">
                        <div class="radio-group" [ngClass]="{'check-group': refiner.refiner !== 'sortBy'}" role="list">
                            <ng-container *ngFor="let limiter of refiner.filters; let i = index">
                                <mat-checkbox axis360MatCheckboxAda class="kz-radio-button check-button" role="listitem"
                                    name="type{{j}}" [value]="limiter.value" [checked]="limiter.isSelected"
                                    (change)="activityRefinerChange(limiter,false,refiner)"
                                    *ngIf="refiner.refiner !== 'sortBy' && limiter.value !== 'All'">
                                    {{(refiner.refiner === 'activityTypes')? ((limiter.value ===
                                    'All')?'All':limiter.value):(limiter.value === 'All')?'All':limiter.value}}
                                </mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-divider aria-hidden="true" class="kz-divider" *ngIf="refiner.refiner !== 'sortBy'"></mat-divider>
            </mat-accordion>
        </ng-container>
    </div>
    <div class="action-buttons btn-group" *ngIf="searchService.selectedCategory !== category.ALL && showRefinerBlock()">
        <button mat-raised-button class="btn-secondary-nobg action-btn" [class.disabled]="disableReset"
            [disabled]="disableReset" (click)="clearFilter()">Reset</button>
        <button mat-raised-button id="loc_search_filter" class="btn-primary-blue action-btn" (click)="searchFilters()">Search</button>
    </div>
    <div class="action-buttons btn-group" *ngIf="isLearningActivity && showAccordion">
        <button mat-raised-button class="btn-secondary-nobg action-btn"  (click)="clearFilter()">Reset</button>
        <button mat-raised-button class="btn-primary-blue action-btn" (click)="searchFilters()">View Results</button>
    </div>
</div>
