<mat-card class="program-card" [attr.role]="cardRole" tabindex="0" (keydown.enter)="programCardClickEvent.emit(this.program)"
    (click)="programCardClickEvent.emit(this.program)">
    <mat-card-header>
        <img src="/angular-app/assets/images/reading-program.svg" alt="" aria-hidden="true" tabindex="-1">
        <mat-card-title class="card-title" attr.aria-label="Reading Program: Read by {{program.readByDateText}}">Reading Program: Read by {{program.readByDateText}}
        </mat-card-title>
        <mat-card-subtitle class="card-subtitle" title="{{program.programName}}" [attr.aria-label]="program.programName">{{program.programName}}</mat-card-subtitle>
    </mat-card-header>

    <div class="card-images-sec" [ngClass]="{'center-img': !program.totalParticipants}">
        <div>
            <axis360-programs-card-images [cardImgs]="program.cardImgs" class="card-images-com">
            </axis360-programs-card-images>
        </div>

        <div *ngIf="program.totalParticipants">
            <span class="count-value" [attr.aria-label]="program.totalParticipants">{{program.totalParticipants}}</span>
            <span class="count-label" aria-label="Number of Participants">Number of <br>Participants</span>
        </div>
    </div>
    <mat-card-content class="card-details">
        <p *ngIf="program.description" title="{{program.description}}" [attr.aria-label]="program.description">
            {{program.description}}
        </p>
    </mat-card-content>

</mat-card>