<div class="kz-goal-modal kz-common" *ngIf="goalData">
    <mat-dialog-content class="mat-typography">
        <button mat-dialog-close class="close-goal" aria-label="close" id="loc_BtnCloseGoal" (click)="closeDialog()"
            (keydown.enter)="closeDialog()">
            <mat-icon class=" icon-keyboard" svgIcon="kz-close"></mat-icon>
        </button>
        <div class="goal-modal-body">
            <h1 class="goal-modal-header" id="{{goalData.modalId}}">
                {{goalData.goalHeading}}
            </h1>
            <p class="goal-modal-info" id="{{goalData?.modalDesID}}">
                {{goalData.content}}
            </p>
            <form class="kz-create-form" [formGroup]="goalForm">
                <div class="kz-rprt-txtara kz-rm-bottom kz-add-form">
                    <mat-form-field appearance="fill" class="kz-create-text">
                        <mat-label aria-hidden="true" class="kz-txt-lble">{{goalData.goalInputTitle}}
                        </mat-label>
                        <input attr.aria-label="{{goalData.goalInputTitle}}" ngDefaultControl matInput id="goal-input" name="goal-input"
                            (keypress)=restrict($event) type="text" inputmode="numeric" pattern="[0-9]*"
                            [min]="minValue" step=1 [max]="" formControlName="goalValue" required autocomplete="off"
                            (keydown)="goalInputKeyDown($event)" (keyup.enter)="setGoal()" >
                        <mat-error aria-live="polite" *ngIf="checkError('goalValue','goal-input')"> Please
                            enter a whole
                            number between {{minValue}}
                            and {{goalData.max}}.</mat-error>
                    </mat-form-field>
                    <p class="goal-disclaimer">* Metrics are based on digital content only</p>
                </div>
            </form>
            <div mat-dialog-actions class="goal-modal-footer doneBtn">
                <button mat-button type="button" aria-label="Set Goal" class="submit-btn active" id="loc_BtnSetGoal"
                    (keyup.enter)="setGoal()" (click)="setGoal()">Set
                    Goal</button>
                <button *ngIf="goalData.goal" aria-label="Remove Goal" mat-button type="button" id="loc_BtnRemoveGoal"
                    (keyup.enter)="setGoal(true)" (click)="setGoal(true)" class="cancel-btn">Remove
                    Goal</button>
            </div>
        </div>
    </mat-dialog-content>
</div>