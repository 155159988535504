export const USER_NAME = 'Username';
export const LIBRARY_CARD_ID = 'Library Card ID';
export const PASSWORD = 'Password';
export const PIN = 'PIN';
export const FAIL_MSG = 'Unable to sendout email currently';
export const MATCH_FAIL_MSG = 'Answer does not match';
export const EMAIL_FAIL_MSG = 'Please contact the library to set password';
export const FAIL = 'FAIL';
export const MATCH_FAIL = 'MatchFail';
export const EMAIL_FAIL = 'EmailFail';
export const SUCCESS = 'SUCCESS';
export const SKIP_PATRONPIN = 'SKIP_PATRONPIN';
export const AUTH_PASSWORD = 'PASSWORD';
export const LOGIN = 'Login';
export const REGISTER = 'Register'
export const FORGOT = 'Forgot'
export const RECOVERY_PASS = 'Recovery'
export const SECURITY_QUESTION = 'Security Question'
export const SECURITYANSWER_ERROR_MSG = {
  [FAIL]: FAIL_MSG,
  [MATCH_FAIL]: MATCH_FAIL_MSG,
  [EMAIL_FAIL]: EMAIL_FAIL
};
export const AGE_RANGE = {
  'Adult 18+': '1',
  'Teen 13-17': '2',
  'Kid 0-12': '3'
};
export const EMAIL = 'Email';
