import { KzLearningActivitiesComponent } from './kz-learning-activities/kz-learning-activities.component';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InterestSurveyComponent } from '@home/interest-survey/interest-survey.component';
import { MainComponent } from '@main/main.component';
import { PressReaderDetailsComponent } from '@pressreader/press-reader-details/press-reader-details.component';
import { PressReaderLandingComponent } from '@pressreader/press-reader-landing/press-reader-landing.component';
import { PressReaderListComponent } from '@pressreader/press-reader-list/press-reader-list.component';
import { PressReaderComponent } from '@pressreader/press-reader.component';
import { ProgramDetailsComponent } from '@readingprograms/program-details/program-details.component';
import { ProgramsCardsComponent } from '@readingprograms/programs/programs-cards/programs-cards.component';
import { ProgramsComponent } from '@readingprograms/programs/programs.component';
import { NewsResultsComponent } from '@search/news-results/news-results.component';
import { SearchResultsComponent } from '@search/search-results.component';
import { SitesResultsComponent } from '@search/sites-results/sites-results.component';
import { MyLibraryMainComponent } from './core/my-library/my-library-main.component';
import { MyShelfMainComponent } from './core/my-shelf/my-shelf.main.component';
import { NgMainComponent } from './core/ng-main/ng-main.component';
import { TitleDetailsComponent } from './core/title-details/title-details.component';
import { KzInterestSurveyComponent } from './kz-interest-survey/kz-interest-survey.component';
import { KzSearchListViewComponent } from './core/kz-search-results/kz-search-list-view/kz-search-list-view.component';
import { ThirdPartyTierOneComponent } from './third-party/third-party-tier-one/third-party-tier-one.component';
import { ThirdPartyTierThreeComponent } from './third-party/third-party-tier-three/third-party-tier-three.component';
import { ThirdPartyTierTwoComponent } from './third-party/third-party-tier-two/third-party-tier-two.component';
import { ThirdPartyComponent } from './third-party/third-party.component';
import { TermsConditionsComponent } from './core/terms-conditions/terms-conditions.component';
import { MyShelfComponent } from './core/my-shelf/my-shelf/my-shelf.component';
import { MyLibraryComponent } from './core/my-library/my-library/my-library.component';
import { TitleListMainComponent } from './core/title-list/title-list-main.component';
import { TitleListComponent } from './core/title-list/titlle-list/title-list.component';
import { KzSearchResultsMainComponent } from './core/kz-search-results/kz-search-results-main.component';
import { KzSearchResultsComponent } from './core/kz-search-results/kz-search-results.component';
import { KzSearchListViewMainComponent } from './core/kz-search-results/kz-search-list-view-main/kz-search-list-view-main.component';
import { ThirdPartyTierTwoMainComponent } from './third-party/third-party-tier-two-main/third-party-tier-two-main.component';
import { LibraryInvalidComponent } from '@shared/components/library-invalid/library-invalid.component';
import { MigratedInvalidComponent } from '@shared/components/migrated-invalid/migrated-invalid.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPInterceptor } from '@shared/http.interceptor';
import { PatronMustLoginComponent } from './core/account/patron-must-login/patron-must-login.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'search',
        component: SearchResultsComponent,
        children: [
          {
            path: 'sites',
            component: SitesResultsComponent
          },
          {
            path: 'news',
            component: NewsResultsComponent
          }
        ]
      },
      {
        path: 'programs',
        component: ProgramsComponent,
        children: [
          {
            path: '',
            component: ProgramsCardsComponent
          },
          {
            path: 'open',
            component: ProgramsCardsComponent
          },
          {
            path: 'open/:programId',
            component: ProgramsCardsComponent
          }
        ]
      },
      {
        path: 'program/:programId',
        component: ProgramDetailsComponent
      },
      {
        path: 'pressreader',
        component: PressReaderComponent,
        children: [
          {
            path: '',
            component: PressReaderLandingComponent
          },
          {
            path: 'list',
            component: PressReaderListComponent
          },
          {
            path: 'details/:publicationId',
            component: PressReaderDetailsComponent
          }
        ]
      },
      {
        path: 'interestsurvey',
        component: InterestSurveyComponent
      },

      // profile
      {
        path: 'profiles',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        data: {
          baseHref: ''
        }
      }
    ]
  },
  {
    path: 'view',
    component: NgMainComponent,
    data: { breadcrumb: 'Library' },

    children: [
      { path: '', redirectTo: 'library', pathMatch: 'full' },
      {
        path: 'myshelf',
        component: MyShelfMainComponent,
        data: { breadcrumb: 'My Shelf' },

        children: [
          { path: '', component: MyShelfComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'title/:itemId/', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'mystuff',
            data: { breadcrumb: 'My Stuff' },
            loadChildren: () => import('./my-stuff/my-stuff.module').then((m) => m.MyStuffModule)
          }
        ]
      },
      {
        path: 'library',
        component: MyLibraryMainComponent,
        data: { breadcrumb: { skip: true } },

        children: [
          { path: '', component: MyLibraryComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } }
          },
          {
            path: 'title/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },

          },
          {
            path: 'content',
            component: ThirdPartyComponent,
            data: { breadcrumb: { alias: 'content' } },
            children: [
              {
                path: '',
                component: ThirdPartyTierOneComponent,
              },
              {
                path: 'list',
                component: ThirdPartyTierTwoMainComponent,
                data: { breadcrumb: { alias: 'contentlist' } },

                children: [
                  {
                    path: 'details/:id',
                    component: ThirdPartyTierThreeComponent,
                    data: { breadcrumb: { info: 'titledetail' } },
                  },
                  {
                    path: '',
                    component: ThirdPartyTierTwoComponent
                  },
                ]
              },
              {
                path: 'details/:id',
                component: ThirdPartyTierThreeComponent,
                data: { breadcrumb: { info: 'titledetail' } },

              }
            ]
          },
        ]
      },
      {
        path: 'mystuff',
        loadChildren: () => import('./my-stuff/my-stuff.module').then((m) => m.MyStuffModule)
      },
      {
        path: 'programs',
        loadChildren: () => import('./core/kz-reading-programs/kz-programs/kz-programs.module').then((p) => p.KzProgramsModule)
      },
      {
        path: 'title',
        component: TitleListMainComponent,
        data: { breadcrumb: { alias: 'subject' } },
        children: [
          { path: '', component: TitleListComponent },
          {
            path: ':subject/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: ':subject/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
        ]
      },
      { path: 'title/:itemId', component: TitleDetailsComponent },
      { path: 'title/:itemId/:isbn', component: TitleDetailsComponent },
      { path: 'interestsurvey/:type', component: KzInterestSurveyComponent },
      {
        path: 'content',
        component: ThirdPartyComponent,
        data: { breadcrumb: { alias: 'content' } },
        children: [
          {
            path: '',
            component: ThirdPartyTierOneComponent,
          },
          {
            path: 'list',
            component: ThirdPartyTierTwoMainComponent,
            data: { breadcrumb: { alias: 'contentlist' } },

            children: [
              {
                path: 'details/:id',
                component: ThirdPartyTierThreeComponent,
                data: { breadcrumb: { info: 'titledetail' } },
              },
              {
                path: '',
                component: ThirdPartyTierTwoComponent
              },
            ]
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },

          }
        ]
      },
      {
        path: 'profiles',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        data: {
          baseHref: 'view'
        }
      },
      {
        path: 'search',
        component: KzSearchResultsMainComponent,
        data: { breadcrumb: { alias: 'searchtext' } },
        children: [
          { path: '', component: KzSearchResultsComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'title/:itemId', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'content',
            component: ThirdPartyComponent,
            children: [
              {
                path: '',
                component: ThirdPartyTierOneComponent,
                data: { breadcrumb: { alias: 'content' } },
              },
              {
                path: 'list',
                component: ThirdPartyTierTwoMainComponent,
                data: { breadcrumb: { alias: 'contentlist' } },

                children: [
                  {
                    path: 'details/:id',
                    component: ThirdPartyTierThreeComponent,
                    data: { breadcrumb: { info: 'titledetail' } },
                  },
                  {
                    path: '',
                    component: ThirdPartyTierTwoComponent
                  },
                ]
              },
              {
                path: 'details/:id',
                component: ThirdPartyTierThreeComponent,
                data: { breadcrumb: { info: 'titledetail' } },

              }
            ]
          },

        ]
      },
      {
        path: 'search/:format',
        component: KzSearchListViewMainComponent,
        data: { breadcrumb: { alias: 'searchformat' } },

        children: [
          {
            path: '',
            component: KzSearchListViewComponent
          },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'details/:id',
            component: ThirdPartyTierThreeComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          { path: 'title/:itemId', component: TitleDetailsComponent },
          {
            path: 'content',
            component: ThirdPartyComponent,
            data: { breadcrumb: { alias: 'content' } },
            children: [
              {
                path: '',
                component: ThirdPartyTierOneComponent,
              },
              {
                path: 'list',
                component: ThirdPartyTierTwoMainComponent,
                data: { breadcrumb: { alias: 'contentlist' } },

                children: [
                  {
                    path: 'details/:id',
                    component: ThirdPartyTierThreeComponent,
                    data: { breadcrumb: { info: 'titledetail' } },
                  },
                  {
                    path: '',
                    component: ThirdPartyTierTwoComponent
                  },
                ]
              },
              {
                path: 'details/:id',
                component: ThirdPartyTierThreeComponent,
                data: { breadcrumb: { info: 'titledetail' } },

              }
            ]
          },
        ]
      },
      {
        path: 'title/detail/:itemId/:isbn', component: TitleDetailsComponent,
        data: { breadcrumb: { info: 'titledetail' } },
      },
      {
        path: 'sharable/details/:id',
        component: ThirdPartyTierThreeComponent,
        data: { breadcrumb: { info: 'titledetail' } },

      },
      { path: 'sharable/title/:itemId/:isbn', component: TitleDetailsComponent },
      { path: 'title/:itemId', component: TitleDetailsComponent },
      { path: 'learning-activity', component: KzLearningActivitiesComponent },
      { path: 'learning-activity/title/:itemId/:isbn', component: TitleDetailsComponent },
      { path: 'notification/title/:itemId/:isbn', component: TitleDetailsComponent },
      { path: 'terms', component: TermsConditionsComponent }
    ]
  },
  { path: 'login', component: PatronMustLoginComponent },
  { path: 'library-Invalid', component: LibraryInvalidComponent },
  { path: 'migration-Invalid', component: MigratedInvalidComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' },
  {
    provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true
  },
  ]
})
export class AppRoutingModule { }
