<div class="join-program" *ngIf="!joinedProgram">
    <div class="join-program-sec">
        <button class="join-close" type="button" aria-label="close" (click)="closeDialog()">
            <mat-icon aria-hidden="false">close</mat-icon>
        </button>
        <div class="join-pro-details-sec">
            <div class="join-pro-details-head">
                <img src="/angular-app/assets/images/reading-program.svg" alt="" aria-hidden="true"/>
                <h2 id="Join-program">Reading Program</h2>
                <h3>{{programData.name}}</h3>
            </div>
            <div class="join-pro-details-date">
                <div class="display-date">
                    <h4>START DATE</h4>
                    <p>{{programData.startDate}}</p>
                </div>
                <div class="display-date">
                    <h4>END DATE</h4>
                    <p>{{programData.endDate}}</p>
                </div>
                <div class="display-date">
                    <h4>READ</h4>
                    <p role="text" attr.aria-label="{{programData.typeText}}">{{programData.typeText}}</p>
                </div>
            </div>
            <div class="join-pro-desc">
                <p *ngIf="programData.description">{{programData.description}}</p>
            </div>
        </div>
        <axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>
        <div class="join-pro-reading-list" *ngIf="programBooks.length > 0">
            <h2>Reading List ({{programBooks.length}})</h2>
            <div class="reading-list">
                <ng-container *ngFor="let book of programBooks">
                    <ng-container *ngIf="book.itemId">
                        <mat-card class="reader-poster">
                            <a href="/Title/TitleDetails?itemId={{book.itemId}}&ISBN={{book.isbn}}&isRecommendable={{
                                book.isRecommendable
                            }}&collectionType={{book.isRecommendable || 'Default'}}">
                                <img class="poster-main-img"
                                    [src]="book.imageUrl" [alt]="book.title" attr.id="title-img-{{book.itemId}}"
                                    attr.aria-label="Book type is {{getIcon(book.formatType).alt}} ,title is {{book.title}}, and author is {{book.author}}, {{book.titleStateInfo?.displayStatus}}">
                                <div class="book-poster" [ngClass]="{'in':(book.isAvailable && book.isRTV),'out':!(book.isAvailable && book.isRTV)}">
                                    <mat-icon [svgIcon]="getIcon(book.formatType).icon"></mat-icon>
                                </div>
                            </a>
                            <div class="poster-detail">
                                <div class="poster-author" aria-hidden="true">
                                    <p class="title-name">{{book.title}}</p>
                                    <p class="author-name">{{book.author}}</p>
                                </div>
                            </div>
                            <div>
                                <axis360-title-action-buttons (menuStateChangeEvent)="menuStateChangeEvent($event)" [bookDetail]="book" [isProgressCalculate]="false"></axis360-title-action-buttons>
                            </div>
                        </mat-card>
                    </ng-container>
                    <ng-container *ngIf="!book.itemId">
                        <mat-card class="reader-poster hidden-title">
                            <mat-icon aria-hidden="false" role="button" aria-label="Title info" matTooltip="This title is no longer available.">info_outline</mat-icon>
                            <div class="hidden-title-img"></div>
                        </mat-card>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="stickyBtnSec">
        <button (click)="joinProgram()">Join Program</button>
    </div>
</div>
<div class="joined-program" *ngIf="joinedProgram">
    <button class="join-close" (click)="closeWelcomeModal()" aria-label="Close welcome program" axis360TrapFocus>
        <mat-icon aria-pressed="false">close</mat-icon>
    </button>
    <div class="joined-pro-sec">
        <h1>Welcome to <span>{{programData.name}}</span></h1>
        <mat-icon svgIcon="JOINED_PROGRAM" class="join-pro-img"></mat-icon>
        <div class="joined-action-sec">
            <button class="gotobtn" type="button" (click)="goToProgram()">Let's Get Started!</button>
        </div>
    </div>
</div>