import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { ConfigService } from '@shared/services/config.service';
import { MainService } from '@shared/services/main.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { Observable } from 'rxjs';
import { NOTIFICATIONS_PAGE_SIZE } from '../constants/notifications.constants';
import { MessagesResponse, UnreadMsgsCountRes, UpdateMsgsRequest, UpdateMsgsResponse } from '../data/notifications.model';
@Injectable()
export class NotificationsService {
  totalUnreadCount: string | number;
  kzTotalUnreadCount: string | number;
  kzActualTotalUnreadCount: string | number;
  constructor(
    private http: HttpClient,
    public userService: UserService,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public mainService: MainService
  ) {}
  /**
   * Get notifications
   * @param pageIndex page number to implement pagination
   * @returns notifications
   */
  getMessages(pageIndex: number): Observable<MessagesResponse> {
    return this.http.get<MessagesResponse>(
      API_INFO.getNotifications.replace('<pageSize>', `${NOTIFICATIONS_PAGE_SIZE}`).replace('<pageIndex>', `${pageIndex}`)
    );
  }
  /**
   * Update the notification
   * @param formData notification info
   * @returns response from update notification API
   */
  updateMessages(formData: UpdateMsgsRequest): Observable<UpdateMsgsResponse> {
    return this.http.put<UpdateMsgsResponse>(API_INFO.updateNotifications, formData);
  }
  /**
   * To get count of un read notifications
   * @returns count of un read notifications
   */
  getUnreadMessagesCount(): Observable<UnreadMsgsCountRes> {
    return this.http.get<UnreadMsgsCountRes>(API_INFO.getUnreadNotificationsCount
      .replace('<profileType>', this.profileService.currentProfile.profileType)
      );
  }
  /**
   * To update the message counts
   */
  updateMessagesCount = (): void => {
    this.getUnreadMessagesCount().subscribe(
      (response) => {
        if (response.message !== 'Success') {
          this.totalUnreadCount = '';
          this.kzTotalUnreadCount = '';
          return;
        }
        this.totalUnreadCount =
          response.unreadMessageresponse.totalUnreadCount > 99 ? '99+' : response.unreadMessageresponse.totalUnreadCount;
          const totalUnreadMessages = response.unreadMessageresponse.totalUnreadCount;
          const userNotifications = this.userService.userInfo.notifications;
          const totalCount = totalUnreadMessages + (userNotifications !== null ? userNotifications.length : 0);
          this.kzTotalUnreadCount = totalCount > 99 ? '99+' : totalCount;
        this.kzActualTotalUnreadCount = response.unreadMessageresponse.totalUnreadCount + this.userService.userInfo.notifications?.length;
      },
      () => {
        this.totalUnreadCount = '';
        this.kzTotalUnreadCount = '';
      }
    );
  };
}
