// import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '@home/shared/services/home.service';
import { PROFILES_ALERTS } from '@profile/shared/constants/profiles-alerts.constants';
import {
  KZ_INTEREST_SURVEY_TYPE_PROFILE,
  PROFILES_MSGS,
  PROFILE_ACTION,
  PROFILE_NAVIGATION,
  PROFILE_TYPES,
  WHITE_SPACE_REGEX,
  VENDER_CODE_LIST,
  REDIRECT_URL,
  PROFILE_TYPES_UI_MAP
} from '@profile/shared/constants/profiles.constants';
import { GetProfileByIdResponse, ProfileDetail, ProfileType, UpdateProfileRequest } from '@profile/shared/data/profiles.model';
import { ProfilesAnalyticsService } from '@profile/shared/services/profiles.analytics.service';
import { ProfilesService } from '@profile/shared/services/profiles.service';
import { DEFAULT_SUCCESS_MESSAGE, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import {
  cdkRemoveVisualHidden,
  cdkVisualHidden,
  deepClone,
  focusElement,
  forceRedirect,
  getBackgroundImageResize,
  getYesOrNo,
  positionFooter,
  setElemAttribute,
  strToBool
} from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { SharedService } from '@shared/services/shared.service';
import { CurrentLibrary } from '@shared/data/config.model';
import { IProfileInfo, ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { UserService } from '@shared/services/user.service';
import { noop } from 'rxjs';
import { SpinnerService } from '@shared/services/spinner.service';
import { PROFILES_API_INFO, PROFILE } from '@profile/shared/constants/profiles.api.constants';
import { MatDialog } from '@angular/material/dialog';
import { ParentalPinComponent } from '@profile/parental-pin/parental-pin.component';
import { SIDE_NAV_URL } from 'app/header/shared/constants/header.constants';
import { CommonService } from '@shared/services/common.service';
import { MainService } from '@shared/services/main.service';
import {
  BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT,
  BACK_ROUNDIMAGE_KID_PROFILE_EDIT,
  BACK_ROUNDIMAGE_TEEN_PROFILE_EDIT
} from '@shared/constants/search.constant';
const EMAIL_PATTERN =
  /^([a-zA-Z0-9_.\-]+)@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[\d]{1,3})(\]?)$/;
@Component({
  selector: 'axis360-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit, OnDestroy {
  currentProfile: IProfileInfo = {};
  currentLibrary: Partial<CurrentLibrary>;
  @ViewChild('securityQuestion') securityQuestion: MatSelect;
  type = '';
  profileId = '';
  patronProfileData: Partial<GetProfileByIdResponse> = { profile: {} };
  isPrimary = false;
  isAdult = false;
  isFrom = PROFILE;
  editProfileForm: FormGroup;
  isLoading = true;
  avatar?: string;
  isAPIFailed = false;
  profileTypes: ProfileType[] = [];
  typeForProfileImage = '';
  previousProfileImage = '';
  myShelfDefaultLandingPage = false;
  checkoutHistory = false;
  enableTracking = false;
  isShowInterests = false;
  showInsightsAndBadges = false;
  highContrast = false;
  isDisableProfileTypes = false;
  forceRedirect = forceRedirect;
  showMyShelf = false;
  isAvatarChanged = false;
  baseHref = '';
  libraryCardId;
  venderCodeList = [];
  checkoutText = 'eBook';
  isEnablePin = false;
  profiles: ProfileDetail[] = [];
  pinStatusToggle;
  isEmailEnabled: boolean;
  universalPINFlag = false;
  sameAsAdultEmail = false;
  preValues: string;
  enableSave = false;
  isShowDisablePinAlert = false;
  isAvatar = false;
  emailRequired = false;
  isPinAutoClose = false;
  backgroundImageUrl: string;
  isMenu;
  setElemAttribute = setElemAttribute;
  focusElement = focusElement;
  isCreateProfile = false;
  isShowSecurityQuestion: boolean;
  patronType;
  patronTypeAuthentication: boolean;
  videoText: string;
  tempPreviousUrl: string;
  avatarName = '';
  selectedProfileType = '';
  pageAnnounce = '';
  setProfileTypeByPatronProfile = '';
  constructor(
    // private liveAnnouncer: LiveAnnouncer,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public profilesService: ProfilesService,
    public profilesAnalyticsService: ProfilesAnalyticsService,
    public userService: UserService,
    private snackBarSerice: SnackBarService,
    private formBuilder: FormBuilder,
    public location: Location,
    public homeService: HomeService,
    public sharedService: SharedService,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    public spinnerService: SpinnerService,
    public matDialog: MatDialog,
    public commonService: CommonService,
    public mainService: MainService
  ) {
  }
  ngOnDestroy(): void {
    Promise.all([this.profilesService.updatePinStatus(this.profileInfo.profileId)]);
    this.profileInfoService.profilePinSuccess = false;
    cdkRemoveVisualHidden();
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage(true);
    }, 2000);
  }
  get profileInfo() {
    return this.patronProfileData.profile;
  }
  /**
   * Get remaining count
   * @param limit limit
   * @param count count
   * @returns limit - count
   */
  getRemaining = (limit: number, count: number) => (limit > 0 ? limit - count : 0);
  /**
   * To return limit and rem
   * @param limit limit value
   * @param count count value
   */
  getLimitRem = (limit = 0, count = 0) => ({ limit, rem: this.getRemaining(limit, count) });
  get myStuff() {
    const {
      eBookCheckoutLimit,
      currenteBookCheckoutCount,
      currenteAudiobookCheckoutCount,
      currentVidVbkCheckoutCount,
      audiobookCheckoutLimit,
      holdLimit,
      currentHoldCount,
      recommendLimit,
      currentRecommendCount,
      currentCheckoutsCount,
      checkoutLimit,
      maxVideoCheckoutLimit
    } = this.patronProfileData.myStuff;
    return {
      eBook: this.getLimitRem(eBookCheckoutLimit, currenteBookCheckoutCount),
      audioBook: this.getLimitRem(audiobookCheckoutLimit, currenteAudiobookCheckoutCount),
      videoVBook: this.getLimitRem(maxVideoCheckoutLimit, currentVidVbkCheckoutCount),
      checkout: this.getLimitRem(checkoutLimit, currentCheckoutsCount),
      hold: this.getLimitRem(holdLimit, currentHoldCount),
      recommend: this.getLimitRem(recommendLimit, currentRecommendCount),
      vBook: this.getLimitRem(maxVideoCheckoutLimit, currentVidVbkCheckoutCount),
      currentHoldCount,
      currentRecommendCount,
      maxVideoCheckoutLimit,
      currentVidVbkCheckoutCount
    };
  }
  get editFormValues() {
    return this.editProfileForm.value;
  }
  get routeParams() {
    return this.activatedRoute.snapshot.params;
  }

  /**
   * Component init method
   */
  ngOnInit() {
    this.focusElement('loc_headerId');
    const {currentProfile, selectedEditProfileType} = this.profileInfoService;
    const currentprofileType = currentProfile?.profileType === 'Adult' ? 'General' : currentProfile?.profileType
    this.selectedProfileType = selectedEditProfileType ? selectedEditProfileType : currentprofileType;
    document.title = `Profile Settings - ${this.selectedProfileType}`;
    this.startTimerByAnnounce().then(result => {
      if (result) {
        this.commonService.isProfileSettingPage = true;
        this.pageAnnounce = `Profile Settings - ${this.selectedProfileType} Page`;
      }
    });
    this.isShowSecurityQuestion = false;
    this.activatedRoute.paramMap.subscribe(() => {
      this.tempPreviousUrl = this.commonService.previousUrl;
      this.isMenu = this.activatedRoute.snapshot.queryParams?.isMenu;
      if (this.isMenu) {
        this.profilesService.isProfileNavaigation = this.commonService.previousUrl;
      }
      // this.BrowserBack();
      if (this.profileInfoService.profiles.length === 0) {
        this.getProfilelist();
      }
      this.currentProfile = this.profileInfoService.currentProfile;
      this.currentLibrary = this.configService.currentLibrary;
      const isPrimary = strToBool(this.currentProfile.isPrimary);
      this.isDisableProfileTypes = !isPrimary && (this.currentProfile.profileTypeId === '2' || this.currentProfile.profileTypeId === '3');
      this.profileId = this.routeParams.profileId;
      this.baseHref = this.activatedRoute.snapshot.parent.data.baseHref;
      this.avatar = this.profilesService.avatar;
      this.profilesService.avatar = undefined;
      const { displayName, email, sameAsAdultEmail, profileTypeId, securityQuestion, securityAnswer } =
        this.profilesService.editProfileForm;
      this.editProfileForm = this.formBuilder.group({
        displayName: [
          displayName,
          [Validators.required, Validators.maxLength(25), Validators.minLength(1), Validators.pattern(WHITE_SPACE_REGEX)]
        ],
        email: [email, Validators.pattern(EMAIL_PATTERN)],
        profileTypeId: [profileTypeId],
        securityQuestion: [securityQuestion],
        securityAnswer: [securityAnswer],
        sameAsAdultEmail: [sameAsAdultEmail],
        isEmailEnabled: []
      });
      if (
        this.currentLibrary &&
        this.currentLibrary.authSettings &&
        this.currentLibrary.authSettings.ilsVendorID &&
        (this.currentLibrary.authSettings.ilsVendorID === 'PREFIX' || this.currentLibrary.authSettings.ilsVendorID === 'PASSWORD')
      ) {
        this.isShowSecurityQuestion = !this.currentLibrary.authSettings.noPatronPinNeeded ? true : false;
      }
      positionFooter(true);
      this.profilesAnalyticsService.trackScreen('PROFILE');
      if (this.profileId) {
        this.getProfileById(this.profileId);
        return;
      }
      this.getPatronInfo();
    });
    this.patronTypeAuthentication = this.currentLibrary.authSettings.patronTypeAuthentication;
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
      this.commonService.toStopAnnoucedPage(true);
    }, 2000);
  }
  startTimerByAnnounce = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
          resolve('Data loaded successfully');
      }, 2000);
    });
  }
  onResize() {
    const profileAudMap = {
      adult: BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT,
      teen: BACK_ROUNDIMAGE_TEEN_PROFILE_EDIT,
      kid: BACK_ROUNDIMAGE_KID_PROFILE_EDIT
    };
    const getImage = profileAudMap[this.type];
    this.backgroundImageUrl = getBackgroundImageResize(getImage);
  }
  avatarChanged(avatar) {
    this.isAvatar = false;
    this.avatar = avatar ?? this.avatar;
    this.profilesService.avatar = undefined;
    this.profilesService.editProfileForm = {};
    this.avatarName = this.profilesService.avatarData?.find((pro) => pro.avatarUrl === this.avatar)?.avatarName || '';
    if (avatar) {
      this.editProfileForm.markAsDirty();
      this.isAvatarChanged = true;
    }
    this.focusElement('loc_headerId');
    this.selectedProfileType = this.profileInfoService.selectedEditProfileType;
    this.pageAnnounce = `Profile Settings - ${this.selectedProfileType.replace('Adult', 'General')} Page`
    this.commonService.isProfileSettingPage = true;
    document.title = `Profile Settings - ${this.selectedProfileType.replace('Adult', 'General')}`;
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage(true);
    }, 2000);
    this.formChange();
  }
  setFormValues = (controlName, value) => {
    this.editProfileForm.controls[controlName].setValue(this.profilesService.editProfileForm[controlName] || value);
  };
  setParentEmail(checked: boolean) {
    this.sameAsAdultEmail = checked;
    if (checked) {
      this.editProfileForm.controls.email.setValue(this.patronProfileData.email);
      return;
    }
    this.applyEmailValidators();
    this.editProfileForm.controls.email.setValue('');
  }
  enableEmail(event) {
    if (!this.profileId) {
      if (this.profileInfo && this.profileInfo?.profileTypeId === '3' && this.patronProfileData?.email) {
        this.editProfileForm.controls.email.setValue(this.patronProfileData.email);
      }
      if (
        !this.editFormValues.email ||
        (this.editFormValues.email && this.editProfileForm.controls.email.errors && (!this.patronProfileData.email || this.isAdult))
      ) {
        event.source.checked = false;
        this.isEmailEnabled = false;
        this.applyEmailValidators();
        this.emailAlert();
        return;
      }
      this.applyEmailValidators();
      return;
    }
    if ((!this.editFormValues.email || (this.editFormValues.email && this.editProfileForm.controls.email.errors)) && event.source.checked) {
      event.source.checked = false;
      this.isEmailEnabled = false;
      this.emailAlert();
    }
    this.applyEmailValidators();
  }

  emailAlert() {
    const alertObj =
      this.profileInfo.profileTypeId !== '3'
        ? PROFILES_ALERTS.ENABLE_EMAIL_NOTIFICATION_ALERT_ADULT_TEEN
        : PROFILES_ALERTS.ENABLE_EMAIL_NOTIFICATION_ALERT_KID;
    this.profilesService.showAlertDialog({
      ...alertObj,
      panelClass: 'setpin-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('OK_CTA');
      }
    });
  }
  setEditFrom(): void {
    this.setFormValues('displayName', this.profileInfo.profileName.slice(0, 25));
    this.setFormValues('email', this.profileInfo.profileTypeId === '3' ? this.patronProfileData.email : this.profileInfo.profileEmail);
    this.setFormValues('profileTypeId', this.profileInfo.profileTypeId);
    this.setFormValues('securityAnswer', this.patronProfileData.securityAnswer);
    this.setFormValues('securityQuestion', (this.patronProfileData.securityQuestion || 0).toString());
    if (this.patronProfileData.securityQuestion !== 0) {
      this.editProfileForm.controls.securityAnswer.setValidators([Validators.required]);
    }
    if (!this.isShowSecurityQuestion) {
      this.editProfileForm.controls.securityAnswer.setValidators([]);
    }
    this.profilesService.editProfileForm = {};
  }
  /**
   * To be called on Mat Select openedChange and adding role as listbox to the panel for ADA
   */
  openMatSelect(event: boolean) {
    this.setElemAttribute('.kz-mat-select-panel', 'role', 'listbox');
    if (event) {
      document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'true');
      return;
    }
    document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'false');
  }
  enableUniversalPIN() {
    const pinEnabledForOtherProfiles = this.profileInfoService.profiles.filter(
      (profile) => profile.isPrimary === 'False' && profile.pinStatus === 'True'
    );
    if (!this.universalPINFlag && pinEnabledForOtherProfiles.length > 0) {
      this.profilesService.showAlertDialog({
        ...PROFILES_ALERTS.DISABLE_UNIVERSALPIN,
        ariaDescribedBy: 'alert-heading-desc',
        submitBtnFunc: () => {
          this.universalPINFlag = false;
          this.profileInfoService.profiles.forEach(p => {
            if (p.isPrimary === 'False' && p.pinStatus === 'True') {
              p.pinStatus = 'False'
            }
          }
          );
          this.applyEmailValidators();
          this.save(true);
        },
        cancelBtnFunc: () => {
          this.universalPINFlag = true;
          this.applyEmailValidators();
        }
      });
    }
    else {
      this.save(true);
    }
  }

  applyEmailValidators() {
    this.editProfileForm.controls.email.setValidators([]);
    const teenSameasAdult =
      this.profileInfoService.profiles.filter((p) => p.profileTypeId === '2' && p.sameAsAdultEmail === 'True').length > 0;
    const kidsEmailEnabled =
      this.profileInfoService.profiles.filter((p) => p.profileTypeId === '3' && p.isEmailEnabled === 'True').length > 0;
    const kidPinEnabled = this.profileInfoService.profiles.filter((p) => p.profileTypeId === '3' && p.pinStatus === 'True').length > 0;
    const emailRequired = this.isPrimary
      ? this.isEmailEnabled || this.isEnablePin || kidPinEnabled || teenSameasAdult || kidsEmailEnabled || this.universalPINFlag
      : this.isEmailEnabled || this.isEnablePin;
    if (emailRequired) {
      this.editProfileForm.controls.email.setValidators([Validators.required, Validators.pattern(EMAIL_PATTERN)]);
      if (!this.editProfileForm.controls.email.value) {
        this.editProfileForm.controls.email.markAsTouched();
        this.editProfileForm.controls.email.setValue('');
      }
      return;
    }
    this.editProfileForm.controls.email.setValidators([Validators.pattern(EMAIL_PATTERN)]);
    !this.editProfileForm.controls.email.value && this.editProfileForm.controls.email.setValue('');
  }
  /**
   * get profile by profileId
   * @param profileId return profile
   */
  getProfileById(profileId: string) {
    this.profilesService.getProfileById(profileId).subscribe(
      (response) => {
        this.patronProfileData = response;
        this.avatarName =
          this.profilesService.avatarData?.find((pro) => pro.avatarUrl === this.patronProfileData.profile.profileImage)?.avatarName || '';
        this.type = this.profileInfo.profileType.toLowerCase();
        this.checkoutText = 'eBook';
        this.getCheckoutText();
        this.isAdult = this.type === PROFILE_TYPES.ADULT;
        const profileFeatures = this.configService.getProfileFeatures(this.profileInfo.profileType.toLowerCase()) || {};
        this.isShowInterests = profileFeatures.recommendationEnabled;
        this.showInsightsAndBadges = profileFeatures.insightsBadgesEnabled;
        this.libraryCardId =
          this.configService.currentLibrary?.urlSettings?.urlPrefix.toLocaleLowerCase() === 'hkpl'
            ? 'USER'
            : this.userService.userInfo?.userName;
        this.profileTypes = this.isAdult ? this.profilesService.profileTypes : this.profilesService.getChildProfilesTypes();
        const profileType = this.patronProfileData.profile.profileType === 'Adult' ? 'General' : this.patronProfileData.profile.profileType;
        document.title = `Profile Settings - ${profileType}`;
        this.pageAnnounce = `Profile Settings - ${profileType} Page`;
        this.selectedProfileType = profileType;
        this.profileInfoService.selectedEditProfileType = profileType;
        this.typeForProfileImage = this.getProfileType(this.profilesService.editProfileForm.profileTypeId) || this.type;
        this.isPrimary = strToBool(this.profileInfo.isPrimary);
        this.isPrimary && this.editProfileForm.controls.email.setValidators([Validators.required]);
        this.myShelfDefaultLandingPage = this.profileInfo.defaultLandingPageID === '1';
        this.checkoutHistory = strToBool(this.profileInfo.displayCheckoutHistory);
        this.enableTracking = strToBool(this.profileInfo.enableTracking);
        this.highContrast = strToBool(this.profileInfo.highContrast);
        this.isEnablePin = strToBool(this.profileInfo.pinStatus);
        this.showMyShelf = this.showMyShelfLandingPageOption();
        this.isEmailEnabled = strToBool(this.profileInfo.isEmailEnabled);
        this.universalPINFlag = this.profileInfo.universalPINFlag;
        this.sameAsAdultEmail = strToBool(this.profileInfo.sameAsAdultEmail);
        this.patronType = this.userService.userInfo?.patronType;
        this.setEditFrom();
        this.onResize();
        this.isLoading = false;
        positionFooter(true);
        if (!this.patronTypeAuthentication)
        {
          const profilePin = this.patronProfileData.pinProfileStatus.find((profile) => profile.profileId === this.profileInfo.profileId);
          !this.profileInfoService.changesSaved &&
            this.isEnablePin &&
            !profilePin.isVerifiedPin &&
            this.openPinPopup(PROFILE_ACTION.ACCESS, true, this.profileInfo);
        }
       if (this.isEnablePin || this.isEmailEnabled) {
          this.applyEmailValidators();
        }
        const { displayName, email, profileTypeId, securityQuestion, securityAnswer } = this.editProfileForm.controls;
        this.preValues = deepClone(
          `${this.sameAsAdultEmail}${displayName.value}${email.value}${profileTypeId.value}${securityQuestion.value}${
            securityAnswer.value
          }${this.patronProfileData.isAutoCheckout}${this.isEmailEnabled}${this.checkoutHistory}${this.enableTracking}${this.highContrast}${
            this.myShelfDefaultLandingPage
          }${this.isEnablePin}${!this.avatar ? this.profileInfo.profileImage : this.avatar}${this.universalPINFlag}`
        );
        setTimeout(() => {
          document.querySelectorAll('#security-questionSelect .mat-select-value-text')?.forEach((n) => {
            n.children[0]?.setAttribute('aria-live', 'polite');
          });
        }, 200);
      },
      () => {
        this.patronProfileData = { profile: {}, myStuff: {} };
        this.profilesService.showErrorAlertDialog();
        this.isLoading = false;
        this.isAPIFailed = true;
        positionFooter(true);
      }
    );
  }
  getPatronInfo() {
    this.commonService.hideHeaderFooter.next(true);
    this.profilesService.getPatronInfo().subscribe((response) => {
      response.profile = {};
      this.patronProfileData = response;
      this.avatarName = this.profilesService.avatarData?.find((pro) => pro.avatarUrl === this.patronProfileData.profile.profileImage)?.avatarName || '';
      this.type = this.profileInfo.profileType = this.routeParams.type;
      this.onResize();
      this.isAdult = this.type === PROFILE_TYPES.ADULT;
      this.profileInfo.profileTypeId = String(this.profilesService.getProfileTypeId(this.type));
      this.profileTypes = this.isAdult ? this.profilesService.profileTypes : this.profilesService.getChildProfilesTypes();
      this.libraryCardId = this.userService.userInfo?.userName;
      this.isCreateProfile = true;
      this.isLoading = false;
      const profileFeatures = this.configService.getProfileFeatures(this.type.toLowerCase()) || {};
      this.showInsightsAndBadges = profileFeatures.insightsBadgesEnabled;
      document.body.classList.add('profile-create');
    });
  }
  createProfile() {
    this.profilesAnalyticsService.trackProfilesEvent('DONE');
    const request: UpdateProfileRequest = {
      profile: {
        profileName: this.editFormValues.displayName,
        profileEmail: this.editFormValues.email,
        profileTypeId: String(this.profilesService.getProfileTypeId(this.type)),
        profileImage: !this.avatar ? this.profileInfo.profileImage : this.avatar,
        defaultLandingPageID: this.myShelfDefaultLandingPage ? '1' : '2',
        enableTracking: getYesOrNo(this.enableTracking),
        displayCheckoutHistory: getYesOrNo(this.checkoutHistory),
        highContrast: getYesOrNo(this.highContrast),
        isEmailEnabled: getYesOrNo(this.isEmailEnabled),
        sameAsAdultEmail: getYesOrNo(this.sameAsAdultEmail),
        isPrimary: 'False'
      }
    };
    const duplicatecheck = this.profileInfoService.profiles.some(
      (item) =>
        item.profileName.toLowerCase() === request.profile.profileName.toLowerCase() && item.profileTypeId === request.profile.profileTypeId
    );
    if (!duplicatecheck) {
      this.profilesService.createProfileNew(request).subscribe(
        () => {
          this.enableSave = false;
          this.snackBarSerice.showSnackBar(
            SNAKE_BAR_MODE.INFO,
            PROFILES_MSGS.PROFILE_CREATION_SUCESS.replace('<type>', PROFILE_TYPES_UI_MAP[this.type])
          );
          this.redirectToCreate();
        },
        () => {
          this.profilesService.showFailureAlertDialog('CREATION_FAILURE');
        }
      );
    } else {
      this.editProfileForm.controls.displayName.setErrors({ isDuplicate: true });
    }
  }
  redirectToCreate = () => {
    this.router.navigate([`${this.baseHref}/profiles`]);
  };
  deleteProfile() {
    if (this.isAdult) {
      this.profilesAnalyticsService.trackProfilesEvent('CANCEL_MY_ACCOUNT');
    } else {
      this.profilesAnalyticsService.trackProfilesEvent('DELETE_ACCOUNT');
    }
    this.profilesAnalyticsService.trackScreen('DELETE_PROFILE');
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.PROFILE_DELETE,
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: this.deleteSubmit,
      cancelBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('DC_NO');
      }
    });
  }
  /**
   * To be called on email info button click
   */
  emailAlertPopup(event: Event) {
    this.profilesAnalyticsService.trackScreen('INFO_POPUP');
    event.stopPropagation();
    this.profilesService.showAlertDialog({ ...PROFILES_ALERTS.EMAIL_INFO });
  }
  navigateOnSuccess = (action: string) => {
    const defaultUrl =
      this.profileInfoService.currentProfile.defaultLandingPageID === '1' ? SIDE_NAV_URL.MY_SHELF : SIDE_NAV_URL.MY_LIBRARY;
    if (action === 'delete') {
      this.typeForProfileImage === 'adult' || !this.isPrimary
        ? ((this.sharedService.isNewUser = true), this.router.navigateByUrl(`${this.baseHref}/profiles`))
        : this.router.navigate([defaultUrl]);
    }
  };
  /**
   * To convert the HTML string to child nodes
   * @param htmlString input as HTML string
   * @param webMob input as Web Responsive or Mobile Responsive
   * @returns return the child node
   */
  createElementFromHTML(htmlString = '', webMob = '') {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return webMob === 'Web' ? div.firstChild : div.lastChild;
  }
  deleteSubmit = () => {
    this.profilesAnalyticsService.trackProfilesEvent('DC_YES');
    this.profilesService.deactivateProfile(this.profileId, this.profileInfo.isPrimary).subscribe(
      () => {
        this.enableSave = false;
        this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_DELETED_SUCCESS);
        this.navigateOnSuccess(PROFILE_NAVIGATION.DELETE);
      },
      () => {
        this.profilesService.showFailureAlertDialog('DELETE_FAILURE');
      }
    );
  };
  onImageEdit() {
    this.typeForProfileImage = this.getProfileType(this.editFormValues.profileTypeId);
    this.profilesAnalyticsService.trackProfilesEvent('PROFILE_PICTURE_EDIT');
    this.profilesService.editProfileForm = this.editProfileForm.value;
    this.isAvatar = true;
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage(true);
    }, 2000);
  }
  saveProfile() {
    if (this.profileInfo.profileTypeId !== this.editFormValues.profileTypeId && this.isShowInterests) {
      this.profilesService.showAlertDialog({
        ...PROFILES_ALERTS.PROFILE_TYPES,
        submitBtnFunc: this.isResetInterestSurvey
      });
    } else {
      this.save(false);
    }
  }
  onKeyPress(event: KeyboardEvent): void {
    if (this.editFormValues.displayName?.length >= 25) {
      event.preventDefault();
    }
  }
  viewInterestsurvey() {
    const isProfileTypeCond = this.profileInfo?.profileType === 'Adult' ? 'teen' : this.profileInfo?.profileType?.toLowerCase();
    this.router.navigateByUrl(
      'view/interestsurvey/' +
        (!this.configService.currentLibrary.axis360Enabled && this.configService.currentLibrary.kidsZoneEnabled
          ? isProfileTypeCond
          : this.profileInfo?.profileType?.toLowerCase()) +
        '?id=' +
        this.profileInfo?.profileId +
        '&isPrimary=' +
        this.isPrimary +
        (this.isMenu ? '&isMenu=yes' : '')
    );
  }
  isResetInterestSurvey = () => {
    const request = { interests: [], audience: [] };
    if (this.isAdult && this.isPrimary && this.configService.currentLibrary.axis360Enabled) {
      this.homeService.saveInterests(request).subscribe(() => {
        this.save(false);
      }, noop);
      return;
    }
    const query = `?currentProfileID=${this.profileInfo.profileId}&currentprofileType=${
      KZ_INTEREST_SURVEY_TYPE_PROFILE[this.editFormValues.profileTypeId]
    }&currentProfileIsPrimary=${this.profileInfo.isPrimary}&isProfileChanged=true`;
    this.homeService.kzSaveInterests(request, query).subscribe(() => {
      this.save(false);
    }, noop);
  };
  openPinPopup(action: string, isEnablePin: boolean, profile: ProfileDetail) {
    document.getElementsByTagName('html')[0]?.classList?.add('cdk-global-scrollblock');
    const pinDialog = this.matDialog.open(ParentalPinComponent, {
      panelClass: 'enablepin-modal-container',
      autoFocus: false,
      disableClose: false,
      id: 'loc_profile_edit_pin',
      data: {
        action: action === PROFILE_ACTION.CREATE ? PROFILE_ACTION.CREATE : PROFILE_ACTION.ACCESS,
        name: profile.profileName,
        type: action,
        email: profile.profileEmail,
        profileId: profile.profileId
      },
      ariaDescribedBy: 'loc_textalertcontent',
      ariaLabelledBy: 'loc_txtProfileManagementPin'
    });
    pinDialog.afterClosed().subscribe((res) => {
      document.getElementsByTagName('html')[0]?.classList.remove('cdk-global-scrollblock');
      const actionMap = {
        [PROFILE_ACTION.CREATE]: () => this.setPinToggle(isEnablePin),
        [PROFILE_ACTION.DELETEACCOUNT]: () => this.deleteAccount(),
        [PROFILE_ACTION.DELETE]: () => this.deleteProfile(),
        [PROFILE_ACTION.DISABLE]: () => this.setPinToggle(isEnablePin),
        [PROFILE_ACTION.ACCESS]: () => (this.profileInfoService.profilePinSuccess = false)
      };
      const toggleBtn = document?.getElementById(`pintoggle${this.isEnablePin}`);
      toggleBtn?.classList.add('cdk-keyboard-focused');
      toggleBtn?.classList.add('cdk-focused');
      const elm: HTMLElement = document.getElementById(`pintoggle${this.isEnablePin}-input`);
      elm?.scrollIntoView(true);
      elm?.focus();
      if (res === undefined) {
        this.resetPinToggle(isEnablePin);
        this.applyEmailValidators();
        //this.formChange();
        this.isPinAutoClose = true;
        return;
      }

      if (res || res?.validPin) {
        actionMap[action]();
        this.isPinAutoClose = false;
        this.applyEmailValidators();
        this.save(true);
        //this.formChange();
        return;
      }
      this.isPinAutoClose = false;
      (action === PROFILE_ACTION.CREATE || action === PROFILE_ACTION.DISABLE) && this.resetPinToggle(isEnablePin);
      this.applyEmailValidators();
      //this.formChange();
    });
  }

  setPinToggle(isEnablePin: boolean) {
    this.isEnablePin = isEnablePin;
    this.pinStatusToggle.source.checked = isEnablePin;
  }

  resetPinToggle(isEnablePin: boolean) {
    this.isEnablePin = !isEnablePin;
    this.pinStatusToggle.source.checked = !isEnablePin;
  }

  disablePin(isEnablePin: boolean) {
    this.isShowDisablePinAlert = true;
    document.getElementsByTagName('html')[0]?.classList?.add('cdk-global-scrollblock');
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.DISABLE_PIN,
      panelClass: 'kz-diable-pin-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('DONE_PIN');
        setTimeout(() => {
          this.openPinPopup(PROFILE_ACTION.DISABLE, isEnablePin, this.profileInfo);
          const disable = document?.getElementById('ngInput');
          disable.querySelectorAll('input').forEach((n, i) => {
            if (i === 0) {
              n.setAttribute('cdkFocusInitial', '');
            }
          });
        }, 100);
      },
      cancelBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('DISMISS_PIN');
        this.isEnablePin = !isEnablePin;
        this.pinStatusToggle.source.checked = !isEnablePin;
        const toggleBtn = document.getElementById(`pintoggle${this.isEnablePin}`);
        toggleBtn.classList.add('cdk-keyboard-focused');
        toggleBtn.classList.add('cdk-focused');
        const elm: HTMLElement = document.getElementById(`pintoggle${this.isEnablePin}-input`);
        elm?.scrollIntoView(true);
        elm?.focus();
        this.formChange();
      }
    });
    this.profilesService.matDialog._afterAllClosed.subscribe(() => {
      document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
      if (this.isShowDisablePinAlert) {
        this.isEnablePin = !isEnablePin;
        this.pinStatusToggle.source.checked = !isEnablePin;
        this.isShowDisablePinAlert = false;
      }
    });
  }

  setPinInfo() {
    this.profilesAnalyticsService.trackScreen('INFO_POPUP');
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.SETPIN_INFO,
      panelClass: 'setpin-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc'
    });
  }
  save(toggles = false) {
    this.editProfileForm.markAsPristine();
    let request: UpdateProfileRequest = {
      profile: {
        ...this.profileInfo,
        // profileName: this.editFormValues.displayName,
        profileEmail: this.editFormValues.email,
        // profileImage: !this.avatar ? this.profileInfo.profileImage : this.avatar,
        profileTypeId: this.editFormValues.profileTypeId,
        defaultLandingPageID: this.myShelfDefaultLandingPage ? '1' : '2',
        enableTracking: getYesOrNo(this.enableTracking),
        displayCheckoutHistory: getYesOrNo(this.checkoutHistory),
        highContrast: getYesOrNo(this.highContrast),
        pinStatus: getYesOrNo(this.isEnablePin),
        isEmailEnabled: this.profileInfo.isEmailEnabled.toLowerCase() === 'true' ? 'Y' : 'N',
        sameAsAdultEmail: getYesOrNo(this.sameAsAdultEmail),
        emailNotificationAlert: getYesOrNo(strToBool(this.profileInfo.emailNotificationAlert)),
        universalPINFlag: this.patronTypeAuthentication ? true : this.universalPINFlag
      }
    };

    if (!toggles) {
      request = {
        profile: {
          ...this.profileInfo,
          profileName: this.editFormValues.displayName,
          profileEmail: this.editFormValues.email,
          profileImage: !this.avatar ? this.profileInfo.profileImage : this.avatar,
          profileTypeId: this.editFormValues.profileTypeId,
          defaultLandingPageID: this.myShelfDefaultLandingPage ? '1' : '2',
          enableTracking: getYesOrNo(this.enableTracking),
          displayCheckoutHistory: getYesOrNo(this.checkoutHistory),
          highContrast: getYesOrNo(this.highContrast),
          pinStatus: getYesOrNo(this.isEnablePin),
          isEmailEnabled: getYesOrNo(this.isEmailEnabled),
          sameAsAdultEmail: getYesOrNo(this.sameAsAdultEmail),
          emailNotificationAlert: getYesOrNo(strToBool(this.profileInfo.emailNotificationAlert)),
          universalPINFlag: this.universalPINFlag
        }
      };
    }

    request.profile.displayTrackingPrompt = getYesOrNo(this.profileInfo.displayTrackingPrompt.toLocaleLowerCase() === 'true');
    if (this.isPrimary) {
      request.isAutoCheckout = this.patronProfileData.isAutoCheckout;
      request.enableTracking = this.enableTracking;
      request.isAutoCheckoutSpecified = true;
      request.checkoutHistory = this.checkoutHistory;
      request.checkoutHistorySpecified = true;
      request.enableTrackingSpecified = true;
      request.securityQuestion = this.editFormValues.securityQuestion || 0;
      request.securityQuestionSpecified = this.editFormValues.securityQuestion !== '0';
      request.securityAnswer = this.editFormValues.securityAnswer;
      request.email = this.editFormValues.email;
      request.emailSpecified = true;
    }
    const duplicatecheck = this.profileInfoService.profiles.some(
      (item) =>
        item.profileName.toLowerCase() === request.profile.profileName.toLowerCase() &&
        item.profileTypeId === request.profile.profileTypeId &&
        item.profileId !== request.profile.profileId
    );
    if (!duplicatecheck) {
      if (this.patronProfileData.profile.displayCheckoutHistory !== getYesOrNo(this.checkoutHistory)) {
        this.profileInfoService.myStuffDetails.pillsDetails = [];
      }
      this.profilesService.updateProfile(this.profileInfo.profileId, request).subscribe(
        () => {
          this.patronProfileData.profile = request.profile;
          this.profileInfoService.changesSaved = true;
          this.getProfileById(request.profile.profileId);
          this.isAdult && this.userService.getCurrentUser();
          if (this.profileId === this.currentProfile.profileId) {
            this.spinnerService.withPromise(this.profileInfoService.getCurrentProfile()).then((res) => {
              if (res) {
                this.spinnerService.isOverlayLoading = false;
                this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_UPDATED_SUCCESS);
                this.navigateOnSuccess(PROFILE_NAVIGATION.SAVE);
                this.mainService.updateTheme();
                return;
              }
              this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_UPDATED_SUCCESS);
            });
          } else {
            this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_UPDATED_SUCCESS);
            this.navigateOnSuccess(PROFILE_NAVIGATION.SAVE);
          }

          if (!toggles) {
            this.enableSave = false;
          }
        },
        () => {
          this.profilesService.showFailureAlertDialog('UPDATION_FAILURE');
        }
      );
      this.profilesAnalyticsService.trackProfilesEvent('SAVE');
    } else {
      this.editProfileForm.controls.displayName.setErrors({ isDuplicate: true });
      this.editProfileForm.controls.displayName.markAsTouched();
    }
  }
  onSecurityQuestionChange() {
    this.editProfileForm.controls.securityAnswer.setValidators([Validators.required]);
    this.editProfileForm.controls.securityAnswer.setValue('');
    this.editProfileForm.controls.securityAnswer.markAsUntouched();
    this.securityQuestion.focus();
    this.profilesAnalyticsService.trackProfilesEvent('VIEW_SECURITY_QUESTION');
  }

  /**
   * Event triggered when profile type drop down changes
   */
  onTypeChange() {
    this.profileInfo.profileImage = '';
    this.avatar = '';
    this.avatarName = '';
    this.profilesService.avatar = '';
    this.profilesAnalyticsService.trackProfilesEvent('PROFILE_TYPE');
  }
  setEnablePin(event) {
    this.pinStatusToggle = event;
    const pinStatus = strToBool(this.profileInfo.pinStatus);
    if (!this.pinStatusToggle.source.checked && pinStatus) {
      this.disablePin(this.pinStatusToggle.source.checked);
      return;
    }
    if (!pinStatus && !this.pinStatusToggle.source.checked) {
      this.isEnablePin = this.pinStatusToggle.source.checked;
      // this.formChange();
      return;
    }

    if (pinStatus && this.pinStatusToggle.source.checked && this.editFormValues.email && !this.editProfileForm.controls.email.errors) {
      this.isEnablePin = this.pinStatusToggle.source.checked;
      return;
    }

    if (this.editFormValues.email && !this.editProfileForm.controls.email.errors) {
      this.openPinPopup(PROFILE_ACTION.CREATE, this.pinStatusToggle.source.checked, this.profileInfo);
      return;
    }
    this.showPinValidationAlertDialog();
  }

  showPinValidationAlertDialog() {
    this.profilesAnalyticsService.trackScreen('POPUP');
    const alert = this.profileInfo.profileTypeId === '3' ? PROFILES_ALERTS.ENABLE_PIN_KID : PROFILES_ALERTS.ENABLE_PIN_ADULT;
    this.profilesService.showAlertDialog({
      ...alert,
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('DISMISS_PIN');
      }
    });
    setTimeout(() => {
      this.isEnablePin = false;
      this.pinStatusToggle.source.checked = false;
    }, 100);
  }
  /**
   * Get profile type using profile id
   * @param typeId profile type id
   * @returns profile type
   */
  getProfileType = (typeId: string) => {
    return this.profileTypes.find((_) => _.profileTypeId === typeId)?.profileType?.toLowerCase();
  };

  /**
   * to show my shelf default landing page option or not
   * @returns if true if profile can show my shelf default landing page
   */
  showMyShelfLandingPageOption() {
    return (
      this.configService.currentLibrary.kidsZoneEnabled &&
      this.userService.isLoggedIn() &&
      (this.profileInfo.profileType.toLowerCase() !== PROFILE_TYPES.ADULT ||
        (!this.configService.currentLibrary.axis360Enabled && this.profileInfo.profileType.toLowerCase() === PROFILE_TYPES.ADULT))
    );
  }
  /**
   * To be called on open dialog box yes or no
   */
  deleteAccountwithPin() {
    if (strToBool(this.profileInfo.pinStatus)) {
      this.openPinPopup(PROFILE_ACTION.DELETEACCOUNT, strToBool(this.profileInfo.pinStatus), this.profileInfo);
      return;
    }
    this.deleteAccount();
  }

  deleteProfileWithPin() {
    if (strToBool(this.profileInfo.pinStatus)) {
      this.openPinPopup(PROFILE_ACTION.DELETE, strToBool(this.profileInfo.pinStatus), this.profileInfo);
      return;
    }
    this.deleteProfile();
  }
  deleteAccount() {
    this.profilesAnalyticsService.trackProfilesEvent('DELETE_ACCOUNT');
    if (this.isAdult) {
      this.profilesAnalyticsService.trackProfilesEvent('CANCEL_MY_ACCOUNT');
    } else {
      this.profilesAnalyticsService.trackProfilesEvent('DELETE_ACCOUNT');
    }
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.ACCOUNT_DELETE,
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: this.deleteAccountSubmit,
      cancelBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('DC_NO');
      }
    });
  }
  canDeactivate(): boolean {
    return !this.enableSave;
  }
  enableEmailInfo() {
    this.profilesAnalyticsService.trackScreen('INFO_POPUP');
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.ENABLE_EMAIL_NOTIFICATION_INFO,
      panelClass: 'setpin-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('OKAY');
      }
    });
  }

  enableUniversalPinInfo() {
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.ENABLE_UNIVERSAL_PIN_INFO,
      panelClass: 'setpin-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('OK_CTA');
      }
    });
  }
  /**
   * To be called on Account Deletion
   */
  deleteAccountSubmit = () => {
    this.profilesAnalyticsService.trackProfilesEvent('DC_YES');
    this.profilesService.deleteAccount().subscribe(
      () => {
        this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.ACCOUNT_DELETED_SUCCESS);
        this.forceRedirect(PROFILES_API_INFO.logOff);
      },
      () => {
        this.profilesService.showFailureAlertDialog('DELETE_ACCOUNT_FAILURE');
      }
    );
  };

  back(applicationBack: boolean) {
    this.profileInfoService.isEditProfile = true;
    const isFrom = this.activatedRoute.snapshot.queryParams?.isFrom;
    const isProfileNavaigationCond =
      this.profilesService.isProfileNavaigation && isFrom ? this.profilesService.isProfileNavaigation : REDIRECT_URL.PROFILES_LIST;
    if (this.tempPreviousUrl) {
      this.commonService.previousUrl = this.router.url?.includes('skip-nav') ? this.tempPreviousUrl : this.commonService.previousUrl;
    }
    this.commonService.previousUrl =
      this.commonService.previousUrl && this.commonService.previousUrl.includes('interestsurvey')
        ? isProfileNavaigationCond
        : this.commonService.previousUrl;
    if (this.commonService.previousUrl && this.router.url?.includes('edit')) {
      this.router.navigateByUrl(this.commonService.previousUrl);
      return;
    }
    document.title = this.commonService?.previousUrl?.includes(`profiles/create`) ? `Create Profile` : document.title;
    this.router.navigateByUrl(REDIRECT_URL.LIBRARY);
    this.enableSave ? this.router.navigate(['/']) : applicationBack && this.location.back();
  }

  handleautoCheckout() {
    const request: any = {
      profile: {
        profileTypeId: this.editFormValues.profileTypeId,
        displayCheckoutHistory: getYesOrNo(this.checkoutHistory),
        isAutoCheckedout: this.patronProfileData.isAutoCheckout,
        isAutoCheckoutSpecified: true
      }

      // isAutoCheckout = this.patronProfileData.isAutoCheckout;
      // enableTracking = this.enableTracking;
      // isAutoCheckoutSpecified = true;
      // checkoutHistory = this.checkoutHistory;
      // request.checkoutHistorySpecified = true;
      // request.enableTrackingSpecified = true;
      // request.securityQuestion = this.editFormValues.securityQuestion || 0;
      // request.securityQuestionSpecified = this.editFormValues.securityQuestion !== '0' ? true : false;
      // request.securityAnswer = this.editFormValues.securityAnswer;
      // request.email = this.editFormValues.email;
      // request.emailSpecified = true;
    };
    request.profile.displayTrackingPrompt = getYesOrNo(this.profileInfo.displayTrackingPrompt.toLocaleLowerCase() === 'true');

    this.profilesService.updateProfile(this.profileInfo.profileId, request).subscribe(
      () => {
        this.patronProfileData.profile = request.profile;
        this.profileInfoService.changesSaved = true;
        this.getProfileById(request.profile.profileId);
        this.isAdult && this.userService.getCurrentUser();
        if (this.profileId === this.currentProfile.profileId) {
          this.spinnerService.withPromise(this.profileInfoService.getCurrentProfile()).then((res) => {
            if (res) {
              this.spinnerService.isOverlayLoading = false;
              this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_UPDATED_SUCCESS);
              this.navigateOnSuccess(PROFILE_NAVIGATION.SAVE);
              this.mainService.updateTheme();
              return;
            }
            this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_UPDATED_SUCCESS);
          });
        } else {
          this.snackBarSerice.showSnackBar(SNAKE_BAR_MODE.INFO, PROFILES_MSGS.PROFILE_UPDATED_SUCCESS);
          this.navigateOnSuccess(PROFILE_NAVIGATION.SAVE);
        }
      },
      () => {
        this.profilesService.showFailureAlertDialog('UPDATION_FAILURE');
      }
    );
  }

  autoCheckoutInfo() {
    this.profilesAnalyticsService.trackScreen('INFO_POPUP');
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.SETAUTOMATICCHECKOUT_INFO,
      panelClass: 'setpin-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        this.profilesAnalyticsService.trackProfilesEvent('OKAY');
      }
    });
  }
  /**
   * To be called on open dialog box yes or no
   */
  checkoutHistoryPopup(checkout) {
    // checkout = !checkout;
    if (!checkout) {
      this.addRemoveTabIndex(true);
      this.profilesService.showAlertDialog({
        ...PROFILES_ALERTS.CHECKOUT_HISTORY,
        submitBtnFunc: this.checkoutHistorySubmit,
        cancelBtnFunc: this.cancelHistoryPopup
      });
    } else {
      this.save(true);
      this.profilesAnalyticsService.trackProfilesEvent('ENABLE_CHECKOUT_HISTORY');
    }
  }
  /**
   * To be called on History Checkout
   */
  checkoutHistorySubmit = () => {
    this.addRemoveTabIndex();
    this.checkoutHistory = false;
    this.save(true);
    //this.formChange();
    setTimeout(() => {
      const toggleBtn = document.getElementById(`loc_chkboxDisplayCheckoutHistory`);
      toggleBtn.classList.add('cdk-focused');
      toggleBtn.classList.remove('cdk-program-focused');
      toggleBtn.classList.add('cdk-keyboard-focused');
    }, 2000);
    this.profilesAnalyticsService.trackProfilesEvent('DISABLE_CHECKOUT_HISTORY');
  };

  /**
   * To be called on Thirdparty vender enable or not
   * if enabled verbage change on checkoutText
   */
  getCheckoutText() {
    const currentProfileFeatures = this.configService.getProfileFeatures(this.profileInfo.profileType.toLowerCase()) || {};
    if (!this.currentLibrary?.enableLibrarySettingsFormatLevel) {
      this.checkoutText = 'eBook';
      if (currentProfileFeatures[VENDER_CODE_LIST.video] && currentProfileFeatures[VENDER_CODE_LIST.vbooks]) {
        this.checkoutText += '/Video/vBook';
      } else if (currentProfileFeatures[VENDER_CODE_LIST.vbooks]) {
        this.checkoutText += '/vBook';
      } else if (currentProfileFeatures[VENDER_CODE_LIST.video]) {
        this.checkoutText += '/Video';
      }
    } else {
      this.videoText = '';
      if (currentProfileFeatures[VENDER_CODE_LIST.video] && currentProfileFeatures[VENDER_CODE_LIST.vbooks]) {
        this.videoText += 'Video/vBook';
      } else if (currentProfileFeatures[VENDER_CODE_LIST.vbooks]) {
        this.videoText += 'vBook';
      } else if (currentProfileFeatures[VENDER_CODE_LIST.video]) {
        this.videoText += 'Video';
      }
    }
  }
  /**
   * Forms change
   */
  formChange() {
    const { displayName, email, profileTypeId, securityQuestion, securityAnswer } = this.editProfileForm.controls;
    this.enableSave =
      this.preValues !==
      `${this.sameAsAdultEmail}${displayName.value}${email.value}${profileTypeId.value}${securityQuestion.value}${securityAnswer.value}${
        this.patronProfileData.isAutoCheckout
      }${this.isEmailEnabled}${this.checkoutHistory}${this.enableTracking}${this.highContrast}${this.myShelfDefaultLandingPage}${
        this.isEnablePin
      }${!this.avatar ? this.profileInfo.profileImage : this.avatar}${this.universalPINFlag}`;
    if (this.isEnablePin && (this.editProfileForm.controls.email.errors || !this.editProfileForm.controls.email.value)) {
      // this.enableSave = false;
    }
  }
  cancelHistoryPopup = () => {
    this.addRemoveTabIndex();
    this.checkoutHistory = true;
    // this.formChange();
    setTimeout(() => {
      const toggleBtn = document.getElementById(`loc_chkboxDisplayCheckoutHistory`);
      toggleBtn.classList.remove('cdk-program-focused');
      toggleBtn.classList.add('cdk-keyboard-focused');
      toggleBtn.classList.add('cdk-focused');
    }, 2000);
    this.profilesAnalyticsService.trackProfilesEvent('CANCEL_DISABLE_CHECKOUT_HISTORY');
  };
  disableBadgesPopup(disable) {
    // disable = !disable;
    if (!disable) {
      this.addRemoveTabIndex(true);
      this.profilesService.showAlertDialog({
        ...PROFILES_ALERTS.DISABLE_INSIGHTSANDBADGES,
        ariaDescribedBy: 'alert-heading-desc',
        submitBtnFunc: this.disablesubmit,
        cancelBtnFunc: this.cancelBadgesPopup
      });
    } else {
      this.save(true);
      this.profilesAnalyticsService.trackProfilesEvent('ENABLE_Insights_Badges');
    }
  }
  disablesubmit = () => {
    this.addRemoveTabIndex();
    this.profilesAnalyticsService.trackProfilesEvent('DISABLE_Insights_Badges');
    this.enableTracking = false;
    this.save(true);
    setTimeout(() => {
      const toggleBtn = document.getElementById(`loc_chkboxInsightsAndBadges`);
      toggleBtn.classList.remove('cdk-program-focused');
      toggleBtn.classList.add('cdk-keyboard-focused');
      toggleBtn.classList.add('cdk-focused');
    }, 2000);
  };
  cancelBadgesPopup = () => {
    this.addRemoveTabIndex();
    this.enableTracking = true;
    this.profilesAnalyticsService.trackProfilesEvent('CANCEL_DISABLE_Insights_Badges');
    setTimeout(() => {
      const toggleBtn = document.getElementById(`loc_chkboxInsightsAndBadges`);
      toggleBtn.classList.remove('cdk-program-focused');
      toggleBtn.classList.add('cdk-keyboard-focused');
      toggleBtn.classList.add('cdk-focused');
    }, 2000);
  };
  addRemoveTabIndex(attrTabIndex = false) {
    const elements = document.querySelectorAll('input[type="checkbox"]');
    elements.forEach((elem) => {
      if (elem && attrTabIndex) {
        elem.setAttribute('tabindex', '-1');
        return;
      }
      elem.removeAttribute('tabindex');
    });
  }
  /**
   * Get Profile list by calling API on init
   */
  getProfilelist() {
    this.profilesService.getProfiles().subscribe(
      (response) => {
        if (response.statusCode === 200 && response.message === DEFAULT_SUCCESS_MESSAGE) {
          this.profileInfoService.profiles = response.profiles.profiles;

          setTimeout(()=>{
            const elements = document.querySelectorAll('[aria-required="true"]');
            elements.forEach(element => {
                element.removeAttribute('aria-required');
            });
          },4000)
        }
      },
      () => {
        this.profilesService.showErrorAlertDialog();
      }
    );
  }
  BrowserBack() {
    window.addEventListener(
      'popstate',
      (event) => {
        if (event.type === 'popstate' && this.router.url.includes('edit')) {
          history.pushState(null, '', this.router.url);
          this.back(false);
        }
      },
      false
    );
  }
}
