import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MyShelfAnalyticsService } from '../shared/services/my-shelf.analytics.service';
import { noop } from 'rxjs';

@Component({
  selector: 'axis360-goals-insights-and-badges',
  templateUrl: './goals-insights-and-badges.component.html',
  styleUrls: ['./goals-insights-and-badges.component.scss']
})
export class GoalsInsightsAndBadgesComponent implements OnInit {
  expandState: boolean;
  tempExpandState: boolean;
  isProgressiveGoalsDone = false;
  isProgressiveBadgesDone = false;
  @Output() progressiveGIBEmit = new EventEmitter();
  constructor(public myShelfAnalyticsService: MyShelfAnalyticsService) { }

  /**
   * on init
   */
  ngOnInit() {
    this.expandState = (sessionStorage.getItem('goalsExpandState') === 'false') ? false : true;
  }
  /**
   * Expands goals
   * @param val
   */
  expandGoals(val) {
    this.tempExpandState = val;
  }
  /**
   * Expands goals insights and badges component
   */
  expand() {
    sessionStorage.setItem('goalsExpandState', `${this.tempExpandState}`);
  }
  progressiveGoalsEmit()
  {
    this.isProgressiveGoalsDone = true;
    this.progressiveGoalsBadgesEmit();
  }
  progressiveBadgesEmit()
  {
    this.isProgressiveBadgesDone = true;
    this.progressiveGoalsBadgesEmit();
  }
  progressiveGoalsBadgesEmit()
  {
    this.isProgressiveGoalsDone && this.isProgressiveBadgesDone ? this.progressiveGIBEmit.emit() : noop;
  }
}
