import { Subscription, noop } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CURATED_LISTS, HOME_AVAILABILITY, KZ_HOME_FORMAT_TYPE, LIST_CARD_COUNT } from '@shared/constants/app.constants';
import { ListContent } from '@shared/data/config.model';
import { MyLibraryList } from '@shared/data/list-base.model';
import { getActionButtonsV2 } from '@shared/helper/app.util';
import { MyShelfListService } from '../shared/services/my-shelf-list.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-recommendation-carousel',
  templateUrl: './recommendation-carousel.component.html',
  styleUrls: ['./recommendation-carousel.component.scss']
})
export class RecommendationCarouselComponent implements OnInit, OnDestroy {
  checkoutRecommendationContent: ListContent = { listName: '' };
  interestRecommendationContent: ListContent = { listName: '' };
  checkoutListData;
  interestListData;
  isFeaturedListHide: boolean;
  isFeaturedListHideSubscri: Subscription;
  isCheckoutRecommendation = false;
  isInterestRecommendation = false;
  @Input() parentRoute: string;
  @Output() progressiveRECEmit = new EventEmitter();
  @Input() isCheckedOutCarousal: boolean;

  constructor(public myShelfListService: MyShelfListService, public profileInfo: ProfileInfoService, public userService: UserService) {}

  ngOnInit(): void {
    this.getLists();
    this.isFeaturedListHideSubscri = this.myShelfListService.isFeaturedListHide.subscribe((res: boolean) => {
      if (!res) {
        return;
      }
      this.isFeaturedListHide = res;
    });
    this.myShelfListService.reloadMyShelfComponent.subscribe((reload: boolean) => {
      if (reload) {
        this.getLists();
      }
    });
  }

  /**
   * Get all list data for retrive list name
   */
  async getLists() {
    try {
      const lists = await this.myShelfListService.getMyLibraryList();
      if (!lists) {
        this.checkoutRecommendationContent.Items = [];
        this.interestRecommendationContent.Items = [];
        this.isCheckoutRecommendation = true;
        this.isInterestRecommendation = this.profileInfo.currentProfile?.recommendationEnabled; //fix for Bug230958
        this.progressiveEmit();
        return;
      }
      this.checkoutListData = this.getListById(lists, CURATED_LISTS.CHECKOUT_RECOMMENDATION);
      this.interestListData = this.getListById(lists, CURATED_LISTS.INTEREST_RECOMMENDATION);
      if (this.isCheckedOutCarousal) {
        this.getCheckoutRecommendation();
      }
      if (this.profileInfo.currentProfile?.recommendationEnabled) {
        this.getInterestRecommendation();
      }
    } catch (e) {
      this.checkoutRecommendationContent.Items = [];
      this.interestRecommendationContent.Items = [];
      this.isCheckoutRecommendation = true;
      this.isInterestRecommendation = this.profileInfo.currentProfile?.recommendationEnabled; //fix for Bug230958
      this.progressiveEmit();
    }
  }

  /**
   * Get list name using list id
   * @param lists
   * @param listId
   * @returns
   */
  getListById(lists: MyLibraryList[], listId: { text: string; title: string }) {
    const listData = lists.find((_) => _ && _.ListID === listId?.text) || { ListID: listId?.text, ListName: listId?.title };
    const trimmedListName = listData.ListName.trim();
    if (trimmedListName.startsWith("Because you checked out")) {
    listData.ListName = "Based On Your Checkouts";
     }
    const { ListName: listName, Description: description } = listData;
    return { listName, description, listId, seeAll: `/view/title?list=${listId?.text}` };
  }

  /**
   * Get recommendation content for last read title
   */
  async getCheckoutRecommendation() {
    try {
      const isLoggedIn = this.userService.isLoggedIn();
      this.isCheckoutRecommendation = false;
      this.checkoutRecommendationContent = (await this.myShelfListService.getListContent(
        CURATED_LISTS.CHECKOUT_RECOMMENDATION.text,
        HOME_AVAILABILITY[0].value,
        KZ_HOME_FORMAT_TYPE[0].value,
        1,
        LIST_CARD_COUNT
      )) || { listName: '', Items: [] };
      this.checkoutListData.totalItems = this.checkoutRecommendationContent.TotalItems || 0;
      if (this.checkoutRecommendationContent?.Items.length > 0 && isLoggedIn) {
        this.getActionViewButtonForLibraryList(this.checkoutRecommendationContent, 'checkoutRecommendationContent');
      }
      this.isCheckoutRecommendation = true;
      this.progressiveEmit();
    } catch (e) {
      this.checkoutRecommendationContent = { listName: '', Items: [] };
      this.isCheckoutRecommendation = true;
      this.progressiveEmit();
    }
  }

  /**
   * Get recommendation content for select interest
   */
  async getInterestRecommendation() {
    try {
      this.isInterestRecommendation = false;
      const isLoggedIn = this.userService.isLoggedIn();
      this.interestRecommendationContent = (await this.myShelfListService.getListContent(
        CURATED_LISTS.INTEREST_RECOMMENDATION.text,
        HOME_AVAILABILITY[0].value,
        KZ_HOME_FORMAT_TYPE[0].value,
        1,
        LIST_CARD_COUNT
      )) || { listName: '', Items: [] };
      this.interestListData.totalItems = this.interestRecommendationContent.TotalItems || 0;
      if (this.interestRecommendationContent?.Items.length > 0 && isLoggedIn) {
        this.getActionViewButtonForLibraryList(this.interestRecommendationContent, 'interestRecommendationContent');
      }
      this.isInterestRecommendation = this.profileInfo.currentProfile?.recommendationEnabled; //fix for Bug230958
      this.progressiveEmit();
    } catch (e) {
      this.interestRecommendationContent = { listName: '', Items: [] };
      this.isInterestRecommendation = this.profileInfo.currentProfile?.recommendationEnabled; //fix for Bug230958
      this.progressiveEmit();
    }
  }

  ngOnDestroy(): void {
    this.isFeaturedListHideSubscri.unsubscribe();
  }

  /**
   * get title state info for card
   * @param inputdata
   */
  getActionViewButtonForLibraryList = getActionButtonsV2.apply(this);
  progressiveEmit() {
    if (this.isCheckoutRecommendation && this.isInterestRecommendation) {
      this.progressiveRECEmit.emit();
    } else {
      noop();
    }
  }

  capitalizeFirstLetter(str: string): string {
    const words = str.split(' ');
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
  }
}
