import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HomeService } from '@home/shared/services/home.service';
import { HOME_AVAILABILITY_EPOPUP_LIB, HOME_REFINER, LIST_CARD_COUNT } from '@shared/constants/app.constants';
import { ListContent } from '@shared/data/config.model';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { LibCompononent } from '../shared/data/library.model';
import { MyLibraryService } from '../shared/services/my-library.service';
import { getActionButtonsV2 } from '@shared/helper/app.util';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-curated-lists',
  templateUrl: './curated-lists.component.html',
  styleUrls: ['./curated-lists.component.scss']
})
export class CuratedListsComponent implements OnInit {
  @Input() listData: LibCompononent;
  @Input() parentRoute: string;
  @Output() removeCuratedListEvent = new EventEmitter();
  @Output() progressiveEmit = new EventEmitter();
  myListData = {};
  myListContent: ListContent = { listName: '' };
  epopupValue: boolean;

  constructor(
    private myShelfListService: MyShelfListService,
    public myLibraryService: MyLibraryService,
    public homeService: HomeService,
    private titleListService: TitleListService,
    public userService: UserService
    ) { 
      this.epopupValue = this.titleListService.configService.currentLibrary.partnerSiteEnabled;
    }

  ngOnInit(): void {
    this.getListContent();
  }

  /**
   * Get list titles content
   */
  async getListContent() {
    try {
      const isLoggedIn = this.userService.isLoggedIn();
      this.myListContent = await this.myShelfListService.getListContent(
        this.listData.entityId,
        this.myShelfListService.selectedAvailability,
        this.myShelfListService.selectedFormat,
        1,
        LIST_CARD_COUNT
      );
      this.myListData = this.getListData();

      if (!this.myListContent?.Items?.length) {
        this.myListContent = { listName: '', Items: [] };
        this.removeCuratedListEvent.emit(this.listData.entityId);
        this.progressiveEmitCurated();
        return;
      }
      isLoggedIn && this.getActionViewButtonForLibraryList(this.myListContent, 'myListContent');
      this.myLibraryService.isCuratedListAvailable = true;
      this.progressiveEmitCurated();
    } catch (e) {
      this.myListContent = { listName: '', Items: [] };
      this.progressiveEmitCurated();
    }
  }

  /**
 * get title state info for card
 * @param inputdata
 */
  getActionViewButtonForLibraryList = getActionButtonsV2.apply(this);


  getListData() {
    const { title: listName, entityId: listID } = this.listData;
    const description = this.listData.description || '';
    const data = { listName, description, listID, seeAll: `/view/title?list=${listID}`, totalItems: this.myListContent.TotalItems };
    this.epopupValue && sessionStorage.setItem(HOME_REFINER.AVAILABILITY, HOME_AVAILABILITY_EPOPUP_LIB[0].value);
    data.seeAll = this.myLibraryService.updateSeeAllURL(data.seeAll);
    return data;
  }
  progressiveEmitCurated() {
    this.listData.isShow = !!this.myListContent?.Items?.length;
    this.progressiveEmit.emit();
  }
}
