import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramsCardsComponent } from '@readingprograms/programs/programs-cards/programs-cards.component';
import { PROGRAMS_TABS } from '@readingprograms/shared/constants/programs.constants';
import { ProgramCard } from '@readingprograms/shared/data/programs.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { Book } from '@shared/data/engage-base.model';
import { displayErrorMessage } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { KZPROGRAM_ALERTS } from '../shared/constants/kz-programs-alerts.constants';
import { KzProgramsService } from '../shared/service/kz-programs.service';

@Component({
  selector: 'axis360-kz-programs-cards',
  templateUrl: './kz-programs-cards.component.html',
  styleUrls: ['./kz-programs-cards.component.scss']
})
export class KzProgramsCardsComponent extends ProgramsCardsComponent implements OnInit {
  PROGRAMS_TABS = { ...PROGRAMS_TABS };
  constructor(
    public activatedRoute: ActivatedRoute,
    public programsService: ProgramsService,
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    public infiniteScroll: InfiniteScrollService,
    public router: Router,
    public snackbarService: SnackBarService,
    public matDialog: MatDialog,
    public programsAnalyticsService: ProgramsAnalyticsService,
    public kzProgramsService: KzProgramsService,
    public commonService: CommonService,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService
  ) {
    super(
      activatedRoute,
      programsService,
      engageService,
      spinnerService,
      infiniteScroll,
      router,
      snackbarService,
      matDialog,
      programsAnalyticsService,
      configService,
      profileInfoService
    );
  }
  /**
   * Component init method
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.programsService.navigationProgram.next(true);
  }
  /**
   * To reduce books images count
   * @param books 
   * @returns 
   */
  reduceProgramBooksLen = (books: Book[] = []): Book[] => books.filter((book) => book).reverse();
  /**
   * navigate to Myprogram or Open Program Tab
   * @param programCard sent programcard value
   * 
   */
  onProgramCardClick(programCard?: ProgramCard) {
    this.programsAnalyticsService.trackNotificationsEvent('TAP_ON_PROGRAM');
    this.router.navigate(['/view/programs/', programCard.programId], { relativeTo: this.activatedRoute });
  }
  /**
   * On delete event yes or no popup
   * @param programId programId
   */
  onDeleteClickEvent(program: ProgramCard) {
    this.programsAnalyticsService.trackNotificationsEvent('DELETE_PROGRAM');
    let alertObj = { ...KZPROGRAM_ALERTS.KZPROGRAM_DELETE };
    alertObj.content = alertObj.content.replace('<programName>', program.programName);
    this.commonService.showAlertDialog({
      ...alertObj,
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => this.onDeleteClick(program.programId),
      cancelBtnFunc: () => {
        this.programsAnalyticsService.trackNotificationsEvent('DC_PROGRAM_NO');
      }
    });
  }
  /**
   * Remove the card from closed program 
   * @param programId filter programid
   */
  onDeleteClick(programId) {
    this.programsAnalyticsService.trackNotificationsEvent('DC_PROGRAM_YES');
    this.spinnerService.withObservable(this.kzProgramsService.leaveProgram(programId)).subscribe(
      (result) => {
        if (result.errors) {
          this.snackbarService.showSnackBar(SNAKE_BAR_MODE.ERROR, displayErrorMessage(result));
        } else {
          this.programs.closed.splice(this.programs.closed.findIndex(program => program.programId === programId), 1);
          this.snackbarService.showSnackBar(SNAKE_BAR_MODE.INFO, result.message);
        }
      },
      (result) => {
        this.snackbarService.showSnackBar(SNAKE_BAR_MODE.ERROR, displayErrorMessage(result));
      }
    );
  }
}
