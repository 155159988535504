import { ListContent } from "@shared/data/config.model";

export const calenderDatas = {
  latestIssues: [
    {
      issueDate: new Date('2022-07-14T18:30:00.000Z'),
      day: 'Friday'
    },
    {
      issueDate: new Date('2022-06-30T18:30:00.000Z'),
      day: 'Friday'
    },
    {
      issueDate: new Date('2022-06-14T18:30:00.000Z'),
      day: 'Wednesday'
    },
    {
      issueDate: new Date('2022-05-31T18:30:00.000Z'),
      day: 'Wednesday'
    },
    {
      issueDate: new Date('2022-05-14T18:30:00.000Z'),
      day: 'Sunday'
    }
  ],
  currentIssue: new Date('2022-07-14T18:30:00.000Z'),
  cid: '9flt',
  invalidDates: [
    new Date('2022-05-04T10:36:44.321Z'),
    new Date('2022-05-05T10:36:44.321Z'),
    new Date('2022-05-06T10:36:44.321Z'),
    new Date('2022-05-07T10:36:44.321Z'),
    new Date('2022-05-08T10:36:44.321Z'),
    new Date('2022-05-09T10:36:44.321Z'),
    new Date('2022-05-10T10:36:44.321Z'),
    new Date('2022-05-11T10:36:44.321Z'),
    new Date('2022-05-12T10:36:44.321Z'),
    new Date('2022-05-13T10:36:44.321Z'),
    new Date('2022-05-14T10:36:44.321Z'),
    new Date('2022-05-16T10:36:44.321Z'),
    new Date('2022-05-17T10:36:44.321Z'),
    new Date('2022-05-18T10:36:44.321Z'),
    new Date('2022-05-19T10:36:44.321Z'),
    new Date('2022-05-20T10:36:44.321Z'),
    new Date('2022-05-21T10:36:44.321Z'),
    new Date('2022-05-22T10:36:44.321Z'),
    new Date('2022-05-23T10:36:44.321Z'),
    new Date('2022-05-24T10:36:44.321Z'),
    new Date('2022-05-25T10:36:44.321Z'),
    new Date('2022-05-26T10:36:44.321Z'),
    new Date('2022-05-27T10:36:44.321Z'),
    new Date('2022-05-28T10:36:44.321Z'),
    new Date('2022-05-29T10:36:44.321Z'),
    new Date('2022-05-30T10:36:44.321Z'),
    new Date('2022-05-31T10:36:44.321Z'),
    new Date('2022-06-02T10:36:44.321Z'),
    new Date('2022-06-03T10:36:44.321Z'),
    new Date('2022-06-04T10:36:44.321Z'),
    new Date('2022-06-05T10:36:44.321Z'),
    new Date('2022-06-06T10:36:44.321Z'),
    new Date('2022-06-07T10:36:44.321Z'),
    new Date('2022-06-08T10:36:44.321Z'),
    new Date('2022-06-09T10:36:44.321Z'),
    new Date('2022-06-10T10:36:44.321Z'),
    new Date('2022-06-11T10:36:44.321Z'),
    new Date('2022-06-12T10:36:44.321Z'),
    new Date('2022-06-13T10:36:44.321Z'),
    new Date('2022-06-14T10:36:44.321Z'),
    new Date('2022-06-16T10:36:44.321Z'),
    new Date('2022-06-17T10:36:44.321Z'),
    new Date('2022-06-18T10:36:44.321Z'),
    new Date('2022-06-19T10:36:44.321Z'),
    new Date('2022-06-20T10:36:44.321Z'),
    new Date('2022-06-21T10:36:44.321Z'),
    new Date('2022-06-22T10:36:44.321Z'),
    new Date('2022-06-23T10:36:44.321Z'),
    new Date('2022-06-24T10:36:44.321Z'),
    new Date('2022-06-25T10:36:44.321Z'),
    new Date('2022-06-26T10:36:44.321Z'),
    new Date('2022-06-27T10:36:44.321Z'),
    new Date('2022-06-28T10:36:44.321Z'),
    new Date('2022-06-29T10:36:44.321Z'),
    new Date('2022-06-30T10:36:44.321Z'),
    new Date('2022-07-02T10:36:44.321Z'),
    new Date('2022-07-03T10:36:44.321Z'),
    new Date('2022-07-04T10:36:44.321Z'),
    new Date('2022-07-05T10:36:44.321Z'),
    new Date('2022-07-06T10:36:44.321Z'),
    new Date('2022-07-07T10:36:44.321Z'),
    new Date('2022-07-08T10:36:44.321Z'),
    new Date('2022-07-09T10:36:44.321Z'),
    new Date('2022-07-10T10:36:44.321Z'),
    new Date('2022-07-11T10:36:44.321Z'),
    new Date('2022-07-12T10:36:44.321Z'),
    new Date('2022-07-13T10:36:44.321Z'),
    new Date('2022-07-14T10:36:44.321Z'),
    new Date('2022-07-16T10:36:44.321Z'),
    new Date('2022-07-17T10:36:44.321Z'),
    new Date('2022-07-18T10:36:44.321Z'),
    new Date('2022-07-19T10:36:44.321Z'),
    new Date('2022-07-20T10:36:44.321Z'),
    new Date('2022-07-21T10:36:44.321Z'),
    new Date('2022-07-22T10:36:44.321Z'),
    new Date('2022-07-23T10:36:44.321Z'),
    new Date('2022-07-24T10:36:44.321Z'),
    new Date('2022-07-25T10:36:44.321Z'),
    new Date('2022-07-26T10:36:44.321Z'),
    new Date('2022-07-27T10:36:44.321Z'),
    new Date('2022-07-28T10:36:44.321Z'),
    new Date('2022-07-29T10:36:44.321Z'),
    new Date('2022-07-30T10:36:44.321Z'),
    new Date('2022-07-31T10:36:44.321Z'),
    new Date('2022-08-01T10:36:44.321Z'),
    new Date('2022-08-02T10:36:44.321Z')
  ],
  maxDate: new Date('2022-08-02T10:36:44.321Z'),
  minDate: new Date('2022-05-04T10:36:44.321Z'),
  type: 'Magazine',
  title: 'Magazines'
};
export const THIRD_PARTY_DATA = [
  {
    categories: [
      {
        categories: null,
        id: 1574,
        name: 'Animals & Pets',
        displayName: 'Animals & Pets',
        slug: 'animals-pets',
        iso: null,
        publicationsCount: 56,
        titlePublicationCid: '9g33',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1575,
        name: 'Art',
        displayName: 'Art',
        slug: 'art',
        iso: null,
        publicationsCount: 138,
        titlePublicationCid: '9fe0',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1068,
        name: 'Automotive',
        displayName: 'Automotive',
        slug: 'automotive',
        iso: null,
        publicationsCount: 237,
        titlePublicationCid: '9f58',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1576,
        name: 'Boating & Aviation',
        displayName: 'Boating & Aviation',
        slug: 'boating-aviation',
        iso: null,
        publicationsCount: 45,
        titlePublicationCid: '6625',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1069,
        name: 'Business & Current Affairs',
        displayName: 'Business  & Current Affairs',
        slug: 'business-current-affairs',
        iso: null,
        publicationsCount: 606,
        titlePublicationCid: '9fc6',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1073,
        name: 'Computers & Tech',
        displayName: 'Computers & Technology',
        slug: 'computers-technology',
        iso: null,
        publicationsCount: 223,
        titlePublicationCid: '9559',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1067,
        name: 'Crafts & Hobbies',
        displayName: 'Crafts & Hobbies',
        slug: 'crafts-hobbies',
        iso: null,
        publicationsCount: 164,
        titlePublicationCid: '9if6',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 2275,
        name: 'Design',
        displayName: 'Design',
        slug: 'design',
        iso: null,
        publicationsCount: 82,
        titlePublicationCid: '9fe0',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1071,
        name: 'Entertainment & TV',
        displayName: 'Entertainment & TV',
        slug: 'entertainment-tv',
        iso: '',
        publicationsCount: 351,
        titlePublicationCid: '9yrr',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1074,
        name: 'Fashion',
        displayName: 'Fashion',
        slug: 'fashion',
        iso: null,
        publicationsCount: 237,
        titlePublicationCid: '9yrr',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1523,
        name: 'Food & Drinks',
        displayName: 'Food & Drinks',
        slug: 'food-drinks',
        iso: null,
        publicationsCount: 295,
        titlePublicationCid: '9f75',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1070,
        name: 'For Kids & Tweens',
        displayName: 'For Kids & Tweens',
        slug: 'for-kids-tweens',
        iso: null,
        publicationsCount: 129,
        titlePublicationCid: '9ybw',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1072,
        name: 'For Men',
        displayName: 'For Men',
        slug: 'for-men',
        iso: null,
        publicationsCount: 97,
        titlePublicationCid: '9f64',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1077,
        name: 'For Women',
        displayName: 'For Women',
        slug: 'for-women',
        iso: null,
        publicationsCount: 324,
        titlePublicationCid: '9yrr',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1644,
        name: 'Gaming',
        displayName: 'Gaming',
        slug: 'gaming',
        iso: null,
        publicationsCount: 50,
        titlePublicationCid: '9636',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1578,
        name: 'Health & Fitness',
        displayName: 'Health & Fitness',
        slug: 'health-fitness',
        iso: '',
        publicationsCount: 245,
        titlePublicationCid: '2959',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1520,
        name: 'History & Science',
        displayName: 'History & Science',
        slug: 'history-science',
        iso: null,
        publicationsCount: 384,
        titlePublicationCid: '9fgk',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1532,
        name: 'Home & Garden',
        displayName: 'Home & Garden',
        slug: 'home-garden',
        iso: null,
        publicationsCount: 351,
        titlePublicationCid: '9fav',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1580,
        name: 'LGBTQ',
        displayName: 'LGBTQ',
        slug: 'lgbtq',
        iso: '',
        publicationsCount: 18,
        titlePublicationCid: '9497',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 2128,
        name: 'Local Living',
        displayName: 'Local Living',
        slug: 'local-living',
        iso: null,
        publicationsCount: 146,
        titlePublicationCid: '9491',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 2127,
        name: 'Music',
        displayName: 'Music',
        slug: 'music',
        iso: null,
        publicationsCount: 51,
        titlePublicationCid: '9wsp',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1124,
        name: 'News',
        displayName: 'News',
        slug: 'news',
        iso: null,
        publicationsCount: 2639,
        titlePublicationCid: '1545',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 2129,
        name: 'Outdoors',
        displayName: 'Outdoors',
        slug: 'outdoors',
        iso: null,
        publicationsCount: 91,
        titlePublicationCid: '9e55',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1582,
        name: 'Parenting & Family',
        displayName: 'Parenting & Family',
        slug: 'parenting-family',
        iso: null,
        publicationsCount: 41,
        titlePublicationCid: '2956',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 2274,
        name: 'Photography',
        displayName: 'Photography',
        slug: 'photography',
        iso: null,
        publicationsCount: 50,
        titlePublicationCid: '9486',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1581,
        name: 'Religion & Spirituality',
        displayName: 'Religion & Spirituality',
        slug: 'religion-spirituality',
        iso: null,
        publicationsCount: 35,
        titlePublicationCid: '8871',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1075,
        name: 'Sports',
        displayName: 'Sports',
        slug: 'sports',
        iso: '',
        publicationsCount: 248,
        titlePublicationCid: '3502',
        isCountry: false,
        displayNameLang: 'en'
      },
      {
        categories: null,
        id: 1076,
        name: 'Travel & Culture',
        displayName: 'Travel & Culture',
        slug: 'travel-culture',
        iso: '',
        publicationsCount: 509,
        titlePublicationCid: '9xz3',
        isCountry: false,
        displayNameLang: 'en'
      }
    ],
    id: 584,
    name: 'BaseContentCatalog',
    displayName: 'Categories',
    slug: 'categories',
    iso: null,
    publicationsCount: -1,
    titlePublicationCid: null,
    isCountry: false,
    displayNameLang: 'en'
  }
];
export const COMPONENT = {
  region: '1',
  title: 'Refine',
  description: null,
  type: 'Refiners',
  hideSeeAllLink: false,
  dataApi: {
    url: 'https://dev1axis360-apim.azure-api.net/pressreader/v1/filters',
    headers: {
      key: 'dbfc475cd35a47be8c1a6685be12b1d3',
      ip: ''
    },
    pathParams: null,
    search: null,
    app: null
  },
  jsonTempl: {
    type: 'AdaptiveCard',
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.5',
    fallbackText: 'This card requires Adaptive Cards v1.2 support to be rendered properly.',
    body: [
      {
        type: 'Container',
        items: [
          {
            type: 'ColumnSet',
            columns: [
              {
                type: 'Column',
                items: [
                  {
                    type: 'TextBlock',
                    text: 'Sort By',
                    wrap: true,
                    size: 'Medium'
                  },
                  {
                    type: 'TextBlock',
                    text: 'Selected option here',
                    wrap: true,
                    size: 'Small'
                  }
                ],
                width: 'stretch'
              },
              {
                type: 'Column',
                id: 'chevronDown1',
                spacing: 'Small',
                verticalContentAlignment: 'Center',
                items: [
                  {
                    type: 'Image',
                    url: 'https: //adaptivecards.io/content/down.png',
                    width: '20px',
                    altText: 'collapsed'
                  }
                ],
                width: 'auto',
                isVisible: false
              },
              {
                type: 'Column',
                id: 'chevronUp1',
                spacing: 'Small',
                verticalContentAlignment: 'Center',
                items: [
                  {
                    type: 'Image',
                    url: 'https: //adaptivecards.io/content/up.png',
                    width: '20px',
                    altText: 'expanded'
                  }
                ],
                width: 'auto'
              }
            ],
            selectAction: {
              type: 'Action.ToggleVisibility',
              targetElements: ['cardContent1', 'chevronUp1', 'chevronDown1']
            }
          },
          {
            type: 'Container',
            id: 'cardContent1',
            items: [
              {
                type: 'Input.ChoiceSet',
                id: 'orderBy',
                value: 'latestissuedate desc',
                style: 'expanded',
                choices: [
                  {
                    title: 'Publishing Date',
                    value: 'latestissuedate desc'
                  },
                  {
                    title: 'Relevance',
                    value: 'searchrank'
                  }
                ]
              }
            ]
          }
        ],
        separator: true,
        spacing: 'Medium'
      },
      {
        type: 'Container',
        items: [
          {
            type: 'ColumnSet',
            columns: [
              {
                type: 'Column',
                items: [
                  {
                    type: 'TextBlock',
                    text: 'Country',
                    wrap: true,
                    size: 'Medium'
                  },
                  {
                    type: 'TextBlock',
                    text: 'Selected option here',
                    wrap: true,
                    size: 'Small'
                  }
                ],
                width: 'stretch'
              },
              {
                type: 'Column',
                id: 'chevronDown2',
                spacing: 'Small',
                verticalContentAlignment: 'Center',
                items: [
                  {
                    type: 'Image',
                    url: 'https: //adaptivecards.io/content/down.png',
                    width: '20px',
                    altText: 'collapsed'
                  }
                ],
                width: 'auto',
                isVisible: false
              },
              {
                type: 'Column',
                id: 'chevronUp2',
                spacing: 'Small',
                verticalContentAlignment: 'Center',
                items: [
                  {
                    type: 'Image',
                    url: 'https: //adaptivecards.io/content/up.png',
                    width: '20px',
                    altText: 'expanded'
                  }
                ],
                width: 'auto'
              }
            ],
            selectAction: {
              type: 'Action.ToggleVisibility',
              targetElements: ['cardContent2', 'chevronUp2', 'chevronDown2']
            }
          },
          {
            type: 'Container',
            id: 'cardContent2',
            isVisible: false,
            items: [
              {
                type: 'Input.ChoiceSet',
                id: 'inVal',
                isMultiSelect: true,
                value: '1455',
                style: 'expanded',
                choices: [
                  {
                    $data: '${countries}',
                    title: '${displayName}',
                    value: '${id}'
                  }
                ]
              }
            ]
          }
        ],
        separator: true,
        spacing: 'Medium'
      },
      {
        type: 'Container',
        items: [
          {
            type: 'ColumnSet',
            columns: [
              {
                type: 'Column',
                items: [
                  {
                    type: 'TextBlock',
                    text: 'Languages',
                    wrap: true,
                    size: 'Medium'
                  },
                  {
                    type: 'TextBlock',
                    text: 'Selected option here',
                    wrap: true,
                    size: 'Small'
                  }
                ],
                width: 'stretch'
              },
              {
                type: 'Column',
                id: 'chevronDown3',
                spacing: 'Small',
                verticalContentAlignment: 'Center',
                items: [
                  {
                    type: 'Image',
                    url: 'https: //adaptivecards.io/content/down.png',
                    width: '20px',
                    altText: 'collapsed'
                  }
                ],
                width: 'auto',
                isVisible: false
              },
              {
                type: 'Column',
                id: 'chevronUp3',
                spacing: 'Small',
                verticalContentAlignment: 'Center',
                items: [
                  {
                    type: 'Image',
                    url: 'https: //adaptivecards.io/content/up.png',
                    width: '20px',
                    altText: 'expanded'
                  }
                ],
                width: 'auto'
              }
            ],
            selectAction: {
              type: 'Action.ToggleVisibility',
              targetElements: ['cardContent3', 'chevronUp3', 'chevronDown3']
            }
          },
          {
            type: 'Container',
            id: 'cardContent3',
            isVisible: false,
            items: [
              {
                type: 'Input.ChoiceSet',
                id: 'inVal1',
                isMultiSelect: true,
                value: '808',
                style: 'expanded',
                choices: [
                  {
                    $data: '${languages}',
                    title: '${displayName}',
                    value: '${id}'
                  }
                ]
              }
            ]
          }
        ],
        separator: true,
        spacing: 'Medium'
      }
    ],
    actions: [
      {
        type: 'Action.Submit',
        title: 'Search'
      }
    ]
  }
};

export const THIRD_PARTY_TIER_TWO_DATA = [
  {
    title: 'Tier2',
    pageType: 'Publication List',
    vendor: {
      name: 'Press Reader',
      display_name: 'Newspapers & Magazines',
      code: 'PRESSRDR',
      urlAlias: '/pressreader'
    },
    layout: {
      column_widths: '100%',
      components: [
        {
          region: 1,
          title: 'Refine',
          description: null,
          type: 'Refiners',
          hideSeeAllLink: false,
          dataApi: {
            url: 'https://dev1axis360-apim.azure-api.net/pressreader/v1/filters',
            headers: {
              'ocp-apim-subscription-key': 'dbfc475cd35a47be8c1a6685be12b1d3'
            },
            pathParams: null,
            search: null,
            app: null
          },
          defaultValues: { orderBy: '', inVal: '1455', inVal1: '808' },
          jsonTempl: {
            type: 'AdaptiveCard',
            $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
            version: '1.5',
            fallbackText: 'This card requires Adaptive Cards v1.2 support to be rendered properly.',
            body: [
              {
                type: 'Container',
                items: [
                  {
                    type: 'ColumnSet',
                    columns: [
                      {
                        type: 'Column',
                        items: [
                          {
                            type: 'TextBlock',
                            text: 'Sort By',
                            wrap: true,
                            size: 'Medium'
                          },
                          {
                            type: 'TextBlock',
                            text: 'Selected option here',
                            wrap: true,
                            size: 'Small'
                          }
                        ],
                        width: 'stretch'
                      },
                      {
                        type: 'Column',
                        id: 'chevronDown1',
                        spacing: 'Small',
                        verticalContentAlignment: 'Center',
                        items: [
                          {
                            type: 'Image',
                            url: 'https: //adaptivecards.io/content/down.png',
                            width: '20px',
                            altText: 'collapsed'
                          }
                        ],
                        width: 'auto',
                        isVisible: false
                      },
                      {
                        type: 'Column',
                        id: 'chevronUp1',
                        spacing: 'Small',
                        verticalContentAlignment: 'Center',
                        items: [
                          {
                            type: 'Image',
                            url: 'https: //adaptivecards.io/content/up.png',
                            width: '20px',
                            altText: 'expanded'
                          }
                        ],
                        width: 'auto'
                      }
                    ],
                    selectAction: {
                      type: 'Action.ToggleVisibility',
                      targetElements: ['cardContent1', 'chevronUp1', 'chevronDown1']
                    }
                  },
                  {
                    type: 'Container',
                    id: 'cardContent1',
                    items: [
                      {
                        type: 'Input.ChoiceSet',
                        id: 'orderBy',
                        value: '${default.orderBy}',
                        style: 'expanded',
                        choices: [
                          {
                            title: 'Publishing Date',
                            value: 'latestissuedate desc'
                          },
                          {
                            title: 'Relevance',
                            value: 'searchrank'
                          }
                        ]
                      }
                    ]
                  }
                ],
                separator: true,
                spacing: 'Medium'
              },
              {
                type: 'Container',
                items: [
                  {
                    type: 'ColumnSet',
                    columns: [
                      {
                        type: 'Column',
                        items: [
                          {
                            type: 'TextBlock',
                            text: 'Country',
                            wrap: true,
                            size: 'Medium'
                          },
                          {
                            type: 'TextBlock',
                            text: 'Selected option here',
                            wrap: true,
                            size: 'Small'
                          }
                        ],
                        width: 'stretch'
                      },
                      {
                        type: 'Column',
                        id: 'chevronDown2',
                        spacing: 'Small',
                        verticalContentAlignment: 'Center',
                        items: [
                          {
                            type: 'Image',
                            url: 'https: //adaptivecards.io/content/down.png',
                            width: '20px',
                            altText: 'collapsed'
                          }
                        ],
                        width: 'auto',
                        isVisible: false
                      },
                      {
                        type: 'Column',
                        id: 'chevronUp2',
                        spacing: 'Small',
                        verticalContentAlignment: 'Center',
                        items: [
                          {
                            type: 'Image',
                            url: 'https: //adaptivecards.io/content/up.png',
                            width: '20px',
                            altText: 'expanded'
                          }
                        ],
                        width: 'auto'
                      }
                    ],
                    selectAction: {
                      type: 'Action.ToggleVisibility',
                      targetElements: ['cardContent2', 'chevronUp2', 'chevronDown2']
                    }
                  },
                  {
                    type: 'Container',
                    id: 'cardContent2',
                    isVisible: false,
                    items: [
                      {
                        type: 'Input.ChoiceSet',
                        id: 'inVal',
                        isMultiSelect: true,
                        value: '${default.inVal}',
                        style: 'expanded',
                        choices: [
                          {
                            $data: '${countries}',
                            title: '${displayName}',
                            value: '${id}'
                          }
                        ]
                      }
                    ]
                  }
                ],
                separator: true,
                spacing: 'Medium'
              },
              {
                type: 'Container',
                items: [
                  {
                    type: 'ColumnSet',
                    columns: [
                      {
                        type: 'Column',
                        items: [
                          {
                            type: 'TextBlock',
                            text: 'Languages',
                            wrap: true,
                            size: 'Medium'
                          },
                          {
                            type: 'TextBlock',
                            text: 'Selected option here',
                            wrap: true,
                            size: 'Small'
                          }
                        ],
                        width: 'stretch'
                      },
                      {
                        type: 'Column',
                        id: 'chevronDown3',
                        spacing: 'Small',
                        verticalContentAlignment: 'Center',
                        items: [
                          {
                            type: 'Image',
                            url: 'https: //adaptivecards.io/content/down.png',
                            width: '20px',
                            altText: 'collapsed'
                          }
                        ],
                        width: 'auto',
                        isVisible: false
                      },
                      {
                        type: 'Column',
                        id: 'chevronUp3',
                        spacing: 'Small',
                        verticalContentAlignment: 'Center',
                        items: [
                          {
                            type: 'Image',
                            url: 'https: //adaptivecards.io/content/up.png',
                            width: '20px',
                            altText: 'expanded'
                          }
                        ],
                        width: 'auto'
                      }
                    ],
                    selectAction: {
                      type: 'Action.ToggleVisibility',
                      targetElements: ['cardContent3', 'chevronUp3', 'chevronDown3']
                    }
                  },
                  {
                    type: 'Container',
                    id: 'cardContent3',
                    isVisible: false,
                    items: [
                      {
                        type: 'Input.ChoiceSet',
                        id: 'inVal1',
                        isMultiSelect: true,
                        value: '${default.inVal1}',
                        style: 'expanded',
                        choices: [
                          {
                            $data: '${languages}',
                            title: '${displayName}',
                            value: '${id}'
                          }
                        ]
                      }
                    ]
                  }
                ],
                separator: true,
                spacing: 'Medium'
              }
            ],
            actions: [
              {
                type: 'Action.Submit',
                title: 'Search'
              }
            ]
          }
        },
        {
          region: 1,
          title: 'Publications',
          description: null,
          type: 'Items_Grid',
          hideSeeAllLink: false,
          dataApi: {
            url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications',
            headers: {
              correlationid: '',
              'ocp-apim-subscription-key': 'dbfc475cd35a47be8c1a6685be12b1d3'
            },
            pathParams: null,
            search: {
              limit: '20',
              offset: '0',
              orderBy: 'latestissuedate',
              tp: 'Newspaper',
              inVal: '1455',
              inVal1: '808',
              has: '',
              q: ''
            },
            app: {
              limit: 'True',
              offset: 'True',
              orderBy: 'True',
              tp: 'True',
              inVal: 'True',
              inVal1: 'True',
              has: 'True',
              q: 'True'
            }
          },
          jsonTempl: {
            type: 'AdaptiveCard',
            $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
            version: '1.5',
            body: [
              {
                type: 'Container',
                items: [
                  {
                    type: 'Image',
                    size: 'Large',
                    horizontalAlignment: 'Center',
                    url: 'https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2'
                  },
                  {
                    type: 'TextBlock',
                    text: '{{DATE(${latestIssue.issueDate}, COMPACT)}}',
                    wrap: true,
                    isSubtle: true,
                    weight: 'Lighter'
                  },
                  {
                    type: 'TextBlock',
                    text: '${countrySlug}',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    wrap: true,
                    text: '${displayName}',
                    color: 'Dark',
                    maxLines: 1,
                    weight: 'Bolder'
                  },
                  {
                    type: 'ActionSet',
                    actions: [
                      {
                        type: 'Action.Submit',
                        title: 'Read Now',
                        id: '${id}',
                        data: {
                          url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                          type: 'download',
                          has: '${cid}',
                          issueDate: '${latestIssue.issueDate}',
                          title: '${displayName}'
                        }
                      }
                    ]
                  }
                ],
                selectAction: {
                  type: 'Action.Submit',
                  id: '${id}',
                  data: {
                    url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                    type: 'title',
                    has: '${cid}',
                    issueDate: '${latestIssue.issueDate}',
                    title: '${displayName}'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  }
];
export const GRID_DATA = {
  items: [
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 18731,
      cid: '9jcy',
      type: 'Newspaper',
      name: 'Le Gaboteur: Dans nos archives',
      displayName: 'Le Gaboteur: Dans nos archives',
      issn: '',
      alternateNames: [],
      slug: 'le-gaboteur-dans-nos-archives',
      language: 'fr',
      isSupplement: false,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 615, 1032, 841, 546, 989, 584, 1124, 1133, 1233, 1911, 1987, 8388608, 142606336],
      countries: ['ca'],
      rank: 16,
      latestIssue: {
        id: 4741388,
        key: '9jcy1991090600000051001001',
        cid: '9jcy',
        issueDate: '1991-09-06T00:00:00Z',
        version: 3,
        expungeVersion: 0,
        firstPage: {
          width: 433,
          height: 586
        },
        issueVer: 51
      },
      displayNameLang: 'fr',
      countrySlug: 'canada',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:9jcy',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11219,
      cid: 'sa52',
      type: 'Newspaper',
      name: 'Immobilier Autresvilles',
      displayName: 'Immobilier Autresvilles',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-autresvilles',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 8388608, 142606336],
      countries: ['fr'],
      rank: 43,
      latestIssue: {
        id: 2910805,
        key: 'sa522014082800000000001001',
        cid: 'sa52',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa52',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11220,
      cid: 'sa53',
      type: 'Newspaper',
      name: 'Immobilier Bordeaux',
      displayName: 'Immobilier Bordeaux',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-bordeaux',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1618, 8388608, 142606336],
      countries: ['fr'],
      rank: 51,
      latestIssue: {
        id: 2910807,
        key: 'sa532014082800000000001001',
        cid: 'sa53',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa53',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11221,
      cid: 'sa54',
      type: 'Newspaper',
      name: 'Immobilier Dom',
      displayName: 'Immobilier Dom',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-dom',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 8388608, 142606336],
      countries: ['fr'],
      rank: 40,
      latestIssue: {
        id: 2910949,
        key: 'sa542014082800000000001001',
        cid: 'sa54',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa54',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11224,
      cid: 'sa55',
      type: 'Newspaper',
      name: 'Immobilier Grenoble',
      displayName: 'Immobilier Grenoble',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-grenoble',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1619, 8388608, 142606336],
      countries: ['fr'],
      rank: 45,
      latestIssue: {
        id: 2910952,
        key: 'sa552014082800000000001001',
        cid: 'sa55',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa55',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11225,
      cid: 'sa56',
      type: 'Newspaper',
      name: 'Immobilier La Rochelle',
      displayName: 'Immobilier La Rochelle',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-la-rochelle',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1618, 8388608, 142606336],
      countries: ['fr'],
      rank: 43,
      latestIssue: {
        id: 2910985,
        key: 'sa562014082800000000001001',
        cid: 'sa56',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa56',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11226,
      cid: 'sa57',
      type: 'Newspaper',
      name: 'Immobilier Lille',
      displayName: 'Immobilier Lille',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-lille',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1636, 8388608, 142606336],
      countries: ['fr'],
      rank: 40,
      latestIssue: {
        id: 2910986,
        key: 'sa572014082800000000001001',
        cid: 'sa57',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa57',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11227,
      cid: 'sa58',
      type: 'Newspaper',
      name: 'Immobilier Lyon',
      displayName: 'Immobilier Lyon',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-lyon',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1619, 8388608, 142606336],
      countries: ['fr'],
      rank: 42,
      latestIssue: {
        id: 2910987,
        key: 'sa582014082800000000001001',
        cid: 'sa58',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa58',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11228,
      cid: 'sa59',
      type: 'Newspaper',
      name: 'Immobilier Marseille',
      displayName: 'Immobilier Marseille',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-marseille',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1640, 8388608, 142606336],
      countries: ['fr'],
      rank: 48,
      latestIssue: {
        id: 2911009,
        key: 'sa592014082800000000001001',
        cid: 'sa59',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa59',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11229,
      cid: 'sa60',
      type: 'Newspaper',
      name: 'Immobilier Metz Nancy',
      displayName: 'Immobilier Metz Nancy',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-metz-nancy',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1617, 8388608, 142606336],
      countries: ['fr'],
      rank: 40,
      latestIssue: {
        id: 2911010,
        key: 'sa602014082800000000001001',
        cid: 'sa60',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa60',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11230,
      cid: 'sa61',
      type: 'Newspaper',
      name: 'Immobilier Montpellier',
      displayName: 'Immobilier Montpellier',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-montpellier',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1629, 8388608, 142606336],
      countries: ['fr'],
      rank: 49,
      latestIssue: {
        id: 2911022,
        key: 'sa612014082800000000001001',
        cid: 'sa61',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa61',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11231,
      cid: 'sa62',
      type: 'Newspaper',
      name: 'Immobilier Nantes',
      displayName: 'Immobilier Nantes',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-nantes',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1637, 8388608, 142606336],
      countries: ['fr'],
      rank: 44,
      latestIssue: {
        id: 2911013,
        key: 'sa622014082800000000001001',
        cid: 'sa62',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa62',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11232,
      cid: 'sa63',
      type: 'Newspaper',
      name: 'Immobilier Nice',
      displayName: 'Immobilier Nice',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-nice',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1640, 8388608, 142606336],
      countries: ['fr'],
      rank: 48,
      latestIssue: {
        id: 2911011,
        key: 'sa632014082800000000001001',
        cid: 'sa63',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa63',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11233,
      cid: 'sa64',
      type: 'Newspaper',
      name: 'Immobilier Normandie',
      displayName: 'Immobilier Normandie',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-normandie',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1632, 8388608, 142606336],
      countries: ['fr'],
      rank: 42,
      latestIssue: {
        id: 2911012,
        key: 'sa642014082800000000001001',
        cid: 'sa64',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa64',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11234,
      cid: 'sa65',
      type: 'Newspaper',
      name: 'Immobilier Paris',
      displayName: 'Immobilier Paris',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-paris',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1627, 8388608, 142606336],
      countries: ['fr'],
      rank: 72,
      latestIssue: {
        id: 2911025,
        key: 'sa652014082800000000001001',
        cid: 'sa65',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa65',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11235,
      cid: 'sa66',
      type: 'Newspaper',
      name: 'Immobilier Rennes',
      displayName: 'Immobilier Rennes',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-rennes',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1620, 8388608, 142606336],
      countries: ['fr'],
      rank: 39,
      latestIssue: {
        id: 2911075,
        key: 'sa662014082800000000001001',
        cid: 'sa66',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa66',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11236,
      cid: 'sa67',
      type: 'Newspaper',
      name: 'Immobilier Strasbourg',
      displayName: 'Immobilier Strasbourg',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-strasbourg',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1617, 8388608, 142606336],
      countries: ['fr'],
      rank: 47,
      latestIssue: {
        id: 2912227,
        key: 'sa672014082800000000001001',
        cid: 'sa67',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa67',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11237,
      cid: 'sa68',
      type: 'Newspaper',
      name: 'Immobilier Toulouse',
      displayName: 'Immobilier Toulouse',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-toulouse',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1629, 8388608, 142606336],
      countries: ['fr'],
      rank: 43,
      latestIssue: {
        id: 2912229,
        key: 'sa682014082800000000001001',
        cid: 'sa68',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa68',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 11238,
      cid: 'sa69',
      type: 'Newspaper',
      name: 'Immobilier Var',
      displayName: 'Immobilier Var',
      issn: '',
      alternateNames: [],
      slug: 'lobs-immobilier-var',
      language: 'fr',
      isSupplement: true,
      isFree: false,
      isSmart: false,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [656, 829, 1032, 841, 546, 918, 584, 1124, 1133, 1616, 1640, 8388608, 142606336],
      countries: ['fr'],
      rank: 41,
      latestIssue: {
        id: 2912228,
        key: 'sa692014082800000000001001',
        cid: 'sa69',
        issueDate: '2014-08-28T00:00:00Z',
        version: 0,
        expungeVersion: 0,
        firstPage: {
          width: 595,
          height: 771
        },
        issueVer: 0
      },
      displayNameLang: 'fr',
      countrySlug: 'france',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:sa69',
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 18818,
      cid: 'vb96',
      type: 'Newspaper',
      name: 'La Voz de Galicia (Monforte) - de Vinos',
      displayName: 'De vinos',
      issn: '',
      alternateNames: [],
      slug: 'la-voz-de-galicia-monforte-de-vinos',
      language: 'es',
      isSupplement: true,
      isFree: false,
      isSmart: true,
      isVirtual: true,
      original: {
        id: 15265,
        cid: 'ea16'
      },
      mastheads: null,
      categories: [546, 989, 584, 1124, 1133, 1233, 656, 1285, 1032, 1286, 1763, 1775, 8388608, 142606336],
      countries: ['es'],
      rank: 4,
      latestIssue: {
        id: 3765046,
        key: 'ea162016013100000000001001',
        cid: 'ea16',
        issueDate: '2016-01-31T00:00:00Z',
        version: 2,
        expungeVersion: 0,
        firstPage: {
          width: 602,
          height: 832
        },
        issueVer: 0
      },
      displayNameLang: 'es',
      countrySlug: 'spain',
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: 'cid:vb96',
      availability: null
    }
  ],
  meta: {
    totalCount: 2913,
    offset: 0,
    limit: 20
  }
};
export const THIRD_PARTY_TIER_THREE_MOCK = [
  {
    title: 'Tier3',
    pageType: 'Publication Details',
    vendor: {
      name: 'Press Reader',
      display_name: 'Newspapers & Magazines',
      code: 'PRESSRDR',
      urlAlias: '/pressreader'
    },
    layout: {
      column_widths: '100%',
      components: [
        {
          region: 1,
          title: 'Publication Details',
          description: null,
          type: 'Item_Details',
          subType: null,
          hideSeeAllLink: false,
          defaultValues: null,
          dataApi: {
            url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}',
            headers: {
              correlationid: '',
              'ocp-apim-subscription-key': '',
              'x-client-ip': ''
            },
            pathParams: {
              id: '1545'
            },
            search: null,
            app: null
          },
          jsonTempl: {
            type: 'AdaptiveCard',
            $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
            version: '1.5',
            body: [
              {
                type: 'TextBlock',
                text: '${displayName}',
                wrap: true,
                horizontalAlignment: 'Center',
                size: 'Large',
                weight: 'Default',
                spacing: 'Large'
              },
              {
                type: 'Container',
                items: [
                  {
                    type: 'Image',
                    horizontalAlignment: 'Center',
                    spacing: 'ExtraLarge',
                    url: 'https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781518918698/Medium/Logo',
                    size: 'Large'
                  },
                  {
                    type: 'ActionSet',
                    actions: [
                      {
                        type: 'Action.OpenUrl',
                        title: 'Download',
                        style: 'positive'
                      }
                    ],
                    spacing: 'Medium',
                    horizontalAlignment: 'Center'
                  }
                ],
                horizontalAlignment: 'Center',
                backgroundImage: {
                  verticalAlignment: 'Center'
                }
              },
              {
                type: 'Container',
                items: [
                  {
                    type: 'ColumnSet',
                    columns: [
                      {
                        type: 'Column',
                        width: 'stretch',
                        items: [
                          {
                            type: 'TextBlock',
                            text: 'ISSUE DATE',
                            wrap: true
                          },
                          {
                            type: 'TextBlock',
                            text: 'COUNTRY',
                            wrap: true
                          },
                          {
                            type: 'TextBlock',
                            text: 'LANGUAGE',
                            wrap: true
                          }
                        ],
                        selectAction: {
                          type: 'Action.OpenUrl'
                        }
                      },
                      {
                        type: 'Column',
                        width: 'stretch',
                        items: [
                          {
                            type: 'TextBlock',
                            text: '{{DATE(${latestIssue.issueDate}, COMPACT)}}',
                            wrap: true
                          },
                          {
                            type: 'TextBlock',
                            text: '${countrySlug}',
                            wrap: true
                          },
                          {
                            type: 'TextBlock',
                            text: '${language}',
                            wrap: true
                          }
                        ]
                      }
                    ]
                  }
                ],
                backgroundImage: {
                  fillMode: 'Repeat'
                },
                spacing: 'ExtraLarge',
                separator: true
              }
            ]
          }
        },
        {
          region: 1,
          title: 'Latest Issues',
          description: null,
          type: 'Item_Carousel',
          subType: 'Latest_Issues',
          hideSeeAllLink: false,
          defaultValues: null,
          dataApi: {
            url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}/issues',
            headers: {
              correlationid: '',
              'ocp-apim-subscription-key': '',
              'x-client-ip': ''
            },
            pathParams: {
              id: '9d70'
            },
            search: {
              limit: '90',
              fromDate: '2022-06-15',
              toDate: '2022-12-24'
            },
            app: {
              limit: 'False',
              fromDate: 'False',
              toDate: 'False'
            }
          },
          jsonTempl: {
            type: 'AdaptiveCard',
            $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
            version: '1.5',
            body: [
              {
                type: 'Container',
                $data: '${$root}',
                items: [
                  {
                    type: 'Image',
                    size: 'Large',
                    horizontalAlignment: 'Center',
                    url: 'https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2',
                    selectAction: {
                      type: 'Action.Submit',
                      data: {
                        type: 'title',
                        id: '${id}',
                        has: '${cid}',
                        issueDate: '${latestIssue.issueDate}}',
                        title: '${displayName}'
                      },
                      id: '${id}'
                    }
                  },
                  {
                    type: 'TextBlock',
                    text: '{{DATE(${latestIssue.issueDate}, COMPACT)}}',
                    wrap: true,
                    isSubtle: true,
                    weight: 'Lighter'
                  },
                  {
                    type: 'TextBlock',
                    text: '${countrySlug}',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    wrap: true,
                    text: '${displayName}',
                    color: 'Dark',
                    maxLines: 1,
                    weight: 'Bolder'
                  },
                  {
                    type: 'ActionSet',
                    actions: [
                      {
                        type: 'Action.Submit',
                        title: "${if(buttonText, buttonText, 'Read Now')}",
                        id: '${id}',
                        data: {
                          url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                          type: 'download',
                          has: '${cid}',
                          issueDate: '${latestIssue.issueDate}',
                          title: '${displayName}'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          region: 1,
          title: 'Supplements',
          description: null,
          type: 'Item_Carousel',
          subType: 'Supplements',
          hideSeeAllLink: false,
          defaultValues: null,
          dataApi: {
            url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}',
            headers: {
              correlationid: '',
              'ocp-apim-subscription-key': '',
              'x-client-ip': ''
            },
            pathParams: {
              id: '1545'
            },
            search: null,
            app: null
          },
          jsonTempl: {
            type: 'AdaptiveCard',
            $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
            version: '1.5',
            body: [
              {
                type: 'Container',
                $data: '${$root}',
                items: [
                  {
                    type: 'Image',
                    size: 'Large',
                    horizontalAlignment: 'Center',
                    url: 'https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2',
                    selectAction: {
                      type: 'Action.Submit',
                      data: {
                        type: 'title',
                        id: '${id}',
                        has: '${cid}',
                        issueDate: '${latestIssue.issueDate}}',
                        title: '${displayName}'
                      },
                      id: '${id}'
                    },
                    width: '174.04px',
                    height: '255px'
                  },
                  {
                    type: 'TextBlock',
                    text: '{{DATE(${latestIssue.issueDate}, COMPACT)}}',
                    wrap: true,
                    isSubtle: true,
                    weight: 'Lighter'
                  },
                  {
                    type: 'TextBlock',
                    text: '${countrySlug}',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    wrap: true,
                    text: '${displayName}',
                    color: 'Dark',
                    maxLines: 1,
                    weight: 'Bolder'
                  },
                  {
                    type: 'ActionSet',
                    actions: [
                      {
                        type: 'Action.Submit',
                        title: "${if(buttonText, buttonText, 'Read Now')}",
                        id: '${id}',
                        data: {
                          url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                          type: 'download',
                          has: '${cid}',
                          issueDate: '${latestIssue.issueDate}',
                          title: '${displayName}',
                          catalog: '${$root}'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  }
];
export const SELECT_ISSUE_COMP_MOCK = {
  region: 1,
  title: 'Latest Issues',
  description: null,
  type: 'Select_Issue_Date',
  subType: null,
  hideSeeAllLink: false,
  handleInApp: true,
  defaultValues: null,
  dataApi: {
    url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}/issues',
    headers: {
      correlationid: '',
      'ocp-apim-subscription-key': '',
      'x-client-ip': ''
    },
    pathParams: {
      id: '890'
    },
    search: {
      limit: '90',
      fromDate: '2022-06-15',
      toDate: '2022-12-24'
    },
    app: {
      limit: 'False',
      fromDate: 'False',
      toDate: 'False'
    }
  },
  jsonTempl: {
    type: 'AdaptiveCard',
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.5',
    body: [
      {
        type: 'Container',
        $data: '${$root}'
      }
    ]
  }
};
export const LATEST_ISSUE_COMP_MOCK = {
  region: 1,
  title: 'Latest Issues',
  description: null,
  type: 'Item_Carousel',
  subType: 'Latest_Issues',
  hideSeeAllLink: true,
  handleInApp: false,
  defaultValues: null,
  dataApi: {
    url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}/issues',
    headers: {
      correlationid: '',
      'ocp-apim-subscription-key': '',
      'x-client-ip': ''
    },
    pathParams: {
      id: '890'
    },
    library_settings: { contentCollectionId: "collection" },
    search: {
      limit: '90',
      fromDate: '2022-06-15',
      toDate: '2022-12-24'
    },
    app: {
      limit: 'False',
      fromDate: 'False',
      toDate: 'False'
    }
  },
  jsonTempl: {
    type: 'AdaptiveCard',
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.5',
    body: [
      {
        type: 'Container',
        $data: '${$root}',
        items: [
          {
            type: 'Image',
            size: 'Large',
            horizontalAlignment: 'Center',
            url: 'https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2',
            selectAction: {
              type: 'Action.Submit',
              data: {
                type: 'title',
                id: '${id}',
                has: '${cid}',
                issueDate: '${latestIssue.issueDate}',
                latestIssue: '${latestIssue}',
                title: '${displayName}'
              },
              id: '${id}'
            },
            width: '174.04px',
            height: '255px'
          },
          {
            type: 'TextBlock',
            text: '{{DATE(${latestIssue.issueDate}, COMPACT)}}',
            wrap: true,
            isSubtle: true,
            weight: 'Lighter'
          },
          {
            type: 'TextBlock',
            text: '${countrySlug}',
            wrap: true
          },
          {
            type: 'TextBlock',
            wrap: true,
            text: '${displayName}',
            color: 'Dark',
            maxLines: 1,
            weight: 'Bolder'
          },
          {
            type: 'ActionSet',
            actions: [
              {
                type: 'Action.Submit',
                title: "${if(buttonText, buttonText, 'Read Now')}",
                id: '${id}',
                data: {
                  url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                  type: 'download',
                  has: '${cid}',
                  issueDate: '${latestIssue.issueDate}',
                  title: '${displayName}',
                  catalog: '${$root}'
                }
              }
            ]
          }
        ]
      }
    ]
  }
};
export const LATEST_ISSUES_MOCK = [
  {
    id: 8302186,
    key: '9ht72022082900000000001001',
    cid: '9ht7',
    issueDate: '2022-08-29T00:00:00Z',
    version: 2,
    expungeVersion: 0,
    firstPage: { width: 576, height: 782 },
    issueVer: 0
  }
];

export const THIRD_PARTY_LIBRARY_COMPONENT_MOCK = {
  components: [
    {
      id: null,
      type: "CAROUSEL",
      sequence: 22,
      title: "COMICS",
      description: null,
      entityId: "COMICS",
      entityType: "THIRD_PARTY",
      enabled: true,
      editable: true,
      mandatory: false,
      readOnly: false,
      hidden: false,
      dataApi: null,
      jsonTempl: null,
      components: []
    },
    {
      id: null,
      type: "CAROUSEL",
      sequence: 23,
      title: "HarryPortter_HARRYPORTTER",
      description: null,
      entityId: "HARRY",
      entityType: "THIRD_PARTY",
      enabled: true,
      editable: true,
      mandatory: false,
      readOnly: false,
      hidden: false,
      dataApi: null,
      jsonTempl: null,
      components: []
    },
    {
      id: null,
      type: "CAROUSEL",
      sequence: 24,
      title: "Newspapers & Magazines",
      description: null,
      entityId: "PRESSRDR",
      entityType: "THIRD_PARTY",
      enabled: true,
      editable: true,
      mandatory: false,
      readOnly: false,
      hidden: false,
      dataApi: null,
      jsonTempl: null,
      components: []
    }
  ],
  statusCode: 200,
  libraryId: null,
  profileType: null
};

export const THIRD_PARTY_VENDOR_MOCK = [
  {
    vendor_name: "Press Reader",
    vendor_code: "PRESSRDR",
    description: "Onboarding Press Reader",
    display_name: "Newspapers & Magazines",
    include_in_search: true,
    url_alias: "/pressreader"
  },
  {
    vendor_name: "Comics",
    vendor_code: "PRESSRDR",
    description: "COMICS",
    display_name: "COMICS",
    include_in_search: true,
    url_alias: "/comics"
  }
];

export const THIRD_PARTY_SEARCH_LAYOUT = [
  {
    title: "Search_Home",
    pageType: "Search Home",
    vendor: {
      name: "Press Reader",
      display_name: "Newspapers & Magazines",
      code: "PRESSRDR",
      urlAlias: "/pressreader"
    },
    layout: {
      column_widths: "100%",
      components: [
        {
          region: 1,
          title: "Publications",
          description: null,
          type: "Item_Carousel",
          subType: null,
          hideSeeAllLink: false,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications",
            method: "GET",
            headers: {
              "content-type": null,
              correlationid: "",
              "ocp-apim-subscription-key": "",
              "x-client-ip": ""
            },
            body: null,
            pathParams: null,
            search: {
              limit: "12",
              offset: "0",
              orderBy: "searchrank",
              tp: "",
              inVal: "",
              inVal1: "",
              has: "",
              q: "cat"
            },
            app: {
              limit: "False",
              offset: "False",
              orderBy: "False",
              tp: "False",
              inVal: "False",
              inVal1: "False",
              has: "False",
              q: "False"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5",
            body: [
              {
                type: "Container",
                $data: "${$root}",
                items: [
                  {
                    type: "Image",
                    size: "Large",
                    horizontalAlignment: "Center",
                    url: "https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2",
                    selectAction: {
                      type: "Action.Submit",
                      data: {
                        type: "title",
                        id: "${id}",
                        has: "${cid}",
                        issueDate: "${latestIssue.issueDate}",
                        latestIssue: "${latestIssue}",
                        title: "${displayName}"
                      },
                      id: "${id}"
                    },
                    width: "174.04px",
                    height: "255px"
                  },
                  {
                    type: "TextBlock",
                    text: "{{DATE(${latestIssue.issueDate}, COMPACT)}}",
                    wrap: true,
                    isSubtle: true,
                    weight: "Lighter"
                  },
                  {
                    type: "TextBlock",
                    text: "${countrySlug}",
                    wrap: true
                  },
                  {
                    type: "TextBlock",
                    wrap: true,
                    text: "${displayName}",
                    color: "Dark",
                    maxLines: 1,
                    weight: "Bolder"
                  },
                  {
                    type: "ActionSet",
                    actions: [
                      {
                        type: "Action.Submit",
                        title: "${if(buttonText, buttonText, 'Read Now')}",
                        id: "${id}",
                        data: {
                          url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                          type: "download",
                          has: "${cid}",
                          issueDate: "${latestIssue.issueDate}",
                          title: "${displayName}",
                          catalog: "${$root}"
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          region: 1,
          title: "Articles",
          description: null,
          type: "Article_Carousel",
          subType: null,
          hideSeeAllLink: false,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://dev1axis360-apim.azure-api.net/pressreader/discovery/v1/search",
            method: "POST",
            headers: {
              "content-type": "application/json",
              correlationid: "",
              "ocp-apim-subscription-key": "",
              "x-client-ip": ""
            },
            body: {
              countries: [
                "us"
              ],
              languages: [
                "en"
              ],
              query: "world"
            },
            pathParams: null,
            search: {
              limit: "12",
              offset: "0",
              sort: "Relevance",
              q: "cat"
            },
            app: {
              limit: "False",
              offset: "False",
              sort: "False"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            selectAction: {
              type: "Action.Submit",
              data: {
                type: "download",
                url: "https://www.pressreader.com/article/${article.id}",
                has: "${publication.cid}",
                title: "${article.title}"
              },
              id: "${article.id}"
            },
            body: [
              {
                type: "Container",
                items: [
                  {
                    type: "ColumnSet",
                    columns: [
                      {
                        type: "Column",
                        width: "50px",
                        items: [
                          {
                            type: "Image",
                            url: "${article.media[0].url}",
                            width: "235px",
                            height: "111px"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "ColumnSet",
                            columns: [
                              {
                                type: "Column",
                                width: "stretch",
                                items: [
                                  {
                                    type: "TextBlock",
                                    text: "${publication.title}",
                                    wrap: true,
                                    weight: "Bolder",
                                    maxLines: 1
                                  }
                                ]
                              },
                              {
                                type: "Column",
                                width: "auto",
                                items: [
                                  {
                                    type: "TextBlock",
                                    text: "{{DATE(${article.date},SHORT)}}",
                                    isSubtle: true,
                                    wrap: true
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            type: "ColumnSet",
                            columns: [
                              {
                                type: "Column",
                                width: "stretch",
                                items: [
                                  {
                                    type: "TextBlock",
                                    weight: "Bolder",
                                    text: "${article.title}",
                                    wrap: true,
                                    maxLines: 1,
                                    spacing: "Small"
                                  },
                                  {
                                    type: "TextBlock",
                                    text: "${content[0]}",
                                    wrap: true,
                                    maxLines: 4,
                                    spacing: "Small"
                                  },
                                  {
                                    type: "ColumnSet",
                                    columns: [
                                      {
                                        type: "Column",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            text: "${if(article.author,'By: ', '')} ${if(article.author,article.author, '')}",
                                            wrap: true,
                                            maxLines: 1
                                          }
                                        ],
                                        width: "stretch"
                                      }
                                    ],
                                    minHeight: "2px",
                                    spacing: "Small"
                                  },
                                  {
                                    type: "ColumnSet",
                                    columns: [
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            spacing: "None",
                                            text: "Country:",
                                            wrap: true
                                          }
                                        ]
                                      },
                                      {
                                        type: "Column",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            spacing: "None",
                                            weight: "Bolder",
                                            text: "${publication.countries[0]}",
                                            wrap: true
                                          }
                                        ],
                                        spacing: "Small",
                                        width: "stretch"
                                      },
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            spacing: "None",
                                            text: "in",
                                            wrap: true
                                          }
                                        ],
                                        rtl: true
                                      },
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            spacing: "None",
                                            text: "${article.language}",
                                            weight: "Bolder",
                                            wrap: true
                                          }
                                        ],
                                        spacing: "Small"
                                      },
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            spacing: "None",
                                            text: "from",
                                            wrap: true
                                          }
                                        ],
                                        spacing: "Small"
                                      },
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            spacing: "None",
                                            text: "${publication.countries[0]}",
                                            weight: "Bolder",
                                            wrap: true
                                          }
                                        ],
                                        spacing: "Small"
                                      }
                                    ],
                                    spacing: "Small"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5"
          }
        }
      ]
    }
  }
];

export const THIRD_PARTY_TIER_THREE_MOCK02 = {
  region: 1,
  title: "Articles",
  description: null,
  type: "Items_Grid",
  subType: "Articles",
  hideSeeAllLink: false,
  handleInApp: false,
  defaultValues: null,
  dataApi: {
    url: "https://dev1axis360-apim.azure-api.net/pressreader/discovery/v1/search",
    method: "POST",
    headers: {
      "content-type": "application/json",
      correlationid: "",
      "ocp-apim-subscription-key": "",
      "x-client-ip": ""
    },
    body: {
      countries: [
        "us"
      ],
      languages: [
        "en"
      ],
      query: "world"
    },
    pathParams: null,
    library_settings: { contentCollectionId: "collection" },
    search: {
      limit: "12",
      offset: "0",
      sort: "Relevance"
    },
    app: {
      limit: "False",
      offset: "False",
      sort: "False"
    }
  },
  jsonTempl: {
    type: "AdaptiveCard",
    selectAction: {
      type: "Action.Submit",
      data: {
        type: "article",
        url: "https://www.pressreader.com/article/${article.id}",
        has: "${publication.cid}",
        title: "${article.title}"
      },
      id: "${article.id}"
    },
    body: [
      {
        type: "Container",
        items: [
          {
            type: "ColumnSet",
            columns: [
              {
                type: "Column",
                width: "auto",
                items: [
                  {
                    type: "Image",
                    url: "${article.media[0].url}",
                    width: "80px",
                    height: "80px",
                    style: "Person"
                  }
                ],
                verticalContentAlignment: "Center"
              },
              {
                type: "Column",
                width: "stretch",
                items: [
                  {
                    type: "ColumnSet",
                    columns: [
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${publication.title}",
                            wrap: true,
                            weight: "Bolder",
                            maxLines: 1
                          },
                          {
                            type: "TextBlock",
                            text: "${if(article.date,formatDateTime(article.date, 'dd MMM yyyy'),'')}",
                            isSubtle: true,
                            wrap: true,
                            spacing: "Small"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    columns: [
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            weight: "Bolder",
                            text: "${article.title}",
                            wrap: true,
                            maxLines: 1,
                            spacing: "Small"
                          },
                          {
                            type: "TextBlock",
                            text: "${content[0]}",
                            wrap: true,
                            maxLines: 4,
                            spacing: "Small"
                          },
                          {
                            type: "ColumnSet",
                            columns: [
                              {
                                type: "Column",
                                items: [
                                  {
                                    type: "ColumnSet",
                                    columns: [
                                      {
                                        type: "Column",
                                        width: "25px",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            text: "${if(article.author,'By :', '')}",
                                            weight: "Bolder"
                                          }
                                        ]
                                      },
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            text: "${if(article.author,article.author, '')}",
                                            maxLines: 1
                                          }
                                        ],
                                        spacing: "None"
                                      }
                                    ]
                                  },
                                  {
                                    type: "ColumnSet",
                                    columns: [
                                      {
                                        type: "Column",
                                        width: "60px",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            text: "Country :",
                                            weight: "Bolder"
                                          }
                                        ]
                                      },
                                      {
                                        type: "Column",
                                        width: "20px",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            text: "${publication.countries[0]}"
                                          }
                                        ],
                                        spacing: "None"
                                      }
                                    ],
                                    spacing: "Small"
                                  },
                                  {
                                    type: "ColumnSet",
                                    columns: [
                                      {
                                        type: "Column",
                                        width: "auto",
                                        items: [
                                          {
                                            type: "TextBlock",
                                            text: "in ${publication.language} from ${publication.countries[0]}"
                                          }
                                        ]
                                      }
                                    ],
                                    spacing: "Small"
                                  }
                                ],
                                width: "auto"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5"
  }
};
export const THIRD_PARTY_DATA1 = {
  items: [
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 7967,
      cid: "1454",
      type: "Newspaper",
      name: "The Detroit News",
      displayName: "The Detroit News",
      issn: "1055-2715",
      alternateNames: [],
      slug: "the-detroit-news",
      language: "en",
      isSupplement: false,
      isFree: false,
      isSmart: true,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [
        546,
        664,
        1032,
        808,
        584,
        1124,
        1133,
        656,
        1455,
        2028,
        2053,
        1666,
        2455,
        8388608,
        142606336
      ],
      countries: [
        "us"
      ],
      rank: 9980,
      latestIssue: {
        id: 8722591,
        key: "14542022111400000000001001",
        cid: "1454",
        issueDate: "2022-11-14T00:00:00Z",
        version: 1,
        expungeVersion: 0,
        firstPage: {
          width: 684,
          height: 1167
        },
        issueVer: 0
      },
      displayNameLang: "en",
      countrySlug: "usa",
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: "cid:1454",
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 25848,
      cid: "9gx8",
      type: "Newspaper",
      name: "The Fort Morgan Times",
      displayName: "The Fort Morgan Times",
      issn: "",
      alternateNames: [],
      slug: "the-fort-morgan-times",
      language: "en",
      isSupplement: false,
      isFree: false,
      isSmart: true,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [
        1032,
        808,
        584,
        1124,
        1133,
        656,
        1455,
        2028,
        2035,
        8388608,
        142606336
      ],
      countries: [
        "us"
      ],
      rank: 1610,
      latestIssue: {
        id: 8722665,
        key: "9gx82022111400000000001001",
        cid: "9gx8",
        issueDate: "2022-11-14T00:00:00Z",
        version: 1,
        expungeVersion: 0,
        firstPage: {
          width: 628,
          height: 1195
        },
        issueVer: 0
      },
      displayNameLang: "en",
      countrySlug: "usa",
      shortDescription: null,
      shortDescriptionLang: "",
      fullDescription: null,
      fullDescriptionLang: "",
      prn: "cid:9gx8",
      availability: null
    },
    {
      supplements: [],
      otherRegionalEditions: [],
      id: 21948,
      cid: "9wap",
      type: "Newspaper",
      name: "The Guardian (USA)",
      displayName: "The Guardian (USA)",
      issn: "",
      alternateNames: [],
      slug: "the-guardian-usa",
      language: "en",
      isSupplement: false,
      isFree: false,
      isSmart: true,
      isVirtual: false,
      original: null,
      mastheads: null,
      categories: [
        1032,
        808,
        584,
        1124,
        1133,
        1233,
        656,
        1455,
        2028,
        2029,
        1666,
        2604,
        8388608,
        142606336,
        2684
      ],
      countries: [
        "us"
      ],
      rank: 9953,
      latestIssue: {
        id: 8722212,
        key: "9wap2022111400000000001001",
        cid: "9wap",
        issueDate: "2022-11-14T00:00:00Z",
        version: 2,
        expungeVersion: 0,
        firstPage: {
          width: 746,
          height: 1123
        },
        issueVer: 0
      },
      displayNameLang: "en",
      countrySlug: "usa",
      shortDescription: null,
      shortDescriptionLang: null,
      fullDescription: null,
      fullDescriptionLang: null,
      prn: "cid:9wap",
      availability: null
    }
  ],
  meta: {
    totalCount: 302,
    offset: 48,
    limit: 24
  }
}


export const QUERY_PARAMS_MOCK = {
  tp: "",
  has: "",
  q: "World",
  id: '1010',
  query: "",
  addeddate: "",
  agelevel: "",
  availability: "ALL",
  collections: "General",
  ereadalong: "false",
  format: "All",
  language: "",
  page: "1",
  pageSize: "10",
  releasedate: "",
  searchText: "cat",
  searchby: "all",
  sortby: "relevancy"
};

export const QUERY_PARAMS_MOCK_02 = {
  tp: "",
  has: "",
  q: "World",
  id: '1010',
  query: "",
  recentlyadded: "",
  agelevel: "",
  availability: "ALL",
  collections: "General",
  axisattribute: "false",
  format: "All",
  language: "",
  page: "1",
  pageSize: "10",
  releasedate: "",
  searchText: "cat",
  searchby: "all",
  sortby: "relevancy"
};

export const QUERY_PARAMS_MOCK_03 = {
  tp: "",
  has: "",
  q: "World",
  id: '1010',
  query: "",
  recentlyadded: "",
  agelevel: "",
  availability: "ALL",
  collections: "General",
  axisattribute: "false",
  format: "All",
  language: "",
  pageTitle: 'Newspapers',
  page: "1",
  pageSize: "10",
  releasedate: "",
  searchText: "cat",
  searchby: "all",
  sortby: "relevancy"
};

export const THIRD_PARTY_SEE_ALL_LATEST_ISSUE_MOCK02 = {
  region: 1,
  title: "Latest Issues",
  description: null,
  type: "Items_Grid",
  subType: "Latest_Issues",
  hideSeeAllLink: true,
  handleInApp: false,
  defaultValues: null,
  dataApi: {
    url: "https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}/issues",
    method: "GET",
    headers: {
      "content-type": null,
      correlationid: "",
      "ocp-apim-subscription-key": "",
      "x-client-ip": ""
    },
    body: null,
    library_settings: { contentCollectionId: "collection" },
    pathParams: {
      id: "1010"
    },
    search: {
      limit: "90",
      fromDate: "2022-06-15",
      toDate: "2022-12-24"
    },
    app: {
      limit: "False",
      fromDate: "False",
      toDate: "False"
    }
  },
  jsonTempl: {
    type: "AdaptiveCard",
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5",
    body: [
      {
        type: "Container",
        $data: "${$root}",
        items: [
          {
            type: "Image",
            size: "Large",
            horizontalAlignment: "Center",
            url: "https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2",
            selectAction: {
              type: "Action.Submit",
              data: {
                type: "title",
                id: "${id}",
                has: "${cid}",
                issueDate: "${latestIssue.issueDate}",
                latestIssue: "${latestIssue}",
                title: "${displayName}"
              },
              id: "${id}"
            },
            width: "174.04px",
            height: "255px"
          },
          {
            type: "TextBlock",
            text: "{{DATE(${latestIssue.issueDate}, COMPACT)}}",
            wrap: true,
            isSubtle: true,
            weight: "Lighter"
          },
          {
            type: "TextBlock",
            text: "${countrySlug}",
            wrap: true
          },
          {
            type: "TextBlock",
            wrap: true,
            text: "${displayName}",
            color: "Dark",
            maxLines: 1,
            weight: "Bolder"
          },
          {
            type: "ActionSet",
            actions: [
              {
                type: "Action.Submit",
                title: "${if(buttonText, buttonText, 'Read Now')}",
                id: "${id}",
                data: {
                  url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                  type: "download",
                  has: "${cid}",
                  issueDate: "${latestIssue.issueDate}",
                  title: "${displayName}",
                  catalog: "${$root}"
                }
              }
            ]
          }
        ]
      }
    ]
  }
};

export const THIRD_PARTY_LATEST_ISSUE_DATA = [
  {
    id: 8717564,
    key: "10102022112100000000001001",
    cid: "1010",
    issueDate: "2022-11-21T00:00:00Z",
    version: 2,
    expungeVersion: 0,
    firstPage: {
      width: 574,
      height: 774
    },
    issueVer: 0
  },
  {
    id: 8689832,
    key: "10102022111400000000001001",
    cid: "1010",
    issueDate: "2022-11-14T00:00:00Z",
    version: 2,
    expungeVersion: 0,
    firstPage: {
      width: 574,
      height: 774
    },
    issueVer: 0
  },
  {
    id: 8675581,
    key: "10102022110700000000001001",
    cid: "1010",
    issueDate: "2022-11-07T00:00:00Z",
    version: 2,
    expungeVersion: 0,
    firstPage: {
      width: 574,
      height: 774
    },
    issueVer: 0
  }
];

export const THIRD_PARTY_TIER_THREE_MOCK_001 = {
  region: 1,
  title: 'Publication Details',
  description: null,
  type: 'Item_Details',
  subType: null,
  hideSeeAllLink: false,
  defaultValues: null,
  dataApi: {
    url: 'https://dev1axis360-apim.azure-api.net/pressreader/catalog/api/v1/publications/{id}',
    headers: {
      correlationid: '',
      'ocp-apim-subscription-key': '',
      'x-client-ip': ''
    },
    pathParams: {
      id: '1545'
    },
    search: null,
    app: null
  },
  jsonTempl: {
    type: 'AdaptiveCard',
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.5',
    body: [
      {
        type: 'TextBlock',
        text: '${displayName}',
        wrap: true,
        horizontalAlignment: 'Center',
        size: 'Large',
        weight: 'Default',
        spacing: 'Large'
      },
      {
        type: 'Container',
        items: [
          {
            type: 'Image',
            horizontalAlignment: 'Center',
            spacing: 'ExtraLarge',
            url: 'https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781518918698/Medium/Logo',
            size: 'Large'
          },
          {
            type: 'ActionSet',
            actions: [
              {
                type: 'Action.OpenUrl',
                title: 'Download',
                style: 'positive'
              }
            ],
            spacing: 'Medium',
            horizontalAlignment: 'Center'
          }
        ],
        horizontalAlignment: 'Center',
        backgroundImage: {
          verticalAlignment: 'Center'
        }
      },
      {
        type: 'Container',
        items: [
          {
            type: 'ColumnSet',
            columns: [
              {
                type: 'Column',
                width: 'stretch',
                items: [
                  {
                    type: 'TextBlock',
                    text: 'ISSUE DATE',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    text: 'COUNTRY',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    text: 'LANGUAGE',
                    wrap: true
                  }
                ],
                selectAction: {
                  type: 'Action.OpenUrl'
                }
              },
              {
                type: 'Column',
                width: 'stretch',
                items: [
                  {
                    type: 'TextBlock',
                    text: '{{DATE(${latestIssue.issueDate}, COMPACT)}}',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    text: '${countrySlug}',
                    wrap: true
                  },
                  {
                    type: 'TextBlock',
                    text: '${language}',
                    wrap: true
                  }
                ]
              }
            ]
          }
        ],
        backgroundImage: {
          fillMode: 'Repeat'
        },
        spacing: 'ExtraLarge',
        separator: true
      }
    ]
  }
};

export const THIRD_PARTY_BOOK_MULTIPLE_TITLE_PROGRESS = [
  {
    itemId: "9780307461889",
    timeoffset: 0,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:28Z"
    },
    audiopositions: []
  },
  {
    itemId: "9780307461889",
    timeoffset: 10,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:28Z"
    },
    audiopositions: [
      {
        uuid: "E9F5AD87-B23A-4BE3-9D7B-37EA353F5797",
        isbn: "0012493555",
        profileid: "",
        chapter: "0",
        part: "0",
        siteid: "",
        timeoffset: 10,
        usercreatedate: "2023-04-19T08:01:41Z"
      }
    ]
  },
  {
    itemId: "9780316103688",
    timeoffset: 10,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:28Z"
    },
    audiopositions: [
      {
        uuid: "E9F5AD87-B23A-4BE3-9D7B-37EA353F5797",
        isbn: "9780316103688",
        profileid: "",
        chapter: "0",
        part: "0",
        siteid: "",
        timeoffset: 10,
        usercreatedate: "2023-04-19T08:01:41Z"
      }
    ]
  },
  {
    itemId: "0012487073",
    timeoffset: 10,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:29Z"
    },
    audiopositions: []
  },
  {
    itemId: "0012488399",
    timeoffset: 126,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:29Z"
    },
    audiopositions: [
      {
        uuid: "E9F5AD87-B23A-4BE3-9D7B-37EA353F5797",
        isbn: "0012488399",
        profileid: "",
        chapter: "0",
        part: "0",
        siteid: "",
        timeoffset: 126,
        usercreatedate: "2023-03-20T14:36:41Z"
      }
    ]
  },
  {
    itemId: "0012276794",
    timeoffset: 20,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:30Z"
    },
    audiopositions: []
  },
  {
    itemId: "0012488399",
    timeoffset: 0,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:30Z"
    },
    audiopositions: []
  },
  {
    itemId: "0012488397",
    timeoffset: 0,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:30Z"
    },
    audiopositions: []
  },
  {
    itemId: "0012493554",
    timeoffset: 83,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:31Z"
    },
    audiopositions: [
      {
        uuid: "E9F5AD87-B23A-4BE3-9D7B-37EA353F5797",
        isbn: "0012493554",
        profileid: "",
        chapter: "0",
        part: "0",
        siteid: "",
        timeoffset: 83,
        usercreatedate: "2023-04-19T01:42:26Z"
      }
    ]
  },
  {
    itemId: "0012493526",
    timeoffset: 0,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:31Z"
    },
    audiopositions: []
  },
  {
    itemId: "0012488398",
    timeoffset: 1,
    status: {
      type: 0,
      message: "OK",
      datetime: "2023-04-19T13:45:31Z"
    },
    audiopositions: [
      {
        uuid: "E9F5AD87-B23A-4BE3-9D7B-37EA353F5797",
        isbn: "0012488398",
        profileid: "",
        chapter: "0",
        part: "0",
        siteid: "",
        timeoffset: 1,
        usercreatedate: "2023-04-06T10:07:58Z"
      }
    ]
  }
];

export const THIRD_PARTY_PAST_CHECKOUT_TITLE = {
  status: null,
  statusCode: 200,
  bookHistoryResult: [
    {
      itemId: "0012488300",
      createDate: "2023-12-27T07:31:28.6422705+00:00",
      transactionID: "",
      placedOnWishList: false,
      placedOnHold: false,
      vendorId: "VIDEOS",
      contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3"
    }
  ],
  errors: null
};

export const THIRD_PARTY_BOOK_TITLE_PROGRESS = {
  status: {
    type: 0,
    message: "OK",
    datetime: "2023-04-17T13:02:50Z"
  },
  audiopositions: [
    {
      uuid: "D9E7EE45-367C-4325-9148-704ED5273C21",
      isbn: "0030502434",
      profileid: "",
      chapter: "0",
      part: "0",
      siteid: "",
      timeoffset: 77,
      usercreatedate: "2023-03-29T09:55:10Z"
    }
  ]
};

export const THIRD_PARTY_VIDEOS_DATA = [
  {
    "@xmlns": "http://axis360api.baker-taylor.com/vendorAPI",
    bookTitle: "<b>Pets</b>",
    authors: {
      author: "Hamilton, Joseph (PRD)"
    },
    titleID: "0012488399",
    formatType: "EBT",
    iSBN: "9781223220154",
    imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220154/Medium/Logo",
    narrators: null,
    publicationDate: "2022-06-18",
    series: "The Reading Road Trip",
    synopsis: null,
    purchaseOption: "MUPO",
    publisher: "CHKEB",
    subject: "JUVENILE NONFICTION/Animals/Pets#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: null,
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "False",
    isBLIO: "N",
    isAcoustik: "N",
    isPDF: "N",
    isEPub: "N",
    isHold: "Y",
    isCheckedout: 'true',
    axisAttribute: null,
    language: "eng",
    audienceCode: "1-2",
    runTime: null,
    createdDate: "2022-12-07T13:17:28Z"
  }
];

export const THIRD_PARTY_VIDEOS_DATA_001 = [
  {
    "@xmlns": "http://axis360api.baker-taylor.com/vendorAPI",
    bookTitle: "<b>Pets</b>",
    authors: {
      author: "Hamilton, Joseph (PRD)"
    },
    titleID: "0012488399",
    formatType: "EBT",
    iSBN: "9781223220154",
    imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220154/Medium/Logo",
    narrators: null,
    publicationDate: "2022-06-18",
    series: "The Reading Road Trip",
    synopsis: null,
    purchaseOption: "MUPO",
    publisher: "CHKEB",
    subject: "JUVENILE NONFICTION/Animals/Pets#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: null,
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "False",
    isBLIO: "N",
    isAcoustik: "N",
    isPDF: "N",
    isEPub: "N",
    isHold: "Y",
    isCheckOut: "N",
    isCheckedout: 'false',
    axisAttribute: null,
    language: "eng",
    audienceCode: "1-2",
    runTime: null,
    createdDate: "2022-12-07T13:17:28Z"
  }
];
export const THIRD_PARTY_TIER_TWO_MOCK_002 = {
  region: 1,
  title: "Video Carousel",
  description: null,
  type: "Items_Grid",
  subType: "Videos",
  hideSeeAllLink: false,
  handleInApp: false,
  defaultValues: null,
  dataApi: {
    url: "https://dev1axis360-apim.azure-api.net/kidszone/api/v1/searchTitles/v7",
    method: "GET",
    headers: {
      authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJQYXRyb25JZCI6IjZlNDY2NmE0LTlmODEtNDhjMi04MDZiLWRhMmQ2MzdmY2UxNSIsIkxpYnJhcnlJZCI6IjU3Qzg2QThCLUUzQjktRTAxMS05QTEyLTY0MzE1MDRBMzA2QSIsIlZlbmRvcklkIjoiUHJva2FybWFBUEkiLCJTZXNzaW9uSWQiOiJDMDQ2RDc2Ni1EMjdBLUVEMTEtOUQ3OS02MDQ1QkRFRTI5NEMiLCJQcm9maWxlSWQiOiI2Mzc5NCIsIlByb2ZpbGVUeXBlIjoiQWR1bHQiLCJJc1ByaW1hcnkiOiJUcnVlIiwibmJmIjoxNjcwOTI3OTY1LCJleHAiOjE2NzQ4MTU5NjIsImlhdCI6MTY3MDkyNzk2NX0.fAcJRVsGbz4Qyp3toQfiZq7BHykUszurM_h46ufxQmo",
      library: "demo",
      "ocp-apim-subscription-key": ""
    },
    body: null,
    pathParams: null,
    library_settings: { contentCollectionId: "collection" },
    search: {
      pageNumber: "0",
      searchSort: "PublicationDate",
      collection: "THIRDPARTY",
      contentColId: "73335E67-5472-ED11-819D-000D3A1EC91D",
      searchType: "term",
      searchTerm: "",
      formatType: "video",
      pageSize: "10"
    },
    app: {
      pageNumber: "false",
      searchSort: "false",
      collection: "false",
      contentColId: "false",
      searchType: "false",
      searchTerm: "false",
      formatType: "false",
      pageSize: "false"
    }
  },
  jsonTempl: {
    type: "AdaptiveCard",
    body: [
      {
        type: "ColumnSet",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "Container",
                selectAction: {
                  type: "Action.Submit",
                  data: {
                    type: "title",
                    ItemId: "${titleID}",
                    ISBN: "${iSBN}",
                    formatType: "${formatType}",
                    isAvailable: "${isAvailable}",
                    title: "${bookTitle}"
                  }
                },
                backgroundImage: {
                  url: "${imageURL}"
                },
                items: [
                  {
                    type: "Image",
                    style: "Person",
                    url: "https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png",
                    size: "Small",
                    horizontalAlignment: "Right",
                    width: "24px",
                    height: "24px"
                  }
                ],
                height: "stretch",
                minHeight: "192px"
              }
            ]
          }
        ],
        minHeight: "50px"
      },
      {
        type: "TextBlock",
        wrap: true,
        text: "${bookTitle}",
        color: "Dark",
        maxLines: 1,
        weight: "Bolder"
      },
      {
        type: "TextBlock",
        text: "${if(authors.author[0], authors.author[0], authors.author)}",
        wrap: true,
        maxLines: 1
      },
      {
        type: "ColumnSet",
        $when: "${isCheckOut == 'N'}",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "ActionSet",
                actions: [
                  {
                    type: "Action.Submit",
                    title: "Checkout",
                    id: "${titleID}",
                    data: {
                      type: "checkout",
                      actiontype: "checkout",
                      ItemId: "${titleID}",
                      ISBN: "${iSBN}",
                      formatType: "${formatType}",
                      isAvailable: "${isAvailable}"
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: "ColumnSet",
        $when: "${isCheckOut == 'Y'}",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "Container",
                items: [
                  {
                    type: "ColumnSet",
                    columns: [
                      {
                        type: "Column",
                        id: "chevronDown1",
                        spacing: "Small",
                        items: [
                          {
                            type: "Image",
                            url: "https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Down_arrow@2x.png",
                            width: "24px",
                            altText: "collapsed",
                            selectAction: {
                              type: "Action.ToggleVisibility",
                              targetElements: [
                                "cardContent1",
                                "chevronUp1",
                                "chevronDown1"
                              ]
                            }
                          }
                        ],
                        width: "auto"
                      },
                      {
                        type: "Column",
                        id: "chevronUp1",
                        spacing: "Small",
                        items: [
                          {
                            type: "Image",
                            url: "https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Up_arrow@2x.png",
                            width: "24px",
                            altText: "expanded",
                            selectAction: {
                              type: "Action.ToggleVisibility",
                              targetElements: [
                                "cardContent1",
                                "chevronUp1",
                                "chevronDown1"
                              ]
                            }
                          }
                        ],
                        width: "auto",
                        isVisible: false
                      }
                    ],
                    selectAction: {
                      type: "Action.ToggleVisibility",
                      targetElements: [
                        "cardContent1",
                        "chevronUp1",
                        "chevronDown1"
                      ]
                    }
                  },
                  {
                    type: "Container",
                    id: "cardContent1",
                    isVisible: false,
                    items: [
                      {
                        type: "ActionSet",
                        actions: [
                          {
                            type: "Action.Submit",
                            title: "Renew",
                            id: "${titleID}",
                            data: {
                              type: "renew",
                              actiontype: "renewtitle",
                              ItemId: "${titleID}",
                              ISBN: "${iSBN}",
                              formatType: "${formatType}",
                              isAvailable: "${isAvailable}"
                            }
                          }
                        ]
                      },
                      {
                        type: "ActionSet",
                        actions: [
                          {
                            type: "Action.Submit",
                            title: "Return",
                            id: "${titleID}",
                            data: {
                              type: "return",
                              actiontype: "returntitle",
                              ItemId: "${titleID}",
                              ISBN: "${iSBN}",
                              formatType: "${formatType}",
                              isAvailable: "${isAvailable}"
                            }
                          }
                        ]
                      }
                    ]
                  }
                ],
                separator: true
              }
            ]
          },
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "ActionSet",
                actions: [
                  {
                    type: "Action.Submit",
                    title: "Play",
                    id: "${titleID}",
                    data: {
                      type: "play",
                      ItemId: "${titleID}",
                      ISBN: "${iSBN}",
                      formatType: "${formatType}",
                      isAvailable: "${isAvailable}"
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5",
    verticalContentAlignment: "Top"
  }
};

export const THIRD_PARTY_DATA_TIER_TWO = {
  version: "1.0",
  encoding: "utf-8",
  searchTitleResponse: {
    searchTitleResult: {
      status: {
        code: "0000",
        statusMessage: "Titles Retrieved Successfully."
      },
      searchParameters: {
        searchType: "term",
        searchValue: "the",
        searchLabel: "the"
      },
      resultCount: "595",
      eBookCount: "5",
      eAudioBookCount: "0",
      titlesList: {
        title: [
          {
            createdDate: "2022-05-26T10:05:28Z",
            bookTitle: "Journey to <b>the</b> Heart of <b>the</b> Abyss",
            authors: {
              author: "Shah, London"
            },
            titleID: "0012487073",
            formatType: "VID",
            iSBN: "9780316103688",
            displayStatus: 'Due on 23 Hour(s)',
            imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9780316103688/Medium/Logo",
            narrators: null,
            publicationDate: "2021-11-16",
            series: "Light the Abyss",
            synopsis: null,
            purchaseOption: "SUPO",
            publisher: "Hachette Book Group USA",
            subject: "YOUNG ADULT FICTION/Science Fiction/Apocalyptic & Post-Apocalyptic#YOUNG ADULT FICTION/Dystopian#YOUNG ADULT FICTION/Science & Nature/Environment",
            edition: null,
            audience: "Teen - Grade 7-9, Age 12-14",
            isAvailable: "True",
            isBLIO: "Y",
            isAcoustik: "N",
            isPDF: "N",
            isEPub: "N",
            isHold: "N",
            isCheckOut: "Y",
            axisAttribute: null,
            language: "eng",
            audienceCode: "7-9",
            runTime: null,
            videoLicenceURL: "",
            streamURL: "https://bunnycdn.com/stream"
          },
          {
            createdDate: "2022-05-26T10:05:28Z",
            bookTitle: "<b>The</b> Covid-19 Virus",
            authors: {
              author: "Hansen, Grace"
            },
            titleID: "0012487011",
            formatType: "VBK",
            iSBN: "9781098205577",
            imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781098205577/Medium/Logo",
            narrators: null,
            publicationDate: "2020-08-01",
            series: "Coronavirus",
            synopsis: null,
            purchaseOption: "SUPO",
            publisher: "Abdo Pub Co",
            subject: "JUVENILE NONFICTION/Health & Daily Living/Diseases, Illnesses & Injuries#JUVENILE NONFICTION/Science & Nature/Anatomy & Physiology#JUVENILE NONFICTION/Science & Nature/Disasters",
            edition: null,
            audience: "Children's - Kindergarten, Age 5-6",
            isAvailable: "True",
            isBLIO: "Y",
            isAcoustik: "N",
            isPDF: "N",
            isEPub: "N",
            isHold: "N",
            isCheckOut: "Y",
            axisAttribute: "RAL",
            language: "eng",
            audienceCode: "K",
            runTime: null,
            videoLicenceURL: "",
            streamURL: "https://bunnycdn.com/stream"
          }
        ]
      }
    }
  }
};

export const THIRD_PARTY_ACTIONS_CHECKOUT = {
  actiontype: "checkout",
  itemId: "0012488399",
  reservationId: "",
  formatType: "VID",
  title: "Title",
  isbn: "9781223220154",
  collectionType: "THIRDPARTY",
  contentCollectionId: "73335E67-5472-ED11-819D-000D3A1EC91D",
  vendorId: "VIDEOS",
  pageName: ''
};

export const THIRD_PARTY_ACTIONS_CTA_ADD_TO_WISHLIST = {
  type: "actions",
  actiontype: "addtowishlist",
  ItemId: "0012488399",
  ISBN: "9781223220154",
  title: "bookTitle",
  vendorCode: "VIDEOS",
  formatType: "VID",
  isAvailable: "true",
  pageName: ''
};

export const THIRD_PARTY_ACTIONS_CTA_REMOVE_WISHLIST = {
  type: "actions",
  actiontype: "removefromwishlist",
  ItemId: "0012488399",
  ISBN: "9781223220154",
  title: "bookTitle",
  vendorCode: "VIDEOS",
  formatType: "VID",
  isAvailable: "true",
  pageName: ''
};


export const THIRD_PARTY_ACTIONS_CTA_CHECKOUT = {
  type: "actions",
  actiontype: "checkout",
  ItemId: "0012488399",
  ISBN: "9781223220154",
  title: "bookTitle",
  vendorCode: "VIDEOS",
  formatType: "VID",
  isAvailable: "true",
  pageName: ''
};

export const THIRD_PARTY_ACTIONS_CTA_RENEW = {
  type: "actions",
  actiontype: "renewtitle",
  ItemId: "0012488399",
  ISBN: "9781223220154",
  title: "bookTitle",
  vendorCode: "VIDEOS",
  formatType: "VID",
  isAvailable: "true",
  pageName: ''
};
export const THIRD_PARTY_ACTIONS_CTA_RETURN = {
  type: "actions",
  actiontype: "returntitle",
  ItemId: "0012488399",
  ISBN: "9781223220154",
  title: "bookTitle",
  vendorCode: "VIDEOS",
  formatType: "VID",
  isAvailable: "true",
  pageName: ''
};

export const THIRD_PARTY_DATA_VIDEOS = {
  searchTitleResponse: {
    searchTitleResult: {
      status: {
        code: "0000",
        statusMessage: "Titles Retrieved Successfully."
      },
      searchParameters: {
        searchType: "term",
        searchValue: "pets*",
        searchLabel: "pets*"
      },
      resultCount: "1",
      titlesList: {
        title: [
          {
            bookTitle: "<b>Pets</b>",
            authors: {
              author: ["Hamilton, Joseph (PRD)"],
              narrator: null
            },
            titleID: "0012488399",
            formatType: "VID",
            iSBN: "9781223220154",
            displayStatus: 'Due On 23 Hour(s)',
            imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220154/Medium/Logo",
            narrators: {
              author: null,
              narrator: []
            },
            publicationDate: "2022-06-18T00:00:00Z",
            series: "The Reading Road Trip",
            synopsis: "",
            purchaseOption: "MUPO",
            publisher: null,
            subject: "JUVENILE NONFICTION/Animals/Pets#JUVENILE NONFICTION/Crafts & Hobbies",
            edition: "",
            audience: "Children's - Grade 1-2, Age 6-7",
            isAvailable: "True",
            isBLIO: null,
            isAcoustik: null,
            isPDF: null,
            isEPub: null,
            isHold: null,
            isCheckout: "N",
            checkoutDetails: null,
            axisAttribute: "",
            language: "eng",
            audienceCode: "1-2",
            runTime: "",
            createdDate: "2022-12-07T13:17:28Z",
            rating: 0
          },
          {
            bookTitle: "<b>Pets</b>",
            authors: {
              author: ["Hamilton, Joseph (PRD)"],
              narrator: null
            },
            titleID: "0012488300",
            formatType: "VID",
            iSBN: "9781223220154",
            displayStatus: '',
            imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220154/Medium/Logo",
            narrators: {
              author: null,
              narrator: []
            },
            publicationDate: "2022-06-18T00:00:00Z",
            series: "The Reading Road Trip",
            synopsis: "",
            purchaseOption: "MUPO",
            publisher: null,
            subject: "JUVENILE NONFICTION/Animals/Pets#JUVENILE NONFICTION/Crafts & Hobbies",
            edition: "",
            audience: "Children's - Grade 1-2, Age 6-7",
            isAvailable: "True",
            isBLIO: null,
            isAcoustik: null,
            isPDF: null,
            isEPub: null,
            isHold: null,
            isCheckout: "N",
            checkoutDetails: null,
            axisAttribute: "",
            language: "eng",
            audienceCode: "1-2",
            runTime: "",
            createdDate: "2022-12-07T13:17:28Z",
            rating: 0
          }
        ]
      }
    }
  }
};


export const THIRD_PARTY_VIDEOS_FEATURED = [
  {
    bookTitle: "Animals That Aren't Here Anymore",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton, Joseph (PRD)"
      ]
    },
    titleID: "0012488397",
    formatType: "VID",
    iSBN: "9781223220130",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781223220130/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2021-06-03T00:00:00Z",
    series: "The Reading Road Trip",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB",
    subject: "JUVENILE NONFICTION/Animals/Dinosaurs & Prehistoric Creatures#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2022-12-01T16:32:28Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.87"
  },
  {
    bookTitle: "Desert Animals",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton, Joseph (PRD)"
      ]
    },
    titleID: "0012488398",
    formatType: "VID",
    iSBN: "9781223220147",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781223220147/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2021-06-11T00:00:00Z",
    series: "The Reading Road Trip",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB",
    subject: "JUVENILE NONFICTION/Animals/General#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2022-12-01T16:32:28Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.59"
  },
  {
    bookTitle: "Pets",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton, Joseph (PRD)"
      ]
    },
    titleID: "0012488399",
    formatType: "VID",
    iSBN: "9781223220154",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9781223220154/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2022-06-18T00:00:00Z",
    series: "The Reading Road Trip",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB",
    subject: "JUVENILE NONFICTION/Animals/Pets#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2022-12-01T16:32:28Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.21"
  },
  {
    bookTitle: "You Can Print a Watch?",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph"
      ]
    },
    titleID: "0099488344",
    formatType: "VID",
    iSBN: "9992223220027",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220027/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2024-01-19T14:43:58Z",
    series: "The Reading Road Trip: Full STEAM AHEAD",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Crafts & Hobbies#JUVENILE NONFICTION/Technology/General#JUVENILE NONFICTION/Technology/Inventions",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:06Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.18"
  },
  {
    bookTitle: "Shiver Me Timbers!",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph"
      ]
    },
    titleID: "0099488361",
    formatType: "VID",
    iSBN: "9992223220044",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220044/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2022-07-29T00:00:00Z",
    series: "The Reading Road Trip",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Crafts & Hobbies#JUVENILE NONFICTION/Curiosities & Wonders#JUVENILE NONFICTION/Social Science/Folklore & Mythology",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:08Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.18"
  },
  {
    bookTitle: "Sound the Alarm",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph"
      ]
    },
    titleID: "0099488347",
    formatType: "VID",
    iSBN: "9992223220030",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220030/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2024-01-19T14:43:58Z",
    series: "The Reading Road Trip: Full STEAM AHEAD",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Crafts & Hobbies#JUVENILE NONFICTION/Health & Daily Living/Safety#JUVENILE NONFICTION/Transportation/Cars & Trucks",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:06Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.18"
  },
  {
    bookTitle: "Forest Animals",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph"
      ]
    },
    titleID: "0099488334",
    formatType: "VID",
    iSBN: "9992223220017",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220017/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2022-07-29T00:00:00Z",
    series: "The Reading Road Trip",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Animals/General#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:05Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.18"
  },
  {
    bookTitle: "Creatures of the Sea",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph"
      ]
    },
    titleID: "0099488360",
    formatType: "VID",
    iSBN: "9992223220043",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220043/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2022-07-29T00:00:00Z",
    series: "The Reading Road Trip",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Animals/Marine Life#JUVENILE NONFICTION/Crafts & Hobbies",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:08Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.17"
  },
  {
    bookTitle: "ABC Rise Up and Be",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph",
        "Annemarie Riley Guertin (ILT)"
      ]
    },
    titleID: "0099488371",
    formatType: "VID",
    iSBN: "9992223220054",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220054/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2022-07-29T00:00:00Z",
    series: "Snoozer's Storytime Adventures",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Crafts & Hobbies#JUVENILE NONFICTION/Social Topics/Self-Esteem & Self-Reliance",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:09Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.17"
  },
  {
    bookTitle: "Atom",
    displayStatus: null,
    authors: {
      author: [
        "Hamilton Joseph",
        "David Miles (ILT)"
      ]
    },
    titleID: "0099488372",
    formatType: "VID",
    iSBN: "9992223220055",
    imageURL: "https://cccloudcdn.baker-taylor.com/Jacket.svc/D65D0665-050A-487B-9908-16E6D8FF5C3E/9992223220055/Medium/Logo",
    narrators: {
      narrator: []
    },
    publicationDate: "2022-07-29T00:00:00Z",
    series: "Snoozer's Storytime Adventures",
    synopsis: "",
    purchaseOption: "MUPO",
    publisher: "CHKEB               ",
    subject: "JUVENILE NONFICTION/Crafts & Hobbies#JUVENILE NONFICTION/Science & Nature/General #JUVENILE NONFICTION/Science & Nature/Chemistry",
    edition: "",
    audience: "Children's - Grade 1-2, Age 6-7",
    isAvailable: "True",
    isBLIO: null,
    isAcoustik: null,
    isPDF: null,
    isEPub: null,
    isHold: null,
    isCheckout: "N",
    checkoutDetails: null,
    axisAttribute: "",
    language: "eng",
    audienceCode: "1-2",
    runTime: "",
    createdDate: "2023-01-11T16:20:09Z",
    rating: 0,
    collectionId: "3430B643-9371-ED11-819C-0022481E03C3",
    score: "0.15"
  }
];

export const THIRD_PARTY_DATA_VIDEOS_EMPTY = {
  searchTitleResponse: {
    searchTitleResult: {
      status: {
        code: "4015",
        statusMessage: "No titles matched your search criteria"
      },
      searchParameters: {
        searchType: "keyword",
        searchValue: "the*",
        searchLabel: "the*"
      },
      resultCount: "0",
      titlesList: {
        title: []
      }
    }
  }
};


export const THIRD_PARTY_DATA_FEATURED_VIDEOS_EMPTY = {
  titlesCount: "0",
  resultCount: "0",
  featuredCarousel: {
    titlesList: {
      title: []
    }
  }
};

export const THIRD_PARTY_READING_PROCESS = {
  bookProgress: [
    {
      bookId: "0012488399",
      progressPercentage: 0,
    }
  ],
  responseCode: 200
};

export const THIRD_PARTY_READING_PROCESS_MOCK = {
  bookProgress: [
    {
      bookId: "0012488399",
      progressPercentage: 20,
    },
    {
      bookId: "0012487073",
      progressPercentage: 30,
    },
    {
      bookId: "0012488400",
      progressPercentage: 40,
    }
  ],
  responseCode: 200
};

export const THIRD_PARTY_READING_PROCESS_ERROR = {
  bookProgress: [
    {
      bookId: "0012488399",
      progressPercentage: 0,
    }
  ],
  responseCode: 500
};

export const THIRD_PARTY_READING_PROCESS_TIER3 = {
  bookProgress: [
    {
      bookId: "0012488400",
      progressPercentage: 0,
    }
  ],
  responseCode: 500
};

export const THIRD_PARTY_VIDEOS_DATA_TIER3 = {
  version: "1.0",
  encoding: "utf-8",
  itemDetailResponseV6: {
    "@xmlns": "http://axis360api.baker-taylor.com/vendorAPI",
    "@xmlns$xsi": "http://www.w3.org/2001/XMLSchema-instance",
    "@xmlns$xsd": "http://www.w3.org/2001/XMLSchema",
    getTitleResult: {
      status: {
        code: "0000",
        statusMessage: "Title details retrieved successfully"
      },
      titleInfo: {
        titleID: "0012488400",
        ISBN: "9781223220017",
        bookTitle: " Proud to Be Latino",
        authors: {
          author: "Mireles, Ashley Marie"
        },
        narrators: null,
        series: null,
        publicationDate: "06/01/2022",
        publisher: "VOOEB",
        subjectList: [
          { subject: "Juvenile Nonfiction", term: "Juvenile Nonfiction" },
          { subject: "Animals", term: "Juvenile Nonfiction/Animals" },
          { subject: "Dinosaurs & Prehistoric Creatures", term: "Juvenile Nonfiction/Animals/Dinosaurs & Prehistoric Creatures" },
          { subject: "Crafts & Hobbies", term: "Juvenile Nonfiction/Crafts & Hobbies" },
        ],
        subjects: {
          subject: {
            subjectName: "Juvenile Nonfiction/People & Places/United States/Hispanic & Latino; Juvenile Nonfiction/People & Places/Caribbean & Latin America",
            subjectCode: "Juvenile Nonfiction/People & Places/United States/Hispanic & Latino; Juvenile Nonfiction/People & Places/Caribbean & Latin America"
          }
        },
        synopsis: null,
        attributes: null,
        fileSize: "0.00KB",
        isInHoldQueue: "false",
        audioSampleAvailable: "N",
        edition: "Bilingual",
        audience: "Children's - Kindergarten, Age 5-6",
        formatType: "VideoBook",
        isAvailable: "N",
        imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220017/Large/Logo",
        isBLIO: "N",
        isEPub: "N",
        isPDF: "N",
        isAcoustik: "N",
        isCheckout: "N",
        isOnHold: "A",
        isWishList: "A",
        isSuspendHold: "N",
        holdsQueueSize: "0",
        holdDetails: {
          holdPosition: "0",
          estimatedHoldPeriod: "0"
        },
        titleURL: "http://demo.axis360dev1.baker-taylor.com/Title/TitleDetails?itemId=0012488400",
        language: "eng",
        annotation: null,
        runtime: "NA",
        minLoanPeriod: "1",
        maxLoanPeriod: "1",
        defaultLoanPeriod: "1",
        availableCopies: "0",
        totalCopies: "9999",
        isRenewButton: "N",
        acoustikItemID: null,
        IsXPSLicenseCreated: "False",
        IsACSLicenseCreated: "False",
        IsFNDLicenseCreated: "False",
        AxisAttribute: null,
        format: "VBK",
        shareURL: "http://demo.axis360dev1.baker-taylor.com/ng/view/title/0012488400/9781223220017",
        availableActions: {
          action: [
            {
              ActionID: "1",
              PrimaryAction: "Y",
              ActionDestination: "http://dev1axisvendorapiwebapp.azurewebsites.netaddtoHold/v2?titleId=0012488400&email=r2@bt.com&patronId=BTRanjith07&patronsessionId=#patronSessionID#&format=",
              ActionName: "Place On Hold",
              ActionType: "GET"
            },
            {
              ActionID: "2",
              PrimaryAction: "N",
              ActionDestination: "http://dev1axisvendorapiwebapp.azurewebsites.netaddToWishlist/v2?itemID=0012488400&patronID=BTRanjith07&patronSessionID=#patronSessionID#",
              ActionName: "Add To Wish List",
              ActionType: "GET"
            }
          ]
        },
        RTV: "false",
        isCheckedout: "true",
        isRecommendation: "N",
        isRecommend: "N",
        isPPC: "N",
        isLibraryAccessibleTitle: "Y",
        AverageRating: "0",
        TotalRating: "0",
        OthersReview: null,
        LibraryPPCBudget: {
          LibraryID: "57C86A8B-E3B9-E011-9A12-6431504A306A",
          LibraryKey: "1030",
          PPCDailyBudget: "3.33",
          PPCMonthlyBudget: "100",
          PPCNotificationsEmailAddresses: "Veerendra.Gadde@baker-taylor.com,raghavendra.ashrit@baker-taylor.com,vinay.dwivedi@baker-taylor.com,deepak.yadav@magicedtech.com,deepak.kumar@magicedtech.com,deepak.yadav@baker-taylor.com,deepak.kumar2@baker-taylor.com",
          PPCRolloverBudget: "9.99",
          RemainingTodaysBudget: "13.15",
          TodaysTotalBudget: "13.15",
          TodaysTotalBudgetWithoutBuffer: "13.32"
        },
        BookLength: "0"
      },
      IsEmailUpdated: "true"
    }
  }
};

export const THIRD_PARTY_VIDEOS_DATA_TIER3_1 = {
  version: "1.0",
  encoding: "utf-8",
  itemDetailResponseV6: {
    "@xmlns": "http://axis360api.baker-taylor.com/vendorAPI",
    "@xmlns$xsi": "http://www.w3.org/2001/XMLSchema-instance",
    "@xmlns$xsd": "http://www.w3.org/2001/XMLSchema",
    getTitleResult: {
      status: {
        code: "0000",
        statusMessage: "Title details retrieved successfully"
      },
      titleInfo: {
        titleID: "0012488400",
        ISBN: "9781223220017",
        bookTitle: " Proud to Be Latino",
        authors: {
          author: "Mireles, Ashley Marie"
        },
        narrators: null,
        series: null,
        publicationDate: "06/01/2022",
        publisher: "VOOEB",
        subjects: {
          subject: {
            subjectName: "Juvenile Nonfiction/People & Places/United States/Hispanic & Latino; Juvenile Nonfiction/People & Places/Caribbean & Latin America",
            subjectCode: "Juvenile Nonfiction/People & Places/United States/Hispanic & Latino; Juvenile Nonfiction/People & Places/Caribbean & Latin America"
          }
        },
        synopsis: null,
        attributes: null,
        fileSize: "0.00KB",
        isInHoldQueue: "false",
        audioSampleAvailable: "N",
        edition: "Bilingual",
        audience: "Children's - Kindergarten, Age 5-6",
        formatType: "VideoBook",
        isAvailable: "N",
        imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220017/Large/Logo",
        isBLIO: "N",
        isEPub: "N",
        isPDF: "N",
        isAcoustik: "N",
        isCheckout: "N",
        isOnHold: "A",
        isWishList: "A",
        isSuspendHold: "N",
        holdsQueueSize: "0",
        holdDetails: {
          holdPosition: "0",
          estimatedHoldPeriod: "0"
        },
        titleURL: "http://demo.axis360dev1.baker-taylor.com/Title/TitleDetails?itemId=0012488400",
        language: "eng",
        annotation: null,
        runtime: "NA",
        minLoanPeriod: "1",
        maxLoanPeriod: "1",
        defaultLoanPeriod: "1",
        availableCopies: "0",
        totalCopies: "9999",
        isRenewButton: "N",
        acoustikItemID: null,
        IsXPSLicenseCreated: "False",
        IsACSLicenseCreated: "False",
        IsFNDLicenseCreated: "False",
        AxisAttribute: null,
        format: "VBK",
        shareURL: "http://demo.axis360dev1.baker-taylor.com/ng/view/title/0012488400/9781223220017",
        availableActions: {
          action: [
            {
              ActionID: "1",
              PrimaryAction: "Y",
              ActionDestination: "http://dev1axisvendorapiwebapp.azurewebsites.netaddtoHold/v2?titleId=0012488400&email=r2@bt.com&patronId=BTRanjith07&patronsessionId=#patronSessionID#&format=",
              ActionName: "Place On Hold",
              ActionType: "GET"
            },
            {
              ActionID: "2",
              PrimaryAction: "N",
              ActionDestination: "http://dev1axisvendorapiwebapp.azurewebsites.netaddToWishlist/v2?itemID=0012488400&patronID=BTRanjith07&patronSessionID=#patronSessionID#",
              ActionName: "Add To Wish List",
              ActionType: "GET"
            }
          ]
        },
        RTV: "false",
        isCheckedout: "false",
        isRecommendation: "N",
        isRecommend: "N",
        isPPC: "N",
        isLibraryAccessibleTitle: "Y",
        AverageRating: "0",
        TotalRating: "0",
        OthersReview: null,
        LibraryPPCBudget: {
          LibraryID: "57C86A8B-E3B9-E011-9A12-6431504A306A",
          LibraryKey: "1030",
          PPCDailyBudget: "3.33",
          PPCMonthlyBudget: "100",
          PPCNotificationsEmailAddresses: "Veerendra.Gadde@baker-taylor.com,raghavendra.ashrit@baker-taylor.com,vinay.dwivedi@baker-taylor.com,deepak.yadav@magicedtech.com,deepak.kumar@magicedtech.com,deepak.yadav@baker-taylor.com,deepak.kumar2@baker-taylor.com",
          PPCRolloverBudget: "9.99",
          RemainingTodaysBudget: "13.15",
          TodaysTotalBudget: "13.15",
          TodaysTotalBudgetWithoutBuffer: "13.32"
        },
        BookLength: "0"
      },
      IsEmailUpdated: "true"
    }
  }
};

export const THIRD_PARTY_MYSTUFF_RESPONSE = {
  vendor: {
    name: "Videos",
    display_name: "Videos",
    code: "VIDEOS",
    urlAlias: "/videos"
  },
  jsonTempl: {
    type: "AdaptiveCard",
    body: [
      {
        type: "ColumnSet",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "Container",
                selectAction: {
                  type: "Action.Submit",
                  data: {
                    type: "title",
                    ItemId: "${ItemId}",
                    ISBN: "${ISBN}",
                    formatType: "${FormatType}",
                    isAvailable: "${IsAvailable}",
                    title: "${Title}",
                    series: "${series}",
                    vendorCode: "${if(FormatType == 'VID','VIDEOS','VBOOKS')}"
                  }
                },
                backgroundImage: {
                  url: "https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/${ISBN}/Large/Logo"
                },
                items: [
                  {
                    type: "Image",
                    style: "Person",
                    $when: "${FormatType == 'VID'}",
                    url: "${if(IsAvailable == true,'https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                    size: "Small",
                    horizontalAlignment: "Right",
                    width: "24px",
                    height: "24px"
                  },
                  {
                    type: "Image",
                    style: "Person",
                    $when: "${FormatType == 'VBK'}",
                    url: "${if(IsAvailable == true,'https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                    size: "Small",
                    horizontalAlignment: "Right",
                    width: "24px",
                    height: "24px"
                  }
                ],
                height: "stretch",
                minHeight: "192px"
              }
            ]
          }
        ],
        minHeight: "50px"
      },
      {
        type: "ColumnSet",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "ActionSet",
                actions: [
                  {
                    type: "Action.Submit",
                    title: "${if(resumeCTA,resumeCTA,'Play')}",
                    id: "${ItemId}",
                    data: {
                      type: "play",
                      ItemId: "${ItemId}",
                      ISBN: "${ISBN}",
                      title: "${Title}",
                      vendorCode: "${if(FormatType == 'VID','VIDEOS','VBOOKS')}",
                      formatType: "${FormatType}",
                      isAvailable: "${IsAvailable}"
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5",
    verticalContentAlignment: "Top"
  }
}

export const THIRD_PARTY_MYSTUFF_LAYOUT = [
  {
    title: "MyStuff",
    pageType: "Home",
    vendor: {
      name: "Videos",
      display_name: "Videos",
      code: "VIDEOS",
      urlAlias: "/videos"
    },
    layout: {
      column_widths: "100%",
      components: [
        {
          region: 1,
          title: "Mystuff",
          description: null,
          type: "Article_Carousel",
          subType: null,
          hideSeeAllLink: false,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://dev1axis360-apim.azure-api.net/kidszone/api/v2/ThirdPartySearch/v1",
            method: "GET",
            headers: {
              api_auth_key: "",
              authorization: "",
              mylibrary: "",
              "ocp-apim-subscription-key": "",
              patronid: "",
              profileid: ""
            },
            body: null,
            pathParams: null,
            search: {
              searhSort: "",
              sortOrder: "",
              pageNumber: "",
              pageSize: "",
              formatType: "",
              audienceDesc: "",
              collectionType: "",
              contentCollectionId: "73335E67-5472-ED11-819D-000D3A1EC91D",
              searchType: "",
              searchTerm: ""
            },
            app: {
              searhSort: "false",
              sortOrder: "false",
              pageNumber: "false",
              pageSize: "false",
              formatType: "false",
              audienceDesc: "false",
              collectionType: "false",
              contentCollectionId: "false",
              searchType: "false",
              searchTerm: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            body: [
              {
                type: "ColumnSet",
                columns: [
                  {
                    type: "Column",
                    width: "stretch",
                    items: [
                      {
                        type: "Container",
                        selectAction: {
                          type: "Action.Submit",
                          data: {
                            type: "title",
                            ItemId: "${ItemId}",
                            ISBN: "${ISBN}",
                            formatType: "${FormatType}",
                            isAvailable: "${IsAvailable}",
                            title: "${Title}",
                            series: "${series}",
                            vendorCode: "${if(FormatType == 'VID','VIDEOS','VBOOKS')}"
                          }
                        },
                        backgroundImage: {
                          url: "https://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/${ISBN}/Large/Logo"
                        },
                        items: [
                          {
                            type: "Image",
                            style: "Person",
                            $when: "${FormatType == 'VID'}",
                            url: "${if(IsAvailable == true,'https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                            size: "Small",
                            horizontalAlignment: "Right",
                            width: "24px",
                            height: "24px"
                          },
                          {
                            type: "Image",
                            style: "Person",
                            $when: "${FormatType == 'VBK'}",
                            url: "${if(IsAvailable == true,'https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                            size: "Small",
                            horizontalAlignment: "Right",
                            width: "24px",
                            height: "24px"
                          }
                        ],
                        height: "stretch",
                        minHeight: "192px"
                      }
                    ]
                  }
                ],
                minHeight: "50px"
              },
              {
                type: "ColumnSet",
                columns: [
                  {
                    type: "Column",
                    width: "stretch",
                    items: [
                      {
                        type: "ActionSet",
                        actions: [
                          {
                            type: "Action.Submit",
                            title: "${if(resumeCTA,resumeCTA,'Play')}",
                            id: "${ItemId}",
                            data: {
                              type: "play",
                              ItemId: "${ItemId}",
                              ISBN: "${ISBN}",
                              title: "${Title}",
                              vendorCode: "${if(FormatType == 'VID','VIDEOS','VBOOKS')}",
                              formatType: "${FormatType}",
                              isAvailable: "${IsAvailable}"
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5",
            verticalContentAlignment: "Top"
          }
        }
      ]
    }
  }
];

export const THIRD_PARTY_MYSTUFF_LAYOUT_EMPTY = [
  {
    title: "MyStuff",
    pageType: "Home",
    vendor: {
      name: "Videos",
      display_name: "Videos",
      code: "VIDEOS",
      urlAlias: "/videos"
    },
    layout: undefined
  }
];

export const LIST_CONTENT_THIRD_PARTY_MOCK: ListContent = {
  listName: '',
  TotalItems: 13,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: [
    {
      ISBN: '9780545675765',
      ItemId: '0028794309',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Amulet 1',
      Title: 'Amulet 1',
      Author: 'Kibuishi, Kazu ; Kibuishi, Kazu (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761362258',
      ItemId: '0009241606',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'And Then There Were Gnomes',
      Title: 'And Then There Were Gnomes',
      Author: 'Venable, Colleen AF ; Yue, Stephanie (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 3,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781682558225',
      ItemId: '0026624013',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Archie',
      Title: 'Archie',
      Author: 'Waid, Mark ; Augustyn, Brian ; Krause, Peter (ILT)',
      Narrator: null,
      FormatType: 'VID',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 2,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9781602704152',
      ItemId: '0009130899',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Around the World in 80 Days',
      Title: 'Around the World in 80 Days',
      Author: 'Verne, Jules ; Espinosa, Rod (ILT)',
      Narrator: null,
      FormatType: 'VBK',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 4,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761359159',
      ItemId: '0009203068',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Attack of the Mutant Meteors',
      Title: 'Attack of the Mutant Meteors',
      Author: 'Jolley, Dan ; Witt, David (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
  ]
};

export const LIST_CONTENT_THIRD_PARTY_MOCK_NOT_VID_VBK: ListContent = {
  listName: '',
  TotalItems: 13,
  PageNum: 1,
  PageSize: 12,
  SortField: 'Title',
  SortOrder: -1,
  Filter: {
    Available: 'All',
    Format: 'All',
    Audience: null,
    Language: null,
    ReleaseDate: 0,
    AddedDate: 0,
    RTV: 'ALL'
  },
  Items: [
    {
      ISBN: '9780545675765',
      ItemId: '0028794309',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Amulet 1',
      Title: 'Amulet 1',
      Author: 'Kibuishi, Kazu ; Kibuishi, Kazu (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761362258',
      ItemId: '0009241606',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'And Then There Were Gnomes',
      Title: 'And Then There Were Gnomes',
      Author: 'Venable, Colleen AF ; Yue, Stephanie (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 3,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
    {
      ISBN: '9780761359159',
      ItemId: '0009203068',
      LibraryId: '538C7225-97BF-E011-B97F-005056936C17',
      ListId: '72984',
      LongTitle: 'Attack of the Mutant Meteors',
      Title: 'Attack of the Mutant Meteors',
      Author: 'Jolley, Dan ; Witt, David (ILT)',
      Narrator: null,
      FormatType: 'EBT',
      IsAvailable: true,
      IsRTV: true,
      IsRecommendable: false,
      TotalQuantity: 1,
      OnOrderQuantity: 0,
      RTVFormats: null,
      ActiveHolds: 0,
      ListName: 'Comic & Graphic Novels ',
      SortField: 'Title',
      CollectionType: 'Default',
      Edition: null,
      Length: null
    },
  ]
};

export const THIRD_PARTY_ARTICLES = {
  items: [
    {
      publication: {
        cid: "9593",
        title: "Fast Ford",
        countries: ["au", "ca", "gb", "us"],
        language: "en",
        url: "https://www.pressreader.com/australia/fast-ford",
        mastheads: null
      },
      issue: {
        date: "2023-02-01T00:00:00",
        url: "https://www.pressreader.com/australia/fast-ford/20230201"
      },
      page: {
        number: 4,
        url: "https://www.pressreader.com/australia/fast-ford/20230201/page/4"
      },
      article: {
        id: 281530820075651,
        title: "NEWS",
        subTitle: "See what’s going down in the world of fast Fords...",
        author: null,
        date: "2023-02-01T00:00:00+00:00",
        language: "en",
        url: "https://www.pressreader.com/article/281530820075651",
        media: [],
        body: null
      },
      content: ["What's new in the Ford world?"]
    },
    {
      publication: {
        cid: "9i75",
        title: "Cruising World",
        countries: ["us"],
        language: "en",
        url: "https://www.pressreader.com/usa/cruising-world",
        mastheads: null
      },
      issue: {
        date: "2023-02-01T00:00:00",
        url: "https://www.pressreader.com/usa/cruising-world/20230201"
      },
      page: {
        number: 10,
        url: "https://www.pressreader.com/usa/cruising-world/20230201/page/10"
      },
      article: {
        id: 282806425329224,
        title: "Cruising World Weekly",
        subTitle: null,
        author: null,
        date: "2023-02-01T00:00:00+00:00",
        language: "en",
        url: "https://www.pressreader.com/article/282806425329224",
        media: [
          {
            type: 1,
            typeName: "Image",
            width: 117,
            height: 191,
            url: "https://i.prcdn.co/img?regionKey=j90xP%2BZmJH4szu%2F9uak7bw%3D%3D",
            thumbnailUrl: null,
            title: "",
            author: null
          }
        ],
        body: null
      },
      content: [
        "Get all the latest from Cruising World. Sign up for our weekly email newsletter, and stay in the loop on our top headlines and current events. cruisingworld.com/newsletter"
      ]
    },
    {
      publication: {
        cid: "9498",
        title: "Porthole Cruise and Travel",
        countries: ["_i", "us"],
        language: "en",
        url: "https://www.pressreader.com/international/porthole-cruise-and-travel",
        mastheads: null
      },
      issue: {
        date: "2023-02-01T00:00:00",
        url: "https://www.pressreader.com/international/porthole-cruise-and-travel/20230201"
      },
      page: {
        number: 7,
        url: "https://www.pressreader.com/international/porthole-cruise-and-travel/20230201/page/7"
      },
      article: {
        id: 281543704983184,
        title: "John Roberts,",
        subTitle: null,
        author: null,
        date: "2023-02-01T00:00:00+00:00",
        language: "en",
        url: "https://www.pressreader.com/article/281543704983184",
        media: [
          {
            type: 1,
            typeName: "Image",
            width: 73,
            height: 115,
            url: "https://i.prcdn.co/img?regionKey=Ln7jgqVvLkcgI0YgwR%2FKlQ%3D%3D",
            thumbnailUrl: null,
            title: "",
            author: null
          }
        ],
        body: null
      },
      content: []
    }
  ],
  meta: {
    totalCount: 5507124,
    offset: 0,
    limit: 12
  }
};

export const THIRD_PARTY_TIER_TWO_REFINERS_VIDEO = {
  refiner_list: [
    {
      listname: "age",
      options: [
        {
          bookCount: 278,
          displayName: "Children's - Grade 4-6, Age 9-11",
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          value: "Children's - Grade 4-6, Age 9-11"
        },
        {
          bookCount: 71,
          displayName: "Children's - Grade 1-2, Age 6-7",
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          value: "Children's - Grade 1-2, Age 6-7"
        },
        {
          bookCount: 18,
          displayName: "Teen - Grade 7-9, Age 12-14",
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          value: "Teen - Grade 7-9, Age 12-14"
        },
        {
          bookCount: 1,
          displayName: "Children's - Babies, Age 0-29",
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          value: "Children's - Babies, Age 0-29"
        }
      ]
    },
    {
      listname: "language",
      options: [
        {
          bookCount: 547,
          displayName: "English",
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          value: "ENGLISH"
        }
      ]
    },
    {
      listname: "publisher",
      options: []
    }
  ]
};

export const THIRD_PARTY_REFINER_MOCK = {
  countries: [
    {
      categories: null,
      id: "938",
      name: "International",
      displayName: "International",
      slug: "international",
      iso: "_i",
      publicationsCount: 12,
      titlePublicationCid: "sats",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "940",
      name: "Iran",
      displayName: "Iran",
      slug: "iran",
      iso: "ir",
      publicationsCount: 29,
      titlePublicationCid: "4a03",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "941",
      name: "Iraq",
      displayName: "Iraq",
      slug: "iraq",
      iso: "iq",
      publicationsCount: 6,
      titlePublicationCid: "9i72",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "942",
      name: "Ireland [country]",
      displayName: "Ireland",
      slug: "ireland",
      iso: "ie",
      publicationsCount: 57,
      titlePublicationCid: "9vgq",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "949",
      name: "Israel",
      displayName: "Israel",
      slug: "israel",
      iso: "il",
      publicationsCount: 9,
      titlePublicationCid: "1007",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "951",
      name: "Italy [country]",
      displayName: "Italy",
      slug: "italy",
      iso: "it",
      publicationsCount: 345,
      titlePublicationCid: "3501",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "953",
      name: "Jamaica",
      displayName: "Jamaica",
      slug: "jamaica",
      iso: "jm",
      publicationsCount: 20,
      titlePublicationCid: "9vgq",
      isCountry: true,
      displayNameLang: "en"
    }
  ],
  languages: [
    {
      categories: null,
      id: "536",
      name: "Afrikaans",
      displayName: "Afrikaans",
      slug: "afrikaans",
      iso: "af",
      publicationsCount: 48,
      titlePublicationCid: "9k94",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "540",
      name: "Albanian [language]",
      displayName: "Albanian",
      slug: "albanian",
      iso: "sq",
      publicationsCount: 4,
      titlePublicationCid: "0814",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "555",
      name: "Arabic",
      displayName: "Arabic",
      slug: "arabic",
      iso: "ar",
      publicationsCount: 98,
      titlePublicationCid: "4271",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "560",
      name: "Armenian [language]",
      displayName: "Armenian",
      slug: "armenian",
      iso: "hy",
      publicationsCount: 1,
      titlePublicationCid: "sgj3",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "588",
      name: "Belarusian [language]",
      displayName: "Belarusian",
      slug: "belarusian",
      iso: "be",
      publicationsCount: 5,
      titlePublicationCid: "0247",
      isCountry: false,
      displayNameLang: "en"
    }
  ],
  publicationtypes: [
    {
      categories: null,
      id: "150994944",
      name: "Magazines",
      displayName: "Magazines",
      slug: "magazines",
      iso: null,
      publicationsCount: 4333,
      titlePublicationCid: "9g84",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "142606336",
      name: "Newspapers",
      displayName: "Newspapers",
      slug: "newspapers",
      iso: null,
      publicationsCount: 2964,
      titlePublicationCid: "1545",
      isCountry: false,
      displayNameLang: "en"
    }
  ],
  categories: [
    {
      categories: null,
      id: "1574",
      name: "Animals & Pets",
      displayName: "Animals & Pets",
      slug: "animals-pets",
      iso: null,
      publicationsCount: 63,
      titlePublicationCid: "9g33",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "AP"
    },
    {
      categories: null,
      id: "1575",
      name: "Art",
      displayName: "Art",
      slug: "art",
      iso: null,
      publicationsCount: 149,
      titlePublicationCid: "9fe0",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "AR"
    },
    {
      categories: null,
      id: "1068",
      name: "Automotive",
      displayName: "Automotive",
      slug: "automotive",
      iso: null,
      publicationsCount: 257,
      titlePublicationCid: "9f58",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "AU"
    },
    {
      categories: null,
      id: "2704",
      name: "Aviation",
      displayName: "Aviation",
      slug: "aVIATION",
      iso: "",
      publicationsCount: 11,
      titlePublicationCid: "6625",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "AV"
    },
    {
      categories: null,
      id: "1069",
      name: "Business & Current Affairs",
      displayName: "Business & Current Affairs",
      slug: "business-current-affairs",
      iso: null,
      publicationsCount: 604,
      titlePublicationCid: "7434",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "BC"
    },
    {
      categories: null,
      id: "1576",
      name: "Boating",
      displayName: "Boating",
      slug: "boating",
      iso: "",
      publicationsCount: 44,
      titlePublicationCid: "f160",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "BO"
    },
    {
      categories: null,
      id: "1067",
      name: "Crafts & Hobbies",
      displayName: "Crafts & Hobbies",
      slug: "crafts-hobbies",
      iso: null,
      publicationsCount: 208,
      titlePublicationCid: "9y63",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "CH"
    },
    {
      categories: null,
      id: "1073",
      name: "Computers & Tech",
      displayName: "Computers & Tech",
      slug: "computers-technology",
      iso: null,
      publicationsCount: 185,
      titlePublicationCid: "9559",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "CT"
    },
    {
      categories: null,
      id: "2275",
      name: "Design",
      displayName: "Design",
      slug: "design",
      iso: null,
      publicationsCount: 86,
      titlePublicationCid: "9fe0",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "DE"
    },
    {
      categories: null,
      id: "1071",
      name: "Entertainment & TV",
      displayName: "Entertainment & TV",
      slug: "entertainment-tv",
      iso: "",
      publicationsCount: 395,
      titlePublicationCid: "9wsp",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "ET"
    },
    {
      categories: null,
      id: "1074",
      name: "Fashion",
      displayName: "Fashion",
      slug: "fashion",
      iso: null,
      publicationsCount: 247,
      titlePublicationCid: "9yrr",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "FA"
    },
    {
      categories: null,
      id: "1523",
      name: "Food & Drinks",
      displayName: "Food & Drinks",
      slug: "food-drinks",
      iso: null,
      publicationsCount: 322,
      titlePublicationCid: "9f75",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "FD"
    },
    {
      categories: null,
      id: "1644",
      name: "Gaming",
      displayName: "Gaming",
      slug: "gaming",
      iso: null,
      publicationsCount: 52,
      titlePublicationCid: "9636",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "GA"
    },
    {
      categories: null,
      id: "1578",
      name: "Health & Fitness",
      displayName: "Health & Fitness",
      slug: "health-fitness",
      iso: "",
      publicationsCount: 240,
      titlePublicationCid: "2959",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "HF"
    },
    {
      categories: null,
      id: "1532",
      name: "Home & Garden",
      displayName: "Home & Garden",
      slug: "home-garden",
      iso: "",
      publicationsCount: 377,
      titlePublicationCid: "9htv",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "HG"
    },
    {
      categories: null,
      id: "1520",
      name: "History & Science",
      displayName: "History & Science",
      slug: "history-science",
      iso: null,
      publicationsCount: 399,
      titlePublicationCid: "9fgk",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "HS"
    },
    {
      categories: null,
      id: "1070",
      name: "Kids & Tweens",
      displayName: "Kids & Tweens",
      slug: "for-kids-tweens",
      iso: "",
      publicationsCount: 159,
      titlePublicationCid: "9ybw",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "KT"
    },
    {
      categories: null,
      id: "1580",
      name: "LGBTQ",
      displayName: "LGBTQ",
      slug: "lgbtq",
      iso: "",
      publicationsCount: 16,
      titlePublicationCid: "9hqb",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "LG"
    },
    {
      categories: null,
      id: "2128",
      name: "Local Living",
      displayName: "Local Living",
      slug: "local-living",
      iso: null,
      publicationsCount: 145,
      titlePublicationCid: "9491",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "LL"
    },
    {
      categories: null,
      id: "1072",
      name: "Men",
      displayName: "Men",
      slug: "for-men",
      iso: null,
      publicationsCount: 99,
      titlePublicationCid: "9f64",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "ME"
    },
    {
      categories: null,
      id: "2127",
      name: "Music",
      displayName: "Music",
      slug: "music",
      iso: null,
      publicationsCount: 65,
      titlePublicationCid: "9wsp",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "MU"
    },
    {
      categories: null,
      id: "1124",
      name: "News",
      displayName: "News",
      slug: "news",
      iso: null,
      publicationsCount: 2640,
      titlePublicationCid: "7434",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "NE"
    },
    {
      categories: null,
      id: "2129",
      name: "Outdoors",
      displayName: "Outdoors",
      slug: "outdoors",
      iso: null,
      publicationsCount: 90,
      titlePublicationCid: "59an",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "OU"
    },
    {
      categories: null,
      id: "1582",
      name: "Parenting & Family",
      displayName: "Parenting & Family",
      slug: "parenting-family",
      iso: null,
      publicationsCount: 47,
      titlePublicationCid: "2956",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "PF"
    },
    {
      categories: null,
      id: "2274",
      name: "Photography",
      displayName: "Photography",
      slug: "photography",
      iso: null,
      publicationsCount: 52,
      titlePublicationCid: "9486",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "PH"
    },
    {
      categories: null,
      id: "1581",
      name: "Religion & Spirituality",
      displayName: "Religion & Spirituality",
      slug: "religion-spirituality",
      iso: null,
      publicationsCount: 35,
      titlePublicationCid: "8871",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "RS"
    },
    {
      categories: null,
      id: "1075",
      name: "Sports",
      displayName: "Sports",
      slug: "sports",
      iso: "",
      publicationsCount: 268,
      titlePublicationCid: "f605",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "SP"
    },
    {
      categories: null,
      id: "1076",
      name: "Travel & Culture",
      displayName: "Travel & Culture",
      slug: "travel-culture",
      iso: "",
      publicationsCount: 541,
      titlePublicationCid: "9bls",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "TC"
    },
    {
      categories: null,
      id: "1077",
      name: "Women",
      displayName: "Women",
      slug: "for-women",
      iso: null,
      publicationsCount: 311,
      titlePublicationCid: "9yrr",
      isCountry: false,
      displayNameLang: "en",
      featureImage: "WO"
    }
  ],
  defaultValues: {
    orderBy: "rank",
    inVal: "940",
    inVal1: "555",
    inVal2: "1574"
  }
}

export const FILLTER_OBJECT = {
  countries: [
    {
      categories: null,
      id: "938",
      name: "International",
      displayName: "International",
      slug: "international",
      iso: "_i",
      publicationsCount: 12,
      titlePublicationCid: "sats",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "539",
      name: "Albania [country]",
      displayName: "Albania",
      slug: "albania",
      iso: "al",
      publicationsCount: 5,
      titlePublicationCid: "0814",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "544",
      name: "Algeria",
      displayName: "Algeria",
      slug: "algeria",
      iso: "dz",
      publicationsCount: 2,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1811",
      name: "Andorra [country]",
      displayName: "Andorra",
      slug: "andorra",
      iso: "ad",
      publicationsCount: 2,
      titlePublicationCid: "24d9",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "550",
      name: "Angola",
      displayName: "Angola",
      slug: "angola",
      iso: "ao",
      publicationsCount: 14,
      titlePublicationCid: "340x",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "558",
      name: "Argentina",
      displayName: "Argentina",
      slug: "argentina",
      iso: "ar",
      publicationsCount: 134,
      titlePublicationCid: "2260",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "559",
      name: "Armenia [country]",
      displayName: "Armenia",
      slug: "armenia",
      iso: "am",
      publicationsCount: 1,
      titlePublicationCid: "sgj3",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "569",
      name: "Australia [country]",
      displayName: "Australia",
      slug: "australia",
      iso: "au",
      publicationsCount: 385,
      titlePublicationCid: "9j66",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "570",
      name: "Austria",
      displayName: "Austria",
      slug: "austria",
      iso: "at",
      publicationsCount: 64,
      titlePublicationCid: "3004",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "573",
      name: "Azerbaijan",
      displayName: "Azerbaijan",
      slug: "azerbaijan",
      iso: "az",
      publicationsCount: 3,
      titlePublicationCid: "1354",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1815",
      name: "Bahamas",
      displayName: "Bahamas",
      slug: "bahamas",
      iso: "bs",
      publicationsCount: 18,
      titlePublicationCid: "9vgq",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "577",
      name: "Bahrain",
      displayName: "Bahrain",
      slug: "bahrain",
      iso: "bh",
      publicationsCount: 16,
      titlePublicationCid: "9id9",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1816",
      name: "Bangladesh [country]",
      displayName: "Bangladesh",
      slug: "bangladesh",
      iso: "bd",
      publicationsCount: 1,
      titlePublicationCid: "9ghs",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "582",
      name: "Barbados [country]",
      displayName: "Barbados",
      slug: "barbados",
      iso: "bb",
      publicationsCount: 1,
      titlePublicationCid: "9lj3",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "585",
      name: "Belarus [country]",
      displayName: "Belarus",
      slug: "belarus",
      iso: "by",
      publicationsCount: 31,
      titlePublicationCid: "0233",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "586",
      name: "Belgium",
      displayName: "Belgium",
      slug: "belgium",
      iso: "be",
      publicationsCount: 13,
      titlePublicationCid: "2530",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1818",
      name: "Benin",
      displayName: "Benin",
      slug: "benin",
      iso: "bj",
      publicationsCount: 2,
      titlePublicationCid: "9lza",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1820",
      name: "Bolivia",
      displayName: "Bolivia",
      slug: "bolivia",
      iso: "bo",
      publicationsCount: 7,
      titlePublicationCid: "34p4",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "594",
      name: "Bosnia and Herzegovina",
      displayName: "Bosnia and Herzegovina",
      slug: "bosnia-and-herzegovina",
      iso: "ba",
      publicationsCount: 6,
      titlePublicationCid: "0980",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1822",
      name: "Botswana",
      displayName: "Botswana",
      slug: "botswana",
      iso: "bw",
      publicationsCount: 9,
      titlePublicationCid: "9fhf",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "599",
      name: "Brazil [country]",
      displayName: "Brazil",
      slug: "brazil",
      iso: "br",
      publicationsCount: 275,
      titlePublicationCid: "9lw1",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1823",
      name: "Brunei",
      displayName: "Brunei",
      slug: "brunei",
      iso: "bn",
      publicationsCount: 2,
      titlePublicationCid: "efba",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "601",
      name: "Bulgaria [country]",
      displayName: "Bulgaria",
      slug: "bulgaria",
      iso: "bg",
      publicationsCount: 1,
      titlePublicationCid: "0533",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1826",
      name: "Cabo Verde",
      displayName: "Cabo Verde",
      slug: "cabo-verde",
      iso: "cv",
      publicationsCount: 17,
      titlePublicationCid: "9yb8",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "613",
      name: "Cambodia",
      displayName: "Cambodia",
      slug: "cambodia",
      iso: "kh",
      publicationsCount: 6,
      titlePublicationCid: "6203",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "615",
      name: "Canada",
      displayName: "Canada",
      slug: "canada",
      iso: "ca",
      publicationsCount: 399,
      titlePublicationCid: "1893",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "627",
      name: "Chile",
      displayName: "Chile",
      slug: "chile",
      iso: "cl",
      publicationsCount: 22,
      titlePublicationCid: "2044",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "628",
      name: "China [country]",
      displayName: "China",
      slug: "china",
      iso: "cn",
      publicationsCount: 283,
      titlePublicationCid: "6150",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "638",
      name: "Colombia",
      displayName: "Colombia",
      slug: "colombia",
      iso: "co",
      publicationsCount: 51,
      titlePublicationCid: "34u1",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1830",
      name: "Comoros",
      displayName: "Comoros",
      slug: "comoros",
      iso: "km",
      publicationsCount: 1,
      titlePublicationCid: "9eam",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1832",
      name: "Congo, Democratic Republic of the",
      displayName: "Congo, Democratic Republic of the",
      slug: "congo-democratic-republic",
      iso: "cd",
      publicationsCount: 1,
      titlePublicationCid: "9lz6",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1831",
      name: "Congo, Republic of the",
      displayName: "Congo, Republic of the",
      slug: "congo-republic",
      iso: "cg",
      publicationsCount: 1,
      titlePublicationCid: "9lza",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "655",
      name: "Costa Rica",
      displayName: "Costa Rica",
      slug: "costa-rica",
      iso: "cr",
      publicationsCount: 20,
      titlePublicationCid: "ea61",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "657",
      name: "Croatia [country]",
      displayName: "Croatia",
      slug: "croatia",
      iso: "hr",
      publicationsCount: 9,
      titlePublicationCid: "0503",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1835",
      name: "Cuba",
      displayName: "Cuba",
      slug: "cuba",
      iso: "cu",
      publicationsCount: 19,
      titlePublicationCid: "ed72",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "659",
      name: "Cyprus [country]",
      displayName: "Cyprus",
      slug: "cyprus",
      iso: "cy",
      publicationsCount: 5,
      titlePublicationCid: "4606",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "661",
      name: "Czech Republic [country]",
      displayName: "Czech Republic",
      slug: "czech-republic",
      iso: "cz",
      publicationsCount: 5,
      titlePublicationCid: "0501",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "680",
      name: "Denmark",
      displayName: "Denmark",
      slug: "denmark",
      iso: "dk",
      publicationsCount: 60,
      titlePublicationCid: "3216",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1837",
      name: "Djibouti",
      displayName: "Djibouti",
      slug: "djibouti",
      iso: "dj",
      publicationsCount: 1,
      titlePublicationCid: "9eam",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "733",
      name: "Dominican Republic",
      displayName: "Dominican Republic",
      slug: "dominican-republic",
      iso: "do",
      publicationsCount: 13,
      titlePublicationCid: "3750",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "742",
      name: "Ecuador",
      displayName: "Ecuador",
      slug: "ecuador",
      iso: "ec",
      publicationsCount: 39,
      titlePublicationCid: "2062",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "747",
      name: "Egypt",
      displayName: "Egypt",
      slug: "egypt",
      iso: "eg",
      publicationsCount: 28,
      titlePublicationCid: "4271",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "805",
      name: "El Salvador",
      displayName: "El Salvador",
      slug: "el-salvador",
      iso: "sv",
      publicationsCount: 8,
      titlePublicationCid: "2032",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1839",
      name: "Equatorial Guinea",
      displayName: "Equatorial Guinea",
      slug: "equatorial-guinea",
      iso: "gq",
      publicationsCount: 3,
      titlePublicationCid: "9lz3",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "814",
      name: "Estonia [country]",
      displayName: "Estonia",
      slug: "estonia",
      iso: "ee",
      publicationsCount: 1,
      titlePublicationCid: "0982",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1551",
      name: "Eswatini [Swaziland]",
      displayName: "Eswatini",
      slug: "eswatini",
      iso: "sz",
      publicationsCount: 2,
      titlePublicationCid: "9wuv",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1841",
      name: "Ethiopia [country]",
      displayName: "Ethiopia",
      slug: "ethiopia",
      iso: "et",
      publicationsCount: 1,
      titlePublicationCid: "9yvj",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1552",
      name: "Fiji",
      displayName: "Fiji",
      slug: "fiji",
      iso: "fj",
      publicationsCount: 4,
      titlePublicationCid: "9gmw",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "824",
      name: "Finland",
      displayName: "Finland",
      slug: "finland",
      iso: "fi",
      publicationsCount: 54,
      titlePublicationCid: "3354",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "829",
      name: "France [country]",
      displayName: "France",
      slug: "france",
      iso: "fr",
      publicationsCount: 356,
      titlePublicationCid: "2507",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1844",
      name: "French Polynesia",
      displayName: "French Polynesia",
      slug: "french-polynesia",
      iso: "pf",
      publicationsCount: 1,
      titlePublicationCid: "9791",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "870",
      name: "Germany [country]",
      displayName: "Germany",
      slug: "germany",
      iso: "de",
      publicationsCount: 341,
      titlePublicationCid: "3035",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1848",
      name: "Ghana [country]",
      displayName: "Ghana",
      slug: "ghana",
      iso: "gh",
      publicationsCount: 13,
      titlePublicationCid: "9yuv",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "878",
      name: "Greece [country]",
      displayName: "Greece",
      slug: "greece",
      iso: "gr",
      publicationsCount: 12,
      titlePublicationCid: "4602",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "883",
      name: "Guatemala",
      displayName: "Guatemala",
      slug: "guatemala",
      iso: "gt",
      publicationsCount: 3,
      titlePublicationCid: "ebds",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1855",
      name: "Guinea-Bissau",
      displayName: "Guinea-Bissau",
      slug: "guinea-bissau",
      iso: "gw",
      publicationsCount: 16,
      titlePublicationCid: "9yb8",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "887",
      name: "Guyana",
      displayName: "Guyana",
      slug: "guyana",
      iso: "gy",
      publicationsCount: 2,
      titlePublicationCid: "6298",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "889",
      name: "Haiti [country]",
      displayName: "Haiti",
      slug: "haiti",
      iso: "ht",
      publicationsCount: 1,
      titlePublicationCid: "2699",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1643",
      name: "Honduras",
      displayName: "Honduras",
      slug: "honduras",
      iso: "hn",
      publicationsCount: 13,
      titlePublicationCid: "e890",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "911",
      name: "Hong Kong",
      displayName: "Hong Kong",
      slug: "hong-kong",
      iso: "hk",
      publicationsCount: 65,
      titlePublicationCid: "6150",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "916",
      name: "Hungary [country]",
      displayName: "Hungary",
      slug: "hungary",
      iso: "hu",
      publicationsCount: 11,
      titlePublicationCid: "9k49",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "919",
      name: "Iceland [country]",
      displayName: "Iceland",
      slug: "iceland",
      iso: "is",
      publicationsCount: 1,
      titlePublicationCid: "9gwr",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "930",
      name: "India",
      displayName: "India",
      slug: "india",
      iso: "in",
      publicationsCount: 191,
      titlePublicationCid: "8880",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "931",
      name: "Indonesia [country]",
      displayName: "Indonesia",
      slug: "indonesia",
      iso: "id",
      publicationsCount: 41,
      titlePublicationCid: "4896",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "940",
      name: "Iran",
      displayName: "Iran",
      slug: "iran",
      iso: "ir",
      publicationsCount: 29,
      titlePublicationCid: "4a03",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "941",
      name: "Iraq",
      displayName: "Iraq",
      slug: "iraq",
      iso: "iq",
      publicationsCount: 6,
      titlePublicationCid: "9i72",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "942",
      name: "Ireland [country]",
      displayName: "Ireland",
      slug: "ireland",
      iso: "ie",
      publicationsCount: 57,
      titlePublicationCid: "9vgq",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "949",
      name: "Israel",
      displayName: "Israel",
      slug: "israel",
      iso: "il",
      publicationsCount: 9,
      titlePublicationCid: "1007",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "951",
      name: "Italy [country]",
      displayName: "Italy",
      slug: "italy",
      iso: "it",
      publicationsCount: 345,
      titlePublicationCid: "3501",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "953",
      name: "Jamaica",
      displayName: "Jamaica",
      slug: "jamaica",
      iso: "jm",
      publicationsCount: 20,
      titlePublicationCid: "9vgq",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "954",
      name: "Japan [country]",
      displayName: "Japan",
      slug: "japan",
      iso: "jp",
      publicationsCount: 46,
      titlePublicationCid: "5014",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "958",
      name: "Jordan [country]",
      displayName: "Jordan",
      slug: "jordan",
      iso: "jo",
      publicationsCount: 8,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "966",
      name: "Kazakhstan",
      displayName: "Kazakhstan",
      slug: "kazakhstan",
      iso: "kz",
      publicationsCount: 3,
      titlePublicationCid: "0935",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "979",
      name: "Kenya [country]",
      displayName: "Kenya",
      slug: "kenya",
      iso: "ke",
      publicationsCount: 6,
      titlePublicationCid: "7047",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "984",
      name: "Korea, Republic",
      displayName: "Korea, Republic",
      slug: "korea-republic",
      iso: "kr",
      publicationsCount: 24,
      titlePublicationCid: "9348",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "987",
      name: "Kuwait",
      displayName: "Kuwait",
      slug: "kuwait",
      iso: "kw",
      publicationsCount: 23,
      titlePublicationCid: "9i72",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1806",
      name: "Laos",
      displayName: "Laos",
      slug: "laos",
      iso: "la",
      publicationsCount: 4,
      titlePublicationCid: "9aaa",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1039",
      name: "Latvia [country]",
      displayName: "Latvia",
      slug: "latvia",
      iso: "lv",
      publicationsCount: 1,
      titlePublicationCid: "9hyj",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1048",
      name: "Lebanon",
      displayName: "Lebanon",
      slug: "lebanon",
      iso: "lb",
      publicationsCount: 17,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1049",
      name: "Lesotho",
      displayName: "Lesotho",
      slug: "lesotho",
      iso: "ls",
      publicationsCount: 1,
      titlePublicationCid: "9gw5",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1866",
      name: "Libya",
      displayName: "Libya",
      slug: "libya",
      iso: "ly",
      publicationsCount: 1,
      titlePublicationCid: "9eam",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1054",
      name: "Lithuania [country]",
      displayName: "Lithuania",
      slug: "lithuania",
      iso: "lt",
      publicationsCount: 1,
      titlePublicationCid: "0855",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1061",
      name: "Luxembourg",
      displayName: "Luxembourg",
      slug: "luxembourg",
      iso: "lu",
      publicationsCount: 2,
      titlePublicationCid: "3034",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1867",
      name: "Macau",
      displayName: "Macau",
      slug: "macau",
      iso: "mo",
      publicationsCount: 5,
      titlePublicationCid: "9feh",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1868",
      name: "Madagascar",
      displayName: "Madagascar",
      slug: "madagascar",
      iso: "mg",
      publicationsCount: 1,
      titlePublicationCid: "9gw7",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1084",
      name: "Malaysia [country]",
      displayName: "Malaysia",
      slug: "malaysia",
      iso: "my",
      publicationsCount: 61,
      titlePublicationCid: "4404",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1087",
      name: "Malta [country]",
      displayName: "Malta",
      slug: "malta",
      iso: "mt",
      publicationsCount: 13,
      titlePublicationCid: "1409",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1873",
      name: "Mauritania",
      displayName: "Mauritania",
      slug: "mauritania",
      iso: "mr",
      publicationsCount: 1,
      titlePublicationCid: "9eam",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1090",
      name: "Mauritius [country]",
      displayName: "Mauritius",
      slug: "mauritius",
      iso: "mu",
      publicationsCount: 8,
      titlePublicationCid: "9gw7",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1099",
      name: "Mexico [country]",
      displayName: "Mexico",
      slug: "mexico",
      iso: "mx",
      publicationsCount: 239,
      titlePublicationCid: "2053",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1658",
      name: "Monaco",
      displayName: "Monaco",
      slug: "monaco",
      iso: "mc",
      publicationsCount: 4,
      titlePublicationCid: "f316",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1876",
      name: "Mongolia",
      displayName: "Mongolia",
      slug: "mongolia",
      iso: "mn",
      publicationsCount: 1,
      titlePublicationCid: "9gtr",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1103",
      name: "Montenegro",
      displayName: "Montenegro",
      slug: "montenegro",
      iso: "me",
      publicationsCount: 1,
      titlePublicationCid: "9y4q",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1586",
      name: "Morocco",
      displayName: "Morocco",
      slug: "morocco",
      iso: "ma",
      publicationsCount: 10,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1524",
      name: "Mozambique [country]",
      displayName: "Mozambique",
      slug: "mozambique",
      iso: "mz",
      publicationsCount: 28,
      titlePublicationCid: "e611",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1535",
      name: "Myanmar [Burma]",
      displayName: "Myanmar",
      slug: "myanmar",
      iso: "mm",
      publicationsCount: 5,
      titlePublicationCid: "9fak",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1111",
      name: "Namibia",
      displayName: "Namibia",
      slug: "namibia",
      iso: "na",
      publicationsCount: 8,
      titlePublicationCid: "9xz5",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1115",
      name: "Nepal [country]",
      displayName: "Nepal",
      slug: "nepal",
      iso: "np",
      publicationsCount: 5,
      titlePublicationCid: "9348",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1117",
      name: "Netherlands",
      displayName: "Netherlands",
      slug: "netherlands",
      iso: "nl",
      publicationsCount: 24,
      titlePublicationCid: "3211",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1119",
      name: "New Caledonia",
      displayName: "New Caledonia",
      slug: "new-caledonia",
      iso: "nc",
      publicationsCount: 1,
      titlePublicationCid: "9791",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1121",
      name: "New Zealand [country]",
      displayName: "New Zealand",
      slug: "new-zealand",
      iso: "nz",
      publicationsCount: 190,
      titlePublicationCid: "9hym",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1908",
      name: "Nicaragua",
      displayName: "Nicaragua",
      slug: "nicaragua",
      iso: "ni",
      publicationsCount: 6,
      titlePublicationCid: "eb84",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1135",
      name: "Nigeria",
      displayName: "Nigeria",
      slug: "nigeria",
      iso: "ng",
      publicationsCount: 31,
      titlePublicationCid: "1502",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1140",
      name: "Norway [country]",
      displayName: "Norway",
      slug: "norway",
      iso: "no",
      publicationsCount: 170,
      titlePublicationCid: "3100",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1152",
      name: "Oman",
      displayName: "Oman",
      slug: "oman",
      iso: "om",
      publicationsCount: 31,
      titlePublicationCid: "9id9",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1170",
      name: "Pakistan [country]",
      displayName: "Pakistan",
      slug: "pakistan",
      iso: "pk",
      publicationsCount: 9,
      titlePublicationCid: "9aaa",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1171",
      name: "Palestine",
      displayName: "Palestine",
      slug: "palestine",
      iso: "ps",
      publicationsCount: 9,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1172",
      name: "Panama",
      displayName: "Panama",
      slug: "panama",
      iso: "pa",
      publicationsCount: 15,
      titlePublicationCid: "9efn",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1645",
      name: "Papua New Guinea",
      displayName: "Papua New Guinea",
      slug: "papua-new-guinea",
      iso: "pg",
      publicationsCount: 3,
      titlePublicationCid: "9aa6",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1176",
      name: "Paraguay [country]",
      displayName: "Paraguay",
      slug: "paraguay",
      iso: "py",
      publicationsCount: 9,
      titlePublicationCid: "e834",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1187",
      name: "Peru",
      displayName: "Peru",
      slug: "peru",
      iso: "pe",
      publicationsCount: 14,
      titlePublicationCid: "eaaj",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1191",
      name: "Philippines",
      displayName: "Philippines",
      slug: "philippines",
      iso: "ph",
      publicationsCount: 68,
      titlePublicationCid: "1731",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1192",
      name: "Poland [country]",
      displayName: "Poland",
      slug: "poland",
      iso: "pl",
      publicationsCount: 17,
      titlePublicationCid: "0517",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1195",
      name: "Portugal [country]",
      displayName: "Portugal",
      slug: "portugal",
      iso: "pt",
      publicationsCount: 141,
      titlePublicationCid: "3958",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1210",
      name: "Puerto Rico",
      displayName: "Puerto Rico",
      slug: "puerto-rico",
      iso: "pr",
      publicationsCount: 11,
      titlePublicationCid: "3863",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1213",
      name: "Qatar",
      displayName: "Qatar",
      slug: "qatar",
      iso: "qa",
      publicationsCount: 31,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1242",
      name: "Romania [country]",
      displayName: "Romania",
      slug: "romania",
      iso: "ro",
      publicationsCount: 29,
      titlePublicationCid: "0537",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1247",
      name: "Russia [country]",
      displayName: "Russia",
      slug: "russia",
      iso: "ru",
      publicationsCount: 5,
      titlePublicationCid: "ee19",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1257",
      name: "Saudi Arabia [country]",
      displayName: "Saudi Arabia",
      slug: "saudi-arabia",
      iso: "sa",
      publicationsCount: 31,
      titlePublicationCid: "9i72",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1259",
      name: "Senegal",
      displayName: "Senegal",
      slug: "senegal",
      iso: "sn",
      publicationsCount: 2,
      titlePublicationCid: "9lza",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1260",
      name: "Serbia [country]",
      displayName: "Serbia",
      slug: "serbia",
      iso: "rs",
      publicationsCount: 5,
      titlePublicationCid: "0535",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1267",
      name: "Singapore",
      displayName: "Singapore",
      slug: "singapore",
      iso: "sg",
      publicationsCount: 62,
      titlePublicationCid: "9395",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1272",
      name: "Slovakia [country]",
      displayName: "Slovakia",
      slug: "slovakia",
      iso: "sk",
      publicationsCount: 5,
      titlePublicationCid: "0512",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1273",
      name: "Slovenia [country]",
      displayName: "Slovenia",
      slug: "slovenia",
      iso: "si",
      publicationsCount: 17,
      titlePublicationCid: "0942",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1894",
      name: "Somalia",
      displayName: "Somalia",
      slug: "somalia",
      iso: "so",
      publicationsCount: 1,
      titlePublicationCid: "9eam",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1279",
      name: "South Africa [country]",
      displayName: "South Africa",
      slug: "south-africa",
      iso: "za",
      publicationsCount: 224,
      titlePublicationCid: "1107",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1285",
      name: "Spain",
      displayName: "Spain",
      slug: "spain",
      iso: "es",
      publicationsCount: 891,
      titlePublicationCid: "2317",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1299",
      name: "Sri Lanka [country]",
      displayName: "Sri Lanka",
      slug: "sri-lanka",
      iso: "lk",
      publicationsCount: 15,
      titlePublicationCid: "8610",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1303",
      name: "Sudan",
      displayName: "Sudan",
      slug: "sudan",
      iso: "sd",
      publicationsCount: 1,
      titlePublicationCid: "9eam",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1336",
      name: "Suriname",
      displayName: "Suriname",
      slug: "suriname",
      iso: "sr",
      publicationsCount: 1,
      titlePublicationCid: "1895",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1338",
      name: "Sweden [country]",
      displayName: "Sweden",
      slug: "sweden",
      iso: "se",
      publicationsCount: 303,
      titlePublicationCid: "3233",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1340",
      name: "Switzerland",
      displayName: "Switzerland",
      slug: "switzerland",
      iso: "ch",
      publicationsCount: 51,
      titlePublicationCid: "3119",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1897",
      name: "Syria",
      displayName: "Syria",
      slug: "syria",
      iso: "sy",
      publicationsCount: 2,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1345",
      name: "Taiwan",
      displayName: "Taiwan",
      slug: "taiwan",
      iso: "tw",
      publicationsCount: 30,
      titlePublicationCid: "9aaa",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1615",
      name: "Tanzania [country]",
      displayName: "Tanzania",
      slug: "tanzania",
      iso: "tz",
      publicationsCount: 6,
      titlePublicationCid: "7047",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1361",
      name: "Thailand [country]",
      displayName: "Thailand",
      slug: "thailand",
      iso: "th",
      publicationsCount: 27,
      titlePublicationCid: "9e81",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1583",
      name: "Tunisia [country]",
      displayName: "Tunisia",
      slug: "tunisia",
      iso: "tn",
      publicationsCount: 11,
      titlePublicationCid: "9gxg",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1444",
      name: "Turkey [country]",
      displayName: "Turkey",
      slug: "turkey",
      iso: "tr",
      publicationsCount: 128,
      titlePublicationCid: "4764",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1904",
      name: "Turks and Caicos Islands",
      displayName: "Turks and Caicos Islands",
      slug: "turks-and-caicos-islands",
      iso: "tc",
      publicationsCount: 1,
      titlePublicationCid: "9xa2",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1451",
      name: "Ukraine [country]",
      displayName: "Ukraine",
      slug: "ukraine",
      iso: "ua",
      publicationsCount: 79,
      titlePublicationCid: "0197",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1447",
      name: "United Arab Emirates [UAE] [country]",
      displayName: "United Arab Emirates",
      slug: "uae",
      iso: "ae",
      publicationsCount: 105,
      titlePublicationCid: "9681",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1450",
      name: "United Kingdom [UK]",
      displayName: "United Kingdom",
      slug: "uk",
      iso: "gb",
      publicationsCount: 730,
      titlePublicationCid: "1545",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1455",
      name: "United States of America [USA]",
      displayName: "United States",
      slug: "usa",
      iso: "us",
      publicationsCount: 752,
      titlePublicationCid: "1047",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1454",
      name: "Uruguay",
      displayName: "Uruguay",
      slug: "uruguay",
      iso: "uy",
      publicationsCount: 11,
      titlePublicationCid: "9vl8",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1458",
      name: "Uzbekistan [country]",
      displayName: "Uzbekistan",
      slug: "uzbekistan",
      iso: "uz",
      publicationsCount: 4,
      titlePublicationCid: "09a8",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1467",
      name: "Venezuela",
      displayName: "Venezuela",
      slug: "venezuela",
      iso: "ve",
      publicationsCount: 3,
      titlePublicationCid: "eb84",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1468",
      name: "Vietnam [country]",
      displayName: "Vietnam",
      slug: "vietnam",
      iso: "vn",
      publicationsCount: 47,
      titlePublicationCid: "9348",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1549",
      name: "Yemen",
      displayName: "Yemen",
      slug: "yemen",
      iso: "ye",
      publicationsCount: 4,
      titlePublicationCid: "9i72",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1493",
      name: "Zambia",
      displayName: "Zambia",
      slug: "zambia",
      iso: "zm",
      publicationsCount: 6,
      titlePublicationCid: "9hzp",
      isCountry: true,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1496",
      name: "Zimbabwe",
      displayName: "Zimbabwe",
      slug: "zimbabwe",
      iso: "zw",
      publicationsCount: 15,
      titlePublicationCid: "1729",
      isCountry: true,
      displayNameLang: "en"
    }
  ],
  languages: [
    {
      categories: null,
      id: "536",
      name: "Afrikaans",
      displayName: "Afrikaans",
      slug: "afrikaans",
      iso: "af",
      publicationsCount: 48,
      titlePublicationCid: "9k94",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "540",
      name: "Albanian [language]",
      displayName: "Albanian",
      slug: "albanian",
      iso: "sq",
      publicationsCount: 4,
      titlePublicationCid: "0814",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "555",
      name: "Arabic",
      displayName: "Arabic",
      slug: "arabic",
      iso: "ar",
      publicationsCount: 98,
      titlePublicationCid: "4271",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "560",
      name: "Armenian [language]",
      displayName: "Armenian",
      slug: "armenian",
      iso: "hy",
      publicationsCount: 1,
      titlePublicationCid: "sgj3",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "588",
      name: "Belarusian [language]",
      displayName: "Belarusian",
      slug: "belarusian",
      iso: "be",
      publicationsCount: 5,
      titlePublicationCid: "0247",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "595",
      name: "Bosnian [language]",
      displayName: "Bosnian",
      slug: "bosnian",
      iso: "bs",
      publicationsCount: 5,
      titlePublicationCid: "0980",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "602",
      name: "Bulgarian [language]",
      displayName: "Bulgarian",
      slug: "bulgarian",
      iso: "bg",
      publicationsCount: 1,
      titlePublicationCid: "0533",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1595",
      name: "Burmese [language]",
      displayName: "Burmese",
      slug: "burmese",
      iso: "my",
      publicationsCount: 1,
      titlePublicationCid: "4925",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "619",
      name: "Catalan [language]",
      displayName: "Catalan",
      slug: "catalan",
      iso: "ca",
      publicationsCount: 33,
      titlePublicationCid: "3752",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "624",
      name: "Cebuano",
      displayName: "Cebuano",
      slug: "cebuano",
      iso: "ceb",
      publicationsCount: 3,
      titlePublicationCid: "8658",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1657",
      name: "Chinese Simplified",
      displayName: "Chinese (Simplified)",
      slug: "chinese-simplified",
      iso: "zh",
      publicationsCount: 234,
      titlePublicationCid: "5926",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "630",
      name: "Chinese Traditional",
      displayName: "Chinese (Traditional)",
      slug: "chinese-traditional",
      iso: "zh",
      publicationsCount: 58,
      titlePublicationCid: "5207",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "658",
      name: "Croatian [language]",
      displayName: "Croatian",
      slug: "croatian",
      iso: "hr",
      publicationsCount: 8,
      titlePublicationCid: "0503",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "660",
      name: "Czech [language]",
      displayName: "Czech",
      slug: "czech",
      iso: "cs",
      publicationsCount: 5,
      titlePublicationCid: "0501",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "676",
      name: "Danish",
      displayName: "Danish",
      slug: "danish",
      iso: "da",
      publicationsCount: 57,
      titlePublicationCid: "3216",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "737",
      name: "Dutch",
      displayName: "Dutch",
      slug: "dutch",
      iso: "nl",
      publicationsCount: 29,
      titlePublicationCid: "3211",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "808",
      name: "English",
      displayName: "English",
      slug: "english",
      iso: "en",
      publicationsCount: 2874,
      titlePublicationCid: "1545",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "825",
      name: "Finnish",
      displayName: "Finnish",
      slug: "finnish",
      iso: "fi",
      publicationsCount: 48,
      titlePublicationCid: "9jan",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "841",
      name: "French",
      displayName: "French",
      slug: "french",
      iso: "fr",
      publicationsCount: 453,
      titlePublicationCid: "2507",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "869",
      name: "German [language]",
      displayName: "German",
      slug: "german",
      iso: "de",
      publicationsCount: 379,
      titlePublicationCid: "3035",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "879",
      name: "Greek [language]",
      displayName: "Greek",
      slug: "greek",
      iso: "el",
      publicationsCount: 12,
      titlePublicationCid: "4602",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "886",
      name: "Gujarati [language]",
      displayName: "Gujarati",
      slug: "gujarati",
      iso: "gu",
      publicationsCount: 5,
      titlePublicationCid: "9l34",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2617",
      name: "Hausa",
      displayName: "Hausa",
      slug: "hausa",
      iso: "ha",
      publicationsCount: 1,
      titlePublicationCid: "9y3g",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "893",
      name: "Hebrew",
      displayName: "Hebrew",
      slug: "hebrew",
      iso: "he",
      publicationsCount: 3,
      titlePublicationCid: "9jag",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "902",
      name: "Hindi",
      displayName: "Hindi",
      slug: "hindi",
      iso: "hi",
      publicationsCount: 22,
      titlePublicationCid: "4855",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "915",
      name: "Hungarian [language]",
      displayName: "Hungarian",
      slug: "hungarian",
      iso: "hu",
      publicationsCount: 11,
      titlePublicationCid: "9k49",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1928",
      name: "Ilocano",
      displayName: "Ilocano",
      slug: "ilocano",
      iso: "ilo",
      publicationsCount: 1,
      titlePublicationCid: "5665",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "932",
      name: "Indonesian [language]",
      displayName: "Indonesian",
      slug: "indonesian",
      iso: "id",
      publicationsCount: 15,
      titlePublicationCid: "4896",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "950",
      name: "Italian [language]",
      displayName: "Italian",
      slug: "italian",
      iso: "it",
      publicationsCount: 325,
      titlePublicationCid: "3501",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "955",
      name: "Japanese [language]",
      displayName: "Japanese",
      slug: "japanese",
      iso: "ja",
      publicationsCount: 38,
      titlePublicationCid: "5014",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "963",
      name: "Kannada",
      displayName: "Kannada",
      slug: "kannada",
      iso: "kn",
      publicationsCount: 3,
      titlePublicationCid: "4268",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2444",
      name: "Kazakh [language]",
      displayName: "Kazakh",
      slug: "kazakh",
      iso: "kk",
      publicationsCount: 1,
      titlePublicationCid: "0452",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "985",
      name: "Korean [language]",
      displayName: "Korean",
      slug: "korean",
      iso: "ko",
      publicationsCount: 13,
      titlePublicationCid: "5505",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2292",
      name: "Kven",
      displayName: "Kven",
      slug: "kven",
      iso: "fkv",
      publicationsCount: 1,
      titlePublicationCid: "9jan",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1055",
      name: "Lithuanian [language]",
      displayName: "Lithuanian",
      slug: "lithuanian",
      iso: "lt",
      publicationsCount: 1,
      titlePublicationCid: "0855",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1082",
      name: "Malay [language]",
      displayName: "Malay",
      slug: "malay",
      iso: "ms",
      publicationsCount: 6,
      titlePublicationCid: "5832",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1083",
      name: "Malayalam [language]",
      displayName: "Malayalam",
      slug: "malayalam",
      iso: "ml",
      publicationsCount: 1,
      titlePublicationCid: "420d",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1089",
      name: "Marathi",
      displayName: "Marathi",
      slug: "marathi",
      iso: "mr",
      publicationsCount: 2,
      titlePublicationCid: "4818",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2287",
      name: "Northern Sami",
      displayName: "Northern Sami",
      slug: "northern-sami",
      iso: "se",
      publicationsCount: 1,
      titlePublicationCid: "9jam",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1141",
      name: "Norwegian [language]",
      displayName: "Norwegian",
      slug: "norwegian",
      iso: "no",
      publicationsCount: 166,
      titlePublicationCid: "3100",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1667",
      name: "Odia",
      displayName: "Odia",
      slug: "odia",
      iso: "or",
      publicationsCount: 1,
      titlePublicationCid: "e955",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1186",
      name: "Persian",
      displayName: "Persian",
      slug: "persian",
      iso: "fa",
      publicationsCount: 27,
      titlePublicationCid: "4319",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1193",
      name: "Polish [language]",
      displayName: "Polish",
      slug: "polish",
      iso: "pl",
      publicationsCount: 19,
      titlePublicationCid: "0517",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1196",
      name: "Portuguese [language]",
      displayName: "Portuguese",
      slug: "portuguese",
      iso: "pt",
      publicationsCount: 340,
      titlePublicationCid: "3958",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1211",
      name: "Punjabi [language]",
      displayName: "Punjabi",
      slug: "punjabi",
      iso: "pa",
      publicationsCount: 5,
      titlePublicationCid: "4807",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1243",
      name: "Romanian [language]",
      displayName: "Romanian",
      slug: "romanian",
      iso: "ro",
      publicationsCount: 29,
      titlePublicationCid: "0537",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1248",
      name: "Russian [language]",
      displayName: "Russian",
      slug: "russian",
      iso: "ru",
      publicationsCount: 96,
      titlePublicationCid: "0197",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1261",
      name: "Serbian [language]",
      displayName: "Serbian",
      slug: "serbian",
      iso: "sr",
      publicationsCount: 4,
      titlePublicationCid: "0535",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1269",
      name: "Sinhala",
      displayName: "Sinhala",
      slug: "sinhala",
      iso: "si",
      publicationsCount: 9,
      titlePublicationCid: "4837",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1271",
      name: "Slovak [language]",
      displayName: "Slovak",
      slug: "slovak",
      iso: "sk",
      publicationsCount: 5,
      titlePublicationCid: "0512",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1274",
      name: "Slovenian [language]",
      displayName: "Slovenian",
      slug: "slovenian",
      iso: "sl",
      publicationsCount: 16,
      titlePublicationCid: "0942",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1286",
      name: "Spanish [language]",
      displayName: "Spanish",
      slug: "spanish",
      iso: "es",
      publicationsCount: 1351,
      titlePublicationCid: "2317",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1339",
      name: "Swedish [language]",
      displayName: "Swedish",
      slug: "swedish",
      iso: "sv",
      publicationsCount: 301,
      titlePublicationCid: "3233",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1343",
      name: "Tagalog",
      displayName: "Tagalog",
      slug: "tagalog",
      iso: "tl",
      publicationsCount: 8,
      titlePublicationCid: "9gb9",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1347",
      name: "Tamil [language]",
      displayName: "Tamil",
      slug: "tamil",
      iso: "ta",
      publicationsCount: 3,
      titlePublicationCid: "9ieg",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1360",
      name: "Thai [language]",
      displayName: "Thai",
      slug: "thai",
      iso: "th",
      publicationsCount: 7,
      titlePublicationCid: "9e81",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1445",
      name: "Turkish [language]",
      displayName: "Turkish",
      slug: "turkish",
      iso: "tr",
      publicationsCount: 121,
      titlePublicationCid: "4764",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1452",
      name: "Ukrainian [language]",
      displayName: "Ukrainian",
      slug: "ukrainian",
      iso: "uk",
      publicationsCount: 36,
      titlePublicationCid: "0230",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1589",
      name: "Urdu",
      displayName: "Urdu",
      slug: "urdu",
      iso: "ur",
      publicationsCount: 2,
      titlePublicationCid: "9hpl",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2334",
      name: "Uzbek [language]",
      displayName: "Uzbek",
      slug: "uzbek",
      iso: "uz",
      publicationsCount: 1,
      titlePublicationCid: "09aa",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2686",
      name: "Valencian [language]",
      displayName: "Valencian",
      slug: "valencian",
      iso: "vlc",
      publicationsCount: 2,
      titlePublicationCid: "e873",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1469",
      name: "Vietnamese [language]",
      displayName: "Vietnamese",
      slug: "vietnamese",
      iso: "vi",
      publicationsCount: 28,
      titlePublicationCid: "5547",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2605",
      name: "Welsh",
      displayName: "Welsh",
      slug: "welsh",
      iso: "cy",
      publicationsCount: 1,
      titlePublicationCid: "9kbe",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "2388",
      name: "Xhosa",
      displayName: "Xhosa",
      slug: "xhosa",
      iso: "xh",
      publicationsCount: 1,
      titlePublicationCid: "4481",
      isCountry: false,
      displayNameLang: "en"
    },
    {
      categories: null,
      id: "1499",
      name: "Zulu",
      displayName: "Zulu",
      slug: "zulu",
      iso: "zu",
      publicationsCount: 11,
      titlePublicationCid: "4300",
      isCountry: false,
      displayNameLang: "en"
    }
  ]
}

export const THIRD_PARTY_TIER_TWO_MOCK_REFINERS = {
  age: [
    {
      count: 59,
      displayName: "Children's - Grade 1-2, Age 6-7",
      groupDisplayName: "Audience",
      refinerTypeValue: "audiencedesc",
      value: "Children's - Grade 1-2, Age 6-7"
    },
    {
      count: 8,
      displayName: "Teen - Grade 7-9, Age 12-14",
      groupDisplayName: "Audience",
      refinerTypeValue: "audiencedesc",
      value: "Teen - Grade 7-9, Age 12-14"
    }
  ],
  language: [
    {
      count: 67,
      displayName: "English",
      groupDisplayName: "Language",
      refinerTypeValue: "languagedesc",
      value: "ENGLISH"
    }
  ],
  publisher: []
};

export const THIRD_PARTY_WIDGETS_MOCK = {
  region: 1,
  title: "Widgets",
  description: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ",
  type: "Widgets_Carousel",
  subType: null,
  vendor_details: [
    {
      vendor_name: "Videos",
      vendor_code: "VIDEOS",
      description: "Useful learning activities are ones where the student is able to take what they have learnt from engaging with the activity and use it in another context.",
      display_name: "Videos",
      category_type: "publisher",
      format_code: "vid"
    },
    {
      vendor_name: "VBooks",
      vendor_code: "VBOOKS",
      description: "Useful learning activities are ones where the student is able to take what they have learnt from engaging with the activity and use it in another context.",
      display_name: "vBooks",
      category_type: "bisac",
      format_code: "vbk"
    }
  ],
  hideSeeAllLink: false,
  handleInApp: false,
  defaultValues: null,
  dataApi: {
    url: "https://dev1axis360-apim.azure-api.net/kidszone/api/v2/GetCategoryForVideo",
    method: "GET",
    library_settings: { contentCollectionId: "collection" },
    headers: {
      api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
      mylibrary: "true",
      "ocp-apim-subscription-key": ""
    },
    body: null,
    pathParams: null,
    search: {
      collectionId: "73335E67-5472-ED11-819D-000D3A1EC91D",
      categoryType: "publisher",
      formatCode: "vbk"
    },
    app: {
      collectionId: "false",
      categoryType: "false",
      formatCode: "false"
    }
  },
  jsonTempl: {
    type: "AdaptiveCard",
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5",
    body: [
      {
        type: "Container",
        items: [
          {
            type: "Image",
            size: "Large",
            $when: "${categoryImageType == 'code'}",
            horizontalAlignment: "Center",
            altText: "${if(categoryName != '',categoryName,'')}",
            url: "https://ui-avatars.com/api/?name=${featuredImage}&rounded=true",
            selectAction: {
              type: "Action.Submit",
              data: {
                type: "category",
                searchType: "subject",
                code: "${if(categoryCode != '',categoryCode,'NA')}",
                title: "${if(categoryName != '',categoryName,'NA')}",
                vendorCode: "VIDEOS"
              }
            }
          },
          {
            type: "Image",
            size: "Large",
            $when: "${categoryImageType == 'url'}",
            horizontalAlignment: "Center",
            altText: "${if(categoryName != '',categoryName,'')}",
            url: "${if(featuredImage != '','featuredImage','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/category_default_image.png')}",
            selectAction: {
              type: "Action.Submit",
              data: {
                type: "category",
                searchType: "subject",
                code: "${if(categoryCode != '',categoryCode,'NA')}",
                title: "${if(categoryName != '',categoryName,'NA')}",
                vendorCode: "VIDEOS"
              }
            }
          },
          {
            type: "TextBlock",
            text: "${if(categoryName != '',categoryName,'NA')}",
            wrap: true,
            horizontalAlignment: "Center",
            maxLines: 2
          }
        ]
      }
    ]
  }
};


export const THIRD_PARTY_RECOMMEND_TITLES = {
  region: 1,
  title: "More Like This",
  description: null,
  type: "Item_Carousel",
  subType: "Recommend_Videos",
  vendor_details: null,
  hideSeeAllLink: false,
  handleInApp: false,
  defaultValues: null,
  dataApi: {
    url: "https://dev1axis360-apim.azure-api.net/kidszone/api/v2/ThirdpartyRecommendation",
    method: "POST",
    headers: {
      api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
      "content-type": "application/json",
      mylibrary: "true",
      "ocp-apim-subscription-key": ""
    },
    library_settings: { contentCollectionId: "collection" },
    body: {
      PatronGUID: "b50cb181-f133-44c8-a7c2-b1d2081b993e",
      LibraryId: "73335E67-5472-ED11-819D-000D3A1EC91D",
      ByPassCache: true,
      Books: [{ ItemId: "0099488428" }],
      Filters: []
    },
    pathParams: null,
    search: {
      pageNumber: "2",
      pageSize: "10"
    },
    app: {
      pageNumber: "false",
      pageSize: "false"
    }
  },
  jsonTempl: {
    type: "AdaptiveCard",
    body: [
      {
        type: "ColumnSet",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "Container",
                selectAction: {
                  type: "Action.Submit",
                  data: {
                    type: "title",
                    ItemId: "${itemId}",
                    ISBN: "${isbn}",
                    formatType: "${format}",
                    isAvailable: "${isAvailable}",
                    title: "${title}",
                    series: "${series}",
                    vendorCode: "VBOOKS"
                  }
                },
                backgroundImage: {
                  url: "${imageURL}"
                },
                items: [
                  {
                    type: "Image",
                    style: "Person",
                    altText: "Vbook",
                    url: "${if(isAvailable == 'true','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                    size: "Small",
                    horizontalAlignment: "Right",
                    width: "24px",
                    height: "24px"
                  }
                ],
                height: "stretch",
                minHeight: "192px"
              }
            ]
          }
        ],
        minHeight: "50px"
      },
      {
        type: "TextBlock",
        wrap: true,
        text: "${if(title,title,'')}",
        color: "Dark",
        maxLines: 1,
        weight: "Bolder"
      },
      {
        type: "TextBlock",
        text: "${if(author, author, '')}",
        wrap: true,
        maxLines: 1,
        spacing: "Small"
      },
      {
        type: "ColumnSet",
        $when: "${isCheckedout == 'false'}",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "ActionSet",
                actions: [
                  {
                    type: "Action.Submit",
                    title: "Checkout",
                    id: "${itemId}",
                    data: {
                      type: "actions",
                      actiontype: "checkout",
                      ItemId: "${itemId}",
                      ISBN: "${isbn}",
                      title: "${title}",
                      vendorCode: "VBOOKS",
                      formatType: "${format}",
                      isAvailable: "${isAvailable}"
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: "ColumnSet",
        $when: "${isCheckedout == 'true'}",
        columns: [
          {
            type: "Column",
            width: "stretch",
            items: [
              {
                type: "ActionSet",
                actions: [
                  {
                    type: "Action.Submit",
                    title: "${if(resumeCTA,resumeCTA,'Play')}",
                    id: "${itemId}",
                    data: {
                      type: "play",
                      ItemId: "${itemId}",
                      ISBN: "${isbn}",
                      title: "${title}",
                      vendorCode: "VBOOKS",
                      formatType: "${format}",
                      isAvailable: "${isAvailable}"
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
    version: "1.5",
    verticalContentAlignment: "Top"
  }
};

export const THIRD_PARTY_RECOMMEND_TITLES_DATA_MOCK = {
  recommendedTitles: [
    {
      isbn: "9781223220154",
      itemId: "0012488399",
      title: " Pets",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9781223220130",
      itemId: "0012488397",
      title: " Animals That Aren't Here Anymore",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9781223220147",
      itemId: "0012488398",
      title: " Desert Animals",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220017",
      itemId: "0099488334",
      title: " Forest Animals",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220018",
      itemId: "0099488335",
      title: " Farm Animals",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220026",
      itemId: "0099488343",
      title: " The Secret of the Dinosaur Egg",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220027",
      itemId: "0099488344",
      title: " You Can Print a Watch?",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220028",
      itemId: "0099488345",
      title: " Delicious and Nutritious",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220029",
      itemId: "0099488346",
      title: " Where No Snoozer Has Gone Before",
      imageUrl: null,
      author: null,
      isCheckedout: null
    },
    {
      isbn: "9992223220021",
      itemId: "0099488338",
      title: " Tropical Animals",
      imageUrl: null,
      author: null,
      isCheckedout: null
    }
  ],
  totalCount: 50
};

export const THIRD_PARTY_RECOMMEND_TITLES_EMPTY_DATA_MOCK = {
  recommendedTitles: [],
  totalCount: 50
};

export const THIRD_PARTY_TIER_THREE_MOCK_01 = [
  {
    title: 'Tier3',
    pageType: 'Publication Details',
    vendor: {
      name: 'Press Reader',
      display_name: 'Newspapers & Magazines',
      code: 'PRESSRDR',
      urlAlias: '/pressreader'
    },
    layout: undefined
  }
];

export const THIRD_PARTY_TIER_TWO_MOCK_003 = {
  title: "Recommend_Title",
  pageType: "Home",
  subType: "Recommend_Videos",
  vendor: {
    name: "Videos",
    display_name: "Videos",
    code: "VIDEOS",
    urlAlias: "/videos"
  },
  layout: {
    column_widths: "100%",
    components: [
      {
        region: 1,
        title: "Recommended Title",
        description: null,
        type: "Items_Grid",
        subType: "Recommend_Videos",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://dev1axis360-apim.azure-api.net/kidszone/api/v2/ThirdpartyRecommendation",
          method: "POST",
          headers: {
            api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
            "content-type": "application/json",
            "ocp-apim-subscription-key": "",
            thirdparty: "true"
          },
          body: {
            PatronGUID: "b50cb181-f133-44c8-a7c2-b1d2081b993e",
            LibraryId: "73335E67-5472-ED11-819D-000D3A1EC91D",
            ByPassCache: true,
            Books: [
              {
                ItemId: "0099488428"
              }
            ],
            Filters: []
          },
          library_settings: { contentCollectionId: "collection" },
          pathParams: null,
          search: {
            pageNumber: "0",
            pageSize: "10"
          },
          app: {
            pageNumber: "false",
            pageSize: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          body: [
            {
              type: "ColumnSet",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "Container",
                      selectAction: {
                        type: "Action.Submit",
                        data: {
                          type: "title",
                          ItemId: "${itemId}",
                          ISBN: "${isbn}",
                          formatType: "${format}",
                          isAvailable: "${isAvailable}",
                          title: "${title}",
                          series: "${series}",
                          vendorCode: "VIDEOS"
                        }
                      },
                      backgroundImage: {
                        url: "${imageURL}"
                      },
                      items: [
                        {
                          type: "Image",
                          style: "Person",
                          altText: "Videos",
                          url: "${if(isAvailable == 'true','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          width: "24px",
                          height: "24px"
                        }
                      ],
                      height: "stretch",
                      minHeight: "192px"
                    }
                  ]
                }
              ],
              minHeight: "50px"
            },
            {
              type: "TextBlock",
              wrap: true,
              text: "${if(title,title,'')}",
              color: "Dark",
              maxLines: 1,
              weight: "Bolder"
            },
            {
              type: "TextBlock",
              text: "${if(author,author,'')}",
              wrap: true,
              maxLines: 1,
              spacing: "Small"
            },
            {
              type: "ColumnSet",
              $when: "${isCheckedout == 'false'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "Checkout",
                          id: "${itemId}",
                          data: {
                            type: "actions",
                            actiontype: "checkout",
                            ItemId: "${itemId}",
                            ISBN: "${isbn}",
                            title: "${title}",
                            vendorCode: "VIDEOS",
                            formatType: "${format}",
                            isAvailable: "${isAvailable}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: "ColumnSet",
              $when: "${isCheckedout == 'true'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "${if(resumeCTA,resumeCTA,'Play')}",
                          id: "${itemId}",
                          data: {
                            type: "play",
                            ItemId: "${itemId}",
                            ISBN: "${isbn}",
                            title: "${title}",
                            vendorCode: "VIDEOS",
                            formatType: "${format}",
                            isAvailable: "${isAvailable}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          verticalContentAlignment: "Top"
        }
      }
    ]
  }
};

export const THIRD_PARTY_TIER_THREE_MOCK_DATA = {
  recommendedTitles: [
    {
      isbn: "9781223220154",
      itemId: "0012488399",
      title: " Pets",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220154/Medium/Logo",
      author: "Hamilton, Joseph (PRD)",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip"
    },
    {
      isbn: "9781223220130",
      itemId: "0012488397",
      title: " Animals That Aren't Here Anymore",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220130/Medium/Logo",
      author: "Hamilton, Joseph (PRD)",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip"
    },
    {
      isbn: "9781223220147",
      itemId: "0012488398",
      title: " Desert Animals",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220147/Medium/Logo",
      author: "Hamilton, Joseph (PRD)",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip"
    },
    {
      isbn: "9992223220017",
      itemId: "0099488334",
      title: " Forest Animals",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220017/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip"
    },
    {
      isbn: "9992223220018",
      itemId: "0099488335",
      title: " Farm Animals",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220018/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip"
    },
    {
      isbn: "9992223220026",
      itemId: "0099488343",
      title: " The Secret of the Dinosaur Egg",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220026/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip: Full STEAM AHEAD"
    },
    {
      isbn: "9992223220027",
      itemId: "0099488344",
      title: " You Can Print a Watch?",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220027/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip: Full STEAM AHEAD"
    },
    {
      isbn: "9992223220028",
      itemId: "0099488345",
      title: " Delicious and Nutritious",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220028/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip: Full STEAM AHEAD"
    },
    {
      isbn: "9992223220029",
      itemId: "0099488346",
      title: " Where No Snoozer Has Gone Before",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220029/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip: Full STEAM AHEAD"
    },
    {
      isbn: "9992223220021",
      itemId: "0099488338",
      title: " Tropical Animals",
      imageUrl: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9992223220021/Medium/Logo",
      author: "Hamilton Joseph",
      isCheckedout: "false",
      isAvailable: "true",
      format: "VID",
      series: "The Reading Road Trip"
    }
  ],
  totalCount: 50
};

export const THIRD_PARTY_LIBRARY_COM = [
  {
    id: null,
    type: "CAROUSEL",
    sequence: 15,
    title: "Newspapers & Magazines - 3rd Party",
    description: null,
    entityId: "PRESSRDR",
    entityType: "THIRD_PARTY",
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: null,
    jsonTempl: null,
    components: null
  },
  {
    id: null,
    type: "CAROUSEL",
    sequence: 16,
    title: "COMICS - 3rd Party",
    description: null,
    entityId: "COMICS",
    entityType: "THIRD_PARTY",
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: null,
    jsonTempl: null,
    components: null
  },
  {
    id: null,
    type: "CAROUSEL",
    sequence: 18,
    title: "Edinburg Festival - 3rd Party",
    description: null,
    entityId: "EDINBURG",
    entityType: "THIRD_PARTY",
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: null,
    jsonTempl: null,
    components: null
  },
  {
    id: null,
    type: "CAROUSEL",
    sequence: 19,
    title: "TEST - 3rd Party",
    description: null,
    entityId: "TEST",
    entityType: "THIRD_PARTY",
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: null,
    jsonTempl: null,
    components: null
  },
  {
    id: null,
    type: "CAROUSEL",
    sequence: 20,
    title: "Test22 Dec - 3rd Party",
    description: null,
    entityId: "TEST22",
    entityType: "THIRD_PARTY",
    enabled: true,
    editable: true,
    mandatory: false,
    readOnly: false,
    hidden: false,
    dataApi: null,
    jsonTempl: null,
    components: null
  }
]
export const LIBRARY_COM = {
  statusCode: 200,
  message: "Success",
  libraryComponents: {
    components: [
      {
        id: null,
        type: "CAROUSEL",
        sequence: 1,
        title: "Let's Read Together Month",
        description: "Titles showcased by staff that are perfect for reading with your child.",
        entityId: "74242",
        entityType: "FEATURED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: {
          url: null,
          headers: null,
          queryParams: null
        },
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CARD",
        sequence: 2,
        title: null,
        description: null,
        entityId: null,
        entityType: "LIBRARY_BANNERS",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: [
          {
            id: null,
            type: "CARD",
            sequence: 1,
            title: "Book of the Month",
            description: "TEXAS",
            entityId: "9781442436022",
            entityType: "FEATURED_TITLE",
            enabled: true,
            editable: true,
            mandatory: false,
            readOnly: false,
            hidden: false,
            dataApi: null,
            jsonTempl: null,
            components: []
          },
          {
            id: null,
            type: "CARD",
            sequence: 2,
            title: "Resource Hub",
            description: "Resource refers to all the materials available in our environment which are technologically accessible, economically feasible.",
            entityId: null,
            entityType: "LEARNING_ACTIVITIES",
            enabled: true,
            editable: true,
            mandatory: false,
            readOnly: false,
            hidden: false,
            dataApi: null,
            jsonTempl: null,
            components: null
          },
          {
            id: null,
            type: "CARD",
            sequence: 3,
            title: null,
            description: null,
            entityId: null,
            entityType: "ANNOUNCEMENTS",
            enabled: true,
            editable: true,
            mandatory: false,
            readOnly: false,
            hidden: false,
            dataApi: null,
            jsonTempl: null,
            components: []
          }
        ]
      },
      {
        id: null,
        type: "CARD",
        sequence: 3,
        title: null,
        description: null,
        entityId: null,
        entityType: "FEATURED_READING_PROGRAM",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: {
          url: null,
          headers: null,
          queryParams: null
        },
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 4,
        title: "Teens",
        description: null,
        entityId: "74005",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 5,
        title: "Always Available",
        description: "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit, sed do eiusmod. Lorem ipsum dolor sit amet, conse ctetur adipiscing elit, sed do eiusmod.",
        entityId: null,
        entityType: "ALWAYS_AVAILABLE",
        enabled: true,
        editable: true,
        mandatory: true,
        readOnly: false,
        hidden: false,
        dataApi: {
          url: null,
          headers: null,
          queryParams: null
        },
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 6,
        title: "Fiction",
        description: null,
        entityId: "74001",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 7,
        title: "Non-Fiction",
        description: null,
        entityId: "74002",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 8,
        title: "Children",
        description: null,
        entityId: "74004",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 9,
        title: "Gardening",
        description: null,
        entityId: "73984",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 10,
        title: "Akshays List",
        description: "Spider-Man ",
        entityId: "73990",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 11,
        title: "Professional",
        description: null,
        entityId: "73993",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 12,
        title: "Featured",
        description: null,
        entityId: "74003",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 13,
        title: "Test Bazooka List",
        description: null,
        entityId: "74220",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 14,
        title: "Shared Sales/Demo UAT list-Criteria based",
        description: null,
        entityId: "73985",
        entityType: "CURATED_LIST",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: []
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 15,
        title: "Newspapers & Magazines - 3rd Party",
        description: null,
        entityId: "PRESSRDR",
        entityType: "THIRD_PARTY",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: null
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 16,
        title: "COMICS - 3rd Party",
        description: null,
        entityId: "COMICS",
        entityType: "THIRD_PARTY",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: null
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 18,
        title: "Edinburg Festival - 3rd Party",
        description: null,
        entityId: "EDINBURG",
        entityType: "THIRD_PARTY",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: null
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 19,
        title: "TEST - 3rd Party",
        description: null,
        entityId: "TEST",
        entityType: "THIRD_PARTY",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: null
      },
      {
        id: null,
        type: "CAROUSEL",
        sequence: 20,
        title: "Test22 Dec - 3rd Party",
        description: null,
        entityId: "TEST22",
        entityType: "THIRD_PARTY",
        enabled: true,
        editable: true,
        mandatory: false,
        readOnly: false,
        hidden: false,
        dataApi: null,
        jsonTempl: null,
        components: null
      }
    ],
    statusCode: 200,
    libraryId: "57474CFC-A382-ED11-AC20-CC60C8793D2B",
    profileType: "teen"
  }
}
export const CATEGORY_RES = [
  {
    text: "Newspapers & Magazines",
    value: "Newspapers & Magazines",
    vendorCode: "PRESSRDR",
    enabled: true,
    url: "/view/search/Newspapers & Magazines"
  },
  {
    text: "Videos",
    value: "Videos",
    vendorCode: "VIDEOS",
    enabled: true,
    url: "/view/search/Videos"
  },
  {
    text: "vBooks",
    value: "vBooks",
    vendorCode: "VBOOKS",
    enabled: true,
    url: "/view/search/vBooks"
  }
];

export const TIER2_REFINES_VIDEO = {
  refinerList: [
    {
      listname: "Language",
      options: [
        {
          bookCount: 2001,
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          displayName: "English",
          value: "ENGLISH"
        },
        {
          bookCount: 16,
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          displayName: "Spanish",
          value: "SPANISH"
        },
        {
          bookCount: 8,
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          displayName: "Italian",
          value: "ITALIAN"
        },
        {
          bookCount: 2,
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          displayName: "French",
          value: "FRENCH"
        },
        {
          bookCount: 2,
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          displayName: "Portuguese",
          value: "PORTUGUESE"
        },
        {
          bookCount: 2,
          groupDisplayName: "Language",
          groupRefinerValue: "languagedesc",
          displayName: "Hindi",
          value: "hindi"
        }
      ]
    },
    {
      listname: "Age Range",
      options: [
        {
          bookCount: 665,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Children's - Grade 4-6, Age 9-11",
          value: "Children's - Grade 4-6, Age 9-11"
        },
        {
          bookCount: 495,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Teen - Grade 7-9, Age 12-14",
          value: "Teen - Grade 7-9, Age 12-14"
        },
        {
          bookCount: 273,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Children's - Grade 3-4, Age 8-9",
          value: "Children's - Grade 3-4, Age 8-9"
        },
        {
          bookCount: 236,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Children's - Kindergarten, Age 5-6",
          value: "Children's - Kindergarten, Age 5-6"
        },
        {
          bookCount: 157,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Teen - Grade 10-12, Age 15-18",
          value: "Teen - Grade 10-12, Age 15-18"
        },
        {
          bookCount: 123,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Children's - Grade 1-2, Age 6-7",
          value: "Children's - Grade 1-2, Age 6-7"
        },
        {
          bookCount: 78,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Children's - Grade 2-3, Age 7-8",
          value: "Children's - Grade 2-3, Age 7-8"
        },
        {
          bookCount: 4,
          groupDisplayName: "Audience",
          groupRefinerValue: "audiencedesc",
          displayName: "Children's - Toddlers, Age 2-4",
          value: "Children's - Toddlers, Age 2-4"
        }
      ]
    }
  ],
  sortList: [
    {
      displayName: "Publication date",
      value: "pubDate"
    },
    {
      displayName: "Popularity",
      value: "score"
    },
    {
      displayName: "Relevance",
      value: "Relevance"
    }
  ]
};

export const VIDEO_ITEMLIST = [{
  bookTitle: "<b>Pets</b>",
  authors: {
    author: ["Hamilton, Joseph (PRD)"],
    narrator: null
  },
  itemId: "0012488399",
  format: "VID",
  iSBN: "9781223220154",
  imageURL: "http://contentcafeau.azureedge.net/Jacket.svc/F96D7B80-0E02-4F68-BDFC-6D9C11FD60A6/9781223220154/Medium/Logo",
  narrators: {
    author: null,
    narrator: []
  },
  publicationDate: "2022-06-18T00:00:00Z",
  series: "The Reading Road Trip",
  synopsis: "",
  purchaseOption: "MUPO",
  publisher: null,
  subject: "JUVENILE NONFICTION/Animals/Pets#JUVENILE NONFICTION/Crafts & Hobbies",
  edition: "",
  audience: "Children's - Grade 1-2, Age 6-7",
  isAvailable: "True",
  isBLIO: null,
  isAcoustik: null,
  isPDF: null,
  isEPub: null,
  isHold: null,
  isCheckout: "N",
  checkoutDetails: null,
  axisAttribute: "",
  language: "eng",
  audienceCode: "1-2",
  runTime: "",
  createdDate: "2022-12-07T13:17:28Z",
  rating: 0
}];

export const THIRD_PARTY_LAYOUT_COMP = [
  {
    vendorCode: "PRESSRDR",
    components: [
      {
        region: 1,
        title: "Publications",
        description: null,
        type: "Item_Carousel",
        subType: "Publications",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/pressreader/catalog/api/v1/publications",
          method: "GET",
          headers: {
            correlationid: "",
            "ocp-apim-subscription-key": "",
            "x-client-ip": ""
          },
          body: null,
          pathParams: null,
          library_settings: [],
          search: {
            limit: "16",
            offset: "0",
            orderBy: "searchrank",
            tp: "",
            inVal: "",
            inVal1: "",
            has: "",
            q: "the"
          },
          app: {
            limit: "false",
            offset: "false",
            orderBy: "false",
            tp: "false",
            inVal: "false",
            inVal1: "false",
            has: "false",
            q: "true"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          body: [
            {
              type: "Container",
              $data: "${$root}",
              items: [
                {
                  type: "Image",
                  size: "Large",
                  horizontalAlignment: "Center",
                  url: "https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2",
                  selectAction: {
                    type: "Action.Submit",
                    data: {
                      type: "title",
                      id: "${id}",
                      has: "${cid}",
                      issueDate: "${latestIssue.issueDate}",
                      latestIssue: "${latestIssue}",
                      title: "${displayName}"
                    },
                    id: "${id}"
                  },
                  width: "174.04px",
                  height: "255px"
                },
                {
                  type: "TextBlock",
                  text: "{{DATE(${latestIssue.issueDate}, COMPACT)}}",
                  wrap: true,
                  isSubtle: true,
                  weight: "Lighter"
                },
                {
                  type: "TextBlock",
                  text: "${countrySlug}",
                  wrap: true
                },
                {
                  type: "TextBlock",
                  wrap: true,
                  text: "${displayName}",
                  color: "Dark",
                  maxLines: 1,
                  weight: "Bolder"
                },
                {
                  type: "ActionSet",
                  actions: [
                    {
                      type: "Action.Submit",
                      title: "${if(buttonText, buttonText, 'Read Now')}",
                      id: "${id}",
                      data: {
                        url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                        type: "download",
                        has: "${cid}",
                        issueDate: "${latestIssue.issueDate}",
                        title: "${displayName}",
                        catalog: "${$root}"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        region: 1,
        title: "Articles",
        description: null,
        type: "Article_Carousel",
        subType: "Articles",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/pressreader/discovery/v1/search",
          method: "POST",
          headers: {
            "content-type": "application/json",
            correlationid: "",
            "ocp-apim-subscription-key": "",
            "x-client-ip": ""
          },
          body: {
            countries: [
              "us"
            ],
            languages: [
              "en"
            ],
            query: "the"
          },
          pathParams: null,
          library_settings: [],
          search: {
            limit: "15",
            offset: "0",
            sort: "Relevance",
            q: "the"
          },
          app: {
            limit: "false",
            offset: "false",
            sort: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          selectAction: {
            type: "Action.Submit",
            data: {
              type: "article",
              url: "https://www.pressreader.com/article/${article.id}",
              has: "${publication.cid}",
              title: "${article.title}"
            },
            id: "${article.id}"
          },
          body: [
            {
              type: "Container",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "auto",
                      items: [
                        {
                          type: "Image",
                          url: "${article.media[0].url}",
                          width: "80px",
                          height: "80px",
                          style: "Person",
                          selectAction: {
                            type: "Action.Submit",
                            data: {
                              type: "article",
                              url: "https://www.pressreader.com/article/${article.id}",
                              has: "${publication.cid}",
                              title: "${article.title}"
                            },
                            id: "${article.id}"
                          }
                        }
                      ],
                      verticalContentAlignment: "Center"
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${publication.title}",
                          wrap: true,
                          weight: "Bolder",
                          maxLines: 1
                        },
                        {
                          type: "TextBlock",
                          text: "{{DATE(${article.date}, COMPACT)}}",
                          isSubtle: true,
                          wrap: true,
                          spacing: "None"
                        },
                        {
                          type: "TextBlock",
                          weight: "Bolder",
                          text: "${article.title}",
                          wrap: true,
                          maxLines: 1,
                          spacing: "None"
                        },
                        {
                          type: "TextBlock",
                          text: "${content[0]}",
                          wrap: true,
                          maxLines: 3,
                          spacing: "None"
                        },
                        {
                          type: "ColumnSet",
                          columns: [
                            {
                              type: "Column",
                              width: "30px",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${if(article.author,'By :', '')}",
                                  weight: "Bolder"
                                }
                              ]
                            },
                            {
                              type: "Column",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${if(article.author,article.author, '')}",
                                  maxLines: 1
                                }
                              ],
                              spacing: "None",
                              width: "stretch"
                            }
                          ],
                          spacing: "None"
                        },
                        {
                          type: "ColumnSet",
                          columns: [
                            {
                              type: "Column",
                              width: "70px",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "Country :",
                                  weight: "Bolder"
                                }
                              ]
                            },
                            {
                              type: "Column",
                              width: "20px",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${publication.countries[0]}"
                                }
                              ],
                              spacing: "None"
                            }
                          ],
                          spacing: "None"
                        },
                        {
                          type: "TextBlock",
                          text: "in ${publication.language} from ${publication.countries[0]}",
                          spacing: "None"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5"
        }
      }
    ]
  },
  {
    vendorCode: "VIDEOS",
    components: [
      {
        region: 1,
        title: "Checkers Library TV",
        description: null,
        type: "Item_Carousel",
        subType: "Videos",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/kidszone/api/v1/ThirdPartySearch/v1",
          method: "GET",
          headers: {
            api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
            authorization: "",
            "ocp-apim-subscription-key": "",
            patronid: "72ca71d3-cc74-4d7e-87bf-8f674a6efd00",
            profileid: "141566",
            thirdparty: "true"
          },
          body: null,
          pathParams: null,
          library_settings: {
            contentCollectionId: "collection"
          },
          search: {
            searhSort: "score",
            sortOrder: "1",
            pageNumber: "0",
            pageSize: "10",
            formatType: "Video",
            audienceDesc: "teen,children",
            collectionType: "thirdparty",
            contentCollectionId: "3430b643-9371-ed11-819c-0022481e03c3",
            searchType: "term",
            searchTerm: "the",
            pubDateFilter: "",
            q: "the",
            series: ""
          },
          app: {
            searhSort: "false",
            sortOrder: "false",
            pageNumber: "false",
            pageSize: "false",
            formatType: "false",
            audienceDesc: "false",
            collectionType: "false",
            contentCollectionId: "false",
            searchType: "true",
            searchTerm: "true",
            pubDateFilter: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          body: [
            {
              type: "ColumnSet",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "Container",
                      selectAction: {
                        type: "Action.Submit",
                        data: {
                          type: "title",
                          ItemId: "${titleID}",
                          ISBN: "${iSBN}",
                          formatType: "${formatType}",
                          isAvailable: "${isAvailable}",
                          collectionId: "${collectionId}",
                          title: "${bookTitle}",
                          series: "${series}",
                          vendorCode: "${if(vendorCode,vendorCode, 'VIDEOS')}"
                        },
                        id: "adp_card_image_card_${formatType}_${titleID}"
                      },
                      backgroundImage: {
                        url: "${imageURL}"
                      },
                      items: [
                        {
                          type: "Image",
                          $when: "${!isMobile}",
                          style: "Person",
                          altText: "Videos ${bookTitle}",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          width: "24px",
                          height: "24px"
                        },
                        {
                          type: "Media",
                          $when: "${isMobile}",
                          style: "Person",
                          altText: "Videos ${bookTitle} button",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          id: "adp_card_image_icon_${formatType}_${titleID}"
                        }
                      ],
                      height: "stretch",
                      minHeight: "200px",
                      id: "adp_card_image_container_${formatType}_${titleID}"
                    }
                  ]
                }
              ],
              minHeight: "50px"
            },
            {
              type: "TextBlock",
              wrap: true,
              text: "${if(bookTitle,bookTitle,'')}",
              color: "Dark",
              maxLines: 1,
              weight: "Bolder",
              id: "adp_card_book_title_${titleID}"
            },
            {
              type: "TextBlock",
              text: "${if(authors.author[0], authors.author[0], if(authors.author,authors.author,''))}",
              wrap: true,
              maxLines: 1,
              spacing: "Small",
              id: "adp_card_book_author_${titleID}"
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'N'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "Checkout",
                          id: "adp_card_checkout_cta_${titleID}",
                          data: {
                            type: "actions",
                            actiontype: "checkout",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VIDEOS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'Y'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "${if(resumeCTA,resumeCTA,'Play')}",
                          id: "adp_card_${if(resumeCTA,'resume','play')}_cta_${titleID}",
                          data: {
                            type: "play",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VIDEOS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          verticalContentAlignment: "Top",
          id: "adp_card_image_container_${formatType}_${titleID}"
        }
      }
    ]
  },
  {
    vendorCode: "VBOOKS",
    components: [
      {
        region: 1,
        title: "Videobooks",
        description: null,
        type: "Item_Carousel",
        subType: "Videos",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/kidszone/api/v1/ThirdPartySearch/v1",
          method: "GET",
          headers: {
            api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
            authorization: "",
            "ocp-apim-subscription-key": "",
            patronid: "",
            profileid: "",
            thirdparty: "true"
          },
          body: null,
          pathParams: null,
          library_settings: {
            contentCollectionId: "collection"
          },
          search: {
            searhSort: "score",
            sortOrder: "1",
            pageNumber: "0",
            pageSize: "10",
            formatType: "Videobook",
            audienceDesc: "teen",
            collectionType: "thirdparty",
            contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
            searchType: "term",
            searchTerm: "",
            pubDateFilter: "",
            q: "the"
          },
          app: {
            searhSort: "false",
            sortOrder: "false",
            pageNumber: "false",
            pageSize: "false",
            formatType: "false",
            audienceDesc: "false",
            collectionType: "false",
            contentCollectionId: "false",
            searchType: "true",
            searchTerm: "true",
            pubDateFilter: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          body: [
            {
              type: "ColumnSet",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "Container",
                      selectAction: {
                        type: "Action.Submit",
                        data: {
                          type: "title",
                          ItemId: "${titleID}",
                          ISBN: "${iSBN}",
                          formatType: "${formatType}",
                          isAvailable: "${isAvailable}",
                          collectionId: "${collectionId}",
                          title: "${bookTitle}",
                          series: "${series}",
                          vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}"
                        },
                        id: "adp_card_image_card_${formatType}_${titleID}"
                      },
                      backgroundImage: {
                        url: "${imageURL}"
                      },
                      items: [
                        {
                          type: "Image",
                          $when: "${!isMobile}",
                          style: "Person",
                          altText: "Vbook ${bookTitle}",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          width: "24px",
                          height: "24px"
                        },
                        {
                          type: "Media",
                          $when: "${isMobile}",
                          style: "Person",
                          altText: "Vbook ${bookTitle} button",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          id: "adp_card_image_icon_${formatType}_${titleID}"
                        }
                      ],
                      height: "stretch",
                      minHeight: "200px",
                      id: "adp_card_image_container_${formatType}_${titleID}"
                    }
                  ]
                }
              ],
              minHeight: "50px"
            },
            {
              type: "TextBlock",
              wrap: true,
              text: "${if(bookTitle,bookTitle,'')}",
              color: "Dark",
              maxLines: 1,
              weight: "Bolder",
              id: "adp_card_book_title_${titleID}"
            },
            {
              type: "TextBlock",
              text: "${if(authors.author[0], authors.author[0], if(authors.author,authors.author,''))}",
              wrap: true,
              maxLines: 1,
              spacing: "Small",
              id: "adp_card_book_author_${titleID}"
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'N'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "Checkout",
                          id: "adp_card_checkout_cta_${titleID}",
                          data: {
                            type: "actions",
                            actiontype: "checkout",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'Y'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "${if(resumeCTA,resumeCTA,'Play')}",
                          id: "adp_card_${if(resumeCTA,'resume','play')}_cta_${titleID}",
                          data: {
                            type: "play",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          verticalContentAlignment: "Top"
        }
      }
    ]
  }
];

export const THIRD_PARTY_LAYOUT_ARRANGE_ORDER = [
  {
    vendorCode: "VIDEOS",
    components: [
      {
        region: 1,
        title: "Checkers Library TV",
        description: null,
        type: "Item_Carousel",
        subType: "Videos",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/kidszone/api/v1/ThirdPartySearch/v1",
          method: "GET",
          headers: {
            api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
            authorization: "",
            "ocp-apim-subscription-key": "",
            patronid: "72ca71d3-cc74-4d7e-87bf-8f674a6efd00",
            profileid: "141566",
            thirdparty: "true"
          },
          body: null,
          pathParams: null,
          library_settings: {
            contentCollectionId: "collection"
          },
          search: {
            searhSort: "score",
            sortOrder: "1",
            pageNumber: "0",
            pageSize: "10",
            formatType: "Video",
            audienceDesc: "teen,children",
            collectionType: "thirdparty",
            contentCollectionId: "3430b643-9371-ed11-819c-0022481e03c3",
            searchType: "term",
            searchTerm: "the",
            pubDateFilter: "",
            q: "the",
            series: ""
          },
          app: {
            searhSort: "false",
            sortOrder: "false",
            pageNumber: "false",
            pageSize: "false",
            formatType: "false",
            audienceDesc: "false",
            collectionType: "false",
            contentCollectionId: "false",
            searchType: "true",
            searchTerm: "true",
            pubDateFilter: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          body: [
            {
              type: "ColumnSet",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "Container",
                      selectAction: {
                        type: "Action.Submit",
                        data: {
                          type: "title",
                          ItemId: "${titleID}",
                          ISBN: "${iSBN}",
                          formatType: "${formatType}",
                          isAvailable: "${isAvailable}",
                          collectionId: "${collectionId}",
                          title: "${bookTitle}",
                          series: "${series}",
                          vendorCode: "${if(vendorCode,vendorCode, 'VIDEOS')}"
                        },
                        id: "adp_card_image_card_${formatType}_${titleID}"
                      },
                      backgroundImage: {
                        url: "${imageURL}"
                      },
                      items: [
                        {
                          type: "Image",
                          $when: "${!isMobile}",
                          style: "Person",
                          altText: "Videos ${bookTitle}",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          width: "24px",
                          height: "24px"
                        },
                        {
                          type: "Media",
                          $when: "${isMobile}",
                          style: "Person",
                          altText: "Videos ${bookTitle} button",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Video_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          id: "adp_card_image_icon_${formatType}_${titleID}"
                        }
                      ],
                      height: "stretch",
                      minHeight: "200px",
                      id: "adp_card_image_container_${formatType}_${titleID}"
                    }
                  ]
                }
              ],
              minHeight: "50px"
            },
            {
              type: "TextBlock",
              wrap: true,
              text: "${if(bookTitle,bookTitle,'')}",
              color: "Dark",
              maxLines: 1,
              weight: "Bolder",
              id: "adp_card_book_title_${titleID}"
            },
            {
              type: "TextBlock",
              text: "${if(authors.author[0], authors.author[0], if(authors.author,authors.author,''))}",
              wrap: true,
              maxLines: 1,
              spacing: "Small",
              id: "adp_card_book_author_${titleID}"
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'N'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "Checkout",
                          id: "adp_card_checkout_cta_${titleID}",
                          data: {
                            type: "actions",
                            actiontype: "checkout",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VIDEOS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'Y'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "${if(resumeCTA,resumeCTA,'Play')}",
                          id: "adp_card_${if(resumeCTA,'resume','play')}_cta_${titleID}",
                          data: {
                            type: "play",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VIDEOS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          verticalContentAlignment: "Top",
          id: "adp_card_image_container_${formatType}_${titleID}"
        }
      }
    ]
  },
  {
    vendorCode: "VBOOKS",
    components: [
      {
        region: 1,
        title: "Videobooks",
        description: null,
        type: "Item_Carousel",
        subType: "Videos",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/kidszone/api/v1/ThirdPartySearch/v1",
          method: "GET",
          headers: {
            api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
            authorization: "",
            "ocp-apim-subscription-key": "",
            patronid: "",
            profileid: "",
            thirdparty: "true"
          },
          body: null,
          pathParams: null,
          library_settings: {
            contentCollectionId: "collection"
          },
          search: {
            searhSort: "score",
            sortOrder: "1",
            pageNumber: "0",
            pageSize: "10",
            formatType: "Videobook",
            audienceDesc: "teen",
            collectionType: "thirdparty",
            contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
            searchType: "term",
            searchTerm: "",
            pubDateFilter: "",
            q: "the"
          },
          app: {
            searhSort: "false",
            sortOrder: "false",
            pageNumber: "false",
            pageSize: "false",
            formatType: "false",
            audienceDesc: "false",
            collectionType: "false",
            contentCollectionId: "false",
            searchType: "true",
            searchTerm: "true",
            pubDateFilter: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          body: [
            {
              type: "ColumnSet",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "Container",
                      selectAction: {
                        type: "Action.Submit",
                        data: {
                          type: "title",
                          ItemId: "${titleID}",
                          ISBN: "${iSBN}",
                          formatType: "${formatType}",
                          isAvailable: "${isAvailable}",
                          collectionId: "${collectionId}",
                          title: "${bookTitle}",
                          series: "${series}",
                          vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}"
                        },
                        id: "adp_card_image_card_${formatType}_${titleID}"
                      },
                      backgroundImage: {
                        url: "${imageURL}"
                      },
                      items: [
                        {
                          type: "Image",
                          $when: "${!isMobile}",
                          style: "Person",
                          altText: "Vbook ${bookTitle}",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          width: "24px",
                          height: "24px"
                        },
                        {
                          type: "Media",
                          $when: "${isMobile}",
                          style: "Person",
                          altText: "Vbook ${bookTitle} button",
                          url: "${if(isAvailable == 'True','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook_icon@2x.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/VBook2x_grey.png')}",
                          size: "Small",
                          horizontalAlignment: "Right",
                          id: "adp_card_image_icon_${formatType}_${titleID}"
                        }
                      ],
                      height: "stretch",
                      minHeight: "200px",
                      id: "adp_card_image_container_${formatType}_${titleID}"
                    }
                  ]
                }
              ],
              minHeight: "50px"
            },
            {
              type: "TextBlock",
              wrap: true,
              text: "${if(bookTitle,bookTitle,'')}",
              color: "Dark",
              maxLines: 1,
              weight: "Bolder",
              id: "adp_card_book_title_${titleID}"
            },
            {
              type: "TextBlock",
              text: "${if(authors.author[0], authors.author[0], if(authors.author,authors.author,''))}",
              wrap: true,
              maxLines: 1,
              spacing: "Small",
              id: "adp_card_book_author_${titleID}"
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'N'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "Checkout",
                          id: "adp_card_checkout_cta_${titleID}",
                          data: {
                            type: "actions",
                            actiontype: "checkout",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: "ColumnSet",
              $when: "${isCheckout == 'Y'}",
              columns: [
                {
                  type: "Column",
                  width: "stretch",
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          type: "Action.Submit",
                          title: "${if(resumeCTA,resumeCTA,'Play')}",
                          id: "adp_card_${if(resumeCTA,'resume','play')}_cta_${titleID}",
                          data: {
                            type: "play",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            title: "${bookTitle}",
                            vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}"
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          verticalContentAlignment: "Top"
        }
      }
    ]
  },
  {
    vendorCode: "PRESSRDR",
    components: [
      {
        region: 1,
        title: "Publications",
        description: null,
        type: "Item_Carousel",
        subType: "Publications",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/pressreader/catalog/api/v1/publications",
          method: "GET",
          headers: {
            correlationid: "",
            "ocp-apim-subscription-key": "",
            "x-client-ip": ""
          },
          body: null,
          pathParams: null,
          library_settings: [],
          search: {
            limit: "16",
            offset: "0",
            orderBy: "searchrank",
            tp: "",
            inVal: "",
            inVal1: "",
            has: "",
            q: "the"
          },
          app: {
            limit: "false",
            offset: "false",
            orderBy: "false",
            tp: "false",
            inVal: "false",
            inVal1: "false",
            has: "false",
            q: "true"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5",
          body: [
            {
              type: "Container",
              $data: "${$root}",
              items: [
                {
                  type: "Image",
                  size: "Large",
                  horizontalAlignment: "Center",
                  url: "https://i.prcdn.co/img?file=${latestIssue.key}&page=1&width=360&retina=2",
                  selectAction: {
                    type: "Action.Submit",
                    data: {
                      type: "title",
                      id: "${id}",
                      has: "${cid}",
                      issueDate: "${latestIssue.issueDate}",
                      latestIssue: "${latestIssue}",
                      title: "${displayName}"
                    },
                    id: "${id}"
                  },
                  width: "174.04px",
                  height: "255px"
                },
                {
                  type: "TextBlock",
                  text: "{{DATE(${latestIssue.issueDate}, COMPACT)}}",
                  wrap: true,
                  isSubtle: true,
                  weight: "Lighter"
                },
                {
                  type: "TextBlock",
                  text: "${countrySlug}",
                  wrap: true
                },
                {
                  type: "TextBlock",
                  wrap: true,
                  text: "${displayName}",
                  color: "Dark",
                  maxLines: 1,
                  weight: "Bolder"
                },
                {
                  type: "ActionSet",
                  actions: [
                    {
                      type: "Action.Submit",
                      title: "${if(buttonText, buttonText, 'Read Now')}",
                      id: "${id}",
                      data: {
                        url: "https://www.pressreader.com/${cid}/${formatDateTime(latestIssue.issueDate,'yyyyMMdd')}",
                        type: "download",
                        has: "${cid}",
                        issueDate: "${latestIssue.issueDate}",
                        title: "${displayName}",
                        catalog: "${$root}"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      },
      {
        region: 1,
        title: "Articles",
        description: null,
        type: "Article_Carousel",
        subType: "Articles",
        vendor_details: null,
        hideSeeAllLink: false,
        handleInApp: false,
        defaultValues: null,
        dataApi: {
          url: "https://apim-axis360api-qa2.azure-api.net/pressreader/discovery/v1/search",
          method: "POST",
          headers: {
            "content-type": "application/json",
            correlationid: "",
            "ocp-apim-subscription-key": "",
            "x-client-ip": ""
          },
          body: {
            countries: [
              "us"
            ],
            languages: [
              "en"
            ],
            query: "the"
          },
          pathParams: null,
          library_settings: [],
          search: {
            limit: "15",
            offset: "0",
            sort: "Relevance",
            q: "the"
          },
          app: {
            limit: "false",
            offset: "false",
            sort: "false"
          }
        },
        jsonTempl: {
          type: "AdaptiveCard",
          selectAction: {
            type: "Action.Submit",
            data: {
              type: "article",
              url: "https://www.pressreader.com/article/${article.id}",
              has: "${publication.cid}",
              title: "${article.title}"
            },
            id: "${article.id}"
          },
          body: [
            {
              type: "Container",
              items: [
                {
                  type: "ColumnSet",
                  columns: [
                    {
                      type: "Column",
                      width: "auto",
                      items: [
                        {
                          type: "Image",
                          url: "${article.media[0].url}",
                          width: "80px",
                          height: "80px",
                          style: "Person",
                          selectAction: {
                            type: "Action.Submit",
                            data: {
                              type: "article",
                              url: "https://www.pressreader.com/article/${article.id}",
                              has: "${publication.cid}",
                              title: "${article.title}"
                            },
                            id: "${article.id}"
                          }
                        }
                      ],
                      verticalContentAlignment: "Center"
                    },
                    {
                      type: "Column",
                      width: "stretch",
                      items: [
                        {
                          type: "TextBlock",
                          text: "${publication.title}",
                          wrap: true,
                          weight: "Bolder",
                          maxLines: 1
                        },
                        {
                          type: "TextBlock",
                          text: "{{DATE(${article.date}, COMPACT)}}",
                          isSubtle: true,
                          wrap: true,
                          spacing: "None"
                        },
                        {
                          type: "TextBlock",
                          weight: "Bolder",
                          text: "${article.title}",
                          wrap: true,
                          maxLines: 1,
                          spacing: "None"
                        },
                        {
                          type: "TextBlock",
                          text: "${content[0]}",
                          wrap: true,
                          maxLines: 3,
                          spacing: "None"
                        },
                        {
                          type: "ColumnSet",
                          columns: [
                            {
                              type: "Column",
                              width: "30px",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${if(article.author,'By :', '')}",
                                  weight: "Bolder"
                                }
                              ]
                            },
                            {
                              type: "Column",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${if(article.author,article.author, '')}",
                                  maxLines: 1
                                }
                              ],
                              spacing: "None",
                              width: "stretch"
                            }
                          ],
                          spacing: "None"
                        },
                        {
                          type: "ColumnSet",
                          columns: [
                            {
                              type: "Column",
                              width: "70px",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "Country :",
                                  weight: "Bolder"
                                }
                              ]
                            },
                            {
                              type: "Column",
                              width: "20px",
                              items: [
                                {
                                  type: "TextBlock",
                                  text: "${publication.countries[0]}"
                                }
                              ],
                              spacing: "None"
                            }
                          ],
                          spacing: "None"
                        },
                        {
                          type: "TextBlock",
                          text: "in ${publication.language} from ${publication.countries[0]}",
                          spacing: "None"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
          version: "1.5"
        }
      }
    ]
  }
];

export const LATEST_ISSUE_DATA_MOCK = [
  {
    displayName: "cat",
    countrySlug: "",
    id: 9149672,
    key: "85702023061600000000001001",
    cid: "8570",
    issueDate: "2023-06-16T00:00:00Z",
    version: 2,
    expungeVersion: 0,
    firstPage: {
      width: 764,
      height: 1096
    },
    issueVer: 0,
    country: "Australia",
    type: "Newspaper",
    latestIssue: {
      id: 9149672,
      key: "85702023061600000000001001",
      cid: "8570",
      issueDate: "2023-06-16T00:00:00Z",
      version: 2,
      expungeVersion: 0,
      firstPage: {
        width: 764,
        height: 1096
      },
      issueVer: 0,
      country: "Australia",
      type: "Newspaper"
    }
  },
  {
    displayName: "",
    countrySlug: "",
    id: 9135688,
    key: "85702023060900000000001001",
    cid: "8570",
    issueDate: "2023-06-09T00:00:00Z",
    version: 2,
    expungeVersion: 0,
    firstPage: {
      width: 764,
      height: 1096
    },
    issueVer: 0,
    country: "Australia",
    type: "Newspaper",
    latestIssue: {
      id: 9135688,
      key: "85702023060900000000001001",
      cid: "8570",
      issueDate: "2023-06-09T00:00:00Z",
      version: 2,
      expungeVersion: 0,
      firstPage: {
        width: 764,
        height: 1096
      },
      issueVer: 0,
      country: "Australia",
      type: "Newspaper"
    }
  },
  {
    displayName: "",
    countrySlug: "",
    id: 9121673,
    key: "85702023060200000000001001",
    cid: "8570",
    issueDate: "2023-06-02T00:00:00Z",
    version: 3,
    expungeVersion: 0,
    firstPage: {
      width: 764,
      height: 1096
    },
    issueVer: 0,
    country: "Australia",
    type: "Newspaper",
    latestIssue: {
      id: 9121673,
      key: "85702023060200000000001001",
      cid: "8570",
      issueDate: "2023-06-02T00:00:00Z",
      version: 3,
      expungeVersion: 0,
      firstPage: {
        width: 764,
        height: 1096
      },
      issueVer: 0,
      country: "Australia",
      type: "Newspaper"
    }
  },
  {
    displayName: "",
    countrySlug: "",
    id: 9107898,
    key: "85702023052600000000001001",
    cid: "8570",
    issueDate: "2023-05-26T00:00:00Z",
    version: 2,
    expungeVersion: 0,
    firstPage: {
      width: 764,
      height: 1096
    },
    issueVer: 0,
    country: "Australia",
    type: "Newspaper",
    latestIssue: {
      id: 9107898,
      key: "85702023052600000000001001",
      cid: "8570",
      issueDate: "2023-05-26T00:00:00Z",
      version: 2,
      expungeVersion: 0,
      firstPage: {
        width: 764,
        height: 1096
      },
      issueVer: 0,
      country: "Australia",
      type: "Newspaper"
    }
  }
];

export const EX_TIER3_TEMP = [
  {
    title: "Tier3_V2",
    pageType: "Publication Details",
    vendor: {
      name: "Videos",
      display_name: "Checkers Library TV",
      code: "VIDEOS",
      urlAlias: "/videos"
    },
    layout: {
      column_widths: "100%",
      components: [
        {
          region: 1,
          title: "Publication Details",
          description: null,
          type: "Item_Details",
          subType: "Videos",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartyItemDetails/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              itemID: "0012488398",
              patronID: "5597f65e-4463-4cb4-a59d-94a22e2d0918",
              profileid: "76774",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              collection: "thirdparty",
              libraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11"
            },
            app: {
              itemID: "false",
              patronID: "false",
              profileid: "false",
              contentCollectionId: "false",
              collection: "false",
              libraryId: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5",
            body: [
              {
                type: "Container",
                id: "adp_detail_page_container",
                extraProps: "${extraProps}",
                items: [
                  {
                    type: "ColumnSet",
                    $when: "${!isMobile}",
                    columns: [
                      {
                        type: "Column",
                        width: "280px",
                        items: [
                          {
                            type: "Container",
                            items: [
                              {
                                type: "Image",
                                altText: "Videos ${bookTitle}",
                                url: "${imageURL}",
                                horizontalAlignment: "Center",
                                width: "280px",
                                height: "400px"
                              }
                            ],
                            height: "stretch",
                            minHeight: "336px",
                            id: "adp_card_title_details_image_card"
                          }
                        ]
                      }
                    ],
                    minHeight: "50px",
                    horizontalAlignment: "Center"
                  },
                  {
                    type: "ColumnSet",
                    $when: "${isMobile}",
                    columns: [
                      {
                        type: "Column",
                        width: "stretch"
                      },
                      {
                        type: "Column",
                        width: "280px",
                        id: "adp_image_shadow_wrapper",
                        items: [
                          {
                            type: "Container",
                            id: "adp_image_shadow_container",
                            items: [
                              {
                                type: "Image",
                                altText: "Videos ${bookTitle}",
                                url: "${imageURL}",
                                horizontalAlignment: "Center",
                                width: "280px",
                                height: "400px",
                                id: "adp_card_title_details_image_cards"
                              },
                              {
                                type: "Container",
                                id: "adp_card_title_footer_text_wrap",
                                items: [
                                  {
                                    type: "Image",
                                    style: "Person",
                                    altText: "Videos ${bookTitle}",
                                    url: "${if(isAvailable == 'Y','https://prodaxiscdn.azureedge.net/reimaginedigital/kidszone/logo/Video_Format_Icon.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                                    size: "Small",
                                    horizontalAlignment: "Center",
                                    width: "36px",
                                    height: "36px",
                                    id: "adp_card_title_details_footer_format_icon_${formatType}"
                                  },
                                  {
                                    type: "TextBlock",
                                    text: "${if(runtime != 'NA' && runtime != '', runtime, formatType)}",
                                    wrap: true,
                                    id: "adp_card_footer_title_text"
                                  }
                                ],
                                style: "accent"
                              }
                            ],
                            height: "stretch",
                            minHeight: "336px"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch"
                      }
                    ],
                    minHeight: "50px",
                    horizontalAlignment: "Center"
                  }
                ]
              }
            ]
          }
        },
        {
          region: 1,
          title: "Publication Title Details",
          description: null,
          type: "Item_Title_Block_Details",
          subType: "Title_Block",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartyItemDetails/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              itemID: "0012488398",
              patronID: "5597f65e-4463-4cb4-a59d-94a22e2d0918",
              profileid: "76774",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              collection: "thirdparty",
              libraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11"
            },
            app: {
              itemID: "false",
              patronID: "false",
              profileid: "false",
              contentCollectionId: "false",
              collection: "false",
              libraryId: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            body: [
              {
                type: "Container",
                id: "title_text_block",
                items: [
                  {
                    type: "ColumnSet",
                    columns: [
                      {
                        type: "Column",
                        id: "title_block",
                        items: [
                          {
                            type: "TextBlock",
                            weight: "Bolder",
                            id: "title_block",
                            text: "${if(bookTitle, bookTitle, '')}",
                            wrap: true
                          },
                          {
                            type: "TextBlock",
                            spacing: "None",
                            text: "${if(subTitle, subTitle, '')}",
                            isSubtle: true,
                            wrap: true
                          }
                        ],
                        width: "stretch"
                      },
                      {
                        type: "Column",
                        id: "title_wishList_share_block",
                        items: [
                          {
                            type: "ColumnSet",
                            columns: [
                              {
                                type: "Column",
                                $when: "${isWishList != 'N'}",
                                selectAction: {
                                  type: "Action.Submit",
                                  data: {
                                    type: "wishList"
                                  }
                                },
                                items: [
                                  {
                                    type: "Image",
                                    $when: "${isWishList == 'A'}",
                                    url: "https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/wishlist.svg",
                                    horizontalAlignment: "Center",
                                    width: "20px",
                                    height: "20px"
                                  },
                                  {
                                    type: "Image",
                                    $when: "${isWishList == 'R'}",
                                    url: "https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/wishlist-fill.svg",
                                    horizontalAlignment: "Center",
                                    width: "20px",
                                    height: "20px"
                                  },
                                  {
                                    type: "TextBlock",
                                    text: "Wishlist",
                                    wrap: true,
                                    color: "Accent"
                                  }
                                ],
                                width: "auto"
                              },
                              {
                                type: "Column",
                                selectAction: {
                                  type: "Action.Submit",
                                  data: {
                                    type: "share"
                                  }
                                },
                                items: [
                                  {
                                    type: "Image",
                                    url: "https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/share.svg",
                                    horizontalAlignment: "Center",
                                    width: "20px",
                                    height: "20px"
                                  },
                                  {
                                    type: "TextBlock",
                                    text: "Share",
                                    wrap: true,
                                    color: "Accent"
                                  }
                                ],
                                width: "auto"
                              }
                            ]
                          }
                        ],
                        width: "100px"
                      }
                    ]
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.6"
          }
        },
        {
          region: 1,
          title: "Publication Title Details",
          description: null,
          type: "Item_Title_Author_Details",
          subType: "Title_Author",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartyItemDetails/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              itemID: "0012488398",
              patronID: "5597f65e-4463-4cb4-a59d-94a22e2d0918",
              profileid: "76774",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              collection: "thirdparty",
              libraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11"
            },
            app: {
              itemID: "false",
              patronID: "false",
              profileid: "false",
              contentCollectionId: "false",
              collection: "false",
              libraryId: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            body: [
              {
                type: "Container",
                items: [
                  {
                    type: "ColumnSet",
                    $when: "${count(authors.author) != 0}",
                    id: "author_text_block",
                    columns: [
                      {
                        type: "Column",
                        width: "70px",
                        altText: "By",
                        items: [
                          {
                            type: "TextBlock",
                            text: "By:",
                            wrap: true,
                            id: "adp_card_title_details_author"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        $when: "${if(isVendorEnable, if(isVendorEnable == 'false', true, false), false)}",
                        items: [
                          {
                            type: "TextBlock",
                            $data: "${authors.author}",
                            text: "${$data}",
                            wrap: true,
                            id: "adp_card_title_details_author_value",
                            color: "Accent"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        id: "author_text_groups",
                        $when: "${if(isVendorEnable, if(isVendorEnable == 'true', true, false), true)}",
                        items: [
                          {
                            type: "Container",
                            $data: "${authors.author}",
                            altText: "${$data} link",
                            items: [
                              {
                                type: "TextBlock",
                                text: "${$data}",
                                color: "Accent",
                                id: "adp_card_title_details_author_value"
                              }
                            ],
                            selectAction: {
                              type: "Action.Submit",
                              id: "adp_card_title_details_author_value",
                              data: {
                                type: "search",
                                searchType: "author",
                                searchTerm: "${$data}",
                                subType: "Videos",
                                title: "${$data}",
                                vendorCode: "${if(vendorCode,vendorCode, 'VBOOKS')}"
                              }
                            }
                          }
                        ]
                      }
                    ],
                    spacing: "Medium"
                  },
                  {
                    type: "ColumnSet",
                    $when: "${count(narrators.narrator) != 0}",
                    id: "narrators_text_block",
                    altText: "Narrator ${if(count(narrators.narrator) > 0,narrators.narrator[0],'-')}",
                    columns: [
                      {
                        type: "Column",
                        items: [
                          {
                            type: "TextBlock",
                            text: "Narrator By:",
                            wrap: true,
                            id: "adp_card_title_details_narrator"
                          }
                        ],
                        width: "70px"
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(count(narrators.narrator) > 0,narrators.narrator[0],'-')}",
                            wrap: true,
                            id: "adp_card_title_details_narrator_value"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.6"
          }
        },
        {
          region: 1,
          title: "Publication Title Details",
          description: null,
          type: "Item_Info_Details",
          subType: "Title_Info_details",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartyItemDetails/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              itemID: "0012488398",
              patronID: "5597f65e-4463-4cb4-a59d-94a22e2d0918",
              profileid: "76774",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              collection: "thirdparty",
              libraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11"
            },
            app: {
              itemID: "false",
              patronID: "false",
              profileid: "false",
              contentCollectionId: "false",
              collection: "false",
              libraryId: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            body: [
              {
                type: "ColumnSet",
                columns: [
                  {
                    type: "Column",
                    id: "title_format_details",
                    items: [
                      {
                        type: "Container",
                        items: [
                          {
                            type: "ColumnSet",
                            columns: [
                              {
                                type: "Column",
                                items: [
                                  {
                                    type: "Image",
                                    url: "${if(format, if(format== 'VID','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/kz-videos1.svg','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/kz-vbook1.svg'), '')}",
                                    backgroundColor: "black",
                                    width: "25px",
                                    height: "25px"
                                  }
                                ],
                                width: "23px"
                              },
                              {
                                type: "Column",
                                items: [
                                  {
                                    type: "TextBlock",
                                    weight: "Bolder",
                                    text: "${if(format, if(format== 'VID','Videos','VideoBooks'), '')}",
                                    wrap: true
                                  }
                                ],
                                width: "stretch"
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    width: "80px"
                  },
                  {
                    type: "Column",
                    width: "stretch",
                    $when: "${BookLength != ''}",
                    id: "title_length_details",
                    items: [
                      {
                        type: "Container",
                        items: [
                          {
                            type: "TextBlock",
                            weight: "Bolder",
                            text: "${if(BookLength, BookLength, '')}",
                            wrap: true,
                            horizontalAlignment: "Center"
                          },
                          {
                            type: "TextBlock",
                            weight: "Bolder",
                            text: "${if(BookLength, 'Pages', '')}",
                            wrap: true,
                            horizontalAlignment: "Center",
                            spacing: "None"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "Column",
                    width: "stretch",
                    id: "title_time_details",
                    $when: "${runtime != '' && runtime != 'NA'}",
                    items: [
                      {
                        type: "Container",
                        items: [
                          {
                            type: "Image",
                            url: "${if(format, if(format== 'VID','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/kz-videos1.svg','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/kz-vbook1.svg'), '')}",
                            backgroundColor: "black",
                            width: "20px",
                            height: "20px",
                            horizontalAlignment: "Center"
                          }
                        ]
                      },
                      {
                        type: "Container",
                        items: [
                          {
                            type: "TextBlock",
                            weight: "Bolder",
                            text: "${if(runtime, runtime, '')}",
                            wrap: true,
                            horizontalAlignment: "Center"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "Column",
                    id: "title_audience_details",
                    items: [
                      {
                        type: "ColumnSet",
                        columns: [
                          {
                            type: "Column",
                            items: [
                              {
                                type: "TextBlock",
                                text: "${if(ageRangeList, ageRangeList.name, '')}",
                                wrap: true
                              }
                            ],
                            width: "40px"
                          },
                          {
                            type: "Column",
                            items: [
                              {
                                type: "TextBlock",
                                weight: "Bolder",
                                text: "${if(ageRangeList, ageRangeList.age, '')}",
                                wrap: false
                              }
                            ],
                            width: "25px"
                          }
                        ]
                      }
                    ],
                    width: "stretch"
                  },
                  {
                    type: "Column",
                    id: "title_language_details",
                    items: [
                      {
                        type: "ColumnSet",
                        columns: [
                          {
                            type: "Column",
                            items: [
                              {
                                type: "TextBlock",
                                text: "${if(language, language, '')}",
                                wrap: true
                              }
                            ],
                            width: "stretch"
                          }
                        ]
                      }
                    ],
                    width: "stretch"
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.6"
          }
        },
        {
          region: 1,
          title: "Publication CTA Details",
          description: null,
          type: "Item_CTA_Details",
          subType: "CTA",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartyItemDetails/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              itemID: "0012488398",
              patronID: "5597f65e-4463-4cb4-a59d-94a22e2d0918",
              profileid: "76774",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              collection: "thirdparty",
              libraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11"
            },
            app: {
              itemID: "false",
              patronID: "false",
              profileid: "false",
              contentCollectionId: "false",
              collection: "false",
              libraryId: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            body: [
              // {
              //   type: "Container",
              //   id: "title_details_cta",
              //   selectAction: {
              //     type: "Action.Submit",
              //     data: {
              //       primaryCTA: [
              //         {
              //           titleCta: "CheckOut",
              //           type: "actions",
              //           actiontype: "checkout",
              //           pageName: "tier3",
              //           ItemId: "${titleID}",
              //           ISBN: "${ISBN}",
              //           title: "${bookTitle}",
              //           vendorCode: "VIDEOS",
              //           formatType: "${format}",
              //           isAvailable: "${isAvailable}",
              //           collectionId: "${collectionId}",
              //           isEnable: true,
              //           isVisible: "${if(isVendorEnable, if(isVendorEnable == 'false', false, true), true) && isCheckedout == 'false'}"
              //         },
              //         {
              //           titleCta: "${if(resumeCTA,resumeCTA,'Play')}",
              //           type: "play",
              //           pageName: "tier3",
              //           ItemId: "${titleID}",
              //           ISBN: "${ISBN}",
              //           title: "${bookTitle}",
              //           vendorCode: "VIDEOS",
              //           formatType: "${format}",
              //           isAvailable: "${isAvailable}",
              //           collectionId: "${collectionId}",
              //           isEnable: true,
              //           isVisible: "${isCheckedout == 'true'}"
              //         }
              //       ],
              //       secondaryCTA: [
              //         {
              //           titleCta: "Renew",
              //           type: "actions",
              //           actiontype: "renewtitle",
              //           pageName: "tier3",
              //           ItemId: "${titleID}",
              //           ISBN: "${ISBN}",
              //           title: "${bookTitle}",
              //           vendorCode: "VIDEOS",
              //           formatType: "${format}",
              //           isAvailable: "${isAvailable}",
              //           collectionId: "${collectionId}",
              //           isEnable: true,
              //           isVisible: "${isCheckedout == 'true' && isRenewButton == 'Y'}"
              //         },
              //         {
              //           titleCta: "Return",
              //           type: "actions",
              //           actiontype: "returntitle",
              //           pageName: "tier3",
              //           ItemId: "${titleID}",
              //           ISBN: "${ISBN}",
              //           title: "${bookTitle}",
              //           vendorCode: "VIDEOS",
              //           formatType: "${format}",
              //           isAvailable: "${isAvailable}",
              //           collectionId: "${collectionId}",
              //           isEnable: true,
              //           isVisible: "${isCheckedout == 'true'}"
              //         }
              //       ]
              //     }
              //   }
              // },
              {
                type: "ProgressBar",
                id: "title_details_cta",
                title: "This is a progress bar",
                value: 45
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5"
          }
        },
        {
          region: 1,
          title: "Publication List Details",
          description: null,
          type: "Item_List_Details",
          subType: "Videos",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartyItemDetails/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              itemID: "0012488398",
              patronID: "5597f65e-4463-4cb4-a59d-94a22e2d0918",
              profileid: "76774",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              collection: "thirdparty",
              libraryId: "E8862F37-CB93-EC11-A507-0003FFFF6A11"
            },
            app: {
              itemID: "false",
              patronID: "false",
              profileid: "false",
              contentCollectionId: "false",
              collection: "false",
              libraryId: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5",
            body: [
              {
                type: "Container",
                spacing: "ExtraLarge",
                id: "adb_sub_item_container",
                separator: true,
                items: [
                  {
                    type: "ColumnSet",
                    $when: "${count(authors.author) != 0}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "AUTHOR",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_author"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(count(authors.author) > 0,authors.author[0],if(authors.author,authors.author,'-'))}",
                            wrap: true,
                            id: "adp_card_title_details_author_value",
                            color: "Accent"
                          }
                        ],
                        selectAction: {
                          type: "Action.Submit",
                          id: "adp_card_title_details_author_value",
                          data: {
                            type: "search",
                            searchType: "author",
                            searchTerm: "${if(count(authors.author) > 0,authors.author[0],if(authors.author,authors.author,''))}",
                            subType: "Videos",
                            title: "${if(count(authors.author) > 0,authors.author[0],if(authors.author,authors.author,''))}",
                            vendorCode: "VIDEOS"
                          }
                        }
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${series != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "SERIES",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_series"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(series != '',series,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_series_value",
                            color: "Accent"
                          }
                        ],
                        selectAction: {
                          type: "Action.Submit",
                          id: "adp_card_title_details_series_value",
                          data: {
                            type: "search",
                            searchType: "series",
                            searchTerm: "${if(series != '',series,'-')}",
                            subType: "Videos",
                            title: "${if(series != '',series,'-')}",
                            vendorCode: "VIDEOS"
                          }
                        }
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${count(narrators.narrator) != 0}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        items: [
                          {
                            type: "TextBlock",
                            text: "NARRATOR",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_narrator"
                          }
                        ],
                        width: "stretch"
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(count(narrators.narrator) > 0,narrators.narrator[0],'-')}",
                            wrap: true,
                            id: "adp_card_title_details_narrator_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${runtime && runtime != 'NA' && runtime != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "VIDEO LENGTH",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_video_length"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(runtime,runtime,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_video_length_value"
                          }
                        ],
                        id: "adp_card_title_details_video_length_value"
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${formatType != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "FORMAT",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_format"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(formatType,formatType,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_format_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${fileSize != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "DOWNLOAD SIZE",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_download_size"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(fileSize,fileSize,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_download_size_value"
                          }
                        ],
                        id: "adp_card_title_details_download_size_value"
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${language != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "LANGUAGE",
                            isSubtle: true,
                            id: "adp_card_title_details_language"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(language,titleCase(language),'-')}",
                            wrap: true,
                            id: "adp_card_title_details_language_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${count(attributes.attribute) != 0}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "ATTRIBUTE",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_attribute"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(count(attributes.attribute) > 0,attributes.attribute[0],'-')}",
                            wrap: true,
                            id: "adp_card_title_details_attribute_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${publisher != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "PUBLISHER",
                            isSubtle: true,
                            id: "adp_card_title_details_language"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(publisher,publisher,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_language_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${publicationDate != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "PUBLISH DATE",
                            isSubtle: true,
                            id: "adp_card_title_details_language"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(publicationDate,publicationDate,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_language_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${ISBN != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "ISBN",
                            wrap: true,
                            isSubtle: true,
                            id: "adp_card_title_details_isbn"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(ISBN,ISBN,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_isbn_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${audience != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "AUDIENCE",
                            isSubtle: true,
                            id: "adp_card_title_details_audience_type"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(audience,audience,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_audience_type_value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    type: "ColumnSet",
                    $when: "${ageRange != ''}",
                    id: "adp_sub_details_text",
                    columns: [
                      {
                        type: "Column",
                        width: "130px",
                        items: [
                          {
                            type: "TextBlock",
                            text: "AGE RANGE",
                            isSubtle: true,
                            id: "adp_card_title_details_age_range"
                          }
                        ]
                      },
                      {
                        type: "Column",
                        width: "stretch",
                        items: [
                          {
                            type: "TextBlock",
                            text: "${if(ageRange,ageRange,'-')}",
                            wrap: true,
                            id: "adp_card_title_details_age_range_value"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          region: 1,
          title: "Titles in This Series",
          description: null,
          type: "Item_Carousel",
          subType: "Videos",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdPartySearch/v1",
            method: "GET",
            headers: {
              api_auth_key: "AD256hFdtr56iBZs8YnFwLmi7fRrnRzi873Vmn71D",
              authorization: "",
              "ocp-apim-subscription-key": "",
              patronid: "",
              profileid: "",
              thirdparty: "true"
            },
            body: null,
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              searhSort: "",
              sortOrder: "",
              pageNumber: "0",
              pageSize: "10",
              formatType: "Video",
              audienceDesc: "",
              collectionType: "thirdparty",
              contentCollectionId: "3430B643-9371-ED11-819C-0022481E03C3",
              searchType: "series",
              searchTerm: "",
              pubDateFilter: ""
            },
            app: {
              searhSort: "false",
              sortOrder: "false",
              pageNumber: "false",
              pageSize: "false",
              formatType: "false",
              audienceDesc: "false",
              collectionType: "false",
              contentCollectionId: "false",
              searchType: "false",
              searchTerm: "true",
              pubDateFilter: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            id: "primary_shadow_container",
            body: [
              {
                type: "ColumnSet",
                columns: [
                  {
                    type: "Column",
                    width: "stretch",
                    items: [
                      {
                        type: "Container",
                        selectAction: {
                          type: "Action.Submit",
                          data: {
                            type: "title",
                            ItemId: "${titleID}",
                            ISBN: "${iSBN}",
                            formatType: "${formatType}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}",
                            title: "${bookTitle}",
                            series: "${series}",
                            vendorCode: "VIDEOS"
                          },
                          id: "adp_card_image_${titleID}"
                        },
                        backgroundImage: {
                          url: "${imageURL}"
                        },
                        height: "stretch",
                        minHeight: "192px"
                      }
                    ]
                  }
                ],
                minHeight: "50px"
              },
              {
                type: "ColumnSet",
                id: "video-poster-bg",
                style: "good",
                columns: [
                  {
                    type: "Column",
                    id: "video_text",
                    title: "Vbook",
                    items: [
                      {
                        type: "Image",
                        $when: "${!isMobile}",
                        style: "Person",
                        altText: "Videos ${bookTitle}",
                        url: "${if(isAvailable == 'True','https://cdne-axis-qa-005.azureedge.net/reimaginedigital/kidszone/thirdparty/Video_icon@2x.svg','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.svg')}",
                        size: "Small",
                        horizontalAlignment: "Right",
                        width: "24px",
                        height: "24px"
                      },
                      {
                        type: "Media",
                        $when: "${isMobile}",
                        style: "Person",
                        altText: "Videos ${bookTitle} button",
                        url: "${if(isAvailable == 'True','https://prodaxiscdn.azureedge.net/reimaginedigital/kidszone/logo/Video_Format_Icon.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                        size: "Small",
                        horizontalAlignment: "Right",
                        id: "adp_card_image_icon_${formatType}_${titleID}"
                      }
                    ],
                    width: "stretch"
                  },
                  {
                    type: "Column",
                    id: "adp_card_title_text_footer_container",
                    title: "Vbook",
                    items: [
                      {
                        type: "TextBlock",
                        wrap: true,
                        text: "${if(runTime == '', 'Video', runTime)}",
                        color: "Dark",
                        weight: "Bolder",
                        id: "adp_card_title_text_bottom",
                        title: "Vbook"
                      }
                    ],
                    width: "stretch"
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5",
            verticalContentAlignment: "Top"
          }
        },
        {
          region: 1,
          title: "More Like This",
          description: null,
          type: "Item_Carousel",
          subType: "Recommend_Videos",
          vendor_details: null,
          hideSeeAllLink: false,
          seeAllLinkPage: null,
          handleInApp: false,
          defaultValues: null,
          dataApi: {
            url: "https://apim-axis360api-qa005.azure-api.net/kidszone/api/v1/ThirdpartyRecommendation",
            method: "POST",
            headers: {
              api_auth_key: "AD509hFdtr56iBZs8YnKwLmi0fRrnRzi598Vmn01D",
              "content-type": "application/json",
              "ocp-apim-subscription-key": "",
              thirdparty: "true"
            },
            body: {
              PatronGUID: "b50cb181-f133-44c8-a7c2-b1d2081b993e",
              LibraryId: "3430B643-9371-ED11-819C-0022481E03C3",
              ByPassCache: false,
              Books: [
                {
                  ItemId: "0099488428"
                }
              ],
              Filters: {
                Format: "video"
              }
            },
            pathParams: null,
            library_settings: {
              contentCollectionId: "collection"
            },
            search: {
              pageNumber: "0",
              pageSize: "10"
            },
            app: {
              pageNumber: "false",
              pageSize: "false"
            }
          },
          jsonTempl: {
            type: "AdaptiveCard",
            id: "primary_shadow_container",
            body: [
              {
                type: "ColumnSet",
                columns: [
                  {
                    type: "Column",
                    width: "stretch",
                    items: [
                      {
                        type: "Container",
                        selectAction: {
                          type: "Action.Submit",
                          data: {
                            type: "title",
                            ItemId: "${itemId}",
                            ISBN: "${iSBN}",
                            formatType: "${format}",
                            isAvailable: "${isAvailable}",
                            collectionId: "${collectionId}",
                            title: "${title}",
                            series: "${series}",
                            vendorCode: "VIDEOS"
                          },
                          id: "adp_card_image_${itemId}"
                        },
                        backgroundImage: {
                          url: "${imageURL}"
                        },
                        height: "stretch",
                        minHeight: "192px"
                      }
                    ]
                  }
                ],
                minHeight: "50px"
              },
              {
                type: "ColumnSet",
                id: "video-poster-bg",
                style: "good",
                columns: [
                  {
                    type: "Column",
                    id: "video_text",
                    title: "Videos",
                    items: [
                      {
                        type: "Image",
                        $when: "${!isMobile}",
                        style: "Person",
                        altText: "Videos ${title}",
                        url: "${if(isAvailable == 'true','https://cdne-axis-qa-005.azureedge.net/reimaginedigital/kidszone/thirdparty/Video_icon@2x.svg','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.svg')}",
                        size: "Small",
                        horizontalAlignment: "Right",
                        width: "24px",
                        height: "24px"
                      },
                      {
                        type: "Media",
                        $when: "${isMobile}",
                        style: "Person",
                        altText: "Videos ${title}",
                        url: "${if(isAvailable == 'true','https://prodaxiscdn.azureedge.net/reimaginedigital/kidszone/logo/Video_Format_Icon.png','https://devaxiscdnstorge.blob.core.windows.net/reimaginedigital/KidsZone/thirdparty/Videos2x_grey.png')}",
                        size: "Small",
                        horizontalAlignment: "Right",
                        width: "24px"
                      }
                    ],
                    width: "stretch"
                  },
                  {
                    type: "Column",
                    id: "adp_card_title_text_footer_container",
                    title: "Videos",
                    items: [
                      {
                        type: "TextBlock",
                        $when: "${runTime && runTime != ''}",
                        wrap: true,
                        text: "${runTime}",
                        color: "Dark",
                        weight: "Bolder",
                        id: "adp_card_title_text_bottom",
                        title: "Videos"
                      },
                      {
                        type: "TextBlock",
                        $when: "${!runTime || runTime == ''}",
                        wrap: true,
                        text: "Video",
                        color: "Dark",
                        weight: "Bolder",
                        id: "adp_card_title_text_bottom",
                        title: "Videos"
                      }
                    ],
                    width: "stretch"
                  }
                ]
              }
            ],
            $schema: "http://adaptivecards.io/schemas/adaptive-card.json",
            version: "1.5",
            verticalContentAlignment: "Top"
          }
        }
      ]
    }
  }
];
