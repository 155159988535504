import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  SITES_FILTER,
  GRADE_MAP,
  MAT_TABS_NAME,
  SEARCH_RESULT_PAGE_SIZE,
  FORMAT_TYPE,
  SITES_DEFAULT_IMG,
  ACTION_HANDLER_TYPE,
  SITES_NO_RESULTS,
  SITES_TITLE,
  SOURCE_TYPE,
  MAT_TABS_ID,
  TAB_GROUP_ID,
  MAT_TABS
} from '@search/shared/constants/search-results.constants';
import { AppliedFilter, Facet, Filter } from '@search/shared/data/filter-model';
import { GetSitesRequest, Sites, WPEDOC } from '@search/shared/data/sites-model';
import { SubjectInfo, TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { SearchService } from '@search/shared/services/search.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { Subscription } from 'rxjs';
import { cdkRemoveVisualHidden, cdkVisualHidden, positionFooter, setSelectedTabId } from '@shared/helper/app.util';
import { checkLoginAndHandleAction, getResultsCount } from '@search/shared/helper/search.util';
import { SEARCH_EVENTS_KEY } from '@search/shared/constants/search.analytics.constants';
import { DEFAULT_SEARCH_FAILS_MSG, DEFAULT_SNAKE_BAR_MSG, SEARCH_QUERY_PARAM, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UserService } from '@shared/services/user.service';
type Action = keyof typeof SEARCH_EVENTS_KEY;
@Component({
  selector: 'axis360-site-results',
  templateUrl: './sites-results.component.html'
})
export class SitesResultsComponent implements OnInit, OnDestroy, AfterViewInit {
  sitesInfo: TitleCardInfo[] = [];
  searchTerm: string;
  totalCount = 0;
  pageIndex = 0;
  availableLimiters: Filter[] = [];
  view: string;
  gradeMap = '';
  domainFilter = '';
  languageFilter = '';
  sourceFilter = '';
  formatFilter = '';
  subjectFilter = '';
  scrollSubscription: Subscription;
  initialFailed = false;
  SITES_TITLE = SITES_TITLE;
  /**
   * Creates an instance of sites results component.
   * @param activatedRoute Activated route info
   * @param searchService Search service
   * @param spinnerService Spinner service
   * @param snackBarService Snackbar service
   * @param infiniteScroll InfiniteScroll service
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    public spinnerService: SpinnerService,
    private snackBarService: SnackBarService,
    private infiniteScroll: InfiniteScrollService,
    private searchAnalyticsService: SearchAnalyticsService,
    private searchStorageService: SearchStorageService,
    private liveAnnouncer: LiveAnnouncer,
    private userService: UserService
  ) {
    this.loadMore = this.loadMore.bind(this);
  }
  /**
   * To get sites data from API by searchTerm
   */
  ngOnInit(): void {
    this.liveAnnouncer.announce(SITES_TITLE, 'assertive');
    this.searchService.selectedSearchTab = MAT_TABS.indexOf(MAT_TABS_NAME.SITES);
    this.searchTerm = this.activatedRoute.snapshot.queryParams[SEARCH_QUERY_PARAM] || '';
    this.getRefiners();
    this.getSitesData();
    this.view = MAT_TABS_NAME.SITES;
    this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
    this.searchAnalyticsService.trackScreen('SITES');
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
  }
  ngAfterViewInit() {
    setSelectedTabId(TAB_GROUP_ID, MAT_TABS_ID[MAT_TABS_NAME.SITES]);
  }
  /**
   * To announces result count
   */
  announceResult() {
    if (this.pageIndex === 0) {
      this.totalCount === 0
        ? this.liveAnnouncer.announce(SITES_NO_RESULTS.split('<searchTerm>').join(this.searchTerm), 'assertive')
        : this.liveAnnouncer.announce(getResultsCount(this.sitesInfo.length, this.totalCount, this.searchTerm), 'assertive');
    }
  }
  /**
   * To get sites data on init and load more click
   */
  getSitesData() {
    const req: GetSitesRequest = {
      searchText: this.searchTerm,
      pageIndex: this.pageIndex,
      pageSize: SEARCH_RESULT_PAGE_SIZE,
      gradeMap: this.gradeMap,
      domainFilter: this.domainFilter,
      languageFilter: this.languageFilter,
      sourceFilter: this.sourceFilter,
      subjectFilter: this.subjectFilter,
      formatFilter: this.formatFilter
    };
    this.spinnerService.showLoader();
    if (this.pageIndex === 0) {
      this.sitesInfo = [];
    }
    this.searchService.getSitesResults(req).subscribe(
      (response) => {
        const { WPEDOC: wpeDoc } = response;
        if (!wpeDoc) {
          this.spinnerService.showLoader(false);
          this.liveAnnouncer.announce(SITES_NO_RESULTS.split('<searchTerm>').join(this.searchTerm), 'assertive');
          positionFooter(true);
          return;
        }
        const { WS_DETAIL_ENTRY: sitesInfo = [], PAGERECORDCOUNT: recordCount = [{ Count: 0 }] } = wpeDoc;
        this.sitesInfo = this.sitesInfo.concat(sitesInfo.filter((item) => item).map((siteInfo) => this.getSiteCard(siteInfo)));
        positionFooter(true);
        this.updateFilters(wpeDoc);
        this.totalCount = recordCount[0].Count;
        this.spinnerService.showLoader(false);
        this.announceResult();
      },
      () => {
        this.spinnerService.showLoader(false);
        positionFooter(true);
        if (this.pageIndex === 0) {
          this.initialFailed = true;
          this.totalCount = 0;
          this.liveAnnouncer.announce(DEFAULT_SNAKE_BAR_MSG, 'assertive');
          return;
        }
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SEARCH_FAILS_MSG);
      }
    );
  }
  /**
   * To convert sites data to title card info for title info component rendering
   * @param sites Sites object
   * @returns title card info based on sites data
   */
  public getSiteCard(sites: Sites): TitleCardInfo {
    const {
      DESCRIPTION: description = '',
      DISPLAYURL: displayURL = '',
      TITLE: mainTitle = '',
      IMAGEDATA: image = { ID: '', URL: undefined, imageAlt: 'No Image' },
      HEADING: subjects = [],
      LEXILE_MEASURE: lexile = '',
      LANGUAGE: languages = [],
      GRADE_PREK_2 = '0',
      GRADE_3_5 = '0',
      GRADE_6_8 = '0',
      GRADE_9_12 = '0',
      REDIRECT_URL: redirectUrl = '',
      FORMAT_TYPE: formatType = '',
      SOURCE_TYPE: sourceType = ''
    } = sites;
    const { URL: imageSrc = SITES_DEFAULT_IMG } = image;
    const grades = [
      (GRADE_PREK_2 === '0' ? '' : GRADE_MAP.GRADE_PREK_2 + ',') +
        (GRADE_3_5 === '0' ? '' : GRADE_MAP.GRADE_3_5 + ',') +
        (GRADE_6_8 === '0' ? '' : GRADE_MAP.GRADE_6_8 + ',') +
        (GRADE_9_12 === '0' ? '' : GRADE_MAP.GRADE_9_12)
    ];
    const interestLevel = [grades[0].slice(-1) === ',' ? grades[0].slice(0, -1) : grades[0]];
    const subjectInfo: SubjectInfo = {
      subjects,
      lexile,
      languages,
      interestLevel
    };
    const formatTypeIcon = FORMAT_TYPE[formatType];
    const sourceTypeIcon = SOURCE_TYPE[sourceType];
    return {
      mainTitle,
      displayURL,
      description,
      subjectInfo,
      imageSrc,
      imageAlt: '',
      redirectUrl,
      formatType,
      formatTypeIcon,
      sourceType,
      sourceTypeIcon
    };
  }
  /**
   * To loads more sites on load more click
   */
  loadMore() {
    if (this.spinnerService.isLoading || this.totalCount <= this.sitesInfo.length) {
      return;
    }
    this.pageIndex++;
    this.getSitesData();
  }
  /**
   * To get availableLimiters from the Sites API response
   * @param gradeOption GRADEOPTION from the Sites API response
   * @param domainOption DOMAINOPTION from the Sites API response
   */
  updateFilters(wpeDoc: WPEDOC) {
    const {
      GRADEOPTION: gradeOption = [],
      DOMAINOPTION: domainOption = [],
      LANGUAGEOPTION: languageOption = [],
      FORMATTYPEOPTION: formatOptions = [],
      SUBJECTOPTION: subjectOption = [],
      SOURCEOPTION: sourceOption = []
    } = wpeDoc;
    const gradeFacets: Facet[] = gradeOption.map((option) => ({
      text: option.GRADEMAP,
      value: option.GRADEMAP,
      count: option.Count,
      selected: option.GRADEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.GRADE)
    }));
    const domainFacets: Facet[] = domainOption.map((option) => ({
      text: option.DOMAINMAP,
      value: option.DOMAINMAP,
      count: option.Count,
      selected: option.DOMAINMAP === this.getSelectedFilterFromStorage(SITES_FILTER.DOMAIN)
    }));
    const languageFacets: Facet[] = languageOption.map((option) => ({
      text: option.LANGUAGEMAP,
      value: option.LANGUAGEMAP,
      count: option.Count,
      selected: option.LANGUAGEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.LANGUAGE)
    }));
    const formatFacets: Facet[] = formatOptions.map((option) => ({
      text: option.FORMATTYPEMAP,
      value: option.FORMATTYPEMAP,
      count: option.Count,
      selected: option.FORMATTYPEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.FORMAT_TYPE)
    }));
    const subjectFacets: Facet[] = subjectOption.map((option) => ({
      text: option.SUBJECTMAP,
      value: option.SUBJECTMAP,
      count: option.Count,
      selected: option.SUBJECTMAP === this.getSelectedFilterFromStorage(SITES_FILTER.SUBJECT)
    }));
    const sourceFacets: Facet[] = sourceOption.map((option) => ({
      text: option.SOURCEMAP,
      value: option.SOURCEMAP,
      count: option.Count,
      selected: option.SOURCEMAP === this.getSelectedFilterFromStorage(SITES_FILTER.SOURCE)
    }));
    this.availableLimiters = [
      {
        title: SITES_FILTER.SUBJECT,
        facets: subjectFacets
      },
      {
        title: SITES_FILTER.FORMAT_TYPE,
        facets: formatFacets
      },
      {
        title: SITES_FILTER.SOURCE,
        facets: sourceFacets
      },
      {
        title: SITES_FILTER.GRADE,
        facets: gradeFacets
      },
      {
        title: SITES_FILTER.LANGUAGE,
        facets: languageFacets
      },
      {
        title: SITES_FILTER.DOMAIN,
        facets: domainFacets
      }
    ].filter((limiter) => limiter.facets.length > 0);
    this.storeRefiners();
  }
  /**
   *  Again to get sites data from API based on filter applied and update selected filter
   * @param applyFilter getting Filter Heading and Value from the filter applied
   */
  applyFilter = (applyFilter: { appliedFilter: AppliedFilter; filters: Filter[] }) => {
    this.searchAnalyticsService.trackSitesEvent('SELECT_FILTER');
    this.pageIndex = 0;
    const limiterMap = {
      [SITES_FILTER.DOMAIN]: () => (this.domainFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.GRADE]: () => (this.gradeMap = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.LANGUAGE]: () => (this.languageFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.SUBJECT]: () => (this.subjectFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.SOURCE]: () => (this.sourceFilter = applyFilter.appliedFilter.facets[0].value),
      [SITES_FILTER.FORMAT_TYPE]: () => (this.formatFilter = applyFilter.appliedFilter.facets[0].value)
    };
    limiterMap[applyFilter.appliedFilter.limiter]();
    this.availableLimiters = applyFilter.filters;
    this.storeRefiners();
    this.getSitesData();
  };
  /**
   * Clears the applied filter
   * @param clearFilter filter string to clear
   */
  clearFilter = (clearFilter: { limiter: string; filters: Filter[] }) => {
    this.searchAnalyticsService.trackSitesEvent('CLEAR_FILTER');
    this.liveAnnouncer.announce(clearFilter.limiter + ' cleared', 'assertive');
    this.pageIndex = 0;
    const limiterMap = {
      [SITES_FILTER.DOMAIN]: () => (this.domainFilter = ''),
      [SITES_FILTER.GRADE]: () => (this.gradeMap = ''),
      [SITES_FILTER.LANGUAGE]: () => (this.languageFilter = ''),
      [SITES_FILTER.SUBJECT]: () => (this.subjectFilter = ''),
      [SITES_FILTER.SOURCE]: () => (this.sourceFilter = ''),
      [SITES_FILTER.FORMAT_TYPE]: () => (this.formatFilter = '')
    };
    limiterMap[clearFilter.limiter]();
    this.availableLimiters.find((facet) => facet.title === clearFilter.limiter)?.facets.forEach((facetObj) => (facetObj.selected = false));
    this.storeRefiners();
    this.getSitesData();
  };
  /**
   * Opens refiner dialog
   * @param [refinerType] SORT or FILTER
   */
  openRefinerDialogEvent(refinerType: string) {
    this.searchAnalyticsService.trackSitesEvent(refinerType as Action);
  }
  /**
   * destroy the scrollSubscription
   */
  ngOnDestroy() {
    this.scrollSubscription.unsubscribe();
    cdkRemoveVisualHidden();
  }
  /**
   * To Get selected filter val of sites results component
   * @param filterTitle filter title
   * @returns selected filter value
   */
  getSelectedFilterVal = (filterTitle: string): string =>
    (this.availableLimiters || []).find((limiter) => limiter.title === filterTitle)?.facets.find((facet) => facet.selected)?.value || '';

  /**
   * To Get selected filter val of sites results component
   * @param filterTitle filter title
   * @returns selected filter value
   */
  getSelectedFilterFromStorage = (filterTitle: string): string => {
    const refiners = this.searchStorageService.getSitesRefiners();
    if (!refiners) {
      return '';
    }
    return (refiners.filters || []).find((limiter) => limiter.title === filterTitle)?.facets.find((facet) => facet.selected)?.value || '';
  };

  /**
   * Gets refiners from session storage
   */
  getRefiners() {
    const refiners = this.searchStorageService.getSitesRefiners();
    if (!refiners) {
      return;
    }
    this.availableLimiters = refiners.filters;
    this.domainFilter = this.getSelectedFilterVal(SITES_FILTER.DOMAIN);
    this.gradeMap = this.getSelectedFilterVal(SITES_FILTER.GRADE);
    this.languageFilter = this.getSelectedFilterVal(SITES_FILTER.LANGUAGE);
    this.subjectFilter = this.getSelectedFilterVal(SITES_FILTER.SUBJECT);
    this.sourceFilter = this.getSelectedFilterVal(SITES_FILTER.SOURCE);
    this.formatFilter = this.getSelectedFilterVal(SITES_FILTER.FORMAT_TYPE);
  }
  /**
   * Sets refiners to session storage
   */
  storeRefiners() {
    this.searchStorageService.setSitesRefiners({ filters: this.availableLimiters });
  }
  /**
   * To handle event on title card click
   * @param input event and url value of the emitter event
   */
  titleCardClick(input) {
    this.searchAnalyticsService.trackSitesEvent('WEB_LINK');
    if (this.userService.isLoggedIn()) {
      window.open(input.url, '_blank');
      return;
    }
    const event = input.event;
    event.data = ACTION_HANDLER_TYPE[MAT_TABS_NAME.SITES];
    event.url = input.url;
    checkLoginAndHandleAction(event);
  }
}
