import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { Facet } from '@search/shared/data/filter-model';
import {
  FORMAT_ICON_MAP,
  FORMAT_TYPES,
  IMAGE_UPLOAD_MAX_HEIGHT,
  IMAGE_UPLOAD_MAX_WIDTH,
  PUBLICATION_IMAGE_URL,
  PUBLICATION_REDIRECTION_URL
} from '@shared/constants/app.constants';
import { LatestIssue, Publication, PublicationCard } from '@shared/data/press-reader-base.model';
import { noop } from 'rxjs';
import {
  ADULT_THEME,
  TEEN_THEME,
  KID_THEME,
  ADULT_HIGH_CONTRAST_THEME,
  KID_HIGH_CONTRAST_THEME,
  TEEN_HIGH_CONTRAST_THEME
} from 'themes/color';
declare const window: any;
/**
 * To re-positioning the footer using .net function refer site.js
 */
export const positionFooter = (delay = false): void => {
  const posFooter = (window as any).positionFooter;
  if (!posFooter) {
    return;
  }
  if (!delay) {
    posFooter();
    return;
  }
  setTimeout(posFooter);
};
/**
 * To check given date is today or not
 * @param date input date
 * @returns true if it is today
 */
export const isToday = (date?: string | Date): boolean => new Date(date).toLocaleDateString() === new Date().toLocaleDateString();
/**
 * To get date in dd/mm/yyyy format
 * @param date input date
 * @returns date in dd/mm/yyyy format
 */
export const getDate = (date?: string | Date) =>
  date ? new Date(date).toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) : '';
/**
 * To lazy load the images using intersection observer
 */
export const lazyLoadImages = (elem?: HTMLImageElement) => {
  const changeSrc = (imgElem: HTMLImageElement) => {
    imgElem.src = imgElem.dataset.src;
    imgElem.classList.remove('lazy-image');
  };
  try {
    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;
          changeSrc(entry.target as HTMLImageElement);
          imageObserver.unobserve(image);
        }
      });
    });
    if (elem) {
      imageObserver.observe(elem);
      return;
    }
    const lazyloadImagesElems = document.querySelectorAll('.lazy-image');
    lazyloadImagesElems.forEach((image) => imageObserver.observe(image));
  } catch (e) {
    if (elem) {
      changeSrc(elem);
      return;
    }
    const lazyloadImagesElems = document.querySelectorAll('.lazy-image');
    lazyloadImagesElems.forEach((image) => changeSrc(image as HTMLImageElement));
  }
};

interface ErrorMsg {
  errorCode: number;
  errorMessage: string;
}
interface ErrorResponse {
  responseCode?: number;
  errors?: ErrorMsg[];
  statusCode?: number;
  message?: string;
}

export const displayErrorMessage = (data: ErrorResponse): string => {
  const errorMsg = 'Something went wrong with your submission. Please try again.';
  if (data.responseCode && data.errors) {
    let msgStr = '';
    data.errors.forEach((element: ErrorMsg) => {
      msgStr = msgStr + ',' + element.errorMessage;
    });
    return msgStr.slice(1);
  }
  if (data.statusCode && data.message) {
    return data.message;
  }
  return errorMsg;
};

export const getLocalDate = (date?: string | Date) => (date ? new Date(date) : '');

/**
 * To update theme on login from header
 * @param isHighContrastEnabled flag for high contrast
 */
export const updateTheme = (isHighContrastEnabled = false, profileType = 'adult') => {
  const kzProfileThemeMap = {
    adult: ADULT_THEME,
    adultHighContrast: ADULT_HIGH_CONTRAST_THEME,
    teen: TEEN_THEME,
    teenHighContrast: TEEN_HIGH_CONTRAST_THEME,
    kid: KID_THEME,
    kidHighContrast: KID_HIGH_CONTRAST_THEME
  };
  if (isHighContrastEnabled) {
    document.body.classList.add('high-contrast');
    Object.keys(kzProfileThemeMap[(profileType || 'adult').toLowerCase() + 'HighContrast'].properties).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        kzProfileThemeMap[(profileType || 'adult').toLowerCase() + 'HighContrast'].properties[property]
      );
    });
    return;
  }
  document.body.classList.remove('high-contrast');
  Object.keys(kzProfileThemeMap[(profileType || 'adult').toLowerCase()].properties).forEach((property) => {
    document.documentElement.style.setProperty(property, kzProfileThemeMap[(profileType || 'adult').toLowerCase()].properties[property]);
  });
};

export const getFormatType = (listFormat: string) => {
  const formatType = listFormat?.toUpperCase();
  const formatMap = {
    [FORMAT_TYPES.FORMATTYPE_EBOOK]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMAT_XPS]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMAT_BLIO]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMAT_PDF]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMAT_ADOBE_EPUB]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMAT_EPB]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMAT_EPUB]: FORMAT_TYPES.FORMATTYPE_EBOOK,
    [FORMAT_TYPES.FORMATTYPE_AUDIO]: FORMAT_TYPES.FORMATTYPE_AUDIO,
    [FORMAT_TYPES.FORMAT_FND]: FORMAT_TYPES.FORMATTYPE_AUDIO,
    [FORMAT_TYPES.FORMAT_ACOUSTIK]: FORMAT_TYPES.FORMATTYPE_AUDIO,
    [FORMAT_TYPES.FORMATTYPE_DIGITALONLINE]: FORMAT_TYPES.FORMATTYPE_DIGITALONLINE,
    [FORMAT_TYPES.FORMAT_DOL]: FORMAT_TYPES.FORMATTYPE_DIGITALONLINE,
    [FORMAT_TYPES.FORMAT_DIGITALONLINE_2]: FORMAT_TYPES.FORMATTYPE_DIGITALONLINE,
    [FORMAT_TYPES.FORMAT_DIGITALONLINE_3]: FORMAT_TYPES.FORMATTYPE_DIGITALONLINE,
    [FORMAT_TYPES.FORMATTYPE_HOME_EBOOK]: FORMAT_TYPES.FORMATTYPE_HOME_EBOOK,
    [FORMAT_TYPES.FORMATTYPE_HOME_AUDIO]: FORMAT_TYPES.FORMATTYPE_HOME_AUDIO,
    [FORMAT_TYPES.FORMATTYPE_KZ_EBOOK]: FORMAT_TYPES.FORMATTYPE_KZ_EBOOK,
    [FORMAT_TYPES.FORMATTYPE_KZ_EBOOK1]: FORMAT_TYPES.FORMATTYPE_KZ_EBOOK,
    [FORMAT_TYPES.FORMATTYPE_KZ_AUDIO]: FORMAT_TYPES.FORMATTYPE_KZ_AUDIO,
    [FORMAT_TYPES.FORMATTYPE_KZ_AUDIOBOOK]: FORMAT_TYPES.FORMATTYPE_KZ_AUDIO,
    [FORMAT_TYPES.FORMATTYPE_KZ_VIDEOS]: FORMAT_TYPES.FORMATTYPE_KZ_VIDEOS,
    [FORMAT_TYPES.FORMATTYPE_KZ_VBOOKS]: FORMAT_TYPES.FORMATTYPE_KZ_VBOOKS
  };
  return formatMap[formatType] || FORMAT_TYPES.FORMATTYPE_DIGITALONLINE;
};
export const getBookIcon = (formatType: string) => {
  return FORMAT_ICON_MAP[getFormatType(formatType)];
};
/**
 * To change selected tab id
 * @param tabId for mat-tab-group id
 * @param selectedTab is current selected tab id
 */
export const setSelectedTabId = (tabId: string, selectedTab: string) => {
  setTimeout(() => {
    const element = document.querySelector(`mat-tab-group#${tabId} div.mat-tab-label[aria-selected='true']`);
    if (element) {
      element.id = selectedTab;
    }
  }, 0);
};

export const cdkVisualHidden = () => {
  setTimeout(() => {
    const elementToHide = document.querySelector('.cdk-live-announcer-element.cdk-visually-hidden');
    if (elementToHide) {
      elementToHide.setAttribute('aria-hidden', 'true');
    }
  }, 4000);
};

export const cdkRemoveVisualHidden = () => {
  const elementToShow = document.querySelector('.cdk-live-announcer-element.cdk-visually-hidden');
  if (elementToShow) {
    elementToShow.removeAttribute('aria-hidden');
  }
};

export const redirectUrl = (router: Router, url: string, forceReload = false) => {
  if (!forceReload) {
    router.navigateByUrl(url);
    return;
  }
  router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigate([url]));
};
export const forceRedirect = (url: string) => {
  window.location.href = url;
};

export const getTimeZone = (replaceDST = false) => {
  const timeZoneName = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
  const timeZoneSplit = timeZoneName.split(' ');
  if (replaceDST) {
    switch (timeZoneSplit[0][0] + timeZoneSplit[1][0] + timeZoneSplit[2][0]) {
      case 'CDT':
        return 'CST';
      case 'EDT':
        return 'EST';
      case 'MDT':
        return 'MST';
      case 'PDT':
        return 'PST';
      case 'ADT':
        return 'AST';
      default:
        return timeZoneSplit[0][0] + timeZoneSplit[1][0] + timeZoneSplit[2][0] || '';
    }
  } else {
    return timeZoneSplit[0][0] + timeZoneSplit[1][0] + timeZoneSplit[2][0] || '';
  }
};

/**
 * To get time in hh:mm AM/PM format
 * @param date given date obj
 * @returns time in hh:mm AM/PM format
 */
export const getTimeAMPM = (date: Date): string => {
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours || 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};
/**
 * To return the difference between dates in days
 * @param date1 date 1
 * @param date2 date 2
 * @returns difference in days
 */
export const getDaysDiff = (date1: Date, date2: Date) => {
  const Time = date2.getTime() - date1?.getTime();
  return Time / (1000 * 3600 * 24);
};

export const getPressReaderDate = (date?: string | Date) => {
  return getFullYear(date) + getFullMonth(date) + getFullDay(date);
};

export const getPressReaderAPIDate = (date?: string | Date) => {
  return `${getFullYear(date)}-${getFullMonth(date)}-${getFullDay(date)}`;
};

export const getFullYear = (date?: string | Date) => (date ? new Date(date).toLocaleString('en-US', { year: 'numeric' }) : '');

export const getFullMonth = (date?: string | Date) => (date ? new Date(date).toLocaleString('en-US', { month: '2-digit' }) : '');

export const getFullDay = (date?: string | Date) => (date ? new Date(date).toLocaleString('en-US', { day: '2-digit' }) : '');

export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));
/**
 * To add styles to container div
 */
export const addStylesToContainerDiv = () => {
  const elem = document.querySelector('.mylibrary-container.container');
  elem && elem.classList.add('home-library-container');
};

/**
 * To focus on the given element by id
 * @param id element id
 */
export const focusElement = (id: string) => {
  const match = /(Android|iPhone|iPod|iPad)/i.exec(navigator.userAgent) || (navigator.userAgent.includes('Mac') && "ontouchend" in document);
  const sec = match ? 2500 : 1000;
  setTimeout(() => {
    if (sec === 2500) {
      const dynamicId = 'loc_headerId' === id ? '#loc_btnHamburgerMenu' : `#${id}`;
      const firstLinkMobile: HTMLElement = document.querySelector(dynamicId);
      firstLinkMobile && firstLinkMobile.focus();
      return;
    }
    const firstLink: HTMLElement = document.querySelectorAll(`#${id}`)[0] as HTMLElement;
    firstLink && firstLink.focus();
  }, sec);
};

export const setCalenderActiveElements = (callback) => {
  setTimeout(() => {
    const disabledElems = document.querySelectorAll('.mat-calendar-table .mat-calendar-body-disabled');
    disabledElems.forEach((elem) => elem.setAttribute('tabindex', '-1'));
    const cellElements = document.querySelectorAll('.mat-calendar-body-cell');
    cellElements.forEach((element) => {
      if (!element.classList.contains('active-date-class')) {
        let ariaLabel = element.getAttribute('aria-label');
        ariaLabel = ariaLabel + ' Edition is not available for this date';
        element.setAttribute('aria-label', ariaLabel);
      }
    });
    const elements = document.querySelectorAll('.active-date-class');
    elements.forEach((element) => {
      element.setAttribute('tabindex', '0');
      element.setAttribute('aria-pressed', 'true');
      let ariaLabel = element.getAttribute('aria-label');
      ariaLabel = ariaLabel + ' Edition is available for this date';
      element.setAttribute('aria-label', ariaLabel);
      element.addEventListener('keydown', (event: any) => {
        if (event.keyCode === 13) {
          const target: any = event.target;
          const dateStr = target.ariaLabel?.substring(0, 16);
          const date = new Date(dateStr);
          callback(date);
        }
      });
    });
  });
};

/**
 * to returns multiple country without pipe symbol
 * @param multiCountry input parameter as multiCountry
 * @returns to returns multiple country without pipe symbol
 */
export const getCountyAtrr = (multiCountry: string) => {
  return multiCountry?.toString().replace(/\|/g, '').replace(/\s\s/g, ' ') || '';
};

export const redirectToHome = () => {
  window.location.href = '/';
};
/**
 * To set the attribute for the given selector element
 * @param  selector selector value
 * @param  attr attribute
 * @param  value value for the attribute
 */
export const setElemAttribute = (selector: string, attr: string, value: string) => {
  setTimeout(() => {
    const elem = document.querySelector(selector);
    elem?.setAttribute(attr, value);
  }, 0);
};
/**
 * To reset the IVY carousel transform to 0 on resize mainly
 */
const width = window.innerWidth;
const height = window.innerHeight;
export const resetCarousleTransform = () => {
  if (window.innerWidth === width) {
    if (getMobileOperatingSystem()) {
      if (window.innerHeight !== height) {
        return;
      }
    } else {
      return;
    }
  }
  document.querySelectorAll('.carousel-cells').forEach((elem: HTMLElement) => {
    const trans = elem.style.transform;
    if (trans && trans !== 'translateX(0px)') {
      elem.style.transform = 'translateX(0px)';
    }
  });
};
/**
 * To set ivy carousel props on this component object when resizing the window
 * @param cellWidth cellWidth
 * @param cellMargin margin
 * @param cellHeight height
 * @param defCellWidth default cellWidth
 * @param defCellMargin default margin
 * @param defCellHeight default height
 */
export function setIvyCarouselProps(
  cellWidth: number,
  cellMargin: number,
  cellHeight: number,
  defCellWidth: number,
  defCellMargin: number,
  defCellHeight: number,
  cellsToScrollNums = [2, 2, 2, 2]
) {
  /** setTimeout(resetCarousleTransform, 0); */
  if (window.innerWidth <= 560) {
    this.cellWidth = cellWidth;
    this.cellMargin = cellMargin;
    this.cellHeight = cellHeight;
    this.cellsToScroll = cellsToScrollNums[3];
    return;
  }
  this.cellWidth = defCellWidth;
  this.cellMargin = defCellMargin;
  this.cellHeight = defCellHeight;
  if (window.innerWidth <= 768) {
    this.cellsToScroll = cellsToScrollNums[2];
    return;
  }
  if (window.innerWidth <= 1024) {
    this.cellsToScroll = cellsToScrollNums[1];
    return;
  }
  this.cellsToScroll = cellsToScrollNums[0];
}

/**
 * Get actio button for title card
 * @param inputdata input data for title
 * @param key this variable key
 */
export const getActionButtons = () => {
  return function (inputdata, key) {
    this.myShelfListService.getMyLibraryListActionButton(inputdata).subscribe((actionButtonResponse) => {
      this[key].Items?.map((elm) => {
        if (elm.ISBN && elm.ISBN === inputdata.ISBN) {
          elm.DisplayStatus = actionButtonResponse.displayStatus;
          elm.HoldPosition = actionButtonResponse.holdPosition;
          elm.titleStateInfo = actionButtonResponse;
        }
      });
    }, noop);
  };
};

/**
 * Get actio button for title card
 * @param inputdata input data for title
 * @param key this variable key
 */
export const getActionButtonsV2 = () => {
  return function (inputdata, key) {
    this.myShelfListService.getMyLibraryListActionButtonV2(inputdata).subscribe((actionButtonResponse) => {
      this[key].Items?.map((elm) => {
        const listData = inputdata.Items?.filter((item) => item.ISBN === elm.ISBN);
        if (listData.length > 0) {
          elm.DisplayStatus = actionButtonResponse.find((item) => item.isbn === elm.ISBN)?.displayStatus || '';
          elm.HoldPosition = actionButtonResponse.find((item) => item.isbn === elm.ISBN)?.holdPosition || '';
          elm.titleStateInfo = { ...actionButtonResponse.find((item) => item.isbn === elm.ISBN), actions: [] };
        }
      });
    }, noop);
  };
};

/**
 * Get actio button for title card
 * @param inputdata input data for title
 * @param key this variable key
 */
export const getActionButtonsForCollectionCard = () => {
  return function (inputdata, collectionName) {
    this.myShelfListService.getMyCollectionActionButton(inputdata).subscribe((actionButtonResponse) => {
      this.collectionData.forEach((collectionList) => {
        if (collectionList.listName === collectionName) {
          collectionList.Items?.map((element) => {
            if (element.ISBN === inputdata.ISBN) {
              element.DisplayStatus = actionButtonResponse.displayStatus;
              element.HoldPosition = actionButtonResponse.holdPosition;
              element.titleStateInfo = actionButtonResponse;
            }
          });
        }
      });
    }, noop);
  };
};

export const getPublicationCard = (publication: Publication, countriesArr: Facet[] = []): PublicationCard => {
  const { id, cid, name, displayName, language, type, countries = [''], latestIssue = {} } = publication;
  const { issueDate, key } = latestIssue;
  const formattedIssueDate = formatIssueDate(issueDate);
  const date = getLocalDate(formattedIssueDate);
  const imageUrl = PUBLICATION_IMAGE_URL.replace('<file>', key).replace('<width>', '360');
  const redirectionUrl = PUBLICATION_REDIRECTION_URL.replace('<cid>', cid).replace('<issueDate>', getPressReaderDate(formattedIssueDate));
  const countrylist = countries.map((obj) => countriesArr.find((obj1) => obj1.value === obj)?.text);
  const regExpSplit = /\(([^()]+)\)/;
  const regionArr = regExpSplit.exec(name);
  const region = regionArr ? regionArr[1] : '';
  return {
    id,
    cid,
    name,
    displayName,
    issueDate: date,
    imageUrl,
    redirectionUrl,
    language,
    country: countries,
    multiCountry: countrylist?.toString().replace(/,/g, ' | ') || '',
    type,
    region
  };
};

export const getPublicationCardFromIssue = (issue: LatestIssue, publication: Publication, countriesArr: Facet[] = []) => {
  const { id, cid, name, displayName, language, type, countries = [''] } = publication;
  const { issueDate, key } = issue;
  const formattedIssueDate = formatIssueDate(issueDate);
  const date = getLocalDate(formattedIssueDate);
  const imageUrl = PUBLICATION_IMAGE_URL.replace('<file>', key).replace('<width>', '360');
  const redirectionUrl = PUBLICATION_REDIRECTION_URL.replace('<cid>', cid).replace('<issueDate>', getPressReaderDate(formattedIssueDate));
  const countrylist = countries.map((obj) => countriesArr.find((obj1) => obj1.value === obj)?.text);
  const regExpSplit = /\(([^()]+)\)/;
  const regionArr = regExpSplit.exec(name);
  const region = regionArr ? regionArr[1] : '';
  return {
    id,
    cid,
    name,
    displayName,
    issueDate: date,
    imageUrl,
    redirectionUrl,
    language,
    country: countries,
    multiCountry: countrylist?.toString().replace(/,/g, ' | ') || '',
    type,
    region
  };
};

export const formatIssueDate = (issueDate: Date | string) => {
  const issueDateStr = issueDate.toString();
  const isZoneAdded = issueDateStr.endsWith('Z');
  return isZoneAdded ? issueDateStr.slice(0, -1) : issueDateStr;
};
/**
 * To hide page scroll
 * @param hide flag to hide or show
 */
export const hidePageScroll = (hide = true) => {
  hide ? document.body.classList.add('hide-scroll') : document.body.classList.remove('hide-scroll');
};

export const getValueFromCaseinsensitiveAttr = (val1, val2, defaultVal) => val1 || val2 || defaultVal;
/**
 * To return Y or N based on boolean
 * @param val boolean value
 * @returns Y or N
 */
export const getYesOrNo = (val: boolean) => (val ? 'Y' : 'N');
/**
 * To return boolean from string
 * @param val string value
 * @returns boolean value
 */
export const strToBool = (val: string) => (val || '').toLowerCase() === 'true';
/**
 * To return the max value
 * @param input input val
 * @param max max number
 * @returns max value
 */
export const returnMax = (input: number, max: number) => (input > max ? max : input);
/**
 * To resize the image on upload
 * @param img image element
 * @returns base64 string based png
 */
export const resizeImageOnUpload = (img: HTMLImageElement): string => {
  const canvas = document.createElement('canvas');
  img.width = returnMax(img.width, IMAGE_UPLOAD_MAX_WIDTH);
  img.height = returnMax(img.height, IMAGE_UPLOAD_MAX_HEIGHT);
  const canvasContext = canvas.getContext('2d');
  canvasContext.clearRect(0, 0, canvas.width, canvas.height);
  canvas.width = img.width;
  canvas.height = img.height;
  canvasContext.drawImage(img, 0, 0, img.width, img.height);
  return canvas.toDataURL('image/png');
};

/**
 * format the bytes to  'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
 * @param [bytes] pass the fileSize
 * @param [decimals] pass the 2 decimals
 * @returns FileSize
 */
export const formatFileSizeBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * formatHoldPosition change to ['th', 'st', 'nd', 'rd']
 * @param [input] Pass the holdPosition value
 * @returns string holdPostion '1st'
 */
export const formatHoldPosition = (input: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = input % 100;
  return input + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * resizeReviewContent base on window innerWidth
 * @param [content] pass review Content
 * @returns resize content review
 */
export const resizeReviewContent = (content: string) => {
  content = content || '';
  if (window.innerWidth <= 560 && content.length > 0) {
    const variable = content.substring(0, 95);
    return variable.length >= 95 ? variable + '...' : variable;
  }
  if (content.length > 0) {
    return content.length >= 192 ? content.substring(0, 192) + '...' : content.substring(0, 192);
  }
};
/**
 * resizeProgramDetailDescription based on window innerWidth
 * @param [content] pass program detail Description
 * @returns resized description
 */
export const resizeProgramDescription = (content: string) => {
  content = content || '';
  if (window.innerWidth <= 560 && content.length > 0) {
    return content.substring(0, 250);
  }
  if (window.innerWidth > 560 && window.innerWidth <= 768 && content.length > 0) {
    return content.substring(0, 300);
  }
  if (window.innerWidth > 768 && window.innerWidth <= 1024 && content.length > 0) {
    return content.substring(0, 350);
  }
  if (content.length > 0) {
    return content.substring(0, 470);
  }
};

/**
 * add the 2 digit on hours in length
 * @param [length] to pass length in title details
 * @returns current length
 */
export const getAddDigitLength = (lengthStr: string) => {
  if (lengthStr.length > 0 && lengthStr !== 'NA') {
    const currentHours = lengthStr.substring(0, 2);
    const hourTwodigit = Number(currentHours) < 10 && lengthStr.includes('Hours') ? '0' + currentHours : currentHours;
    const currentlength = lengthStr.replace(currentHours, hourTwodigit.toString()).replace(' Hours', 'h').replace(' Minutes', 'm');
    return currentlength;
  }
};

/**
 *
 * @param [bgImage] pass the Image Url
 * @returns image url based on bgImage array
 */
export const getBackgroundImageResize = (bgImage: string[]) => {
  bgImage = bgImage || [];
  if (window.innerWidth <= 560 && bgImage.length > 0) {
    return bgImage.find((imgurl) => imgurl.includes('375'));
  }
  if (window.innerWidth > 560 && window.innerWidth <= 768 && bgImage.length > 0) {
    return bgImage.find((imgurl) => imgurl.includes('768'));
  }
  if (window.innerWidth > 768 && window.innerWidth <= 1024 && bgImage.length > 0) {
    return bgImage.find((imgurl) => imgurl.includes('1024'));
  }
  if (bgImage.length > 0) {
    return bgImage.find((imgurl) => imgurl.includes('1440'));
  }
};

/**
 *
 * @param [focusText] pass the id
 */
export const setFocusToText = (focusText: string) => {
  setTimeout(() => {
    document.getElementById(focusText)?.focus();
  });
};

/**
 * get formdata for the object
 * @param [length] to pass object
 * @returns formdata
 */
export const getFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
};
export const appendQueryParams = (url: string, params = {}) => {
  return Object.keys(params).reduce((acc, cur) => {
    acc += `&${cur}=${params[cur]}`;
    return acc;
  }, url);
};

export const getTitleFormatType = (listFormat: string) => {
  let result = listFormat.toLocaleUpperCase();
  if (
    FORMAT_TYPES.FORMAT_XPS === result ||
    FORMAT_TYPES.FORMAT_BLIO === result ||
    FORMAT_TYPES.FORMAT_PDF === result ||
    FORMAT_TYPES.FORMAT_ADOBE_PDF === result ||
    FORMAT_TYPES.FORMAT_ADOBE_EPUB === result ||
    FORMAT_TYPES.FORMAT_EPB === result ||
    FORMAT_TYPES.FORMAT_EPUB === result
  ) {
    result = FORMAT_TYPES.FORMATTYPE_EBOOK;
  } else if (FORMAT_TYPES.FORMAT_FND === result || FORMAT_TYPES.FORMAT_ACOUSTIK === result) {
    result = FORMAT_TYPES.FORMATTYPE_AUDIO;
  } else if (
    FORMAT_TYPES.FORMAT_DOL === result ||
    FORMAT_TYPES.FORMAT_DIGITALONLINE_2 === result ||
    FORMAT_TYPES.FORMAT_DIGITALONLINE_3 === result
  ) {
    result = FORMAT_TYPES.FORMATTYPE_DIGITALONLINE;
  } else {
    result = listFormat;
  }
  return result;
};

/**
 * Get Cookie
 * @param [name] pass the cookie value
 * @returns empty or value
 */
export const getCookie = (name: string): string => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

/**
 * Get Mobile Operating System
 * @returns null or Os version
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || window.opera;

  if (/Android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
    if (
      /Safari/i.test(userAgent) !== null &&
      (/CriOS/i.test(userAgent) !== null ||
        /FxiOS/i.test(userAgent) != null ||
        window.Number(userAgent.substr(userAgent.indexOf('OS ') + 3, 3).replace('_', '.')) < 6)
    ) {
      return 'iOS';
    }
  }

  return null;
};

export const scrollFactory = (overlay: Overlay) => {
  return () => overlay.scrollStrategies.block();
};

export const refinerScrollFound = (): void => {
  const elm: HTMLElement = document.querySelector('.sticky-refiner');
  setTimeout(() => {
    document.querySelectorAll('.radio-group').forEach((groupElm: HTMLElement) => {
      if (elm?.offsetHeight < elm?.scrollHeight) {
        groupElm.classList.remove('parentScrollNone');
      } else {
        groupElm.classList.add('parentScrollNone');
      }
    });
  }, 0);
};

export const replaceTimeStrings = (text: string): string => {
  return text?.replace(/h(r?s)?/gi, 'hours').replace(/m(in)?s?/gi, 'minutes');
};

export const arrangeInOrder = (orderResult: any, order: any, isPageName = false, orderItem = 'text'): any => {
  const inOrder: any = [];
  if (isPageName) {
    order.forEach((type: string) => {
      const response = orderResult.filter((item: any) => item[orderItem] === type);
      inOrder.push(response[0]);
    });
    return inOrder;
  }
  order.forEach((type: string) => {
    const response = orderResult.filter((item: any) => item.vendorCode === type);
    inOrder.push(response[0]);
  });
  return inOrder;
};

/**
 *
 * @param value input value having hyphen between words and numbers
 * @returns it will replace hyphen to endash between numbers only
 */
export const replaceHyphenToEndash = (value: string) => {
  return value?.replace(/(\d{1,6})-(\d{1,6})/g, '$1-$2');
};

export const replaceHyphenToEndashTitleDetails = (value: string) => {
  return value?.replace(/(\d{1,6})-(\d{1,6})/g, '$1-$2').replace(/Grade (\d+)-(\d+)/, 'Grade $1 to $2').replace(/Age (\d+)-(\d+)/, 'Age $1 to $2');
};

export const isKindle = () => {
  const ua = navigator.userAgent;
  return (
    /Kindle/i.test(ua) ||
    /Silk/i.test(ua) ||
    /KFTT/i.test(ua) ||
    /KFOT/i.test(ua) ||
    /KFJWA/i.test(ua) ||
    /KFJWI/i.test(ua) ||
    /KFSOWI/i.test(ua) ||
    /KFTHWA/i.test(ua) ||
    /KFTHWI/i.test(ua) ||
    /KFAPWA/i.test(ua) ||
    /KFAPWI/i.test(ua)
  );
};

export const randomNumderGenerator = () => {
  // Create an array to store the random bytes
  const randomBytes = new Uint32Array(1);

  // Generate cryptographically secure random values
  crypto.getRandomValues(randomBytes);

  // Convert the random bytes to a random number between 0 and 1
  const randomNumber = randomBytes[0] / (0xffffffff + 1);
  return randomNumber;
};

export const setTimeoutPromise = (callback, time) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      callback();
      resolve();
    }, time);
  });
};

