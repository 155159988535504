import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
import { MainComponent } from '@main/main.component';
import { AppBannerComponent } from '@shared/components/app-banner/app-banner.component';
import { LIBRARY_URL } from '@shared/constants/app.constants';
import { CurrentLibrary } from '@shared/data/config.model';
import { forceRedirect, getCookie, getMobileOperatingSystem, hidePageScroll } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
import { MainService } from '@shared/services/main.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SidenavService } from '@shared/services/sidenav.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { Subscription } from 'rxjs';
declare const window: any;
@Component({
  selector: 'axis360-ng-main',
  templateUrl: './ng-main.component.html',
  styleUrls: ['./ng-main.component.scss']
})
export class NgMainComponent extends MainComponent implements OnInit, OnDestroy {
  showSubMenu = false;
  @ViewChild('sidenav') sidenav: MatSidenav;
  reload: boolean = true;
  reloadMainComponentSubscriptions: Subscription;
  getMobileOperatingSystem = getMobileOperatingSystem;
  getCookie = getCookie;
  forceRedirect = forceRedirect;
  hideHeaderFooter = false;
  constructor(
    public sidenavService: SidenavService,
    public configService: ConfigService,
    public userService: UserService,
    public mainService: MainService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public spinnerService: SpinnerService,
    public commonService: CommonService,
    public matDialog: MatDialog,
    public profileInfo: ProfileInfoService
  ) {
    super(configService, userService, mainService, router, activatedRoute, spinnerService, profileInfo);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.sidenavService.sidenavAction.subscribe((action) => {
      switch (action) {
        case 'open':
          this.openSidenav();
          break;
        case 'close':
          this.closeSidenav();
          break;
        default:
          break;
      }
    });
    /**
     *  Ticket - 205060 for until ALA demo to hide popup so committing this function call
     * //this.getAppBannerMessageFlag();
     */
    this.configService.getLibrarySettings().subscribe((libSettings: CurrentLibrary) => {
      if (libSettings && !libSettings.isActiveLibrary) {
        this.forceRedirect('/ng/library-Invalid');
      };
      if (libSettings && libSettings.isActiveLibrary && !libSettings.isMigrated) {
        this.forceRedirect('/ng/migration-Invalid');
      };
      const url = window.location.href;
      if (libSettings.isActiveLibrary && libSettings.isMigrated && url.includes(LIBRARY_URL.AXIS360)) {
        window.open(url.replace(LIBRARY_URL.AXIS360, LIBRARY_URL.BOUNDLESS));
      }
    });
    this.reloadMainComponentSubscriptions = this.commonService.reloadMainComponent.subscribe((reload: boolean) => {
      if (reload) {
        this.isLoading = true;
        super.ngOnInit();
      }
    });
  }

  /**
   * Get AppBaneerMessageFlag in Service to
   * return Boolean
   */
  async getAppBannerMessageFlag() {
    this.configService.getBanner().subscribe(async (bannerView: boolean) => {
      const showAppBannerMessage = await this.configService.currentLibrary.isAppBannerMessage;
      const appBannerMessageFlag = this.getCookie('AppBannerMessageFlag');
      if (bannerView && showAppBannerMessage && appBannerMessageFlag !== 'false') {
        this.getAppBanner();
      }
    });
  }

  /**
   * to Open the AppBanner in based on Get Mobile Operating System
   * @returns null
   */
  getAppBanner() {
    const type = this.getMobileOperatingSystem();
    var standalone = window.navigator.standAlone;
    if (!type || standalone) {
      return;
    } else {
      this.matDialog.open(AppBannerComponent, {
        panelClass: 'app-banner',
        ariaLabelledBy: 'app-banner',
        autoFocus: true,
        hasBackdrop: false,
        data: { type: type }
      });
    }
  }

  /**
   * Closes sidenav
   */
  closeSidenav() {
    hidePageScroll(false);
    this.sidenav.close();
    const bodySelect = document.querySelector('.mat-drawer-content');
    bodySelect.setAttribute('aria-hidden', 'false');
    const howSelect = document.querySelector('.cdk-live-announcer-element');
    const helpSelect = document.querySelector('#walkme-player');
    helpSelect?.setAttribute('aria-hidden', 'false');
    howSelect?.setAttribute('aria-hidden', 'false');
  }

  ngAfterViewInit() {
    const howSelect = document.querySelector('.cdk-visually-hidden');
    howSelect.removeAttribute('aria-atomic');
  }

  /**
   * Open's sidenav
   */
  openSidenav() {
    hidePageScroll();
    this.sidenav.open();
  }

  ngOnDestroy(): void {
    this.reloadMainComponentSubscriptions.unsubscribe();
  }
}
