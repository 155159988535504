import { ConfigService } from './../../shared/services/config.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationsComponent } from '@notifications/notifications.component';
import { MSG_DATA_CATEGORY, MSG_SAD_FACE_EMOJI, SAD_FACE_EMOJI } from '@notifications/shared/constants/notifications.constants';
import { Message } from '@notifications/shared/data/notifications.model';
import { NotificationsAnalyticsService } from '@notifications/shared/services/notifications.analytics.service';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { AlertDialogNewComponent } from '@shared/components/alert-dialog-new/alert-dialog-new.component';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-kz-notifications',
  templateUrl: './kz-notifications.component.html',
  styleUrls: ['./kz-notifications.component.scss']
})
export class KzNotificationsComponent extends NotificationsComponent {
  sadFaceEmoji = SAD_FACE_EMOJI;
  showEmojiMsg = MSG_SAD_FACE_EMOJI;
  constructor(
    public notifitcationsDialog: MatDialogRef<NotificationsComponent>,
    public matDialog: MatDialog,
    public notificationsService: NotificationsService,
    public notificationsAnalyticsService: NotificationsAnalyticsService,
    public programService: ProgramsService,
    public liveAnnouncer: LiveAnnouncer,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public engageService: EngageService,
    public overlayContainer: OverlayContainer,
    public router: Router,
    public userService: UserService,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public datePipe: DatePipe
  ) {
    super(
      notifitcationsDialog,
      matDialog,
      notificationsService,
      notificationsAnalyticsService,
      programService,
      liveAnnouncer,
      iconRegistry,
      sanitizer,
      engageService,
      overlayContainer,
      router,
      userService,
      profileInfoService,
      configService,
      datePipe
    );
  }
  /**
   * To get first set messages on init
   * And call messages count on dialog close
   */
  ngOnInit() {
    this.overlayContainer.getContainerElement().classList.remove('refiner-overlay-container');
    document.querySelectorAll('.mat-dialog-container').forEach((n) => {
      n.removeAttribute('tabindex');
    });
    this.notificationsAnalyticsService.trackScreen('NOTIFICATIONS');
    this.currentLibrary = this.configService.currentLibrary;
    this.loadMessages();
    this.notifitcationsDialog.afterClosed().subscribe(() => this.notificationsService.updateMessagesCount());
    this.notifitcationsDialog.backdropClick().subscribe(() => this.notifitcationsDialog.close());
    this.userNotification = this.userService.userInfo.notifications;
    this.loaduserNotification();
    setTimeout(() => {
      document.getElementById('msgReadAll-input').setAttribute('aria-label', 'Select All Notifications');
    }, 100);
  }
  redirectToProgram(url: string) {
    this.router.navigateByUrl(url);
  }
  alertDialog(data: {}) {
    this.matDialog.open(AlertDialogNewComponent, { ...data, panelClass: 'kz-mat-alert-dialog' });
  }
  /**
   * Gets details url
   * @param message message data
   * @returns details url
   */
  getDetailsURL({ data }: Message): string {
    const [{ category, uri, text }] = data;
    if (text === 'See Other Programs?') {
      return '/view/programs/open';
    }
    const catMap: { [key: string]: string } = {
      [MSG_DATA_CATEGORY.READINGPROGRAM]: '/view/programs/'
    };
    const catStr = catMap[category];
    return catStr + uri;
  }
  notifitcationAriaLabel(message) {
    return `${message.title} notification ${message.status}`;
  }
}
