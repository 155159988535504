import { NEWS_DEFAULT_IMG } from '@search/shared/constants/search-results.constants';
import { DataApi } from '@shared/data/third-party-model';
import { getPressReaderAPIDate } from '@shared/helper/app.util';
import { THIRD_PARTY_GROUPS, THIRD_PARTY_SUBTYPE, THIRD_PARTY_TIER, THIRD_PARTY_URLS } from '../constants/third-party.constants';
export const getRefinerCount = (refinersObj = {}, regExp = true) => {
  let count = 0;
  const r = regExp && /,/g;
  Object.keys(refinersObj).some((key) => {
    const str = refinersObj[key];
    if (!str || key === 'tp' || key === 'orderBy') {
      return false;
    }
    count += (str.match(r) || '').length + 1;
    return count > 9;
  });
  return count > 9 ? '9+' : `${count}`;
};

export function seeAllClick() {
  const { id } = this.activeRoute.snapshot.params;
  const {
    agelevel,
    availability,
    collections,
    axisattribute,
    format,
    language,
    page,
    pageSize,
    releasedate,
    recentlyadded,
    searchText,
    searchby,
    sortby,
    series: seriesType,
    searchText: query,
    searchText: q = '',
    collectionId,
    issueDate,
    cardTitle: bookTitle = ''
  } = this.activeRoute.snapshot.queryParams;
  const { title, subType } = this.componentData;
  const { tp = '', has = '', searchType = '', series: seriesTitle, searchTerm = '', groups = '', topics = '' }:
    { tp?: string; has?: string; searchType: string; series: string; searchTerm: string, groups: string, topics: String } = this.componentData.dataApi.search || {};
  this.thirdPartyAnalyticService.trackTitleCarouselEvent('ACTIONS_SEE_ALL',
    { vendorName: this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code });
  // Comment the Below Line for Current ADA Fixes
  // this.searchService.selectedCategory = this.pageTitle;
  const queryParams = {
    vendor_code: this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code,
    tp: '',
  };
  if (seriesTitle === '' || seriesTitle === undefined) {
    Object.assign(queryParams, {
      id,
      has,
      tp,
      title,
      q,
      query: subType === THIRD_PARTY_SUBTYPE.PUBLICATIONS ? '' : query,
      recentlyadded,
      agelevel,
      availability,
      collections,
      axisattribute,
      format,
      language,
      page,
      pageSize,
      releasedate,
      searchText,
      searchType,
      series: seriesType,
      searchby,
      sortby,
      subType,
      collectionId,
      searchTerm,
      issueDate,
      bookTitle,
      pageTitle: this.pageTitle,
      groups,
      topics
    });
  } else {
    Object.assign(queryParams, {
      title: seriesTitle,
      code: "NA",
      searchType: searchType || searchby,
      q: '',
      bookTitle
    });
  }
  !this.router.url?.includes('content') && this.breadcrumbService.set('@content', 'content');
  const isParentRouteList = this.parentRoute && !this.parentRoute.includes('mystuff') ? `view/${this.parentRoute}/list` : this.seeAllLink;
  const isParentRoute = this.parentRoute && !this.parentRoute.includes('mystuff') ? `view/${this.parentRoute}` : this.seeAllLink;
  const TIER2_URL = (this.seeAllLink === THIRD_PARTY_URLS.TIER2 && !this.parentRoute.includes('list')) ?
    isParentRouteList : isParentRoute;
  this.router.navigate([TIER2_URL], {
    queryParams
  });
}
export const formatLatestIssues = (data: {}[]) =>
  data.map((latestIssue) => ({ displayName: '', countrySlug: '', ...latestIssue, latestIssue }));
export const latestIssuesDates = (dataApi: DataApi, currentIssue: Date) => {
  let fromDate = new Date();
  let toDate = new Date();
  const { fromDate: from, toDate: to }: { fromDate?: any, toDate?: any } = dataApi.search;
  try {
    const currentIssueDate = new Date(currentIssue);
    toDate = dataApi.app?.toDate === 'true' ? new Date() : new Date(to);
    if (dataApi.app?.fromDate === 'true') {
      fromDate.setDate(toDate.getDate() - 90);
    } else { fromDate = new Date(from) };
    fromDate = (fromDate > currentIssueDate) ? currentIssueDate : fromDate;
    toDate = (toDate < currentIssueDate) ? currentIssueDate : toDate;
    if (fromDate > toDate) { toDate = new Date(fromDate) };
  } catch {
    fromDate = from ? new Date(from) : new Date();
    toDate = to ? new Date(to) : new Date();
  }
  return { fromDate, toDate };
};

export async function getMultipleVideoProgressBasedOnItemId() {
  const getItemId = (this.itemsData || [])?.map(itemList => {
    itemList.vendorCode = (this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code);
    return (itemList.isCheckout === 'Y' || itemList.isCheckedout === 'true') && (itemList.iSBN ? itemList.iSBN : itemList.isbn);
  }).filter((element) => {
    return element !== false;
  });
  const bookDetail = { bookIdList: getItemId };
  if (!getItemId.length) {
    return this.itemsData || [];
  }
  try {
    const booksDetailsRes = await this.thirdPartyService.getMultipleTitleBookProgress(bookDetail);
    if (booksDetailsRes && booksDetailsRes.length > 0) {
      let itemProgress: any;
      booksDetailsRes.forEach(progressItems => {
        itemProgress = (this.itemsData || [])?.map(itemList => {
          if (
            (itemList.iSBN === progressItems.itemId || itemList.isbn === progressItems.itemId)
            && progressItems.status.type === 0 &&
            Math.round(progressItems.timeoffset) > 0
          ) {
            itemList.resumeCTA = 'Resume';
            return itemList;
          }
          return itemList;
        });
      });
      return itemProgress;
    } else {
      return this.itemsData || [];
    }
  } catch (e) {
    return this.itemsData || [];
  }

}

export async function getVideoProgressBasedOnItemIdInTier3() {
  const getItemId = this.itemsData?.ISBN;
  if (!getItemId) {
    return this.itemsData || [];
  }
  try {
    if (this.itemsData.isCheckedout !== 'false') {
      const bookTitleProgress = await this.thirdPartyService.getTitleBookProgressByItemId(getItemId);
      if (bookTitleProgress.status &&
        bookTitleProgress.status.type === 0 &&
        bookTitleProgress.audiopositions &&
        bookTitleProgress.audiopositions.length > 0 &&
        Math.round(bookTitleProgress.audiopositions[0].timeoffset) > 0) {
        this.itemsData.resumeCTA = 'Resume';
      }
      return this.itemsData;
    }
    return this.itemsData;
  } catch (e) {
    return this.itemsData || [];
  }
}

export const updateDataApiItemForVideo = (dataRes) => {
  if (dataRes?.searchTitleResult) {
    return dataRes?.searchTitleResult?.titlesList?.title;
  }
  return dataRes?.titlesList?.title;
};

export const formatDataAPILatestIssues = ({ fromDate, toDate }) => {
  return { fromDate: getPressReaderAPIDate(fromDate), toDate: getPressReaderAPIDate(toDate) };
};

export const getThirdPartyBaseURL = (queryParams) => {
  if (queryParams.query && queryParams.query !== '' &&
    (queryParams.vendorCode !== THIRD_PARTY_GROUPS.VIDEOS &&
      queryParams.vendorCode !== THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.SEARCH_LIST_ARTICLES, class: { divClass: 'third-party-articles-container', cardsClass: 'third-party-articles' } };
  } else if (!queryParams.id && queryParams.q !== '' && queryParams.pageTitle === 'Magazines' &&
    (queryParams.vendorCode !== THIRD_PARTY_GROUPS.VIDEOS &&
      queryParams.vendorCode !== THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.SEARCH_LIST_MAGAZINES, class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common' } };
  } else if (!queryParams.id && queryParams.q !== '' && queryParams.pageTitle === 'Newspapers' &&
    (queryParams.vendorCode !== THIRD_PARTY_GROUPS.VIDEOS &&
      queryParams.vendorCode !== THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.SEARCH_LIST_NEWSPAPER, class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common' } };
  } else if (!queryParams.id && queryParams.q !== '' &&
    (queryParams.vendorCode !== THIRD_PARTY_GROUPS.VIDEOS &&
      queryParams.vendorCode !== THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.SEARCH_LIST_PUBLICATIONS, class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common ' } };
  } else if (queryParams.q !== '' &&
    (queryParams.vendorCode === THIRD_PARTY_GROUPS.VIDEOS
      || queryParams.vendorCode === THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.SEARCH_LIST, class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-common' } };
  } else if (queryParams.id && queryParams.id !== '' &&
    (queryParams.vendorCode !== THIRD_PARTY_GROUPS.VIDEOS &&
      queryParams.vendorCode !== THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.LATEST_ISSUES, class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common' } };
  } else if (queryParams.id && queryParams.id !== '' && queryParams.subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS &&
    (queryParams.vendorCode === THIRD_PARTY_GROUPS.VIDEOS ||
      queryParams.vendorCode === THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.RECOMMEND_TILE, class: { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-common' } };
  } else if (queryParams.subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST &&
    (queryParams.vendorCode === THIRD_PARTY_GROUPS.VIDEOS ||
      queryParams.vendorCode === THIRD_PARTY_GROUPS.VBOOKS)) {
    return { url: THIRD_PARTY_TIER.FEATURED_LIST, class: queryParams.vendorCode === THIRD_PARTY_GROUPS.PRESSRDR ? { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common' } : { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-common' } };
  } else if (queryParams.subType === THIRD_PARTY_SUBTYPE.PRESSRDR_FEATURED_LIST
    && queryParams.vendorCode === THIRD_PARTY_GROUPS.PRESSRDR) {
    return { url: THIRD_PARTY_TIER.FEATURED_LIST, class: queryParams.vendorCode === THIRD_PARTY_GROUPS.PRESSRDR ? { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common' } : { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-common' } };
  } else {
    return { url: THIRD_PARTY_TIER.TIER_TWO, class: queryParams.vendorCode === THIRD_PARTY_GROUPS.PRESSRDR ? { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-pressrdr third-party-card-common' } : { divClass: 'third-party-tier-two-container', cardsClass: 'third-party-tier-two-card third-party-card-common' } };
  }
};

export const getCheckArticleContentAndMediaData = (itemsData: any) => {
  return itemsData.map((items: { article: { media: string | any[]; id: string | any; }; content: string | any[]; id: string | any }) => {
    items.id = items.article.id;
    if (items.article.media.length === 0) {
      items.article.media = [{ url: window.origin + NEWS_DEFAULT_IMG }]
    }
    else if (items.content && items.content.length === 0) {
      items.content = [" "]
    }
    return items;
  });
};

export async function getPastCheckoutsByItemId() {
  const { profileInfoService, userService, configService } = this;
  const { currentProfile } = profileInfoService;
  const getItemId = (this.itemsData || [])?.map(itemList => {
    itemList.vendorCode = (this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code);
    itemList.altLabel = `Format ${(itemList.formatType || itemList.format) === 'VID' ? 'Video' : 'Videobook'}, Book title ${itemList.bookTitle || itemList.title}, Author ${itemList?.authors?.author[0] || itemList?.author}`
    return (itemList.titleID ? itemList.titleID : (itemList.titleid || itemList.itemId));
  }).filter((element) => {
    return element !== false;
  });
  const vendorSubscription = configService.getSubscriptionData(
    (this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code)?.toLocaleLowerCase());
  if (!getItemId.length) {
    return this.itemsData || [];
  }
  const bookDetail = {
    itemIds: getItemId.join(','),
    profileId: currentProfile.profileId,
    patronGuid: userService.getPatronId(),
    collectionID: vendorSubscription.collection
  };
  try {
    const pastCheckoutsDetailsRes = await this.thirdPartyService.getPastCheckouts(bookDetail);
    if (pastCheckoutsDetailsRes.statusCode === 200 && pastCheckoutsDetailsRes.bookHistoryResult.length !== 0) {
      pastCheckoutsDetailsRes.bookHistoryResult.forEach(book => {
        this.itemsData.map(itemList => {
          const itemId = itemList.titleID ? itemList.titleID : (itemList.titleid || itemList.itemId)
          if ((book.itemId === itemId) && (itemList.displayStatus === '' || itemList.displayStatus === null)) {
            itemList.displayStatus = 'Past Checkout';
          };
          return itemList;
        });
      });
    }
    return this.itemsData;
  } catch (error) {
    return this.itemData;
  }
};

