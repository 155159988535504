import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InSightGoalModal } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'axis360-set-goal-popup',
  templateUrl: './set-goal-popup.component.html',
  styleUrls: ['./set-goal-popup.component.scss']
})
export class SetGoalPopupComponent implements OnInit {
  goalForm: FormGroup;
  minValue: number;
  invalidChars = ['-', '+', 'e'];
  constructor(
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public goalData: InSightGoalModal,
    public dialogRef: MatDialogRef<SetGoalPopupComponent>,
    protected homeAnalyticsService: HomeAnalyticsService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public renderer: Renderer2
  ) {
    iconRegistry.addSvgIcon('CLOSE_IMAGE', sanitizer.bypassSecurityTrustResourceUrl('/angular-app/assets/images/remove-small.svg'));
  }
  /**
   * Set goal popup init
   */
  ngOnInit(): void {
    this.handleGoal();
    document.querySelectorAll('.ng-trigger-dialogContainer').forEach((n) => {
      n.removeAttribute('tabindex');
    });
    const badgesCardHidden = document?.getElementById('loc_badges_carosel');
    badgesCardHidden?.setAttribute('aria-hidden', 'true');
  }
  /**
   * To handle the set goal popup
   */
  handleGoal() {
    const { goal, min, max } = this.goalData;
    if (goal == 0) {
      this.homeAnalyticsService.trackScreen('SET_GOAL_POPUP');
      this.homeAnalyticsService.trackHomeEvent('ENTERS_YOUR_TARGET_GOAL');
    }
    else {
      this.homeAnalyticsService.trackScreen('UPDATE_GOAL_POPUP');
    }
    const goalValue = goal === 0 ? null : goal;
    this.minValue = min;
    this.goalForm = this.formBuilder.group({
      goalValue: [goalValue, [Validators.required, Validators.min(this.minValue), Validators.max(max), this.validateDecimal.bind(this)]]
    });
  }
  /**
   * To restrict the invalid chars on goals input key down
   * @param event keyboard event on input
   */
  goalInputKeyDown(event: KeyboardEvent) {
    this.invalidChars.indexOf(event.key) !== -1 && event.preventDefault();
  }
  /**
   * To set or remove goal
   * @param [isRemove] true if remove goal
   */
  setGoal(isRemove = false) {
    const { goalName } = this.goalData;
    if (isRemove) {
      this.homeAnalyticsService.trackHomeEvent('REMOVE_GOAL');
      this.dialogRef.close({ goalValue: 0, goalName });
      return;
    }
    this.goalForm.controls.goalValue.markAsTouched();
    this.renderer.selectRootElement('#goal-input').focus();
    this.homeAnalyticsService.trackHomeEvent('SET_GOAL');
    if (this.goalForm.valid) {
      this.dialogRef.close({ goalValue: this.goalForm.controls.goalValue.value, goalName });
    }
  }
  /**
   * Validator function -  goal input has decimal
   * @param control goal input form control
   * @returns null if no decimal
   */
  validateDecimal(control: FormControlName) {
    return control.value && !(control.value + '').match(/^[0-9]+$/g) ? { validateDecimal: 'Decimal value not allowed' } : null;
  }

  /**
   * Close dialog popup
   */
  closeDialog() {
    this.dialogRef.close();
    this.homeAnalyticsService.trackHomeEvent('CLOSE_POP_UP');
  }
}
