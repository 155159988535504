<div mat-tab-nav-bar class="nav-main-tab {{navClass}}" *ngIf="!isPrograms"
     [ngClass]="{'nav-lib-tab': centerTab, 'kz-mystuff-tabs': isMyStuff, 'kz-title-list-tabs': isTitleList || isLearningActivity || isWebResources , 'kz-search-list-tabs': isSearchListView ,'kz-thirdpartytiertwo-tabs': isTierTwo}">
  <ng-container *ngIf="!isTitleList && !isLearningActivity && !isTierTwo && !isWebResources && !isSearchListView">
    <a class="nav-item"
       [ngClass]="{'nav-item-lib d-flex': item.value === undefined, 'active': item.name?.toLowerCase() === activeMyStuff }"
       id="loc_label{{item.name}}"
       [attr.aria-label]="(item.name?.toLowerCase() === activeMyStuff) ? 'My Stuff ' + item.text + ' book title count ' + item.value + ' selected' : 'My Stuff ' + item.text + ' ' + 'book title count' + ' ' + item.value"
       *ngFor="let item of tabsList; index as i" [routerLink]="item.url"
       (click)="sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB', { tabName: item.text });isFromMyShelf(true);$event.stopPropagation();"
       (keyup.enter)="sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB', { tabName: item.text });isFromMyShelf(true);$event.stopPropagation();">
      {{item.text}}<span class="count" [ngClass]="{'multi-digit' : item.value > 9}"
                         *ngIf="item.value !== undefined">{{item.value |thousandSuff}}</span>
    </a>
  </ng-container>
  <ng-container *ngIf="isTierTwo">
    <div class="nav-item" id="loc_label{{item.value}}" *ngFor="let item of tabsList; index as i"
         (click)="sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB', { tabName: item.value })"
         (keyup.enter)="sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB', { tabName: item.value })">
      {{item.value.replaceAll('-', '&ndash;')| pascalCase}}
      <span class="count close-icon" id="loc_remove_pill{{item.value}}" role="button"
            attr.aria-label="{{item.value.replaceAll('-', '&ndash;')| pascalCase}} clear filter" tabindex="0"
            (click)="removeRefiner(item.key, item.value);$event.stopPropagation();"
            (keyup.enter)="removeRefiner(item.key, item.value); $event.stopPropagation();"
            *ngIf="item.value != 'Clear All'">
        <mat-icon svgIcon="kz-nav-x-icon"></mat-icon>
      </span>
    </div>
    <a class="nav-item close-all" role="button" tabindex="0" aria-label="Clear all filter"
       *ngIf="tabsList.length>1 && !isDefaultLearningActivity" (click)="removeRefiner('ClearAll','')"
       (keyup.enter)="removeRefiner('ClearAll','')" [class.disabled]="isDefaultLearningActivity">Clear All</a>
  </ng-container>
  <ng-container *ngIf="isTitleList">
    <ng-container *ngIf="featuredListData === TITLE_LIST.SUBJECT; else notEqualFeaturdList">
      <ng-container *ngFor="let item of tabsList">
        <ng-container *ngIf="!(epopupValue && item.value === 'Available Now') && !this.configService.isPPCLibrary">
          <a class="nav-item a" id="loc_label{{item.value}}" attr.aria-label="{{item.value}}"
             [ngClass]="{'hide-pill': item.value === 'All'}">
            {{item.value}}
            <span class="count close-icon" role="button" attr.aria-label="{{item.value}} clear filter"
                  tabindex="0" (click)="removeRefiner(item.key, item.value);$event.stopPropagation();"
                  (keyup.enter)="removeRefiner(item.key, item.value);$event.stopPropagation();"
                  id="loc_search_pill{{item.value}}">
              <mat-icon svgIcon="kz-nav-x-icon"></mat-icon>
            </span>
          </a>

        </ng-container>
        <ng-container *ngIf="!(epopupValue && item.value === 'Available Now') && this.configService.isPPCLibrary">
          <a class="nav-item a" id="loc_label{{item.value}}" attr.aria-label="{{item.value}}"
             [ngClass]="{'hide-pill': (item.value === 'All' || item.value === 'Available Now')}">
            {{item.value}}
            <span class="count close-icon" role="button" attr.aria-label="{{item.value}} clear filter"
                  tabindex="0" (click)="removeRefiner(item.key, item.value);$event.stopPropagation();"
                  (keyup.enter)="removeRefiner(item.key, item.value);$event.stopPropagation();"
                  id="loc_search_pill{{item.value}}">
              <mat-icon svgIcon="kz-nav-x-icon"></mat-icon>
            </span>
          </a>

        </ng-container>
      </ng-container>
      <a class="nav-item close-all" role="button" tabindex="0" aria-label="Clear all filter"
         *ngIf="tabsList.length > (epopupValue ? 2 : 1)" (click)="removeRefiner('ClearAll','')"
         (keyup.enter)="removeRefiner('ClearAll','')">Clear All</a>
    </ng-container>
    <ng-template #notEqualFeaturdList>
      <ng-container *ngFor="let item of tabsList">
        <a role="none" class="nav-item" id="loc_label{{item.tabvalue}}" *ngIf="item.tabvalue !== 'Clear All'"
           [ngClass]="{'hide-pill': (item.value === 'All')}">
          {{item.tabvalue === "Audiobooks" ? "eAudio": (item.tabvalue ? item.tabvalue : item.value)}}
          <span class="count close-icon" role="button"
                attr.aria-label="{{item.tabvalue === 'Audiobooks' ? 'eAudio':item.tabvalue||''}} clear filter"
                tabindex="0"
                id="loc_label{{item.tabvalue === 'Audiobooks' ? 'eAudio':(item.tabvalue?item.tabvalue.split(' ').join('') : '')}}_clear"
                (click)="removeRefiner(item.key, item.value);sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB', { tabName: item.tabvalue }); clearFilters(item);$event.stopPropagation();"
                (keyup.enter)="sharedAnalyticsService.trackNavTabsEvent('CLICK_NAV_TAB', { tabName: item.tabvalue }); clearFilters(item);$event.stopPropagation();">
            <mat-icon svgIcon="kz-nav-x-icon"></mat-icon>
          </span>
        </a>
      </ng-container>
      <a class="nav-item close-all" role="button" tabindex="0" aria-label="Clear all filter"
         *ngIf="tabsList.length > 1"
         (click)="clearFilters({tabvalue: 'Clear All', text: '', value: '', items: []})"
         (keyup.enter)="clearFilters({tabvalue: 'Clear All', text: '', value: '', items: []})">Clear All</a>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="isLearningActivity">
    <div class="nav-item" id="loc_label{{item.value}}" *ngFor="let item of tabsList"
         [ngClass]="{'hide-pill': item.value === 'All'}">
      {{(item.key === 'activityTypes')?item.value === 'All'?'All Resource Type':item.value:item.value}}
      <span class="count close-icon" role="button" id="resource_hub_remove_pill{{item.value}}"
            attr.aria-label="{{(item.key === 'activityTypes')?item.value === 'All'?'All Resource Type':item.value:item.value}} clear filter"
            tabindex="0" (click)="removeRefiner(item.key, item.value);$event.stopPropagation();"
            (keyup.enter)="removeRefiner(item.key, item.value);$event.stopPropagation();">
        <mat-icon svgIcon="kz-nav-x-icon"></mat-icon>
      </span>
    </div>
    <a class="nav-item close-all" role="button" tabindex="0" aria-label="Clear all filter"
       *ngIf="tabsList.length>1 && !isDefaultLearningActivity" (click)="removeRefiner('ClearAll','')"
       (keyup.enter)="removeRefiner('ClearAll','')" [class.disabled]="isDefaultLearningActivity">Clear All</a>
  </ng-container>
  <ng-container *ngIf="isWebResources">
    <div class="nav-item" id="loc_label{{item.value}}" *ngFor="let item of tabsList">
      {{item.value}}
      <span class="count close-icon" role="button" id="loc_remove_pill{{item.value}}" attr.aria-label="{{item.value}} clear filter" tabindex="0"
            (click)="removeRefiner(item.key, item.value);$event.stopPropagation();"
            (keyup.enter)="removeRefiner(item.key, item.value);$event.stopPropagation();">
        <mat-icon svgIcon="kz-nav-x-icon"></mat-icon>
      </span>
    </div>
    <a class="nav-item close-all" role="button" tabindex="0" aria-label="Clear all filter"
       *ngIf="tabsList.length>1 && !isDefaultLearningActivity" (click)="removeRefiner('ClearAll','')"
       (keyup.enter)="removeRefiner('ClearAll','')" [class.disabled]="isDefaultLearningActivity">Clear All</a>
  </ng-container>
  <ng-container *ngIf="isSearchListView">
    <a class="nav-item" tabindex="0" id="loc_label{{item.value}}"
       attr.aria-label="{{item.text }} count {{ item.value}}" *ngFor="let item of tabsList"
       (click)="showActive(item);$event.stopPropagation();"
       (keyup.enter)="showActive(item);$event.stopPropagation();">
      <span aria-hidden="true">{{item.text}}</span>
      <span aria-hidden="true" class="count" [ngClass]="{'multi-digit' : item.value > 9}"
            *ngIf="item.value !== undefined">{{item.value |thousandSuff}}</span>
    </a>
  </ng-container>
  <axis360-shimmer-cards [compName]="compName" *ngIf="tabsList === undefined "></axis360-shimmer-cards>
</div>
<ng-container *ngIf="isPrograms">
  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event)" [selectedIndex]="selectedTab">
    <mat-tab *ngFor="let item of tabsList">
      <ng-template mat-tab-label>
        <span id="loc_label{{item.text}}">{{item.text}}</span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
