<div class="featured-list-section kz-feature-bg kz-custom-container-secondary"
    *ngIf="(listContent.Items === undefined || listContent.Items.length > 0)" (window:resize)="onResize()"
    [ngStyle]="backgroundImageUrl ? {'background-image': 'url(' + backgroundImageUrl + ')'} : ''">
    <div class="kz-featured">
        <div class="kz-featured-sec">
            <h2 class="kz-heading">{{featuredList.title }}</h2>
            <div *ngIf="listContent.Items && listContent.Items.length >= LIST_CARD_COUNT">
                <a class="link see-all-text" tabindex="0" routerLink="/view/title" href="javascript:;" role="link"
                    [queryParams]="{ list: featuredList.entityId }" attr.aria-label="See all {{featuredList.title}}">See
                    all
                    <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon>
                </a>
            </div>
        </div>
        <div class="featured-carousel">
            <axis360-titles-carousel [cellsToScrollNums]="[5,4,2,1]" [titlesList]="listContent.Items"
                [totalItemCount]="listContent.TotalItems" [showCarouselTitle]="false"  [addCard]="true" [parentRoute]="parentRoute"
                [featuredList]="featuredList" [myShelfLoad]="myShelfLoad">
            </axis360-titles-carousel>
        </div>
    </div>
</div>