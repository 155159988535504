<mat-card class="press-poster" [ngClass]="{'home-page': fromHomeCarousel}">
    <div class="poster-main-sec">
        <a  href="javascript:;" [style.background-image]="'url(' + publicationCard.imageUrl + ')'" (click)="cardClick(type, title)" (keyup.enter)="cardClick(type, title)"
        attr.aria-label="{{(usedIn === 'featured' ? 'Featured ': '')}}{{getDate(publicationCard.issueDate) | date:'d MMM yyyy'}} {{getCountyAtrr(publicationCard.multiCountry)}} {{publicationCard.region}} {{publicationCard.displayName}}">
        <p class="float-label" *ngIf="publicationCard.region">{{publicationCard.region}}</p>
     </a>
    </div>
    <div class="poster-author-detail" aria-hidden="true">
        <p class="title-name">
            {{getDate(publicationCard.issueDate) | date:'d MMM yyyy'}}</p>
            <p class="title-name">
                {{publicationCard.multiCountry}}
            </p>
        <p class="author-name" [matTooltip]="publicationCard.displayName" matTooltipClass="card-title-tooltip">{{publicationCard.displayName}}</p>
    </div>
    <a class="pressreader-list-cta" href="javascript:;" (click)="readNow($event, publicationCard.redirectionUrl)"
        (keyup.enter)="readNow($event, publicationCard.redirectionUrl)">Read Now</a>
</mat-card>