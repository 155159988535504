<ng-container *ngIf="!backToLogin && !backToPwdVerification">
    <a class="back-icon" role="button" matTooltip="Back" matTooltipClass="kz-card-tooltip"
            aria-label="Go Back To Login Dialog" href="javascript:void(0)" (click)="goBack()">
            <mat-icon aria-hidden="true" svgIcon="kz-back-arrow"></mat-icon>
    </a>
    <div class="login-container-sec kz-main">
        <h2 class="heading-1" id="dialogHeadingRegister">Register</h2>
        <form [formGroup]="registerFormGroup">
            <p class="primary-para mb-1" tabindex="-1" aria-hidden="true"> <span class="req kz-required">*</span> indicates a required field.</p>
            <div class="kz-login-form">
                <mat-error aria-live="polite" id="registerError"*ngIf="postRegisterResponse && postRegisterResponse.errorMessage">{{
                    postRegisterResponse.errorMessage }}</mat-error>
                <mat-form-field appearance="fill">
                    <mat-label>{{libraryLoginInfo.userDisplayName }}<span class="kz-required" aria-hidden="true">*</span></mat-label>
                    <input matInput formControlName="libraryId" ngDefaultControl matInput placeholder="" maxlength="51"
                        required axis360TrapFocus id="RegisterModel_UserName"
                        (change)="libraryLoginInfo.userDisplayName === 'Library Card Id' ?accountAnalyticsService.trackAccountEvent('TYPE_LC_ID') : accountAnalyticsService.trackAccountEvent('TYPE_USERNAME')  ">
                    <mat-error aria-live="polite"
                        *ngIf="checkError('libraryId', 'maxlength','RegisterModel_UserName')">{{libraryLoginInfo.userDisplayName
                        }} can not
                        exceed 50 characters.</mat-error>
                    <mat-error aria-live="polite"
                        *ngIf="checkError('libraryId', 'required','RegisterModel_UserName')">{{libraryLoginInfo.userDisplayName
                        }}
                        is
                        required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="!currentLibrary.authSettings.noPatronPinNeeded">
                    <mat-label>{{libraryLoginInfo.authenticationType }}<span class="kz-required" aria-hidden="true">*</span></mat-label>
                    <input matInput placeholder="" [type]="passwordHide ? 'password' : 'text'" minlength="4"
                        maxlength="51" formControlName="passWord" ngDefaultControl id="RegisterModel_Password" required
                        (change)="libraryLoginInfo.authenticationType === 'PIN' ? accountAnalyticsService.trackAccountEvent('TYPE_PIN') : accountAnalyticsService.trackAccountEvent('TYPE_PASSWORD')">
                    <mat-error aria-live="polite"
                        *ngIf="checkError('passWord', 'maxlength')">{{libraryLoginInfo.authenticationType
                        }} can not
                        exceed 50 characters.</mat-error>
                        <mat-error aria-live="polite"
                        *ngIf="checkError('passWord', 'minlength')">{{libraryLoginInfo.authenticationType
                        }} must have minimum 4 characters.</mat-error>
                    <mat-error aria-live="polite"
                        *ngIf="checkError('passWord', 'required')">{{libraryLoginInfo.authenticationType
                        }} is
                        required</mat-error>
                    <button mat-icon-button matSuffix [matTooltip]="passwordHide ? 'Show password': 'Hide password'"
                        matTooltipClass="kz-card-tooltip" (click)="passwordHide = !passwordHide"
                        [attr.aria-label]="passwordHide ? 'Show password': 'Hide password'" class="hide-btn">
                        <mat-icon *ngIf="passwordHide" svgIcon="kz-password-hide-icon"></mat-icon>
                        <mat-icon *ngIf="!passwordHide" svgIcon="kz-password-show-icon"></mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill" class="kz-securityque-form" *ngIf="isShowSecurityQuestion">
                    <mat-label id="seturity_question_register">Security Question<span
                            class="kz-required" aria-hidden="true">*</span></mat-label>
                    <mat-select disableOptionCentering required formControlName="securityQuestion" #mySelect
                        panelClass="kz-security-que-panel kz-register-select-panel kz-mat-select-panel" role="combobox"
                        id="RegisterModel_SecurityQuestion_button" axis360MatSelectAriaExpanded aria-haspopup="listbox"
                        [required]="isShowSecurityQuestion" [attr.aria-expanded]="mySelect.panelOpen" aria-labelledby="seturity_question_register"
                        (openedChange)="openMatSelect($event)" (selectionChange)="onSelectChange($event);">
                        <mat-option value="{{ques.value}}" attr.aria-label="{{ques.text}}" id="{{ques.id}}"
                            *ngFor="let ques of securityQuestions">
                            {{ques.text}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>keyboard_arrow_down
                    </mat-icon>
                </mat-form-field>
                <div class="kz-visually-hidden" role="alert" id="loc_securityQuestion" *ngIf="isShowSelectedItemMessage">
                    {{ selectedItemMessage }}
                </div>
                <mat-form-field appearance="fill" *ngIf="isShowSecurityQuestion">
                    <mat-label>Security Answer<span class="kz-required" aria-hidden="true">*</span></mat-label>
                    <input matInput formControlName="securityAnswer" ngDefaultControl matInput maxlength="51" required
                        placeholder="" id="RegisterModel_SecurityAnswer"
                        (change)="accountAnalyticsService.trackAccountEvent('TYPE_ANSWER')">
                    <mat-error aria-live="polite"
                        *ngIf="checkError('securityAnswer', 'maxlength', 'RegisterModel_SecurityAnswer')">
                        Answer can not exceed 50 characters.</mat-error>
                    <mat-error aria-live="polite"
                        *ngIf="checkError('securityAnswer', 'required', 'RegisterModel_SecurityAnswer')">
                        Please enter your security answer.
                    </mat-error>

                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Display Name</mat-label>
                    <input matInput formControlName="displayName" ngDefaultControl matInput placeholder=""
                        maxlength="26" id="RegisterModel_DisplayName">
                    <mat-error aria-live="polite"
                        *ngIf="checkError('displayName', 'minlength','RegisterModel_DisplayName')">
                        Display Name must be at least 3 characters long.</mat-error>
                    <mat-error aria-live="polite"
                        *ngIf="checkError('displayName', 'required','RegisterModel_DisplayName')">Please enter your
                        Display
                        Name</mat-error>
                    <mat-error aria-live="polite"
                        *ngIf="checkError('displayName', 'maxlength','RegisterModel_DisplayName')">
                        Display Name can not exceed 25 characters.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="email" autocomplete="email" matInput formControlName="emailAddress" ngDefaultControl
                        matInput placeholder="" id="RegisterModel_Email">
                    <mat-error aria-live="polite"
                        *ngIf="registerFormGroup.get('emailAddress').hasError('pattern')">Whoops! The email
                        you entered
                        is not in valid format. Please try again.</mat-error>
                </mat-form-field>

            </div>
            <div class="done_Submit d-flex">
                <button mat-raised-button class="btn-primary-blue text-capitalize saveBtn"
                    [ngClass]="{active: registerFormGroup && !registerFormGroup.invalid}"
                    [disabled]="registerFormGroup.invalid || !registerFormGroup" aria-describedby="registerError"
                    (click)="onSubmit();accountAnalyticsService.trackAccountEvent('REGISTER')">Register</button>
            </div>
        </form>
        
    </div>
</ng-container>
<axis360-login *ngIf="backToLogin"></axis360-login>
<axis360-password-verification *ngIf="backToPwdVerification"></axis360-password-verification>