import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MyLibraryAnalyticsService } from '../shared/services/my-library.analytics.service';
import { forceRedirect, getBookIcon, replaceTimeStrings, setIvyCarouselProps } from '@shared/helper/app.util';
import { LibCompononent } from '../shared/data/library.model';
import { Router } from '@angular/router';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { CAROUSEL_CONST, LIBRARY_CAROUSEL_FOOTER_TEXT, LIST_CARD_COUNT } from '@shared/constants/app.constants';
import { THIRD_PARTY_URLS } from 'app/third-party/shared/constants/third-party.constants';
@Component({
  selector: 'axis360-book-of-month',
  templateUrl: './book-of-month.component.html',
  styleUrls: ['./book-of-month.component.scss']
})
export class BookOfMonthComponent {
  @Input() componentData: LibCompononent;
  url: SafeResourceUrl;
  forceRedirect = forceRedirect;
  cellsToScrollNums = [1, 1, 1, 1];
  redirectUrl: any;
  replaceTimeStrings = replaceTimeStrings;
  LIST_CARD_COUNT = LIST_CARD_COUNT;
  waitList = LIBRARY_CAROUSEL_FOOTER_TEXT.Waitlist;
  onHold = LIBRARY_CAROUSEL_FOOTER_TEXT.onHold;
  unAvailableStatus = LIBRARY_CAROUSEL_FOOTER_TEXT.unAvailable;
  readyForCheckout = LIBRARY_CAROUSEL_FOOTER_TEXT.readyForCheckout;
  holdSuspended = LIBRARY_CAROUSEL_FOOTER_TEXT.holdSuspended;
  comingSoon = LIBRARY_CAROUSEL_FOOTER_TEXT.comingSoon;
  bookstatus = CAROUSEL_CONST.bookstatus;
  cellWidth = 294;
  cellMargin = 0;
  cellHeight = 30;
  cellsToScroll = 1;
  onResize = setIvyCarouselProps.bind(this, 294, 0, 252, 294, 0, 252, this.cellsToScrollNums);
  constructor(
    public titleDetailsSerivce: TitleDetailsService,
    private myLibraryAnalyticsService: MyLibraryAnalyticsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const domBookOfMonth = document.querySelectorAll('.book-of-month.single');
    if (domBookOfMonth.length > 0)
    {
      document.querySelectorAll('.single').forEach((n) => {
        n?.children[0]?.children[0]?.children[0]?.classList.add('single');
      });
      this.cellWidth = 350;
      this.onResize = setIvyCarouselProps.bind(this, 350, 0, 250, 350, 0, 250, this.cellsToScrollNums);
      this.onResize();
      return;
    }
    this.onResize = setIvyCarouselProps.bind(this, 294, 0, 250, 294, 0, 250, this.cellsToScrollNums);
    this.onResize();
    
  }
  ngAfterViewInit() {
    const carouselCells = document.querySelectorAll('.kz-carousel.book-of-month-carousel .carousel-cells .carousel-cell');
    if(carouselCells.length === 1) {
      document.querySelectorAll('.kz-carousel.book-of-month-carousel .carousel-arrows .carousel-arrow-next')[0].classList.add('carousel-arrow-disabled');
    }
  }
  /**
   * redirect to the feature details page
   */

  redirectToDetailsPage(bookDetails) {
    this.myLibraryAnalyticsService.trackMyLibraryEvent('BOOK_OF_MONTH');
    this.titleDetailsSerivce.itemsTitleDetails = bookDetails;
    if (['VID', 'VBK'].includes(bookDetails.formatType)) {
      const TIER3_URL = THIRD_PARTY_URLS.TIER3
      const vendorcode = bookDetails.formatType === 'VID' ? 'VIDEOS' : 'VBOOKS';
      this.router.navigate([`${decodeURIComponent(TIER3_URL)}/${bookDetails.itemId}`], {
        queryParams: { ISBN: bookDetails.isbn, vendor_code: vendorcode, title: bookDetails.title, series: bookDetails.series, collectionId: bookDetails.libraryId }
      });
    }
    else {
      this.router.navigate([`/view/library/title/${bookDetails.itemId}/${bookDetails.isbn}`]);
    }
  }
  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType) {
    return getBookIcon(`KZ_${formatType}`);
  }
  /**
   * get Icon
   * @param iconImage icon Image
   * @returns icon name
   */
  getImage(iconImage) {
    return 'kz-' + iconImage;
  }

  getTitleFormat(format: string) {
    return format.replace('Read Along', 'eRead-Along');
  }

  isShowWaitList(bookDetails) {
    let showWaitList = false;
    showWaitList =
      (bookDetails?.DisplayStatus === this.unAvailableStatus ||
        bookDetails?.DisplayStatus === this.comingSoon ||
        (!bookDetails?.IsAvailable && !bookDetails?.isAvailable)) &&
      ![this.onHold, this.holdSuspended, this.readyForCheckout].some((x) => x === bookDetails?.DisplayStatus) &&
      !bookDetails?.DisplayStatus?.startsWith('Due');
    return showWaitList;
  }
  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.myLibraryAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target?.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.myLibraryAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_LEFT');
      target?.nextSibling?.classList.remove('carousel-arrow-disabled');
    }
  }
  titleAriaLabel(book: any) {
    let ariaLabel = '';
    if (this.isShowWaitList(book.bookDetails)) {
      ariaLabel = 'Book Status Waitlist, ';
    }

    if (book.bookDetails?.DisplayStatus && book.bookDetails?.DisplayStatus !== '') {
      ariaLabel += (ariaLabel.includes(this.bookstatus) ? '' : (this.bookstatus.includes(book.bookDetails.DisplayStatus) ? this.bookstatus: this.bookstatus +  book.bookDetails.DisplayStatus )) + (book.bookDetails?.HoldPosition ? '  , ' + book.bookDetails.HoldPosition + ', ' : '' + ', ')
    }
    if (book?.DisplayStatus && book?.DisplayStatus !== '' && book.bookDetails?.HoldPosition && book.bookDetails?.HoldPosition !== '') {
      ariaLabel += (ariaLabel.includes(this.bookstatus) ? '': (this.bookstatus.includes('ON HOLD') ? this.bookstatus : this.bookstatus + 'ON HOLD ') ) + book.HoldPosition + ', ';
    }

    if (book.bookDetails?.formatType && this.getIcon(book.bookDetails.formatType).alt) {
      ariaLabel += 'Format ' + this.getIcon(book.bookDetails.formatType).alt + ', ';
    }
    if (book.bookDetails?.title) {
      ariaLabel += 'Book title ' + book.bookDetails.title + ', ';
    }
    if (book.bookDetails?.author) {
      ariaLabel += 'Author ' + book.bookDetails.author + ', ';
    }
    if (replaceTimeStrings(book.bookDetails?.runTime)) {
      ariaLabel += 'Duration ' + replaceTimeStrings(book.bookDetails?.runTime);
    }
    const pageCount = book.bookDetails?.bookLength || book.bookDetails?.BookLength;
    const pageCountTextCondition = (pageCount !== '0') ? `${pageCount} Pages` : '';
    const pageCountText = pageCount ? pageCountTextCondition : '';
    if (pageCountText) {
      ariaLabel += pageCountText;
    }
    return ariaLabel;
  }
}
