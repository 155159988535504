import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HOME_AVAILABILITY, KZ_HOME_FORMAT_TYPE, LIST_CARD_COUNT } from '@shared/constants/app.constants';
import { ListContent } from '@shared/data/config.model';
import { cdkRemoveVisualHidden, cdkVisualHidden, forceRedirect, getActionButtonsV2, getBackgroundImageResize } from '@shared/helper/app.util';
import { SharedService } from '@shared/services/shared.service';
import { LibCompononent } from 'app/core/my-library/shared/data/library.model';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import {
  BACK_ROUNDIMAGE_FEATUREDLIST,
  BACK_ROUNDIMAGEKID_FEATUREDLIST,
  BACK_ROUNDIMAGETEEN_FEATUREDLIST
} from '@shared/constants/search.constant';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { UserService } from '@shared/services/user.service';
@Component({
  selector: 'axis360-featured-list',
  templateUrl: './featured-list.component.html',
  styleUrls: ['./featured-list.component.scss']
})
export class FeaturedListComponent implements OnInit, OnDestroy {
  @Input() featuredList: Partial<LibCompononent> = { title: '', entityId: '', description: '' };
  @Input() myShelfLoad: boolean;
  @Input() parentRoute: string;
  @Output() progressiveEmit = new EventEmitter();
  @Output() removeCuratedListEvent = new EventEmitter();
  backgroundImageUrl: string;
  kidsZoneEnabled: boolean;
  axis360Enabled: boolean;
  getActionViewButtonForLibraryList = getActionButtonsV2.apply(this);
  listContent: ListContent = { listName: '' };
  forceRedirect = forceRedirect;
  LIST_CARD_COUNT = LIST_CARD_COUNT;
  constructor(
    public myShelfListService: MyShelfListService,
    private sharedService: SharedService,
    // private homeService: HomeService,
    public profileService: ProfileInfoService,
    public configService: ConfigService,
    public userService: UserService
  ) { }
  ngOnInit() {
    this.onResize();
    this.getFeaturedList();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
    this.myShelfListService.reloadMyShelfComponent.subscribe((reload: boolean) => {
      if (reload) {
        this.getFeaturedList();
      }
    });
  }
  async getFeaturedList() {
    try {
      if (!this.featuredList.entityId) {
        const featuredListResponse = await this.sharedService.getFeaturedListComponent();
        if (featuredListResponse.statusCode !== 200) {
          this.listContent = { listName: '', Items: [] };
          this.progressiveEmitFeatured();
          return this.myShelfListService.isFeaturedListHide.next(true);
        }
        const { component } = featuredListResponse;
        if (component) {
          this.featuredList = component;
        }
      }
      if (this.featuredList.entityId && this.featuredList.enabled) {
        this.getFeaturedListContent();
      } else {
        this.listContent = { listName: '', Items: [] };
        this.myShelfListService.isFeaturedListHide.next(true);
        this.removeCuratedListEvent.emit(this.featuredList.entityId);
        this.progressiveEmitFeatured();
      }
    } catch (e) {
      this.listContent = { listName: '', Items: [] };
      this.myShelfListService.isFeaturedListHide.next(true);
      this.progressiveEmitFeatured();
    }
  }

  /**
   * Get recommendation content for last read title
   */
  async getFeaturedListContent() {
    try {
      const isLoggedIn = this.userService.isLoggedIn();
      this.listContent = (await this.myShelfListService.getListContent(
        this.featuredList.entityId,
        this.myShelfLoad ? HOME_AVAILABILITY[0].value : this.myShelfListService.selectedAvailability,
        this.myShelfLoad ? KZ_HOME_FORMAT_TYPE[0].value : this.myShelfListService.selectedFormat,
        1,
        LIST_CARD_COUNT
      )) || { listName: '', Items: [] };
      if (this.listContent.Items.length === 0) {
        this.removeCuratedListEvent.emit(this.featuredList.entityId);
      }
      this.myShelfListService.isFeaturedListHide.next(this.listContent.Items.length === 0);
      isLoggedIn && this.getActionViewButtonForLibraryList(this.listContent, 'listContent');
      this.progressiveEmitFeatured();
    } catch (e) {
      this.listContent = { listName: '', Items: [] };
      this.progressiveEmitFeatured();
      this.removeCuratedListEvent.emit(this.featuredList.entityId);
    }
  }

  /**
   * Redirect to the see all list page
   * @param listId list id
   */
  redirectToListPage(listId: string) {
    this.forceRedirect(`/Home/Category/?catid=${listId}`);
  }

  onResize() {
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    if (!this.userService.userInfo?.isLoggedIn) {
      const backgroundImage =
        (this.axis360Enabled && this.kidsZoneEnabled) || (this.axis360Enabled && !this.kidsZoneEnabled)
          ? BACK_ROUNDIMAGE_FEATUREDLIST
          : BACK_ROUNDIMAGETEEN_FEATUREDLIST;
      this.backgroundImageUrl = getBackgroundImageResize(backgroundImage);
    } else {
      const profileAudMap = {
        Adult: BACK_ROUNDIMAGE_FEATUREDLIST,
        Teen: BACK_ROUNDIMAGETEEN_FEATUREDLIST,
        Kid: BACK_ROUNDIMAGEKID_FEATUREDLIST
      };
      const profileType =
        !this.axis360Enabled && this.profileService.currentProfile.profileType === 'Adult'
          ? 'Teen'
          : this.profileService.currentProfile.profileType;
      const getImage = profileAudMap[profileType];
      this.backgroundImageUrl = getBackgroundImageResize(getImage);
    }
  }
  progressiveEmitFeatured() {
    this.featuredList.isShow = this.listContent.Items.length > 0;
    this.progressiveEmit.emit();
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
}
