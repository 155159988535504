import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  PROFILES_EVENTS_KEY,
  PROFILES_SCREENS,
  PROFILES_EVENTS,
  PROFILES_SCREENS_KEY,
  PROFILES_CATEGORY
} from '@profile/shared/constants/profiles.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof PROFILES_EVENTS_KEY;
@Injectable()
export class ProfilesAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of Profiles analytics service.
   */
  constructor() {
    super(PROFILES_SCREENS, PROFILES_EVENTS);
  }
  /**
   * To track search page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof PROFILES_SCREENS_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track profiles common event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackProfilesEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(PROFILES_CATEGORY.PROFILES, action, altObj);
  }
}
