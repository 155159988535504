<div class="book-title-details">
    <ng-container *ngIf="componentApiData && componentApiData?.isVendorEnable === 'true'">
        <div class="btn-group checkoutbtn-group"
            [ngClass]="{'btncheck': (componentApiData?.isCheckedout !== 'false') }">
            <ng-container>
                <ng-container>
                    <ng-container *ngIf="componentApiData?.isCheckedout === 'false'">
                        <button class="btn-primary btn-primary-blue" id="loc_Checkout"
                            (click)="cardAction({type:'actions', actionType:'checkout', btn:'moreOption', btnId: 'loc_playBtn'})">
                            <span>Checkout</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="componentApiData?.isCheckedout ==='true'">
                        <button class="btn-primary btn-primary-blue" id="loc_playBtn"
                            (click)="cardAction({type:'play', actionType:''})">
                            <span>{{componentApiData?.resumeCTA?componentApiData?.resumeCTA:'Play'}}</span>
                        </button>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="componentApiData && componentApiData?.isCheckedout ==='true'">
                <div class="btn-group d-flex">
                    <button class="expand-sec d-flex" aria-label="more option" id="loc_more_option" axis360MatMenuFocus
                        [matMenuTriggerFor]="menu" #matMenuItem="matMenuTrigger" (menuOpened)="menuState(true)" (menuClosed)="menuState(false)">
                        <small class="icon-text">More Options </small>
                        <mat-icon *ngIf="!matMenuItem.menuOpen" svgIcon="kz-down-arrow"></mat-icon>
                        <mat-icon *ngIf="matMenuItem.menuOpen" svgIcon="kz-up-arrow"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
        <mat-menu #menu="matMenu" class="kz-action-menu-item kz-title-details" yPosition="below" xPosition="after">
            <ng-container>
                <ng-container>
                    <ng-container>
                        <div *ngIf="componentApiData?.isRenewButton === 'Y'">
                            <button type="button" mat-menu-item alt="renew" class="action-expand action-links"
                                (click)="cardAction({type:'actions', actionType:'renewtitle', btn:'moreOption', btnId:'loc_more_option'})">
                                Renew
                            </button>
                        </div>
                        <div>
                            <button type="button" mat-menu-item alt="return" class="action-expand action-links"
                                (click)="cardAction({type:'actions', actionType:'returntitle', btn:'moreOption', btnId:'loc_Checkout'})">
                                Return
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-menu>
    </ng-container>
</div>