<div class="kz-myshelf kz-kids">
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">My Shelf Page</p>
    <h1 class="hidden">My Shelf</h1>
    <axis360-mystuff-nav-tabs [navClass]="'my-shelf-nav'"
        (progressivePillsEmit)="progressivePillsEmit()"></axis360-mystuff-nav-tabs>
    <axis360-goals-insights-and-badges *ngIf="goalsInsightsTrackingEnabled" (progressiveGIBEmit)="progressiveGIBEmit()">
    </axis360-goals-insights-and-badges>
    <div class="currently-checked-out">
        <axis360-currently-checked-out [parentRoute]="parentRoute" [myShelfLoad]="true"
            (progressiveCCEmit)="progressiveCCEmit()"></axis360-currently-checked-out>
    </div>
    <div class="because-you-checked-out" *ngIf="userService.isLoggedIn()">
        <axis360-recommendation-carousel [isCheckedOutCarousal]="isCheckedOutCarousal" [parentRoute]="parentRoute"
            (progressiveRECEmit)="progressiveRECEmit()"></axis360-recommendation-carousel>
    </div>
    <div class="featured-list"
        *ngIf="isPillsLoaded && isGoalsBadgesLoaded && isCurrentlyCheckoutLoaded && isRecommendationsLoaded">
        <axis360-featured-list [myShelfLoad]="true" [parentRoute]="parentRoute"></axis360-featured-list>
    </div>
    <div class="my-programs kz-custom-container-secondary"
        *ngIf="currentProfile.programsEnabled && isPillsLoaded && isGoalsBadgesLoaded && isCurrentlyCheckoutLoaded && isRecommendationsLoaded">
        <axis360-my-programs></axis360-my-programs>
    </div>
</div>
