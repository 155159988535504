import { PROGRAMS_TABS } from './programs.constants';

export const PROGRAMS_CATEGORY = 'READING PROGRAMS';
export const PROGRAMS_SCREENS_KEY = {
  MY_PROGRAMS: 'MY_PROGRAMS',
  OPEN_PROGRAMS: 'OPEN_PROGRAMS',
  PROGRAM_DETAILS: 'PROGRAM_DETAILS',
  CLOSED_PROGRAM_DETAILS: 'CLOSED_PROGRAM_DETAILS',
  JOIN_PROGRAM: 'JOIN_PROGRAM',
  PROGRAM_DETAILS_SCREEN: 'PROGRAM_DETAILS_SCREEN',
  WELCOME_PROGRAM: 'WELCOME_PROGRAM',
  ADD_TITLE_SCREEN: 'ADD_TITLE_SCREEN',
  READ_LIST_SCREEN: 'READ_LIST_SCREEN'
};

export const PROGRAMS_SCREENS: { [key: string]: string } = {
  [PROGRAMS_SCREENS_KEY.MY_PROGRAMS]: 'My Programs Landing Page',
  [PROGRAMS_SCREENS_KEY.OPEN_PROGRAMS]: 'Open Programs Landing Page',
  [PROGRAMS_SCREENS_KEY.PROGRAM_DETAILS]: 'Program Detail screen',
  [PROGRAMS_SCREENS_KEY.CLOSED_PROGRAM_DETAILS]: 'Closed Program - <programName>',
  [PROGRAMS_SCREENS_KEY.JOIN_PROGRAM]: 'Join Open Program',
  [PROGRAMS_SCREENS_KEY.WELCOME_PROGRAM]: 'Welcome to the Program',
  [PROGRAMS_SCREENS_KEY.PROGRAM_DETAILS_SCREEN]: 'Program Detail screen',
  [PROGRAMS_SCREENS_KEY.ADD_TITLE_SCREEN]: 'Add a Title screen',
  [PROGRAMS_SCREENS_KEY.READ_LIST_SCREEN]: 'Reading List screen'
};

export const PROGRAMS_EVENTS_KEY = {
  MY_PROGRAMS: 'MY_PROGRAMS',
  OPEN_PROGRAMS: 'OPEN_PROGRAMS',
  TAP_ON_PROGRAM: 'TAP_ON_PROGRAM',
  LEAVE_PROGRAM: 'LEAVE_PROGRAM',
  OK_LEAVE_PROGRAM: 'OK_LEAVE_PROGRAM',
  CANCEL_LEAVE_PROGRAM: 'CANCEL_LEAVE_PROGRAM',
  TITLE_DETAILS: 'TITLE_DETAILS',
  JOIN_PROGAM: 'JOIN_PROGAM',
  GOTO_PROGRAM: 'GOTO_PROGRAM',
  CLOSE_JOIN_POPUP: 'CLOSE_JOIN_POPUP',
  DELETE_PROGRAM: 'DELETE_PROGRAM',
  DC_PROGRAM_YES: 'DC_PROGRAM_YES',
  DC_PROGRAM_NO: 'DC_PROGRAM_NO',
  SEE_ALL: 'SEE_ALL',
  EDIT_READING_LIST: 'EDIT_READING_LIST',
  SEARCH_YOUR_LIBRARY: 'SEARCH_YOUR_LIBRARY',
  ADD_TITLE: 'ADD_TITLE',
  SORT: 'SORT',
  FILTER: 'FILTER',
  EDIT_LIST_CTA: 'EDIT_LIST_CTA',
  SAVE: 'SAVE',
  CANCEL: 'CANCEL',
  POPUP_CANCEL: 'POPUP_CANCEL',
  OK: 'OK',
  ADD_CTA: 'ADD_CTA',
  ADD_YOUR_OWN_TITLE: 'ADD_YOUR_OWN_TITLE',
  REMOVE: 'REMOVE',
  CLICK_CAROUSEL_RIGHT: 'CLICK_CAROUSEL_RIGHT',
  CLICK_CAROUSEL_LEFT: 'CLICK_CAROUSEL_LEFT',
};
export const PROGRAMS_EVENTS: { [key: string]: string } = {
  [PROGRAMS_EVENTS_KEY.MY_PROGRAMS]: 'View My Programs',
  [PROGRAMS_EVENTS_KEY.OPEN_PROGRAMS]: 'View Open Programs',
  [PROGRAMS_EVENTS_KEY.TAP_ON_PROGRAM]: 'Select a Program',
  [PROGRAMS_EVENTS_KEY.LEAVE_PROGRAM]: 'Leave Program CTA',
  [PROGRAMS_EVENTS_KEY.OK_LEAVE_PROGRAM]: 'Ok - Leave Program',
  [PROGRAMS_EVENTS_KEY.CANCEL_LEAVE_PROGRAM]: 'Cancel - Leave Program',
  [PROGRAMS_EVENTS_KEY.TITLE_DETAILS]: 'View Title Details',
  [PROGRAMS_EVENTS_KEY.JOIN_PROGAM]: 'Join Program',
  [PROGRAMS_EVENTS_KEY.GOTO_PROGRAM]: 'Go to Program',
  [PROGRAMS_EVENTS_KEY.CLOSE_JOIN_POPUP]: 'Close - Join Program',
  [PROGRAMS_EVENTS_KEY.DELETE_PROGRAM]: 'Delete program',
  [PROGRAMS_EVENTS_KEY.DC_PROGRAM_YES]: 'Yes - Delete program confimration',
  [PROGRAMS_EVENTS_KEY.DC_PROGRAM_NO]: 'No - Delete program confimration',
  [PROGRAMS_EVENTS_KEY.SEE_ALL]: 'See all CTA',
  [PROGRAMS_EVENTS_KEY.EDIT_READING_LIST]: 'Edit Reading List CTA',
  [PROGRAMS_EVENTS_KEY.SEARCH_YOUR_LIBRARY]: 'search your library',
  [PROGRAMS_EVENTS_KEY.ADD_YOUR_OWN_TITLE]: 'Add your own title',
  [PROGRAMS_EVENTS_KEY.ADD_TITLE]: 'Add Title CTA',
  [PROGRAMS_EVENTS_KEY.SORT]: 'Sort - <sortName>',
  [PROGRAMS_EVENTS_KEY.FILTER]: 'Filter - <filterName>',
  [PROGRAMS_EVENTS_KEY.EDIT_LIST_CTA]: 'Edit Reading List CTA',
  [PROGRAMS_EVENTS_KEY.SAVE]: 'Save',
  [PROGRAMS_EVENTS_KEY.CANCEL]: 'Cancel',
  [PROGRAMS_EVENTS_KEY.POPUP_CANCEL]: 'Pop up - Cancel ',
  [PROGRAMS_EVENTS_KEY.OK]: 'Ok',
  [PROGRAMS_EVENTS_KEY.ADD_CTA]: 'Add CTA',
  [PROGRAMS_EVENTS_KEY.REMOVE]: 'Remove',
  [PROGRAMS_EVENTS_KEY.CLICK_CAROUSEL_RIGHT]: 'Click carousel navigation - right',
  [PROGRAMS_EVENTS_KEY.CLICK_CAROUSEL_LEFT]: 'Click carousel navigation - left',


};

export const PROGRAMS_EVENTS_KEY_BY_TAB: { [key: string]: keyof typeof PROGRAMS_EVENTS_KEY } = {
  [PROGRAMS_TABS.MY_PROGRAMS]: 'MY_PROGRAMS',
  [PROGRAMS_TABS.OPEN_PROGRAMS]: 'OPEN_PROGRAMS'
};
export const PROGRAMS_SCREENS__KEY_BY_TAB: { [key: string]: keyof typeof PROGRAMS_SCREENS_KEY } = {
  [PROGRAMS_TABS.MY_PROGRAMS]: 'MY_PROGRAMS',
  [PROGRAMS_TABS.OPEN_PROGRAMS]: 'OPEN_PROGRAMS'
};
