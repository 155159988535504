export enum BreakPoint {
  pixel3840 = '3840px',
  pixel1440 = '1440px',
  pixel2560 = '2560px',
  pixel2200 = '2200px',
  pixel1920 = '1920px',
  pixel1600 = '1600px',
  pixel1280 = '1280px',
  pixel1024 = '1024px',
  pixel1023 = '1023px',
  pixel992 = '992px',
  pixel768 = '768px',
  pixel700 = '700px',
  pixel560 = '560px',
  pixel475 = '475px',
  pixel425 = '425px'
}
