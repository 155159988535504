import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { MatSelectTriggerAriaHiddenDirective } from './directive/mat-select-trigger-aria-hidden.directive';
import { DisableAfterClickDirective } from './directive/disable-after-click.directive';
import { AnnounceElemDirective } from './directive/announce-elem.directive';
import { TrapFocusDirective } from './directive/trap-focus.directive';
import { MatSelectOverlayClassDirective } from './directive/mat-select-overlay-class.directive';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { TitlesCarouselComponent } from './components/titles-carousel/titles-carousel.component';
import { TitleCardComponent } from './components/title-card/title-card.component';
import { TitleActionsComponent } from './components/title-card/title-actions/title-actions.component';
import { FeaturedListComponent } from './components/featured-list/featured-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { ShimmerCardsComponent } from './components/shimmer-cards/shimmer-cards.component';
import { TitleFilterComponent } from './components/title-filter/title-filter.component';
import { MatSelectModule } from '@angular/material/select';
import { IframeComponent } from './components/iframe/iframe.component';
import { AlertDialogNewComponent } from './components/alert-dialog-new/alert-dialog-new.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NewspaperMagazineCarouselComponent } from './components/newspaper-magazine-carousel/newspaper-magazine-carousel.component';
import { NewspaperMagazineCardComponent } from './components/newspaper-magazine-card/newspaper-magazine-card.component';
import { MystuffNavTabsComponent } from './components/mystuff-nav-tabs/mystuff-nav-tabs.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TitleDetailsMoreComponent } from './components/title-details-more/title-details-more.component';
import { AlertDialogInputComponent } from './components/alert-dialog-input/alert-dialog-input.component';
import { IvyCarouselDirective } from './directive/ivy-carousel.directive';
import { PradioAdaDirective } from './directive/p-radio-ada.directive';
import { KzLearningActivityNewComponent } from './components/kz-learning-activity-new/kz-learning-activity-new.component';
import { KzLearningActivityNewCarouselComponent } from './components/kz-learning-activity-new/kz-learning-activity-new-carousel/kz-learning-activity-new-carousel.component';
import { ThousandSuffixesPipe } from './pipes/thousand-suffixes.pipe';
import { ThirdPartyCardsCarouselComponent } from './components/third-party-cards-carousel/third-party-cards-carousel.component';
import { ThirdPartyCardsComponent } from './components/third-party-cards/third-party-cards.component';
import { MatSelectAriaExpandedDirective } from './directive/mat-select-aria-expanded.directive';
import { AppBannerComponent } from './components/app-banner/app-banner.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { PascalCasePipe } from './pipes/pascal-case.pipe';
import { MatCheckboxAdaDirective } from './directive/mat-checkbox-ada.directive';
import { LibraryInvalidComponent } from './components/library-invalid/library-invalid.component';
import { MigratedInvalidComponent } from './components/migrated-invalid/migrated-invalid.component';
import { EllipsisTitleDirective } from './directive/ellipsis-title.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuFocusDirective } from './directive/mat-menu-focus.directive';
import { EmailShareDialogComponent } from './components/email-share-dialog/email-share-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatIconModule,
    A11yModule,
    IvyCarouselModule,
    MatTabsModule,
    MatMenuModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
    BreadcrumbModule,
    MatCheckboxModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  declarations: [
    SpinnerLoaderComponent,
    AlertDialogComponent,
    AlertDialogNewComponent,
    MatSelectTriggerAriaHiddenDirective,
    DisableAfterClickDirective,
    TrapFocusDirective,
    AnnounceElemDirective,
    MatSelectOverlayClassDirective,
    TitlesCarouselComponent,
    TitleCardComponent,
    TitleActionsComponent,
    FeaturedListComponent,
    NavigationTabsComponent,
    ShimmerCardsComponent,
    TitleFilterComponent,
    IframeComponent,
    BreadcrumbComponent,
    NewspaperMagazineCarouselComponent,
    NewspaperMagazineCardComponent,
    MystuffNavTabsComponent,
    AlertDialogInputComponent,
    TitleDetailsMoreComponent,
    IvyCarouselDirective,
    PradioAdaDirective,
    KzLearningActivityNewComponent,
    KzLearningActivityNewCarouselComponent,
    ThousandSuffixesPipe,
    ThirdPartyCardsCarouselComponent,
    ThirdPartyCardsComponent,
    MatSelectAriaExpandedDirective,
    AppBannerComponent,
    VideoDialogComponent,
    PascalCasePipe,
    MatCheckboxAdaDirective,
    LibraryInvalidComponent,
    MigratedInvalidComponent,
    EllipsisTitleDirective,
    MatMenuFocusDirective,
    EmailShareDialogComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    MatIconModule,
    SpinnerLoaderComponent,
    AlertDialogComponent,
    AlertDialogNewComponent,
    MatSelectTriggerAriaHiddenDirective,
    DisableAfterClickDirective,
    TrapFocusDirective,
    AnnounceElemDirective,
    MatSelectOverlayClassDirective,
    TitleCardComponent,
    TitlesCarouselComponent,
    TitleActionsComponent,
    IvyCarouselModule,
    NavigationTabsComponent,
    MatTabsModule,
    MatMenuModule,
    MatProgressBarModule,
    FeaturedListComponent,
    MatExpansionModule,
    ShimmerCardsComponent,
    TitleFilterComponent,
    MatSelectModule,
    IframeComponent,
    BreadcrumbComponent,
    NewspaperMagazineCarouselComponent,
    NewspaperMagazineCardComponent,
    MystuffNavTabsComponent,
    TitleDetailsMoreComponent,
    IvyCarouselDirective,
    PradioAdaDirective,
    KzLearningActivityNewComponent,
    KzLearningActivityNewCarouselComponent,
    ThousandSuffixesPipe,
    ThirdPartyCardsCarouselComponent,
    ThirdPartyCardsComponent,
    AppBannerComponent,
    VideoDialogComponent,
    PascalCasePipe,
    MatCheckboxAdaDirective,
    EllipsisTitleDirective,
    MatSelectAriaExpandedDirective,
    MatCheckboxModule,
    MatMenuFocusDirective
  ]
})
export class SharedModule {}
