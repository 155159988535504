<div class="kz-carousel kz-category-carousel" *ngIf="isLoading || catData && catData[0]?.categories?.length>0">
    <h2 class="heading-2 text-capitalize" attr.aria-label="{{componentData.title}}"><span aria-hidden="true">{{componentData.title}}</span></h2>
    <div (window:resize)="onResize()" class="carousel-categories">
        <carousel axis360IvyCarousel *ngIf="!isLoading && catData && catData[0]?.categories?.length>0" [dots]="false" [margin]="cellMargin"
            (click)="trackCarousel($event);$event.stopPropagation();"
            (keydown.enter)="trackCarousel($event);$event.stopPropagation();" [cellWidth]="cellWidth"
            [height]="cellHeight" [cellsToScroll]="cellsToScroll">
            <div class="carousel-cell" *ngFor="let category of catData[0].categories" role="listitem">
                <axis360-third-party-categories (acOnExecuteAction)="acOnExecuteAction($event)"
                    [componentData]="category"
                    [categoryClass]="componentData.subType !== THIRD_PARTY_SUBTYPE.VIDEOS ? 'third-party-category-card' : 'third-party-video-category'"
                    [componentTemplate]="componentData.jsonTempl">
                </axis360-third-party-categories>
            </div>
        </carousel>
    </div>
    <axis360-shimmer-cards [compName]="compName" *ngIf="isLoading" [showCarouselTitle]="false">
    </axis360-shimmer-cards>
</div>