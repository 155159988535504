import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { UrlConstrutor } from '@shared/helper/url-constructor';
import { IThirdPartyLibraryComponent, IThirdPartyVendor } from 'app/third-party/shared/data/third-party-model';
import { of } from 'rxjs';
import { DataApi, ThirdPartyLayouts } from '../data/third-party-model';
import { CommonService } from './common.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyBaseService {
  constructor(public http: HttpClient, protected userService: UserService, protected commonService: CommonService) { }
  getDataFromDataApi(dataApi: DataApi) {
    if (!dataApi?.url) {
      return Promise.resolve({});
    }
    const url = new UrlConstrutor(dataApi).formatUrl();
    return this.http.post(API_INFO.getThirdPartyData, {
      url, headers: dataApi.headers, body: dataApi.body, country: dataApi.country || '',
      type: dataApi.type || '', displayName: dataApi.displayName || ''
    }).toPromise();
  }
  getDataFromDataCategoryApi(dataApi: DataApi){
    if (!dataApi?.url) {
      return of({});
    }
    const url = new UrlConstrutor(dataApi).formatUrl();
    return this.http.post(API_INFO.getThirdPartyData, { url, headers: dataApi.headers, body: dataApi.body });
  }
  getThirdPartyItems(dataApi: DataApi) {
    return this.getDataFromDataApi(dataApi);
  }
  getThirdPartyLibrary(vendorCode: string): Promise<ThirdPartyLayouts> {
    return this.http.get<ThirdPartyLayouts>(API_INFO.getThirdPartyLibrary.replace('<vendorCode>', vendorCode)).toPromise();
  }
  getThirdPartyLibraryComponents(entityType: string): Promise<IThirdPartyLibraryComponent> {
    return this.http.get<IThirdPartyLibraryComponent>(
      API_INFO.getThirdPartyLibraryComponents
      .replace(`<entityType>`, `${entityType}`)
      ).toPromise();
  }
  getThirdPartyVendor(): Promise<IThirdPartyVendor[]> {
    return this.http.get<IThirdPartyVendor[]>(API_INFO.getThirdPartyVendors).toPromise();
  }
}
