import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Badge } from '@home/shared/data/home.model';
import { HomeService } from '@home/shared/services/home.service';
import { positionFooter } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  isLoading = true;
  badges: Badge[] = [];
  @Output() progressiveBadgesEmit = new EventEmitter();
  constructor(protected homeService: HomeService) { }
  /**
   * To get badges on init
   */
  ngOnInit(): void {
    this.getBadges();
  }
  /**
   * To handle badges
   * @param [badgesFromRes] badges from API
   * @returns  badges in 2D Array for carousel UI
   */
  handleBadges(badgesFromRes: Badge[] = []) {
    const badges = [];
    const badgesLen = badgesFromRes.length;
    const chunk = 2;
    for (let i = 0; i < badgesLen; i += chunk) {
      badges.push(badgesFromRes.slice(i, i + chunk));
    }
    return badges;
  }
  /**
   * To get badges from API
   */
  getBadges() {
    this.homeService.getBadges().subscribe((response) => {
      this.badges = this.handleBadges(response.badges);
      this.isLoading = false;
      positionFooter(true);
      this.progressiveEmitBadges();
    },
      () => {
        this.isLoading = false;
        this.progressiveEmitBadges();
      });
  }
  progressiveEmitBadges() {
    this.progressiveBadgesEmit.emit();
  }
}
