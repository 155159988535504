import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  MYSTUFF_LIST_MAP,
  MYSTUFF_FILTER_HOLDS,
  MYSTUFF_FILTER_RECOMMENDATIONS,
  MYSTUFF_FORMAT_TYPE_ALL,
  KZ_HOME_FORMAT_TYPE,
  HOME_AVAILABILITY,
  MYSTUFF_SORT,
  MAX_CARD_COUNT,
  SEARCH_NORESULT_MESSAGE,
  PURCHASE_REQUESTS,
  HOME,
  LIST_CARD_COUNT_HISTORY,
  MYSTUFF_SORT_CHECKOUT,
  MYSTUFF_ACTIVE_TAB,
  FILTER_FORMAT,
  MYSTUFF_NORESULT_MESSAGE
} from '@shared/constants/app.constants';
import { ListContent } from '@shared/data/config.model';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { noop, Subscription } from 'rxjs';
import { MyStuffService } from '../../shared/services/my-stuff.service';
import { MyStuffAnalyticsService } from '../../shared/services/my-stuff.analytics.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { BookDetail } from '@shared/data/engage-base.model';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { SharedService } from '@shared/services/shared.service';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
// import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement } from '@shared/helper/app.util';
import { Title } from '@angular/platform-browser';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-my-stuff-title',
  templateUrl: './my-stuff-title.component.html',
  styleUrls: ['./my-stuff-title.component.scss']
})
export class MyStuffTitleComponent implements OnInit, OnDestroy, AfterViewInit {
  activeMyStuff: string;
  scrollSubscription: Subscription;
  refinerSubscription: Subscription;
  pageIndex = 1;
  myStuffContent: ListContent = { listName: '', Items: [] };
  listContent: ListContent = { listName: '', Items: [] };
  myStuffLength = 0;
  pageToken = '';
  MYSTUFF_ACTIVE_TAB = MYSTUFF_ACTIVE_TAB;
  searchSubscription: Subscription;
  reloadDataAPISubscriptions: Subscription;
  noResultMessage: string;
  isSearch = false;
  All = 'All';
  refreshResumeCTA = false;
  parentRoute: string;
  homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'My Library';
  breadcrumbs = [
    { text: this.homePage, url: HOME[this.homePage], isHref: true },
    { text: 'My Stuff', url: './' }
  ];
  isLoading = true;
  selectedMystuffTitle: string;
  isLoadingCheckoutHistory = true;
  focusElement = focusElement;
  pageAnnounce = '';
  constructor(
    public myStuffService: MyStuffService,
    private infiniteScroll: InfiniteScrollService,
    public spinnerService: SpinnerService,
    private myStuffAnalyticsServcie: MyStuffAnalyticsService,
    public searchStorageService: SearchStorageService,
    public enagageService: EngageBaseService,
    private sharedService: SharedService,
    public thirdPartyService: ThirdPartyService,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    // private liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private titleService: Title,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.listContent.Items = [];
    this.activeMyStuff = this.myStuffService.activeMyStuff;
    this.titleService.setTitle('My Stuff');
    const routePath = this.router.url.includes('myshelf') ? 'myshelf/mystuff/' : 'mystuff/';
    this.parentRoute = routePath + this.activeMyStuff;
    const isAnnouncer = (this.activeMyStuff === 'history') ? 'Checkout History page Loading' : `${this.activeMyStuff} page Loading`;
    // this.liveAnnouncer.announce(
    //   this.activeMyStuff === PURCHASE_REQUESTS.recommendations ? `${PURCHASE_REQUESTS.pageName} Loading`
    //     : isAnnouncer,
    //   'assertive'
    // );
    this.pageAnnounce = this.activeMyStuff === PURCHASE_REQUESTS.recommendations ? `${PURCHASE_REQUESTS.pageName} Loading` : isAnnouncer;
    this.commonService.isAnnouncedPage = true;
    this.myStuffService.activeMyStuff = this.activeMyStuff;
    this.myStuffService.isGridView = true;
    this.myStuffService.filterOption = KZ_HOME_FORMAT_TYPE[0].value;
    this.myStuffService.selectAvailability = HOME_AVAILABILITY[0].value;
    this.myStuffService.sortOption = this.activeMyStuff === MYSTUFF_ACTIVE_TAB.checkouts.toLowerCase() ? MYSTUFF_SORT_CHECKOUT[0].value
      : MYSTUFF_SORT[0].value;
    this.myStuffService.searchKeyword = '';
    this.myStuffService.showSearch = false;
    this.getMyStuffTitles(this.activeMyStuff);
    this.myStuffAnalyticsServcie.trackScreen('MY_STUFF', { myStuff: this.activeMyStuff });

    if (this.activeMyStuff === 'history') {
      this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
    }
    this.refinerSubscription = this.sharedService.refinerSubject.subscribe((res) => {
      if (res !== 'checkCount' && res !== 'initial' && res !== 'offcanvas') {
        this.refreshAndGetMyStuffContent();
      }
    });
    this.searchSubscription = this.sharedService.searchSubject.subscribe((res) => {
      if (this.activeMyStuff === 'history' && res === 'search') {
        this.refereshMyStuffContent();
        this.getMyStuffTitles(this.activeMyStuff);
        return;
      }
      if (res === 'search') {
        this.searchRefiners(res);
      }
    });
    this.reloadDataAPISubscriptions = this.thirdPartyService.getReloadDataAPI().subscribe((item) => {
      if (item) {
        this.refereshMyStuffContent();
        this.getMyStuffTitles(this.activeMyStuff);
      }
    });
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
  }
  /**
   * To verify can load more or not.
   * @returns returns can load more or not
   */
  canLoadMore = (): boolean => !this.spinnerService.isLoading && this.myStuffLength > this.pageIndex * LIST_CARD_COUNT_HISTORY;

  /**
   * Load more content
   * @returns returns can load more or not
   */
  loadMore = () => {
    if (!this.canLoadMore()) {
      return;
    }
    this.pageIndex++;
    this.getMyStuffTitles(this.activeMyStuff, true);
  };

  /**
   * Referesh the content when filter or sort applies
   */
  refereshMyStuffContent() {
    this.pageIndex = 1;
    this.myStuffLength = 0;
    this.myStuffContent = { listName: '', Items: [] };
    this.listContent = { listName: '', Items: [] };
  }

  /**
   * Get my stuff content
   */
  async getMyStuffTitles(activeMyStuff, loadMore = false) {
    this.isLoading = true;
    this.spinnerService.showLoader(true);
    let listContent;
    try {
      listContent = (await this.myStuffService.getMyStuffContent(
        MYSTUFF_LIST_MAP[activeMyStuff],
        MYSTUFF_FORMAT_TYPE_ALL,
        this.myStuffService.filterOption,
        this.myStuffService.sortOption,
        this.pageIndex,
        activeMyStuff === 'history' ? LIST_CARD_COUNT_HISTORY : MAX_CARD_COUNT,
        this.pageToken,
        this.myStuffService.searchKeyword.trim()
      )) || { listName: '', Items: [] };
      this.spinnerService.showLoader(false);
      this.getMessage();
      if (this.activeMyStuff === 'history') {
        this.searchRefiners('search');
      }
      this.isLoading = false;
      if (this.isLoadingCheckoutHistory && this.configService.focusMystuffTab) {
        this.configService.focusMystuffTab && this.focusElement(this.selectedMystuffTitle);
      }
      if (this.activeMyStuff === 'history' && this.isLoadingCheckoutHistory) {
        this.isLoadingCheckoutHistory = false;
      }
      this.configService.focusMystuffTab = false;
    } catch (e) {
      listContent = { listName: '', Items: [] };
      this.spinnerService.showLoader(false);
      this.isLoading = false;
    }
    if (activeMyStuff === MYSTUFF_FILTER_HOLDS || activeMyStuff === MYSTUFF_FILTER_RECOMMENDATIONS) {
      const filterType = this.myStuffService.filterOption;
      if (filterType !== MYSTUFF_FORMAT_TYPE_ALL && filterType !== undefined) {
        listContent.Items = listContent.Items.filter((x: BookDetail) => x.FormatType === filterType);
      }
    }
    if (loadMore) {
      const id = 'mystuff-' + this.myStuffContent.Items[this.myStuffContent.Items.length - 1].ItemId;
      const firstElement = document.getElementById('mystuff-' + this.myStuffContent.Items[0].ItemId);
      const loadElement = document.getElementById(id);
      firstElement && firstElement.setAttribute('tabindex', '-1');
      loadElement && loadElement.setAttribute('tabindex', '1');
      setTimeout(() => {
        document.getElementById(id)?.focus();
        firstElement && firstElement.setAttribute('tabindex', '-1');
      }, 2000);
    }
    if (loadMore) {
      this.myStuffContent.Items.push(...listContent.Items);
    } else {
      this.myStuffContent.Items = listContent.Items;
    }
    const toCheckVideoOrVbookInList = (this.myStuffContent.Items || []).some((elm) => elm.FormatType === 'VID' || elm.FormatType === 'VBK');
    this.myStuffService.activeVideoVbooksTitle.next(toCheckVideoOrVbookInList);
    this.myStuffLength = listContent.TotalItems;
    this.pageToken = listContent.PageToken;
    this.listContent.Items = JSON.parse(JSON.stringify(this.myStuffContent.Items));
    this.getBookDetails(this.myStuffContent);
    this.getMultipleVideoProgressBasedOnItemId(this.myStuffContent);
    this.sharedService.refinerSubject.next('checkCount');
    for (const listConstent in this.myStuffContent.Items) {
      if (this.myStuffContent.Items.hasOwnProperty(listConstent)) {
        this.getActionViewButtonForLibraryList(this.myStuffContent.Items[listConstent]);
      }
    }
  }
  selectMystuffTitle(id) {
    this.selectedMystuffTitle = id;
  }

  /**
   * Generate action button for the my stuff card
   * @param listConstent list content
   */
  async getActionViewButtonForLibraryList(listConstent) {
    this.myStuffService.getMyLibraryListActionButton(listConstent, this.activeMyStuff === 'history').subscribe((actionButtonResponse) => {
      if (this.activeMyStuff === 'history') {
        actionButtonResponse.displayStatus = (listConstent.FormatType !== 'VBK' && listConstent.FormatType !== 'VID') ?
          actionButtonResponse.displayStatus : '';
      }
      if (this.activeMyStuff === 'wishlist' && (listConstent.FormatType === 'VBK' || listConstent.FormatType === 'VID')) {
        actionButtonResponse.actions.map(btn => {
          if (btn.actionButtonId.includes('btnAccessinApp') || btn.actionButtonId.includes('btnRenew') || btn.actionButtonId.includes('btnReturn')) {
            actionButtonResponse.isRemoveWishlist = 'N';
          }
          if (btn.actionButtonId.includes('btnCheckoutNow')) {
            actionButtonResponse.isRemoveWishlist = 'Y';
          }
          return btn;
        });
      }
      this.myStuffContent.Items?.map((elm) => {
        if (elm.ISBN === listConstent.ISBN) {
          elm.DisplayStatus = actionButtonResponse.displayStatus;
          elm.HoldPosition = actionButtonResponse.holdPosition;
          elm.titleStateInfo = actionButtonResponse;
          elm.isRemoveWishlist = actionButtonResponse.isRemoveWishlist || '';
          elm.tabName = this.activeMyStuff;
          elm.displayStatusCss = (elm.titleStateInfo && elm.titleStateInfo.displayStatus !== '') ? 'carousel-card-ribbon-info' : '';
          if (elm.FormatType === 'VID' || elm.FormatType === 'VBK') {
            const bookStatus = (elm.titleStateInfo && (elm.titleStateInfo.displayStatus) !== '') ? 'Book Status ' + (elm.titleStateInfo.displayStatus) + ', ' : '';
            const bookDuration = elm.RunTime ? ', Duration ' + this.replaceTimeStrings(elm.RunTime) : '';
            elm.altLabel = `${bookStatus} Format ${elm.FormatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${elm.Title}, Author ${elm.Author}${bookDuration}`
          }
        }
      });
      this.listContent.Items?.map((elm) => {
        if (elm.ISBN === listConstent.ISBN) {
          elm.DisplayStatus = actionButtonResponse.displayStatus;
          elm.HoldPosition = actionButtonResponse.holdPosition;
          elm.titleStateInfo = actionButtonResponse;
          elm.isRemoveWishlist = actionButtonResponse.isRemoveWishlist || '';
          elm.tabName = this.activeMyStuff;
          elm.displayStatusCss = (elm.titleStateInfo && elm.titleStateInfo.displayStatus !== '') ? 'carousel-card-ribbon-info' : '';
          if (elm.FormatType === 'VID' || elm.FormatType === 'VBK') {
            const bookStatus = (elm.titleStateInfo && (elm.titleStateInfo.displayStatus) !== '') ? 'Book Status ' + (elm.titleStateInfo.displayStatus) + ', ' : '';
            const bookDuration = elm.RunTime ? ', Duration ' + this.replaceTimeStrings(elm.RunTime) + ',' : '';
            elm.altLabel = `${bookStatus} Format ${elm.FormatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${elm.Title}, Author ${elm.Author}${bookDuration}`
          }
        }
      });
    }, noop);
  }
  /**
   * Refresh the MyStuffContent and get MyStuff Contents
   */
  refreshAndGetMyStuffContent() {
    this.myStuffService.searchKeyword = '';
    this.refereshMyStuffContent();
    this.getMyStuffTitles(this.activeMyStuff);
    this.sharedService.refinerSubject.next('initial');
  }

  /**
   * Unsubscribe the behaviour subjects
   */
  ngOnDestroy() {
    if (this.refinerSubscription) {
      this.refinerSubscription.unsubscribe();
    }
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.sharedService.searchSubject.next('');
      this.searchSubscription.unsubscribe();
    }
    if (this.reloadDataAPISubscriptions) {
      this.thirdPartyService.setReloadDataAPI(false);
      this.reloadDataAPISubscriptions.unsubscribe();
    }
    cdkRemoveVisualHidden();
  }
  /**
   * Reading BookProgress
   */
  async getBookDetails(books: ListContent): Promise<{ [itemId: string]: ListContent }> {
    this.spinnerService.showLoader();
    const bookIds = (books.Items || [])
      .filter((elem) => elem.FormatType !== 'VID' && elem.FormatType !== 'VBK')
      .map(({ ItemId }) => ItemId);
    const bookDetail = { bookIdList: bookIds };
    if (!bookIds.length) {
      this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
      return;
    }
    try {
      const booksDetailsRes = await this.enagageService.getBookProgress(bookDetail);
      this.listContent.Items.forEach((book) => {
        booksDetailsRes.bookProgress.forEach((title) => {
          if (title.bookId === book.ItemId && Math.round(title.progressPercentage) > 0) {
            book.percentageComplete = Math.round(title.progressPercentage);
          }
        });
      });
      this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
    } catch (e) {
      this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
      return;
    }
  }

  replaceTimeStrings = (text: string): string => {
    if(text?.includes('Mins')) {
      return text?.replace(/m(in)?s?/gi, 'minutes');
    }
    return text?.replace(/h(r?s)?/gi, ' hours').replace(/m(in)?s?/gi, ' minutes');
  };

  /**
   * Get MultipleVideoProgressBasedOn ItemId
   * @param [books] to pass the booklist
   * @returns List of Item return with Resume or play CTA
   */
  async getMultipleVideoProgressBasedOnItemId(books: ListContent) {
    this.spinnerService.showLoader();
    const bookIds = (books.Items || []).filter((elem) => elem.FormatType === 'VID' || elem.FormatType === 'VBK').map(({ ISBN }) => ISBN);

    if (!bookIds.length) {
      this.refreshResumeCTA = true;
      this.spinnerService.showLoader(false);
      this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
      return;
    }
    const bookDetail = { bookIdList: bookIds };
    try {
      const booksDetailsRes = await this.thirdPartyService.getMultipleTitleBookProgress(bookDetail);
      if (booksDetailsRes && booksDetailsRes.length > 0) {
        let itemProgress: any;
        booksDetailsRes.forEach((progressItems) => {
          itemProgress = (this.listContent.Items || [])?.map((itemList) => {
            itemList.displayStatusCss = (itemList.titleStateInfo && itemList.titleStateInfo.displayStatus !== '') ? 'carousel-card-ribbon-info' : '';
            if (itemList.ISBN === progressItems.itemId && progressItems.status.type === 0 && Math.round(progressItems.timeoffset) > 0) {
              itemList.resumeCTA = 'Resume';
              return itemList;
            }
            return itemList;
          });
        });
        this.myStuffContent.Items = JSON.parse(JSON.stringify(itemProgress));
        this.refreshResumeCTA = true;
        this.spinnerService.showLoader(false);
      } else {
        this.refreshResumeCTA = true;
        this.spinnerService.showLoader(false);
        this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
        return;
      }
    } catch (error) {
      this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
      this.refreshResumeCTA = true;
      this.spinnerService.showLoader(false);
      return;
    }
  }
  /**
   * Search in mystuff Screens
   */
  searchRefiners(res: string) {
    this.sharedService.noResults = false;
    this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
    if (res === 'search' && this.myStuffService.searchKeyword.trim() === '') {
      this.isSearch = false;
      this.getMessage();
      this.myStuffContent.Items = JSON.parse(JSON.stringify(this.listContent.Items));
      return this.myStuffContent.Items;
    }
    this.myStuffContent.Items = this.myStuffContent.Items.filter((item) => {
      if (
        item.Title.toLowerCase()
          .replace(/ +(?= )/g, '')
          .includes(
            this.myStuffService.searchKeyword
              ?.trim()
              .toLowerCase()
              .replace(/ +(?= )/g, '')
          )
      ) {
        return item;
      }
    });
    this.isSearch = true;
    this.noResultMessage = SEARCH_NORESULT_MESSAGE;
    this.sharedService.noResults = this.myStuffContent.Items.length > 0 ? false : true;
  }
  getMessage() {
    this.noResultMessage = MYSTUFF_NORESULT_MESSAGE[this.activeMyStuff].replace(
      '<format>',
      `${FILTER_FORMAT[this.myStuffService.filterOption]}`
    );
  }
  refinerChanged(id: string) {
    this.selectedMystuffTitle = id;
    this.sharedService.refinerSubject.next('refinerChanged');
  }
  searchChange(id: string) {
    this.selectedMystuffTitle = id;
    this.sharedService.searchSubject.next('search');
  }
  ngAfterViewInit(): void {
    this.sharedService.updateActionsButtonCheckoutCTA.subscribe((res) => {
      if (!res) {
        !this.configService.focusMystuffTab && this.focusElement('loc_headerId');
      }
    });
  }
}
