<div class="kz-title-carousel" *ngIf="titlesList && titlesList.length > 0">
    <ng-container *ngIf="listData">
        <div class="title-with-arrow">
            <h2 class="title" [innerHTML]="listData.listName"></h2>
            <a href="javascript:;" class="link see-all-text" (click)="redirectToListPage(listData.seeAll)"
                (keydown.enter)="redirectToListPage(listData.seeAll)"
                *ngIf="(listData.totalItems > LIST_CARD_COUNT && titlesList.length >= LIST_CARD_COUNT)"
                attr.aria-label="See all {{listData.listName}}">
                See all
                <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon>
            </a>
        </div>

        <p class="description description-text five-ellipsis" axis360EllipsisTitle [description]="listData.description"
            *ngIf="listData.description || listData.description !== ''">
            {{listData.description}}
        </p>
    </ng-container>
    <div class="kz-carousel" (window:resize)="onResize()">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="cellsToScroll" [margin]="cellMargin"
            [cellWidth]="cellWidth" (click)="trackCarousel($event);$event.stopPropagation();"
            height="{{addCard ? 292 : 252}}" (keydown.enter)="trackCarousel($event);$event.stopPropagation();">
            <div class="carousel-cell featured-list-cell" *ngIf="featuredList && featuredList.description" role="listitem">
                <mat-card class="kz-card-des">
                    <div class="text-sec carousel-description eight-ellipsis" title="{{featuredList.description}}">
                        {{featuredList.description}}
                    </div>
                </mat-card>
            </div>
                <div class="carousel-cell carousel-aTag" *ngFor="let titleContent of titlesList" role="listitem"
                    (click)="cardClick(titleContent);$event.stopPropagation();" (keyup.enter)="cardClick(titleContent);$event.stopPropagation();"  id="carousel_{{titleContent?.itemId}}">
                    <axis360-title-card [isProgramDetail]="addCard" [isProgramCTAShow]="isProgramCTAShow" [parentRoute]="parentRoute" [isCarousel]="true"
                        [searchResult]="searchResult" [isParticipant]="isParticipant" [titleContent]="titleContent"
                        (titleActionEvt)="titleActionEvent()" [programId]="programId" (seeAllClickEvent)="seeAllClick()"
                        [isFeaturedProgram]="isFeaturedProgram" [isCurrentlyCheckout]="isCurrentlyCheckout"
                        [myShelfLoad]="myShelfLoad"
                        *ngIf="titleContent.FormatType !== 'VID' && titleContent.FormatType !== 'VBK'" ></axis360-title-card>
                   <div class="mystuff-lists"><axis360-third-party-cards [componentData]="titleContent" [parentRoute]="parentRoute"
                    [isCurrentlyCheckout]="isCurrentlyCheckout"
                    [vendorCode]="(titleContent.VendorId ? titleContent.VendorId : (titleContent.FormatType === 'VID'?'VIDEOS':'VBOOKS'))"
                    [componentTemplate]="layoutComponents"
                    *ngIf="(titleContent.FormatType === 'VID' || titleContent.FormatType === 'VBK') && layoutComponents"
                    [thirdPartyClass]=" titleContent.displayStatusCss + ' third-party-card-common third-party-card-checkout-videos third-party-card-mystuff-videos'" iscarousel="true">
                </axis360-third-party-cards></div> 
                </div>
        </carousel>
    </div>
</div>
<axis360-shimmer-cards [compName]="compName" *ngIf="titlesList === undefined" [showCarouselTitle]="showCarouselTitle"
    [showCarouselSubTitle]="showCarouselSubTitle">
</axis360-shimmer-cards>