import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { CategoryCard } from '@pressreader/shared/data/press-reader-model';
import { BreakPointService } from '@shared/services/break-point.service';

@Component({
  selector: 'axis360-press-reader-category-carousel',
  templateUrl: './press-reader-category-carousel.component.html'
})
export class PressReaderCategoryCarouselComponent implements OnInit, AfterContentChecked {
  @Input() categories: CategoryCard[];
  catogoryList = [];
  numVisible: number;
  numScroll: number;
  responsiveOptions: any;
  hideCarouselOption = false;

  /**
   * Creates an instance of press reader card component.
   * @param router router info
   */
  constructor(private breakPointService: BreakPointService) {}

  /**
   * split the category array into multiple chunks each chunk has 2 items
   */
  ngOnInit(): void {
    const chunk = 2;
    for (let index = 0; index < this.categories.length; index += chunk) {
      this.catogoryList.push(this.categories.slice(index, index + chunk));
    }
    this.onResize(0, null);
  }

  /**
   * Initialize the carousel options
   */
  ngAfterContentChecked() {
    this.initializeCarouselOption();
    this.onResize(0, null);
  }

  /**
   * Handle window resize
   * @param type typw of carousel
   * @param event event object
   */
  onResize(type: number, event?: any) {
    this.breakPointService.onResizeCategories(type, event);
    this.hideCarouselOption = this.categories.length <= this.breakPointService.categoryBreakPoint.numVisible;
  }

  /**
   * Initialize carousel options
   */
  public initializeCarouselOption() {
    this.numScroll = this.breakPointService.categoryBreakPoint.numScroll;
    this.numVisible = this.breakPointService.categoryBreakPoint.numVisible;
    this.responsiveOptions = this.breakPointService.responsiveCategoryOptions;
  }
}
