import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, OnDestroy, AfterContentChecked, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { UserInterest } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { DEFAULT_SUCCESS_MESSAGE, PROGRESSIVE_CALLED_COUNT } from '@shared/constants/app.constants';
import { MyLibraryList } from '@shared/data/list-base.model';
import { getDaysDiff, deepClone, focusElement, cdkVisualHidden, cdkRemoveVisualHidden } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
import { IProfileInfo, ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { KzInterestSurveyPopupComponent } from 'app/kz-interest-survey/kz-interest-survey-popup/kz-interest-survey-popup.component';
import { noop } from 'rxjs';
import { MyShelfListService } from '../../my-shelf/shared/services/my-shelf-list.service';
import { LIB_COMP_ENTITY_TYPES } from './../shared/constants/my-library.constants';
import { LibCompononent } from './../shared/data/library.model';
import { MyLibraryService } from './../shared/services/my-library.service';
import { PROFILE_ENABLED, PROFILE_FEATURES } from '@profile/shared/constants/profiles.constants';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'axis360-my-library',
  templateUrl: './my-library.component.html',
  styleUrls: ['./my-library.component.scss']
})
export class MyLibraryComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  tabsList = [
    { url: '/view/library', text: 'New' },
    { url: '/view/library', text: 'Trending' },
    { url: '/view/library', text: 'Surprise Me!' }
  ];
  myLists = [];
  componentsArr: LibCompononent[] = [];
  componentsFilterArr: LibCompononent[] = [];
  componentsTitleFiler: LibCompononent[] = [];
  LIB_COMP_ENTITY_TYPES = LIB_COMP_ENTITY_TYPES;
  isLoggedIn = false;
  showComponent = true;
  centerTab = true;
  showFeaturedProgram = false;
  showNewsPaperMagazine = false;
  currentProfile: IProfileInfo;
  featuredList: MyLibraryList;
  isLoading = true;
  hasError: boolean = false;
  noComponentsToShow = false;
  kidsZoneEnabled: boolean = false;
  profileTypeChecker: any;
  alwaysAvailableKzEnabled: boolean;
  parentRoute: string;
  showAlwaysAvailable = false;
  updataLibComponents: any;
  showIframe = false;
  called = PROGRESSIVE_CALLED_COUNT.PROGRESSIVE_INITIAL_CALLED;
  enableFilter: boolean = false;
  axis360Enabled: boolean = false;
  isPPCLibraryEnabled: boolean = false;
  myLibraryPage = 'My Library Page';
  constructor(
    public userService: UserService,
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    public myLibraryService: MyLibraryService,
    private homeService: HomeService,
    private matDialog: MatDialog,
    public commonService: CommonService,
    public homeAnalyticsService: HomeAnalyticsService,
    private liveannouncer: LiveAnnouncer,
    private titleService: Title,
    private myShelfListService: MyShelfListService,
    public breadcrumbService: BreadcrumbService,
    public cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userService.isInitialLoaded = false;
    document.title = 'Library';
    sessionStorage.setItem('home', 'Library');
    this.parentRoute = 'library';
    sessionStorage.setItem('defaultValues', undefined);
    sessionStorage.setItem('titleListFilters', JSON.stringify({}));
    sessionStorage.setItem('thirdparty', 'library');
    this.getLibraryComponents();
    this.titleService.setTitle('My Library');
    if (navigator.userAgent.toLowerCase().indexOf('firefox') <= -1) {
      this.showIframe = true;
    }
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
  }

  async getLibraryComponents() {
    try {
      this.homeAnalyticsService.trackScreen('MY_LIBRARY_PAGE');
      this.currentProfile = this.profileInfoService.currentProfile;
      this.isPPCLibraryEnabled = this.configService.currentLibrary.isPPCOnlyLibrary;
      this.showAlwaysAvailable = this.toCheckAlwaysAvailable();
      if (this.currentProfile.profileType !== '') {
        this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
        this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
        const profileType = this.currentProfile.profileType === 'Adult' ? 'teen' : this.currentProfile.profileType.toLowerCase();
        this.profileTypeChecker = this.configService.getProfileTypeFeatures(
          this.axis360Enabled ? this.currentProfile.profileType.toLocaleLowerCase() : profileType
        );
      }
      this.showFeaturedProgram =
        this.userService.isLoggedIn() &&
        this.configService.getProfileFeatures(this.profileInfoService.currentProfile.profileType.toLowerCase()).programsEnabled &&
        this.currentProfile.programsEnabled;
      const libData = await this.myLibraryService.getLibraryComponents();
      let libraryBannerComp, featuredReadingProgram, featuredList: LibCompononent;
      if (libData.message === DEFAULT_SUCCESS_MESSAGE) {
        libData.libraryComponents.components =
          (!this.showFeaturedProgram
            ? libData.libraryComponents.components.filter((frp) => frp.entityType !== LIB_COMP_ENTITY_TYPES.FEATURED_READING_PROGRAM)
            : libData.libraryComponents.components) || [];
        libData.libraryComponents.components =
          (libData.libraryComponents?.components.find((lib) => lib.entityType === LIB_COMP_ENTITY_TYPES.LIBRARY_BANNERS)?.components
            .length === 0
            ? libData.libraryComponents?.components.filter((frp) => frp.entityType !== LIB_COMP_ENTITY_TYPES.LIBRARY_BANNERS)
            : libData.libraryComponents?.components) || [];
        this.componentsArr = libData.libraryComponents.components.sort((a, b) => a.sequence - b.sequence);
        this.componentsTitleFiler = libData.libraryComponents.components.filter(
          (comp) => (comp.entityType === 'THIRD_PARTY' || comp.entityType === LIB_COMP_ENTITY_TYPES.WIDGETS_BANNERS) && comp.enabled
        );
        this.componentsFilterArr = libData.libraryComponents.components.sort((a, b) => a.sequence - b.sequence);
        this.componentsFilterArr.forEach((item) => (item.isShow = true));
        const filterLibComponents = this.componentsTitleFiler.filter(
          ({ entityId: entity_Id }) =>
            !(this.configService.vendorDetails || []).some(({ vendor_code: vendorcode }) => vendorcode === entity_Id)
        );
        this.updataLibComponents = this.componentsArr.filter(
          ({ entityId: entity_Id }) => !filterLibComponents.some(({ entityId: entity_id }) => entity_id === entity_Id)
        );
        this.alternateColorClass(this.updataLibComponents);
        this.componentsArr = this.configService.currentLibrary.isPPCOnlyLibrary
          ? this.updataLibComponents
          : this.componentsArr.slice(0, this.called);
        this.componentsFilterArr = this.configService.currentLibrary.isPPCOnlyLibrary
          ? this.updataLibComponents
          : this.componentsFilterArr.slice(0, this.called);
        libraryBannerComp = this.componentsArr.find((comp) => comp.entityType === 'LIBRARY_BANNERS') || { components: [] };
        featuredReadingProgram = this.componentsArr.find((comp) => comp.entityType === 'FEATURED_READING_PROGRAM');
        featuredList = this.componentsArr.find((comp) => comp.entityType === 'FEATURED_LIST');
        if (this.myShelfListService.selectedFormat !== 'all') {
          this.filterBasedonFormatType(this.myShelfListService.selectedFormat);
        }
      }
      if (libraryBannerComp.components.length == 0 && (!this.showFeaturedProgram || !featuredReadingProgram) && !featuredList) {
        this.noComponentsToShow = true;
      }
      this.isLoggedIn = this.userService.isLoggedIn();
      this.profileInfoService.isEditProfile = false;
      this.configService.setBanner(true);
      this.isLoading = false;
      setTimeout(() => {
        window.scrollTo(0, this.commonService.scrollPostion);
        focusElement('loc_headerId');
      }, 0);
    } catch (e) {
      this.componentsTitleFiler = [];
      this.isLoading = false;
      if (!this.userService.isLoggedIn()) {
        this.commonService.showErrorAlert();
        return;
      }
      this.hasError = true;
    }
  }

  /**
   * Bg color isvailable or not
   */
  alternateColorClass(updataLibComponents) {
    let isAdded = false;
    updataLibComponents?.map((elm, index: number) => {
      if (elm.entityType != 'FEATURED_READING_PROGRAM') {
        if (elm.entityType === 'WIDGETS_BANNERS' && updataLibComponents[index - 1]?.entityType === 'LIBRARY_BANNERS') {
          const bgClass = !isAdded ? 'theme-even' : 'theme-odd';
          elm['bgclass'] = bgClass;
          return;
        }
        elm['bgclass'] = isAdded ? 'theme-even' : 'theme-odd';
        isAdded = !isAdded;
      }
    });
  }

  /**
   * Refresh Featured list, always available and curated list carousels
   */
  refreshCarousels() {
    setTimeout(() => {
      this.enableFilter = false;
      this.showComponent = false;
    }, 500);
    this.filterBasedonFormatType(this.myShelfListService.selectedFormat);
    setTimeout(() => {
      this.showComponent = true;
      this.enableFilter = true;
    },1000);
  }

  filterBasedonFormatType(formatType: string) {
    let libraryBannerComp, featuredReadingProgram, libraryVendorList;
    const vendorDetailsList = this.configService.vendorDetails;
    const formatCode = formatType.toLowerCase();
    switch (formatType) {
      case 'EBT':
      case 'ABT':
        this.componentsArr = this.componentsFilterArr
          .filter((comp) => comp.entityType !== 'THIRD_PARTY' && comp.entityType !== LIB_COMP_ENTITY_TYPES.WIDGETS_BANNERS)
          .sort((a, b) => a.sequence - b.sequence);
        break;
      case 'VID':
      case 'VBK': {
        const templeteComponentsList = deepClone(this.componentsFilterArr);
        const updateVendorDetailsBasedOnFormatCode = vendorDetailsList.filter((vendorList) => vendorList.format_code === formatCode);
        libraryBannerComp = this.componentsFilterArr.filter((comp) => comp.entityType === 'LIBRARY_BANNERS');
        featuredReadingProgram = this.componentsFilterArr.filter((comp) => comp.entityType === 'FEATURED_READING_PROGRAM');
        libraryVendorList = templeteComponentsList
          .filter((comp) =>
            updateVendorDetailsBasedOnFormatCode.some(
              (vendorListComp) => comp.entityId === vendorListComp.vendor_code || comp.entityId === 'WIDGETS'
            )
          )
          .map((list) => {
            if (list.entityId === 'WIDGETS') {
              list.components = list.components
                .filter((compList) =>
                  updateVendorDetailsBasedOnFormatCode.some((vendorList) => compList.entityId === vendorList.vendor_code)
                )
                .map((updateCompList) => {
                  return updateCompList;
                });
            }
            return list;
          });
        this.componentsArr = [...featuredReadingProgram, ...libraryBannerComp, ...libraryVendorList].sort(
          (a, b) => a.sequence - b.sequence
        );
        break;
      }
      default:
        this.componentsArr = this.componentsFilterArr;
        break;
    }
  }

  /**
   * To get interest survey data from API and display popup
   */
  getInterestSurvey() {
    const axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    const queryParams = `?currentProfileID=${this.currentProfile.profileId}&currentprofileType=${
      !axis360Enabled && this.currentProfile.profileType === 'Adult' ? 'teen' : this.currentProfile.profileType.toLowerCase()
    }&currentProfileIsPrimary=${this.currentProfile.isPrimary}`;
    this.homeService.getKzInterests(queryParams).subscribe((interestRes) => {
      if (interestRes.statusCode === 200 && interestRes.message === DEFAULT_SUCCESS_MESSAGE) {
        this.showInterestSurvey(interestRes.userInterests) && this.openSurvey(interestRes.userInterests);
      }
    }, noop);
  }
  /**
   * To decide interest survey popup display
   * @param interestRes API response
   * @returns true if can show the popup
   */
  showInterestSurvey(interestRes: UserInterest): boolean {
    const { interests, modifiedDate, audience = [] } = interestRes;
    const selectedAlready = interests.some((interest) => interest.selected) || audience.length > 0;
    return !selectedAlready && (!modifiedDate || getDaysDiff(new Date(modifiedDate), new Date()) > 30);
  }
  /**
   * To opens survey popup
   * @param interestRes API response
   */
  openSurvey(interestRes: UserInterest) {
    const interestSurveyPopup = this.matDialog.open(KzInterestSurveyPopupComponent, {
      panelClass: 'kz-interest-popup-modal',
      autoFocus: true,
      data: {
        ...interestRes,
        profileType:
          !this.configService.currentLibrary.axis360Enabled && this.currentProfile.profileType === 'Adult'
            ? 'teen'
            : this.currentProfile.profileType.toLowerCase()
      },
      ariaLabelledBy: 'loc_txtSetyourReadingInterest'
    });
    interestSurveyPopup.afterClosed().subscribe(() => {
      this.titleService.setTitle('My Library');
      this.liveannouncer.announce('Library Page', 'assertive');
    });
    setTimeout(() => {
      const myInterests = document.getElementById('myInterestsCloseButton');
      myInterests?.setAttribute('tabindex', '0');
      myInterests && myInterests.focus();
    }, 2000);
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
    this.commonService.scrollPostion = window.scrollY;
    this.myLibraryService.titlesAvailable = true;
    this.breadcrumbService.set('@content', 'content');
    this.userService.isInitialLoaded = true;
  }
  refreshBgColor(removeElm: string) {
    const idList = this.componentsArr.map((elm) => `list_id_${elm.entityId}`);
    let isStartElm = false;
    idList.forEach((elm) => {
      if (elm === removeElm) {
        isStartElm = true;
      }
      isStartElm && this.isToggleClass(elm);
    });
  }
  isToggleClass(elmId: string) {
    let elmNode = document.getElementById(elmId);
    if (elmNode) {
      if (elmNode.classList.contains('theme-odd')) {
        elmNode.classList.remove('theme-odd');
        elmNode.classList.add('theme-even');
      } else if (elmNode.classList.contains('theme-even')) {
        elmNode.classList.remove('theme-even');
        elmNode.classList.add('theme-odd');
      }
    }
  }
  removeCuratedList(listId: string) {
    const div = document.getElementById(`list_id_${listId}`);
    if (div) {
      div.remove();
      this.refreshBgColor(`list_id_${listId}`);
    }
  }
  removeEmptyThirdParty(event: any) {
    if (event.count == 0) {
      const div = document.getElementById(`list_id_${event.entityId}`);
      if (div) {
        div.remove();
        this.refreshBgColor(`list_id_${event.entityId}`);
      }
    }
  }
  toCheckAlwaysAvailable() {
    const offerProfileType = {
      adult: PROFILE_ENABLED.ADULT_ENABLED,
      teen: PROFILE_ENABLED.TEEN_ENABLED,
      kid: PROFILE_ENABLED.KID_ENABLED
    };
    if (this.currentProfile.profileType !== '') {
      const isOfferProfileItemType = offerProfileType[this.currentProfile.profileType.toLowerCase()];
      this.showAlwaysAvailable =
        this.configService.currentLibrary.profileOfferItems?.find((ref) => ref.offerName === PROFILE_FEATURES.PAYPERCIRC)?.[
          isOfferProfileItemType
        ] || false;
      return this.showAlwaysAvailable;
    }
    this.showAlwaysAvailable =
      this.configService.currentLibrary.profileOfferItems?.find((ref) => ref.offerName === PROFILE_FEATURES.PAYPERCIRC)?.isAdultEnabled ||
      false;
    return this.showAlwaysAvailable;
  }
  progressiveEmit() {
    if (this.called === PROGRESSIVE_CALLED_COUNT.PROGRESSIVE_INITIAL_CALLED) {
      this.userService.isInitialLoaded = true;
      this.currentProfile.defaultLandingPageID !== '1' &&
        this.currentProfile.recommendationEnabled &&
        !this.profileInfoService.isEditProfile &&
        this.getInterestSurvey();
    }
    if (this.called <= this.updataLibComponents.length) {
      this.componentsArr = this.updataLibComponents.slice(0, this.called + PROGRESSIVE_CALLED_COUNT.PROGRESSIVE_CALLED);
      this.componentsFilterArr = this.updataLibComponents.slice(0, this.called + PROGRESSIVE_CALLED_COUNT.PROGRESSIVE_CALLED);
    }
    this.called++;

    if (this.called === this.updataLibComponents.length) {
      this.enableFilter = true;
      this.showIframe = true;
    }
    document.querySelectorAll('.carousel-block').forEach((elm, index) => {
      elm.classList.remove('theme-odd');
      elm.classList.remove('theme-even');
      if (index % 2) {
        elm.classList.add('theme-even');
      } else {
        elm.classList.add('theme-odd');
      }
    });
  }
}
