import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { PRESS_READER_PAGE_SIZE, PRESS_READER_SORT_OPTIONS } from '@shared/constants/app.constants';
import { Publications } from '@shared/data/press-reader-base.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PressReaderBaseService {
  localIp = sessionStorage.getItem('LOCAL_IP');

  constructor(public http: HttpClient) { }

  /**
   * To get magazines from press reader
   * @returns magazines info
   */
   getMagazines = (
    offset = 0,
    limit = PRESS_READER_PAGE_SIZE,
    orderBy = PRESS_READER_SORT_OPTIONS.LATEST_ISSUE_DATE.value,
    inVal = '',
    inVal1 = ''
  ): Observable<Publications> => {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<Publications>(
      API_INFO.pressReaderMagazines
        .replace('<offSet>', offset.toString())
        .replace('<limit>', limit.toString())
        .replace('<inVal>', inVal)
        .replace('<inVal1>', inVal1)
        .replace('<orderBy>', orderBy)
    );
  };

  /**
   * To get news papers from press reader
   * @returns news papers info
   */
   getNewsPaper = (
    offset = 0,
    limit = PRESS_READER_PAGE_SIZE,
    orderBy = PRESS_READER_SORT_OPTIONS.LATEST_ISSUE_DATE.value,
    inVal = '',
    inVal1 = ''
  ): Observable<Publications> => {
    const headers = new HttpHeaders();
    this.createClientIPHeader(headers);
    return this.http.get<Publications>(
      API_INFO.pressReaderNewsPaper
        .replace('<offSet>', offset.toString())
        .replace('<limit>', limit.toString())
        .replace('<inVal>', inVal)
        .replace('<inVal1>', inVal1)
        .replace('<orderBy>', orderBy),
      { headers }
    );
  };

  createClientIPHeader(headers: HttpHeaders) {
    headers.append('x-client-ip', this.localIp);
  }

}
