import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialog {
  url?: string;
}

@Component({
  selector: 'axis360-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public modaldata: AlertDialog, public alertDialog: MatDialogRef<VideoDialogComponent>) { }
}
