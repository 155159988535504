import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BadgesCarouselComponent } from '@home/badges/badges-carousel/badges-carousel.component';
import { BadgesComponent } from '@home/badges/badges.component';
import { FeaturedProgramCarouselComponent } from '@home/featured-program/featured-program-carousel/featured-program-carousel.component';
import { FeaturedProgramComponent } from '@home/featured-program/featured-program.component';
import { GoalsAndInsightsComponent } from '@home/goals-and-insights/goals-and-insights.component';
import { InsightsCardsCarouselComponent } from '@home/goals-and-insights/insights-cards-carousel/insights-cards-carousel.component';
import { SetGoalPopupComponent } from '@home/goals-and-insights/set-goal-popup/set-goal-popup.component';
import { HomeCarouselComponent } from '@home/home-carousel/home-carousel.component';
import { HomeMyLibraryListCardComponent } from '@home/home-my-library-list-card/home-my-library-list-card.component';
import { HomeMyLibraryListCarouselsComponent } from '@home/home-my-library-list-carousels/home-my-library-list-carousels.component';
import { HomeComponent } from '@home/home.component';
import { InterestSurveyPopupComponent } from '@home/interest-survey/interest-survey-popup/interest-survey-popup.component';
import { InterestSurveyComponent } from '@home/interest-survey/interest-survey.component';
import { NewspapersAndMagazinesComponent } from '@home/newspapers-and-magazines/newspapers-and-magazines.component';
import { MainComponent } from '@main/main.component';
import { NotificationIconComponent } from '@notifications/notification-icon/notification-icon.component';
import { NotificationsComponent } from '@notifications/notifications.component';
import { NotificationsAnalyticsService } from '@notifications/shared/services/notifications.analytics.service';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { PressReaderDetailsComponent } from '@pressreader/press-reader-details/press-reader-details.component';
import { PressReaderLandingComponent } from '@pressreader/press-reader-landing/press-reader-landing.component';
import { PressReaderListComponent } from '@pressreader/press-reader-list/press-reader-list.component';
import { PressReaderComponent } from '@pressreader/press-reader.component';
import { PressReaderCalenderHeaderComponent } from '@pressreader/shared/components/press-reader-calender-header/press-reader-calender-header.component';
import { PressReaderCardCarouselsComponent } from '@pressreader/shared/components/press-reader-card-carousels/press-reader-card-carousels.component';
import { PressReaderCardComponent } from '@pressreader/shared/components/press-reader-card/press-reader-card.component';
import { PressReaderCategoryCardsComponent } from '@pressreader/shared/components/press-reader-category-cards/press-reader-category-cards.component';
import { PressReaderCategoryCarouselComponent } from '@pressreader/shared/components/press-reader-category-carousel/press-reader-category-carousel.component';
import { ProgramDetailsComponent } from '@readingprograms/program-details/program-details.component';
import { ProgramsCardsComponent } from '@readingprograms/programs/programs-cards/programs-cards.component';
import { ProgramsComponent } from '@readingprograms/programs/programs.component';
import { JoinProgramPopupComponent } from '@readingprograms/programs/shared/components/join-program-popup/join-program-popup.component';
import { ProgramCardComponent } from '@readingprograms/programs/shared/components/program-card/program-card.component';
import { ProgramsCardImagesComponent } from '@readingprograms/programs/shared/components/programs-card-images/programs-card-images.component';
import { ProgramsTabsComponent } from '@readingprograms/programs/shared/components/programs-tabs/programs-tabs.component';
import { ProgramsTitleComponent } from '@readingprograms/programs/shared/components/programs-title/programs-title.component';
import { NewsResultsComponent } from '@search/news-results/news-results.component';
import { SearchResultsComponent } from '@search/search-results.component';
import { SearchResultsCardsComponent } from '@search/shared/components/search-results-cards/search-results-cards.component';
import { SearchtabsComponent } from '@search/shared/components/search-tabs/search-tabs.component';
import { TitleInfoCardComponent } from '@search/shared/components/title-info-card/title-info-card.component';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { SearchService } from '@search/shared/services/search.service';
import { SitesResultsComponent } from '@search/sites-results/sites-results.component';
import { RefinersComponent } from '@shared/components/refiners/refiners.component';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { TitleActionButtonsComponent } from '@shared/components/title-action-buttons/title-action-buttons.component';
import { TitleAndRefinersComponent } from '@shared/components/title-and-refiners/title-and-refiners.component';
import { APP_ELEM_TAGS } from '@shared/constants/app.constants';
import { documentToken, windowToken } from '@shared/constants/di-tokens';
import { AnalyticsService } from '@shared/services/analytics.service';
import { CommonService } from '@shared/services/common.service';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { SharedModule } from '@shared/shared.module';
import { CodeInputModule } from 'angular-code-input';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BudgetreachedComponent } from './core/home/shared/component/budgetreached/budgetreached.component';
import { AlwaysAvailableComponent } from './core/my-library/always-available/always-available.component';
import { AnnouncementsComponent } from './core/my-library/announcements/announcements.component';
import { BookOfMonthComponent } from './core/my-library/book-of-month/book-of-month.component';
import { CuratedListsComponent } from './core/my-library/curated-lists/curated-lists.component';
import { FeatureProgramComponent } from './core/my-library/feature-program/feature-program.component';
import { LearningActivitiesCarouselComponent } from './core/my-library/learning-activities/learning-activities-carousel/learning-activities-carousel.component';
import { LearningActivitiesComponent } from './core/my-library/learning-activities/learning-activities.component';
import { LibBannersComponent } from './core/my-library/lib-banners/lib-banners.component';
import { MyLibraryMainComponent } from './core/my-library/my-library-main.component';
import { NewspaperMagazineComponent } from './core/my-library/newspaper-magazine/newspaper-magazine.component';
import { CurrentlyCheckedOutComponent } from './core/my-shelf/currently-checked-out/currently-checked-out.component';
import { BadgesCardCarouselComponent } from './core/my-shelf/goals-insights-and-badges/badges/badges-card-carousel.component';
import { BadgesCardComponent } from './core/my-shelf/goals-insights-and-badges/badges/badges-card/badges-card.component';
import { GoalsInsightPopupComponent } from './core/my-shelf/goals-insights-and-badges/goals-insight-popup/goals-insight-popup.component';
import { GoalsInsightsAndBadgesComponent } from './core/my-shelf/goals-insights-and-badges/goals-insights-and-badges.component';
import { GoalsInsightsCardComponent } from './core/my-shelf/goals-insights-and-badges/goals-insights/goals-insights-card/goals-insights-card.component';
import { GoalsInsightsComponent } from './core/my-shelf/goals-insights-and-badges/goals-insights/goals-insights.component';
import { MyProgramsComponent } from './core/my-shelf/my-programs/my-programs.component';
import { MyShelfMainComponent } from './core/my-shelf/my-shelf.main.component';
import { RecommendationCarouselComponent } from './core/my-shelf/recommendation-carousel/recommendation-carousel.component';
import { MyShelfAnalyticsService } from './core/my-shelf/shared/services/my-shelf.analytics.service';
import { NgMainComponent } from './core/ng-main/ng-main.component';
import { PressReaderCalendarComponent } from './core/press-reader/shared/components/press-reader-calendar/press-reader-calendar.component';
import { PressReaderPublicationsComponent } from './core/search-results/shared/components/press-reader-publications/press-reader-publications.component';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { HeaderAnalyticsService } from './header/shared/services/header.analytics.service';
import { MyStuffModule } from './my-stuff/my-stuff.module';
import { OverlaySpinnerComponent } from './shared/components/overlay-spinner/overlay-spinner.component';
import { CarouselAdaDirective } from './shared/directive/carousel-ada.directive';
import { RemoveTabIndexDirective } from './shared/directive/remove-tab-index.directive';
import { KzProgramsModule } from './core/kz-reading-programs/kz-programs/kz-programs.module';
import { TitleDetailsComponent } from './core/title-details/title-details.component';
import { TitleDetailsActionsComponent } from './core/title-details/title-details-actions/title-details-actions.component';
import { TitleDetailsInfoComponent } from './core/title-details/title-details-info/title-details-info.component';
import { KzInterestSurveyComponent } from './kz-interest-survey/kz-interest-survey.component';
import { TitleListViewComponent } from './core/title-list/title-list-view/title-list-view.component';
import { SelectedRefinersComponent } from './core/title-list/shared/components/selected-refiners/selected-refiners.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { KzInterestSurveyPopupComponent } from './kz-interest-survey/kz-interest-survey-popup/kz-interest-survey-popup.component';
import { KzNotificationsComponent } from './core/kz-notifications/kz-notifications.component';
import { TitleDetailsActionButtonComponent } from './core/title-details/shared/components/title-details-action-button/title-details-action-button.component';
import { AccountComponent } from './core/account/account.component';
import { EmailUpdateComponent } from './core/account/email-update/email-update.component';
import { LoginComponent } from './core/account/login/login.component';
import { PasswordRecoveryComponent } from './core/account/password-recovery/password-recovery.component';
import { PasswordVerificationComponent } from './core/account/password-verification/password-verification.component';
import { RegisterationComponent } from './core/account/registeration/registeration.component';
import { TitleListRefinersComponent } from './core/title-list/title-list-refiners/title-list-refiners.component';
import { TitleRelatedItemsComponent } from '@titledetails/shared/components/title-related-items/title-related-items.component';
import { TitleRecommendationsComponent } from '@titledetails/shared/components/title-recommendations/title-recommendations.component';
import { TitleReviewComponent } from './core/title-details/shared/components/title-review/title-review.component';
import { ThirdPartyComponent } from './third-party/third-party.component';
import { ThirdPartyTierOneComponent } from './third-party/third-party-tier-one/third-party-tier-one.component';
import { ThirdPartyFeaturedComponent } from './third-party/shared/components/third-party-featured/third-party-featured.component';
import { ThirdPartyCategoriesComponent } from './third-party/shared/components/third-party-categories/third-party-categories.component';
import { ThirdPartyCategoriesCarouselComponent } from './third-party/shared/components/third-party-categories-carousel/third-party-categories-carousel.component';
import { ThirdPartyCategoryCardComponent } from './third-party/shared/third-party-category-card/third-party-category-card.component';
import { ThirdPartyTierTwoComponent } from './third-party/third-party-tier-two/third-party-tier-two.component';
import { KzSearchResultsComponent } from './core/kz-search-results/kz-search-results.component';
import { SearchRefinersComponent } from '@shared/components/search-refiners/search-refiners.component';
import { SearchViewComponent } from './core/kz-search-results/search-view/search-view.component';
import { KzActivityResourcesCardComponent } from './core/kz-search-results/kz-activity-resources-card/kz-activity-resources-card.component';
import { KzWebResourcesCardComponent } from './core/kz-search-results/kz-web-resources-card/kz-web-resources-card.component';
import { ThirdPartyBaseComponent } from './third-party/shared/components/third-party-base/third-party-base.component';
import { ThirdPartyRefinersComponent } from './third-party/third-party-refiners/third-party-refiners.component';
import { ThirdPartyTierThreeComponent } from './third-party/third-party-tier-three/third-party-tier-three.component';
import { ThirdPartyCalenderComponent } from './third-party/shared/components/third-party-calender/third-party-calender.component';
import { ThirdPartyCalenderHeaderComponent } from './third-party/shared/components/third-party-calender-header/third-party-calender-header.component';
import { TitleShareComponent } from './core/title-details/shared/components/title-share/title-share.component';
import { KzLearningActivitiesComponent } from './kz-learning-activities/kz-learning-activities.component';
import { KzWebResourcesCarouselComponent } from './core/kz-search-results/kz-web-resources-carousel/kz-web-resources-carousel.component';
import { KzSearchListViewComponent } from './core/kz-search-results/kz-search-list-view/kz-search-list-view.component';
import { KzLearningActivitiesCarouselComponent } from './kz-learning-activities/kz-learning-activities-carousel/kz-learning-activities-carousel.component';
import { ThirdPartyCarouselComponent } from './core/my-library/third-party-carousel/third-party-carousel.component';
import { ThirdPartyRefinerAcComponent } from './third-party/shared/components/third-party-refiner-ac/third-party-refiner-ac.component';
import { ThirdPartyCardsGridComponent } from './third-party/shared/components/third-party-cards-grid/third-party-cards-grid.component';
import { AccountAnalyticsService } from './core/account/shared/services/account.analytics.service';
import { TermsConditionsComponent } from './core/terms-conditions/terms-conditions.component';
import { ThirdPartyCarouselTypeComponent } from '@shared/components/third-party-carousel-type/third-party-carousel-type.component';
import { ThirdPartyCardDetailsComponent } from './third-party/third-party-card-details/third-party-card-details.component';
import { NoContentComponent } from './core/my-library/no-content/no-content.component';
import { CategoriesComponent } from '@shared/components/categories/categories.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { MyShelfComponent } from './core/my-shelf/my-shelf/my-shelf.component';
import { MyLibraryComponent } from './core/my-library/my-library/my-library.component';
import { KzSearchResultsMainComponent } from './core/kz-search-results/kz-search-results-main.component';
import { TitleListMainComponent } from './core/title-list/title-list-main.component';
import { TitleListComponent } from './core/title-list/titlle-list/title-list.component';
import { KzSearchListViewMainComponent } from './core/kz-search-results/kz-search-list-view-main/kz-search-list-view-main.component';
import { ThirdPartyWidgetsComponent } from './third-party/third-party-widgets/third-party-widgets.component';
import { ThirdPartyWidgetsCarouselComponent } from './third-party/shared/components/third-party-widgets-carousel/third-party-widgets-carousel.component';
import { ProfileModule } from '@profile/profile.module';
import { TitleDetailsSecondaryActionsComponent } from './core/title-details/shared/components/title-details-secondary-actions/title-details-secondary-actions.component';
import { ThirdPartyTierTwoMainComponent } from './third-party/third-party-tier-two-main/third-party-tier-two-main.component';
import { PatronMustLoginComponent } from './core/account/patron-must-login/patron-must-login.component';
import { ThirdPartyActionButtonsComponent } from './third-party/shared/components/third-party-action-buttons/third-party-action-buttons.component';
import { ThirdPartySecondaryActionsComponent } from './third-party/shared/components/third-party-secondary-actions/third-party-secondary-actions.component';
@NgModule({
  declarations: [
    AppComponent,
    SearchResultsComponent,
    SearchtabsComponent,
    TitleInfoCardComponent,
    NewsResultsComponent,
    SitesResultsComponent,
    RefinersComponent,
    TitleAndRefinersComponent,
    SearchResultsCardsComponent,
    SnackBarComponent,
    NotificationsComponent,
    ProgramsComponent,
    ProgramsTabsComponent,
    ProgramsTitleComponent,
    ProgramsCardsComponent,
    ProgramCardComponent,
    ProgramDetailsComponent,
    JoinProgramPopupComponent,
    ProgramsCardImagesComponent,
    MainComponent,
    NotificationIconComponent,
    TitleActionButtonsComponent,
    RemoveTabIndexDirective,
    CarouselAdaDirective,
    PressReaderComponent,
    PressReaderListComponent,
    PressReaderDetailsComponent,
    PressReaderLandingComponent,
    PressReaderCardComponent,
    PressReaderCardCarouselsComponent,
    HomeComponent,
    FeaturedProgramComponent,
    GoalsAndInsightsComponent,
    BadgesComponent,
    InterestSurveyComponent,
    SetGoalPopupComponent,
    PressReaderCategoryCarouselComponent,
    PressReaderCategoryCardsComponent,
    BadgesCarouselComponent,
    InterestSurveyPopupComponent,
    InsightsCardsCarouselComponent,
    FeaturedProgramCarouselComponent,
    PressReaderCalendarComponent,
    PressReaderCalenderHeaderComponent,
    OverlaySpinnerComponent,
    HomeMyLibraryListCarouselsComponent,
    HomeMyLibraryListCardComponent,
    NewspapersAndMagazinesComponent,
    HomeCarouselComponent,
    PressReaderPublicationsComponent,
    BudgetreachedComponent,
    MyShelfComponent,
    MyShelfMainComponent,
    GoalsInsightsAndBadgesComponent,
    GoalsInsightsComponent,
    CurrentlyCheckedOutComponent,
    MyProgramsComponent,
    NgMainComponent,
    GoalsInsightsCardComponent,
    BadgesCardComponent,
    GoalsInsightPopupComponent,
    BadgesCardCarouselComponent,
    RecommendationCarouselComponent,
    MyLibraryComponent,
    MyLibraryMainComponent,
    LibBannersComponent,
    AnnouncementsComponent,
    LearningActivitiesComponent,
    BookOfMonthComponent,
    FeatureProgramComponent,
    LearningActivitiesCarouselComponent,
    AlwaysAvailableComponent,
    CuratedListsComponent,
    NewspaperMagazineComponent,
    TitleDetailsComponent,
    TitleDetailsActionsComponent,
    TitleDetailsInfoComponent,
    KzInterestSurveyComponent,
    TitleListMainComponent,
    TitleListComponent,
    TitleListRefinersComponent,
    TitleListViewComponent,
    SelectedRefinersComponent,
    KzInterestSurveyPopupComponent,
    KzNotificationsComponent,
    TitleDetailsActionButtonComponent,
    LoginComponent,
    AccountComponent,
    RegisterationComponent,
    PasswordRecoveryComponent,
    PasswordVerificationComponent,
    EmailUpdateComponent,
    TitleRecommendationsComponent,
    TitleRelatedItemsComponent,
    TitleReviewComponent,
    ThirdPartyComponent,
    ThirdPartyTierOneComponent,
    ThirdPartyCategoriesComponent,
    ThirdPartyFeaturedComponent,
    ThirdPartyCategoriesCarouselComponent,
    ThirdPartyCategoryCardComponent,
    ThirdPartyTierTwoComponent,
    KzSearchResultsComponent,
    KzSearchListViewMainComponent,
    KzSearchResultsMainComponent,
    SearchRefinersComponent,
    SearchViewComponent,
    KzActivityResourcesCardComponent,
    KzWebResourcesCardComponent,
    ThirdPartyBaseComponent,
    ThirdPartyRefinersComponent,
    ThirdPartyTierThreeComponent,
    ThirdPartyCalenderComponent,
    ThirdPartyCalenderHeaderComponent,
    TitleShareComponent,
    KzLearningActivitiesComponent,
    KzWebResourcesCarouselComponent,
    KzSearchListViewComponent,
    KzLearningActivitiesCarouselComponent,
    ThirdPartyCarouselComponent,
    ThirdPartyRefinerAcComponent,
    ThirdPartyCardsGridComponent,
    TermsConditionsComponent,
    ThirdPartyCarouselTypeComponent,
    ThirdPartyCardDetailsComponent,
    NoContentComponent,
    CategoriesComponent,
    ThirdPartyWidgetsComponent,
    ThirdPartyWidgetsCarouselComponent,
    TitleDetailsSecondaryActionsComponent,
    TitleDetailsSecondaryActionsComponent,
    ThirdPartyTierTwoMainComponent,
    PatronMustLoginComponent,
    ThirdPartyActionButtonsComponent,
    ThirdPartySecondaryActionsComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    HttpClientModule,
    MatDialogModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatInputModule,
    A11yModule,
    OverlayModule,
    ClipboardModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatToolbarModule,
    CarouselModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CalendarModule,
    ProfileModule,
    CodeInputModule,
    IvyCarouselModule,
    MatSidenavModule,
    SharedModule,
    HeaderModule,
    FooterModule,
    MyStuffModule,
    KzProgramsModule,
    RadioButtonModule,
    BreadcrumbModule
  ],
  providers: [
    SearchService,
    SpinnerService,
    SnackBarService,
    InfiniteScrollService,
    AnalyticsService,
    SharedAnalyticsService,
    SearchAnalyticsService,
    SearchStorageService,
    NotificationsService,
    NotificationsAnalyticsService,
    HeaderAnalyticsService,
    AccountAnalyticsService,
    MyShelfAnalyticsService,
    CommonService,
    { provide: APP_BASE_HREF, useValue: '/ng' },
    { provide: windowToken, useValue: window },
    { provide: documentToken, useValue: document },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    DatePipe
  ],
  bootstrap: [],
  entryComponents: [NotificationIconComponent]
})
export class AppModule {
  /**
   * Creates an instance of app module.
   * @param injector injector
   */
  constructor(injector: Injector) {
    const notificationIconElem = createCustomElement(NotificationIconComponent, { injector });
    // Notification icon defined as web component to use in header
    customElements.define(APP_ELEM_TAGS.NOTIFICATION_ICON, notificationIconElem);
    const homeElem = createCustomElement(HomeComponent, { injector });
    // Home components defined as web component to use in user landing page
    customElements.define(APP_ELEM_TAGS.HOME, homeElem);
    const homeCarouelElem = createCustomElement(HomeCarouselComponent, { injector });
    // Home components defined as web component to use in user landing page wthout login
    customElements.define(APP_ELEM_TAGS.HOMECAROUSEL, homeCarouelElem);
  }
  /**
   * To do custom bootstrap the App component
   * @param appRef application ref
   */
  ngDoBootstrap(appRef: ApplicationRef) {
    if (document.getElementsByTagName(APP_ELEM_TAGS.APP_ROOT).length > 0) {
      appRef.bootstrap(AppComponent);
    }
  }
}
