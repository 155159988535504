import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LayoutComponent } from '@shared/data/third-party-model';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import {
  formatLatestIssues,
  getCheckArticleContentAndMediaData,
  getRefinerCount,
  updateDataApiItemForVideo,
  getMultipleVideoProgressBasedOnItemId,
  latestIssuesDates,
  formatDataAPILatestIssues,
  getPastCheckoutsByItemId
} from '../../helper/third-party-util';
import { ThirdPartyService } from '../../services/third-party.service';
import {
  ADDEDDATE,
  AVAILLIBRARY,
  FORMAT_TYPE_BY_VENDOR_CODE,
  GET_FILTER_VALUE,
  NEWS_FILTER,
  PROFILE_TYPES_UI_AUDIENCE_DESC,
  PUBLICATIONS_DATE,
  THIRD_PARTY_REFINER_ADDEDDATE_PILLS,
  THIRD_PARTY_REFINER_AVAILABLE_PILLS,
  THIRD_PARTY_REFINER_PUBLICATIONS_PILLS,
  THIRD_PARTY_SUBTYPE,
  THIRD_PARTY_SUBTYPE_KEY,
  THIRD_PARTY_VENDOR_CODE
} from '../../constants/third-party.constants';
import { GetNewsAPIRequest } from '@search/shared/data/news-model';
import { ActivatedRoute } from '@angular/router';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { ConfigService } from '@shared/services/config.service';
import { deepClone, focusElement } from '@shared/helper/app.util';
@Component({
  selector: 'axis360-third-party-cards-grid',
  templateUrl: './third-party-cards-grid.component.html',
  styleUrls: ['./third-party-cards-grid.component.scss']
})
export class ThirdPartyCardsGridComponent implements OnInit, OnDestroy {
  @Input() component: LayoutComponent;
  @Input() filtersCount = '';
  @Output() openFilterEvent = new EventEmitter();
  @Input() title: string;
  @Input() vendorCode: string;
  @Input() parentRoute: string;
  @Input() isSearchPageTier2 = false;
  @Input() isSearchPage = false;
  @Input() componentClass: string;
  @Input() thirdPartyClass: { divClass: string; cardsClass: string } = {
    divClass: 'third-party-tier-two-container',
    cardsClass: 'third-party-tier-two-card third-party-card-common'
  };
  thirdPartyVideoClass = '';
  initialQueryParams: {};
  componentData = [];
  centerTab = true;
  selectedOptions = {};
  countriesList;
  languagesList;
  tempDefaultValues = {};
  componentMetaData: { totalCount?: number } = {};
  tabsList = [{ text: 'All Fiction' }, { text: 'Publishing Date' }, { text: 'English' }];
  isLoading = true;
  updatePageNumber = false;
  itemsData;
  filterOpened = false;
  updateFilter = false;
  changeFilter = false;
  hideFilter = false;
  tierTwoRefinerChangeSubscription: Subscription;
  reloadDataAPISubscriptions: Subscription;
  getMultipleVideoProgressBasedOnItemId = getMultipleVideoProgressBasedOnItemId;
  getCheckArticleContentAndMediaData = getCheckArticleContentAndMediaData;
  getPastCheckoutsByItemId = getPastCheckoutsByItemId;
  formatLatestIssues = formatLatestIssues;
  focusElement = focusElement;
  constructor(
    private thirdPartyService: ThirdPartyService,
    public activeRoute: ActivatedRoute,
    private infiniteScroll: InfiniteScrollService,
    public enagageService: EngageBaseService,
    public userService: UserService,
    public configService: ConfigService,
    private profileInfoService: ProfileInfoService
  ) { }

  ngOnInit(): void {
    this.initialQueryParams = { ...this.component.dataApi.search };
    this.component.dataApi.search = {
      ...this.component.dataApi.search,
      inVal: this.configService.currentLibrary.countryCode,
      inVal1: this.configService.currentLibrary.languageCode
    };
    if (this.component.subType === THIRD_PARTY_SUBTYPE.ARTICLES) {
      this.component.dataApi.body = {
        ...this.component.dataApi.body,
        countries: [this.configService.currentLibrary.countryTxtCode],
        languages: [this.configService.currentLibrary.languageTxtCode]
      };
    }
    let onload = true;
    this.getData();
    this.thirdPartyService.getRefiner.subscribe((ref: any) => {
      if (ref) {
        if (
          (this.vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR && ref.defaultValues?.inVal && ref.defaultValues?.inVal !== '') ||
          (ref.defaultValues?.inVal1 && ref.defaultValues?.inVal1 !== '') ||
          (ref.defaultValues?.inVal2 && ref.defaultValues?.inVal2 !== '')
        ) {
          const { countryCode, languageCode } = this.configService.currentLibrary;
          const isRefiner =
            sessionStorage.getItem('refinerUpdated') !== 'undefined' ? JSON.parse(sessionStorage.getItem('refinerUpdated')) : false;
          if (!isRefiner) {
            this.selectedOptions = {};
            return;
          }
          const getdefaultValuesRefiners = deepClone(ref.defaultValues);
          const inValIds = getdefaultValuesRefiners.inVal ? getdefaultValuesRefiners.inVal.split(',') : [];
          const inVal1Ids = getdefaultValuesRefiners?.inVal1 ? getdefaultValuesRefiners?.inVal1?.split(',') : [];
          const inVal2Ids = getdefaultValuesRefiners?.inVal2 ? getdefaultValuesRefiners?.inVal2?.split(',') : [];
          // Filter countries whose IDs match the inVal IDs
          const countriesName = ref.countries
            .filter((country) =>
              inValIds
                .filter((countryRef) => (this.isSearchPageTier2 ? countryRef === countryCode.toString() : countryRef))
                .includes(country.id)
            )
            .map((countryMap) => countryMap.displayName);
          // Filter countries whose IDs match the inVal1 IDs
          const languagesName = ref.languages
            .filter((language) =>
              inVal1Ids
                .filter((languageRef) => (this.isSearchPageTier2 ? languageRef === languageCode.toString() : languageRef))
                .includes(language.id)
            )
            .map((languageMap) => languageMap.displayName);
          if (getdefaultValuesRefiners?.inVal2) {
            const categoriesName = ref.categories
              .filter((category) => inVal2Ids.filter((categoryRef) => categoryRef).includes(category.id))
              .map((categoryMap) => categoryMap.displayName);
            getdefaultValuesRefiners.inVal2 = categoriesName;
          }
          getdefaultValuesRefiners.inVal = countriesName;
          getdefaultValuesRefiners.inVal1 = languagesName;
          this.selectedOptions = this.formatObjectSelected(getdefaultValuesRefiners);
          return;
        }
        const defaultValues = ref.defaultValues?.tp && ref.defaultValues.tp !== '' ? ref.defaultValues : ref;
        this.selectedOptions = this.formatObjectSelected(defaultValues);
      }
    });
    this.thirdPartyService.tierTwoRefinerReset.subscribe((response: boolean) => {
      if (response) {
        this.selectedOptions = {
          inVal: [this.configService.currentLibrary.countryCode],
          inVal1: [this.configService.currentLibrary.languageCode],
          orderBy: ['rank']
        };
      }
    });
    this.infiniteScroll.subscribe(this.loadMore);
    this.reloadDataAPISubscriptions = this.thirdPartyService.getReloadDataAPI().subscribe((item) => {
      if (
        item &&
        (this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS ||
          this.component.subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS ||
          this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST)
      ) {
        this.updatePageNumber = true;
        this.isLoading = true;
        const componentMetaDataTemp = this.componentMetaData;
        const componentDataTemp = (this.componentData || []).map((itemList) => {
          if (
            (itemList.titleID === item.ItemId && itemList.formatType === item.formatType) ||
            (itemList.itemId === item.ItemId && itemList.format === item.formatType)
          ) {
            itemList.isCheckout = 'Y';
            itemList.isCheckedout = 'true';
          }
          return itemList;
        });
        this.componentData = [];
        this.componentMetaData = {};
        this.componentData = deepClone(componentDataTemp);
        this.componentMetaData = componentMetaDataTemp;
        this.componentData.length > 0 && (this.isLoading = false);
      }
    });
    this.tierTwoRefinerChangeSubscription = this.thirdPartyService.tierTwoRefinerChange.subscribe((data) => {
      if (!data) {
        onload = false;
        return;
      }
      const tp = 'tp';
      this.updateFilter = true;
      this.changeFilter = true;
      const defaultValue = {
        inVal1: 'inVal1',
        inVal: 'inVal'
      };
      if (data && (!data[defaultValue.inVal1] || !data[defaultValue.inVal]) && this.vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
        data[defaultValue.inVal] = !data[defaultValue.inVal] ? '' : data[defaultValue.inVal];
        data[defaultValue.inVal1] = !data[defaultValue.inVal1] ? '' : data[defaultValue.inVal1];
      }
      if (data[tp]) {
        data[tp] = data[tp] === 'all' ? '' : data[tp];
      }
      if (this.component.subType === THIRD_PARTY_SUBTYPE.ARTICLES) {
        const payload = this.getNewsAPIRequest(data);
        this.component.dataApi.body = payload;
      }
      if (this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS || this.component.subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST) {
        this.filtersCount = getRefinerCount(data, false);
        /* tslint:disable */
        const { q, searchby, searchType, title, code } = this.activeRoute.snapshot.queryParams;
        const sortByOptionIsSelected = this.thirdPartyService.sortByOptionIsSelected['searhSort'];
        const { audiencedesc, bisacsdesc, languagedesc, ...rest } = data || ({} as any);
        !audiencedesc && (this.changeFilter = false);
        this.component.dataApi.search = {
          ...this.component.dataApi.search,
          searhSort: sortByOptionIsSelected ?? 'score',
          pageNumber: 0,
          audiencedesc,
          bisacsdesc: bisacsdesc ? bisacsdesc : '',
          languagedesc: languagedesc ? languagedesc : '',
          ...rest
        };
        var isSearchTerms = '';
        var isSearchType = searchType;
        (code || q) && (
          isSearchTerms = q !== '' ? q : (code !== 'NA' ? title : ''),
          isSearchType = searchby ? searchby : searchType
        );
        !bisacsdesc &&
          (this.component.dataApi.search = {
            ...this.component.dataApi.search,
            searchTerm: searchby === 'series' ? '' : isSearchTerms,
            searchType: searchby === 'all' ? 'term' : isSearchType,
          });
        (!rest?.pubDateFilter || rest?.pubDateFilter === 'All') &&
          (this.component.dataApi.search = {
            ...this.component.dataApi.search,
            pubDateFilter: '',
          });
        (!rest?.dateFilter || rest?.dateFilter === 'All') &&
          (this.component.dataApi.search = {
            ...this.component.dataApi.search,
            dateFilter: '',
          });
        !rest?.availlibrary &&
          (this.component.dataApi.search = {
            ...this.component.dataApi.search,
            availlibrary: '',
          });
        this.itemsData = [];
        this.componentData = [];
        this.componentMetaData = {};
        !onload && this.getData();
        return;
      }
      this.filtersCount = getRefinerCount(data);
      /* tslint:disable */
      const sortByOptionIsOrderBy = this.thirdPartyService.sortByOptionIsSelected['orderBy'];
      this.component.dataApi = {
        ...this.component.dataApi,
        search: {
          ...this.component.dataApi.search,
          ...this.initialQueryParams,
          ...data,
          orderBy: sortByOptionIsOrderBy ?? 'searchrank'
        }
      };
      this.componentData = [];
      this.componentMetaData = {};
      this.getData();
    });
    if (this.activeRoute.snapshot.queryParams.title === 'Latest Issues') {
      this.hideFilter = true;
    }
    setTimeout(() => {
      document.getElementById('searchResultsThirdParty')?.classList.add('hidden');
    }, 1000);
  }
  ngOnDestroy(): void {
    if (this.tierTwoRefinerChangeSubscription) {
      this.thirdPartyService.tierTwoRefinerChange.next(undefined);
      this.tierTwoRefinerChangeSubscription.unsubscribe();
    }
    if (this.reloadDataAPISubscriptions) {
      this.thirdPartyService.setReloadDataAPI(false);
      this.reloadDataAPISubscriptions.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    const matchResult = /(Android|iPhone|iPod|iPad)/i.exec(navigator.userAgent) || (navigator.userAgent.includes('Mac') && "ontouchend" in document);
    matchResult ? this.focusElement('loc_btnHamburgerMenu') : this.focusElement('loc_headerId');
  }
  getDataFromApi() {
    const {
      tp = '',
      has,
      q,
      id,
      title,
      searchType = 'term',
      searchby,
      searchTerm = '',
      series,
      vendor_code = '',
      collectionId = '',
      code,
      groups,
      topics
    } = this.activeRoute.snapshot.queryParams;
    const { userService, profileInfoService, configService } = this;
    const { subType, dataApi } = this.component as any;
    const { currentProfile } = profileInfoService;
    const { currentLibrary, vendorDetails } = configService;
    const selectedVendorList = (vendorDetails || []).find((x) => x.vendor_code === vendor_code);
    const vendorSubscription = configService.getSubscriptionData((this.vendorCode || vendor_code).toLocaleLowerCase());
    // Update collection ID based on library settings
    const updateCollectionId = Object.keys(dataApi.library_settings || {}).reduce((acc, key) => {
      acc[key] = vendorSubscription[dataApi.library_settings[key]];
      return acc;
    }, {});
    const formatMap = {
      [THIRD_PARTY_SUBTYPE.LATEST_ISSUES]: latestIssuesDates
    };
    const formatFunc = formatMap[this.component.subType];
    switch (subType) {
      case THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS:
        /* tslint:disable */
        this.updatePageNumber && (dataApi.search = { ...dataApi.search, pageNumber: 0 });
        dataApi.body = {
          ...dataApi.body,
          LibraryId: collectionId,
          PatronGUID: userService.getPatronId(),
          Books: [{ ItemId: id }],
          Filters: { Format: FORMAT_TYPE_BY_VENDOR_CODE[selectedVendorList.format_code] }
        };
        dataApi.headers = {
          ...dataApi.headers,
          patronid: userService.getPatronId(),
          profileid: currentProfile.profileId || ''
        };
        break;
      case THIRD_PARTY_SUBTYPE.LATEST_ISSUES:
        {
          this.thirdPartyService.setLatestIssueData.subscribe((res) => {
            if (res) {
              dataApi.country = res.country;
              dataApi.type = res.type;
              dataApi.displayName = res.displayName;
            }
          });
          dataApi.pathParams = { id };
          const { fromDate, toDate }: { fromDate?: Date; toDate?: Date } = formatFunc(
            dataApi,
            this.activeRoute.snapshot.queryParams.issueDate
          );
          dataApi.search = {
            ...dataApi.search,
            ...formatDataAPILatestIssues({ fromDate, toDate })
          };
          break;
        }
      case THIRD_PARTY_SUBTYPE.ARTICLES:
        dataApi.body = { ...dataApi.body, query: q };
        break;
      case THIRD_PARTY_SUBTYPE.VIDEOS:
      case THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST:
        if (!this.changeFilter) {
          const { contentCollectionId } = updateCollectionId as { contentCollectionId: string };
          const defaultSelected =
            sessionStorage.getItem('defaultValues') !== 'undefined' ? JSON.parse(sessionStorage.getItem('defaultValues')) : null;
          const { audiencedesc, ...selectedList } = defaultSelected || {};
          if (defaultSelected) {
            dataApi.search = {
              ...dataApi.search,
              contentCollectionId,
              audiencedesc,
              ...selectedList
            };
          }
          const isZoneEnabled = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
            ? 'teen'
            : 'adult';
          const isZoneEnabledAudience = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
            ? 'teen,children'
            : '';
          const isProfileTypeByAudience = currentProfile.profileType.toLowerCase() === 'adult'
            ? isZoneEnabled
            : currentProfile.profileType.toLowerCase();
          !audiencedesc &&
            (dataApi.search = {
              ...dataApi.search,
              contentCollectionId,
              audiencedesc: currentProfile.profileType
                ? PROFILE_TYPES_UI_AUDIENCE_DESC[isProfileTypeByAudience]
                : isZoneEnabledAudience,
              pageNumber: this.updatePageNumber ? 0 : dataApi.search.pageNumber
            });
          dataApi.headers = {
            ...dataApi.headers,
            patronid: this.userService.getPatronId(),
            profileid: currentProfile.profileId || ''
          };
          q &&
            (dataApi.search = {
              ...dataApi.search,
              groups,
              topics,
              contentCollectionId,
              searchTerm: searchby === 'series' ? '' : q,
              searchType: searchby === 'all' ? 'term' : searchby,
              series: searchby === 'series' ? q : ''
            });
          (!defaultSelected?.pubDateFilter || defaultSelected?.pubDateFilter === 'All') &&
            (dataApi.search = {
              ...dataApi.search,
              pubDateFilter: '',
            });
          (!defaultSelected?.dateFilter || defaultSelected?.dateFilter === 'All') &&
            (dataApi.search = {
              ...dataApi.search,
              dateFilter: '',
            });
          !defaultSelected?.availlibrary &&
            (dataApi.search = {
              ...dataApi.search,
              availlibrary: '',
            });
          series && (dataApi.search = { ...dataApi.search, series: series, searchType });
          const seriesType =
            searchType === 'series' && searchTerm ? { series: searchTerm, searchTerm: '' } : { searchTerm: searchTerm, series: '' };
          !q &&
            !series &&
            (dataApi.search = {
              ...dataApi.search,
              groups,
              topics,
              contentCollectionId,
              searchTerm: '',
              searchType,
              ...seriesType
            });
          // Categories pass queryParams
          !q &&
            code &&
            vendor_code === selectedVendorList.vendor_code &&
            (dataApi.search =
              searchType === 'series'
                ? { ...dataApi.search, contentCollectionId, series: title, searchType, searchTerm: '' }
                : { ...dataApi.search, contentCollectionId, searchTerm: title, searchType });
        }
        break;
      default:
        break;
    }

    if (!this.updateFilter) {
      const defaultSelected =
        sessionStorage.getItem('defaultValues') !== 'undefined' ? JSON.parse(sessionStorage.getItem('defaultValues')) : null;
      const defaultValues = {
        inVal: 'inVal',
        inVal1: 'inVal1'
      };
      defaultSelected &&
        Object.keys(defaultSelected).forEach((key) => {
          if (
            (key.includes(defaultValues.inVal) || key.includes(defaultValues.inVal1)) &&
            (defaultSelected[defaultValues.inVal] === '' || defaultSelected[defaultValues.inVal1] === '')
          ) {
            delete defaultSelected[key];
          }
        });
      const { ...selectedList } = defaultSelected || {};

      if (defaultSelected && this.component.subType === THIRD_PARTY_SUBTYPE.PUBLICATIONS_SEARCH_LIST) {
        const defaultValues = {
          inVal: 'inVal',
          inVal1: 'inVal1'
        };
        if (selectedList[tp]) {
          selectedList[defaultValues.inVal] = this.configService.currentLibrary.countryCode.toString();
          selectedList[defaultValues.inVal1] = this.configService.currentLibrary.languageCode.toString();
        }
      }

      if (defaultSelected) {
        const searchParams = {
          ...dataApi.search,
          ...selectedList,
          tp,
          has,
          q
        };
        if (tp !== '') {
          dataApi.search = searchParams;
        } else {
          (defaultSelected.tp === 'all')
            ? (dataApi.search = { ...searchParams, tp: '', })
            : (dataApi.search = {
              ...dataApi.search,
              ...selectedList,
              has,
              q
            });
        }
        if (this.vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
          return this.thirdPartyService.getDataFromDataApi(dataApi);
        }
        this.selectedOptions = this.formatObjectSelected(defaultSelected);
        return this.thirdPartyService.getDataFromDataApi(dataApi);
      }
      dataApi.search = { ...dataApi.search, tp, has, q };
    }
    return this.thirdPartyService.getDataFromDataApi(dataApi);
  }

  /**
   *
   * @param [data] Pass the fomatted data
   * @returns to get Fomatted result on objectOfArray
   */
  formatObjectSelected(data: any) {
    const result = {};
    if (data['tp']) {
      data['tp'] = data['tp'] && data['tp'] === 'all' ? 'All' : data['tp'];
    }
    for (const key in data) {
      if (data[key] !== '' && data.hasOwnProperty(key)) {
        result[key] = typeof data[key] === 'object' ? data[key] : [data[key]];
      }
      if (key === ADDEDDATE || key === PUBLICATIONS_DATE || key === AVAILLIBRARY) {
        switch (key) {
          case ADDEDDATE:
            result[key] = [THIRD_PARTY_REFINER_ADDEDDATE_PILLS[data[key]]]
            break;
          case PUBLICATIONS_DATE:
            result[key] = [THIRD_PARTY_REFINER_PUBLICATIONS_PILLS[data[key]]]
            break;
          case AVAILLIBRARY:
            result[key] = [THIRD_PARTY_REFINER_AVAILABLE_PILLS[data[key]]]
            break;
          default:
            break;
        }
      }
    }
    return result;
  }

  /**
   * Gets filtered contries ids or languages ids
   * @param type type to idendify it is country or language
   * @param data type to idendify it is country or language
   * @returns filtered ids
   */
  getFilteredContriesOrLanguages(type: string, data: string) {
    const list = data.split(',');
    const typeMap = this.thirdPartyService.filterObject[type];
    const filterContriesOrLanguages = list.map((x) => {
      const availableValues = typeMap.find((filter) => filter.id === x);
      return availableValues?.iso;
    });
    return filterContriesOrLanguages;
  }

  /**
   * To get news API request
   * @param [defaultValues] defaultValues in {}
   * @param [languages] languages filter
   * @param [countries] countries filter
   * @returns news API request
   */
  getNewsAPIRequest(defaultValues): GetNewsAPIRequest {
    const { q } = this.activeRoute.snapshot.queryParams;
    return {
      query: q,
      countries: this.getFilteredContriesOrLanguages(NEWS_FILTER.COUNTRY, defaultValues.inVal),
      languages: this.getFilteredContriesOrLanguages(NEWS_FILTER.LANGUAGE, defaultValues.inVal1)
    };
  }

  formatData(data: any) {
    const formatMap = {
      [THIRD_PARTY_SUBTYPE.LATEST_ISSUES]: this.formatLatestIssues,
      [THIRD_PARTY_SUBTYPE.VIDEOS]: updateDataApiItemForVideo,
      [THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST]: updateDataApiItemForVideo
    };
    const formatFunc = formatMap[this.component.subType];
    if (formatFunc) {
      return formatFunc(data);
    }
    return data;
  }

  async getData() {
    const { profileInfoService, component } = this;
    const { subType } = component;
    const { currentProfile } = profileInfoService;
    try {
      this.isLoading = true;
      const data: any = await this.getDataFromApi();
      if (subType === THIRD_PARTY_SUBTYPE.ARTICLES) {
        data.items.map(art => {
          const {
            publication: { title: publishedTitle, countries = [], language: publishedLanguage } = { title: '', language: '' },
            article: { date, title: articleTitle, author }
          } = art;
          const publication = publishedTitle ? `Publication Title ${publishedTitle}` : 'Publication Title none';
          const publicationDate = date ? `Date ${this.formatDate(date)}` : 'Date none';
          const article = articleTitle ? `Article Title ${articleTitle}` : 'Article Title none';
          const articleAuthor = author ? `Author ${author}` : 'Author none';
          const country = countries[0] ? `Country ${countries[0]}` : 'Country none';
          const languageInfo = publishedLanguage ? `in ${publishedLanguage}` : '';
          const countryInfo = countries[0] ? `from ${countries[0]}` : '';
          const articleAltLabel = `${publication}, ${publicationDate}, ${article}, ${articleAuthor}, ${country}, ${languageInfo} ${countryInfo}`;
          art.article.altLabel = articleAltLabel;
          return art;
        });
        data.items = this.getCheckArticleContentAndMediaData(data.items);
      }
      if (
        subType === THIRD_PARTY_SUBTYPE.VIDEOS ||
        subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS ||
        subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST
      ) {
        this.thirdPartyVideoClass = '  third-party-card-checkout-videos';
        this.updatePageNumber = false;
        const rooKey = THIRD_PARTY_SUBTYPE_KEY[subType || THIRD_PARTY_SUBTYPE.ITEMS];
        this.itemsData = this.formatData(rooKey ? data[rooKey] : data);
        this.itemsData = currentProfile.profileId ? await this.getPastCheckoutsByItemId.apply(this) : this.itemsData;
        (subType === THIRD_PARTY_SUBTYPE.VIDEOS || subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST) &&
          this.itemsData.map((items: any) => {
            items.vendorCode = this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code;
            const bookStatus = 'Book Status ' + items.displayStatus + ',';
            const bookDuration = items.runTime !== '' ? ', Duration ' + this.replaceTimeStrings(items.runTime) : '';
            const bookAltTitle = `Format ${items.formatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${items.bookTitle}, Author ${items?.authors?.author[0]}${bookDuration}`;
            items.altLabel = `${(items.displayStatus && items.displayStatus !== '') ? bookStatus : ''} ${bookAltTitle}`
            items.bookTitle = items.bookTitle.replace(/<[^<>]*?>/g, '');
            items.thirdPartyVideoClass =
              (items.displayStatus && items.displayStatus !== '')
                ? 'third-party-card-checkout-videos third-party-card-title-banner'
                : 'third-party-card-checkout-videos';
            return items;
          });
        subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS &&
          this.itemsData.map((items: any) => {
            const bookStatus = 'Book Status ' + items.displayStatus + ',';
            const bookDuration = (items.runTime && items.runTime !== '') ? ', Duration ' + this.replaceTimeStrings(items.runTime) : '';
            const bookAltTitle = `Format ${items.formatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${items.bookTitle || items.title}, Author ${items?.authors?.author[0] || items?.authors || items?.author}${bookDuration}`;
            items.altLabel = `${(items.displayStatus && items.displayStatus !== '') ? bookStatus : ''} ${bookAltTitle}`
            items.thirdPartyVideoClass = (items.displayStatus && items.displayStatus !== '')
              ? 'third-party-card-checkout-videos third-party-card-title-banner'
              : 'third-party-card-checkout-videos';
            return items;
        });
        const count =
          subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS
            ? Number(data.totalCount)
            : Number(data[rooKey].searchTitleResult.resultCount) || 0;
        this.componentData = this.componentData.concat(this.itemsData);
        this.componentMetaData = { totalCount: count };
        if (this.thirdPartyService.isPillsEmptyTitleFoucs) {
          const id = this.componentData[0].titleID;
          setTimeout(() => {
            const thirdPartyTitleFoucs = document.getElementById(`loc_title_${id}`);
            thirdPartyTitleFoucs && thirdPartyTitleFoucs.focus();
          }, 1000);
          this.thirdPartyService.isPillsEmptyTitleFoucs = false;
        }
        this.thirdPartyService.tierTwoRefinerSearch.subscribe((response: string) => {
          if (this.componentMetaData?.totalCount >= 0 && response == 'search') {
            const searchbtn: HTMLElement = document.getElementById('tier2-heading');
            const matchResult = /(iPhone|iPod|iPad)/i.exec(navigator.userAgent);
            if (matchResult) {
              searchbtn?.setAttribute('tabindex', '-1');
              searchbtn?.removeAttribute('aria-hidden');
            } else {
              searchbtn?.removeAttribute('tabindex');
            }
            searchbtn && searchbtn.focus();
            this.thirdPartyService.tierTwoRefinerSearch.next('');
          }
        });
        this.isLoading = false;
        return;
      }
      if (subType === THIRD_PARTY_SUBTYPE.LATEST_ISSUES) {
        const rooKey = THIRD_PARTY_SUBTYPE_KEY[subType || THIRD_PARTY_SUBTYPE.ITEMS];
        const itemsData = this.formatData(rooKey ? data[rooKey] : data);
        this.componentData = this.componentData.concat(itemsData);
        this.componentData.map((item) => {
          item.countrySlug = item.country;
          const date = new Date(item.issueDate);
          const options: Intl.DateTimeFormatOptions = {
            month: 'long', // Abbreviated month name (e.g., "February ")
            day: '2-digit', // Two-digit day of the month (e.g., "26")
            year: 'numeric' // Full numeric year (e.g., "2024")
          };
          let title = 'Published in '
          const multicountry = 'and multiple countries'
          if (item?.countrySlug?.split('| ').length > 1) {
            title = title + item.countrySlug?.split('| ')[0] + multicountry;
          } else {
            title = title + item?.countrySlug?.split('| ')[0];
          }
          const formattedDate = new Intl.DateTimeFormat('en-US', options)?.format(date);
          const bookAltTitle = `${title}, Book title ${item.displayName}, Type ${item.type}`;
          item.altLabel = formattedDate ? `${title}, Book title ${item.displayName}, Type ${item.type}, issue On ${formattedDate}` : bookAltTitle;
          return item;
        });
        this.componentMetaData = { totalCount: data?.length };
        this.isLoading = false;
        return;
      }
      this.componentData = this.componentData.concat(data.items);
      this.componentMetaData = data.meta;
      if (this.thirdPartyService.isPillsEmptyTitleFoucs) {
        const id = this.componentData[0].id;
        setTimeout(() => {
          const thirdPartyTitleFoucs = document.getElementById(`loc_title_${id}`);
          thirdPartyTitleFoucs.focus();
        }, 1000);
        this.thirdPartyService.isPillsEmptyTitleFoucs = false;
      }
      if (subType !== THIRD_PARTY_SUBTYPE.ARTICLES) {
        this.componentData = this.componentData.map(item => {
          const date = new Date(item.latestIssue.issueDate);
          const options: Intl.DateTimeFormatOptions = {
            month: 'long', // Abbreviated month name (e.g., "February ")
            day: '2-digit', // Two-digit day of the month (e.g., "26")
            year: 'numeric' // Full numeric year (e.g., "2024")
          };
          let title = 'Published in '
          const multicountry = 'and multiple countries'
          if (item?.countrySlug?.split('| ').length > 1) {
            title = title + item.countrySlug?.split('| ')[0] + multicountry;
          } else {
            title = title + item?.countrySlug?.split('| ')[0];
          }
          const formattedDate = new Intl.DateTimeFormat('en-US', options)?.format(date);
          const bookAltTitle = `${title}, Book title ${item.displayName}, Type ${item.type}`;
          item.altLabel = formattedDate ? `${title}, Book title ${item.displayName}, Type ${item.type}, issue On ${formattedDate}` : bookAltTitle;
          return item;
        });
      }

      this.thirdPartyService.tierTwoRefinerSearch.subscribe((response: string) => {
        if (this.componentMetaData?.totalCount >= 0 && response == 'search') {
          const searchbtn: HTMLElement = document.getElementById('tier2-heading');
          searchbtn.removeAttribute('aria-hidden');
          searchbtn && searchbtn.focus();
          this.thirdPartyService.tierTwoRefinerSearch.next('');
        }
      });
      this.isLoading = false;
    } catch (e) {
      this.componentMetaData = { totalCount: 0 };
      this.isLoading = false;
    }
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const month = date.getMonth() + 1; // getMonth() returns 0-based month index
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  loadMore = async () => {
    const { component, componentMetaData } = this;
    const { subType, dataApi } = component;
    if (!(componentMetaData.totalCount > this.componentData.length)) {
      return;
    }
    if (
      subType === THIRD_PARTY_SUBTYPE.VIDEOS ||
      subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS ||
      subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST
    ) {
      const { pageNumber }: { pageSize?: string; pageNumber?: string } = dataApi.search || {};
      this.component.dataApi = {
        ...dataApi,
        search: { ...dataApi.search, pageNumber: Number(pageNumber) + 1 }
      };
      this.getData();
      return;
    }
    const { offset: cOffset, limit: cLimit }: { offset?: number; limit?: number } = dataApi.search || {};
    this.component.dataApi = {
      ...dataApi,
      search: { ...dataApi.search, offset: Number(cOffset) + Number(cLimit) }
    };
    this.getData();
  };

  removeSelection(option) {
    if (option.key === 'ClearAll') {
      this.selectedOptions = {};
      sessionStorage.setItem('defaultValues', JSON.stringify(this.thirdPartyService.tempRefiners));
      sessionStorage.setItem('refinerUpdated', JSON.stringify(false));
      this.thirdPartyService.updateRef$.next(this.thirdPartyService.tempRefiners);
      this.thirdPartyService.isPillsEmptyTitleFoucs = true;
      this.thirdPartyService.tierTwoRefinerReset.next(true);
    } else {
      if (option.key === 'sortBy') {
      } else {
        if (this.vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
          if (option.key === 'tp') {
            this.selectedOptions[option.key].splice(this.selectedOptions[option.key].indexOf(option.value), 1);
            this.selectedOptions = { ...this.selectedOptions };
            const defaultSelected =
              sessionStorage.getItem('defaultValues') !== 'undefined' ? JSON.parse(sessionStorage.getItem('defaultValues')) : null;
            const updateDefaultSelected = {
              ...defaultSelected,
              tp: 'all'
            };
            sessionStorage.setItem('defaultValues', JSON.stringify(updateDefaultSelected));
            sessionStorage.setItem('refinerUpdated', JSON.stringify(true));
            this.thirdPartyService.updateRef$.next(updateDefaultSelected);
            return;
          }
          const removeLists = this.thirdPartyService.filterObject[GET_FILTER_VALUE[option.key]]
            .filter((country) => country.displayName === option.value) // Filter countries whose DisplayName match the inVal DisplayName
            .map((country) => country.id);
          this.selectedOptions[option.key].splice(this.selectedOptions[option.key].indexOf(option.value), 1);
          this.selectedOptions = { ...this.selectedOptions };
          const defaultSelected =
            sessionStorage.getItem('defaultValues') !== 'undefined' ? JSON.parse(sessionStorage.getItem('defaultValues')) : null;
          const removeDefaultSelected = {
            ...defaultSelected,
            inVal: defaultSelected.inVal ? defaultSelected.inVal.split(',') : [],
            inVal1: defaultSelected.inVal1 ? defaultSelected.inVal1.split(',') : [],
            inVal2: defaultSelected.inVal2 ? defaultSelected.inVal2.split(',') : []
          };
          removeDefaultSelected[option.key].splice(removeDefaultSelected[option.key].indexOf(removeLists[0]), 1);
          let updateDefaultSelected = {
            ...removeDefaultSelected,
            inVal: removeDefaultSelected.inVal.toString(),
            inVal1: removeDefaultSelected.inVal1.toString(),
            inVal2: removeDefaultSelected.inVal2.toString(),
          };
          if (removeDefaultSelected.inVal.toString() !== '' || removeDefaultSelected.inVal1.toString() !== '' || removeDefaultSelected.inVal2.toString() !== '') {
            sessionStorage.setItem('defaultValues', JSON.stringify(updateDefaultSelected));
            sessionStorage.setItem('refinerUpdated', JSON.stringify(true));
            this.thirdPartyService.updateRef$.next(updateDefaultSelected);
            return;
          }
          updateDefaultSelected = {
            ...removeDefaultSelected,
            inVal:
              removeDefaultSelected.inVal.toString() !== ''
                ? removeDefaultSelected.inVal.toString()
                : this.configService.currentLibrary.countryCode.toString(),
            inVal1:
              removeDefaultSelected.inVal1.toString() !== ''
                ? removeDefaultSelected.inVal1.toString()
                : this.configService.currentLibrary.languageCode.toString(),
            inVal2:
              removeDefaultSelected.inVal2.toString() !== ''
                ? removeDefaultSelected.inVal2.toString()
                : '',
          };
          sessionStorage.setItem('defaultValues', JSON.stringify(updateDefaultSelected));
          sessionStorage.setItem('refinerUpdated', JSON.stringify(false));
          const {
            inVal,
            inVal1,
            inVal2,
            tp
          } = updateDefaultSelected;

          if (!(inVal || inVal1 || inVal2) && (tp === 'all')) {
            this.thirdPartyService.isPillsEmptyTitleFoucs = true;
          }
          this.thirdPartyService.updateRef$.next(updateDefaultSelected);
          return;
        }
        this.selectedOptions[option.key].splice(this.selectedOptions[option.key].indexOf(option.value), 1);
        this.selectedOptions = { ...this.selectedOptions };
        const defaultSelected =
          sessionStorage.getItem('defaultValues') !== 'undefined' ? JSON.parse(sessionStorage.getItem('defaultValues')) : null;
        defaultSelected && (defaultSelected[option.key] = '');
        sessionStorage.setItem('defaultValues', JSON.stringify(defaultSelected));
        const {
          availlibrary,
          audiencedesc,
          bisacsdesc,
          languagedesc
        } = defaultSelected;

        if (!(availlibrary || audiencedesc || bisacsdesc || languagedesc)) {
          this.thirdPartyService.isPillsEmptyTitleFoucs = true;
        }
        this.thirdPartyService.updateRef$.next(defaultSelected);
      }
    }
  }
  replaceTimeStrings = (text: string): string => {
    if(text?.includes('Mins')) {
      return text?.replace(/m(in)?s?/gi, 'minutes');
    }
    return text?.replace(/h(r?s)?/gi, ' hours').replace(/m(in)?s?/gi, ' minutes');
  };
}
