import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { BookDetail, ListContent, ActionData } from '@shared/data/config.model';
import {
  forceRedirect,
  getBookIcon,
  getValueFromCaseinsensitiveAttr,
  replaceTimeStrings,
  focusElement
} from '@shared/helper/app.util';
import { Router } from '@angular/router';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { IThirdPartyMystuff } from '@shared/data/third-party-model';
import { SpinnerService } from '@shared/services/spinner.service';
import { THIRD_PARTY_TIER } from 'app/third-party/shared/constants/third-party.constants';
import { ConfigService } from '@shared/services/config.service';
import { MyStuffService } from 'app/my-stuff/shared/services/my-stuff.service';
import { Subscription } from 'rxjs';
import { CAROUSEL_CONST, LIBRARY_CAROUSEL_FOOTER_TEXT, MYSTUFF_CAROUSEL_FOOTER_TEXT } from '@shared/constants/app.constants';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { CommonService } from '@shared/services/common.service';
import { SharedService } from '@shared/services/shared.service';
@Component({
  selector: 'axis360-my-stuff-list-card',
  templateUrl: './my-stuff-list-card.component.html',
  styleUrls: ['./my-stuff-list-card.component.scss']
})
export class MyStuffListCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() myStuffContent: ListContent;
  @Input() activeMyStuff: string;
  @Output() titleActionEvt = new EventEmitter();
  @Input() parentRoute: string;
  @Input() vendorCode = 'VIDEOS';
  activeVideoVbooksTitleSubscription: Subscription;
  layoutComponents: IThirdPartyMystuff[] = [];
  layoutComponentsList: IThirdPartyMystuff;
  loadThirdPartyAPIM = false;
  getBookIcon = getBookIcon;
  forceRedirect = forceRedirect;
  replaceTimeStrings = replaceTimeStrings;
  eBook = MYSTUFF_CAROUSEL_FOOTER_TEXT.EBOOK;
  eAudio = MYSTUFF_CAROUSEL_FOOTER_TEXT.EAUDIO;
  bookstatus = CAROUSEL_CONST.bookstatus;
  waitList = LIBRARY_CAROUSEL_FOOTER_TEXT.Waitlist;
  onHold = LIBRARY_CAROUSEL_FOOTER_TEXT.onHold;
  unAvailableStatus = LIBRARY_CAROUSEL_FOOTER_TEXT.unAvailable;
  readyForCheckout = LIBRARY_CAROUSEL_FOOTER_TEXT.readyForCheckout;
  holdSuspended = LIBRARY_CAROUSEL_FOOTER_TEXT.holdSuspended;
  comingSoon = LIBRARY_CAROUSEL_FOOTER_TEXT.comingSoon;
  requested = LIBRARY_CAROUSEL_FOOTER_TEXT.requested;
  pastCheckout = LIBRARY_CAROUSEL_FOOTER_TEXT.pastCheckout;
  focusElement = focusElement;
  titleLink: string;
  constructor(
    public thirdPartyService: ThirdPartyService,
    public titleDetailsSerivce: TitleDetailsService,
    public spinnerService: SpinnerService,
    public configService: ConfigService,
    public myStuffService: MyStuffService,
    public router: Router,
    public commonService: CommonService,
    private sharedService: SharedService
  ) { }
  ngOnInit() {
    this.activeVideoVbooksTitleSubscription = this.myStuffService.activeVideoVbooksTitle.subscribe((list) => {
      if (!list) {
        this.loadThirdPartyAPIM = true;
        this.spinnerService.showLoader(false);
        return;
      }
      this.getThirdPartyMyStuff(this.vendorCode).then((layOut) => {
        this.layoutComponentsList = layOut;
      });
    });
  }

  /**
   * getThirdPartyMyStuff
   * @param vendorcode pass the VendorCode get jsonTempl
   * @returns json template for Mystuff in APIM
   */
  async getThirdPartyMyStuff(vendorcode: string) {
    this.spinnerService.showLoader(false);
    const pageTitle = this.activeMyStuff === 'history' ? THIRD_PARTY_TIER.MYSTUFF_HISTORY : THIRD_PARTY_TIER.MYSTUFF;
    try {
      const [layoutData] = await this.thirdPartyService.getTierLayout(pageTitle, vendorcode);
      if (!layoutData?.layout) {
        this.spinnerService.showLoader(false);
        this.loadThirdPartyAPIM = true;
        return null;
      }
      this.loadThirdPartyAPIM = true;
      const { vendor, layout } = layoutData;
      const { components } = layout;
      const [{ jsonTempl }] = components;
      const { code } = vendor;
      const layoutComponentsList = { vendorCode: code, jsonTempl };
      this.spinnerService.showLoader(false);
      return layoutComponentsList;
    } catch (error) {
      this.loadThirdPartyAPIM = true;
      this.spinnerService.showLoader(false);
      return null;
    }
  }

  /**
   *
   * @param [attribute] pass attribute to change the value
   * @returns change the attribute value
   */
  getAttributeFormat(attribute: string) {
    return attribute.replace('Read Along', 'eRead-Along');
  }

  isMobileTab(): boolean {
    return window.innerWidth < 1024
  }

  /**
   * Get image for the title card by ISBN
   * @param isbn ISBN number of the title
   * @returns image url
   */
  getTitleImageUrl(isbn: string) {
    return this.configService.appSettings?.titleImageSrcFormat?.replace('{0}', isbn);
  }

  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType) {
    return this.getBookIcon(`KZ_${formatType}`);
  }

  /**
   * Navigate to title detail page
   * @param titleContent title content
   */
  navigateToListPage(titleContent: BookDetail) {
    const itemId = getValueFromCaseinsensitiveAttr(titleContent.ItemId, titleContent.itemId, '');
    const isbn = getValueFromCaseinsensitiveAttr(titleContent.ISBN, titleContent.isbn, '');
    this.titleDetailsSerivce.itemsTitleDetails = titleContent;
    this.commonService.breadcrumbs = [];
    this.commonService.titleBreadCrumb = false;
    this.titleLink = `/view/${this.parentRoute}/title/${itemId}/${isbn}`
    this.router.navigate([`/view/${this.parentRoute}/title/${itemId}/${isbn}`])
  }
  /**
   * Emits the Event when any Action is Triggered.
   */
  titleActionEvent(data: ActionData) {
    this.titleActionEvt.emit();
    if (this.activeMyStuff === 'wishlist' && data && data.actiontype === 'checkout') {
      this.navigateToListPage(data);
    }
  }

  getTitleFormat(format: string) {
    return format.replace('Read Along', 'eRead-Along');
  }

  ngAfterViewInit(): void {
    if (this.activeMyStuff) {
      setTimeout(() => {
        const mystuffLists = document.getElementById('mystuff-' + this.myStuffContent.Items[0].ItemId);
        this.sharedService.updateActionsButtonCheckoutCTA.subscribe((res) => {
          if (res && res === 'checkout') {
            mystuffLists && focusElement('mystuff-' + this.myStuffContent.Items[0].ItemId);
          }
        });
      }, 2000);
    }
  }


  isShowWaitList(bookDetails) {
    let showWaitList = false;
    showWaitList =
      (bookDetails?.DisplayStatus === this.unAvailableStatus ||
        bookDetails?.DisplayStatus === this.comingSoon ||
        (!bookDetails?.IsAvailable && !bookDetails?.isAvailable)) &&
      ![this.onHold, this.holdSuspended, this.readyForCheckout,
      this.requested, this.pastCheckout].some((x) => x === bookDetails?.DisplayStatus) &&
      !bookDetails?.DisplayStatus?.startsWith('Due');
    return showWaitList;
  }

  titleAriaLabel(book: any) {
    let ariaLabel = '';
    if (this.isShowWaitList(book)) {
      ariaLabel = 'Book Status Waitlist, ';
    }

    if (book?.DisplayStatus && book?.DisplayStatus !== '' && book?.DisplayStatus !== this.unAvailableStatus) {
      const updatedDisplayStatus = book.DisplayStatus === 'REQUESTED' ? 'Purchase Request' : book.DisplayStatus;
      book.DisplayStatus === this.pastCheckout && this.activeMyStuff === 'history'
        ? ariaLabel
        : (book.DisplayStatus === this.requested && this.activeMyStuff === 'recommendations'
        ? ariaLabel
        : (ariaLabel += this.bookstatus + updatedDisplayStatus + ', '));
    }

    if (book?.DisplayStatus && book?.DisplayStatus !== '' && book?.HoldPosition && book?.HoldPosition !== '' && book?.DisplayStatus !== this.readyForCheckout) {
      ariaLabel += (ariaLabel.includes(this.bookstatus) ? '': (this.bookstatus.includes('ON HOLD') ? this.bookstatus : this.bookstatus + 'ON HOLD, ') ) + book.HoldPosition + ', ';
    }

    if (book?.FormatType && this.getIcon(book.FormatType).alt) {
      ariaLabel += 'Format ' + this.getIcon(book.FormatType).alt + ', ';
    }
    if (book?.Title) {
      ariaLabel += 'Book title ' + book.Title + ', ';
    }
    if (book?.Author) {
      ariaLabel += 'Author ' + book.Author + ', ';
    }
    if (replaceTimeStrings(book?.RunTime)) {
      ariaLabel += 'Duration ' + replaceTimeStrings(book.RunTime);
    }
    const pageCount = book?.BookLength || '0';
    const isPageCount = (pageCount !== '0') ? `${pageCount} Pages` : '';
    const pageCountText = pageCount ? isPageCount : '';
    if (pageCountText) {
      ariaLabel += pageCountText;
    }
    return ariaLabel;
  }

  /**
   * Initialize the aria-label in all button CTA
   */
  titleAriaLabelActionsCTA(titleContent) {
    return titleContent.Title || '';
  }


  ngOnDestroy(): void {
    this.activeVideoVbooksTitleSubscription && this.activeVideoVbooksTitleSubscription.unsubscribe();
  }
}
