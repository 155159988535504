import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { INIT_LOAD_IMGS_COUNT, MAT_TABS_NAME } from '@search/shared/constants/search-results.constants';
import { TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { lazyLoadImages, getDate, getCountyAtrr } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-title-info-card',
  templateUrl: './title-info-card.component.html',
  styleUrls: ['./title-info-card.component.scss']
})
export class TitleInfoCardComponent implements AfterViewInit {
  @Input() titleInfo: TitleCardInfo;
  @Input() view: string;
  @Input() index: number;
  @Output() titleCardClickEvent = new EventEmitter<{ event: Event; url: string }>();
  @ViewChild('cardImg') cardImg: ElementRef;
  MAT_TABS_NAME = MAT_TABS_NAME;
  INIT_LOAD_IMGS_COUNT = INIT_LOAD_IMGS_COUNT;
  /**
   * Launch to target url
   * @param event input param as event
   * @param url input param as url
   */
  titleCardClick(event: Event, url: string) {
    this.titleCardClickEvent.emit({ event, url });
  }
  /**
   * Getm required date format
   * @param date input param as date
   * @returns returns formatted date
   */
  getDate(date: string) {
    return getDate(date);
  }
  /**
   * lazy load the images on after view init
   */
  ngAfterViewInit() {
    lazyLoadImages(this.cardImg && this.cardImg.nativeElement);
  }
  /**
   * to returns multiple country without pipe symbol
   * @param multiCountry input parameter as multiCountry
   * @returns to returns multiple country without pipe symbol
   */
  getCountyAtrr = (multiCountry: string) => {
    return getCountyAtrr(multiCountry);
  };
}
