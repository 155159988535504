<div class="title-details-info">
        <ng-container *ngIf="!removeRating && titleDetailsRating.patronReviewsEnabled">
                <div class="start-rating-icons" *ngIf="titleDetailsRating">
                        <ng-container *ngIf="isUserLogin; else ratingStar">
                                <button mat-icon-button
                                        class="btn-nobg kz-rating-star profile-star-icon star-icon {{starFillCss}}"
                                        axis360MatMenuFocus [matMenuTriggerFor]="ratingmenu"
                                        (menuOpened)="menuState(true)" (menuClosed)="menuState(false)"
                                        aria-haspopup="true" id="ratingMenuButton">
                                        <span aria-hidden="true" class="empty-stars"><span
                                                        class="kz-visually-hidden">Star</span></span>
                                        <span aria-hidden="true" class="full-stars"
                                                [ngStyle]="{'width': avgStarWidth}"><span
                                                        class="kz-visually-hidden">Star</span>
                                        </span>
                                        <span id="star-rating-update" aria-live="off" aria-hidden="false"
                                                class="kz-visually-hidden">{{titleDetailsRating?.title}} {{ratingStars}}
                                                of 5 stars selected</span>
                                </button>
                        </ng-container>
                        <ng-template #ratingStar>
                                <button mat-icon-button
                                        class="btn-nobg kz-rating-star profile-star-icon star-icon {{starFillCss}}"
                                        (click)="rateTitle(0)">
                                        <span aria-hidden="true" class="empty-stars"><span
                                                        class="kz-visually-hidden">Star</span></span>
                                        <span aria-hidden="true" class="full-stars"
                                                [ngStyle]="{'width': avgStarWidth}"><span
                                                        class="kz-visually-hidden">Star</span>
                                        </span>
                                        <span id="star-rating-update" aria-live="assertive"  role="status" aria-hidden="false" aria-atomic="true"
                                                class="kz-visually-hidden">{{titleDetailsRating?.title}} {{ratingStars}}
                                                of 5 stars selected</span>
                                </button>
                        </ng-template>
                        <div class="rating-title" *ngIf="titleDetailsRating">
                                <div class="ratings" role="text">
                                        <span class="ratings-count">({{titleDetailsRating.reviewsCount}})</span>
                                        <span class="kz-visually-hidden">Global rating</span>
                                </div>
                        </div>
                        <mat-menu #ratingmenu="matMenu" class="ratingDropmenu">
                                <div class="rating-titlewrap" id="new">
                                        <span class="rating-title">How many stars would you like to rate
                                                "{{titleDetailsRating.title || titleDetailsRating.bookTitle}}"?</span>
                                </div>
                                <button class="star-card" mat-menu-item (click)="rateTitle(5)" id="fivestartrating"
                                        [attr.aria-label]="ariaLabelFiveStartRating">
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                </button>
                                <button class="star-card" mat-menu-item (click)="rateTitle(4)" aria-label="Four stars">
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                </button>
                                <button class="star-card" mat-menu-item (click)="rateTitle(3)" aria-label="Three stars">
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                </button>
                                <button class="star-card" mat-menu-item (click)="rateTitle(2)" aria-label="Two stars">
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                </button>
                                <button class="star-card" mat-menu-item (click)="rateTitle(1)" aria-label="One star">
                                        <mat-icon svgIcon="kz-star-full" class="fill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                        <mat-icon svgIcon="kz-star-full" class="unfill-star"></mat-icon>
                                </button>
                        </mat-menu>
                </div>
                <p class="ratings" id="review_count" *ngIf="titleDetailsRating && titleDetailsRating?.otherUserReviewCount > 0">
                        <a class="primary-link review-det-flex" href="javascript:void(0)"
                                (click)="firstTimeReviewTitle(titleDetailsRating?.otherUserReviewCount)">
                                <span>{{titleDetailsRating.otherUserReviewCount}}</span>
                                {{titleDetailsRating.otherUserReviewCount > 1 ? REVIEWS : REVIEW}}
                                <span class="kz-visually-hidden">jump</span>
                        </a>
                </p>

                <p class="ratings" id="write_review"
                        *ngIf="titleDetailsRating && titleDetailsRating?.otherUserReviewCount === 0">
                        <span><a class="primary-link" href="javascript:void(0)"
                                        (click)="firstTimeReviewTitle(titleDetailsRating?.otherUserReviewCount)">
                                        Be the first to write a review</a></span>
                </p>
        </ng-container>
        <ng-container *ngIf="!removeBooksInfo">
                <div role="List" aria-roledescription="list" class="btn-group book-details-menu"
                        *ngIf="titleDetailsRating">
                        <div class="btn-secondary-nobg btn-theme" role="listitem"
                                *ngIf="titleDetailsRating.formatTypeName && titleDetailsRating.formatType && titleDetailsRating.formatType.icon !== ''">
                                <mat-icon [svgIcon]="titleDetailsRating.formatType.icon"
                                        class="format-icon">{{titleDetailsRating.formatType.icon}}
                                </mat-icon>
                                <span>{{titleDetailsRating.formatTypeName}}</span>
                        </div>
                        <div class="btn-secondary-nobg btn-icon-colunm btn-secondary-fill timer-fill"
                                *ngIf="titleDetailsRating?.length && titleDetailsRating?.length !== 'NA' && titleDetailsRating?.runTime !=='NA'"
                                role="listitem">
                                <mat-icon svgIcon="kz-timer">kz-timer</mat-icon>
                                <span class="sr-only">{{titleDetailsRating?.runTime?.replace('h',
                                        'hours')?.replace('m','minutes')}}</span>
                                <span aria-hidden="true">{{titleDetailsRating?.runTime}}</span>
                        </div>
                        <div class="btn-secondary-nobg btn-icon-colunm btn-secondary-fill pages-count"
                                *ngIf="titleDetailsRating?.bookLength > 0 && titleDetailsRating?.formatTypeName === 'eBook'"
                                role="listitem">

                                <span>{{titleDetailsRating?.bookLength}}</span>
                                <span>pages</span>
                        </div>
                        <div class="btn-secondary-nobg user-profile-btn btn-theme" *ngIf="titleDetailsRating.ageRange"
                                role="listitem">
                                <span class="user-profile-text">{{titleDetailsRating.ageRange.name}}</span>
                                <span class="user-profile d-flex">{{titleDetailsRating.ageRange.age}}</span>
                        </div>
                        <div class="btn-secondary-nobg btn-secondary-fill btn-chat" *ngIf="titleDetailsRating?.language"
                                role="listitem">
                                <span aria-hidden="true">{{titleDetailsRating?.language}}</span><span
                                        class="sr-only">{{titleDetailsRating?.languageDesc}}</span>
                                <mat-icon svgIcon="kz-chat-arrow" class="kz-chat-arrow"></mat-icon>
                        </div>
                </div>
                <p class="secondary-para text-to-speech" *ngIf="titleDetailsRating">{{titleDetailsRating.attribute
                        ?titleDetailsRating.attribute + ' • ':''}}
                        {{titleDetailsRating?.subjectName | titlecase}} • {{titleDetailsRating?.publicationDate |
                        date:'MMM
                        yyyy'}}</p>
        </ng-container>
</div>