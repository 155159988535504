<div class="action-wrapper">
    <ng-container>
        <ng-container>
            <ng-container
                *ngIf="componentApiData && componentApiData?.isWishList !== 'N' && componentApiData?.isVendorEnable === 'true'">
                <div *ngIf="componentApiData?.isWishList === 'A'">
                    <button mat-icon-button id="thirdPartyAfterCtaFocusAdd" aria-describedby="wishlist-add"
                        class="btn-nobg-bluecolor icon-button-with-txt wishlist-btn"
                        (click)="cardAction({type:'actions', actionType:'addtowishlist'})">
                        <mat-icon svgIcon="kz-favorite">
                        </mat-icon>
                        <small class="icon-text">Wishlist</small>
                    </button>
                    <span aria-hidden="true" *ngIf="componentApiData?.isWishList === 'A'" class="kz-visually-hidden"
                        id="wishlist-add">Add the title to wishlist</span>
                </div>
                <div *ngIf="componentApiData?.isWishList === 'R'">
                    <button mat-icon-button class="btn-nobg-bluecolor icon-button-with-txt wishlist-btn"
                        aria-describedby="wishlist-remove" id="thirdPartyAfterCtaFocusRemove"
                        (click)="cardAction({type:'actions', actionType:'removefromwishlist'})">
                        <mat-icon svgIcon="kz-favorite-fill">
                        </mat-icon>
                        <small class="icon-text">Wishlist</small>
                    </button>
                    <span aria-hidden="true" *ngIf="componentApiData?.isWishList === 'R'" class="kz-visually-hidden"
                        id="wishlist-remove">Remove the title from wishlist</span>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container>
        <div>
            <button mat-icon-button axis360MatMenuFocus id="loc_btnShare" [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger" class="btn-nobg-bluecolor icon-button-with-txt share-btn"
                (click)="trackByTitleDetailsActions('ACTIONS_SHARE');removeAnnounce()"
                (keydown.enter)="trackByTitleDetailsActions('ACTIONS_SHARE');removeAnnounce()"
                (menuOpened)="menuState(true)" (menuClosed)="menuState(false)">
                <mat-icon svgIcon="kz-share"></mat-icon>
                <small class="icon-text">Share</small>
            </button>
            <mat-menu #menu="matMenu" class="shareDropmenu">
                <div class="action-buttons-sec">
                    <button mat-menu-item
                        (click)="shareTitleSocialMedia('copyLink');menuCloseEvent($event)"
                        (keydown.enter)="shareTitleSocialMedia('copyLink');menuCloseEvent($event)">
                        <mat-icon svgIcon="kz-copy-icon"></mat-icon>
                        <span>Copy Link</span>
                    </button>
                    <button mat-menu-item (click)="shareTitleSocialMedia('email')"
                        (keydown.enter)="shareTitleSocialMedia('email')">
                        <mat-icon svgIcon="kz-titlemail-icon"></mat-icon>
                        <span>Email</span>
                    </button>
                    <button mat-menu-item (click)="shareTitleSocialMedia('facebook')"
                        (keydown.enter)="shareTitleSocialMedia('facebook')">
                        <mat-icon svgIcon="kz-facebook-icon"></mat-icon>
                        <span>Facebook</span>
                    </button>
                    <button mat-menu-item (click)="shareTitleSocialMedia('instagram')"
                        (keydown.enter)="shareTitleSocialMedia('instagram')">
                        <mat-icon svgIcon="kz-instagram-icon"></mat-icon>
                        <span>Instagram</span>
                    </button>
                </div>
            </mat-menu>
        </div>
    </ng-container>
</div>