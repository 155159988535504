import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  THIRD_PARTY_SCREENS_KEY,
  THIRD_PARTY_SCREENS_CATEGORY,
  THIRD_PARTY_SCREENS_EVENTS,
  THIRD_PARTY_SCREENS_EVENTS_KEY,
  THIRD_PARTY_SCREENS_SCREENS
} from '../constants/third-party-analytic.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof THIRD_PARTY_SCREENS_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class ThirdPartyAnalyticService extends AnalyticsService {
  constructor() {
    super(THIRD_PARTY_SCREENS_SCREENS, THIRD_PARTY_SCREENS_EVENTS);
  }

  /**
   * To track titleDetails page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof THIRD_PARTY_SCREENS_KEY, altObj: AltObj = {}) {
    this.trackPage(screen, altObj);
  }

  /**
   * To trackTitleCarouselEvent
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackTitleCarouselEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.TITLE_CAROUSEL, action, altObj);
  }

  /**
   * To trackRefinersEvent
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackRefinersEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.REFINERS, action, altObj);
  }

  /**
   * To trackCategortyCarouselEvent
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackCategortyCarouselEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.CATEGORTY_CAROUSEL, action, altObj);
  }

  /**
   * To trackWidgetsEvent
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackWidgetsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.WIDGETS, action, altObj);
  }

  /**
   * To trackTitleActionsEvent
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackTitleActionsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.TITLE_ACTIONS, action, altObj);
  }

  /**
   * To trackCollectionActionsEvent
   * @param action event action name
   * @param [altObj] to alter the collection text
   */
  trackCollectionActionsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(THIRD_PARTY_SCREENS_CATEGORY.SEARCH, action, altObj);
  }
}
