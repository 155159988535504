import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'axis360-badges-carousel',
  templateUrl: './badges-carousel.component.html',
  styleUrls: ['./badges-carousel.component.scss']
})
export class BadgesCarouselComponent implements OnInit {
  @Input() badges = [];
  responsiveOptions = [
    {
      breakpoint: '3840px',
      breakpointWidth: 3840,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1440px',
      breakpointWidth: 3840,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1024px',
      breakpointWidth: 1024,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      breakpointWidth: 768,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '700px',
      breakpointWidth: 700,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '600px',
      breakpointWidth: 600,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '500px',
      breakpointWidth: 500,
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '375px',
      breakpointWidth: 375,
      numVisible: 2.5,
      numScroll: 2.5
    },
    {
      breakpoint: '0px',
      breakpointWidth: 0,
      numVisible: 2,
      numScroll: 2
    }
  ];
  hideNavButtons: boolean;
  numScroll: number;
  numVisible: number;
  /**
   * To refresh the carousel on init
   */
  ngOnInit() {
    this.onResize();
  }
  /**
   * To refresh the carousel on window resize
   */
  onResize() {
    const innerWidth = window.innerWidth;
    const curBreakpoint = this.responsiveOptions.find((option) => innerWidth >= option.breakpointWidth);
    this.numScroll = curBreakpoint.numScroll;
    this.numVisible = curBreakpoint.numVisible;
    this.hideNavButtons = curBreakpoint.numVisible >= this.badges.length;
  }
}
