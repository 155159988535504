import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProfileInfoService } from '@shared/services/profile-info.service';

@Component({
  selector: 'axis360-profile-avatar-image',
  templateUrl: './profile-avatar-image.component.html',
  styleUrls: ['./profile-avatar-image.component.scss']
})
export class ProfileAvatarImageComponent implements AfterViewInit {
  @Input() isEdit = false;
  @Input() isImageEdit = false;
  @Input() imgSrc = '';
  @Input() imgAlt = '';
  @Input() editAriaLabel = '';
  @Input() profileType = '';
  @Input() isPrimary = false;
  @Input() isManageEdit = false;
  @Output() editEvent = new EventEmitter();
  hideImg = false;
  editImg(): void {
    this.editEvent.emit();
    this.editImg
  }
  constructor(public profileInfoService: ProfileInfoService) {}
  ngAfterViewInit(): void {
    if (document.getElementById('edit-icon-focus')) {
      document.getElementById('edit-icon-focus').setAttribute('aria-label', 'Edit Profile picture');
    }
  }
  removeTooltipAnnouce() {
    document.querySelectorAll('.kz-card-tooltip').forEach((element) => {
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    });

    const profileTooltip = document.getElementById('edit-icon-focus');
    profileTooltip?.removeAttribute('aria-describedby');
  }
}
