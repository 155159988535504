import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { forceRedirect } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SIDE_NAV_URL } from 'app/header/shared/constants/header.constants';

@Component({
  selector: 'axis360-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  forceRedirect = forceRedirect;
  hideHeaderFooter = false;
  constructor(
    public configService: ConfigService,
    public profileInfoService: ProfileInfoService,
    public commonService: CommonService,
    private liveAnnouncer: LiveAnnouncer
  ) { }

  ngOnInit(): void {
    this.commonService.hideHeaderFooter.subscribe((hideHeaderFooter: boolean) => {
      this.hideHeaderFooter = hideHeaderFooter;
    });
    document.addEventListener('keydown', (e: any) => this.listen(e), false);
  }
  listen(event: any) {
    const elem = document.getElementById('home');
    if (elem?.id === document.activeElement?.id) {
      this.liveAnnouncer.announce('', 'assertive');
    }
    if (elem?.id === event.currentTarget?.id) {
      this.liveAnnouncer.announce('', 'assertive');
    }
  }
  navigateToHome() {
    const url = this.profileInfoService.currentProfile.defaultLandingPageID === '1' ? SIDE_NAV_URL.MY_SHELF : SIDE_NAV_URL.MY_LIBRARY;
    this.forceRedirect('/ng' + url);
  }
}
