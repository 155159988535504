import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutComponent, ThirdPartyLayout } from '@shared/data/third-party-model';
import { cdkRemoveVisualHidden, cdkVisualHidden, focusElement, forceRedirect, getPressReaderAPIDate } from '@shared/helper/app.util';
import { THIRD_PARTY_TYPES, PRESS_READER_LATEST_ISSUE_LIMIT, THIRD_PARTY_VENDOR_CODE, PROFILE_TYPES, THIRD_PARTY_SUBTYPE } from '../shared/constants/third-party.constants';
import { getRefinerCount, getThirdPartyBaseURL } from '../shared/helper/third-party-util';
import { ThirdPartyAnalyticService } from '../shared/services/third-party-analytic.service';
import { ThirdPartyService } from '../shared/services/third-party.service';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '@shared/services/config.service';
import { UserService } from '@shared/services/user.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CommonService } from '@shared/services/common.service';
import { Location } from '@angular/common';
@Component({
  selector: 'axis360-third-party-tier-two',
  templateUrl: './third-party-tier-two.component.html',
  styleUrls: ['./third-party-tier-two.component.scss']
})
export class ThirdPartyTierTwoComponent implements OnInit, AfterViewInit, OnDestroy {
  initialLayout = { layout: { components: [] } };
  layoutData: Partial<ThirdPartyLayout> = this.initialLayout;
  refinersComponent: LayoutComponent;
  layoutComponent: LayoutComponent;
  pageTitle: string;
  filtersCount: string;
  tabsList = [{ text: 'All Fiction' }, { text: 'Publishing Date' }, { text: 'English' }];
  isLoading = true;
  showCategories = false;
  vendorCode = '';
  maxDate: Date;
  minDate: Date;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  isKZGuest: any;
  focusElement = focusElement;
  forceRedirect = forceRedirect;
  getThirdPartyBaseURL = getThirdPartyBaseURL;
  getPressReaderAPIDate = getPressReaderAPIDate;
  thirdPartyBaseGridClass: { divClass: string; cardsClass: string };
  breadcrumbs: ({ text: string; url: string; isHref: boolean; queryParams?: {} } | { text: string; url?: string; isHref?: boolean })[];
  parentRoute: string;
  THIRD_PARTY_SUBTYPE = THIRD_PARTY_SUBTYPE;
  isMobile = false;
  skipLink = '';
  titleCardAnnounce = '';
  newsTitle = '';
  constructor(
    public thirdPartyService: ThirdPartyService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public activatedRoute: ActivatedRoute,
    public profileInfoService: ProfileInfoService,
    public userService: UserService,
    public configService: ConfigService,
    private titleService: Title,
    public breadcrumbService: BreadcrumbService,
    public commonService: CommonService,
    public router: Router,
    public location: Location
  ) { }

  ngOnInit() {
    this.isMobile = window.innerWidth <= 768;
    this.skipLink = `/ng${this.location.path(false)}#thirdparty_result`;
    const parentRoute = new RegExp('view/' + "(.*?)" + '/list').exec(this.router.url);
    this.parentRoute = parentRoute ? parentRoute[1] + '/list' : '';
    this.maxDate = new Date();
    const minDate = new Date();
    sessionStorage.setItem('thirdparty', 'tire2');
    minDate.setDate(minDate.getDate() - PRESS_READER_LATEST_ISSUE_LIMIT);
    this.minDate = minDate;
    this.getLayout();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
    this.thirdPartyAnalyticService.trackScreen('LANDING_TIRE2');
    const { vendor_code: vendorCode, cardTitle: bookTitle, title } = this.activatedRoute.snapshot.queryParams;
    this.thirdPartyService.searchViewRefiner = parentRoute && parentRoute[1]?.includes('search');
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    this.isKZGuest = !this.userService.userInfo?.isLoggedIn;
    const isZoneEnabled = (!this.axis360Enabled && this.kidsZoneEnabled) ? PROFILE_TYPES.TEEN : PROFILE_TYPES.ADULT;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(
        this.isKZGuest ? isZoneEnabled :
          this.profileInfoService.currentProfile.profileType.toLowerCase()
      ) || {};
    if (!currentProfileFeatures[vendorCode]) {
      this.forceRedirect('/ng/view/library');
      return;
    }
    if (vendorCode !== THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
      // const formatType = vendorCode === THIRD_PARTY_VENDOR_CODE.VBOOKS ? 'Videobooks' : 'Videos';
      const CarouselAnnounce = bookTitle ? `${bookTitle}${' - '} Title List page` : `${title}${' - '} Title List page`;
      this.titleCardAnnounce = CarouselAnnounce || `${bookTitle || title} ${' - '} Title List page`;
      this.titleService.setTitle(this.titleCardAnnounce);
    } else {
      this.newsTitle = `${title || 'Newspapers and Magazines'} - Publication List`;
      this.titleService.setTitle(this.newsTitle);
      this.titleCardAnnounce = `${this.newsTitle} Page`;
      this.parentRoute = this.parentRoute.replace(/%20/g, " ");
    }
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('breadcrumb');
    this.thirdPartyService.searchViewRefiner = false;
    cdkRemoveVisualHidden();
  }

  groupLayout(layoutData: ThirdPartyLayout) {
    const { components } = layoutData.layout;
    this.layoutComponent = components.find((comp) => comp.type === THIRD_PARTY_TYPES.ITEMS_GRID);
    this.refinersComponent = components.find((comp) => comp.type === THIRD_PARTY_TYPES.REFINERS);
    this.filtersCount = this.refinersComponent && getRefinerCount(this.refinersComponent.defaultValues || {});
  }
  async getLayout() {
    try {
      const { vendor_code: vendorCode, title: pageTitle, ...rest } = this.activatedRoute.snapshot.queryParams;
      this.vendorCode = vendorCode;
      this.showCategories = !!rest.q;
      this.pageTitle = pageTitle;
      let restQuertParams;
      if (rest.id) {
        restQuertParams = { ...rest, fromDate: this.getPressReaderAPIDate(this.minDate), toDate: this.getPressReaderAPIDate(this.maxDate) };
      }
      const thirdPartyBaseURL = this.getThirdPartyBaseURL({ ...rest, vendorCode });
      this.thirdPartyBaseGridClass = thirdPartyBaseURL.class;
      const [layoutData] = await this.thirdPartyService.getTierLayout(
        thirdPartyBaseURL.url,
        vendorCode,
        restQuertParams || rest
      );
      if (!layoutData?.layout) {
        return;
      }
      this.layoutData = layoutData;

      this.groupLayout(layoutData);
      this.isLoading = false;

      if (!rest.q) {
        this.breadcrumbService.set('@contentlist', this.pageTitle || this.layoutComponent.title);
      }
      this.getBreadcrumb();

    } catch (e) {
      this.layoutData = this.initialLayout;
      this.isLoading = false;
    }
  }

  getBreadcrumb() {
    const b = sessionStorage.getItem('breadcrumb')
    if (b) {
      this.commonService.breadcrumbs = JSON.parse(sessionStorage.getItem('breadcrumb'))
    }
    else {
      this.breadcrumbService.breadcrumbs$.subscribe((response) => {
        if (response.filter(res => res.alias === 'contentlist').length > 0) {
          this.commonService.breadcrumbs = [];
          const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
          const url = homePage === 'Library' ? '/view/library' : '/view/myshelf'
          response.forEach((res) => {
            if (res.label !== 'content' && res.label !== 'list') {
              const breadcrumbData = {
                text: res.label,
                url: res.routeLink,
                queryParams: res.queryParams
              }
              this.commonService.breadcrumbs.push(breadcrumbData)
            }
          })
          this.commonService.breadcrumbs[0] = { text: homePage, url, queryParams: undefined }
          const unique = this.commonService.breadcrumbs.filter((set => f => !set.has(f.text) && set.add(f.text))(new Set()));
          this.commonService.breadcrumbs = unique;
          sessionStorage.setItem("breadcrumb", JSON.stringify(this.commonService.breadcrumbs))
        }
      });

    }
  }

  onResize(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  ngAfterViewInit() {
    window.scrollTo(0, this.commonService.scrollPostion);
    focusElement('loc_headerId');
  }
  skipRefiner() {
    document.getElementById('thirdparty_result').focus();
   }
}
