<axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>

<div [hidden]="isLoading" class="programDetail">
    <section class="reading-program">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"></axis360-breadcrumb>
        <div class="reading-book-detail">
            <div class="book-detail-lft" id="prog-det-readprogram">
                <h1 class="pro-sub-heading">Reading Program</h1>
                <h2 class="pro-heading" id="prog-det-heading">{{programDetails.name}}</h2>
                <p class="pro-desc" id="prog-det-ptext" *ngIf="programDetails.description">{{programDetails.description}}</p>
            </div>
            <div class="book-detail-rgt">
                <div class="pro-date-sec">
                    <div class="display-date">
                        <div class="start-date" [ngClass]="{'closed-program' : programDetails.isClosedProgram}">
                            <h3>START DATE</h3>
                            <p id="pd-strt-date">{{programDetails.startDate}}</p>
                        </div>
                        <div class="start-date">
                            <h3>END DATE</h3>
                            <p id="pd-end-date">{{programDetails.endDate}}</p>
                        </div>
                        <div class="start-date">
                            <h3>COMPLETED</h3>
                            <p id="pd-read">{{programDetails.typeText}}</p>
                        </div>
                    </div>
                    <div class="display-date">
                        <div class="start-date">
                            <h3>STATUS</h3>
                            <p id="pd-stauts">{{programDetails.statusText}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="programDetails.statusText === programStatusText.JOINED || programDetails.statusText === programStatusText.STARTED" class="pro-option-sec">
            <div class="pro-option">
                <a (click)="openLeaveDialog()" (keyup.enter)="openLeaveDialog()" tabindex="0" role="button" id="pro-det-leave">LEAVE PROGRAM<mat-icon>close</mat-icon></a>
            </div>
        </div>
    </section>
    <section *ngIf="programDetails.books && programDetails.books.length && programDetails.bookLoaded" class="reading-list">
        <h2 id="pd-readlist-head">Reading List ({{programDetails.books.length}})</h2>
        <div class="reading-main-sec">
            <ng-container *ngFor="let bookDetail of programDetails.books">
                <axis360-programs-title [isBooksInOrder]="programDetails.isOrder" [bookDetail]="bookDetail"  class="title-card-space" (titleMarkAsReadEvent)="markAsReadClick($event)"></axis360-programs-title>
            </ng-container>
        </div>
    </section>
</div>