import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  SHARED_EVENTS_KEY,
  SHARED_SCREENS,
  SHARED_EVENTS,
  SHARED_SCREENS_KEY,
  SHARED_CATEGORY
} from '../constants/shared.analytics.constants';
import { SharedService } from '@shared/services/shared.service';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof SHARED_EVENTS_KEY;
@Injectable()
export class SharedAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of shared analytics service.
   */
  constructor(private sharedService: SharedService) {
    super(SHARED_SCREENS, SHARED_EVENTS);
  }
  /**
   * To track shared page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof SHARED_SCREENS_KEY) {
    this.trackPage(screen);
  }
  /**
   * To track navigation tabs events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackNavTabsEvent(action: Action, altObj: AltObj = {}) {
    this.sharedService.updateActionsButtonCheckoutCTA.next('');
    this.trackEvent(SHARED_CATEGORY.NAV_TABS, action, altObj);
  }
  /**
   * To track title carousel events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackCarouselEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(SHARED_CATEGORY.TITLE_CAROUSEL, action, altObj);
  }
  /**
   * To track title carousel events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackNewspaperCarouselEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(SHARED_CATEGORY.NEWSPAPER_CAROUSEL, action, altObj);
  }
  /**
   * To track title action events
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackActionButtonEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(SHARED_CATEGORY.TITLE_ACTIONS, action, altObj);
  }
}
