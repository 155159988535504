<mat-sidenav-container class="example-container" autosize>
    <mat-sidenav class="kz-mat-drawer-inner-container" #sidenav mode="over" disableClose fixedInViewport="true"
        fixedTopGap="0" fixedBottomGap="0">
        <axis360-offcanvas></axis360-offcanvas>
    </mat-sidenav>
    <mat-sidenav-content #sidenavContent>
        <axis360-header [sidebarNav]="false" [advancedSearchToolbar]="false" [signInButton]="false" [isLoading]="isLoading"></axis360-header>
        <main class="ng-view-main-container main-container-content sticky-header" id="skip-nav">
            <ng-container *ngIf="!isLoading">
                <div class="login-container" *ngIf="isUserAndSettingCalled">
                    <!-- <div class="login-close">
                        <a class="close-icon" role="button" attr.aria-label="close dialog" href="javascript:;">
                            <mat-icon aria-hidden="true" svgIcon="kz-close"></mat-icon>
                        </a>
                    </div> -->
                    <div class="profile-image">
                        <img src="/angular-app/assets/images/kz-images/kz-user-profile.svg" alt="user profile icon">
                    </div>
                    <div class="login-container-patron">
                        <axis360-login [noModal]="true"></axis360-login>
                    </div>

                </div>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <axis360-spinner-loader></axis360-spinner-loader>
            </ng-container>
        </main>
        <axis360-footer></axis360-footer>

    </mat-sidenav-content>
</mat-sidenav-container>
<axis360-overlay-spinner></axis360-overlay-spinner>