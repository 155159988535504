export const SEARCH_RESULT_PAGE_SIZE = 24;
export const INIT_LOAD_IMGS_COUNT = 4;
export const SEARCH_TERM = 'searchTerm';
export const DEFAULT_COUNTRIES = ['us', 'ca'];
export const DEFAULT_LANGUAGES = null;
export const NEWS_DEFAULT_IMG = '/angular-app/assets/images/noimage_news_bg.svg';
export const SITES_DEFAULT_IMG = '/angular-app/assets/images/noimage_web_bg.svg';
export const APPLIED_REFINERS = 'applied-refiners';
export const APPLIED_PR_REFINERS = 'applied-pr-refiners';
export const SITES_NO_RESULTS = 'No results found for “<searchTerm>”.';
export const NEWS_NO_RESULTS = 'No results found for “<searchTerm>”.';
export const NEWS_SORT_OPTIONS = {
  RELEVANCE: 'Relevance',
  DATE: 'Date'
};
export const NEWS_RESPONSE_CATEGORIES = {
  COUNTRIES: 'Countries',
  LANGUAGES: 'Languages',
  PUBLICATION_TYPES: 'PublicationTypes'
};
export const NEWS_FILTER = {
  COUNTRY: 'Country',
  LANGUAGE: 'Language',
  PUBLICATION_TYPE: 'Type'
};
export const MAT_TABS_NAME = {
  BOOKS: 'MY LIBRARY',
  SITES: 'STUDY SITES',
  NEWS: 'NEWSSTAND'
};
export const MAT_TABS = [MAT_TABS_NAME.BOOKS, MAT_TABS_NAME.SITES, MAT_TABS_NAME.NEWS];
export const PATH_TAB_NAME_MAP = {
  BOOKS: MAT_TABS_NAME.BOOKS,
  SITES: MAT_TABS_NAME.SITES,
  NEWS: MAT_TABS_NAME.NEWS
};
export const MAT_TABS_INDEX = {
  [MAT_TABS_NAME.BOOKS]: 0,
  [MAT_TABS_NAME.SITES]: 1,
  [MAT_TABS_NAME.NEWS]: 2
};
export const MAT_TABS_ID = {
  [MAT_TABS_NAME.SITES]: 'sitesTab',
  [MAT_TABS_NAME.NEWS]: 'newsTab',
  [MAT_TABS_NAME.BOOKS]: 'booksTab'
};
export const TAB_ROUTE_MAP = {
  [MAT_TABS_NAME.NEWS]: `/search/news`,
  [MAT_TABS_NAME.SITES]: `/search/sites`
};
export const GRADE_MAP = {
  GRADE_PREK_2: 'PREK-2',
  GRADE_3_5: '3-5',
  GRADE_6_8: '6-8',
  GRADE_9_12: '9-12'
};
export const SITES_FILTER = {
  GRADE: 'Grade Level',
  DOMAIN: 'Domain',
  LANGUAGE: 'Language',
  FORMAT_TYPE: 'Format',
  SUBJECT: 'Subject',
  SOURCE: 'Source'
};
export const FILTER_TYPES = {
  LIST: 'List',
  TAB: 'Tab',
  LIST_SINGLE_SELECTION: 'List_Single_Selection'
};
export const REFINER_TYPE = {
  SORT: 'SORT',
  FILTER: 'FILTER'
};
export const REFINER_DIALOG_PANEL_CLASS = {
  [REFINER_TYPE.SORT]: 'sort-popup-width',
  [REFINER_TYPE.FILTER]: 'filter-popup-width'
};
export const FORMAT_TYPES = {
  BIOGRAPHY: 'Biagrophy',
  VIDEO: 'Video',
  ANIMATION: 'Animation',
  EDUCATIONAL_GAME: 'Educational game',
  INTERACTIVE: 'Interactive',
  EBOOK: 'eBook',
  IMAGE_COLLECTION: 'Image collection',
  AUDIO: 'Audio',
  MUSIC: 'Music',
  ARTICLE: 'Article'
};
export const FORMAT_TYPE = {
  [FORMAT_TYPES.BIOGRAPHY]: 'BIOGRAPHY',
  [FORMAT_TYPES.VIDEO]: 'VIDEO',
  [FORMAT_TYPES.ANIMATION]: 'ANIMATION',
  [FORMAT_TYPES.EDUCATIONAL_GAME]: 'EDUCATIONAL_GAME',
  [FORMAT_TYPES.INTERACTIVE]: 'INTERACTIVE',
  [FORMAT_TYPES.EBOOK]: 'EBOOK',
  [FORMAT_TYPES.IMAGE_COLLECTION]: 'IMAGE_COLLECTION',
  [FORMAT_TYPES.AUDIO]: 'AUDIO',
  [FORMAT_TYPES.MUSIC]: 'MUSIC',
  [FORMAT_TYPES.ARTICLE]: 'ARTICLE'
};
export const SOURCE_TYPES = {
  DICTIONARY_THESAURUS: 'Dictionary/Thesaurus',
  ENCYCLOPEDIA: 'Encyclopedia',
  MAGAZINE: 'Magazine',
  MAP_ATLAS: 'Map/Atlas',
  NEWS_SOURCE: 'News Source',
  PRIMARY_SOURCE_MATERIAL: 'Primary Source Material'
};
export const SOURCE_TYPE = {
  [SOURCE_TYPES.DICTIONARY_THESAURUS]: 'DICTIONARY_THESAURUS',
  [SOURCE_TYPES.ENCYCLOPEDIA]: 'ENCYCLOPEDIA',
  [SOURCE_TYPES.MAGAZINE]: 'MAGAZINE',
  [SOURCE_TYPES.MAP_ATLAS]: 'MAP_ATLAS',
  [SOURCE_TYPES.NEWS_SOURCE]: 'NEWS_SOURCE',
  [SOURCE_TYPES.PRIMARY_SOURCE_MATERIAL]: 'PRIMARY_SOURCE_MATERIAL'
};
export const ACTION_HANDLER_TYPE = {
  [MAT_TABS_NAME.NEWS]: 'pressReaderArticle',
  [MAT_TABS_NAME.SITES]: 'webPathExpress'
};
export const NEWS_TITLE = 'Newspapers & Magazines';
export const SITES_TITLE = 'Curated Websites';
export const PRESS_READER_REDIRECT_URL = `/PressReader?redirectionUrl=<redirectionUrl>`;
export const TAB_GROUP_ID = 'searchTabs';
export const PRESSREADER_TITLE = 'Newspapers';
export const MAT_PUB_TABS_NAME = {
  PUBLICATIONS: 'Publications',
  ARTICLES: 'Articles'
};
export const MAT_PUB_TABS = [MAT_PUB_TABS_NAME.PUBLICATIONS, MAT_PUB_TABS_NAME.ARTICLES];
export const MAT_PUB_TABS_ID = {
  [MAT_PUB_TABS_NAME.PUBLICATIONS]: 'publicationsTab',
  [MAT_PUB_TABS_NAME.ARTICLES]: 'articlesTab'
};
export const PRESS_READER_CATEGORY = {
  NEWSPAPER: "Newspapers",
  MAGAZINE : "Magazines",
  ARTICLES : "Articles"
}
export const PRESSRDR = 'PRESSRDR';
export const VIDEOS = 'VIDEOS';
export const VBOOKS = 'VBOOKS';
export const SORT_RESOURCE_VALUE = {
  "Relevance": "Relevance",
  "Title (A-Z)": '1',
  "Title (Z-A)": '-1'
}
export const TITLE_A_Z = 'Title (Z-A)'
