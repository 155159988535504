<div (window:resize)="onResize()">
    <ng-container *ngIf="!errorHandler;else nodata">
        <ng-container *ngIf="vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR">
            <axis360-third-party-base (acOnExecuteAction)="acOnExecuteAction($event)" [componentData]="componentApiData"
                [componentTemplate]="componentData.jsonTempl"
                [componentClass]="'third-party-card-details' + ' ' + thirdPartyDetailsClass"
                *ngIf="!isLoading && componentData"></axis360-third-party-base>
            <axis360-shimmer-cards [compName]="compName" *ngIf="isLoading"
                [showCarouselTitle]="vendorCode !== THIRD_PARTY_VENDOR_CODE.PRESSRDR">
            </axis360-shimmer-cards>
        </ng-container>
        <div class="titledetails-bookwrapper"
            *ngIf="vendorCode !== THIRD_PARTY_VENDOR_CODE.WIDGETS && vendorCode !== THIRD_PARTY_VENDOR_CODE.PRESSRDR && this.enableThirdPartyVendors.length > 0 && this.enableThirdPartyVendors.includes(vendorCode)">
            <div class="col-lg-4 col-sm-5 col-xs-12 titlebook-leftwrapper"
                [ngClass]="{'book-progress-bar': isProgressBarShow}">
                <div class="book-title-details">
                    <div class="book-of-month-img" id="loc_cta_id">
                        <axis360-third-party-base (acOnExecuteAction)="acOnExecuteAction($event)"
                            [componentData]="componentApiData" [componentTemplate]="componentData.jsonTempl"
                            [componentClass]="'third-party-card-details' + ' ' + thirdPartyDetailsClass"
                            *ngIf="!isLoading && componentData"></axis360-third-party-base>
                        <axis360-shimmer-cards [compName]="compNameItemDetails" *ngIf="isLoading">
                        </axis360-shimmer-cards>
                    </div>
                    <ng-container *ngIf="componentApiData && itemDetailsRatingsComponent">
                        <div class="title-rating-center" *ngIf="titleDetailsRating;else nodata">
                            <div class="title-rating-review d-flex">
                                <ng-container>
                                    <axis360-title-details-more [removeBooksInfo]="true"
                                        [titleDetailsRating]="titleDetailsRating"
                                        (titleRatingEvent)="handleRating($event)"
                                        (firstTitleReview)="firstTimeReviewTitle($event)"
                                        (scrollTitleReview)="firstTimeReviewTitle($event)">
                                    </axis360-title-details-more>
                                </ng-container>
                            </div>
                        </div>
                        <ng-template #nodata>
                            <axis360-shimmer-cards [compName]="compNameRating"></axis360-shimmer-cards>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="componentApiData && itemDetailsProgressbarComponent">
                        <div class="kz-progress-bar-wrapper"
                            *ngIf="enableTracking && readingProgress > 0 && showInsightsAndBadges">
                            <div class="kz-progress-bar-container">
                                <div class="kz-progress-bar"
                                    [ngStyle]="{'width': (readingProgress >= 0)? (readingProgress + '%'):'0%'}">
                                </div>
                            </div>
                            <div class="kz-progressbar-percentage-sec"
                                attr.aria-label="Title progress bar {{readingProgress}} percent"
                                [ngStyle]="{'width': (readingProgress >= 0)? (readingProgress + '%'):'0%'}">
                                <span class="kz-progressbar-percentage" role="text"><span
                                        class="kz-visually-hidden">Title progress bar</span>
                                    {{readingProgress}}%</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-8 col-sm-7 col-xs-12 titlebook-rightwrapper">
                <div class="title-details-info">
                    <div class="author-name visible-xs"
                        *ngIf="!isLoading && componentApiData && componentApiData?.totalCopies > 0 && itemDetailsDuedateComponent">
                        <p class="primary-heading-2 available-copies"
                            *ngIf="componentApiData?.availableCopies !== 9999;else availableCopies">
                            {{componentApiData?.availableCopies > 0 &&
                            !componentApiData?.RTV ?
                            0 : componentApiData?.availableCopies}} of
                            {{componentApiData?.totalQuantity}}
                            Copies Available</p>
                        <ng-template #availableCopies>
                            <p class="primary-heading-2 available-copies">
                                {{UNLIMITED_COPIES}}
                            </p>
                        </ng-template>
                    </div>
                    <div class="visible-xs" *ngIf="componentApiData && itemDetailsMainCTAComponent && isVisibleMobile">
                        <axis360-third-party-action-buttons
                            [componentApiData]="componentApiData"></axis360-third-party-action-buttons>
                    </div>
                    <div class="title-wrapper">
                        <div class="title-sec" *ngIf="itemDetailsTitleComponent">
                            <h1 class="primary-heading-1" *ngIf="componentApiData"
                                [innerHTML]="componentApiData.bookTitle"
                                [ngClass]="{'no-description': !componentApiData.subTitle}">
                            </h1>
                            <p class="primary-para" *ngIf="componentApiData?.subTitle">
                                {{componentApiData.subTitle}}</p>
                        </div>
                        <div class="action-sec" *ngIf="componentApiData && itemDetailsInlineCTAComponent">
                            <axis360-third-party-secondary-actions
                                [componentApiData]="componentApiData"></axis360-third-party-secondary-actions>
                        </div>

                    </div>
                    <div class="author-name" *ngIf="itemDetailsInfoComponent">
                        <p class="primary-para author" *ngIf="componentApiData?.authors?.author.length > 0">By: <a
                                *ngFor="let authorName of componentApiData?.authors?.author;let i = index"
                                class="primary-link" [routerLink]="['/view/search']"
                                (click)="actionThirdpartyDetailsSearch('ACTIONS_AUTHOR', authorName)"
                                [queryParams]="{page:1,pageSize:10,searchText:authorName,q:authorName,searchby:'author',vendor_code:vendorCode,format:'All',addeddate:'',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}">
                                {{authorName}} {{ (i < componentApiData?.authors?.author.length - 1) ? ';' : '' }} </a>
                        </p>
                        <p class="primary-para narrator" *ngIf="componentApiData?.narrators?.narrator.length > 0">
                            Narrated By: <a class="primary-link" [routerLink]="['/view/search']"
                                *ngFor="let narratorName of componentApiData?.narrators?.narrator;let i = index"
                                (click)="actionThirdpartyDetailsSearch('ACTIONS_NARRATOR', narratorName)"
                                [queryParams]="{page:1,pageSize:10,searchText:narratorName,q:narratorName,searchby:'author',vendor_code:vendorCode,format:'All',addeddate:'',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}">
                                {{narratorName}} {{ (i < componentApiData?.narrators?.narrator.length - 1) ? ';' : '' }}
                                    </a>
                        </p>
                    </div>
                    <div>
                        <ng-container *ngIf="componentApiData && titleDetailsRating && itemDetailsIconlistComponent">
                            <axis360-title-details-more [removeRating]="true" [titleDetailsRating]="titleDetailsRating"
                                (titleRatingEvent)="handleRating($event)"
                                (firstTitleReview)="firstTimeReviewTitle($event)"
                                (scrollTitleReview)="firstTimeReviewTitle($event)">
                            </axis360-title-details-more>
                        </ng-container>
                        <axis360-shimmer-cards *ngIf="!titleDetailsRating"
                            [compName]="compNameInfo"></axis360-shimmer-cards>
                    </div>
                    <div class="visible-xs title-state-info">
                        <div class="due-date-sec mt-1"
                            *ngIf="componentApiData?.checkoutDetails && itemDetailsDuedateComponent"
                            id="afterCtaFocusMobile">
                            <mat-icon svgIcon="kz-calender-icon"></mat-icon>
                            <p class="primary-heading-2 due-date">Due Date:
                                {{componentApiData?.checkoutDetails?.expirationDate | date:'MMM dd yyyy'}}
                            </p>
                        </div>
                    </div>
                    <ng-container *ngIf="itemDetailsDescriptionComponent">
                        <h2 class="primary-heading-2 synopsis" *ngIf="componentApiData?.synopsis">
                            Synopsis</h2>
                        <p class="primary-para synopsis-text" *ngIf="componentApiData?.synopsis && !showParagraph"><span
                                [innerHTML]="componentApiData?.synopsis?.substring(0, 361)"></span><span
                                aria-hidden="true" tabindex="-1">...</span></p>
                        <button class="primary-link expand-link" href="javascript:void(0);" id="more"
                            *ngIf="!showParagraph && componentApiData?.synopsis?.length > 358"
                            (click)="synopsisMoreLess(!showParagraph, 'less');">View More</button>
                        <ng-container *ngIf="showParagraph">
                            <p class="primary-para synopsis-text" *ngIf="showParagraph"
                                [innerHTML]="componentApiData?.synopsis"></p>
                            <button class="primary-link expand-link" href="javascript:void(0);" id="less"
                                (click)="synopsisMoreLess(!showParagraph, 'more')">View
                                Less</button>
                        </ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="!isLoading && itemDetailsDuedateComponent && componentApiData && componentApiData?.totalCopies > 0 ">
                        <div class="author-name hidden-xs">
                            <p class="primary-heading-2 available-copies"
                                *ngIf="componentApiData?.availableCopies !== 9999;else availableCopies">
                                {{componentApiData?.availableCopies > 0 &&
                                !componentApiData?.RTV ?
                                0 : componentApiData?.availableCopies}} of
                                {{componentApiData?.totalQuantity}}
                                Copies Available</p>
                            <ng-template #availableCopies>
                                <p class="primary-heading-2 available-copies">
                                    {{UNLIMITED_COPIES}}
                                </p>
                            </ng-template>
                        </div>
                    </ng-container>

                    <div class="hidden-xs title-state-info">
                        <div class="due-date-sec mt-1"
                            *ngIf="componentApiData?.checkoutDetails && itemDetailsDuedateComponent"
                            id="afterCtaFocusPc">
                            <mat-icon svgIcon="kz-calender-icon"></mat-icon>
                            <p class="primary-heading-2 due-date">Due Date:
                                {{componentApiData?.checkoutDetails?.expirationDate | date:'MMM dd yyyy'}}
                            </p>
                        </div>
                    </div>
                    <axis360-shimmer-cards *ngIf="!componentApiData"
                        [compName]="compNameButtons"></axis360-shimmer-cards>
                    <div class="hidden-xs" *ngIf="itemDetailsMainCTAComponent && !isVisibleMobile">
                        <axis360-third-party-action-buttons
                            [componentApiData]="componentApiData"></axis360-third-party-action-buttons>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #nodata>
        <div class="third-party-tier3-no-title-sec">
            <p class="third-party-tier3-no-title-heading">No Title Details Yet</p>
        </div>
    </ng-template>
</div>