<div class="kz-title-carousel">
    <div *ngIf="isLoading; else content">
        <ng-container>
            <div class="title-shimmer"></div>
            <div class="title-shimmer-BG"></div>
        </ng-container>
        <ng-container>
            <div class="kz-carousel kz-widget-carousel" (window:resize)="onResize()">
                <carousel [dots]="false" [margin]="cellMargin" [cellWidth]="cellWidth" [height]="cellHeight"
                    [cellsToScroll]="cellsToScroll">
                    <div class="carousel-cell" *ngFor="let x of [1,2,3,4,5,6]">
                        <axis360-shimmer-cards [compName]="compName">
                        </axis360-shimmer-cards>
                    </div>
                </carousel>
            </div>
        </ng-container>
    </div>
    <ng-template #content>
        <ng-container *ngIf=" componentData && vendorDetails?.length > 0 && widgetsLoading">
            <div class="kz-carousel kz-widget-carousel" (window:resize)="onResize()">
                <carousel axis360IvyCarousel [dots]="false" [margin]="cellMargin" [cellWidth]="cellWidth"
                    [height]="cellHeight" [cellsToScroll]="cellsToScroll">
                    <div class="carousel-cell" *ngFor="let details of vendorDetails;">
                        <axis360-third-party-widgets-carousel [vendorDetails]="details" [seeAllLink]="seeAllLink"
                            [parentRoute]="parentRoute"
                            (errorHandler)="errorHandlerEvent($event)"></axis360-third-party-widgets-carousel>
                    </div>
                </carousel>
            </div>
        </ng-container>
    </ng-template>
</div>