<p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{pageAnnounce}}</p>
<div class="kz-main kz-profile-section" (window:resize)="onResize()"
[ngStyle]="backgroundImageUrl ? {'background-image': 'url(' + backgroundImageUrl + ')'} : ''">
    <div class="kz-title profile-heading">
        <button class="btn-nobg-bluecolor d-flex back-btn" id="kz_back_btn" *ngIf="!sharedService?.isNewUser" (click)="back()" (keydown.enter)="back()"> <mat-icon aria-hidden="true" class="back-arrow"
            svgIcon="kz-back-arrow" ></mat-icon>back</button>
        <h1 class="kz-heading" id="loc_txtProfiles">Profiles</h1>
    </div>
    <axis360-spinner-loader *ngIf="isLoading;else listContent" isLoading="true"></axis360-spinner-loader>
    <ng-template #listContent>
        <div class="profile-listing-sec" id="loc_profilesList">
            <div class="profile-image-sec" *ngFor="let profile of profileInfoService.profiles; let i = index">
                <div (mouseover)="removeTooltipAnnouce(profile)" (keyup.Tab)="removeTooltipAnnouce(profile)" class="profile-image-div profile-select {{profile.profileType.toLowerCase()}}"
                    tabindex="0" role="button" id="loc_link{{profile.profileType}}EditProfile_{{profile.profileId}}"
                    [matTooltip]="isEditProfile ? 'Edit profile settings' : ''" matTooltipClass="kz-card-tooltip"
                    attr.aria-label="{{isEditProfile ? 'Edit,':''}} Profile Name {{profile.profileName}} Profile Type {{(profile.profileType.toLowerCase() === 'adult' && profile.isPrimary === 'True') ? 'General '+ profile.profileType +' Primary': (profile.profileType.toLowerCase() === 'adult') ? 'General '+ profile.profileType : profile.profileType}} {{profile.pinStatus === 'True'? 'Pin Enabled': ''}} "
                    (click)="isEditProfile ? onEditProfile(profile,'list'):selectedProfile(profile)"
                    (keydown.enter)="isEditProfile ? onEditProfile(profile,'list'):selectedProfile(profile)">
                    <axis360-profile-avatar-image [imgSrc]="profile.profileImage" [imgAlt]="getAvatarName(profile.profileImage, profile.profileName)" [isEdit]="isEditProfile"
                        [profileType]="profile.profileType" [isPrimary]="profile.isPrimary.toLowerCase()==='true'"
                        [isManageEdit]="true"
                        editAriaLabel=" Profile Name {{profile.profileName}} Profile Type {{profile.profileType}} ">
                    </axis360-profile-avatar-image>
                </div>
                <div class="profile-details">
                    <p axis360EllipsisTitle><span><mat-icon aria-hidden="true" svgIcon="kz-lock-profile" *ngIf="profile.pinStatus === 'True'"></mat-icon></span>
                         {{profile.profileName}}
                    </p>
                </div>

            </div>
            <div *ngIf="profileInfoService.canAddProfile" class="profile-image-sec">
                <div class="profile-image-add-div">
                    <button class="action-icon" (click)="onCreateProfile()" id="add-profile-button"
                        aria-label="Add Profile">
                        <mat-icon aria-hidden="true" class="add-icon" svgIcon="kz-addProfile-btn"></mat-icon>
                    </button>
                </div>
                <div class="profile-details">
                    <p title="addprofile">Add Profile
                    </p>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="manage-profile-btn">
        <button id="loc_linkEdit" *ngIf="!isEditProfile" class="profile-edit"
            (click)="enableProfileEdit('loc_linkCancel')" (keydown.enter)="enableProfileEdit('loc_linkCancel')"><mat-icon svgIcon="kz-manage-edit"
                aria-hidden="true"></mat-icon> Manage
            Profiles</button>
        <button id="loc_linkCancel" *ngIf="isEditProfile" class="profile-edit"
            (click)="enableProfileEdit('loc_linkEdit')"  (keydown.enter)="enableProfileEdit('loc_linkEdit')">Cancel</button>
        <button id="loc_linkLearnAbout" class="profile-edit profile-learn-about" (click)="learnMoreProfiles()"
            (keydown.enter)="learnMoreProfiles()">Learn more about Profiles</button>
    </div>
</div>