import { Component, OnInit } from '@angular/core';
import { cdkVisualHidden } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-kz-search-results-main',
  templateUrl: './kz-search-results-main.component.html',
  styleUrls: ['./kz-search-results.component.scss']
})
export class KzSearchResultsMainComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    cdkVisualHidden();
  }
}
