import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilesService } from 'app/profile/shared/services/profiles.service';
import { ProfileDetail } from 'app/profile/shared/data/profiles.model';
import {
  cdkRemoveVisualHidden,
  cdkVisualHidden,
  focusElement,
  forceRedirect,
  getBackgroundImageResize,
  positionFooter,
  redirectToHome,
  strToBool
} from '@shared/helper/app.util';
import { MAX_PROFILE_SIZE, PROFILE_ACTION } from '@profile/shared/constants/profiles.constants';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ProfilesAnalyticsService } from '@profile/shared/services/profiles.analytics.service';
import { IProfileInfo, ProfileInfoService } from '@shared/services/profile-info.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { DEFAULT_SUCCESS_MESSAGE, HOME_AVAILABILITY, KZ_HOME_FORMAT_TYPE, PATRON_FAILURE_MESSAGE } from '@shared/constants/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ParentalPinComponent } from '@profile/parental-pin/parental-pin.component';
import { PROFILES_ALERTS } from '@profile/shared/constants/profiles-alerts.constants';
import { CommonService } from '@shared/services/common.service';
import { SharedService } from '@shared/services/shared.service';
import { Location } from '@angular/common';
import { ConfigService } from '@shared/services/config.service';
import { SIDE_NAV_URL } from 'app/header/shared/constants/header.constants';
import { BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT } from '@shared/constants/search.constant';
import { AccountService } from '@shared/services/account.service';
@Component({
  selector: 'axis360-profiles-list',
  templateUrl: './profiles-list.component.html',
  styleUrls: ['./profiles-list.component.scss']
})
export class ProfilesListComponent implements OnInit, OnDestroy, AfterViewInit {
  profiles: ProfileDetail[] = [];
  selectedProfileId: string;
  isEditProfile = false;
  canAddProfile = false;
  isLoading = true;
  isMenu = false;
  isLogin = false;
  isMenuHide = false;
  forceRedirect = forceRedirect;
  currentProfile: IProfileInfo = {};
  backgroundImageUrl: string;
  pageAnnounce = '';
  constructor(
    public profilesService: ProfilesService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public liveAnnouncer: LiveAnnouncer,
    public profilesAnalyticsService: ProfilesAnalyticsService,
    public profileInfoService: ProfileInfoService,
    public searchStorageService: SearchStorageService,
    public matDialog: MatDialog,
    public commonService: CommonService,
    public sharedService: SharedService,
    public location: Location,
    public configService: ConfigService,
    public accountService: AccountService
  ) {}
  /**
   * Get Profilelist from API reponse
   */
  ngOnInit(): void {
    if (
      this.commonService.previousUrl &&
      !this.commonService.previousUrl.includes('edit') &&
      !this.commonService.previousUrl.includes('create')
    ) {
      this.commonService.listpreviousUrl = this.commonService.previousUrl;
    }
    if (this.accountService.fromActionHandler) {
      this.isLoading = true;
      this.accountService.fromActionHandler = false;
    }
    this.accountService.isActionCompleted.subscribe((res) => {
      if (res) {
        this.isLoading = false;
      }
    });
    const isLogin = this.activatedRoute.snapshot.queryParamMap.get('isLogin');
    const isMenu = this.activatedRoute.snapshot.queryParamMap.get('isMenu');
    isMenu && this.enableProfileEditList();
    isLogin && (this.sharedService.isNewUser = true);
    this.profilesService.forceStopOverlaySpinner();
    this.profilesAnalyticsService.trackScreen('MANAGE_PROFILE');
    positionFooter(true);
    this.currentProfile = this.profileInfoService.currentProfile;
    this.getProfilelist();
    document.title = `Manage profile`;
    this.pageAnnounce = `Manage profile Page`;
    this.commonService.hideHeaderFooter.next(true);
    this.backSignOut();
    this.onResize();
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      this.commonService.toStopAnnoucedPage();
      cdkVisualHidden();
    }, 2000);
  }
  backSignOut() {
    window.addEventListener(
      'popstate',
      (event) => {
        if (this.sharedService?.isNewUser && this.router.url?.includes('isLogin')) {
          if (event.type === 'popstate') {
            history.pushState(null, '', location.href);
            this.forceRedirect(SIDE_NAV_URL.GOBACKSIGNOUT);
            return;
          }
        }
      },
      false
    );
  }
  /**
   * To hide loader and announce page and positioning the footer after list load
   */
  handleGetProfileList() {
    this.isLoading = false;
    positionFooter(true);
  }
  /*back */

  back() {
    if (this.configService.currentLibrary.axis360Enabled && !this.configService.currentLibrary.kidsZoneEnabled) {
      this.location.back();
      return;
    }
    let previousUrl: string;
    if (this.commonService.previousUrl) {
      previousUrl = this.commonService.previousUrl;
    } else {
      previousUrl = this.configService.currentLibrary.axis360Enabled ? undefined : 'view/library';
    }
    this.commonService.previousUrl = previousUrl;
    const listpreviousUrl =
      this.commonService.previousUrl &&
      (this.commonService.previousUrl.includes('edit') || this.commonService.previousUrl.includes('create'))
        ? this.commonService.listpreviousUrl
        : this.commonService.previousUrl;
    if (listpreviousUrl === '') {
      this.router.navigateByUrl('/view/library');
    }
    listpreviousUrl && this.router.navigateByUrl(listpreviousUrl);
  }
  onResize() {
    this.backgroundImageUrl = getBackgroundImageResize(BACK_ROUNDIMAGE_ADULT_PROFILE_EDIT);
  }
  /**
   * Get Profile list by calling API on init
   */
  getProfilelist() {
    this.profilesService.getProfiles().subscribe(
      (response) => {
        if (response.statusCode === 200 && response.message === DEFAULT_SUCCESS_MESSAGE) {
          this.profileInfoService.profiles = response.profiles.profiles;
          this.selectedProfileId = response.profiles.selectedProfileId?.toLowerCase();
          this.profileInfoService.canAddProfile = response.profiles.profiles.length < MAX_PROFILE_SIZE;
          if (this.sharedService?.isNewUser) {
            setTimeout(() => {
              const elem = document.getElementById(`loc_link${response.profiles.profiles[0].profileType}EditProfile`);
              elem && elem.focus();
            }, 2000);
          }
          this.handleGetProfileList();
        } else {
          if (response.statusCode === 400 && response.message === PATRON_FAILURE_MESSAGE) {
            return this.forceRedirect('/ng/view/library');
          }
          this.selectedProfileId = '';
          this.profilesService.showErrorAlertDialog();
          this.handleGetProfileList();
        }
      },
      () => {
        this.selectedProfileId = '';
        this.profilesService.showErrorAlertDialog();
        this.handleGetProfileList();
      }
    );
  }
  getAvatarName(profileUrl, profileName)
  {
    if (this.profilesService.avatarData && !profileUrl.includes('base64'))
    {
      return `avatar ${this.profilesService.avatarData?.find((pro) => pro.avatarUrl === profileUrl)?.avatarName}`;
    }
    if (this.profilesService.avatarData && profileUrl.includes('base64'))
    {
        return `avatar ${profileName}`;
    }
    return 'Profile picture';
  }
  ngAfterViewInit() {
    if (!this.sharedService?.isNewUser) {
      setTimeout(() => {
        focusElement('kz_back_btn');
      }, 100);
    }
  }
  /**
   * Navigate to pin page
   */
  onCreateProfile() {
    document.title = `Create Profile`;
    this.profilesAnalyticsService.trackProfilesEvent('ADD_PROFILE');
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }
  /**
   * On click edit/cancel link list In Profile Page
   */
  enableProfileEditList() {
    this.isMenuHide = true;
    this.isEditProfile = !this.isEditProfile;
  }
  /**
   * On click edit/cancel link
   */
  enableProfileEdit(focusId: string) {
    setTimeout(() => {
      document.getElementById(focusId)?.focus();
    });
    this.isMenuHide = true;
    this.isEditProfile = !this.isEditProfile;
    this.removeTooltipAnnouce();
  }
  /**
   * Navigate to profile Type Page
   */
  onEditProfile(profile: ProfileDetail, page: string) {
    const status = strToBool(profile.pinStatus);
    this.profileInfoService.selectedEditProfileType = profile.profileType === 'Adult' ? 'General' : profile.profileType;
    if (!status) {
      this.isMenuHide = true;
      document.body.classList.remove('profile-create');
      this.profilesAnalyticsService.trackProfilesEvent('EDIT_PROFILE');
      page === 'list'
        ? this.router.navigate([`edit/${profile.profileId.toLowerCase()}`], {
            relativeTo: this.activatedRoute
          })
        : this.router.navigate([`view/profiles/edit/${profile.profileId.toLowerCase()}`]);
    } else {
      this.openPinPopup(profile, 'EDIT');
    }
  }
  /**
   * profile hint popup
   */
  setProfileHint() {
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.PROFILE_HINT,
      panelClass: 'profile-hint-alert-dialog'
    });
  }
  /**
   * Profile details
   * @param profile profile info
   */
  redirectToprofileDetails(profile: ProfileDetail) {
    this.profilesAnalyticsService.trackProfilesEvent('VIEW_PROFILE');
    this.searchStorageService.setHomeFiters(HOME_AVAILABILITY[0].value, KZ_HOME_FORMAT_TYPE[0].value);
    this.profilesService.selectProfile(profile).subscribe(
      (response) => {
        if (response.statusCode === 200 && response.message === DEFAULT_SUCCESS_MESSAGE) {
          this.isMenuHide = true;
          this.selectedProfileId = profile.profileId;
          this.liveAnnouncer.announce(profile.profileName + profile.profileType + ' selected', 'assertive');
          if (this.commonService.redirectURLfromMail) {
            this.forceRedirect('/ng' + this.commonService.redirectURLfromMail);
            return;
          }
          if (response.profile.defaultLandingPageID === '1') {
            this.configService.currentLibrary.kidsZoneEnabled &&
            (!this.configService.currentLibrary.axis360Enabled || response?.profile?.profileType !== 'Adult')
              ? this.forceRedirect('/ng/view/myshelf')
              : this.forceRedirect('/');
            return;
          }
          this.redirectToHome();
        }
      },
      () => {
        this.commonService.showErrorAlert();
      }
    );
  }

  /**
   * Switch Profile
   * @param profile profile info
   */
  selectedProfile(profile: ProfileDetail) {
    const status = strToBool(profile.pinStatus);
    this.profileInfoService.selectedEditProfileType = profile.profileType === 'Adult' ? 'General' : profile.profileType;
    localStorage.removeItem('selectedSortByValueStorage');
    localStorage.removeItem('selectedValues');
    localStorage.removeItem('selectedSortByStorage');
    localStorage.removeItem('selectedSubjectValues');
    localStorage.removeItem('selectedSubjectCatValues');
    localStorage.removeItem('checkedSubjectList');
    if (!status) {
      this.sharedService.isNewUser = true;
      sessionStorage.removeItem('breadcrumb');

      this.redirectToprofileDetails(profile);
    } else {
      this.openPinPopup(profile, 'SELECT');
    }
  }
  /**
   * Redirect to home page
   */
  redirectToHome = () => {
    redirectToHome();
  };
  /**
   * Pop up to check Pin Status
   */
  openPinPopup(profile: ProfileDetail, selectedValue: string) {
    const pinDialog = this.matDialog.open(ParentalPinComponent, {
      panelClass: 'enablepin-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'loc_txtProfileManagementPin',
      ariaDescribedBy: 'loc_textalertcontent',
      disableClose: true,
      data: {
        action: PROFILE_ACTION.ACCESS,
        name: profile.profileName,
        email: profile.profileEmail,
        type: PROFILE_ACTION.EDIT,
        profileId: profile.profileId
      }
    });

    pinDialog.afterClosed().subscribe((res) => {
      if (res.validPin) {
        if (selectedValue === 'SELECT') {
          this.sharedService.isNewUser = true;
          this.redirectToprofileDetails(profile);
        } else {
          this.isMenuHide = true;
          this.profilesAnalyticsService.trackProfilesEvent('EDIT_PROFILE');
          this.profileInfoService.profilePinSuccess = true;
          if (this.profileInfoService.isFromForgotPin) {
            if (profile.defaultLandingPageID === '1') {
              this.forceRedirect('/ng/view/myshelf');
            } else {
              this.forceRedirect('/ng/view/library');
            }
            return;
          }
          this.router.navigate([`edit/${profile.profileId.toLowerCase()}`], {
            relativeTo: this.activatedRoute
          });
        }
      }
    });
  }
  learnMoreProfiles() {
    const profilesList = document.getElementById('loc_profilesList');
    profilesList.setAttribute('aria-hidden', 'true');
    this.profilesAnalyticsService.trackScreen('ABOUT_PROFILE');
    this.profilesService.showAlertDialog({
      ...PROFILES_ALERTS.ABOUT_PROFILES,
      panelClass: 'learn-profile-alert-dialog',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => {
        setTimeout(() => {
          profilesList.removeAttribute('aria-hidden');
          const elem = document.getElementById('loc_linkLearnAbout');
          elem?.focus();
        }, 500);
        this.profilesAnalyticsService.trackProfilesEvent('GOT_IT');
      }
    });
  }
  ngOnDestroy(): void {
    if (this.isMenuHide) {
      this.commonService.hideHeaderFooter.next(false);
    }
    cdkRemoveVisualHidden();
  }
  removeTooltipAnnouce(profile?) {
    document.querySelectorAll('.kz-card-tooltip').forEach((element) => {
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    });
    if (!profile) {
      setTimeout(() => {
        this.profileInfoService.profiles.forEach((ele) => {
          const profilesTooltip = document.getElementById(`loc_link${ele.profileType}EditProfile_${ele.profileId}`);
          profilesTooltip?.removeAttribute('aria-describedby');
        });
      }, 100);
      return;
    }
    const profileTooltip = document.getElementById(`loc_link${profile.profileType}EditProfile_${profile.profileId}`);
    profileTooltip?.removeAttribute('aria-describedby');
  }
}
