<mat-card *ngIf="program" class="kz-program-card"
    [ngClass]="{'kz-program-not-completed': (program.status===PROGRAMS_STATUS_TEXT.NOT_COMPLETED || program.status===PROGRAMS_STATUS_TEXT.COMPLETED) && program.dbStatus!=='Active'}"
    (click)="programCardClickEvent.emit(this.program)">
    <a href="javascript:;" class="kz-progarm-link"
        attr.aria-label="{{program.programName}}  {{program.status !=='Open' ? ', Status ' + program.status : ''}}"
        (keydown.enter)="programCardClickEvent.emit(this.program)"></a>
    <div *ngIf="program.isParticipant"
        class="kz-program-status {{PROGRAMS_CLASS_BY_STATUS[program.status]}}">
        <span id="loc_txtProgramStatus{{program.status}}">{{program.status}}</span>
        <div *ngIf="program.status===PROGRAMS_STATUS_TEXT.COMPLETED" class="kz-program-status-icon">
            <img src="/angular-app/assets/images/kz-tick.svg" alt="" />
        </div>

    </div>
    <mat-card-header>
        <mat-card-title class="kz-card-title single-ellipsis" id="loc_programName{{program.programId}}"
            axis360EllipsisTitle role="heading" aria-level="3">{{program.programName}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="kz-card-details-main">
            <div class="kz-card-details-left-sec">
                <div class="kz-card-images-sec">
                    <axis360-kz-programs-card-images [cardImgs]="program.cardImgs" class="kz-card-images-com"
                        (keydown.enter)="programCardClickEvent.emit(this.program)"
                        (click)="programCardClickEvent.emit(this.program)" [programType]="program.programType"
                        [isParticipant]="program.isParticipant">
                    </axis360-kz-programs-card-images>
                </div>
                <div class="kz-progress-bar-wrapper" *ngIf="program.isParticipant">
                    <div class="kz-progress-bar-container"
                        [ngClass]="{'kz-progress-bar-grey': (program.status === PROGRAMS_STATUS_TEXT.COMPLETED || program.dbStatus === PROGRAMS_STATUS_TEXT.DB_COMPLETE)}">
                        <div class="kz-progress-bar"
                            [ngStyle]="{'width': (program.totalCompletePercentage >= 0)?(program.totalCompletePercentage + '%'):'0%'}">
                        </div>
                    </div>
                    <div class="kz-progressbar-percentage-sec"
                        [ngStyle]="{'width': (program.totalCompletePercentage > 10)?(program.totalCompletePercentage + '%'):'10%'}">
                        <span class="kz-progressbar-percentage" 
                        [attr.aria-label]="'Program progress bar  ' + program.totalCompletePercentage + ' percent'" ><span
                                class="kz-visually-hidden">Program progress bar</span>
                            {{program.totalCompletePercentage}}%</span>
                    </div>
                </div>
            </div>
            <div class="kz-card-details-sec">
                <div class="kz-card-details">
                    <div class="kz-card-details-date">
                        <div class="kz-card-details-row">
                            <div class="kz-card-details-col-lft">
                                <p>start</p>
                            </div>
                            <div class="kz-card-details-col-rgt">
                                <p id="loc_txt_startDate{{program.programName}}">{{program.startDate | date :'MMM dd,
                                    yyyy'}}</p>
                            </div>
                        </div>
                        <div class="kz-card-details-row" *ngIf="program.readByDate">
                            <div class="kz-card-details-col-lft">
                                <p>end</p>
                            </div>
                            <div class="kz-card-details-col-rgt">
                                <p id="loc_txt_readByDate{{program.programName}}">{{program.readByDate | date :'MMM dd,
                                    yyyy'}}</p>
                            </div>
                        </div>
                        <div class="kz-card-details-row" *ngIf="program.programType !== 'Milestone'">
                            <div class="kz-card-details-col-lft">
                                <p>{{program.totalParticipants <= 1?'Participant':'Participants'}}</p>
                            </div>
                            <div class="kz-card-details-col-rgt">
                                <p id="loc_txt_totalParticipants{{program.programName}}">{{program.totalParticipants}}
                                </p>
                            </div>
                        </div>
                        <div class="kz-card-details-row" *ngIf="program.goal">
                            <div class="kz-card-details-col-lft">
                                <p>goal</p>
                            </div>
                            <div class="kz-card-details-col-rgt">
                                <p class="single-ellipsis" axis360EllipsisTitle
                                    id="loc_txt_goal{{program.programName}}">{{program.goal}}</p>
                            </div>
                        </div>

                    </div>
                    <div class="kz-card-desc">
                        <p class="five-ellipsis" axis360EllipsisTitle [description]="program.description"
                            *ngIf="program.description" id="loc_txt_description{{program.programName}}">
                            {{program.description}}
                        </p>

                    </div>
                    <div class="kz-remove-card mobile-hide">
                        <button id="loc_btnProgramRemove{{program.programName}}"
                            *ngIf="(program.status===PROGRAMS_STATUS_TEXT.NOT_COMPLETED || program.status===PROGRAMS_STATUS_TEXT.COMPLETED) && program.dbStatus!=='Active'"
                            class="kz-delete-btn" (click)="$event.stopPropagation();deleteProgramPopup(program);"
                            attr.aria-label="Remove {{program.programName}} program">Remove</button>

                    </div>
                </div>
            </div>
        </div>
        <div class="kz-remove-card mobile-show">
            <button
                *ngIf="(program.status===PROGRAMS_STATUS_TEXT.NOT_COMPLETED || program.status===PROGRAMS_STATUS_TEXT.COMPLETED) && program.dbStatus!=='Active'"
                class="kz-delete-btn" id="loc_btnProgramRemove{{program.programName}}"
                (click)="$event.stopPropagation();deleteProgramPopup(program);"
                (keyup.enter)="$event.stopPropagation();deleteProgramPopup(program);">Remove</button>
        </div>
    </mat-card-content>
</mat-card>