<axis360-spinner-loader [isLoading]="isLoad" *ngIf="isLoad"></axis360-spinner-loader>
<div [hidden]="isLoad">
    <ng-container *ngIf="recommendedBooksRev?.length > 0">
        <h2 class="primary-heading-2">Also Available</h2>
        <axis360-titles-carousel [parentRoute]="parentRoute" [showCarouselSubTitle]="false" [titlesList]="recommendedBooksRev"></axis360-titles-carousel>
    </ng-container>
    <ng-container *ngIf="learningActivityBooks?.length > 0 ">
        <h2 class="primary-heading-2">Resource Hub</h2>
        <axis360-kz-learning-activity-new-carousel [learningActivityBooks]="learningActivityBooks" [parentRoute]="parentRoute">
        </axis360-kz-learning-activity-new-carousel>
    </ng-container>
    <ng-container *ngIf="recommendedBooksRev?.length === 0 && learningActivityBooks?.length === 0">
        <p class="primary-heading-2">No Related Items to display.</p>
    </ng-container>
</div>