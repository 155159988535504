import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutComponent } from '../../../../shared/data/third-party-model';
import { THIRD_PARTY_SUBTYPE, THIRD_PARTY_URLS } from '../../constants/third-party.constants';
import { seeAllClick } from '../../helper/third-party-util';
import { ThirdPartyAnalyticService } from '../../services/third-party-analytic.service';
import { ConfigService } from '@shared/services/config.service';
import { UserService } from '@shared/services/user.service';
import {
  BACK_GROUNDIMAGE_KID_TIER_ONE, BACK_GROUNDIMAGE_TEEN_TIER_ONE, BACK_GROUNDIMAGE_TIER_ONE
} from '@shared/constants/search.constant';
import { getBackgroundImageResize } from '@shared/helper/app.util';
import { ProfileInfoService } from '@shared/services/profile-info.service';

@Component({
  selector: 'axis360-third-party-featured',
  templateUrl: './third-party-featured.component.html',
  styleUrls: ['./third-party-featured.component.scss']
})
export class ThirdPartyFeaturedComponent implements OnInit {
  @Input() componentData: LayoutComponent;
  @Input() seeAllLink = THIRD_PARTY_URLS.TIER2;
  @Input() vendorCode = '';
  @Input() parentRoute: string;
  THIRD_PARTY_SUBTYPE = THIRD_PARTY_SUBTYPE;
  errorHandler = false;
  totalCount = 0;
  seeAllClick = seeAllClick;
  getBackgroundImageResize = getBackgroundImageResize;
  backgroundImageUrl: string;
  kidsZoneEnabled: boolean;
  axis360Enabled: boolean;
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public userService: UserService) { }
  /**
   *
   * errorHandler event
   */
  errorHandlerEvent(event) {
    this.errorHandler = event.error;
  }
  ngOnInit() {
    this.onResize();
  }

  checkTitleCount(event) {
    this.totalCount = event.count;
    if (this.componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS && event.count === 0) {
      this.errorHandler = true;
    }
  }

  onResize() {
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    if (!this.userService.userInfo?.isLoggedIn) {
      const backgroundImage = ((this.axis360Enabled && this.kidsZoneEnabled) || (this.axis360Enabled && !this.kidsZoneEnabled)) ?
        BACK_GROUNDIMAGE_TIER_ONE : BACK_GROUNDIMAGE_TEEN_TIER_ONE;
      this.backgroundImageUrl = this.getBackgroundImageResize(backgroundImage);
    } else {
      const profileAudMap = {
        Adult: BACK_GROUNDIMAGE_TIER_ONE,
        Teen: BACK_GROUNDIMAGE_TEEN_TIER_ONE,
        Kid: BACK_GROUNDIMAGE_KID_TIER_ONE
      };
      const getImage = profileAudMap[this.profileService.currentProfile.profileType];
      this.backgroundImageUrl = this.getBackgroundImageResize(getImage)
    }
  }
}
