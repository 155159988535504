import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { ConfigService } from '@shared/services/config.service';
import { ListBaseService } from '@shared/services/list-base.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { Observable } from 'rxjs';
import {
  BadgesAPIResponse,
  GetFeatureProgramAPIRes,
  InsightsAPIResponse,
  InterestAPIResponse,
  MyLibraryList,
  SaveInterestReq,
  SetGoalAPIResponse,
  SetGoalReq
} from '../data/home.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends ListBaseService {
  myLibraryListDataRecommendation = [];
  myLibraryList: MyLibraryList[];
  constructor(public http: HttpClient, public profileInfoService: ProfileInfoService, public configService: ConfigService) {
    super(http, profileInfoService, configService);
  }
  /**
   * Gets insights from API
   * @returns insights
   */
  getInsights(): Observable<InsightsAPIResponse> {
    return this.http.get<InsightsAPIResponse>(API_INFO.getInsights);
  }
  /**
   * Gets interests from API
   * @returns interests
   */
  getInterests(): Observable<InterestAPIResponse> {
    return this.http.get<InterestAPIResponse>(API_INFO.getInterests);
  }
  /**
   * Gets kz interests
   * @param [queryParams]
   * @returns kz interests
   */
  getKzInterests(queryParams?): Observable<InterestAPIResponse> {
    const queryParam = queryParams ? API_INFO.getInterests + queryParams : API_INFO.getInterests;
    return this.http.get<InterestAPIResponse>(queryParam);
  }
  /**
   * Gets badges from API
   * @returns badges
   */
  getBadges(): Observable<BadgesAPIResponse> {
    return this.http.get<BadgesAPIResponse>(API_INFO.getBadges);
  }
  /**
   * Saves interests via API
   * @param interests APi request
   * @returns interests
   */
  saveInterests(interests: SaveInterestReq): Observable<InterestAPIResponse> {
    return this.http.put<InterestAPIResponse>(API_INFO.saveInterests, interests);
  }
  /**
   * save interests
   * @param interests
   * @param queryParams
   * @returns save interests
   */
  kzSaveInterests(interests: SaveInterestReq, queryParams): Observable<InterestAPIResponse> {
    return this.http.put<InterestAPIResponse>(API_INFO.saveInterests + queryParams, interests);
  }
  /**
   * Sets goal via API
   * @param goal APi request
   * @returns goal
   */
  setGoal(goal: SetGoalReq): Observable<SetGoalAPIResponse> {
    return this.http.put<SetGoalAPIResponse>(API_INFO.setGoal, goal);
  }
  /**
   * Gets feature program from API
   * @returns feature program
   */
  getFeatureProgram(): Observable<GetFeatureProgramAPIRes> {
    return this.http.get<GetFeatureProgramAPIRes>(API_INFO.getFeatureProgram);
  }
}
