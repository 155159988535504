<div class="container kz-advanced-search">
    <form [formGroup]="advancedSearchForm" id="advancedSearchForm">
        <div class="search-header">
            <button class="close-icon" aria-label="close" cdkFocusInitial (click)="closeDialog()"
                (keydown.enter)="closeDialog()">
                <mat-icon svgIcon="kz-close" aria-hidden="true"></mat-icon>
            </button>
            <div class="kz-login-form search-input">
                <mat-form-field appearance="fill" id="advance-search-form"
                    [ngClass]="{'mat-form-field-no': typeInput == 'number'}">
                    <mat-label>Search for content</mat-label>
                    <input *ngIf="!(typeInput ==='number')" matInput placeholder="" (keypress)="inputValidation($event)"
                        (paste)="onPaste()" formControlName="term" id="searchText"
                        (keydown.enter)="advancedSearchForm.valid && !isLoading && search()" (keydown.arrowup)="(false)"
                        (keydown.arrowdown)="(false)" autocomplete="off">
                    <input *ngIf="(typeInput ==='number')" matInput placeholder="" minlength="13"
                        (keypress)="inputValidation($event)" (paste)="onPaste()" formControlName="term"
                        id="searchTextisbn" (keydown.enter)="advancedSearchForm.valid && !isLoading && search()"
                        (keydown.arrowup)="(false)" (keydown.arrowdown)="(false)" autocomplete="off" required>
                    <button aria-label="Clear Search" class="close-icon-search" matTooltip="Clear Search"
                        matTooltipClass="kz-card-tooltip" *ngIf="advancedSearchForm.controls.term.value.length"
                        (click)="clearSearch()" (keydown.enter)="clearSearch()"><mat-icon matSuffix
                            class="close-icon close-icon-fixed" svgIcon="kz-close"
                            aria-hidden="true"></mat-icon></button>
                    <mat-error
                        *ngIf="!(typeInput ==='number') && (advancedSearchForm.get('term').errors?.required || advancedSearchForm.get('term').hasError('whitespace')) && isRestCta"
                        aria-atomic="true">{{searchError}}</mat-error>
                    <p *ngIf="(typeInput ==='number') && ( (advancedSearchForm.get('term').errors?.required || advancedSearchForm.get('term').hasError('whitespace')) || (advancedSearchForm.get('term').errors?.maxlength || advancedSearchForm.get('term').errors?.minlength)) && isRestCta"
                         class="mat-error" role="alert">
                        Invalid ISBN, please enter 13 digits for the ISBN search
                    </p>
                    <mat-error
                        *ngIf="!(typeInput ==='number') && (advancedSearchForm.get('term').invalid && advancedSearchForm.get('term').touched) && !isRestCta"
                        aria-atomic="true">{{searchError}}</mat-error>
                    <mat-error
                        *ngIf="(typeInput ==='number') && (advancedSearchForm.get('term').invalid && advancedSearchForm.get('term').touched) && !isRestCta"
                        aria-atomic="true">Invalid ISBN, please enter 13 digits for the ISBN search</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="filter">
            <h2 class="heading">Advanced Search Options</h2>
            <div aria-labelledby="group_label_1" class="type divider kz-adv-desktop" *ngIf="!isMobile"
                role="radiogroup">
                <h3 id="group_label_1" class="sub-heading">Type</h3>
                <div aria-labelledby="kz-radio-group-label" class="kz-login-radio-btn-group mb-17" role="list">
                    <div *ngFor="let label of type; let i = index" class="kz-radio-btn-group" role="listitem">
                        <p-radioButton class="kz-radio-button" [label]="label.text" [value]="label.value"
                            [disabled]="(((advancedSearchForm.get('collections').value === disableCollection.STUDY_SITES || vendorCode === PRESS_READER ) && label.value !== ALL )
                        || (advancedSearchForm.get('collections').value === disableCollection.RESOURCES_HUB ) && (label.value !== ALL))" formControlName="searchType"
                            (onClick)="typeChange(label.value)" [(ngModel)]="typeValue" [inputId]="'desktopType'+i"
                            name="typedesktop" axis360PradioAda isRevers="false" ngDefaultControl>
                        </p-radioButton>
                    </div>
                </div>
            </div>
            <div class="type divider kz-adv-mobile" *ngIf="isMobile">
                <mat-accordion class="kz-adv-search-accordion" multi>
                    <mat-expansion-panel class="kz-adv-search-expansion-panel" [expanded]="true">
                        <mat-expansion-panel-header class="kz-adv-panel-header p-0">
                            <mat-panel-title>
                                <h3 class="sub-heading">Type</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div aria-labelledby="kz-radio-group-label" class="kz-login-radio-btn-group mb-17" role="list">
                            <div *ngFor="let label of type; let i = index" class="kz-radio-btn-group" role="listitem">
                                <p-radioButton class="kz-radio-button" [label]="label.text" [value]="label.value"
                                    [disabled]="(((advancedSearchForm.get('collections').value === disableCollection.STUDY_SITES || vendorCode === PRESS_READER ) && label.value !== ALL )
                                    || (advancedSearchForm.get('collections').value === disableCollection.RESOURCES_HUB ) && (label.value !== ALL))"
                                    formControlName="searchType" (onClick)="typeChange(label.value)"
                                    [(ngModel)]="typeValue" [inputId]="'mobileType'+i" name="typemobile"
                                    axis360PradioAda isRevers="false" ngDefaultControl>
                                </p-radioButton>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div aria-labelledby="group_label_2" class="type-collection divider kz-adv-desktop" *ngIf="!isMobile"
                role="radiogroup">
                <h3 class="sub-heading" id="group_label_2">Format</h3>
                <div aria-labelledby="kz-radio-group-label" class="kz-login-radio-btn-group mb-1" role="list">
                    <div *ngFor="let label of collections; let i = index" class="kz-radio-btn-group" role="listitem">
                        <ng-container *ngIf="label.enabled">
                            <p-radioButton class="kz-radio-button" id="loc_{{label.text}}"
                                [ngClass]="{'category-text': label.text === 'eBooks' || label.text === 'eAudios' || label.text === 'vBooks'  }"
                                [label]="label.text" [value]="label.value"
                                [disabled]="((label.value === disableCollection.RESOURCES_HUB && !showResources)||(label.value === 'eAudio' && (advancedSearchForm.get('axisattribute').value === 'RAL'))||(label.value === disableCollection.STUDY_SITES  && advancedSearchForm.get('searchType').value === 'title')||(label.value === disableCollection.STUDY_SITES || label.vendor_code === PRESS_READER) &&
                                (advancedSearchForm.get('searchType').value === 'author')|| (advancedSearchForm.get('searchType').value === 'isbn' && (label.value === disableCollection.STUDY_SITES || label.vendor_code === PRESS_READER)))"
                                formControlName="collections" (onClick)="collectionChange(label)" ngDefaultControl
                                [(ngModel)]="collectionValue" [inputId]="'desktopCollections'+i"
                                name="collectionsdesktop" axis360PradioAda isRevers="false">
                            </p-radioButton>
                        </ng-container>
                    </div>
                </div>
                <axis360-spinner-loader [loaderClass]="'advance-search-loader'"></axis360-spinner-loader>
            </div>
            <div class="type-collection divider kz-adv-mobile" *ngIf="isMobile">
                <mat-accordion class="kz-adv-search-accordion" multi>
                    <mat-expansion-panel class="kz-adv-search-expansion-panel" [expanded]="true">
                        <mat-expansion-panel-header class="kz-adv-panel-header p-0">
                            <mat-panel-title>
                                <h3 class="sub-heading">Format</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div aria-labelledby="kz-radio-group-label" class="kz-login-radio-btn-group mb-17" role="list">
                            <div *ngFor="let label of collections; let i = index" class="kz-radio-btn-group"
                                role="listitem">
                                <ng-container *ngIf="label.enabled">
                                    <p-radioButton class="kz-radio-button" id="loc_{{label.text}}"
                                        [ngClass]="{'category-text': label.text === 'eBooks' || label.text === 'eAudios' || label.text === 'vBooks'  }"
                                        [label]="label.text" [value]="label.value" [disabled]="((label.value === disableCollection.RESOURCES_HUB && !showResources)||(label.value === disableCollection.STUDY_SITES || (label.value === 'eAudio' && (advancedSearchForm.get('axisattribute').value === 'RAL')) || label.vendor_code === PRESS_READER) &&
                                        (advancedSearchForm.get('searchType').value !== ALL) )"
                                        formControlName="collections" (onClick)="collectionChange(label)"
                                        ngDefaultControl [(ngModel)]="collectionValue" [inputId]="'mobileCollections'+i"
                                        name="collectionsmobile" axis360PradioAda isRevers="false">
                                    </p-radioButton>
                                </ng-container>
                            </div>
                        </div>
                        <axis360-spinner-loader [loaderClass]="'advance-search-loader'"></axis360-spinner-loader>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div aria-labelledby="group_label_3" class="availability kz-check-box divider kz-adv-desktop"
                *ngIf="((!epopupValue || !isPPC) && !isMobile)" role="radiogroup">
                <h3 class="sub-heading" id="group_label_3">Availability</h3>
                <div class="kz-checkbox-sec">
                    <div *ngFor="let label of availability" class="kz-checkbox-group">
                        <mat-checkbox axis360MatCheckboxAda [value]="label.value" [checked]="label.checked"
                            (keydown.enter)="$event.preventDefault();$event.stopPropagation()" ngDefaultControl
                            (change)="avalibilityChange(label.value, $event.checked)"
                            [disabled]="!(advancedSearchForm.get('collections').value === GENERAL || advancedSearchForm.get('collections').value === EBook || advancedSearchForm.get('collections').value === EAudio)">{{label.text}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="availability divider kz-check-box kz-adv-mobile" *ngIf="(!isPPC && isMobile)">
                <mat-accordion class="kz-adv-search-accordion" multi>
                    <mat-expansion-panel class="kz-adv-search-expansion-panel" [expanded]="true">
                        <mat-expansion-panel-header class="kz-adv-panel-header p-0">
                            <mat-panel-title>
                                <h3 class="sub-heading">Availability</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="kz-checkbox-sec">
                            <div *ngFor="let label of availability" class="kz-checkbox-group">
                                <mat-checkbox axis360MatCheckboxAda [value]="label.value" [checked]="label.checked"
                                    (keydown.enter)="$event.preventDefault();$event.stopPropagation()" ngDefaultControl
                                    (change)="avalibilityChange(label.value, $event.checked)"
                                    [disabled]="!(advancedSearchForm.get('collections').value === GENERAL || advancedSearchForm.get('collections').value === EBook || advancedSearchForm.get('collections').value === EAudio)">{{label.text}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <axis360-spinner-loader [loaderClass]="'advance-search-loader'"></axis360-spinner-loader>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="format kz-check-box divider kz-adv-desktop" *ngIf="isHiddenFormat && !isPPC">
                <h3 class="sub-heading">Format</h3>
                <div class="kz-checkbox-sec">
                    <div *ngFor="let label of format" class="kz-checkbox-group">
                        <mat-checkbox axis360MatCheckboxAda [value]="label.value" [checked]="label.checked"
                            (change)="formatChange(label.value, $event.checked)"
                            [disabled]="(advancedSearchForm.get('collections').value === disableCollection[advancedSearchForm.get('collections').value]
                        || advancedSearchForm.get('collections').value === disableCollection.STUDY_SITES || advancedSearchForm.get('collections').value === 'Videos' || advancedSearchForm.get('collections').value === 'VBooks') || label.value === FORMAT_TYPE.All && (advancedSearchForm.get('axisattribute').value === 'RAL')
                        || (label.value === 'eAudio' && (advancedSearchForm.get('axisattribute').value === 'RAL'))">{{label.name}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="format divider kz-check-box kz-adv-mobile" *ngIf="isHiddenFormat && isPPC">
                <mat-accordion class="kz-adv-search-accordion" multi>
                    <mat-expansion-panel class="kz-adv-search-expansion-panel" [expanded]="true">
                        <mat-expansion-panel-header class="kz-adv-panel-header p-0">
                            <mat-panel-title>
                                <h3 class="sub-heading">Format</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="kz-checkbox-sec">
                            <div *ngFor="let label of format" class="kz-checkbox-group">
                                <mat-checkbox axis360MatCheckboxAda [value]="label.value" [checked]="label.checked"
                                    (change)="formatChange(label.value, $event.checked)"
                                    [disabled]="(advancedSearchForm.get('collections').value === disableCollection[advancedSearchForm.get('collections').value]
                                || advancedSearchForm.get('collections').value === disableCollection.STUDY_SITES || advancedSearchForm.get('collections').value === 'Videos' || advancedSearchForm.get('collections').value === 'VBooks') || (label.value === 'eAudio' && (advancedSearchForm.get('axisattribute').value === 'RAL')) || label.value === FORMAT_TYPE.All && (advancedSearchForm.get('axisattribute').value === 'RAL')">{{label.name}}
                                </mat-checkbox>
                            </div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div aria-labelledby="group_label_4" class="attributes kz-check-box kz-adv-desktop"
                *ngIf="( !epopupValue || !isPPC && !isMobile)" role="radiogroup">
                <h3 class="sub-heading" id="group_label_4">Attributes</h3>
                <div class="kz-checkbox-sec">
                    <div *ngFor="let label of attributes" class="kz-checkbox-group">
                        <mat-checkbox axis360MatCheckboxAda ngDefaultControl [value]="label.value"
                            (keydown.enter)="$event.preventDefault();$event.stopPropagation()"
                            (change)="attributeChange($event.checked);label.checked=$event.checked"
                            [checked]="label.checked" [disabled]="attributeDisable()">{{label.value}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="attributes kz-check-box kz-adv-mobile" *ngIf="(!isPPC && isMobile)">
                <mat-accordion class="kz-adv-search-accordion" multi>
                    <mat-expansion-panel class="kz-adv-search-expansion-panel" [expanded]="true">
                        <mat-expansion-panel-header class="kz-adv-panel-header p-0">
                            <mat-panel-title>
                                <h3 class="sub-heading">Attributes</h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="kz-checkbox-sec">
                            <div *ngFor="let label of attributes" class="kz-checkbox-group">
                                <mat-checkbox axis360MatCheckboxAda ngDefaultControl [value]="label.value"
                                    (keydown.enter)="$event.preventDefault();$event.stopPropagation()"
                                    (change)="attributeChange($event.checked);label.checked=$event.checked"
                                    [checked]="label.checked"
                                    [disabled]="attributeDisable()">{{label.value}}</mat-checkbox>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="action-button search-footer">
            <button [disabled]="!advancedSearchForm.dirty" mat-raised-button type="button" class="btn-secondary-blue"
                (click)="clearForm()">Reset</button>
            <button [disabled]="isLoading" mat-raised-button id="loc_btnSearch" class="btn-primary-blue" type="submit"
                (click)="search()">Search</button>
        </div>
    </form>
</div>