import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MyStuffService } from 'app/my-stuff/shared/services/my-stuff.service';
import { Subscription, noop } from 'rxjs';
import { IBookDetail, IBookDetailById, IRecommendTitle, ISeriesTitleDetails, ITitleDetails, ListContentTitleOptions } from '../../data/title-details.model';
import { TitleDetailsService } from '../../services/title-details.service';
import { Router } from '@angular/router';

@Component({
  selector: 'axis360-title-recommendations',
  templateUrl: './title-recommendations.component.html',
  styleUrls: ['./title-recommendations.component.scss']
})
export class TitleRecommendationsComponent implements OnInit, OnDestroy {
  @Input() titleDetailsData: ITitleDetails;
  @Input() recommendedTitleDetails: IRecommendTitle[];
  @Input() isLoad: boolean;
  @Output() moreLikeTabsHide = new EventEmitter();
  showCarouselSubTitle: boolean = false;
  showCarouselTitle: boolean = false;
  isbn: string;
  itemId: string;
  bookIdArray = [];
  seriesIdArray = [];
  recommendedBooks: IBookDetail[];
  copyRecommendedBooks: IBookDetail[];
  recommendedSeriesBooks: IBookDetail[];
  isLoading = true;
  showShimmerRecommendedCards = true;
  showShimmerSeriesCards = true;
  buttonViews: ITitleDetails | {};
  recommendationsBtn = [];
  buttonSeriesViews: ITitleDetails | {};
  bookDetails: IBookDetail;
  isRecommendable: boolean = false;
  seriesTitle: ISeriesTitleDetails;
  parentRoute = "detail";
  recommendationSubscription: Subscription
  constructor(
    private titleDetailsService: TitleDetailsService,
    public myStuffService: MyStuffService,
    public router: Router) { }

  /**
   *
   * To ngOnInit Changes TitleRecommendationsComponent
   *
   */
  ngOnInit(): void {
    this.recommendedBooks = this.titleDetailsService.recommendedTitleBooks;
    this.recommendedSeriesBooks = this.titleDetailsService.recommendedSeriesTitleBooks;
    if (!this.recommendedBooks && !this.recommendedSeriesBooks) {
      this.getRecommendedTitles();
    } else {
      this.showShimmerRecommendedCards = this.recommendedBooks.length > 0;
      this.showShimmerSeriesCards = this.recommendedSeriesBooks.length > 0;
    }
    const regExString = /(?:view\/)((.[\s\S]*))(?:\/title)/ig; //set ig flag for global search and case insensitive
    const regExString1 = regExString.exec(this.router.url);
    this.parentRoute = regExString1 ? regExString1[1] : '';
    let newParentRoute: string;
    if (this.parentRoute) {
      newParentRoute = this.parentRoute;
    } else if (this.router.url.includes('subject')) {
      newParentRoute = 'subject';
    } else if (this.router.url.includes('title/list')) {
      newParentRoute = 'list';
    } else {
      newParentRoute = '';
    }
    this.parentRoute = newParentRoute;
    this.recommendationSubscription = this.titleDetailsService.getRecommendationList().subscribe(list => {
      if (list) {
        this.recommendedBooks = undefined;
        this.recommendedSeriesBooks = undefined;
        this.isLoading = false;
        this.recommendedTitleDetails = list;
        this.getRecommendedTitles();
      }
    });
  }

  /**
   *  Get the recommended titles.
   */
  async getRecommendedTitles() {
    try {
      await Promise.all([this.getRecommendedTitleDetails(), this.getSeriesTitles()]);
    } catch (e) {
      this.recommendedBooks = [];
      this.recommendedSeriesBooks = [];
      this.showShimmerRecommendedCards = false;
      this.showShimmerSeriesCards = false;
      this.isLoading = false;
    }
  }
  /**
  * To get Recommended Title Details from API
  */
  async getRecommendedTitleDetails() {
    try {

      const { series } = this.titleDetailsData;
      if (this.recommendedTitleDetails.length === 0) {
        (!series) && this.moreLikeTabsHide.emit('moreLikeItem');
        this.recommendedBooks = [];
        this.titleDetailsService.recommendedTitleBooks = [];
        this.showShimmerRecommendedCards = false;
        this.isLoading = false;
        return;
      }
      this.bookIdArray = this.recommendedTitleDetails.map((book: any) => book.itemId);
      this.getRecommendedBookDetails();
      this.isLoading = false;
    } catch (e) {
      this.recommendedBooks = [];
      this.titleDetailsService.recommendedTitleBooks = [];
      this.showShimmerRecommendedCards = false;
      this.recommendedTitleDetails?.length === 0 && this.moreLikeTabsHide.emit('moreLikeItem');
      this.isLoading = false;
    }
  }

  /**
   * To get details of recommended books
   */
  async getRecommendedBookDetails() {
    const { series } = this.titleDetailsData || {};
    const { titleDetailsActions } = this.titleDetailsService;
    const collectionType = titleDetailsActions?.availability?.collectionType || '';
    if (this.bookIdArray.length === 0) {
      this.showShimmerRecommendedCards = false;
      return;
    }
    //retreive the recommended books based on Id for current title
    const bookDetailsResponse = await this.getBookDetail(this.bookIdArray, collectionType);
    const recommendedBooks = [];
    if (bookDetailsResponse.length === 0) {
      this.recommendedBooks = [];
      this.titleDetailsService.recommendedTitleBooks = [];
      this.isLoading = false;
      this.showShimmerRecommendedCards = false;
      return;
    } else {
      bookDetailsResponse.forEach((books: IBookDetailById) => {
        this.bookDetails = {
          Title: books.title,
          ISBN: books.isbn,
          Author: books.author,
          ImageUrl: books.imageUrl,
          ItemId: books.itemId,
          FormatType: books.formatType,
          TotalQuantity: books.totalQuantity,
          OnOrderQuantity: books.onOrderQuantity,
          CollectionType: books.collectionType,
          IsAvailable: books.isAvailable,
          IsRTV: books.isRTV,
          runTime: books.runTime,
          bookLength: books.bookLength,
          titleUrl: `${window.origin}/ng/view/title/${books.itemId}/${books.isbn}`
        };
        recommendedBooks.push(this.bookDetails);
      });
      this.isLoading = false;
    }
    const recommendedBooksBtn = {items: recommendedBooks};
    this.getActionViewButtonForLibraryList(recommendedBooksBtn, false);
    this.copyRecommendedBooks = recommendedBooks;
    if (!series) {
      this.recommendedBooks = [...this.copyRecommendedBooks];
      this.showShimmerRecommendedCards = this.recommendedBooks.length > 0;
      this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
    }
  }

  /**
   * To get BookDetails from API
   * @param [_bookIdArray] pass the ItemId Array
   */
  async getBookDetail(_bookIdArray: string[], collectionType = ''): Promise<any> {
    try {
      return (await this.titleDetailsService.getRelatedBooksById(_bookIdArray, collectionType)).items;
    } catch (e) { }
  }



  /**
   *
   * To get button Views in RecommendedBook details
   * @param [itemId]
   * @param [isbn]
   * @returns
   */
  async getButtonViewRecommendedBook(itemId: string, isbn: string) {
    return await this.titleDetailsService.getButtonViews(itemId, isbn);
  }

  async getSeriesTitles() {
    const { series } = this.titleDetailsData || {};
    if (!series) {
      this.recommendedSeriesBooks = [];
      this.titleDetailsService.recommendedSeriesTitleBooks = [];
      this.showShimmerSeriesCards = false;
      (this.recommendedTitleDetails.length === 0) && this.moreLikeTabsHide.emit('moreLikeItem');
      return;
    }
    this.seriesTitle = await this.titleDetailsService.getSeriesByTitle(this.titleDetailsData.series, 'series');
    if (!this.seriesTitle) {
      this.recommendedBooks = this.copyRecommendedBooks ? [...this.copyRecommendedBooks] : [];
      this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
      this.recommendedSeriesBooks = [];
      this.titleDetailsService.recommendedSeriesTitleBooks = [];
      this.showShimmerSeriesCards = false;
      (this.recommendedTitleDetails.length === 0 && this.recommendedSeriesBooks.length === 0) && this.moreLikeTabsHide.emit('moreLikeItem');
      return;
    }
    this.seriesTitle.sortBy = this.seriesTitle.refiner.find((x: { text: string; }) => x.text === 'Sort By')?.items.find((x: { id: string; }) => x.id === 'rdbRelevancy')?.value
    this.seriesTitle.availability = this.seriesTitle.refiner.find((x: { text: string; }) => x.text === 'Availability')?.items.find(x => x.id === 'rdbAllTitles')?.value
    this.getListContentTitle(this.seriesTitle);
  }

  async getListContentTitle(seriesTitle: ISeriesTitleDetails) {
    const options: ListContentTitleOptions = {
      sortby: seriesTitle.sortBy,
      sortorder: -1,
      term: seriesTitle.term,
      searchby: seriesTitle.searchBy,
      availability: seriesTitle.availability,
      format: "",
      releasedate: "",
      addeddate: "",
      language: "",
      agelevel: "",
      collections: seriesTitle.defaultSearchCollection,
      pageSize: 24,
      page: 1,
      ereadalong: "",
      itemId: this.titleDetailsData.itemId
    }
    const listContentTitle = await this.titleDetailsService.getListContentTitle(options);
    let listContentTitleItems = listContentTitle;
    if (listContentTitleItems.Items.length === 0) {
      this.recommendedBooks = this.copyRecommendedBooks ? [...this.copyRecommendedBooks] : [];
      this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
      this.recommendedSeriesBooks = [];
      this.titleDetailsService.recommendedSeriesTitleBooks = [];
      this.showShimmerSeriesCards = false;
      (this.recommendedTitleDetails.length === 0 && listContentTitleItems.Items.length === 0) && this.moreLikeTabsHide.emit('moreLikeItem');
      return;
    }
    this.seriesIdArray = listContentTitleItems.Items.map((book: any) => book.ItemId);
    this.getSeriesTitlesBookDetails(this.seriesIdArray);
  }

  async getSeriesTitlesBookDetails(seriesIdArray: string[]) {
    const { titleDetailsActions } = this.titleDetailsService;
    const collectionType = titleDetailsActions?.availability?.collectionType || '';
    if (seriesIdArray.length === 0) {
      return;
    }
    //retreive the recommended books based on Id for current title
    const bookDetailsResponse = await this.getBookDetail(seriesIdArray, collectionType);
    const recommendedSeriesBooks = [];
    if (bookDetailsResponse.length === 0) {
      this.recommendedSeriesBooks = [];
      this.showShimmerSeriesCards = false;
      this.recommendedBooks = this.copyRecommendedBooks ? [...this.copyRecommendedBooks] : [];
      this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
      this.titleDetailsService.recommendedSeriesTitleBooks = [];
      return;
    } else {
      bookDetailsResponse.forEach((series: IBookDetailById) => {
        this.bookDetails = {
          Title: series.title,
          ISBN: series.isbn,
          Author: series.author,
          ImageUrl: series.imageUrl,
          ItemId: series.itemId,
          FormatType: series.formatType,
          TotalQuantity: series.totalQuantity,
          OnOrderQuantity: series.onOrderQuantity,
          CollectionType: series.collectionType,
          IsAvailable: series.isAvailable,
          IsRTV: series.isRTV,
          runTime: series.runTime,
          bookLength: series.bookLength,
          titleUrl: `${window.origin}/ng/view/title/${series.itemId}/${series.isbn}`
        };
        recommendedSeriesBooks.push(this.bookDetails);
      });
    }
    this.isLoading = false;
    const recommendedSeriesBooksBtn = { items: recommendedSeriesBooks }
    this.getActionViewButtonForLibraryList(recommendedSeriesBooksBtn, true);
    this.recommendedSeriesBooks = recommendedSeriesBooks;
    this.recommendedBooks = this.copyRecommendedBooks?.filter(rb => !this.recommendedSeriesBooks?.some(rsb => rb.ISBN === rsb.ISBN));
    this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
    this.titleDetailsService.recommendedSeriesTitleBooks = this.recommendedSeriesBooks;
    this.showShimmerRecommendedCards = this.recommendedBooks?.length > 0;
    this.showShimmerSeriesCards = this.recommendedSeriesBooks?.length > 0;
  }

  /**
 * Generate action button for the my stuff card
 * @param listConstent list content
 */
  async getActionViewButtonForLibraryList(listConstent, seriesBook?: boolean) {
    this.myStuffService.getMyLibraryListActionButtonV2(listConstent).subscribe((actionButtonResponse) => {
      if (seriesBook) {
        this.recommendedSeriesBooks?.map((elm) => {
          listConstent.items.map(items => {
            if (elm.ISBN === items.ISBN) {
              elm.titleStateInfo = { ...actionButtonResponse.find(item => item.isbn === elm.ISBN) };
              elm.DisplayStatus = actionButtonResponse.find(item => item.isbn === elm.ISBN)?.displayStatus || '';
              const bookStatus = elm.DisplayStatus !== ''?`Book Status ${elm.DisplayStatus} ,`: ''
              const formatType = elm.FormatType === 'ABT'? 'eAudio': 'eBook'
              elm.altLabel = `${bookStatus} Format ${formatType}, Book ${elm.Title}, Author ${elm.Author}, ${elm.runTime}`
            }
          })
        });
        this.recommendedBooks = this.copyRecommendedBooks?.filter(rb => !this.recommendedSeriesBooks?.some(rsb => rb.ISBN === rsb.ISBN));
        this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
        this.titleDetailsService.recommendedSeriesTitleBooks = this.recommendedSeriesBooks;
        return;
      }
      this.copyRecommendedBooks?.map((elm) => {
        listConstent.items.map(items => {
          if (elm.ISBN === items.ISBN) {
            elm.titleStateInfo = { ...actionButtonResponse.find(item => item.isbn === elm.ISBN) };
            elm.DisplayStatus = actionButtonResponse.find(item => item.isbn === elm.ISBN)?.displayStatus || '';
            const bookStatus = elm.DisplayStatus !== ''?`Book Status ${elm.DisplayStatus} ,`: ''
            const formatType = elm.FormatType === 'ABT'? 'eAudio': 'eBook'
            elm.altLabel = `${bookStatus} Format ${formatType}, Book ${elm.Title}, Author ${elm.Author}, ${elm.runTime}`
          }
        });
      });
      this.recommendedBooks = [...this.copyRecommendedBooks];
      this.titleDetailsService.recommendedTitleBooks = this.recommendedBooks;
    }, noop);
  }
  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.recommendationSubscription.unsubscribe();
  }

}
