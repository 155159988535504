import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { IButtonDetails } from '@titledetails/shared/data/title-details.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FeatureRefiner, Filters, IBrowseItemList, IRefinerItemList, ISubMenuItemList, ITitleContent, Refiner, SearchObj } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class TitleListService extends EngageBaseService {
  public setFilters$ = new Subject<Filters>();
  filterData$ = this.setFilters$.asObservable();
  public clearfilter$ = new Subject<Filters>();
  filterChange$ = this.clearfilter$.asObservable();
  public setReset$ = new Subject<boolean>();
  resetChange$ = this.setReset$.asObservable();
  filterData: Filters;
  tempFilterData: Filters;
  isFirstItemListSelection = false;
  elementId: string;
  isSelectedSubjectDisable = new Subject<any>();
  initialGetListContent = new BehaviorSubject<boolean>(false);
  initialCategoryGetListContent = new Subject<any>();
  initialSetSubjectList = new Subject<any>();
  public sortRefiner$ = new Subject<Refiner[]>();
  sortRefinerData$ = this.sortRefiner$.asObservable();
  public refinersUpdate$ = new Subject<any>();
  refinersUpdateData$ = this.refinersUpdate$.asObservable();
  public updateFilters$ = new Subject<any>();
  updateFiltersData$ = this.updateFilters$.asObservable();
  public subjectAndfeaturedDataPills$ = new Subject<string>();
  subjectAndfeaturedDataPillsValue$ = this.subjectAndfeaturedDataPills$.asObservable();
  public checkEmptySubjectItems$ = new Subject<any>();
  checkEmptySubjectItemsData$ = this.checkEmptySubjectItems$.asObservable();
  loadFeaturedLists = new Subject<any>();
  removePillsAferCheckSubject = new Subject<any>();
  isLoadAnnouncerbyPillsRemove = new Subject<any>();
  isRemoveisReadOnly = new Subject<boolean>();;
  refreshBreadcrumb = false;
  loadBrowseLists = new BehaviorSubject<boolean>(false);
  selectedSubjectTitle: string;
  selectedSubject: string;
  subCategory: string;
  subCategoryLevel3: string;
  isTitleDetail: boolean = false;
  pageRetainInfo = {
    listContent: [],
    pageYOffset: 0,
    pageNumber: 0
  }
  tabCount: number = 0;

  /**
   * Sets filters
   * @param filter
   */
  setFilters(filter: Filters) {
    this.setFilters$.next(filter);
    this.filterData = filter;
  }

  /**
   * Sets filters
   * @param filter
   */
  setSortFilters(filter: Refiner[]) {
    this.sortRefiner$.next(filter);
  }

  /**
   * Sets RefinerUpdate list
   * @param filter
   */
  setRefinerUpdate(filter: any) {
    this.refinersUpdate$.next(filter);
  }

  /**
  * Sets setUpdateFilters list
  * @param filter
  */
  setUpdateFilters(filter: any) {
    this.updateFilters$.next(filter);
  }

  /**
  * Set SubjectAndfeaturedDataPills
  * @param menuType
  */
  setSubjectAndfeaturedDataPills(menuType: string) {
    this.subjectAndfeaturedDataPills$.next(menuType);
  }

  /**
  * Set checkEmptySubjectItems
  * @param "string"
  */
  checkEmptySubjectItems(menuType: any) {
    this.checkEmptySubjectItems$.next(menuType);
  }

  /**
   * Clears filter
   * @param filter
   */
  clearFilter(filter: Filters) {
    this.clearfilter$.next(filter);
  }
  /**
   * Gets categories
   * @param url
   * @returns categories
   */
  getCategories(url): Observable<any> {
    return this.http.get<any>(url);
  }
  /**
   * Gets refiners
   * @param url
   * @returns refiners
   */
  getRefiners(url): Observable<SearchObj> {
    return this.http.get<SearchObj>(url);
  }

  /**
   * Get SubMenuView Browse
   * @param [browseItemList]
   * @returns SubMenuItemList
   */
  getSubMenuViewBrowse(browseItemList: IBrowseItemList): Observable<ISubMenuItemList> {
    return this.http.post<ISubMenuItemList>(API_INFO.getSubMenuViewBrowse, browseItemList);
  }
  /**
   * Get Refiner For Browse
   * @param [refinerItemList]
   * @returns search refiner list
   */
  getRefinerForBrowse(refinerItemList: IRefinerItemList): Observable<SearchObj> {
    return this.http.post<SearchObj>(API_INFO.getRefinerBrowse, refinerItemList);
  }

  /**
   * Get List ContentForBrowse
   * @param [titleContentList]
   * @returns Title ListContent ForBrowse
   */
  getListContentForBrowse(titleContentList: ITitleContent): Observable<any> {
    return this.http.post<any>(API_INFO.getTitleListV1, titleContentList);
  }

  /**
   * Get list content of title list service
   */
  getListContent = (url): Promise<any> =>
    this.http.get<any>(url).toPromise();
  /**
   * Get feature list refiners of title list service
   */
  getFeatureListRefiners = (id, type): Promise<FeatureRefiner> =>
    this.http.get<any>(API_INFO.getFeatureListRefiners.replace('<controller>', `${type}`).replace('<catid>', `${id}`)).toPromise();
  /**
  * API Call for getting the Button views
  * @param [itemId]
  * @param [isbn]
  * @returns
  */
  getButtonViews = (itemId: string, isbn: string): Promise<IButtonDetails> =>
    this.http.get<IButtonDetails>(API_INFO.getButtonViews.replace('<itemId>', `${itemId}`).replace('<isbn>', `${isbn}`)).toPromise();

}
