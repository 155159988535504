<div class="card-images">
    <ng-container *ngFor="let img of cardImgs;let j = index">
        <div class="cover-poster" [ngClass]="(j==0)?'bt-img1':(j>3)?'bt-img-none':'bt-img2'"
            [style.top.px]="(j!=0) ? j * 10 : 0" [style.left.px]="(j!=0) ? j * 10 : 0">
                <div>
                    <div class="hidden-title" *ngIf="!img.src">
                        <mat-icon matTooltip="This title is no longer available.">info_outline</mat-icon>
                        <div class="hidden-title-img"></div>
                    </div>                    
                    <img *ngIf="img.src" [src]="img.src" alt=""/>
                </div>                    
            </div> 
    </ng-container>
</div>