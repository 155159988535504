import { NgModule } from '@angular/core';
import { KzProgramsRoutingModule } from './kz-programs-routing.module';
import { KzProgramDetailsComponent } from '../kz-program-details/kz-program-details.component';
import { KzProgramsComponent } from './kz-programs.component';
import { KzProgramsCardsComponent } from './kz-programs-cards/kz-programs-cards.component';
import { KzProgramCardComponent } from './shared/components/kz-program-card/kz-program-card.component';
import { KzProgramViewAllComponent } from '../kz-program-view-all/kz-program-view-all.component';
import { KzProgramCardCarouselComponent } from './shared/components/kz-program-card-carousel/kz-program-card-carousel.component';
import { KzProgramsCardImagesComponent } from './shared/components/kz-programs-card-images/kz-programs-card-images.component';
import { KzProgramsTitleComponent } from './shared/components/kz-programs-title/kz-programs-title.component';
import { SharedModule } from '@shared/shared.module';
import { KzJoinProgramPopupComponent } from './shared/components/kz-join-program-popup/kz-join-program-popup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { KzProgramDetailsMainComponent } from '../kz-program-details-main/kz-program-details-main.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KzProgramAddOwnTitleComponent } from './shared/components/kz-program-add-own-title/kz-program-add-own-title.component';
@NgModule({
  declarations: [
    KzProgramsComponent,
    KzProgramDetailsComponent,
    KzProgramCardComponent,
    KzProgramsCardImagesComponent,
    KzProgramsTitleComponent,
    KzProgramCardCarouselComponent,
    KzProgramViewAllComponent,
    KzProgramsCardsComponent,
    KzProgramDetailsMainComponent,
    KzJoinProgramPopupComponent,
    KzProgramAddOwnTitleComponent
  ],

  imports: [KzProgramsRoutingModule, MatTooltipModule, MatDividerModule, MatFormFieldModule, MatInputModule, SharedModule, FormsModule,
    ReactiveFormsModule],
  providers: []
})
export class KzProgramsModule { }
