import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '@shared/services/account.service';
import { AccountAnalyticsService } from './shared/services/account.analytics.service';
export interface AccountModel {
  openLoginForm?: boolean;
  emailUpdate?: boolean;
  fromActionHandler?: boolean;
}
@Component({
  selector: 'axis360-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  openLoginForm = false;
  emailUpdate = false;
  fromActionHandler = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public modaldata: AccountModel,
    private loginDialog: MatDialogRef<AccountComponent>,
    public accountService: AccountService,
    public changeDetectorRef: ChangeDetectorRef,
    public accountAnalyticsService: AccountAnalyticsService

  ) { }
  ngOnInit(): void {
    this.openLoginForm = this.modaldata.openLoginForm;
    this.emailUpdate = this.modaldata.emailUpdate;
    this.accountService.fromActionHandler = this.modaldata.fromActionHandler
  }
  closeDialog() {
    this.loginDialog.close(false);
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
