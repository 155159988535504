import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TitleDetailsComponent } from '@titledetails/title-details.component';
import { KzProgramDetailsMainComponent } from '../kz-program-details-main/kz-program-details-main.component';
import { KzProgramDetailsComponent } from '../kz-program-details/kz-program-details.component';
import { KzProgramsCardsComponent } from './kz-programs-cards/kz-programs-cards.component';
import { KzProgramsComponent } from './kz-programs.component';
import { KzProgramAddOwnTitleComponent } from './shared/components/kz-program-add-own-title/kz-program-add-own-title.component';
import { KzSearchResultsComponent } from 'app/core/kz-search-results/kz-search-results.component';
import { KzSearchResultsMainComponent } from 'app/core/kz-search-results/kz-search-results-main.component';
import { KzSearchListViewMainComponent } from 'app/core/kz-search-results/kz-search-list-view-main/kz-search-list-view-main.component';
import { KzSearchListViewComponent } from 'app/core/kz-search-results/kz-search-list-view/kz-search-list-view.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HTTPInterceptor } from '@shared/http.interceptor';

const routes: Routes = [
  {
    path: '',
    component: KzProgramsComponent,
    children: [
      {
        path: '',
        component: KzProgramsCardsComponent
      },
      {
        path: 'open',
        component: KzProgramsCardsComponent
      }

    ]
  },
  {
    path: ':programId',
    component: KzProgramDetailsMainComponent,
    data: { breadcrumb: { alias: 'programName' } },
    children: [
      {
        path: '',
        component: KzProgramDetailsComponent
      },
      {
        path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
        data: { breadcrumb: { info: 'titledetail' } }
      },
      {
        path: 'title/:itemId', component: TitleDetailsComponent,
        data: { breadcrumb: { info: 'titledetail' } }
      },
      {
        path: 'addtitle', component: KzProgramAddOwnTitleComponent
      },
      {
        path: 'search',
        component: KzSearchResultsMainComponent,
        data: { breadcrumb: { alias: 'searchtext' } },
        children: [
          { path: '', component: KzSearchResultsComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'title/:itemId/', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          }

        ]
      },
      {
        path: 'search/:format',
        component: KzSearchListViewMainComponent,
        data: { breadcrumb: { alias: 'searchformat' } },
        children: [
          { path: '', component: KzSearchListViewComponent },
          {
            path: 'title/:itemId/:isbn', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          },
          {
            path: 'title/:itemId/', component: TitleDetailsComponent,
            data: { breadcrumb: { info: 'titledetail' } },
          }

        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true
    },
  ]
})
export class KzProgramsRoutingModule { }
