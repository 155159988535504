<div class="kz-goals_insight-container">
    <mat-accordion class="goals-accordion" id="goals-accordion" multi>
        <mat-expansion-panel (opened)="myShelfAnalyticsService.trackGoalsEvent('EXPAND_GOALS_ACC');expandGoals(true)"
            (closed)="myShelfAnalyticsService.trackGoalsEvent('COLLAPSE_GOALS_ACC');expandGoals(false)"
            class="goals-insights-panel" [expanded]="expandState" (click)="expand()">
                <mat-expansion-panel-header class="goals-insights-header" tabindex="0">
                    <mat-panel-title>
                        <h2 class="kz_title heading-1" id="loc_labelGoals&Insights" aria-label="goals & insights">goals
                            &
                            insights</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
            <div class="goals-bagdes">
                <axis360-goals-insights (progressiveGoalsEmit)="progressiveGoalsEmit()"></axis360-goals-insights>
                <axis360-badges-card-carousel
                    (progressiveBadgesEmit)="progressiveBadgesEmit()"></axis360-badges-card-carousel>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>