<div class="title-list" (window:resize)="onResize()">
    <div class="titlelist-breadcrumb">
        <axis360-breadcrumb [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs"
            [breadcrumbsClass]="'kz-breadcrumb'"></axis360-breadcrumb>
    </div>
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isBBSPage" aria-live="polite" id="isBBSPage">{{titleListAnnoucePage}}</p>
    <!-- <a class="skip-refiners" id="loc_btnSkiptoMain" [href]="skipLink">Skip the Refiners</a> -->
    <ng-container *ngIf="!showErrorMsg; else errorMsg">
        <div class="title-list-container mb-3">
            <nav>
                <div class="refiner-sec"
                    [ngClass]="{' kz-browse-refiner': selectedSubject?.type === TITLE_LIST.SUBJECT}">
                    <div class="col-lg-4 col-xs-12 sticky-refiner">
                        <axis360-title-list-refiners (defaultcategory)="setCategory($event)"
                            (selectedId)="selectedId($event)" [isMobileResponsive]="isMobile"
                            [selectedSubject]="selectedSubject"></axis360-title-list-refiners>
                    </div>
                </div>
            </nav>
            <div class="title-details-info" id="title_list_result" tabindex="-1">
                <axis360-title-list-view (filter)="changeSubject($event)" [selectedCategoryId]="selectedCategoryId"
                    [pageTitle]="pageName" [parentRoute]="parentRoute"></axis360-title-list-view>
            </div>
        </div>
    </ng-container>
    <ng-template #errorMsg>
        <div class="title-list-no-title-sec">
            <p class="title-list-no-title-heading">Browse Subjects cannot be displayed. Please use Search.</p>
        </div>
    </ng-template>
</div>