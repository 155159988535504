import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AdaptiveCard } from '../../helper/adaptive-card';
import * as AdaptiveCards from 'adaptivecards';
import { THIRD_PARTY_VENDOR_CODE } from '../../constants/third-party.constants';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ThirdPartyService } from '../../services/third-party.service';
import { randomNumderGenerator } from '@shared/helper/app.util';
@Component({
  selector: 'axis360-third-party-base',
  templateUrl: './third-party-base.component.html',
  styleUrls: ['./third-party-base.component.scss']
})
export class ThirdPartyBaseComponent implements AfterViewInit {
  @Input() componentData = {} as any;
  @Input() componentTemplate;
  @Input() categoryClass = '';
  @Input() vendorCode = '';
  @Input() parentRoute = '';
  @Input() isCurrentlyCheckout = false;
  @Input() activeMyStuff = '';
  @Output() acOnExecuteAction = new EventEmitter<AdaptiveCards.Action>();
  @ViewChild('adaptiveCardSection') cardsSection: ElementRef;
  adaptiveCard: AdaptiveCard;
  queryData: string;
  itemId: string;
  ISBN: string;
  collectionId: string;
  routerLinkURL: string;
  @Input() componentClass: string;
  playerLinkURL: string;
  vendorCodeParam = 'vendor_code';
  randomNumderGenerator = randomNumderGenerator;
  constructor(
    public datePipe: DatePipe,
    public cdref: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public thirdPartyService: ThirdPartyService
  ) {
    this.adaptiveCard = new AdaptiveCard((action) => {
      this.executeAction(action);
    });
  }
  executeAction(action) {
    setTimeout(() => {
      document.querySelectorAll('.third-party-refiners  .ac-container.ac-adaptiveCard .ac-container').forEach((n) => {
        if (n?.children[0]?.className.includes('ac-selectable')) {
          n?.children[0]?.setAttribute('aria-expanded', n?.children[0]?.children[4]?.getAttribute('aria-expanded') === 'true' ? 'true' : 'false'),
          n?.children[0]?.setAttribute('role', 'button')
        }
      });
      document.querySelectorAll('.third-party-refiners .ac-actionSet button').forEach((n) => {
        n?.removeAttribute('title');
      });
    }, 0);
    this.acOnExecuteAction.emit(action);
    if (action.data && action.data.type) {
      if (action.data.type === 'search') {
        const searchbtn: HTMLElement = document.getElementById('tier2-heading');
        searchbtn?.setAttribute('tabindex', '-1');
        setTimeout(() => {
          searchbtn?.removeAttribute('aria-hidden');
        }, 2000);
        this.thirdPartyService.tierTwoRefinerSearch.next('search');
      }
      setTimeout(() => {
        if (action.data.type === 'reset') {
          const resetbtn: HTMLElement = document.getElementById('Resetbtn');
          resetbtn && resetbtn.focus();
        }
      }, 2000);
    }
  }
  getPlayerURLLink() {
    const {
      titleID = '',
      iSBN = '',
      ISBN = '',
      ItemId = '',
      ContentCollectionId = '',
      collectionId = '',
      isbn = '',
      itemId = ''
    } = (this.componentData as any) || {};
    this.queryData = titleID || itemId || ItemId;
    this.itemId = titleID || itemId || ItemId;
    this.ISBN = iSBN || isbn || ISBN;
    this.collectionId = collectionId || ContentCollectionId;
    this.playerLinkURL = this.isCurrentlyCheckout ? '/Action/PlayNowClick?itemId=' + this.itemId + '&ISBN=' + this.ISBN + '&contentCollectionId=' + this.collectionId : null;
    return this.playerLinkURL;
  }
  getRouterParams(vendorCode: string) {
    if (vendorCode === THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
      const { latestIssue, displayName } = this.componentData as any;
      const { cid = '', key = '', issueDate = '' } = (latestIssue as any) || {};
      this.queryData = cid;
      this.routerLinkURL = '/view/' + this.parentRoute + '/details/' + this.queryData;
      return { vendor_code: vendorCode, key, issueDate, title: displayName, ref: this.randomNumderGenerator() };
    }
    const {
      titleID = '',
      iSBN = '',
      ISBN = '',
      ItemId = '',
      Title = '',
      ContentCollectionId = '',
      Series = '',
      bookTitle = '',
      series = '',
      collectionId = '',
      isbn = '',
      itemId = '',
      title = ''
    } = (this.componentData as any) || {};
    const { cardTitle: cardTitle = '', title: header } = this.activatedRoute.snapshot.queryParams;
    const titleAnnounce = cardTitle ? cardTitle : header;
    this.queryData = titleID || itemId || ItemId;
    this.itemId = titleID || itemId || ItemId;
    this.ISBN = iSBN || isbn || ISBN;
    this.collectionId = collectionId || ContentCollectionId;
    this.routerLinkURL = this.isCurrentlyCheckout ? null : '/view/' + this.parentRoute + '/details/' + this.queryData;
    return {
      ISBN: iSBN || isbn || ISBN,
      vendor_code: vendorCode,
      title: bookTitle || title || Title,
      series: series || Series,
      collectionId: collectionId || ContentCollectionId,
      titleAnnounce,
      ref: this.randomNumderGenerator()
    };
  }
  isRefinerExpxenedOrNot(): void {
    if (this.componentClass && this.componentClass === "featuredClass") {
      return;
    }
    document.querySelectorAll('.third-party-refiners  .ac-container.ac-adaptiveCard .ac-container').forEach((n) => {
      const elm = n.children[0];
      const classname = elm.className;
      if (elm) {
        if (classname.includes('ac-selectable')) {
          const id = n?.children[2]?.id ? n?.children[2]?.id : '';
          n?.children[0]?.setAttribute('aria-controls', n.children[2]?.id ? n.children[2]?.id : '');
          n?.children[0]?.setAttribute('aria-expanded', 'false');
          n?.children[2]?.setAttribute('role', 'region');
          n?.children[0]?.setAttribute('id', 'refine' + id.charAt(id.length - 1));
          n?.children[2]?.setAttribute('aria-labelledby', 'refine' + id.charAt(id.length - 1));
          n?.children[0]?.children[2].children[0].children[0].setAttribute('tabindex', '-1');
          n?.children[2]?.children[0].children[0].children[0].removeAttribute('tabindex');
          n?.children[0]?.children[4].children[0].children[0].setAttribute('tabindex', '-1');
          n?.children[0]?.children[2].children[0].setAttribute('aria-hidden', 'true')
          n?.children[0]?.children[4].children[0].setAttribute('aria-hidden', 'true');
          setTimeout(() => {
            n.children[0].setAttribute('role', 'button');
          }, 10);
        }
      }
    });
  }
  isEllipesForNewspaperAndMagazineLabel() {
    const elm = this.cardsSection.nativeElement.querySelector('#newspaper_magazine_ribbon_text');
    let title = 'Published in ';
    const { altLabel } = this.componentData as any;
    const multicountry = 'and multiple countries'
    if (elm?.textContent?.split('| ').length > 1) {
      title = title + elm.textContent?.split('| ')[0] + multicountry;
    } else {
      title = title + elm?.textContent?.split('| ')[0];
    }
    elm?.setAttribute('title', altLabel);
    if (elm?.textContent.length > 6) {
      elm.textContent = elm.textContent.substring(0, 6) + '...';
    }
  }
  radioBtnAlign(): void {
    setTimeout(() => {
      document.querySelectorAll('.ac-choiceSetInput-expanded').forEach((elem) => {
        Array.from(elem.children).forEach((elm) => {
          const labelText = elm.children[2];
          const inputElm = elm.children[0];
          elm.classList.add('custom-radio');
          labelText.innerHTML = labelText.textContent.replace('-', '&ndash;');
          elm.prepend(labelText);
          elm.append(inputElm);
          elm.addEventListener('click', this.addClassFn);
          this.addClassFn();
        })
        elem?.closest('.ac-choiceSetInput-expanded')?.removeAttribute('tabindex');
      });
      document.querySelectorAll('.ac-choiceSetInput-multiSelect div').forEach((elm) => {
        elm?.setAttribute('role', 'listitem');
        elm?.closest('.ac-choiceSetInput-multiSelect')?.setAttribute('role', 'list');
        elm?.closest('.ac-choiceSetInput-multiSelect')?.removeAttribute('tabindex');
        elm?.querySelector('label')?.setAttribute('aria-hidden', 'true');
        Array.from(elm.children).forEach((child) => {
          child.addEventListener('click', () => {
            setTimeout(() => {
              document.querySelectorAll('.third-party-refiners .ac-actionSet button').forEach((n) => {
                n?.removeAttribute('title');
              });
            });
          });
        });
      })
    })
  }
  addClassFn() {
    document.querySelectorAll('.ac-input input[type=radio]').forEach((elm) => {
      elm.parentElement.classList.remove('custom-radio-checked');
    })
    document.querySelectorAll('.ac-input input[type=radio]:checked').forEach((elm) => {
      elm.parentElement.classList.add('custom-radio-checked');
    })
    setTimeout(() => {
      document.querySelectorAll('.third-party-refiners .ac-actionSet button').forEach((n) => {
        n?.removeAttribute('title');
      });
    });
  }
  ngAfterViewInit(): void {
    this.cdref.detectChanges();
    this.renderCatSection();
    this.isRefinerExpxenedOrNot();
    this.isEllipesForNewspaperAndMagazineLabel();
    this.radioBtnAlign();
    this.checkoutBtnAction();   
    const insnLabelText: HTMLElement = this.cardsSection.nativeElement.querySelector('#adp_card_title_details_isbn');
    if (insnLabelText) {
      insnLabelText.innerHTML = 'I S B N';
      insnLabelText.classList.add('isbn-label');
    }
    const downloadSize: HTMLElement = document.querySelector('#adp_card_title_details_download_size_value');
    downloadSize?.setAttribute('aria-label', downloadSize.innerText.replace('MB', 'Mega Byte'));
    document.querySelectorAll('.third-party-card-common .ac-adaptiveCard').forEach((n) => {
      const title = n?.children[0]?.children[6]?.innerHTML;
      n?.children[0]?.children[0]?.children[0]?.setAttribute('tabindex', '-1');
      n?.children[0]?.children[0]?.children[0]?.setAttribute('alt', title);
      n?.children[0]?.children[0]?.children[0]?.setAttribute('role', 'none');
      n?.querySelector('#newspaper_magazine_book-img')?.classList.add('news-magazine-img');
      n?.children[0]?.children[2]?.children[0]?.removeAttribute('tabindex');
      n?.querySelector('#newspaper_magazine_book-poster-bg')?.classList.add('newspaper-magazine-book-poster-bg');
      n?.querySelector('#adp_card_title_text_bottom')?.classList.add('adp-card-title-text-bottom');
      n?.querySelector('#adp_card_newspaper_format_icon')?.classList.add('adp-card-newspaper-format-icon');
      n?.querySelector('#adp_format_text_wrapper')?.classList.add('newspaper-magazine-format_text_wrapper');
      n?.removeAttribute('tabindex');
      if (title) {
        n?.children[0]?.children[0]?.setAttribute('title', title);
      }
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-checkout-videos').forEach((n) => {
      let title = this.isCurrentlyCheckout ?
        n?.children[0]?.children[0]?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt') :
        n?.children[0]?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      const duration = this.isCurrentlyCheckout ?
        n?.children[0]?.children[0]?.children[2]?.children[2]?.children[0]?.innerHTML :
        n?.children[0]?.children[2]?.children[2]?.children[0]?.innerHTML;
      const durationText = (['Vbook', 'Video', 'Videobooks', 'Videobook'].includes(duration)) ? '' : duration;
      const durationReplace = durationText?.replace('h', 'hours').replace('m', 'minutes').replace('Mins', 'Minutes');
      if (navigator.userAgent.match(/(Mac)/i)) {
        setTimeout(() => {
          const videoCarosel = n?.querySelector('#video-poster-bg');
          if (videoCarosel) {
            title = videoCarosel?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
          }
          const videoBookCarosel = n?.querySelector('#vbook-poster-bg');
          if (videoBookCarosel) {
            title = videoBookCarosel?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
          }
        }, 100);
      }      
      n?.setAttribute('aria-label', title + ' ' + (durationReplace ? 'Duration' + durationReplace : ''));
      n?.removeAttribute('aria-label');
      if (this.isCurrentlyCheckout) {
        setTimeout(() => {
          n?.children[0]?.children[0]?.children[0]?.setAttribute('title', title);
          n?.children[0]?.children[0]?.children[0]?.querySelector('.ac-selectable')?.removeAttribute('tabindex');
        }, 100);
      }     
      n?.children[0]?.setAttribute('aria-hidden', 'true');
    });
    document.querySelectorAll('.kz-my-stuff .third-party-card-common.third-party-card-checkout-videos').forEach((n) => {
      const title = n?.children[0]?.children[0]?.children[4]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      const duration = n?.children[0]?.children[0]?.children[0]?.children[0]?.innerHTML;
      n?.children[0]?.children[0]?.setAttribute('aria-label', duration?.replace('(s)', 's') + ' ' + title);
      n?.children[0]?.children[0]?.children[0]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.children[0]?.children[2]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.children[0]?.children[4]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.removeAttribute('aria-hidden');
      n?.children[0]?.children[0]?.setAttribute('tabindex', '-1');
      /*const removeBtnRole = n?.children[0]?.children[2]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0];
      removeBtnRole?.setAttribute('tabindex', '-1');
      removeBtnRole?.setAttribute('aria-hidden', 'true');*/
      n?.children[0]?.children[0]?.children[2]?.children[0]?.children[0]?.removeAttribute('role');
      n?.children[0]?.children[0]?.children[2]?.children[0]?.children[0]?.removeAttribute('tabindex');
    });
    setTimeout(() => {
      document.querySelectorAll('.kz-third-party-PRESSRDR .third-party-card-common').forEach((n) => {
        const state = n?.children[0]?.children[0]?.children[0]?.getAttribute('title');
        n?.setAttribute('aria-label', state);
        n?.children[0]?.setAttribute('aria-hidden', 'true');
      });
    }, 0);
    document.querySelectorAll('.third-party-card-pressrdr.third-party-tier-two-card').forEach((n) => {
      const state = n?.children[0]?.children[0]?.children[0]?.getAttribute('title');
      n?.setAttribute('aria-label', state);
      n?.children[0]?.setAttribute('aria-hidden', 'true');
    });
    document.querySelectorAll('.tier-three-wrap .kz-third-party-PRESSRDR .third-party-card-common').forEach((n) => {
      n?.classList.add('newspaper-magazine-detail');
      n?.setAttribute('aria-label', '');
    });
    document.querySelectorAll('.no-refiners .third-party-tier-two-container .parent-elm').forEach((n) => {
      const state = n?.children[0]?.children[0]?.children[0]?.children[0]?.getAttribute('title');
      n?.children[0]?.children[0]?.children[0]?.children[2]?.setAttribute('title', state);
      n?.children[0]?.setAttribute('aria-label', state);
    });
    document.querySelectorAll('.third-party-tier-two-card.third-party-card-common .ac-adaptiveCard').forEach((n) => {
      n?.querySelector('#newspaper_magazine_ribbon_text')?.classList.add('newspaper-magazine-ribbon-text');
    });
    document.querySelectorAll('.kz-third-party-PRESSRDR .third-party-card-common .ac-adaptiveCard').forEach((n) => {
      n?.querySelector('#newspaper_magazine_ribbon_text')?.classList.add('newspaper-magazine-ribbon-text');
      const title = n?.children[0]?.children[4]?.children[0]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      n?.children[0]?.children[2]?.setAttribute('title', title);
    });
    document.querySelectorAll('.third-party-card-checkout-videos.third-party-card-common .ac-adaptiveCard').forEach((n) => {
      n?.querySelector('#newspaper_magazine_ribbon_text')?.classList.remove('newspaper-magazine-ribbon-text');
    });
    document.querySelectorAll('.third-party-card-checkout-videos.third-party-card-common.third-party-tier-two-card').forEach((n) => {
      const title = n?.children[0]?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      const duration = n?.children[0]?.children[2]?.children[2]?.children[0]?.innerHTML;
      const durationText = (['Vbook', 'Video', 'Videobooks', 'Videobook'].includes(duration)) ? '' : duration;
      const durationReplace = durationText?.replace('h', 'hours').replace('m', 'minutes').replace('Mins', 'Minutes');
      n?.setAttribute('aria-label', title + ' ' + (durationReplace ? ('Duration' + durationReplace) : ''));
      n?.children[0]?.setAttribute('aria-hidden', 'true');
    });
    document.querySelectorAll('.third-party-articles .ac-adaptiveCard .ac-columnSet').forEach((n) => {
      const title = n?.children[2]?.children[2]?.innerHTML;
      n?.children[2]?.children[2]?.setAttribute('title', title);
    });
    document.querySelectorAll('.third-party-category-card .ac-adaptiveCard .ac-container').forEach((n) => {
      const title = n?.children[2]?.textContent;
      n?.setAttribute('title', title);
      n?.children[2]?.setAttribute('title', title);
      n?.parentElement?.removeAttribute('tabindex');
      n?.setAttribute('tabindex', '0');
      n?.setAttribute('role', 'link');
      n?.children[0]?.classList.add('newspaper-categories');
      n?.children[1]?.classList.add('newspaper-categories-separator');
      n?.children[2]?.classList.add('newspaper-categories-icon-name');
      n?.children[0]?.setAttribute('aria-hidden', 'true');
      n?.children[2]?.setAttribute('aria-hidden', 'true');
    });
    document.querySelectorAll('.third-party-video-category .ac-adaptiveCard .ac-container').forEach((n) => {
      const title = n?.children[2]?.textContent;
      n?.children[2]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.setAttribute('title', title);
      n?.children[0]?.children[0]?.setAttribute('tabindex', '-1');
      const elem = n?.children[0];
      elem.classList.add('video-widgets-category');
      if (elem?.children?.length === 0) {
        elem.classList.add('vbook-widgets-category');
      }
      const category = n?.children[2];
      n?.setAttribute("aria-label", title);
      if (category && category.scrollHeight > 45) {
        n?.children[2]?.setAttribute('title', title);
      }
    });
    document.querySelectorAll('.third-party-card-checkout-videos.third-party-card-common .ac-adaptiveCard').forEach((n) => {
      const title = n?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      const authorTitle = n?.children[4]?.innerHTML;
      n?.children[0]?.children[0]?.children[0]?.setAttribute('tabindex', '-1');
      if (title) {
        n?.children[0]?.children[0]?.setAttribute('title', title);
      }
      n?.children[4]?.setAttribute('title', authorTitle);
    });

    document.querySelectorAll('.third-party-articles').forEach((n) => {
      n?.children[0]?.removeAttribute('tabindex');
      const title = n?.children[0]?.children[0]?.children[0]?.children[2]?.children[0]?.innerHTML;
      n?.children[0]?.children[0]?.children[0]?.children[0]?.children[0].children[0].setAttribute('alt', title);
      n?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0].removeAttribute('tabindex');
    });

    document
      .querySelectorAll('.tier-three-wrap .third-party-card-details .ac-adaptiveCard .ac-columnSet .ac-container.ac-selectable')
      .forEach((n) => {
        n?.removeAttribute('role');
      });
    document
      .querySelectorAll('.third-party-card-details.third-party-videos-detail .ac-adaptiveCard .ac-columnSet .ac-container.ac-selectable')
      .forEach((n) => {
        n?.setAttribute('role', 'link');
      });
    document.querySelectorAll('.third-party-card-details.third-party-videos-detail .ac-container.ac-adaptiveCard').forEach((n) => {
      n?.removeAttribute('tabindex');
    });
    document.querySelectorAll('.third-party-video-category .ac-adaptiveCard').forEach((n) => {
      n?.removeAttribute('tabindex');
      n?.children[0]?.setAttribute('role', 'link');
    });
    document
      .querySelectorAll(
        '.tier-three-wrap .third-party-card-details .ac-adaptiveCard > .ac-container:last-child .ac-columnSet div:first-of-type.ac-container'
      )
      .forEach((n) => {
        n?.removeAttribute('tabindex');
      });
    document.querySelectorAll('.tier-three-wrap .third-party-card-details .ac-adaptiveCard .ac-pushButton').forEach((n) => {
      n?.removeAttribute('aria-label');
      n?.children[0]?.setAttribute('aria-hidden', 'true');
      n?.setAttribute('id', n?.children[0]?.innerHTML + 'btn');
    });

    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      const title = n?.children[4]?.children[0]?.children[0].children[0]?.getAttribute('alt');
      // this.isCurrentlyCheckout ? n?.children[0]?.removeAttribute('tabindex') : n?.children[0]?.setAttribute('tabindex', '0');
      n?.children[0]?.removeAttribute('aria-label');
      n?.setAttribute('title', title);
      n?.children[4]?.setAttribute('title', '');
      n?.children[0]?.children[0]?.children[0]?.children[0]?.setAttribute('tabindex', '-1');
      n?.children[0]?.children[0]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.children[0]?.children[0]?.removeAttribute('tabindex');
      n?.children[0]?.children[0]?.children[0]?.children[0]?.setAttribute('tabindex', '0');


      const removeRole = n?.children[0]?.children[2]?.children[0]?.children[0];
      removeRole?.removeAttribute('role');
      removeRole?.removeAttribute('tabindex');
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      n?.children[4]?.children[2]?.children[0]?.classList.add('mystuff-third-party-info');
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      n?.children[4]?.classList.add('mystuff-play-bg');
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      const pastCheckOut = n?.children[0]?.children[0]?.textContent;
      if (pastCheckOut && pastCheckOut.includes('Past Checkout')) {
        n?.children[0]?.children[0]?.classList.add('past-checkout-bg');
      }
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      n?.children[6]?.classList.add('mystuff-play-video');
    });

    document
      .querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard .ac-pushButton')
      .forEach((n: HTMLElement) => {
        n?.removeAttribute('title');
        n?.removeAttribute('aria-label');
        n?.removeAttribute('role');
        n.style.display = 'unset';
      });


    document.querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard').forEach((n) => {
      if (n?.children[4]?.children[0]?.children.length !== 0) {
        const title = n?.children[4]?.children[0]?.children[0].children[0]?.getAttribute('alt');
        n?.setAttribute('title', title);
        n?.children[4]?.setAttribute('title', '');
      }
      n?.children[4]?.setAttribute('title', '');
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard').forEach((n) => {
      n?.children[4]?.children[2]?.children[0]?.classList.add('mystuff-third-party-info');
    });

    document.querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard').forEach((n) => {
      if (n?.children[4]?.children[0]?.children.length !== 0) {
        const elem = n?.children[2]?.children[0]?.children[0];
        elem?.removeAttribute('role');
        elem?.setAttribute('tabindex', '-1');
        n?.children[4]?.classList.add('title-banner-play-bg');
      }
    });

    document.querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard').forEach((n) => {
      const pastCheckOut = n?.children[0]?.children[0]?.textContent;
      if (pastCheckOut && pastCheckOut.includes('Past Checkout')) {
        n?.children[0]?.classList.add('past-checkout-bg');
      }
    });

    document.querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard').forEach((n) => {
      n?.children[6]?.classList.add('title-banner-play-video');
    });

    document
      .querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard .ac-pushButton')
      .forEach((n: HTMLElement) => {
        n?.removeAttribute('title');
        n?.removeAttribute('aria-label');
        n?.removeAttribute('role');
        n.style.display = 'unset';
      });

    document.querySelectorAll('.third-party-video-widgets-category .ac-adaptiveCard').forEach((n) => {
      const title = n?.children[0]?.children[2]?.textContent;
      n?.children[0]?.children[2]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.setAttribute('title', title);
      n?.children[0]?.setAttribute('role', 'link');
      n?.removeAttribute('tabindex');
      n?.children[0]?.children[0]?.setAttribute('aria-hidden', 'true');
      const elem = n?.children[0]?.children[0];
      elem?.classList?.add('video-widgets-category');
      if (elem?.children?.length === 0) {
        elem.classList.add('vbook-widgets-category');
      }
      const category = n?.children[0]?.children[2];
      n?.children[0].setAttribute("aria-label", n?.children[0]?.getAttribute('title'));
      if (category && category.scrollHeight < 45) {
        n?.children[0]?.removeAttribute('title')
      }
    });
    document.querySelectorAll('.third-party-refiners .ac-adaptiveCard').forEach((n) => {
      const refinerButton = n?.children[4]?.children[1];
      if (refinerButton && refinerButton?.children[0] === undefined) {
        refinerButton?.remove();
      }
      n.removeAttribute('tabindex');
    });
    document.querySelectorAll('.third-party-refiners .ac-actionSet button').forEach((n) => {
      n?.setAttribute('id', n?.children[0]?.innerHTML + 'btn');
      n?.removeAttribute('title');
      n?.children[0]?.setAttribute('aria-hidden', 'true');
    });
    document.querySelectorAll('.third-party-refiners  .ac-container.ac-adaptiveCard .ac-container').forEach((n) => {
      if (n?.children[0]?.className.includes('ac-selectable')) {
        const id = n?.children[2]?.id ? n?.children[2]?.id : '';
        const getClass = n?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('id');
        if (getClass) {
          const container = document?.getElementById(getClass);
          // Get all input elements within the container
          const labels = container?.querySelectorAll("label");
          const inputs = container?.querySelectorAll("input");
          // Loop through each input element and add aria-hidden="true"
          inputs?.forEach(input => {
            input?.removeAttribute("aria-hidden");
          });
          labels?.forEach(label => {
            label?.setAttribute("aria-hidden", "true");
          });
        }
        n?.children[0]?.setAttribute('aria-controls', n?.children[2]?.id ? n?.children[2]?.id : '');
        n?.children[2]?.setAttribute('role', 'region');
        n?.children[0]?.removeAttribute('role');
        n?.children[0]?.setAttribute('id', 'refine' + id.charAt(id.length - 1));
        n?.children[2]?.setAttribute('aria-labelledby', 'refine' + id.charAt(id.length - 1));
      }
    });
    /* tslint:disable */
    document.querySelectorAll('.tier-three-wrap .third-party-card-details.third-party-news-paper .ac-adaptiveCard').forEach((n: HTMLElement) => {
      n?.children[0]?.children[2]?.classList.add('tier-three-container');
      n?.children[0]?.children[4]?.classList.add('tier-three-container-center');
      n?.removeAttribute('tabindex');
      const title = n?.children[0]?.children[2]?.children[2]?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      n?.children[0]?.children[2]?.children[2]?.setAttribute('aria-label', title);
      const readingButtonCTA = n?.children[0]?.children[4]?.children[0]?.children[0]?.children[0]?.children[0]?.children[0];
      const selectIssueButtonCTA = n?.children[0]?.children[4]?.children[2]?.children[0]?.children[0]?.children[0]?.children[0];
      const titleReading = readingButtonCTA?.getAttribute('title') || '';
      const titleSelectIssue = selectIssueButtonCTA.getAttribute('title') || '';
      readingButtonCTA.setAttribute('aria-label', titleReading);
      selectIssueButtonCTA.setAttribute('aria-label', titleSelectIssue);
      n?.children[0]?.children[2]?.children[2]?.setAttribute('role', 'img');
      n?.children[0]?.children[2]?.children[2]?.children[0]?.setAttribute('aria-hidden', 'true');
      n?.children[0]?.children[2]?.children[2]?.children[2]?.setAttribute('aria-hidden', 'true');
      const lang = n?.children[2]?.children[4]?.children[2]?.children[0]?.innerHTML;
      if (lang && lang === 'EN') {
        const language = n?.children[2]?.children[4]?.children[2]?.children[0];
        language.innerHTML = '<span aria-hidden="true">EN</span><span class="sr-only third-party-card-languages">English</span>';
      }
      if (this.activatedRoute.snapshot.queryParams[this.vendorCodeParam] === THIRD_PARTY_VENDOR_CODE.PRESSRDR) {
        const latestIssues = n?.children[2]?.children[0]?.children[2];
        latestIssues.innerHTML = `<span class="issuedatesec">${this.datePipe.transform(
          latestIssues?.children[0]?.textContent,
          'MMM dd, yyyy'
        )}</span>`;
      }
    });
    document.querySelectorAll('#author_groups').forEach((n) => {
      n?.children[0]?.setAttribute('tabindex', '0');
    });
    document.querySelectorAll('#subject_groups').forEach((n) => {
      n?.children[0]?.setAttribute('tabindex', '0');
    });
    document.querySelectorAll('#countryarrow').forEach((n) => {
      if (!navigator.userAgent.match(/(Android|iPhone|Mac|iPad)/i)) {
        n.setAttribute('role', 'button');
      }
    });
    document.querySelectorAll('#countryuparrow').forEach((n) => {
      n?.children[0].removeAttribute('role');
      n?.children[0].setAttribute('aria-label', 'Show less countries');
      n?.children[0].removeAttribute('title');
      const uparrowbutton = navigator.userAgent.match(/(Mac|iPhone|iPad)/i) ? 'button' : 'strong';
      n?.children[0].setAttribute('role', uparrowbutton);
    });
    document.querySelectorAll('#countrydownarrow').forEach((n) => {
      n?.children[0].removeAttribute('role');
      n?.children[0].setAttribute('aria-label', 'Show all countries');
      n?.children[0].removeAttribute('title');
      const updownbutton = navigator.userAgent.match(/(Mac|iPhone|iPad)/i) ? 'button' : 'strong';
      n?.children[0].setAttribute('role', updownbutton);
    });

    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      if (this.activeMyStuff) {
        const getArrayValue = (this.activeMyStuff === 'checkouts') ? 4 : 2;
        const textContentNode  = n?.childNodes[0]?.childNodes[0]?.childNodes[0]?.textContent;
        let getAltTextValue;
        let altText;
        if ( ((this.activeMyStuff === 'wishlist') && !textContentNode)|| this.activeMyStuff === 'history') {
          const removeTabIndex = n?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0] as any;
          removeTabIndex && removeTabIndex?.removeAttribute('tabindex');
          removeTabIndex && removeTabIndex?.removeAttribute('role');
      }
        if ( (this.activeMyStuff === 'wishlist') && textContentNode) {
          getAltTextValue = n?.childNodes[0]?.childNodes[4]?.childNodes[0]?.childNodes[0]?.childNodes[0] as any;
          getAltTextValue?.setAttribute('role', 'none');
          altText = getAltTextValue?.getAttribute('alt');
        } else {
          getAltTextValue = n?.childNodes[0]?.childNodes[getArrayValue]?.childNodes[0]?.childNodes[0]?.childNodes[0] as any;
          getAltTextValue?.setAttribute('role', 'none');
          altText = getAltTextValue?.getAttribute('alt');
        }
        if (altText) {
          const setButtonTextValue = n?.childNodes[2]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0] as any;
          const regex = /Book title ([^,]+), Author/;
          // Execute the regex on the input string
          const match = altText?.match(regex);
          const btnCTA = setButtonTextValue?.getAttribute('aria-label');
          const bookTile = btnCTA + ' ' +  (match ? match[1] : '') ;
          setButtonTextValue?.setAttribute('aria-label', bookTile);
          (getAltTextValue as any)?.removeAttribute('alt');
          setButtonTextValue?.removeAttribute('title');
          const btn = n?.childNodes[2];
          setTimeout(() => {
            const getCurrentItemId = (n?.childNodes[0] as any)?.getAttribute('id');
            const setCurrentItemId = this.componentData.ItemId ? this.componentData.ItemId : getCurrentItemId;
            document.querySelectorAll(`.my-stuff-id-${setCurrentItemId}`).forEach((ref) => {
              if (ref.childNodes.length === 1) {
                if (this.activeMyStuff !== 'history' && this.activeMyStuff !== 'wishlist') {
                  btn && ref?.insertBefore(btn, ref?.nextElementSibling);
                } else {
                  btn && ref?.insertBefore(btn, (ref?.childNodes[0] as any)?.nextElementSibling);
                }
              } else {
                const refNode = ref?.querySelector('.third-party-card-checkout-videos.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard');
                refNode && refNode?.removeChild(refNode?.lastElementChild);
              }
            });
          }, 500);
        }
      }
    });
    document.querySelectorAll('.third-party-card-common.third-party-card-mystuff-videos .ac-adaptiveCard').forEach((n) => {
      const dueDate = n?.children[0]?.children[0]?.textContent;
      if (dueDate && dueDate.includes('Due') && !dueDate.includes('Due In')) {
        n?.children[0]?.children[0]?.classList.add('due-date-dark-top-banner-bg');
      }
    });

    document.querySelectorAll('.third-party-card-common.third-party-card-title-banner .ac-adaptiveCard').forEach((n) => {
      const dueDate = n?.children[0]?.children[0]?.textContent;
      if (dueDate && dueDate.includes('Due') && !dueDate.includes('Due In')) {
        n?.children[0]?.classList.add('due-date-dark-top-banner-bg');
      }
    });

    document
      .querySelectorAll(
        '.third-party-video-category .ac-textBlock:not(.video-widgets-category), .third-party-video-widgets-category .ac-textBlock:not(.video-widgets-category)'
      )
      .forEach((el: HTMLElement) => {
        if (el.scrollHeight < 45) {
          el.classList.add('no-ellipsis');
        }
      });
    const magazineTitle = document.querySelectorAll('#newspaper_magazine_title');
    magazineTitle.forEach((heading) => {
      if (heading) {
        const headingTextContent = heading.textContent;
        if (navigator.userAgent.match(/(Safari|iPhone|iPod|iPad)/i) && this.componentClass?.includes('third-party-news-paper')) {
          heading.innerHTML = `<h1 class="tier-three-heading heading-2">
          <span class='kz-visually-hidden'>${headingTextContent}</span>
          <span aria-hidden="true">${headingTextContent}</span></h1>`;
        }
      }
    });
    const titleNewspaper = document.querySelectorAll('#adp_card_title_text_container, #adp_format_text_wrapper');
    titleNewspaper.forEach((heading) => {
      if (heading) {
        heading.setAttribute('aria-hidden', 'true');
      }
    });
    if (window.matchMedia('screen and (max-width: 1199px)').matches) {
      document.querySelectorAll('.tier-three-wrap .third-party-card-details .ac-adaptiveCard').forEach((n) => {
        n.removeAttribute('tabindex');
      });
    }
    this.onResize();
    const element: HTMLElement = document.querySelector('#defaultcountrytext');
    const elementexpen: HTMLElement = document.querySelector('#countrytext');
    if (elementexpen) {
      elementexpen.setAttribute('aria-hidden', 'true');
    }
    if (element) {
      element.setAttribute('aria-label', element.textContent?.replace(/\|/g, ''));
      element.setAttribute('aria-hidden', 'true');
      const hiddenElm = document.createElement('span');
      hiddenElm.innerHTML = element.textContent?.replace(/\|/g, '');
      hiddenElm.classList.add('sr-only');
      hiddenElm.classList.add('third-party-card-languages');
      element.parentElement.querySelector('.sr-only')?.remove();
      element.insertAdjacentElement('beforebegin', hiddenElm);
    }
    document.querySelectorAll('.video-widgets-category').forEach((n) => {
      if (n?.children[0]?.className === 'ac-image') {
        n?.children[0]?.setAttribute('alt', ' ');
      }
    });
  }
  async renderCatSection() {
    try {
      const content = this.adaptiveCard.getRenderedContent(this.componentData, this.componentTemplate);
      this.cardsSection.nativeElement.append(content);
    } catch (e) { }
  }
  isEllipsisActive(element) {
    if (element?.clientWidth < element?.scrollWidth) {
      const style = element?.currentStyle || window.getComputedStyle(element);
      return style.textOverflow === 'ellipsis';
    }
    return false;
  }
  onResize() {
    const element: HTMLElement = document.querySelector('#defaultcountrytext');
    const upArrow: HTMLElement = document.querySelector('#countryuparrow');
    const countryArrow: HTMLElement = document.querySelector('#countryarrow');
    if (!this.isEllipsisActive(element)) {
      document.querySelectorAll('#countrydownarrow').forEach((n: HTMLElement) => {
        n.style.display = 'none';
      });
      upArrow?.setAttribute('aria-hidden', 'true');
      countryArrow?.setAttribute('aria-hidden', 'true');
    } else {
      document.querySelectorAll('#countrydownarrow').forEach((n: HTMLElement) => {
        n.style.display = 'flex';
      });
      upArrow?.setAttribute('aria-hidden', 'false');
      countryArrow?.setAttribute('aria-hidden', 'false');
    }
  }
  checkoutBtnAction() {
    setTimeout(() => {
      document.querySelectorAll('.tier-three-wrap .third-party-card-details .ac-adaptiveCard .ac-pushButton').forEach((n: HTMLElement) => {
        const btn = n?.children[0]?.innerHTML;
        if (btn && btn === 'Play') {
          setTimeout(
            () => {
              n.focus();
            },
            navigator.userAgent.match(/(Mac|Android|iPhone|iPod|iPad)/i) ? 2000 : 0
          );
          const renewbtn = document.getElementById('Renewbtn');
          renewbtn && renewbtn.setAttribute('tabindex', '0');
        }
      });
    }, 1000);
  }
   extractTitle(input: string): string {
    const titleMatch = input?.match(/Book title (.+?),/);
    return titleMatch ? titleMatch[1] : '';
  }
}
