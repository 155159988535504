<div class="preference-goal-modal-align-center">
    <div class="goal-modal axis-common">
        <mat-dialog-content class="mat-typography">
            <button mat-dialog-close class="close-goal" aria-label="close" (click)="closeDialog()"
                (keydown.enter)="closeDialog()">
                <mat-icon class="icon-keyboard" svgIcon="CLOSE_IMAGE"></mat-icon>
            </button>
            <div class="goal-modal-body">
                <h1 class="goal-modal-header" id="set-reading-goal">
                    {{goalData.goalHeading}}
                </h1>
                <p class="goal-modal-info">
                    {{goalData.content}}
                </p>
                <form [formGroup]="goalForm" class="axis-create-form">
                    <div class="axis-rprt-txtara axis-rm-bottom">
                        <mat-form-field appearance="fill" class="axis-create-text">
                            <mat-label aria-hidden="false" class="axis-txt-lble">{{goalData.goalInputTitle}}
                            </mat-label>
                            <input attr.aria-label="{{goalData.goalInputTitle}}" [placeholder]="goalData.goalInputTitle"
                                matInput id="goal-input" name="goal-input" type="tel" [min]="minValue" step=1 [max]=""
                                formControlName="goalValue" aria-required="true" required autocomplete="off"
                                (keydown)="goalInputKeyDown($event)">
                            <mat-error *ngIf="goalForm.controls['goalValue'].errors?.required">Please enter a whole
                                number between {{minValue}} and
                                {{goalData.max}}
                            </mat-error>
                        </mat-form-field>
                        <p class="goal-disclamr">*
                            Metrics are
                            based on digital content only</p>
                    </div>
                </form>
                <div mat-dialog-actions class="goal-modal-footer">
                    <button mat-button type="submit" aria-label="Set Goal" (keyup.enter)="setGoal()" (click)="setGoal()"
                        class="submit-btn" [ngClass]="{'dob-submitbtn': goalData.goal}">Set
                        Goal</button>
                    <button *ngIf="goalData.goal" aria-label="Remove Goal" mat-button type="button"
                        (keyup.enter)="setGoal(true)" (click)="setGoal(true)" class="cancel-btn">Remove
                        Goal</button>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>