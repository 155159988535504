import { Component, Input } from '@angular/core';
import { InSightGoalModal } from '@home/shared/data/home.model';

@Component({
  selector: 'axis360-goals-insights-card',
  templateUrl: './goals-insights-card.component.html',
  styleUrls: ['./goals-insights-card.component.scss']
})
export class GoalsInsightsCardComponent {
  @Input() insight: InSightGoalModal;
}
