import { Program, ProgramCard } from '@readingprograms/shared/data/programs.model';
import { getLocalDate, isToday } from '@shared/helper/app.util';
import { PROGRAMS_STATUS_TEXT } from '../constants/kz-programs.constants';

export const getOverAllStatus = (program: Program | ProgramCard): string => {
  let rpStatus = '';
  let readByDateEnds = isToday(program?.readByDate);
  if (readByDateEnds) {
    rpStatus = PROGRAMS_STATUS_TEXT.ENDS_TODAY;
  }
  else if (getLocalDate(program?.startDate) > getLocalDate(new Date())) {
    rpStatus = PROGRAMS_STATUS_TEXT.JOINED;
  }
  else if (program?.dbStatus === 'Active') {
    rpStatus = PROGRAMS_STATUS_TEXT.STARTED;
  }
  else if (program?.totalCompletePercentage === 100) {
    rpStatus = PROGRAMS_STATUS_TEXT.COMPLETED;
  } else {
    rpStatus = PROGRAMS_STATUS_TEXT.NOT_COMPLETED;
  }
  return rpStatus;
};
