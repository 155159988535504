<div class="kz-alert-dialog" [ngClass]="{'kz-info-dialog':modaldata.type === 'INFO'}" cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true">

  <button class="btn-nobg-bluecolor btn-close" cdkFocusInitial aria-label="Close" *ngIf="modaldata.closeIcon"
    (click)="cancelClick()" (keyup.enter)="cancelClick()">
    <mat-icon aria-hidden="true">close</mat-icon>
  </button>
  <ng-container *ngIf="showTextArea">
    <h2 mat-dialog-title id="alert-heading" class="alert-heading-title"
      [ngClass]="{'heading-success': modaldata.type === 'SUCCESS', 'heading-error': modaldata.type === 'ERROR', 'heading-bold': modaldata.type === 'INFO'}">
      {{modaldata.heading}}</h2>
  </ng-container>

  <ng-container *ngIf="!showTextArea">
    <p class="kz-visually-hidden" aria-hidden="true" id="alert-heading">Write a Review</p>
    <h2 mat-dialog-title id="loc_alert_content" class="alert-heading-title"
      [ngClass]="{'heading-success': modaldata.type === 'SUCCESS', 'heading-error': modaldata.type === 'ERROR', 'heading-bold': modaldata.type === 'INFO'}">
      {{modaldata.heading}}</h2>
  </ng-container>

  <ng-container *ngIf="showTextArea">
    <textarea aria-describedby="alertmessages" id="alert-dialog-content" [innerHTML]="value"
      [disabled]="modaldata?.patronReviewDetails?.patronHasAlreadyReviewed" (ngModelChange)="valueChange(value)"
      maxlength="1000"></textarea>
  </ng-container>
  <ng-container *ngIf="!showTextArea">
    <textarea id="alert-dialog-content"  [disabled]="modaldata?.patronReviewDetails?.patronHasAlreadyReviewed"
      [(ngModel)]="value" (ngModelChange)="valueChange(value)" maxlength="1000"></textarea>
  </ng-container>
  <div id="alert-dialog-count" aria-live="off" class="character-limit mb-1"><span class="secondary-para"
      *ngIf="!modaldata?.patronReviewDetails?.patronHasAlreadyReviewed">{{remainingText}} character(s) left</span>
  </div>

  <ng-container *ngIf="modaldata?.patronReviewDetails?.patronHasAlreadyReviewed">
    <div class="error-mark mt-2">
      <span><mat-icon class="exclamation-icon" svgIcon="kz-exclamation-mark-icon"></mat-icon></span>
      <div class="teritary-para alerttext" id="alertmessages" role="alert">
        <span> {{modaldata?.patronReviewDetails?.msg}}</span>
      </div>
    </div>
  </ng-container>
  <div mat-dialog-actions *ngIf="!modaldata?.patronReviewDetails?.patronHasAlreadyReviewed">
    <ng-container *ngIf="value?.trim().length < 3">
      <button mat-button class="okBtn" [ngClass]="'disabledBtn'" [disabled]="valueLength === 0" (click)="submitClick()"
        [attr.aria-labelledby]="modaldata.type === 'ERROR' ? 'alert-dialog-content': ' '"
        *ngIf="modaldata.submitBtnText">{{modaldata.submitBtnText}}</button>
    </ng-container>
    <ng-container *ngIf="value?.trim().length >= 3">
      <button mat-button class="okBtn"
        [ngClass]="modaldata?.patronReviewDetails?.patronHasAlreadyReviewed ?'disabledBtn':'okBtn'"
        [disabled]="modaldata?.patronReviewDetails?.patronHasAlreadyReviewed" (click)="submitClick()"
        [attr.aria-labelledby]="modaldata.type === 'ERROR' ? 'alert-dialog-content': ' '"
        *ngIf="modaldata.submitBtnText">{{modaldata.submitBtnText}}</button>
    </ng-container>
  </div>
</div>