import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeaturedProgramBookDetails, GetFeatureProgramAPIRes } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { PROGRAMS_STATUS } from '@readingprograms/shared/constants/programs.constants';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { forceRedirect, getBookIcon, positionFooter } from '@shared/helper/app.util';
import { HomeService } from '../shared/services/home.service';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'axis360-featured-program',
  templateUrl: './featured-program.component.html',
  styleUrls: ['./featured-program.component.scss']
})
export class FeaturedProgramComponent implements OnInit, AfterContentChecked {
  @Input() isTrackingEnabled?: boolean;
  @Output() progressiveEmit = new EventEmitter();
  isLoading = true;
  forceRedirect = forceRedirect;
  isNotCarousel = false;
  program: {
    programId: string;
    programName: string;
    isParticipant: boolean;
    books: FeaturedProgramBookDetails[] | {}[];
    viewDetailsLink: string;
  };
  getFeatureProgram: GetFeatureProgramAPIRes;
  constructor(
    public homeService: HomeService,
    public myShelfListService: MyShelfListService,
    public programsService: ProgramsService,
    public engageService: EngageService,
    public homeAnalyticsService: HomeAnalyticsService,
    public cdRef: ChangeDetectorRef,
    public commonService: CommonService
  ) { }
  /**
   * To get feature program and details on init
   */
  ngOnInit(compData?): void {
    this.getFeatureBanner(compData);
  }
  /**
   * To get feature banner detail
   */
  async getFeatureBanner(compData?) {
    this.homeService.getFeatureProgram().subscribe(
      (response) => {
        this.getFeatureProgram = response;
        const { programId, backgroundImage, programName, status, isParticipant, bookIds } = this.getFeatureProgram;
        if (!programId || status === PROGRAMS_STATUS.COMPLETE || status === PROGRAMS_STATUS.UNPUBLISHED) {
          this.isLoading = false;
          if (compData?.isShow) { compData.isShow = false; }
          this.progressiveEmitFRP();
          return;
        }
        this.programsService.setBackgroundImage(backgroundImage);
        this.getActionViewButtonForFeaturedProgramList(this.getFeatureProgram);
        const bookDetails = this.getBookDetail(this.getFeatureProgram.items);
        this.program = {
          programId,
          programName,
          isParticipant,
          viewDetailsLink: isParticipant ? `/ng/program/${programId}` : `/ng/programs/open/${programId}`,
          books: bookIds.map((bookId) => bookDetails[bookId] || {})
        };
        this.isLoading = false;
        this.isNoCarouselArrows();
        positionFooter(true);
        compData?.isShow && (compData.isShow = this.program.books.length > 0);
        this.progressiveEmitFRP();
      },
      () => {
        this.isLoading = false;
        this.progressiveEmitFRP();
      }
    );
  }
  /**
   * get title state info for card
   * @param inputdata
   */
  getActionViewButtonForFeaturedProgramList(inputdata) {
    this.myShelfListService.getMyLibraryListActionButtonV2(inputdata).subscribe((actionButtonResponse: any) => {
      this.getFeatureProgram.items?.map((elm) => {
        const checkList = inputdata.items.filter(x => x.isbn === (elm.ISBN || elm.isbn)).length > 0
        if (checkList) {
          elm.DisplayStatus = actionButtonResponse.find(item => item.isbn === (elm.ISBN || elm.isbn))?.displayStatus || '';
          elm.HoldPosition = actionButtonResponse.find(item => item.isbn === (elm.ISBN || elm.isbn))?.holdPosition || '';
          elm.titleStateInfo = actionButtonResponse.find(item => item.isbn === (elm.ISBN || elm.isbn));
          elm.titleStatusInfo = actionButtonResponse.find(item => item.isbn === (elm.ISBN || elm.isbn));
        }
      });
      const bookDetails = this.getBookDetail(this.getFeatureProgram.items);
      const { programId, programName, isParticipant, bookIds } = this.getFeatureProgram;
      this.program = {
        programId,
        programName,
        isParticipant,
        viewDetailsLink: isParticipant ? `/ng/program/${programId}` : `/ng/programs/open/${programId}`,
        books: bookIds.map((bookId) => bookDetails[bookId] || {})
      };
    });
  }

  /**
   * Gets book details from API
   * @param bookIds list of bookIds
   * @returns book detail object
   */
  getBookDetail(items): Promise<{ [itemId: string]: FeaturedProgramBookDetails }> {
    return items.reduce((acc, cur) => {
      acc[cur.itemId] = {
        title: cur.title,
        author: cur.author,
        url: cur.imageUrl,
        isbn: cur.isbn,
        itemId: cur.itemId,
        formatType: cur.formatType,
        FormatType: cur.formatType,
        DisplayStatus: cur.DisplayStatus,
        HoldPosition: cur.HoldPosition,
        titleStateInfo: {
          "actions": [],
          ...cur.titleStateInfo
        },
        titleStatusInfo: {
          "actions": [],
          ...cur.titleStatusInfo
        },
        iconInfo: getBookIcon(cur.formatType),
        isAvailable: cur.isAvailable,
        runTime: cur.runTime,
        bookLength: cur.bookLength,
        isRTV: cur.isRTV,
        isRecommendable: cur.isRecommendable,
        totalQuantity: cur.totalQuantity,
        collectionType: cur.collectionType,
        onOrderQuantity: cur.onOrderQuantity,
        fromCheckoutHistory: cur.fromCheckoutHistory,
        titleUrl: `${window.origin}/Title/TitleDetails?itemId=${cur.itemId}&ISBN=${cur.isbn}&isRecommendable=${cur.isRecommendable}
          &collectionType=${cur.isRecommendable || 'Default'}`
      };
      return acc;
    }, {});
  }

  updateTitleStateInfo(program) {
    for (let item of program.books) {
      this.homeService.getFeaturedProgramAction(item).subscribe((res) => {
        item.titleStateInfo = res;
      });
    }
    return program;
  }
  /**
   * To views program details on clicking view program details button
   */
  viewProgramDetails() {
    this.commonService.isAnnouncedPage = true;
    this.homeAnalyticsService.trackHomeEvent('VIEW_PROGRAM_DETAILS');
  }
  /**
   * To be called on See all programs link click
   */
  seeAllPrograms() {
    this.homeAnalyticsService.trackHomeEvent('SEE_ALL_PROGRAMS');
  }
  isNoCarouselArrows() {
    setTimeout(() => {
      const rightArrow = document.querySelector('.featured-accordion .carousel-arrow-next.carousel-arrow-disabled');
      if (!rightArrow) {
        return;
      }
      this.isNotCarousel = true;
    });
  }
  progressiveEmitFRP() {
    this.progressiveEmit.emit();
  }
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
}
