export const LOGIN_MOCK = {
  logOnModel_UserName: 'BTtest',
  logOnModel_Password: 'test',
  authenticationMethod: 'PREFIX',
  errorMessage: '',
  isDialog: true,
  libraryID: '57C86A8B-E3B9-E011-9A12-6431504A306A',
  noPatronPinNeeded: false,
  rememberMe: false,
  ignoreValidation: false,
  libraryURL: '',
  passwordAuthenticationPrompt: 'false',
  passwordRecoverable: false,
  registrationPassword: 'false',
  returnUrl: '',
  showLoginPrompt: 'false',
  showRegisterPrompt: 'false',
  usageMode: 'false'
};

export const LOGIN_UPDATE_MOCK = {
  logOnModel_UserName: 'BTtest',
  logOnModel_Password: 'test',
  authenticationMethod: 'PREFIX',
  errorMessage: '',
  isDialog: true,
  libraryID: '57C86A8B-E3B9-E011-9A12-6431504A306A',
  noPatronPinNeeded: false,
  rememberMe: false,
  ignoreValidation: false,
  libraryURL: '',
  passwordAuthenticationPrompt: 'false',
  passwordRecoverable: false,
  registrationPassword: 'false',
  returnUrl: '/ng/view/library',
  isRedirect: true,
  showLoginPrompt: 'false',
  showRegisterPrompt: 'false',
  usageMode: 'false'
};

export const LOGIN_MOCK_02 = {
  logOnModel_UserName: '',
  logOnModel_Password: '',
  authenticationMethod: '',
  errorMessage: '',
  isDialog: true,
  libraryID: '',
  noPatronPinNeeded: false,
  rememberMe: false,
  ignoreValidation: false,
  libraryURL: '',
  passwordAuthenticationPrompt: 'false',
  passwordRecoverable: false,
  registrationPassword: 'false',
  returnUrl: '',
  showLoginPrompt: 'false',
  showRegisterPrompt: 'false',
  usageMode: 'false'
};

export const LOGIN_RESPONSE_MOCK = {
  hasErrors: false,
  isLogOnSuccess: true,
  isNewUser: false,
  returnUrl: '/home/index',
  defaultLandingPageID: 1
};
export const LOGIN_RESPONSE_MOCK_02 = {
  hasErrors: false,
  isLogOnSuccess: true,
  isNewUser: false,
  returnUrl: '/home/index',
  defaultLandingPageID: 0
};

export const LOGIN_RESPONSE_MOCK_03 = {
  hasErrors: false,
  isLogOnSuccess: false,
  isNewUser: false,
  returnUrl: '/home/index',
  defaultLandingPageID: 0
};

export const REGISTER_MOCK = {
  authenticationMethod: 'PREFIX',
  errorMessage: null,
  isDialog: true,
  noPatronPinNeeded: true,
  passwordRecoverable: false,
  profileTypeId: null,
  registerModel_DisplayName: null,
  registerModel_Email: null,
  registerModel_Password: 'SKIP_PATRONPIN',
  registerModel_SecurityAnswer: null,
  registerModel_SecurityQuestion: 0,
  registerModel_UserName: 'BTphn223',
  returnUrl: 'null'
};

export const REGISTER_SECURITYQUESTION_MOCK = {
  authenticationMethod: 'PREFIX',
  errorMessage: null,
  isDialog: true,
  noPatronPinNeeded: true,
  passwordRecoverable: false,
  profileTypeId: null,
  registerModel_DisplayName: null,
  registerModel_Email: null,
  registerModel_Password: 'SKIP_PATRONPIN',
  registerModel_SecurityAnswer: null,
  registerModel_SecurityQuestion: 0,
  registerModel_UserName: 'BTphn223',
  registerModel_SecurityQuestionList: [
    {
      text: 'What is your favorite animal?',
      name: 'SecurityQuestion',
      value: '6'
    },
    {
      text: 'What is your favorite color?',
      name: 'SecurityQuestion',
      value: '7'
    },
    {
      text: 'What is your favorite dessert?',
      name: 'SecurityQuestion',
      value: '8'
    }
  ],
  returnUrl: 'null'
};

export const REGISTER_WITHOUT_SECURITYQUESTION_MOCK = {
  authenticationMethod: 'PREFIX',
  isAuthenticated: true,
  errorMessage: null,
  isDialog: true,
  noPatronPinNeeded: true,
  passwordRecoverable: false,
  profileTypeId: null,
  registerModel_DisplayName: null,
  registerModel_Email: null,
  defaultLandingPageID: 1,
  registerModel_Password: 'SKIP_PATRONPIN',
  registerModel_SecurityAnswer: null,
  registerModel_SecurityQuestion: 0,
  registerModel_UserName: 'BTphn223',
  registerModel_SecurityQuestionList: [],
  returnUrl: 'home/index'
};

export const REGISTER_WITHOUT_SECURITYQUESTION_MOCK_02 = {
  authenticationMethod: 'PREFIX',
  isAuthenticated: false,
  errorMessage: null,
  isDialog: true,
  noPatronPinNeeded: true,
  passwordRecoverable: false,
  profileTypeId: null,
  registerModel_DisplayName: null,
  registerModel_Email: null,
  defaultLandingPageID: 1,
  registerModel_Password: 'SKIP_PATRONPIN',
  registerModel_SecurityAnswer: null,
  registerModel_SecurityQuestion: 0,
  registerModel_UserName: 'BTphn223',
  registerModel_SecurityQuestionList: [],
  returnUrl: 'home/index'
};

export const responseWithPassword = {
  registerModel_UserName: 'user',
  registerModel_Email: 'user@in.com',
  registerModel_Password: '1234',
  registerModel_SecurityQuestion: 'whats ur fav animal?',
  registerModel_SecurityAnswer: 'dog',
  registerModel_profileTypeId: '1'
};

export const resWithoutPassword = {
  registerModel_UserName: 'user',
  registerModel_Email: 'user@in.com',
  registerModel_SecurityQuestion: 'whats ur fav animal?',
  registerModel_SecurityAnswer: 'dog',
  registerModel_profileTypeId: '1'
};

export const registerationResponseData = {
  libraryId: 'BTphn77',
  passWord: '1234',
  displayName: 'UserOne',
  emailAddress: 'userone@in.com',
  securityAnswer: 'dog',
  securityQuestion: 'whats your favourite animal'
};
export const registerationResponseData_02 = {
  libraryId: '',
  passWord: '',
  displayName: '',
  emailAddress: '',
  securityAnswer: '',
  securityQuestion: ''
};
export const LIBRARY_AUTHSETTING = {
  ilsVendorID: 'PREFIX',
  noPatronPinNeeded: true,
  caseInsensitiveBarcode: true,
  isTrimPatronBarcode: true,
  loginRequired: true,
  prefix: '',
  registrationPassword: '',
  registrationPasswordPrompt: '',
  showRegistrationPasswordPrompt: '',
  showLoginPrompt: '',
  ncipServerURL: '',
  ezProxyLabel: '',
  patronTypeAuthentication: true
};

export const LIBRARY_AUTHSETTING_02 = {
  ilsVendorID: 'PREFIX',
  noPatronPinNeeded: false,
  caseInsensitiveBarcode: true,
  isTrimPatronBarcode: true,
  loginRequired: true,
  prefix: '',
  registrationPassword: '',
  registrationPasswordPrompt: '',
  showRegistrationPasswordPrompt: '',
  showLoginPrompt: '',
  ncipServerURL: '',
  ezProxyLabel: '',
  patronTypeAuthentication: true
};

export const USERNAME_SECURITYQUESTION_MOCK = {
  username: 'BTtest',
  securityQuestion: 'Waht is your fav animal?',
  isDialog: true
};

export const REGISTER_RESPONSE = {
  registerModel_UserName: 'test',
  registerModel_Password: 'test',
  errorMessage: '',
  registerModel_Email: 'test@gmail.com',
  NoPatronPinNeeded: false,
  RegisterModel_SecurityQuestion: 0,
  registerModel_SecurityAnswer: 'test',
  profileTypeId: 'BTphn223',
  RegisterModel_SecurityQuestionList: []
};

export const AGE_LEVEL = [
  { type: 'adult', ageRange: 'Adult 18+' },
  { type: 'teen', ageRange: 'Teen 13-17' },
  { type: 'kid', ageRange: 'Kid 0-12' }
];
