export const THIRD_PARTY_SCREENS_CATEGORY = {
  TIER_THREE_PAGE: 'Newspapers & magazines tier 3 page',
};

export const THIRD_PARTY_SCREENS_KEY = {
  LANDING_TIRE3: 'LANDING_TIRE3'
};

export const THIRD_PARTY_SCREENS_SCREENS: { [key: string]: string } = {
  [THIRD_PARTY_SCREENS_KEY.LANDING_TIRE3]: 'Newspapers & magazines tier 3 page'
};

export const THIRD_PARTY_SCREENS_EVENTS_KEY = {
  ACTIONS_SEE_ALL: 'ACTIONS_SEE_ALL',
  ACTIONS_SEE_LESS_COUNTRY: 'ACTIONS_SEE_LESS_COUNTRY'
};
export const THIRD_PARTY_SCREENS_EVENTS: { [key: string]: string } = {
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_SEE_ALL]: 'Chevron - Show all countries',
  [THIRD_PARTY_SCREENS_EVENTS_KEY.ACTIONS_SEE_LESS_COUNTRY]: 'Chevron - Show less countries'
};
