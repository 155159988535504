<div class="kz-newspaper-carousel" *ngIf="publicationList && publicationList.length > 0">
    <ng-container>
        <h2 class="title">NEWSPAPERS & MAGAZINES</h2>
        <p class="description">
            <span class="description-text">newspaper and magazine description</span>
            <a class="link see-all-text" tabindex="0" [href]="seeAllLink" role="link"
                aria-label="See all newspapers & magazines">See all
                <mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon>
            </a>
        </p>
    </ng-container>
    <div class="kz-carousel">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="5" [margin]="cellMargin" [cellWidth]="cellWidth"
            [height]="cellHeight" (click)="trackCarousel($event)">
            <div class="carousel-cell" *ngFor="let item of publicationList">
                <axis360-newspaper-magazine-card [publicationCard]="item" [type]="type">
                </axis360-newspaper-magazine-card>
            </div>
        </carousel>
    </div>
</div>
<axis360-shimmer-cards [compName]="compName" *ngIf="publicationList === undefined"
    [showCarouselTitle]="showCarouselTitle">
</axis360-shimmer-cards>