<div class="shareDropmenu">
    <button mat-menu-item (click)="shareTitleSocialMedia('copyLink')">
        <mat-icon svgIcon="kz-copy-icon"></mat-icon>
        <span>Copy Link</span>
    </button>
    <button mat-menu-item (click)="shareTitleSocialMedia('email')">
        <mat-icon svgIcon="kz-titlemail-icon"></mat-icon>
        <span>Email</span>
    </button>
    <button mat-menu-item (click)="shareTitleSocialMedia('facebook')">
        <mat-icon svgIcon="kz-facebook-icon"></mat-icon>
        <span>Facebook</span>
    </button>
    <button mat-menu-item (click)="shareTitleSocialMedia('twitter')">
        <mat-icon svgIcon="kz-twitter-icon"></mat-icon>
        <span>Twitter</span>
    </button>
</div>