import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  MY_STUFF_CATEGORY,
  MY_STUFF_EVENTS,
  MY_STUFF_EVENTS_KEY,
  MY_STUFF_SCREENS,
  MY_STUFF_SCREENS_KEY
} from '../constants/my-stuff.analytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof MY_STUFF_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class MyStuffAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of MyStuff analytics service.
   */
  constructor() {
    super(MY_STUFF_SCREENS, MY_STUFF_EVENTS);
  }
  /**
   * To track MyStuff screens
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof MY_STUFF_SCREENS_KEY, altObj: AltObj = {}) {
    this.trackPage(screen, altObj);
  }
  /**
   * To track MyStuff event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackMyStuffEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(MY_STUFF_CATEGORY.MY_STUFF, action, altObj);
  }


}
