import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramDetailsComponent } from '@readingprograms/program-details/program-details.component';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { ACTION_BUTTON_DATA, HOME, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { CommonService } from '@shared/services/common.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { KZPROGRAM_ALERTS } from '../kz-programs/shared/constants/kz-programs-alerts.constants';
import { BOOLIAN_FLAGS, PROGRAMS_STATUS_TEXT, VIEW_BOOKS_SIZE } from '../kz-programs/shared/constants/kz-programs.constants';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { SpinnerService } from '@shared/services/spinner.service';
import { KzJoinProgramPopupComponent } from '../kz-programs/shared/components/kz-join-program-popup/kz-join-program-popup.component';
import { cdkRemoveVisualHidden, cdkVisualHidden, displayErrorMessage, focusElement, getYesOrNo, redirectUrl, resizeProgramDescription, setFocusToText, strToBool } from '@shared/helper/app.util';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ProfileDetail } from '@profile/shared/data/profiles.model';
import { BreadcrumbService } from 'xng-breadcrumb';
import { getCompletedBooks, getTypeInfo } from '@readingprograms/programs/shared/helper/programs.util';
import { KzProgramsService } from '../kz-programs/shared/service/kz-programs.service';
import { PROGRAM_TYPE } from '@readingprograms/shared/constants/programs.constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'axis360-kz-program-details',
  templateUrl: './kz-program-details.component.html',
  styleUrls: ['./kz-program-details.component.scss']
})
export class KzProgramDetailsComponent extends ProgramDetailsComponent {
  seeAll = false;
  showButton = true;
  joinedProgram = false;
  VIEW_BOOKS_SIZE = VIEW_BOOKS_SIZE;
  showDescription = false;
  PROGRAMS_STATUS_TEXT = { ...PROGRAMS_STATUS_TEXT };
  setFocusToText: any = setFocusToText;
  cellWidth = 164;
  cellMargin = 0;
  cellHeight = 270;
  parentRoute: string;
  focusElement = focusElement;
  redirectUrl = redirectUrl;
  titleListAnnoucePage = ''
  constructor(
    public programsService: ProgramsService,
    public engageService: EngageService,
    public activatedRoute: ActivatedRoute,
    public toastMessage: SnackBarService,
    public router: Router,
    public matDialog: MatDialog,
    public programsAnalyticsService: ProgramsAnalyticsService,
    public liveAnnouncer: LiveAnnouncer,
    public commonService: CommonService,
    public spinnerService: SpinnerService,
    private profileInfoService: ProfileInfoService,
    private breadcrumbService: BreadcrumbService,
    public kzProgramsService: KzProgramsService,
    public elementRef: ElementRef,
    public titleService: Title,
  ) {
    super(programsService, engageService, activatedRoute, toastMessage, router, matDialog, programsAnalyticsService, liveAnnouncer, kzProgramsService, elementRef, commonService);
  }

  ngOnInit(): void {
    this.focusElement('loc_headerId');
    this.commonService.isProgramDetailPage = true;
    this.titleService.setTitle('Program Details');
    super.ngOnInit();
    this.activatedRoute.queryParams?.subscribe((params) => {
      if (params?.ref) {
        this.titleService.setTitle('Program Details');
        this.liveAnnouncer.announce('Program Details Page', 'assertive');
      }
    });
    this.titleListAnnoucePage = 'Program Details';
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
      this.commonService.toStopAnnoucedPage(false, true);
    }, 4000);
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }

  getResizedDescription = () => {
    this.programDetails.resizedDescription = resizeProgramDescription(this.programDetails.description);
  };
  /**
   * Navigation BreadCrumbs
   */
  getBreadCrumbs() {
    this.seeAll = false;
    const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
    this.breadcrumbs = [
      { text: homePage, url: HOME[homePage], isHref: true },
      {
        text: !this.programDetails.isParticipant ? 'Open Programs' : 'My Programs',
        url: !this.programDetails.isParticipant ? '/view/programs/open' : '/view/programs'
      },
      { text: this.programDetails.name, url: '' }
    ];
    this.breadcrumbService.set('@programName', this.programDetails.name);
  }

  /**
   * To be called on open dialog box yes or no
   */
  openKZLeaveDialog(programName: string) {
    this.programsAnalyticsService.trackNotificationsEvent('LEAVE_PROGRAM');
    let alertObj = this.programDetails.type === PROGRAM_TYPE.MileStone ? { ...KZPROGRAM_ALERTS.KZPROGRAM_MILESTONE_LEAVE } : { ...KZPROGRAM_ALERTS.KZPROGRAM_LEAVE };
    alertObj.content = alertObj.content.replace('<programName>', programName);
    this.commonService.showAlertDialog({
      ...alertObj,
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: this.leaveProgram,
      cancelBtnFunc: () => this.programsAnalyticsService.trackNotificationsEvent('CANCEL_LEAVE_PROGRAM')
    });
  }

  /**
   * Leave the Program from My Program Tab
   */
  leaveProgram = () => {
    this.programsAnalyticsService.trackNotificationsEvent('OK_LEAVE_PROGRAM');
    this.spinnerService.withObservable(this.programsService.leaveProgram(this.programId)).subscribe(
      (result) => {
        this.spinnerService.isOverlayLoading = false;
        if (result.errors) {
          const msg = displayErrorMessage(result);
          this.router.navigate(['/view/programs'], { relativeTo: this.activatedRoute }).then(() => {
            this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, msg, { duration: 1500 });
          });
        } else {
          const msg = result.message;
          this.liveAnnouncer.announce(msg, 'assertive');
          this.router.navigate(['/view/programs'], { relativeTo: this.activatedRoute }).then(() => {
            this.toastMessage.showSnackBar(SNAKE_BAR_MODE.INFO, msg, { duration: 1500 });
            this.kzProgramsService.focusElementHeaderId.next(true);
          });
        }
      },
      (result) => {
        this.spinnerService.isOverlayLoading = false;
        const msg = displayErrorMessage(result);
        this.router.navigate(['/view/programs'], { relativeTo: this.activatedRoute }).then(() => {
          this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, msg);
        });
      }
    );
  };
  /**
   * Enables disable tracking
   * @param enable
   */
  seeAllEnabled() {
    this.focusElement('loc_headerId');
    this.titleService.setTitle('Program Reading List');
    this.liveAnnouncer.announce('Program Reading List Page', 'assertive');
  }
  enableDisableTracking(enable: boolean) {
    const profileId = this.profileInfoService.currentProfile.profileId;
    this.profileInfoService.currentProfile.defaultLandingPageID = this.profileInfoService.currentProfile.defaultLandingPageID
      ? this.profileInfoService.currentProfile.defaultLandingPageID
      : '2';
    this.profileInfoService.currentProfile.enableTracking = enable ? BOOLIAN_FLAGS.TRUE : BOOLIAN_FLAGS.FALSE;
    this.profileInfoService.currentProfile.displayCheckoutHistory = getYesOrNo(
      strToBool(this.profileInfoService.currentProfile.displayCheckoutHistory)
    );
    this.profileInfoService.currentProfile.highContrast = getYesOrNo(strToBool(this.profileInfoService.currentProfile.highContrast));
    this.profileInfoService.currentProfile.isEmailEnabled = getYesOrNo(strToBool(this.profileInfoService.currentProfile.isEmailEnabled));
    this.profileInfoService.currentProfile.pinStatus = getYesOrNo(strToBool(this.profileInfoService.currentProfile.pinStatus));
    this.profileInfoService.currentProfile.emailNotificationAlert = getYesOrNo(strToBool(this.profileInfoService.currentProfile.emailNotificationAlert));
    this.profileInfoService.currentProfile.displayTrackingPrompt = BOOLIAN_FLAGS.FALSE;
    const request: ProfileDetail = { ...this.profileInfoService.currentProfile };
    request.enableTracking = getYesOrNo(enable);
    this.spinnerService.withObservable(this.profileInfoService.updateProfile(profileId, request)).subscribe(() => {
      this.joinProgram(this.programDetails);
    });
  }
  /**
   * Join Program from open Program page
   * @param programDetails programdetils
   */
  joinProgram(programDetails: ProgramDetails) {
    this.programsAnalyticsService.trackNotificationsEvent('JOIN_PROGAM');
    const displayTrackingPrompt = strToBool(this.profileInfoService.currentProfile.displayTrackingPrompt);
    const enableTracking = strToBool(this.profileInfoService.currentProfile.enableTracking);
    const insightsBadgesEnabled = this.profileInfoService.currentProfile.insightsBadgesEnabled;
    if (displayTrackingPrompt && !enableTracking && insightsBadgesEnabled) {
      this.commonService.showAlertDialog({
        ...KZPROGRAM_ALERTS.KZPROGRAM_JOIN,
        submitBtnFunc: () => this.enableDisableTracking(true),
        cancelBtnFunc: () => this.enableDisableTracking(false)
      });
      return;
    }
    this.spinnerService.withObservable(this.programsService.joinProgram(this.programId)).subscribe(
      (result) => {
        if (result.errors) {
          this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, displayErrorMessage(result));
          this.router.navigate(['/view/programs'], { relativeTo: this.activatedRoute });
        } else {
          this.programsAnalyticsService.trackScreen('WELCOME_PROGRAM');
          this.redirectUrl(this.router, `/view/programs/${programDetails.id}`, true);
        }
      },
      (result) => {
        this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, displayErrorMessage(result));
        this.router.navigate(['/view/programs'], { relativeTo: this.activatedRoute });
      }
    );
    this.liveAnnouncer.announce('Program Joined Successfully', 'assertive');
  }
  /**
   * Navigate to MyProgram page
   */
  markAsReadClick(event: any) {
    let completedBook = getCompletedBooks(this.programDetails.type, this.programDetails.participants)
    if (completedBook === this.programDetails.numberOfBooks || event === ACTION_BUTTON_DATA.REMOVE) {
      this.getProgram();
    }
    this.programDetails.typeText = getTypeInfo(this.programDetails.type, this.programDetails.numberOfBooks, this.programDetails.books, this.programDetails.participants);
    this.router.navigate(['/view/programs/' + this.programId]);
  }
  /**
   * SeeAll Boolean Value change
   */
  onUpdateSeeAll() {
    this.seeAll = false;
    this.programDetails.book = [...this.programDetails.books];
    const programbook = this.programDetails.book.map((book) => book.bookId)
    this.filteredSuggestedBooks = this.suggestedBooks.filter((sugges) => !programbook.includes(sugges.itemId))
  }
  /**
   * Join program popup Open
   * @param programDetails programDetails sent to popup
   */
  joinProgramPopUp(programDetails: ProgramDetails) {
    this.matDialog.open(KzJoinProgramPopupComponent, {
      width: '100%',
      panelClass: 'join-program-modal-container',
      ariaLabelledBy: 'Join-program',
      autoFocus: true,
      data: programDetails
    });
  }
  replaceNewLine(description: string) {
    const text = '<p class="description-text">' + description + '</p>';
    return text.replace(/\n/g, '</p><p class="description-text">');
  }
  toggleshowDescription() {
    this.showDescription = !this.showDescription
    setTimeout(() => {
      const id = this.showDescription ? 'less' : 'more'
      const elem = document.getElementById(id);
      elem.focus();
    }, 1000)
  }
}
