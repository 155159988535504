import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SetGoalPopupComponent } from '@home/goals-and-insights/set-goal-popup/set-goal-popup.component';
import { InSightGoalModal } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';

@Component({
  selector: 'app-goals-insight-popup',
  templateUrl: './goals-insight-popup.component.html',
  styleUrls: ['./goals-insight-popup.component.scss']
})
export class GoalsInsightPopupComponent extends SetGoalPopupComponent implements OnInit {

  constructor(
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public goalData: InSightGoalModal,
    public dialogRef: MatDialogRef<SetGoalPopupComponent>,
    protected homeAnalyticsService: HomeAnalyticsService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public renderer: Renderer2
  ) {
    super(formBuilder, goalData, dialogRef, homeAnalyticsService, iconRegistry, sanitizer, renderer);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  restrict(event: any) {
    const charCode = event.charCode;
    if (!(charCode >= 48 && charCode <= 57) && charCode !== 13) {
      event.preventDefault()
    }
  }
  public checkError = (controlName?: string, id?: string) => {
    const elementId = document?.getElementById(id);
    if (elementId) {
      const ariaDescribedby = elementId.getAttribute('aria-describedby');
      elementId.setAttribute('aria-errormessage', ariaDescribedby);
      elementId.setAttribute('aria-labelledby', ariaDescribedby);
    }
    return this.goalForm.controls[controlName]?.invalid;
  };
}
