<section class="press-reader-main">
    <div class="hero-banner">
        <axis360-spinner-loader [isLoading]="true" *ngIf="isFeaturedLoading"></axis360-spinner-loader>
        <div class="press-hero" *ngIf="!isFeaturedLoading && featuredPublications.length > 0">
            <div class="hero-card">
                <div class="hero-box">
                    <h1 class="title-hero">Newspapers & Magazines</h1>
                </div>
                <div class="hero-box1">
                    <h2 class="title-hero">FEATURED</h2>
                    <axis360-press-reader-card-carousels class="press-reader-banner-carousel" usedIn="featured"
                        [publicationList]="featuredPublications"></axis360-press-reader-card-carousels>
                </div>
            </div>
        </div>
    </div>

    <div class="secondary-main">
        <div class="press-catagory">
            <div class="achev-heading">
                <h2 class="axis-achev-title" *ngIf="isCategoryLoading || (!isCategoryLoading && categories.length > 0)">
                    Categories</h2>
            </div>
            <axis360-spinner-loader [isLoading]="true" *ngIf="isCategoryLoading"></axis360-spinner-loader>
            <!-- carosel -->
            <ng-container *ngIf="!isCategoryLoading && categories.length > 0">
                <axis360-press-reader-category-carousel [categories]="categories">
                </axis360-press-reader-category-carousel>
            </ng-container>
        </div>
        <div class="newspaper-main">
            <div class="achev-heading">
                <h2 class="axis-achev-title "
                    *ngIf="isNewspaperLoading || (!isNewspaperLoading && newsPapers.length > 0)">Newspapers</h2>
                <button *ngIf="!isNewspaperLoading && newsPapers.length >= PRESS_READER_PAGE_SIZE" role="link" class="achev-seeall-btn"
                    aria-label="See all Newspapers" (click)="redirectToListPage(PUBLICATION_TYPES.NEWS_PAPERS, '')"> SEE
                    ALL <span class="material-icons">&#xe5cc;</span></button>
            </div>
            <axis360-spinner-loader [isLoading]="true" *ngIf="isNewspaperLoading"></axis360-spinner-loader>
            <ng-container *ngIf="!isNewspaperLoading && newsPapers.length > 0">
                <axis360-press-reader-card-carousels usedIn="view" [publicationList]="newsPapers"
                    [type]="PUBLICATION_TYPES.NEWS_PAPERS" title="Newspapers" class="press-reader-inner-carousel"></axis360-press-reader-card-carousels>
            </ng-container>
        </div>
        <div class="newspaper-main">
            <div class="achev-heading">
                <h2 class="axis-achev-title " *ngIf="isMagazineLoading || (!isMagazineLoading && magazines.length > 0)">
                    Magazines</h2>
                <button *ngIf="!isMagazineLoading && magazines.length >= PRESS_READER_PAGE_SIZE" role="link" class="achev-seeall-btn"
                    aria-label="See all Magazines" (click)="redirectToListPage(PUBLICATION_TYPES.MAGAZINES, '')"> SEE
                    ALL <span class="material-icons">&#xe5cc;</span></button>
            </div>
            <axis360-spinner-loader [isLoading]="true" *ngIf="isMagazineLoading"></axis360-spinner-loader>
            <ng-container *ngIf="!isMagazineLoading && magazines.length > 0">
                <axis360-press-reader-card-carousels usedIn="view" [publicationList]="magazines"
                    [type]="PUBLICATION_TYPES.MAGAZINES" title="Magazines" class="press-reader-inner-carousel"></axis360-press-reader-card-carousels>
            </ng-container>
        </div>
        <ng-container *ngFor="let category of CATEGORY_CAROUSEL">
            <div class="newspaper-main">
                <ng-container *ngIf="getCarouselInfoByCategory(category).items.length > 0">
                    <div class="achev-heading">
                        <h2 class="axis-achev-title">{{getCarouselInfoByCategory(category).name}}</h2>
                        <button role="link" class="achev-seeall-btn"
                            attr.aria-label="See all {{getCarouselInfoByCategory(category).name}}"
                            (click)="clickCategorySeeAll(category)" *ngIf="getCarouselInfoByCategory(category).items.length >= PRESS_READER_PAGE_SIZE"> SEE ALL
                            <span class="material-icons">&#xe5cc;</span></button>
                    </div>
                    <axis360-press-reader-card-carousels usedIn="view"
                        [publicationList]="getCarouselInfoByCategory(category).items"
                        class="press-reader-inner-carousel" [title]="getCarouselInfoByCategory(category).name"></axis360-press-reader-card-carousels>
                </ng-container>
            </div>
        </ng-container>
    </div>
</section>