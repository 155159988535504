<div class="kz-title-carousel kz-program-card-carousel"
    *ngIf="!suggestedBooks && programDetails && programDetails.book && programDetails.book.length > 0">
    <div class="kz-carousel">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="5" [margin]="cellMargin" [cellWidth]="cellWidth"
            height='{{programDetails.book.length > 1 ? 320 : 272}}' (click)="trackCarousel($event)">
            <div class="carousel-cell" *ngFor="let bookDetail of programDetails.book; index as i">
                <axis360-kz-programs-title [bookDetail]="bookDetail" [programDetails]="programDetails"
                    [isProgramDetail]="true" [suggestedBooks]="suggestedBooks"
                    [isParticipant]="programDetails.isParticipant" (titleMarkAsReadEvent)="markAsReadClick($event)">
                </axis360-kz-programs-title>
            </div>
        </carousel>
    </div>
</div>
<div class="kz-title-carousel" *ngIf="suggestedBooks && programDetails && programDetails.book">
    <div class="kz-carousel kz-program-card-carousel" *ngIf="programDetails.type === 'Milestone'">
        <carousel axis360IvyCarousel [dots]="false" [cellsToScroll]="cellsToScroll" [margin]="cellMargin"
            [cellWidth]="cellWidth" [height]="cellHeight" (click)="trackCarousel($event)">

            <div class="carousel-cell" *ngFor="let titleContent of suggestedBooks">
                <ng-container>
                    <axis360-title-card [titleContent]="titleContent" [isProgramCTAShow]="true" [parentRoute]="parentRoute" [programDetails]="programDetails" [isSuggestedBook] = "true"
                        [isProgramDetail]="true" [isReadingProgress] = "true"> </axis360-title-card>
                </ng-container>
            </div>

        </carousel>
    </div>
</div>