<section class="achievement-section" *ngIf="badges.length>0">
    <div class="actieve-main">
        <div class="achive-card">
            <div class="axis-badges-main">
                <div class="achev-heading">
                    <h2 class="axis-achev-title">Recent Badges</h2>
                </div>
                <div class="badges-pref badges-desk">
                    <div class="badges-container">
                        <div class="badge-col">
                            <axis360-badges-carousel [badges]="badges"></axis360-badges-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>