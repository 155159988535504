<mat-card class="press-poster">
    <div class="poster-main-sec">
        <a href="javascript:;" (click)="cardClick(type)" (keyup.enter)="cardClick(type)"
        [style.background-image]="'url(' + getTitleImage(myListContent.ISBN) + ')'" [attr.aria-label]="titleAriaLabel"> 
        <div *ngIf="myListContent?.titleStateInfo" class="book-poster" [ngClass]="{'in':(myListContent.IsAvailable && myListContent.IsRTV),'out':!(myListContent.IsAvailable && myListContent.IsRTV)}">
            <mat-icon [svgIcon]="getIcon(myListContent.FormatType).icon"></mat-icon>
        </div>
    </a>
    </div>    
    <div class="poster-author-detail" aria-hidden="true">
        <p class="title-name" [matTooltip]="myListContent.Title" matTooltipClass="card-title-tooltip">{{myListContent.Title}}</p>
        <p class="author-name"> {{myListContent.Author}}</p>
    </div>
  
    <ng-container *ngIf="myListContent?.titleStateInfo">
        <axis360-title-action-buttons [bookDetail]="myListContent" [fromHomeCarousel]="fromHomeCarousel" (titleCheckoutEvent)="titleCheckedOutEvent()"></axis360-title-action-buttons>
    </ng-container> 
    
</mat-card>