import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_INFO } from '@shared/constants/api.constant';
import { CategoryInfoAPIRes, GetNewsRequest, LibLocation, NewsInfo } from '@search/shared/data/news-model';
import { GetSitesRequest, SitesInfo } from '@search/shared/data/sites-model';
import { NEWS_RESPONSE_CATEGORIES } from '@search/shared/constants/search-results.constants';
import { Facet } from '../data/filter-model';
interface NewsCategoryData {
  [key: string]: Facet[];
}
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  selectedSearchTab = 0;
  selectedNewsStandTab = 0;
  locationInfo: LibLocation;
  /**
   * Creates an instance of search service.
   * @param http http client
   */
  constructor(private http: HttpClient) {}
  /**
   * Reduce the resonse based on the condition and apply transformation
   * @param categoryInfo input param as category info
   * @param categoryName input param as category name
   * @returns and convert category
   */
  reduceFilter(categoryInfo: CategoryInfoAPIRes): NewsCategoryData {
    return categoryInfo.reduce((acc: NewsCategoryData, cur): NewsCategoryData => {
      const { name } = cur;
      const isPublicationType = name === NEWS_RESPONSE_CATEGORIES.PUBLICATION_TYPES;
      acc[name.toLowerCase()] = cur.categories.map((item) => ({
        id: item.id,
        text: item.displayName,
        value: isPublicationType ? (item.titlePublicationCid || '').toString() : item.iso
      }));
      return acc;
    }, {});
  }
  /**
   * To parse and transform the filter response
   * @param apiObserver input as news data
   * @returns filter wrapper
   */
  newsFilterWrapper(apiObserver: Observable<CategoryInfoAPIRes>): Observable<NewsCategoryData> {
    return new Observable((observer) => {
      apiObserver.subscribe(
        (res) => {
          res.statusCode && res.statusCode !== 200 ? observer.next({}) : observer.next(this.reduceFilter(res));
        },
        (err) => observer.error(err),
        () => observer.complete()
      );
    });
  }
  /**
   * To fetch news results based on the request
   * @param req Get news request
   * @returns News results response
   */
  getNewsResults(req: GetNewsRequest): Observable<NewsInfo> {
    const apiUrl = API_INFO.getNews
      .replace('<sortOption>', `${req.sortOption}`)
      .replace('<limit>', `${req.limit}`)
      .replace('<offset>', `${req.offset}`);
    return this.http.post<NewsInfo>(apiUrl, req.requestBody);
  }
  /**
   * To fetch sites results based on the request
   * @param req Get sites request
   * @returns Sites results response
   */
  getSitesResults(req: GetSitesRequest): Observable<SitesInfo> {
    const apiUrl = API_INFO.getSites
      .replace('<searchtext>', `${encodeURIComponent(req.searchText)}`)
      .replace('<pageindex>', `${req.pageIndex}`)
      .replace('<pagesize>', `${req.pageSize}`)
      .replace('<grade>', `${req.gradeMap}`)
      .replace('<domain>', `${req.domainFilter}`)
      .replace('<language>', `${req.languageFilter}`)
      .replace('<format>', `${req.formatFilter}`)
      .replace('<sourcetype>', `${req.sourceFilter}`)
      .replace('<subject>', `${req.subjectFilter}`);
    return this.http.get<SitesInfo>(apiUrl);
  }
  /**
   * Gets countries for filter
   * @param languageCode gets countries based on languageCode
   * @returns countries
   */
  getCountries = (languageCode): Observable<NewsCategoryData> => {
    const apiUrl = API_INFO.getCountries.replace('<languageCode>', `${languageCode}`);
    return this.newsFilterWrapper(this.http.get<CategoryInfoAPIRes>(apiUrl));
  };
  /**
   * Gets languages for filter
   * @param countryCode get languages based on countryCode
   * @returns languages data from API
   */
  getLanguages = (countryCode): Observable<NewsCategoryData> => {
    const apiUrl = API_INFO.getLanguages.replace('<countryCode>', `${countryCode}`);
    return this.newsFilterWrapper(this.http.get<CategoryInfoAPIRes>(apiUrl));
  };
  /**
   * Gets initails values for news filters
   * @returns list of countries, languages and publication types
   */
  getNewsFilters(): Observable<NewsCategoryData> {
    const apiUrl = API_INFO.getNewsFilters;
    return this.newsFilterWrapper(this.http.get<CategoryInfoAPIRes>(apiUrl));
  }
  /**
   * Getlocation  of search service
   */
  getlocation = (libraryId): Promise<LibLocation> => {
    const apiUrl = API_INFO.getlocation.replace('<libraryId>', `${libraryId}`);
    return this.http.get<LibLocation>(apiUrl).toPromise();
  };
}
