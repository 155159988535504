import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterestAPIResponse } from '@home/shared/data/home.model';
import { focusElement } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-kz-interest-survey-popup',
  templateUrl: './kz-interest-survey-popup.component.html',
  styleUrls: ['./kz-interest-survey-popup.component.scss']
})
export class KzInterestSurveyPopupComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<KzInterestSurveyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: InterestAPIResponse
  ) {}
  /**
   * To close interests survey dialog of interest survey popup component
   */
  ngOnInit(): void {
    setTimeout(() => {
      focusElement('myInterestsCloseButton');
    }, 200);
  }
  closeKzInterestsSurveyDialog = () => {
    this.dialogRef.close();
  };
}
