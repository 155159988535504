import { Component, Input } from '@angular/core';
import { SpinnerService } from '@shared/services/spinner.service';

@Component({
  selector: 'axis360-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent {
  @Input() isLoading = false;
  @Input() loaderClass: string;
  /**
   * Creates an instance of spinner loader component.
   * @param spinnerService Spinner service
   */
  constructor(public spinnerService: SpinnerService) {}
}
