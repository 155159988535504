import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { LayoutComponent, VendorDetails } from '@shared/data/third-party-model';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { forkJoin, Observable } from 'rxjs';
import { ThirdPartyAnalyticService } from '../shared/services/third-party-analytic.service';
import { ThirdPartyService } from '../shared/services/third-party.service';
import { THIRD_PARTY_SCREENS_CATEGORY } from '../shared/constants/third-party-analytic.constants';

@Component({
  selector: 'axis360-third-party-widgets',
  templateUrl: './third-party-widgets.component.html',
  styleUrls: ['./third-party-widgets.component.scss']
})
export class ThirdPartyWidgetsComponent implements OnInit {
  @Input() componentData: LayoutComponent;
  @Input() seeAllLink: string;
  @Input() parentRoute: string;
  @Output() count = new EventEmitter<{ type: string; count: number | string; entityId: string }>();
  cellWidth = 340;
  cellMargin = 0;
  cellHeight = 468;
  isLoading = true;
  widgetsLoading = false;
  cellsToScroll = 3;
  cellsToScrollNums = [3, 3, 2, 1];
  vendorDetails: VendorDetails[];
  vendorDetailsMock: any[];
  errorHandler = false;
  compName = SHIMMER_COMPONENTS.WIDGET_CARD;
  /**
   * To update the carousel cell width and margin
   */
  onResize = setIvyCarouselProps.bind(this, 340, 0, 468, 340, 0, 468, this.cellsToScrollNums);
  constructor(
    private thirdPartyService: ThirdPartyService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.onResize();
    this.vendorDetails = (this.componentData.vendor_details || []).map((vendor) => {
      const vendorSubscription = this.configService.getSubscriptionData((vendor.vendor_code || '').toLocaleLowerCase());
      const searchSubscription = {};
      Object.keys(this.componentData.dataApi.library_settings).forEach((key) => {
        searchSubscription[key] = vendorSubscription[this.componentData.dataApi.library_settings[key]];
        return searchSubscription;
      });
      vendor.jsonTempl = this.componentData.jsonTempl;
      vendor.dataApi = {
        ...this.componentData.dataApi,
        search: {
          ...this.componentData.dataApi.search,
          ...searchSubscription,
          categoryType: vendor.category_type,
          formatCode: vendor.format_code
        }
      };
      return vendor;
    });
    this.getCatData(this.vendorDetails);
  }
  getCatData(vendorDetailsList: any) {
    if (vendorDetailsList.length === 0) {
      this.componentData = undefined;
      this.isLoading = false;
      this.widgetsLoading = true;
      this.count.emit({ type: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS,
        count: vendorDetailsList.length, entityId: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS });
      return;
    }
    try {
      const vendorDetailsWidgets: Observable<any>[] = [];
      vendorDetailsList.forEach((vendor) => {
        vendorDetailsWidgets.push(this.thirdPartyService.getThirdPartyCategoriesWidgets(vendor.dataApi));
      });
      forkJoin(vendorDetailsWidgets).subscribe(
        (item) => {
          if (item.length === 0) {
            this.isLoading = false;
            this.widgetsLoading = true;
            this.count.emit({ type: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS,
              count: item.length, entityId: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS });
            return;
          }

          this.vendorDetails = this.vendorDetails.filter((x, i) => {
            if (item[i] && item[i].categories && item[i].categories.length > 0) {
              x.dataResponse = [item[i]];
              return x;
            }
          });
          this.isLoading = false;
          this.widgetsLoading = true;
          this.count.emit({ type: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS,
            count: item.length, entityId: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS });
        },
        () => {
          this.componentData = undefined;
          this.isLoading = false;
          this.widgetsLoading = true;
          this.count.emit({ type: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS, count: 0, entityId: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS });
        }
      );
    } catch (e) {
      this.count.emit({ type: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS,
        count: vendorDetailsList.length, entityId: THIRD_PARTY_SCREENS_CATEGORY.WIDGETS });
      (e.status === 400 || e.status === 401 || e.status === 500 || e.statusCode === 500) &&
        ((this.componentData = undefined), (this.isLoading = false), (this.widgetsLoading = true));
    }
  }

  /**
   *
   * errorHandler event
   */
  errorHandlerEvent(event) {
    this.vendorDetails = this.vendorDetails.filter((item) => item.vendor_code !== event.vendor_code);
    this.errorHandler = event.error;
  }
}
