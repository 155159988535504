import { Component } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent {
  constructor(public userService: UserService){};
}
