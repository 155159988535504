import { Injectable } from '@angular/core';
import { IAnnouncementResponse } from '../data/announcement.model';
import { API_INFO } from '@shared/constants/api.constant';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { LibCompononentResponse } from '../data/library.model';
import { ILearningActivityCategoryResponse } from '@shared/data/learning-activity.model';
import { HttpClient } from '@angular/common/http';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { HOME_REFINER } from '@shared/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class MyLibraryService extends EngageBaseService {
  // this flag is used to show error message in library page
  titlesAvailable = true;
  isThirdPartyAvailable = false;
  isCuratedListAvailable = false;
  constructor(public configService: ConfigService, private profileService: ProfileInfoService, public http: HttpClient) {
    super(http, profileService, configService);
  }
  //API Call for getting the announcements
  getAnnouncements = (): Promise<IAnnouncementResponse> => this.http.get<IAnnouncementResponse>(API_INFO.getAnnouncements).toPromise();
  /**
   * To get the library components from API
   * @returns Library components response in promise
   */
  getLibraryComponents = (): Promise<LibCompononentResponse> =>
    this.http.get<LibCompononentResponse>(API_INFO.getLibraryComponents).toPromise();

  getLearningActivities = async (): Promise<ILearningActivityCategoryResponse> => {
    return this.http
      .get<ILearningActivityCategoryResponse>(
        API_INFO.getLearningActivityCategories
          .replace('<profileType>', this.profileService.currentProfile.profileType)
          .replace('<libraryId>', this.configService.currentLibrary.ppcLibraryId)
      )
      .toPromise();
  };
  /**
   * Updates see all url
   * @param seeAll 
   */
  updateSeeAllURL(seeAll) {
    const sessionFilters = {
      availability: sessionStorage.getItem(HOME_REFINER.AVAILABILITY),
      format: sessionStorage.getItem(HOME_REFINER.FORMAT)
    }
    if (sessionFilters.availability && sessionFilters.format) {
      seeAll = `${seeAll}&format=${sessionFilters.format}&availability=${sessionFilters.availability}`;
    }
    return seeAll;
  }
}
