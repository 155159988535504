import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionData, BookDetail, ListContent } from '@shared/data/config.model';
import { forceRedirect, getBookIcon, getValueFromCaseinsensitiveAttr } from '@shared/helper/app.util';
import { PROGRESS_BAR, READY_FOR_CHECKOUT } from '@shared/constants/app.constants';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from '@shared/services/common.service';
import { ConfigService } from '@shared/services/config.service';
@Component({
  selector: 'axis360-my-stuff-grid-card',
  templateUrl: './my-stuff-grid-card.component.html',
  styleUrls: ['./my-stuff-grid-card.component.scss']
})
export class MyStuffGridCardComponent implements OnInit {
  @Input() myStuffContent: ListContent;
  @Input() activeMyStuff: string;
  @Output() titleActionEvt = new EventEmitter();
  @Input() parentRoute: string;
  getBookIcon = getBookIcon;
  forceRedirect = forceRedirect;
  showProgress = true;
  READY_FOR_CHECKOUT = READY_FOR_CHECKOUT;
  constructor(
    public profileInfoService: ProfileInfoService,
    public router: Router,
    public commonService: CommonService,
    public configService: ConfigService) { }
  ngOnInit() {
    if (
      !PROGRESS_BAR[this.activeMyStuff] ||
      this.profileInfoService.profileInfo.enableTracking === 'False' ||
      !this.profileInfoService.currentProfile.insightsBadgesEnabled
    ) {
      this.showProgress = false;
    }
    this.myStuffContent.Items.forEach((items) => {
      if (items.AxisAttribute != null && items.AxisAttribute.length > 0) {
        items.Attribute = items.AxisAttribute[0] === 'RAL' ? 'eRead-Along' : '';
      }
    });
  }

  /**
   * Get image for the title card by ISBN
   * @param isbn ISBN number of the title
   * @returns image url
   */
  getTitleImageUrl(isbn: string) {
    return this.configService.appSettings?.titleImageSrcFormat?.replace('{0}', isbn);
  }

  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType) {
    return this.getBookIcon(`KZ_${formatType}`);
  }

  /**
   * Navigate to title detail page
   * @param titleContent title content
   */
  navigateToListPage(titleContent: BookDetail) {
    const itemId = getValueFromCaseinsensitiveAttr(titleContent.ItemId, titleContent.itemId, '');
    const isbn = getValueFromCaseinsensitiveAttr(titleContent.ISBN, titleContent.isbn, '');
    this.router.navigate([`/view/${this.parentRoute}/title/${itemId}/${isbn}`]);
    this.commonService.breadcrumbs = [];
    this.commonService.titleBreadCrumb = false;
  }

  /**
   * Emits the Event when any Action is Triggered.
   */
  titleActionEvent(data: ActionData) {
    this.titleActionEvt.emit();
    if (this.activeMyStuff === 'wishlist' && data.actiontype === 'checkout') {
      this.navigateToListPage(data);
    }
  }

  /**
   * format the given last checkout date
   */
  formatLastCheckoutDate(date: string) {
    return formatDate(date, 'MMM dd, yyyy', 'en-US');
  }
}
