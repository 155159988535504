import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { HOME_AVAILABILITY, HOME_AVAILABILITY_EPOPUP_LIB, KZ_HOME_FORMAT_TYPE } from '@shared/constants/app.constants';
import { HomeAvailability, HomeFormatType } from '@shared/data/config.model';
import { LibCompononent } from '@shared/data/library-base.model';
import { cdkRemoveVisualHidden, cdkVisualHidden, deepClone, setElemAttribute } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ThirdPartyBaseService } from '@shared/services/third-party-base.service';
import { UserService } from '@shared/services/user.service';
import { MyLibraryAnalyticsService } from 'app/core/my-library/shared/services/my-library.analytics.service';
import { MyLibraryService } from 'app/core/my-library/shared/services/my-library.service';

@Component({
  selector: 'axis360-title-filter',
  templateUrl: './title-filter.component.html',
  styleUrls: ['./title-filter.component.scss']
})
export class TitleFilterComponent implements OnInit, OnChanges {
  @Input() componentsTitleFiler: LibCompononent[] = [];
  @Output() homeFilterChanged = new EventEmitter();
  @Input() enableFilter = false;
  homeAvailability: HomeAvailability[] = HOME_AVAILABILITY;
  homeFormatType: HomeFormatType[] = KZ_HOME_FORMAT_TYPE;
  selectedAvailability = HOME_AVAILABILITY[0].value;
  selectedFormat = KZ_HOME_FORMAT_TYPE[0].value;
  isPPCOnlyLibrary = false;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  templeValue = 'All';
  partnerSiteEnabled = false;
  epopupValue: boolean;
  setElemAttribute = setElemAttribute;
  cdkRemoveVisualHidden = cdkRemoveVisualHidden;
  cdkVisualHidden = cdkVisualHidden;
  constructor(
    public configService: ConfigService,
    private searchStorageService: SearchStorageService,
    private myLibraryAnalytics: MyLibraryAnalyticsService,
    public thirdPartyBaseService: ThirdPartyBaseService,
    public myLibraryService: MyLibraryService,
    public profileInfoService: ProfileInfoService,
    public userService: UserService,
    public liveAnnouncer: LiveAnnouncer
  ) { this.epopupValue = this.configService.currentLibrary.partnerSiteEnabled }

  ngOnInit(): void {
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    const isKZGuest = !this.userService.userInfo?.isLoggedIn;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(
        isKZGuest
          ? !this.axis360Enabled && this.kidsZoneEnabled
            ? PROFILE_TYPES.TEEN
            : PROFILE_TYPES.ADULT
          : this.profileInfoService.currentProfile.profileType.toLowerCase()
      ) || {};
    this.homeFormatType = KZ_HOME_FORMAT_TYPE.map((type) => {
      const formatFilter = this.configService.vendorDetails?.some(
        (elm) => elm.format_code === type.value.toLowerCase() && currentProfileFeatures[elm.vendor_code]
      );
      if (formatFilter) {
        type.enabled = formatFilter;
      }
      return type;
    }).filter((list) => list.enabled);
    /**
     * To remove the below getItem in sessionStorage value to set static value on selectedAvailability and selectedFormat.
     * Please use if you need to set the below code for selectedAvailability and selectedFormat.
     *
     * this.selectedAvailability = sessionStorage.getItem(HOME_REFINER.AVAILABILITY) || HOME_AVAILABILITY[0].value;
     * this.selectedFormat = sessionStorage.getItem(HOME_REFINER.FORMAT) || KZ_HOME_FORMAT_TYPE[0].value
     */
    this.selectedFormat = KZ_HOME_FORMAT_TYPE[0].value;
    if (this.configService.currentLibrary.partnerSiteEnabled) {
      this.partnerSiteEnabled = this.configService.currentLibrary.partnerSiteEnabled;
      this.selectedAvailability = HOME_AVAILABILITY_EPOPUP_LIB[0].value;
      this.homeAvailability = HOME_AVAILABILITY_EPOPUP_LIB;
      this.templeValue = HOME_AVAILABILITY_EPOPUP_LIB[0].value;
    } else {
      this.selectedAvailability = HOME_AVAILABILITY[0].value;
    }
    this.searchStorageService.setHomeFiters(this.selectedAvailability, this.selectedFormat);
    this.getLibrarySettings();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enableFilter.currentValue) {
      this.liveAnnouncer.announce('Page content will be filtered', 'assertive');
      setTimeout(() => {
        this.cdkVisualHidden();
      }, 2000);
    }
  }
  /**
   * To get Library Settings from API
   */
  async getLibrarySettings() {
    // this.isPPCOnlyLibrary = this.configService.getIsPPCOnlyLibrary(this.profileInfoService.currentProfile.profileType.toLowerCase()); // It checks subscribed to PPC condition.
    this.isPPCOnlyLibrary = this.configService.isPPCLibrary;
    if (this.isPPCOnlyLibrary) {
      this.selectedAvailability = HOME_AVAILABILITY[0].value;
      this.selectedFormat = KZ_HOME_FORMAT_TYPE[2].value;
      this.searchStorageService.setHomeFiters(this.selectedAvailability, this.selectedFormat);
    } else {
      this.selectedAvailability = this.selectedAvailability;
      this.selectedFormat = this.selectedFormat;
    }
  }
  /**
   * To be called on Mat Select openedChange and adding role as listbox to the panel for ADA
   */
  openMatSelect(event: boolean) {
    this.setElemAttribute('.kz-title-filter-select ', 'role', 'listbox');
    if (event) {
      document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'true');
      return;
    }
    document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'false');
    document.querySelectorAll('.mat-select-value-text').forEach((n) => {
      n.children[0].setAttribute('aria-live', 'polite');
    });
    document.getElementById('matSidenavContent')?.setAttribute('aria-hidden', 'false');
  }
  /**
   * Update the filter options and refresh the carousels
   * @param filter filter name
   * @param filterValue filter value
   */
  filterChangeForCarousel(filter: string, filterValue: string) {
    this.cdkRemoveVisualHidden();
    if (filter === 'Availability') {
      this.templeValue = filterValue;
      this.selectedAvailability = filterValue;
      document.querySelectorAll('#availabilitySelect .mat-select-value-text').forEach((n) => {
        n.children[0].setAttribute('id', 'loc_txtavialbleSelect');
        n.children[0].setAttribute('aria-live', 'polite');
      });
      document.querySelectorAll('#formatSelect .mat-select-value-text').forEach((n) => {
        n.children[0].setAttribute('aria-live', 'off');
      });
    } else {
      this.selectedFormat = filterValue;
      if (filterValue === 'VID' || filterValue === 'VBK') {
        this.selectedAvailability = 'Available';
      } else {
        this.selectedAvailability = deepClone(this.templeValue);
      }
      document.querySelectorAll('#formatSelect .mat-select-value-text').forEach((n) => {
        n.children[0].setAttribute('id', 'loc_formatSelecttext');
        n.children[0].setAttribute('aria-live', 'polite');
      });
      document.querySelectorAll('#availabilitySelect .mat-select-value-text').forEach((n) => {
        n.children[0].setAttribute('aria-live', 'off');
      });
    }
    this.myLibraryAnalytics.trackMyLibraryFilterEvent('FILTERS', { filterName: filterValue });
    this.searchStorageService.setHomeFiters(this.selectedAvailability, this.selectedFormat);
    this.homeFilterChanged.emit();
  }
}
