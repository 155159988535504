import { Component, Input } from '@angular/core';
import { ThirdPartyService } from '../../services/third-party.service';

@Component({
  selector: 'axis360-third-party-action-buttons',
  templateUrl: './third-party-action-buttons.component.html',
  styleUrls: ['./third-party-action-buttons.component.scss']
})
export class ThirdPartyActionButtonsComponent {
  @Input() componentApiData: any;
  constructor(public thirdPartyService: ThirdPartyService) { }

  cardAction(actions) {
    const { titleID, ISBN, bookTitle, format, isAvailable, collectionId } = this.componentApiData
    const action = {
      data: {
        type: actions.type,
        actiontype: actions.actionType,
        pageName: "tier3",
        ItemId: titleID,
        ISBN,
        title: bookTitle,
        vendorCode: format === 'VBK' ? "VBOOKS" : 'VIDEOS',
        formatType: format,
        isAvailable,
        collectionId
      }
    };
    this.thirdPartyService.cardAction(action)
  }

  menuState(state){
    if (state) {
      return;
    }
    setTimeout(() => {
      document.getElementById('loc_more_option').setAttribute('aria-expanded', 'false');
    }, 1000);
  }
}
