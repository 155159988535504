import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
@Directive({
  selector: '[axis360EllipsisTitle]'
})
export class EllipsisTitleDirective implements AfterViewInit {
  @Input() description: string;
  constructor(public elementRef: ElementRef, public renderer: Renderer2) { }
  @HostListener('mouseenter') onMouseEnter() {
    const element: HTMLElement = this.elementRef.nativeElement;
    if (element.classList.contains('single-ellipsis')) {
      if (element.offsetWidth < element.scrollWidth) {
        element.title = element.innerText;
      }
    } else {
      if (element.offsetHeight < element.scrollHeight) {
        element.title = this.description ? this.description : element.innerText;
      }
    }
  }
  /**
   * Hosts listener
   */
  @HostListener('mouseleave') onMouseLeave() {
    const element: HTMLElement = this.elementRef.nativeElement;
    element.title = '';
  }
  /**
   * after view init
   */
  ngAfterViewInit() {
    const paragraph = this.elementRef.nativeElement;
    if (paragraph.offsetHeight < paragraph.scrollHeight && paragraph.classList.contains('five-ellipsis')) {
      const el = this.elementRef.nativeElement;
      const words = el.textContent.split(" ");
      el.textContent = "";
      const spliID = words.findIndex((word, i) => {
        el.textContent += (i ? " " : "") + word;
        return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
      });
      el.innerHTML = `<span>${words.slice(0, spliID + 1).join(" ")}</span>`;
    }
  }
}
