<p class="kz-visually-hidden" role="alert" *ngIf="isAvatarAnnouce" aria-live="polite" aria-atomic="true">{{pageAnnounce}} Page</p>
<div class="kz-main kz-add-avatar" (window:resize)="onResize()"
[ngStyle]="backgroundImageUrl ? {'background-image': 'url(' + backgroundImageUrl + ')'} : ''">
    <button tabindex="0" id="avatar-back" class="btn-nobg-bluecolor d-flex back-btn" (click)="avatarChanged.emit(undefined)"> <mat-icon aria-hidden="true"
            class="back-arrow" svgIcon="kz-back-arrow" aria-hidden="true"></mat-icon>back</button>
    <div class="kz-title">
        <h1 class="kz-heading">Avatars</h1>
    </div>
    <axis360-spinner-loader isLoading="true" *ngIf="isLoading; else avatarContent"></axis360-spinner-loader>
    <ng-template #avatarContent>
        <div role="list" class="kz-avatar-img" *ngIf="avatarUrls">
            <div role="listitem" *ngFor="let aImg of avatarUrls; let i = index">
                <a href="javascript:;" class="avatar-select" 
                (click)="setAvatarAndNavigate(aImg.avatarUrl)"><img decoding="sync" [src]="aImg.avatarUrl"
                    [alt]="'avatar '+ aImg.avatarName" aria-hidden="true"/><span class="avatar-name" >{{aImg.avatarName}}</span></a>
            </div>
        </div>
        <div class="done_Submit kz-avatar-btn">
            <button class="avatar-btn doneBtn" aria-label="Upload Photo" (click)="uploadPhoto()" *ngIf="type.toLowerCase() !== 'kid'"
                id="upload-photo-button">
                <mat-icon svgIcon="kz-upload-photo" aria-hidden="true"></mat-icon>Upload Photo
            </button>
            <axis360-file-upload-input (uploadEvent)="uploadPhotoEvent($event)" *ngIf="type.toLowerCase() !== 'kid'"
                style="display: none;"></axis360-file-upload-input>
        </div>
    </ng-template>
</div>