import { Component, EventEmitter, Output } from '@angular/core';
import { IMG_FILE_MAX_SIZE } from '@profile/shared/constants/profiles.constants';
import { resizeImageOnUpload } from '@shared/helper/app.util';

export interface UploadEventData {
  error?: string;
  data?: string;
}
@Component({
  selector: 'axis360-file-upload-input',
  templateUrl: './file-upload-input.component.html'
})
export class FileUploadInputComponent {
  @Output() uploadEvent = new EventEmitter<UploadEventData>();
  imageError: string;
  profileImg: string;
  /**
   * Called on upload photon button click to trigger click event on input file type
   */
  uploadPhoto(): void {
    document.getElementById('image-upload-input')?.click();
  }
  /**
   * Emit the uploadEvent with event data
   * @param data uploadEventDate
   */
  emitUpload = (data: UploadEventData) => {
    this.uploadEvent.emit(data);
  };
  /**
   * Called on image upload to resize the image and emit result
   * @param img html image element
   * @returns function on img onload
   */
  onImageUpload = (img: HTMLImageElement) => () => {
    this.emitUpload({ data: resizeImageOnUpload(img) });
  };
  /**
   * To be called on success of file upload
   * @param e file reader event
   */
  fileOnload = (e: ProgressEvent<FileReader>) => {
    const img = new Image();
    img.src = e.target.result as string;
    img.onload = this.onImageUpload(img);
  };
  /**
   * Upload the file on upload photo click
   * @param event Html input event
   */
  changeEvent(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    const ImageInput = document.getElementById('image-upload-input') as HTMLInputElement;
    if (!file) {
      this.emitUpload({ error: 'File is not uploaded' });
      if (ImageInput) {
        ImageInput.value = '';
      }
      return;
    }
    if (file.size > IMG_FILE_MAX_SIZE) {
      this.emitUpload({ error: 'Please try uploading image less than ' + IMG_FILE_MAX_SIZE / 1024 / 1024 + 'MB' });
      if (ImageInput) {
        ImageInput.value = '';
      }
      return;
    }
    if (!['image/png', 'image/gif', 'image/jpeg'].includes(file.type)) {
      this.emitUpload({ error: 'Only Images are allowed ( JPG | PNG | GIF)' });
      if (ImageInput) {
        ImageInput.value = '';
      }
      return;
    }
    const reader = new FileReader();
    reader.onload = this.fileOnload;
    reader.readAsDataURL(file);
  }
}
