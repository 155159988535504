<div class="thirdparty-tiertworightwrap">
    <p class="kz-visually-hidden no-result-title heading-1" *ngIf="isSearchPage && (componentMetaData.totalCount === undefined || componentMetaData.totalCount === 0 )">
        <span id="searchResultsThirdParty" class="kz-visually-hidden"  role="alert">
            Search Results Page Loading</span>
    </p>
    <div class="thirdparty-titlewrap" role="presentation">
        <h2 class="thirdparty-title" id="tier2-heading">
            <span class="kz-visually-hidden" id="tier2-heading-count" role="alert" *ngIf="componentMetaData.totalCount != undefined">{{(title || component.title) + ' Count ' + componentMetaData.totalCount }}</span>
            <span aria-hidden="true" tabindex="-1">{{title || component.title}} </span>
            <span class="results-text" *ngIf="componentMetaData.totalCount != undefined" aria-hidden="true"
                tabindex="-1">({{componentMetaData.totalCount}})</span>
        </h2>
        <button class="filter btn-nobg-bluecolor" (click)="openFilterEvent.emit()" *ngIf="!hideFilter">
            <mat-icon svgIcon="kz-filter-icon" attr.aria-label="refine menu count of {{filtersCount}}"
                aria-hidden="false">
            </mat-icon>
            <span class="kz-filter-count" *ngIf="filtersCount" aria-hidden="true">{{filtersCount}}</span>
        </button>
    </div>
    <axis360-navigation-tabs class="nav-library" [centerTab]="centerTab" [multiTabsList]="selectedOptions"
        [isTierTwo]="true" (removeSelection)="removeSelection($event)">
    </axis360-navigation-tabs>
    <div [class]="thirdPartyClass.divClass">
        <axis360-third-party-cards [componentData]="compData" [vendorCode]="vendorCode"
            [componentTemplate]="component.jsonTempl" [parentRoute]="parentRoute" [componentClass]="componentClass"
            [thirdPartyClass]="thirdPartyClass.cardsClass +  ' ' + compData.thirdPartyVideoClass"
            *ngFor="let compData of componentData" [carouselClass]="'no-carousel-view'">
        </axis360-third-party-cards>
    </div>
    <p class="no-third-party-title" *ngIf="!isLoading && componentData && componentData.length === 0">No Results Found
        for "{{title ||
        component.title}}"</p>
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
</div>