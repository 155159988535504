import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { VendorDetails } from '@shared/data/third-party-model';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ThirdPartyAnalyticService } from '../../services/third-party-analytic.service';
import { ThirdPartyService } from '../../services/third-party.service';
import { THIRD_PARTY_URLS } from '../../constants/third-party.constants';

@Component({
  selector: 'axis360-third-party-widgets-carousel',
  templateUrl: './third-party-widgets-carousel.component.html',
  styleUrls: ['./third-party-widgets-carousel.component.scss']
})
export class ThirdPartyWidgetsCarouselComponent implements OnInit, AfterViewInit {
  @Input() vendorDetails: VendorDetails;
  @Input() seeAllLink: string;
  @Input() parentRoute: string;
  @Output() errorHandler = new EventEmitter<{ error: boolean; vendor_code: string }>();
  catData;
  cellWidth = 130;
  cellMargin = 0;
  cellHeight = 150;
  compName = SHIMMER_COMPONENTS.BADGE_CARD;
  isLoading = true;
  cellsToScroll = 5;
  cellsToScrollNums = [5, 4, 3, 2];
  /**
   * To update the carousel cell width and margin
   */
  onResize = setIvyCarouselProps.bind(this, 130, 0, 150, 130, 0, 150, this.cellsToScrollNums);
  constructor(
    private thirdPartyService: ThirdPartyService,
    public router: Router,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.onResize();
  }
  ngAfterViewInit() {
    const carousel = document.querySelectorAll('.kz-widget-carousel .third-party-widgets-carousel .carousel-arrows');
    carousel.forEach(cardCarousel => {
      const pElement = cardCarousel?.querySelector('a');
      if (cardCarousel) {
        const anchor = document.createElement('a');
        anchor.className = 'link see-all-text'
        anchor.innerText = 'See All';
        anchor.href = 'javascript:void(0);';
        anchor.setAttribute('id', `loc_btn_See all ${this.vendorDetails.display_name}`);
        anchor.setAttribute('aria-label', `See all ${this.vendorDetails.display_name}`);
        const isParentRouteList = this.parentRoute ? `view/${this.parentRoute}/list` : this.seeAllLink;
        const isParentRouteContent = this.parentRoute ? `view/${this.parentRoute}/content` : this.seeAllLink;
        anchor.addEventListener('click', () => {
          const TIER2_URL = this.seeAllLink === THIRD_PARTY_URLS.TIER2 ?
            isParentRouteList : isParentRouteContent;
          this.router.navigate([TIER2_URL],
            {
              queryParams: {
                title: this.vendorDetails.vendor_name,
                vendor_code: this.vendorDetails.vendor_code,
                cardTitle: this.vendorDetails.display_name
              }
            });
        });
        if (!pElement) {
          cardCarousel.insertBefore(anchor, cardCarousel?.children[1]);
        }
      }
    });
  }
  formatDataApi() {
    const vendorSubscription = this.configService.getSubscriptionData((this.vendorDetails.vendor_code || '').toLocaleLowerCase());
    const search = {};
    Object.keys(this.vendorDetails.dataApi.library_settings).forEach((key) => {
      search[key] = vendorSubscription[this.vendorDetails.dataApi.library_settings[key]];
      return search;
    });
    this.vendorDetails.dataApi.search = {
      ...this.vendorDetails.dataApi.search,
      ...search,
      categoryType: this.vendorDetails.category_type,
      formatCode: this.vendorDetails.format_code
    };
  }
  async getCatData() {
    try {
      this.catData = await this.thirdPartyService.getThirdPartyCategories(this.vendorDetails.dataApi);
      this.catData = [this.catData];
      (!this.catData[0] || this.catData[0]?.categories?.length === 0) &&
        this.errorHandler.emit({ error: true, vendor_code: this.vendorDetails.vendor_code });
      this.isLoading = false;
    } catch (e) {
      (e.status === 400 || e.status === 401 || e.status === 500 || e.statusCode === 500) &&
        this.errorHandler.emit({ error: true, vendor_code: this.vendorDetails.vendor_code });
      this.isLoading = false;
    }
  }
  redirectToTierTwo(qParams: {}) {
    this.router.navigate(['view/library/content/list'], {
      queryParams: { ...qParams, searchType: this.vendorDetails.category_type, vendor_code: this.vendorDetails.vendor_code, q: '', tp: '' }
    });
  }
  acOnExecuteAction(action: { data: { id?: string; title?: string; code?: string } }) {
    this.thirdPartyAnalyticService.trackCategortyCarouselEvent('ACTIONS_CATEGORTY');
    const { id: has, title, code } = action.data;
    this.redirectToTierTwo({ has, title, code, page: 'library' });
  }

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.thirdPartyAnalyticService.trackWidgetsEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.thirdPartyAnalyticService.trackWidgetsEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling?.classList.remove('carousel-arrow-disabled');
    }
  }
}
