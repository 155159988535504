import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { BehaviorSubject, Observable } from 'rxjs';
import { PROGRAMS_PAGE_SIZE } from '../constants/programs.constants';
import { ProgramAPIRes, ProgramsAPIRes } from '../data/programs.model';
@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  navigationProgram = new BehaviorSubject<boolean>(false);
  backgroundImageSubject = new BehaviorSubject<string[]>([]);
  selectedProgramsTab = 0;

  /**
   * set BackgroundImage
   * @param [bgImage] pass the BackgroundImage Array
   */

  public setBackgroundImage(bgImage: string[]) {
    this.backgroundImageSubject.next(bgImage);
  }

  /**
   * 
   * @returns send the BackgroundImageSubject
   */
  public getBackgroundImageSubject(): Observable<string[]> {
    return this.backgroundImageSubject.asObservable();
  }

  /**
   * Creates an instance of programs service.
   * @param http http client
   */
  constructor(public http: HttpClient) { }
  getPrograms = (pageIndex: number, filterByStatus: string): Observable<ProgramsAPIRes> => {
    return this.http.get<ProgramsAPIRes>(
      API_INFO.getPrograms
        .replace('<pageIndex>', `${pageIndex}`)
        .replace('<pageSize>', `${PROGRAMS_PAGE_SIZE}`)
        .replace('<filterByStatus>', `${filterByStatus}`.toLowerCase())
        .replace('<isMyshelf>', `false`)
    );
  };
  getOpenPrograms = (pageIndex: number): Observable<ProgramsAPIRes> => {
    return this.http.get<ProgramsAPIRes>(
      API_INFO.getOpenPrograms.replace('<pageIndex>', `${pageIndex}`).replace('<pageSize>', `${PROGRAMS_PAGE_SIZE}`)
    );
  };
  getProgramById(programId: string): Observable<ProgramAPIRes> {
    return this.http.get<ProgramAPIRes>(API_INFO.getProgramById.replace('<programId>', programId));
  }
  getProgramProgress(programId: string): Observable<ProgramAPIRes> {
    return this.http.get<ProgramAPIRes>(API_INFO.getProgramProgress.replace('<programId>', programId));
  }
  leaveProgram(programId: string): Observable<ProgramAPIRes> {
    return this.http.post<ProgramAPIRes>(API_INFO.leaveProgram.replace('<programId>', programId), {});
  }
  joinProgram(programId: string): Observable<ProgramAPIRes> {
    return this.http.post<ProgramAPIRes>(API_INFO.joinProgram.replace('<programId>', programId), {});
  }
}
