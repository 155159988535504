import { Injectable } from '@angular/core';
import { AnalyticsService } from '@shared/services/analytics.service';
import {
  PROGRAMS_CATEGORY,
  PROGRAMS_EVENTS,
  PROGRAMS_EVENTS_KEY,
  PROGRAMS_SCREENS,
  PROGRAMS_SCREENS_KEY
} from '../constants/programs.anaytics.constants';
interface AltObj {
  [key: string]: string;
}
type Action = keyof typeof PROGRAMS_EVENTS_KEY;
@Injectable({
  providedIn: 'root'
})
export class ProgramsAnalyticsService extends AnalyticsService {
  /**
   * Creates an instance of notifications analytics service.
   */
  constructor() {
    super(PROGRAMS_SCREENS, PROGRAMS_EVENTS);
  }
  /**
   * To track programs page view
   * @param screen screen key to track
   */
  trackScreen(screen: keyof typeof PROGRAMS_SCREENS_KEY, altObj: AltObj = {}) {
    this.trackPage(screen, altObj);
  }
  /**
   * To track programs event
   * @param action event action name
   * @param [altObj] to alter the tracking text
   */
  trackNotificationsEvent(action: Action, altObj: AltObj = {}) {
    this.trackEvent(PROGRAMS_CATEGORY, action, altObj);
  }
}
