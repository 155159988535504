import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'axis360-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: { text: string; url: string; handleClick?: boolean; isHref?: boolean, queryParams?: any }[];
  @Input() breadcrumbsClass: string;
  @Output() clickBreadcrumb = new EventEmitter<string>();

  /**
   * clickonBreadcrumb
   * @param text Emiting the clicked card name
   */
  clickOnBreadcrumb(text: string) {
    this.clickBreadcrumb.emit(text);
  }
}
