import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProfileDetail } from '@profile/shared/data/profiles.model';
import { OPEN_JOIN_PROGRAM } from '@readingprograms/shared/constants/programs.constants';
import { Book, BookDetail } from '@readingprograms/shared/data/engage.model';
import { ProgramCard, ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { AlertDialog, AlertDialogComponent } from '@shared/components/alert-dialog/alert-dialog.component';
import { getBookIcon, getYesOrNo, redirectUrl, strToBool } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { UserService } from '@shared/services/user.service';
import { handleProgramDetails } from '../../helper/programs.util';

@Component({
  selector: 'axis360-join-program-popup',
  templateUrl: './join-program-popup.component.html',
  styleUrls: ['./join-program-popup.component.scss']
})
export class JoinProgramPopupComponent implements OnInit {
  programData: ProgramDetails;
  programBooks: BookDetail[] = [];
  joinedProgram = false;
  kidsZoneEnabled = false;
  userName: string;
  isLoading: boolean;
  hiddenTitleCover = '/angular-app/assets/images/icons-core-unavailable.svg';
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ProgramCard,
    private enagageService: EngageService,
    private programsService: ProgramsService,
    private snackBarService: SnackBarService,
    private userService: UserService,
    private router: Router,
    public joinProgramDialog: MatDialogRef<JoinProgramPopupComponent>,
    private programsAnalyticsService: ProgramsAnalyticsService,
    private matDialog: MatDialog,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService
  ) { }
  ngOnInit(): void {
    this.userName = this.userService.getUserName();
    this.getProgramDetails();
    this.programsAnalyticsService.trackScreen('JOIN_PROGRAM');
    this.joinProgramDialog.afterClosed().subscribe(() => {
      this.programsAnalyticsService.trackNotificationsEvent('CLOSE_JOIN_POPUP');
    });
  }
  menuStateChangeEvent(state: boolean) {
    if (state) {
      this.joinProgramDialog.disableClose = true;
      return;
    }
    // This timeout is needed for enabling close after menu is opened and tapping ESC
    setTimeout(() => {
      this.joinProgramDialog.disableClose = false;
    }, 300);
  }
  async getBookDetails(books?: Book[]): Promise<{ [itemId: string]: BookDetail }> {
    const bookIds = (books || []).map(({ bookId }) => bookId);
    if (!bookIds.length) {
      return {};
    }
    try {
      this.isLoading = true;
      const booksDetailsRes = await this.enagageService.getBooksByIdV1(bookIds, true);
      const bookDetails = (booksDetailsRes.items || []).reduce((acc, cur) => {
        acc[cur.itemId] = cur;
        return acc;
      }, {});
      this.programBooks = bookIds.map((bookId) => bookDetails[bookId] || {});
      this.isLoading = false;
    } catch (e) {
      this.snackBarService.showSnackBar();
      this.isLoading = false;
      return {};
    }
  }
  async getProgramDetails() {
    this.programData = { ...handleProgramDetails(this.modalData) };
    this.getBookDetails(this.programData.books);
  }

  openAlert(data: AlertDialog) {
    this.matDialog.open(AlertDialogComponent, { panelClass: 'mat-alert-dialog', data, ariaLabelledBy: 'alert-heading' });
  }

  doJoinProgram = () => this.joinProgram();

  /**
   * kidsZone Enable tracking update service
   * @param enable sent boolean value
   */
  enableDisableTrackingKidsZone(enable: boolean) {
    const { profileId, defaultLandingPageID, displayCheckoutHistory, highContrast } = this.profileInfoService.currentProfile || {};
    this.profileInfoService.currentProfile.defaultLandingPageID = defaultLandingPageID ?? '2';
    this.profileInfoService.currentProfile.enableTracking = enable?'True':'False';
    this.profileInfoService.currentProfile.displayCheckoutHistory = getYesOrNo(strToBool(displayCheckoutHistory));
    this.profileInfoService.currentProfile.highContrast = getYesOrNo(strToBool(highContrast));
    this.profileInfoService.currentProfile.displayTrackingPrompt = 'False';
    const request: ProfileDetail = { ...this.profileInfoService.currentProfile };
    request.enableTracking=getYesOrNo(enable);
    this.profileInfoService.updateProfile(profileId, request).subscribe(this.doJoinProgram);
  }
  enableDisableTracking(enable: boolean) {
    this.userService.userInfo.enableTracking = enable;
    this.userService.userInfo.displayTrackingPrompt = false;
    this.userService.updateUserTracking().subscribe(this.doJoinProgram, this.doJoinProgram);
  }
  /**
   * Join Program dialog alert
   * @returns alert open and update service
   */
  joinProgram() {
    this.programsAnalyticsService.trackNotificationsEvent('JOIN_PROGAM');
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    const { displayTrackingPrompt, enableTracking, insightsBadgesEnabled } = this.profileInfoService.currentProfile || {};
    if (this.kidsZoneEnabled && strToBool(displayTrackingPrompt) && !strToBool(enableTracking) && insightsBadgesEnabled) {
      this.isLoading = true;
      this.openAlert({
        ...OPEN_JOIN_PROGRAM,
        submitBtnFunc: () => this.enableDisableTrackingKidsZone(true),
        cancelBtnFunc: () => this.enableDisableTrackingKidsZone(false)
      });
      return;
    }
    else if (!this.kidsZoneEnabled && this.configService.currentLibrary.isMigrated? (!this.userService.isTrackingEnabled() && this.userService.isDisplayTrackingPrompt()): this.userService.isDisplayTrackingPrompt()) {
      this.isLoading = true;
      this.openAlert({
        ...OPEN_JOIN_PROGRAM,
        submitBtnFunc: () => this.enableDisableTracking(true),
        cancelBtnFunc: () => this.enableDisableTracking(false)
      });
      return;
    }
    this.programsService.joinProgram(this.programData.id).subscribe(
      () => {
        this.isLoading = false;
        this.joinedProgram = true;
        this.programsAnalyticsService.trackScreen('WELCOME_PROGRAM');
      },
      () => {
        this.isLoading = false;
        this.snackBarService.showSnackBar();
      }
    );
  }
  goToProgram() {
    this.joinProgramDialog.close();
    this.router.navigateByUrl(`/program/${this.programData.id}`);
    this.programsAnalyticsService.trackNotificationsEvent('GOTO_PROGRAM');
  }
  getIcon(formatType) {
    return getBookIcon(formatType);
  }
  closeWelcomeModal() {
    this.matDialog.closeAll();
    redirectUrl(this.router, '/programs/open', true);
  }
  closeDialog(): void {
    this.joinProgramDialog.close();
  }
}
