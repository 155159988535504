import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@shared/services/spinner.service';
import { MyStuffService } from '../shared/services/my-stuff.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { EngageBaseService } from '@shared/services/engage-base.service';
@Component({
  selector: 'axis360-my-stuff-titles-main',
  templateUrl: './my-stuff-titles-main.component.html',
  styleUrls: ['./my-stuff-titles-main.component.scss']
})
export class MyStuffTitlesMainComponent implements OnInit {
  activeMyStuff: string;
  parentRoute: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public myStuffService: MyStuffService,
    public spinnerService: SpinnerService,
    public searchStorageService: SearchStorageService,
    public enagageService: EngageBaseService,
    public router: Router
  ) { }
  ngOnInit(): void {
    this.myStuffService.activeMyStuff = this.activatedRoute.snapshot.routeConfig.path;
  }

}
