import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '@shared/services/account.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { FAIL, REGISTER } from '../shared/constants/account.constants';
import { RegisterModel, RegisterResponseModel } from '../shared/data/account.model';

@Component({
  selector: 'axis360-password-verification',
  templateUrl: './password-verification.component.html',
  styleUrls: ['./password-verification.component.scss']
})
export class PasswordVerificationComponent implements OnInit {
  @Input() loginData: RegisterModel;
  @Input() noModal = false;
  isLoggedIn = false;
  registerData: RegisterResponseModel;
  passwordVerificationForm: FormGroup;
  registerResponse: string;
  showRegisterPrompt: boolean;
  enterValidPassword: boolean = false;
  constructor(public spinnerService: SpinnerService, public accountService: AccountService, public matDialog: MatDialog) {}

  ngOnInit() {
    this.accountService.currentDialog = REGISTER;
    this.getRegisterData();
  }

  getRegisterData() {
    this.passwordVerificationForm = new FormGroup({
      RegistrationPassword: new FormControl('',[Validators.required, Validators.maxLength(50)])
    });
  }

  onSubmit() {
    this.spinnerService.withObservable(this.accountService.verifyRegisterPassword(this.passwordVerificationForm.value)).subscribe(
      (response) => {
        if (response !== FAIL) {
          this.enterValidPassword = false;
          this.registerResponse = response;
          this.showRegisterPrompt = true;
          return;
        }
        this.showRegisterPrompt = false;
        this.enterValidPassword = true;
      },
      () => {
        this.showRegisterPrompt = false;
        this.enterValidPassword = false;
      }
    );
  }
}
