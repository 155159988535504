export const FORMAT_TYPE = {
    eBOOK: 'EBT',
    eAUDIO: 'ABT',
}
export const CAROUSEL_TITLE = [
    'eBook (2k+)',
    'eAudio (999)',
    'Video (999)',
    'vBook (999)'
];
export const CATEGORY_TYPE = {
    ALL: "All",
    TITLES: "titles",
    EBOOKS_TITLES: "eBook",
    EAUDIO_TITLES: "eAudio",
    ACTIVE_RESOURCES: "active_Resources",
    WEB_RESOURCES: "web_Resources",
    THIRD_PARTY_SEARCH: 'third_Party'
}

export const CATEGORY_TYPE_LIST = [
    { text: 'All', value: 'All', enabled: true, url: '/view/search' },
    // { text: 'eBook, eAudio', value: 'titles', enabled: true, url: '/view/search/titles' },
    { text: 'eBook', value: 'eBook', enabled: true, url: '/view/search/eBook' },
    { text: 'eAudio', value: 'eAudio', enabled: true, url: '/view/search/eAudio' },
    { text: 'Resource Hub', value: 'active_Resources', enabled: true, url: '/view/search/active_Resources' },
    { text: 'Web Resources', value: 'web_Resources', enabled: true, url: '/view/search/web_Resources' }
]
export const CATEGORY_VALUE = {
    [CATEGORY_TYPE.ALL]: "All",
    [CATEGORY_TYPE.TITLES]: "titles",
    [CATEGORY_TYPE.EBOOKS_TITLES]: "eBook",
    [CATEGORY_TYPE.EAUDIO_TITLES]: "eAudio",
    [CATEGORY_TYPE.ACTIVE_RESOURCES]: "active_Resources",
    [CATEGORY_TYPE.WEB_RESOURCES]: "web_Resources"
}
export const SELECTED_CATEGORY = {
    eAudio: "ABT",
    eBook: "EBT",
    active_Resources: "learningActivity",
    web_Resources: "webPathExpress"
}
export const DEFAULT_PATH_PARMS = {
    page: "1",
    pageSize: "10",
    format: "EBT",
    agelevel: "",
    availability: "",
    collections: "",
    axisattribute: "",
    recentlyadded: "",
    language: "",
    releasedate: "",
    searchText: "",
    searchby: "",
    undefined: ""
}
export const SITES_TITLE = 'Web Resources';
export const AUDIO_FORMAT = 'ABT';
export const EAUDIO = 'eAudios';
export const EBOOK = 'eBooks';
export const EBOOK_EAUDIO = 'eBooks, eAudios';
export const FORMAT = 'format';
export const CATEGORY_REFINER = {
    [CATEGORY_TYPE.TITLES]: "showTitleRefiner",
    [CATEGORY_TYPE.EAUDIO_TITLES]: "showEaudioRefiner",
    [CATEGORY_TYPE.EBOOKS_TITLES]: "showEbookRefiner",
    [CATEGORY_TYPE.ACTIVE_RESOURCES]: "showLeaningActivityRefiner",
    [CATEGORY_TYPE.WEB_RESOURCES]: "showSitesRefiner"
}
export const CATEGORY_SORT_BY = {
    RETURNDATE: "Returndate",
    ADDEDDATE: "AddedDate",
    PUBLISHDATE: "PublishDate",
    SCORE: "Score"
}

export const THIRD_PARTY_VENDOR_CODE = {
    PRESSRDR: 'PRESSRDR',
    VIDEOS: 'VIDEOS',
    VBOOKS: 'VBOOKS'
};

export const THIRD_PARTY_VENDOR_CODE_TITLE = {
    PRESSRDR: 'NewaPaper and Magazines',
    VIDEOS: 'Videos',
    VBOOKS: 'Videobooks'
};

export const CHECK_VENDOR_CODE = {
    Publications: THIRD_PARTY_VENDOR_CODE.PRESSRDR,
    Articles: THIRD_PARTY_VENDOR_CODE.PRESSRDR,
}

export const CATEGORY_SORT_FIELD = {
    [CATEGORY_SORT_BY.RETURNDATE]: "Returndate",
    [CATEGORY_SORT_BY.ADDEDDATE]: "AddedDate",
    [CATEGORY_SORT_BY.PUBLISHDATE]: "PublishDate",
    [CATEGORY_SORT_BY.SCORE]: "Score"
}
export const PRESSRDR = 'PRESSRDR';

export const EBOOK_EAUDIO_REFINER_ORDER_LIST = [
    'Sort By',
    'Availability',
    'Format',
    'Audience Level',
    'Subject',
    'Language',
    'Publication Date',
    'Added Date',
    'Attributes'
];

export const SITES_REFINER_ORDER_LIST = [
    'Grade Level',
    'Subject',
    'Language',
    'Format',
    'Source',
    'Domain'
];