<div class="kz-drawer-refiners-container kz-main kz-search-refiners" aria-describedby="loc_refineAnnounce"
  *ngIf="!isMobileResponsive" [ngClass]="{'browse-sec': filterData?.menuType === TITLE_LIST.SUBJECT}">
  <div class="refiners-head visible-sm">
    <a tabindex="0" #closeButton cdkFocusInitial class="close-icon" role="button" aria-label="Close refiners" (click)="dialogClose()" (keydown.enter)="dialogClose()">
      <mat-icon svgIcon="kz-close-black" aria-hidden="true">close</mat-icon>
    </a>
  </div>
  <p class="cdk-visually-hidden" #loc_refineAnnounce aria-live="polite">Content on main section will get update on
    selecting any of the refiner option</p>
  <p class="kz-visually-hidden" aria-live="polite" *ngIf="isLoadAnnouncer" role="alert"> Loading</p>
  <button class="skip-refiners skip-refiners-sort" id="loc_btnSkiptoMain" (click)="skipRefiner()"(keydown.enter)="skipRefiner()">
      Skip the Refiners
  </button>
  <div class="refine-filter-head refiners-header" *ngIf="filterData && filterData?.menuType === TITLE_LIST.SUBJECT">
    <button class="anchor-link btn-nobg-bluecolor" [disabled]="disableReset" (click)="refinerClear()">Reset</button>
  </div>
  <mat-divider aria-hidden="true" class="kz-divider"
    *ngIf="filterData && filterData?.menuType === TITLE_LIST.SUBJECT"></mat-divider>
  <mat-accordion class="kz-checkbox-sec refiners-accordion" multi>
    <mat-expansion-panel class="refiners-expansion-panel category category-list-ref"
      *ngIf="filterData?.menuType === TITLE_LIST.LIST" [expanded]="true">
      <mat-expansion-panel-header class="refiners-panel-header" (focus)="onFocus($event)" (blur)="onBlur($event)">
        <mat-panel-title class="heading-3 panel-heading-description">
          <div class="content-assign content-read">
            <div class="content-name-head">
              <h2 class="refiners-title text-uppercase">featured lists </h2>
              <p class="sub-title-txt">{{filterData?.selectedSubject}}</p>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="accordion-content">
        <ul class="sub-menu">
          <li *ngFor="let item of filterData.featuredList; let i = index">
            <button class="menuitem btn-nobg-bluecolor" id="list_txt{{item.ListName}}{{i}}"
              [ngClass]="{'active': item?.ListID===filterData?.categoryId}"
              (click)="updateFilterList(item, 'featureList', true, false, false,'list_txt'+item.ListName + i );selectedId.emit('list_txt'+item.ListName+i);dialogClose()"
              routerLink="/view/title"
              attr.aria-label="{{item?.ListID===filterData?.categoryId ? 'selected ' + item.ListName : item.ListName}}"
              [queryParams]="{ list: item.ListID }">
              {{item.ListName}}
            </button>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
    <mat-divider aria-hidden="true" class="kz-divider"
      *ngIf="filterData && filterData?.menuType === TITLE_LIST.LIST"></mat-divider>
      <axis360-spinner-loader [isLoading]="filterData.featuredList.length === 0" *ngIf="filterData?.menuType === TITLE_LIST.LIST && filterData.featuredList.length === 0">
      </axis360-spinner-loader>  
    <mat-expansion-panel class="refiners-expansion-panel category"
      (opened)="checkRefineAnalyticsView(sortBy);checkExpandAndCollapseAnalytics(sortBy,'Open')"
      (closed)="checkExpandAndCollapseAnalytics(sortBy,'Close')" [expanded]="true">
      <mat-expansion-panel-header class="refiners-panel-header">
        <mat-panel-title class="heading-3 panel-heading-description">
          <div class="content-assign content-read">
            <div class="content-name-head">
              <h2 class="refiners-title single-ellipsis text-capitalize">
                SORT BY
              </h2>
              <span class="sub-title-txt">{{filterData.selectedSortBy}}</span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let type of filterData?.sortRefinerList ; let j = index">
        <div class="accordion-content" *ngIf="type.text == sortBy">
          <div class="radio-group kz-radio-btn-group" *ngFor="let item of type.items; let i = index">
            <p-radioButton class="kz-radio-button radio-fluid"
              [ngClass]="{'category-text': item.text === 'eBooks' || 'eAudio' }" id="loc_radiobtn{{item.text}}"
              (onClick)="refinerChange(type.text,item.text, true);selectedId.emit('loc_radiobtn'+item.text);dialogClose()"
              [label]="item.text === 'Audiobooks' ? 'eAudio':item.text" [value]="item.value" [(ngModel)]="type.selected"
              (ngModelChange)="type.selectedText=item.text" [inputId]="item.value+j+i" name="type{{j}}" axis360PradioAda
              isRevers="true">
            </p-radioButton>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-divider aria-hidden="true" class="kz-divider"
      *ngIf="filterData?.menuType === TITLE_LIST.SUBJECT && filterData?.isShowSubject"></mat-divider>
  </mat-accordion>
  <mat-divider aria-hidden="true" class="kz-divider" *ngIf="filterData.isEnableSort"></mat-divider>
  <ng-container *ngIf="filterData?.menuType === TITLE_LIST.SUBJECT">
    <div class="refine-filter-head">
      <h2 class="refiners-title text-uppercase" id="loc_txtRefine">Refine</h2>
    </div>
    <div class="refine-section" id="loc_title_list_refiner">
      <mat-divider aria-hidden="true" class="kz-divider"
        *ngIf="(filterData?.menuType === TITLE_LIST.SUBJECT)"></mat-divider>
      <mat-accordion class="kz-checkbox-sec refiners-accordion" multi>
        <ng-container *ngIf="(filterData?.menuType === TITLE_LIST.SUBJECT)">
          <mat-expansion-panel class="refiners-expansion-panel category"
            (opened)="checkExpandAndCollapseAnalytics(TITLE_LIST.SUBJECT,'Open')"
            (closed)="checkExpandAndCollapseAnalytics(TITLE_LIST.SUBJECT,'Close')"
            *ngIf="filterData?.menuType === TITLE_LIST.SUBJECT && filterData?.isShowSubject" [expanded]="true">
            <mat-expansion-panel-header class="refiners-panel-header" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-panel-title class="heading-3 panel-heading-description">
                <div class="content-assign content-read">
                  <div class="content-name-head">
                    <h2 class="refiners-title-secondary text-capitalize" id="loc_dropdwnBrowseBySubject">
                      subject
                    </h2>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-content category-items" role="list">
              <axis360-spinner-loader [isLoading]="filterData.isLoadingSubject" *ngIf="filterData.isLoadingSubject">
              </axis360-spinner-loader>
              <div class="radio-group check-group" *ngFor="let item of filterData?.subjectList" role="listitem">
                <mat-checkbox axis360MatCheckboxAda class="kz-radio-button check-button" [name]="item.key"
                  id="loc_txt{{item.text}}" [value]="item.text"
                  [ngClass]="{'mat-checkbox-checked': item.isChecked }" [checked]="item.isChecked"
                  attr.aria-checked="{{item.isChecked}}"
                  (change)="updateFilterList(item.text,TITLE_LIST.SUBJECT, item,true, $event.checked);dialogClose()">
                  {{item.text.toLowerCase()}}
                </mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <mat-divider aria-hidden="true" class="kz-divider"
          *ngIf="filterData?.menuType === TITLE_LIST.SUBJECT && filterData?.isShowSubject"></mat-divider>
        <ng-container *ngIf="filterData?.showCategory">
          <mat-expansion-panel class="refiners-expansion-panel category category-list-ref"
            (opened)="checkExpandAndCollapseAnalytics('Categories','Open')"
            (closed)="checkExpandAndCollapseAnalytics('Categories','Close')"
            *ngIf="filterData?.menuType === 'subject' && filterData?.isShowSubject" [expanded]="true">
            <mat-expansion-panel-header class="refiners-panel-header" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-panel-title class="heading-3 panel-heading-description">
                <div class="content-assign content-read">
                  <div class="content-name-head">
                    <div class="refiners-title-secondary text-capitalize" id="loc_dropdwnFeaturedCategories"> categories
                    </div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-content category-items">
              <axis360-spinner-loader [isLoading]="filterData.isLoadingCategory" *ngIf="filterData.isLoadingCategory">
              </axis360-spinner-loader>
              <ul>
                <li *ngFor="let item of filterData?.categoryList">
                  <div class="radio-group check-group">
                    <mat-checkbox axis360MatCheckboxAda class="kz-radio-button check-button"
                      [ngClass]="{'mat-checkbox-checked': item.isChecked }" [name]="item.key" id="loc_txt{{item.code}}"
                      [value]="item.text" [checked]="item.isChecked"
                      attr.aria-label="{{item.text===filterData.selectedCategory.toUpperCase() ? 'selected ' + item.text.toLowerCase() : item.text.toLowerCase()}}"
                      (change)="updateFilterList(item.text,'category', item,true, $event.checked);dialogClose()">
                      {{(item.text.toUpperCase()===TITLE_LIST.LGBTQ)?item.text.toUpperCase():item.text.toLowerCase()}}
                    </mat-checkbox>
                  </div>
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
          <mat-divider aria-hidden="true" class="kz-divider"
            *ngIf="filterData?.menuType === 'subject' && filterData?.isShowSubject"></mat-divider>
        </ng-container>
        <mat-divider aria-hidden="true" class="kz-divider"
          *ngIf="filterData?.menuType === 'subject' && filterData?.isShowSubject">
        </mat-divider>
        <ng-container *ngIf="!filterData.isLoadingRefiner">
          <ng-container *ngFor="let type of filterData?.refiners ; let j = index">
            <div *ngIf="!(epopupValue && type.text === 'Availability')">
              <mat-expansion-panel role="listitem" class="refiners-expansion-panel category"
                *ngIf="(filterData?.menuType === TITLE_LIST.SUBJECT && type.text != 'Sort By') || filterData?.menuType !== TITLE_LIST.SUBJECT"
                (opened)="checkRefineAnalyticsView(type.text);checkExpandAndCollapseAnalytics(type.text,'Open')"
                (closed)="checkExpandAndCollapseAnalytics(type.text,'Close')" [expanded]="state === j" (opened)="setState(j)">
                <mat-expansion-panel-header class="refiners-panel-header" (focus)="onFocus($event)" (blur)="onBlur($event)">
                  <mat-panel-title class="heading-3 panel-heading-description">
                    <div class="content-assign content-read">
                      <div class="content-name-head">
                        <h2 class="refiners-title-secondary single-ellipsis text-capitalize" id="loc_dropdwn{{type.text}}">
                          {{type.text}}</h2>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="accordion-content" role="list">
                  <div class="radio-group check-group" *ngFor="let item of type.items; let i = index" role="listitem">
                    <mat-checkbox axis360MatCheckboxAda class="kz-radio-button check-button" [name]="item.key"
                      [value]="item.value" id="loc_txt{{item.text === 'Audiobooks' ? 'eAudio':item.text}}" [ngClass]="{'category-text-browse': type.value === categoryText[type.value], 'format-text': item.text === 'eBooks' || 'eAudio',
                            'mat-checkbox-checked': item.isSelected}" [checked]="item.isSelected"
                      (change)="setSelectedRefiner($event.checked, item , true, 'loc_txt' + (item.text === 'Audiobooks' ? 'eAudio':item.text));dialogClose()"
                      [attr.aria-label]="titleAriaLabel(item.text)"
                      [disabled]="isReadOnly"
                      [attr.aria-disabled]="isReadOnly ? 'true' : 'false'">
                      {{item.text === 'Audiobooks' ? 'eAudio' : (type.text === 'Age Level' ?
                      replaceHyphenToEndash(item.text) : item.text)}}
                    </mat-checkbox>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <mat-divider aria-hidden="true" class="kz-divider"
              *ngIf="(filterData?.menuType === TITLE_LIST.SUBJECT && type.text != 'Sort By') || filterData?.menuType !== TITLE_LIST.SUBJECT"></mat-divider>
          </ng-container>
        </ng-container>
      </mat-accordion>
      <axis360-spinner-loader [isLoading]="filterData.isLoadingRefiner" *ngIf="filterData.isLoadingRefiner">
      </axis360-spinner-loader>
    </div>
  </ng-container>
  <ng-container *ngIf="filterData?.menuType === TITLE_LIST.LIST || filterData?.menuType === TITLE_LIST.ALWAYSAVAILABLE || filterData?.menuType === TITLE_LIST.BASED_ON_INTEREST">
    <div class="refine-section" id="loc_title_list_refiner">
      <div class="refine-filter-head">
        <h2 class="refiners-title text-uppercase" id="loc_txtRefine">Refine</h2>
        <button class="anchor-link btn-nobg-bluecolor" [disabled]="disableReset" (click)="clear(true)">Reset</button>
      </div>
      <axis360-spinner-loader [isLoading]="filterData.isLoadingRefiner" *ngIf="filterData.isLoadingRefiner">
      </axis360-spinner-loader>
      <mat-accordion *ngIf="!filterData.isLoadingRefiner" class="kz-checkbox-sec refiners-accordion" multi>
        <ng-container *ngFor="let type of filterData?.refiners ; let j = index">
          <mat-expansion-panel class="refiners-expansion-panel category"
            *ngIf="type.text !== 'Collections' && type.text != 'Sort By'"
            (opened)="checkRefineAnalyticsView(type.text);checkExpandAndCollapseAnalytics(type.text,'Open')"
            (closed)="checkExpandAndCollapseAnalytics(type.text,'Close')" [expanded]="state === j"
            (opened)="setState(j)">
            <mat-expansion-panel-header class="refiners-panel-header" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-panel-title class="heading-3 panel-heading-description">
                <div class="content-assign content-read">
                  <div class="content-name-head">
                    <h2 class="refiners-title-secondary single-ellipsis text-capitalize"
                      id="loc_dropdwn{{type.text}}">
                      {{type.text}}
                      </h2>
                      <span class="sub-title-txt">
                        {{
                        type.selectedText === "Audiobooks" ?
                        "eAudio":type.selectedText
                        }}
                      </span>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-content">
              <div class="radio-group kz-radio-btn-group" *ngFor="let item of type.items; let i = index">
                <p-radioButton class="kz-radio-button radio-fluid"
                  [ngClass]="{'category-text': item.text === 'eBooks' || 'eAudio' }" id="loc_radiobtn{{item.text}}"
                  (onClick)="refinerChange(type.text,item.text, true);dialogClose()"
                  [label]="item.text === 'Audiobooks' ? 'eAudio':item.text" [value]="item.value"
                  [(ngModel)]="type.selected" (ngModelChange)="type.selectedText=item.text" [inputId]="item.value+j+i"
                  name="type{{j}}" axis360PradioAda isRevers="true">
                </p-radioButton>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-divider aria-hidden="true" class="kz-divider"
            *ngIf="(filterData?.menuType === TITLE_LIST.SUBJECT && type.text != 'Sort By') || filterData?.menuType !== TITLE_LIST.SUBJECT"></mat-divider>
        </ng-container>
      </mat-accordion>
    </div>
  </ng-container>
</div>