import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit, Directive } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: '[axis360MatSelectOverlayClass]'
})
export class MatSelectOverlayClassDirective implements AfterViewInit {
  constructor(private select: MatSelect, private overlayContainer: OverlayContainer) {}
  /**
   * To add and remove class after view init
   */
  ngAfterViewInit() {
    const matSelectOverlay = this.overlayContainer.getContainerElement().classList;
    this.select.openedChange.subscribe((isOpen) => {
      if (isOpen) {
        matSelectOverlay.add('kz-mat-select-overlay');
        document.querySelectorAll('.mat-option').forEach(n => {
          if (!n.classList.value.includes('mat-selected')) {
            n.setAttribute('aria-selected', 'false')
          }
        })
      } else {
        matSelectOverlay.remove('kz-mat-select-overlay');
      }
    });
  }
}
