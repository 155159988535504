import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryCard } from '@pressreader/shared/data/press-reader-model';
import { PressReaderAnalyticsService } from '@pressreader/shared/services/press-reader.analytics.service';
import { redirectUrl } from '@shared/helper/app.util';

@Component({
  selector: 'axis360-press-reader-category-cards',
  templateUrl: './press-reader-category-cards.component.html'
})
export class PressReaderCategoryCardsComponent {
  @Input() categories: CategoryCard[];

  /**
   * Creates an instance of press reader card component.
   * @param router router info
   */
  constructor(private router: Router, private pressReaderAnalyticsService: PressReaderAnalyticsService) {}

  /**
   * redirect to the press reader list page
   * @param type publication type
   * @param cid category id
   */
  redirectToListPage(type: string, cid: string, categoryName = '') {
    this.pressReaderAnalyticsService.trackPressReaderEvent('CATEGORY', { categoryName });
    redirectUrl(this.router, `/pressreader/list?type=${type}&cid=${cid}&refresh=true`);
  }
}
