import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { TitleActionButtonsComponent } from '@shared/components/title-action-buttons/title-action-buttons.component';
import { SNAKE_BAR_MODE, DEFAULT_SNAKE_BAR_MSG } from '@shared/constants/app.constants';
import { ActionClickResponse, Actions, BookDetail, TitleStateInfo } from '@shared/data/engage-base.model';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { TitleDetailsAnalyticsService } from '../../services/title-details.analytics.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { TitleShareComponent } from '../title-share/title-share.component';
import { SharedService } from '@shared/services/shared.service';
import { TITLE_DETAILS_EVENTS_KEY } from '@titledetails/shared/constants/title-details.analytics.constants';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { AccountService } from '@shared/services/account.service';
import { CommonService } from '@shared/services/common.service';
import {
  FACEBOOK_SHARE_LINK,
  SHARE_BASE_URL,
  SOCIAL_MEDIA_LIST,
  TWITTER_SHARE_LINK
} from '@titledetails/shared/constants/title-details.constants';
import { Subscription } from 'rxjs';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { IButtonDetails, IShareActionButton, ITitleDetails } from '@titledetails/shared/data/title-details.model';
import { cdkVisualHidden, deepClone, strToBool } from '@shared/helper/app.util';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { Router } from '@angular/router';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { EmailShareDialogComponent } from '@shared/components/email-share-dialog/email-share-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'axis360-title-details-action-button',
  templateUrl: './title-details-action-button.component.html',
  styleUrls: ['./title-details-action-button.component.scss']
})
export class TitleDetailsActionButtonComponent extends TitleActionButtonsComponent {
  @Input() bookDetail: BookDetail;
  @Input() titleButtonViews: IButtonDetails;
  @Input() shareActionsButton: IShareActionButton;
  @Input() titleDetailsData: ITitleDetails;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  isLoading = false;
  navUrl: string;
  titleStateInfo: TitleStateInfo;
  upDateButtonViewSubscriptions: Subscription;
  showWishlist: boolean = true;
  actiontype: string;
  constructor(
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    public toastService: SnackBarService,
    public userService: UserService,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public liveAnnouncer: LiveAnnouncer,
    public overlayContainer: OverlayContainer,
    @Inject(DOCUMENT) public document: Document,
    public matDialog: MatDialog,
    public elRef: ElementRef,
    public clipboard: Clipboard,
    public sharedService: SharedService,
    public sharedAnalyticsService: SharedAnalyticsService,
    public accountService: AccountService,
    public commonService: CommonService,
    public notificationsService: NotificationsService,
    public dialog: MatDialog,
    public titleDetailsService: TitleDetailsService,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public kzProgramsService: KzProgramsService,
    public router: Router,
    public programAnalyticsService: ProgramsAnalyticsService,
    public location: Location
  ) {
    super(
      engageService,
      spinnerService,
      toastService,
      userService,
      configService,
      profileService,
      liveAnnouncer,
      overlayContainer,
      document,
      matDialog,
      elRef,
      sharedService,
      sharedAnalyticsService,
      accountService,
      commonService,
      location,
      kzProgramsService,
      notificationsService,
      programAnalyticsService,
      router,
      titleDetailsService
    );
  }

  /**
   * To init TitleDetailsActionButtonComponent
   */
  ngOnInit(): void {
    this.initBookDetail();
    this.titleDetailsService.statusUserAgentCheck = navigator.userAgent.match(/(Android|iPhone|iPad|iPod|Mac)/i);
    this.titleStateInfo = this.bookDetail && { ...this.bookDetail?.titleStateInfo, actions: this.bookDetail?.titleStateInfo.actions.filter(x => !x.actionButtonId.includes('btnAddWishlist') && !x.actionButtonId.includes('btnRemoveWishlist')) };
    this.currentLibrary = this.configService.currentLibrary;
    this.currentProfile = this.profileService.profileInfo;
    if (this.currentLibrary.partnerSiteEnabled) {
      this.showWishlist = false;
    }
    this.upDateButtonViewSubscriptions = this.titleDetailsService.getUpdateButtonView().subscribe((updateBtn) => {
      if (updateBtn !== null && updateBtn.actions[0].itemId === this.bookDetail.itemId) {
        this.titleButtonViews = deepClone(updateBtn);
        this.titleStateInfo = updateBtn;
        this.titleStateInfo.actions = this.titleStateInfo.actions.filter(x => !x.actionButtonId.includes('btnAddWishlist') && !x.actionButtonId.includes('btnRemoveWishlist'));
      }
    });
  }

  removeAnnounce() {
    this.liveAnnouncer.announce('', 'assertive');
  }

  /**
   * Unsubscribe the upDateButtonViewSubscriptions Destory
   */
  ngOnDestroy(): void {
    this.upDateButtonViewSubscriptions && this.upDateButtonViewSubscriptions.unsubscribe();
  }
  /**
   * Opens a share dialog to share the book details.
   */
  openShareDialog() {
    this.dialog
      .open(TitleShareComponent, {
        panelClass: 'kz-share-dialog',
        autoFocus: true
      })
      .afterClosed()
      .subscribe((response: string) => {
        this.shareTitleSocialMedia(response);
      });
  }

  /**
   *  Track by Title details Actions in href values
   * @param action  Selected action to pass
   * @param value   set the value in pass the action
   */
  trackByTitleDetailsActions(action: keyof typeof TITLE_DETAILS_EVENTS_KEY) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action);
  }

  /**
   * ActionHandler in title details
   * @param [data] pass the actions information in title details
   */
  actionHandler(data: any, eventId?: { btn: string, btnId: string, errorId: string }) {
    this.spinnerService.withObservable(this.engageService.bookActionClick(data), undefined, false).subscribe(
      async (response: ActionClickResponse) => {
        this.getMyStuffData();
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        if (response.secondaryMessage === 'Already added by another profile.') {
          this.openAddWishListCTAModel(response.primaryStatusMessage, data);
          return;
        }
        if (data?.actiontype === 'checkout' || data?.actiontype === 'returntitle') {
          this.notificationsService.updateMessagesCount();
        }
        this.titleDetailsService.thirdPartyToast?.next(this.titleDetailsService.statusUserAgentCheck);
        this.titleDetailsService.titleDetailsStautsCheck = (data?.actiontype === 'suspendhold');
        const checkReachedOutItems = response.primaryStatusMessage.toLocaleLowerCase().includes('checkout limit');
        const suspendHoldStatus = (data?.actiontype === 'suspendhold') ? 'Your hold for Explore Winter is now suspended' : '';
        checkReachedOutItems && (this.titleDetailsService.titleDetailsStautsCheck = checkReachedOutItems);
        this.titleDetailsService.titleDetailsStautsContent = checkReachedOutItems ? 'Checkout limit reached.' : suspendHoldStatus
        this.toastService.showSnackBar(
          response.status ? SNAKE_BAR_MODE.INFO : SNAKE_BAR_MODE.ERROR,
          this.getActionHandlerMessage(response)
        );
        if (navigator.userAgent.match(/(Mac)/i)) {
          await this.liveAnnouncer.announce(
            document.getElementById('toast-heading')?.innerHTML + ' ' + document.getElementById('toast-description')?.innerHTML,
            'assertive'
          );
        }
        setTimeout(() => {
          cdkVisualHidden();
        }, 2000);
        this.fromLogin && this.accountService.isActionCompleted.next(true);
        this.fromLogin = false;
        var eventIdAction = eventId;
        if (response.status && strToBool(this.currentProfile.emailNotificationAlert)) {
          eventIdAction = {btn:'', btnId:'',errorId:''};
          this.showEmailNotificationAlert(data, eventId);
        }
        this.isLoading = true;
        this.actiontype = data?.actiontype;
        if (!response.status && eventIdAction) {
          eventIdAction.btnId = eventIdAction?.errorId;
        };
        this.getButtonViews(eventIdAction);
      },
      () => {
        this.isLoading = false;
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
      }
    );
  }

  /**
   * Gets the list of button views for this title.
   */
  async getButtonViews(eventId?) {
    const buttonViewData = await this.titleDetailsService.getButtonViews(this.bookDetail.itemId, this.bookDetail.isbn);
    if (buttonViewData && this.titleStateInfo.actions) {
      this.isLoading = false;
      this.titleButtonViews = deepClone(buttonViewData);
      this.titleStateInfo = buttonViewData;
      this.titleStateInfo.actions.filter(element => !element.actionButtonId.includes('btnAddWishlist') && !element.actionButtonId.includes("btnRemoveWishlist"));
      const unknownBtuView = this.titleStateInfo.actions.filter(x => x.actionButtonId === "btnAccessinApp").length > 0;
      const btnFocusId = unknownBtuView ? { btn: 'moreOption', btnId: 'loc_Access_On_App' } : eventId;
      this.titleDetailsService.setUpdateButtonView(this.titleStateInfo);
      /**
       * setTimeout(() => {
       * const { elmId, childCount } = this.isMobile() ? { elmId: AFTER_ACTION_CTA_FOCUS_ELEMENT_ID.MOBILE, childCount: 0 } : { elmId: AFTER_ACTION_CTA_FOCUS_ELEMENT_ID.DESKTOP, childCount: 1 };
       * const elm = document.querySelector(`#${elmId}`) || document.querySelectorAll('.btn-group.checkoutbtn-group')[childCount]?.children[0];
       * (elm as HTMLElement)?.setAttribute('tabindex', '0');
       * (elm as HTMLElement)?.focus();
       * }, 2000);*/
      const skipLinkContent = document?.getElementById('loc_btnSkiptoMain');
      skipLinkContent?.removeAttribute('aria-hidden');
      this.secondaryOptionsCTA(btnFocusId);
    } else {
      this.isLoading = false;
    }
  }

  isMobile() {
    return window.innerWidth < 610;
  }

  /**
   * Tracks the ACTIONS_WISHLIST event.
   * And
   * Action by TitleActionHandlerWrapper
   * @param [event] get click event
   * @param [addWishlist] ACTION_BUTTON_DATA is only wishlist
   * @param [action] get button action for getButtonViews
   */
  addToWishlist(event: any, addWishlist: string, action: Actions) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent('ACTIONS_WISHLIST');
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent('TITLE_PRIMARY_ACTIONS');
    this.titleActionHandlerWrapper(event, addWishlist, action, this.titleDetailsData);
  }

  /**
   * Switch type base on social media options by clicking Share icon
   * @param [type] Pass by facebook,twitter,email,copyLink
   */
  shareTitleSocialMedia(type: string, ) {
    let searchParams = new URLSearchParams();
    const shareUrl = SHARE_BASE_URL.replace('<originUrl>', `${window.origin}`)
      .replace('<itemId>', `${this.bookDetail.itemId}`)
      .replace('<isbn>', `${this.bookDetail.isbn}`);
    const { FACEBOOK, TWITTER, EMAIL, COPY_LINK, INSTAGRAM } = SOCIAL_MEDIA_LIST;
    switch (type) {
      case FACEBOOK:
        searchParams.set('u', shareUrl);
        this.navUrl = FACEBOOK_SHARE_LINK.replace('<params>', searchParams.toString());
        window.open(this.navUrl, '_blank');
        break;
      case TWITTER:
        searchParams.set('url', shareUrl);
        this.navUrl = TWITTER_SHARE_LINK.replace('<params>', searchParams.toString());
        window.open(this.navUrl, '_blank');
        break;
      case EMAIL:
        this.openShareEmailTemplate(shareUrl);
        break;
      case INSTAGRAM:
        this.clipboard.copy(shareUrl);
        this.shareOnInstagram(shareUrl);
        break;
      case COPY_LINK:
        this.clipboard.copy(shareUrl);
        this.toastService.showSnackBar(SNAKE_BAR_MODE.INFO, 'Link has been copied to clipboard');
        break;
      default:
        break;
    }
  }

  openShareEmailTemplate(urlLink) {
    this.matDialog
      .open(EmailShareDialogComponent, {
        panelClass: 'email-modal-container',
        autoFocus: true,
        ariaLabelledBy: 'email-title',
        data: {
          titleName: this.bookDetail.title,
          titleImage: this.configService.appSettings?.titleImageSrcFormat?.replace('{0}', this.bookDetail.isbn),
          author: this.bookDetail.authors?.join('; '),
          publisher: this.bookDetail.publisher,
          narrator: this.bookDetail.Narrator?.join('; '),
          series: this.bookDetail?.series,
          format: this.bookDetail?.format || '',
          ccMailId: this.profileService?.currentProfile?.profileEmail || '',
          shareUrl: urlLink,
          brandLogoImage: `${window.origin}/angular-app/assets/images/boundless_logo-primary.png`
        }
      })
      .afterClosed()
      .subscribe((response: any) => {
        if (response) {
          this.toastService.showSnackBar(response.mode, response.message);
          this.liveAnnouncer.announce(
            document.getElementById('toast-heading')?.innerHTML + ' ' + document.getElementById('toast-description')?.innerHTML,
            'assertive'
          );
          return;
        }
        setTimeout(() => {
          const shareButton = document.getElementById('share_btn');
          shareButton?.focus();
        }, 1000);
      });
  }

  shareOnInstagram(link) {
    const productLink = link;
    const instagramLink = "https://www.instagram.com/direct/inbox/";

    // You can customize the message as needed
    const message = encodeURIComponent("Check out this product on Amazon: " + productLink);

    // Combine the Instagram link with the product message
    const finalLink = instagramLink + "?message=" + message;

    // Open a new window or redirect the user to the Instagram link
    window.open(finalLink, "_blank");
  }

  /**
   * Removes the kz - mat - menu - overlay class from the container
   * @param [state]
   * @returns string
   */

  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      !this.isMobile && document.getElementsByTagName('html')[0].setAttribute('class', 'cdk-global-scrollblock');
      return;
    }
    setTimeout(() => {
      document.getElementById('loc_more_option').setAttribute('aria-expanded', 'false');
    }, 1000);
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    !this.isMobile && document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
  }
}
