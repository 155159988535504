export const NOTIFICATIONS_CATEGORY = 'Notifications';
export enum NOTIFICATIONS_SCREEN_KEY {
  NOTIFICATIONS = 'NOTIFICATIONS',
  MORE_MENU = 'MORE_MENU',
  DELETE = 'DELETE'
}
export enum NOTIFICATIONS_EVENT_KEY {
  ICON = 'ICON',
  CLOSE = 'CLOSE',
  SELECT_MSG = 'SELECT_MSG',
  UNSELECT_MSG = 'UNSELECT_MSG',
  DELETE = 'DELETE',
  SELECT_ALL = 'SELECT_ALL',
  SELECT_NONE = 'SELECT_NONE',
  SELECT_READ = 'SELECT_READ',
  SELECT_UNREAD = 'SELECT_UNREAD',
  MARK_AS_READ = 'MARK_AS_READ',
  MARK_AS_UNREAD = 'MARK_AS_UNREAD',
  CANCEL = 'CANCEL',
  EXPAND_MSG = 'EXPAND_MSG',
  COLLAPSE_MSG = 'COLLAPSE_MSG',
  GO_TO_PROGRAM = 'GO_TO_PROGRAM',
  CANCEL_MENU = 'CANCEL_MENU'
}
export const NOTIFICATIONS_EVENTS = {
  [NOTIFICATIONS_EVENT_KEY.ICON]: 'Notifications Icon',
  [NOTIFICATIONS_EVENT_KEY.SELECT_MSG]: 'Select',
  [NOTIFICATIONS_EVENT_KEY.UNSELECT_MSG]: 'Unselect',
  [NOTIFICATIONS_EVENT_KEY.DELETE]: 'Delete',
  [NOTIFICATIONS_EVENT_KEY.CLOSE]: 'Close',
  [NOTIFICATIONS_EVENT_KEY.SELECT_ALL]: 'Select All',
  [NOTIFICATIONS_EVENT_KEY.SELECT_NONE]: 'Unselect All',
  [NOTIFICATIONS_EVENT_KEY.SELECT_READ]: 'Select Read',
  [NOTIFICATIONS_EVENT_KEY.SELECT_UNREAD]: 'Select Unread',
  [NOTIFICATIONS_EVENT_KEY.MARK_AS_READ]: 'Mark as Read',
  [NOTIFICATIONS_EVENT_KEY.MARK_AS_UNREAD]: 'Mark as Unread',
  [NOTIFICATIONS_EVENT_KEY.CANCEL]: 'Cancel',
  [NOTIFICATIONS_EVENT_KEY.EXPAND_MSG]: 'Expand Notification',
  [NOTIFICATIONS_EVENT_KEY.COLLAPSE_MSG]: 'Collapse Notification',
  [NOTIFICATIONS_EVENT_KEY.GO_TO_PROGRAM]: 'See Details',
  [NOTIFICATIONS_EVENT_KEY.CANCEL_MENU]: 'Notifications - Cancel Menu'
};
export const NOTIFICATIONS_SCREENS = {
  [NOTIFICATIONS_SCREEN_KEY.NOTIFICATIONS]: 'Notifications',
  [NOTIFICATIONS_SCREEN_KEY.MORE_MENU]: 'Mark menu',
  [NOTIFICATIONS_SCREEN_KEY.DELETE]: 'Delete menu'
};
