<nav class="breadcrumb-sec" aria-label="Breadcrumb">
    <ul class="breadcrumb {{breadcrumbsClass}}">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let lastIndex = last; let i = index">
            <ng-container *ngIf="!lastIndex">
                <li class="breadcrumb-item" *ngIf="breadcrumb.isHref"> <a [attr.aria-current]="lastIndex? 'page' : null"
                        [href]="breadcrumb.url" id="breadcrumb-link-{{i}}" [innerHTML]="breadcrumb.text"></a> </li>
                <li class="breadcrumb-item" *ngIf="!breadcrumb.isHref"> <a
                        [attr.aria-current]="lastIndex? 'page' : null"
                        (click)="breadcrumb.handleClick && clickOnBreadcrumb(breadcrumb.text)"
                        [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.queryParams"
                        id="breadcrumb-link-{{i}}" [innerHTML]="breadcrumb.text"></a> </li>
            </ng-container>
            <ng-container *ngIf="lastIndex">
                <li class="breadcrumb-item current">
                    <a href="javascript:;" aria-hidden="false" aria-current="page" [innerHTML]="breadcrumb.text" tabindex="-1"></a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</nav>