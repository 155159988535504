<ng-container *ngIf="!isLoggedIn && !showRegisterPrompt">
    <div class="login-container-sec kz-main">
        <h2 class="heading-1">Register</h2>
        <form [formGroup]="passwordVerificationForm" (ngSubmit)="onSubmit()">
            <div class="kz-login-form">
                <mat-form-field appearance="fill"
                    [ngClass]="{'mb-0': !passwordVerificationForm.controls['RegistrationPassword'].errors?.required && enterValidPassword}">
                    <mat-label>{{accountService.loginInfo.passwordAuthenticationPrompt}}</mat-label>
                    <input matInput formControlName="RegistrationPassword"
                        maxlength="51" type="password" matInput
                        placeholder axis360TrapFocus id="RegistrationPassword">
                    <mat-error
                        *ngIf="!passwordVerificationForm.controls['RegistrationPassword'].errors?.required && passwordVerificationForm.controls['RegistrationPassword'].errors?.maxlength">Registration
                        Password can not exceed 50 characters.</mat-error>
                    <mat-error
                        *ngIf="passwordVerificationForm.controls['RegistrationPassword'].errors?.required">Registration
                        Password is required</mat-error>
                </mat-form-field>
                <mat-error
                    *ngIf="!passwordVerificationForm.controls['RegistrationPassword'].errors?.required && enterValidPassword">
                    The credentials you entered are not recognized. Please try again.</mat-error>
            </div>
            <div class="done_Submit d-flex mt-1">
                <button mat-raised-button
                    class="btn-primary-blue text-capitalize saveBtn"
                    [ngClass]="{active: passwordVerificationForm && !passwordVerificationForm.invalid}"
                    [disabled]="passwordVerificationForm.invalid || !passwordVerificationForm">Register</button>
                <a class="secondary-btn forgot-btn" href="javascript:void(0)"
                    role="link"
                    (click)="isLoggedIn = true">{{accountService.loginInfo.showLoginPrompt}}
                    Click Here</a>
            </div>
        </form>
    </div>
</ng-container>
<axis360-login *ngIf="isLoggedIn"></axis360-login>
<axis360-registeration [noModal]="noModal" *ngIf="showRegisterPrompt" [registerResponse]="registerResponse"></axis360-registeration>
