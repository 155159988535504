import { Component, Input, OnInit } from '@angular/core';
import { SHIMMER_COMPONENTS, LIST_CARD_COUNT } from '@shared/constants/app.constants';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { ILearningActivityData } from '@titledetails/shared/data/title-details.model';

@Component({
  selector: 'axis360-kz-learning-activity-new-carousel',
  templateUrl: './kz-learning-activity-new-carousel.component.html',
  styleUrls: ['./kz-learning-activity-new-carousel.component.scss']
})
export class KzLearningActivityNewCarouselComponent implements OnInit {
  @Input() learningActivityBooks: ILearningActivityData;
  @Input() parentRoute = 'learning-activity';
  cellsToScrollNums = [4, 3, 2, 1];
  cellWidth = 354;
  cellMargin = 0;
  cellHeight = 196;
  cellsToScroll = 5;
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  LIST_CARD_COUNT = LIST_CARD_COUNT;
  onResize = setIvyCarouselProps.bind(this, 354, 0, 196, 354, 0, 196, this.cellsToScrollNums);
  constructor(public sharedAnalyticsService: SharedAnalyticsService) {}

  ngOnInit(): void {
    this.onResize();
  }

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.sharedAnalyticsService.trackNewspaperCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.sharedAnalyticsService.trackNewspaperCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
    }
  }
}
