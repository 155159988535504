import { Component, Input } from '@angular/core';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';

@Component({
  selector: 'axis360-kz-learning-activities-carousel',
  templateUrl: './kz-learning-activities-carousel.component.html',
  styleUrls: ['./kz-learning-activities-carousel.component.scss']
})
export class KzLearningActivitiesCarouselComponent {
  list = [1, 2, 3, 4, 5, 6];
  cellWidth = 354;
  cellMargin = 0;
  cellHeight = 196;
  cellsToScroll = 2;
  @Input() cellsToScrollNums = [2, 2, 1, 1];
  @Input() learningActivityInfo: [];
  @Input() parentRoute: string
  onResize = setIvyCarouselProps.bind(this, 354, 0, 196, 354, 0, 196, this.cellsToScrollNums);
  constructor(private sharedAnalyticsService: SharedAnalyticsService) {}

  /**
   * Tracks carousel
   */
  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.sharedAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling && target.previousSibling.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.sharedAnalyticsService.trackCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling && target.nextSibling.classList.remove('carousel-arrow-disabled');
    }
  }
}
