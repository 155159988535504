export const HOME_CATEGORY = 'User Landing';
export const INTEREST_SURVEY_CATEGORY = 'Interest Survey';
export const HOME_SCREENS_KEY = {
  SET_GOAL_POPUP: 'SET_GOAL_POPUP',
  INTEREST_SURVEY: 'INTEREST_SURVEY',
  INTEREST_SURVEY_PAGE: 'INTEREST_SURVEY_PAGE',
  UPDATE_GOAL_POPUP: 'UPDATE_GOAL_POPUP',
  MY_LIBRARY_PAGE: 'MY_LIBRARY_PAGE'
};

export const HOME_SCREENS: { [key: string]: string } = {
  [HOME_SCREENS_KEY.SET_GOAL_POPUP]: 'Myshelfpage-Set goal',
  [HOME_SCREENS_KEY.INTEREST_SURVEY]: 'Set Reading Preferences',
  [HOME_SCREENS_KEY.INTEREST_SURVEY_PAGE]: 'Interest Survey',
  [HOME_SCREENS_KEY.UPDATE_GOAL_POPUP]: 'Myshelfpage-update goal',
  [HOME_SCREENS_KEY.MY_LIBRARY_PAGE]: 'My library page',
};

export const HOME_EVENTS_KEY = {
  VIEW_PROGRAM_DETAILS: 'VIEW_PROGRAM_DETAILS',
  SEE_ALL_PROGRAMS: 'SEE_ALL_PROGRAMS',
  SET_GOAL: 'SET_GOAL',
  CLOSE_SET_GOAL: 'CLOSE_SET_GOAL',
  REMOVE_GOAL: 'REMOVE_GOAL',
  CLOSE_POP_UP: 'CLOSE_POP_UP',
  GOAL_DAILY_READ: 'GOAL_DAILY_READ',
  GOAL_DAILY_LISTENED: 'GOAL_DAILY_LISTENED',
  GOAL_CURRENT_STREAK: 'GOAL_CURRENT_STREAK',
  GOAL_MONTHLY_READ: 'GOAL_MONTHLY_READ',
  GOAL_YEARLY_READ: 'GOAL_YEARLY_READ',
  GOAL_MONTHLY_LISTENED: 'GOAL_MONTHLY_LISTENED',
  GOAL_YEARLY_LISTENED: 'GOAL_YEARLY_LISTENED',
  INSIGHTS_DAILY_READ: 'INSIGHTS_DAILY_READ',
  INSIGHTS_DAILY_LISTENED: 'INSIGHTS_DAILY_LISTENED',
  INSIGHTS_CURRENT_STREAK: 'INSIGHTS_CURRENT_STREAK',
  INSIGHTS_MONTHLY_READ: 'INSIGHTS_MONTHLY_READ',
  INSIGHTS_YEARLY_READ: 'INSIGHTS_YEARLY_READ',
  INSIGHTS_MONTHLY_LISTENED: 'INSIGHTS_MONTHLY_LISTENED',
  INSIGHTS_YEARLY_LISTENED: 'INSIGHTS_YEARLY_LISTENED',
  INTEREST_SELECTED: 'INTEREST_SELECTED',
  INTEREST_UNSELECTED: 'INTEREST_UNSELECTED',
  AUDIENCE_SELECTED: 'AUDIENCE_SELECTED',
  AUDIENCE_UNSELECTED: 'AUDIENCE_UNSELECTED',
  INTEREST_SAVE: 'INTEREST_SAVE',
  KZ_INTEREST_SAVE: 'KZ_INTEREST_SAVE',
  INTEREST_NO_THANKS: 'INTEREST_NO_THANKS',
  INTEREST_CLOSE: 'INTEREST_CLOSE',
  CAROUSEL_SEE_ALL: 'CAROUSEL_SEE_ALL',
  AVAILABILITY_FILTER: 'AVAILABILITY_FILTER',
  FORMAT_FILTER: 'FORMAT_FILTER',
  INTEREST_CANCEL: 'INTEREST_CANCEL',
  ENTERS_YOUR_TARGET_GOAL: 'ENTERS_YOUR_TARGET_GOAL'
};
export const HOME_EVENTS: { [key: string]: string } = {
  [HOME_EVENTS_KEY.VIEW_PROGRAM_DETAILS]: 'View Program Details',
  [HOME_EVENTS_KEY.SEE_ALL_PROGRAMS]: 'See All Programs',
  [HOME_EVENTS_KEY.SET_GOAL]: 'View setgoal CTA',
  [HOME_EVENTS_KEY.REMOVE_GOAL]: 'View Remove goal CTA',
  [HOME_EVENTS_KEY.CLOSE_POP_UP]: 'Close pop-up',
  [HOME_EVENTS_KEY.GOAL_DAILY_READ]: 'Goal - Daily minutes read​',
  [HOME_EVENTS_KEY.GOAL_DAILY_LISTENED]: 'Goal - Daily minutes listened',
  [HOME_EVENTS_KEY.GOAL_CURRENT_STREAK]: 'Goal - Current Streak',
  [HOME_EVENTS_KEY.GOAL_MONTHLY_READ]: 'Goal - Monthly books read',
  [HOME_EVENTS_KEY.GOAL_YEARLY_READ]: 'Goal - Yearly books read',
  [HOME_EVENTS_KEY.GOAL_MONTHLY_LISTENED]: 'Goal - Monthly books listened',
  [HOME_EVENTS_KEY.GOAL_YEARLY_LISTENED]: 'Goal - Yearly books listened',
  [HOME_EVENTS_KEY.INSIGHTS_DAILY_READ]: 'Insights – Average read minutes',
  [HOME_EVENTS_KEY.INSIGHTS_DAILY_LISTENED]: 'Insights – Average listened minutes',
  [HOME_EVENTS_KEY.INSIGHTS_CURRENT_STREAK]: 'Insights – Current Streak',
  [HOME_EVENTS_KEY.INSIGHTS_MONTHLY_READ]: 'Insights – Books read this month',
  [HOME_EVENTS_KEY.INSIGHTS_YEARLY_READ]: 'Insights – Books read this year',
  [HOME_EVENTS_KEY.INSIGHTS_MONTHLY_LISTENED]: 'Insights – Books listened this month',
  [HOME_EVENTS_KEY.INSIGHTS_YEARLY_LISTENED]: 'Insights – Books listened this year',
  [HOME_EVENTS_KEY.INTEREST_SELECTED]: '<interestName> selected',
  [HOME_EVENTS_KEY.INTEREST_UNSELECTED]: '<interestName> unselected',
  [HOME_EVENTS_KEY.AUDIENCE_SELECTED]: '<audienceLevel> selected',
  [HOME_EVENTS_KEY.AUDIENCE_UNSELECTED]: '<audienceLevel> unselected',
  [HOME_EVENTS_KEY.INTEREST_SAVE]: 'Save preferences',
  [HOME_EVENTS_KEY.KZ_INTEREST_SAVE]: 'Save interest',
  [HOME_EVENTS_KEY.INTEREST_NO_THANKS]: 'No, Thanks',
  [HOME_EVENTS_KEY.INTEREST_CLOSE]: 'Close - Interest Popup',
  [HOME_EVENTS_KEY.CAROUSEL_SEE_ALL]: 'See All Home Carousel',
  [HOME_EVENTS_KEY.AVAILABILITY_FILTER]: 'Availability Filter',
  [HOME_EVENTS_KEY.FORMAT_FILTER]: 'Format Filter',
  [HOME_EVENTS_KEY.INTEREST_CANCEL]:'Cancel Interest',
  [HOME_EVENTS_KEY.ENTERS_YOUR_TARGET_GOAL]: 'Enter your target goal'
};
