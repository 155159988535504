import { Component } from '@angular/core';
import { PressReaderCalenderHeaderComponent } from '@pressreader/shared/components/press-reader-calender-header/press-reader-calender-header.component';
@Component({
  selector: 'axis360-third-party-calender-header',
  templateUrl: './third-party-calender-header.component.html',
  styleUrls: ['./third-party-calender-header.component.scss']
})
export class ThirdPartyCalenderHeaderComponent extends PressReaderCalenderHeaderComponent<any> {

}

