<div class="kz-alert-dialog" [ngClass]="{'kz-info-dialog' :modaldata.type === 'INFO'}">
  <span class="kz-visually-hidden" *ngIf='isDialogHeadingLabel()'></span>
  <span class="kz-visually-hidden" id="{{dialogHeadingId}}">{{dialogHeadingLabel}}</span>
  <mat-icon class="close-icon" tabindex="0" id="loc_btncloseicon" #closeButton cdkFocusInitial aria-label="Close"
    role="button" aria-hidden="false" *ngIf="modaldata.closeIcon || modaldata.heading === 'Add a Title'"
    (click)="cancelClick()" (keyup.enter)="cancelClick()" aria-hidden="true" svgIcon="close"></mat-icon>
   <h2 mat-dialog-title id="alert-heading" *ngIf="modaldata.heading"
    [ngClass]="{'heading-success': modaldata.type === 'SUCCESS', 'heading-error': modaldata.type === 'ERROR', 'heading-bold heading-title': modaldata.type === 'INFO', 'heading-bold': modaldata.type === 'CONFIRMATION'}">
    {{modaldata.heading}}</h2>
  <div mat-dialog-content id="alert-heading-desc"  [innerHTML]="modaldata.content"></div>
  <div class="learn-more-profile" *ngIf="modaldata.link">
  </div>
  <div mat-dialog-actions *ngIf="!modaldata.closeIcon">
    <button mat-button mat-dialog-close class="cancelBtn" (click)="cancelClick();$event.stopPropagation();" id="loc_cancelbtn"
    *ngIf="modaldata.cancelBtnText">{{modaldata.cancelBtnText}}</button>
    <button mat-button class="okBtn" cdkFocusInitial (click)="submitClick();$event.stopPropagation();"
      id="loc_confirmbtnOK" *ngIf="modaldata.submitBtnText">{{modaldata.submitBtnText}}</button>
    <button mat-button class="searchBtn mb-2 " id="loc_SearchYourLibrary" (click)="searchBtnClick();$event.stopPropagation();"
      (keyup.enter)="searchBtnClick();$event.stopPropagation();" *ngIf="modaldata.searchBtnText">{{modaldata.searchBtnText}}</button>
    <button mat-button class="searchOwnBtn" id="Loc_AddyourOwnTitle" (click)="searchOwnBtnClick();$event.stopPropagation();"
      (keyup.enter)="searchOwnBtnClick();$event.stopPropagation();" *ngIf="modaldata.searchOwnBtnText">{{modaldata.searchOwnBtnText}}</button>
  </div>
  <div mat-dialog-actions *ngIf="modaldata.type === 'INFO'">
    <button mat-button class="holdBtn"
      (keyup.enter)="modaldata.buttonText && modaldata.buttonText !== 'Ok' ? submitClick() : cancelClick()"
      (click)="modaldata.buttonText && modaldata.buttonText !== 'Ok' ? submitClick() : cancelClick()">{{modaldata.buttonText
      !== 'Ok' ? modaldata.buttonText:'Ok'}}</button>
  </div>
</div>
