import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[axis360IvyCarousel]'
})
export class IvyCarouselDirective implements AfterViewInit {
  @Input() roleWidgets = 'list';
  constructor(public elem: ElementRef) { }
  /**
   * To set ADA tags after view init
   */
  ngAfterViewInit() {
    this.updateAdaTags();
    this.removeADAClickable();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateAdaTags();
    // this.elem.nativeElement.querySelector('.carousel-cells').style.transform = 'translateX(0px)';
    this.elem.nativeElement.querySelector('.carousel-arrow-prev').classList.add('carousel-arrow-disabled');
  }

  updateAdaTags() {
    this.setRibBtnsAriaLabel('.carousel-arrow-next', 'Next');
    this.setRibBtnsAriaLabel('.carousel-arrow-prev', 'Previous');
  }
  /**
   * Sets ribbon buttons aria label
   * @param clsName class name
   * @param lbl label
   */
  setRibBtnsAriaLabel(clsName: string, lbl: string) {
    const ribbonBtns = this.elem.nativeElement.querySelectorAll(clsName);
    if (ribbonBtns.length === 0) {
      return;
    }
    for (const key in ribbonBtns) {
      if (ribbonBtns[key] && typeof ribbonBtns[key] === 'object') {
        ribbonBtns[key].setAttribute('aria-label', lbl);
        ribbonBtns[key].setAttribute('tabindex', -1);
        ribbonBtns[key].setAttribute('aria-hidden', true);
        ribbonBtns[key].setAttribute('role', 'button');
      }
    }
  }

  /**
   * Calulate tab index for differnt scenario
   * @returns returns -1 or 0
   */
  calulateTabIndex() {
    if (this.elem.nativeElement.classList.contains('carousel-arrow-disabled')) {
      return -1;
    }
    return 0;
  }
  /**
   * Carousel has been updated to make the first and last card as clickable.
   */
  removeADAClickable() {
    this.elem.nativeElement.querySelector('.carousel-cells').setAttribute('role', this.roleWidgets);
    this.elem.nativeElement.setAttribute('role', 'none');
  }
}
