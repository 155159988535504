import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { HOME, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { KzProgramsService } from '../kz-programs/shared/service/kz-programs.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { cdkRemoveVisualHidden, cdkVisualHidden, deepClone, displayErrorMessage, focusElement, randomNumderGenerator } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { Book } from '@shared/data/engage-base.model';

@Component({
  selector: 'axis360-kz-program-view-all',
  templateUrl: './kz-program-view-all.component.html',
  styleUrls: ['./kz-program-view-all.component.scss']
})
export class KzProgramViewAllComponent implements OnInit, OnDestroy {
  @Input() programDetails: ProgramDetails;
  @Output() updateSeeAll = new EventEmitter();
  showSaveCancelBtn = false;
  isDeleteFlag = false;
  editTitleLable = 'Edit Program';
  deleteList = [];
  programId: string;
  isSortActive: string;
  removeEditProgramNotification = true;
  filterOption = { type: 'All', code: 'ALL' }
  filters = [
    { type: 'All', code: 'ALL' },
    { type: 'eBook', code: 'EBT' },
    { type: 'eAudio', code: 'ABT' },
    { type: 'Added By User', code: 'USER' }
  ];
  sortOptions = [
    { type: 'Titles A-Z', code: 'A-Z' },
    { type: 'Titles Z-A', code: 'Z-A' }
  ];
  tempProgramDetails: ProgramDetails;
  randomNumderGenerator = randomNumderGenerator;
  focusElement = focusElement;
  breadcrumbs: { text: string; url: string; handleClick?: boolean; isHref?: boolean; queryParams?: any }[];
  EnableProgramList = [];
  filteredBooks: Book[];
  constructor(
    public router: Router,
    public overlayContainer: OverlayContainer,
    public kzProgramsService: KzProgramsService,
    public spinnerService: SpinnerService,
    public toastMessage: SnackBarService,
    public programAnalyticsService: ProgramsAnalyticsService,
    public commonService: CommonService
  ) { }

  /**
   * on init
   */

  ngOnInit() {
    this.tempProgramDetails = deepClone(this.programDetails);
    this.filteredBooks = [...this.programDetails.books];
    this.programId = this.programDetails?.id;
    this.kzProgramsService.removeEditProgramNotification.subscribe(programNotify => {
      if (!programNotify) {
        this.removeEditProgramNotification = false;
      }
    });
    this.programAnalyticsService.trackScreen('READ_LIST_SCREEN');
    this.kzProgramsService.isDelete.subscribe((bookDetails: any) => {
      this.EnableProgramList = this.programDetails.books.filter((a) => a.info?.ischecked === true);
      if (bookDetails?.ischecked) {
        const removeIndex = this.programDetails.books.findIndex((o) => o.bookId === bookDetails.itemId);
        this.deleteList.push(this.programDetails.books[removeIndex]);
      }
    });
    const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'My Library';
    const refRandomNumber = this.randomNumderGenerator();
    this.breadcrumbs = [
      { text: homePage, url: HOME[homePage], isHref: true },
      {
        text: this.programDetails.isParticipant === false ? 'Open Programs' : 'Programs',
        url: this.programDetails.isParticipant === false ? '/view/programs/open' : '/view/programs'
      },
      { text: this.programDetails?.name, url: `/view/programs/${this.programDetails?.id}`, handleClick: true, queryParams: { ref: refRandomNumber } },
      { text: 'Reading List', url: './' }
    ];
    // Call cdkVisualHidden after 4 seconds
    cdkRemoveVisualHidden();

  }
  ngAfterViewInit(): void {
   setTimeout(() => {
    cdkVisualHidden();
    this.commonService.toStopAnnoucedPage(false, false, true);
   }, 2000);
  }
  ngOnDestroy(): void {
    cdkRemoveVisualHidden();
  }
  /**
   * Cancels kz program view all component
   */
  cancel() {
    this.programAnalyticsService.trackNotificationsEvent('CANCEL');
    this.isDeleteFlag = this.showSaveCancelBtn = !this.showSaveCancelBtn;
  }
  /**
   * To be called on open dialog box yes or no
   */
  removeTitleDialog() {
    this.programAnalyticsService.trackNotificationsEvent('REMOVE');
    this.commonService.showAlertDialog({
      content: `Are you sure you want to delete the ${this.EnableProgramList.length === 1 ? 'Title?' : 'Titles?'}`,
      submitBtnText: 'Ok',
      cancelBtnText: 'Cancel',
      ariaDescribedBy: 'alert-heading-desc',
      submitBtnFunc: () => this.save(),
      cancelBtnFunc: () => this.programAnalyticsService.trackNotificationsEvent('CANCEL')
    });
  }
  /**
   * Saves kz program view all component
   */
  save() {
    const deleteProgramList = this.programDetails.books.filter((a) => a.info?.ischecked === true);
    this.removeEditProgramNotification = false;
    this.programAnalyticsService.trackNotificationsEvent('OK');
    if (deleteProgramList?.length) {
      const saveObj = {
        milestoneBooks: {
          externalBooks: [],
          libraryBooks: []
        }
      };
      deleteProgramList.forEach((element) => {
        if (element.isManual) {
          saveObj.milestoneBooks.externalBooks.push({
            bookId: element.bookId
          });
          return;
        }
        saveObj.milestoneBooks.libraryBooks.push({
          bookId: element.bookId
        });
      });
      this.spinnerService.withObservable(this.kzProgramsService.deleteMilestoneBook(this.programId, saveObj)).subscribe(
        (result) => {
          if (result.responseCode === 200) {
            this.deleteList = [];
            this.kzProgramsService.tempSequenceId = this.kzProgramsService.tempSequenceId - this.EnableProgramList.length;
            this.isDeleteFlag = this.showSaveCancelBtn = false;
            this.toastMessage.showSnackBar(SNAKE_BAR_MODE.INFO, result.message);
            this.filteredBooks = this.programDetails.books = this.programDetails.books.filter((a) => !a.info?.ischecked);
            this.filterOption = { type: 'All', code: 'ALL' }
            this.filterChange(this.filterOption);
            this.focusElement('loc_headerId');
            return;
          }
          this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, displayErrorMessage(result));
        },
        () => {
          this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, 'Something went wrong with your submission. Please try again.');
        }
      );
    }
  }
  /**
   * Filters change
   * @param event
   */
  filterChange(event) {
    this.programAnalyticsService.trackNotificationsEvent('FILTER', { filterName: event.type });
    this.filterOption = event;
    if (event.code === 'ALL') {
      this.filteredBooks = [...this.programDetails.books];
    } else if (event.code === 'USER') {
      this.filteredBooks = this.programDetails.books.filter((x) => x.isManual);
    } else {
      this.filteredBooks = this.programDetails.books.filter((x) => {
        return x.bookType?.includes(event.type) || x.info?.formatType === event.code || x.info?.addBtn;
      });
    }
  }
  /**
   * Sorts change
   */
  sortChange(event) {
    this.programAnalyticsService.trackNotificationsEvent('SORT', { sortName: event.type });
    this.isSortActive = event.code;
    if (event.code === 'A-Z') {
      this.filteredBooks = this.filteredBooks.sort((a, b) =>
        a.info?.title > b.info?.title ? 1 : b.info?.title > a.info?.title ? -1 : 0
      );
    } else {
      this.filteredBooks = this.filteredBooks.sort((a, b) =>
        a.info?.title < b.info?.title ? 1 : b.info?.title < a.info?.title ? -1 : 0
      );
      this.filteredBooks.unshift(this.filteredBooks.pop());
    }
  }
  /**
   * Marks as read click
   */

  markAsReadClick() {
    this.router.navigate(['/view/programs/' + this.programDetails.id]);
  }
  onBreadCrumbClicked(text: string) {
    if (text === this.programDetails.name) {
      this.updateSeeAll.emit();
    }
  }
  /**
   * Menus state
   * @param state
   * @returns
   */
  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      document.getElementsByTagName('html')[0].setAttribute('class', 'cdk-global-scrollblock');
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementsByTagName('html')[0].classList.remove('cdk-global-scrollblock');
  }
  removeTooltipAnnouce() {
    document.querySelectorAll('.kz-card-tooltip').forEach((element) => {
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    });

    const formatTooltip = document.getElementById('loc_Filter');
    formatTooltip?.removeAttribute('aria-describedby');
    const sortTooltip = document.getElementById('loc_Sort');
    sortTooltip?.removeAttribute('aria-describedby');
    const editTooltip = document.getElementById('loc_editPenicon');
    editTooltip?.removeAttribute('aria-describedby');
  }
  /**
   * Edits title
   */
  editTitle() {
    this.programAnalyticsService.trackNotificationsEvent('EDIT_LIST_CTA');
    this.isDeleteFlag = this.showSaveCancelBtn = !this.showSaveCancelBtn;
    this.removeEditProgramNotification = true;
    if (navigator.userAgent.match(/(Android|iPhone|iPad|iPod|Mac)/i)) {
      this.editTitleLable = 'Edit program button disable, and checkboxes disappear';
      cdkRemoveVisualHidden();
      if (this.isDeleteFlag) {
        this.editTitleLable = 'Edit program button enable, and checkboxes appear';
      }
    }
  }
}
