import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { handleProgramDetails } from '@readingprograms/programs/shared/helper/programs.util';
import { PROGRAMS_STATUS, PROGRAMS_STATUS_TEXT, PROGRAM_TYPE } from '@readingprograms/shared/constants/programs.constants';
import { Program, ProgramAPIRes, ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { AlertDialog, AlertDialogComponent } from '@shared/components/alert-dialog/alert-dialog.component';
import { SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { displayErrorMessage, focusElement, positionFooter, redirectUrl } from '@shared/helper/app.util';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { noop } from 'rxjs';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { Book, BookDetail, MilestoneInfo } from '@shared/data/engage-base.model';
import { CommonService } from '@shared/services/common.service';
@Component({
  selector: 'axis360-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss']
})
export class ProgramDetailsComponent implements OnInit {
  programId: string;
  programDetails: ProgramDetails = {};
  siteUrl: string;
  userName: string;
  isLoading = true;
  isNewUI = false;
  suggestedBooks: BookDetail[];
  breadcrumbs: ({ text: string; url: string; isHref: boolean } | { text: string; url: string; isHref?: boolean })[];
  getResizedDescription = noop;
  programStatus = PROGRAMS_STATUS;
  programStatusText = PROGRAMS_STATUS_TEXT;
  parentRoute: string;
  redirectUrl = redirectUrl;
  focusElement = focusElement;
  filteredSuggestedBooks: BookDetail[];
  constructor(
    public programsService: ProgramsService,
    public engageService: EngageService,
    public activatedRoute: ActivatedRoute,
    public toastMessage: SnackBarService,
    public router: Router,
    public matDialog: MatDialog,
    public programsAnalyticsService: ProgramsAnalyticsService,
    public liveAnnouncer: LiveAnnouncer,
    public kzProgramsService: KzProgramsService,
    public elementRef: ElementRef,
    public commonService: CommonService
  ) { }
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(() => {
      this.activatedRoute.params.subscribe((param) => {
        this.programId = param.programId;
        this.init();
      })
      this.commonService.isAnnouncedPage = true;
      this.kzProgramsService.programId.next(this.programId);
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      focusElement('loc_headerId');
    }, 2000);
  }
  init() {
    this.isNewUI = this.router.url.includes('view');
    this.siteUrl = window.origin;
    this.kzProgramsService.updateProgramAfterAddCTA.subscribe(item => {
      if (!item) {
        return;
      }
      this.getProgram(true);
    });
    this.getProgram();
    positionFooter();
  }
  getBreadCrumbs() {
    this.breadcrumbs = [
      { text: 'Home', url: '/', isHref: true },
      { text: 'MY PROGRAMS', url: '/programs' },
      { text: this.programDetails.name, url: '' }
    ];
  }
  focusingOn() {
    setTimeout(() => {
      if (this.kzProgramsService.titleId === 'ADD_NEW_TITLE' && this.programDetails) {
        const addNewProgramId = 'title_' + (this.programDetails?.books ? this.programDetails?.books[1]?.info.itemId : '');
        this.programDetails?.books[1] && this.focusElement(addNewProgramId);
        this.kzProgramsService.titleId = '';
      } else {
        this.kzProgramsService.titleId && this.focusElement(this.kzProgramsService.titleId);
        this.kzProgramsService.titleId = '';
      }
    }, 0)
  }
  trackPage(status: string) {
    if (status === PROGRAMS_STATUS.COMPLETE ||
      status === PROGRAMS_STATUS.COMPLETED ||
      status === PROGRAMS_STATUS.COMPLETE_CLOSED ||
      status === PROGRAMS_STATUS.NOT_COMPLETE_CLOSED
    ) {
      this.programsAnalyticsService.trackScreen('CLOSED_PROGRAM_DETAILS', { programName: this.programDetails.name });
      return;
    }
    this.programsAnalyticsService.trackScreen('PROGRAM_DETAILS');
  }
  handleUserRead(participantCanReadStatus: { [bookId: string]: boolean }) {
    let programBooks = [];
    if (this.programDetails?.type === PROGRAM_TYPE.MileStone) {
      programBooks = (this.programDetails?.participants?.length > 0 && this.programDetails?.participants[0]?.milestoneBookList)
        ? this.programDetails.participants[0].milestoneBookList
        : [];
    } else {
      programBooks = this.programDetails?.books || [];
    }
    let SequenceVal = programBooks?.length ? Math.max(...programBooks.map(o => o.sequence)) : 0;
    this.kzProgramsService.tempSequenceId = SequenceVal;
    this.kzProgramsService.numberOfBooks.next(this.programDetails.numberOfBooks);
    const booksLen = programBooks.length || 0;
    for (let index = 0; index < booksLen; index++) {
      const book: Book = programBooks[index];
      const canUserRead =
        !index ||
        ((participantCanReadStatus[programBooks[0].bookId] ||
          programBooks[0].isHiddenTitle ||
          !(programBooks[0].info.isAvailable && programBooks[0].info.isRTV)) &&
          (participantCanReadStatus[book.bookId] || book.isHiddenTitle || !(book.info.isAvailable && book.info.isRTV)));
      book.isParticipantCanRead = canUserRead;
      if (!canUserRead) {
        book.isParticipantCanRead =
          index > 1 ||
          participantCanReadStatus[programBooks[0].bookId] ||
          programBooks[0].isHiddenTitle ||
          !(programBooks[0].info.isAvailable && programBooks[0].info.isRTV);
        break;
      }
    }
  }
  async getBooks(program: Program) {
    const milestoneBookList = (program.participants?.length > 0 && program?.participants[0]?.milestoneBookList) ? program?.participants[0].milestoneBookList.map((book) => book.bookId) : [];
    const programBookDetails = (program?.programType === PROGRAM_TYPE.MileStone ? milestoneBookList : program?.books.map((book) => book.bookId)) || [];
    const suggestedList = program.suggestedBooks?.length > 0 ? program.suggestedBooks.map((sbook) => sbook.suggestedBookId) : [];
    let bookIds = [];
    if (programBookDetails.length > 0) {
      bookIds = programBookDetails;
    }
    if (programBookDetails.length === 0 && program?.programType !== PROGRAM_TYPE.MileStone) {
      this.isLoading = false;
      this.focusingOn();
      return;
    }
    try {
      const suggestedbookRes = await this.engageService.getBooksByIdV1(suggestedList, true);
      this.suggestedBooks = suggestedbookRes.items?.map((elm) => {
        elm.DisplayStatus = elm.titleStateInfo.displayStatus;
        elm.HoldPosition = elm.titleStateInfo.holdPosition;
        elm.titleStateInfo.actions = [];
        elm.titleStateInfo.actions.push({
          actionButtonId: 'btnAddProgram',
          action: 0,
          buyNowUrl: null,
          enabled: true,
          formatType: elm.formatType || elm.FormatType,
          isbn: elm.ISBN || elm.isbn,
          itemId: elm.itemId || elm.ItemId,
          isSuggestedbook: true
        });
        return elm;
      });

      const bookRes = await this.engageService.getBooksByIdV1(bookIds, true);
      this.programDetails.bookLoaded = true;
      const participantCanReadStatus: { [bookId: string]: boolean } = {};

      let programBooks: Book[] = [];

      if (this.programDetails?.type === PROGRAM_TYPE.MileStone) {
        if (this.programDetails.participants?.length > 0 && this.programDetails.participants[0].milestoneBookList) {
          programBooks = this.programDetails.participants[0].milestoneBookList;
        } else {
          programBooks = [];
        }
      } else {
        programBooks = this.programDetails?.books || [];
      }
      programBooks.forEach((book, index) => {

        book.isHiddenTitle = false;
        participantCanReadStatus[book.bookId] = !this.programDetails.isOrder || programBooks[index].percentageComplete === 100;
        const bookIndex = bookRes.items.findIndex((title) => title.itemId === book.bookId);
        if (bookIndex > -1) {
          book.info = bookRes.items[bookIndex];
          book.info.DisplayStatus = book.info.titleStateInfo.displayStatus;
          book.info.HoldPosition = book.info.titleStateInfo.holdPosition;
        } else if (this.programDetails?.type === PROGRAM_TYPE.MileStone && book?.isManual) {
            book.info = { ...new MilestoneInfo(book) };
            book.isHiddenTitle = false;
            book.info.percentageComplete = book.percentageComplete
          }
          else {
            book.isHiddenTitle = true;
            book.percentageComplete = index === 0 ? 100 : programBooks[index - 1].percentageComplete;
          }

        book.percentageComplete = Math.round(book.percentageComplete);
      });
      const dummyBook = {
        bookId: "0001",
        bookName: "",
        author: "",
        bookType: "",
        isManual: true,
        isHiddenTitle: false,
        isbn: "",
        percentageComplete: "",
        sequence: 0,
        info: { addBtn: true }
      }
      let programBooksDummy;
      if (program?.programType === PROGRAM_TYPE.MileStone) {
        programBooks.reverse()
        programBooksDummy = this.insert(programBooks, 0, dummyBook);
        this.programDetails.books = programBooksDummy;
      }
      else {
        programBooksDummy = programBooks;
      }
      this.programDetails.book = programBooksDummy.length > 10 ? programBooksDummy.slice(0, 10) : programBooksDummy;
      const programbook = this.programDetails.book.map((book) => book.bookId)
      this.filteredSuggestedBooks = this.suggestedBooks?.filter((sugges) => !programbook.includes(sugges.itemId));
      this.handleUserRead(participantCanReadStatus);
      this.isLoading = false;
      this.focusingOn();
      positionFooter(true);
    } catch (e) {
      this.isLoading = false;
      this.focusingOn();
      positionFooter(true);
    }
  }
  insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ]
  getProgram(isLoading = true) {
    this.isLoading = isLoading;
    this.programsService.getProgramProgress(this.programId).subscribe(
      (programRes) => {
        if (programRes.programId) {
          this.programDetails = handleProgramDetails(programRes);
          this.parentRoute = 'programs/' + this.programDetails?.id;
          this.getResizedDescription();
          this.getBreadCrumbs();
          positionFooter();
          this.getBooks(programRes);
          this.trackPage(programRes.dbStatus);
        } else {
          this.displayError(programRes);
        }
      },
      (error) => {
        this.displayError(error);
      }
    );
  }
  private displayError(programRes: ProgramAPIRes) {
    this.isLoading = false;
    this.focusingOn();
    const msg = displayErrorMessage(programRes);
    this.toastMessage.showSnackBar(SNAKE_BAR_MODE.ERROR, msg);
    this.router.navigateByUrl(this.isNewUI ? '/view/programs' : '/programs');
  }
  openAlert(data: AlertDialog) {
    this.matDialog.open(AlertDialogComponent, {
      panelClass: 'program-detail-alert-dialog',
      autoFocus: true,
      data,
      ariaLabelledBy: 'alert-heading-desc'
    });
  }
  openLeaveDialog() {
    this.openAlert({
      content: `Are you sure you want to leave the program, ${this.programDetails.name || ''}?`,
      submitBtnText: 'OK',
      submitBtnFunc: () => this.leaveProgram(),
      cancelBtnText: 'Cancel',
      cancelBtnFunc: () => this.programsAnalyticsService.trackNotificationsEvent('CANCEL_LEAVE_PROGRAM')
    });
    this.programsAnalyticsService.trackNotificationsEvent('LEAVE_PROGRAM');
  }
  leaveProgram() {
    this.programsAnalyticsService.trackNotificationsEvent('OK_LEAVE_PROGRAM');
    this.programsService.leaveProgram(this.programId).subscribe(
      (result) => {
        if (result.errors) {
          this.displayError(result);
        } else {
          const msg = result.message;
          this.liveAnnouncer.announce(msg, 'assertive');
          this.toastMessage.showSnackBar(SNAKE_BAR_MODE.INFO, msg);
          this.router.navigateByUrl('/programs');
        }
      },
      (error) => {
        this.displayError(error);
      }
    );
  }
  markAsReadClick(_event) {
    this.redirectUrl(this.router, '/program/' + this.programId, true);
  }
}
