<nav class="kz-drawer-inner-container" *ngIf="!(isMobile() && showSubMenu)" cdkTrapFocus>
    <a class="close-icon" href="javascript:void(0)" (click)="sidebarAction('close')" role="button" aria-expanded="true"
                aria-label="Close" id="menuClosebtn">
                <mat-icon svgIcon="kz-close" aria-hidden="true"></mat-icon>
    </a>
    <ul id="main-menu" role="menu">
        <li role="presentation" *ngIf="userService.userInfo.isLoggedIn">
            <a class="menuitem" role="menuitem" [routerLink]="SIDE_NAV_URL.MY_SHELF" id="loc_linkMyShelf"
                (click)="sidebarAction('close');trackByMenuBarEvent('VIEW_MY_SHEIF')">
                <mat-icon svgIcon="kz-myshelf" aria-hidden="true">myshelf</mat-icon>
                <span class="menuitem-text">My Shelf</span>
            </a>
        </li>
        <li role="presentation">
            <a class="menuitem" role="menuitem" [routerLink]="SIDE_NAV_URL.MY_LIBRARY" id="loc_linkLibrary"
                (click)="sidebarAction('close');trackByMenuBarEvent('VIEW_LIBRARY')">
                <mat-icon svgIcon="kz-library" aria-hidden="true">library</mat-icon>
                <span class="menuitem-text kz-library">Library</span>
            </a>
        </li>
        <li role="presentation">
            <a class="menuitem"  role="menuitem" id="loc_linkBrowserBySubject" [routerLink]="SIDE_NAV_URL.BROWSEBYSUBJECT"
                (click)="sidebarAction('close');trackByMenuBarEvent('VIEW_BROWSE')" (keyup.enter)="sidebarAction('close');" [queryParams]="{subject:''}"
                >
                <mat-icon svgIcon="kz-browse" aria-hidden="true">browse</mat-icon>
                <span class="menuitem-text">{{lblBrowseBySubject}}</span>
            </a>

        </li>
        <li role="presentation" *ngIf="showFeaturedList">
            <a #loc_linkFeaturedLists role="menuitem" (click)="getSubMenuandShow(lblFeaturedList);"
                [ngClass]="{'active':selectedItem==lblFeaturedList}" class="menuitem" href="javascript:void(0)"
                 id="loc_linkFeaturedLists" aria-controls="sub-menu-featured-lists"
                [attr.aria-expanded]="isFeatureListExpand" aria-haspopup="true" [attr.tabindex]="featureListIndex">
                <mat-icon svgIcon="kz-featurelist" aria-hidden="true">featurelist</mat-icon>
                <span class="menuitem-text">{{lblFeaturedList}}</span>
            </a>
        </li>
        <li role="presentation"
            *ngIf="profileInfoService.currentProfile.programsEnabled && userService.userInfo.isLoggedIn">
            <a class="menuitem" role="menuitem" [routerLink]="SIDE_NAV_URL.PROGRAMS" id="loc_linkPrograms"
                (click)="sidebarAction('close');trackByMenuBarEvent('VIEW_PROGRAMS')" [attr.tabindex]="tabIndex">
                <mat-icon svgIcon="kz-programs" aria-hidden="true">programs</mat-icon>
                <span class="menuitem-text">Programs</span>
            </a>
        </li>
        <ng-container *ngIf="userService.userInfo.isLoggedIn">
            <div aria-hidden="true" class="hrule"></div>
            <li role="presentation">
                <a class="menuitem" role="menuitem" attr.aria-label="Checkouts with {{profileInfoService.myStuffCounts['checkouts']}} Books"
                    [routerLink]="SIDE_NAV_URL.CHECKOUT" id="loc_linkCheckouts"
                    (click)="sidebarAction('close');trackByMenuBarEvent('VIEW_CHECKOUTS')" (keyup.enter)="sidebarAction('close');" [attr.tabindex]="tabIndex">
                    <mat-icon svgIcon="kz-checkouts" aria-hidden="true">Checkouts</mat-icon>
                    <span class="menuitem-text">Checkouts</span>
                    <span class="count"
                        *ngIf="profileInfoService.myStuffCounts['checkouts'] !== undefined">{{profileInfoService.myStuffCounts['checkouts']}}</span>
                    <span class="count count-shimmer"
                        *ngIf="profileInfoService.myStuffCounts['checkouts'] === undefined"></span>
                </a>
            </li>
            <li role="presentation" *ngIf="configService.currentLibrary.limits.holdType !== 0 && !configService.currentLibrary.isPPCOnlyLibrary && !configService.currentLibrary.partnerSiteEnabled">
                <a class="menuitem" role="menuitem" attr.aria-label="Holds with {{profileInfoService.myStuffCounts['holds']}} Books"
                    [routerLink]="SIDE_NAV_URL.HOLDS" (keyup.enter)="sidebarAction('close');" id="loc_linkHolds"
                    (click)="sidebarAction('close');trackByMenuBarEvent('VIEW_HOLDS')" [attr.tabindex]="tabIndex">
                    <mat-icon svgIcon="kz-holds" aria-hidden="true">Holds</mat-icon>
                    <span class="menuitem-text">Holds</span>
                    <span class="count"
                        *ngIf="profileInfoService.myStuffCounts['holds'] !== undefined">{{profileInfoService.myStuffCounts['holds']}}</span>
                    <span class="count count-shimmer" *ngIf="profileInfoService.myStuffCounts['holds'] === undefined"></span>
                </a>
            </li>
        </ng-container>
        <div aria-hidden="true" class="hrule"></div>
        <li role="presentation">
            <a class="menuitem" role="menuitem" [href]="SIDE_NAV_URL.HELP" target="_blank" id="loc_linkHelp"
                (click)="trackByMenuBarEvent('VIEW_HELP')" [attr.tabindex]="tabIndex">
                <mat-icon svgIcon="kz-help" aria-hidden="true">help</mat-icon>
                <span class="menuitem-text">Help</span>
            </a>
        </li>
        <li role="presentation" *ngIf="patronTechnicalSupportEnabled">
            <a class="menuitem" role="menuitem" [href]="configService.currentLibrary.supportPageURL" target="_blank" id="loc_linkPatronSupport"
                (click)="trackByMenuBarEvent('VIEW_PATRON_SUPPORT')" [attr.tabindex]="tabIndex">
                <mat-icon svgIcon="kz-support" aria-hidden="true">support</mat-icon>
                <span class="menuitem-text">{{configService.currentLibrary.supportPageLabel || "Patron Support"}}</span>
            </a>
        </li>
        <div aria-hidden="true" class="kz-visually-hidden"></div>
    </ul>
</nav>
<section class="kz-drawer-inner-container drawer-subnav-container" *ngIf="showSubMenu">
    <div class="subnav-head">
        <a aria-label="Back to Main Menu" class="back-link" href="javascript:void(0)" (click)="showSubMenu=false"
            id="back">
            <mat-icon aria-hidden="true" class="back-arrow" svgIcon="kz-left-arrow" aria-hidden="true">left-arrow
            </mat-icon>
            Back
        </a>
        <strong class="main-nav-head">{{selectedItem}}</strong>
        <a aria-label="Close" class="close-icon" (click)="sidebarAction('close')" href="javascript:void(0)" id="close">
            <mat-icon aria-hidden="true">close</mat-icon>
        </a>
    </div>
    <axis360-spinner-loader [isLoading]="true" *ngIf="isLoading"></axis360-spinner-loader>
    <ul id="sub-menu-{{openSubMenuItemId}}" class="sub-menu" role="menu">
        <li role="presentation" *ngFor="let item of collection" #submenu>
            <a class="menuitem" role="menuitem" routerLink="/view/title"
                (click)="sidebarAction('close'); trackBySubMenuItems(item.text)"
                [queryParams]="(openSubMenuItem === 'Featured Lists')?{ list: item.value }:{subject: item?.text?.toLowerCase()}"
                id="loc_txt{{item?.text?.replaceAll(' ', '_').toLowerCase()}}" tabindex="0">{{item?.text?.toLowerCase()}}</a>
        </li>
    </ul>
</section>