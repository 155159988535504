import { Directive, HostListener } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: '[axis360MatMenuFocus]'
})
export class MatMenuFocusDirective {
  constructor(public menuTrigger: MatMenuTrigger) {}

  /**
   * Set focus to the first menu item when directive's host element is clicked
   */
  @HostListener('click')
  onClick() {
    this.menuTrigger.focus();
    setTimeout(() => {
      const menuItems = document.querySelectorAll('.mat-menu-item');
      if (menuItems.length > 0) {
        const firstMenuItem = menuItems[0] as HTMLElement;
        firstMenuItem.focus();
        menuItems[0].setAttribute('cdkFocusRegionStart', 'true');
        menuItems[0].setAttribute('cdkFocusInitial', 'true');
        menuItems[menuItems.length - 1].setAttribute('cdkFocusRegionEnd', 'true');
      }
    }, 0);
  }
}
