import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';
import { PROGRAMS_STATUS, PROGRAMS_STATUS_TEXT } from '@readingprograms/shared/constants/programs.constants';
import { Book } from '@readingprograms/shared/data/engage.model';
import { getBookIcon, getLocalDate } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-programs-title',
  templateUrl: './programs-title.component.html'
})
export class ProgramsTitleComponent implements OnInit {
  @Input() bookDetail: Book & { hideImage: boolean };
  @Input() isBooksInOrder: boolean;
  @Input() programDetails: any;
  @Output() titleMarkAsReadEvent = new EventEmitter();
  showActionCTA = false;
  showPercentage = false;
  kidsZoneEnabled = false;
  axis360Enabled = false;
  hiddenTitleCover = '/angular-app/assets/images/icons-core-unavailable.svg';
  whatNextCover = '/angular-app/assets/images/invalid-name.svg';
  bookRedirectionUrl: string;
  titleAriaLabel: string;
  programStatus: any;
  programStart: any;

  constructor(public userService: UserService, public configService: ConfigService, public profileInfoService: ProfileInfoService) { }

  ngOnInit() {
    if (this.bookDetail.isHiddenTitle) {
      return;
    }
    this.bookRedirectionUrl = this.bookDetail.info
      ? `${window.origin}/Title/TitleDetails?itemId=${this.bookDetail.info.itemId}&ISBN=${this.bookDetail.info.isbn}&isRecommendable=${this.bookDetail.info.isRecommendable
      }&collectionType=${this.bookDetail.info.isRecommendable || 'Default'}`
      : ``;
    this.programStatus = this.programDetails?.statusText;
    this.programStart = this.programDetails?.startDate;
    this.showActionCTA = (getLocalDate(this.programStart) <= getLocalDate(new Date())) && (this.programStatus === PROGRAMS_STATUS_TEXT.STARTED || this.programStatus === PROGRAMS_STATUS_TEXT.JOINED || this.programStatus === PROGRAMS_STATUS_TEXT.Endstoday);
    this.kidsZoneEnabled = this.configService.currentLibrary.kidsZoneEnabled;
    this.axis360Enabled = this.configService.currentLibrary.axis360Enabled;
    const isKZGuest = !this.axis360Enabled && this.kidsZoneEnabled && !this.userService.userInfo?.isLoggedIn;
    const currentProfileFeatures =
      this.configService.getProfileFeatures(isKZGuest ?
        PROFILE_TYPES.TEEN : this.profileInfoService.currentProfile.profileType.toLowerCase()) || {};
    this.showPercentage =
      currentProfileFeatures.insightsBadgesEnabled &&
      (this.programStatus === PROGRAMS_STATUS_TEXT.STARTED
        || this.programStatus === PROGRAMS_STATUS.COMPLETE
        || this.programStatus === PROGRAMS_STATUS.COMPLETED
        || this.programStatus === PROGRAMS_STATUS.NOT_COMPLETE_CLOSED
        || this.programStatus === PROGRAMS_STATUS.COMPLETE_CLOSED);
    const ariaLblPrefix = this.isBooksInOrder ? `${this.bookDetail.sequence}, book` : 'Book';
    const progressAriaLabel =
      this.bookDetail.isParticipantCanRead && this.showPercentage && this.bookDetail.percentageComplete > 0
        ? `and completed ${this.bookDetail.percentageComplete} %`
        : '';
    this.titleAriaLabel = `${ariaLblPrefix} type is ${this.getIcon(this.bookDetail.info.formatType).alt}, title is ${this.bookDetail.info.title
      } , and author is ${this.bookDetail.info.author}, ${progressAriaLabel} ${this.bookDetail.info.titleStateInfo?.displayStatus}`;
  }

  getIcon(formatType) {
    return getBookIcon(formatType);
  }

  markAsReadClick() {
    this.titleMarkAsReadEvent.emit();
  }
}
