<div class="activity-resources-container">
    <div class="activity-resources-content">
        <div class="card-image">
            <img [src]="learningActivity?.imageUrl" alt="">
            <div class="activity-poster d-flex">
                <mat-icon class="activity-poster-icon" svgIcon="kz-activity-poster-icon"></mat-icon>
            </div>
        </div>
        <div class="card-content ">
            <div class="card-text single-ellipsis">
                <h2 class="title single-ellipsis" axis360EllipsisTitle>
                    {{learningActivity?.learningResourceName}}
                </h2>
                <p class="primary-para single-ellipsis" axis360EllipsisTitle>
                    {{learningActivity?.activityType}}
                </p>
                <a href="javascript:;" class="link single-ellipsis" axis360EllipsisTitle [ngClass]="{'disabled': learningActivity?.disabled}"
               (click)="redirectLinkedTitle(learningActivity?.linkedTitleID,learningActivity?.linkedTitleISBN)" >{{learningActivity?.linkedTitle}}</a>
            </div>
            <button mat-raised-button class="btn-primary-blue mt-2" *ngIf="!isDownloading"
                (click)="downloadLearningActivity(learningActivity?.fileUrl);$event.stopPropagation()">Download</button>
            <div class="mt-2 downloading-btn" *ngIf="isDownloading">
                <div class="kz-download-bar-wrapper">
                    <div class="kz-download-bar-container d-flex" tabindex="0">
                        <div class="kz-download-bar" style="width: 50%;">
                        </div>
                    </div>
                    <div class="kz-downloadbar-percentage-sec" style="width: 50%;">
                        <span class="kz-downloadbar-percentage" aria-hidden="true">50%</span>
                    </div>
                </div>
                <button class="kz-play d-flex btn-nobg-bluecolor" [attr.aria-label]="isPlaying ? 'play' : 'pause'">
                    <mat-icon svgIcon="kz-play-blue" *ngIf="isPlaying" aria-hidden="true"></mat-icon>
                    <mat-icon svgIcon="kz-pause" *ngIf="!isPlaying" aria-hidden="true"></mat-icon>
                </button>
                <button class="kz-play d-flex btn-nobg-bluecolor" aria-label="Cancel download">
                    <mat-icon svgIcon="kz-close" aria-hidden="true"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>