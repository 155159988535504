import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_INFO } from '@shared/constants/api.constant';
import { LibCompononent } from '@shared/data/library-base.model';
import { MyStuffsDetail } from '@shared/data/shared.model';
import { ReviewDetails } from '@shared/data/common.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface FeaturedListResponse {
  statusCode: number;
  component: LibCompononent
}
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  inputValue: ReviewDetails;
  isNewUser = false;
  public refinerSubject = new BehaviorSubject('initial');
  public searchSubject = new BehaviorSubject('');
  public isCheckTitleActionCTAList = new Subject<string>();
  public updateActionsButtonCTA = new BehaviorSubject<any>(null);
  public updateAllActionsButtonCTA = new Subject<any>();
  public updateMystuffTabCount = new Subject<boolean>();
  public myStuffCounts = {};
  public updateActionsButtonCheckoutCTA = new BehaviorSubject<any>(null);
  noResults = false;

  constructor(public http: HttpClient) { }


  setRefinerSubject(refiner: string) {
    this.refinerSubject.next(refiner);
  }


  /**
   * set the isCheckTitleActionCTAList
   * pass Param actionBtnCTA
   */
  public setIsCheckTitleActionCTAList(actionBtnCTA: any) {
    this.isCheckTitleActionCTAList.next(actionBtnCTA);
  }

  /**
   *
   * @returns isCheckTitleActionCTAList
   */
  public getIsCheckTitleActionCTAList(): Observable<any> {
    return this.isCheckTitleActionCTAList.asObservable();
  }

  /**
   * set the updateAllActionsButtonCTA
   * pass Param updateBtnCTA
   */
  public setUpdateAllButtonView(updateBtnCTA: any) {
    this.updateAllActionsButtonCTA.next(updateBtnCTA);
  }

  /**
   *
   * @returns updateAllActionsButtonCTA
   */
  public getUpdateAllButtonView(): Observable<any> {
    return this.updateAllActionsButtonCTA.asObservable();
  }
  /**
   * set the updateActionsButtonCTA
   * pass Param updateBtnCTA
   */
  public setUpdateButtonView(updateBtnCTA: any) {
    this.updateActionsButtonCTA.next(updateBtnCTA);
  }

  /**
   *
   * @returns updateActionsButtonCTA
   */
  public getUpdateButtonView(): Observable<any> {
    return this.updateActionsButtonCTA.asObservable();
  }


  getMyShelfNavigation(): Observable<MyStuffsDetail[]> {
    return this.http.get<MyStuffsDetail[]>(API_INFO.getMyStuffs);
  }

  /**
   * To get featured list component data
   * @returns featured list component data
   */
  getFeaturedListComponent = (): Promise<FeaturedListResponse> =>
    this.http.get<FeaturedListResponse>(API_INFO.getFeaturedListComponent).toPromise();

  setInputAlertDialogValue(review) {
    this.inputValue = review;
  }

  getInputAlertDialogValue() {
    return this.inputValue;
  }

  updatMystuffTabCount(isUpdate: boolean){
    this.updateMystuffTabCount.next(isUpdate);
  }
}
