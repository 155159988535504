import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { CAROUSEL_CONST, COLLECTION_TYPE, HOME_AVAILABILITY_EPOPUP_LIB, HOME_FORMAT_TYPE, LIST_CARD_COUNT, SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { getActionButtonsForCollectionCard } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { MyShelfListService } from 'app/core/my-shelf/shared/services/my-shelf-list.service';
import { LibCompononent } from '../shared/data/library.model';
import { MyLibraryService } from '../shared/services/my-library.service';
import { noop } from 'rxjs';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'axis360-always-available',
  templateUrl: './always-available.component.html',
  styleUrls: ['./always-available.component.scss']
})
export class AlwaysAvailableComponent implements OnInit, AfterViewChecked {
  @Input() parentRoute: string;
  @Output() removeCuratedListEvent = new EventEmitter();

  showAlwaysAvailable = false;
  isPPCOnlyLibrary = false;
  isCollectionEnabled = false;
  collectionData = [];
  listData = {};
  defaultList = { listName: '' };
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  showCarouselTitle = true;
  isLoading = true;
  @Input() compData: LibCompononent;
  @Output() progressiveEmit = new EventEmitter();
  epopupValue: boolean;
  constructor(
    private configService: ConfigService,
    private myShelfListService: MyShelfListService,
    public myLibraryService: MyLibraryService,
    public changeDetectorRef: ChangeDetectorRef,
    public searchStorageService: SearchStorageService,
    public profileInfoService: ProfileInfoService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.epopupValue = this.configService.currentLibrary.partnerSiteEnabled;
    this.GetLibrarySettings();
  }

  /**
   * Get library settings and based on that get always available collection
   */
  async GetLibrarySettings() {
    this.isCollectionEnabled = this.configService.getCollectionEnabled();
    const { profileType } = this.profileInfoService.currentProfile || { profileType: '' }
    const profileTypePPC = profileType !== '' ? profileType.toLowerCase() : 'adult';
    this.isPPCOnlyLibrary = this.configService.getIsPPCOnlyLibrary(profileTypePPC);
    if (this.isPPCOnlyLibrary || (this.isCollectionEnabled && !this.isPPCOnlyLibrary)) {
      this.alwaysAvailable(this.isPPCOnlyLibrary);
    } else {
      this.removeCuratedListEvent.emit('always_available');
      this.progressiveEmitAlwaysAvailable();
    }
  }

  /**
   * Get the list for Always Available
   * @param isPPCOnlyLibrary is PPC only library
   */
  alwaysAvailable(isPPCOnlyLibrary = false) {
    this.myShelfListService.getMyCollectionList(COLLECTION_TYPE.PPC).subscribe(
      (listResponse) => {
        this.alwaysAvailableContent(listResponse, this.myShelfListService.selectedFormat, isPPCOnlyLibrary);
      },
      () => {
        this.isLoading = false;
        this.progressiveEmitAlwaysAvailable();
      }
    );
  }

  /**
   * Get the list for Always Available content
   */
  alwaysAvailableContent(myCollectionList, selectedFormat, isPPCOnlyLibrary = false) {
    const format =
      selectedFormat === HOME_FORMAT_TYPE[0].value || selectedFormat === HOME_FORMAT_TYPE[2].value
        ? HOME_FORMAT_TYPE[0].value
        : HOME_FORMAT_TYPE[1].value;
    const { profileType } = this.profileInfoService.currentProfile || { profileType: '' }
    if (!isPPCOnlyLibrary) {
      myCollectionList = myCollectionList?.listModels?.filter((item) => item.listName === CAROUSEL_CONST.featured);
      this.myShelfListService.getMyCollectionContent((myCollectionList || [{}])[0]?.listID, format, '1', LIST_CARD_COUNT.toString()).subscribe(
        (res1) => {
          const alwaysAvail = res1;
          if (alwaysAvail) {
            this.myLibraryService.titlesAvailable = alwaysAvail.Items.length > 0;
            const listData = {
              listName: this.compData.title,
              description: this.compData.description,
              listId: 'ppc',
              totalItems: alwaysAvail?.TotalItems || 0,
              seeAll: `/view/title?alwaysavailable=${(myCollectionList || [{}])[0]?.ListID || (myCollectionList || [{}])[0]?.listID}&listName=${this.compData.title}`
            };
            this.epopupValue && this.searchStorageService.setHomeFiters(HOME_AVAILABILITY_EPOPUP_LIB[0].value, format);
            listData.seeAll = this.myLibraryService.updateSeeAllURL(listData.seeAll);
            this.collectionData.push({ ...alwaysAvail, listData });
            if (profileType !== '') {
              for (const listConstent in alwaysAvail?.Items) {
                if (alwaysAvail?.Items.hasOwnProperty(listConstent)) {
                  this.getActionViewButtonForCollectionList(alwaysAvail?.Items[listConstent], alwaysAvail.listName);
                }
              }
            }
            if (listData.totalItems === 0) {
              this.removeCuratedListEvent.emit('always_available');
            }
          }
          this.isLoading = false;
          let { Items } = this.collectionData[0];
          this.compData.isShow = Items.length > 0;
          this.progressiveEmitAlwaysAvailable();
        },
        () => {
          this.isLoading = false;
          this.progressiveEmitAlwaysAvailable();
        }
      );
      return;
    }
    this.alwaysAvailableContentPPCOnly((myCollectionList?.listModels || []), format);
  }

  /**
   * Get the list for Always Available content for ppc only library
   */
  alwaysAvailableContentPPCOnly(myCollectionList, format) {
    if (myCollectionList?.length === 0) {
      return;
    }
    const { profileType } = this.profileInfoService.currentProfile || { profileType: '' }
    for (const list in myCollectionList) {
      if (myCollectionList.hasOwnProperty(list)) {
        this.myShelfListService
          .getMyCollectionContent((myCollectionList[list].ListID || myCollectionList[list].listID), format, '1', LIST_CARD_COUNT.toString())
          .subscribe((res1) => {
            const alwaysAvail = res1;
            if (alwaysAvail) {
              const listData = {
                listName: myCollectionList[list].ListName || myCollectionList[list].listName,
                listId: 'ppc',
                description: myCollectionList[list].Description || myCollectionList[list].description,
                totalItems: alwaysAvail.TotalItems,
                seeAll: `/view/title?list=${myCollectionList[list].ListID || myCollectionList[list].listID}`
              };
              this.collectionData.push({ ...alwaysAvail, listData });
              if (profileType !== '') {
                this.getMyLibraryListActionButtonV2(alwaysAvail);
              }
            }
            let { Items } = this.collectionData[0];
            this.compData.isShow = Items.length > 0;
          });
      }
    }
    this.isLoading = false;
    this.progressiveEmitAlwaysAvailable();
  }

  /**
   * get title state info for card
   * @param inputdata
   */
  getActionViewButtonForCollectionList = getActionButtonsForCollectionCard.apply(this);

  /**
   * get title state info for card
   * @param inputdata
   */
  getMyLibraryListActionButtonV2(inputdata) {
    try {
      this.myShelfListService.getMyLibraryListActionButtonV2(inputdata).subscribe((actionButtonResponse) => {
        this.collectionData.map(dataCollection => {
          dataCollection.Items.map(item => {
            const listData = inputdata.Items?.filter((elm) => elm.ISBN === item.ISBN);
            if (listData.length > 0) {
              item.DisplayStatus = actionButtonResponse.find((elm) => elm.isbn === item.isbn)?.displayStatus || '';
              item.HoldPosition = actionButtonResponse.find((elm) => elm.isbn === item.isbn)?.holdPosition || '';
              item.titleStateInfo = { ...actionButtonResponse.find((elm) => elm.isbn === item.isbn), actions: [] };
            }
          });
        });
      });
    } catch (error) {
      const listConstentItems = this.collectionData;
      this.collectionData = listConstentItems;
      return;
    }
  }

  progressiveEmitAlwaysAvailable() {
    if (!this.isPPCOnlyLibrary) {
      this.progressiveEmit.emit();
    } else {
      this.userService.isInitialLoaded = true;
      noop();  // Assuming noop is a function
    }
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
